import React from "react";
import axios from 'axios';
import '../util/config'
import { Button } from "react-bootstrap";
import RicercaCassaRiga from "./RicercaCassaRiga";
import {connect} from 'react-redux'

class RicercaCassa extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            movimenti:[],
            dal:localStorage.getItem('ricercaCassaDal'),
            al:localStorage.getItem('ricercaCassaAl'),
            descrizione:localStorage.getItem('ricercaCassaDescrizione')?localStorage.getItem('ricercaCassaDescrizione'):'',
            totEntrate:0,
            totUscite:0,
            totRicevute:0,
            printRecipients:[],
            saveRecipients:[],
            totDaStampare:0,
            selectAllPagamenti:false,
            selectAllRicevute:false
        }
        this.update = this.update.bind(this);
        this.cercaCassa = this.cercaCassa.bind(this);
        this.addStampaList = this.addStampaList.bind(this);
        this.addSalvaList = this.addSalvaList.bind(this);
        this.stampaRicevute = this.stampaRicevute.bind(this);
        this.salvaRicevute = this.salvaRicevute.bind(this);
        this.selectAllPagamenti = this.selectAllPagamenti.bind(this);
        this.selectAllRicevute = this.selectAllRicevute.bind(this);
	  }

    selectAllPagamenti(){
        this.setState({selectAllPagamenti: !this.state.selectAllPagamenti})
        if (!this.state.selectAllPagamenti){
            if(this.state.movimenti&&this.state.movimenti.length>0){
                        this.state.movimenti.map(movimento=>(
                            ((!movimento.ricevute||movimento.ricevute.length===0)&&movimento.key)?
                            
                            this.addSalvaList('add',movimento.key.id+'**'+movimento.dataOperazione+'**'+movimento.entrate+'**'+movimento.idAbbonamento)
                            :null
                        )
            )
        }
        }
        else{
            this.setState({totDaStampare:0, saveRecipients:[]})
        }
    }

    selectAllRicevute(){
        this.setState({selectAllRicevute: !this.state.selectAllRicevute})
        if (!this.state.selectAllRicevute){
            if(this.state.movimenti&&this.state.movimenti.length>0){
                        this.state.movimenti.map(movimento=>(
                            ((movimento.ricevute&&movimento.ricevute.length>0)&&movimento.key)?
                            movimento.ricevute.map(ricevuta=>(
                                this.addStampaList('add',ricevuta.id)
                            ))
                            :null
                        )
            )
        }
        }
        else{
            this.setState({printRecipients:[]})
        }
    }

      addStampaList(action, id){
        if (action==='add'){
            this.state.printRecipients.push(id)
            console.log('aggiunto '+id)
            console.log(this.state.printRecipients);
            console.log(this.state.tutti)
        }
        else{
            var anagIndex = this.state.printRecipients.indexOf(id);
            this.state.printRecipients.splice(anagIndex, 1);
            console.log('eliminato '+id)
            console.log(this.state.printRecipients)
            console.log(this.state.tutti)
        }
    }

    addSalvaList(action, id){
        if (action==='add'){
            this.state.saveRecipients.push(id)
            console.log('aggiunto '+id)

            console.log(id.split('**')[2])
            console.log(this.state.saveRecipients);
        }
        else{
            var anagIndex = this.state.saveRecipients.indexOf(id);
            this.state.saveRecipients.splice(anagIndex, 1);
            console.log('eliminato '+id)
            
            console.log(this.state.saveRecipients)
        }
        var ciccio=0;
        if(this.state.saveRecipients){
            this.state.saveRecipients.map(rec=>(
                ciccio+=parseFloat(rec.split('**')[2])
            ))
        }
        console.log(ciccio)
        this.setState({totDaStampare:ciccio})
    }

    salvaRicevute(e){
        
         if (this.state.saveRecipients
             &&this.state.saveRecipients.length>0){

        var mapForm = document.createElement("form");
        mapForm.target = "Map";
        mapForm.id = "formSsalvaRicevute";
        mapForm.method = "POST";
        mapForm.action = global.config.server+'/salvaStampaRicevute';
        

        var salva = document.createElement("input");
        salva.type = "hidden";
        salva.name = "salvaStampaRicevute";
        salva.value = 1;
        mapForm.appendChild(salva);

        var website = document.createElement("input");
        website.type = "hidden";
        website.name = "website";
        website.value = global.config.domain;
        mapForm.appendChild(website);

        var email = document.createElement("input");
        email.type = "hidden";
        email.name = "email";
        email.value = this.props.email
        mapForm.appendChild(email);

        var palestra = document.createElement("input");
        palestra.type = "hidden";
        palestra.name = "palestra";
        palestra.value = this.props.palestra.id
        mapForm.appendChild(palestra);

        this.state.saveRecipients.map(ric=>{
            var elements=ric.split('**')

            var idPagamento = document.createElement("input");
            idPagamento.type = "hidden";
            idPagamento.name = "idPagamento";
            idPagamento.value = elements[0]
            mapForm.appendChild(idPagamento);

            var data = document.createElement("input");
            data.type = "hidden";
            data.name = "dataPagamento_"+elements[0];
            data.value = elements[1]
            mapForm.appendChild(data);

            var importo = document.createElement("input");
            importo.type = "hidden";
            importo.name = "importoPagamento_"+elements[0];
            importo.value = elements[2]
            mapForm.appendChild(importo);

            var abbo = document.createElement("input");
            abbo.type = "hidden";
            abbo.name = "idAbbonamento_"+elements[0];
            abbo.value = elements[3]
            mapForm.appendChild(abbo);
        })
        document.body.appendChild(mapForm);

        var map = window.open("", "Map", "status=0,title=0,height=600,width=800,scrollbars=1");

            if (map) {
                mapForm.submit()
                mapForm.remove();
                setTimeout(function () {
                    this.cercaCassa()
                }.bind(this), 4000);
                   
            } else {
                alert('You must allow popups for this map to work.');
            }

         }
         else{
             alert('Devi selezionare almeno un pagamento');
         }
    }

    stampaRicevute(){
        if (this.state.printRecipients
            &&this.state.printRecipients.length>0){

        var mapForm = document.createElement("form");
        mapForm.target = "Map";
        mapForm.id = "formStampaRicevute";
        mapForm.method = "POST";
        mapForm.action = global.config.server+'/salvaStampaRicevute';

        var website = document.createElement("input");
        website.type = "hidden";
        website.name = "website";
        website.value = global.config.domain;
        mapForm.appendChild(website);

        var email = document.createElement("input");
        email.type = "hidden";
        email.name = "email";
        email.value = this.props.email
        mapForm.appendChild(email);

        var palestra = document.createElement("input");
        palestra.type = "hidden";
        palestra.name = "palestra";
        palestra.value = this.props.palestra.id;
        mapForm.appendChild(palestra);

        this.state.printRecipients.map(ric=>{
            var idRicevuta = document.createElement("input");
            idRicevuta.type = "hidden";
            idRicevuta.name = "idRicevuta";
            idRicevuta.value = ric
            mapForm.appendChild(idRicevuta);
        })
        document.body.appendChild(mapForm);

        var map = window.open("", "Map", "status=0,title=0,height=600,width=800,scrollbars=1");

            if (map) {
                mapForm.submit();
                mapForm.remove();
            } else {
                alert('You must allow popups for this map to work.');
            }
        }
        else{
            alert('Devi selezionare almeno una ricevuta');
        }
    }

	componentDidMount(){
        
    }

    cercaCassa() {
        //e.preventDefault()
        this.setState({printRecipients:[]})
        this.setState({saveRecipients:[]})
        const { dal, al, descrizione} = this.state;
        localStorage.setItem('ricercaCassaDal',dal)
        localStorage.setItem('ricercaCassaAl',al)
        localStorage.setItem('ricercaCassaDescrizione',descrizione)
        if (dal===''&&al===''&&descrizione===''){
            alert('Specificare almeno un filtro')
            return
        }
	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('dal', dal);
        formData.append('al', al);
        formData.append('descrizione', descrizione);

	      axios.post(global.config.server+'/cercaCassa',formData)
          .then((result) => {
			console.log(result.data)
            if (result.data
                ){
                   var toten=0;
                   var totus=0;
                   var totricevute=0;
                    result.data.map(movimento=>{
                        if (movimento.key){
                            toten+=movimento.entrate
                            totus+=movimento.uscite
                            if (movimento.ricevute){
                                movimento.ricevute.map(ricevuta=>{
                                    totricevute+=ricevuta.importo
                                })
                            }
                        }
                    })
                    this.setState({totEntrate:toten})
                    this.setState({totUscite:totus})
                    this.setState({totRicevute:totricevute})
                    this.setState({movimenti:[]})
                    this.setState({movimenti:result.data})
                }
                else{
        	        alert(result.data)
                }
        });

    }

	update(e) {
          this.setState({ [e.target.name]: e.target.value});
    }
    
	render() {
	    return (
            <div className="container-fluid">
              
                <div className="form-group">
                    <label htmlFor="dal">DA</label>
                    <input type="date" className="form-control" id="dal" name="dal" value={this.state.dal} placeholder="Specificare un periodo iniziale" onChange={this.update} />
                    <label htmlFor="al">A</label>
                    <input type="date" className="form-control" id="al" name="al" value={this.state.al} placeholder="Specificare un periodo finale" onChange={this.update} />
                </div>
                <div className="form-group">
                    <label htmlFor="descrizione">Descrizione</label>
                    <input type="text" className="form-control" id="descrizione" name="descrizione" value={this.state.descrizione} placeholder="cerca per descrizione" onChange={this.update} />
                </div>
                <Button className="btn btn-primary" onClick={this.cercaCassa}>Cerca</Button>
                <table  className="table table-striped">
                    <thead>
                    <tr>
                    <th>ID</th>
                        <th>Data</th><th>Descrizione</th><th>Entrata</th><th>Uscita</th><th>Tipo pagamento</th>
                        <th>Emetti ricevuta<br />
                    <label>(seleziona tutto)<Button onClick={this.selectAllPagamenti}>Seleziona tutti</Button></label></th>
                    <th>Ricevute salvate <br /><label>(seleziona tutto)<Button onClick={this.selectAllRicevute}>Seleziona tutti</Button></label></th>
                    </tr>
                    </thead>
                    <tbody>
                {
                    this.state.movimenti&&this.state.movimenti.length>0?
                        this.state.movimenti.map(movimento=>(
                                <RicercaCassaRiga 
                                key={movimento.id}
                                movimento={movimento} link={this.props.link} 
                                addStampaList={this.addStampaList} addSalvaList={this.addSalvaList} 
                                selectAllPagamenti={this.state.selectAllPagamenti} selectAllRicevute={this.state.selectAllRicevute} 
                                setview={this.props.setview} />
                        ))
                    :"Nesun movimento trovato"
                }
                <tr style={{extAlign: 'right'}}>
                       <td colSpan={2}></td>
                        <td>TOTALE</td>
                        <td style={{textAlign:'right'}}>{this.state.totEntrate}</td>
                        <td style={{textAlign:'right'}}>{this.state.totUscite}</td>
                      <td style={{textAlign:'center'}}>
                      
                      </td> 
                      <td>
                          Totale ricevute da salvare: {this.state.totDaStampare}<br />
                      <Button onClick={this.salvaRicevute}>Salva e Stampa le Ricevute selezionate</Button>
                    </td> 
                      <td>
                      Totale ricevute: {this.state.totRicevute}<br />
                      <Button onClick={this.stampaRicevute}>Stampa le Ricevute selezionate</Button>
                      </td> 
                    </tr>
                </tbody>
                </table>
            </div>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(RicercaCassa)