import {createSlice} from '@reduxjs/toolkit';

export const gestoreOrdiniSlice = createSlice({
  name: 'gestoreOrdini',
  initialState: {
    ordini: [
    ],
  },
  reducers: {
    setordini: (state, ordini) => {
      console.log('setordini ' + ordini.payload);
      state.ordini = ordini.payload;
    },
  },
});

export const {setordini,} = gestoreOrdiniSlice.actions;

export default gestoreOrdiniSlice.reducer;