import React from "react";
import { useSelector } from 'react-redux';
import { getallattivita, getattivitaanag } from "../../DAO/attivitaquotidianaDAO";
import { useState } from "react";
import { useEffect } from "react";
import InputText from "../../util/UI/InputText";
import Text from "../../util/UI/Text";

export default function Metabolismobasale({altezza, setmeabolismobasale, obiettivopeso, setobiettivopeso, setfcgobiettivopeso, data}){

    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);
    const palestra= useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    
    const [attanag, setattanag]=useState([])
    const [att, setatt]=useState([])

    function calculateage(dob, dataaquandocalcolareeta) { 
        var diff_ms = (dataaquandocalcolareeta?new Date(dataaquandocalcolareeta.split('/')[2]+'-'+dataaquandocalcolareeta.split('/')[1]+'-'+dataaquandocalcolareeta.split('/')[0]).getTime():Date.now) - new Date(dob.split('/')[2]+'-'+dob.split('/')[1]+'-'+dob.split('/')[0]).getTime();
        var age_dt = new Date(diff_ms); 
        var test=Math.abs(age_dt.getUTCFullYear() - 1970);
        return test
    }

    function harris(sesso, altezza, peso, eta){
        let valoreharris=0
        if (sesso==='M'){
            valoreharris=Math.round((66.473+(13.7516*peso)+(5.0033*altezza)-(6.755*eta))*100)/100
        }
        else{
            valoreharris=Math.round((655.09+(9.56*peso)+(1.64*altezza)-(6.67*eta))*100)/100
        }
        return valoreharris;
    }

    function  moltiplicatore(eta, sesso){
        if (sesso==='M'){
            if(eta<=29){
                return 15.3
            }
            else if(eta<=59){
                return 11.6
            }
            else if(eta<=74){
                return 11.9
            }
            else return 8.4
        }
        else{
            if(eta<=29){
                return 14.7
            }
            else if(eta<=59){
                return 8.7
            }
            else if(eta<=74){
                return 9.2
            }
            else return 9.8
        }
    }

    function additore(eta, sesso){
        if (sesso==='M'){
            if(eta<=29){
                return 679
            }
            else if(eta<=59){
                return 879
            }
            else if(eta<=74){
                return 700
            }
            else return 819
        }
        else{
            if(eta<=29){
                return 496
            }
            else if(eta<=59){
                return 829
            }
            else if(eta<=74){
                return 688
            }
            else return 624
        }
    }


    function pesoideale(altezza, sesso){
        const pesoideale=Math.round(
            (Math.pow(((altezza)/100),2)*(sesso==='M'?22.1:20.6)*100)
            )/100
        return pesoideale
    }

    function metabolismo(sesso, eta,peso){
        const molt=moltiplicatore(eta,sesso)
        const add=additore(eta, sesso)
        const tot=Math.round(((peso*molt)+add)*100)/100
        setmeabolismobasale(tot)
        return tot
    }

    function laf(){
        let ttt=0;
        let tttiei=0
        attanag.map(res => {
            ttt+=res.ore
            let atttemp=att.filter(alltemp => alltemp.id===res.idattivita)[0]
            const ieitemp=atttemp?atttemp.iei:0
            tttiei+=res.ore*ieitemp
        })
        return Math.round((tttiei/ttt)*100)/100
    }

    function schoff(peso, eta, sesso){

        
        return Math.round(((peso*moltiplicatore(eta, sesso))+additore(eta, sesso))*100)/100
    }

    function fcg(sesso, eta, peso, isobiettivopeso){

        const metab=metabolismo(sesso, eta, peso)
        
        //console.log(metab+"*"+tttiei+"/"+ttt)
        const valuefcg=(Math.round(metab*laf()*100)/100)
        if (isobiettivopeso){
            setfcgobiettivopeso(valuefcg)
        }
        return valuefcg
    }

    useEffect(() => {
        async function load(){
            const result=await getattivitaanag(anagselezionata.id)
            setattanag(result)
            const allatt=await getallattivita(palestra.id)
            setatt(allatt)
        }
        load()
    },[anagselezionata])

    return (
        <>
        <div className="row">

        <div className="col-md-4">
            <label>Peso ideale kg &nbsp;
            {pesoideale(altezza,anagselezionata.sesso)}
            </label>
        </div>
            
        <div className="col-md-4">
            <label>Metabolismo basale su Peso ideale &nbsp;
            {metabolismo(anagselezionata.sesso, calculateage(anagselezionata.datanascita, data),pesoideale(altezza,anagselezionata.sesso))}
            </label>
        </div>

        <div className="col-md-4">
            <label>FCG &nbsp;
            {fcg(anagselezionata.sesso, calculateage(anagselezionata.datanascita, data),pesoideale(altezza,anagselezionata.sesso), false)}
            </label>
        </div>
        </div>
<div className="row">
    <InputText 
            label={"Obiettivo peso kg"}
            bootstrapsize={"col-md-4"}
            name={"obiettivopeso"}
            onChange={(e) => { setobiettivopeso(e.target.value)}}
            placeholder={"Obiettivo peso kg"}
            type={"number"}
            value={obiettivopeso} width={100}/>

<div className="col-md-4">
            <label>Metabolismo basale su obiettivo peso &nbsp;
            {metabolismo(anagselezionata.sesso, calculateage(anagselezionata.datanascita,data), obiettivopeso)}
            </label>
        </div>
        <div className="col-md-4">
            <label>FCG &nbsp;
            {fcg( anagselezionata.sesso, calculateage(anagselezionata.datanascita,data),obiettivopeso, true)}
            </label>
        </div>
        </div>

        <div className="row">
        <Text label={"LAF"}
        bootstrapsize={"col-md-2"}
        value={laf()}
         />
         <div className="col-md-4">
         <Text label={"HARRIS"}
        bootstrapsize={"col-md-12"}
        value={harris(anagselezionata.sesso,altezza,obiettivopeso,calculateage(anagselezionata.datanascita,data))}
         />
 <Text label={"Dispendio HA*LAF"}
        bootstrapsize={"col-md-12"}
        value={Math.round((harris(anagselezionata.sesso,altezza,obiettivopeso,calculateage(anagselezionata.datanascita,data))*laf())*100)/100}
         />
         </div>
         <div className="col-md-4">
<Text label={"SCHOFF"}
        bootstrapsize={"col-md-12"}
        value={schoff(obiettivopeso, calculateage(anagselezionata.datanascita, data), anagselezionata.sesso)}
         />
        
         <Text label={"Dispendio SCH*LAF"}
        bootstrapsize={"col-md-12"}
        value={Math.round((schoff(obiettivopeso, calculateage(anagselezionata.datanascita, data), anagselezionata.sesso)*laf())*100)/100}
         />
         </div>
          <Text label={"DIFF HA-SCH"}
        bootstrapsize={"col-md-2"}
        value={Math.abs(harris(anagselezionata.sesso,altezza,obiettivopeso,calculateage(anagselezionata.datanascita,data))-schoff(obiettivopeso, calculateage(anagselezionata.datanascita, data), anagselezionata.sesso))/harris(anagselezionata.sesso,altezza,obiettivopeso,calculateage(anagselezionata.datanascita,data))}
         />
         

        </div>
        </>
    )
}