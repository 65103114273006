import React from "react";
import { useState } from "react";
import Calendari from "../calendario/Calendari";
import CalendarioAdmin from "../calendario/CalendarioAdmin";

export default function ViewCalendario(props){
    const [calendario, setCalendario] = useState(props.calendario);

function caricacalendario(calendario){
    console.log(calendario)
    setCalendario(calendario)
}

    return(
        <div>
            <Calendari email={props.email} calendario={props.calendario} setCalendario={caricacalendario} />
            <CalendarioAdmin key={calendario} email={props.email} calendario={calendario} />
        </div>
    );
}