import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import {navigate} from 'hookrouter'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";
import { getMessaggi, getMessaggiadmin, inviaMessaggio } from "../DAO/messaggiDAO";

export default function Messaggi(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata)

    const [messaggi, setmessaggi]=useState([])
    const [messaggio, setmessaggio] = useState('')

    function caricamessaggi(){
      async function carica(){
        const result = await getMessaggi(email,palestra.id,idanagselezionata);
        setmessaggi(result)
      }
      carica()
    }

    useEffect(() =>{
      caricamessaggi()
      },[idanagselezionata])

    function inviaMsg(){
        if (messaggio.trim()===''){
          alert("Messaggio obbligatorio");
          return;
      }
      async function invia(){
        const result = await inviaMessaggio(email,palestra.id,idanagselezionata,messaggio,0)
        alert(result)
        caricamessaggi()
        setmessaggio('')
      }
      invia()
    }

    return (
        <span>
            <div className="row">
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-body">
                            {messaggi&&Array.isArray(messaggi)&&messaggi.length>0?
                            messaggi.map(messaggio=>(
                        <p className="card-text">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    {messaggio.messaggio}
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Data e ora</label>
                                    <input type="text" className="form-control" value={messaggio.data} />
                                </div>
                                <div className="form-group col-md-3">
                                    <label>Letto</label>
                                    <input type="text" className="form-control" value={messaggio.letto} />
                                </div>
                            </div>
                            <hr />
                        </p>
                        ))
                        :"Nessun messaggio trovato"}
                        </div>
                    </div>
                </div>
                <div className="col-md-3">
                  Nuovo messaggio<br />
                  <textarea class="form-control" 
                  name="messaggio" id="Messaggio" 
                  value={messaggio}
                  onChange={(e) => setmessaggio(e.target.value)}></textarea>
                  <button class="btn btn-primary" onClick={inviaMsg}>Invia messaggio</button>
                </div>
    </div>
    
</span>
);
  }