import React from "react";
import RepAbbonamenti from "./RepAbbonamenti";

export default class  ListaAbbonamenti extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            abbonamenti:[]
	    };

	}

	componentDidMount(){
		this.setState({abbonamenti:this.props.abbonamenti})
	  }
	
	componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
		console.log("errore");
	    console.log(error, info);
	  }
	
	render() {
	    return (
	    		<div>
					<RepAbbonamenti abbonamenti={this.props.abbonamenti}/>
		</div>
	    );
	}
}