import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updalimento } from "../DAO/alimentiDAO";
import { useEffect } from "react";
import InputText from "../util/UI/InputText";
import Swal from "sweetalert2";

let timeout=0; 
export default function EditAlimento(props) {
    const [modificato, setmodificato] = useState(false)
    
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);

    const [nome, setnome] = useState(props.alimento.nome);
    const [acidofolico, setacidofolico] = useState(props.alimento.acidofolico);
    const [amido, setamido] = useState(props.alimento.amido);
    const [caplusplus, setcaplusplus] = useState(props.alimento.caplusplus);
    const [carboidrati, setcarboidrati] = useState(props.alimento.carboidrati);
    const [colesterolo, setcolesterolo] = useState(props.alimento.colesterolo);
    const [feplusplus, setfeplusplus] = useState(props.alimento.feplusplus);
    const [fibra, setfibra] = useState(props.alimento.fibra);
    const [qtarif, setqtarif] = useState(props.alimento.qtarif);
    const [h2o, seth2o] = useState(props.alimento.h2o);
    const [id, setid] = useState(props.alimento.id);
    const [indiceglicemico, setindiceglicemico] = useState(props.alimento.indiceglicemico);
    const [kcal, setkcal] = useState(props.alimento.kcal);
    const [kplus, setkplus] = useState(props.alimento.kplus);
    const [lattosio, setlattosio] = useState(props.alimento.lattosio);
    const [lipidi, setlipidi] = useState(props.alimento.lipidi);
    const [mg, setmg] = useState(props.alimento.mg);
    const [naplus, setnaplus] = useState(props.alimento.naplus);
    const [niacinab3, setniacinab3] = useState(props.alimento.niacinab3);
    const [p, setp] = useState(props.alimento.p);
    const [proteine, setproteine] = useState(props.alimento.proteine);
    const [riboflavinab2, setriboflavinab2] = useState(props.alimento.riboflavinab2);
    const [saturi, setsaturi] = useState(props.alimento.saturi);
    const [tiaminab1, settiaminab1] = useState(props.alimento.tiaminab1);
    const [vitaminaa, setvitaminaa] = useState(props.alimento.vitaminaa);
    const [vitaminab12, setvitaminab12] = useState(props.alimento.vitaminab12);
    const [vitaminac, setvitaminac] = useState(props.alimento.vitaminac);
    const [vitaminad, setvitaminad] = useState(props.alimento.vitaminad);
    const [vitaminae, setvitaminae] = useState(props.alimento.vitaminae);
    const [zn, setzn] = useState(props.alimento.zn);
    const [zuccherisolubili, setzuccherisolubili] = useState(props.alimento.zuccherisolubili);
    const [note, setnote] = useState('');

    function updalim(){
        async function upd(){
            const result = await updalimento(nome, 
                acidofolico, amido, caplusplus, carboidrati, colesterolo, feplusplus, fibra,
                qtarif, h2o, id, palestra.id, indiceglicemico, kcal, kplus, lattosio, lipidi,
                mg, naplus, niacinab3, p, proteine, riboflavinab2, saturi, tiaminab1, vitaminaa, 
                vitaminab12, vitaminac, vitaminad, vitaminae, zn, zuccherisolubili, note);
                if (result.id>0){
                    Swal.fire({
                        position:'bottom-end',
                        title:'Salvataggio Alimento',
                        showConfirmButton:false,
                        text:nome+ ' salvato correttamente',
                        icon:'success',
                        timer:2000
                    }
                      )
                }
        }
        upd()
    }

    useEffect(() => {
        if (!modificato) {
            setmodificato(true)
         } else {
            clearTimeout(timeout); 
            timeout = setTimeout(() => {
                updalim()
                
            },1000) 
        }
    },[nome, 
        acidofolico, amido, caplusplus, carboidrati, colesterolo, feplusplus, fibra,
        qtarif, h2o, id, palestra.id, indiceglicemico, kcal, kplus, lattosio, lipidi,
        mg, naplus, niacinab3, p, proteine, riboflavinab2, saturi, tiaminab1, vitaminaa, 
        vitaminab12, vitaminac, vitaminad, vitaminae, zn, zuccherisolubili, note])

    return (
        <tr>
            <td>
                <InputText width={200} type="text" name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />
            </td>
            <td>
                <InputText width={100} type="number" name="qtarif" step={.01} value={qtarif} onChange={(e) => {setqtarif(e.target.value)}} />
            </td>
            <td><textarea type="text" name="note" step={.01} value={note} onChange={(e) => setnote(e.target.value)} /></td> 
            <td><InputText width={100} type="number" name="kcal" step={.01} value={kcal} onChange={(e) => setkcal(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="h2o" step={.01} value={h2o} onChange={(e) => seth2o(e.target.value)} /></td>
            <td><InputText width={100}tText type="number" name="lipidi" step={.01} value={lipidi} onChange={(e) => setlipidi(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="saturi" step={.01} value={saturi} onChange={(e) => setsaturi(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="colesterolo" step={.01} value={colesterolo} onChange={(e) => setcolesterolo(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="proteine" step={.01} value={proteine} onChange={(e) => setproteine(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="carbo" step={.01} value={carboidrati} onChange={(e) => setcarboidrati(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="amido" step={.01} value={amido} onChange={(e) => setamido(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="zuccheri" step={.01} value={zuccherisolubili} onChange={(e) => setzuccherisolubili(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="fibra" step={.01} value={fibra} onChange={(e) => setfibra(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="latto" step={.01} value={lattosio} onChange={(e) => setlattosio(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="glicemia" step={.01} value={indiceglicemico} onChange={(e) => setindiceglicemico(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="nap" step={.01} value={naplus} onChange={(e) => setnaplus(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="kp" step={.01} value={kplus} onChange={(e) => setkplus(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="fe++" step={.01} value={feplusplus} onChange={(e) => setfeplusplus(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="ca++" step={.01} value={caplusplus} onChange={(e) => setcaplusplus(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="p" step={.01} value={p} onChange={(e) => setp(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="mg" step={.01} value={mg} onChange={(e) => setmg(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="zn" step={.01} value={zn} onChange={(e) => setzn(e.target.value)} /></td>								
            <td><InputText width={100} type="number" name="tiaminab1" step={.01} value={tiaminab1} onChange={(e) => settiaminab1(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="riblo" step={.01} value={riboflavinab2} onChange={(e) => setriboflavinab2(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="niacinab3" step={.01} value={niacinab3} onChange={(e) => setniacinab3(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="acido" step={.01} value={acidofolico} onChange={(e) => setacidofolico(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="b12" step={.01} value={vitaminab12} onChange={(e) => setvitaminab12(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="vita" step={.01} value={vitaminaa} onChange={(e) => setvitaminaa(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="vitc" step={.01} value={vitaminac} onChange={(e) => setvitaminac(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="vite" step={.01} value={vitaminae} onChange={(e) => setvitaminae(e.target.value)} /></td>
            <td><InputText width={100} type="number" name="vitd" step={.01} value={vitaminad} onChange={(e) => setvitaminad(e.target.value)} /></td>
        </tr>
                   
    );
    
}