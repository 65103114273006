import React from "react";
import { useState } from "react";
import {OutTable, ExcelRenderer} from 'react-excel-renderer'
import { cercatessera, checkanagtessera, salvadatitessera } from "../DAO/tessereDAO";
import {useSelector} from "react-redux";
import RigaTessereAnagrafica from "./RigaTessereAnagrafica";

export default function Uploadtesserati(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

    const [rows, setrows] = useState([]);
    const [cols, setcols] = useState([]);
    const [numriga, setnumriga] = useState(0)
    const [righe, setrighe] = useState([])
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

    const [cercanome, setcercanome] = useState('')
    const [cercacognome, setcercacognome] = useState('')
    const [cercanumero, setcercanumero] = useState('')
    const [cercavalidoda, setcercavalidoda] = useState('')
    const [cercavalidoa, setcercavalidoa] = useState('')
    const [tesseratitrovati, settesseratitrovati] = useState([])
    var righepostcheck=[]

    const fileHandler = (event) => {
        setrighe([])
        let fileObj = event.target.files[0];
    
        //just pass the fileObj as parameter
        ExcelRenderer(fileObj, (err, resp) => {
          if(err){
            console.log(err);            
          }
          else{
              setcols(resp.cols);
              setrows(resp.rows);
          }
        });               
    
      }

    
    function checkriga(riga){
        async function checktessera(cognome, nome, datanascita, numero, validoda, validoa){
            const result=await checkanagtessera(cognome, nome, datanascita, numero, validoda, validoa, palestra.palestra.id);
            console.log("result "+result)
            const rigachecked=[result, cognome, nome, datanascita, numero, validoda, validoa]
            righepostcheck.push(rigachecked)
            setkeytorefresh(new Date().getTime())
            
        }
        let cognome=riga[0]
        let nome=riga[1]
        let datetemp=new Date(Date.UTC(0, 0, riga[2] - 1));
        let datanascita=("0" + datetemp.getDate()).slice(-2) + '/' + (("0" + (datetemp.getMonth() + 1)).slice(-2))+'/'+datetemp.getFullYear()
        let numero=riga[6]
        datetemp=new Date(Date.UTC(0, 0, riga[7] - 1));
        let validoda=("0" + datetemp.getDate()).slice(-2) + '/' + (("0" + (datetemp.getMonth() + 1)).slice(-2))+'/'+datetemp.getFullYear()
        datetemp=new Date(Date.UTC(0, 0, riga[8] - 1));
        let validoa=("0" + datetemp.getDate()).slice(-2) + '/' + (("0" + (datetemp.getMonth() + 1)).slice(-2))+'/'+datetemp.getFullYear()
        
        let rigatemp=checktessera(cognome?cognome:'', 
            nome?nome:'', 
            datanascita?datanascita:'', 
            numero?numero:'', 
            validoda?validoda:'', 
            validoa?validoa:'');
            console.log("rigatemp: "+rigatemp)
        return rigatemp
    }
    
    function invia(){
        let rowstemp=rows;
        rowstemp=rowstemp.filter((row,index) => index>=numriga);
        //console.log(rowstemp)
        righepostcheck=[]

        for (let i=0; i<rowstemp.length; i++){
            (checkriga(rowstemp[i]));
        } 
         setrighe(righepostcheck)
         setkeytorefresh(new Date().getTime())
    }

    function showrighe(){
        console.log(righe)
        return righe.map((riga, index) =>
        <tr style={{backgroundColor:riga[0].indexOf("OK")===0?"green":"orange"}}>
           <td>{index}</td>
           <td>{riga[0]}</td>
           <td>{riga[1]}</td>
           <td>{riga[2]}</td>
           <td>{riga[3]}</td>
           <td>{riga[4]}</td>
           <td>{riga[5]}</td>
           <td>{riga[6]}</td>
        </tr>)
    }

    function uploaddati(){
        async function salva(cognome, nome, datanascita, numero, validoda, validoa){
            const result=await salvadatitessera(cognome, nome, datanascita, numero, validoda, validoa, palestra.palestra.id);
            console.log("result "+result)
            const rigachecked=[result, cognome, nome, datanascita, numero, validoda, validoa]
            righepostcheck.push(rigachecked)
            setkeytorefresh(new Date().getTime())
        }
        righepostcheck=[]
        for (let i=0; i<righe.length; i++){
            salva(righe[i][1], righe[i][2], righe[i][3], righe[i][4], righe[i][5], righe[i][6]);
        }
        setrighe(righepostcheck)
         setkeytorefresh(new Date().getTime())
    }

    function cerca(){
        async function cercatess(){
            const result = await cercatessera(cercanome, cercacognome, cercanumero, cercavalidoda, cercavalidoda, palestra.palestra.id)
            settesseratitrovati(result)
        }
        cercatess()
    }

    return (
        <div key={keytorefresh}>
            <div>
                <h3>Upload tesseramenti</h3>
             <input type="file" onChange={(event) => fileHandler(event)} style={{"padding":"10px"}} />
            {(!righe||righe.length===0)&& <OutTable data={rows} columns={cols} tableClassName="ExcelTable2007" tableHeaderRowClass="heading" /> }
            prendi i dati dalla riga numero <input type="number" name="numeroriga" value={numriga} onChange={(e) =>setnumriga(e.target.value)} />
            <button onClick={invia}>Invia</button>
            Controllate {righe.length} righe

            {righe&&righe.length>0&&
            <div>
            <table>
                <thead>
                    <th>num. riga</th>
                    <th>Risultato check</th>
                    <th>Cognome</th>
                    <th>Nome</th>
                    <th>Data nascita</th>
                    <th>Numero tessera</th>
                    <th>Valido Da</th>
                    <th>Valido A</th>
                </thead>
                <tbody>
            {showrighe()}
            </tbody>
            </table>
            <button onClick={uploaddati}>Salva i dati</button>
            </div>
            }
            </div>
            <hr style={{margin:30}} />
            <div>
                <h3>Ricerca tesseramenti</h3>
            </div>
            <div>
                <input type="text" name="nome" value={cercanome} placeholder="Nome" onChange={(e) => setcercanome(e.target.value) } />
                <input type="text" name="nome" value={cercacognome} placeholder="Cognome" onChange={(e) => setcercacognome(e.target.value) } />
                <input type="text" name="nome" value={cercanumero} placeholder="Numero" onChange={(e) => setcercanumero(e.target.value) } />
                <input type="date" name="nome" value={cercavalidoda} placeholder="Valido da" onChange={(e) => setcercavalidoda(e.target.value) } />
                <input type="date" name="nome" value={cercavalidoa} placeholder="Valido a" onChange={(e) => setcercavalidoa(e.target.value) } />
                <button onClick={cerca}>Cerca</button>
            </div>
            <div>
            <table className="table">
                <thead>
                <th>Nome</th>
                <th>Cognome</th>
                <th>Data nascita</th>
                    <th>Numero</th>
                    <th>Valida dal</th>
                    <th>Valida al</th>
                    <th>Salva</th>
                    <th>Elimina</th>
                </thead>
                <tbody>
                {tesseratitrovati.map(tesserato=> 
                    <RigaTessereAnagrafica tessera={tesserato} />
                    )}
                </tbody>
                </table>
            </div>
        </div>
    );
}