import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import NuovoScadenzaRataInput from "./NuovoScadenzaRataInput";
import ScadenzaRataInput from "./ScadenzaRataInput";
import { connect } from "react-redux";

class ListaScadenzaRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    		listaScadenze:[]
    };
    this.refresh = this.refresh.bind(this);
  }

	componentDidMount(){
		  this.setState({listaScadenze: this.props.listaScadenze});
		  console.log(this.props.listaScadenze)
  }

  refresh(){
	const formData = new FormData();
	formData.append('website', global.config.domain);
	formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  formData.append('idPeriodo',this.props.periodo);
	  axios.post(global.config.server+"/visualizzaScadenza",formData)
	  .then(response=>{
		  this.setState({listaScadenze:[]});
		  this.setState({listaScadenze: response.data});
		  console.log(response);
		  
	  });
  }
  
  componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
	    console.log(error, info);
	  }

  render() {
    return (
    		<div>
			{
			   this.props.listaScadenze?
    			 this.props.listaScadenze.map(item =>(
    					<ScadenzaRataInput key={item.id} selected={item} 
						periodo={this.props.periodo} 
						refresh={this.refresh} 
						email={this.props.email} />
    					)
				 )
				:null
    		}
    		<NuovoScadenzaRataInput periodo={this.props.periodo} 
			refresh={this.refresh}
			email={this.props.email} />
    		</div>
    );
  }
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(ListaScadenzaRate)