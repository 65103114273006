import React from "react";
import axios from 'axios';
import NuovaRiga from './NuovaRiga'
import Swal from "sweetalert2";

export default class NuovaFattura extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: 0,
	    		anno: new Date().getFullYear(),
	    		data: new Date().getDate() +"/"+(new Date().getMonth()+1)+'/'+new Date().getFullYear(),
	    		importo:0,
	    		numero:null,
				visible: false,
				righe:[],
	    		messaggio:null,
				reversecharge:false
	    	}
		this.update = this.update.bind(this);
		this.updateRiga = this.updateRiga.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
		this.aggiungiRiga=this.aggiungiRiga.bind(this);
		this.eliminaRiga=this.eliminaRiga.bind(this);
		this.calcolaTotaleLordo=this.calcolaTotaleLordo.bind(this);
	  }
	  
	  update(e) {
		if (e.target.name==='reversecharge'){
			if (e.target.checked){
				this.setState({reversecharge:1})
				this.setState({iva:0})
			}
			else{
				this.setState({reversecharge:0})
			}
		}
		else{
		  this.setState({ [e.target.name]: e.target.value, visible: true });
		}
		  this.calcolaTotaleLordo();
	  }

	  updateRiga(e,idriga) {
		var righetemp=this.state.righe;
		const index=righetemp.findIndex(rigat => rigat.idrigafattura === idriga);
		righetemp[index][e.target.name]=e.target.value
		this.setState({ righetemp });
		this.calcolaTotaleLordo();
	}

	calcolaTotaleLordo() {
		var tot=0;
		this.state.righe.map(riga=>(
			tot=tot+(parseFloat(riga.quantita)*parseFloat(riga.costo))
		))
		var ivatot=tot*parseFloat(this.state.iva)/100;
		tot=tot+ivatot;
		this.setState({importoLordoTot:tot})
		
	}
	  
	handleSubmit(event) {
		event.preventDefault();
		const { id,anno,data, numero, iva, idabbonamento,righe, intestazione, reversecharge } = this.state;

		if (this.props.palestraassociata){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
	    formData.append('id', id);
	    formData.append('anno', anno);
		formData.append('data', data);
		formData.append('numero', numero);
		formData.append('iva', iva);
		formData.append('idabbonamento', this.props.idabbonamento);
		formData.append('intestazione', intestazione);
		formData.append('righe', JSON.stringify(righe));
		formData.append('palestra',this.props.palestraassociata.palestra.id);
		formData.append('reversecharge', reversecharge&&reversecharge===1?1:0);

		axios.post(global.config.server+'/aggiornaFatturaRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.setState({ visible: false });
	      });
		}
	  }

	componentDidMount(){
		if (this.props.palestraassociata){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestraassociata.palestra.id);
		axios.post(global.config.server+"/getNumeroFattura",formData)
		.then(response=>{
			this.setState({numero: this.state.anno+""+('0' + response.data).slice(-2)});
			//console.log(response);
		});
	}
	}

	aggiungiRiga(event){
		var righetemp=this.state.righe;
		var nuovariga={idrigafattura:'_' + Math.random().toString(36).substr(2, 9),quantita:0, costo:0, descrizione: this.props.descrizioneAbbonamento}
		righetemp.push(nuovariga);
		this.setState({ righetemp });
		this.calcolaTotaleLordo();
	}
	 
	eliminaRiga(event,idriga){
		var righetemp=this.state.righe;
		const index=righetemp.findIndex(rigat => rigat.idrigafattura === idriga);
		righetemp.splice(index,1)
		this.setState({ righetemp });
		this.calcolaTotaleLordo();
	}

	render() {
		var style1={
				color:"white"
		}

		var style3={
			backgroundColor: "#D0D0D0"
		}
		return (
			<div id="nuovaFattura">
					<div >
					<div >
						<div className="modal-header">
						<h4 className="modal-title">Nuova Fattura</h4>
						</div>
						<div className="modal-body">
							<div className="col-sm-12">
								<span>{this.state.messaggio}</span>
								<div className="row">
									<label>Intestazione</label>
										{this.props.possibiliIntestatari&&this.props.possibiliIntestatari.anag?
											<select name="intestazione" onChange={this.update}>
											<option value={"anag"+this.props.possibiliIntestatari.anag.id}>{this.props.possibiliIntestatari.anag.nome+" "+this.props.possibiliIntestatari.anag.cognome}</option>
											{
												this.props.possibiliIntestatari.societa?
												this.props.possibiliIntestatari.societa.map(dettsocieta=>(
													<option value={"societa"+dettsocieta.id}>{dettsocieta.palestra}</option>
												))
												:null
											}
										</select>
										:null
										}
								</div>
								<div className="row">
									<div className="col">
										<label className="control-label">Anno</label>
										<input className="form-control" type="text" maxLength="5" readOnly={true} value={this.state.anno} />
									</div>
									<div className="col">
										<label className="control-label">Data</label>
										<input className="form-control" type="date" name="data" maxLength="50"  value={this.state.data} onChange={this.update} />
									</div>
									<div className="col">
										<label className="control-label">Numero</label>
										<input className="form-control" type="text" name="numero" onChange={this.update} value={this.state.numero} />
									</div>
									<div className="w-100"/>
									<div className="col">
										<label className="control-label">Reverse Charge</label>
										<input className="form-control" type="checkbox" name="reversecharge" onChange={this.update} value={1} checked={this.state.reversecharge} />
									</div>
									<div className="col">
										<label className="control-label">IVA %</label>
										<input className="form-control" type="text" name="iva" onChange={this.update} value={this.state.iva} />
									</div>
									<div className="col">
										<label className="control-label">Importo Totale Lordo</label>
										{this.state.importoLordoTot}
									</div>
									<div className="w-100"/>
									{
										this.state.righe?
										this.state.righe.map((riga,index)=>(
											<NuovaRiga key={index} riga={riga} idabbonamento={this.props.idabbonamento} updateRiga={this.updateRiga} eliminaRiga={this.eliminaRiga}/>
										))
										:null
									}
									<button className="btn btn-default" onClick={e=>this.aggiungiRiga(e)}>Nuova riga</button>
									<div className="col">
									<a type="button" className="btn btn-primary" onClick={e=>this.handleSubmit(e)} style={style1}>Salva modifiche</a>
									</div>
								</div>
							</div>
						</div>
						<div className="modal-footer">
						<button className="btn btn-default" onClick={this.props.showHide}>Close</button>
						</div>
					</div>
					
					</div>
				</div>
	);
	}
}