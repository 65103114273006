import React from "react";
import axios from 'axios';
import ModificaFattura from './ModificaFattura'
import NuovoPagamento from '../pagamenti/NuovoPagamento'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave, faEnvelopeOpen, faEdit, faPrint, faEuroSign, faTrashAlt} from '@fortawesome/free-solid-svg-icons'
import PagamentiSelect from '../pagamenti/PagamentiSelect'
import Swal from "sweetalert2";
import serverInfo from "../util/globals";
import { connect } from "react-redux";

class FatturaEdit extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		anno: this.props.selected.anno,
	    		data: this.props.selected.data,
	    		importo:this.props.selected.importolordototale,
	    		numero:this.props.selected.numero,
				email:this.props.selected.email,
				iva:this.props.selected.iva,
				idutentedestinatario: this.props.selected.idutentedestinatario,
            	idpalestradestinaria: this.props.selected.idpalestradestinataria,
				visible: false,
				idpalestra:this.props.selected.idpalestra,
				pagamenti:[],
	    		messaggio:null,
				showModalPagamenti:false,
				reversecharge:this.props.selected.reversecharge
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.sendEmailFattura = this.sendEmailFattura.bind(this);
		this.modificaFattura=this.modificaFattura.bind(this);
		this.nuovoPagamento=this.nuovoPagamento.bind(this);
		this.stampaFattura=this.stampaFattura.bind(this);
	  }

	  componentDidMount(){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
			axios.post(global.config.server+"/getPagamentiFatt/"+this.props.selected.id,formData)
			.then(response=>{
				this.setState({pagamenti: response.data});
				//console.log(response);
			});
	}

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	
	handleSubmit(event) {
		event.preventDefault();
	    const { id,idpagamento } = this.state;

	    const formData = new FormData();
	    formData.append('idpagamento', idpagamento);
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
	      axios.post(global.config.server+'/aggiornaPagamentoFatturaRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.setState({ visible: false });
	      });
	  }
	
	stampaFattura(event){
		
			  var win = window.open(serverInfo.name+'/stampaFattura?id='+this.state.id, '_blank');
			  win.focus();
	}
	
	modificaFattura(event){
		
	}


	sendEmailFattura(event) {
		event.preventDefault();
	    const { id } = this.state;

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
	    axios.post(global.config.server+'/inviaEmailPdfFatturaRest',formData)
	      .then((result) => {
	    	  console.log(result)
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: result.data.message?'success':'error',
					  title: result.data.message?result.data.message:result.data.errore
					})
		          this.setState({ visible: false });
	      }).
	      catch((err)=>{
			alert("Fattura: "+err.response.data.message);
		    this.setState({ visible: false });
	      });
	  }

	  nuovoPagamento() {
		this.setState({showModalPagamenti:!this.state.showModalPagamenti})
	  }

	  elimina() {
		if (window.confirm('Sei sicuro di voler cancellare la fattura selezionata?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
					axios.post(global.config.server+'/eliminaFatturaRest/'+this.state.id,formData)
				  .then((result) => {
					   const Toast = Swal.mixin({
						toast: true,
						position: 'top-end',
						showConfirmButton: false,
						timer: 3000
					  });
					  Toast.fire({
						type: 'success',
						title: result.data
					  })
					this.setState({ visible: false });
				  });
		  }
	}

	render() {
		var style1={
				color:"white"
		}

	    return (
			<span>
	    		<div className="row">
					<ModificaFattura possibiliIntestatari={this.props.possibiliIntestatari} fattura={this.state} email={this.props.email}/>
					{this.state.showModalPagamenti&&this.state.showModalPagamenti===true?
					<NuovoPagamento id={this.state.id} fattura={this.state} idabbonamento={this.props.idabbonamento}
									idprenotazione={this.props.idprenotazione} importo={this.state.importo}
									/>
					:null}
							<div className="col">
								<span><nobr><b>Data</b> {this.state.data}</nobr></span>
							</div>
							<div className="col-xs col-sm w-100"></div>
							<div className="col">
							<span><nobr><b>Anno</b> {this.state.anno}</nobr></span>
							</div>
							
							<div className="col">
							<span><nobr><b>Numero</b> {this.state.numero}</nobr></span>
							</div>
							<div className="col-xs col-sm w-100"></div>
							<div className="col">
								<label className="control-label">Lordo</label>
								<br />{this.state.importo}
							</div>
							<div className="col">
								<label className="control-label">Iva</label>
								<br />{(this.state.importo-this.props.selected.importonettototale).toFixed(2)}
							</div>
							<div className="col">
								<label className="control-label">Netto</label>
								<br />{this.props.selected.importonettototale}
							</div>
							<div className="col">
								{this.state.pagamenti&&this.state.pagamenti.length>0?
								<span>Pagamenti
								<ul>
									{
										this.state.pagamenti.map(pagamento=>(
										<li>{pagamento.dataPagamento}, {pagamento.importo} - {pagamento.tipo}</li>
										))
									}
								</ul>
								</span>
								:"nessun pagamento per questa fattura"}
								<label>Associa pagamento esistente</label>
								<PagamentiSelect idabbonamento={this.props.idabbonamento} update={this.update} email={this.props.email} palestra={this.props.selected.idpalestra} />
							</div>
							<div className="w-100"></div>
							{this.state.visible?
									<div className="col">
					  				<a type="button" title="Salva" className="btn btn-success" onClick={this.handleSubmit} style={style1}>
										  <FontAwesomeIcon icon={faSave} />
									</a>
					  				</div>
					  			:null}
									<div className="col">
					  				<a type="button" title="Invia per email" className="btn btn-primary" onClick={this.sendEmailFattura} style={style1}>
										<FontAwesomeIcon icon={faEnvelopeOpen} />
									</a>
					  				</div>
							<div className="col">
								<a className="btn btn-warning" title="Modifica" style={{color:"white"}} data-toggle="modal" data-target={"#modFattura"+this.state.id} onClick={this.modificaFattura}>
								<FontAwesomeIcon icon={faEdit} />
								</a>
							</div>
							<div className="col">
								<a className="btn btn-warning" title="Stampa" style={{color:"white"}} onClick={this.stampaFattura}>
								<FontAwesomeIcon icon={faPrint} />
								</a>
							</div>
							<div className="col">
								<a className="btn btn-info" title="Nuovo pagamento" style={{color:"white"}} data-toggle="modal" data-target={"#nuovoPagamento"+this.state.id} onClick={this.nuovoPagamento}>
								<FontAwesomeIcon icon={faEuroSign} />
								</a>
							</div>
							<div className="col">
								<a className="btn btn-danger" title="Elimina" style={{color:"white"}} onClick={this.elimina}>
									<FontAwesomeIcon icon={faTrashAlt} />
								</a>
							</div>
							
					</div>
					<div className="row">
						<hr style={{width: '100%',  borderTop: '1px dashed red'}} />
					</div>
				</span>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
		  palestra: state.gestoreUtente.palestraassociata.palestra,
		  email: state.gestoreUtente.emailloggato,
		  idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	  }
	  
	  export default connect(mapStateToProps, null)(FatturaEdit)