import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function SelectTipologiaCorso(props){

	const tipicorso = useSelector(state => state.gestoreTipiProdotto.tipi);

    const[show, setshow] = useState(false);
    const [selected, setselected] = useState(props.selected?props.selected.id:"");

	function aggiornaSelezionato(e){
		setselected(e.target.value)
		 props.updateTipo(tipicorso[e.target.selectedIndex-1]);
	 }

	    return (
	    	<div className="col">
			<select className="form-control" onChange={aggiornaSelezionato} name="tipoCorso" value={selected}>
				<option key={-1} value="">--seleziona tipo--</option>
				{
					tipicorso.map((item,index) =>(
					<option key={index} value={item.id} >{item.nome}</option>
					)
	    			)
	    		}
			</select>
			</div>
	    );
	}