import React from "react";

export default function ToolbarSchede(props){
    return(
            <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-info">
                <a className="navbar-brand" href="#appadminannotatio" onClick={()=> props.setview('annotatiohome')}>
                    <img src="https://annotatio.online/wp-content/uploads/2022/09/annotatioA512__1_-removebg-preview-1.png" width="30" height="30" alt="Annotatio" />nnotatio
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='calendari'?"active":"")} href="#appadminannotatio" onClick={()=> props.setview('calendari')}>Calendari</a>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#appadminannotatio" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Schede
                        </a>
                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a className={"nav-link "+(props.viewselected==='schedeSA'?"active":"")} href="#appadminannotatio" onClick={()=> props.setview('schedeSA')}>Sala Attrezzi</a>
                        <a className={"nav-link "+(props.viewselected==='schedeCC'?"active":"")} href="#appadminannotatio" onClick={()=> props.setview('schedeCC')}>Composizione Corporea e Diario Alimentare</a>
                        </div>
                    </li>
                    </ul>
                </div>
            </nav>
    );

}