import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import TipologieEserciziSelect from "./TipologieEserciziSelect";
import {connect} from 'react-redux'

class EsercizioScheda extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            idschedaesercizi: this.props.esercizio.idschedaesercizi,
            numeroGiorno:this.props.esercizio.numeroGiorno,
            ordine:this.props.esercizio.ordine,
            serie:this.props.esercizio.serie,
            
            recupero:this.props.esercizio.recupero,
            
            
            note:this.props.esercizio.note,
            tipo:this.props.esercizio.tipo,
            tut:this.props.esercizio.tut,
            ripetizioni:this.props.esercizio.ripetizioni,
            peso:this.props.esercizio.peso,
            idesercizio:this.props.esercizio.idEsercizio,

            ripetizioni2:this.props.esercizio.ripetizioni2,
            peso2:this.props.esercizio.peso2,
            esercizio2:this.props.esercizio.esercizio2,
            ripetizioni3:this.props.esercizio.ripetizioni3,
            peso3:this.props.esercizio.peso3,
            esercizio3:this.props.esercizio.esercizio3,
           
            link:this.props.esercizio.link,
            link2:this.props.esercizio.link2,
            categoria:this.props.esercizio.categoria,
            tipologieEsercizi:this.props.tipologieEsercizi,
            visible:false
	    	}
        this.update = this.update.bind(this);
        this.aggiorna = this.aggiorna.bind(this);
      }
      
      shouldComponentUpdate(nextProps, nextState){
          console.log(nextProps.esercizio.idschedaesercizi+" - "+this.state.idschedaesercizi+": "+(nextProps.esercizio.idschedaesercizi!==this.state.idschedaesercizi))
        const aggiorna=(nextProps.esercizio.idschedaesercizi!==this.state.idschedaesercizi) || (this.state!=nextState)
        if (aggiorna){
            this.state = {
                idschedaesercizi: this.props.esercizio.idschedaesercizi,
                numeroGiorno:this.props.esercizio.numeroGiorno,
                ordine:this.props.esercizio.ordine,
                serie:this.props.esercizio.serie,
                ripetizioni:this.props.esercizio.ripetizioni,
                recupero:this.props.esercizio.recupero,
                tut:this.props.esercizio.tut?this.props.esercizio.tut:'',
                peso:this.props.esercizio.peso,
                note:this.props.esercizio.note,
                tipo:this.props.esercizio.tipo,
                idesercizio:this.props.esercizio.idEsercizio,
                link:this.props.esercizio.link,
                link2:this.props.esercizio.link2,

                ripetizioni2:this.props.esercizio.ripetizioni2,
                peso2:this.props.esercizio.peso2,
                esercizio2:this.props.esercizio.esercizio2,
                ripetizioni3:this.props.esercizio.ripetizioni3,
                peso3:this.props.esercizio.peso3,
                esercizio3:this.props.esercizio.esercizio3,

                categoria:this.props.esercizio.categoria,
                tipologieEsercizi:this.props.tipologieEsercizi,
                visible:false
                }
        }
        console.log("Esercizio scheda shouldComponentUpdate: "+aggiorna)
          return aggiorna
      }

       static getDerivedStateFromProps(props, state) {
          if (props.esercizio.idschedaesercizi!=state.idschedaesercizi){
          return{
            idschedaesercizi: props.esercizio.idschedaesercizi,
            numeroGiorno:props.esercizio.numeroGiorno,
            ordine:props.esercizio.ordine,
            serie:props.esercizio.serie,
            ripetizioni:props.esercizio.ripetizioni,
            recupero:props.esercizio.recupero,
            tut:props.esercizio.tut?props.esercizio.tut:'',
            peso:props.esercizio.peso,
            note:props.esercizio.note,
            tipo:props.esercizio.tipo,
            idesercizio:props.esercizio.idEsercizio,

            ripetizioni2:props.esercizio.ripetizioni2,
            peso2:props.esercizio.peso2,
            esercizio2:props.esercizio.esercizio2,
            ripetizioni3:props.esercizio.ripetizioni3,
            peso3:props.esercizio.peso3,
            esercizio3:props.esercizio.esercizio3,
            ripetizioni4:props.esercizio.ripetizioni4,

            link:props.esercizio.link,
            link2:props.esercizio.link2,
            categoria:props.esercizio.categoria,
            tipologieEsercizi:props.tipologieEsercizi
          }
        }
        return null;
    }

    componentDidMount(){
        console.log("EsercizioScheda.componentDidMount")
    }

	update(name, value) {
          this.setState({ [name]: value, visible:true });
    }

    aggiorna(e){
        e.preventDefault()
		const { idschedaesercizi,peso,recupero, ripetizioni, serie, tut, ordine, numeroGiorno, note, idesercizio } = this.state;

	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','0001');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('id', idschedaesercizi);
	    formData.append('peso', peso?peso:'');
		formData.append('recupero', recupero?recupero:0);
		formData.append('ripetizioni', ripetizioni?ripetizioni:'');
		formData.append('serie', serie?serie:'');
        formData.append('tut', tut?tut:'');
        formData.append('ordine', ordine?ordine:1);
        formData.append('numeroGiorno', numeroGiorno?numeroGiorno:'');
        formData.append('note', note?note:'');
        formData.append('idesercizio', idesercizio);

        formData.append('peso2', this.state.peso2?this.state.peso2:'');
		formData.append('ripetizioni2', this.state.ripetizioni2?this.state.ripetizioni2:0);
		formData.append('esercizio2', this.state.esercizio2?this.state.esercizio2:'');
        formData.append('peso3', this.state.peso3?this.state.peso3:'');
		formData.append('ripetizioni3', this.state.ripetizioni3?this.state.ripetizioni3:0);
		formData.append('esercizio3', this.state.esercizio3?this.state.esercizio3:'');
        

	      axios.post(global.config.server+'/aggiornaEsercizioScheda/'+idschedaesercizi,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
                    })
                    this.setState({ visible: false });
		          //this.props.refresh();
		          
	      });
    }

	render() {
	    return (
            <>
                        <tr>
                            <td>
                            <div class="form-group">
                        <small><label >Giorno</label></small>
                        <input className="form-control" placeholder="giorno" required={true} style={{padding:1}} type="text" name="numeroGiorno" value={this.state.numeroGiorno} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        
                        </div></td>
                        <td>
                        <div class="form-group">
                        <small><label >Ordine</label></small>
                        <input className="form-control" placeholder="ordine" required={true} style={{padding:1}} type="text" name="ordine" value={this.state.ordine} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                       </div>
                        </td>
                        <td>
                        <div class="form-group">
                        <small><label >Serie</label></small>
                        <input className="form-control" placeholder="serie" required={true} style={{padding:1}} type="text" name="serie" value={this.state.serie} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        
                        </div></td>
                        <td>
                        <div class="form-group">
                        <small><label >Recupero</label></small>
                        <input className="form-control" placeholder="recupero" required={true} style={{padding:1}} type="text" name="recupero" value={this.state.recupero} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        <td>
                        <div class="form-group">
                        <small><label >Tut</label></small>
                        <input className="form-control" placeholder="tut" style={{padding:1}} ype="text" name="tut" value={this.state.tut} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        <td>
                        <div class="form-group">
                        <small><label >Tipo eser. 1</label></small>
                            <TipologieEserciziSelect tipologie={this.state.tipologieEsercizi} idschedaesercizi={this.state.idschedaesercizi} name="idesercizio" selected={this.state.idesercizio} update={(e) => this.update(e.target.name, e.target.value)}/>
                        </div>
                        </td>
                        <td style={{backgroundColor:'#d2d5f7'}}>
                        <div class="form-group">
                        <small><label >Ripetiz.</label></small>
                        <input className="form-control" placeholder="ripetizioni 1" required={true} style={{padding:1}} type="text" name="ripetizioni" value={this.state.ripetizioni} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        <td style={{backgroundColor:'#d2d5f7'}}>
                        <div class="form-group">
                        <small><label >Peso</label></small>
                        <input className="form-control" placeholder="peso 1" style={{padding:1}} type="text" name="peso" value={this.state.peso} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        <td>
                        <div class="form-group">
                        <small><label >Tipo eser. 2</label></small>
                            <TipologieEserciziSelect tipologie={this.props.tipologieEsercizi} idschedaesercizi={this.state.esercizio2} selected={this.state.esercizio2} name="esercizio2" update={this.update}/>
                        </div>
                        </td>
                        <td style={{backgroundColor:'#afb5fa'}}>
                        <div class="form-group">
                        <small><label >Ripetiz.</label></small>
                        <input className="form-control" placeholder="ripetizioni 2" required={true} style={{padding:1}} type="text" name="ripetizioni2" value={this.state.ripetizioni2} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        <td style={{backgroundColor:'#afb5fa'}}>
                        <div class="form-group">
                        <small><label >Peso</label></small>
                        <input className="form-control" placeholder="peso 2" style={{padding:1}} type="text" name="peso2" value={this.state.peso2} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                       </div>
                        </td>
                       
                       <td>
                       <div class="form-group">
                        <small><label >Tipo eser. 3</label></small>
                            <TipologieEserciziSelect tipologie={this.props.tipologieEsercizi} idschedaesercizi={this.state.esercizio3} selected={this.state.esercizio3} name="esercizio3" update={this.update}/>
                        </div>
                        </td>
                        <td style={{backgroundColor:'#7c86f7'}}>
                        <div class="form-group">
                        <small><label >Ripetiz.</label></small>
                        <input className="form-control" placeholder="ripetizioni 3" required={true} style={{padding:1}} type="text" name="ripetizioni3" value={this.state.ripetizioni3} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        <td  style={{backgroundColor:'#7c86f7'}}>
                        <div class="form-group">
                        <small><label >Peso</label></small>
                        <input className="form-control" placeholder="peso 3" style={{padding:1}} type="text" name="peso3" value={this.state.peso3} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                        
                        <td>
                    {this.state.visible?
                    <div className="col">
                        <button className="btn btn-danger" name={this.state.idschedaesercizi} onClick={this.aggiorna}>Salva</button>
                    </div>
                    :null}
                    </td>
                        <td>
                        <button className="btn btn-danger" name={this.state.idschedaesercizi} onClick={this.props.eliminaEsercizio}>Elimina</button>
                        </td>
                </tr>
                <tr>
                     <td colSpan={15}>
                     <div class="form-group">
                        <small><label >Note</label></small>
                        <textarea className="form-control" type="text" name="note" value={this.state.note} onChange= {(e) => this.update(e.target.name, e.target.value)} />
                        </div>
                        </td>
                </tr>
   </>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(EsercizioScheda)