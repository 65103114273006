import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { aggiornaCorsoCateg, aggiornaCorsoMaxIngredienti, caricaIngredienti } from "../../DAO/corsiDAO";
import CercaIngredienti from "./CercaIngredienti";
import DettIngrediente from "./DettIngrediente";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfigIngrediente from "./ConfigIngrediente";
import InputText from "../../util/UI/InputText";

export default function Ingredienti({prodottopadre}){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());

    const [ingredienti, setingredienti] = useState([]);
    const [categorie, setcategorie] = useState([]);
    const [expanded, setExpanded] = useState(false);

    const [maxtotingr,setmaxtotingr] = useState(prodottopadre&&prodottopadre.maxingredienti?prodottopadre.maxingredienti:1)
    
    const handleChange =
    (idcorso, panel, isExpanded,e) => {
        console.log(panel)
        if (panel.target.toString().indexOf("SVG")>=0){
            setExpanded(isExpanded ? idcorso : false);
        }
        //alert(panel.target.toString())
	  
    };

    function setCategorie(ingredientis){
        let categorietemp=new Set();
        ingredientis.map(ingr=> categorietemp.add(ingr.categoria));
        setcategorie(Array.from(categorietemp));
      }

    function caricaListaIngredienti(){
        async function caricaIngrs(){
            const result=await caricaIngredienti(emailloggato, palestra.palestra.id, prodottopadre.id);
            //console.log("carica ingredienti ",result)
            setingredienti(result);
            setCategorie(result);
        }
        caricaIngrs();
    }

    function setmaxingr(maxing){
        async function aggiornacorso(){
            if (!maxing||maxing<0){
                maxing=0
            }
            const result=await aggiornaCorsoMaxIngredienti(prodottopadre.id, maxing);
        }
        aggiornacorso();
    }

    useEffect(() => {
        caricaListaIngredienti();
    },[prodottopadre.id, keytorefresh])

    return (
        <div key={keytorefresh}>
            <h5>Eventuale lista ingredienti selezionabili dall'utente (max <input type={"number"} width={"10%"} style={{width:'10%', textAlign:"right"}} name={maxtotingr} value={maxtotingr} onChange={(e) => {setmaxtotingr(e.target.value);setmaxingr(e.target.value) }} />)</h5>
            <div>
                {
                        categorie && categorie.map(categ => (
                        <Accordion key={categ} expanded={expanded === categ} onChange={(event,isExpanded) => handleChange(categ,event, isExpanded)}>
                            <AccordionSummary
                            
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={categ+"bh-content"}
                                id={categ+"bh-header"}>
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {categ} 
                                    </Typography>
                                    <Typography>
                                    <ConfigIngrediente categ={prodottopadre.configCategorie?
                                        JSON.parse(prodottopadre.configCategorie).filter(categtemp => categtemp.categoria===categ)[0]?
                                        JSON.parse(prodottopadre.configCategorie).filter(categtemp => categtemp.categoria===categ)[0]:
                                        {categoria:categ}
                                        :{categoria:categ}} prodottopadre={prodottopadre} />
                                    </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Prezzo</th>
                                        <th>Nun Min selezionabile</th>
                                        <th>Nun Max selezionabile</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                            {ingredienti.map(ingrediente => (
                            ingrediente.categoria===categ && 
                            
                                    <DettIngrediente key={ingrediente.id} ingrediente={ingrediente} setkeytorefresh={setkeytorefresh} />
                                
                            ))}
                            </tbody>
                            </table>
                        </AccordionDetails>
                        </Accordion>
                    ))
                }
            </div>
                <CercaIngredienti ingredientidaescludere={ingredienti} corso={prodottopadre} refresh={setkeytorefresh} />
        </div>
    );
}