import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import gettoken from "../DAO/tokenDAO";
import { setpalestraassociata, settoken } from "../STORE/reducers/gestoreUtente";
import '../util/config'
import { setPalestra } from "../DAO/palestraDAO";

export default function PalestraAssociataSelect(props) {
  const [palselected, setpalselected] = useState(global.localstorage.palestrasocioSelezionata)
  
  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const palestrasocio = useSelector(state => state.gestoreUtente.palestraassociata);
  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

  const dispatch=useDispatch();

  useEffect(() => {
    
  },[palestrasocio, keytorefresh])

    function eseguiclick() {
     
        localStorage.setItem('palselezionata', JSON.stringify(props.soc.palestra))
        localStorage.setItem('palestrasocioSelezionata', JSON.stringify(props.soc))
        async function setpalestra(){
          const result=await setPalestra(props.soc)
        }
        setpalestra()
        dispatch(setpalestraassociata(props.soc))
        setpalselected(props.soc);
        props.setkeytorefresh(new Date().getTime())

        async function gett(){
          const result = await gettoken(emailloggato, props.soc.palestra.id, props.soc.palestra.website+props.soc.palestra.subdomain);
          console.log(result)
          window.$t = result
          dispatch(settoken(result));
        }
        gett();
      
        if (props.link){
          window.location.href=props.link;
        }
      }

      return (
        props.soc&&
          <div >
            <button style={{color:'white'}} className="btn btn-link" onClick={eseguiclick}>
            {props.soc.palestra.palestra} ({props.soc.amministratore?"Amministratore":"Collaboratore"})
            
            {palestrasocio&&palestrasocio.palestra&&palestrasocio.palestra.id===props.soc.palestra.id&&
              <img alt="selected" src="https://annotatio.online/wp-content/uploads/2022/07/checkedGreen.png" style={{width:"20px"}} />
            }
            </button>
          </div>
        );
  }