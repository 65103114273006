import React, { useState } from "react";
import { useSelector } from "react-redux";
import { eliminaIngrediente, salvaDatiIngrediente } from "../../DAO/corsiDAO";


export default function DettIngrediente(props){
    var timeout;
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

    const [prezzo, setprezzo] = useState(props.ingrediente.prezzo);
    const [maxvolte, setmaxvolte] = useState(props.ingrediente.maxvolte);
    const [minvolte, setminvolte] = useState(props.ingrediente.minvolte);
    

    function salvaDettIngrediente(id, prezzo, minvolte, maxvolte, idcorso, idcorsopadre){
        async function salvaIngr(){
            const result = await salvaDatiIngrediente(id, prezzo, minvolte, maxvolte, idcorso, idcorsopadre, props.categobbligatoria, props.categmaxuno, palestra.palestra.id);
            //console.log(result);
            //props.setkeytorefresh(new Date().getTime())
        }
        timeout = setTimeout(() =>salvaIngr(), 1000);
    }

    function elimina(idingrediente){
        async function eliminaIngrs(){
            const result=await eliminaIngrediente(emailloggato, palestra.palestra.id, idingrediente);
            alert(result)
            props.setkeytorefresh(new Date().getTime())
        }
        eliminaIngrs();
    }

    return (
        <tr>
            <td>
            {props.ingrediente.nome}
            </td>
            <td>
            <input style={{textAlign:'right'}} type="number" step=".1" name="prezzo" value={prezzo} onChange={(e) => {setprezzo(e.target.value); clearTimeout(timeout); salvaDettIngrediente(props.ingrediente.id, e.target.value, minvolte, maxvolte, props.ingrediente.idcorso, props.ingrediente.idcorsopadre)}} />
            </td>
            <td>
            <input style={{textAlign:'right'}} type="number" step=".5" name="minvolte" value={minvolte} onChange={(e) => {setminvolte(e.target.value); clearTimeout(timeout); salvaDettIngrediente(props.ingrediente.id, prezzo, e.target.value, maxvolte, props.ingrediente.idcorso, props.ingrediente.idcorsopadre)}} />
           </td>
            <td>
            <input style={{textAlign:'right'}} type="number" step=".5" name="maxvolte" value={maxvolte} onChange={(e) => {setmaxvolte(e.target.value); clearTimeout(timeout); salvaDettIngrediente(props.ingrediente.id, prezzo, minvolte, e.target.value, props.ingrediente.idcorso, props.ingrediente.idcorsopadre)}} />
           </td>
           <td>
            <button onClick={() => elimina(props.ingrediente.id)}>Elimina</button>
            </td>
</tr>
    )
}