import React from 'react';

export default function AppointmentTemplate(model) {
  return (
    <React.Fragment>
    <div style={{width:'100', backgroundColor:model.appointmentData.backgroundColor}}>
      <div>{model.appointmentData.text}</div>
    </div>
    </React.Fragment>
  );
}