import React from "react";

export default function SelectText({bootstrapsize, label, id, placeholder,name, value, onChange, lista}){

    return (
        <div className={"form-group "+bootstrapsize}>
            <label for={id}>{label}</label>
            <select 
            className="form-control"
            name={name} value={value} onChange={onChange}>
                    <option>-- {placeholder} --</option>
                    {lista&&Array.isArray(lista)&&lista.length>0&&
                    lista.map(datatemp => (
                        <option key={datatemp.value} value={datatemp.value}>{datatemp.name}</option>
                    ))}
                </select>
        </div>
    );
}