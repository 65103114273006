import React from "react";
import axios from 'axios';
import ListinoSelect from "../archivi/listino/ListinoSelect";
import ScontiSelect from "../archivi/sconti/ScontiSelect";
import { Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import { connect } from "react-redux";

class NuovoAbbonamento extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		validoDa:  null,
	    		sconto:null,
	    		listino:'',
	    		buttonVisible:false,
	    		visible:false
	    	}

	    this.updateSconto = this.updateSconto.bind(this);
	    this.updateListino = this.updateListino.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.update = this.update.bind(this);
	    this.showNuovoAbbonamento=this.showNuovoAbbonamento.bind(this);
	  }

	  update(e) {
		this.setState({ [e.target.name]: e.target.value });
	}
	
		handleSubmit(event) {
			event.preventDefault();
		    const { validoDa,listino,sconto } = this.state;
	
		    const formData = new FormData();
		    formData.append('validoDa', validoDa);
			formData.append('listino',listino);
			formData.append('sconto',sconto?sconto:"");
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.palestra.id);
			formData.append('idanagrafica',this.props.idanagselezionata);
			axios.post(global.config.server+'/nuovoAbbonamentoRest', formData)
	      .then((result) => {
		         const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: "Nuovo abbonamento creato con successo"
					})
					this.props.refresh(new Date().getTime())
	        this.setState({ buttonVisible: false });
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
	  }
	
	updateSconto(scontoSelezionato) {
		var sconto = this.state.sconto
		sconto = scontoSelezionato;
		this.setState({sconto, buttonVisible: true})
	  }
	
	updateListino(e) {
		var listino = this.state.listino
		listino = e;
		this.setState({listino, buttonVisible: true})
		console.log('listino: '+listino.id)
	  }
	
	showNuovoAbbonamento(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		var style1={
				color:"white"
		}
		
		var style2={
				width:"80px"
		}
		
		var style3={
			backgroundColor: "#D0D0D0"
		}

		return (
			
			<Modal show={this.props.show} onHide={this.props.togglePopup}>
			<Modal.Header closeButton>
			<Modal.Title>Nuovo Abbonamento</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Form>
					<Form.Group controlId="listino">
						<Form.Label>Listino</Form.Label>
						<ListinoSelect email={this.props.email}
                  idpalestra={this.props.idpalestra}
							selected={this.state.listino?this.state.listino:""} 
							listini={this.props.listini} 
							onChange={this.update}/>
					</Form.Group>
					<Form.Group controlId="datainizio">
						<Form.Label>Data inizio abbonamento</Form.Label>
						<Form.Control type="date" placeholder="Data inizio abbonamento" 
						name="validoDa" value={this.state.validoDa} required={true} onChange={this.update} />
					</Form.Group>
					<Form.Group controlId="datapagamento">
						<Form.Label>Data</Form.Label>
						<ScontiSelect email={this.props.email} selected={this.state.sconto?this.state.sconto:""} onChange={this.update}/>
					</Form.Group>
						<div class="col">
							<button type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>Inserisci nuovo abbonamento</button>
						</div>
				</Form>
				</Modal.Body>
				</Modal>
	);
	}
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(NuovoAbbonamento)