import axios from 'axios';
import serverInfo from '../util/globals';

export async function getallattivita(palestra) {
  console.log('getallattivita '+palestra)
  const formData = new FormData();
  formData.append('palestra', palestra);
  const response = await axios.post(
    serverInfo.namesec+'/attquot/getallattivita',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function getattivitaanag(idanag) {
  const formData = new FormData();
  formData.append('idanag', idanag);
  const response = await axios.post(
    serverInfo.namesec+'/attquot/getattivitaanag',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function salvaattquotanag(palestra, idanag, id, idattivita, ore) {
  const formData = new FormData();
  formData.append('palestra', palestra);
  formData.append('idanag', idanag);
  formData.append('id', id);
  formData.append('idattivita', idattivita);
  formData.append('ore', ore);
  const response = await axios.post(
    serverInfo.namesec+'/attquot/salva',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function delattquotanag(id) {
  const formData = new FormData();
  formData.append('id', id);
  const response = await axios.post(
    serverInfo.namesec+'/attquot/del',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}