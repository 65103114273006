import {createSlice} from '@reduxjs/toolkit';

export const gestoreFattureSlice = createSlice({
  name: 'gestoreFatture',
  initialState: {
    idfattura: 0,
    fatturericercate:[]
  },
  reducers: {
    setidfattura: (state, idfatt) => {
      console.log('setidfattura ' + idfatt.payload);
      state.idfattura = idfatt.payload;
    },
    setfatturericercate: (state, fatture) => {
      console.log('setfatturericercate ' + fatture.payload);
      state.fatturericercate = fatture.payload;
    },
  },
});

export const {setidfattura, setfatturericercate} = gestoreFattureSlice.actions;

export default gestoreFattureSlice.reducer;