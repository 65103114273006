import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import CategorieSelect from "./CategorieSelect";
import MostraImmagineLink from "./MostraImmagineLink";
import { connect } from "react-redux";

class NuovoEsercizio extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: 0,
	    		link: '',
	    		link2: '',
				descrizione:'',
				categoria:'',
				categoria2:'',
				categorie:'',
	    		visible: false,
	    		messaggio:null
	    	}
	    this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.updateCategoria = this.updateCategoria.bind(this);
	    this.showNuovoEsercizio=this.showNuovoEsercizio.bind(this);
	  }
	  
	  updateCategoria(categSelezionata) {
		var categoria = {...this.state.categoria}
		categoria = categSelezionata;
		this.setState({categoria, buttonVisible: true})
	  }

	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
	handleSubmit(event) {
		event.preventDefault();
	    const { id,link, link2,descrizione, categoria } = this.state;

	    const formData = new FormData();
		  formData.append('website', global.config.domain);
		  formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        
		formData.append('link', link);
		formData.append('link2', link2);
		formData.append('categoria', categoria);
		formData.append('descrizione', descrizione);
	      axios.post(global.config.server+'/inserisciEsercizioRest',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				alert(result.data)
				this.setState({ visible: false });
				this.props.refresh()
	      });
	  }

	  showNuovoEsercizio(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		var style1={
				color:"white"
		}
		
		var style2={
				width:"80px"
		}
		
		var style3={
			backgroundColor: "#D0D0D0"
		}
		return (
			<div class="card">
				<div class="card-header">
					<h4 onClick={this.showNuovoEsercizio}>Nuovo Esercizio</h4>
					</div>
				{this.state.visible?
				<div class="card-body">
				<div class="col-xs-3">
					<CategorieSelect selected={this.state.categoria} categorie={this.props.categorie} updateCategoria={this.updateCategoria} />
					Oppure inserisci una nuova categoria
					<input className="form-control" type="text" name="categoria" onChange={this.update} value={this.state.categoria} />
					<label htmlFor="ex2">Descrizione</label>
					<input className="form-control" type="text" name="descrizione" onChange={this.update} value={this.state.descrizione} />
				</div>
				<div class="row">
					<div class="col-sm">
						<div class="media">
							<MostraImmagineLink link={this.state.link}/>
							<div class="media-body">
								<h5 class="mt-0">Link M</h5>
								<input type="text" name="link" onChange={this.update} value={this.state.link} />
							</div>
						</div>
					</div>
					<div class="col-sm">
						<div class="media">
							<MostraImmagineLink link={this.state.link2}/>
							<div class="media-body">
								<h5 class="mt-0">Link F</h5>
								<input type="text" name="link2" onChange={this.update} value={this.state.link2} />
							</div>
						</div>
					</div>
					</div>
					<div class="card-footer text-muted row justify-content-md-center">
					<a href="#" class="btn btn-primary" onClick={this.handleSubmit}>Salva</a>&nbsp;&nbsp;&nbsp;
				</div>
				</div>
			:null}
			</div>
	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(NuovoEsercizio)