import React from "react";
import {  Button,  Form, Col, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import ListinoSelect from "../archivi/listino/ListinoSelect";
import ScontiSelect from "../archivi/sconti/ScontiSelect";
import { connect } from "react-redux";

class AbbonamentoEdit extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
            id:null,
            dataOperazione: null,
            prezzo: 0,
            prezzoIscrizione: 0,
            validoDa: "",
            validoA: "",
            dataScadenzaPagamento: "",
            prezzoListino:0 ,
            listino: null,
            palestra: 0,
            sconto:null,
            idsconto:'',
            fatture:[],
            valoreSconto:0
      };
      this.update=this.update.bind(this)
      this.aggiornaIscrizione=this.aggiornaIscrizione.bind(this)
      this.calcolaTotale=this.calcolaTotale.bind(this);
    }

    componentDidMount(){
        this.setState({...this.props.abbonamento},()=>(
            this.setState({listino:this.state.listino,
              prezzoListino:this.state.listino?this.state.listino.prezzoSenzaIscrizione:null,
              idsconto:this.state.sconto?this.state.sconto.id:'',
              idlistino:this.state.listino?this.state.listino.id:'',
            valoreSconto:this.state.sconto?this.state.sconto.valoreSconto:0})
        ))
    }

    calcolaTotale(){
        var tot=(parseFloat(this.state.prezzoIscrizione)+parseFloat(this.state.prezzoListino));
        var sconto=this.state.valoreSconto?tot*parseFloat(this.state.valoreSconto)/100:0
        this.setState({prezzo:tot-sconto})
    }

    update(e) {
        if(e.target.name==='listino'){
            this.setState({idlistino:e.target.value})
            if (this.props.listini){
                var result = this.props.listini.find(obj => {
                    return obj.id === Number(e.target.value)
                })

                this.setState({prezzoListino:result?result.prezzo:0},()=>(
                  this.calcolaTotale()
                ))
            }
        }
        else if(e.target.name==='sconto'){
          this.setState({idsconto:e.target.value})
          if (this.props.sconti){
              var result = this.props.sconti.find(obj => {
                  return obj.id === Number(e.target.value)
              })
              var scontistica=0;
              if (result){
                scontistica=result.valoreSconto;
              }
              this.setState({valoreSconto:scontistica},()=>(
                  this.calcolaTotale()
              ))
          }
        }
          else if(e.target.name==='prezzoIscrizione'){
            var iscrizione=0
            if (!e.target.value||e.target.value===''){
              iscrizione=0;
            }
            else{
              iscrizione=e.target.value;
            }
            this.setState({ prezzoIscrizione: iscrizione},()=>(
              this.calcolaTotale()
            ));
            
          }
          else{
            this.setState({ [e.target.name]: e.target.value, visible: true });
          }
    }
    
    aggiornaIscrizione(){
      console.log('EMAIL: '+this.props.email)
        const { id,validoDa,validoA,prezzo,idlistino,prezzoIscrizione,idsconto,dataScadenzaPagamento,iscrizione} = this.state;
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('id', id);
        formData.append('validoDa',validoDa);
        formData.append('validoA', validoA);
        formData.append('prezzo', prezzo);
        formData.append('listino', idlistino);
        formData.append('prezzoIscrizione', prezzoIscrizione);
        formData.append('sconto', idsconto);
        formData.append('dataScadenzaPagamento', dataScadenzaPagamento);
        formData.append('idiscrizione', iscrizione.id);//TODO
        axios.post(global.config.server+'/aggiornaAbbonamento', 
            formData
        )
          .then((result) => {
              this.setState({messaggio:result.data});
          const Toast = Swal.mixin({
              toast: true,
              position: 'top-end',
              showConfirmButton: false
            });
            Toast.fire({
              type: 'success',
              title: result.data
            })
                this.setState({ visible: false });
                this.props.refresh();
          })
          .catch(err => {
            alert(err.response.data.message);
          });
	}
  
    componentDidCatch(error, info) {
          // You can also log the error to an error reporting service
          console.log(error, info);
        }
  
    render() {
      return (
            <Form>
                <Form.Row className="align-items-center">
                <Col xs="auto">
                  <Form.Label htmlFor="inlineFormInput">
                 Listino 
                  </Form.Label>
                  <ListinoSelect email={this.props.email}
                  idpalestra={this.props.idpalestra}
                  selected={this.state.idlistino?this.state.idlistino:""} 
                  listini={this.props.listini} 
                  onChange={this.update}/>
                </Col>
                <Col>
                  <Form.Label htmlFor="inlineFormInput">
                  Abbonamento valido da:
                  </Form.Label>
                  <Form.Control size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Valido da"
                    value={this.state.validoDa}
                    name="validoDa"
                    type="date"
                    onChange={this.update}
                  />
                </Col>
                <Col>
                  <Form.Label htmlFor="inlineFormInput">
                 Valido a
                  </Form.Label>
                  <Form.Control size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Valido A"
                    value={this.state.validoA}
                    name="validoA"
                    type="date"
                    onChange={this.update}
                  />
                </Col>
                <Col>
                  <Form.Label htmlFor="inlineFormInput">
                 Scadenza pagamento
                  </Form.Label>
                  <Form.Control size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Valido da"
                    value={this.state.dataScadenzaPagamento}
                    name="dataScadenzaPagamento"
                    onChange={this.update}
                  />
                </Col>
                </Form.Row>

                <Form.Row className="align-items-center">
                <Col>
                  <Form.Label htmlFor="inlineFormInput">
                  Costo iscrizione
                  </Form.Label>
                  <InputGroup className="mb-2 " size="sm">
                  <Form.Control
                    id="inlineFormInput"
                    placeholder="Prezzo"
                    value={this.state.prezzoIscrizione}
                    name="prezzoIscrizione"
                    type="number"
                    onChange={this.update}
                  />
                    <InputGroup.Prepend>
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup.Prepend>
                  
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label htmlFor="inlineFormInput">
                  Prezzo da listino
                  </Form.Label>
                  <InputGroup className="mb-2 " size="sm">
                  <Form.Control
                    id="inlineFormInput"
                    placeholder="Prezzo da listino"
                    value={this.state.prezzoListino}
                    type="number"
                    disabled
                  />
                  <InputGroup.Prepend>
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                  </Col>
                  <Col xs="auto">
                  <Form.Label htmlFor="inlineFormInput">
                 Sconti 
                  </Form.Label>
                  <ScontiSelect email={this.props.email} selected={this.state.idsconto?this.state.idsconto:""} onChange={this.update}/>
                </Col>
                  <Col>
                  <Form.Label htmlFor="inlineFormInput">
                  Da pagare
                  </Form.Label>
                  <InputGroup className="mb-2 " size="sm">
                  <Form.Control 
                    id="inlineFormInput"
                    placeholder="Da pagare"
                    type="number"
                    value={(this.state.prezzo)}
                    name="prezzo"
                    onChange={this.update}
                  />
                    <InputGroup.Prepend>
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup.Prepend>
                  </InputGroup>
                </Col>
                
                
                <Col xs="auto">
                <Button variant="primary" onClick={this.aggiornaIscrizione}>
                  Aggiorna Abbonamento
                </Button>
                </Col>
                </Form.Row>
            </Form>
      );
    }
  }

  const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(AbbonamentoEdit)