import React from 'react';
import FiltriAbbonamenti from './FiltriAbbonamenti';
import FiltriFatturato from './FiltriFatturato';


export default class Dashboard extends React.Component {
  componentDidMount() {
  }

  render() {
    return (
      <div>
        <div>
        <FiltriFatturato />
        </div>
      <hr />
      <hr />
        <div>
        <FiltriAbbonamenti />
        </div>
      </div>
    );
  }
}