import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";

export default function UltimeIscrizioni(props) {
  const palestraassociata = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

  const [ultimeIscrizioni, setultimeIscrizioni] = useState(null)

    useEffect(() =>{
      if(palestraassociata
        &&palestraassociata.palestra
        &&palestraassociata.palestra.id
        &&palestraassociata.palestra.website){
          console.log("UltimeIscrizioni")
          const formData = new FormData();
          formData.append('palestra',palestraassociata.palestra.id);
          formData.append('email',email);
          formData.append('website', palestraassociata.palestra.website);
          axios.post(global.config.server+"/getUltimeIscrizioni", formData)
            .then(response=>{
              //console.log(response);
              setultimeIscrizioni(response.data)
          })
        }
	},[palestraassociata])

      return (
          <div>
            {
              ultimeIscrizioni?
              <div>
                <h4>Totale iscrizioni</h4>
                <p>Oggi {ultimeIscrizioni.totOggi}</p>
                <p>Questo mese {ultimeIscrizioni.totMese}</p>
                <p>Questo anno {ultimeIscrizioni.totAnno}</p>
                <h4>Abbonamenti in scadenza</h4>
                <p>Nei prossimi 7 giorni {ultimeIscrizioni.totInScadenza7}</p>
                <p>nei prossimi 30 giorni {ultimeIscrizioni.totInScadenza30}</p>
              </div>
              :"Totale iscrizioni non disponibile"
            }
            </div>
        );
  }