import React, { useState } from "react";
import { useSelector } from "react-redux";
import {navigate} from 'hookrouter'
import Bottone from "../../util/UI/Bottone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

export default function RigaSchededieta(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);

    function vaialdettaglio(){

        navigate('/annotatio-ali-schedadieta/'+props.scheda.id)
    }

    return (
       
        <tr>
            <td>
                <Bottone onClick={vaialdettaglio}
                bootstrapstyle={"info"}
                label={<FontAwesomeIcon icon={faEdit} />} />
            </td>
            <td>{props.scheda.idanag}</td>
            <td>{props.scheda.data}</td>
        </tr>
                
    );
}