import React from "react";
import { Modal } from "react-bootstrap";

export default function AnnotatioModal({show, setshow, titolo, body}){

    return (
        <Modal show={show} onHide={() => setshow(false)}>
			<Modal.Header closeButton>
			<Modal.Title>{titolo}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
                {body}
            </Modal.Body>
            </Modal>
    )
}