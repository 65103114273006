import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import { Modal, Button, Form } from "react-bootstrap";
import BancheSelect from "../banche/BancheSelect";
import MovimentiSelect from "./MocimentiSelect";
import { connect } from "react-redux";

class NuovoPagamento extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		dataPagamento: '',
	    		importo: this.props.importo,
                tipo:'',
                idabbonamento:this.props.idabbonamento,
                idprenotazione:this.props.idprenotazione,
                ricevuta:this.props.ricevuta,
                fattura:this.props.fattura,
	    		visible: false,
	    		totRicevute:0,
	    		messaggio:null
	    	}

        this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.associaMovimento=this.associaMovimento.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	  associaMovimento(event) {
		event.preventDefault();
	    const { idmovimento,idabbonamento,tipo, fattura, ricevuta} = this.state;
        const formData = new FormData();
		formData.append('idmovimento', idmovimento);
		formData.append('idabbonamento', idabbonamento);
		formData.append('tipo', tipo);
        formData.append('idfattura', fattura?fattura.id:'');
		formData.append('idricevuta', ricevuta?ricevuta.id:'');
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		axios.post(global.config.server+'/insPagamentoDaMovimento',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				alert(result.data)
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
      }

      handleSubmit(event) {
		event.preventDefault();
	    const { idabbonamento,dataPagamento,importo, tipo, fattura, ricevuta, banca} = this.state;

        const formData = new FormData();
	    formData.append('idabbonamento', idabbonamento);
	    formData.append('datapagamento', dataPagamento);
		formData.append('importo', importo);
		formData.append('tipo', tipo);
        formData.append('idfattura', fattura?fattura.id:'');
		formData.append('idricevuta', ricevuta?ricevuta.id:'');
		formData.append('banca', banca?banca:'');
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		axios.post(global.config.server+'/insPagamento',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				alert(result.data)
				this.props.refresh();
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
      }
      
      componentDidMount(){
        this.state = {
            dataPagamento: '',
	    		importo: this.props.importo,
                tipo:'',
                idabbonamento:this.props.idabbonamento,
                idprenotazione:this.props.idprenotazione,
                ricevuta:this.props.ricevuta,
                fattura:this.props.fattura,
	    		visible: false,
	    		totRicevute:0,
	    		messaggio:null
        }
	}

	render() {
	    return (
			<div>
				<Modal.Header>
				<Modal.Title>Nuovo Pagamentooo</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Importalo da un movimento esistente
					<MovimentiSelect email={this.props.email} update={this.update}/>
					<Button onClick={this.associaMovimento}>Importa</Button>
					<hr/>
					Oppure
				<Form>
					<Form.Group controlId="datapagamento">
						<Form.Label>Data</Form.Label>
						<Form.Control type="date" placeholder="Enter email" 
						name="dataPagamento" value={this.state.dataPagamento} required={true} onChange={this.update} />
					</Form.Group>

					<Form.Group controlId="importo">
						<Form.Label>Importo</Form.Label>
						<Form.Control type="number" placeholder="Importo" 
						name="importo" value={this.state.importo} required={true} onChange={this.update} />
					</Form.Group>
					<Form.Group controlId="tipo">
						<Form.Label>Tipo</Form.Label>
						<Form.Control as="select" size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Tipologia pagamento"
                    value={this.state.tipo}
                    name="tipo"
                    onChange={this.update}
                  >
				 <option value="contanti">Contanti</option>
							<option value="carta">Bancomat/Carta</option>
							<option value="bonifico">Bonifico</option>
							<option value="assegno">Assegno</option>
							<option value="paypal">Paypal</option>
							<option value="alma">Alma</option>
							</Form.Control>
					</Form.Group>
					<Form.Group controlId="importo">
						<Form.Label>Banca</Form.Label>
						<BancheSelect update={this.update} email={this.props.email}/>
					</Form.Group>
					<Button variant="primary" onClick={this.handleSubmit}>
						Salva
					</Button>
					</Form>

				</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={this.props.togglePopup}>
					Close
				</Button>
				</Modal.Footer>
			</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(NuovoPagamento)