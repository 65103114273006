import React from "react";
import axios from 'axios';
import '../../util/config'
import {  Form} from 'react-bootstrap';
import { connect } from "react-redux";

class CorsiSelect extends React.Component {
	 constructor(props) {
		    super(props);
		    this.state = {
		    		corsi:[]
					};
		  }
	 
	 componentDidMount(){
		if(this.props.corsi){
			this.setState({corsi: this.props.corsi});
		}
		else{
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  	formData.append('calendario',localStorage.getItem('calendario'));
			axios.post(global.config.server+"/getCorsiRest",formData,
			{
				headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+window.$t
				},
			},)
			.then(response=>{
				this.setState({corsi: response.data});
				//console.log(response);
			}).catch(err => {
				if (err.response){
				alert(err.response.data.message);
				}
				else{
				alert(err.response);
				}
		});
		}
	  }

	componentDidUpdate(prevProps) {
		if (prevProps.corsi !== this.props.corsi) {
			this.setState({corsi: this.props.corsi});
		}
	}
	
	render() {
	    return (
				<Form.Control as="select" className="form-control" name="corso" size="sm" value={this.props.selected} onChange={this.props.onChange}>
				<option value="">--Seleziona un prodotto--</option>
				{
					this.state.corsi&&Array.isArray(this.state.corsi)&&this.state.corsi.length>0?
					this.state.corsi.map(tipocorso =>(
						<optgroup label={tipocorso.nome}>
							
							{
								tipocorso.corsi.map(corso =>(
									<option value={corso.id}>{corso.nome}</option>
								)
								)
							}
							
						</optgroup>
					
					)
					)
					:null
				}
				
				</Form.Control>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(CorsiSelect)