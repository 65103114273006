import React, { useEffect, useState } from "react";
import axios from 'axios';
import serverInfo from "../util/globals";
import { useSelector } from "react-redux";

export default function BancheSelect (props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const [banche, setbanche] = useState([]);
  
  useEffect(() =>{
    const formData = new FormData();
      formData.append('website', global.config.domain);
      formData.append('email',email);
      formData.append('lic','000');
      formData.append('palestra',palestra.palestra.id);
      axios.post(serverInfo.name+"/getBanche",formData)
	  .then(response=>{
      setbanche(response.data);
      
	  });
  },[palestra, email]);
 

        return (
                <select className="form-control" onChange={props.update} name="banca" value={props.selected}>
                    <option key={-1} value="">--seleziona una banca--</option>
                {
                    banche.map((item,index) =>(
                            <option key={index} value={item.nome}>
                                {item.descrizione}
                                </option>
                            )
                    )
                }
                </select>
        );
}