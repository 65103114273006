import axios from "axios";
import '../util/config'

export async function getlistini(email, idpalestra) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('palestra', idpalestra);
    formData.append('website', global.config.domain);
    
    const response = await axios.post(
    global.config.server+"/listiniRest", 
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }