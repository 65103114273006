import React, { useEffect, useState } from "react";
import '../util/config'
import Ordine from "./Ordine";
import { ordiniadmin } from "../DAO/ordiniDAO";
import { useSelector } from 'react-redux';
import LoadMainThings from "../util/LoadMainThings";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function Ordini(props) {
  const [ordini, setordini] = useState([])
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
  const email = useSelector(state => state.gestoreUtente.emailloggato);

  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const [messaggio, setmessaggio] = useState('')

  const [expanded, setExpanded] = useState(false);
	
	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };

  function caricaordini() {
    async function getordini() {
        const listaordinitemp = await ordiniadmin(email, palestra.palestra.id);
        console.log(listaordinitemp)
        setordini(listaordinitemp);
        setmessaggio('Trovati '+listaordinitemp.length+' ordini')
    }
    if (palestra&&palestra.id){
      setmessaggio('Caricamento ordini ...')
        getordini();
    }
    
  }

  function stampa(ordine){
        
    //console.log('print');
    var myWindow=window.open('','','');
    myWindow.document.write( document.getElementById(ordine.id).innerHTML);
    
    myWindow.document.close();
    myWindow.focus();
    myWindow.print();
    myWindow.close();
  }

  useEffect(()=> {
    console.log("palestra ",palestra)
    caricaordini()
  }, [palestra,email, keytorefresh])

        return (
            <span key={keytorefresh}>
                
                <div className="row">
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                              {messaggio}
                                {ordini&&Array.isArray(ordini)&&ordini.length>0&&
                                ordini.map(ordine=>(
                                    <Accordion key={ordine.id} expanded={expanded === ordine.id} onChange={(event,isExpanded) => handleChange(ordine.id, event, isExpanded)}>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls={ordine.id+"bh-content"}
                                      id={ordine.id+"bh-header"}>
                                        <Typography sx={{ width: '33%', flexShrink: 0, color:ordine.status.indexOf('PRONTO')>=0?'green':ordine.status.indexOf('NUOVO')>=0?'blue':ordine.status.indexOf('ANNULLATO')>=0?'red':"black"}}>
                                          {ordine.id} {ordine.status}
                                        </Typography>
                                        <Typography>
                                          <button onClick={() => stampa(ordine)}>Stampa</button>
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                            <Ordine ordine={ordine} refresh={() => setkeytorefresh(new Date().getTime())}/>
                            </AccordionDetails>
                                    </Accordion>
                            ))
                            }
                            </div>
                        </div>
                    </div>
        </div>
        
    </span>
    );
}