import { useEffect } from "react";
import { useSelector } from "react-redux";
import InputText from "../../../util/UI/InputText";
import ListaAttQuotSeelct from "../attivitaquotidiane/listaattquotselect";
import { delattquotanag, salvaattquotanag } from "../../../DAO/attivitaquotidianaDAO";
import { useState } from "react";
import Swal from "sweetalert2";
import Bottone from "../../../util/UI/Bottone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash} from '@fortawesome/free-solid-svg-icons'

let timeout=0; 
export default function RigaAttQuotAnag({id, idattivita, oreatt, listaattquot, eliminaattquot, caricaAtt}){
    const [modificato, setmodificato] = useState(false)

    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [idatt, setidatt] = useState(idattivita)
    const [ore, setore] = useState(oreatt)

    function salvaAttQuotAnag(idatt2, ore2){
        async function salvaaqa(){
            const result=await salvaattquotanag(palestra.id,anagselezionata.id,id,idatt2, ore2)
            if(result&&result.id>0){
                Swal.fire({
                    position:'bottom-end',
                    title:'Salvataggio Attività quotidiana',
                    showConfirmButton:false,
                    text:'Attività salvata correttamente',
                    icon:'success',
                    timer:2000
                })
                caricaAtt()
            }
            else{
                Swal.fire({
                    position:'bottom-end',
                    title:'Salvataggio Attività quotidiana',
                    showConfirmButton:false,
                    text:"Si è verificato un errore durante il salvataggio dell'attività",
                    icon:'error',
                    timer:2000
                })
            }
        }
       if (idatt2&&ore2)
            salvaaqa()
    }

    useEffect(() => {
        if (!modificato) {
            setmodificato(true)
         } else {
            clearTimeout(timeout); 
            timeout = setTimeout(() => {
                salvaAttQuotAnag(idatt, ore)
                
            },1000) 
        }
    },[ore, idatt])

    return (
        
    <tr>
        <td>
            <ListaAttQuotSeelct
            onChange={(e) => {
                setidatt(e.target.value); 
                //salvaAttQuotAnag(e.target.value, ore)
            }}
            value={idatt}
            listaattquot={listaattquot}
            />
            
            </td>
        <td style={{textAlign:'right'}}>
            <InputText
            name={"ore"}
            onChange={(e) => {setore(e.target.value); 
                //salvaAttQuotAnag(idatt, e.target.value)
            }}
            placeholder={"Ore attività"}
            type={"number"}
            value={ore}
            width={100}
            />
            </td>
            <td>
            <Bottone
            onClick={() => eliminaattquot(id)}
            bootstrapstyle={"danger"}
            label={
                  <FontAwesomeIcon icon={faTrash} />
            }
             />
            </td>
    </tr>
                    
                
    )
}