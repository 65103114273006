import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { delattquotanag, getallattivita, getattivitaanag, salvaattquotanag } from "../../../DAO/attivitaquotidianaDAO";
import { useState } from "react";
import InputText from "../../../util/UI/InputText";
import ListaAttQuotSeelct from "../attivitaquotidiane/listaattquotselect";
import RigaAttQuotAnag from "./rigaattquotanag";
import Bottone from "../../../util/UI/Bottone";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import AnnotatioModal from "../../../util/UI/Modal";

let timeout=0; 
export default function ListaAttQuotAnag(){
    const [modificato, setmodificato] = useState(false)
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [attivita, setattivita] = useState([])
    const [attivitaquot, setattivitaquot] = useState([])

    const [newora, setnewora]=useState(0)
    const [newatt, setnewatt]=useState()
    let [totieiore, settotieiore] = useState(0)
    let [totore, settotore] = useState(0)
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
    const [shownuovo, setshownuovo] = useState(false)

    function salvanewattquot(){
        async function salva(){
            const result=await salvaattquotanag(palestra.id, anagselezionata.id, 0,newatt,newora)
            if (result&&result.id>0){
                Swal.fire({
                    position:'bottom-end',
                    title:'Salvataggio Nuova Attività quotidiana',
                    showConfirmButton:false,
                    text:'Attività salvata correttamente',
                    icon:'success',
                    timer:2000
                })
                const result=await getattivitaanag(anagselezionata.id)
            setattivita(result)
                setkeytorefresh(new Date().getTime())
                
                setshownuovo(false)
            }
            else{
                Swal.fire({
                    position:'bottom-end',
                    title:'Salvataggio Nuova Attività quotidiana',
                    showConfirmButton:false,
                    text:"Qualcosa è andato storo durante l'inserimento dell'attività "+newatt+ " :(",
                    icon:'error',
                    timer:2000
                })
            }
        }
        if (newatt&&newora>0){
            salva()
        }
    }

    function eliminaattquot(id){
        async function elim(){
            const result=await delattquotanag(id)
            Swal.fire({
                position:'bottom-end',
                title:'Cancellazione Attività quotidiana',
                showConfirmButton:false,
                text:result,
                icon:'info',
                timer:2000
            })
            caricaAtt()
            setkeytorefresh(new Date().getTime())
        }
        Swal.fire({
            title: "Confermi la cancellazione dell'attività selezionata?",
            showCancelButton: true,
            confirmButtonText: 'Conferma cancellazione',
          }).then((result) => {
            if (result.isConfirmed) {
                elim()
            }
          })
        
    }

    function caricaAtt(){
        async function load(){
            const result=await getattivitaanag(anagselezionata.id)
            const allatt=await getallattivita(palestra.id)
            setattivita(result)

            let ttt=0;
            let tttiei=0
            result&&Array.isArray(result)&&result.map(res => {
                ttt+=res.ore
                tttiei+=res.ore*(allatt.filter(alltemp => alltemp.id===res.idattivita)[0].iei)
            })
            settotore(ttt)
            settotieiore(Math.round((tttiei)*100)/100)
            setattivitaquot([])
            setattivitaquot(allatt)
        }
        load()
    }

    useEffect (() => {
        caricaAtt()
    },[anagselezionata])

    useEffect(() => {
        if (!modificato) {
            setmodificato(true)
         } else {
            clearTimeout(timeout); 
            timeout = setTimeout(() => {
                salvanewattquot()
                
            },1000) 
        }
    },[newora, newatt])

    return (
        <div key={keytorefresh} className="container-fluid">
            <a type="button" className="btn btn-info btn-lg" onClick={() => setshownuovo(true)}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </a>
                <AnnotatioModal
                body={<>
                <ListaAttQuotSeelct listaattquot={attivitaquot}
                onChange={(e) => setnewatt(e.target.value)} 
                value={newatt}
                />
                <InputText
                label={"Ore"}
                name={"ore"}
                placeholder={"Ore attività"}
                type={"number"}
                value={newora}
                onChange={(e) => setnewora(e.target.value)}
                bootstrapsize={"col-md-4"}
                /></>}
                setshow={setshownuovo}
                show={shownuovo}
                titolo={"Nuova Attività quotidiana"}
                />
                
            <table className="table">
                <thead>
                    <tr>
                        <th>Attività</th>
                        <th>Ore</th>
                    </tr>
                </thead>
                <tbody>
            {
                attivita&&Array.isArray(attivita)&&attivita.length>0&&
                attivita.map(att => (
                    <RigaAttQuotAnag
                    id={att.id}
                    idattivita={att.idattivita}
                    oreatt={att.ore}
                    listaattquot={attivitaquot}
                    key={att.id}
                    eliminaattquot={eliminaattquot}
                    caricaAtt={caricaAtt} />
                ))
            }
            <tr>
                <td>TOT</td>
            <td style={{textAlign:'right'}}>{totore}</td>
            <td style={{textAlign:'right'}}>{totieiore}</td>
            </tr>
            </tbody>
            </table>
            <div className="row">LAF = {totieiore}/{totore} = <strong>{Math.round((totieiore/totore)*100)/100}</strong></div>
        </div>
    )
}