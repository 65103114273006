import React from 'react';

import Scheduler, { Resource, View } from 'devextreme-react/scheduler';

import axios from 'axios';
import '../util/config'
import NuovaSchedulazione from './NuovaSchedulazione';
import DettaglioSchedulazione from './DettaglioSchedulazione';
import AppointmentTemplate from './AppointmentTemplate';
import { Button } from 'react-bootstrap';
import { connect } from "react-redux";

const views = ['day','week', 'month'];
const groups = ['location'];

class CalendarioAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cellDuration: 30,
      appuntamenti:[],
      places:[],
      groupByDate: true,
      popupDettaglioVisible:false,
      eventoSelezionato:null,
      schedulazione:null,
      status:
        [{
          text: 'NON PRENOTABILE',
          id: 'red',
          color: 'red',
        }, {
          text: 'PRENOTAZIONE CONFERMATA',
          color: 'green',
          id: 'green',
        },
        {
          text: 'DISPONIBILE',
          color: 'blue',
          id: 'blue',
        },
        {
          text: 'SOLO RISERVA',
          color: 'orange',
          id: 'orange',
        },
        {
          text: 'NON DISPONIBILE',
          color: 'gray',
          id: 'gray',
        },
        ]
    };
    this.showAppointmentPopup = this.showAppointmentPopup.bind(this);
    this.onOptionChanged = this.onOptionChanged.bind(this);
    this.ciccio = this.ciccio.bind(this);
    this.nuovociccio = this.nuovociccio.bind(this);
    this.schedulerRef = React.createRef();
    this.togglePopupDettaglio = this.togglePopupDettaglio.bind(this);
    this.togglePopupNuovo = this.togglePopupNuovo.bind(this);
    this.copiaSchedulazione=this.copiaSchedulazione.bind(this);
    this.refreshSchedulazioni=this.refreshSchedulazioni.bind(this);
    this.update=this.update.bind(this)
  }

  update(e) {
    //alert(e.target.name+' = '+e.target.value)
    this.setState({ [e.target.name]: e.target.value, visible: true });
  }  

  copiaSchedulazione(){
    const formData = new FormData();
    formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
    formData.append('lic','000');
    formData.append('palestra',this.props.palestra.id);
    formData.append('settimanaOrigine',this.state.settimanaOrigine)
    formData.append('settimanaDestinazione',this.state.settimanaDestinazione)
    formData.append('calendario',localStorage.getItem("calendario"));
    axios.post(global.config.server+'/copiaSchedulazioni',formData)
    .then((result) => {
      alert(result.data);
  }).catch(err => {
    if (err.response){
      alert(err.response.data.message);
    }
    else{
      alert(err)
    }
  });
  }

  togglePopupDettaglio(){
    this.setState({popupDettaglioVisible:!this.state.popupDettaglioVisible})
  }

  togglePopupNuovo(){
    this.setState({popupNuovoVisible:!this.state.popupNuovoVisible})
  }

  nuovociccio(e){
    console.log(e)
    e.cancel=true
    const dataselezionata=e.cellData.startDate.toLocaleString("it-IT", {timeZone: "Europe/Rome"}).split(',')
    const oraselezionata= (dataselezionata[1].split(':')[0]+":"+dataselezionata[1].split(':')[1]).trim()
    this.setState({popupNuovoVisible:true, 
      popupDettaglioVisible:false, 
      startDate:(e.cellData.startDate.toISOString().split('T')[0]), 
      oraSelezionata:oraselezionata})
  }

  ciccio(e){
    console.log(e)
    e.cancel=true
    this.setState({popupDettaglioVisible:true, popupNuovoVisible:false, schedulazione:e.appointmentData.schedulazione, eventoSelezionato:e.appointmentData})
  }

  refreshSchedulazioni(){
    const formData2 = new FormData();
    formData2.append('website', global.config.domain);
    formData2.append('email',this.props.email);
    formData2.append('lic','000');
    formData2.append('palestra',this.props.palestra.id);
    formData2.append('calendario',localStorage.getItem("calendario"));

    axios.post(global.config.server+"/calendario",formData2)
    
    .then(response=>{
      this.setState({appuntamenti: response.data, popupDettaglioVisible:false})
    }).catch(err => {
        if (err.response){
        alert(err.response.data.message);
        }
        else{
          alert(err.response);
        }
    });
  }

  componentDidMount(){
    const formData = new FormData();
  formData.append('website', global.config.domain);
  formData.append('email',this.props.email);
    formData.append('lic','000');
    formData.append('palestra',this.props.palestra.id);
  formData.append('calendario',this.props.calendario?this.props.calendario:localStorage.getItem("calendario"));
  axios.post(global.config.server+"/getCorsiRest",formData,
  {withCredentials: true,
    credentials:'include',
      headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
      },
  },)
        .then(response=>{
          axios.post(global.config.server+"/getPlaces",formData,
          {withCredentials: true,
            credentials:'include',
              headers: {
              Accept: 'application/json',
              'Content-Type': 'multipart/form-data',
              'Access-Control-Allow-Origin': '*',
              'Access-Control-Allow-Methods': 'POST',
              'Access-Control-Allow-Headers': 'Content-Type, Authorization',
              'Access-Control-Allow-Credentials': 'true',
              'Authorization' :'Bearer '+window.$t
              },
          },)
              .then(response1=>{
                axios.post(global.config.server+"/calendario",formData,
                {withCredentials: true,
                  credentials:'include',
                    headers: {
                    Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Methods': 'POST',
                    'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                    'Access-Control-Allow-Credentials': 'true',
                    'Authorization' :'Bearer '+window.$t
                    },
                },)
                    .then(response2=>{
                      console.log(response.data)
                      this.setState({corsi: response.data, places: response1.data, appuntamenti: response2.data})
                    console.log(response2.data)
                    }).catch(err => {
                        if (err.response){
                        alert(err.response.data.message);
                        }
                        else{
                        alert(err.response);
                        }
                    });
              
              }).catch(err => {
                  if (err.response){
                  alert(err.response.data.message);
                  }
                  else{
                  alert(err.response);
                  }
              });
        }).catch(err => {
            if (err.response){
              alert(err.response.data.message);
            }
            else{
              alert(err.response);
            }
          });
   
}

  render() {
    return (
      <React.Fragment>
        {this.state.popupDettaglioVisible&&this.state.eventoSelezionato?
        <DettaglioSchedulazione show={this.state.popupDettaglioVisible} 
        togglePopupDettaglio={this.togglePopupDettaglio}
        corsi={this.state.corsi}
        email={this.props.email}
        schedulazione={this.state.eventoSelezionato.schedulazione}
        eventoSelezionato={this.state.eventoSelezionato}
        refresh={this.refreshSchedulazioni}
        calendario={localStorage.getItem("calendario")}
        />
        :
        this.state.popupNuovoVisible?
        <NuovaSchedulazione show={this.state.popupVisible} 
      togglePopup={this.togglePopupNuovo}
      corsi={this.state.corsi}
      email={this.props.email}
      giornoSelezionato={this.state.startDate}
      oraSelezionata={this.state.oraSelezionata}
      refresh={this.refreshSchedulazioni}
      calendario={localStorage.getItem("calendario")}
      />
      :
      <span>
          copia tutti gli eventi della settimana <input type="week" name="settimanaOrigine" value={this.state.settimanaOrigine} onChange={this.update}  /> 
          nella settimana <input type="week" name="settimanaDestinazione" value={this.state.settimanaDestinazione} onChange={this.update} /> <Button onClick={this.copiaSchedulazione}>Copia</Button>
          </span>      
      }
        <Scheduler
        visible={!(this.state.popupDettaglioVisible||this.state.popupNuovoVisible)}
          timeZone="Europe/Rome"
          dataSource={this.state.appuntamenti}
          //groups={groups}
          //groupByDate={this.state.groupByDate}
          views={views}
          adaptivityEnabled={false}
          onCellClick={this.nuovociccio}
          onAppointmentClick={this.ciccio}
          onAppointmentDblClick={this.ciccio}
          onOptionChanged={this.onOptionChanged}
          defaultCurrentView="week"
          cellDuration={this.state.cellDuration}
          appointmentRender={AppointmentTemplate}
          firstDayOfWeek={1}
          startDayHour={9}
          endDayHour={22}
          appointmentTemplate={AppointmentTemplate}
          showAllDayPanel={false}

          crossScrollingEnabled={false}
          showCurrentTimeIndicator={true}>
            <Resource
            dataSource={this.state.places}
            fieldExpr="location"
            label="Location"
            useColorAsDefault={false}
          />
          <Resource
            dataSource={this.state.status}
            fieldExpr="backgroundColor"
            label="Status"
            useColorAsDefault={true}
          />
          <View
            type="day"
            name="Day"
          />
          <View
            type="week"
            name="Week"
          /> 
          <View
            type="month"
            name="Month"
          />
        </Scheduler>
        
      </React.Fragment>
    );
  }

  onOptionChanged(e) {
    if (e.name === 'currentDate') {
      this.setState({ currentDate: e.value });
    }
  }

  showAppointmentPopup() {
    this.schedulerRef.current.instance.showAppointmentPopup();
  }
}

const mapStateToProps = state => {
  return {
    palestra: state.gestoreUtente.palestraassociata.palestra,
    email: state.gestoreUtente.emailloggato,
    idanagselezionata: state.gestoreUtente.idanagselezionata
  }
}

export default connect(mapStateToProps, null)(CalendarioAdmin)