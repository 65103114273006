import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import TipologiaAbbInput from "./TipologiaAbbInput";
import NuovaTipologiaAbbInput from "./NuovaTipologiaAbbInput";
import { useSelector } from "react-redux";
import { Accordion, AccordionSummary, Typography } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function TipologieAbbonamentoInput(props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [listaTipologie, setlistaTipologie] = useState([])
	const [tipiCorso, settipiCorso] = useState([])
	const [selectedTosFile, setselectedTosFile] = useState(null)
	const [selectedPrivacyFile, setselectedPrivacyFile] = useState(null)
	const [listaFilesToS, setlistaFilesToS] = useState(null)
	const [listaFilesPrivacy, setlistaFilesPrivacy] = useState()
	const [messaggio, setmessaggio] = useState('')
	const [visible, setvisible] = useState(false)
	const [expanded, setExpanded] = useState(false);

	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };

  
  function onToSFileChange (event) { 
	setselectedTosFile(event.target.files[0]); 
  }; 

  function onPrivacyFileChange (event){ 
	setselectedPrivacyFile(event.target.files[0]); 
  }; 

  function onToSFileUpload () {
	const formData = new FormData(); 
	formData.append( 
	  "file", 
	  selectedTosFile, 
	  selectedTosFile.name 
	);
	//console.log(selectedTosFile); 
	formData.append('website', palestra.website);
	formData.append('email',email);
	formData.append('lic','000');
	formData.append('palestra',palestra.id);
	axios.post(global.config.server+"/salvaToSPalestraRest", formData)
	.then((result) => {
		setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 6000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
		  props.refresh();
		  setvisible(false);
  }).catch(err => {
	if (err.response){
	  alert(err.response.data.message);
	}
	else{
	  alert(err.response);
	}
  });
  };

  function onPrivacyFileUpload () {
	const formData = new FormData(); 
	formData.append( 
	  "file", 
	  selectedPrivacyFile, 
	  selectedPrivacyFile.name 
	);
	//console.log(selectedTosFile); 
	formData.append('website', palestra.website);
	formData.append('email',email);
	formData.append('lic','000');
	formData.append('palestra',palestra.id);
	axios.post(global.config.server+"/salvaDocPrivacyPalestraRest", formData)
	.then((result) => {
		setmessaggio(result.data);
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
		  props.refresh();
		  setvisible(false);
  }).catch(err => {
	if (err.response){
	  alert(err.response.data.message);
	}
	else{
	  alert(err.response);
	}
  });
  }; 

	useEffect(() =>{
		if (palestra&&palestra.id){
		const formData = new FormData();
		formData.append('website', palestra.website);
		formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
		axios.post(global.config.server+"/getTipologieCorsoRest",formData)
	  		.then(response2=>{		  
		axios.post(global.config.server+"/getTipologieAbbRest", formData)
		  .then(response=>{
			axios.post(global.config.server+"/getToSPalestraRest", formData)
	  		.then(response3=>{
				axios.post(global.config.server+"/getPrivacyPalestraRest",formData)
	  		.then(response4=>{
			  //console.log("getTipologieAbbRest")
			  console.log(response.data)
			  setlistaTipologie(response.data);
			  settipiCorso(response2.data);
			  console.log("TIPICORSO:")
			  console.log(response2.data)
			  setlistaFilesToS(response3.data);
			  setlistaFilesPrivacy(response4.data);
		  }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
	  }).catch(err => {
		if (err.response){
		  alert(err.response.data.message);
		}
		else{
		  alert(err.response);
		}
	  });
	  
	  }).catch(err => {
		if (err.response){
		  alert(err.response.data.message);
		}
		else{
		  alert(err.response);
		}
	  });
	  }).catch(err => {
		if (err.response){
		  alert(err.response.data.message);
		}
		else{
		  alert(err.response);
		}
	  });
	}
	},[palestra]
	);

    function fileDataToS () { 
		if (selectedTosFile) { 
		  return ( 
			<div> 
			  <h2>File Details:</h2> 
			  <p>File Name: {selectedTosFile.name}</p> 
			  <p>File Type: {selectedTosFile.type}</p> 
			  <p> 
				Last Modified:{" "} 
				{selectedTosFile.lastModifiedDate.toDateString()} 
			  </p> 
			</div> 
		  ); 
		} else { 
		  return ( 
			<span/>
		  ); 
		} 
	  }; 
	
	function fileDataPrivacy () { 
		if (selectedPrivacyFile) { 
		  return ( 
			<div> 
			  <h2>File Details:</h2> 
			  <p>File Name: {selectedPrivacyFile.name}</p> 
			  <p>File Type: {selectedPrivacyFile.type}</p> 
			  <p> 
				Last Modified:{" "} 
				{selectedPrivacyFile.lastModifiedDate.toDateString()} 
			  </p> 
			</div> 
		  ); 
		} else { 
		  return ( 
			<span/>
		  ); 
		} 
	  }; 

    return (
    		<div className="container">
				<div className="row" style={{borderWidth:"1px", borderStyle:"solid", borderColor:"black"}}>
					<div className="col-4">
						<b>Termini di servizio</b><br /> (da visualizzare durante la prenotazione o un nuovo abbonamento)
					</div>
					<div className="col-8">
						<div className="row">
						{
							listaFilesToS?listaFilesToS.map(file=>(
								<div className="col">
									<b>File caricato:</b> 
									<i><a href={"/scaricaFile/"+file.id}>{file.nomeOriginale}</a></i>
								</div>
							)
							)
							:<div className="col">Nessun Documento di termine di servizio caricato</div>
						}
						</div>
						<div className="row">
							<input type="file" onChange={onToSFileChange} />
							{
							selectedTosFile?
							<button onClick={onToSFileUpload}>Upload!</button>
  							:null
							  }
							{fileDataToS()} 
						</div>
					</div>
				</div>
				<div className="row" style={{borderWidth:"1px", borderStyle:"solid", borderColor:"black"}}>
					<div className="col-4">
						<b>Documento sulla Privacy</b><br /> (da visualizzare durante la prenotazione o un nuovo abbonamento)
					</div>
					<div className="col-8">
						<div className="row">
						{
							listaFilesPrivacy?listaFilesPrivacy.map(file=>(
								<div className="col">
									<b>File caricato:</b> 
									<i><a href={"/scaricaFile/"+file.id}>{file.nomeOriginale}</a></i>
								</div>
							)
							)
							:<div className="col">Nessun Documento sulla Privacy caricato</div>
						}
						</div>
						<div className="row">
							<input type="file" onChange={onPrivacyFileChange} />
							{
							selectedPrivacyFile?
							<button onClick={onPrivacyFileUpload}>Upload!</button>
  							:null
							  }
							{fileDataPrivacy()} 
						</div>
					</div>
				</div>
    		{
    			listaTipologie.map(item =>(
					<Accordion key={item.id} expanded={expanded === item.id} onChange={(event,isExpanded) => handleChange(item.id,event, isExpanded)}>
    					<AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={item.id+"bh-content"}
                                id={item.id+"bh-header"}>
                                    <Typography sx={{ width: '33%', flexShrink: 0 }}>
                                        {item.descrizione} 
                                    </Typography>
                            </AccordionSummary>
						<TipologiaAbbInput key={item.id} 
						selected={item} 
						tipiCorso={tipiCorso} 
						email={props.email} />
						</Accordion>
    				)
    			)
    		}
    		<NuovaTipologiaAbbInput 
			tipiCorso={tipiCorso}
			email={props.email} />
    		</div>
    );
}