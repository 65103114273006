import React, { useState } from "react";
import axios from 'axios';
import serverInfo from "../util/globals";
import {connect, useDispatch, useSelector} from 'react-redux'
import {navigate} from 'hookrouter'
import { setidanagselezionata } from "../STORE/reducers/gestoreUtente";
import { comuneCf, dataCf, sessoCf } from "../util/calcolocodfisc";
import ComuniItalianiJson from "../util/ComuniItalianiJson";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { calcolocodfisc } from "../util/codfisc";

var timeout=0
export default function NuovaAnagrafica (){
    
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagricercate = useSelector(state => state.gestoreUtente.anagricercate);

    const dispatch = useDispatch();
    const [code, setcode] = useState('');
    const [emailanag, setemailanag] = useState('');
    const [nome, setnome] = useState('');
    const [cognome, setcognome] = useState('');
    const [sesso, setsesso] = useState('');
    const [dataNascita, setdataNascita] = useState('');
    const [luogonascita, setluogonascita] = useState('');
    const [codiceFiscale, setcodiceFiscale] = useState('');
    const [indirizzo, setindirizzo] = useState('');
    const [citta, setcitta] = useState('');
    const [cap, setcap] = useState('');
    const [telefono, settelefono] = useState('');
    const [cognomeNomeGenitore, setcognomeNomeGenitore] = useState('');
    const [dataNascitaGenitore, setdataNascitaGenitore] = useState('');
    const [codiceFiscaleGenitore, setcodiceFiscaleGenitore] = useState('');
    const [indirizzoGenitore, setindirizzoGenitore] = useState('');
    const [cittaGenitore, setcittaGenitore] = useState('');
    const [capGenitore, setcapGenitore] = useState('');

    const [showcomunipernascita, setshowcomunipernascita] = useState(false);
    const [showcomunipercitta, setshowcomunipercitta] = useState(false)
    const [showcomunipercap, setshowcomunipercap] = useState(false)
    
    const [tesserasanitaria, settesserasanitaria] = useState('');


    function handleSubmit(e){
          e.preventDefault();
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestraanagrafica.id);
        formData.append('code', code);
        formData.append('emailanag', emailanag);
        formData.append('nome', nome);
        formData.append('cognome', cognome);
        formData.append('sesso', sesso);
        formData.append('datanascita', dataNascita);
        formData.append('luogonascita', luogonascita);
        formData.append('codicefiscale', codiceFiscale);
        formData.append('indirizzo', indirizzo);
        formData.append('citta', citta);
        formData.append('cap', cap);
        formData.append('telefono', telefono);
        formData.append('cognomeNomeGenitore', cognomeNomeGenitore);
        formData.append('dataNascitaGenitore', dataNascitaGenitore);
        formData.append('codiceFiscaleGenitore', codiceFiscaleGenitore);
        formData.append('indirizzoGenitore', indirizzoGenitore);
        formData.append('cittaGenitore', cittaGenitore);
        formData.append('capGenitore', capGenitore);

        axios.post(serverInfo.namesec+'/user/insAnagraficaSelezionata', formData,
        {
            withCredentials:true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
          },
        },)
        .then((result) => {
			console.log(result.data)
            if (result.data
                &&!isNaN(result.data.id)){
                    //localStorage.setItem('idanagrafica',result.data)
                    alert('inserimento effettuato con successo');
                    //this.props.setView('dettanag');
                    dispatch(setidanagselezionata(result.data.id))
                    navigate('/anntt-adm-dettanag/')
                }
                else{
        	        alert(result.data)
                }
        });
	  }

    function testalo(inputcodfisc){
        if (inputcodfisc.indexOf('%')===0){
            inputcodfisc=inputcodfisc.substring(1,17)
        }
        else{
            inputcodfisc=inputcodfisc.substring(0,16)
        }
        let codfisc=inputcodfisc.toUpperCase()
        console.log('codfisc '+codfisc)
        setcodiceFiscale(codfisc)
        setsesso(sessoCf(codfisc));
        setdataNascita(dataCf(codfisc));
        setluogonascita(comuneCf(codfisc));
        let valuetemp=[];
        if (inputcodfisc.indexOf('?')>0){
            valuetemp = inputcodfisc.split('?')
        }
        else if (inputcodfisc.indexOf('_')>0){
            valuetemp = inputcodfisc.split('_')
        }
        if (valuetemp[0]){
         console.log('prima parte cod fisc '+valuetemp[0].substring(1,17))
         console.log('seconda parte nome cognome '+valuetemp[0].substring(17))
         let nomecognometemp=valuetemp[0].substring(17).split(' ');
         nomecognometemp=nomecognometemp.filter(nc => nc!=='')
         if (nomecognometemp.length===2){
                  setcognome(nomecognometemp[0])
                  setnome(nomecognometemp[1])
              }
              else if (nomecognometemp.length>2){
                  setcognome(nomecognometemp[0]+" "+nomecognometemp[1])
                  let nometemp=''
                  for (let i=2; i<nomecognometemp.length; i++){
                    nometemp+=nomecognometemp[i]
                    if (i<nomecognometemp.length-1){
                        nometemp+=' '
                    }
                  }
                  setnome(nometemp)
              }
              else if (nomecognometemp.length===1){
                  setcognome(nomecognometemp[0])
                  setnome('')
        }
    }
         setTimeout(() => {
            settesserasanitaria('')
         }, 1000); 
    }

    function splitdati(value){
        if (value.indexOf('%')===0&&value.length>=30){
            clearTimeout(timeout);
            let inputcodfisc=value
            inputcodfisc=inputcodfisc.replace('. ','')
            inputcodfisc=inputcodfisc.replace('\n',' ')
            inputcodfisc=inputcodfisc.replace('\r',' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
      }

      return (
        <form id="formAnag"  onSubmit={handleSubmit}>
            <div className="row">
                    <button className="btn btn-success" >SALVA</button>
                </div>

            <div className="row">
            <div className="form-group col-4">
            <input placeholder="DATI TESSSERA SANITARIA" className="form-control" type="text" name="tesserasanitaria" value={tesserasanitaria} onChange={(e)=>{settesserasanitaria(e.target.value); clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000);}}/>
            </div>
            </div>

        <div className="row">
            <div className="form-group col-md-4">
            <label className="control-label">Nome</label>
            <input placeholder="Nome" className="form-control" type="text" size="15" name="nome" value={nome} required="required" onChange={(e)=>{setnome(e.target.value);}}/>
            </div>
            
            <div className="form-group col-md-4">
            <label className="control-label">Cognome</label>
            <input placeholder="Cognome" className="form-control" type="text" size="14" name="cognome" value={cognome} required="required" onChange={(e)=>{setcognome(e.target.value);}}/>
            </div>

            <div className="form-group col-1">
            <label className="control-label">Sesso</label>
            <select className="form-control" name="sesso" required="required" placeholder="sesso" value={sesso}  onChange={(e)=>{setsesso(e.target.value);}}>
                    <option></option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                    </select>
            </div>
        </div>
        <div className="row">
            <div className="col-md-3">
                <div className="form-group">
                <label>Data di nascita <small className="text-muted">yyyy-mm-dd</small></label>
                <input type="date" name="dataNascita" className="form-control date-inputmask" id="date-mask" placeholder="Enter Date" value={dataNascita} required="required" onChange={(e)=>{setdataNascita(e.target.value);}}/>
                </div>
            </div>
            <div className="col-md-4">
           <div className="form-group">
                        <label for="inputPassword4">Luogo nascita</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="LUOGO NASCITA" name="luogonascita" value={luogonascita} 
                        required="required"
                        onChange={(e) =>{setluogonascita(e.target.value); setshowcomunipernascita(true)}} />
                        {luogonascita&&luogonascita.length>=3&&
                        ComuniItalianiJson.map(comune =>
                            showcomunipernascita&&comune.Comune.toUpperCase().indexOf(luogonascita.toUpperCase())===0&&
                            <ul style={{cursor:'pointer', fontSize:'smaller', borderStyle:"dashed", borderWidth:1, borderColor:'black'}}>
                                 <li onClick={() => {setluogonascita(comune.Comune); setshowcomunipernascita(false)}}>{comune.Comune} ({comune.Provincia}) - {comune.Regione}</li>
                             </ul>
                            )}
                    </div>
            </div>
            <div className="form-group col-md-3">
                        <label for="inputPassword4">Cod. Fiscale</label>
                        <div className="input-group">
                        <div class="input-group-prepend">
                            <button class="btn btn-outline-secondary" type="button" 
                            onClick={() => setcodiceFiscale(calcolocodfisc(nome, cognome, luogonascita, dataNascita, sesso))}><FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                        <input type="text" 
                        className="form-control"
                        placeholder="CODICE FISCALE" name="codiceFiscale" value={codiceFiscale}
                        required="required"
                         onChange={(e) =>setcodiceFiscale(e.target.value.toUpperCase())} />
                        
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" onClick={() => testalo(codiceFiscale)}><FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>
                        </div>
                    </div>
        </div>
        <div className="row">
            <div className="form-group col-md-6">
            <label className="control-label">Indirizzo</label>
            <input placeholder="via" type="text" name="indirizzo" 
            required="required"
            className="form-control" value={indirizzo} maxLength="255" onChange={(e)=>{setindirizzo(e.target.value);}}/>
            </div>
            <div className="form-group col-md-4">
                <label for="inputPassword4">Citta</label>
                <input type="text" 
                required="required"
                            className="form-control"
                            placeholder="CITTA" name="citta" value={citta} onChange={(e) =>{setcitta(e.target.value); setshowcomunipercitta(true)}} />
                            {citta&&citta.length>=3&&
                        ComuniItalianiJson.map(comune =>
                            showcomunipercitta&&comune.Comune.toUpperCase().indexOf(citta.toUpperCase())===0&&
                            <ul style={{cursor:'pointer', fontSize:'smaller', borderStyle:"dashed", borderWidth:1, borderColor:'black'}}>
                                 <li onClick={() => {setcitta(comune.Comune); setcap(comune.CAP);setshowcomunipercitta(false)}}>{comune.Comune} ({comune.Provincia}) - {comune.Regione}</li>
                             </ul>
                            )}
            </div>
            <div className="form-group col-md-2">
                            <label for="inputPassword4">Cap</label>
                            <input type="text" 
                            required="required"
                            className="form-control"
                            placeholder="CAP" name="cap" value={cap} onChange={(e) =>{setcap(e.target.value); setshowcomunipercap(true)}} />
                            {cap&&cap.length>=3&&
                        ComuniItalianiJson.map(comune =>
                            showcomunipercap&&(""+comune.CAP).indexOf(cap)===0&&
                            <ul style={{cursor:'pointer', fontSize:'smaller', borderStyle:"dashed", borderWidth:1, borderColor:'black'}}>
                                 <li onClick={() => {setcitta(comune.Comune); setcap(comune.CAP);setshowcomunipercap(false)}}>{comune.Comune} ({comune.Provincia}) - {comune.Regione}</li>
                             </ul>
                            )}
                        </div>
        </div>
        <div className="row">
        <div className="col">
        <div className="form-group">
        <label className="control-label">Tel</label>
        <input className="form-control" type="tel" value={telefono} maxLength="24" name="telefono" required="required" placeholder="Telefono" onChange={(e)=>{settelefono(e.target.value);}}/>
        </div></div>
        <div className="col">
        <div className="form-group">
        <label className="control-label">Email</label>
        <input className="form-control" type="email" name="emailanag" value={emailanag} placeholder="Email" required="required" onChange={(e)=>{setemailanag(e.target.value);}}/>
        </div>
        </div>
        </div>
        
        
        <div className="row">
        <div className="col">
        <h4>Eventuali dati del genitore/tutore</h4>
        </div>
        </div>
        <div className="row">
        <div className="col-6">
        <label className="control-label">Cognome e Nome</label>
        <input className="form-control" type="text" name="cognomeNomeGenitore" value={cognomeNomeGenitore} onChange={(e)=>{setcognomeNomeGenitore(e.target.value);}}/>
        </div>
        <div className="col">
            <div className="form-group">
            <label>Data nascita <small className="text-muted">yyyy-mm-yy</small></label>
            <input type="date" className="form-control date-inputmask" id="date-mask" placeholder="Data nascita genitore" name="dataNascitaGenitore" value={dataNascitaGenitore} onChange={(e)=>{setdataNascitaGenitore(e.target.value);}}/>
        </div>
            </div>
        <div className="col-4">
        <div className="form-group">
        <label className="control-label">Codice fiscale/P. IVA</label>
        <input className="form-control" type="text" name="codiceFiscaleGenitore" value={codiceFiscaleGenitore} onChange={(e)=>{setcodiceFiscaleGenitore(e.target.value);}}/>
        </div></div>
        </div>
        <div className="row">
        <div className="col-6">
        <div className="form-group">
        <label className="control-label">Indirizzo</label>
        <input className="form-control" type="text" name="indirizzoGenitore" value={indirizzoGenitore} onChange={(e)=>{setindirizzoGenitore(e.target.value);}}/>
        </div>
        </div>
        <div className="col-4">
        <div className="form-group">
        <label className="control-label">Città</label>
        <input className="form-control" type="text" name="cittaGenitore" value={cittaGenitore} onChange={(e)=>{setcittaGenitore(e.target.value);}}/>
        </div>
        </div>
        <div className="col">
        <div className="form-group">
        <label className="control-label">CAP</label>
        <input className="form-control" type="text" name="capGenitore" value={capGenitore} onChange={(e)=>{setcapGenitore(e.target.value);}}/>
        </div>
        </div>
        </div>
        
        </form>
        );
  }