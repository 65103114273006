import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import { Modal, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";

class NuovaRicevuta extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: 0,
	    		anno: new Date().getFullYear(),
	    		data: new Date().getDate() +"/"+(new Date().getMonth()+1)+'/'+new Date().getFullYear(),
	    		importo:0,
	    		numero:null,
				idpagamento:this.props.idpagamento,
				idabbonamento:this.props.idabbonamento,
	    		visible: false,
	    		messaggio:null
	    	}
	    this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
	  componentDidMount(){
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		axios.post(global.config.server+"/getNumeroRicevuta", formData)
		  .then(response=>{
			  this.setState({numero: response.data});
			  //console.log(response);
		  }).catch(err => {
			//alert("Numero Ricevute: "+err.response.data.message);
		}).catch(err => {
			if (err.response){
			alert("Ricevute: "+err.response.data.message);
			}
			else{
			alert("Ricevute: "+err.response);
			}
		})
	  }
	  
	handleSubmit(event) {
		event.preventDefault();
		const { id,anno,data, importo, numero, idpagamento, idabbonamento } = this.state;

		const formData = new FormData();
    	formData.append('website', global.config.domain);
    	formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
	    formData.append('id', 0);
	    formData.append('anno', anno);
		formData.append('data', data);
		formData.append('importo', importo);
		formData.append('numero', numero);
		formData.append('idpagamento', idpagamento?idpagamento:'');
		formData.append('idabbonamento', idabbonamento);

	      axios.post(global.config.server+'/aggiornaRicevutaRest',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
					this.props.togglePopup();
					this.props.refresh();
	      }).catch(err => {
			if (err.response){
			alert("Ricevute: "+err.response.data.message);
			}
			else{
			alert("Ricevute: "+err.response);
			}
		})
	  }
	  

	render() {
		return (
			<Modal show={this.props.show} onHide={this.props.togglePopup}>
				<Modal.Header closeButton>
				<Modal.Title>Nuova Ricevuta</Modal.Title>
				</Modal.Header>
				<Modal.Body>
				<Form>
				<span>{this.state.messaggio}</span>
					<Form.Group controlId="anno">
						<Form.Label>Anno</Form.Label>
						<Form.Control placeholder="Anno" 
						name="Anno" 
						value={this.state.anno} 
						required={true} />
					</Form.Group>
					<Form.Group controlId="numero">
						<Form.Label>Numero</Form.Label>
						<Form.Control placeholder="numero" 
						name="numero" 
						value={this.state.numero} 
						required={true} />
					</Form.Group>
					<Form.Group controlId="Data">
						<Form.Label>Data</Form.Label>
						<Form.Control type="date" 
						placeholder="Data" 
						name="data" 
						value={this.state.data} 
						onChange={this.update}
						required={true} />
					</Form.Group>
					<Form.Group controlId="importo">
						<Form.Label>Importo</Form.Label>
						<Form.Control type="number" placeholder="importo" 
						name="importo" 
						value={this.state.importo} 
						onChange={this.update}
						required={true} />
					</Form.Group>
					<Button variant="primary" onClick={this.handleSubmit}>
						Salva
					</Button>
					</Form>
					</Modal.Body>
			</Modal>
	);
	}
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(NuovaRicevuta)