import React, { useEffect, useState } from "react";
import { getgiornischeda, getscheda, salvaschedadieta } from "../../DAO/schededietaDAO";
import { useSelector } from "react-redux";
import DettGiornoSchedadieta from "./DettGiornoSchedadieta";
import { getlistaalimenti } from "../../DAO/alimentiDAO";
import InputText from "../../util/UI/InputText";
import Text from "../../util/UI/Text";

export default function NuovaSchedadieta({setkeytorefresh}){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const anagrafica = useSelector(state => state.gestoreUtente.anagselezionata);

    const [data, setdata]=useState(new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2))
    const [giorni, setgiorni]=useState([])

    const [listaalimentiselect, setlistaalimentiselect] = useState([])

    function addriga(numgiorno){
        let giornotemp={
            giorno:numgiorno?numgiorno:'',
            pasto:'',
            alimenti:[]
        };
        setgiorni([...giorni,giornotemp]) 
    }

    const deletegiorno = (index)=>{
        const rows = [...giorni];
        rows.splice(index, 1);
        setgiorni(rows);
   }
 
   function handleChangeGiorni (index, evnt){
        const { name, value } = evnt.target;
        const rowsInput = [...giorni];
        rowsInput[index][name] = value;
        setgiorni(rowsInput);
    }

    function handleChangeAlimenti(index, alimenti){
        console.log('handleChangeAlimenti')
        const rowsInput = [...giorni];
        rowsInput[index]['alimenti'] = alimenti;
        setgiorni(rowsInput);
    }

    function salvascheda(){
        // giorni.map(giorno =>{
        //     console.log(giorno.giorno)
        //     console.log(giorno.alimenti)
        // })
        async function salvasch(){
            const result = await salvaschedadieta(JSON.stringify(giorni), palestra.id,0, data, anagrafica.id)
            if (result.id>0){
                setkeytorefresh(new Date().getTime())
            }
        }
        salvasch()
    }

    function caricalistaalimenti(){
        async function caricalista(){
            const result = await getlistaalimenti(palestra.id)

            //let listaalimentiselecttemp=[]
            //result.map(alim =>listaalimentiselecttemp.push({name:alim.nome, value:alim.id}) )
            setlistaalimentiselect(result)
        }
        caricalista()
    }

    useEffect(() => {
        caricalistaalimenti()
       
    },[])

    return(
        <div className="container-fluid">
            <h4>Nuova Scheda</h4>
            <div className="row">
            <Text bootstrapsize={"col-md-4"} id={"data"} label={"Anagrafica"} value={anagrafica.nome + anagrafica.cognome} />
            </div>
            <div className="row">
                <InputText bootstrapsize={'col-md-4'}
                id={'data'} label={'Data'} name={'data'} 
                placeholder={'Data'} onChange={(e) => setdata(e.target.value)} type={'date'}
                value={data} 
                 />
            </div>
            <div className="row">
            <button className="btn btn-primary" onClick={salvascheda}>Avanti</button>
            </div>
        </div>
    );
}