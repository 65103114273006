import React from "react";
import axios from 'axios';
import {connect} from 'react-redux'
import {navigate} from 'hookrouter'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faSave, faTrash} from '@fortawesome/free-solid-svg-icons'
import serverInfo from "../util/globals";

class RiquadroDettAnagrafica extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        anagrafica:null,
        risultato:'',
            istesserato:0
        };
        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.caricaAnag = this.caricaAnag.bind(this);
        this.elimina = this.elimina.bind(this);
        this.setSpecialCode= this.setSpecialCode.bind(this);
    }

    componentDidMount(){
        console.log("RiquadroDettAnagrafiche 1")
        this.setState({id: this.props.idanagselezionata});
       
        this.caricaAnag();
        console.log("fine RiquadroDettAnagrafiche")
	  }

      setSpecialCode(e) {
        this.setState({ code: "HCS"+Math.floor(Date.now()/1000) });
    }
  
    componentDidCatch(error, info) {
          console.log(error, info);
        }
  
    update(e) {
        if (e.target.name==='istesserato'){
            if (e.target.checked) {
                this.setState({ [e.target.name]: 1, visible: true });
            }
            else{
                this.setState({ [e.target.name]: 0, visible: true });
            }
        }
        else{
            this.setState({ [e.target.name]: e.target.value, visible: true });
        }
    }

    caricaAnag(){
        if (!this.props.idanagselezionata){
            alert('Id anagrafica non specificato')
            return
        }
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('id', this.props.idanagselezionata);
      axios.post(serverInfo.namesec+'/user/getDettaglioAnagraficaSelezionata', formData,
      {
          withCredentials:true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      })
        .then((result) => {
			console.log(result.data)
        	this.setState({...result.data});
        });
	  }

      elimina(){
        if (window.confirm("Sei sicuro di voler CANCELLARE l'anagrafica selezionata?")){
            const formData = new FormData();
            formData.append('website', global.config.domain);
            formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
            formData.append('id', this.state.id);
            axios.post(global.config.server+'/eliminaAnagSelezionata', formData, 
            {
                withCredentials:true,
              headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization',
                'Access-Control-Allow-Credentials': 'true',
                'Authorization' :'Bearer '+window.$t
              },
            })
            .then((result) => {
                //console.log(result.data)
                alert(result.data)
                //window.location.href="/";
                navigate('/annotatio-anag/',true)
            });
        }
	  }

      handleSubmit(e){
          e.preventDefault()
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('id', this.state.id);
        formData.append('code', this.state.code);
        formData.append('emailanag', this.state.email);
        formData.append('nome', this.state.nome);
        formData.append('cognome', this.state.cognome);
        formData.append('sesso', this.state.sesso);
        formData.append('datanascita', this.state.datanascita);
        formData.append('luogonascita', this.state.luogonascita);
        formData.append('codicefiscale', this.state.codicefiscale);
        formData.append('indirizzo', this.state.indirizzo);
        formData.append('citta', this.state.citta);
        formData.append('cap', this.state.cap);
        formData.append('telefono', this.state.telefono);
        formData.append('cognomeNomeGenitore', this.state.cognomeNomeGenitore);
        formData.append('dataNascitaGenitore', this.state.dataNascitaGenitore);
        formData.append('codiceFiscaleGenitore', this.state.codiceFiscaleGenitore);
        formData.append('indirizzoGenitore', this.state.indirizzoGenitore);
        formData.append('cittaGenitore', this.state.cittaGenitore);
        formData.append('capGenitore', this.state.capGenitore);

        axios.post(serverInfo.namesec+'/user/modAnagraficaSelezionata', formData,
        {
            withCredentials:true,
          headers: {
            Accept: 'application/json',
            'Content-Type': 'multipart/form-data',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'POST',
            'Access-Control-Allow-Headers': 'Content-Type, Authorization',
            'Access-Control-Allow-Credentials': 'true',
            'Authorization' :'Bearer '+window.$t
          },
        },)
        .then((result) => {
			console.log(result.data)
        	alert(result.data)
        });
	  }

    render() {
      return (
        <div>
            <h3>Dettaglio Anagrafica</h3>
        <form id="formAnag" onSubmit={this.handleSubmit}>
            <button className="btn btn-primary">
            <FontAwesomeIcon icon={faSave} /> Salva
            </button>
            <a href="#" className="btn btn-danger" onClick={this.elimina}>
            <FontAwesomeIcon icon={faTrash} /> Elimina
			</a>
          <div className="row">
            <div className="col-1">
                <input type="text" className="form-control" name="id" value={this.state.id} readOnly="readonly" />
            </div>
        
                    
            <div className="col">
                <label>Codice per apertura tornello</label>
                <input type="text" id="code" name="code" value={this.state.code}  onChange={this.update}/>
                <input type="button" onClick={this.setSpecialCode} value="Associa un codice speciale (no APP)" />
                <br />
                <label>Codice nuova app <strong>{this.state.applogin?this.state.applogin.codice+' - confermato: '+(this.state.applogin.confermato===1?'SI':'NO')+' - data richiesta codice: '+this.state.applogin.data:"Nessuna login con nuova app"}</strong></label>
            </div>
        </div>
        
        <div className="row">
            <div className="col-md-4">
            <div className="form-group">
            <label className="control-label">Nome</label>
            <input placeholder="Nome" className="form-control" type="text" size="15" name="nome" value={this.state.nome} required="required" onChange={this.update}/>
            </div>
            </div>
            
            <div className="col-md-4">
            <div className="form-group">
            <label className="control-label">Cognome</label>
            <input placeholder="Cognome" className="form-control" type="text" size="14" name="cognome" value={this.state.cognome} required="required" onChange={this.update}/>
            </div>
            </div>
            <div className="col-md-1">
            <div className="form-group">
            <label className="control-label">Sesso</label>
            <select className="form-control" name="sesso" placeholder="sesso" value={this.state.sesso}  onChange={this.update}>
                    <option></option>
                    <option value="M">M</option>
                    <option value="F">F</option>
                    </select>
            </div>
            </div>
            
        </div>
        <div className="row">
        <div className="col-md-4">
            <div className="form-group">
               <label>Data di nascita <small className="text-muted">yyyy-mm-dd</small></label>
               <input type="date" name="datanascita" className="form-control date-inputmask" id="date-mask" placeholder="Enter Date" value={this.state.datanascita} required="required" onChange={this.update}/>
           </div>
           <div className="form-group">
               <label>Luogo nascita</label>
               <input type="text" name="luogonascita" className="form-control" placeholder="Luogo nascita" value={this.state.luogonascita} onChange={this.update}/>
           </div>
            </div>
            <div className="col-md">
            <div className="form-group">
            <label className="control-label">Codice fiscale</label>
            <input type="text" className="form-control" name="codicefiscale" maxLength="16" value={this.state.codicefiscale}  onChange={this.update}/>
            </div>
            </div>
        </div>
        <div className="row">
            <div className="col-md-6">
            <div className="form-group">
            <label className="control-label">Indirizzo</label>
            <input placeholder="via" type="text" name="indirizzo" className="form-control" value={this.state.indirizzo} maxLength="255" onChange={this.update}/>
            </div>
            </div>
            <div className="col-md-4">
            <div className="form-group">
            <label className="control-label">Città</label>
            <input className="form-control" placeholder="Citt&agrave;" type="text" name="citta" value={this.state.citta} maxLength="100" onChange={this.update}/>
            </div>
            </div>
            <div className="col-md">
            <div className="form-group">
            <label className="control-label">CAP</label>
            <input className="form-control" type="text" size="8" maxLength="5" name="cap" value={this.state.cap} onChange={this.update}/>
            </div>
            </div>
        </div>
        <div className="row">
        <div className="col-md">
        <div className="form-group">
        <label className="control-label">Tel</label>
        <input className="form-control" type="tel" value={this.state.telefono} maxLength="24" name="telefono" required="required" placeholder="Telefono" onChange={this.update}/>
        </div></div>
        <div className="col-md">
        <div className="form-group">
        <label className="control-label">Email</label>
        <input className="form-control" type="email" name="email" value={this.state.email} placeholder="Email" onChange={this.update}/>
        </div>
        </div>
        </div>
        
        
        <div className="row">
        <div className="col-md">
        <h4>Eventuali dati del genitore/tutore</h4>
        </div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <label className="control-label">Cognome e Nome</label>
        <input className="form-control" type="text" name="cognomeNomeGenitore" value={this.state.cognomeNomeGenitore} onChange={this.update}/>
        </div>
        <div className="col-md">
            <div className="form-group">
            <label>Data nascita <small className="text-muted">yyyy-mm-yy</small></label>
            <input type="date" className="form-control date-inputmask" id="date-mask" placeholder="Data nascita genitore" name="dataNascitaGenitore" value={this.state.dataNascitaGenitore} onChange={this.update}/>
        </div>
            </div>
        <div className="col-md-4">
        <div className="form-group">
        <label className="control-label">Codice fiscale/P. IVA</label>
        <input className="form-control" type="text" name="codiceFiscaleGenitore" value={this.state.codiceFiscaleGenitore} onChange={this.update}/>
        </div></div>
        </div>
        <div className="row">
        <div className="col-md-6">
        <div className="form-group">
        <label className="control-label">Indirizzo</label>
        <input className="form-control" type="text" name="indirizzoGenitore" value={this.state.indirizzoGenitore} onChange={this.update}/>
        </div>
        </div>
        <div className="col-md-4">
        <div className="form-group">
        <label className="control-label">Città</label>
        <input className="form-control" type="text" name="cittaGenitore" value={this.state.cittaGenitore} onChange={this.update}/>
        </div>
        </div>
        <div className="col-md">
        <div className="form-group">
        <label className="control-label">CAP</label>
        <input className="form-control" type="text" name="capGenitore" value={this.state.capGenitore} onChange={this.update}/>
        </div>
        </div>
        </div>
        
        </form>
        </div>
        );
    }
  }

  const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
        idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(RiquadroDettAnagrafica)