import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import { useSelector } from "react-redux";
import serverInfo from "../util/globals";
export default function PeriodiSelect(props){
	const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
	const email = useSelector(state => state.gestoreUtente.emailloggato);
	const token= useSelector(state => state.gestoreUtente.token)
	
	const [periodi, setperiodi] = useState(props.periodi)
	 
	 useEffect(() =>{
		 
		if (!periodi){
			const formData = new FormData();
			formData.append('email',email);
			formData.append('website', global.config.domain);
			formData.append('palestra',palestraanagrafica.id);
			formData.append('lic','000');
			axios.post(serverInfo.namesec+"/arch/getPeriodi",formData,
			{
				withCredentials: true,
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+window.$t
				},
			  },)
			.then(response=>{
				console.log(response.headers);
				console.log("Periodi: ")
				console.log(response.data)
				setperiodi(response.data)
			})
			.catch(err => {
				alert("Periodi: "+err?err.response.data.message:err);
			});
		}
	});

	    return (
			<select className="form-control" onChange={props.update} name="periodo" value={props.selected?props.selected:""}>
				<option key={-1} value="">-- Periodi --</option>
				{
					periodi&&Array.isArray(periodi)&&periodi.length>0?
					periodi.map((item,index) =>(
					<option key={index} value={item.id} >{item.descrizione}</option>
					)
					)
					:null
	    		}
			</select>
	    );
	}