import React, { useState } from "react";
import '../../util/config'
import { useSelector } from "react-redux";
import FileBase64 from 'react-file-base64';

import NomiCalendariSelect from "../corsi/NomiCalendariSelect";
import { aggiornaTipoProdotto, eliminaTipoProdotto } from "../../DAO/tipiprodottiDAO";
import SelectText from "../../util/UI/SelectText";
import InputText from "../../util/UI/InputText";

export default function TipologiaInput(props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

		const [nome, setnome] = useState(props.selected.nome);
		const [descrizione, setdescrizione] = useState(props.selected.descrizione);
		const [nomecalendario, setnomecalendario] = useState(props.selected.nomecalendario);
		const [foto, setfoto] = useState(props.selected.foto);

		const [file, setfile] = useState(null);
		const [visible, setvisible] = useState(false);
		const [messaggio, setmessaggio] = useState(null);
		const [visibile, setvisibile] = useState(props.selected.visibile);
		const [ordine, setordine]= useState(props.selected.ordine);
		const [visualizzazione, setvisualizzazione]= useState(props.selected.visualizzazione);
		const listaTipoVisualizzazione=[{name:'calendario',value:'calendario'},{name:'lista',value:'menu'},{name:'menu composto',value:'menucomposto'}]

		const listaVisibileUtenti=[{name:'SI',value:1},{name:'NO',value:0}]
		
		const [salvataggio, setsalvataggio] = useState(false)

	function handleInputChange(file){
		setfile({
			base64:file.base64,
			name:file.name,
			type:file.type
		})
	}

	function salva(){
        async function salvaTipo(){
            const result = await aggiornaTipoProdotto(email, palestra.palestra.id,
                props.selected.id, nome, descrizione, nomecalendario,visibile, ordine, foto, file, visualizzazione);
            setmessaggio(result);
			setsalvataggio(false)
        }
		setsalvataggio(true)
		setmessaggio("Attendere prego...")
        salvaTipo();
    }

	function elimina() {
        async function eliminaTipo() {
            const message = await eliminaTipoProdotto(props.selected.id, email, palestra.palestra.id);
            alert(message);
            props.setkeytorefresh(new Date().getTime())
          }
        if (window.confirm("Sei sicuro di voler eliminare la tipologia "+nome+"?")){
            eliminaTipo();
		}
      }

	    return (
			<form className="form-horizontal" method="post" action="/aggiornaAbbonamento">
						<span>{messaggio}</span>
						<div className="row">
							<div className="col-md-3">
								<label htmlFor="nome">Nome Tipologia</label>
								<input className="form-control" id="nome" type="text" onChange={(event)=>setnome(event.target.value)} maxLength="50" name="nome" placeholder="nome categoria" value={nome} required="required" />
							</div>
							<div className="col-md-5">
								<label htmlFor="descr">Descrizione</label>
								<input className="form-control" id="descr" type="text" onChange={(e)=>setdescrizione(e.target.value)} maxLength="50" name="descrizione" placeholder="Descrizione categoria" value={descrizione} required="required" />
							</div>
							<div className="col-md-4">
								<label>Nome Calendario (che conterrà i corsi di questa tipologia)</label>
								<NomiCalendariSelect onChange={setnomecalendario} selected={nomecalendario} nomi={props.nomicalendario} />
							</div>
							</div>
							<div className="row">
							<SelectText bootstrapsize={"col-md-3"} 
							label={"Visibile agli utenti"}
							placeholder={"Visibile agli utenti"}
							lista={listaVisibileUtenti}
							value={visibile}
							onChange={(e) => setvisibile(e.target.value)}/>
							<InputText bootstrapsize={"col-md-3"}
							label={"Ordine"}
							onChange={(event) => {setordine(event.target.value)}}
							placeholder={"Ordine"}
							type={"number"} value={ordine} />
							
							<SelectText bootstrapsize={"col-md-6"} 
							label={"Tipo di visualizzazione prodotti"}
							placeholder={"Tipo di visualizzazione prodotti"}
							lista={listaTipoVisualizzazione}
							value={visualizzazione}
							onChange={(e) => setvisualizzazione(e.target.value)}/>
							
							<div className="col-md">
							<img src={foto} style={{maxWidth:'100px'}} />
							<input type="text" name="foto" value={foto} style={{maxWidth:'100px'}} onChange={(e) => setfoto(e.target.value)} />
							<FileBase64 multiple={ false } onDone={(file) => handleInputChange(file)} />
							</div>
										<div className="col-xs-12 col-sm-12 col-md-2 col-lg-2">
						  				{!salvataggio&&<a type="button" className="btn btn-primary" onClick={salva} style={{color:'white'}}>Salva modifiche</a>}
										{messaggio}
						  				</div>
							<div className="col-xs-12 col-sm-12 col-md-1 col-lg-1">
								<a className="btn btn-danger" style={{color:"white"}} onClick={elimina}>Elimina</a>
							</div>
						</div>
						
					<div className="row">
					<hr style={{borderTop: '8px solid #bbb', borderRadius: '5px', marginBottom:'25px'}} />
					</div>
					</form>
	    );
}