import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function ScontoInput(props) {

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagrafica = useSelector(state => state.gestoreUtente.anagrafica);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);

	const [id, setid] = useState(props.selected.id);
	const [descrizione, setdescrizione] = useState(props.selected.descrizione);
	const [valore, setvalore] = useState(props.selected.valoreSconto);
	const [visible, setvisible] = useState(false);
	const [messaggio, setmessaggio] = useState();
	const [numutenti, setnumutenti] = useState(props.selected.numutenti);
	const [validoda, setvalidoda] = useState(props.selected.validoda);
	const [validoa, setvalidoa] = useState(props.selected.validoa);

	 function handleSubmit(event) {
		event.preventDefault();

	    const formData = new FormData();
		formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('valoreSconto', valore);
		formData.append('numutenti', numutenti);
		formData.append('validoda', validoda);
		formData.append('validoa', validoa);
		formData.append('website', global.config.domain);
        formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		  axios.post(global.config.server+'/aggiornaScontoRest/'+id,formData)
	      .then((result) => {
		        setmessaggio(result.data);
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          props.refresh(new Date().getTime());
		          setvisible(false);
	      });
	  }

	function elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare lo sconto selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		  axios.post(global.config.server+'/eliminaScontoRest/'+id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
						props.refresh(new Date().getTime());
			         setvisible(false);
			        });
			}
	  }

		var style1={
				color:"white"
		}

	    return (
			<form className="form-horizontal">
	    		<div className="row">
						<span>{messaggio}</span>
						<div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
							<label className="control-label">Descrizione</label>
							<input className="form-control" type="text" onChange={(e)=>setdescrizione(e.target.value)} 
							name="descrizione" value={descrizione} required="required" />
						</div>
						<div className="col">
							<label className="control-label">% Sconto</label>
							 <input className="form-control" type="number" onChange={(e)=> setvalore(e.target.value) } name="valore" value={valore} required="required" />
						</div>
						<div className="col">
							<label className="control-label">Num max volte utilizzabile</label>
							 <input className="form-control" type="number" onChange={(e)=> setnumutenti(e.target.value) } 
							 name="numutenti" value={numutenti} required="required" />
						</div>
						<div className="col">
							<label className="control-label">Valido dal</label>
							 <input className="form-control" type="date" onChange={(e)=> setvalidoda(e.target.value) } 
							 name="validoda" value={validoda} required="required" />
						</div>
						<div className="col">
							<label className="control-label">Valido al</label>
							 <input className="form-control" type="date" onChange={(e)=> setvalidoa(e.target.value) } 
							 name="validoa" value={validoa} required="required" />
						</div>
						<div className="col">
				  			<a type="button" className="btn btn-primary" onClick={handleSubmit} style={style1}>Salva modifiche</a>
				  		</div>
						  <div className="col">
				  			<a type="button" className="btn btn-primary" onClick={elimina} style={style1}>Elimina</a>
				  		</div>
		  		</div>
			</form>
	    );
	}