import {configureStore} from '@reduxjs/toolkit';
import gestoreOrdiniReducer from './reducers/gestoreOrdini';
import gestoreUtentiReducer from './reducers/gestoreUtente';
import gestoreTipiProdottoReducer from './reducers/gestoreTipiProdotto';
import gestoreFattureReducer from './reducers/gestoreFattura';
import gestoreDocumentiReducer from './reducers/gestoreDocumenti';

export default configureStore({
  reducer: {
    gestoreOrdini: gestoreOrdiniReducer,
    gestoreUtente: gestoreUtentiReducer,
    gestoreFatture: gestoreFattureReducer,
    gestoreTipiProdotto: gestoreTipiProdottoReducer,
    gestoreDocumenti: gestoreDocumentiReducer,
  },
});
