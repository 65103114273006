import React from "react";
import axios from 'axios';
import '../util/config'
import RicercaSchedaCCRiga from "./RicercaSchedaCCRiga";
import {connect} from 'react-redux'

export class RicercaSchedaCC extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            descrscheda:'',
            cognomenome:'',
               anagrafiche:[],
               nuovaSchedaVisible:false
	    	}
        this.update = this.update.bind(this);
        this.cercaScheda = this.cercaScheda.bind(this);
	  }

	componentDidMount(){
        
    }

    showNuovaScheda(){
        this.setState({nuovaSchedaVisible:!this.state.nuovaSchedaVisible})
    }

    cercaScheda(e) {
        e.preventDefault()
        const { cognomenome} = this.state;
        if (cognomenome===''){
            alert('Specificare almeno un filtro')
            return
        }
	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','00000');
        formData.append('palestra',this.props.palestra.id);
          formData.append('term', cognomenome);

	      axios.post(global.config.server+'/cercaAnagraficaCCDA',formData)
	      .then((result) => {
            console.log(result.data)
		        this.setState({anagrafiche:result.data})
	      });
    }

    

	update(e) {
          this.setState({ [e.target.name]: e.target.value});
    }
    
	render() {
	    return (
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="collapse navbar-collapse" id="navbarSupportedContent22">
                    <form className="form-inline mr-auto">
                    <input className="form-control" name="cognomenome" onChange={this.update} value={this.state.cognomenome} type="text" placeholder="assegnata a" aria-label="Search" />
                    <button className="btn btn-mdb-color btn-rounded btn-sm my-0 ml-sm-2" onClick={this.cercaScheda}>Cerca</button>
                    </form>
                </div>
                <a class="navbar-brand" onClick={this.cercaScheda}>Cerca scheda</a>
                </nav>
                                    
          <article class="post">
          {this.state.anagrafiche&&this.state.anagrafiche.length>0?
                    <span>trovate {this.state.anagrafiche.length} Anagrafiche</span>
                    :null}
        <div class="table-responsive">
            <table id="zero_config" class="table table-striped table-bordered">
                <thead>
                <tr><th>Anagrafica</th><th>Ultimo aggiornamento scheda</th>
                </tr>
                </thead>
                <tbody>
                {this.state.anagrafiche&&this.state.anagrafiche.length>0?
                    this.state.anagrafiche.map(anag=>(
                    <RicercaSchedaCCRiga anag={anag} link={this.props.link} setview={this.props.setview} />
                ))
                :<tr><td colspan="6">Nessuna anagrafica trovata</td></tr>
                }
                </tbody>
                </table>
        </div>
                    </article>
                    
                    <hr /> 
        
        <article class="post">
        <div class="entry-content">
        
        </div>
         </article>
        
                </div>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }

    export default connect(mapStateToProps, null)(RicercaSchedaCC)