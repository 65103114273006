import axios from 'axios';
import '../util/config'
import AnnotatioAlert from '../util/UI/AnnotatioAlert';

export async function getPlaces(idtipo) {
  const formData = new FormData();
  formData.append('idtipo', idtipo);
  const response = await axios.post(
    global.config.server+'/getPlacesPerTipo',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}

export async function getAllPlaces(palestra) {
  const formData = new FormData();
  formData.append('palestra', palestra);
  formData.append('website', 'annotatio.online');
  const response = await axios.post(
    global.config.server+'/getPlaces',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log('places ' + response.data);

  return response.data;
}

export async function getTipi(palestra, filtroidtipi, filtrolnomicalendario, visibili) {
  const formData = new FormData();
  formData.append('palestra', palestra);
  if (filtroidtipi){
    formData.append('idtipi', filtroidtipi);
  }
  if (filtrolnomicalendario){
    formData.append('nomicalendario', filtrolnomicalendario); //lista di nomi di calendario completi e non parziali
  }
  if (visibili&&visibili===1){
    formData.append('visibile', visibili);
  }
  if (visibili&&visibili===0){
    formData.append('visibile', visibili);
  }
  const response = await axios.post(global.config.server+'/getTipiCorsoFilter',
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  //console.log(response);
  return response.data;
}

export async function aggiornaTipoProdotto(email, palestra, idtipo, nome, descrizione, nomecalendario, visibile, ordine, foto, file,visualizzazione){

  const formData = new FormData();
  formData.append('id', idtipo);
  formData.append('nome', nome);
  formData.append('descrizione', descrizione);
  formData.append('nomecalendario', nomecalendario?nomecalendario:'');
  formData.append('visibile', visibile&&visibile===1?"1":visibile===0||visibile==='0'?"0":"1");
  formData.append('website', 'annotatio.online');
  formData.append('ordine', ordine?ordine:100);
  formData.append('visualizzazione', visualizzazione?visualizzazione:'calendario');
  formData.append('foto', foto?foto:'');
  //formData.append('fotoTemp', file?(Platform.OS === 'ios' ? file.uri.replace('file://', '') : file.uri):'');
  formData.append('filename', file&&file.name?file.name:'');
  formData.append('filetype', file&&file.type?file.type:'');
  formData.append('fotoTemp', file&&file.base64?file.base64:'');
  formData.append('email',email);
  formData.append('lic','00000000');
  formData.append('palestra',palestra);
  try{
      const response = await axios.post(global.config.server+'/aggiornaTipologiaCorsoRest/'+idtipo,
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    //console.log(response);
    AnnotatioAlert({icon:'success'});
    return response.data;
  }
  catch(err) {
        AnnotatioAlert({
        icon:"error",
        position:"center",
        title:err})
    };
}


export async function getNomiCalendari(email, palestra){
  const formData = new FormData();
  formData.append('email',email);
  formData.append('website', 'annotatio.online');
  formData.append('palestra',palestra);
  formData.append('lic','00000000');
  const response = await axios.post(global.config.server+"/getNomiCalendari",
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
    },
  },
);

return response.data;
}

export async function eliminaTipoProdotto(idtipo, email, palestra){
  const formData = new FormData();
	formData.append('id', idtipo);
	formData.append('website', 'annotatio.online');
	formData.append('email',email);
	formData.append('lic','00000000');
	formData.append('palestra',palestra);
	const response = await axios.post(global.config.server+'/eliminaTipologiaCorsoRest/'+idtipo,
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
    },
  },
);

return response.data;
}