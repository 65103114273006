import React from "react";
import '../../util/config'

export default class CategorieSelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
              selezionato:this.props.selected
      };
      this.aggiornaSelezionato = this.aggiornaSelezionato.bind(this);
    }
  
      componentDidMount(){
  
      }
    
      aggiornaSelezionato(e){
           this.props.updateCategoria(this.props.categorie[e.target.selectedIndex-1]);
       }
  
    render() {
      return (
              <div>
                  <label className="control-label">Categoria</label>
                  <select className="form-control" onChange={this.aggiornaSelezionato} name="categoria" value={this.props.selected?this.props.selected:""}>
                      <option key={-1} value="">--nessuna categoria--</option>
                      {
                          this.props.categorie.map((item,index) =>(
                          <option key={index} value={item} >{item}</option>
                          )
                          )
                      }
                  </select>
              </div>
      );
    }
  }