import React, { useEffect } from "react";
import { GraficoGauge } from "./GraficoGauge";
import { useSelector } from 'react-redux';
import { useState } from "react";
import axios from 'axios';
import serverInfo from "../../util/globals";
import AnnotatioAlert from "../../util/UI/AnnotatioAlert";

let timeout=0; 
export default function Plicometria(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata)

    const [id ,setid] = useState(0)
    const [altezza ,setaltezza] = useState(0)
   const [peso ,setpeso] = useState(0)
   const [bmi ,setbmi] = useState(0)
   const [plbicipite ,setplbicipite] = useState(0)
   const [pltricipite ,setpltricipite] = useState(0)
   const [plsottoscapolare ,setplsottoscapolare] = useState(0)
   const [plsovrailiaca ,setplsovrailiaca] = useState(0)
   const [pltotale, setpltotale] = useState(0)
   const [circvita ,setcircvita] = useState(0)
   const [circfianchi ,setcircfianchi] = useState(0)
   const [percmassagrassa ,setpercmassagrassa] = useState(0)
   const [massagrassa ,setmassagrassa] = useState(0)
   const [massamagra ,setmassamagra] = useState(0)
   const [data ,setdata] = useState(new Date().toISOString().slice(0, 10))
   const [modificato ,setmodificato] = useState(false)

   function salvaScheda() {

     if (percmassagrassa===''||percmassagrassa==='null'
     ||parseInt(percmassagrassa)<0){
         alert("Inserire la percentuale di massa grassa")
         return
     }

    const formData = new FormData();
    formData.append('website', 'annotatio.online');
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',palestra.id);
    formData.append('id', id);
    formData.append('data', data);

    formData.append('idanagrafica',anagselezionata.id);

   formData.append('plbicipite',plbicipite?plbicipite:0);
   formData.append('pltricipite',pltricipite?pltricipite:0);
   formData.append('plsottoscapolare',plsottoscapolare?plsottoscapolare:0);
   formData.append('plsovrailiaca',plsovrailiaca?plsovrailiaca:0);
   formData.append('circvita',circvita?circvita:0);
   formData.append('circfianchi',circfianchi?circfianchi:0);
   formData.append('percmassagrassa',percmassagrassa?percmassagrassa:0);
   formData.append('massagrassa',massagrassa?massagrassa:0);
   formData.append('massamagra',massamagra?massamagra:0);
   formData.append('idutente',email);

      axios.post(serverInfo.name+'/aggiornaSchedaCC',formData)
      .then((result) => {
        AnnotatioAlert({icon:'success'});
        
      })
      .catch(err => {
        alert(err)
            AnnotatioAlert({
            icon:"error",
            position:"center",
            title:err})
        });
}

   function update(e) {
  
        if (e.target.name==='plbicipite'
        ||e.target.name==='pltricipite'
        ||e.target.name==='plsottoscapolare'
        ||e.target.name==='plsovrailiaca'){
            setpltotale(parseInt(plbicipite)+parseInt(pltricipite)+parseInt(plsottoscapolare)+parseInt(plsovrailiaca))
        }
    }

    useEffect(() => {
        if (!modificato) {
             setmodificato(true)
          } else {
             clearTimeout(timeout); 
             timeout = setTimeout(() => {
             salvaScheda()
             },1000) 
         }
     },[
         plbicipite,
         pltricipite,
         plsottoscapolare,
         plsovrailiaca,
         circvita,
         circfianchi,
         percmassagrassa,
         massagrassa,
         massamagra
     ])

    useEffect( () =>{
        const formData = new FormData();
        formData.append('website', 'annotatio.online');
        formData.append('email',email);
        formData.append('lic','000');
        formData.append('palestra',palestra.id);
        formData.append('idanagrafica',anagselezionata.id);
        axios.post(serverInfo.name+"/getUltimaSchedaCC", formData)
        .then(response=>{
            setaltezza(response.data.altezza)
            setbmi(response.data.bmi)
            setcircfianchi(response.data.circfianchi)
            setcircvita(response.data.circvita)
            setdata(response.data.data)
            setid(response.data.id)
            setmassagrassa(response.data.massagrassa)
            setmassamagra(response.data.massamagra)
            setpercmassagrassa(response.data.percmassagrassa)
            setpeso(response.data.peso)
            setplbicipite(response.data.plbicipite)
            setplsottoscapolare(response.data.plsottoscapolare)
            setplsovrailiaca(response.data.plsovrailiaca)
            setpltricipite(response.data.pltricipite)
            
            setpltotale(parseInt(response.data.plbicipite)+parseInt(response.data.pltricipite)+parseInt(response.data.plsottoscapolare)+parseInt(response.data.plsovrailiaca))
            setmodificato(false)
        });
    }
    ,[])
    
    return(
        <div>
            <div className="row">
                <h4>Plicometria</h4>
            </div>
            <div className="row form-group">
                <div className="col-md-2 form-floating">
                    <label htmlFor="plbicipite">Bicipite cm</label>
                    <input className="form-control" id="plbicipite" type="number" min={0} name="plbicipite" placeholder="bicipite" value={plbicipite} onChange={(e) => {update(e); setplbicipite(e.target.value)}} step={1} />
                </div>

                <div className="col-md-2 form-floating">
                    <label htmlFor="pltricipite">Tricipite cm</label>
                    <input className="form-control" id="pltricipite" type="number" min={0} name="pltricipite" placeholder="tricipite" value={pltricipite} onChange={(e) => {update(e); setpltricipite(e.target.value)}} step={1} />
                </div>
                <div className="col-md-2 form-floating">
                    <label htmlFor="plsottoscapolare">Sottoscapolare cm</label>
                    <input className="form-control" id="plsottoscapolare" type="number" min={0} name="plsottoscapolare" placeholder="sottoscapolare" value={plsottoscapolare} onChange={(e) => {update(e); setplsottoscapolare(e.target.value)}} step={1} />
                </div>
                <div className="col-md-2 form-floating">
                    <label htmlFor="plsovrailiaca">Sovrailiaca cm</label>
                    <input className="form-control" id="plsovrailiaca" type="number" min={0} name="plsovrailiaca" placeholder="sovrailiaca" value={plsovrailiaca} onChange={(e) => {update(e); setplsovrailiaca(e.target.value)}} step={1} />
                </div>
            
                <div className="col-md-4 form-floating">
                    <label htmlFor="pltotale">Somma Pliche cm</label>
                    <input className="form-control" id="pltotale" type="number" min={0} name="pltotale" placeholder="plicometria totale" value={pltotale} readOnly={true} />
                </div>
            </div>

            <div className="row form-group">
                <div className="col-4">
                    <label>Circ. Vita
                    <input className="form-control" type="number" name="circvita" value={circvita} onChange={(e) => {setcircvita(e.target.value)}} step={.01} />
                    </label>
                </div>
                <div className="col-4">
                    <label>Circ. Fianchi
                    <input className="form-control" type="number" name="circfianchi" value={circfianchi} onChange={(e) => {setcircfianchi(e.target.value)}} step={.01} />
                    </label>
                </div>
                <div className="col-4">
                <GraficoGauge
                valore={parseFloat(circvita/circfianchi)} 
                label="WHR"
                redFrom={anagselezionata.sesso==='M'?0.95:0.85}
                redTo={2}
                greenFrom={0}
                greenTo={anagselezionata.sesso==='M'?0.95:0.85}
                max={2}
                min={0}
                />
                </div>
            </div>
            <div className="row form-group">
                <div className="col-4">
                    <label>Massa Grassa %
                    <input className="form-control" type="number" name="percmassagrassa" value={percmassagrassa} onChange={(e) => setpercmassagrassa(e.target.value)} step={.01} />
                    </label>
                </div>
                <div className="col-4">
                    <label>Massa Grassa kg
                    <input className="form-control" type="number" name="massagrassa" value={massagrassa} onChange={(e) => setmassagrassa(e.target.value)} step={.01} />
                    </label>
                </div>
                <div className="col-4">
                    <label>Massa Magra kg
                    <input className="form-control" type="number" name="massamagra" value={massamagra} onChange={(e) => setmassamagra(e.target.value)} step={.01} />
                    </label>
                </div>
            </div>
            
    {
    anagselezionata.sesso==='D'?
        <div className="row">
            <div className="row" style={{backgroundColor:(percmassagrassa>0&&percmassagrassa<14?'yellow':'white')}} >
                <div className="col">DONNA GRASSO ESSENZIALE (&lt; 14)</div>
                <div className="col">
                    <img src="https://annotatio.online/wp-content/uploads/2022/07/donnaEssenziale.jpg" 
                style={{opacity:(percmassagrassa>0&&percmassagrassa<14?'1':'0.2'), maxWidth:"150px"}} />
                </div>
            </div>
            <div className="row" style={{backgroundColor:(percmassagrassa>=14&&percmassagrassa<20?'green':'white')}} >
                <div className="col">DONNA ATLETICO (14 - 20)</div>
                <div className="col">
                <img src="https://annotatio.online/wp-content/uploads/2022/07/donnaAtletico.jpg" 
                style={{opacity:(percmassagrassa>=14&&percmassagrassa<20?'1':'0.2'), maxWidth:"150px"}} /></div>
            </div>
            <div className="row" style={{backgroundColor:(percmassagrassa>=20&&percmassagrassa<24?'orange':'white')}} >
                <div className="col">DONNA FITNESS (21 - 24)</div>
                <div className="col"><img src="https://annotatio.online/wp-content/uploads/2022/07/donnaFitness.jpg" 
                style={{opacity:(percmassagrassa>=20&&percmassagrassa<24?'1':'0.2'), maxWidth:"150px"}} /></div>
            </div>
            <div className="row" style={{backgroundColor:(percmassagrassa>=24&&percmassagrassa<31?'red':'white')}} >
                <div className="col">DONNA PROMEDIO (24 - 31)</div>
                <div className="col"><img src="https://annotatio.online/wp-content/uploads/2022/07/donnaPromedio.jpg" 
                style={{opacity:(percmassagrassa>=24&&percmassagrassa<31?'1':'0.2'), maxWidth:"150px"}} /></div>
            </div>
            <div className="row" style={{backgroundColor:(percmassagrassa>=31?'#FF33FF':'white')}} >
                <div className="col">DONNA OBESA (&gt; 31 %)</div>
                <div className="col"><img src="https://annotatio.online/wp-content/uploads/2022/07/donnaPromedio.jpg" 
                style={{opacity:(percmassagrassa>=31?'1':'0.2'), maxWidth:"150px"}} /></div>
            </div>
        </div>
    :
    <div>
        <div className="row">
        <GraficoGauge 
                        valore={parseFloat(percmassagrassa)} 
                        label="% grasso"
                        redFrom="24"
                        redTo="40"
                        yellowFrom="13"
                        yellowTo="24"
                        greenFrom="0"
                        greenTo="23"
                        max="40"
                        min="1"
                        />
            </div>
            <div className="row">
            <figure className="figure" style={{backgroundColor:(percmassagrassa>0&&percmassagrassa<5?'yellow':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoEssenzale.jpg" 
                style={{opacity:(percmassagrassa>0&&percmassagrassa<5?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="GRASSO ESSENZIALE (&lt; 5)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>GRASSO ESSENZIALE<br/>(&lt; 5)</figcaption>
            </figure>
            <figure className="figure" style={{backgroundColor:(percmassagrassa>=5&&percmassagrassa<13?'green':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoAtletico.jpg"
                style={{opacity:(percmassagrassa>=5&&percmassagrassa<13?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO ATLETICO (5 - 13)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>ATLETICO<br/>(5 - 13)</figcaption>
            </figure>
            <figure className="figure" style={{backgroundColor:(percmassagrassa>=13&&percmassagrassa<17?'orange':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoFitness.jpg"
                style={{opacity:(percmassagrassa>=13&&percmassagrassa<17?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO FITNESS (13 - 17)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>FITNESS<br/>(13 - 17)</figcaption>
            </figure>
            <figure className="figure" 
            style={{backgroundColor:(percmassagrassa>=17&&percmassagrassa<24?'red':'white')}}>
                <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoPromedio.jpg" 
                style={{opacity:(percmassagrassa>=17&&percmassagrassa<24?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO PROMEDIO (17 - 24)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>PROMEDIO<br/>(17 - 24)</figcaption>
            </figure>
            <figure className="figure" style={{backgroundColor:(percmassagrassa>24?'#FF33FF':'white')}} >
            <img src="https://annotatio.online/wp-content/uploads/2022/07/uomoObeso.jpg" 
                                style={{opacity:(percmassagrassa>24?'1':'0.2'), maxWidth:"150px"}}
                className="figure-img img-fluid rounded" 
                alt="UOMO OBESO (&gt; 24)" />
                <figcaption className="figure-caption text-center" style={{color:"black"}}>OBESO<br/>(&gt; 24)</figcaption>
            </figure>
        </div>
        </div>}
        </div>
        
    );

}