import React from "react";
import axios from 'axios';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

class DettMovimentoCassa extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        dataOperazione:'',
        descrizione:'',
        entrate:0,
        uscite:0
        };
        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.cancella = this.cancella.bind(this);
    }

    componentDidMount(){
        console.log("DettMovimentoCassa")
        const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
        formData.append('id',localStorage.getItem('idcassa'));
        axios.post(global.config.server+"/dettaglioCassa",formData)
        .then(response=>{
            this.setState({dataOperazione: response.data.dataOperazione});
            this.setState({descrizione: response.data.descrizione});
            this.setState({entrate: response.data.entrate});
            this.setState({uscite: response.data.uscite});
        });
	  }

  
    componentDidCatch(error, info) {
          console.log(error, info);
        }
  
    update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }

      handleSubmit(e){
          e.preventDefault();
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
        formData.append('id',localStorage.getItem('idcassa'));
        formData.append('data',this.state.dataOperazione);
        formData.append('descrizione',this.state.descrizione);
        formData.append('entrate',this.state.entrate);
        formData.append('uscite',this.state.uscite);
        axios.post(global.config.server+'/salvaCassa', formData)
        .then((result) => {
			console.log(result.data)
            if (result.data
                &&!isNaN(result.data)){
                    localStorage.setItem('idcassa',result.data)
                    if (this.props.link){
                        window.location.href='/'+this.props.link
                    }
                    else{
                        alert('Movimento modificato con successo')
                    }
                }
                else{
        	        alert(result.data)
                }
        });
	}

    cancella(){
      const formData = new FormData();
      formData.append('website', global.config.domain);
      formData.append('email',this.props.email);
      formData.append('lic','000');
      formData.append('palestra',this.props.palestra.id);
      formData.append('id',localStorage.getItem('idcassa'));
      axios.post(global.config.server+'/eliminaCassa', formData)
      .then((result) => {
          console.log(result.data)
                  localStorage.setItem('idcassa',null)
                  if (this.props.link){
                    window.location.href='/'+this.props.linkgetPrenotazioni
                    
                  }
                  else{
                    this.props.setview('cassa')
                  }
      });
  }

    render() {
      return (
        <form method="post" onSubmit={this.handleSubmit}>
        <button className="elementor-button elementor-size-sm">
            <span>
                <span className="elementor-button-icon"></span>
                <span className="elementor-button-text" >AGGIORNA</span>
            </span>
        </button>
        <Button className="elementor-button elementor-size-sm" onClick={this.cancella}>
            <span>
                <span className="elementor-button-icon"></span>
                <span className="elementor-button-text" >ELIMINA</span>
            </span>
        </Button>
            <div className="form-group row">
                <label htmlFor="data" className="col-sm-2 form-control-label">Data operazione</label>
                <div className="col-sm-10">
                <input type="date" size="15" 
                maxLength="10" className="form-control" name="dataOperazione" 
                placeholder="Data Operazione" value={this.state.dataOperazione } 
                required="required" 
                onChange={this.update} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="descrizione" className="col-sm-2 form-control-label">Descrizione</label>
                <div className="col-sm-10">
                <input type="text" className="form-control" id="descrizione" name="descrizione" 
                value={this.state.descrizione} placeholder="descrizione del movimento" 
                required="required" 
                onChange={this.update} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="entrata" className="col-sm-2 form-control-label">Entrate</label>
                <div className="col-sm-2">
                <input type="number" className="form-control" 
                id="entrata" name="entrate" value={this.state.entrate} 
                placeholder="Entrate" 
                onChange={this.update} />
                </div>
            </div>
            <div className="form-group row">
                <label htmlFor="uscite" className="col-sm-2 form-control-label">Uscite</label>
                <div className="col-sm-2">
                <input type="number" className="form-control" id="uscite" 
                name="uscite" value={this.state.uscite} 
                placeholder="Uscite" 
                onChange={this.update} />
                </div>
            </div>
            </form>
        );
    }
  }

  const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(DettMovimentoCassa)