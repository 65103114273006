import {createSlice} from '@reduxjs/toolkit';

export const gestoreDocumentiSlice = createSlice({
  name: 'gestoreDocumenti',
  initialState: {
    documenti: [],
    docselezionato:{}
  },
  reducers: {
    setdocumenti: (state, docs) => {
      state.documenti = docs.payload;
    },
    setdocumentoselezionato: (state, doc) => {
      //console.log('settipo ',tipo.payload);
      state.docselezionato = doc.payload;
    },
  },
});

export const {setdocumenti, setdocumentoselezionato} = gestoreDocumentiSlice.actions;

export default gestoreDocumentiSlice.reducer;
