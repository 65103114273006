import React from "react";
import axios from 'axios';
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

class MovimentiSelect extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        movimenti:[]
      };
      this.update = this.update.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

  update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }

    handleSubmit(){
        const formData = new FormData();
		formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
        formData.append('descrizioneMovimento',this.state.descrizioneMovimento);
		axios.post(global.config.server+'/VisualizzaMovimenti',formData)
      .then(response=>{
        this.setState({movimenti: response.data});
        console.log(response);
      });
    }
  
    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
        console.log(error, info);
    }
  
    render() {
      return (
          <span>
          <input type="text" name="descrizioneMovimento" value={this.state.descrizioneMovimento} onChange={this.update} />
          <Button onClick={this.handleSubmit}>Filtra Movimenti</Button>
              <select name="idmovimento" onChange={this.props.update}>
                {this.state.movimenti&&this.state.movimenti.length>0?
              <option value="">-- seleziona un movimento --</option>
              :null
                }
              {
                  this.state.movimenti&&this.state.movimenti.length>0?
                  this.state.movimenti.map(movimento =>(
                  <option key={movimento.id} value={movimento.id} selected={this.props.selezionato===movimento.id}>
                      {movimento.dataOperazione + " " + movimento.nomeBanca+", importo:" +(movimento.entrata>0?movimento.entrata:movimento.uscita)+" "+movimento.descrizione}
                  </option>
                    )
                  )
                  :<option>Nessun movimento presente</option>
              }
              </select>
            </span>
      );
    }
  }

  const mapStateToProps = state => {
		return {
		  palestra: state.gestoreUtente.palestraassociata.palestra,
		  email: state.gestoreUtente.emailloggato,
		  idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	  }
	  
	  export default connect(mapStateToProps, null)(MovimentiSelect)