import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputText from "../../util/UI/InputText";
import MyInput from "../../util/UI/MyInput";
import { savetemplate } from "../../DAO/DocumentiDAO";

export default function NewTemplateDoc(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    
    const [descr, setdescr] = useState('')
    const [html, sethtml] =useState('')
    const [id, setid] =useState(0)

    function savetempl(){
        async function save(){
            const result = await savetemplate(palestra.palestra.id, id, descr, html)
        }
        save()
    }

    
    return(
        <div className="container-fluid">
            <h4>Nuovo template documento</h4>
            <div className="row">
                <InputText bootstrapsize={"col-md-4"}
                id={"descr"}
                label={"descrizione"}
                name={"descr"}
                onChange={(e) => setdescr(e.target.value)}
                placeholder={"Descrizione"}
                type={"text"}
                value={descr}
                 />
            </div>
            <div className="row">
                <MyInput sethtml={sethtml} defaultcontent={html} />
            </div>
        <div>
            <button className="btn btn-primary" onClick={savetempl}>Salva</button>
        </div>
        </div>
               
    );
}