import React, { useState } from "react";
import InputText from "../../util/UI/InputText";
import Text from "../../util/UI/Text";
import Bottone from "../../util/UI/Bottone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashAlt } from "@fortawesome/free-solid-svg-icons";

let filtrati=[]
export default function AlimentoSchedadieta({alimento, handleChangeG, index, listaalimenti, delalimento}){
    const [alim, setalim] = useState('');
    const [alimselected, setalimselected] = useState();
    const [showcerca, setshowcerca] = useState(!(alimento&&alimento.alimentobo));

    function calcolaproporz(riferimento, qta, valore){
        //riferimento:100=qta:X
        if (!riferimento) return valore;
        
        return parseFloat(((Number.parseFloat(qta)/Number.parseFloat(riferimento))*Number.parseFloat(valore))).toFixed(2);
    }

    function filtraalimenti(term){
      setshowcerca(true)
      filtrati=term.length>3&&showcerca&&
        listaalimenti.filter(alitemp =>( 
            alitemp&&
            alitemp.id&&
            
            alitemp.nome.toUpperCase().includes(term.toUpperCase())
                
            )

            )
    }

    return (
        <tr>
             <td>
            {showcerca&&
            <InputText 
               id={'alimento'} label={'Cerca alimento'} name={'alimento'} 
               placeholder={'Seleziona un alimento'} 
               onChange={(e)=>{setalim(e.target.value); filtraalimenti(e.target.value)}}
               value={alim} 
                 />}
                 
            <ul className="col-12">
            {showcerca&& filtrati&&filtrati.map(alitemp =>( 
                    <li key={index} className="" onClick={() => {
                        handleChangeG(index, {target: {name:'alimento', value:alitemp}}); 
                        setshowcerca(false); 
                        setalimselected(alitemp)}}>{alitemp.nome}</li>
                )

                )}
            </ul>
            
            <Text
               label={''} 
               value={alimselected&&alimselected.nome?alimselected.nome:(alimento&&alimento.alimentobo?alimento.alimentobo.nome:'')} 
               onClick={() => setshowcerca(!showcerca)}
                 />
            </td>
            <td>
                <InputText 
                id={'quantita'} label={''} name={'quantita'} 
                placeholder={'Quantita'} onChange={(evnt)=>(handleChangeG(index, evnt))} 
                type={'number'}
                value={alimento.quantita} 
                 />
            </td>
            <td>
                 <Text 
               label={''} 
               value={alimselected&&alimselected.kcal?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.kcal):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.kcal):'0')} 
                 />
                 </td>
            <td>
                 <Text
               label={''} 
               value={alimselected&&alimselected.proteine?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.proteine):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.proteine):'')} 
                 />
                </td>
            <td>
                 <Text 
               label={''} 
               value={alimselected&&alimselected.carboidrati?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.carboidrati):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.carboidrati):'')} 
                 />
                 </td>
            <td>
                 <Text
               label={''} 
               value={alimselected&&alimselected.saturi?calcolaproporz(alimselected.qtarif,alimento.quantita,alimselected.saturi):(alimento&&alimento.alimentobo?calcolaproporz(alimento.alimentobo.qtarif,alimento.quantita,alimento.alimentobo.saturi):'')} 
                 />
                 </td>
                 <td> <Bottone bootstrapsize={"col-md-2"} bootstrapstyle={"danger"} label={<FontAwesomeIcon icon={faTrashAlt} />} onClick={() => delalimento(index)} /></td>

        </tr>
    );
}