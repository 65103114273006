import React from 'react';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';
import 'devextreme/dist/css/dx.light.css';
import config from 'devextreme/core/config';
import Chart, {
  AdaptiveLayout,
  Aggregation,
  CommonSeriesSettings,
  Grid,
  Label,
  Pane,
  Series,
  Size,
  Title,
  Tooltip,
  ValueAxis,
} from 'devextreme-react/chart';

import PivotGrid, {
  FieldChooser
} from 'devextreme-react/pivot-grid';
import { locale } from 'devextreme/localization';

const fullStackedBarSettings = { aggregation: { enabled: true } };

class RepAbbonamenti extends React.Component {
  constructor(props) {
    super(props);
    config({ defaultCurrency: 'EUR',  });
    locale(navigator.language);
    this.state = {
          abbonamenti:[]
    };

}
  componentDidMount() {
    
    this._pivotGrid.bindChart(this._chart, {
      inverted:false,
      dataFieldsDisplayMode: 'splitAxis',
      putDataFieldsInto:'args',
      alternateDataFields:false
    });
    this.setState({abbonamenti:this.props.abbonamenti})
    console.log(this.props.abbonamenti.length)
    this.getDatasource = this.getDatasource.bind(this);
  }

  getDatasource(){
    
    return new PivotGridDataSource({
        fields: [
        {
            caption: 'Listino',
            width: 120,
            dataField: 'listino',
            area: 'row',
            sortBySummaryField: 'Total'
          },
          {
            caption: 'Periodo',
            width: 120,
            dataField: 'periodo',
            area: 'row'
          },
          {
          caption: 'Cliente',
          width: 120,
          dataField: 'causale',
          area: 'row',
          selector: function(data) {
            return `${data.nome } ${ data.cognome } ${ data.datanascita }`;
          }
        },
        {
          dataField: 'validoda',
          dataType: 'date',
          area: 'column'
        }, 
        {
          groupName: 'date',
          groupInterval: 'month',
          visible: true
        }, 
        {
          caption: 'Tot. Fatturato',
          dataField: 'prezzo',
          dataType: 'number',
          summaryType: 'sum',
          format: 'currency',
          area: 'data'
        },
        {
          caption: '% Fatturato',
          dataField: 'prezzo',
          dataType: 'number',
          summaryType: 'sum',
          area: 'filter',
          summaryDisplayMode: 'percentOfColumnGrandTotal',
          
        },
        {
          caption: 'Conteggio',
          dataField: 'prezzo',
          dataType: 'number',
          summaryType: 'count',
          area: 'data'
          
        },],
        store: this.props.abbonamenti
      });
      
      
  }

  render() {
    return (
      <React.Fragment>
        <Chart ref={(ref) => ref?this._chart = ref.instance:null} >
          <Size height={200} />
          <Tooltip enabled={true} customizeTooltip={customizeTooltip} />
          <CommonSeriesSettings type="stackedBar" />
          <AdaptiveLayout width={450} />
        </Chart>
       

        <PivotGrid
          id="pivotgrid"
          dataSource={this.getDatasource()}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={true}
          showColumnGrandTotals={true}
          showRowTotals={true}
          showRowGrandTotals={true}
          ref={(ref) => ref?this._pivotGrid = ref.instance:null}
        >
          <FieldChooser enabled={true} height={400} />
        </PivotGrid>
      </React.Fragment>
    );
  }
}

const currencyFormatter = new Intl.NumberFormat(
    'it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
    }
  );
function customizeTooltip(args) {
  const valueText = currencyFormatter.format(args.originalValue);
  return {
    html: `${args.seriesName} | Total<div class="currency">${valueText}</div>`
  };
}

export default RepAbbonamenti;
