import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { getTessereByAnag } from "../DAO/tessereDAO";
import RigaTessereAnagrafica from "./RigaTessereAnagrafica";

export default function TessereAnagrafica(){
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [tessere, settessere] = useState([])

    function caricatessere(){
        async function gettessereanag(){
            const result = await getTessereByAnag(idanagselezionata)
            settessere(result)
        }
        gettessereanag();
    }

    useEffect(() =>{
        caricatessere();
    },[idanagselezionata])

    return (
        <div>
            <h3>Tesseramenti {anagselezionata.nome} {anagselezionata.cognome}
            </h3>
            <table className="table">
                <thead>
                    <th>Nome</th>
                    <th>Cognome</th>
                    <th>Data nascita</th>
                    <th>Numero</th>
                    <th>Valida dal</th>
                    <th>Valida al</th>
                    <th>Salva</th>
                    <th>Elimina</th>
                </thead>
                <tbody>
            {tessere&&Array.isArray(tessere)&&tessere.length>0&&
            tessere.map(tessera => 
           <RigaTessereAnagrafica tessera={tessera} />
                )}
                </tbody>
            </table>
        </div>
    );
}
