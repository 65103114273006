import React, { useState } from "react";
import { useSelector } from "react-redux";
import { aggiungiIngrediente, cercaCorsi } from "../../DAO/corsiDAO";


export default function CercaIngredienti(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [show, setshow] =useState(false);
    const [nomeprodotto, setnomeprodotto] = useState('')
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());
    const [sbvisible, setsbvisible] = useState(false)
    const [messaggio, setmessaggio] = useState(false)
    const [aggiunti, setaggiunti] = useState([])

    const [prodotti, setprodotti] = useState([])

    function cerca(){
        async function cercaPrds(){
            const result =await cercaCorsi(email, palestra.palestra.id, nomeprodotto);
            setprodotti(result)
        }
        if(nomeprodotto!==''&&nomeprodotto.length>3){
            cercaPrds();
        }
    }

    function chiudiRicerca(){
        setshow(false);
        props.refresh(new Date().getTime());
    }

    function aggiungiingrediente(idcorso){
        async function aggiungi(){
            const result=await aggiungiIngrediente(email, palestra.palestra.id,props.corso.id,idcorso);
            setmessaggio(result)
            setsbvisible(true)
            let aggtemp=aggiunti;
            aggtemp.push(idcorso)
            setaggiunti(aggtemp)
        }
        aggiungi();
    }

    return (
        <div key={keytorefresh}>
            <button onClick={() => setshow(true)}>Aggiungi un nuovo sottoprodotto</button>
            {show && <div >
                <div style={{flex:1, backgroundColor:'#ffffbb', margin:20}}>
                <p>Lista Prodotti per ingrediente {props.corso.nome} <button onClick={() => chiudiRicerca()}>Chiudi</button></p>
                <label>cerca un prodotto 
                <input type="text"  value={nomeprodotto} onChange={(e) => setnomeprodotto(e.target.value)} />
                </label>
                <button onClick={cerca}>Cerca</button>
                
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Categoria</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            prodotti.map(prodotto => (
                                <tr>
                                    <td>{prodotto.nome}</td>
                                    <td>{prodotto.tipoCorso.nome}</td>
                                    {props.ingredientidaescludere.filter(ingtemp => ingtemp.idcorso===prodotto.id).length===0 &&prodotto.id!==props.corso.id && aggiunti.filter(aggtemp => aggtemp===prodotto.id).length===0 &&
                                        <button onClick={() => aggiungiingrediente(prodotto.id)}>Aggiungi</button>}
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
                    <button onClick={() => chiudiRicerca()}>Chiudi</button>
                    {sbvisible && <span
                        visible={sbvisible}
                        onDismiss={() => setsbvisible(false)}
                    >
                        {messaggio}
                    </span>}
                </div>
            </div>}
        </div>
    );
}