import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import TipologieAbbonamentoSelect from "../tipologiaAbbonamento/TipologieAbbonamentoSelect";
import PeriodoSelect from "../periodi/PeriodoSelect";
import { connect } from "react-redux";

class NuovoListinoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id:0,
	    		pagaIscrizione: 1,
	    		prezzo: null,
	    		periodo: null,
	    		tipoAbbonamento: null,
	    		visible: false,
	    		messaggio:null
	    	}
	    this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.updateTipo = this.updateTipo.bind(this);
	    this.updatePeriodo = this.updatePeriodo.bind(this);
	    this.showNuovoListino = this.showNuovoListino.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
	  updateTipo(tipoSelezionato) {
		var tipoAbbonamento = {...this.state.tipoAbbonamento}
		tipoAbbonamento = tipoSelezionato;
		this.setState({tipoAbbonamento, buttonVisible: true})
	  }
	
	updatePeriodo(periodoSelezionato) {
		var periodo = {...this.state.periodo}
		periodo = periodoSelezionato;
		this.setState({periodo, buttonVisible: true})
	  }
	  
	handleSubmit(event) {
		event.preventDefault();
		const { id,pagaIscrizione, prezzo, periodo, tipoAbbonamento} = this.state;

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('pagaIscrizione', pagaIscrizione);
		formData.append('prezzo', prezzo);
		formData.append('periodo', periodo);
		formData.append('tipoAbbonamento', tipoAbbonamento);
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaListinoRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
	  }

	showNuovoListino(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		var style1={
				color:"white"
		}

		var style3={
			backgroundColor: "#D0D0D0"
		}
		return (
			<form className="form-horizontal" method="post">
				<div className="row">
					<div className="col" style={style3}>
						<h4 onClick={this.showNuovoListino}>Nuovo Listino</h4>
					</div>
				</div>
				{this.state.visible?
					<div className="row">
						<span>{this.state.messaggio}</span>
						<div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
							<TipologieAbbonamentoSelect listaTipologie={this.props.listaTipologie} 
							selected={this.state.tipoAbbonamento} 
							updateTipo={this.updateTipo} 
							email={this.props.email} />
						</div>
						<div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
							<PeriodoSelect listaPeriodi={this.props.listaPeriodi} 
							selected={this.state.periodo} 
							updatePeriodo={this.updatePeriodo} 
							email={this.props.email} />
						</div>
						<div className="col">
							<label className="control-label">Prezzo</label>
							<input className="form-control" type="number" onChange={this.update} name="prezzo" step="0.5" value={this.state.prezzo} />
						</div>
						<div className="col">
							<label className="control-label">Pag.Iscrizione</label>
							<select className="form-control" name="pagaIscrizione" value={this.state.pagaIscrizione} onChange={this.update}>
								<option value="1">Si</option>
								<option value="0">No</option>
							</select>
						</div>
						<div className="col">
							<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>Salva modifiche</a>
						</div>
					</div>
			:null}
			</form>
	);
	}
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(NuovoListinoInput)