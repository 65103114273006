import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Bottone({bootstrapsize, label, id, bootstrapstyle, onClick, icona}){

    return (
        <div className={"form-group "+bootstrapsize}>
            <button className={"btn btn-"+bootstrapstyle} 
            onClick={onClick} 
            id={id}>
                {label}
                {icona&&
                <FontAwesomeIcon icon={icona}
                />
                }
            </button>
        </div>
    );
}