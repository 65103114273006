import React, {useEffect, useState} from 'react';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import {Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

export default function MyInput({sethtml, defaultcontent}) {

  const[editorState, seteditorState] = useState(() => defaultcontent?EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(defaultcontent))):EditorState.createEmpty())

  useEffect(() =>{
    console.log('MyInput')
    seteditorState(defaultcontent?EditorState.createWithContent(ContentState.createFromBlockArray(convertFromHTML(defaultcontent))):EditorState.createEmpty())
  },[])
  return (
  <div style={{borderColor:'black', borderStyle:'dashed', borderWidth:1}}>

  <Editor
  editorState={editorState}
  onEditorStateChange={(state) => {seteditorState(state); sethtml(draftToHtml(convertToRaw(editorState.getCurrentContent())))}}
  />

  </div>);
};