import React, { useEffect, useState } from "react";
import '../../util/config'
import axios from 'axios';
import SelectTipologiaCorso from "../tipologiaCorso/SelectTipologiaCorso";
import Swal from "sweetalert2";
import HtmlEditor, { Toolbar, MediaResizing, Item } from 'devextreme-react/html-editor';
import LuogoSelect from "./LuogoSelect";
import { useSelector } from "react-redux";
import { aggiornaCorso, eliminaCorso } from "../../DAO/corsiDAO";
import InputText from "../../util/UI/InputText";
const sizeValues = ['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt'];
const fontValues = ['Arial', 'Courier New', 'Georgia', 'Impact', 'Lucida Console', 'Tahoma', 'Times New Roman', 'Verdana'];
const headerValues = [false, 1, 2, 3, 4, 5];

export default function NuovoCorsoInput (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    
    const [nome, setnome] =useState('')
    const [descrizione, setdescrizione] =useState('')
    const [place, setplace] = useState('')
    const [idtipo, setidtipo] = useState(props.tipo)
    const [duratagiornaliera, setduratagiornaliera] = useState(true)
    const [durata, setdurata] = useState('')
    const [utenti, setutenti] = useState('100')
    const [abb, setabb] = useState('')
    const [calendariopubblico, setcalendariopubblico] = useState('')
    const [prezzo, setprezzo] = useState('')
    const [foto1, setfoto1] = useState('')
	const [file, setfile] = useState(null);
	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())

    const [disponibileda, setdisponibileda] = useState('')
    const [disponibilea, setdisponibilea] = useState('')
    const [disponibile, setdisponibile] = useState('disponibile')
	const [imgUpload, setimgUpload] = useState(null);
	const [fenatura, setfenatura] = useState('')
	const [aliquotaiva, setaliquotaiva] = useState(0)
		
	const [visible, setvisible] = useState(false);
	const [messaggio, setmessaggio] = useState('');

	function handleInputChange(event) {
        if (event.target.files && event.target.files[0]) {
			console.log(event.target.files[0])
			let img = event.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = (e) => {

				setfile(
					{
						base64:e.target.result,
						name:img.name,
						type:img.type
					}
				)
				setvisible(true)
			}
		}
    }

	  function salva(){
        async function salvaprod(){
            const result=await aggiornaCorso(0,nome, descrizione, descrizione?descrizione:nome, idtipo&&idtipo.id?idtipo.id:props.tipo.id, utenti, durata, duratagiornaliera,
                place, abb, calendariopubblico, prezzo, disponibileda, disponibilea, disponibile,
                foto1, file, fenatura, aliquotaiva, emailloggato, palestra.palestra.id);
            setmessaggio(result)
            props.setkeytorefresh(new Date().getTime());
			setkeytorefresh(new Date().getTime())
			setvisible(false)
			setnome('')
			setdescrizione('')
			setidtipo('')
        }
		if (!nome){
			alert('Nome obbligatorio')
			return;
		}
		if (!descrizione){
			setdescrizione(nome);
		}
		if ((!idtipo||!idtipo.id)&&props.tipo){
			setidtipo(props.tipo)
		}
		if ((!idtipo||!idtipo.id)&&!props.tipo){
			alert('Tipologia obbligatoria')
			return;
		}
		if (!utenti){
			alert("Numero max prenotazioni obbligatorio");
			return
		}

		
		if (!place){
			alert('Luogo obbligatorio')
			return;
		}
		if (!prezzo){
			alert('Prezzo obbligatorio')
			return;
		}
        salvaprod();
    }
	  
	function updateTipo(tipoSelezionato) {
		setidtipo(tipoSelezionato)
		setvisible(true)
	}

	useEffect(()=>{

	},[props.tipo])

	    return (
            <div>
                <button type="button" className="btn btn-primary" onClick={()=>setvisible(!visible)}>Nuovo Prodotto</button>

		{visible && <form key={keytorefresh} className="form-horizontal" method="post">
	    	<div className="row">
				<div className="col-md-6">
					<label htmlFor="cnome"className="control-label">Nome</label>
						<input className="form-control" id="cnome" type="text" onChange={(e)=> setnome(e.target.value)} maxLength="150" name="nome" value={nome} required="required" />
					</div>
				<InputText label={"Prezzo €"} 
				type="number" name="prezzo" value={prezzo} 
				onChange={(e)=>setprezzo(e.target.value)} 
				bootstrapsize={"col-md-6 input-group-lg"}
				
				step="0.1" />
					<div className="col-md-12">
					<div className="widget-container">
						<label>Descrizione
					<textarea style={{width:'100%'}} rows={5} cols={50} name="descrizione" onChange={(e)=>setdescrizione(e.target.value)} value={descrizione} />
					</label>
					</div>
					</div>
					<div className="col-md-2">
						<label className="control-label">Luogo</label>
						<LuogoSelect setselected={(value) => {setplace(value)}} selected={place}/>
					</div>

							<div className="col-md-2">
								<label htmlFor="ctipoc" className="control-label">Categoria</label>
								<SelectTipologiaCorso updateTipo={updateTipo} 
								id="ctipoc" 
								selected={props.tipo} 
								 />
							</div>
							<div className="col-md-2">
								<label htmlFor ="cdurata" className="control-label">Durata</label>
								<input className="form-control" id="cdurata" type="number" onChange={(e) => setdurata(e.target.value)} name="durata" value={durata} required="required" />
								<label>Tutto il giorno <input type={"checkbox"} checked={duratagiornaliera} name={"duratagiornaliera"} onChange={(e) =>{e.target.checked?setduratagiornaliera(true):setduratagiornaliera(false)}}  /></label>
							</div>
							<div className="col-md-2">
								<label htmlFor="cmaxp" className="control-label">Max Prenotazioni</label>
								<input className="form-control" id="cmaxp" type="number" onChange={(e) => setutenti(e.target.value)} name="utenti" value={utenti} required="required" />
							</div>
							<div className="col-md-2">
								<label>Solo abbonati
								<select className="form-control" name="abb" value={abb&&(abb===true||abb==='true')} onChange={(e)=>{setabb(e.target.value)}}>
									<option value="false" >Aperto a tutti</option>
									<option value="true" >Solo abbonati</option>
								</select>
								</label>
							</div>
							<div className="col-md-2">
								<label htmlFor="showPub" className="control-label">Mostra nel calendario pubblicamente</label>
								<select id="showPub" className="form-control" name="calendariopubblico" value={calendariopubblico&&(calendariopubblico===true||calendariopubblico==='true')} onChange={(e)=>{setcalendariopubblico(e.target.value)}}>
									<option value="true" >Mostra</option>
									<option value="false" >Nascondi</option>
								</select>
							</div>

							<div className="col-md-4">
								<label>Disponibile <input type="radio" name="disponibile" value="disponibile" onChange={() => setdisponibile('disponibile') } checked={disponibile==='disponibile'} /></label>
								<label>Non Disponibile <input type="radio" label="Non Disponibile" name="disponibile" value="nondisponibie" onChange={() => {setdisponibile('nondisponibile')} } checked={disponibile!=='disponibile'}/></label>
							</div>
							<div className="col-md-4">
								<label>Disponibile dal <input type="date" name="disponibileda" value={disponibileda} onChange={(e) => {setdisponibileda(e.target.value); setdisponibilea(e.target.value)}} /></label>
							</div>
							<div className="col-md-4">
								<label>Disponibile fino a <input type="date" name="disponibilea" value={disponibilea} onChange={(e) => setdisponibilea(e.target.value)} /></label>
							</div>
							<div className="col-md">
							<img src={foto1} style={{maxWidth:'100px'}} />
							<label>Link immagine
							<input type="text" name="foto1" value={foto1}  onChange={(e) => setfoto1(e.target.value)} />
							</label>
							<label>Oppure seleziona una immagine
							<input id="imgUpload" type="file" name="fotoTemp" ref={imgUpload} onChange={handleInputChange} />
							</label>
							</div>
							<div className="row">
								<select name="fenatura" value={fenatura} onChange={(e) => setfenatura(e.target.value)} >
									<option value="">--Natura bene/servizio per Fattura Elettrovica</option>
									<option value="N1">N1 - escluse ex art. 15 del DPR 633/72</option>
									<option value="N2.1">N2.1 - non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72</option>	
									<option value="N2.2">N2.2 - non soggette - altri casi</option>
									<option value="N3.1">N3.1 - non imponibili - esportazioni</option>
									<option value="N3.2">N3.2 - non imponibili - cessioni intracomunitarie</option>
									<option value="N3.3">N3.3 - non imponibili - cessioni verso San Marino</option>
									<option value="N3.4">N3.4 - non imponibili - operazioni assimilate alle cessioni all'esportazione</option>
									<option value="N3.5">N3.5 - non imponibili - a seguito di dichiarazioni d'intento</option>			
									<option value="N3.6">N3.6 - non imponibili - altre operazioni che non concorrono alla formazione del plafond</option>
									<option value="N4">N4 - esenti</option>
									<option value="N5">N5 - regime del margine / IVA non esposta in fattura</option>
									<option value="N6.1">N6.1 - inversione contabile - cessione di rottami e altri materiali di recupero</option>
									<option value="N6.2">N6.2 - inversione contabile - cessione di oro e argento ai sensi della legge 7/2000 nonché di oreficeria usata ad OPO</option>
									<option value="N6.3">N6.3 - inversione contabile - subappalto nel settore edile</option>
									<option value="N6.4">N6.4 - inversione contabile - cessione di fabbricati</option>
									<option value="N6.5">N6.5 - inversione contabile - cessione di telefoni cellulari</option>
									<option value="N6.6">N6.6 - inversione contabile - cessione di prodotti elettronici</option>
									<option value="N6.7">N6.7 - inversione contabile - prestazioni comparto edile e settori connessi</option>
									<option value="N6.8">N6.8 - inversione contabile - operazioni settore energetico</option>
									<option value="N6.9">N6.9 - inversione contabile - altri casi</option>
									<option value="N7">N7 - IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-octies, comma 1 lett. a, b, art. 74-sexies DPR 633/72)</option>
								</select>
							</div>
							<div className="row">
								<label>Aliquota iva per fattura
									<input type="number" step=".01" name="aliquotaiva" value={aliquotaiva} onChange={(e) => setaliquotaiva(e.target.value)} />
								</label>
							</div>
								<div className="col-md-2">
				  				<a type="button" className="btn btn-primary" onClick={salva}>Salva modifiche</a>
								{messaggio}
				  				</div>
			</div>
			</form>}
            </div>
	    );
	}