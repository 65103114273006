import React from "react";
import ListaFilesProto from "./listaFilesProto";
import { useState } from "react";
import Text from "../util/UI/Text";
import { documentiricevuti, handleMostraFile } from "../DAO/DocumentiDAO";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function ProtoEntrataAnag(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [id, setid] = useState(0)
    const [html, sethtml] = useState('')
    const [tipo, settipo] = useState('E')
    const [idtipomovimento, setidtipomovimento] = useState('')

    const [docricevuti, setdocricevuti] = useState([])
    const [titolo, settitolo] = useState('')
    const [files, setfiles]=useState([])

    function docricevutianag(){
        async function carica(){
            let result = await documentiricevuti(palestra.palestra.id, anagselezionata.id);
            console.log(result)
            setdocricevuti(result)
        }
        carica();
    }

    function selectdocinviato(index){
        setid(docricevuti[index].id)
        sethtml(docricevuti[index].htmlreadable)
        settitolo(docricevuti[index].oggetto)
        setidtipomovimento(docricevuti[index].idtipomovimento)
        settipo(docricevuti[index].tipo)
        async function caricaFiles(){
            const result = await handleMostraFile(docricevuti[index].id, email, palestra.palestra.id)
            setfiles(result)
        }
        caricaFiles()
        //setkeytorefresh(new Date().getTime())
    }

    useEffect( ()=> {
        docricevutianag()
    },[palestra])

    return (
        <div className="col-md-12">
       Documenti ricevuti da {anagselezionata.nome} {anagselezionata.cognome}
            <table className="table table-hover">
                    <thead>
                        <tr>
                            <th>Data</th>
                            <th>Titolo</th>
                        </tr>
                    </thead>
                    <tbody>
                    {docricevuti&&Array.isArray(docricevuti)&&docricevuti.length>0&&
                    docricevuti.map((doc,index) =>(
                    <tr  onClick={() => selectdocinviato(index)}>
                        <td>{doc.dataarrivo}</td>
                        <td>{doc.oggetto}</td>
                    </tr>
                    ))}
                    </tbody>
                </table>
                <div className="row" >
            <div className="col-md-9" style={{borderColor:'black', borderStyle:'dashed', borderWidth:1}}>

            <Text bootstrapsize={"col-md-12"} 
            id={"oggetto"}
            label={"Titolo"}
            name={"titolo"}
            onChange={(e) => settitolo(e.target.value)}
            value={titolo} />
            
        </div>
        
        </div>
                <div className="row">
				<ListaFilesProto files={files} allega={false}  />
		        </div>
        </div>
    )

}