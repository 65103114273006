import axios from "axios";
import '../util/config'

export async function checkanagtessera(cognome, nome, datanascita, numero, validoda, validoa, idpalestra){
    const formData = new FormData();
    formData.append('cognome',cognome);
    formData.append('nome',nome);
    formData.append('datanascita',datanascita);
    formData.append('numero',numero);
    formData.append('validoda',validoda);
    formData.append('validoa',validoa);
    formData.append('idpalestra',idpalestra);
    const response = await axios.post(global.config.server+"/checkanagtessera",
    formData,
    {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    return response.data;
}

export async function salvadatitessera(cognome, nome, datanascita, numero, validoda, validoa, idpalestra){
  const formData = new FormData();
  formData.append('cognome',cognome);
  formData.append('nome',nome);
  formData.append('datanascita',datanascita);
  formData.append('numero',numero);
  formData.append('validoda',validoda);
  formData.append('validoa',validoa);
  formData.append('idpalestra',idpalestra);
  const response = await axios.post(global.config.server+"/salvatessera",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function getTessereByAnag(idanag){
  const formData = new FormData();
  formData.append('idanagrafica',idanag);
  const response = await axios.post(global.config.server+"/gettesserebyanag",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function salvatesseraanag(idtessera, idanagrafica, numero, validoda, validoa){
  const formData = new FormData();
  formData.append('idtessera',idtessera);
  formData.append('idanagrafica',idanagrafica);
  formData.append('numero',numero);
  formData.append('validoda',validoda);
  formData.append('validoa',validoa);
  const response = await axios.post(global.config.server+"/salvatesseraanag",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function eliminatessera(idtessera){
  const formData = new FormData();
  formData.append('idtessera',idtessera);
  const response = await axios.post(global.config.server+"/eliminatessera",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}

export async function cercatessera(nome,cognome, numero, validoda, validoa, idpalestra){
  const formData = new FormData();
  formData.append('cognome',cognome);
  formData.append('nome',nome);
  formData.append('numero',numero);
  formData.append('validoda',validoda);
  formData.append('validoa',validoa);
  formData.append('idpalestra',idpalestra);
  const response = await axios.post(global.config.server+"/cercatessera",
  formData,
  {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
      },
    },
  );
  return response.data;
}