import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import {navigate} from 'hookrouter'
import { useSelector } from "react-redux";

export default function Prenotazioni(props) {
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata)
  const [prenotazioni, setprenotazioni] = useState([]);

      useEffect(() =>{
        const formData = new FormData();
		formData.append('website', palestra.website);
    formData.append('palestra',palestra.id);
    formData.append('email',email);
    formData.append('lic','000');
        
        formData.append('id',idanagselezionata);
		  axios.post(global.config.server+"/getPrenotazioni",formData)
		  .then(response=>{
			console.log("Prenotazioni: ")
			  console.log(response.data)
			  setprenotazioni(response.data);
		  })
		  .catch(err => {
              if (err.response){
                alert("Prenotazioni: "+err.response.data.message);
              }
              else{
                alert("Prenotazioni: "+err.response);
              }
		});
      },[idanagselezionata])

    return (
        <span>
            <h2 className="entry-title"><a href="#">Prenotazioni effettuate</a></h2>
            <table className="table hover table-responsive">
                <thead><tr><th>Corso</th><th>Data ora prenotazione</th><th>Cancellazione</th></tr></thead>
                <tbody>
                {prenotazioni&&Array.isArray(prenotazioni)&&prenotazioni.length>0?
                            prenotazioni.map(prenotazione=>(
                                <tr>
                                    <td>{prenotazione.corso+" del "+ prenotazione.dataOra}</td>
                                    <td>{prenotazione.dataOraPrenotazione}</td>
                                    <td>{prenotazione.dataAnnullamento?"Annullato il "+prenotazione.dataAnnullamento:""}</td>
                                </tr>
                            ))
                :<tr><td colspan={3}>Nessuna prenotazione trovata</td></tr>}
                </tbody>
                </table>
</span>
);
  }