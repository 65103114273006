import React from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import { connect } from "react-redux";

class TipoAbbTipoCorsoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            descrCorso:"",
            id:"",
            free:false,
            valore:0,
	    	}

            this.update=this.update.bind(this)
	  }

      timer=0

      update(e) {
		this.setState({ [e.target.name]: e.target.value});
        var that=this
        clearTimeout(this.timer)
        this.timer=setTimeout(function() {
            const formData = new FormData();
            formData.append('idtipoabb', that.props.idtipoabb);
            formData.append('idtipocorso', that.props.tipocorso.id);
            formData.append('free', that.state.free?1:0);
            formData.append('valore', that.state.valore);
            formData.append('website', global.config.domain);
            formData.append('email',that.props.email);
          formData.append('lic','000');
		  formData.append('palestra',that.props.palestra.id);
		        axios.post(global.config.server+'/aggiornaTipoAbbTipoCorsoRest',formData)
            .then((result) => {
                
                that.setState({messaggio:result.data});
                  const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                      });
                      Toast.fire({
                        type: 'success',
                        title: result.data
                      })
            })
        },1000)
	}
  
	  componentDidMount(){
          var trovato=false;
          if (this.props.corsi&&this.props.corsi.length>0){
            for (var i = 0; i < this.props.corsi.length; i++) {
                if (this.props.corsi[i].idtipocorso===this.props.tipocorso.id){
                    this.setState({descrCorso:this.props.corsi[i].descrTipoCorso})
                    this.setState({valore:this.props.corsi[i].numingressisett})
                    this.setState({free:this.props.corsi[i].free})
                    trovato=true;
                    continue;
                }
            }
          }
          if(!trovato){
            this.setState({descrCorso:this.props.tipocorso.nome})
            this.setState({valore:0})
          }
 
    }


	render() {
		return (
      <tr>
        <td>
       Ingressi {this.state.descrCorso} sett.
        </td>
        <td>
          <input className="form-control" type="number" name="valore" 
          onChange={this.update}  value={this.state.valore} required="required"  />
        </td>
        <td>
          <label className="control-label">Free <input className="form-control" type="checkbox" name="free" 
          onChange={this.update} defaultChecked={this.state.free?"checked":""} value={this.state.free?0:1}/></label>
        </td>
        </tr>
        )
	  }
	}

  const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato
		}
	}
	
	export default connect(mapStateToProps, null)(TipoAbbTipoCorsoInput)