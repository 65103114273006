const ComuniItalianiJson=
  [
    {
      "Istat": "028001",
      "Comune": "Abano Terme",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35031,
      "CodFisco": "A001",
      "Abitanti": 19726,
      "Link": "http://www.comuni-italiani.it/028/001/"
    },
    {
      "Istat": "098001",
      "Comune": "Abbadia Cerreto",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26834,
      "CodFisco": "A004",
      "Abitanti": 293,
      "Link": "http://www.comuni-italiani.it/098/001/"
    },
    {
      "Istat": "097001",
      "Comune": "Abbadia Lariana",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23821,
      "CodFisco": "A005",
      "Abitanti": 3305,
      "Link": "http://www.comuni-italiani.it/097/001/"
    },
    {
      "Istat": "052001",
      "Comune": "Abbadia San Salvatore",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53021,
      "CodFisco": "A006",
      "Abitanti": 6722,
      "Link": "http://www.comuni-italiani.it/052/001/"
    },
    {
      "Istat": "095001",
      "Comune": "Abbasanta",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09071",
      "CodFisco": "A007",
      "Abitanti": 2872,
      "Link": "http://www.comuni-italiani.it/095/001/"
    },
    {
      "Istat": "068001",
      "Comune": "Abbateggio",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "A008",
      "Abitanti": 443,
      "Link": "http://www.comuni-italiani.it/068/001/"
    },
    {
      "Istat": "015002",
      "Comune": "Abbiategrasso",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20081,
      "CodFisco": "A010",
      "Abitanti": 32035,
      "Link": "http://www.comuni-italiani.it/015/002/"
    },
    {
      "Istat": "047001",
      "Comune": "Abetone",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51021,
      "CodFisco": "A012",
      "Abitanti": 697,
      "Link": "http://www.comuni-italiani.it/047/001/"
    },
    {
      "Istat": "076001",
      "Comune": "Abriola",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "A013",
      "Abitanti": 1599,
      "Link": "http://www.comuni-italiani.it/076/001/"
    },
    {
      "Istat": "088001",
      "Comune": "Acate",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97011,
      "CodFisco": "A014",
      "Abitanti": 9793,
      "Link": "http://www.comuni-italiani.it/088/001/"
    },
    {
      "Istat": "071001",
      "Comune": "Accadia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71021,
      "CodFisco": "A015",
      "Abitanti": 2481,
      "Link": "http://www.comuni-italiani.it/071/001/"
    },
    {
      "Istat": "004001",
      "Comune": "Acceglio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12021,
      "CodFisco": "A016",
      "Abitanti": 177,
      "Link": "http://www.comuni-italiani.it/004/001/"
    },
    {
      "Istat": "077001",
      "Comune": "Accettura",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75011,
      "CodFisco": "A017",
      "Abitanti": 2019,
      "Link": "http://www.comuni-italiani.it/077/001/"
    },
    {
      "Istat": "066001",
      "Comune": "Acciano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67020,
      "CodFisco": "A018",
      "Abitanti": 368,
      "Link": "http://www.comuni-italiani.it/066/001/"
    },
    {
      "Istat": "057001",
      "Comune": "Accumoli",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02011",
      "CodFisco": "A019",
      "Abitanti": 708,
      "Link": "http://www.comuni-italiani.it/057/001/"
    },
    {
      "Istat": "076002",
      "Comune": "Acerenza",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85011,
      "CodFisco": "A020",
      "Abitanti": 2595,
      "Link": "http://www.comuni-italiani.it/076/002/"
    },
    {
      "Istat": "065001",
      "Comune": "Acerno",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84042,
      "CodFisco": "A023",
      "Abitanti": 2877,
      "Link": "http://www.comuni-italiani.it/065/001/"
    },
    {
      "Istat": "063001",
      "Comune": "Acerra",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80011,
      "CodFisco": "A024",
      "Abitanti": 56177,
      "Link": "http://www.comuni-italiani.it/063/001/"
    },
    {
      "Istat": "087001",
      "Comune": "Aci Bonaccorsi",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95020,
      "CodFisco": "A025",
      "Abitanti": 3223,
      "Link": "http://www.comuni-italiani.it/087/001/"
    },
    {
      "Istat": "087002",
      "Comune": "Aci Castello",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95021,
      "CodFisco": "A026",
      "Abitanti": 18031,
      "Link": "http://www.comuni-italiani.it/087/002/"
    },
    {
      "Istat": "087003",
      "Comune": "Aci Catena",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95022,
      "CodFisco": "A027",
      "Abitanti": 28920,
      "Link": "http://www.comuni-italiani.it/087/003/"
    },
    {
      "Istat": "087005",
      "Comune": "Aci Sant'Antonio",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95025,
      "CodFisco": "A029",
      "Abitanti": 17610,
      "Link": "http://www.comuni-italiani.it/087/005/"
    },
    {
      "Istat": "087004",
      "Comune": "Acireale",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95024,
      "CodFisco": "A028",
      "Abitanti": 53122,
      "Link": "http://www.comuni-italiani.it/087/004/"
    },
    {
      "Istat": "072001",
      "Comune": "Acquaviva delle Fonti",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70021,
      "CodFisco": "A048",
      "Abitanti": 21096,
      "Link": "http://www.comuni-italiani.it/072/001/"
    },
    {
      "Istat": "043001",
      "Comune": "Acquacanina",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62035,
      "CodFisco": "A031",
      "Abitanti": 121,
      "Link": "http://www.comuni-italiani.it/043/001/"
    },
    {
      "Istat": "060001",
      "Comune": "Acquafondata",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "A032",
      "Abitanti": 296,
      "Link": "http://www.comuni-italiani.it/060/001/"
    },
    {
      "Istat": "078001",
      "Comune": "Acquaformosa",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "A033",
      "Abitanti": 1184,
      "Link": "http://www.comuni-italiani.it/078/001/"
    },
    {
      "Istat": "017001",
      "Comune": "Acquafredda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "A034",
      "Abitanti": 1615,
      "Link": "http://www.comuni-italiani.it/017/001/"
    },
    {
      "Istat": "041001",
      "Comune": "Acqualagna",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61041,
      "CodFisco": "A035",
      "Abitanti": 4520,
      "Link": "http://www.comuni-italiani.it/041/001/"
    },
    {
      "Istat": "019001",
      "Comune": "Acquanegra Cremonese",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26020,
      "CodFisco": "A039",
      "Abitanti": 1327,
      "Link": "http://www.comuni-italiani.it/019/001/"
    },
    {
      "Istat": "020001",
      "Comune": "Acquanegra sul Chiese",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46011,
      "CodFisco": "A038",
      "Abitanti": 3061,
      "Link": "http://www.comuni-italiani.it/020/001/"
    },
    {
      "Istat": "056001",
      "Comune": "Acquapendente",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0763",
      "CAP": "01021",
      "CodFisco": "A040",
      "Abitanti": 5677,
      "Link": "http://www.comuni-italiani.it/056/001/"
    },
    {
      "Istat": "078002",
      "Comune": "Acquappesa",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87020,
      "CodFisco": "A041",
      "Abitanti": 1936,
      "Link": "http://www.comuni-italiani.it/078/002/"
    },
    {
      "Istat": "075001",
      "Comune": "Acquarica del Capo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "A042",
      "Abitanti": 4951,
      "Link": "http://www.comuni-italiani.it/075/001/"
    },
    {
      "Istat": 102001,
      "Comune": "Acquaro",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89832,
      "CodFisco": "A043",
      "Abitanti": 2632,
      "Link": "http://www.comuni-italiani.it/102/001/"
    },
    {
      "Istat": "044001",
      "Comune": "Acquasanta Terme",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63095,
      "CodFisco": "A044",
      "Abitanti": 3092,
      "Link": "http://www.comuni-italiani.it/044/001/"
    },
    {
      "Istat": "055001",
      "Comune": "Acquasparta",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05021",
      "CodFisco": "A045",
      "Abitanti": 5173,
      "Link": "http://www.comuni-italiani.it/055/001/"
    },
    {
      "Istat": "070001",
      "Comune": "Acquaviva Collecroce",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86030,
      "CodFisco": "A050",
      "Abitanti": 697,
      "Link": "http://www.comuni-italiani.it/070/001/"
    },
    
    {
      "Istat": "094001",
      "Comune": "Acquaviva d'Isernia",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "A051",
      "Abitanti": 464,
      "Link": "http://www.comuni-italiani.it/094/001/"
    },
    {
      "Istat": "044002",
      "Comune": "Acquaviva Picena",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63075,
      "CodFisco": "A047",
      "Abitanti": 3860,
      "Link": "http://www.comuni-italiani.it/044/002/"
    },
    {
      "Istat": "085001",
      "Comune": "Acquaviva Platani",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "A049",
      "Abitanti": 1028,
      "Link": "http://www.comuni-italiani.it/085/001/"
    },
    {
      "Istat": "083107",
      "Comune": "Acquedolci",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "M211",
      "Abitanti": 5652,
      "Link": "http://www.comuni-italiani.it/083/107/"
    },
    {
      "Istat": "006001",
      "Comune": "Acqui Terme",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15011,
      "CodFisco": "A052",
      "Abitanti": 20552,
      "Link": "http://www.comuni-italiani.it/006/001/"
    },
    {
      "Istat": "078003",
      "Comune": "Acri",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87041,
      "CodFisco": "A053",
      "Abitanti": 21228,
      "Link": "http://www.comuni-italiani.it/078/003/"
    },
    {
      "Istat": "060002",
      "Comune": "Acuto",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "A054",
      "Abitanti": 1914,
      "Link": "http://www.comuni-italiani.it/060/002/"
    },
    {
      "Istat": "072002",
      "Comune": "Adelfia",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "A055",
      "Abitanti": 17306,
      "Link": "http://www.comuni-italiani.it/072/002/"
    },
    {
      "Istat": "087006",
      "Comune": "Adrano",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95031,
      "CodFisco": "A056",
      "Abitanti": 36779,
      "Link": "http://www.comuni-italiani.it/087/006/"
    },
    {
      "Istat": "016001",
      "Comune": "Adrara San Martino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "A057",
      "Abitanti": 2171,
      "Link": "http://www.comuni-italiani.it/016/001/"
    },
    {
      "Istat": "016002",
      "Comune": "Adrara San Rocco",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "A058",
      "Abitanti": 857,
      "Link": "http://www.comuni-italiani.it/016/002/"
    },
    {
      "Istat": "029001",
      "Comune": "Adria",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45011,
      "CodFisco": "A059",
      "Abitanti": 20441,
      "Link": "http://www.comuni-italiani.it/029/001/"
    },
    {
      "Istat": "017002",
      "Comune": "Adro",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "A060",
      "Abitanti": 7180,
      "Link": "http://www.comuni-italiani.it/017/002/"
    },
    {
      "Istat": "023001",
      "Comune": "Affi",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "A061",
      "Abitanti": 2362,
      "Link": "http://www.comuni-italiani.it/023/001/"
    },
    {
      "Istat": "058001",
      "Comune": "Affile",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00021",
      "CodFisco": "A062",
      "Abitanti": 1562,
      "Link": "http://www.comuni-italiani.it/058/001/"
    },
    {
      "Istat": "063002",
      "Comune": "Afragola",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80021,
      "CodFisco": "A064",
      "Abitanti": 63981,
      "Link": "http://www.comuni-italiani.it/063/002/"
    },
    {
      "Istat": "080001",
      "Comune": "Africo",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "A065",
      "Abitanti": 3251,
      "Link": "http://www.comuni-italiani.it/080/001/"
    },
    {
      "Istat": "033001",
      "Comune": "Agazzano",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "A067",
      "Abitanti": 2107,
      "Link": "http://www.comuni-italiani.it/033/001/"
    },
    {
      "Istat": "063003",
      "Comune": "Agerola",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80051,
      "CodFisco": "A068",
      "Abitanti": 7456,
      "Link": "http://www.comuni-italiani.it/063/003/"
    },
    {
      "Istat": 104001,
      "Comune": "Aggius",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07020",
      "CodFisco": "A069",
      "Abitanti": 1631,
      "Link": "http://www.comuni-italiani.it/104/001/"
    },
    {
      "Istat": "086001",
      "Comune": "Agira",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94011,
      "CodFisco": "A070",
      "Abitanti": 8282,
      "Link": "http://www.comuni-italiani.it/086/001/"
    },
    {
      "Istat": "047002",
      "Comune": "Agliana",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0574",
      "CAP": 51031,
      "CodFisco": "A071",
      "Abitanti": 17076,
      "Link": "http://www.comuni-italiani.it/047/002/"
    },
    {
      "Istat": "005001",
      "Comune": "Agliano Terme",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14041,
      "CodFisco": "A072",
      "Abitanti": 1685,
      "Link": "http://www.comuni-italiani.it/005/001/"
    },
    {
      "Istat": "001001",
      "Comune": "AgliË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10011,
      "CodFisco": "A074",
      "Abitanti": 2591,
      "Link": "http://www.comuni-italiani.it/001/001/"
    },
    {
      "Istat": 104002,
      "Comune": "Aglientu",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07020",
      "CodFisco": "H848",
      "Abitanti": 1213,
      "Link": "http://www.comuni-italiani.it/104/002/"
    },
    {
      "Istat": "028002",
      "Comune": "Agna",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35021,
      "CodFisco": "A075",
      "Abitanti": 3412,
      "Link": "http://www.comuni-italiani.it/028/002/"
    },
    {
      "Istat": "019002",
      "Comune": "Agnadello",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26020,
      "CodFisco": "A076",
      "Abitanti": 3776,
      "Link": "http://www.comuni-italiani.it/019/002/"
    },
    {
      "Istat": "080002",
      "Comune": "Agnana Calabra",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "A077",
      "Abitanti": 616,
      "Link": "http://www.comuni-italiani.it/080/002/"
    },
    {
      "Istat": "094002",
      "Comune": "Agnone",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86081,
      "CodFisco": "A080",
      "Abitanti": 5323,
      "Link": "http://www.comuni-italiani.it/094/002/"
    },
    {
      "Istat": "017003",
      "Comune": "Agnosine",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25071,
      "CodFisco": "A082",
      "Abitanti": 1839,
      "Link": "http://www.comuni-italiani.it/017/003/"
    },
    {
      "Istat": "025001",
      "Comune": "Agordo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32021,
      "CodFisco": "A083",
      "Abitanti": 4230,
      "Link": "http://www.comuni-italiani.it/025/001/"
    },
    {
      "Istat": "058002",
      "Comune": "Agosta",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "A084",
      "Abitanti": 1768,
      "Link": "http://www.comuni-italiani.it/058/002/"
    },
    {
      "Istat": "012001",
      "Comune": "Agra",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "A085",
      "Abitanti": 408,
      "Link": "http://www.comuni-italiani.it/012/001/"
    },
    {
      "Istat": 108001,
      "Comune": "Agrate Brianza",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20864,
      "CodFisco": "A087",
      "Abitanti": 15065,
      "Link": "http://www.comuni-italiani.it/108/001/"
    },
    {
      "Istat": "003001",
      "Comune": "Agrate Conturbia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "A088",
      "Abitanti": 1560,
      "Link": "http://www.comuni-italiani.it/003/001/"
    },
    {
      "Istat": "084001",
      "Comune": "Agrigento",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92100,
      "CodFisco": "A089",
      "Abitanti": 59175,
      "Link": "http://www.comuni-italiani.it/084/001/"
    },
    {
      "Istat": "065002",
      "Comune": "Agropoli",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84043,
      "CodFisco": "A091",
      "Abitanti": 21305,
      "Link": "http://www.comuni-italiani.it/065/002/"
    },
    {
      "Istat": "042001",
      "Comune": "Agugliano",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60020,
      "CodFisco": "A092",
      "Abitanti": 4820,
      "Link": "http://www.comuni-italiani.it/042/001/"
    },
    {
      "Istat": "024001",
      "Comune": "Agugliaro",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36020,
      "CodFisco": "A093",
      "Abitanti": 1416,
      "Link": "http://www.comuni-italiani.it/024/001/"
    },
    {
      "Istat": 108002,
      "Comune": "Aicurzio",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20886,
      "CodFisco": "A096",
      "Abitanti": 2069,
      "Link": "http://www.comuni-italiani.it/108/002/"
    },
    {
      "Istat": "095002",
      "Comune": "Aidomaggiore",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09070",
      "CodFisco": "A097",
      "Abitanti": 480,
      "Link": "http://www.comuni-italiani.it/095/002/"
    },
    {
      "Istat": "086002",
      "Comune": "Aidone",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "A098",
      "Abitanti": 5083,
      "Link": "http://www.comuni-italiani.it/086/002/"
    },
    {
      "Istat": "066002",
      "Comune": "Aielli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67041,
      "CodFisco": "A100",
      "Abitanti": 1473,
      "Link": "http://www.comuni-italiani.it/066/002/"
    },
    {
      "Istat": "078004",
      "Comune": "Aiello Calabro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87031,
      "CodFisco": "A102",
      "Abitanti": 2017,
      "Link": "http://www.comuni-italiani.it/078/004/"
    },
    {
      "Istat": "030001",
      "Comune": "Aiello del Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33041,
      "CodFisco": "A103",
      "Abitanti": 2260,
      "Link": "http://www.comuni-italiani.it/030/001/"
    },
    {
      "Istat": "064001",
      "Comune": "Aiello del Sabato",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83020,
      "CodFisco": "A101",
      "Abitanti": 4003,
      "Link": "http://www.comuni-italiani.it/064/001/"
    },
    {
      "Istat": "078005",
      "Comune": "Aieta",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "A105",
      "Abitanti": 845,
      "Link": "http://www.comuni-italiani.it/078/005/"
    },
    {
      "Istat": "061001",
      "Comune": "Ailano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "A106",
      "Abitanti": 1406,
      "Link": "http://www.comuni-italiani.it/061/001/"
    },
    {
      "Istat": "096001",
      "Comune": "Ailoche",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13861,
      "CodFisco": "A107",
      "Abitanti": 333,
      "Link": "http://www.comuni-italiani.it/096/001/"
    },
    {
      "Istat": "001002",
      "Comune": "Airasca",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "A109",
      "Abitanti": 3808,
      "Link": "http://www.comuni-italiani.it/001/002/"
    },
    {
      "Istat": "062001",
      "Comune": "Airola",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82011,
      "CodFisco": "A110",
      "Abitanti": 8147,
      "Link": "http://www.comuni-italiani.it/062/001/"
    },
    {
      "Istat": "008001",
      "Comune": "Airole",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18030,
      "CodFisco": "A111",
      "Abitanti": 497,
      "Link": "http://www.comuni-italiani.it/008/001/"
    },
    {
      "Istat": "097002",
      "Comune": "Airuno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23881,
      "CodFisco": "A112",
      "Abitanti": 2999,
      "Link": "http://www.comuni-italiani.it/097/002/"
    },
    {
      "Istat": "004002",
      "Comune": "Aisone",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "A113",
      "Abitanti": 256,
      "Link": "http://www.comuni-italiani.it/004/002/"
    },
    {
      "Istat": "022001",
      "Comune": "Ala",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38061,
      "CodFisco": "A116",
      "Abitanti": 9034,
      "Link": "http://www.comuni-italiani.it/022/001/"
    },
    {
      "Istat": 104003,
      "Comune": "Al‡ dei Sardi",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07020",
      "CodFisco": "A115",
      "Abitanti": 1960,
      "Link": "http://www.comuni-italiani.it/104/003/"
    },
    {
      "Istat": "001003",
      "Comune": "Ala di Stura",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "A117",
      "Abitanti": 465,
      "Link": "http://www.comuni-italiani.it/001/003/"
    },
    {
      "Istat": "018001",
      "Comune": "Alagna",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "A118",
      "Abitanti": 906,
      "Link": "http://www.comuni-italiani.it/018/001/"
    },
    {
      "Istat": "002002",
      "Comune": "Alagna Valsesia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13021,
      "CodFisco": "A119",
      "Abitanti": 434,
      "Link": "http://www.comuni-italiani.it/002/002/"
    },
    {
      "Istat": "068002",
      "Comune": "Alanno",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "A120",
      "Abitanti": 3679,
      "Link": "http://www.comuni-italiani.it/068/002/"
    },
    {
      "Istat": "025002",
      "Comune": "Alano di Piave",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32031,
      "CodFisco": "A121",
      "Abitanti": 3048,
      "Link": "http://www.comuni-italiani.it/025/002/"
    },
    {
      "Istat": "009001",
      "Comune": "Alassio",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17021,
      "CodFisco": "A122",
      "Abitanti": 11312,
      "Link": "http://www.comuni-italiani.it/009/001/"
    },
    {
      "Istat": "060003",
      "Comune": "Alatri",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03011",
      "CodFisco": "A123",
      "Abitanti": 29496,
      "Link": "http://www.comuni-italiani.it/060/003/"
    },
    {
      "Istat": "004003",
      "Comune": "Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12051,
      "CodFisco": "A124",
      "Abitanti": 31341,
      "Link": "http://www.comuni-italiani.it/004/003/"
    },
    {
      "Istat": "067001",
      "Comune": "Alba Adriatica",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64011,
      "CodFisco": "A125",
      "Abitanti": 12522,
      "Link": "http://www.comuni-italiani.it/067/001/"
    },
    {
      "Istat": "095003",
      "Comune": "Albagiara",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "A126",
      "Abitanti": 283,
      "Link": "http://www.comuni-italiani.it/095/003/"
    },
    {
      "Istat": "015005",
      "Comune": "Albairate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "A127",
      "Abitanti": 4680,
      "Link": "http://www.comuni-italiani.it/015/005/"
    },
    {
      "Istat": "065003",
      "Comune": "Albanella",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84044,
      "CodFisco": "A128",
      "Abitanti": 6519,
      "Link": "http://www.comuni-italiani.it/065/003/"
    },
    {
      "Istat": "076003",
      "Comune": "Albano di Lucania",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "A131",
      "Abitanti": 1485,
      "Link": "http://www.comuni-italiani.it/076/003/"
    },
    {
      "Istat": "058003",
      "Comune": "Albano Laziale",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00041",
      "CodFisco": "A132",
      "Abitanti": 40516,
      "Link": "http://www.comuni-italiani.it/058/003/"
    },
    {
      "Istat": "016003",
      "Comune": "Albano Sant'Alessandro",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24061,
      "CodFisco": "A129",
      "Abitanti": 8075,
      "Link": "http://www.comuni-italiani.it/016/003/"
    },
    {
      "Istat": "002003",
      "Comune": "Albano Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "A130",
      "Abitanti": 342,
      "Link": "http://www.comuni-italiani.it/002/003/"
    },
    {
      "Istat": "018002",
      "Comune": "Albaredo Arnaboldi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "A134",
      "Abitanti": 223,
      "Link": "http://www.comuni-italiani.it/018/002/"
    },
    {
      "Istat": "023002",
      "Comune": "Albaredo d'Adige",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37041,
      "CodFisco": "A137",
      "Abitanti": 5313,
      "Link": "http://www.comuni-italiani.it/023/002/"
    },
    {
      "Istat": "014001",
      "Comune": "Albaredo per San Marco",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "A135",
      "Abitanti": 350,
      "Link": "http://www.comuni-italiani.it/014/001/"
    },
    {
      "Istat": "034001",
      "Comune": "Albareto",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43051,
      "CodFisco": "A138",
      "Abitanti": 2232,
      "Link": "http://www.comuni-italiani.it/034/001/"
    },
    {
      "Istat": "004004",
      "Comune": "Albaretto della Torre",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "A139",
      "Abitanti": 260,
      "Link": "http://www.comuni-italiani.it/004/004/"
    },
    {
      "Istat": "013003",
      "Comune": "Albavilla",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22031,
      "CodFisco": "A143",
      "Abitanti": 6272,
      "Link": "http://www.comuni-italiani.it/013/003/"
    },
    {
      "Istat": "009002",
      "Comune": "Albenga",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17031,
      "CodFisco": "A145",
      "Abitanti": 24378,
      "Link": "http://www.comuni-italiani.it/009/002/"
    },
    {
      "Istat": "006002",
      "Comune": "Albera Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "A146",
      "Abitanti": 323,
      "Link": "http://www.comuni-italiani.it/006/002/"
    },
    {
      "Istat": "072003",
      "Comune": "Alberobello",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70011,
      "CodFisco": "A149",
      "Abitanti": 11013,
      "Link": "http://www.comuni-italiani.it/072/003/"
    },
    {
      "Istat": "071002",
      "Comune": "Alberona",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71031,
      "CodFisco": "A150",
      "Abitanti": 1012,
      "Link": "http://www.comuni-italiani.it/071/002/"
    },
    {
      "Istat": "013004",
      "Comune": "Albese con Cassano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22032,
      "CodFisco": "A153",
      "Abitanti": 4139,
      "Link": "http://www.comuni-italiani.it/013/004/"
    },
    {
      "Istat": "024002",
      "Comune": "Albettone",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36020,
      "CodFisco": "A154",
      "Abitanti": 2104,
      "Link": "http://www.comuni-italiani.it/024/002/"
    },
    {
      "Istat": "079002",
      "Comune": "Albi",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88055,
      "CodFisco": "A155",
      "Abitanti": 1027,
      "Link": "http://www.comuni-italiani.it/079/002/"
    },
    {
      "Istat": "022002",
      "Comune": "Albiano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38041,
      "CodFisco": "A158",
      "Abitanti": 1511,
      "Link": "http://www.comuni-italiani.it/022/002/"
    },
    {
      "Istat": "001004",
      "Comune": "Albiano d'Ivrea",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "A157",
      "Abitanti": 1778,
      "Link": "http://www.comuni-italiani.it/001/004/"
    },
    {
      "Istat": 108003,
      "Comune": "Albiate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20847,
      "CodFisco": "A159",
      "Abitanti": 6201,
      "Link": "http://www.comuni-italiani.it/108/003/"
    },
    {
      "Istat": "078006",
      "Comune": "Albidona",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "A160",
      "Abitanti": 1481,
      "Link": "http://www.comuni-italiani.it/078/006/"
    },
    {
      "Istat": "028003",
      "Comune": "Albignasego",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "A161",
      "Abitanti": 23284,
      "Link": "http://www.comuni-italiani.it/028/003/"
    },
    {
      "Istat": "035001",
      "Comune": "Albinea",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42020,
      "CodFisco": "A162",
      "Abitanti": 8766,
      "Link": "http://www.comuni-italiani.it/035/001/"
    },
    {
      "Istat": "016004",
      "Comune": "Albino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24021,
      "CodFisco": "A163",
      "Abitanti": 18186,
      "Link": "http://www.comuni-italiani.it/016/004/"
    },
    {
      "Istat": "013005",
      "Comune": "Albiolo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "A164",
      "Abitanti": 2687,
      "Link": "http://www.comuni-italiani.it/013/005/"
    },
    {
      "Istat": "009004",
      "Comune": "Albisola Superiore",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17011,
      "CodFisco": "A166",
      "Abitanti": 10581,
      "Link": "http://www.comuni-italiani.it/009/004/"
    },
    {
      "Istat": "009003",
      "Comune": "Albissola Marina",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17012,
      "CodFisco": "A165",
      "Abitanti": 5617,
      "Link": "http://www.comuni-italiani.it/009/003/"
    },
    {
      "Istat": "012002",
      "Comune": "Albizzate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21041,
      "CodFisco": "A167",
      "Abitanti": 5275,
      "Link": "http://www.comuni-italiani.it/012/002/"
    },
    {
      "Istat": "018003",
      "Comune": "Albonese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "A171",
      "Abitanti": 567,
      "Link": "http://www.comuni-italiani.it/018/003/"
    },
    {
      "Istat": "014002",
      "Comune": "Albosaggia",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "A172",
      "Abitanti": 3170,
      "Link": "http://www.comuni-italiani.it/014/002/"
    },
    {
      "Istat": "005002",
      "Comune": "Albugnano",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 14022,
      "CodFisco": "A173",
      "Abitanti": 553,
      "Link": "http://www.comuni-italiani.it/005/002/"
    },
    {
      "Istat": "018004",
      "Comune": "Albuzzano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "A175",
      "Abitanti": 3368,
      "Link": "http://www.comuni-italiani.it/018/004/"
    },
    {
      "Istat": "081001",
      "Comune": "Alcamo",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91011,
      "CodFisco": "A176",
      "Abitanti": 45835,
      "Link": "http://www.comuni-italiani.it/081/001/"
    },
    {
      "Istat": "083001",
      "Comune": "Alcara Li Fusi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "A177",
      "Abitanti": 2116,
      "Link": "http://www.comuni-italiani.it/083/001/"
    },
    {
      "Istat": "022003",
      "Comune": "Aldeno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38060,
      "CodFisco": "A178",
      "Abitanti": 3014,
      "Link": "http://www.comuni-italiani.it/022/003/"
    },
    {
      "Istat": "021001",
      "Comune": "Aldino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "A179",
      "Abitanti": 1665,
      "Link": "http://www.comuni-italiani.it/021/001/"
    },
    {
      "Istat": "095004",
      "Comune": "Ales",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09091",
      "CodFisco": "A180",
      "Abitanti": 1512,
      "Link": "http://www.comuni-italiani.it/095/004/"
    },
    {
      "Istat": "006003",
      "Comune": "Alessandria",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": "1512x",
      "CodFisco": "A182",
      "Abitanti": 94974,
      "Link": "http://www.comuni-italiani.it/006/003/"
    },
    {
      "Istat": "078007",
      "Comune": "Alessandria del Carretto",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "A183",
      "Abitanti": 538,
      "Link": "http://www.comuni-italiani.it/078/007/"
    },
    {
      "Istat": "084002",
      "Comune": "Alessandria della Rocca",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "A181",
      "Abitanti": 3109,
      "Link": "http://www.comuni-italiani.it/084/002/"
    },
    {
      "Istat": "075002",
      "Comune": "Alessano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73031,
      "CodFisco": "A184",
      "Abitanti": 6552,
      "Link": "http://www.comuni-italiani.it/075/002/"
    },
    {
      "Istat": "075003",
      "Comune": "Alezio",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73011,
      "CodFisco": "A185",
      "Abitanti": 5547,
      "Link": "http://www.comuni-italiani.it/075/003/"
    },
    {
      "Istat": "065004",
      "Comune": "Alfano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "A186",
      "Abitanti": 1110,
      "Link": "http://www.comuni-italiani.it/065/004/"
    },
    {
      "Istat": "066003",
      "Comune": "Alfedena",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "A187",
      "Abitanti": 822,
      "Link": "http://www.comuni-italiani.it/066/003/"
    },
    {
      "Istat": "017004",
      "Comune": "Alfianello",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "A188",
      "Abitanti": 2476,
      "Link": "http://www.comuni-italiani.it/017/004/"
    },
    {
      "Istat": "006004",
      "Comune": "Alfiano Natta",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 15021,
      "CodFisco": "A189",
      "Abitanti": 772,
      "Link": "http://www.comuni-italiani.it/006/004/"
    },
    {
      "Istat": "039001",
      "Comune": "Alfonsine",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0544",
      "CAP": 48011,
      "CodFisco": "A191",
      "Abitanti": 12411,
      "Link": "http://www.comuni-italiani.it/039/001/"
    },
    {
      "Istat": "090003",
      "Comune": "Alghero",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07041",
      "CodFisco": "A192",
      "Abitanti": 40965,
      "Link": "http://www.comuni-italiani.it/090/003/"
    },
    {
      "Istat": "016248",
      "Comune": "Algua",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "A193",
      "Abitanti": 726,
      "Link": "http://www.comuni-italiani.it/016/248/"
    },
    {
      "Istat": "083002",
      "Comune": "AlÏ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98020,
      "CodFisco": "A194",
      "Abitanti": 834,
      "Link": "http://www.comuni-italiani.it/083/002/"
    },
    {
      "Istat": "083003",
      "Comune": "AlÏ Terme",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98021,
      "CodFisco": "A201",
      "Abitanti": 2581,
      "Link": "http://www.comuni-italiani.it/083/003/"
    },
    {
      "Istat": "082001",
      "Comune": "Alia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90021,
      "CodFisco": "A195",
      "Abitanti": 3907,
      "Link": "http://www.comuni-italiani.it/082/001/"
    },
    {
      "Istat": "077002",
      "Comune": "Aliano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "A196",
      "Abitanti": 1110,
      "Link": "http://www.comuni-italiani.it/077/002/"
    },
    {
      "Istat": "006005",
      "Comune": "Alice Bel Colle",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "A197",
      "Abitanti": 781,
      "Link": "http://www.comuni-italiani.it/006/005/"
    },
    {
      "Istat": "002004",
      "Comune": "Alice Castello",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "A198",
      "Abitanti": 2716,
      "Link": "http://www.comuni-italiani.it/002/004/"
    },
    {
      "Istat": "001005",
      "Comune": "Alice Superiore",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "A199",
      "Abitanti": 713,
      "Link": "http://www.comuni-italiani.it/001/005/"
    },
    {
      "Istat": "061002",
      "Comune": "Alife",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81011,
      "CodFisco": "A200",
      "Abitanti": 7571,
      "Link": "http://www.comuni-italiani.it/061/002/"
    },
    {
      "Istat": "082002",
      "Comune": "Alimena",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90020,
      "CodFisco": "A202",
      "Abitanti": 2187,
      "Link": "http://www.comuni-italiani.it/082/002/"
    },
    {
      "Istat": "082003",
      "Comune": "Aliminusa",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "A203",
      "Abitanti": 1334,
      "Link": "http://www.comuni-italiani.it/082/003/"
    },
    {
      "Istat": "095005",
      "Comune": "Allai",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "A204",
      "Abitanti": 385,
      "Link": "http://www.comuni-italiani.it/095/005/"
    },
    {
      "Istat": "025003",
      "Comune": "Alleghe",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32022,
      "CodFisco": "A206",
      "Abitanti": 1354,
      "Link": "http://www.comuni-italiani.it/025/003/"
    },
    {
      "Istat": "007001",
      "Comune": "Allein",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "A205",
      "Abitanti": 258,
      "Link": "http://www.comuni-italiani.it/007/001/"
    },
    {
      "Istat": "055002",
      "Comune": "Allerona",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05011",
      "CodFisco": "A207",
      "Abitanti": 1881,
      "Link": "http://www.comuni-italiani.it/055/002/"
    },
    {
      "Istat": "075004",
      "Comune": "Alliste",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "A208",
      "Abitanti": 6719,
      "Link": "http://www.comuni-italiani.it/075/004/"
    },
    {
      "Istat": "058004",
      "Comune": "Allumiere",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "00051",
      "CodFisco": "A210",
      "Abitanti": 4268,
      "Link": "http://www.comuni-italiani.it/058/004/"
    },
    {
      "Istat": "006006",
      "Comune": "Alluvioni CambiÚ",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "A211",
      "Abitanti": 986,
      "Link": "http://www.comuni-italiani.it/006/006/"
    },
    {
      "Istat": "016005",
      "Comune": "AlmË",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24011,
      "CodFisco": "A214",
      "Abitanti": 5736,
      "Link": "http://www.comuni-italiani.it/016/005/"
    },
    {
      "Istat": "016006",
      "Comune": "Almenno San Bartolomeo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "A216",
      "Abitanti": 6018,
      "Link": "http://www.comuni-italiani.it/016/006/"
    },
    {
      "Istat": "016007",
      "Comune": "Almenno San Salvatore",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24031,
      "CodFisco": "A217",
      "Abitanti": 5825,
      "Link": "http://www.comuni-italiani.it/016/007/"
    },
    {
      "Istat": "001006",
      "Comune": "Almese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "A218",
      "Abitanti": 6378,
      "Link": "http://www.comuni-italiani.it/001/006/"
    },
    {
      "Istat": "024003",
      "Comune": "Alonte",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36045,
      "CodFisco": "A220",
      "Abitanti": 1636,
      "Link": "http://www.comuni-italiani.it/024/003/"
    },
    {
      "Istat": "001007",
      "Comune": "Alpette",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "A221",
      "Abitanti": 271,
      "Link": "http://www.comuni-italiani.it/001/007/"
    },
    {
      "Istat": "001008",
      "Comune": "Alpignano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10091,
      "CodFisco": "A222",
      "Abitanti": 17097,
      "Link": "http://www.comuni-italiani.it/001/008/"
    },
    {
      "Istat": "033002",
      "Comune": "Alseno",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "A223",
      "Abitanti": 4897,
      "Link": "http://www.comuni-italiani.it/033/002/"
    },
    {
      "Istat": "013006",
      "Comune": "Alserio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22040,
      "CodFisco": "A224",
      "Abitanti": 1195,
      "Link": "http://www.comuni-italiani.it/013/006/"
    },
    {
      "Istat": "072004",
      "Comune": "Altamura",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70022,
      "CodFisco": "A225",
      "Abitanti": 69665,
      "Link": "http://www.comuni-italiani.it/072/004/"
    },
    {
      "Istat": "009005",
      "Comune": "Altare",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17041,
      "CodFisco": "A226",
      "Abitanti": 2156,
      "Link": "http://www.comuni-italiani.it/009/005/"
    },
    {
      "Istat": "064002",
      "Comune": "Altavilla Irpina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83011,
      "CodFisco": "A228",
      "Abitanti": 4185,
      "Link": "http://www.comuni-italiani.it/064/002/"
    },
    {
      "Istat": "082004",
      "Comune": "Altavilla Milicia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90010,
      "CodFisco": "A229",
      "Abitanti": 7177,
      "Link": "http://www.comuni-italiani.it/082/004/"
    },
    {
      "Istat": "006007",
      "Comune": "Altavilla Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15041,
      "CodFisco": "A227",
      "Abitanti": 506,
      "Link": "http://www.comuni-italiani.it/006/007/"
    },
    {
      "Istat": "065005",
      "Comune": "Altavilla Silentina",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84045,
      "CodFisco": "A230",
      "Abitanti": 7010,
      "Link": "http://www.comuni-italiani.it/065/005/"
    },
    {
      "Istat": "024004",
      "Comune": "Altavilla Vicentina",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36077,
      "CodFisco": "A231",
      "Abitanti": 11780,
      "Link": "http://www.comuni-italiani.it/024/004/"
    },
    {
      "Istat": 109001,
      "Comune": "Altidona",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63824,
      "CodFisco": "A233",
      "Abitanti": 3238,
      "Link": "http://www.comuni-italiani.it/109/001/"
    },
    {
      "Istat": "078008",
      "Comune": "Altilia",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "A234",
      "Abitanti": 749,
      "Link": "http://www.comuni-italiani.it/078/008/"
    },
    {
      "Istat": "069001",
      "Comune": "Altino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "A235",
      "Abitanti": 2879,
      "Link": "http://www.comuni-italiani.it/069/001/"
    },
    {
      "Istat": "024005",
      "Comune": "Altissimo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36070,
      "CodFisco": "A236",
      "Abitanti": 2312,
      "Link": "http://www.comuni-italiani.it/024/005/"
    },
    {
      "Istat": "026001",
      "Comune": "Altivole",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31030,
      "CodFisco": "A237",
      "Abitanti": 6837,
      "Link": "http://www.comuni-italiani.it/026/001/"
    },
    {
      "Istat": "004005",
      "Comune": "Alto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "A238",
      "Abitanti": 127,
      "Link": "http://www.comuni-italiani.it/004/005/"
    },
    {
      "Istat": "082005",
      "Comune": "Altofonte",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "A239",
      "Abitanti": 10316,
      "Link": "http://www.comuni-italiani.it/082/005/"
    },
    {
      "Istat": "078009",
      "Comune": "Altomonte",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87042,
      "CodFisco": "A240",
      "Abitanti": 4679,
      "Link": "http://www.comuni-italiani.it/078/009/"
    },
    {
      "Istat": "046001",
      "Comune": "Altopascio",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55011,
      "CodFisco": "A241",
      "Abitanti": 15047,
      "Link": "http://www.comuni-italiani.it/046/001/"
    },
    {
      "Istat": "055003",
      "Comune": "Alviano",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05020",
      "CodFisco": "A242",
      "Abitanti": 1546,
      "Link": "http://www.comuni-italiani.it/055/003/"
    },
    {
      "Istat": "061003",
      "Comune": "Alvignano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81012,
      "CodFisco": "A243",
      "Abitanti": 5011,
      "Link": "http://www.comuni-italiani.it/061/003/"
    },
    {
      "Istat": "060004",
      "Comune": "Alvito",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03041",
      "CodFisco": "A244",
      "Abitanti": 2885,
      "Link": "http://www.comuni-italiani.it/060/004/"
    },
    {
      "Istat": "016008",
      "Comune": "Alzano Lombardo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24022,
      "CodFisco": "A246",
      "Abitanti": 13757,
      "Link": "http://www.comuni-italiani.it/016/008/"
    },
    {
      "Istat": "006008",
      "Comune": "Alzano Scrivia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "A245",
      "Abitanti": 380,
      "Link": "http://www.comuni-italiani.it/006/008/"
    },
    {
      "Istat": "013007",
      "Comune": "Alzate Brianza",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22040,
      "CodFisco": "A249",
      "Abitanti": 5103,
      "Link": "http://www.comuni-italiani.it/013/007/"
    },
    {
      "Istat": "065006",
      "Comune": "Amalfi",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84011,
      "CodFisco": "A251",
      "Abitanti": 5317,
      "Link": "http://www.comuni-italiani.it/065/006/"
    },
    {
      "Istat": 109002,
      "Comune": "Amandola",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63857,
      "CodFisco": "A252",
      "Abitanti": 3823,
      "Link": "http://www.comuni-italiani.it/109/002/"
    },
    {
      "Istat": "078010",
      "Comune": "Amantea",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87032,
      "CodFisco": "A253",
      "Abitanti": 13925,
      "Link": "http://www.comuni-italiani.it/078/010/"
    },
    {
      "Istat": "030002",
      "Comune": "Amaro",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "A254",
      "Abitanti": 820,
      "Link": "http://www.comuni-italiani.it/030/002/"
    },
    {
      "Istat": "079003",
      "Comune": "Amaroni",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "A255",
      "Abitanti": 1918,
      "Link": "http://www.comuni-italiani.it/079/003/"
    },
    {
      "Istat": "060005",
      "Comune": "Amaseno",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03021",
      "CodFisco": "A256",
      "Abitanti": 4415,
      "Link": "http://www.comuni-italiani.it/060/005/"
    },
    {
      "Istat": "079004",
      "Comune": "Amato",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88040,
      "CodFisco": "A257",
      "Abitanti": 861,
      "Link": "http://www.comuni-italiani.it/079/004/"
    },
    {
      "Istat": "057002",
      "Comune": "Amatrice",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02012",
      "CodFisco": "A258",
      "Abitanti": 2717,
      "Link": "http://www.comuni-italiani.it/057/002/"
    },
    {
      "Istat": "016009",
      "Comune": "Ambivere",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "A259",
      "Abitanti": 2343,
      "Link": "http://www.comuni-italiani.it/016/009/"
    },
    {
      "Istat": "022004",
      "Comune": "Amblar",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38011,
      "CodFisco": "A260",
      "Abitanti": 232,
      "Link": "http://www.comuni-italiani.it/022/004/"
    },
    {
      "Istat": "011001",
      "Comune": "Ameglia",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19031,
      "CodFisco": "A261",
      "Abitanti": 4541,
      "Link": "http://www.comuni-italiani.it/011/001/"
    },
    {
      "Istat": "055004",
      "Comune": "Amelia",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05022",
      "CodFisco": "A262",
      "Abitanti": 12091,
      "Link": "http://www.comuni-italiani.it/055/004/"
    },
    {
      "Istat": "078011",
      "Comune": "Amendolara",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87071,
      "CodFisco": "A263",
      "Abitanti": 3124,
      "Link": "http://www.comuni-italiani.it/078/011/"
    },
    {
      "Istat": "003002",
      "Comune": "Ameno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "A264",
      "Abitanti": 893,
      "Link": "http://www.comuni-italiani.it/003/002/"
    },
    {
      "Istat": "062002",
      "Comune": "Amorosi",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82031,
      "CodFisco": "A265",
      "Abitanti": 2879,
      "Link": "http://www.comuni-italiani.it/062/002/"
    },
    {
      "Istat": "030003",
      "Comune": "Ampezzo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33021,
      "CodFisco": "A267",
      "Abitanti": 1058,
      "Link": "http://www.comuni-italiani.it/030/003/"
    },
    {
      "Istat": "063004",
      "Comune": "Anacapri",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80071,
      "CodFisco": "A268",
      "Abitanti": 6768,
      "Link": "http://www.comuni-italiani.it/063/004/"
    },
    {
      "Istat": "060006",
      "Comune": "Anagni",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03012",
      "CodFisco": "A269",
      "Abitanti": 21676,
      "Link": "http://www.comuni-italiani.it/060/006/"
    },
    {
      "Istat": "067002",
      "Comune": "Ancarano",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "A270",
      "Abitanti": 1921,
      "Link": "http://www.comuni-italiani.it/067/002/"
    },
    {
      "Istat": "042002",
      "Comune": "Ancona",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": "601xx",
      "CodFisco": "A271",
      "Abitanti": 102997,
      "Link": "http://www.comuni-italiani.it/042/002/"
    },
    {
      "Istat": "079005",
      "Comune": "Andali",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "A272",
      "Abitanti": 811,
      "Link": "http://www.comuni-italiani.it/079/005/"
    },
    {
      "Istat": "022005",
      "Comune": "Andalo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "A274",
      "Abitanti": 1037,
      "Link": "http://www.comuni-italiani.it/022/005/"
    },
    {
      "Istat": "014003",
      "Comune": "Andalo Valtellino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23014,
      "CodFisco": "A273",
      "Abitanti": 550,
      "Link": "http://www.comuni-italiani.it/014/003/"
    },
    {
      "Istat": "001009",
      "Comune": "Andezeno",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "A275",
      "Abitanti": 2010,
      "Link": "http://www.comuni-italiani.it/001/009/"
    },
    {
      "Istat": "009006",
      "Comune": "Andora",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17051,
      "CodFisco": "A278",
      "Abitanti": 7657,
      "Link": "http://www.comuni-italiani.it/009/006/"
    },
    {
      "Istat": "096002",
      "Comune": "Andorno Micca",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13811,
      "CodFisco": "A280",
      "Abitanti": 3481,
      "Link": "http://www.comuni-italiani.it/096/002/"
    },
    {
      "Istat": "075005",
      "Comune": "Andrano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73032,
      "CodFisco": "A281",
      "Abitanti": 5027,
      "Link": "http://www.comuni-italiani.it/075/005/"
    },
    {
      "Istat": "001010",
      "Comune": "Andrate",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "A282",
      "Abitanti": 521,
      "Link": "http://www.comuni-italiani.it/001/010/"
    },
    {
      "Istat": "093001",
      "Comune": "Andreis",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "A283",
      "Abitanti": 289,
      "Link": "http://www.comuni-italiani.it/093/001/"
    },
    {
      "Istat": "064003",
      "Comune": "Andretta",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "A284",
      "Abitanti": 2089,
      "Link": "http://www.comuni-italiani.it/064/003/"
    },
    {
      "Istat": 110001,
      "Comune": "Andria",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76123,
      "CodFisco": "A285",
      "Abitanti": 100086,
      "Link": "http://www.comuni-italiani.it/110/001/"
    },
    {
      "Istat": "021002",
      "Comune": "Andriano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39010,
      "CodFisco": "A286",
      "Abitanti": 1042,
      "Link": "http://www.comuni-italiani.it/021/002/"
    },
    {
      "Istat": "090004",
      "Comune": "Anela",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "A287",
      "Abitanti": 699,
      "Link": "http://www.comuni-italiani.it/090/004/"
    },
    {
      "Istat": "017005",
      "Comune": "Anfo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "A288",
      "Abitanti": 487,
      "Link": "http://www.comuni-italiani.it/017/005/"
    },
    {
      "Istat": "012003",
      "Comune": "Angera",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21021,
      "CodFisco": "A290",
      "Abitanti": 5694,
      "Link": "http://www.comuni-italiani.it/012/003/"
    },
    {
      "Istat": "051001",
      "Comune": "Anghiari",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52031,
      "CodFisco": "A291",
      "Abitanti": 5815,
      "Link": "http://www.comuni-italiani.it/051/001/"
    },
    {
      "Istat": "023003",
      "Comune": "Angiari",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37050,
      "CodFisco": "A292",
      "Abitanti": 2177,
      "Link": "http://www.comuni-italiani.it/023/003/"
    },
    {
      "Istat": "017006",
      "Comune": "Angolo Terme",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "A293",
      "Abitanti": 2563,
      "Link": "http://www.comuni-italiani.it/017/006/"
    },
    {
      "Istat": "065007",
      "Comune": "Angri",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84012,
      "CodFisco": "A294",
      "Abitanti": 32226,
      "Link": "http://www.comuni-italiani.it/065/007/"
    },
    {
      "Istat": "001011",
      "Comune": "Angrogna",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "A295",
      "Abitanti": 882,
      "Link": "http://www.comuni-italiani.it/001/011/"
    },
    {
      "Istat": "058005",
      "Comune": "Anguillara Sabazia",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00061",
      "CodFisco": "A297",
      "Abitanti": 18882,
      "Link": "http://www.comuni-italiani.it/058/005/"
    },
    {
      "Istat": "028004",
      "Comune": "Anguillara Veneta",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35022,
      "CodFisco": "A296",
      "Abitanti": 4624,
      "Link": "http://www.comuni-italiani.it/028/004/"
    },
    {
      "Istat": "019003",
      "Comune": "Annicco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26021,
      "CodFisco": "A299",
      "Abitanti": 2097,
      "Link": "http://www.comuni-italiani.it/019/003/"
    },
    {
      "Istat": "097003",
      "Comune": "Annone di Brianza",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23841,
      "CodFisco": "A301",
      "Abitanti": 2304,
      "Link": "http://www.comuni-italiani.it/097/003/"
    },
    {
      "Istat": "027001",
      "Comune": "Annone Veneto",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 30020,
      "CodFisco": "A302",
      "Abitanti": 3995,
      "Link": "http://www.comuni-italiani.it/027/001/"
    },
    {
      "Istat": "080003",
      "Comune": "Anoia",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "A303",
      "Abitanti": 2289,
      "Link": "http://www.comuni-italiani.it/080/003/"
    },
    {
      "Istat": "016010",
      "Comune": "Antegnate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24051,
      "CodFisco": "A304",
      "Abitanti": 3194,
      "Link": "http://www.comuni-italiani.it/016/010/"
    },
    {
      "Istat": "021003",
      "Comune": "Anterivo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "A306",
      "Abitanti": 383,
      "Link": "http://www.comuni-italiani.it/021/003/"
    },
    {
      "Istat": "007002",
      "Comune": "Antey-Saint-AndrË",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "A305",
      "Abitanti": 622,
      "Link": "http://www.comuni-italiani.it/007/002/"
    },
    {
      "Istat": "058006",
      "Comune": "Anticoli Corrado",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00022",
      "CodFisco": "A309",
      "Abitanti": 969,
      "Link": "http://www.comuni-italiani.it/058/006/"
    },
    {
      "Istat": "005003",
      "Comune": "Antignano",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "A312",
      "Abitanti": 1002,
      "Link": "http://www.comuni-italiani.it/005/003/"
    },
    {
      "Istat": "083004",
      "Comune": "Antillo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "A313",
      "Abitanti": 966,
      "Link": "http://www.comuni-italiani.it/083/004/"
    },
    {
      "Istat": "080004",
      "Comune": "Antonimina",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "A314",
      "Abitanti": 1384,
      "Link": "http://www.comuni-italiani.it/080/004/"
    },
    {
      "Istat": "057003",
      "Comune": "Antrodoco",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02013",
      "CodFisco": "A315",
      "Abitanti": 2762,
      "Link": "http://www.comuni-italiani.it/057/003/"
    },
    {
      "Istat": 103001,
      "Comune": "Antrona Schieranco",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28841,
      "CodFisco": "A317",
      "Abitanti": 482,
      "Link": "http://www.comuni-italiani.it/103/001/"
    },
    {
      "Istat": "066004",
      "Comune": "Anversa degli Abruzzi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "A318",
      "Abitanti": 389,
      "Link": "http://www.comuni-italiani.it/066/004/"
    },
    {
      "Istat": "013009",
      "Comune": "Anzano del Parco",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22040,
      "CodFisco": "A319",
      "Abitanti": 1759,
      "Link": "http://www.comuni-italiani.it/013/009/"
    },
    {
      "Istat": "071003",
      "Comune": "Anzano di Puglia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71020,
      "CodFisco": "A320",
      "Abitanti": 1759,
      "Link": "http://www.comuni-italiani.it/071/003/"
    },
    {
      "Istat": "076004",
      "Comune": "Anzi",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "A321",
      "Abitanti": 1811,
      "Link": "http://www.comuni-italiani.it/076/004/"
    },
    {
      "Istat": "058007",
      "Comune": "Anzio",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00042",
      "CodFisco": "A323",
      "Abitanti": 55413,
      "Link": "http://www.comuni-italiani.it/058/007/"
    },
    {
      "Istat": "037001",
      "Comune": "Anzola dell'Emilia",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40011,
      "CodFisco": "A324",
      "Abitanti": 11961,
      "Link": "http://www.comuni-italiani.it/037/001/"
    },
    {
      "Istat": 103002,
      "Comune": "Anzola d'Ossola",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28877,
      "CodFisco": "A325",
      "Abitanti": 458,
      "Link": "http://www.comuni-italiani.it/103/002/"
    },
    {
      "Istat": "007003",
      "Comune": "Aosta",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11100,
      "CodFisco": "A326",
      "Abitanti": 35049,
      "Link": "http://www.comuni-italiani.it/007/003/"
    },
    {
      "Istat": "041002",
      "Comune": "Apecchio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61042,
      "CodFisco": "A327",
      "Abitanti": 2054,
      "Link": "http://www.comuni-italiani.it/041/002/"
    },
    {
      "Istat": "062003",
      "Comune": "Apice",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82021,
      "CodFisco": "A328",
      "Abitanti": 5819,
      "Link": "http://www.comuni-italiani.it/062/003/"
    },
    {
      "Istat": "043002",
      "Comune": "Apiro",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62021,
      "CodFisco": "A329",
      "Abitanti": 2445,
      "Link": "http://www.comuni-italiani.it/043/002/"
    },
    {
      "Istat": "062004",
      "Comune": "Apollosa",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "A330",
      "Abitanti": 2725,
      "Link": "http://www.comuni-italiani.it/062/004/"
    },
    {
      "Istat": "013010",
      "Comune": "Appiano Gentile",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "A333",
      "Abitanti": 7726,
      "Link": "http://www.comuni-italiani.it/013/010/"
    },
    {
      "Istat": "021004",
      "Comune": "Appiano sulla Strada del Vino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39057,
      "CodFisco": "A332",
      "Abitanti": 14235,
      "Link": "http://www.comuni-italiani.it/021/004/"
    },
    {
      "Istat": "043003",
      "Comune": "Appignano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "A334",
      "Abitanti": 4293,
      "Link": "http://www.comuni-italiani.it/043/003/"
    },
    {
      "Istat": "044005",
      "Comune": "Appignano del Tronto",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63083,
      "CodFisco": "A335",
      "Abitanti": 1906,
      "Link": "http://www.comuni-italiani.it/044/005/"
    },
    {
      "Istat": "014004",
      "Comune": "Aprica",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23031,
      "CodFisco": "A337",
      "Abitanti": 1612,
      "Link": "http://www.comuni-italiani.it/014/004/"
    },
    {
      "Istat": "008002",
      "Comune": "Apricale",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18035,
      "CodFisco": "A338",
      "Abitanti": 582,
      "Link": "http://www.comuni-italiani.it/008/002/"
    },
    {
      "Istat": "071004",
      "Comune": "Apricena",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71011,
      "CodFisco": "A339",
      "Abitanti": 13694,
      "Link": "http://www.comuni-italiani.it/071/004/"
    },
    {
      "Istat": "078012",
      "Comune": "Aprigliano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87051,
      "CodFisco": "A340",
      "Abitanti": 2714,
      "Link": "http://www.comuni-italiani.it/078/012/"
    },
    {
      "Istat": "059001",
      "Comune": "Aprilia",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "04011",
      "CodFisco": "A341",
      "Abitanti": 70349,
      "Link": "http://www.comuni-italiani.it/059/001/"
    },
    {
      "Istat": "065008",
      "Comune": "Aquara",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "A343",
      "Abitanti": 1606,
      "Link": "http://www.comuni-italiani.it/065/008/"
    },
    {
      "Istat": "008003",
      "Comune": "Aquila d'Arroscia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "A344",
      "Abitanti": 177,
      "Link": "http://www.comuni-italiani.it/008/003/"
    },
    {
      "Istat": "030004",
      "Comune": "Aquileia",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33051,
      "CodFisco": "A346",
      "Abitanti": 3493,
      "Link": "http://www.comuni-italiani.it/030/004/"
    },
    {
      "Istat": "064004",
      "Comune": "Aquilonia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83041,
      "CodFisco": "A347",
      "Abitanti": 1848,
      "Link": "http://www.comuni-italiani.it/064/004/"
    },
    {
      "Istat": "060007",
      "Comune": "Aquino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03031",
      "CodFisco": "A348",
      "Abitanti": 5247,
      "Link": "http://www.comuni-italiani.it/060/007/"
    },
    {
      "Istat": "075006",
      "Comune": "Aradeo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73040,
      "CodFisco": "A350",
      "Abitanti": 9827,
      "Link": "http://www.comuni-italiani.it/075/006/"
    },
    {
      "Istat": "084003",
      "Comune": "Aragona",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92021,
      "CodFisco": "A351",
      "Abitanti": 9626,
      "Link": "http://www.comuni-italiani.it/084/003/"
    },
    {
      "Istat": "005004",
      "Comune": "Aramengo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "A352",
      "Abitanti": 642,
      "Link": "http://www.comuni-italiani.it/005/004/"
    },
    {
      "Istat": "093002",
      "Comune": "Arba",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "A354",
      "Abitanti": 1336,
      "Link": "http://www.comuni-italiani.it/093/002/"
    },
    {
      "Istat": "095006",
      "Comune": "Arborea",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09092",
      "CodFisco": "A357",
      "Abitanti": 4068,
      "Link": "http://www.comuni-italiani.it/095/006/"
    },
    {
      "Istat": "002006",
      "Comune": "Arborio",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13031,
      "CodFisco": "A358",
      "Abitanti": 939,
      "Link": "http://www.comuni-italiani.it/002/006/"
    },
    {
      "Istat": 106001,
      "Comune": "Arbus",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09031",
      "CodFisco": "A359",
      "Abitanti": 6615,
      "Link": "http://www.comuni-italiani.it/106/001/"
    },
    {
      "Istat": "026002",
      "Comune": "Arcade",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31030,
      "CodFisco": "A360",
      "Abitanti": 4407,
      "Link": "http://www.comuni-italiani.it/026/002/"
    },
    {
      "Istat": "060008",
      "Comune": "Arce",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03032",
      "CodFisco": "A363",
      "Abitanti": 5925,
      "Link": "http://www.comuni-italiani.it/060/008/"
    },
    {
      "Istat": "016011",
      "Comune": "Arcene",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "A365",
      "Abitanti": 4774,
      "Link": "http://www.comuni-italiani.it/016/011/"
    },
    {
      "Istat": "042003",
      "Comune": "Arcevia",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60011,
      "CodFisco": "A366",
      "Abitanti": 5022,
      "Link": "http://www.comuni-italiani.it/042/003/"
    },
    {
      "Istat": "069002",
      "Comune": "Archi",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66044,
      "CodFisco": "A367",
      "Abitanti": 2294,
      "Link": "http://www.comuni-italiani.it/069/002/"
    },
    {
      "Istat": "053001",
      "Comune": "Arcidosso",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58031,
      "CodFisco": "A369",
      "Abitanti": 4427,
      "Link": "http://www.comuni-italiani.it/053/001/"
    },
    {
      "Istat": "058008",
      "Comune": "Arcinazzo Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "A370",
      "Abitanti": 1466,
      "Link": "http://www.comuni-italiani.it/058/008/"
    },
    {
      "Istat": "012004",
      "Comune": "Arcisate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21051,
      "CodFisco": "A371",
      "Abitanti": 9991,
      "Link": "http://www.comuni-italiani.it/012/004/"
    },
    {
      "Istat": "022006",
      "Comune": "Arco",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38062,
      "CodFisco": "A372",
      "Abitanti": 16901,
      "Link": "http://www.comuni-italiani.it/022/006/"
    },
    {
      "Istat": "011002",
      "Comune": "Arcola",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19021,
      "CodFisco": "A373",
      "Abitanti": 10507,
      "Link": "http://www.comuni-italiani.it/011/002/"
    },
    {
      "Istat": "023004",
      "Comune": "Arcole",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37040,
      "CodFisco": "A374",
      "Abitanti": 6253,
      "Link": "http://www.comuni-italiani.it/023/004/"
    },
    {
      "Istat": "015007",
      "Comune": "Arconate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "A375",
      "Abitanti": 6499,
      "Link": "http://www.comuni-italiani.it/015/007/"
    },
    {
      "Istat": 108004,
      "Comune": "Arcore",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20862,
      "CodFisco": "A376",
      "Abitanti": 17750,
      "Link": "http://www.comuni-italiani.it/108/004/"
    },
    {
      "Istat": "024006",
      "Comune": "Arcugnano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36057,
      "CodFisco": "A377",
      "Abitanti": 7965,
      "Link": "http://www.comuni-italiani.it/024/006/"
    },
    {
      "Istat": "090005",
      "Comune": "Ardara",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "A379",
      "Abitanti": 810,
      "Link": "http://www.comuni-italiani.it/090/005/"
    },
    {
      "Istat": "095007",
      "Comune": "Ardauli",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09081",
      "CodFisco": "A380",
      "Abitanti": 960,
      "Link": "http://www.comuni-italiani.it/095/007/"
    },
    {
      "Istat": "058117",
      "Comune": "Ardea",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "M213",
      "Abitanti": 42879,
      "Link": "http://www.comuni-italiani.it/058/117/"
    },
    {
      "Istat": "014005",
      "Comune": "Ardenno",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23011,
      "CodFisco": "A382",
      "Abitanti": 3302,
      "Link": "http://www.comuni-italiani.it/014/005/"
    },
    {
      "Istat": "016012",
      "Comune": "Ardesio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "A383",
      "Abitanti": 3651,
      "Link": "http://www.comuni-italiani.it/016/012/"
    },
    {
      "Istat": "080005",
      "Comune": "Ardore",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89031,
      "CodFisco": "A385",
      "Abitanti": 4977,
      "Link": "http://www.comuni-italiani.it/080/005/"
    },
    {
      "Istat": 102002,
      "Comune": "Arena",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89832,
      "CodFisco": "A386",
      "Abitanti": 1572,
      "Link": "http://www.comuni-italiani.it/102/002/"
    },
    {
      "Istat": "018005",
      "Comune": "Arena Po",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "A387",
      "Abitanti": 1660,
      "Link": "http://www.comuni-italiani.it/018/005/"
    },
    {
      "Istat": "010001",
      "Comune": "Arenzano",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16011,
      "CodFisco": "A388",
      "Abitanti": 11724,
      "Link": "http://www.comuni-italiani.it/010/001/"
    },
    {
      "Istat": "015009",
      "Comune": "Arese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20020,
      "CodFisco": "A389",
      "Abitanti": 19506,
      "Link": "http://www.comuni-italiani.it/015/009/"
    },
    {
      "Istat": "051002",
      "Comune": "Arezzo",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52100,
      "CodFisco": "A390",
      "Abitanti": 100212,
      "Link": "http://www.comuni-italiani.it/051/002/"
    },
    {
      "Istat": "013011",
      "Comune": "Argegno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22010,
      "CodFisco": "A391",
      "Abitanti": 693,
      "Link": "http://www.comuni-italiani.it/013/011/"
    },
    {
      "Istat": "037002",
      "Comune": "Argelato",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "A392",
      "Abitanti": 9744,
      "Link": "http://www.comuni-italiani.it/037/002/"
    },
    {
      "Istat": "038001",
      "Comune": "Argenta",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44011,
      "CodFisco": "A393",
      "Abitanti": 22575,
      "Link": "http://www.comuni-italiani.it/038/001/"
    },
    {
      "Istat": "004006",
      "Comune": "Argentera",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "A394",
      "Abitanti": 78,
      "Link": "http://www.comuni-italiani.it/004/006/"
    },
    {
      "Istat": "004007",
      "Comune": "Arguello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "A396",
      "Abitanti": 200,
      "Link": "http://www.comuni-italiani.it/004/007/"
    },
    {
      "Istat": "079007",
      "Comune": "Argusto",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "A397",
      "Abitanti": 538,
      "Link": "http://www.comuni-italiani.it/079/007/"
    },
    {
      "Istat": "069003",
      "Comune": "Ari",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "A398",
      "Abitanti": 1208,
      "Link": "http://www.comuni-italiani.it/069/003/"
    },
    {
      "Istat": "064005",
      "Comune": "Ariano Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83031,
      "CodFisco": "A399",
      "Abitanti": 23134,
      "Link": "http://www.comuni-italiani.it/064/005/"
    },
    {
      "Istat": "029002",
      "Comune": "Ariano nel Polesine",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45012,
      "CodFisco": "A400",
      "Abitanti": 4656,
      "Link": "http://www.comuni-italiani.it/029/002/"
    },
    {
      "Istat": "058009",
      "Comune": "Ariccia",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "A401",
      "Abitanti": 18555,
      "Link": "http://www.comuni-italiani.it/058/009/"
    },
    {
      "Istat": "069004",
      "Comune": "Arielli",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66030,
      "CodFisco": "A402",
      "Abitanti": 1143,
      "Link": "http://www.comuni-italiani.it/069/004/"
    },
    {
      "Istat": "061004",
      "Comune": "Arienzo",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81021,
      "CodFisco": "A403",
      "Abitanti": 5364,
      "Link": "http://www.comuni-italiani.it/061/004/"
    },
    {
      "Istat": "001012",
      "Comune": "Arignano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "A405",
      "Abitanti": 1057,
      "Link": "http://www.comuni-italiani.it/001/012/"
    },
    {
      "Istat": "091001",
      "Comune": "Aritzo",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08031",
      "CodFisco": "A407",
      "Abitanti": 1361,
      "Link": "http://www.comuni-italiani.it/091/001/"
    },
    {
      "Istat": 103003,
      "Comune": "Arizzano",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28811,
      "CodFisco": "A409",
      "Abitanti": 2080,
      "Link": "http://www.comuni-italiani.it/103/003/"
    },
    {
      "Istat": "056002",
      "Comune": "Arlena di Castro",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "A412",
      "Abitanti": 903,
      "Link": "http://www.comuni-italiani.it/056/002/"
    },
    {
      "Istat": "015010",
      "Comune": "Arluno",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "A413",
      "Abitanti": 11601,
      "Link": "http://www.comuni-italiani.it/015/010/"
    },
    {
      "Istat": "003006",
      "Comune": "Armeno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28011,
      "CodFisco": "A414",
      "Abitanti": 2261,
      "Link": "http://www.comuni-italiani.it/003/006/"
    },
    {
      "Istat": "076005",
      "Comune": "Armento",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "A415",
      "Abitanti": 696,
      "Link": "http://www.comuni-italiani.it/076/005/"
    },
    {
      "Istat": "008004",
      "Comune": "Armo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18026,
      "CodFisco": "A418",
      "Abitanti": 121,
      "Link": "http://www.comuni-italiani.it/008/004/"
    },
    {
      "Istat": "092002",
      "Comune": "Armungia",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "A419",
      "Abitanti": 498,
      "Link": "http://www.comuni-italiani.it/092/002/"
    },
    {
      "Istat": "007004",
      "Comune": "Arnad",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "A424",
      "Abitanti": 1323,
      "Link": "http://www.comuni-italiani.it/007/004/"
    },
    {
      "Istat": "060009",
      "Comune": "Arnara",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "A421",
      "Abitanti": 2395,
      "Link": "http://www.comuni-italiani.it/060/009/"
    },
    {
      "Istat": "009007",
      "Comune": "Arnasco",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17032,
      "CodFisco": "A422",
      "Abitanti": 621,
      "Link": "http://www.comuni-italiani.it/009/007/"
    },
    {
      "Istat": "075007",
      "Comune": "Arnesano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "A425",
      "Abitanti": 3981,
      "Link": "http://www.comuni-italiani.it/075/007/"
    },
    {
      "Istat": 103004,
      "Comune": "Arola",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28899,
      "CodFisco": "A427",
      "Abitanti": 269,
      "Link": "http://www.comuni-italiani.it/103/004/"
    },
    {
      "Istat": "003008",
      "Comune": "Arona",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28041,
      "CodFisco": "A429",
      "Abitanti": 14547,
      "Link": "http://www.comuni-italiani.it/003/008/"
    },
    {
      "Istat": "013012",
      "Comune": "Arosio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "A430",
      "Abitanti": 4964,
      "Link": "http://www.comuni-italiani.it/013/012/"
    },
    {
      "Istat": "062005",
      "Comune": "Arpaia",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82011,
      "CodFisco": "A431",
      "Abitanti": 2001,
      "Link": "http://www.comuni-italiani.it/062/005/"
    },
    {
      "Istat": "062006",
      "Comune": "Arpaise",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82010,
      "CodFisco": "A432",
      "Abitanti": 858,
      "Link": "http://www.comuni-italiani.it/062/006/"
    },
    {
      "Istat": "060010",
      "Comune": "Arpino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03033",
      "CodFisco": "A433",
      "Abitanti": 7552,
      "Link": "http://www.comuni-italiani.it/060/010/"
    },
    {
      "Istat": "028005",
      "Comune": "Arqu‡ Petrarca",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35032,
      "CodFisco": "A434",
      "Abitanti": 1860,
      "Link": "http://www.comuni-italiani.it/028/005/"
    },
    {
      "Istat": "029003",
      "Comune": "Arqu‡ Polesine",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45031,
      "CodFisco": "A435",
      "Abitanti": 2850,
      "Link": "http://www.comuni-italiani.it/029/003/"
    },
    {
      "Istat": "044006",
      "Comune": "Arquata del Tronto",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63096,
      "CodFisco": "A437",
      "Abitanti": 1302,
      "Link": "http://www.comuni-italiani.it/044/006/"
    },
    {
      "Istat": "006009",
      "Comune": "Arquata Scrivia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15061,
      "CodFisco": "A436",
      "Abitanti": 6260,
      "Link": "http://www.comuni-italiani.it/006/009/"
    },
    {
      "Istat": "028006",
      "Comune": "Arre",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "A438",
      "Abitanti": 2179,
      "Link": "http://www.comuni-italiani.it/028/006/"
    },
    {
      "Istat": "055005",
      "Comune": "Arrone",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05031",
      "CodFisco": "A439",
      "Abitanti": 2918,
      "Link": "http://www.comuni-italiani.it/055/005/"
    },
    {
      "Istat": "012005",
      "Comune": "Arsago Seprio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21010,
      "CodFisco": "A441",
      "Abitanti": 4886,
      "Link": "http://www.comuni-italiani.it/012/005/"
    },
    {
      "Istat": "025004",
      "Comune": "ArsiË",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "A443",
      "Abitanti": 2535,
      "Link": "http://www.comuni-italiani.it/025/004/"
    },
    {
      "Istat": "024007",
      "Comune": "Arsiero",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36011,
      "CodFisco": "A444",
      "Abitanti": 3323,
      "Link": "http://www.comuni-italiani.it/024/007/"
    },
    {
      "Istat": "067003",
      "Comune": "Arsita",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64031,
      "CodFisco": "A445",
      "Abitanti": 889,
      "Link": "http://www.comuni-italiani.it/067/003/"
    },
    {
      "Istat": "058010",
      "Comune": "Arsoli",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00023",
      "CodFisco": "A446",
      "Abitanti": 1670,
      "Link": "http://www.comuni-italiani.it/058/010/"
    },
    {
      "Istat": "030005",
      "Comune": "Arta Terme",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33022,
      "CodFisco": "A447",
      "Abitanti": 2273,
      "Link": "http://www.comuni-italiani.it/030/005/"
    },
    {
      "Istat": "030006",
      "Comune": "Artegna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33011,
      "CodFisco": "A448",
      "Abitanti": 2912,
      "Link": "http://www.comuni-italiani.it/030/006/"
    },
    {
      "Istat": "058011",
      "Comune": "Artena",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00031",
      "CodFisco": "A449",
      "Abitanti": 13959,
      "Link": "http://www.comuni-italiani.it/058/011/"
    },
    {
      "Istat": "017007",
      "Comune": "Artogne",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "A451",
      "Abitanti": 3545,
      "Link": "http://www.comuni-italiani.it/017/007/"
    },
    {
      "Istat": "007005",
      "Comune": "Arvier",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11011,
      "CodFisco": "A452",
      "Abitanti": 900,
      "Link": "http://www.comuni-italiani.it/007/005/"
    },
    {
      "Istat": 104004,
      "Comune": "Arzachena",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07021",
      "CodFisco": "A453",
      "Abitanti": 13317,
      "Link": "http://www.comuni-italiani.it/104/004/"
    },
    {
      "Istat": "016013",
      "Comune": "Arzago d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "A440",
      "Abitanti": 2811,
      "Link": "http://www.comuni-italiani.it/016/013/"
    },
    {
      "Istat": 105001,
      "Comune": "Arzana",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "A454",
      "Abitanti": 2526,
      "Link": "http://www.comuni-italiani.it/105/001/"
    },
    {
      "Istat": "063005",
      "Comune": "Arzano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80022,
      "CodFisco": "A455",
      "Abitanti": 35926,
      "Link": "http://www.comuni-italiani.it/063/005/"
    },
    {
      "Istat": "093003",
      "Comune": "Arzene",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33098,
      "CodFisco": "A456",
      "Abitanti": 1808,
      "Link": "http://www.comuni-italiani.it/093/003/"
    },
    {
      "Istat": "028007",
      "Comune": "Arzergrande",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "A458",
      "Abitanti": 4755,
      "Link": "http://www.comuni-italiani.it/028/007/"
    },
    {
      "Istat": "024008",
      "Comune": "Arzignano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36071,
      "CodFisco": "A459",
      "Abitanti": 26046,
      "Link": "http://www.comuni-italiani.it/024/008/"
    },
    {
      "Istat": "065009",
      "Comune": "Ascea",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84046,
      "CodFisco": "A460",
      "Abitanti": 5830,
      "Link": "http://www.comuni-italiani.it/065/009/"
    },
    {
      "Istat": "052002",
      "Comune": "Asciano",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53041,
      "CodFisco": "A461",
      "Abitanti": 7299,
      "Link": "http://www.comuni-italiani.it/052/002/"
    },
    {
      "Istat": "044007",
      "Comune": "Ascoli Piceno",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63100,
      "CodFisco": "A462",
      "Abitanti": 51168,
      "Link": "http://www.comuni-italiani.it/044/007/"
    },
    {
      "Istat": "071005",
      "Comune": "Ascoli Satriano",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71022,
      "CodFisco": "A463",
      "Abitanti": 6390,
      "Link": "http://www.comuni-italiani.it/071/005/"
    },
    {
      "Istat": "057004",
      "Comune": "Ascrea",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "A464",
      "Abitanti": 279,
      "Link": "http://www.comuni-italiani.it/057/004/"
    },
    {
      "Istat": "024009",
      "Comune": "Asiago",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36012,
      "CodFisco": "A465",
      "Abitanti": 6485,
      "Link": "http://www.comuni-italiani.it/024/009/"
    },
    {
      "Istat": "024010",
      "Comune": "Asigliano Veneto",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36020,
      "CodFisco": "A467",
      "Abitanti": 891,
      "Link": "http://www.comuni-italiani.it/024/010/"
    },
    {
      "Istat": "002007",
      "Comune": "Asigliano Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13032,
      "CodFisco": "A466",
      "Abitanti": 1390,
      "Link": "http://www.comuni-italiani.it/002/007/"
    },
    {
      "Istat": "020002",
      "Comune": "Asola",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46041,
      "CodFisco": "A470",
      "Abitanti": 10127,
      "Link": "http://www.comuni-italiani.it/020/002/"
    },
    {
      "Istat": "026003",
      "Comune": "Asolo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31011,
      "CodFisco": "A471",
      "Abitanti": 9311,
      "Link": "http://www.comuni-italiani.it/026/003/"
    },
    {
      "Istat": "015011",
      "Comune": "Assago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "A473",
      "Abitanti": 8168,
      "Link": "http://www.comuni-italiani.it/015/011/"
    },
    {
      "Istat": "092003",
      "Comune": "Assemini",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09032",
      "CodFisco": "A474",
      "Abitanti": 26979,
      "Link": "http://www.comuni-italiani.it/092/003/"
    },
    {
      "Istat": "054001",
      "Comune": "Assisi",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06081",
      "CodFisco": "A475",
      "Abitanti": 28143,
      "Link": "http://www.comuni-italiani.it/054/001/"
    },
    {
      "Istat": "013013",
      "Comune": "Asso",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22033,
      "CodFisco": "A476",
      "Abitanti": 3665,
      "Link": "http://www.comuni-italiani.it/013/013/"
    },
    {
      "Istat": "095008",
      "Comune": "Assolo",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "A477",
      "Abitanti": 444,
      "Link": "http://www.comuni-italiani.it/095/008/"
    },
    {
      "Istat": "086003",
      "Comune": "Assoro",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "A478",
      "Abitanti": 5389,
      "Link": "http://www.comuni-italiani.it/086/003/"
    },
    {
      "Istat": "005005",
      "Comune": "Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14100,
      "CodFisco": "A479",
      "Abitanti": 76534,
      "Link": "http://www.comuni-italiani.it/005/005/"
    },
    {
      "Istat": "095009",
      "Comune": "Asuni",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "A480",
      "Abitanti": 365,
      "Link": "http://www.comuni-italiani.it/095/009/"
    },
    {
      "Istat": "066005",
      "Comune": "Ateleta",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "A481",
      "Abitanti": 1174,
      "Link": "http://www.comuni-italiani.it/066/005/"
    },
    {
      "Istat": "076006",
      "Comune": "Atella",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85020,
      "CodFisco": "A482",
      "Abitanti": 3883,
      "Link": "http://www.comuni-italiani.it/076/006/"
    },
    {
      "Istat": "065010",
      "Comune": "Atena Lucana",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "A484",
      "Abitanti": 2344,
      "Link": "http://www.comuni-italiani.it/065/010/"
    },
    {
      "Istat": "069005",
      "Comune": "Atessa",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66041,
      "CodFisco": "A485",
      "Abitanti": 10775,
      "Link": "http://www.comuni-italiani.it/069/005/"
    },
    {
      "Istat": "060011",
      "Comune": "Atina",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03042",
      "CodFisco": "A486",
      "Abitanti": 4519,
      "Link": "http://www.comuni-italiani.it/060/011/"
    },
    {
      "Istat": "065011",
      "Comune": "Atrani",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "A487",
      "Abitanti": 913,
      "Link": "http://www.comuni-italiani.it/065/011/"
    },
    {
      "Istat": "067004",
      "Comune": "Atri",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64032,
      "CodFisco": "A488",
      "Abitanti": 11239,
      "Link": "http://www.comuni-italiani.it/067/004/"
    },
    {
      "Istat": "064006",
      "Comune": "Atripalda",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83042,
      "CodFisco": "A489",
      "Abitanti": 11174,
      "Link": "http://www.comuni-italiani.it/064/006/"
    },
    {
      "Istat": "055006",
      "Comune": "Attigliano",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05012",
      "CodFisco": "A490",
      "Abitanti": 1967,
      "Link": "http://www.comuni-italiani.it/055/006/"
    },
    {
      "Istat": "030007",
      "Comune": "Attimis",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "A491",
      "Abitanti": 1893,
      "Link": "http://www.comuni-italiani.it/030/007/"
    },
    {
      "Istat": "091003",
      "Comune": "Atzara",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08030",
      "CodFisco": "A492",
      "Abitanti": 1224,
      "Link": "http://www.comuni-italiani.it/091/003/"
    },
    {
      "Istat": "041003",
      "Comune": "Auditore",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61020,
      "CodFisco": "A493",
      "Abitanti": 1646,
      "Link": "http://www.comuni-italiani.it/041/003/"
    },
    {
      "Istat": "089001",
      "Comune": "Augusta",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96011,
      "CodFisco": "A494",
      "Abitanti": 34539,
      "Link": "http://www.comuni-italiani.it/089/001/"
    },
    {
      "Istat": "065012",
      "Comune": "Auletta",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84031,
      "CodFisco": "A495",
      "Abitanti": 2440,
      "Link": "http://www.comuni-italiani.it/065/012/"
    },
    {
      "Istat": "045001",
      "Comune": "Aulla",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54011,
      "CodFisco": "A496",
      "Abitanti": 11312,
      "Link": "http://www.comuni-italiani.it/045/001/"
    },
    {
      "Istat": 103005,
      "Comune": "Aurano",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28812,
      "CodFisco": "A497",
      "Abitanti": 112,
      "Link": "http://www.comuni-italiani.it/103/005/"
    },
    {
      "Istat": "008005",
      "Comune": "Aurigo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "A499",
      "Abitanti": 357,
      "Link": "http://www.comuni-italiani.it/008/005/"
    },
    {
      "Istat": "025005",
      "Comune": "Auronzo di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32041,
      "CodFisco": "A501",
      "Abitanti": 3513,
      "Link": "http://www.comuni-italiani.it/025/005/"
    },
    {
      "Istat": "060012",
      "Comune": "Ausonia",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "A502",
      "Abitanti": 2636,
      "Link": "http://www.comuni-italiani.it/060/012/"
    },
    {
      "Istat": "091004",
      "Comune": "Austis",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08030",
      "CodFisco": "A503",
      "Abitanti": 881,
      "Link": "http://www.comuni-italiani.it/091/004/"
    },
    {
      "Istat": "010002",
      "Comune": "Avegno",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "A506",
      "Abitanti": 2527,
      "Link": "http://www.comuni-italiani.it/010/002/"
    },
    {
      "Istat": "021005",
      "Comune": "Avelengo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "A507",
      "Abitanti": 752,
      "Link": "http://www.comuni-italiani.it/021/005/"
    },
    {
      "Istat": "064007",
      "Comune": "Avella",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83021,
      "CodFisco": "A508",
      "Abitanti": 7958,
      "Link": "http://www.comuni-italiani.it/064/007/"
    },
    {
      "Istat": "064008",
      "Comune": "Avellino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83100,
      "CodFisco": "A509",
      "Abitanti": 56339,
      "Link": "http://www.comuni-italiani.it/064/008/"
    },
    {
      "Istat": "016014",
      "Comune": "Averara",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "A511",
      "Abitanti": 185,
      "Link": "http://www.comuni-italiani.it/016/014/"
    },
    {
      "Istat": "061005",
      "Comune": "Aversa",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81031,
      "CodFisco": "A512",
      "Abitanti": 51631,
      "Link": "http://www.comuni-italiani.it/061/005/"
    },
    {
      "Istat": "073001",
      "Comune": "Avetrana",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "A514",
      "Abitanti": 7079,
      "Link": "http://www.comuni-italiani.it/073/001/"
    },
    {
      "Istat": "066006",
      "Comune": "Avezzano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67051,
      "CodFisco": "A515",
      "Abitanti": 42029,
      "Link": "http://www.comuni-italiani.it/066/006/"
    },
    {
      "Istat": "093004",
      "Comune": "Aviano",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33081,
      "CodFisco": "A516",
      "Abitanti": 9270,
      "Link": "http://www.comuni-italiani.it/093/004/"
    },
    {
      "Istat": "016015",
      "Comune": "Aviatico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "A517",
      "Abitanti": 525,
      "Link": "http://www.comuni-italiani.it/016/015/"
    },
    {
      "Istat": "001013",
      "Comune": "Avigliana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10051,
      "CodFisco": "A518",
      "Abitanti": 12367,
      "Link": "http://www.comuni-italiani.it/001/013/"
    },
    {
      "Istat": "076007",
      "Comune": "Avigliano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85021,
      "CodFisco": "A519",
      "Abitanti": 11948,
      "Link": "http://www.comuni-italiani.it/076/007/"
    },
    {
      "Istat": "055033",
      "Comune": "Avigliano Umbro",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05020",
      "CodFisco": "M258",
      "Abitanti": 2671,
      "Link": "http://www.comuni-italiani.it/055/033/"
    },
    {
      "Istat": "022007",
      "Comune": "Avio",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38063,
      "CodFisco": "A520",
      "Abitanti": 4122,
      "Link": "http://www.comuni-italiani.it/022/007/"
    },
    {
      "Istat": "007006",
      "Comune": "Avise",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "A521",
      "Abitanti": 340,
      "Link": "http://www.comuni-italiani.it/007/006/"
    },
    {
      "Istat": "089002",
      "Comune": "Avola",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96012,
      "CodFisco": "A522",
      "Abitanti": 31827,
      "Link": "http://www.comuni-italiani.it/089/002/"
    },
    {
      "Istat": "006010",
      "Comune": "Avolasca",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "A523",
      "Abitanti": 298,
      "Link": "http://www.comuni-italiani.it/006/010/"
    },
    {
      "Istat": "007007",
      "Comune": "Ayas",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "A094",
      "Abitanti": 1359,
      "Link": "http://www.comuni-italiani.it/007/007/"
    },
    {
      "Istat": "007008",
      "Comune": "Aymavilles",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "A108",
      "Abitanti": 2065,
      "Link": "http://www.comuni-italiani.it/007/008/"
    },
    {
      "Istat": "001014",
      "Comune": "Azeglio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "A525",
      "Abitanti": 1375,
      "Link": "http://www.comuni-italiani.it/001/014/"
    },
    {
      "Istat": "019004",
      "Comune": "Azzanello",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26010,
      "CodFisco": "A526",
      "Abitanti": 744,
      "Link": "http://www.comuni-italiani.it/019/004/"
    },
    {
      "Istat": "005006",
      "Comune": "Azzano d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "A527",
      "Abitanti": 418,
      "Link": "http://www.comuni-italiani.it/005/006/"
    },
    {
      "Istat": "093005",
      "Comune": "Azzano Decimo",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33082,
      "CodFisco": "A530",
      "Abitanti": 15601,
      "Link": "http://www.comuni-italiani.it/093/005/"
    },
    {
      "Istat": "017008",
      "Comune": "Azzano Mella",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "A529",
      "Abitanti": 2900,
      "Link": "http://www.comuni-italiani.it/017/008/"
    },
    {
      "Istat": "016016",
      "Comune": "Azzano San Paolo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24052,
      "CodFisco": "A528",
      "Abitanti": 7685,
      "Link": "http://www.comuni-italiani.it/016/016/"
    },
    {
      "Istat": "012006",
      "Comune": "Azzate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21022,
      "CodFisco": "A531",
      "Abitanti": 4520,
      "Link": "http://www.comuni-italiani.it/012/006/"
    },
    {
      "Istat": "012007",
      "Comune": "Azzio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "A532",
      "Abitanti": 808,
      "Link": "http://www.comuni-italiani.it/012/007/"
    },
    {
      "Istat": "016017",
      "Comune": "Azzone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "A533",
      "Abitanti": 437,
      "Link": "http://www.comuni-italiani.it/016/017/"
    },
    {
      "Istat": 103006,
      "Comune": "Baceno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28861,
      "CodFisco": "A534",
      "Abitanti": 928,
      "Link": "http://www.comuni-italiani.it/103/006/"
    },
    {
      "Istat": "063006",
      "Comune": "Bacoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80070,
      "CodFisco": "A535",
      "Abitanti": 27267,
      "Link": "http://www.comuni-italiani.it/063/006/"
    },
    {
      "Istat": "008006",
      "Comune": "Badalucco",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "A536",
      "Abitanti": 1221,
      "Link": "http://www.comuni-italiani.it/008/006/"
    },
    {
      "Istat": 104005,
      "Comune": "Badesi",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "M214",
      "Abitanti": 1909,
      "Link": "http://www.comuni-italiani.it/104/005/"
    },
    {
      "Istat": "021006",
      "Comune": "Badia",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39036,
      "CodFisco": "A537",
      "Abitanti": 3369,
      "Link": "http://www.comuni-italiani.it/021/006/"
    },
    {
      "Istat": "023005",
      "Comune": "Badia Calavena",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "A540",
      "Abitanti": 2675,
      "Link": "http://www.comuni-italiani.it/023/005/"
    },
    {
      "Istat": "018006",
      "Comune": "Badia Pavese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "A538",
      "Abitanti": 407,
      "Link": "http://www.comuni-italiani.it/018/006/"
    },
    {
      "Istat": "029004",
      "Comune": "Badia Polesine",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45021,
      "CodFisco": "A539",
      "Abitanti": 10865,
      "Link": "http://www.comuni-italiani.it/029/004/"
    },
    {
      "Istat": "051003",
      "Comune": "Badia Tedalda",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52032,
      "CodFisco": "A541",
      "Abitanti": 1118,
      "Link": "http://www.comuni-italiani.it/051/003/"
    },
    {
      "Istat": "079008",
      "Comune": "Badolato",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "A542",
      "Abitanti": 3268,
      "Link": "http://www.comuni-italiani.it/079/008/"
    },
    {
      "Istat": "080006",
      "Comune": "Bagaladi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89060,
      "CodFisco": "A544",
      "Abitanti": 1132,
      "Link": "http://www.comuni-italiani.it/080/006/"
    },
    {
      "Istat": "082006",
      "Comune": "Bagheria",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90011,
      "CodFisco": "A546",
      "Abitanti": 56336,
      "Link": "http://www.comuni-italiani.it/082/006/"
    },
    {
      "Istat": "039002",
      "Comune": "Bagnacavallo",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48012,
      "CodFisco": "A547",
      "Abitanti": 16665,
      "Link": "http://www.comuni-italiani.it/039/002/"
    },
    {
      "Istat": "080007",
      "Comune": "Bagnara Calabra",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89011,
      "CodFisco": "A552",
      "Abitanti": 10660,
      "Link": "http://www.comuni-italiani.it/080/007/"
    },
    {
      "Istat": "039003",
      "Comune": "Bagnara di Romagna",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48010,
      "CodFisco": "A551",
      "Abitanti": 2330,
      "Link": "http://www.comuni-italiani.it/039/003/"
    },
    {
      "Istat": "018007",
      "Comune": "Bagnaria",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "A550",
      "Abitanti": 684,
      "Link": "http://www.comuni-italiani.it/018/007/"
    },
    {
      "Istat": "030008",
      "Comune": "Bagnaria Arsa",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "A553",
      "Abitanti": 3595,
      "Link": "http://www.comuni-italiani.it/030/008/"
    },
    {
      "Istat": "004008",
      "Comune": "Bagnasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12071,
      "CodFisco": "A555",
      "Abitanti": 1054,
      "Link": "http://www.comuni-italiani.it/004/008/"
    },
    {
      "Istat": "016018",
      "Comune": "Bagnatica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "A557",
      "Abitanti": 4190,
      "Link": "http://www.comuni-italiani.it/016/018/"
    },
    {
      "Istat": "046002",
      "Comune": "Bagni di Lucca",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55022,
      "CodFisco": "A560",
      "Abitanti": 6528,
      "Link": "http://www.comuni-italiani.it/046/002/"
    },
    {
      "Istat": "048001",
      "Comune": "Bagno a Ripoli",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50012,
      "CodFisco": "A564",
      "Abitanti": 25787,
      "Link": "http://www.comuni-italiani.it/048/001/"
    },
    {
      "Istat": "040001",
      "Comune": "Bagno di Romagna",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47021,
      "CodFisco": "A565",
      "Abitanti": 6212,
      "Link": "http://www.comuni-italiani.it/040/001/"
    },
    {
      "Istat": "094003",
      "Comune": "Bagnoli del Trigno",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86091,
      "CodFisco": "A567",
      "Abitanti": 784,
      "Link": "http://www.comuni-italiani.it/094/003/"
    },
    {
      "Istat": "028008",
      "Comune": "Bagnoli di Sopra",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35023,
      "CodFisco": "A568",
      "Abitanti": 3762,
      "Link": "http://www.comuni-italiani.it/028/008/"
    },
    {
      "Istat": "064009",
      "Comune": "Bagnoli Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83043,
      "CodFisco": "A566",
      "Abitanti": 3286,
      "Link": "http://www.comuni-italiani.it/064/009/"
    },
    {
      "Istat": "019005",
      "Comune": "Bagnolo Cremasco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "A570",
      "Abitanti": 4841,
      "Link": "http://www.comuni-italiani.it/019/005/"
    },
    {
      "Istat": "075008",
      "Comune": "Bagnolo del Salento",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "A572",
      "Abitanti": 1880,
      "Link": "http://www.comuni-italiani.it/075/008/"
    },
    {
      "Istat": "029005",
      "Comune": "Bagnolo di Po",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45022,
      "CodFisco": "A574",
      "Abitanti": 1434,
      "Link": "http://www.comuni-italiani.it/029/005/"
    },
    {
      "Istat": "035002",
      "Comune": "Bagnolo in Piano",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42011,
      "CodFisco": "A573",
      "Abitanti": 9536,
      "Link": "http://www.comuni-italiani.it/035/002/"
    },
    {
      "Istat": "017009",
      "Comune": "Bagnolo Mella",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25021,
      "CodFisco": "A569",
      "Abitanti": 12969,
      "Link": "http://www.comuni-italiani.it/017/009/"
    },
    {
      "Istat": "004009",
      "Comune": "Bagnolo Piemonte",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12031,
      "CodFisco": "A571",
      "Abitanti": 6066,
      "Link": "http://www.comuni-italiani.it/004/009/"
    },
    {
      "Istat": "020003",
      "Comune": "Bagnolo San Vito",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46031,
      "CodFisco": "A575",
      "Abitanti": 5926,
      "Link": "http://www.comuni-italiani.it/020/003/"
    },
    {
      "Istat": "045002",
      "Comune": "Bagnone",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54021,
      "CodFisco": "A576",
      "Abitanti": 1930,
      "Link": "http://www.comuni-italiani.it/045/002/"
    },
    {
      "Istat": "056003",
      "Comune": "Bagnoregio",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01022",
      "CodFisco": "A577",
      "Abitanti": 3678,
      "Link": "http://www.comuni-italiani.it/056/003/"
    },
    {
      "Istat": "017010",
      "Comune": "Bagolino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25072,
      "CodFisco": "A578",
      "Abitanti": 3968,
      "Link": "http://www.comuni-italiani.it/017/010/"
    },
    {
      "Istat": "061006",
      "Comune": "Baia e Latina",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "A579",
      "Abitanti": 2349,
      "Link": "http://www.comuni-italiani.it/061/006/"
    },
    {
      "Istat": "064010",
      "Comune": "Baiano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83022,
      "CodFisco": "A580",
      "Abitanti": 4763,
      "Link": "http://www.comuni-italiani.it/064/010/"
    },
    {
      "Istat": "001015",
      "Comune": "Bairo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10010,
      "CodFisco": "A584",
      "Abitanti": 819,
      "Link": "http://www.comuni-italiani.it/001/015/"
    },
    {
      "Istat": "035003",
      "Comune": "Baiso",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42031,
      "CodFisco": "A586",
      "Abitanti": 3440,
      "Link": "http://www.comuni-italiani.it/035/003/"
    },
    {
      "Istat": "008007",
      "Comune": "Bajardo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18031,
      "CodFisco": "A581",
      "Abitanti": 342,
      "Link": "http://www.comuni-italiani.it/008/007/"
    },
    {
      "Istat": "001016",
      "Comune": "Balangero",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "A587",
      "Abitanti": 3178,
      "Link": "http://www.comuni-italiani.it/001/016/"
    },
    {
      "Istat": "005007",
      "Comune": "Baldichieri d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14011,
      "CodFisco": "A588",
      "Abitanti": 1099,
      "Link": "http://www.comuni-italiani.it/005/007/"
    },
    {
      "Istat": "001017",
      "Comune": "Baldissero Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "A590",
      "Abitanti": 551,
      "Link": "http://www.comuni-italiani.it/001/017/"
    },
    {
      "Istat": "004010",
      "Comune": "Baldissero d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "A589",
      "Abitanti": 1053,
      "Link": "http://www.comuni-italiani.it/004/010/"
    },
    {
      "Istat": "001018",
      "Comune": "Baldissero Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "A591",
      "Abitanti": 3825,
      "Link": "http://www.comuni-italiani.it/001/018/"
    },
    {
      "Istat": "082007",
      "Comune": "Balestrate",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90041,
      "CodFisco": "A592",
      "Abitanti": 6598,
      "Link": "http://www.comuni-italiani.it/082/007/"
    },
    {
      "Istat": "009008",
      "Comune": "Balestrino",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17020,
      "CodFisco": "A593",
      "Abitanti": 597,
      "Link": "http://www.comuni-italiani.it/009/008/"
    },
    {
      "Istat": "097004",
      "Comune": "Ballabio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23811,
      "CodFisco": "A594",
      "Abitanti": 3967,
      "Link": "http://www.comuni-italiani.it/097/004/"
    },
    {
      "Istat": "092004",
      "Comune": "Ballao",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "A597",
      "Abitanti": 882,
      "Link": "http://www.comuni-italiani.it/092/004/"
    },
    {
      "Istat": "001019",
      "Comune": "Balme",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "A599",
      "Abitanti": 97,
      "Link": "http://www.comuni-italiani.it/001/019/"
    },
    {
      "Istat": "002008",
      "Comune": "Balmuccia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "A600",
      "Abitanti": 92,
      "Link": "http://www.comuni-italiani.it/002/008/"
    },
    {
      "Istat": "002009",
      "Comune": "Balocco",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "A601",
      "Abitanti": 250,
      "Link": "http://www.comuni-italiani.it/002/009/"
    },
    {
      "Istat": "066007",
      "Comune": "Balsorano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67052,
      "CodFisco": "A603",
      "Abitanti": 3722,
      "Link": "http://www.comuni-italiani.it/066/007/"
    },
    {
      "Istat": "076008",
      "Comune": "Balvano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85050,
      "CodFisco": "A604",
      "Abitanti": 1883,
      "Link": "http://www.comuni-italiani.it/076/008/"
    },
    {
      "Istat": "006011",
      "Comune": "Balzola",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15031,
      "CodFisco": "A605",
      "Abitanti": 1424,
      "Link": "http://www.comuni-italiani.it/006/011/"
    },
    {
      "Istat": "090007",
      "Comune": "Banari",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "A606",
      "Abitanti": 606,
      "Link": "http://www.comuni-italiani.it/090/007/"
    },
    {
      "Istat": "001020",
      "Comune": "Banchette",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "A607",
      "Abitanti": 3355,
      "Link": "http://www.comuni-italiani.it/001/020/"
    },
    {
      "Istat": 103007,
      "Comune": "Bannio Anzino",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28871,
      "CodFisco": "A610",
      "Abitanti": 524,
      "Link": "http://www.comuni-italiani.it/103/007/"
    },
    {
      "Istat": "076009",
      "Comune": "Banzi",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "A612",
      "Abitanti": 1437,
      "Link": "http://www.comuni-italiani.it/076/009/"
    },
    {
      "Istat": "028009",
      "Comune": "Baone",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35030,
      "CodFisco": "A613",
      "Abitanti": 3098,
      "Link": "http://www.comuni-italiani.it/028/009/"
    },
    {
      "Istat": "095010",
      "Comune": "Baradili",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "A614",
      "Abitanti": 95,
      "Link": "http://www.comuni-italiani.it/095/010/"
    },
    {
      "Istat": "076010",
      "Comune": "Baragiano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85050,
      "CodFisco": "A615",
      "Abitanti": 2693,
      "Link": "http://www.comuni-italiani.it/076/010/"
    },
    {
      "Istat": "070002",
      "Comune": "Baranello",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86011,
      "CodFisco": "A616",
      "Abitanti": 2806,
      "Link": "http://www.comuni-italiani.it/070/002/"
    },
    {
      "Istat": "063007",
      "Comune": "Barano d'Ischia",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80070,
      "CodFisco": "A617",
      "Abitanti": 10083,
      "Link": "http://www.comuni-italiani.it/063/007/"
    },
    {
      "Istat": "015250",
      "Comune": "Baranzate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20021,
      "CodFisco": "A618",
      "Abitanti": 11448,
      "Link": "http://www.comuni-italiani.it/015/250/"
    },
    {
      "Istat": "012008",
      "Comune": "Barasso",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "A619",
      "Abitanti": 1751,
      "Link": "http://www.comuni-italiani.it/012/008/"
    },
    {
      "Istat": "095011",
      "Comune": "Baratili San Pietro",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "A621",
      "Abitanti": 1335,
      "Link": "http://www.comuni-italiani.it/095/011/"
    },
    {
      "Istat": "001021",
      "Comune": "Barbania",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "A625",
      "Abitanti": 1632,
      "Link": "http://www.comuni-italiani.it/001/021/"
    },
    {
      "Istat": "042004",
      "Comune": "Barbara",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "A626",
      "Abitanti": 1482,
      "Link": "http://www.comuni-italiani.it/042/004/"
    },
    {
      "Istat": "056004",
      "Comune": "Barbarano Romano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "A628",
      "Abitanti": 1110,
      "Link": "http://www.comuni-italiani.it/056/004/"
    },
    {
      "Istat": "024011",
      "Comune": "Barbarano Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36021,
      "CodFisco": "A627",
      "Abitanti": 4602,
      "Link": "http://www.comuni-italiani.it/024/011/"
    },
    {
      "Istat": "004011",
      "Comune": "Barbaresco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "A629",
      "Abitanti": 674,
      "Link": "http://www.comuni-italiani.it/004/011/"
    },
    {
      "Istat": "017011",
      "Comune": "Barbariga",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "A630",
      "Abitanti": 2410,
      "Link": "http://www.comuni-italiani.it/017/011/"
    },
    {
      "Istat": "016019",
      "Comune": "Barbata",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "A631",
      "Abitanti": 743,
      "Link": "http://www.comuni-italiani.it/016/019/"
    },
    {
      "Istat": "048002",
      "Comune": "Barberino di Mugello",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50031,
      "CodFisco": "A632",
      "Abitanti": 10858,
      "Link": "http://www.comuni-italiani.it/048/002/"
    },
    {
      "Istat": "048003",
      "Comune": "Barberino Val d'Elsa",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50021,
      "CodFisco": "A633",
      "Abitanti": 4373,
      "Link": "http://www.comuni-italiani.it/048/003/"
    },
    {
      "Istat": "018008",
      "Comune": "Barbianello",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27041,
      "CodFisco": "A634",
      "Abitanti": 888,
      "Link": "http://www.comuni-italiani.it/018/008/"
    },
    {
      "Istat": "021007",
      "Comune": "Barbiano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "A635",
      "Abitanti": 1602,
      "Link": "http://www.comuni-italiani.it/021/007/"
    },
    {
      "Istat": "028010",
      "Comune": "Barbona",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35040,
      "CodFisco": "A637",
      "Abitanti": 739,
      "Link": "http://www.comuni-italiani.it/028/010/"
    },
    {
      "Istat": "083005",
      "Comune": "Barcellona Pozzo di Gotto",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98051,
      "CodFisco": "A638",
      "Abitanti": 41897,
      "Link": "http://www.comuni-italiani.it/083/005/"
    },
    {
      "Istat": "041004",
      "Comune": "Barchi",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "A639",
      "Abitanti": 1003,
      "Link": "http://www.comuni-italiani.it/041/004/"
    },
    {
      "Istat": "093006",
      "Comune": "Barcis",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "A640",
      "Abitanti": 256,
      "Link": "http://www.comuni-italiani.it/093/006/"
    },
    {
      "Istat": "007009",
      "Comune": "Bard",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "A643",
      "Abitanti": 132,
      "Link": "http://www.comuni-italiani.it/007/009/"
    },
    {
      "Istat": "012009",
      "Comune": "Bardello",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "A645",
      "Abitanti": 1559,
      "Link": "http://www.comuni-italiani.it/012/009/"
    },
    {
      "Istat": "034002",
      "Comune": "Bardi",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43032,
      "CodFisco": "A646",
      "Abitanti": 2382,
      "Link": "http://www.comuni-italiani.it/034/002/"
    },
    {
      "Istat": "009009",
      "Comune": "Bardineto",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17057,
      "CodFisco": "A647",
      "Abitanti": 692,
      "Link": "http://www.comuni-italiani.it/009/009/"
    },
    {
      "Istat": "023006",
      "Comune": "Bardolino",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37011,
      "CodFisco": "A650",
      "Abitanti": 6750,
      "Link": "http://www.comuni-italiani.it/023/006/"
    },
    {
      "Istat": "001022",
      "Comune": "Bardonecchia",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10052,
      "CodFisco": "A651",
      "Abitanti": 3273,
      "Link": "http://www.comuni-italiani.it/001/022/"
    },
    {
      "Istat": "015012",
      "Comune": "Bareggio",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "A652",
      "Abitanti": 17254,
      "Link": "http://www.comuni-italiani.it/015/012/"
    },
    {
      "Istat": "003012",
      "Comune": "Barengo",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28010,
      "CodFisco": "A653",
      "Abitanti": 891,
      "Link": "http://www.comuni-italiani.it/003/012/"
    },
    {
      "Istat": "095012",
      "Comune": "Baressa",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "A655",
      "Abitanti": 747,
      "Link": "http://www.comuni-italiani.it/095/012/"
    },
    {
      "Istat": "066008",
      "Comune": "Barete",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67010,
      "CodFisco": "A656",
      "Abitanti": 701,
      "Link": "http://www.comuni-italiani.it/066/008/"
    },
    {
      "Istat": "046003",
      "Comune": "Barga",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55051,
      "CodFisco": "A657",
      "Abitanti": 10327,
      "Link": "http://www.comuni-italiani.it/046/003/"
    },
    {
      "Istat": "010003",
      "Comune": "Bargagli",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16021,
      "CodFisco": "A658",
      "Abitanti": 2829,
      "Link": "http://www.comuni-italiani.it/010/003/"
    },
    {
      "Istat": "004012",
      "Comune": "Barge",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12032,
      "CodFisco": "A660",
      "Abitanti": 7891,
      "Link": "http://www.comuni-italiani.it/004/012/"
    },
    {
      "Istat": "017012",
      "Comune": "Barghe",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "A661",
      "Abitanti": 1195,
      "Link": "http://www.comuni-italiani.it/017/012/"
    },
    {
      "Istat": "072006",
      "Comune": "Bari",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": "701xx",
      "CodFisco": "A662",
      "Abitanti": 320475,
      "Link": "http://www.comuni-italiani.it/072/006/"
    },
    {
      "Istat": 105002,
      "Comune": "Bari Sardo",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08042",
      "CodFisco": "A663",
      "Abitanti": 3983,
      "Link": "http://www.comuni-italiani.it/105/002/"
    },
    {
      "Istat": "016020",
      "Comune": "Bariano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "A664",
      "Abitanti": 4431,
      "Link": "http://www.comuni-italiani.it/016/020/"
    },
    {
      "Istat": "037003",
      "Comune": "Baricella",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40052,
      "CodFisco": "A665",
      "Abitanti": 6739,
      "Link": "http://www.comuni-italiani.it/037/003/"
    },
    {
      "Istat": "076011",
      "Comune": "Barile",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85022,
      "CodFisco": "A666",
      "Abitanti": 3012,
      "Link": "http://www.comuni-italiani.it/076/011/"
    },
    {
      "Istat": "066009",
      "Comune": "Barisciano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67021,
      "CodFisco": "A667",
      "Abitanti": 1854,
      "Link": "http://www.comuni-italiani.it/066/009/"
    },
    {
      "Istat": 108005,
      "Comune": "Barlassina",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20825,
      "CodFisco": "A668",
      "Abitanti": 6887,
      "Link": "http://www.comuni-italiani.it/108/005/"
    },
    {
      "Istat": 110002,
      "Comune": "Barletta",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76121,
      "CodFisco": "A669",
      "Abitanti": 94459,
      "Link": "http://www.comuni-italiani.it/110/002/"
    },
    {
      "Istat": "013015",
      "Comune": "Barni",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "A670",
      "Abitanti": 628,
      "Link": "http://www.comuni-italiani.it/013/015/"
    },
    {
      "Istat": "004013",
      "Comune": "Barolo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "A671",
      "Abitanti": 728,
      "Link": "http://www.comuni-italiani.it/004/013/"
    },
    {
      "Istat": "001023",
      "Comune": "Barone Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10010,
      "CodFisco": "A673",
      "Abitanti": 597,
      "Link": "http://www.comuni-italiani.it/001/023/"
    },
    {
      "Istat": "065013",
      "Comune": "Baronissi",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84081,
      "CodFisco": "A674",
      "Abitanti": 16850,
      "Link": "http://www.comuni-italiani.it/065/013/"
    },
    {
      "Istat": "086004",
      "Comune": "Barrafranca",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 94012,
      "CodFisco": "A676",
      "Abitanti": 13053,
      "Link": "http://www.comuni-italiani.it/086/004/"
    },
    {
      "Istat": "092005",
      "Comune": "Barrali",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "A677",
      "Abitanti": 1123,
      "Link": "http://www.comuni-italiani.it/092/005/"
    },
    {
      "Istat": "066010",
      "Comune": "Barrea",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "A678",
      "Abitanti": 750,
      "Link": "http://www.comuni-italiani.it/066/010/"
    },
    {
      "Istat": 106002,
      "Comune": "Barumini",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09021",
      "CodFisco": "A681",
      "Abitanti": 1339,
      "Link": "http://www.comuni-italiani.it/106/002/"
    },
    {
      "Istat": "097005",
      "Comune": "Barzago",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23890,
      "CodFisco": "A683",
      "Abitanti": 2566,
      "Link": "http://www.comuni-italiani.it/097/005/"
    },
    {
      "Istat": "016021",
      "Comune": "Barzana",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "A684",
      "Abitanti": 1769,
      "Link": "http://www.comuni-italiani.it/016/021/"
    },
    {
      "Istat": "097006",
      "Comune": "BarzanÚ",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23891,
      "CodFisco": "A686",
      "Abitanti": 5203,
      "Link": "http://www.comuni-italiani.it/097/006/"
    },
    {
      "Istat": "097007",
      "Comune": "Barzio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23816,
      "CodFisco": "A687",
      "Abitanti": 1321,
      "Link": "http://www.comuni-italiani.it/097/007/"
    },
    {
      "Istat": "006012",
      "Comune": "Basaluzzo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "A689",
      "Abitanti": 2113,
      "Link": "http://www.comuni-italiani.it/006/012/"
    },
    {
      "Istat": "018009",
      "Comune": "BascapË",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "A690",
      "Abitanti": 1777,
      "Link": "http://www.comuni-italiani.it/018/009/"
    },
    {
      "Istat": "055007",
      "Comune": "Baschi",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05023",
      "CodFisco": "A691",
      "Abitanti": 2847,
      "Link": "http://www.comuni-italiani.it/055/007/"
    },
    {
      "Istat": "067005",
      "Comune": "Basciano",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64030,
      "CodFisco": "A692",
      "Abitanti": 2449,
      "Link": "http://www.comuni-italiani.it/067/005/"
    },
    {
      "Istat": "022009",
      "Comune": "Baselga di PinË",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38042,
      "CodFisco": "A694",
      "Abitanti": 4899,
      "Link": "http://www.comuni-italiani.it/022/009/"
    },
    {
      "Istat": "062007",
      "Comune": "Baselice",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "A696",
      "Abitanti": 2593,
      "Link": "http://www.comuni-italiani.it/062/007/"
    },
    {
      "Istat": "015014",
      "Comune": "Basiano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "A697",
      "Abitanti": 3686,
      "Link": "http://www.comuni-italiani.it/015/014/"
    },
    {
      "Istat": "083006",
      "Comune": "BasicÚ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "A698",
      "Abitanti": 692,
      "Link": "http://www.comuni-italiani.it/083/006/"
    },
    {
      "Istat": "015015",
      "Comune": "Basiglio",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "A699",
      "Abitanti": 8003,
      "Link": "http://www.comuni-italiani.it/015/015/"
    },
    {
      "Istat": "030009",
      "Comune": "Basiliano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33031,
      "CodFisco": "A700",
      "Abitanti": 5410,
      "Link": "http://www.comuni-italiani.it/030/009/"
    },
    {
      "Istat": "017013",
      "Comune": "Bassano Bresciano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "A702",
      "Abitanti": 2219,
      "Link": "http://www.comuni-italiani.it/017/013/"
    },
    {
      "Istat": "024012",
      "Comune": "Bassano del Grappa",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36061,
      "CodFisco": "A703",
      "Abitanti": 43540,
      "Link": "http://www.comuni-italiani.it/024/012/"
    },
    {
      "Istat": "056006",
      "Comune": "Bassano in Teverina",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "A706",
      "Abitanti": 1319,
      "Link": "http://www.comuni-italiani.it/056/006/"
    },
    {
      "Istat": "056005",
      "Comune": "Bassano Romano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "A704",
      "Abitanti": 5049,
      "Link": "http://www.comuni-italiani.it/056/005/"
    },
    {
      "Istat": "059002",
      "Comune": "Bassiano",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "A707",
      "Abitanti": 1651,
      "Link": "http://www.comuni-italiani.it/059/002/"
    },
    {
      "Istat": "006013",
      "Comune": "Bassignana",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15042,
      "CodFisco": "A708",
      "Abitanti": 1772,
      "Link": "http://www.comuni-italiani.it/006/013/"
    },
    {
      "Istat": "004014",
      "Comune": "Bastia MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "A709",
      "Abitanti": 658,
      "Link": "http://www.comuni-italiani.it/004/014/"
    },
    {
      "Istat": "054002",
      "Comune": "Bastia Umbra",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06083",
      "CodFisco": "A710",
      "Abitanti": 21800,
      "Link": "http://www.comuni-italiani.it/054/002/"
    },
    {
      "Istat": "018010",
      "Comune": "Bastida de' Dossi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "A711",
      "Abitanti": 173,
      "Link": "http://www.comuni-italiani.it/018/010/"
    },
    {
      "Istat": "018011",
      "Comune": "Bastida Pancarana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "A712",
      "Abitanti": 1047,
      "Link": "http://www.comuni-italiani.it/018/011/"
    },
    {
      "Istat": "036001",
      "Comune": "Bastiglia",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41030,
      "CodFisco": "A713",
      "Abitanti": 4137,
      "Link": "http://www.comuni-italiani.it/036/001/"
    },
    {
      "Istat": "028011",
      "Comune": "Battaglia Terme",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35041,
      "CodFisco": "A714",
      "Abitanti": 4031,
      "Link": "http://www.comuni-italiani.it/028/011/"
    },
    {
      "Istat": "004015",
      "Comune": "Battifollo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "A716",
      "Abitanti": 237,
      "Link": "http://www.comuni-italiani.it/004/015/"
    },
    {
      "Istat": "065014",
      "Comune": "Battipaglia",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84091,
      "CodFisco": "A717",
      "Abitanti": 51133,
      "Link": "http://www.comuni-italiani.it/065/014/"
    },
    {
      "Istat": "018012",
      "Comune": "Battuda",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "A718",
      "Abitanti": 599,
      "Link": "http://www.comuni-italiani.it/018/012/"
    },
    {
      "Istat": "082008",
      "Comune": "Baucina",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "A719",
      "Abitanti": 2008,
      "Link": "http://www.comuni-italiani.it/082/008/"
    },
    {
      "Istat": "095013",
      "Comune": "Bauladu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "A721",
      "Abitanti": 711,
      "Link": "http://www.comuni-italiani.it/095/013/"
    },
    {
      "Istat": 105003,
      "Comune": "Baunei",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "A722",
      "Abitanti": 3736,
      "Link": "http://www.comuni-italiani.it/105/003/"
    },
    {
      "Istat": 103008,
      "Comune": "Baveno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28831,
      "CodFisco": "A725",
      "Abitanti": 4966,
      "Link": "http://www.comuni-italiani.it/103/008/"
    },
    {
      "Istat": "037004",
      "Comune": "Bazzano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40053,
      "CodFisco": "A726",
      "Abitanti": 6896,
      "Link": "http://www.comuni-italiani.it/037/004/"
    },
    {
      "Istat": "012010",
      "Comune": "Bedero Valcuvia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21039,
      "CodFisco": "A728",
      "Abitanti": 643,
      "Link": "http://www.comuni-italiani.it/012/010/"
    },
    {
      "Istat": "017014",
      "Comune": "Bedizzole",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25081,
      "CodFisco": "A729",
      "Abitanti": 11937,
      "Link": "http://www.comuni-italiani.it/017/014/"
    },
    {
      "Istat": "022011",
      "Comune": "Bedollo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38043,
      "CodFisco": "A730",
      "Abitanti": 1478,
      "Link": "http://www.comuni-italiani.it/022/011/"
    },
    {
      "Istat": "034003",
      "Comune": "Bedonia",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43041,
      "CodFisco": "A731",
      "Abitanti": 3701,
      "Link": "http://www.comuni-italiani.it/034/003/"
    },
    {
      "Istat": "016022",
      "Comune": "Bedulita",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "A732",
      "Abitanti": 734,
      "Link": "http://www.comuni-italiani.it/016/022/"
    },
    {
      "Istat": 103009,
      "Comune": "Bee",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28813,
      "CodFisco": "A733",
      "Abitanti": 722,
      "Link": "http://www.comuni-italiani.it/103/009/"
    },
    {
      "Istat": "001024",
      "Comune": "Beinasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10092,
      "CodFisco": "A734",
      "Abitanti": 18185,
      "Link": "http://www.comuni-italiani.it/001/024/"
    },
    {
      "Istat": "004016",
      "Comune": "Beinette",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12081,
      "CodFisco": "A735",
      "Abitanti": 3130,
      "Link": "http://www.comuni-italiani.it/004/016/"
    },
    {
      "Istat": "079009",
      "Comune": "Belcastro",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "A736",
      "Abitanti": 1365,
      "Link": "http://www.comuni-italiani.it/079/009/"
    },
    {
      "Istat": "023007",
      "Comune": "Belfiore",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37050,
      "CodFisco": "A737",
      "Abitanti": 3030,
      "Link": "http://www.comuni-italiani.it/023/007/"
    },
    {
      "Istat": "041005",
      "Comune": "Belforte all'Isauro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61026,
      "CodFisco": "A740",
      "Abitanti": 823,
      "Link": "http://www.comuni-italiani.it/041/005/"
    },
    {
      "Istat": "043004",
      "Comune": "Belforte del Chienti",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "A739",
      "Abitanti": 1859,
      "Link": "http://www.comuni-italiani.it/043/004/"
    },
    {
      "Istat": "006014",
      "Comune": "Belforte Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15070,
      "CodFisco": "A738",
      "Abitanti": 501,
      "Link": "http://www.comuni-italiani.it/006/014/"
    },
    {
      "Istat": "018013",
      "Comune": "Belgioioso",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27011,
      "CodFisco": "A741",
      "Abitanti": 6427,
      "Link": "http://www.comuni-italiani.it/018/013/"
    },
    {
      "Istat": 103010,
      "Comune": "Belgirate",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28832,
      "CodFisco": "A742",
      "Abitanti": 554,
      "Link": "http://www.comuni-italiani.it/103/010/"
    },
    {
      "Istat": "076012",
      "Comune": "Bella",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85051,
      "CodFisco": "A743",
      "Abitanti": 5324,
      "Link": "http://www.comuni-italiani.it/076/012/"
    },
    {
      "Istat": "013019",
      "Comune": "Bellagio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22021,
      "CodFisco": "A744",
      "Abitanti": 3078,
      "Link": "http://www.comuni-italiani.it/013/019/"
    },
    {
      "Istat": "097008",
      "Comune": "Bellano",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23822,
      "CodFisco": "A745",
      "Abitanti": 3305,
      "Link": "http://www.comuni-italiani.it/097/008/"
    },
    {
      "Istat": "067006",
      "Comune": "Bellante",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64020,
      "CodFisco": "A746",
      "Abitanti": 7176,
      "Link": "http://www.comuni-italiani.it/067/006/"
    },
    {
      "Istat": "099001",
      "Comune": "Bellaria-Igea Marina",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47814,
      "CodFisco": "A747",
      "Abitanti": 19358,
      "Link": "http://www.comuni-italiani.it/099/001/"
    },
    {
      "Istat": "058012",
      "Comune": "Bellegra",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "A749",
      "Abitanti": 3008,
      "Link": "http://www.comuni-italiani.it/058/012/"
    },
    {
      "Istat": "004017",
      "Comune": "Bellino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "A750",
      "Abitanti": 144,
      "Link": "http://www.comuni-italiani.it/004/017/"
    },
    {
      "Istat": "015016",
      "Comune": "Bellinzago Lombardo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "A751",
      "Abitanti": 3830,
      "Link": "http://www.comuni-italiani.it/015/016/"
    },
    {
      "Istat": "003016",
      "Comune": "Bellinzago Novarese",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28043,
      "CodFisco": "A752",
      "Abitanti": 9258,
      "Link": "http://www.comuni-italiani.it/003/016/"
    },
    {
      "Istat": "065158",
      "Comune": "Bellizzi",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84092,
      "CodFisco": "M294",
      "Abitanti": 13172,
      "Link": "http://www.comuni-italiani.it/065/158/"
    },
    {
      "Istat": "061007",
      "Comune": "Bellona",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81041,
      "CodFisco": "A755",
      "Abitanti": 5846,
      "Link": "http://www.comuni-italiani.it/061/007/"
    },
    {
      "Istat": "065015",
      "Comune": "Bellosguardo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "A756",
      "Abitanti": 865,
      "Link": "http://www.comuni-italiani.it/065/015/"
    },
    {
      "Istat": "025006",
      "Comune": "Belluno",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32100,
      "CodFisco": "A757",
      "Abitanti": 36599,
      "Link": "http://www.comuni-italiani.it/025/006/"
    },
    {
      "Istat": 108006,
      "Comune": "Bellusco",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20882,
      "CodFisco": "A759",
      "Abitanti": 7217,
      "Link": "http://www.comuni-italiani.it/108/006/"
    },
    {
      "Istat": "078013",
      "Comune": "Belmonte Calabro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87033,
      "CodFisco": "A762",
      "Abitanti": 2251,
      "Link": "http://www.comuni-italiani.it/078/013/"
    },
    {
      "Istat": "060013",
      "Comune": "Belmonte Castello",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "A763",
      "Abitanti": 777,
      "Link": "http://www.comuni-italiani.it/060/013/"
    },
    {
      "Istat": "094004",
      "Comune": "Belmonte del Sannio",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "A761",
      "Abitanti": 843,
      "Link": "http://www.comuni-italiani.it/094/004/"
    },
    {
      "Istat": "057005",
      "Comune": "Belmonte in Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "A765",
      "Abitanti": 677,
      "Link": "http://www.comuni-italiani.it/057/005/"
    },
    {
      "Istat": "082009",
      "Comune": "Belmonte Mezzagno",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90031,
      "CodFisco": "A764",
      "Abitanti": 11146,
      "Link": "http://www.comuni-italiani.it/082/009/"
    },
    {
      "Istat": 109003,
      "Comune": "Belmonte Piceno",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63838,
      "CodFisco": "A760",
      "Abitanti": 675,
      "Link": "http://www.comuni-italiani.it/109/003/"
    },
    {
      "Istat": "087007",
      "Comune": "Belpasso",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95032,
      "CodFisco": "A766",
      "Abitanti": 25404,
      "Link": "http://www.comuni-italiani.it/087/007/"
    },
    {
      "Istat": "078014",
      "Comune": "Belsito",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87030,
      "CodFisco": "A768",
      "Abitanti": 973,
      "Link": "http://www.comuni-italiani.it/078/014/"
    },
    {
      "Istat": 101001,
      "Comune": "Belvedere di Spinello",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88824,
      "CodFisco": "A772",
      "Abitanti": 2306,
      "Link": "http://www.comuni-italiani.it/101/001/"
    },
    {
      "Istat": "004018",
      "Comune": "Belvedere Langhe",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "A774",
      "Abitanti": 388,
      "Link": "http://www.comuni-italiani.it/004/018/"
    },
    {
      "Istat": "078015",
      "Comune": "Belvedere Marittimo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87021,
      "CodFisco": "A773",
      "Abitanti": 9388,
      "Link": "http://www.comuni-italiani.it/078/015/"
    },
    {
      "Istat": "042005",
      "Comune": "Belvedere Ostrense",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "A769",
      "Abitanti": 2299,
      "Link": "http://www.comuni-italiani.it/042/005/"
    },
    {
      "Istat": "005008",
      "Comune": "Belveglio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "A770",
      "Abitanti": 327,
      "Link": "http://www.comuni-italiani.it/005/008/"
    },
    {
      "Istat": "091007",
      "Comune": "BelvÏ",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08030",
      "CodFisco": "A776",
      "Abitanti": 671,
      "Link": "http://www.comuni-italiani.it/091/007/"
    },
    {
      "Istat": "014006",
      "Comune": "Bema",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "A777",
      "Abitanti": 132,
      "Link": "http://www.comuni-italiani.it/014/006/"
    },
    {
      "Istat": "013021",
      "Comune": "Bene Lario",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "A778",
      "Abitanti": 365,
      "Link": "http://www.comuni-italiani.it/013/021/"
    },
    {
      "Istat": "004019",
      "Comune": "Bene Vagienna",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12041,
      "CodFisco": "A779",
      "Abitanti": 3675,
      "Link": "http://www.comuni-italiani.it/004/019/"
    },
    {
      "Istat": "080008",
      "Comune": "Benestare",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "A780",
      "Abitanti": 2494,
      "Link": "http://www.comuni-italiani.it/080/008/"
    },
    {
      "Istat": "090008",
      "Comune": "Benetutti",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "A781",
      "Abitanti": 2010,
      "Link": "http://www.comuni-italiani.it/090/008/"
    },
    {
      "Istat": "004020",
      "Comune": "Benevello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "A782",
      "Abitanti": 461,
      "Link": "http://www.comuni-italiani.it/004/020/"
    },
    {
      "Istat": "062008",
      "Comune": "Benevento",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82100,
      "CodFisco": "A783",
      "Abitanti": 62035,
      "Link": "http://www.comuni-italiani.it/062/008/"
    },
    {
      "Istat": "096003",
      "Comune": "Benna",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13871,
      "CodFisco": "A784",
      "Abitanti": 1183,
      "Link": "http://www.comuni-italiani.it/096/003/"
    },
    {
      "Istat": "037005",
      "Comune": "Bentivoglio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40010,
      "CodFisco": "A785",
      "Abitanti": 5282,
      "Link": "http://www.comuni-italiani.it/037/005/"
    },
    {
      "Istat": "016023",
      "Comune": "Berbenno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "A786",
      "Abitanti": 2492,
      "Link": "http://www.comuni-italiani.it/016/023/"
    },
    {
      "Istat": "014007",
      "Comune": "Berbenno di Valtellina",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "A787",
      "Abitanti": 4367,
      "Link": "http://www.comuni-italiani.it/014/007/"
    },
    {
      "Istat": "034004",
      "Comune": "Berceto",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43042,
      "CodFisco": "A788",
      "Abitanti": 2189,
      "Link": "http://www.comuni-italiani.it/034/004/"
    },
    {
      "Istat": 104006,
      "Comune": "Berchidda",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07022",
      "CodFisco": "A789",
      "Abitanti": 2941,
      "Link": "http://www.comuni-italiani.it/104/006/"
    },
    {
      "Istat": "013022",
      "Comune": "Beregazzo con Figliaro",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "A791",
      "Abitanti": 2590,
      "Link": "http://www.comuni-italiani.it/013/022/"
    },
    {
      "Istat": "018014",
      "Comune": "Bereguardo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27021,
      "CodFisco": "A792",
      "Abitanti": 2843,
      "Link": "http://www.comuni-italiani.it/018/014/"
    },
    {
      "Istat": "006015",
      "Comune": "Bergamasco",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15022,
      "CodFisco": "A793",
      "Abitanti": 780,
      "Link": "http://www.comuni-italiani.it/006/015/"
    },
    {
      "Istat": "016024",
      "Comune": "Bergamo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": "241xx",
      "CodFisco": "A794",
      "Abitanti": 119551,
      "Link": "http://www.comuni-italiani.it/016/024/"
    },
    {
      "Istat": "029006",
      "Comune": "Bergantino",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45032,
      "CodFisco": "A795",
      "Abitanti": 2617,
      "Link": "http://www.comuni-italiani.it/029/006/"
    },
    {
      "Istat": "009010",
      "Comune": "Bergeggi",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17028,
      "CodFisco": "A796",
      "Abitanti": 1163,
      "Link": "http://www.comuni-italiani.it/009/010/"
    },
    {
      "Istat": "004021",
      "Comune": "Bergolo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12074,
      "CodFisco": "A798",
      "Abitanti": 67,
      "Link": "http://www.comuni-italiani.it/004/021/"
    },
    {
      "Istat": "017015",
      "Comune": "Berlingo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "A799",
      "Abitanti": 2600,
      "Link": "http://www.comuni-italiani.it/017/015/"
    },
    {
      "Istat": "077003",
      "Comune": "Bernalda",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75012,
      "CodFisco": "A801",
      "Abitanti": 12258,
      "Link": "http://www.comuni-italiani.it/077/003/"
    },
    {
      "Istat": 108007,
      "Comune": "Bernareggio",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20881,
      "CodFisco": "A802",
      "Abitanti": 10501,
      "Link": "http://www.comuni-italiani.it/108/007/"
    },
    {
      "Istat": "015019",
      "Comune": "Bernate Ticino",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "A804",
      "Abitanti": 3117,
      "Link": "http://www.comuni-italiani.it/015/019/"
    },
    {
      "Istat": "004022",
      "Comune": "Bernezzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "A805",
      "Abitanti": 3785,
      "Link": "http://www.comuni-italiani.it/004/022/"
    },
    {
      "Istat": "038002",
      "Comune": "Berra",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44033,
      "CodFisco": "A806",
      "Abitanti": 5308,
      "Link": "http://www.comuni-italiani.it/038/002/"
    },
    {
      "Istat": "022012",
      "Comune": "Bersone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38085,
      "CodFisco": "A808",
      "Abitanti": 282,
      "Link": "http://www.comuni-italiani.it/022/012/"
    },
    {
      "Istat": "040003",
      "Comune": "Bertinoro",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47032,
      "CodFisco": "A809",
      "Abitanti": 11029,
      "Link": "http://www.comuni-italiani.it/040/003/"
    },
    {
      "Istat": "030010",
      "Comune": "Bertiolo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33032,
      "CodFisco": "A810",
      "Abitanti": 2578,
      "Link": "http://www.comuni-italiani.it/030/010/"
    },
    {
      "Istat": "098002",
      "Comune": "Bertonico",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26821,
      "CodFisco": "A811",
      "Abitanti": 1190,
      "Link": "http://www.comuni-italiani.it/098/002/"
    },
    {
      "Istat": "005009",
      "Comune": "Berzano di San Pietro",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 14020,
      "CodFisco": "A812",
      "Abitanti": 454,
      "Link": "http://www.comuni-italiani.it/005/009/"
    },
    {
      "Istat": "006016",
      "Comune": "Berzano di Tortona",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "A813",
      "Abitanti": 170,
      "Link": "http://www.comuni-italiani.it/006/016/"
    },
    {
      "Istat": "017016",
      "Comune": "Berzo Demo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "A816",
      "Abitanti": 1751,
      "Link": "http://www.comuni-italiani.it/017/016/"
    },
    {
      "Istat": "017017",
      "Comune": "Berzo Inferiore",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "A817",
      "Abitanti": 2439,
      "Link": "http://www.comuni-italiani.it/017/017/"
    },
    {
      "Istat": "016025",
      "Comune": "Berzo San Fermo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "A815",
      "Abitanti": 1309,
      "Link": "http://www.comuni-italiani.it/016/025/"
    },
    {
      "Istat": 108008,
      "Comune": "Besana in Brianza",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20842,
      "CodFisco": "A818",
      "Abitanti": 15582,
      "Link": "http://www.comuni-italiani.it/108/008/"
    },
    {
      "Istat": "012011",
      "Comune": "Besano",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "A819",
      "Abitanti": 2593,
      "Link": "http://www.comuni-italiani.it/012/011/"
    },
    {
      "Istat": "015022",
      "Comune": "Besate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "A820",
      "Abitanti": 2032,
      "Link": "http://www.comuni-italiani.it/015/022/"
    },
    {
      "Istat": "022013",
      "Comune": "Besenello",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "A821",
      "Abitanti": 2500,
      "Link": "http://www.comuni-italiani.it/022/013/"
    },
    {
      "Istat": "033003",
      "Comune": "Besenzone",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "A823",
      "Abitanti": 989,
      "Link": "http://www.comuni-italiani.it/033/003/"
    },
    {
      "Istat": "012012",
      "Comune": "Besnate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21010,
      "CodFisco": "A825",
      "Abitanti": 5437,
      "Link": "http://www.comuni-italiani.it/012/012/"
    },
    {
      "Istat": "012013",
      "Comune": "Besozzo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21023,
      "CodFisco": "A826",
      "Abitanti": 9192,
      "Link": "http://www.comuni-italiani.it/012/013/"
    },
    {
      "Istat": "090010",
      "Comune": "Bessude",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "A827",
      "Abitanti": 433,
      "Link": "http://www.comuni-italiani.it/090/010/"
    },
    {
      "Istat": "033004",
      "Comune": "Bettola",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29021,
      "CodFisco": "A831",
      "Abitanti": 3024,
      "Link": "http://www.comuni-italiani.it/033/004/"
    },
    {
      "Istat": "054003",
      "Comune": "Bettona",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06084",
      "CodFisco": "A832",
      "Abitanti": 4428,
      "Link": "http://www.comuni-italiani.it/054/003/"
    },
    {
      "Istat": 103011,
      "Comune": "Beura-Cardezza",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28851,
      "CodFisco": "A834",
      "Abitanti": 1425,
      "Link": "http://www.comuni-italiani.it/103/011/"
    },
    {
      "Istat": "054004",
      "Comune": "Bevagna",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06031",
      "CodFisco": "A835",
      "Abitanti": 5156,
      "Link": "http://www.comuni-italiani.it/054/004/"
    },
    {
      "Istat": "011003",
      "Comune": "Beverino",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "A836",
      "Abitanti": 2418,
      "Link": "http://www.comuni-italiani.it/011/003/"
    },
    {
      "Istat": "023008",
      "Comune": "Bevilacqua",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "A837",
      "Abitanti": 1865,
      "Link": "http://www.comuni-italiani.it/023/008/"
    },
    {
      "Istat": "087008",
      "Comune": "Biancavilla",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95033,
      "CodFisco": "A841",
      "Abitanti": 23947,
      "Link": "http://www.comuni-italiani.it/087/008/"
    },
    {
      "Istat": "078016",
      "Comune": "Bianchi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "A842",
      "Abitanti": 1411,
      "Link": "http://www.comuni-italiani.it/078/016/"
    },
    {
      "Istat": "080009",
      "Comune": "Bianco",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89032,
      "CodFisco": "A843",
      "Abitanti": 4337,
      "Link": "http://www.comuni-italiani.it/080/009/"
    },
    {
      "Istat": "003018",
      "Comune": "Biandrate",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28061,
      "CodFisco": "A844",
      "Abitanti": 1190,
      "Link": "http://www.comuni-italiani.it/003/018/"
    },
    {
      "Istat": "012014",
      "Comune": "Biandronno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21024,
      "CodFisco": "A845",
      "Abitanti": 3275,
      "Link": "http://www.comuni-italiani.it/012/014/"
    },
    {
      "Istat": "016026",
      "Comune": "Bianzano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "A846",
      "Abitanti": 604,
      "Link": "http://www.comuni-italiani.it/016/026/"
    },
    {
      "Istat": "002011",
      "Comune": "BianzË",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13041,
      "CodFisco": "A847",
      "Abitanti": 2060,
      "Link": "http://www.comuni-italiani.it/002/011/"
    },
    {
      "Istat": "014008",
      "Comune": "Bianzone",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "A848",
      "Abitanti": 1278,
      "Link": "http://www.comuni-italiani.it/014/008/"
    },
    {
      "Istat": 108009,
      "Comune": "Biassono",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20853,
      "CodFisco": "A849",
      "Abitanti": 11754,
      "Link": "http://www.comuni-italiani.it/108/009/"
    },
    {
      "Istat": "035004",
      "Comune": "Bibbiano",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42021,
      "CodFisco": "A850",
      "Abitanti": 10021,
      "Link": "http://www.comuni-italiani.it/035/004/"
    },
    {
      "Istat": "051004",
      "Comune": "Bibbiena",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52011,
      "CodFisco": "A851",
      "Abitanti": 12731,
      "Link": "http://www.comuni-italiani.it/051/004/"
    },
    {
      "Istat": "049001",
      "Comune": "Bibbona",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 57020,
      "CodFisco": "A852",
      "Abitanti": 3251,
      "Link": "http://www.comuni-italiani.it/049/001/"
    },
    {
      "Istat": "001025",
      "Comune": "Bibiana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "A853",
      "Abitanti": 3399,
      "Link": "http://www.comuni-italiani.it/001/025/"
    },
    {
      "Istat": "071006",
      "Comune": "Biccari",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71032,
      "CodFisco": "A854",
      "Abitanti": 2893,
      "Link": "http://www.comuni-italiani.it/071/006/"
    },
    {
      "Istat": "030011",
      "Comune": "Bicinicco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "A855",
      "Abitanti": 1930,
      "Link": "http://www.comuni-italiani.it/030/011/"
    },
    {
      "Istat": "095014",
      "Comune": "BidonÏ",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "A856",
      "Abitanti": 150,
      "Link": "http://www.comuni-italiani.it/095/014/"
    },
    {
      "Istat": "096004",
      "Comune": "Biella",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13900,
      "CodFisco": "A859",
      "Abitanti": 45589,
      "Link": "http://www.comuni-italiani.it/096/004/"
    },
    {
      "Istat": "017018",
      "Comune": "Bienno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "A861",
      "Abitanti": 3615,
      "Link": "http://www.comuni-italiani.it/017/018/"
    },
    {
      "Istat": "022015",
      "Comune": "Bieno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "A863",
      "Abitanti": 447,
      "Link": "http://www.comuni-italiani.it/022/015/"
    },
    {
      "Istat": "050001",
      "Comune": "Bientina",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56031,
      "CodFisco": "A864",
      "Abitanti": 7709,
      "Link": "http://www.comuni-italiani.it/050/001/"
    },
    {
      "Istat": "020004",
      "Comune": "Bigarello",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46030,
      "CodFisco": "A866",
      "Abitanti": 2171,
      "Link": "http://www.comuni-italiani.it/020/004/"
    },
    {
      "Istat": "013023",
      "Comune": "Binago",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "A870",
      "Abitanti": 4862,
      "Link": "http://www.comuni-italiani.it/013/023/"
    },
    {
      "Istat": "015024",
      "Comune": "Binasco",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20082,
      "CodFisco": "A872",
      "Abitanti": 7268,
      "Link": "http://www.comuni-italiani.it/015/024/"
    },
    {
      "Istat": "072008",
      "Comune": "Binetto",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70020,
      "CodFisco": "A874",
      "Abitanti": 2133,
      "Link": "http://www.comuni-italiani.it/072/008/"
    },
    {
      "Istat": "096005",
      "Comune": "Bioglio",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13841,
      "CodFisco": "A876",
      "Abitanti": 1013,
      "Link": "http://www.comuni-italiani.it/096/005/"
    },
    {
      "Istat": "007010",
      "Comune": "Bionaz",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "A877",
      "Abitanti": 243,
      "Link": "http://www.comuni-italiani.it/007/010/"
    },
    {
      "Istat": "017019",
      "Comune": "Bione",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "A878",
      "Abitanti": 1471,
      "Link": "http://www.comuni-italiani.it/017/019/"
    },
    {
      "Istat": "091008",
      "Comune": "Birori",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "A880",
      "Abitanti": 562,
      "Link": "http://www.comuni-italiani.it/091/008/"
    },
    {
      "Istat": "064011",
      "Comune": "Bisaccia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83044,
      "CodFisco": "A881",
      "Abitanti": 4044,
      "Link": "http://www.comuni-italiani.it/064/011/"
    },
    {
      "Istat": "082010",
      "Comune": "Bisacquino",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90032,
      "CodFisco": "A882",
      "Abitanti": 4882,
      "Link": "http://www.comuni-italiani.it/082/010/"
    },
    {
      "Istat": 110003,
      "Comune": "Bisceglie",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 76011,
      "CodFisco": "A883",
      "Abitanti": 54847,
      "Link": "http://www.comuni-italiani.it/110/003/"
    },
    {
      "Istat": "066011",
      "Comune": "Bisegna",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "A884",
      "Abitanti": 286,
      "Link": "http://www.comuni-italiani.it/066/011/"
    },
    {
      "Istat": "067007",
      "Comune": "Bisenti",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64033,
      "CodFisco": "A885",
      "Abitanti": 1957,
      "Link": "http://www.comuni-italiani.it/067/007/"
    },
    {
      "Istat": "078017",
      "Comune": "Bisignano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87043,
      "CodFisco": "A887",
      "Abitanti": 10487,
      "Link": "http://www.comuni-italiani.it/078/017/"
    },
    {
      "Istat": "006017",
      "Comune": "Bistagno",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15012,
      "CodFisco": "A889",
      "Abitanti": 1944,
      "Link": "http://www.comuni-italiani.it/006/017/"
    },
    {
      "Istat": "012015",
      "Comune": "Bisuschio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "A891",
      "Abitanti": 4293,
      "Link": "http://www.comuni-italiani.it/012/015/"
    },
    {
      "Istat": "072010",
      "Comune": "Bitetto",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70020,
      "CodFisco": "A892",
      "Abitanti": 11717,
      "Link": "http://www.comuni-italiani.it/072/010/"
    },
    {
      "Istat": "072011",
      "Comune": "Bitonto",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70032,
      "CodFisco": "A893",
      "Abitanti": 56462,
      "Link": "http://www.comuni-italiani.it/072/011/"
    },
    {
      "Istat": "072012",
      "Comune": "Bitritto",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70020,
      "CodFisco": "A894",
      "Abitanti": 10881,
      "Link": "http://www.comuni-italiani.it/072/012/"
    },
    {
      "Istat": "091009",
      "Comune": "Bitti",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08021",
      "CodFisco": "A895",
      "Abitanti": 3074,
      "Link": "http://www.comuni-italiani.it/091/009/"
    },
    {
      "Istat": "084004",
      "Comune": "Bivona",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "A896",
      "Abitanti": 3952,
      "Link": "http://www.comuni-italiani.it/084/004/"
    },
    {
      "Istat": "080010",
      "Comune": "Bivongi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "A897",
      "Abitanti": 1429,
      "Link": "http://www.comuni-italiani.it/080/010/"
    },
    {
      "Istat": "013024",
      "Comune": "Bizzarone",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "A898",
      "Abitanti": 1541,
      "Link": "http://www.comuni-italiani.it/013/024/"
    },
    {
      "Istat": "022017",
      "Comune": "Bleggio Superiore",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38071,
      "CodFisco": "A902",
      "Abitanti": 1530,
      "Link": "http://www.comuni-italiani.it/022/017/"
    },
    {
      "Istat": "016027",
      "Comune": "Blello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "A903",
      "Abitanti": 79,
      "Link": "http://www.comuni-italiani.it/016/027/"
    },
    {
      "Istat": "056007",
      "Comune": "Blera",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "A857",
      "Abitanti": 3402,
      "Link": "http://www.comuni-italiani.it/056/007/"
    },
    {
      "Istat": "013025",
      "Comune": "Blessagno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22028,
      "CodFisco": "A904",
      "Abitanti": 284,
      "Link": "http://www.comuni-italiani.it/013/025/"
    },
    {
      "Istat": "013026",
      "Comune": "Blevio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "A905",
      "Abitanti": 1268,
      "Link": "http://www.comuni-italiani.it/013/026/"
    },
    {
      "Istat": "082082",
      "Comune": "Blufi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90020,
      "CodFisco": "M268",
      "Abitanti": 1094,
      "Link": "http://www.comuni-italiani.it/082/082/"
    },
    {
      "Istat": "028012",
      "Comune": "Boara Pisani",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35040,
      "CodFisco": "A906",
      "Abitanti": 2617,
      "Link": "http://www.comuni-italiani.it/028/012/"
    },
    {
      "Istat": "033005",
      "Comune": "Bobbio",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29022,
      "CodFisco": "A909",
      "Abitanti": 3737,
      "Link": "http://www.comuni-italiani.it/033/005/"
    },
    {
      "Istat": "001026",
      "Comune": "Bobbio Pellice",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "A910",
      "Abitanti": 566,
      "Link": "http://www.comuni-italiani.it/001/026/"
    },
    {
      "Istat": "003019",
      "Comune": "Boca",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "A911",
      "Abitanti": 1225,
      "Link": "http://www.comuni-italiani.it/003/019/"
    },
    {
      "Istat": "078018",
      "Comune": "Bocchigliero",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "A912",
      "Abitanti": 1548,
      "Link": "http://www.comuni-italiani.it/078/018/"
    },
    {
      "Istat": "002014",
      "Comune": "Boccioleto",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13022,
      "CodFisco": "A914",
      "Abitanti": 221,
      "Link": "http://www.comuni-italiani.it/002/014/"
    },
    {
      "Istat": "022018",
      "Comune": "Bocenago",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "A916",
      "Abitanti": 415,
      "Link": "http://www.comuni-italiani.it/022/018/"
    },
    {
      "Istat": "012016",
      "Comune": "Bodio Lomnago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "A918",
      "Abitanti": 2117,
      "Link": "http://www.comuni-italiani.it/012/016/"
    },
    {
      "Istat": "098003",
      "Comune": "Boffalora d'Adda",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26811,
      "CodFisco": "A919",
      "Abitanti": 1694,
      "Link": "http://www.comuni-italiani.it/098/003/"
    },
    {
      "Istat": "015026",
      "Comune": "Boffalora Sopra Ticino",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "A920",
      "Abitanti": 4290,
      "Link": "http://www.comuni-italiani.it/015/026/"
    },
    {
      "Istat": "010004",
      "Comune": "Bogliasco",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16031,
      "CodFisco": "A922",
      "Abitanti": 4529,
      "Link": "http://www.comuni-italiani.it/010/004/"
    },
    {
      "Istat": 103012,
      "Comune": "Bognanco",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28842,
      "CodFisco": "A925",
      "Abitanti": 245,
      "Link": "http://www.comuni-italiani.it/103/012/"
    },
    {
      "Istat": "003021",
      "Comune": "Bogogno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "A929",
      "Abitanti": 1316,
      "Link": "http://www.comuni-italiani.it/003/021/"
    },
    {
      "Istat": "009011",
      "Comune": "Boissano",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17054,
      "CodFisco": "A931",
      "Abitanti": 2432,
      "Link": "http://www.comuni-italiani.it/009/011/"
    },
    {
      "Istat": "070003",
      "Comune": "Bojano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86021,
      "CodFisco": "A930",
      "Abitanti": 8175,
      "Link": "http://www.comuni-italiani.it/070/003/"
    },
    {
      "Istat": "011004",
      "Comune": "Bolano",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "A932",
      "Abitanti": 7910,
      "Link": "http://www.comuni-italiani.it/011/004/"
    },
    {
      "Istat": "022019",
      "Comune": "Bolbeno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38079,
      "CodFisco": "A933",
      "Abitanti": 348,
      "Link": "http://www.comuni-italiani.it/022/019/"
    },
    {
      "Istat": "016028",
      "Comune": "Bolgare",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "A937",
      "Abitanti": 5698,
      "Link": "http://www.comuni-italiani.it/016/028/"
    },
    {
      "Istat": "015027",
      "Comune": "Bollate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20021,
      "CodFisco": "A940",
      "Abitanti": 36467,
      "Link": "http://www.comuni-italiani.it/015/027/"
    },
    {
      "Istat": "001027",
      "Comune": "Bollengo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10012,
      "CodFisco": "A941",
      "Abitanti": 2088,
      "Link": "http://www.comuni-italiani.it/001/027/"
    },
    {
      "Istat": "037006",
      "Comune": "Bologna",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": "401xx",
      "CodFisco": "A944",
      "Abitanti": 380181,
      "Link": "http://www.comuni-italiani.it/037/006/"
    },
    {
      "Istat": "068003",
      "Comune": "Bolognano",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "A945",
      "Abitanti": 1195,
      "Link": "http://www.comuni-italiani.it/068/003/"
    },
    {
      "Istat": "082011",
      "Comune": "Bolognetta",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "A946",
      "Abitanti": 4096,
      "Link": "http://www.comuni-italiani.it/082/011/"
    },
    {
      "Istat": "043005",
      "Comune": "Bolognola",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62035,
      "CodFisco": "A947",
      "Abitanti": 164,
      "Link": "http://www.comuni-italiani.it/043/005/"
    },
    {
      "Istat": "091010",
      "Comune": "Bolotana",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08011",
      "CodFisco": "A948",
      "Abitanti": 2884,
      "Link": "http://www.comuni-italiani.it/091/010/"
    },
    {
      "Istat": "056008",
      "Comune": "Bolsena",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01023",
      "CodFisco": "A949",
      "Abitanti": 4187,
      "Link": "http://www.comuni-italiani.it/056/008/"
    },
    {
      "Istat": "016029",
      "Comune": "Boltiere",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "A950",
      "Abitanti": 5757,
      "Link": "http://www.comuni-italiani.it/016/029/"
    },
    {
      "Istat": "021008",
      "Comune": "Bolzano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39100,
      "CodFisco": "A952",
      "Abitanti": 104029,
      "Link": "http://www.comuni-italiani.it/021/008/"
    },
    {
      "Istat": "003022",
      "Comune": "Bolzano Novarese",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "A953",
      "Abitanti": 1180,
      "Link": "http://www.comuni-italiani.it/003/022/"
    },
    {
      "Istat": "024013",
      "Comune": "Bolzano Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "A954",
      "Abitanti": 6503,
      "Link": "http://www.comuni-italiani.it/024/013/"
    },
    {
      "Istat": "056009",
      "Comune": "Bomarzo",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01020",
      "CodFisco": "A955",
      "Abitanti": 1862,
      "Link": "http://www.comuni-italiani.it/056/009/"
    },
    {
      "Istat": "069006",
      "Comune": "Bomba",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66042,
      "CodFisco": "A956",
      "Abitanti": 906,
      "Link": "http://www.comuni-italiani.it/069/006/"
    },
    {
      "Istat": "085002",
      "Comune": "Bompensiere",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "A957",
      "Abitanti": 624,
      "Link": "http://www.comuni-italiani.it/085/002/"
    },
    {
      "Istat": "082012",
      "Comune": "Bompietro",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90020,
      "CodFisco": "A958",
      "Abitanti": 1503,
      "Link": "http://www.comuni-italiani.it/082/012/"
    },
    {
      "Istat": "036002",
      "Comune": "Bomporto",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41030,
      "CodFisco": "A959",
      "Abitanti": 9748,
      "Link": "http://www.comuni-italiani.it/036/002/"
    },
    {
      "Istat": "095015",
      "Comune": "Bonarcado",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "A960",
      "Abitanti": 1650,
      "Link": "http://www.comuni-italiani.it/095/015/"
    },
    {
      "Istat": "011005",
      "Comune": "Bonassola",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19011,
      "CodFisco": "A961",
      "Abitanti": 962,
      "Link": "http://www.comuni-italiani.it/011/005/"
    },
    {
      "Istat": "016030",
      "Comune": "Bonate Sopra",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "A963",
      "Abitanti": 8964,
      "Link": "http://www.comuni-italiani.it/016/030/"
    },
    {
      "Istat": "016031",
      "Comune": "Bonate Sotto",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "A962",
      "Abitanti": 6606,
      "Link": "http://www.comuni-italiani.it/016/031/"
    },
    {
      "Istat": "023009",
      "Comune": "Bonavigo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "A964",
      "Abitanti": 2010,
      "Link": "http://www.comuni-italiani.it/023/009/"
    },
    {
      "Istat": "038003",
      "Comune": "Bondeno",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44012,
      "CodFisco": "A965",
      "Abitanti": 15401,
      "Link": "http://www.comuni-italiani.it/038/003/"
    },
    {
      "Istat": "022020",
      "Comune": "Bondo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38081,
      "CodFisco": "A967",
      "Abitanti": 701,
      "Link": "http://www.comuni-italiani.it/022/020/"
    },
    {
      "Istat": "022021",
      "Comune": "Bondone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "A968",
      "Abitanti": 677,
      "Link": "http://www.comuni-italiani.it/022/021/"
    },
    {
      "Istat": "062009",
      "Comune": "Bonea",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82013,
      "CodFisco": "A970",
      "Abitanti": 1498,
      "Link": "http://www.comuni-italiani.it/062/009/"
    },
    {
      "Istat": "070004",
      "Comune": "Bonefro",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86041,
      "CodFisco": "A971",
      "Abitanti": 1544,
      "Link": "http://www.comuni-italiani.it/070/004/"
    },
    {
      "Istat": "019006",
      "Comune": "Bonemerse",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26040,
      "CodFisco": "A972",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/019/006/"
    },
    {
      "Istat": "078019",
      "Comune": "Bonifati",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87020,
      "CodFisco": "A973",
      "Abitanti": 3116,
      "Link": "http://www.comuni-italiani.it/078/019/"
    },
    {
      "Istat": "064012",
      "Comune": "Bonito",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83032,
      "CodFisco": "A975",
      "Abitanti": 2553,
      "Link": "http://www.comuni-italiani.it/064/012/"
    },
    {
      "Istat": "090011",
      "Comune": "Bonnanaro",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07043",
      "CodFisco": "A976",
      "Abitanti": 1036,
      "Link": "http://www.comuni-italiani.it/090/011/"
    },
    {
      "Istat": "090012",
      "Comune": "Bono",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07011",
      "CodFisco": "A977",
      "Abitanti": 3679,
      "Link": "http://www.comuni-italiani.it/090/012/"
    },
    {
      "Istat": "090013",
      "Comune": "Bonorva",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07012",
      "CodFisco": "A978",
      "Abitanti": 3728,
      "Link": "http://www.comuni-italiani.it/090/013/"
    },
    {
      "Istat": "004023",
      "Comune": "Bonvicino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "A979",
      "Abitanti": 115,
      "Link": "http://www.comuni-italiani.it/004/023/"
    },
    {
      "Istat": "057006",
      "Comune": "Borbona",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "A981",
      "Abitanti": 660,
      "Link": "http://www.comuni-italiani.it/057/006/"
    },
    {
      "Istat": "025007",
      "Comune": "Borca di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "A982",
      "Abitanti": 826,
      "Link": "http://www.comuni-italiani.it/025/007/"
    },
    {
      "Istat": "030012",
      "Comune": "Bordano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "A983",
      "Abitanti": 810,
      "Link": "http://www.comuni-italiani.it/030/012/"
    },
    {
      "Istat": "008008",
      "Comune": "Bordighera",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18012,
      "CodFisco": "A984",
      "Abitanti": 10746,
      "Link": "http://www.comuni-italiani.it/008/008/"
    },
    {
      "Istat": "019007",
      "Comune": "Bordolano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26020,
      "CodFisco": "A986",
      "Abitanti": 610,
      "Link": "http://www.comuni-italiani.it/019/007/"
    },
    {
      "Istat": "034005",
      "Comune": "Bore",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43030,
      "CodFisco": "A987",
      "Abitanti": 800,
      "Link": "http://www.comuni-italiani.it/034/005/"
    },
    {
      "Istat": "035005",
      "Comune": "Boretto",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42022,
      "CodFisco": "A988",
      "Abitanti": 5335,
      "Link": "http://www.comuni-italiani.it/035/005/"
    },
    {
      "Istat": "018015",
      "Comune": "Borgarello",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "A989",
      "Abitanti": 2689,
      "Link": "http://www.comuni-italiani.it/018/015/"
    },
    {
      "Istat": "001028",
      "Comune": "Borgaro Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10071,
      "CodFisco": "A990",
      "Abitanti": 13502,
      "Link": "http://www.comuni-italiani.it/001/028/"
    },
    {
      "Istat": "082013",
      "Comune": "Borgetto",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90042,
      "CodFisco": "A991",
      "Abitanti": 7237,
      "Link": "http://www.comuni-italiani.it/082/013/"
    },
    {
      "Istat": "008009",
      "Comune": "Borghetto d'Arroscia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "A993",
      "Abitanti": 476,
      "Link": "http://www.comuni-italiani.it/008/009/"
    },
    {
      "Istat": "006018",
      "Comune": "Borghetto di Borbera",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "A998",
      "Abitanti": 2009,
      "Link": "http://www.comuni-italiani.it/006/018/"
    },
    {
      "Istat": "011006",
      "Comune": "Borghetto di Vara",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "A992",
      "Abitanti": 991,
      "Link": "http://www.comuni-italiani.it/011/006/"
    },
    {
      "Istat": "098004",
      "Comune": "Borghetto Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26812,
      "CodFisco": "A995",
      "Abitanti": 4417,
      "Link": "http://www.comuni-italiani.it/098/004/"
    },
    {
      "Istat": "009012",
      "Comune": "Borghetto Santo Spirito",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17052,
      "CodFisco": "A999",
      "Abitanti": 5261,
      "Link": "http://www.comuni-italiani.it/009/012/"
    },
    {
      "Istat": "040004",
      "Comune": "Borghi",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47030,
      "CodFisco": "B001",
      "Abitanti": 2763,
      "Link": "http://www.comuni-italiani.it/040/004/"
    },
    {
      "Istat": "079011",
      "Comune": "Borgia",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88021,
      "CodFisco": "B002",
      "Abitanti": 7677,
      "Link": "http://www.comuni-italiani.it/079/011/"
    },
    {
      "Istat": "001029",
      "Comune": "Borgiallo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "B003",
      "Abitanti": 556,
      "Link": "http://www.comuni-italiani.it/001/029/"
    },
    {
      "Istat": "009013",
      "Comune": "Borgio Verezzi",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17022,
      "CodFisco": "B005",
      "Abitanti": 2402,
      "Link": "http://www.comuni-italiani.it/009/013/"
    },
    {
      "Istat": "046004",
      "Comune": "Borgo a Mozzano",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55023,
      "CodFisco": "B007",
      "Abitanti": 7396,
      "Link": "http://www.comuni-italiani.it/046/004/"
    },
    {
      "Istat": "002015",
      "Comune": "Borgo d'Ale",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "B009",
      "Abitanti": 2644,
      "Link": "http://www.comuni-italiani.it/002/015/"
    },
    {
      "Istat": "016032",
      "Comune": "Borgo di Terzo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "B010",
      "Abitanti": 1134,
      "Link": "http://www.comuni-italiani.it/016/032/"
    },
    {
      "Istat": "041006",
      "Comune": "Borgo Pace",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61040,
      "CodFisco": "B026",
      "Abitanti": 655,
      "Link": "http://www.comuni-italiani.it/041/006/"
    },
    {
      "Istat": "018016",
      "Comune": "Borgo Priolo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "B028",
      "Abitanti": 1428,
      "Link": "http://www.comuni-italiani.it/018/016/"
    },
    {
      "Istat": "004025",
      "Comune": "Borgo San Dalmazzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12011,
      "CodFisco": "B033",
      "Abitanti": 12521,
      "Link": "http://www.comuni-italiani.it/004/025/"
    },
    {
      "Istat": "017020",
      "Comune": "Borgo San Giacomo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25022,
      "CodFisco": "B035",
      "Abitanti": 5536,
      "Link": "http://www.comuni-italiani.it/017/020/"
    },
    {
      "Istat": "098005",
      "Comune": "Borgo San Giovanni",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26851,
      "CodFisco": "B017",
      "Abitanti": 2180,
      "Link": "http://www.comuni-italiani.it/098/005/"
    },
    {
      "Istat": "048004",
      "Comune": "Borgo San Lorenzo",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50032,
      "CodFisco": "B036",
      "Abitanti": 18191,
      "Link": "http://www.comuni-italiani.it/048/004/"
    },
    {
      "Istat": "006020",
      "Comune": "Borgo San Martino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15032,
      "CodFisco": "B037",
      "Abitanti": 1465,
      "Link": "http://www.comuni-italiani.it/006/020/"
    },
    {
      "Istat": "018018",
      "Comune": "Borgo San Siro",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "B038",
      "Abitanti": 1078,
      "Link": "http://www.comuni-italiani.it/018/018/"
    },
    {
      "Istat": "003025",
      "Comune": "Borgo Ticino",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28040,
      "CodFisco": "B043",
      "Abitanti": 4872,
      "Link": "http://www.comuni-italiani.it/003/025/"
    },
    {
      "Istat": "037007",
      "Comune": "Borgo Tossignano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40021,
      "CodFisco": "B044",
      "Abitanti": 3323,
      "Link": "http://www.comuni-italiani.it/037/007/"
    },
    {
      "Istat": "034006",
      "Comune": "Borgo Val di Taro",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43043,
      "CodFisco": "B042",
      "Abitanti": 7319,
      "Link": "http://www.comuni-italiani.it/034/006/"
    },
    {
      "Istat": "022022",
      "Comune": "Borgo Valsugana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38051,
      "CodFisco": "B006",
      "Abitanti": 6842,
      "Link": "http://www.comuni-italiani.it/022/022/"
    },
    {
      "Istat": "057008",
      "Comune": "Borgo Velino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "A996",
      "Abitanti": 1004,
      "Link": "http://www.comuni-italiani.it/057/008/"
    },
    {
      "Istat": "002017",
      "Comune": "Borgo Vercelli",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13012,
      "CodFisco": "B046",
      "Abitanti": 2372,
      "Link": "http://www.comuni-italiani.it/002/017/"
    },
    {
      "Istat": "020005",
      "Comune": "Borgoforte",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46030,
      "CodFisco": "B011",
      "Abitanti": 3562,
      "Link": "http://www.comuni-italiani.it/020/005/"
    },
    {
      "Istat": "001030",
      "Comune": "Borgofranco d'Ivrea",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10013,
      "CodFisco": "B015",
      "Abitanti": 3780,
      "Link": "http://www.comuni-italiani.it/001/030/"
    },
    {
      "Istat": "020006",
      "Comune": "Borgofranco sul Po",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "B013",
      "Abitanti": 803,
      "Link": "http://www.comuni-italiani.it/020/006/"
    },
    {
      "Istat": "003023",
      "Comune": "Borgolavezzaro",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28071,
      "CodFisco": "B016",
      "Abitanti": 2085,
      "Link": "http://www.comuni-italiani.it/003/023/"
    },
    {
      "Istat": "004024",
      "Comune": "Borgomale",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "B018",
      "Abitanti": 393,
      "Link": "http://www.comuni-italiani.it/004/024/"
    },
    {
      "Istat": "003024",
      "Comune": "Borgomanero",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28021,
      "CodFisco": "B019",
      "Abitanti": 21518,
      "Link": "http://www.comuni-italiani.it/003/024/"
    },
    {
      "Istat": "008010",
      "Comune": "Borgomaro",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18021,
      "CodFisco": "B020",
      "Abitanti": 884,
      "Link": "http://www.comuni-italiani.it/008/010/"
    },
    {
      "Istat": "001031",
      "Comune": "Borgomasino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10031,
      "CodFisco": "B021",
      "Abitanti": 845,
      "Link": "http://www.comuni-italiani.it/001/031/"
    },
    {
      "Istat": "001032",
      "Comune": "Borgone Susa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "B024",
      "Abitanti": 2372,
      "Link": "http://www.comuni-italiani.it/001/032/"
    },
    {
      "Istat": "033006",
      "Comune": "Borgonovo Val Tidone",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29011,
      "CodFisco": "B025",
      "Abitanti": 7713,
      "Link": "http://www.comuni-italiani.it/033/006/"
    },
    {
      "Istat": "006019",
      "Comune": "Borgoratto Alessandrino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15013,
      "CodFisco": "B029",
      "Abitanti": 618,
      "Link": "http://www.comuni-italiani.it/006/019/"
    },
    {
      "Istat": "018017",
      "Comune": "Borgoratto Mormorolo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "B030",
      "Abitanti": 442,
      "Link": "http://www.comuni-italiani.it/018/017/"
    },
    {
      "Istat": "028013",
      "Comune": "Borgoricco",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "B031",
      "Abitanti": 8478,
      "Link": "http://www.comuni-italiani.it/028/013/"
    },
    {
      "Istat": "057007",
      "Comune": "Borgorose",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02021",
      "CodFisco": "B008",
      "Abitanti": 4644,
      "Link": "http://www.comuni-italiani.it/057/007/"
    },
    {
      "Istat": "017021",
      "Comune": "Borgosatollo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "B040",
      "Abitanti": 9232,
      "Link": "http://www.comuni-italiani.it/017/021/"
    },
    {
      "Istat": "002016",
      "Comune": "Borgosesia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13011,
      "CodFisco": "B041",
      "Abitanti": 13336,
      "Link": "http://www.comuni-italiani.it/002/016/"
    },
    {
      "Istat": "009014",
      "Comune": "Bormida",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17045,
      "CodFisco": "B048",
      "Abitanti": 426,
      "Link": "http://www.comuni-italiani.it/009/014/"
    },
    {
      "Istat": "014009",
      "Comune": "Bormio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23032,
      "CodFisco": "B049",
      "Abitanti": 4084,
      "Link": "http://www.comuni-italiani.it/014/009/"
    },
    {
      "Istat": "018019",
      "Comune": "Bornasco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "B051",
      "Abitanti": 2607,
      "Link": "http://www.comuni-italiani.it/018/019/"
    },
    {
      "Istat": "017022",
      "Comune": "Borno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25042,
      "CodFisco": "B054",
      "Abitanti": 2672,
      "Link": "http://www.comuni-italiani.it/017/022/"
    },
    {
      "Istat": "095016",
      "Comune": "Boroneddu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09080",
      "CodFisco": "B055",
      "Abitanti": 162,
      "Link": "http://www.comuni-italiani.it/095/016/"
    },
    {
      "Istat": "091011",
      "Comune": "Borore",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08016",
      "CodFisco": "B056",
      "Abitanti": 2209,
      "Link": "http://www.comuni-italiani.it/091/011/"
    },
    {
      "Istat": "069007",
      "Comune": "Borrello",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "B057",
      "Abitanti": 381,
      "Link": "http://www.comuni-italiani.it/069/007/"
    },
    {
      "Istat": "096006",
      "Comune": "Borriana",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13872,
      "CodFisco": "B058",
      "Abitanti": 894,
      "Link": "http://www.comuni-italiani.it/096/006/"
    },
    {
      "Istat": "026004",
      "Comune": "Borso del Grappa",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31030,
      "CodFisco": "B061",
      "Abitanti": 5863,
      "Link": "http://www.comuni-italiani.it/026/004/"
    },
    {
      "Istat": "091012",
      "Comune": "Bortigali",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08012",
      "CodFisco": "B062",
      "Abitanti": 1438,
      "Link": "http://www.comuni-italiani.it/091/012/"
    },
    {
      "Istat": 104007,
      "Comune": "Bortigiadas",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "B063",
      "Abitanti": 806,
      "Link": "http://www.comuni-italiani.it/104/007/"
    },
    {
      "Istat": "090015",
      "Comune": "Borutta",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "B064",
      "Abitanti": 283,
      "Link": "http://www.comuni-italiani.it/090/015/"
    },
    {
      "Istat": "010005",
      "Comune": "Borzonasca",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16041,
      "CodFisco": "B067",
      "Abitanti": 2206,
      "Link": "http://www.comuni-italiani.it/010/005/"
    },
    {
      "Istat": "095079",
      "Comune": "Bosa",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08013",
      "CodFisco": "B068",
      "Abitanti": 8133,
      "Link": "http://www.comuni-italiani.it/095/079/"
    },
    {
      "Istat": "029007",
      "Comune": "Bosaro",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45033,
      "CodFisco": "B069",
      "Abitanti": 1569,
      "Link": "http://www.comuni-italiani.it/029/007/"
    },
    {
      "Istat": "023010",
      "Comune": "Boschi Sant'Anna",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "B070",
      "Abitanti": 1448,
      "Link": "http://www.comuni-italiani.it/023/010/"
    },
    {
      "Istat": "023011",
      "Comune": "Bosco Chiesanuova",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37021,
      "CodFisco": "B073",
      "Abitanti": 3663,
      "Link": "http://www.comuni-italiani.it/023/011/"
    },
    {
      "Istat": "006021",
      "Comune": "Bosco Marengo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15062,
      "CodFisco": "B071",
      "Abitanti": 2551,
      "Link": "http://www.comuni-italiani.it/006/021/"
    },
    {
      "Istat": "001033",
      "Comune": "Bosconero",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10080,
      "CodFisco": "B075",
      "Abitanti": 3101,
      "Link": "http://www.comuni-italiani.it/001/033/"
    },
    {
      "Istat": "063008",
      "Comune": "Boscoreale",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80041,
      "CodFisco": "B076",
      "Abitanti": 26984,
      "Link": "http://www.comuni-italiani.it/063/008/"
    },
    {
      "Istat": "063009",
      "Comune": "Boscotrecase",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80042,
      "CodFisco": "B077",
      "Abitanti": 10645,
      "Link": "http://www.comuni-italiani.it/063/009/"
    },
    {
      "Istat": "022023",
      "Comune": "Bosentino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38049,
      "CodFisco": "B078",
      "Abitanti": 814,
      "Link": "http://www.comuni-italiani.it/022/023/"
    },
    {
      "Istat": "004026",
      "Comune": "Bosia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "B079",
      "Abitanti": 186,
      "Link": "http://www.comuni-italiani.it/004/026/"
    },
    {
      "Istat": "006022",
      "Comune": "Bosio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "B080",
      "Abitanti": 1239,
      "Link": "http://www.comuni-italiani.it/006/022/"
    },
    {
      "Istat": "097009",
      "Comune": "Bosisio Parini",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23842,
      "CodFisco": "B081",
      "Abitanti": 3519,
      "Link": "http://www.comuni-italiani.it/097/009/"
    },
    {
      "Istat": "018020",
      "Comune": "Bosnasco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "B082",
      "Abitanti": 655,
      "Link": "http://www.comuni-italiani.it/018/020/"
    },
    {
      "Istat": "016033",
      "Comune": "Bossico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "B083",
      "Abitanti": 977,
      "Link": "http://www.comuni-italiani.it/016/033/"
    },
    {
      "Istat": "004027",
      "Comune": "Bossolasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "B084",
      "Abitanti": 695,
      "Link": "http://www.comuni-italiani.it/004/027/"
    },
    {
      "Istat": "079012",
      "Comune": "Botricello",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88070,
      "CodFisco": "B085",
      "Abitanti": 4985,
      "Link": "http://www.comuni-italiani.it/079/012/"
    },
    {
      "Istat": "075009",
      "Comune": "Botrugno",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "B086",
      "Abitanti": 2891,
      "Link": "http://www.comuni-italiani.it/075/009/"
    },
    {
      "Istat": "016034",
      "Comune": "Bottanuco",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "B088",
      "Abitanti": 5254,
      "Link": "http://www.comuni-italiani.it/016/034/"
    },
    {
      "Istat": "017023",
      "Comune": "Botticino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25082,
      "CodFisco": "B091",
      "Abitanti": 10792,
      "Link": "http://www.comuni-italiani.it/017/023/"
    },
    {
      "Istat": "090016",
      "Comune": "Bottidda",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "B094",
      "Abitanti": 756,
      "Link": "http://www.comuni-italiani.it/090/016/"
    },
    {
      "Istat": "080011",
      "Comune": "Bova",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89033,
      "CodFisco": "B097",
      "Abitanti": 465,
      "Link": "http://www.comuni-italiani.it/080/011/"
    },
    {
      "Istat": "080013",
      "Comune": "Bova Marina",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89035,
      "CodFisco": "B099",
      "Abitanti": 3873,
      "Link": "http://www.comuni-italiani.it/080/013/"
    },
    {
      "Istat": "080012",
      "Comune": "Bovalino",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89034,
      "CodFisco": "B098",
      "Abitanti": 9021,
      "Link": "http://www.comuni-italiani.it/080/012/"
    },
    {
      "Istat": "017024",
      "Comune": "Bovegno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25061,
      "CodFisco": "B100",
      "Abitanti": 2280,
      "Link": "http://www.comuni-italiani.it/017/024/"
    },
    {
      "Istat": "004028",
      "Comune": "Boves",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12012,
      "CodFisco": "B101",
      "Abitanti": 9867,
      "Link": "http://www.comuni-italiani.it/004/028/"
    },
    {
      "Istat": "017025",
      "Comune": "Bovezzo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25073,
      "CodFisco": "B102",
      "Abitanti": 7512,
      "Link": "http://www.comuni-italiani.it/017/025/"
    },
    {
      "Istat": "060014",
      "Comune": "Boville Ernica",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03022",
      "CodFisco": "A720",
      "Abitanti": 8898,
      "Link": "http://www.comuni-italiani.it/060/014/"
    },
    {
      "Istat": "071007",
      "Comune": "Bovino",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71023,
      "CodFisco": "B104",
      "Abitanti": 3574,
      "Link": "http://www.comuni-italiani.it/071/007/"
    },
    {
      "Istat": 108010,
      "Comune": "Bovisio-Masciago",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20813,
      "CodFisco": "B105",
      "Abitanti": 16903,
      "Link": "http://www.comuni-italiani.it/108/010/"
    },
    {
      "Istat": "028014",
      "Comune": "Bovolenta",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35024,
      "CodFisco": "B106",
      "Abitanti": 3384,
      "Link": "http://www.comuni-italiani.it/028/014/"
    },
    {
      "Istat": "023012",
      "Comune": "Bovolone",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37051,
      "CodFisco": "B107",
      "Abitanti": 15933,
      "Link": "http://www.comuni-italiani.it/023/012/"
    },
    {
      "Istat": "006023",
      "Comune": "Bozzole",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "B109",
      "Abitanti": 315,
      "Link": "http://www.comuni-italiani.it/006/023/"
    },
    {
      "Istat": "020007",
      "Comune": "Bozzolo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46012,
      "CodFisco": "B110",
      "Abitanti": 4185,
      "Link": "http://www.comuni-italiani.it/020/007/"
    },
    {
      "Istat": "004029",
      "Comune": "Bra",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12042,
      "CodFisco": "B111",
      "Abitanti": 29871,
      "Link": "http://www.comuni-italiani.it/004/029/"
    },
    {
      "Istat": "016035",
      "Comune": "Bracca",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "B112",
      "Abitanti": 767,
      "Link": "http://www.comuni-italiani.it/016/035/"
    },
    {
      "Istat": "058013",
      "Comune": "Bracciano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00062",
      "CodFisco": "B114",
      "Abitanti": 18889,
      "Link": "http://www.comuni-italiani.it/058/013/"
    },
    {
      "Istat": "065016",
      "Comune": "Bracigliano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84082,
      "CodFisco": "B115",
      "Abitanti": 5621,
      "Link": "http://www.comuni-italiani.it/065/016/"
    },
    {
      "Istat": "021009",
      "Comune": "Braies",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "B116",
      "Abitanti": 657,
      "Link": "http://www.comuni-italiani.it/021/009/"
    },
    {
      "Istat": "018021",
      "Comune": "Brallo di Pregola",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "B117",
      "Abitanti": 725,
      "Link": "http://www.comuni-italiani.it/018/021/"
    },
    {
      "Istat": "080014",
      "Comune": "Brancaleone",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89036,
      "CodFisco": "B118",
      "Abitanti": 3769,
      "Link": "http://www.comuni-italiani.it/080/014/"
    },
    {
      "Istat": "017026",
      "Comune": "Brandico",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "B120",
      "Abitanti": 1653,
      "Link": "http://www.comuni-italiani.it/017/026/"
    },
    {
      "Istat": "001034",
      "Comune": "Brandizzo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10032,
      "CodFisco": "B121",
      "Abitanti": 8297,
      "Link": "http://www.comuni-italiani.it/001/034/"
    },
    {
      "Istat": "016036",
      "Comune": "Branzi",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "B123",
      "Abitanti": 727,
      "Link": "http://www.comuni-italiani.it/016/036/"
    },
    {
      "Istat": "017027",
      "Comune": "Braone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "B124",
      "Abitanti": 673,
      "Link": "http://www.comuni-italiani.it/017/027/"
    },
    {
      "Istat": "012017",
      "Comune": "Brebbia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "B126",
      "Abitanti": 3360,
      "Link": "http://www.comuni-italiani.it/012/017/"
    },
    {
      "Istat": "026005",
      "Comune": "Breda di Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31030,
      "CodFisco": "B128",
      "Abitanti": 7852,
      "Link": "http://www.comuni-italiani.it/026/005/"
    },
    {
      "Istat": "012018",
      "Comune": "Bregano",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "B131",
      "Abitanti": 842,
      "Link": "http://www.comuni-italiani.it/012/018/"
    },
    {
      "Istat": "024014",
      "Comune": "Breganze",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36042,
      "CodFisco": "B132",
      "Abitanti": 8747,
      "Link": "http://www.comuni-italiani.it/024/014/"
    },
    {
      "Istat": "013028",
      "Comune": "Bregnano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "B134",
      "Abitanti": 6152,
      "Link": "http://www.comuni-italiani.it/013/028/"
    },
    {
      "Istat": "022024",
      "Comune": "Breguzzo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38081,
      "CodFisco": "B135",
      "Abitanti": 586,
      "Link": "http://www.comuni-italiani.it/022/024/"
    },
    {
      "Istat": "002019",
      "Comune": "Breia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "B136",
      "Abitanti": 188,
      "Link": "http://www.comuni-italiani.it/002/019/"
    },
    {
      "Istat": "016037",
      "Comune": "Brembate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24041,
      "CodFisco": "B137",
      "Abitanti": 8234,
      "Link": "http://www.comuni-italiani.it/016/037/"
    },
    {
      "Istat": "016038",
      "Comune": "Brembate di Sopra",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "B138",
      "Abitanti": 7832,
      "Link": "http://www.comuni-italiani.it/016/038/"
    },
    {
      "Istat": "016039",
      "Comune": "Brembilla",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24012,
      "CodFisco": "B140",
      "Abitanti": 4179,
      "Link": "http://www.comuni-italiani.it/016/039/"
    },
    {
      "Istat": "098006",
      "Comune": "Brembio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26822,
      "CodFisco": "B141",
      "Abitanti": 2707,
      "Link": "http://www.comuni-italiani.it/098/006/"
    },
    {
      "Istat": "018022",
      "Comune": "Breme",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "B142",
      "Abitanti": 872,
      "Link": "http://www.comuni-italiani.it/018/022/"
    },
    {
      "Istat": "024015",
      "Comune": "Brendola",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "B143",
      "Abitanti": 6721,
      "Link": "http://www.comuni-italiani.it/024/015/"
    },
    {
      "Istat": "013029",
      "Comune": "Brenna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22040,
      "CodFisco": "B144",
      "Abitanti": 1987,
      "Link": "http://www.comuni-italiani.it/013/029/"
    },
    {
      "Istat": "021010",
      "Comune": "Brennero",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39041,
      "CodFisco": "B145",
      "Abitanti": 2093,
      "Link": "http://www.comuni-italiani.it/021/010/"
    },
    {
      "Istat": "017028",
      "Comune": "Breno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25043,
      "CodFisco": "B149",
      "Abitanti": 4965,
      "Link": "http://www.comuni-italiani.it/017/028/"
    },
    {
      "Istat": "012019",
      "Comune": "Brenta",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "B150",
      "Abitanti": 1811,
      "Link": "http://www.comuni-italiani.it/012/019/"
    },
    {
      "Istat": "023013",
      "Comune": "Brentino Belluno",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "B152",
      "Abitanti": 1416,
      "Link": "http://www.comuni-italiani.it/023/013/"
    },
    {
      "Istat": "022025",
      "Comune": "Brentonico",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "B153",
      "Abitanti": 3920,
      "Link": "http://www.comuni-italiani.it/022/025/"
    },
    {
      "Istat": "023014",
      "Comune": "Brenzone",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "B154",
      "Abitanti": 2542,
      "Link": "http://www.comuni-italiani.it/023/014/"
    },
    {
      "Istat": "035006",
      "Comune": "Brescello",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42041,
      "CodFisco": "B156",
      "Abitanti": 5604,
      "Link": "http://www.comuni-italiani.it/035/006/"
    },
    {
      "Istat": "017029",
      "Comune": "Brescia",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": "251xx",
      "CodFisco": "B157",
      "Abitanti": 193879,
      "Link": "http://www.comuni-italiani.it/017/029/"
    },
    {
      "Istat": "022026",
      "Comune": "Bresimo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "B158",
      "Abitanti": 248,
      "Link": "http://www.comuni-italiani.it/022/026/"
    },
    {
      "Istat": "018023",
      "Comune": "Bressana Bottarone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27042,
      "CodFisco": "B159",
      "Abitanti": 3560,
      "Link": "http://www.comuni-italiani.it/018/023/"
    },
    {
      "Istat": "021011",
      "Comune": "Bressanone",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39042,
      "CodFisco": "B160",
      "Abitanti": 20689,
      "Link": "http://www.comuni-italiani.it/021/011/"
    },
    {
      "Istat": "024016",
      "Comune": "Bressanvido",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "B161",
      "Abitanti": 3133,
      "Link": "http://www.comuni-italiani.it/024/016/"
    },
    {
      "Istat": "015032",
      "Comune": "Bresso",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20091,
      "CodFisco": "B162",
      "Abitanti": 26399,
      "Link": "http://www.comuni-italiani.it/015/032/"
    },
    {
      "Istat": "022027",
      "Comune": "Brez",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38021,
      "CodFisco": "B165",
      "Abitanti": 725,
      "Link": "http://www.comuni-italiani.it/022/027/"
    },
    {
      "Istat": "012020",
      "Comune": "Brezzo di Bedero",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "B166",
      "Abitanti": 1185,
      "Link": "http://www.comuni-italiani.it/012/020/"
    },
    {
      "Istat": "004030",
      "Comune": "Briaglia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "B167",
      "Abitanti": 319,
      "Link": "http://www.comuni-italiani.it/004/030/"
    },
    {
      "Istat": 102003,
      "Comune": "Briatico",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89817,
      "CodFisco": "B169",
      "Abitanti": 4111,
      "Link": "http://www.comuni-italiani.it/102/003/"
    },
    {
      "Istat": "001035",
      "Comune": "Bricherasio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "B171",
      "Abitanti": 4454,
      "Link": "http://www.comuni-italiani.it/001/035/"
    },
    {
      "Istat": "013030",
      "Comune": "Brienno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22010,
      "CodFisco": "B172",
      "Abitanti": 420,
      "Link": "http://www.comuni-italiani.it/013/030/"
    },
    {
      "Istat": "076013",
      "Comune": "Brienza",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "B173",
      "Abitanti": 4187,
      "Link": "http://www.comuni-italiani.it/076/013/"
    },
    {
      "Istat": "004031",
      "Comune": "Briga Alta",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 18025,
      "CodFisco": "B175",
      "Abitanti": 48,
      "Link": "http://www.comuni-italiani.it/004/031/"
    },
    {
      "Istat": "003026",
      "Comune": "Briga Novarese",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "B176",
      "Abitanti": 3038,
      "Link": "http://www.comuni-italiani.it/003/026/"
    },
    {
      "Istat": "016040",
      "Comune": "Brignano Gera d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24053,
      "CodFisco": "B178",
      "Abitanti": 5901,
      "Link": "http://www.comuni-italiani.it/016/040/"
    },
    {
      "Istat": "006024",
      "Comune": "Brignano-Frascata",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "B179",
      "Abitanti": 456,
      "Link": "http://www.comuni-italiani.it/006/024/"
    },
    {
      "Istat": "074001",
      "Comune": "Brindisi",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72100,
      "CodFisco": "B180",
      "Abitanti": 89780,
      "Link": "http://www.comuni-italiani.it/074/001/"
    },
    {
      "Istat": "076014",
      "Comune": "Brindisi Montagna",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "B181",
      "Abitanti": 931,
      "Link": "http://www.comuni-italiani.it/076/014/"
    },
    {
      "Istat": "012021",
      "Comune": "Brinzio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "B182",
      "Abitanti": 880,
      "Link": "http://www.comuni-italiani.it/012/021/"
    },
    {
      "Istat": "003027",
      "Comune": "Briona",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28072,
      "CodFisco": "B183",
      "Abitanti": 1244,
      "Link": "http://www.comuni-italiani.it/003/027/"
    },
    {
      "Istat": "017030",
      "Comune": "Brione",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "B184",
      "Abitanti": 703,
      "Link": "http://www.comuni-italiani.it/017/030/"
    },
    {
      "Istat": "022028",
      "Comune": "Brione",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38083,
      "CodFisco": "B185",
      "Abitanti": 151,
      "Link": "http://www.comuni-italiani.it/022/028/"
    },
    {
      "Istat": 108011,
      "Comune": "Briosco",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20836,
      "CodFisco": "B187",
      "Abitanti": 5955,
      "Link": "http://www.comuni-italiani.it/108/011/"
    },
    {
      "Istat": "039004",
      "Comune": "Brisighella",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 48013,
      "CodFisco": "B188",
      "Abitanti": 7847,
      "Link": "http://www.comuni-italiani.it/039/004/"
    },
    {
      "Istat": "012022",
      "Comune": "Brissago-Valtravaglia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "B191",
      "Abitanti": 1253,
      "Link": "http://www.comuni-italiani.it/012/022/"
    },
    {
      "Istat": "007011",
      "Comune": "Brissogne",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "B192",
      "Abitanti": 962,
      "Link": "http://www.comuni-italiani.it/007/011/"
    },
    {
      "Istat": "068004",
      "Comune": "Brittoli",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "B193",
      "Abitanti": 343,
      "Link": "http://www.comuni-italiani.it/068/004/"
    },
    {
      "Istat": "097010",
      "Comune": "Brivio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23883,
      "CodFisco": "B194",
      "Abitanti": 4770,
      "Link": "http://www.comuni-italiani.it/097/010/"
    },
    {
      "Istat": "060015",
      "Comune": "Broccostella",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "B195",
      "Abitanti": 2823,
      "Link": "http://www.comuni-italiani.it/060/015/"
    },
    {
      "Istat": "024017",
      "Comune": "Brogliano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36070,
      "CodFisco": "B196",
      "Abitanti": 3846,
      "Link": "http://www.comuni-italiani.it/024/017/"
    },
    {
      "Istat": 102004,
      "Comune": "Brognaturo",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89822,
      "CodFisco": "B197",
      "Abitanti": 665,
      "Link": "http://www.comuni-italiani.it/102/004/"
    },
    {
      "Istat": "083007",
      "Comune": "Brolo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98061,
      "CodFisco": "B198",
      "Abitanti": 5846,
      "Link": "http://www.comuni-italiani.it/083/007/"
    },
    {
      "Istat": "004032",
      "Comune": "Brondello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "B200",
      "Abitanti": 319,
      "Link": "http://www.comuni-italiani.it/004/032/"
    },
    {
      "Istat": "018024",
      "Comune": "Broni",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27043,
      "CodFisco": "B201",
      "Abitanti": 9528,
      "Link": "http://www.comuni-italiani.it/018/024/"
    },
    {
      "Istat": "087009",
      "Comune": "Bronte",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95034,
      "CodFisco": "B202",
      "Abitanti": 19437,
      "Link": "http://www.comuni-italiani.it/087/009/"
    },
    {
      "Istat": "021012",
      "Comune": "Bronzolo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39051,
      "CodFisco": "B203",
      "Abitanti": 2647,
      "Link": "http://www.comuni-italiani.it/021/012/"
    },
    {
      "Istat": "004033",
      "Comune": "Brossasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "B204",
      "Abitanti": 1099,
      "Link": "http://www.comuni-italiani.it/004/033/"
    },
    {
      "Istat": "001036",
      "Comune": "Brosso",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "B205",
      "Abitanti": 472,
      "Link": "http://www.comuni-italiani.it/001/036/"
    },
    {
      "Istat": 103013,
      "Comune": "Brovello-Carpugnino",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28833,
      "CodFisco": "B207",
      "Abitanti": 689,
      "Link": "http://www.comuni-italiani.it/103/013/"
    },
    {
      "Istat": "001037",
      "Comune": "Brozolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "B209",
      "Abitanti": 481,
      "Link": "http://www.comuni-italiani.it/001/037/"
    },
    {
      "Istat": 108012,
      "Comune": "Brugherio",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20861,
      "CodFisco": "B212",
      "Abitanti": 33484,
      "Link": "http://www.comuni-italiani.it/108/012/"
    },
    {
      "Istat": "028015",
      "Comune": "Brugine",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "B213",
      "Abitanti": 6968,
      "Link": "http://www.comuni-italiani.it/028/015/"
    },
    {
      "Istat": "011007",
      "Comune": "Brugnato",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "B214",
      "Abitanti": 1276,
      "Link": "http://www.comuni-italiani.it/011/007/"
    },
    {
      "Istat": "093007",
      "Comune": "Brugnera",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33070,
      "CodFisco": "B215",
      "Abitanti": 9300,
      "Link": "http://www.comuni-italiani.it/093/007/"
    },
    {
      "Istat": "001038",
      "Comune": "Bruino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "B216",
      "Abitanti": 8520,
      "Link": "http://www.comuni-italiani.it/001/038/"
    },
    {
      "Istat": "016041",
      "Comune": "Brumano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24037,
      "CodFisco": "B217",
      "Abitanti": 95,
      "Link": "http://www.comuni-italiani.it/016/041/"
    },
    {
      "Istat": "013032",
      "Comune": "Brunate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22034,
      "CodFisco": "B218",
      "Abitanti": 1805,
      "Link": "http://www.comuni-italiani.it/013/032/"
    },
    {
      "Istat": "012023",
      "Comune": "Brunello",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "B219",
      "Abitanti": 1041,
      "Link": "http://www.comuni-italiani.it/012/023/"
    },
    {
      "Istat": "021013",
      "Comune": "Brunico",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39031,
      "CodFisco": "B220",
      "Abitanti": 15523,
      "Link": "http://www.comuni-italiani.it/021/013/"
    },
    {
      "Istat": "005010",
      "Comune": "Bruno",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14046,
      "CodFisco": "B221",
      "Abitanti": 360,
      "Link": "http://www.comuni-italiani.it/005/010/"
    },
    {
      "Istat": "016042",
      "Comune": "Brusaporto",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "B223",
      "Abitanti": 5354,
      "Link": "http://www.comuni-italiani.it/016/042/"
    },
    {
      "Istat": "001039",
      "Comune": "Brusasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "B225",
      "Abitanti": 1760,
      "Link": "http://www.comuni-italiani.it/001/039/"
    },
    {
      "Istat": "063010",
      "Comune": "Brusciano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80031,
      "CodFisco": "B227",
      "Abitanti": 16017,
      "Link": "http://www.comuni-italiani.it/063/010/"
    },
    {
      "Istat": "012024",
      "Comune": "Brusimpiano",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "B228",
      "Abitanti": 1173,
      "Link": "http://www.comuni-italiani.it/012/024/"
    },
    {
      "Istat": "096007",
      "Comune": "Brusnengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13862,
      "CodFisco": "B229",
      "Abitanti": 2206,
      "Link": "http://www.comuni-italiani.it/096/007/"
    },
    {
      "Istat": "007012",
      "Comune": "Brusson",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11022,
      "CodFisco": "B230",
      "Abitanti": 859,
      "Link": "http://www.comuni-italiani.it/007/012/"
    },
    {
      "Istat": "001040",
      "Comune": "Bruzolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "B232",
      "Abitanti": 1540,
      "Link": "http://www.comuni-italiani.it/001/040/"
    },
    {
      "Istat": "080015",
      "Comune": "Bruzzano Zeffirio",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "B234",
      "Abitanti": 1209,
      "Link": "http://www.comuni-italiani.it/080/015/"
    },
    {
      "Istat": "015035",
      "Comune": "Bubbiano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "B235",
      "Abitanti": 2193,
      "Link": "http://www.comuni-italiani.it/015/035/"
    },
    {
      "Istat": "005011",
      "Comune": "Bubbio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14051,
      "CodFisco": "B236",
      "Abitanti": 919,
      "Link": "http://www.comuni-italiani.it/005/011/"
    },
    {
      "Istat": "089003",
      "Comune": "Buccheri",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "B237",
      "Abitanti": 2148,
      "Link": "http://www.comuni-italiani.it/089/003/"
    },
    {
      "Istat": "069008",
      "Comune": "Bucchianico",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66011,
      "CodFisco": "B238",
      "Abitanti": 5223,
      "Link": "http://www.comuni-italiani.it/069/008/"
    },
    {
      "Istat": "062010",
      "Comune": "Bucciano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82010,
      "CodFisco": "B239",
      "Abitanti": 2064,
      "Link": "http://www.comuni-italiani.it/062/010/"
    },
    {
      "Istat": "015036",
      "Comune": "Buccinasco",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "B240",
      "Abitanti": 26937,
      "Link": "http://www.comuni-italiani.it/015/036/"
    },
    {
      "Istat": "065017",
      "Comune": "Buccino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84021,
      "CodFisco": "B242",
      "Abitanti": 5394,
      "Link": "http://www.comuni-italiani.it/065/017/"
    },
    {
      "Istat": "051005",
      "Comune": "Bucine",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52021,
      "CodFisco": "B243",
      "Abitanti": 10194,
      "Link": "http://www.comuni-italiani.it/051/005/"
    },
    {
      "Istat": 104008,
      "Comune": "BuddusÚ",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07020",
      "CodFisco": "B246",
      "Abitanti": 4009,
      "Link": "http://www.comuni-italiani.it/104/008/"
    },
    {
      "Istat": "093008",
      "Comune": "Budoia",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33070,
      "CodFisco": "B247",
      "Abitanti": 2573,
      "Link": "http://www.comuni-italiani.it/093/008/"
    },
    {
      "Istat": 104009,
      "Comune": "Budoni",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "B248",
      "Abitanti": 4951,
      "Link": "http://www.comuni-italiani.it/104/009/"
    },
    {
      "Istat": "037008",
      "Comune": "Budrio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40054,
      "CodFisco": "B249",
      "Abitanti": 17994,
      "Link": "http://www.comuni-italiani.it/037/008/"
    },
    {
      "Istat": 107001,
      "Comune": "Buggerru",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "B250",
      "Abitanti": 1110,
      "Link": "http://www.comuni-italiani.it/107/001/"
    },
    {
      "Istat": "047003",
      "Comune": "Buggiano",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51011,
      "CodFisco": "B251",
      "Abitanti": 8882,
      "Link": "http://www.comuni-italiani.it/047/003/"
    },
    {
      "Istat": "014010",
      "Comune": "Buglio in Monte",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "B255",
      "Abitanti": 2104,
      "Link": "http://www.comuni-italiani.it/014/010/"
    },
    {
      "Istat": "066012",
      "Comune": "Bugnara",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "B256",
      "Abitanti": 1088,
      "Link": "http://www.comuni-italiani.it/066/012/"
    },
    {
      "Istat": "012025",
      "Comune": "Buguggiate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "B258",
      "Abitanti": 3122,
      "Link": "http://www.comuni-italiani.it/012/025/"
    },
    {
      "Istat": "030013",
      "Comune": "Buja",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "B259",
      "Abitanti": 6759,
      "Link": "http://www.comuni-italiani.it/030/013/"
    },
    {
      "Istat": "097011",
      "Comune": "Bulciago",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23892,
      "CodFisco": "B261",
      "Abitanti": 3034,
      "Link": "http://www.comuni-italiani.it/097/011/"
    },
    {
      "Istat": "013034",
      "Comune": "Bulgarograsso",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "B262",
      "Abitanti": 3921,
      "Link": "http://www.comuni-italiani.it/013/034/"
    },
    {
      "Istat": "090018",
      "Comune": "Bultei",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "B264",
      "Abitanti": 1057,
      "Link": "http://www.comuni-italiani.it/090/018/"
    },
    {
      "Istat": "090019",
      "Comune": "Bulzi",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "B265",
      "Abitanti": 562,
      "Link": "http://www.comuni-italiani.it/090/019/"
    },
    {
      "Istat": "065018",
      "Comune": "Buonabitacolo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84032,
      "CodFisco": "B266",
      "Abitanti": 2632,
      "Link": "http://www.comuni-italiani.it/065/018/"
    },
    {
      "Istat": "062011",
      "Comune": "Buonalbergo",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "B267",
      "Abitanti": 1852,
      "Link": "http://www.comuni-italiani.it/062/011/"
    },
    {
      "Istat": "052003",
      "Comune": "Buonconvento",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53022,
      "CodFisco": "B269",
      "Abitanti": 3197,
      "Link": "http://www.comuni-italiani.it/052/003/"
    },
    {
      "Istat": "078020",
      "Comune": "Buonvicino",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "B270",
      "Abitanti": 2349,
      "Link": "http://www.comuni-italiani.it/078/020/"
    },
    {
      "Istat": 108013,
      "Comune": "Burago di Molgora",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20875,
      "CodFisco": "B272",
      "Abitanti": 4250,
      "Link": "http://www.comuni-italiani.it/108/013/"
    },
    {
      "Istat": "092008",
      "Comune": "Burcei",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "B274",
      "Abitanti": 2917,
      "Link": "http://www.comuni-italiani.it/092/008/"
    },
    {
      "Istat": "084005",
      "Comune": "Burgio",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92010,
      "CodFisco": "B275",
      "Abitanti": 2801,
      "Link": "http://www.comuni-italiani.it/084/005/"
    },
    {
      "Istat": "090020",
      "Comune": "Burgos",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "B276",
      "Abitanti": 958,
      "Link": "http://www.comuni-italiani.it/090/020/"
    },
    {
      "Istat": "001041",
      "Comune": "Buriasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "B278",
      "Abitanti": 1411,
      "Link": "http://www.comuni-italiani.it/001/041/"
    },
    {
      "Istat": "001042",
      "Comune": "Burolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "B279",
      "Abitanti": 1267,
      "Link": "http://www.comuni-italiani.it/001/042/"
    },
    {
      "Istat": "002021",
      "Comune": "Buronzo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "B280",
      "Abitanti": 922,
      "Link": "http://www.comuni-italiani.it/002/021/"
    },
    {
      "Istat": "095017",
      "Comune": "Busachi",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09082",
      "CodFisco": "B281",
      "Abitanti": 1415,
      "Link": "http://www.comuni-italiani.it/095/017/"
    },
    {
      "Istat": "010006",
      "Comune": "Busalla",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16012,
      "CodFisco": "B282",
      "Abitanti": 5848,
      "Link": "http://www.comuni-italiani.it/010/006/"
    },
    {
      "Istat": "035007",
      "Comune": "Busana",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42032,
      "CodFisco": "B283",
      "Abitanti": 1319,
      "Link": "http://www.comuni-italiani.it/035/007/"
    },
    {
      "Istat": "001043",
      "Comune": "Busano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "B284",
      "Abitanti": 1571,
      "Link": "http://www.comuni-italiani.it/001/043/"
    },
    {
      "Istat": "004034",
      "Comune": "Busca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12022,
      "CodFisco": "B285",
      "Abitanti": 10084,
      "Link": "http://www.comuni-italiani.it/004/034/"
    },
    {
      "Istat": "015038",
      "Comune": "Buscate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20010,
      "CodFisco": "B286",
      "Abitanti": 4793,
      "Link": "http://www.comuni-italiani.it/015/038/"
    },
    {
      "Istat": "089004",
      "Comune": "Buscemi",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "B287",
      "Abitanti": 1147,
      "Link": "http://www.comuni-italiani.it/089/004/"
    },
    {
      "Istat": "081002",
      "Comune": "Buseto Palizzolo",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91012,
      "CodFisco": "B288",
      "Abitanti": 3095,
      "Link": "http://www.comuni-italiani.it/081/002/"
    },
    {
      "Istat": 108051,
      "Comune": "Busnago",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20874,
      "CodFisco": "B289",
      "Abitanti": 6376,
      "Link": "http://www.comuni-italiani.it/108/051/"
    },
    {
      "Istat": "015040",
      "Comune": "Bussero",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "B292",
      "Abitanti": 8645,
      "Link": "http://www.comuni-italiani.it/015/040/"
    },
    {
      "Istat": "034007",
      "Comune": "Busseto",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43011,
      "CodFisco": "B293",
      "Abitanti": 7052,
      "Link": "http://www.comuni-italiani.it/034/007/"
    },
    {
      "Istat": "068005",
      "Comune": "Bussi sul Tirino",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65022,
      "CodFisco": "B294",
      "Abitanti": 2718,
      "Link": "http://www.comuni-italiani.it/068/005/"
    },
    {
      "Istat": "070005",
      "Comune": "Busso",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "B295",
      "Abitanti": 1405,
      "Link": "http://www.comuni-italiani.it/070/005/"
    },
    {
      "Istat": "023015",
      "Comune": "Bussolengo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37012,
      "CodFisco": "B296",
      "Abitanti": 19690,
      "Link": "http://www.comuni-italiani.it/023/015/"
    },
    {
      "Istat": "001044",
      "Comune": "Bussoleno",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10053,
      "CodFisco": "B297",
      "Abitanti": 6521,
      "Link": "http://www.comuni-italiani.it/001/044/"
    },
    {
      "Istat": "012026",
      "Comune": "Busto Arsizio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21052,
      "CodFisco": "B300",
      "Abitanti": 81760,
      "Link": "http://www.comuni-italiani.it/012/026/"
    },
    {
      "Istat": "015041",
      "Comune": "Busto Garolfo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "B301",
      "Abitanti": 13513,
      "Link": "http://www.comuni-italiani.it/015/041/"
    },
    {
      "Istat": "085003",
      "Comune": "Butera",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93011,
      "CodFisco": "B302",
      "Abitanti": 4992,
      "Link": "http://www.comuni-italiani.it/085/003/"
    },
    {
      "Istat": "050002",
      "Comune": "Buti",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56032,
      "CodFisco": "B303",
      "Abitanti": 5856,
      "Link": "http://www.comuni-italiani.it/050/002/"
    },
    {
      "Istat": "023016",
      "Comune": "Buttapietra",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "B304",
      "Abitanti": 6968,
      "Link": "http://www.comuni-italiani.it/023/016/"
    },
    {
      "Istat": "001045",
      "Comune": "Buttigliera Alta",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "B305",
      "Abitanti": 6458,
      "Link": "http://www.comuni-italiani.it/001/045/"
    },
    {
      "Istat": "005012",
      "Comune": "Buttigliera d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 14021,
      "CodFisco": "B306",
      "Abitanti": 2512,
      "Link": "http://www.comuni-italiani.it/005/012/"
    },
    {
      "Istat": "030014",
      "Comune": "Buttrio",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33042,
      "CodFisco": "B309",
      "Abitanti": 4140,
      "Link": "http://www.comuni-italiani.it/030/014/"
    },
    {
      "Istat": "019008",
      "Comune": "Ca' d'Andrea",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26030,
      "CodFisco": "B320",
      "Abitanti": 482,
      "Link": "http://www.comuni-italiani.it/019/008/"
    },
    {
      "Istat": "006025",
      "Comune": "Cabella Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "B311",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/006/025/"
    },
    {
      "Istat": "013035",
      "Comune": "Cabiate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "B313",
      "Abitanti": 7394,
      "Link": "http://www.comuni-italiani.it/013/035/"
    },
    {
      "Istat": "095018",
      "Comune": "Cabras",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09072",
      "CodFisco": "B314",
      "Abitanti": 9169,
      "Link": "http://www.comuni-italiani.it/095/018/"
    },
    {
      "Istat": "082014",
      "Comune": "Caccamo",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90012,
      "CodFisco": "B315",
      "Abitanti": 8382,
      "Link": "http://www.comuni-italiani.it/082/014/"
    },
    {
      "Istat": 101002,
      "Comune": "Caccuri",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 88833,
      "CodFisco": "B319",
      "Abitanti": 1730,
      "Link": "http://www.comuni-italiani.it/101/002/"
    },
    {
      "Istat": "012027",
      "Comune": "Cadegliano-Viconago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21031,
      "CodFisco": "B326",
      "Abitanti": 1870,
      "Link": "http://www.comuni-italiani.it/012/027/"
    },
    {
      "Istat": "035008",
      "Comune": "Cadelbosco di Sopra",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42023,
      "CodFisco": "B328",
      "Abitanti": 10542,
      "Link": "http://www.comuni-italiani.it/035/008/"
    },
    {
      "Istat": "033007",
      "Comune": "Cadeo",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "B332",
      "Abitanti": 6187,
      "Link": "http://www.comuni-italiani.it/033/007/"
    },
    {
      "Istat": "022029",
      "Comune": "Caderzone Terme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "B335",
      "Abitanti": 679,
      "Link": "http://www.comuni-italiani.it/022/029/"
    },
    {
      "Istat": "028016",
      "Comune": "Cadoneghe",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "B345",
      "Abitanti": 16131,
      "Link": "http://www.comuni-italiani.it/028/016/"
    },
    {
      "Istat": "013036",
      "Comune": "Cadorago",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22071,
      "CodFisco": "B346",
      "Abitanti": 7646,
      "Link": "http://www.comuni-italiani.it/013/036/"
    },
    {
      "Istat": "012028",
      "Comune": "Cadrezzate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21020,
      "CodFisco": "B347",
      "Abitanti": 1779,
      "Link": "http://www.comuni-italiani.it/012/028/"
    },
    {
      "Istat": "026006",
      "Comune": "Caerano di San Marco",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31031,
      "CodFisco": "B349",
      "Abitanti": 8050,
      "Link": "http://www.comuni-italiani.it/026/006/"
    },
    {
      "Istat": "001046",
      "Comune": "Cafasse",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "B350",
      "Abitanti": 3585,
      "Link": "http://www.comuni-italiani.it/001/046/"
    },
    {
      "Istat": "065019",
      "Comune": "Caggiano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "B351",
      "Abitanti": 2858,
      "Link": "http://www.comuni-italiani.it/065/019/"
    },
    {
      "Istat": "041007",
      "Comune": "Cagli",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61043,
      "CodFisco": "B352",
      "Abitanti": 9148,
      "Link": "http://www.comuni-italiani.it/041/007/"
    },
    {
      "Istat": "092009",
      "Comune": "Cagliari",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "091xx",
      "CodFisco": "B354",
      "Abitanti": 156488,
      "Link": "http://www.comuni-italiani.it/092/009/"
    },
    {
      "Istat": "013037",
      "Comune": "Caglio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "B355",
      "Abitanti": 448,
      "Link": "http://www.comuni-italiani.it/013/037/"
    },
    {
      "Istat": "066013",
      "Comune": "Cagnano Amiterno",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67012,
      "CodFisco": "B358",
      "Abitanti": 1472,
      "Link": "http://www.comuni-italiani.it/066/013/"
    },
    {
      "Istat": "071008",
      "Comune": "Cagnano Varano",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71010,
      "CodFisco": "B357",
      "Abitanti": 7663,
      "Link": "http://www.comuni-italiani.it/071/008/"
    },
    {
      "Istat": "013038",
      "Comune": "Cagno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "B359",
      "Abitanti": 2054,
      "Link": "http://www.comuni-italiani.it/013/038/"
    },
    {
      "Istat": "022030",
      "Comune": "CagnÚ",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38028,
      "CodFisco": "B360",
      "Abitanti": 365,
      "Link": "http://www.comuni-italiani.it/022/030/"
    },
    {
      "Istat": "061008",
      "Comune": "Caianello",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81059,
      "CodFisco": "B361",
      "Abitanti": 1775,
      "Link": "http://www.comuni-italiani.it/061/008/"
    },
    {
      "Istat": "061009",
      "Comune": "Caiazzo",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81013,
      "CodFisco": "B362",
      "Abitanti": 5822,
      "Link": "http://www.comuni-italiani.it/061/009/"
    },
    {
      "Istat": "021014",
      "Comune": "Caines",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "B364",
      "Abitanti": 400,
      "Link": "http://www.comuni-italiani.it/021/014/"
    },
    {
      "Istat": "017031",
      "Comune": "Caino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25070,
      "CodFisco": "B365",
      "Abitanti": 2082,
      "Link": "http://www.comuni-italiani.it/017/031/"
    },
    {
      "Istat": "014011",
      "Comune": "Caiolo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "B366",
      "Abitanti": 1017,
      "Link": "http://www.comuni-italiani.it/014/011/"
    },
    {
      "Istat": "064013",
      "Comune": "Cairano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "B367",
      "Abitanti": 371,
      "Link": "http://www.comuni-italiani.it/064/013/"
    },
    {
      "Istat": "012029",
      "Comune": "Cairate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21050,
      "CodFisco": "B368",
      "Abitanti": 7887,
      "Link": "http://www.comuni-italiani.it/012/029/"
    },
    {
      "Istat": "009015",
      "Comune": "Cairo Montenotte",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17014,
      "CodFisco": "B369",
      "Abitanti": 13695,
      "Link": "http://www.comuni-italiani.it/009/015/"
    },
    {
      "Istat": "063011",
      "Comune": "Caivano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80023,
      "CodFisco": "B371",
      "Abitanti": 36871,
      "Link": "http://www.comuni-italiani.it/063/011/"
    },
    {
      "Istat": "064014",
      "Comune": "Calabritto",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "B374",
      "Abitanti": 2560,
      "Link": "http://www.comuni-italiani.it/064/014/"
    },
    {
      "Istat": "025008",
      "Comune": "Calalzo di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32042,
      "CodFisco": "B375",
      "Abitanti": 2246,
      "Link": "http://www.comuni-italiani.it/025/008/"
    },
    {
      "Istat": "005013",
      "Comune": "Calamandrana",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14042,
      "CodFisco": "B376",
      "Abitanti": 1807,
      "Link": "http://www.comuni-italiani.it/005/013/"
    },
    {
      "Istat": "084006",
      "Comune": "Calamonaci",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92010,
      "CodFisco": "B377",
      "Abitanti": 1387,
      "Link": "http://www.comuni-italiani.it/084/006/"
    },
    {
      "Istat": 104010,
      "Comune": "Calangianus",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07023",
      "CodFisco": "B378",
      "Abitanti": 4337,
      "Link": "http://www.comuni-italiani.it/104/010/"
    },
    {
      "Istat": "080016",
      "Comune": "Calanna",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89050,
      "CodFisco": "B379",
      "Abitanti": 1006,
      "Link": "http://www.comuni-italiani.it/080/016/"
    },
    {
      "Istat": 103014,
      "Comune": "Calasca-Castiglione",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28873,
      "CodFisco": "B380",
      "Abitanti": 699,
      "Link": "http://www.comuni-italiani.it/103/014/"
    },
    {
      "Istat": "086005",
      "Comune": "Calascibetta",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "B381",
      "Abitanti": 4685,
      "Link": "http://www.comuni-italiani.it/086/005/"
    },
    {
      "Istat": "066014",
      "Comune": "Calascio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "B382",
      "Abitanti": 148,
      "Link": "http://www.comuni-italiani.it/066/014/"
    },
    {
      "Istat": 107002,
      "Comune": "Calasetta",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09011",
      "CodFisco": "B383",
      "Abitanti": 2901,
      "Link": "http://www.comuni-italiani.it/107/002/"
    },
    {
      "Istat": "087010",
      "Comune": "Calatabiano",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95011,
      "CodFisco": "B384",
      "Abitanti": 5437,
      "Link": "http://www.comuni-italiani.it/087/010/"
    },
    {
      "Istat": "081003",
      "Comune": "Calatafimi Segesta",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91013,
      "CodFisco": "B385",
      "Abitanti": 7055,
      "Link": "http://www.comuni-italiani.it/081/003/"
    },
    {
      "Istat": "022031",
      "Comune": "Calavino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38072,
      "CodFisco": "B386",
      "Abitanti": 1496,
      "Link": "http://www.comuni-italiani.it/022/031/"
    },
    {
      "Istat": "056010",
      "Comune": "Calcata",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "B388",
      "Abitanti": 924,
      "Link": "http://www.comuni-italiani.it/056/010/"
    },
    {
      "Istat": "022032",
      "Comune": "Calceranica al Lago",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "B389",
      "Abitanti": 1278,
      "Link": "http://www.comuni-italiani.it/022/032/"
    },
    {
      "Istat": "050003",
      "Comune": "Calci",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56011,
      "CodFisco": "B390",
      "Abitanti": 6513,
      "Link": "http://www.comuni-italiani.it/050/003/"
    },
    {
      "Istat": "077004",
      "Comune": "Calciano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "B391",
      "Abitanti": 807,
      "Link": "http://www.comuni-italiani.it/077/004/"
    },
    {
      "Istat": "050004",
      "Comune": "Calcinaia",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56012,
      "CodFisco": "B392",
      "Abitanti": 11692,
      "Link": "http://www.comuni-italiani.it/050/004/"
    },
    {
      "Istat": "016043",
      "Comune": "Calcinate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "B393",
      "Abitanti": 5878,
      "Link": "http://www.comuni-italiani.it/016/043/"
    },
    {
      "Istat": "017032",
      "Comune": "Calcinato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25011,
      "CodFisco": "B394",
      "Abitanti": 12725,
      "Link": "http://www.comuni-italiani.it/017/032/"
    },
    {
      "Istat": "016044",
      "Comune": "Calcio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24054,
      "CodFisco": "B395",
      "Abitanti": 5410,
      "Link": "http://www.comuni-italiani.it/016/044/"
    },
    {
      "Istat": "097012",
      "Comune": "Calco",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23885,
      "CodFisco": "B396",
      "Abitanti": 5132,
      "Link": "http://www.comuni-italiani.it/097/012/"
    },
    {
      "Istat": "021015",
      "Comune": "Caldaro sulla Strada del Vino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39052,
      "CodFisco": "B397",
      "Abitanti": 7609,
      "Link": "http://www.comuni-italiani.it/021/015/"
    },
    {
      "Istat": "043006",
      "Comune": "Caldarola",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "B398",
      "Abitanti": 1888,
      "Link": "http://www.comuni-italiani.it/043/006/"
    },
    {
      "Istat": "037009",
      "Comune": "Calderara di Reno",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40012,
      "CodFisco": "B399",
      "Abitanti": 13163,
      "Link": "http://www.comuni-italiani.it/037/009/"
    },
    {
      "Istat": "022033",
      "Comune": "Caldes",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38022,
      "CodFisco": "B400",
      "Abitanti": 1115,
      "Link": "http://www.comuni-italiani.it/022/033/"
    },
    {
      "Istat": "023017",
      "Comune": "Caldiero",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37042,
      "CodFisco": "B402",
      "Abitanti": 7537,
      "Link": "http://www.comuni-italiani.it/023/017/"
    },
    {
      "Istat": "024018",
      "Comune": "Caldogno",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36030,
      "CodFisco": "B403",
      "Abitanti": 11291,
      "Link": "http://www.comuni-italiani.it/024/018/"
    },
    {
      "Istat": "022034",
      "Comune": "Caldonazzo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38052,
      "CodFisco": "B404",
      "Abitanti": 3344,
      "Link": "http://www.comuni-italiani.it/022/034/"
    },
    {
      "Istat": "033008",
      "Comune": "Calendasco",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "B405",
      "Abitanti": 2509,
      "Link": "http://www.comuni-italiani.it/033/008/"
    },
    {
      "Istat": "048005",
      "Comune": "Calenzano",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50041,
      "CodFisco": "B406",
      "Abitanti": 16462,
      "Link": "http://www.comuni-italiani.it/048/005/"
    },
    {
      "Istat": "034008",
      "Comune": "Calestano",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43030,
      "CodFisco": "B408",
      "Abitanti": 2126,
      "Link": "http://www.comuni-italiani.it/034/008/"
    },
    {
      "Istat": "011008",
      "Comune": "Calice al Cornoviglio",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "B410",
      "Abitanti": 1177,
      "Link": "http://www.comuni-italiani.it/011/008/"
    },
    {
      "Istat": "009016",
      "Comune": "Calice Ligure",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17020,
      "CodFisco": "B409",
      "Abitanti": 1691,
      "Link": "http://www.comuni-italiani.it/009/016/"
    },
    {
      "Istat": "075010",
      "Comune": "Calimera",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73021,
      "CodFisco": "B413",
      "Abitanti": 7281,
      "Link": "http://www.comuni-italiani.it/075/010/"
    },
    {
      "Istat": "064015",
      "Comune": "Calitri",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83045,
      "CodFisco": "B415",
      "Abitanti": 5042,
      "Link": "http://www.comuni-italiani.it/064/015/"
    },
    {
      "Istat": "009017",
      "Comune": "Calizzano",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17057,
      "CodFisco": "B416",
      "Abitanti": 1586,
      "Link": "http://www.comuni-italiani.it/009/017/"
    },
    {
      "Istat": "096008",
      "Comune": "Callabiana",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13821,
      "CodFisco": "B417",
      "Abitanti": 154,
      "Link": "http://www.comuni-italiani.it/096/008/"
    },
    {
      "Istat": "005014",
      "Comune": "Calliano",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14031,
      "CodFisco": "B418",
      "Abitanti": 1395,
      "Link": "http://www.comuni-italiani.it/005/014/"
    },
    {
      "Istat": "022035",
      "Comune": "Calliano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "B419",
      "Abitanti": 1565,
      "Link": "http://www.comuni-italiani.it/022/035/"
    },
    {
      "Istat": "097013",
      "Comune": "Calolziocorte",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23801,
      "CodFisco": "B423",
      "Abitanti": 14315,
      "Link": "http://www.comuni-italiani.it/097/013/"
    },
    {
      "Istat": "078021",
      "Comune": "Calopezzati",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "B424",
      "Abitanti": 1314,
      "Link": "http://www.comuni-italiani.it/078/021/"
    },
    {
      "Istat": "005015",
      "Comune": "Calosso",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14052,
      "CodFisco": "B425",
      "Abitanti": 1351,
      "Link": "http://www.comuni-italiani.it/005/015/"
    },
    {
      "Istat": "078022",
      "Comune": "Caloveto",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "B426",
      "Abitanti": 1297,
      "Link": "http://www.comuni-italiani.it/078/022/"
    },
    {
      "Istat": "084007",
      "Comune": "Caltabellotta",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92010,
      "CodFisco": "B427",
      "Abitanti": 3974,
      "Link": "http://www.comuni-italiani.it/084/007/"
    },
    {
      "Istat": "087011",
      "Comune": "Caltagirone",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95041,
      "CodFisco": "B428",
      "Abitanti": 39573,
      "Link": "http://www.comuni-italiani.it/087/011/"
    },
    {
      "Istat": "085004",
      "Comune": "Caltanissetta",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93100,
      "CodFisco": "B429",
      "Abitanti": 60267,
      "Link": "http://www.comuni-italiani.it/085/004/"
    },
    {
      "Istat": "082015",
      "Comune": "Caltavuturo",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90022,
      "CodFisco": "B430",
      "Abitanti": 4219,
      "Link": "http://www.comuni-italiani.it/082/015/"
    },
    {
      "Istat": "003030",
      "Comune": "Caltignaga",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28010,
      "CodFisco": "B431",
      "Abitanti": 2617,
      "Link": "http://www.comuni-italiani.it/003/030/"
    },
    {
      "Istat": "029008",
      "Comune": "Calto",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "B432",
      "Abitanti": 827,
      "Link": "http://www.comuni-italiani.it/029/008/"
    },
    {
      "Istat": "024019",
      "Comune": "Caltrano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "B433",
      "Abitanti": 2636,
      "Link": "http://www.comuni-italiani.it/024/019/"
    },
    {
      "Istat": "016046",
      "Comune": "Calusco d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24033,
      "CodFisco": "B434",
      "Abitanti": 8342,
      "Link": "http://www.comuni-italiani.it/016/046/"
    },
    {
      "Istat": "001047",
      "Comune": "Caluso",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10014,
      "CodFisco": "B435",
      "Abitanti": 7679,
      "Link": "http://www.comuni-italiani.it/001/047/"
    },
    {
      "Istat": "017033",
      "Comune": "Calvagese della Riviera",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "B436",
      "Abitanti": 3501,
      "Link": "http://www.comuni-italiani.it/017/033/"
    },
    {
      "Istat": "065020",
      "Comune": "Calvanico",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84080,
      "CodFisco": "B437",
      "Abitanti": 1570,
      "Link": "http://www.comuni-italiani.it/065/020/"
    },
    {
      "Istat": "019009",
      "Comune": "Calvatone",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26030,
      "CodFisco": "B439",
      "Abitanti": 1296,
      "Link": "http://www.comuni-italiani.it/019/009/"
    },
    {
      "Istat": "076015",
      "Comune": "Calvello",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "B440",
      "Abitanti": 2000,
      "Link": "http://www.comuni-italiani.it/076/015/"
    },
    {
      "Istat": "024020",
      "Comune": "Calvene",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "B441",
      "Abitanti": 1335,
      "Link": "http://www.comuni-italiani.it/024/020/"
    },
    {
      "Istat": "016047",
      "Comune": "Calvenzano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "B442",
      "Abitanti": 4030,
      "Link": "http://www.comuni-italiani.it/016/047/"
    },
    {
      "Istat": "076016",
      "Comune": "Calvera",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "B443",
      "Abitanti": 442,
      "Link": "http://www.comuni-italiani.it/076/016/"
    },
    {
      "Istat": "062012",
      "Comune": "Calvi",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82018,
      "CodFisco": "B444",
      "Abitanti": 2634,
      "Link": "http://www.comuni-italiani.it/062/012/"
    },
    {
      "Istat": "055008",
      "Comune": "Calvi dell'Umbria",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05032",
      "CodFisco": "B446",
      "Abitanti": 1942,
      "Link": "http://www.comuni-italiani.it/055/008/"
    },
    {
      "Istat": "061010",
      "Comune": "Calvi Risorta",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81042,
      "CodFisco": "B445",
      "Abitanti": 5855,
      "Link": "http://www.comuni-italiani.it/061/010/"
    },
    {
      "Istat": "018025",
      "Comune": "Calvignano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "B447",
      "Abitanti": 127,
      "Link": "http://www.comuni-italiani.it/018/025/"
    },
    {
      "Istat": "015042",
      "Comune": "Calvignasco",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "B448",
      "Abitanti": 1196,
      "Link": "http://www.comuni-italiani.it/015/042/"
    },
    {
      "Istat": "017034",
      "Comune": "Calvisano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25012,
      "CodFisco": "B450",
      "Abitanti": 8726,
      "Link": "http://www.comuni-italiani.it/017/034/"
    },
    {
      "Istat": "063012",
      "Comune": "Calvizzano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80012,
      "CodFisco": "B452",
      "Abitanti": 12737,
      "Link": "http://www.comuni-italiani.it/063/012/"
    },
    {
      "Istat": "006026",
      "Comune": "Camagna Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "B453",
      "Abitanti": 533,
      "Link": "http://www.comuni-italiani.it/006/026/"
    },
    {
      "Istat": "046005",
      "Comune": "Camaiore",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55041,
      "CodFisco": "B455",
      "Abitanti": 32600,
      "Link": "http://www.comuni-italiani.it/046/005/"
    },
    {
      "Istat": "098007",
      "Comune": "Camairago",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26823,
      "CodFisco": "B456",
      "Abitanti": 686,
      "Link": "http://www.comuni-italiani.it/098/007/"
    },
    {
      "Istat": "096009",
      "Comune": "Camandona",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13821,
      "CodFisco": "B457",
      "Abitanti": 366,
      "Link": "http://www.comuni-italiani.it/096/009/"
    },
    {
      "Istat": "084008",
      "Comune": "Camastra",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "B460",
      "Abitanti": 2076,
      "Link": "http://www.comuni-italiani.it/084/008/"
    },
    {
      "Istat": "015044",
      "Comune": "Cambiago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20040,
      "CodFisco": "B461",
      "Abitanti": 6498,
      "Link": "http://www.comuni-italiani.it/015/044/"
    },
    {
      "Istat": "001048",
      "Comune": "Cambiano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "B462",
      "Abitanti": 6304,
      "Link": "http://www.comuni-italiani.it/001/048/"
    },
    {
      "Istat": 103015,
      "Comune": "Cambiasca",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28814,
      "CodFisco": "B463",
      "Abitanti": 1636,
      "Link": "http://www.comuni-italiani.it/103/015/"
    },
    {
      "Istat": "096010",
      "Comune": "Camburzano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13891,
      "CodFisco": "B465",
      "Abitanti": 1237,
      "Link": "http://www.comuni-italiani.it/096/010/"
    },
    {
      "Istat": "004035",
      "Comune": "Camerana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12072,
      "CodFisco": "B467",
      "Abitanti": 671,
      "Link": "http://www.comuni-italiani.it/004/035/"
    },
    {
      "Istat": "042006",
      "Comune": "Camerano",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60021,
      "CodFisco": "B468",
      "Abitanti": 7268,
      "Link": "http://www.comuni-italiani.it/042/006/"
    },
    {
      "Istat": "005016",
      "Comune": "Camerano Casasco",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "B469",
      "Abitanti": 502,
      "Link": "http://www.comuni-italiani.it/005/016/"
    },
    {
      "Istat": "016048",
      "Comune": "Camerata Cornello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "B471",
      "Abitanti": 619,
      "Link": "http://www.comuni-italiani.it/016/048/"
    },
    {
      "Istat": "058014",
      "Comune": "Camerata Nuova",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "B472",
      "Abitanti": 485,
      "Link": "http://www.comuni-italiani.it/058/014/"
    },
    {
      "Istat": "042007",
      "Comune": "Camerata Picena",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60020,
      "CodFisco": "B470",
      "Abitanti": 2369,
      "Link": "http://www.comuni-italiani.it/042/007/"
    },
    {
      "Istat": "003032",
      "Comune": "Cameri",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28062,
      "CodFisco": "B473",
      "Abitanti": 10878,
      "Link": "http://www.comuni-italiani.it/003/032/"
    },
    {
      "Istat": "043007",
      "Comune": "Camerino",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62032,
      "CodFisco": "B474",
      "Abitanti": 7130,
      "Link": "http://www.comuni-italiani.it/043/007/"
    },
    {
      "Istat": "065021",
      "Comune": "Camerota",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "B476",
      "Abitanti": 7289,
      "Link": "http://www.comuni-italiani.it/065/021/"
    },
    {
      "Istat": "061011",
      "Comune": "Camigliano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "B477",
      "Abitanti": 1884,
      "Link": "http://www.comuni-italiani.it/061/011/"
    },
    {
      "Istat": "033009",
      "Comune": "Caminata",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "B479",
      "Abitanti": 283,
      "Link": "http://www.comuni-italiani.it/033/009/"
    },
    {
      "Istat": "080017",
      "Comune": "Camini",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "B481",
      "Abitanti": 737,
      "Link": "http://www.comuni-italiani.it/080/017/"
    },
    {
      "Istat": "006027",
      "Comune": "Camino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "B482",
      "Abitanti": 810,
      "Link": "http://www.comuni-italiani.it/006/027/"
    },
    {
      "Istat": "030015",
      "Comune": "Camino al Tagliamento",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "B483",
      "Abitanti": 1676,
      "Link": "http://www.comuni-italiani.it/030/015/"
    },
    {
      "Istat": "019010",
      "Comune": "Camisano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B484",
      "Abitanti": 1311,
      "Link": "http://www.comuni-italiani.it/019/010/"
    },
    {
      "Istat": "024021",
      "Comune": "Camisano Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36043,
      "CodFisco": "B485",
      "Abitanti": 10564,
      "Link": "http://www.comuni-italiani.it/024/021/"
    },
    {
      "Istat": "084009",
      "Comune": "Cammarata",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92022,
      "CodFisco": "B486",
      "Abitanti": 6453,
      "Link": "http://www.comuni-italiani.it/084/009/"
    },
    {
      "Istat": "004036",
      "Comune": "Camo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 12050,
      "CodFisco": "B489",
      "Abitanti": 203,
      "Link": "http://www.comuni-italiani.it/004/036/"
    },
    {
      "Istat": "010007",
      "Comune": "Camogli",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16032,
      "CodFisco": "B490",
      "Abitanti": 5582,
      "Link": "http://www.comuni-italiani.it/010/007/"
    },
    {
      "Istat": "065022",
      "Comune": "Campagna",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84022,
      "CodFisco": "B492",
      "Abitanti": 16183,
      "Link": "http://www.comuni-italiani.it/065/022/"
    },
    {
      "Istat": "027002",
      "Comune": "Campagna Lupia",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30010,
      "CodFisco": "B493",
      "Abitanti": 6992,
      "Link": "http://www.comuni-italiani.it/027/002/"
    },
    {
      "Istat": "058015",
      "Comune": "Campagnano di Roma",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00063",
      "CodFisco": "B496",
      "Abitanti": 11166,
      "Link": "http://www.comuni-italiani.it/058/015/"
    },
    {
      "Istat": "053002",
      "Comune": "Campagnatico",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58042,
      "CodFisco": "B497",
      "Abitanti": 2532,
      "Link": "http://www.comuni-italiani.it/053/002/"
    },
    {
      "Istat": "019011",
      "Comune": "Campagnola Cremasca",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B498",
      "Abitanti": 694,
      "Link": "http://www.comuni-italiani.it/019/011/"
    },
    {
      "Istat": "035009",
      "Comune": "Campagnola Emilia",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42012,
      "CodFisco": "B499",
      "Abitanti": 5594,
      "Link": "http://www.comuni-italiani.it/035/009/"
    },
    {
      "Istat": "078023",
      "Comune": "Campana",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87061,
      "CodFisco": "B500",
      "Abitanti": 1980,
      "Link": "http://www.comuni-italiani.it/078/023/"
    },
    {
      "Istat": 108014,
      "Comune": "Camparada",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20857,
      "CodFisco": "B501",
      "Abitanti": 2106,
      "Link": "http://www.comuni-italiani.it/108/014/"
    },
    {
      "Istat": "035010",
      "Comune": "Campegine",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42040,
      "CodFisco": "B502",
      "Abitanti": 5187,
      "Link": "http://www.comuni-italiani.it/035/010/"
    },
    {
      "Istat": "054005",
      "Comune": "Campello sul Clitunno",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06042",
      "CodFisco": "B504",
      "Abitanti": 2538,
      "Link": "http://www.comuni-italiani.it/054/005/"
    },
    {
      "Istat": "002025",
      "Comune": "Campertogno",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13023,
      "CodFisco": "B505",
      "Abitanti": 246,
      "Link": "http://www.comuni-italiani.it/002/025/"
    },
    {
      "Istat": "048006",
      "Comune": "Campi Bisenzio",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50013,
      "CodFisco": "B507",
      "Abitanti": 43901,
      "Link": "http://www.comuni-italiani.it/048/006/"
    },
    {
      "Istat": "075011",
      "Comune": "Campi Salentina",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73012,
      "CodFisco": "B506",
      "Abitanti": 10820,
      "Link": "http://www.comuni-italiani.it/075/011/"
    },
    {
      "Istat": "096011",
      "Comune": "Campiglia Cervo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13812,
      "CodFisco": "B508",
      "Abitanti": 165,
      "Link": "http://www.comuni-italiani.it/096/011/"
    },
    {
      "Istat": "024022",
      "Comune": "Campiglia dei Berici",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36020,
      "CodFisco": "B511",
      "Abitanti": 1782,
      "Link": "http://www.comuni-italiani.it/024/022/"
    },
    {
      "Istat": "049002",
      "Comune": "Campiglia Marittima",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57021,
      "CodFisco": "B509",
      "Abitanti": 13339,
      "Link": "http://www.comuni-italiani.it/049/002/"
    },
    {
      "Istat": "001049",
      "Comune": "Campiglione-Fenile",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "B512",
      "Abitanti": 1385,
      "Link": "http://www.comuni-italiani.it/001/049/"
    },
    {
      "Istat": "013040",
      "Comune": "Campione d'Italia",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "004191",
      "CAP": 22060,
      "CodFisco": "B513",
      "Abitanti": 2121,
      "Link": "http://www.comuni-italiani.it/013/040/"
    },
    {
      "Istat": "022036",
      "Comune": "Campitello di Fassa",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38031,
      "CodFisco": "B514",
      "Abitanti": 748,
      "Link": "http://www.comuni-italiani.it/022/036/"
    },
    {
      "Istat": "067008",
      "Comune": "Campli",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64012,
      "CodFisco": "B515",
      "Abitanti": 7522,
      "Link": "http://www.comuni-italiani.it/067/008/"
    },
    {
      "Istat": "080018",
      "Comune": "Campo Calabro",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89052,
      "CodFisco": "B516",
      "Abitanti": 4407,
      "Link": "http://www.comuni-italiani.it/080/018/"
    },
    {
      "Istat": "066015",
      "Comune": "Campo di Giove",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "B526",
      "Abitanti": 860,
      "Link": "http://www.comuni-italiani.it/066/015/"
    },
    {
      "Istat": "021016",
      "Comune": "Campo di Trens",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "B529",
      "Abitanti": 2661,
      "Link": "http://www.comuni-italiani.it/021/016/"
    },
    {
      "Istat": "010008",
      "Comune": "Campo Ligure",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16013,
      "CodFisco": "B538",
      "Abitanti": 3051,
      "Link": "http://www.comuni-italiani.it/010/008/"
    },
    {
      "Istat": "049003",
      "Comune": "Campo nell'Elba",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57034,
      "CodFisco": "B553",
      "Abitanti": 4651,
      "Link": "http://www.comuni-italiani.it/049/003/"
    },
    {
      "Istat": "028020",
      "Comune": "Campo San Martino",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "B564",
      "Abitanti": 5784,
      "Link": "http://www.comuni-italiani.it/028/020/"
    },
    {
      "Istat": "021017",
      "Comune": "Campo Tures",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39032,
      "CodFisco": "B570",
      "Abitanti": 5267,
      "Link": "http://www.comuni-italiani.it/021/017/"
    },
    {
      "Istat": "070006",
      "Comune": "Campobasso",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86100,
      "CodFisco": "B519",
      "Abitanti": 50916,
      "Link": "http://www.comuni-italiani.it/070/006/"
    },
    {
      "Istat": "084010",
      "Comune": "Campobello di Licata",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92023,
      "CodFisco": "B520",
      "Abitanti": 10328,
      "Link": "http://www.comuni-italiani.it/084/010/"
    },
    {
      "Istat": "081004",
      "Comune": "Campobello di Mazara",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91021,
      "CodFisco": "B521",
      "Abitanti": 10812,
      "Link": "http://www.comuni-italiani.it/081/004/"
    },
    {
      "Istat": "070007",
      "Comune": "Campochiaro",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "B522",
      "Abitanti": 647,
      "Link": "http://www.comuni-italiani.it/070/007/"
    },
    {
      "Istat": "028017",
      "Comune": "Campodarsego",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35011,
      "CodFisco": "B524",
      "Abitanti": 14041,
      "Link": "http://www.comuni-italiani.it/028/017/"
    },
    {
      "Istat": "022037",
      "Comune": "Campodenno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "B525",
      "Abitanti": 1526,
      "Link": "http://www.comuni-italiani.it/022/037/"
    },
    {
      "Istat": "059003",
      "Comune": "Campodimele",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04020",
      "CodFisco": "B527",
      "Abitanti": 660,
      "Link": "http://www.comuni-italiani.it/059/003/"
    },
    {
      "Istat": "070008",
      "Comune": "Campodipietra",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "B528",
      "Abitanti": 2569,
      "Link": "http://www.comuni-italiani.it/070/008/"
    },
    {
      "Istat": "014012",
      "Comune": "Campodolcino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23021,
      "CodFisco": "B530",
      "Abitanti": 1045,
      "Link": "http://www.comuni-italiani.it/014/012/"
    },
    {
      "Istat": "028018",
      "Comune": "Campodoro",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "B531",
      "Abitanti": 2737,
      "Link": "http://www.comuni-italiani.it/028/018/"
    },
    {
      "Istat": "082016",
      "Comune": "Campofelice di Fitalia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "B533",
      "Abitanti": 553,
      "Link": "http://www.comuni-italiani.it/082/016/"
    },
    {
      "Istat": "082017",
      "Comune": "Campofelice di Roccella",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "B532",
      "Abitanti": 6939,
      "Link": "http://www.comuni-italiani.it/082/017/"
    },
    {
      "Istat": 109004,
      "Comune": "Campofilone",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63828,
      "CodFisco": "B534",
      "Abitanti": 1942,
      "Link": "http://www.comuni-italiani.it/109/004/"
    },
    {
      "Istat": "082018",
      "Comune": "Campofiorito",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "B535",
      "Abitanti": 1353,
      "Link": "http://www.comuni-italiani.it/082/018/"
    },
    {
      "Istat": "030016",
      "Comune": "Campoformido",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "B536",
      "Abitanti": 7771,
      "Link": "http://www.comuni-italiani.it/030/016/"
    },
    {
      "Istat": "085005",
      "Comune": "Campofranco",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "B537",
      "Abitanti": 3259,
      "Link": "http://www.comuni-italiani.it/085/005/"
    },
    {
      "Istat": "036003",
      "Comune": "Campogalliano",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41011,
      "CodFisco": "B539",
      "Abitanti": 8650,
      "Link": "http://www.comuni-italiani.it/036/003/"
    },
    {
      "Istat": "062013",
      "Comune": "Campolattaro",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "B541",
      "Abitanti": 1090,
      "Link": "http://www.comuni-italiani.it/062/013/"
    },
    {
      "Istat": "060016",
      "Comune": "Campoli Appennino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "B543",
      "Abitanti": 1756,
      "Link": "http://www.comuni-italiani.it/060/016/"
    },
    {
      "Istat": "062014",
      "Comune": "Campoli del Monte Taburno",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "B542",
      "Abitanti": 1538,
      "Link": "http://www.comuni-italiani.it/062/014/"
    },
    {
      "Istat": "070009",
      "Comune": "Campolieto",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "B544",
      "Abitanti": 950,
      "Link": "http://www.comuni-italiani.it/070/009/"
    },
    {
      "Istat": "027003",
      "Comune": "Campolongo Maggiore",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 30010,
      "CodFisco": "B546",
      "Abitanti": 10300,
      "Link": "http://www.comuni-italiani.it/027/003/"
    },
    {
      "Istat": "024023",
      "Comune": "Campolongo sul Brenta",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36020,
      "CodFisco": "B547",
      "Abitanti": 829,
      "Link": "http://www.comuni-italiani.it/024/023/"
    },
    {
      "Istat": "030138",
      "Comune": "Campolongo Tapogliano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33040,
      "CodFisco": "M311",
      "Abitanti": 1215,
      "Link": "http://www.comuni-italiani.it/030/138/"
    },
    {
      "Istat": "076017",
      "Comune": "Campomaggiore",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "B549",
      "Abitanti": 866,
      "Link": "http://www.comuni-italiani.it/076/017/"
    },
    {
      "Istat": "070010",
      "Comune": "Campomarino",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86042,
      "CodFisco": "B550",
      "Abitanti": 7208,
      "Link": "http://www.comuni-italiani.it/070/010/"
    },
    {
      "Istat": "010009",
      "Comune": "Campomorone",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16014,
      "CodFisco": "B551",
      "Abitanti": 7458,
      "Link": "http://www.comuni-italiani.it/010/009/"
    },
    {
      "Istat": "027004",
      "Comune": "Camponogara",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30010,
      "CodFisco": "B554",
      "Abitanti": 12958,
      "Link": "http://www.comuni-italiani.it/027/004/"
    },
    {
      "Istat": "065023",
      "Comune": "Campora",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "B555",
      "Abitanti": 479,
      "Link": "http://www.comuni-italiani.it/065/023/"
    },
    {
      "Istat": "082019",
      "Comune": "Camporeale",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 90043,
      "CodFisco": "B556",
      "Abitanti": 3489,
      "Link": "http://www.comuni-italiani.it/082/019/"
    },
    {
      "Istat": "046006",
      "Comune": "Camporgiano",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55031,
      "CodFisco": "B557",
      "Abitanti": 2316,
      "Link": "http://www.comuni-italiani.it/046/006/"
    },
    {
      "Istat": "008011",
      "Comune": "Camporosso",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18033,
      "CodFisco": "B559",
      "Abitanti": 5741,
      "Link": "http://www.comuni-italiani.it/008/011/"
    },
    {
      "Istat": "043008",
      "Comune": "Camporotondo di Fiastrone",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "B562",
      "Abitanti": 598,
      "Link": "http://www.comuni-italiani.it/043/008/"
    },
    {
      "Istat": "087012",
      "Comune": "Camporotondo Etneo",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95040,
      "CodFisco": "B561",
      "Abitanti": 4464,
      "Link": "http://www.comuni-italiani.it/087/012/"
    },
    {
      "Istat": "028019",
      "Comune": "Camposampiero",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35012,
      "CodFisco": "B563",
      "Abitanti": 12211,
      "Link": "http://www.comuni-italiani.it/028/019/"
    },
    {
      "Istat": "063013",
      "Comune": "Camposano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "B565",
      "Abitanti": 5450,
      "Link": "http://www.comuni-italiani.it/063/013/"
    },
    {
      "Istat": "036004",
      "Comune": "Camposanto",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41031,
      "CodFisco": "B566",
      "Abitanti": 3218,
      "Link": "http://www.comuni-italiani.it/036/004/"
    },
    {
      "Istat": "018026",
      "Comune": "Campospinoso",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "B567",
      "Abitanti": 982,
      "Link": "http://www.comuni-italiani.it/018/026/"
    },
    {
      "Istat": "066016",
      "Comune": "Campotosto",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67013,
      "CodFisco": "B569",
      "Abitanti": 660,
      "Link": "http://www.comuni-italiani.it/066/016/"
    },
    {
      "Istat": "037010",
      "Comune": "Camugnano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40032,
      "CodFisco": "B572",
      "Abitanti": 2038,
      "Link": "http://www.comuni-italiani.it/037/010/"
    },
    {
      "Istat": "022038",
      "Comune": "Canal San Bovo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38050,
      "CodFisco": "B577",
      "Abitanti": 1623,
      "Link": "http://www.comuni-italiani.it/022/038/"
    },
    {
      "Istat": "004037",
      "Comune": "Canale",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12043,
      "CodFisco": "B573",
      "Abitanti": 5791,
      "Link": "http://www.comuni-italiani.it/004/037/"
    },
    {
      "Istat": "025023",
      "Comune": "Canale d'Agordo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "B574",
      "Abitanti": 1200,
      "Link": "http://www.comuni-italiani.it/025/023/"
    },
    {
      "Istat": "058016",
      "Comune": "Canale Monterano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "B576",
      "Abitanti": 3966,
      "Link": "http://www.comuni-italiani.it/058/016/"
    },
    {
      "Istat": "029009",
      "Comune": "Canaro",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45034,
      "CodFisco": "B578",
      "Abitanti": 2907,
      "Link": "http://www.comuni-italiani.it/029/009/"
    },
    {
      "Istat": "022039",
      "Comune": "Canazei",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38032,
      "CodFisco": "B579",
      "Abitanti": 1903,
      "Link": "http://www.comuni-italiani.it/022/039/"
    },
    {
      "Istat": "076018",
      "Comune": "Cancellara",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "B580",
      "Abitanti": 1438,
      "Link": "http://www.comuni-italiani.it/076/018/"
    },
    {
      "Istat": "061012",
      "Comune": "Cancello ed Arnone",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81030,
      "CodFisco": "B581",
      "Abitanti": 5371,
      "Link": "http://www.comuni-italiani.it/061/012/"
    },
    {
      "Istat": "029010",
      "Comune": "Canda",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "B582",
      "Abitanti": 967,
      "Link": "http://www.comuni-italiani.it/029/010/"
    },
    {
      "Istat": "071009",
      "Comune": "Candela",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71024,
      "CodFisco": "B584",
      "Abitanti": 2753,
      "Link": "http://www.comuni-italiani.it/071/009/"
    },
    {
      "Istat": "096012",
      "Comune": "Candelo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13878,
      "CodFisco": "B586",
      "Abitanti": 8058,
      "Link": "http://www.comuni-italiani.it/096/012/"
    },
    {
      "Istat": "001050",
      "Comune": "Candia Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10010,
      "CodFisco": "B588",
      "Abitanti": 1317,
      "Link": "http://www.comuni-italiani.it/001/050/"
    },
    {
      "Istat": "018027",
      "Comune": "Candia Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27031,
      "CodFisco": "B587",
      "Abitanti": 1688,
      "Link": "http://www.comuni-italiani.it/018/027/"
    },
    {
      "Istat": "028021",
      "Comune": "Candiana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "B589",
      "Abitanti": 2505,
      "Link": "http://www.comuni-italiani.it/028/021/"
    },
    {
      "Istat": "064016",
      "Comune": "Candida",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83040,
      "CodFisco": "B590",
      "Abitanti": 1163,
      "Link": "http://www.comuni-italiani.it/064/016/"
    },
    {
      "Istat": "080019",
      "Comune": "Candidoni",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "B591",
      "Abitanti": 392,
      "Link": "http://www.comuni-italiani.it/080/019/"
    },
    {
      "Istat": "001051",
      "Comune": "Candiolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "B592",
      "Abitanti": 5591,
      "Link": "http://www.comuni-italiani.it/001/051/"
    },
    {
      "Istat": "015046",
      "Comune": "Canegrate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20010,
      "CodFisco": "B593",
      "Abitanti": 12431,
      "Link": "http://www.comuni-italiani.it/015/046/"
    },
    {
      "Istat": "005017",
      "Comune": "Canelli",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14053,
      "CodFisco": "B594",
      "Abitanti": 10722,
      "Link": "http://www.comuni-italiani.it/005/017/"
    },
    {
      "Istat": "056011",
      "Comune": "Canepina",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "B597",
      "Abitanti": 3212,
      "Link": "http://www.comuni-italiani.it/056/011/"
    },
    {
      "Istat": "093009",
      "Comune": "Caneva",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33070,
      "CodFisco": "B598",
      "Abitanti": 6541,
      "Link": "http://www.comuni-italiani.it/093/009/"
    },
    {
      "Istat": "018028",
      "Comune": "Canevino",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "B599",
      "Abitanti": 119,
      "Link": "http://www.comuni-italiani.it/018/028/"
    },
    {
      "Istat": "084011",
      "Comune": "CanicattÏ",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92024,
      "CodFisco": "B602",
      "Abitanti": 35097,
      "Link": "http://www.comuni-italiani.it/084/011/"
    },
    {
      "Istat": "089005",
      "Comune": "Canicattini Bagni",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "B603",
      "Abitanti": 7355,
      "Link": "http://www.comuni-italiani.it/089/005/"
    },
    {
      "Istat": "056012",
      "Comune": "Canino",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01011",
      "CodFisco": "B604",
      "Abitanti": 5310,
      "Link": "http://www.comuni-italiani.it/056/012/"
    },
    {
      "Istat": "001052",
      "Comune": "Canischio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "B605",
      "Abitanti": 301,
      "Link": "http://www.comuni-italiani.it/001/052/"
    },
    {
      "Istat": "066017",
      "Comune": "Canistro",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "B606",
      "Abitanti": 1060,
      "Link": "http://www.comuni-italiani.it/066/017/"
    },
    {
      "Istat": "078024",
      "Comune": "Canna",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "B607",
      "Abitanti": 797,
      "Link": "http://www.comuni-italiani.it/078/024/"
    },
    {
      "Istat": "065024",
      "Comune": "Cannalonga",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "B608",
      "Abitanti": 1098,
      "Link": "http://www.comuni-italiani.it/065/024/"
    },
    {
      "Istat": "054006",
      "Comune": "Cannara",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06033",
      "CodFisco": "B609",
      "Abitanti": 4324,
      "Link": "http://www.comuni-italiani.it/054/006/"
    },
    {
      "Istat": 103016,
      "Comune": "Cannero Riviera",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28821,
      "CodFisco": "B610",
      "Abitanti": 1017,
      "Link": "http://www.comuni-italiani.it/103/016/"
    },
    {
      "Istat": "018029",
      "Comune": "Canneto Pavese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27044,
      "CodFisco": "B613",
      "Abitanti": 1459,
      "Link": "http://www.comuni-italiani.it/018/029/"
    },
    {
      "Istat": "020008",
      "Comune": "Canneto sull'Oglio",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46013,
      "CodFisco": "B612",
      "Abitanti": 4555,
      "Link": "http://www.comuni-italiani.it/020/008/"
    },
    {
      "Istat": 103017,
      "Comune": "Cannobio",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28822,
      "CodFisco": "B615",
      "Abitanti": 5181,
      "Link": "http://www.comuni-italiani.it/103/017/"
    },
    {
      "Istat": "075012",
      "Comune": "Cannole",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "B616",
      "Abitanti": 1758,
      "Link": "http://www.comuni-italiani.it/075/012/"
    },
    {
      "Istat": "080020",
      "Comune": "Canolo",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "B617",
      "Abitanti": 814,
      "Link": "http://www.comuni-italiani.it/080/020/"
    },
    {
      "Istat": "016049",
      "Comune": "Canonica d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 24040,
      "CodFisco": "B618",
      "Abitanti": 4455,
      "Link": "http://www.comuni-italiani.it/016/049/"
    },
    {
      "Istat": 110004,
      "Comune": "Canosa di Puglia",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76012,
      "CodFisco": "B619",
      "Abitanti": 31115,
      "Link": "http://www.comuni-italiani.it/110/004/"
    },
    {
      "Istat": "069010",
      "Comune": "Canosa Sannita",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "B620",
      "Abitanti": 1471,
      "Link": "http://www.comuni-italiani.it/069/010/"
    },
    {
      "Istat": "004038",
      "Comune": "Canosio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "B621",
      "Abitanti": 87,
      "Link": "http://www.comuni-italiani.it/004/038/"
    },
    {
      "Istat": "035018",
      "Comune": "Canossa",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42026,
      "CodFisco": "C669",
      "Abitanti": 3843,
      "Link": "http://www.comuni-italiani.it/035/018/"
    },
    {
      "Istat": "066018",
      "Comune": "Cansano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "B624",
      "Abitanti": 280,
      "Link": "http://www.comuni-italiani.it/066/018/"
    },
    {
      "Istat": 100001,
      "Comune": "Cantagallo",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "0574",
      "CAP": 59025,
      "CodFisco": "B626",
      "Abitanti": 3095,
      "Link": "http://www.comuni-italiani.it/100/001/"
    },
    {
      "Istat": "057009",
      "Comune": "Cantalice",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02014",
      "CodFisco": "B627",
      "Abitanti": 2796,
      "Link": "http://www.comuni-italiani.it/057/009/"
    },
    {
      "Istat": "001053",
      "Comune": "Cantalupa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "B628",
      "Abitanti": 2588,
      "Link": "http://www.comuni-italiani.it/001/053/"
    },
    {
      "Istat": "057010",
      "Comune": "Cantalupo in Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "B631",
      "Abitanti": 1742,
      "Link": "http://www.comuni-italiani.it/057/010/"
    },
    {
      "Istat": "006028",
      "Comune": "Cantalupo Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "B629",
      "Abitanti": 559,
      "Link": "http://www.comuni-italiani.it/006/028/"
    },
    {
      "Istat": "094005",
      "Comune": "Cantalupo nel Sannio",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86092,
      "CodFisco": "B630",
      "Abitanti": 756,
      "Link": "http://www.comuni-italiani.it/094/005/"
    },
    {
      "Istat": "005018",
      "Comune": "Cantarana",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "B633",
      "Abitanti": 1015,
      "Link": "http://www.comuni-italiani.it/005/018/"
    },
    {
      "Istat": "012030",
      "Comune": "Cantello",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "B634",
      "Abitanti": 4598,
      "Link": "http://www.comuni-italiani.it/012/030/"
    },
    {
      "Istat": "058017",
      "Comune": "Canterano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "B635",
      "Abitanti": 369,
      "Link": "http://www.comuni-italiani.it/058/017/"
    },
    {
      "Istat": "041008",
      "Comune": "Cantiano",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61044,
      "CodFisco": "B636",
      "Abitanti": 2420,
      "Link": "http://www.comuni-italiani.it/041/008/"
    },
    {
      "Istat": "001054",
      "Comune": "Cantoira",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "B637",
      "Abitanti": 554,
      "Link": "http://www.comuni-italiani.it/001/054/"
    },
    {
      "Istat": "013041",
      "Comune": "Cant˘",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22063,
      "CodFisco": "B639",
      "Abitanti": 39540,
      "Link": "http://www.comuni-italiani.it/013/041/"
    },
    {
      "Istat": "067009",
      "Comune": "Canzano",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64020,
      "CodFisco": "B640",
      "Abitanti": 1973,
      "Link": "http://www.comuni-italiani.it/067/009/"
    },
    {
      "Istat": "013042",
      "Comune": "Canzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22035,
      "CodFisco": "B641",
      "Abitanti": 5144,
      "Link": "http://www.comuni-italiani.it/013/042/"
    },
    {
      "Istat": "027005",
      "Comune": "Caorle",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30021,
      "CodFisco": "B642",
      "Abitanti": 12032,
      "Link": "http://www.comuni-italiani.it/027/005/"
    },
    {
      "Istat": "033010",
      "Comune": "Caorso",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29012,
      "CodFisco": "B643",
      "Abitanti": 4896,
      "Link": "http://www.comuni-italiani.it/033/010/"
    },
    {
      "Istat": "065025",
      "Comune": "Capaccio",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84047,
      "CodFisco": "B644",
      "Abitanti": 22300,
      "Link": "http://www.comuni-italiani.it/065/025/"
    },
    {
      "Istat": "082020",
      "Comune": "Capaci",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "B645",
      "Abitanti": 10623,
      "Link": "http://www.comuni-italiani.it/082/020/"
    },
    {
      "Istat": "053003",
      "Comune": "Capalbio",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58011,
      "CodFisco": "B646",
      "Abitanti": 4287,
      "Link": "http://www.comuni-italiani.it/053/003/"
    },
    {
      "Istat": "050005",
      "Comune": "Capannoli",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56033,
      "CodFisco": "B647",
      "Abitanti": 6145,
      "Link": "http://www.comuni-italiani.it/050/005/"
    },
    {
      "Istat": "046007",
      "Comune": "Capannori",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55012,
      "CodFisco": "B648",
      "Abitanti": 46207,
      "Link": "http://www.comuni-italiani.it/046/007/"
    },
    {
      "Istat": "058018",
      "Comune": "Capena",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "B649",
      "Abitanti": 9709,
      "Link": "http://www.comuni-italiani.it/058/018/"
    },
    {
      "Istat": "019012",
      "Comune": "Capergnanica",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B650",
      "Abitanti": 2094,
      "Link": "http://www.comuni-italiani.it/019/012/"
    },
    {
      "Istat": "066019",
      "Comune": "Capestrano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67022,
      "CodFisco": "B651",
      "Abitanti": 957,
      "Link": "http://www.comuni-italiani.it/066/019/"
    },
    {
      "Istat": "013043",
      "Comune": "Capiago Intimiano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "B653",
      "Abitanti": 5530,
      "Link": "http://www.comuni-italiani.it/013/043/"
    },
    {
      "Istat": 102005,
      "Comune": "Capistrano",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89818,
      "CodFisco": "B655",
      "Abitanti": 1076,
      "Link": "http://www.comuni-italiani.it/102/005/"
    },
    {
      "Istat": "066020",
      "Comune": "Capistrello",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67053,
      "CodFisco": "B656",
      "Abitanti": 5419,
      "Link": "http://www.comuni-italiani.it/066/020/"
    },
    {
      "Istat": "066021",
      "Comune": "Capitignano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67014,
      "CodFisco": "B658",
      "Abitanti": 685,
      "Link": "http://www.comuni-italiani.it/066/021/"
    },
    {
      "Istat": "083008",
      "Comune": "Capizzi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 98031,
      "CodFisco": "B660",
      "Abitanti": 3389,
      "Link": "http://www.comuni-italiani.it/083/008/"
    },
    {
      "Istat": "016050",
      "Comune": "Capizzone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "B661",
      "Abitanti": 1347,
      "Link": "http://www.comuni-italiani.it/016/050/"
    },
    {
      "Istat": "017035",
      "Comune": "Capo di Ponte",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25044,
      "CodFisco": "B664",
      "Abitanti": 2519,
      "Link": "http://www.comuni-italiani.it/017/035/"
    },
    {
      "Istat": "083009",
      "Comune": "Capo d'Orlando",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98071,
      "CodFisco": "B666",
      "Abitanti": 13221,
      "Link": "http://www.comuni-italiani.it/083/009/"
    },
    {
      "Istat": "056013",
      "Comune": "Capodimonte",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "B663",
      "Abitanti": 1797,
      "Link": "http://www.comuni-italiani.it/056/013/"
    },
    {
      "Istat": "061013",
      "Comune": "Capodrise",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "B667",
      "Abitanti": 9747,
      "Link": "http://www.comuni-italiani.it/061/013/"
    },
    {
      "Istat": "049004",
      "Comune": "Capoliveri",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57031,
      "CodFisco": "B669",
      "Abitanti": 3887,
      "Link": "http://www.comuni-italiani.it/049/004/"
    },
    {
      "Istat": "051006",
      "Comune": "Capolona",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "B670",
      "Abitanti": 5515,
      "Link": "http://www.comuni-italiani.it/051/006/"
    },
    {
      "Istat": 108052,
      "Comune": "Caponago",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20867,
      "CodFisco": "B671",
      "Abitanti": 5199,
      "Link": "http://www.comuni-italiani.it/108/052/"
    },
    {
      "Istat": "066022",
      "Comune": "Caporciano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "B672",
      "Abitanti": 236,
      "Link": "http://www.comuni-italiani.it/066/022/"
    },
    {
      "Istat": "064017",
      "Comune": "Caposele",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "B674",
      "Abitanti": 3605,
      "Link": "http://www.comuni-italiani.it/064/017/"
    },
    {
      "Istat": "092011",
      "Comune": "Capoterra",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09012",
      "CodFisco": "B675",
      "Abitanti": 24030,
      "Link": "http://www.comuni-italiani.it/092/011/"
    },
    {
      "Istat": "017036",
      "Comune": "Capovalle",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "B676",
      "Abitanti": 401,
      "Link": "http://www.comuni-italiani.it/017/036/"
    },
    {
      "Istat": "066023",
      "Comune": "Cappadocia",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67060,
      "CodFisco": "B677",
      "Abitanti": 535,
      "Link": "http://www.comuni-italiani.it/066/023/"
    },
    {
      "Istat": "019013",
      "Comune": "Cappella Cantone",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "B679",
      "Abitanti": 590,
      "Link": "http://www.comuni-italiani.it/019/013/"
    },
    {
      "Istat": "019014",
      "Comune": "Cappella de' Picenardi",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "B680",
      "Abitanti": 438,
      "Link": "http://www.comuni-italiani.it/019/014/"
    },
    {
      "Istat": "026007",
      "Comune": "Cappella Maggiore",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31012,
      "CodFisco": "B678",
      "Abitanti": 4666,
      "Link": "http://www.comuni-italiani.it/026/007/"
    },
    {
      "Istat": "068006",
      "Comune": "Cappelle sul Tavo",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "B681",
      "Abitanti": 3974,
      "Link": "http://www.comuni-italiani.it/068/006/"
    },
    {
      "Istat": "094006",
      "Comune": "Capracotta",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86082,
      "CodFisco": "B682",
      "Abitanti": 959,
      "Link": "http://www.comuni-italiani.it/094/006/"
    },
    {
      "Istat": "048008",
      "Comune": "Capraia e Limite",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50050,
      "CodFisco": "B684",
      "Abitanti": 7268,
      "Link": "http://www.comuni-italiani.it/048/008/"
    },
    {
      "Istat": "049005",
      "Comune": "Capraia Isola",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 57032,
      "CodFisco": "B685",
      "Abitanti": 410,
      "Link": "http://www.comuni-italiani.it/049/005/"
    },
    {
      "Istat": "019015",
      "Comune": "Capralba",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B686",
      "Abitanti": 2479,
      "Link": "http://www.comuni-italiani.it/019/015/"
    },
    {
      "Istat": "056014",
      "Comune": "Capranica",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01012",
      "CodFisco": "B688",
      "Abitanti": 6673,
      "Link": "http://www.comuni-italiani.it/056/014/"
    },
    {
      "Istat": "058019",
      "Comune": "Capranica Prenestina",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "B687",
      "Abitanti": 375,
      "Link": "http://www.comuni-italiani.it/058/019/"
    },
    {
      "Istat": "075013",
      "Comune": "Caprarica di Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "B690",
      "Abitanti": 2595,
      "Link": "http://www.comuni-italiani.it/075/013/"
    },
    {
      "Istat": "056015",
      "Comune": "Caprarola",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01032",
      "CodFisco": "B691",
      "Abitanti": 5715,
      "Link": "http://www.comuni-italiani.it/056/015/"
    },
    {
      "Istat": "004039",
      "Comune": "Caprauna",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "B692",
      "Abitanti": 121,
      "Link": "http://www.comuni-italiani.it/004/039/"
    },
    {
      "Istat": "051007",
      "Comune": "Caprese Michelangelo",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52033,
      "CodFisco": "B693",
      "Abitanti": 1551,
      "Link": "http://www.comuni-italiani.it/051/007/"
    },
    {
      "Istat": 103018,
      "Comune": "Caprezzo",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28815,
      "CodFisco": "B694",
      "Abitanti": 171,
      "Link": "http://www.comuni-italiani.it/103/018/"
    },
    {
      "Istat": "063014",
      "Comune": "Capri",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80073,
      "CodFisco": "B696",
      "Abitanti": 7349,
      "Link": "http://www.comuni-italiani.it/063/014/"
    },
    {
      "Istat": "083010",
      "Comune": "Capri Leone",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "B695",
      "Abitanti": 4566,
      "Link": "http://www.comuni-italiani.it/083/010/"
    },
    {
      "Istat": "022040",
      "Comune": "Capriana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "B697",
      "Abitanti": 602,
      "Link": "http://www.comuni-italiani.it/022/040/"
    },
    {
      "Istat": "017037",
      "Comune": "Capriano del Colle",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "B698",
      "Abitanti": 4499,
      "Link": "http://www.comuni-italiani.it/017/037/"
    },
    {
      "Istat": "006029",
      "Comune": "Capriata d'Orba",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "B701",
      "Abitanti": 1950,
      "Link": "http://www.comuni-italiani.it/006/029/"
    },
    {
      "Istat": "016051",
      "Comune": "Capriate San Gervasio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 24042,
      "CodFisco": "B703",
      "Abitanti": 7819,
      "Link": "http://www.comuni-italiani.it/016/051/"
    },
    {
      "Istat": "061014",
      "Comune": "Capriati a Volturno",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81014,
      "CodFisco": "B704",
      "Abitanti": 1661,
      "Link": "http://www.comuni-italiani.it/061/014/"
    },
    {
      "Istat": "001055",
      "Comune": "Caprie",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "B705",
      "Abitanti": 2136,
      "Link": "http://www.comuni-italiani.it/001/055/"
    },
    {
      "Istat": "064018",
      "Comune": "Capriglia Irpina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "B706",
      "Abitanti": 2416,
      "Link": "http://www.comuni-italiani.it/064/018/"
    },
    {
      "Istat": "005019",
      "Comune": "Capriglio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14014,
      "CodFisco": "B707",
      "Abitanti": 298,
      "Link": "http://www.comuni-italiani.it/005/019/"
    },
    {
      "Istat": "096013",
      "Comune": "Caprile",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13864,
      "CodFisco": "B708",
      "Abitanti": 223,
      "Link": "http://www.comuni-italiani.it/096/013/"
    },
    {
      "Istat": "016052",
      "Comune": "Caprino Bergamasco",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "B710",
      "Abitanti": 3120,
      "Link": "http://www.comuni-italiani.it/016/052/"
    },
    {
      "Istat": "023018",
      "Comune": "Caprino Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37013,
      "CodFisco": "B709",
      "Abitanti": 8189,
      "Link": "http://www.comuni-italiani.it/023/018/"
    },
    {
      "Istat": "017038",
      "Comune": "Capriolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25031,
      "CodFisco": "B711",
      "Abitanti": 9322,
      "Link": "http://www.comuni-italiani.it/017/038/"
    },
    {
      "Istat": "031001",
      "Comune": "Capriva del Friuli",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "B712",
      "Abitanti": 1747,
      "Link": "http://www.comuni-italiani.it/031/001/"
    },
    {
      "Istat": "061015",
      "Comune": "Capua",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81043,
      "CodFisco": "B715",
      "Abitanti": 18839,
      "Link": "http://www.comuni-italiani.it/061/015/"
    },
    {
      "Istat": "072014",
      "Comune": "Capurso",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "B716",
      "Abitanti": 15411,
      "Link": "http://www.comuni-italiani.it/072/014/"
    },
    {
      "Istat": "080021",
      "Comune": "Caraffa del Bianco",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "B718",
      "Abitanti": 554,
      "Link": "http://www.comuni-italiani.it/080/021/"
    },
    {
      "Istat": "079017",
      "Comune": "Caraffa di Catanzaro",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "B717",
      "Abitanti": 1990,
      "Link": "http://www.comuni-italiani.it/079/017/"
    },
    {
      "Istat": "004040",
      "Comune": "Caraglio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12023,
      "CodFisco": "B719",
      "Abitanti": 6774,
      "Link": "http://www.comuni-italiani.it/004/040/"
    },
    {
      "Istat": "004041",
      "Comune": "Caramagna Piemonte",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "B720",
      "Abitanti": 3023,
      "Link": "http://www.comuni-italiani.it/004/041/"
    },
    {
      "Istat": "068007",
      "Comune": "Caramanico Terme",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65023,
      "CodFisco": "B722",
      "Abitanti": 2032,
      "Link": "http://www.comuni-italiani.it/068/007/"
    },
    {
      "Istat": "022041",
      "Comune": "Carano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38033,
      "CodFisco": "B723",
      "Abitanti": 1077,
      "Link": "http://www.comuni-italiani.it/022/041/"
    },
    {
      "Istat": "071010",
      "Comune": "Carapelle",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71041,
      "CodFisco": "B724",
      "Abitanti": 6527,
      "Link": "http://www.comuni-italiani.it/071/010/"
    },
    {
      "Istat": "066024",
      "Comune": "Carapelle Calvisio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "B725",
      "Abitanti": 94,
      "Link": "http://www.comuni-italiani.it/066/024/"
    },
    {
      "Istat": "010010",
      "Comune": "Carasco",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16042,
      "CodFisco": "B726",
      "Abitanti": 3648,
      "Link": "http://www.comuni-italiani.it/010/010/"
    },
    {
      "Istat": "044010",
      "Comune": "Carassai",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63063,
      "CodFisco": "B727",
      "Abitanti": 1144,
      "Link": "http://www.comuni-italiani.it/044/010/"
    },
    {
      "Istat": 108015,
      "Comune": "Carate Brianza",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20841,
      "CodFisco": "B729",
      "Abitanti": 17997,
      "Link": "http://www.comuni-italiani.it/108/015/"
    },
    {
      "Istat": "013044",
      "Comune": "Carate Urio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22010,
      "CodFisco": "B730",
      "Abitanti": 1216,
      "Link": "http://www.comuni-italiani.it/013/044/"
    },
    {
      "Istat": "016053",
      "Comune": "Caravaggio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24043,
      "CodFisco": "B731",
      "Abitanti": 16228,
      "Link": "http://www.comuni-italiani.it/016/053/"
    },
    {
      "Istat": "012031",
      "Comune": "Caravate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21032,
      "CodFisco": "B732",
      "Abitanti": 2632,
      "Link": "http://www.comuni-italiani.it/012/031/"
    },
    {
      "Istat": "001056",
      "Comune": "Caravino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "B733",
      "Abitanti": 1008,
      "Link": "http://www.comuni-italiani.it/001/056/"
    },
    {
      "Istat": "008012",
      "Comune": "Caravonica",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "B734",
      "Abitanti": 312,
      "Link": "http://www.comuni-italiani.it/008/012/"
    },
    {
      "Istat": "056016",
      "Comune": "Carbognano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "B735",
      "Abitanti": 2066,
      "Link": "http://www.comuni-italiani.it/056/016/"
    },
    {
      "Istat": "018030",
      "Comune": "Carbonara al Ticino",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "B741",
      "Abitanti": 1550,
      "Link": "http://www.comuni-italiani.it/018/030/"
    },
    {
      "Istat": "063015",
      "Comune": "Carbonara di Nola",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "B740",
      "Abitanti": 2293,
      "Link": "http://www.comuni-italiani.it/063/015/"
    },
    {
      "Istat": "020009",
      "Comune": "Carbonara di Po",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "B739",
      "Abitanti": 1344,
      "Link": "http://www.comuni-italiani.it/020/009/"
    },
    {
      "Istat": "006030",
      "Comune": "Carbonara Scrivia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "B736",
      "Abitanti": 1074,
      "Link": "http://www.comuni-italiani.it/006/030/"
    },
    {
      "Istat": "013045",
      "Comune": "Carbonate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 22070,
      "CodFisco": "B742",
      "Abitanti": 2920,
      "Link": "http://www.comuni-italiani.it/013/045/"
    },
    {
      "Istat": "076019",
      "Comune": "Carbone",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "B743",
      "Abitanti": 715,
      "Link": "http://www.comuni-italiani.it/076/019/"
    },
    {
      "Istat": "026008",
      "Comune": "Carbonera",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31030,
      "CodFisco": "B744",
      "Abitanti": 11187,
      "Link": "http://www.comuni-italiani.it/026/008/"
    },
    {
      "Istat": 107003,
      "Comune": "Carbonia",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09013",
      "CodFisco": "B745",
      "Abitanti": 29764,
      "Link": "http://www.comuni-italiani.it/107/003/"
    },
    {
      "Istat": "009018",
      "Comune": "Carcare",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17043,
      "CodFisco": "B748",
      "Abitanti": 5682,
      "Link": "http://www.comuni-italiani.it/009/018/"
    },
    {
      "Istat": "028022",
      "Comune": "Carceri",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "B749",
      "Abitanti": 1616,
      "Link": "http://www.comuni-italiani.it/028/022/"
    },
    {
      "Istat": "002029",
      "Comune": "Carcoforo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13026,
      "CodFisco": "B752",
      "Abitanti": 79,
      "Link": "http://www.comuni-italiani.it/002/029/"
    },
    {
      "Istat": "012032",
      "Comune": "Cardano al Campo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21010,
      "CodFisco": "B754",
      "Abitanti": 14542,
      "Link": "http://www.comuni-italiani.it/012/032/"
    },
    {
      "Istat": "004042",
      "Comune": "CardË",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "B755",
      "Abitanti": 1124,
      "Link": "http://www.comuni-italiani.it/004/042/"
    },
    {
      "Istat": 105004,
      "Comune": "Cardedu",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "M285",
      "Abitanti": 1711,
      "Link": "http://www.comuni-italiani.it/105/004/"
    },
    {
      "Istat": "080022",
      "Comune": "Cardeto",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89060,
      "CodFisco": "B756",
      "Abitanti": 1877,
      "Link": "http://www.comuni-italiani.it/080/022/"
    },
    {
      "Istat": "079018",
      "Comune": "Cardinale",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88062,
      "CodFisco": "B758",
      "Abitanti": 2396,
      "Link": "http://www.comuni-italiani.it/079/018/"
    },
    {
      "Istat": "063016",
      "Comune": "Cardito",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80024,
      "CodFisco": "B759",
      "Abitanti": 21302,
      "Link": "http://www.comuni-italiani.it/063/016/"
    },
    {
      "Istat": "046008",
      "Comune": "Careggine",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55030,
      "CodFisco": "B760",
      "Abitanti": 607,
      "Link": "http://www.comuni-italiani.it/046/008/"
    },
    {
      "Istat": "001057",
      "Comune": "Carema",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "B762",
      "Abitanti": 771,
      "Link": "http://www.comuni-italiani.it/001/057/"
    },
    {
      "Istat": "097014",
      "Comune": "Carenno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23802,
      "CodFisco": "B763",
      "Abitanti": 1533,
      "Link": "http://www.comuni-italiani.it/097/014/"
    },
    {
      "Istat": "006031",
      "Comune": "Carentino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15026,
      "CodFisco": "B765",
      "Abitanti": 341,
      "Link": "http://www.comuni-italiani.it/006/031/"
    },
    {
      "Istat": "080023",
      "Comune": "Careri",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "B766",
      "Abitanti": 2370,
      "Link": "http://www.comuni-italiani.it/080/023/"
    },
    {
      "Istat": "002030",
      "Comune": "Caresana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13010,
      "CodFisco": "B767",
      "Abitanti": 1051,
      "Link": "http://www.comuni-italiani.it/002/030/"
    },
    {
      "Istat": "002031",
      "Comune": "Caresanablot",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "B768",
      "Abitanti": 1145,
      "Link": "http://www.comuni-italiani.it/002/031/"
    },
    {
      "Istat": "006032",
      "Comune": "Carezzano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15051,
      "CodFisco": "B769",
      "Abitanti": 448,
      "Link": "http://www.comuni-italiani.it/006/032/"
    },
    {
      "Istat": 101003,
      "Comune": "Carfizzi",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88817,
      "CodFisco": "B771",
      "Abitanti": 788,
      "Link": "http://www.comuni-italiani.it/101/003/"
    },
    {
      "Istat": "090022",
      "Comune": "Cargeghe",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "B772",
      "Abitanti": 629,
      "Link": "http://www.comuni-italiani.it/090/022/"
    },
    {
      "Istat": "078025",
      "Comune": "Cariati",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87062,
      "CodFisco": "B774",
      "Abitanti": 8618,
      "Link": "http://www.comuni-italiani.it/078/025/"
    },
    {
      "Istat": "064019",
      "Comune": "Carife",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "B776",
      "Abitanti": 1530,
      "Link": "http://www.comuni-italiani.it/064/019/"
    },
    {
      "Istat": "001058",
      "Comune": "Carignano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10041,
      "CodFisco": "B777",
      "Abitanti": 9234,
      "Link": "http://www.comuni-italiani.it/001/058/"
    },
    {
      "Istat": "013046",
      "Comune": "Carimate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "B778",
      "Abitanti": 4320,
      "Link": "http://www.comuni-italiani.it/013/046/"
    },
    {
      "Istat": "061016",
      "Comune": "Carinaro",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81032,
      "CodFisco": "B779",
      "Abitanti": 7034,
      "Link": "http://www.comuni-italiani.it/061/016/"
    },
    {
      "Istat": "082021",
      "Comune": "Carini",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90044,
      "CodFisco": "B780",
      "Abitanti": 36106,
      "Link": "http://www.comuni-italiani.it/082/021/"
    },
    {
      "Istat": "061017",
      "Comune": "Carinola",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81030,
      "CodFisco": "B781",
      "Abitanti": 8287,
      "Link": "http://www.comuni-italiani.it/061/017/"
    },
    {
      "Istat": "002032",
      "Comune": "Carisio",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "B782",
      "Abitanti": 919,
      "Link": "http://www.comuni-italiani.it/002/032/"
    },
    {
      "Istat": "022042",
      "Comune": "Carisolo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "B783",
      "Abitanti": 974,
      "Link": "http://www.comuni-italiani.it/022/042/"
    },
    {
      "Istat": "071011",
      "Comune": "Carlantino",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71030,
      "CodFisco": "B784",
      "Abitanti": 1079,
      "Link": "http://www.comuni-italiani.it/071/011/"
    },
    {
      "Istat": "013047",
      "Comune": "Carlazzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "B785",
      "Abitanti": 2966,
      "Link": "http://www.comuni-italiani.it/013/047/"
    },
    {
      "Istat": "089006",
      "Comune": "Carlentini",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 96013,
      "CodFisco": "B787",
      "Abitanti": 17587,
      "Link": "http://www.comuni-italiani.it/089/006/"
    },
    {
      "Istat": "030018",
      "Comune": "Carlino",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "B788",
      "Abitanti": 2806,
      "Link": "http://www.comuni-italiani.it/030/018/"
    },
    {
      "Istat": 107004,
      "Comune": "Carloforte",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09014",
      "CodFisco": "B789",
      "Abitanti": 6420,
      "Link": "http://www.comuni-italiani.it/107/004/"
    },
    {
      "Istat": "079020",
      "Comune": "Carlopoli",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "B790",
      "Abitanti": 1653,
      "Link": "http://www.comuni-italiani.it/079/020/"
    },
    {
      "Istat": "001059",
      "Comune": "Carmagnola",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10022,
      "CodFisco": "B791",
      "Abitanti": 28653,
      "Link": "http://www.comuni-italiani.it/001/059/"
    },
    {
      "Istat": "075014",
      "Comune": "Carmiano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73041,
      "CodFisco": "B792",
      "Abitanti": 12366,
      "Link": "http://www.comuni-italiani.it/075/014/"
    },
    {
      "Istat": 100002,
      "Comune": "Carmignano",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 59015,
      "CodFisco": "B794",
      "Abitanti": 14187,
      "Link": "http://www.comuni-italiani.it/100/002/"
    },
    {
      "Istat": "028023",
      "Comune": "Carmignano di Brenta",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "B795",
      "Abitanti": 7612,
      "Link": "http://www.comuni-italiani.it/028/023/"
    },
    {
      "Istat": "012033",
      "Comune": "Carnago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "B796",
      "Abitanti": 6466,
      "Link": "http://www.comuni-italiani.it/012/033/"
    },
    {
      "Istat": 108016,
      "Comune": "Carnate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20866,
      "CodFisco": "B798",
      "Abitanti": 7270,
      "Link": "http://www.comuni-italiani.it/108/016/"
    },
    {
      "Istat": "016055",
      "Comune": "Carobbio degli Angeli",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "B801",
      "Abitanti": 4619,
      "Link": "http://www.comuni-italiani.it/016/055/"
    },
    {
      "Istat": "078026",
      "Comune": "Carolei",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87030,
      "CodFisco": "B802",
      "Abitanti": 3517,
      "Link": "http://www.comuni-italiani.it/078/026/"
    },
    {
      "Istat": "016056",
      "Comune": "Carona",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "B803",
      "Abitanti": 355,
      "Link": "http://www.comuni-italiani.it/016/056/"
    },
    {
      "Istat": "083011",
      "Comune": "Caronia",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98072,
      "CodFisco": "B804",
      "Abitanti": 3426,
      "Link": "http://www.comuni-italiani.it/083/011/"
    },
    {
      "Istat": "012034",
      "Comune": "Caronno Pertusella",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 21042,
      "CodFisco": "B805",
      "Abitanti": 16263,
      "Link": "http://www.comuni-italiani.it/012/034/"
    },
    {
      "Istat": "012035",
      "Comune": "Caronno Varesino",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "B807",
      "Abitanti": 4944,
      "Link": "http://www.comuni-italiani.it/012/035/"
    },
    {
      "Istat": "073002",
      "Comune": "Carosino",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74021,
      "CodFisco": "B808",
      "Abitanti": 6776,
      "Link": "http://www.comuni-italiani.it/073/002/"
    },
    {
      "Istat": "074002",
      "Comune": "Carovigno",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72012,
      "CodFisco": "B809",
      "Abitanti": 16307,
      "Link": "http://www.comuni-italiani.it/074/002/"
    },
    {
      "Istat": "094007",
      "Comune": "Carovilli",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86083,
      "CodFisco": "B810",
      "Abitanti": 1430,
      "Link": "http://www.comuni-italiani.it/094/007/"
    },
    {
      "Istat": "033011",
      "Comune": "Carpaneto Piacentino",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29013,
      "CodFisco": "B812",
      "Abitanti": 7681,
      "Link": "http://www.comuni-italiani.it/033/011/"
    },
    {
      "Istat": "078027",
      "Comune": "Carpanzano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "B813",
      "Abitanti": 311,
      "Link": "http://www.comuni-italiani.it/078/027/"
    },
    {
      "Istat": "008013",
      "Comune": "Carpasio",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "B814",
      "Abitanti": 159,
      "Link": "http://www.comuni-italiani.it/008/013/"
    },
    {
      "Istat": "041009",
      "Comune": "Carpegna",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61021,
      "CodFisco": "B816",
      "Abitanti": 1689,
      "Link": "http://www.comuni-italiani.it/041/009/"
    },
    {
      "Istat": "017039",
      "Comune": "Carpenedolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25013,
      "CodFisco": "B817",
      "Abitanti": 12698,
      "Link": "http://www.comuni-italiani.it/017/039/"
    },
    {
      "Istat": "006033",
      "Comune": "Carpeneto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15071,
      "CodFisco": "B818",
      "Abitanti": 966,
      "Link": "http://www.comuni-italiani.it/006/033/"
    },
    {
      "Istat": "036005",
      "Comune": "Carpi",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41012,
      "CodFisco": "B819",
      "Abitanti": 69021,
      "Link": "http://www.comuni-italiani.it/036/005/"
    },
    {
      "Istat": "015050",
      "Comune": "Carpiano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "B820",
      "Abitanti": 3916,
      "Link": "http://www.comuni-italiani.it/015/050/"
    },
    {
      "Istat": "075015",
      "Comune": "Carpignano Salentino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "B822",
      "Abitanti": 3853,
      "Link": "http://www.comuni-italiani.it/075/015/"
    },
    {
      "Istat": "003036",
      "Comune": "Carpignano Sesia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28064,
      "CodFisco": "B823",
      "Abitanti": 2568,
      "Link": "http://www.comuni-italiani.it/003/036/"
    },
    {
      "Istat": "035011",
      "Comune": "Carpineti",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42033,
      "CodFisco": "B825",
      "Abitanti": 4223,
      "Link": "http://www.comuni-italiani.it/035/011/"
    },
    {
      "Istat": "068008",
      "Comune": "Carpineto della Nora",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "B827",
      "Abitanti": 698,
      "Link": "http://www.comuni-italiani.it/068/008/"
    },
    {
      "Istat": "058020",
      "Comune": "Carpineto Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00032",
      "CodFisco": "B828",
      "Abitanti": 4714,
      "Link": "http://www.comuni-italiani.it/058/020/"
    },
    {
      "Istat": "069011",
      "Comune": "Carpineto Sinello",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66030,
      "CodFisco": "B826",
      "Abitanti": 706,
      "Link": "http://www.comuni-italiani.it/069/011/"
    },
    {
      "Istat": "071012",
      "Comune": "Carpino",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71010,
      "CodFisco": "B829",
      "Abitanti": 4380,
      "Link": "http://www.comuni-italiani.it/071/012/"
    },
    {
      "Istat": "094008",
      "Comune": "Carpinone",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86093,
      "CodFisco": "B830",
      "Abitanti": 1225,
      "Link": "http://www.comuni-italiani.it/094/008/"
    },
    {
      "Istat": "045003",
      "Comune": "Carrara",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0585",
      "CAP": 54033,
      "CodFisco": "B832",
      "Abitanti": 65573,
      "Link": "http://www.comuni-italiani.it/045/003/"
    },
    {
      "Istat": "024024",
      "Comune": "CarrË",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "B835",
      "Abitanti": 3665,
      "Link": "http://www.comuni-italiani.it/024/024/"
    },
    {
      "Istat": "006034",
      "Comune": "Carrega Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "B836",
      "Abitanti": 85,
      "Link": "http://www.comuni-italiani.it/006/034/"
    },
    {
      "Istat": "011009",
      "Comune": "Carro",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19012,
      "CodFisco": "B838",
      "Abitanti": 593,
      "Link": "http://www.comuni-italiani.it/011/009/"
    },
    {
      "Istat": "011010",
      "Comune": "Carrodano",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "B839",
      "Abitanti": 535,
      "Link": "http://www.comuni-italiani.it/011/010/"
    },
    {
      "Istat": "006035",
      "Comune": "Carrosio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "B840",
      "Abitanti": 482,
      "Link": "http://www.comuni-italiani.it/006/035/"
    },
    {
      "Istat": "004043",
      "Comune": "Carr˘",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12061,
      "CodFisco": "B841",
      "Abitanti": 4376,
      "Link": "http://www.comuni-italiani.it/004/043/"
    },
    {
      "Istat": "066025",
      "Comune": "Carsoli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67061,
      "CodFisco": "B842",
      "Abitanti": 5607,
      "Link": "http://www.comuni-italiani.it/066/025/"
    },
    {
      "Istat": "024025",
      "Comune": "Cartigliano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36050,
      "CodFisco": "B844",
      "Abitanti": 3830,
      "Link": "http://www.comuni-italiani.it/024/025/"
    },
    {
      "Istat": "004044",
      "Comune": "Cartignano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "B845",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/004/044/"
    },
    {
      "Istat": "041010",
      "Comune": "Cartoceto",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "B846",
      "Abitanti": 7992,
      "Link": "http://www.comuni-italiani.it/041/010/"
    },
    {
      "Istat": "006036",
      "Comune": "Cartosio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15015,
      "CodFisco": "B847",
      "Abitanti": 817,
      "Link": "http://www.comuni-italiani.it/006/036/"
    },
    {
      "Istat": "028026",
      "Comune": "Cartura",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35025,
      "CodFisco": "B848",
      "Abitanti": 4653,
      "Link": "http://www.comuni-italiani.it/028/026/"
    },
    {
      "Istat": "015051",
      "Comune": "Carugate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20061,
      "CodFisco": "B850",
      "Abitanti": 14540,
      "Link": "http://www.comuni-italiani.it/015/051/"
    },
    {
      "Istat": "013048",
      "Comune": "Carugo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "B851",
      "Abitanti": 6262,
      "Link": "http://www.comuni-italiani.it/013/048/"
    },
    {
      "Istat": "069012",
      "Comune": "Carunchio",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "B853",
      "Abitanti": 668,
      "Link": "http://www.comuni-italiani.it/069/012/"
    },
    {
      "Istat": "016057",
      "Comune": "Carvico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "B854",
      "Abitanti": 4651,
      "Link": "http://www.comuni-italiani.it/016/057/"
    },
    {
      "Istat": "022043",
      "Comune": "Carzano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "B856",
      "Abitanti": 521,
      "Link": "http://www.comuni-italiani.it/022/043/"
    },
    {
      "Istat": 101004,
      "Comune": "Casabona",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88822,
      "CodFisco": "B857",
      "Abitanti": 2911,
      "Link": "http://www.comuni-italiani.it/101/004/"
    },
    {
      "Istat": "070011",
      "Comune": "Casacalenda",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86043,
      "CodFisco": "B858",
      "Abitanti": 2236,
      "Link": "http://www.comuni-italiani.it/070/011/"
    },
    {
      "Istat": "069013",
      "Comune": "Casacanditella",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "B859",
      "Abitanti": 1372,
      "Link": "http://www.comuni-italiani.it/069/013/"
    },
    {
      "Istat": "061018",
      "Comune": "Casagiove",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81022,
      "CodFisco": "B860",
      "Abitanti": 14159,
      "Link": "http://www.comuni-italiani.it/061/018/"
    },
    {
      "Istat": "006037",
      "Comune": "Casal Cermelli",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15072,
      "CodFisco": "B870",
      "Abitanti": 1263,
      "Link": "http://www.comuni-italiani.it/006/037/"
    },
    {
      "Istat": "061019",
      "Comune": "Casal di Principe",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81033,
      "CodFisco": "B872",
      "Abitanti": 21404,
      "Link": "http://www.comuni-italiani.it/061/019/"
    },
    {
      "Istat": "065028",
      "Comune": "Casal Velino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "B895",
      "Abitanti": 4995,
      "Link": "http://www.comuni-italiani.it/065/028/"
    },
    {
      "Istat": "069014",
      "Comune": "Casalanguida",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66031,
      "CodFisco": "B861",
      "Abitanti": 1026,
      "Link": "http://www.comuni-italiani.it/069/014/"
    },
    {
      "Istat": "060017",
      "Comune": "Casalattico",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "B862",
      "Abitanti": 656,
      "Link": "http://www.comuni-italiani.it/060/017/"
    },
    {
      "Istat": "003037",
      "Comune": "Casalbeltrame",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "B864",
      "Abitanti": 1033,
      "Link": "http://www.comuni-italiani.it/003/037/"
    },
    {
      "Istat": "069015",
      "Comune": "Casalbordino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66021,
      "CodFisco": "B865",
      "Abitanti": 6382,
      "Link": "http://www.comuni-italiani.it/069/015/"
    },
    {
      "Istat": "064020",
      "Comune": "Casalbore",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83034,
      "CodFisco": "B866",
      "Abitanti": 1933,
      "Link": "http://www.comuni-italiani.it/064/020/"
    },
    {
      "Istat": "001060",
      "Comune": "Casalborgone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "B867",
      "Abitanti": 1851,
      "Link": "http://www.comuni-italiani.it/001/060/"
    },
    {
      "Istat": "065026",
      "Comune": "Casalbuono",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "B868",
      "Abitanti": 1236,
      "Link": "http://www.comuni-italiani.it/065/026/"
    },
    {
      "Istat": "019016",
      "Comune": "Casalbuttano ed Uniti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26011,
      "CodFisco": "B869",
      "Abitanti": 4112,
      "Link": "http://www.comuni-italiani.it/019/016/"
    },
    {
      "Istat": "070012",
      "Comune": "Casalciprano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "B871",
      "Abitanti": 579,
      "Link": "http://www.comuni-italiani.it/070/012/"
    },
    {
      "Istat": "062015",
      "Comune": "Casalduni",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82027,
      "CodFisco": "B873",
      "Abitanti": 1493,
      "Link": "http://www.comuni-italiani.it/062/015/"
    },
    {
      "Istat": 103019,
      "Comune": "Casale Corte Cerro",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28881,
      "CodFisco": "B876",
      "Abitanti": 3473,
      "Link": "http://www.comuni-italiani.it/103/019/"
    },
    {
      "Istat": "019017",
      "Comune": "Casale Cremasco-Vidolasco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B881",
      "Abitanti": 1855,
      "Link": "http://www.comuni-italiani.it/019/017/"
    },
    {
      "Istat": "028027",
      "Comune": "Casale di Scodosia",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "B877",
      "Abitanti": 4888,
      "Link": "http://www.comuni-italiani.it/028/027/"
    },
    {
      "Istat": "012036",
      "Comune": "Casale Litta",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "B875",
      "Abitanti": 2674,
      "Link": "http://www.comuni-italiani.it/012/036/"
    },
    {
      "Istat": "050006",
      "Comune": "Casale Marittimo",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 56040,
      "CodFisco": "B878",
      "Abitanti": 1067,
      "Link": "http://www.comuni-italiani.it/050/006/"
    },
    {
      "Istat": "006039",
      "Comune": "Casale Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15033,
      "CodFisco": "B885",
      "Abitanti": 36069,
      "Link": "http://www.comuni-italiani.it/006/039/"
    },
    {
      "Istat": "026009",
      "Comune": "Casale sul Sile",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31032,
      "CodFisco": "B879",
      "Abitanti": 12789,
      "Link": "http://www.comuni-italiani.it/026/009/"
    },
    {
      "Istat": "037011",
      "Comune": "Casalecchio di Reno",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40033,
      "CodFisco": "B880",
      "Abitanti": 35761,
      "Link": "http://www.comuni-italiani.it/037/011/"
    },
    {
      "Istat": "006038",
      "Comune": "Casaleggio Boiro",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15070,
      "CodFisco": "B882",
      "Abitanti": 420,
      "Link": "http://www.comuni-italiani.it/006/038/"
    },
    {
      "Istat": "003039",
      "Comune": "Casaleggio Novara",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "B883",
      "Abitanti": 920,
      "Link": "http://www.comuni-italiani.it/003/039/"
    },
    {
      "Istat": "023019",
      "Comune": "Casaleone",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37052,
      "CodFisco": "B886",
      "Abitanti": 6041,
      "Link": "http://www.comuni-italiani.it/023/019/"
    },
    {
      "Istat": "019018",
      "Comune": "Casaletto Ceredano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B889",
      "Abitanti": 1188,
      "Link": "http://www.comuni-italiani.it/019/018/"
    },
    {
      "Istat": "019019",
      "Comune": "Casaletto di Sopra",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26014,
      "CodFisco": "B890",
      "Abitanti": 548,
      "Link": "http://www.comuni-italiani.it/019/019/"
    },
    {
      "Istat": "098008",
      "Comune": "Casaletto Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26852,
      "CodFisco": "B887",
      "Abitanti": 2719,
      "Link": "http://www.comuni-italiani.it/098/008/"
    },
    {
      "Istat": "065027",
      "Comune": "Casaletto Spartano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0973",
      "CAP": 84030,
      "CodFisco": "B888",
      "Abitanti": 1466,
      "Link": "http://www.comuni-italiani.it/065/027/"
    },
    {
      "Istat": "019020",
      "Comune": "Casaletto Vaprio",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "B891",
      "Abitanti": 1784,
      "Link": "http://www.comuni-italiani.it/019/020/"
    },
    {
      "Istat": "037012",
      "Comune": "Casalfiumanese",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40020,
      "CodFisco": "B892",
      "Abitanti": 3478,
      "Link": "http://www.comuni-italiani.it/037/012/"
    },
    {
      "Istat": "035012",
      "Comune": "Casalgrande",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42013,
      "CodFisco": "B893",
      "Abitanti": 18785,
      "Link": "http://www.comuni-italiani.it/035/012/"
    },
    {
      "Istat": "004045",
      "Comune": "Casalgrasso",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 12030,
      "CodFisco": "B894",
      "Abitanti": 1449,
      "Link": "http://www.comuni-italiani.it/004/045/"
    },
    {
      "Istat": "069016",
      "Comune": "Casalincontrada",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66012,
      "CodFisco": "B896",
      "Abitanti": 3143,
      "Link": "http://www.comuni-italiani.it/069/016/"
    },
    {
      "Istat": "003040",
      "Comune": "Casalino",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "B897",
      "Abitanti": 1589,
      "Link": "http://www.comuni-italiani.it/003/040/"
    },
    {
      "Istat": "019021",
      "Comune": "Casalmaggiore",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26041,
      "CodFisco": "B898",
      "Abitanti": 15073,
      "Link": "http://www.comuni-italiani.it/019/021/"
    },
    {
      "Istat": "098009",
      "Comune": "Casalmaiocco",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26831,
      "CodFisco": "B899",
      "Abitanti": 3108,
      "Link": "http://www.comuni-italiani.it/098/009/"
    },
    {
      "Istat": "019022",
      "Comune": "Casalmorano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "B900",
      "Abitanti": 1681,
      "Link": "http://www.comuni-italiani.it/019/022/"
    },
    {
      "Istat": "020010",
      "Comune": "Casalmoro",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "B901",
      "Abitanti": 2243,
      "Link": "http://www.comuni-italiani.it/020/010/"
    },
    {
      "Istat": "006040",
      "Comune": "Casalnoceto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15052,
      "CodFisco": "B902",
      "Abitanti": 1007,
      "Link": "http://www.comuni-italiani.it/006/040/"
    },
    {
      "Istat": "063017",
      "Comune": "Casalnuovo di Napoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80013,
      "CodFisco": "B905",
      "Abitanti": 50724,
      "Link": "http://www.comuni-italiani.it/063/017/"
    },
    {
      "Istat": "071013",
      "Comune": "Casalnuovo Monterotaro",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71033,
      "CodFisco": "B904",
      "Abitanti": 1697,
      "Link": "http://www.comuni-italiani.it/071/013/"
    },
    {
      "Istat": "020011",
      "Comune": "Casaloldo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "B907",
      "Abitanti": 2624,
      "Link": "http://www.comuni-italiani.it/020/011/"
    },
    {
      "Istat": "098010",
      "Comune": "Casalpusterlengo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26841,
      "CodFisco": "B910",
      "Abitanti": 15302,
      "Link": "http://www.comuni-italiani.it/098/010/"
    },
    {
      "Istat": "020012",
      "Comune": "Casalromano",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "B911",
      "Abitanti": 1578,
      "Link": "http://www.comuni-italiani.it/020/012/"
    },
    {
      "Istat": "028028",
      "Comune": "Casalserugo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "B912",
      "Abitanti": 5514,
      "Link": "http://www.comuni-italiani.it/028/028/"
    },
    {
      "Istat": "061020",
      "Comune": "Casaluce",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "B916",
      "Abitanti": 10283,
      "Link": "http://www.comuni-italiani.it/061/020/"
    },
    {
      "Istat": "071014",
      "Comune": "Casalvecchio di Puglia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71030,
      "CodFisco": "B917",
      "Abitanti": 1978,
      "Link": "http://www.comuni-italiani.it/071/014/"
    },
    {
      "Istat": "083012",
      "Comune": "Casalvecchio Siculo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98032,
      "CodFisco": "B918",
      "Abitanti": 945,
      "Link": "http://www.comuni-italiani.it/083/012/"
    },
    {
      "Istat": "060018",
      "Comune": "Casalvieri",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03034",
      "CodFisco": "B919",
      "Abitanti": 3098,
      "Link": "http://www.comuni-italiani.it/060/018/"
    },
    {
      "Istat": "003041",
      "Comune": "Casalvolone",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 28060,
      "CodFisco": "B920",
      "Abitanti": 891,
      "Link": "http://www.comuni-italiani.it/003/041/"
    },
    {
      "Istat": "012037",
      "Comune": "Casalzuigno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "B921",
      "Abitanti": 1360,
      "Link": "http://www.comuni-italiani.it/012/037/"
    },
    {
      "Istat": "063018",
      "Comune": "Casamarciano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80032,
      "CodFisco": "B922",
      "Abitanti": 3359,
      "Link": "http://www.comuni-italiani.it/063/018/"
    },
    {
      "Istat": "072015",
      "Comune": "Casamassima",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "B923",
      "Abitanti": 19184,
      "Link": "http://www.comuni-italiani.it/072/015/"
    },
    {
      "Istat": "063019",
      "Comune": "Casamicciola Terme",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80074,
      "CodFisco": "B924",
      "Abitanti": 8361,
      "Link": "http://www.comuni-italiani.it/063/019/"
    },
    {
      "Istat": "063020",
      "Comune": "Casandrino",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80025,
      "CodFisco": "B925",
      "Abitanti": 13179,
      "Link": "http://www.comuni-italiani.it/063/020/"
    },
    {
      "Istat": "002033",
      "Comune": "Casanova Elvo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "B928",
      "Abitanti": 275,
      "Link": "http://www.comuni-italiani.it/002/033/"
    },
    {
      "Istat": "009019",
      "Comune": "Casanova Lerrone",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17033,
      "CodFisco": "B927",
      "Abitanti": 781,
      "Link": "http://www.comuni-italiani.it/009/019/"
    },
    {
      "Istat": "018031",
      "Comune": "Casanova Lonati",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27041,
      "CodFisco": "B929",
      "Abitanti": 479,
      "Link": "http://www.comuni-italiani.it/018/031/"
    },
    {
      "Istat": "058021",
      "Comune": "Casape",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "B932",
      "Abitanti": 748,
      "Link": "http://www.comuni-italiani.it/058/021/"
    },
    {
      "Istat": "061103",
      "Comune": "Casapesenna",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "M260",
      "Abitanti": 6874,
      "Link": "http://www.comuni-italiani.it/061/103/"
    },
    {
      "Istat": "096014",
      "Comune": "Casapinta",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13866,
      "CodFisco": "B933",
      "Abitanti": 464,
      "Link": "http://www.comuni-italiani.it/096/014/"
    },
    {
      "Istat": "057011",
      "Comune": "Casaprota",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02030",
      "CodFisco": "B934",
      "Abitanti": 774,
      "Link": "http://www.comuni-italiani.it/057/011/"
    },
    {
      "Istat": "061021",
      "Comune": "Casapulla",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "B935",
      "Abitanti": 8622,
      "Link": "http://www.comuni-italiani.it/061/021/"
    },
    {
      "Istat": "075016",
      "Comune": "Casarano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73042,
      "CodFisco": "B936",
      "Abitanti": 20632,
      "Link": "http://www.comuni-italiani.it/075/016/"
    },
    {
      "Istat": "097015",
      "Comune": "Casargo",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23831,
      "CodFisco": "B937",
      "Abitanti": 874,
      "Link": "http://www.comuni-italiani.it/097/015/"
    },
    {
      "Istat": "015055",
      "Comune": "Casarile",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "B938",
      "Abitanti": 3915,
      "Link": "http://www.comuni-italiani.it/015/055/"
    },
    {
      "Istat": "093010",
      "Comune": "Casarsa della Delizia",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33072,
      "CodFisco": "B940",
      "Abitanti": 8585,
      "Link": "http://www.comuni-italiani.it/093/010/"
    },
    {
      "Istat": "010011",
      "Comune": "Casarza Ligure",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "B939",
      "Abitanti": 6694,
      "Link": "http://www.comuni-italiani.it/010/011/"
    },
    {
      "Istat": "006041",
      "Comune": "Casasco",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "B941",
      "Abitanti": 125,
      "Link": "http://www.comuni-italiani.it/006/041/"
    },
    {
      "Istat": "013050",
      "Comune": "Casasco d'Intelvi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22022,
      "CodFisco": "B942",
      "Abitanti": 433,
      "Link": "http://www.comuni-italiani.it/013/050/"
    },
    {
      "Istat": "097016",
      "Comune": "Casatenovo",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23880,
      "CodFisco": "B943",
      "Abitanti": 12691,
      "Link": "http://www.comuni-italiani.it/097/016/"
    },
    {
      "Istat": "018032",
      "Comune": "Casatisma",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "B945",
      "Abitanti": 889,
      "Link": "http://www.comuni-italiani.it/018/032/"
    },
    {
      "Istat": "063021",
      "Comune": "Casavatore",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80020,
      "CodFisco": "B946",
      "Abitanti": 18499,
      "Link": "http://www.comuni-italiani.it/063/021/"
    },
    {
      "Istat": "016058",
      "Comune": "Casazza",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "B947",
      "Abitanti": 4053,
      "Link": "http://www.comuni-italiani.it/016/058/"
    },
    {
      "Istat": "054007",
      "Comune": "Cascia",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06043",
      "CodFisco": "B948",
      "Abitanti": 3257,
      "Link": "http://www.comuni-italiani.it/054/007/"
    },
    {
      "Istat": "012038",
      "Comune": "Casciago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "B949",
      "Abitanti": 3916,
      "Link": "http://www.comuni-italiani.it/012/038/"
    },
    {
      "Istat": "050007",
      "Comune": "Casciana Terme",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56034,
      "CodFisco": "A559",
      "Abitanti": 3676,
      "Link": "http://www.comuni-italiani.it/050/007/"
    },
    {
      "Istat": "050008",
      "Comune": "Cascina",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56021,
      "CodFisco": "B950",
      "Abitanti": 44201,
      "Link": "http://www.comuni-italiani.it/050/008/"
    },
    {
      "Istat": "001061",
      "Comune": "Cascinette d'Ivrea",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "B953",
      "Abitanti": 1506,
      "Link": "http://www.comuni-italiani.it/001/061/"
    },
    {
      "Istat": "018033",
      "Comune": "Casei Gerola",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "B954",
      "Abitanti": 2547,
      "Link": "http://www.comuni-italiani.it/018/033/"
    },
    {
      "Istat": "001062",
      "Comune": "Caselette",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "B955",
      "Abitanti": 2874,
      "Link": "http://www.comuni-italiani.it/001/062/"
    },
    {
      "Istat": "010012",
      "Comune": "Casella",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16015,
      "CodFisco": "B956",
      "Abitanti": 3278,
      "Link": "http://www.comuni-italiani.it/010/012/"
    },
    {
      "Istat": "065029",
      "Comune": "Caselle in Pittari",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84030,
      "CodFisco": "B959",
      "Abitanti": 2001,
      "Link": "http://www.comuni-italiani.it/065/029/"
    },
    {
      "Istat": "098011",
      "Comune": "Caselle Landi",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26842,
      "CodFisco": "B961",
      "Abitanti": 1687,
      "Link": "http://www.comuni-italiani.it/098/011/"
    },
    {
      "Istat": "098012",
      "Comune": "Caselle Lurani",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26853,
      "CodFisco": "B958",
      "Abitanti": 3155,
      "Link": "http://www.comuni-italiani.it/098/012/"
    },
    {
      "Istat": "001063",
      "Comune": "Caselle Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10072,
      "CodFisco": "B960",
      "Abitanti": 18577,
      "Link": "http://www.comuni-italiani.it/001/063/"
    },
    {
      "Istat": "061022",
      "Comune": "Caserta",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81100,
      "CodFisco": "B963",
      "Abitanti": 78693,
      "Link": "http://www.comuni-italiani.it/061/022/"
    },
    {
      "Istat": "026010",
      "Comune": "Casier",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31030,
      "CodFisco": "B965",
      "Abitanti": 11093,
      "Link": "http://www.comuni-italiani.it/026/010/"
    },
    {
      "Istat": "080024",
      "Comune": "Casignana",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "B966",
      "Abitanti": 827,
      "Link": "http://www.comuni-italiani.it/080/024/"
    },
    {
      "Istat": "035013",
      "Comune": "Casina",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42034,
      "CodFisco": "B967",
      "Abitanti": 4538,
      "Link": "http://www.comuni-italiani.it/035/013/"
    },
    {
      "Istat": "016059",
      "Comune": "Casirate d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "B971",
      "Abitanti": 3956,
      "Link": "http://www.comuni-italiani.it/016/059/"
    },
    {
      "Istat": "013052",
      "Comune": "Caslino d'Erba",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "B974",
      "Abitanti": 1722,
      "Link": "http://www.comuni-italiani.it/013/052/"
    },
    {
      "Istat": "013053",
      "Comune": "Casnate con Bernate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "B977",
      "Abitanti": 4936,
      "Link": "http://www.comuni-italiani.it/013/053/"
    },
    {
      "Istat": "016060",
      "Comune": "Casnigo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "B978",
      "Abitanti": 3343,
      "Link": "http://www.comuni-italiani.it/016/060/"
    },
    {
      "Istat": "063022",
      "Comune": "Casola di Napoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80050,
      "CodFisco": "B980",
      "Abitanti": 3864,
      "Link": "http://www.comuni-italiani.it/063/022/"
    },
    {
      "Istat": "045004",
      "Comune": "Casola in Lunigiana",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0585",
      "CAP": 54014,
      "CodFisco": "B979",
      "Abitanti": 1042,
      "Link": "http://www.comuni-italiani.it/045/004/"
    },
    {
      "Istat": "039005",
      "Comune": "Casola Valsenio",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 48010,
      "CodFisco": "B982",
      "Abitanti": 2761,
      "Link": "http://www.comuni-italiani.it/039/005/"
    },
    {
      "Istat": "078028",
      "Comune": "Casole Bruzio",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "B983",
      "Abitanti": 2606,
      "Link": "http://www.comuni-italiani.it/078/028/"
    },
    {
      "Istat": "052004",
      "Comune": "Casole d'Elsa",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53031,
      "CodFisco": "B984",
      "Abitanti": 3880,
      "Link": "http://www.comuni-italiani.it/052/004/"
    },
    {
      "Istat": "069017",
      "Comune": "Casoli",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66043,
      "CodFisco": "B985",
      "Abitanti": 5922,
      "Link": "http://www.comuni-italiani.it/069/017/"
    },
    {
      "Istat": "018034",
      "Comune": "Casorate Primo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 27022,
      "CodFisco": "B988",
      "Abitanti": 8425,
      "Link": "http://www.comuni-italiani.it/018/034/"
    },
    {
      "Istat": "012039",
      "Comune": "Casorate Sempione",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21011,
      "CodFisco": "B987",
      "Abitanti": 5747,
      "Link": "http://www.comuni-italiani.it/012/039/"
    },
    {
      "Istat": "015058",
      "Comune": "Casorezzo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "B989",
      "Abitanti": 5384,
      "Link": "http://www.comuni-italiani.it/015/058/"
    },
    {
      "Istat": "063023",
      "Comune": "Casoria",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80026,
      "CodFisco": "B990",
      "Abitanti": 79562,
      "Link": "http://www.comuni-italiani.it/063/023/"
    },
    {
      "Istat": "005020",
      "Comune": "Casorzo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14032,
      "CodFisco": "B991",
      "Abitanti": 680,
      "Link": "http://www.comuni-italiani.it/005/020/"
    },
    {
      "Istat": "057012",
      "Comune": "Casperia",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02041",
      "CodFisco": "A472",
      "Abitanti": 1241,
      "Link": "http://www.comuni-italiani.it/057/012/"
    },
    {
      "Istat": "014013",
      "Comune": "Caspoggio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "B993",
      "Abitanti": 1513,
      "Link": "http://www.comuni-italiani.it/014/013/"
    },
    {
      "Istat": "030019",
      "Comune": "Cassacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "B994",
      "Abitanti": 2926,
      "Link": "http://www.comuni-italiani.it/030/019/"
    },
    {
      "Istat": "097017",
      "Comune": "Cassago Brianza",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23893,
      "CodFisco": "B996",
      "Abitanti": 4393,
      "Link": "http://www.comuni-italiani.it/097/017/"
    },
    {
      "Istat": "078029",
      "Comune": "Cassano all'Ionio",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87011,
      "CodFisco": "C002",
      "Abitanti": 17587,
      "Link": "http://www.comuni-italiani.it/078/029/"
    },
    {
      "Istat": "015059",
      "Comune": "Cassano d'Adda",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 20062,
      "CodFisco": "C003",
      "Abitanti": 18767,
      "Link": "http://www.comuni-italiani.it/015/059/"
    },
    {
      "Istat": "072016",
      "Comune": "Cassano delle Murge",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70020,
      "CodFisco": "B998",
      "Abitanti": 13701,
      "Link": "http://www.comuni-italiani.it/072/016/"
    },
    {
      "Istat": "064021",
      "Comune": "Cassano Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "B997",
      "Abitanti": 996,
      "Link": "http://www.comuni-italiani.it/064/021/"
    },
    {
      "Istat": "012040",
      "Comune": "Cassano Magnago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21012,
      "CodFisco": "C004",
      "Abitanti": 21595,
      "Link": "http://www.comuni-italiani.it/012/040/"
    },
    {
      "Istat": "006042",
      "Comune": "Cassano Spinola",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15063,
      "CodFisco": "C005",
      "Abitanti": 1852,
      "Link": "http://www.comuni-italiani.it/006/042/"
    },
    {
      "Istat": "012041",
      "Comune": "Cassano Valcuvia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "B999",
      "Abitanti": 663,
      "Link": "http://www.comuni-italiani.it/012/041/"
    },
    {
      "Istat": "089007",
      "Comune": "Cassaro",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "C006",
      "Abitanti": 819,
      "Link": "http://www.comuni-italiani.it/089/007/"
    },
    {
      "Istat": "016061",
      "Comune": "Cassiglio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "C007",
      "Abitanti": 120,
      "Link": "http://www.comuni-italiani.it/016/061/"
    },
    {
      "Istat": "015060",
      "Comune": "Cassina de' Pecchi",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "C014",
      "Abitanti": 13130,
      "Link": "http://www.comuni-italiani.it/015/060/"
    },
    {
      "Istat": "013055",
      "Comune": "Cassina Rizzardi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "C020",
      "Abitanti": 3224,
      "Link": "http://www.comuni-italiani.it/013/055/"
    },
    {
      "Istat": "097018",
      "Comune": "Cassina Valsassina",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23817,
      "CodFisco": "C024",
      "Abitanti": 481,
      "Link": "http://www.comuni-italiani.it/097/018/"
    },
    {
      "Istat": "005021",
      "Comune": "Cassinasco",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14050,
      "CodFisco": "C022",
      "Abitanti": 629,
      "Link": "http://www.comuni-italiani.it/005/021/"
    },
    {
      "Istat": "006043",
      "Comune": "Cassine",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15016,
      "CodFisco": "C027",
      "Abitanti": 3085,
      "Link": "http://www.comuni-italiani.it/006/043/"
    },
    {
      "Istat": "006044",
      "Comune": "Cassinelle",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15070,
      "CodFisco": "C030",
      "Abitanti": 924,
      "Link": "http://www.comuni-italiani.it/006/044/"
    },
    {
      "Istat": "015061",
      "Comune": "Cassinetta di Lugagnano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20081,
      "CodFisco": "C033",
      "Abitanti": 1879,
      "Link": "http://www.comuni-italiani.it/015/061/"
    },
    {
      "Istat": "060019",
      "Comune": "Cassino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03043",
      "CodFisco": "C034",
      "Abitanti": 33153,
      "Link": "http://www.comuni-italiani.it/060/019/"
    },
    {
      "Istat": "024026",
      "Comune": "Cassola",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36022,
      "CodFisco": "C037",
      "Abitanti": 14301,
      "Link": "http://www.comuni-italiani.it/024/026/"
    },
    {
      "Istat": "018035",
      "Comune": "Cassolnovo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0381",
      "CAP": 27023,
      "CodFisco": "C038",
      "Abitanti": 7116,
      "Link": "http://www.comuni-italiani.it/018/035/"
    },
    {
      "Istat": "023020",
      "Comune": "Castagnaro",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37043,
      "CodFisco": "C041",
      "Abitanti": 4065,
      "Link": "http://www.comuni-italiani.it/023/020/"
    },
    {
      "Istat": "049006",
      "Comune": "Castagneto Carducci",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57022,
      "CodFisco": "C044",
      "Abitanti": 8934,
      "Link": "http://www.comuni-italiani.it/049/006/"
    },
    {
      "Istat": "001064",
      "Comune": "Castagneto Po",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "C045",
      "Abitanti": 1820,
      "Link": "http://www.comuni-italiani.it/001/064/"
    },
    {
      "Istat": "004046",
      "Comune": "Castagnito",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "C046",
      "Abitanti": 2157,
      "Link": "http://www.comuni-italiani.it/004/046/"
    },
    {
      "Istat": "005022",
      "Comune": "Castagnole delle Lanze",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14054,
      "CodFisco": "C049",
      "Abitanti": 3833,
      "Link": "http://www.comuni-italiani.it/005/022/"
    },
    {
      "Istat": "005023",
      "Comune": "Castagnole Monferrato",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "C047",
      "Abitanti": 1270,
      "Link": "http://www.comuni-italiani.it/005/023/"
    },
    {
      "Istat": "001065",
      "Comune": "Castagnole Piemonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "C048",
      "Abitanti": 2186,
      "Link": "http://www.comuni-italiani.it/001/065/"
    },
    {
      "Istat": "018036",
      "Comune": "Castana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "C050",
      "Abitanti": 743,
      "Link": "http://www.comuni-italiani.it/018/036/"
    },
    {
      "Istat": "015062",
      "Comune": "Castano Primo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20022,
      "CodFisco": "C052",
      "Abitanti": 11026,
      "Link": "http://www.comuni-italiani.it/015/062/"
    },
    {
      "Istat": "018037",
      "Comune": "Casteggio",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27045,
      "CodFisco": "C053",
      "Abitanti": 6530,
      "Link": "http://www.comuni-italiani.it/018/037/"
    },
    {
      "Istat": "017040",
      "Comune": "Castegnato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25045,
      "CodFisco": "C055",
      "Abitanti": 7987,
      "Link": "http://www.comuni-italiani.it/017/040/"
    },
    {
      "Istat": "024027",
      "Comune": "Castegnero",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36020,
      "CodFisco": "C056",
      "Abitanti": 2843,
      "Link": "http://www.comuni-italiani.it/024/027/"
    },
    {
      "Istat": "064022",
      "Comune": "Castel Baronia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "C058",
      "Abitanti": 1178,
      "Link": "http://www.comuni-italiani.it/064/022/"
    },
    {
      "Istat": "005024",
      "Comune": "Castel Boglione",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "C064",
      "Abitanti": 632,
      "Link": "http://www.comuni-italiani.it/005/024/"
    },
    {
      "Istat": "039006",
      "Comune": "Castel Bolognese",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 48014,
      "CodFisco": "C065",
      "Abitanti": 9626,
      "Link": "http://www.comuni-italiani.it/039/006/"
    },
    {
      "Istat": "061023",
      "Comune": "Castel Campagnano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "B494",
      "Abitanti": 1635,
      "Link": "http://www.comuni-italiani.it/061/023/"
    },
    {
      "Istat": "067010",
      "Comune": "Castel Castagna",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64030,
      "CodFisco": "C040",
      "Abitanti": 502,
      "Link": "http://www.comuni-italiani.it/067/010/"
    },
    {
      "Istat": "042009",
      "Comune": "Castel Colonna",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "C071",
      "Abitanti": 1072,
      "Link": "http://www.comuni-italiani.it/042/009/"
    },
    {
      "Istat": "022045",
      "Comune": "Castel Condino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38082,
      "CodFisco": "C183",
      "Abitanti": 243,
      "Link": "http://www.comuni-italiani.it/022/045/"
    },
    {
      "Istat": "037013",
      "Comune": "Castel d'Aiano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40034,
      "CodFisco": "C075",
      "Abitanti": 1982,
      "Link": "http://www.comuni-italiani.it/037/013/"
    },
    {
      "Istat": "020014",
      "Comune": "Castel d'Ario",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46033,
      "CodFisco": "C076",
      "Abitanti": 4882,
      "Link": "http://www.comuni-italiani.it/020/014/"
    },
    {
      "Istat": "023021",
      "Comune": "Castel d'Azzano",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "C078",
      "Abitanti": 11795,
      "Link": "http://www.comuni-italiani.it/023/021/"
    },
    {
      "Istat": "094009",
      "Comune": "Castel del Giudice",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "C082",
      "Abitanti": 355,
      "Link": "http://www.comuni-italiani.it/094/009/"
    },
    {
      "Istat": "066026",
      "Comune": "Castel del Monte",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67023,
      "CodFisco": "C083",
      "Abitanti": 508,
      "Link": "http://www.comuni-italiani.it/066/026/"
    },
    {
      "Istat": "053004",
      "Comune": "Castel del Piano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58033,
      "CodFisco": "C085",
      "Abitanti": 4700,
      "Link": "http://www.comuni-italiani.it/053/004/"
    },
    {
      "Istat": "037014",
      "Comune": "Castel del Rio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40022,
      "CodFisco": "C086",
      "Abitanti": 1260,
      "Link": "http://www.comuni-italiani.it/037/014/"
    },
    {
      "Istat": "037015",
      "Comune": "Castel di Casio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40030,
      "CodFisco": "B969",
      "Abitanti": 3526,
      "Link": "http://www.comuni-italiani.it/037/015/"
    },
    {
      "Istat": "066027",
      "Comune": "Castel di Ieri",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67020,
      "CodFisco": "C090",
      "Abitanti": 343,
      "Link": "http://www.comuni-italiani.it/066/027/"
    },
    {
      "Istat": "087013",
      "Comune": "Castel di Iudica",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95040,
      "CodFisco": "C091",
      "Abitanti": 4726,
      "Link": "http://www.comuni-italiani.it/087/013/"
    },
    {
      "Istat": "044011",
      "Comune": "Castel di Lama",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63082,
      "CodFisco": "C093",
      "Abitanti": 8511,
      "Link": "http://www.comuni-italiani.it/044/011/"
    },
    {
      "Istat": "083013",
      "Comune": "Castel di Lucio",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98070,
      "CodFisco": "C094",
      "Abitanti": 1390,
      "Link": "http://www.comuni-italiani.it/083/013/"
    },
    {
      "Istat": "066028",
      "Comune": "Castel di Sangro",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67031,
      "CodFisco": "C096",
      "Abitanti": 6125,
      "Link": "http://www.comuni-italiani.it/066/028/"
    },
    {
      "Istat": "061024",
      "Comune": "Castel di Sasso",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "C097",
      "Abitanti": 1201,
      "Link": "http://www.comuni-italiani.it/061/024/"
    },
    {
      "Istat": "057013",
      "Comune": "Castel di Tora",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "C098",
      "Abitanti": 310,
      "Link": "http://www.comuni-italiani.it/057/013/"
    },
    {
      "Istat": "051008",
      "Comune": "Castel Focognano",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52016,
      "CodFisco": "C102",
      "Abitanti": 3298,
      "Link": "http://www.comuni-italiani.it/051/008/"
    },
    {
      "Istat": "069018",
      "Comune": "Castel Frentano",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66032,
      "CodFisco": "C114",
      "Abitanti": 4305,
      "Link": "http://www.comuni-italiani.it/069/018/"
    },
    {
      "Istat": "019024",
      "Comune": "Castel Gabbiano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "C115",
      "Abitanti": 474,
      "Link": "http://www.comuni-italiani.it/019/024/"
    },
    {
      "Istat": "058022",
      "Comune": "Castel Gandolfo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "C116",
      "Abitanti": 9037,
      "Link": "http://www.comuni-italiani.it/058/022/"
    },
    {
      "Istat": "055009",
      "Comune": "Castel Giorgio",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05013",
      "CodFisco": "C117",
      "Abitanti": 2207,
      "Link": "http://www.comuni-italiani.it/055/009/"
    },
    {
      "Istat": "020015",
      "Comune": "Castel Goffredo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46042,
      "CodFisco": "C118",
      "Abitanti": 12094,
      "Link": "http://www.comuni-italiani.it/020/015/"
    },
    {
      "Istat": "037016",
      "Comune": "Castel Guelfo di Bologna",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40023,
      "CodFisco": "C121",
      "Abitanti": 4281,
      "Link": "http://www.comuni-italiani.it/037/016/"
    },
    {
      "Istat": "058023",
      "Comune": "Castel Madama",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00024",
      "CodFisco": "C203",
      "Abitanti": 7568,
      "Link": "http://www.comuni-italiani.it/058/023/"
    },
    {
      "Istat": "037019",
      "Comune": "Castel Maggiore",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40013,
      "CodFisco": "C204",
      "Abitanti": 17466,
      "Link": "http://www.comuni-italiani.it/037/019/"
    },
    {
      "Istat": "017042",
      "Comune": "Castel Mella",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "C208",
      "Abitanti": 10803,
      "Link": "http://www.comuni-italiani.it/017/042/"
    },
    {
      "Istat": "061026",
      "Comune": "Castel Morrone",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "C211",
      "Abitanti": 3982,
      "Link": "http://www.comuni-italiani.it/061/026/"
    },
    {
      "Istat": "054008",
      "Comune": "Castel Ritaldi",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06044",
      "CodFisco": "C252",
      "Abitanti": 3354,
      "Link": "http://www.comuni-italiani.it/054/008/"
    },
    {
      "Istat": "005032",
      "Comune": "Castel Rocchero",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14044,
      "CodFisco": "C253",
      "Abitanti": 414,
      "Link": "http://www.comuni-italiani.it/005/032/"
    },
    {
      "Istat": "016063",
      "Comune": "Castel Rozzone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "C255",
      "Abitanti": 2918,
      "Link": "http://www.comuni-italiani.it/016/063/"
    },
    {
      "Istat": "065034",
      "Comune": "Castel San Giorgio",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84083,
      "CodFisco": "C259",
      "Abitanti": 13728,
      "Link": "http://www.comuni-italiani.it/065/034/"
    },
    {
      "Istat": "033013",
      "Comune": "Castel San Giovanni",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29015,
      "CodFisco": "C261",
      "Abitanti": 13943,
      "Link": "http://www.comuni-italiani.it/033/013/"
    },
    {
      "Istat": "065035",
      "Comune": "Castel San Lorenzo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84049,
      "CodFisco": "C262",
      "Abitanti": 2708,
      "Link": "http://www.comuni-italiani.it/065/035/"
    },
    {
      "Istat": "051010",
      "Comune": "Castel San NiccolÚ",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52018,
      "CodFisco": "C263",
      "Abitanti": 2778,
      "Link": "http://www.comuni-italiani.it/051/010/"
    },
    {
      "Istat": "058025",
      "Comune": "Castel San Pietro Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "C266",
      "Abitanti": 859,
      "Link": "http://www.comuni-italiani.it/058/025/"
    },
    {
      "Istat": "037020",
      "Comune": "Castel San Pietro Terme",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40024,
      "CodFisco": "C265",
      "Abitanti": 20689,
      "Link": "http://www.comuni-italiani.it/037/020/"
    },
    {
      "Istat": "094012",
      "Comune": "Castel San Vincenzo",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86071,
      "CodFisco": "C270",
      "Abitanti": 561,
      "Link": "http://www.comuni-italiani.it/094/012/"
    },
    {
      "Istat": "057015",
      "Comune": "Castel Sant'Angelo",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "C268",
      "Abitanti": 1289,
      "Link": "http://www.comuni-italiani.it/057/015/"
    },
    {
      "Istat": "056017",
      "Comune": "Castel Sant'Elia",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "C269",
      "Abitanti": 2642,
      "Link": "http://www.comuni-italiani.it/056/017/"
    },
    {
      "Istat": "055010",
      "Comune": "Castel Viscardo",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05014",
      "CodFisco": "C289",
      "Abitanti": 3079,
      "Link": "http://www.comuni-italiani.it/055/010/"
    },
    {
      "Istat": "008015",
      "Comune": "Castel Vittorio",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18030,
      "CodFisco": "C110",
      "Abitanti": 350,
      "Link": "http://www.comuni-italiani.it/008/015/"
    },
    {
      "Istat": "061027",
      "Comune": "Castel Volturno",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81030,
      "CodFisco": "C291",
      "Abitanti": 24149,
      "Link": "http://www.comuni-italiani.it/061/027/"
    },
    {
      "Istat": "028029",
      "Comune": "Castelbaldo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35040,
      "CodFisco": "C057",
      "Abitanti": 1653,
      "Link": "http://www.comuni-italiani.it/028/029/"
    },
    {
      "Istat": "020013",
      "Comune": "Castelbelforte",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46032,
      "CodFisco": "C059",
      "Abitanti": 3029,
      "Link": "http://www.comuni-italiani.it/020/013/"
    },
    {
      "Istat": "042008",
      "Comune": "Castelbellino",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "C060",
      "Abitanti": 4858,
      "Link": "http://www.comuni-italiani.it/042/008/"
    },
    {
      "Istat": "021018",
      "Comune": "Castelbello-Ciardes",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "C062",
      "Abitanti": 2392,
      "Link": "http://www.comuni-italiani.it/021/018/"
    },
    {
      "Istat": "009020",
      "Comune": "Castelbianco",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17030,
      "CodFisco": "C063",
      "Abitanti": 324,
      "Link": "http://www.comuni-italiani.it/009/020/"
    },
    {
      "Istat": "070013",
      "Comune": "Castelbottaccio",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "C066",
      "Abitanti": 363,
      "Link": "http://www.comuni-italiani.it/070/013/"
    },
    {
      "Istat": "082022",
      "Comune": "Castelbuono",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90013,
      "CodFisco": "C067",
      "Abitanti": 9301,
      "Link": "http://www.comuni-italiani.it/082/022/"
    },
    {
      "Istat": "065030",
      "Comune": "Castelcivita",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "C069",
      "Abitanti": 1902,
      "Link": "http://www.comuni-italiani.it/065/030/"
    },
    {
      "Istat": "017041",
      "Comune": "Castelcovati",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "C072",
      "Abitanti": 6564,
      "Link": "http://www.comuni-italiani.it/017/041/"
    },
    {
      "Istat": "026011",
      "Comune": "Castelcucco",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31030,
      "CodFisco": "C073",
      "Abitanti": 2220,
      "Link": "http://www.comuni-italiani.it/026/011/"
    },
    {
      "Istat": "082023",
      "Comune": "Casteldaccia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90014,
      "CodFisco": "C074",
      "Abitanti": 11233,
      "Link": "http://www.comuni-italiani.it/082/023/"
    },
    {
      "Istat": "099021",
      "Comune": "Casteldelci",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47861,
      "CodFisco": "C080",
      "Abitanti": 454,
      "Link": "http://www.comuni-italiani.it/099/021/"
    },
    {
      "Istat": "004047",
      "Comune": "Casteldelfino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "C081",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/004/047/"
    },
    {
      "Istat": "019023",
      "Comune": "Casteldidone",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26030,
      "CodFisco": "C089",
      "Abitanti": 592,
      "Link": "http://www.comuni-italiani.it/019/023/"
    },
    {
      "Istat": "042010",
      "Comune": "Castelfidardo",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60022,
      "CodFisco": "C100",
      "Abitanti": 18935,
      "Link": "http://www.comuni-italiani.it/042/010/"
    },
    {
      "Istat": "048010",
      "Comune": "Castelfiorentino",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50051,
      "CodFisco": "C101",
      "Abitanti": 17959,
      "Link": "http://www.comuni-italiani.it/048/010/"
    },
    {
      "Istat": "022046",
      "Comune": "Castelfondo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "C103",
      "Abitanti": 643,
      "Link": "http://www.comuni-italiani.it/022/046/"
    },
    {
      "Istat": "059004",
      "Comune": "Castelforte",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04021",
      "CodFisco": "C104",
      "Abitanti": 4505,
      "Link": "http://www.comuni-italiani.it/059/004/"
    },
    {
      "Istat": "064023",
      "Comune": "Castelfranci",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "C105",
      "Abitanti": 2165,
      "Link": "http://www.comuni-italiani.it/064/023/"
    },
    {
      "Istat": "051009",
      "Comune": "Castelfranco di Sopra",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52020,
      "CodFisco": "C112",
      "Abitanti": 3099,
      "Link": "http://www.comuni-italiani.it/051/009/"
    },
    {
      "Istat": "050009",
      "Comune": "Castelfranco di Sotto",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 56022,
      "CodFisco": "C113",
      "Abitanti": 13219,
      "Link": "http://www.comuni-italiani.it/050/009/"
    },
    {
      "Istat": "036006",
      "Comune": "Castelfranco Emilia",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41013,
      "CodFisco": "C107",
      "Abitanti": 32102,
      "Link": "http://www.comuni-italiani.it/036/006/"
    },
    {
      "Istat": "062016",
      "Comune": "Castelfranco in Miscano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82022,
      "CodFisco": "C106",
      "Abitanti": 964,
      "Link": "http://www.comuni-italiani.it/062/016/"
    },
    {
      "Istat": "026012",
      "Comune": "Castelfranco Veneto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31033,
      "CodFisco": "C111",
      "Abitanti": 33740,
      "Link": "http://www.comuni-italiani.it/026/012/"
    },
    {
      "Istat": "024028",
      "Comune": "Castelgomberto",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36070,
      "CodFisco": "C119",
      "Abitanti": 6185,
      "Link": "http://www.comuni-italiani.it/024/028/"
    },
    {
      "Istat": "076021",
      "Comune": "Castelgrande",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85050,
      "CodFisco": "C120",
      "Abitanti": 1032,
      "Link": "http://www.comuni-italiani.it/076/021/"
    },
    {
      "Istat": "029011",
      "Comune": "Castelguglielmo",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "C122",
      "Abitanti": 1698,
      "Link": "http://www.comuni-italiani.it/029/011/"
    },
    {
      "Istat": "069019",
      "Comune": "Castelguidone",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66040,
      "CodFisco": "C123",
      "Abitanti": 427,
      "Link": "http://www.comuni-italiani.it/069/019/"
    },
    {
      "Istat": "065031",
      "Comune": "Castellabate",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84048,
      "CodFisco": "C125",
      "Abitanti": 8213,
      "Link": "http://www.comuni-italiani.it/065/031/"
    },
    {
      "Istat": "066029",
      "Comune": "Castellafiume",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "C126",
      "Abitanti": 1124,
      "Link": "http://www.comuni-italiani.it/066/029/"
    },
    {
      "Istat": "005025",
      "Comune": "Castell'Alfero",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14033,
      "CodFisco": "C127",
      "Abitanti": 2801,
      "Link": "http://www.comuni-italiani.it/005/025/"
    },
    {
      "Istat": "067011",
      "Comune": "Castellalto",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64020,
      "CodFisco": "C128",
      "Abitanti": 7458,
      "Link": "http://www.comuni-italiani.it/067/011/"
    },
    {
      "Istat": "081005",
      "Comune": "Castellammare del Golfo",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91014,
      "CodFisco": "C130",
      "Abitanti": 15293,
      "Link": "http://www.comuni-italiani.it/081/005/"
    },
    {
      "Istat": "063024",
      "Comune": "Castellammare di Stabia",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80053,
      "CodFisco": "C129",
      "Abitanti": 64506,
      "Link": "http://www.comuni-italiani.it/063/024/"
    },
    {
      "Istat": "001066",
      "Comune": "Castellamonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10081,
      "CodFisco": "C133",
      "Abitanti": 10008,
      "Link": "http://www.comuni-italiani.it/001/066/"
    },
    {
      "Istat": "072017",
      "Comune": "Castellana Grotte",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70013,
      "CodFisco": "C134",
      "Abitanti": 19435,
      "Link": "http://www.comuni-italiani.it/072/017/"
    },
    {
      "Istat": "082024",
      "Comune": "Castellana Sicula",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90020,
      "CodFisco": "C135",
      "Abitanti": 3612,
      "Link": "http://www.comuni-italiani.it/082/024/"
    },
    {
      "Istat": "073003",
      "Comune": "Castellaneta",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74011,
      "CodFisco": "C136",
      "Abitanti": 17144,
      "Link": "http://www.comuni-italiani.it/073/003/"
    },
    {
      "Istat": "006045",
      "Comune": "Castellania",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15051,
      "CodFisco": "C137",
      "Abitanti": 86,
      "Link": "http://www.comuni-italiani.it/006/045/"
    },
    {
      "Istat": "012042",
      "Comune": "Castellanza",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21053,
      "CodFisco": "C139",
      "Abitanti": 14400,
      "Link": "http://www.comuni-italiani.it/012/042/"
    },
    {
      "Istat": "004048",
      "Comune": "Castellar",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "C140",
      "Abitanti": 272,
      "Link": "http://www.comuni-italiani.it/004/048/"
    },
    {
      "Istat": "006046",
      "Comune": "Castellar Guidobono",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "C142",
      "Abitanti": 422,
      "Link": "http://www.comuni-italiani.it/006/046/"
    },
    {
      "Istat": "035014",
      "Comune": "Castellarano",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 42014,
      "CodFisco": "C141",
      "Abitanti": 15095,
      "Link": "http://www.comuni-italiani.it/035/014/"
    },
    {
      "Istat": "008014",
      "Comune": "Castellaro",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18011,
      "CodFisco": "C143",
      "Abitanti": 1267,
      "Link": "http://www.comuni-italiani.it/008/014/"
    },
    {
      "Istat": "033012",
      "Comune": "Castell'Arquato",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29014,
      "CodFisco": "C145",
      "Abitanti": 4773,
      "Link": "http://www.comuni-italiani.it/033/012/"
    },
    {
      "Istat": "025009",
      "Comune": "Castellavazzo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "C146",
      "Abitanti": 1635,
      "Link": "http://www.comuni-italiani.it/025/009/"
    },
    {
      "Istat": "053005",
      "Comune": "Castell'Azzara",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58034,
      "CodFisco": "C147",
      "Abitanti": 1625,
      "Link": "http://www.comuni-italiani.it/053/005/"
    },
    {
      "Istat": "006047",
      "Comune": "Castellazzo Bormida",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15073,
      "CodFisco": "C148",
      "Abitanti": 4696,
      "Link": "http://www.comuni-italiani.it/006/047/"
    },
    {
      "Istat": "003042",
      "Comune": "Castellazzo Novarese",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "C149",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/003/042/"
    },
    {
      "Istat": "019025",
      "Comune": "Castelleone",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26012,
      "CodFisco": "C153",
      "Abitanti": 9603,
      "Link": "http://www.comuni-italiani.it/019/025/"
    },
    {
      "Istat": "042011",
      "Comune": "Castelleone di Suasa",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "C152",
      "Abitanti": 1731,
      "Link": "http://www.comuni-italiani.it/042/011/"
    },
    {
      "Istat": "005026",
      "Comune": "Castellero",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14013,
      "CodFisco": "C154",
      "Abitanti": 307,
      "Link": "http://www.comuni-italiani.it/005/026/"
    },
    {
      "Istat": "096015",
      "Comune": "Castelletto Cervo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13851,
      "CodFisco": "C155",
      "Abitanti": 905,
      "Link": "http://www.comuni-italiani.it/096/015/"
    },
    {
      "Istat": "006048",
      "Comune": "Castelletto d'Erro",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "C156",
      "Abitanti": 149,
      "Link": "http://www.comuni-italiani.it/006/048/"
    },
    {
      "Istat": "018038",
      "Comune": "Castelletto di Branduzzo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "C157",
      "Abitanti": 1065,
      "Link": "http://www.comuni-italiani.it/018/038/"
    },
    {
      "Istat": "006049",
      "Comune": "Castelletto d'Orba",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "C158",
      "Abitanti": 2092,
      "Link": "http://www.comuni-italiani.it/006/049/"
    },
    {
      "Istat": "006050",
      "Comune": "Castelletto Merli",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 15020,
      "CodFisco": "C160",
      "Abitanti": 493,
      "Link": "http://www.comuni-italiani.it/006/050/"
    },
    {
      "Istat": "005027",
      "Comune": "Castelletto Molina",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "C161",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/005/027/"
    },
    {
      "Istat": "006051",
      "Comune": "Castelletto Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "C162",
      "Abitanti": 1562,
      "Link": "http://www.comuni-italiani.it/006/051/"
    },
    {
      "Istat": "003043",
      "Comune": "Castelletto Sopra Ticino",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0331",
      "CAP": 28053,
      "CodFisco": "C166",
      "Abitanti": 10259,
      "Link": "http://www.comuni-italiani.it/003/043/"
    },
    {
      "Istat": "004049",
      "Comune": "Castelletto Stura",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12040,
      "CodFisco": "C165",
      "Abitanti": 1341,
      "Link": "http://www.comuni-italiani.it/004/049/"
    },
    {
      "Istat": "004050",
      "Comune": "Castelletto Uzzone",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12070,
      "CodFisco": "C167",
      "Abitanti": 370,
      "Link": "http://www.comuni-italiani.it/004/050/"
    },
    {
      "Istat": "067012",
      "Comune": "Castelli",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64041,
      "CodFisco": "C169",
      "Abitanti": 1256,
      "Link": "http://www.comuni-italiani.it/067/012/"
    },
    {
      "Istat": "016062",
      "Comune": "Castelli Calepio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "C079",
      "Abitanti": 10016,
      "Link": "http://www.comuni-italiani.it/016/062/"
    },
    {
      "Istat": "052005",
      "Comune": "Castellina in Chianti",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53011,
      "CodFisco": "C172",
      "Abitanti": 2971,
      "Link": "http://www.comuni-italiani.it/052/005/"
    },
    {
      "Istat": "050010",
      "Comune": "Castellina Marittima",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56040,
      "CodFisco": "C174",
      "Abitanti": 2055,
      "Link": "http://www.comuni-italiani.it/050/010/"
    },
    {
      "Istat": "004051",
      "Comune": "Castellinaldo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "C173",
      "Abitanti": 906,
      "Link": "http://www.comuni-italiani.it/004/051/"
    },
    {
      "Istat": "070014",
      "Comune": "Castellino del Biferno",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "C175",
      "Abitanti": 613,
      "Link": "http://www.comuni-italiani.it/070/014/"
    },
    {
      "Istat": "004052",
      "Comune": "Castellino Tanaro",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "C176",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/004/052/"
    },
    {
      "Istat": "060020",
      "Comune": "Castelliri",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "C177",
      "Abitanti": 3539,
      "Link": "http://www.comuni-italiani.it/060/020/"
    },
    {
      "Istat": "012043",
      "Comune": "Castello Cabiaglio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "B312",
      "Abitanti": 548,
      "Link": "http://www.comuni-italiani.it/012/043/"
    },
    {
      "Istat": "018039",
      "Comune": "Castello d'Agogna",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "C184",
      "Abitanti": 1073,
      "Link": "http://www.comuni-italiani.it/018/039/"
    },
    {
      "Istat": "037017",
      "Comune": "Castello d'Argile",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "C185",
      "Abitanti": 6419,
      "Link": "http://www.comuni-italiani.it/037/017/"
    },
    {
      "Istat": "061025",
      "Comune": "Castello del Matese",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81016,
      "CodFisco": "C178",
      "Abitanti": 1539,
      "Link": "http://www.comuni-italiani.it/061/025/"
    },
    {
      "Istat": "014014",
      "Comune": "Castello dell'Acqua",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "C186",
      "Abitanti": 677,
      "Link": "http://www.comuni-italiani.it/014/014/"
    },
    {
      "Istat": "005028",
      "Comune": "Castello di Annone",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14034,
      "CodFisco": "A300",
      "Abitanti": 1938,
      "Link": "http://www.comuni-italiani.it/005/028/"
    },
    {
      "Istat": "097019",
      "Comune": "Castello di Brianza",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23884,
      "CodFisco": "C187",
      "Abitanti": 2469,
      "Link": "http://www.comuni-italiani.it/097/019/"
    },
    {
      "Istat": "063025",
      "Comune": "Castello di Cisterna",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "C188",
      "Abitanti": 7435,
      "Link": "http://www.comuni-italiani.it/063/025/"
    },
    {
      "Istat": "026013",
      "Comune": "Castello di Godego",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31030,
      "CodFisco": "C190",
      "Abitanti": 7102,
      "Link": "http://www.comuni-italiani.it/026/013/"
    },
    {
      "Istat": "037018",
      "Comune": "Castello di Serravalle",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "C191",
      "Abitanti": 4917,
      "Link": "http://www.comuni-italiani.it/037/018/"
    },
    {
      "Istat": "022048",
      "Comune": "Castello Tesino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38053,
      "CodFisco": "C194",
      "Abitanti": 1345,
      "Link": "http://www.comuni-italiani.it/022/048/"
    },
    {
      "Istat": "022047",
      "Comune": "Castello-Molina di Fiemme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "C189",
      "Abitanti": 2273,
      "Link": "http://www.comuni-italiani.it/022/047/"
    },
    {
      "Istat": "020016",
      "Comune": "Castellucchio",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46014,
      "CodFisco": "C195",
      "Abitanti": 5190,
      "Link": "http://www.comuni-italiani.it/020/016/"
    },
    {
      "Istat": "071015",
      "Comune": "Castelluccio dei Sauri",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71025,
      "CodFisco": "C198",
      "Abitanti": 2144,
      "Link": "http://www.comuni-italiani.it/071/015/"
    },
    {
      "Istat": "076022",
      "Comune": "Castelluccio Inferiore",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85040,
      "CodFisco": "C199",
      "Abitanti": 2175,
      "Link": "http://www.comuni-italiani.it/076/022/"
    },
    {
      "Istat": "076023",
      "Comune": "Castelluccio Superiore",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85040,
      "CodFisco": "C201",
      "Abitanti": 874,
      "Link": "http://www.comuni-italiani.it/076/023/"
    },
    {
      "Istat": "071016",
      "Comune": "Castelluccio Valmaggiore",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71020,
      "CodFisco": "C202",
      "Abitanti": 1370,
      "Link": "http://www.comuni-italiani.it/071/016/"
    },
    {
      "Istat": "083014",
      "Comune": "Castell'Umberto",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "C051",
      "Abitanti": 3337,
      "Link": "http://www.comuni-italiani.it/083/014/"
    },
    {
      "Istat": "004053",
      "Comune": "Castelmagno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "C205",
      "Abitanti": 85,
      "Link": "http://www.comuni-italiani.it/004/053/"
    },
    {
      "Istat": "013058",
      "Comune": "Castelmarte",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "C206",
      "Abitanti": 1303,
      "Link": "http://www.comuni-italiani.it/013/058/"
    },
    {
      "Istat": "029012",
      "Comune": "Castelmassa",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45035,
      "CodFisco": "C207",
      "Abitanti": 4378,
      "Link": "http://www.comuni-italiani.it/029/012/"
    },
    {
      "Istat": "070015",
      "Comune": "Castelmauro",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86031,
      "CodFisco": "C197",
      "Abitanti": 1661,
      "Link": "http://www.comuni-italiani.it/070/015/"
    },
    {
      "Istat": "076024",
      "Comune": "Castelmezzano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "C209",
      "Abitanti": 861,
      "Link": "http://www.comuni-italiani.it/076/024/"
    },
    {
      "Istat": "083015",
      "Comune": "Castelmola",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "C210",
      "Abitanti": 1082,
      "Link": "http://www.comuni-italiani.it/083/015/"
    },
    {
      "Istat": "018040",
      "Comune": "Castelnovetto",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "C213",
      "Abitanti": 642,
      "Link": "http://www.comuni-italiani.it/018/040/"
    },
    {
      "Istat": "029013",
      "Comune": "Castelnovo Bariano",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "C215",
      "Abitanti": 2968,
      "Link": "http://www.comuni-italiani.it/029/013/"
    },
    {
      "Istat": "093011",
      "Comune": "Castelnovo del Friuli",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "C217",
      "Abitanti": 942,
      "Link": "http://www.comuni-italiani.it/093/011/"
    },
    {
      "Istat": "035015",
      "Comune": "Castelnovo di Sotto",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42024,
      "CodFisco": "C218",
      "Abitanti": 8673,
      "Link": "http://www.comuni-italiani.it/035/015/"
    },
    {
      "Istat": "035016",
      "Comune": "Castelnovo ne' Monti",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42035,
      "CodFisco": "C219",
      "Abitanti": 10761,
      "Link": "http://www.comuni-italiani.it/035/016/"
    },
    {
      "Istat": "022049",
      "Comune": "Castelnuovo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "C216",
      "Abitanti": 1022,
      "Link": "http://www.comuni-italiani.it/022/049/"
    },
    {
      "Istat": "005029",
      "Comune": "Castelnuovo Belbo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14043,
      "CodFisco": "C226",
      "Abitanti": 913,
      "Link": "http://www.comuni-italiani.it/005/029/"
    },
    {
      "Istat": "052006",
      "Comune": "Castelnuovo Berardenga",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53019,
      "CodFisco": "C227",
      "Abitanti": 9133,
      "Link": "http://www.comuni-italiani.it/052/006/"
    },
    {
      "Istat": "098013",
      "Comune": "Castelnuovo Bocca d'Adda",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26843,
      "CodFisco": "C228",
      "Abitanti": 1698,
      "Link": "http://www.comuni-italiani.it/098/013/"
    },
    {
      "Istat": "006052",
      "Comune": "Castelnuovo Bormida",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15017,
      "CodFisco": "C229",
      "Abitanti": 690,
      "Link": "http://www.comuni-italiani.it/006/052/"
    },
    {
      "Istat": "013059",
      "Comune": "Castelnuovo Bozzente",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "C220",
      "Abitanti": 864,
      "Link": "http://www.comuni-italiani.it/013/059/"
    },
    {
      "Istat": "005030",
      "Comune": "Castelnuovo Calcea",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "C230",
      "Abitanti": 783,
      "Link": "http://www.comuni-italiani.it/005/030/"
    },
    {
      "Istat": "065032",
      "Comune": "Castelnuovo Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "C231",
      "Abitanti": 2614,
      "Link": "http://www.comuni-italiani.it/065/032/"
    },
    {
      "Istat": "023022",
      "Comune": "Castelnuovo del Garda",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37014,
      "CodFisco": "C225",
      "Abitanti": 12606,
      "Link": "http://www.comuni-italiani.it/023/022/"
    },
    {
      "Istat": "071017",
      "Comune": "Castelnuovo della Daunia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71034,
      "CodFisco": "C222",
      "Abitanti": 1578,
      "Link": "http://www.comuni-italiani.it/071/017/"
    },
    {
      "Istat": "004054",
      "Comune": "Castelnuovo di Ceva",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "C214",
      "Abitanti": 138,
      "Link": "http://www.comuni-italiani.it/004/054/"
    },
    {
      "Istat": "065033",
      "Comune": "Castelnuovo di Conza",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "C235",
      "Abitanti": 662,
      "Link": "http://www.comuni-italiani.it/065/033/"
    },
    {
      "Istat": "057014",
      "Comune": "Castelnuovo di Farfa",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02031",
      "CodFisco": "C224",
      "Abitanti": 1072,
      "Link": "http://www.comuni-italiani.it/057/014/"
    },
    {
      "Istat": "046009",
      "Comune": "Castelnuovo di Garfagnana",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55032,
      "CodFisco": "C236",
      "Abitanti": 6117,
      "Link": "http://www.comuni-italiani.it/046/009/"
    },
    {
      "Istat": "058024",
      "Comune": "Castelnuovo di Porto",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "C237",
      "Abitanti": 8886,
      "Link": "http://www.comuni-italiani.it/058/024/"
    },
    {
      "Istat": "050011",
      "Comune": "Castelnuovo di Val di Cecina",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0588",
      "CAP": 56041,
      "CodFisco": "C244",
      "Abitanti": 2341,
      "Link": "http://www.comuni-italiani.it/050/011/"
    },
    {
      "Istat": "005031",
      "Comune": "Castelnuovo Don Bosco",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 14022,
      "CodFisco": "C232",
      "Abitanti": 3238,
      "Link": "http://www.comuni-italiani.it/005/031/"
    },
    {
      "Istat": "011011",
      "Comune": "Castelnuovo Magra",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19033,
      "CodFisco": "C240",
      "Abitanti": 8256,
      "Link": "http://www.comuni-italiani.it/011/011/"
    },
    {
      "Istat": "001067",
      "Comune": "Castelnuovo Nigra",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "C241",
      "Abitanti": 415,
      "Link": "http://www.comuni-italiani.it/001/067/"
    },
    {
      "Istat": "060021",
      "Comune": "Castelnuovo Parano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "C223",
      "Abitanti": 894,
      "Link": "http://www.comuni-italiani.it/060/021/"
    },
    {
      "Istat": "036007",
      "Comune": "Castelnuovo Rangone",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41051,
      "CodFisco": "C242",
      "Abitanti": 14324,
      "Link": "http://www.comuni-italiani.it/036/007/"
    },
    {
      "Istat": "006053",
      "Comune": "Castelnuovo Scrivia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15053,
      "CodFisco": "C243",
      "Abitanti": 5473,
      "Link": "http://www.comuni-italiani.it/006/053/"
    },
    {
      "Istat": "062017",
      "Comune": "Castelpagano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82024,
      "CodFisco": "C245",
      "Abitanti": 1572,
      "Link": "http://www.comuni-italiani.it/062/017/"
    },
    {
      "Istat": "094010",
      "Comune": "Castelpetroso",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86090,
      "CodFisco": "C246",
      "Abitanti": 1649,
      "Link": "http://www.comuni-italiani.it/094/010/"
    },
    {
      "Istat": "094011",
      "Comune": "Castelpizzuto",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86090,
      "CodFisco": "C247",
      "Abitanti": 161,
      "Link": "http://www.comuni-italiani.it/094/011/"
    },
    {
      "Istat": "042012",
      "Comune": "Castelplanio",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60031,
      "CodFisco": "C248",
      "Abitanti": 3552,
      "Link": "http://www.comuni-italiani.it/042/012/"
    },
    {
      "Istat": "062018",
      "Comune": "Castelpoto",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "C250",
      "Abitanti": 1370,
      "Link": "http://www.comuni-italiani.it/062/018/"
    },
    {
      "Istat": "043009",
      "Comune": "Castelraimondo",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62022,
      "CodFisco": "C251",
      "Abitanti": 4899,
      "Link": "http://www.comuni-italiani.it/043/009/"
    },
    {
      "Istat": "021019",
      "Comune": "Castelrotto",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "C254",
      "Abitanti": 6464,
      "Link": "http://www.comuni-italiani.it/021/019/"
    },
    {
      "Istat": "043010",
      "Comune": "Castelsantangelo sul Nera",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62039,
      "CodFisco": "C267",
      "Abitanti": 318,
      "Link": "http://www.comuni-italiani.it/043/010/"
    },
    {
      "Istat": "076025",
      "Comune": "Castelsaraceno",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85031,
      "CodFisco": "C271",
      "Abitanti": 1497,
      "Link": "http://www.comuni-italiani.it/076/025/"
    },
    {
      "Istat": "090023",
      "Comune": "Castelsardo",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07031",
      "CodFisco": "C272",
      "Abitanti": 5881,
      "Link": "http://www.comuni-italiani.it/090/023/"
    },
    {
      "Istat": "012044",
      "Comune": "Castelseprio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21050,
      "CodFisco": "C273",
      "Abitanti": 1296,
      "Link": "http://www.comuni-italiani.it/012/044/"
    },
    {
      "Istat": 101005,
      "Comune": "Castelsilano",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 88834,
      "CodFisco": "B968",
      "Abitanti": 1092,
      "Link": "http://www.comuni-italiani.it/101/005/"
    },
    {
      "Istat": "006054",
      "Comune": "Castelspina",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15070,
      "CodFisco": "C274",
      "Abitanti": 425,
      "Link": "http://www.comuni-italiani.it/006/054/"
    },
    {
      "Istat": "084012",
      "Comune": "Casteltermini",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92025,
      "CodFisco": "C275",
      "Abitanti": 8487,
      "Link": "http://www.comuni-italiani.it/084/012/"
    },
    {
      "Istat": "012045",
      "Comune": "Castelveccana",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "C181",
      "Abitanti": 2032,
      "Link": "http://www.comuni-italiani.it/012/045/"
    },
    {
      "Istat": "066030",
      "Comune": "Castelvecchio Calvisio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "C278",
      "Abitanti": 184,
      "Link": "http://www.comuni-italiani.it/066/030/"
    },
    {
      "Istat": "009021",
      "Comune": "Castelvecchio di Rocca Barbena",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17034,
      "CodFisco": "C276",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/009/021/"
    },
    {
      "Istat": "066031",
      "Comune": "Castelvecchio Subequo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67024,
      "CodFisco": "C279",
      "Abitanti": 1084,
      "Link": "http://www.comuni-italiani.it/066/031/"
    },
    {
      "Istat": "062019",
      "Comune": "Castelvenere",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82037,
      "CodFisco": "C280",
      "Abitanti": 2562,
      "Link": "http://www.comuni-italiani.it/062/019/"
    },
    {
      "Istat": "019026",
      "Comune": "Castelverde",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26022,
      "CodFisco": "B129",
      "Abitanti": 5664,
      "Link": "http://www.comuni-italiani.it/019/026/"
    },
    {
      "Istat": "094013",
      "Comune": "Castelverrino",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "C200",
      "Abitanti": 138,
      "Link": "http://www.comuni-italiani.it/094/013/"
    },
    {
      "Istat": "062020",
      "Comune": "Castelvetere in Val Fortore",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82023,
      "CodFisco": "C284",
      "Abitanti": 1442,
      "Link": "http://www.comuni-italiani.it/062/020/"
    },
    {
      "Istat": "064024",
      "Comune": "Castelvetere sul Calore",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "C283",
      "Abitanti": 1707,
      "Link": "http://www.comuni-italiani.it/064/024/"
    },
    {
      "Istat": "081006",
      "Comune": "Castelvetrano",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91022,
      "CodFisco": "C286",
      "Abitanti": 30735,
      "Link": "http://www.comuni-italiani.it/081/006/"
    },
    {
      "Istat": "036008",
      "Comune": "Castelvetro di Modena",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41014,
      "CodFisco": "C287",
      "Abitanti": 11165,
      "Link": "http://www.comuni-italiani.it/036/008/"
    },
    {
      "Istat": "033014",
      "Comune": "Castelvetro Piacentino",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "C288",
      "Abitanti": 5581,
      "Link": "http://www.comuni-italiani.it/033/014/"
    },
    {
      "Istat": "019027",
      "Comune": "Castelvisconti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26010,
      "CodFisco": "C290",
      "Abitanti": 333,
      "Link": "http://www.comuni-italiani.it/019/027/"
    },
    {
      "Istat": "037021",
      "Comune": "Castenaso",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40055,
      "CodFisco": "C292",
      "Abitanti": 14317,
      "Link": "http://www.comuni-italiani.it/037/021/"
    },
    {
      "Istat": "017043",
      "Comune": "Castenedolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25014,
      "CodFisco": "C293",
      "Abitanti": 11215,
      "Link": "http://www.comuni-italiani.it/017/043/"
    },
    {
      "Istat": "092106",
      "Comune": "Castiadas",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "M288",
      "Abitanti": 1507,
      "Link": "http://www.comuni-italiani.it/092/106/"
    },
    {
      "Istat": "051011",
      "Comune": "Castiglion Fibocchi",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52029,
      "CodFisco": "C318",
      "Abitanti": 2248,
      "Link": "http://www.comuni-italiani.it/051/011/"
    },
    {
      "Istat": "051012",
      "Comune": "Castiglion Fiorentino",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52043,
      "CodFisco": "C319",
      "Abitanti": 13630,
      "Link": "http://www.comuni-italiani.it/051/012/"
    },
    {
      "Istat": "068009",
      "Comune": "Castiglione a Casauria",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "C308",
      "Abitanti": 875,
      "Link": "http://www.comuni-italiani.it/068/009/"
    },
    {
      "Istat": "010013",
      "Comune": "Castiglione Chiavarese",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "C302",
      "Abitanti": 1658,
      "Link": "http://www.comuni-italiani.it/010/013/"
    },
    {
      "Istat": "078030",
      "Comune": "Castiglione Cosentino",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "C301",
      "Abitanti": 2983,
      "Link": "http://www.comuni-italiani.it/078/030/"
    },
    {
      "Istat": "098014",
      "Comune": "Castiglione d'Adda",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26823,
      "CodFisco": "C304",
      "Abitanti": 4939,
      "Link": "http://www.comuni-italiani.it/098/014/"
    },
    {
      "Istat": "037022",
      "Comune": "Castiglione dei Pepoli",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40035,
      "CodFisco": "C296",
      "Abitanti": 5964,
      "Link": "http://www.comuni-italiani.it/037/022/"
    },
    {
      "Istat": "065036",
      "Comune": "Castiglione del Genovesi",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84090,
      "CodFisco": "C306",
      "Abitanti": 1364,
      "Link": "http://www.comuni-italiani.it/065/036/"
    },
    {
      "Istat": "054009",
      "Comune": "Castiglione del Lago",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06061",
      "CodFisco": "C309",
      "Abitanti": 15618,
      "Link": "http://www.comuni-italiani.it/054/009/"
    },
    {
      "Istat": "053006",
      "Comune": "Castiglione della Pescaia",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58043,
      "CodFisco": "C310",
      "Abitanti": 7449,
      "Link": "http://www.comuni-italiani.it/053/006/"
    },
    {
      "Istat": "020017",
      "Comune": "Castiglione delle Stiviere",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46043,
      "CodFisco": "C312",
      "Abitanti": 22700,
      "Link": "http://www.comuni-italiani.it/020/017/"
    },
    {
      "Istat": "046010",
      "Comune": "Castiglione di Garfagnana",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55033,
      "CodFisco": "C303",
      "Abitanti": 1895,
      "Link": "http://www.comuni-italiani.it/046/010/"
    },
    {
      "Istat": "087014",
      "Comune": "Castiglione di Sicilia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 95012,
      "CodFisco": "C297",
      "Abitanti": 3366,
      "Link": "http://www.comuni-italiani.it/087/014/"
    },
    {
      "Istat": "013060",
      "Comune": "Castiglione d'Intelvi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22023,
      "CodFisco": "C299",
      "Abitanti": 1031,
      "Link": "http://www.comuni-italiani.it/013/060/"
    },
    {
      "Istat": "052007",
      "Comune": "Castiglione d'Orcia",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53023,
      "CodFisco": "C313",
      "Abitanti": 2485,
      "Link": "http://www.comuni-italiani.it/052/007/"
    },
    {
      "Istat": "004055",
      "Comune": "Castiglione Falletto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "C314",
      "Abitanti": 719,
      "Link": "http://www.comuni-italiani.it/004/055/"
    },
    {
      "Istat": "056018",
      "Comune": "Castiglione in Teverina",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01024",
      "CodFisco": "C315",
      "Abitanti": 2421,
      "Link": "http://www.comuni-italiani.it/056/018/"
    },
    {
      "Istat": "069020",
      "Comune": "Castiglione Messer Marino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66033,
      "CodFisco": "C298",
      "Abitanti": 1942,
      "Link": "http://www.comuni-italiani.it/069/020/"
    },
    {
      "Istat": "067013",
      "Comune": "Castiglione Messer Raimondo",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64034,
      "CodFisco": "C316",
      "Abitanti": 2397,
      "Link": "http://www.comuni-italiani.it/067/013/"
    },
    {
      "Istat": "012046",
      "Comune": "Castiglione Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21043,
      "CodFisco": "C300",
      "Abitanti": 7906,
      "Link": "http://www.comuni-italiani.it/012/046/"
    },
    {
      "Istat": "004056",
      "Comune": "Castiglione Tinella",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 12053,
      "CodFisco": "C317",
      "Abitanti": 869,
      "Link": "http://www.comuni-italiani.it/004/056/"
    },
    {
      "Istat": "001068",
      "Comune": "Castiglione Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "C307",
      "Abitanti": 6336,
      "Link": "http://www.comuni-italiani.it/001/068/"
    },
    {
      "Istat": "044012",
      "Comune": "Castignano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63072,
      "CodFisco": "C321",
      "Abitanti": 2962,
      "Link": "http://www.comuni-italiani.it/044/012/"
    },
    {
      "Istat": "067014",
      "Comune": "Castilenti",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64035,
      "CodFisco": "C322",
      "Abitanti": 1585,
      "Link": "http://www.comuni-italiani.it/067/014/"
    },
    {
      "Istat": "004057",
      "Comune": "Castino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "C323",
      "Abitanti": 517,
      "Link": "http://www.comuni-italiani.it/004/057/"
    },
    {
      "Istat": "014015",
      "Comune": "Castione Andevenno",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23012,
      "CodFisco": "C325",
      "Abitanti": 1554,
      "Link": "http://www.comuni-italiani.it/014/015/"
    },
    {
      "Istat": "016064",
      "Comune": "Castione della Presolana",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "C324",
      "Abitanti": 3476,
      "Link": "http://www.comuni-italiani.it/016/064/"
    },
    {
      "Istat": "030020",
      "Comune": "Castions di Strada",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "C327",
      "Abitanti": 3904,
      "Link": "http://www.comuni-italiani.it/030/020/"
    },
    {
      "Istat": "098015",
      "Comune": "Castiraga Vidardo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26866,
      "CodFisco": "C329",
      "Abitanti": 2623,
      "Link": "http://www.comuni-italiani.it/098/015/"
    },
    {
      "Istat": "017044",
      "Comune": "Casto",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "C330",
      "Abitanti": 1900,
      "Link": "http://www.comuni-italiani.it/017/044/"
    },
    {
      "Istat": "044013",
      "Comune": "Castorano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63081,
      "CodFisco": "C331",
      "Abitanti": 2349,
      "Link": "http://www.comuni-italiani.it/044/013/"
    },
    {
      "Istat": "017045",
      "Comune": "Castrezzato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "C332",
      "Abitanti": 7018,
      "Link": "http://www.comuni-italiani.it/017/045/"
    },
    {
      "Istat": "075017",
      "Comune": "Castri di Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73020,
      "CodFisco": "C334",
      "Abitanti": 3030,
      "Link": "http://www.comuni-italiani.it/075/017/"
    },
    {
      "Istat": "075018",
      "Comune": "Castrignano de' Greci",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "C335",
      "Abitanti": 4144,
      "Link": "http://www.comuni-italiani.it/075/018/"
    },
    {
      "Istat": "075019",
      "Comune": "Castrignano del Capo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "C336",
      "Abitanti": 5422,
      "Link": "http://www.comuni-italiani.it/075/019/"
    },
    {
      "Istat": "016065",
      "Comune": "Castro",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24063,
      "CodFisco": "C337",
      "Abitanti": 1438,
      "Link": "http://www.comuni-italiani.it/016/065/"
    },
    {
      "Istat": "075096",
      "Comune": "Castro",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73030,
      "CodFisco": "M261",
      "Abitanti": 2495,
      "Link": "http://www.comuni-italiani.it/075/096/"
    },
    {
      "Istat": "060023",
      "Comune": "Castro dei Volsci",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "C338",
      "Abitanti": 4977,
      "Link": "http://www.comuni-italiani.it/060/023/"
    },
    {
      "Istat": "040005",
      "Comune": "Castrocaro Terme e Terra del Sole",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47011,
      "CodFisco": "C339",
      "Abitanti": 6600,
      "Link": "http://www.comuni-italiani.it/040/005/"
    },
    {
      "Istat": "060022",
      "Comune": "Castrocielo",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "C340",
      "Abitanti": 4014,
      "Link": "http://www.comuni-italiani.it/060/022/"
    },
    {
      "Istat": "084013",
      "Comune": "Castrofilippo",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "C341",
      "Abitanti": 3034,
      "Link": "http://www.comuni-italiani.it/084/013/"
    },
    {
      "Istat": "078031",
      "Comune": "Castrolibero",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "C108",
      "Abitanti": 10323,
      "Link": "http://www.comuni-italiani.it/078/031/"
    },
    {
      "Istat": "012047",
      "Comune": "Castronno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21040,
      "CodFisco": "C343",
      "Abitanti": 5361,
      "Link": "http://www.comuni-italiani.it/012/047/"
    },
    {
      "Istat": "082025",
      "Comune": "Castronovo di Sicilia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "C344",
      "Abitanti": 3213,
      "Link": "http://www.comuni-italiani.it/082/025/"
    },
    {
      "Istat": "076026",
      "Comune": "Castronuovo di Sant'Andrea",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "C345",
      "Abitanti": 1189,
      "Link": "http://www.comuni-italiani.it/076/026/"
    },
    {
      "Istat": "070016",
      "Comune": "Castropignano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "C346",
      "Abitanti": 1044,
      "Link": "http://www.comuni-italiani.it/070/016/"
    },
    {
      "Istat": "083016",
      "Comune": "Castroreale",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98053,
      "CodFisco": "C347",
      "Abitanti": 2654,
      "Link": "http://www.comuni-italiani.it/083/016/"
    },
    {
      "Istat": "078032",
      "Comune": "Castroregio",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "C348",
      "Abitanti": 360,
      "Link": "http://www.comuni-italiani.it/078/032/"
    },
    {
      "Istat": "078033",
      "Comune": "Castrovillari",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87012,
      "CodFisco": "C349",
      "Abitanti": 22561,
      "Link": "http://www.comuni-italiani.it/078/033/"
    },
    {
      "Istat": "087015",
      "Comune": "Catania",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": "951xx",
      "CodFisco": "C351",
      "Abitanti": 293458,
      "Link": "http://www.comuni-italiani.it/087/015/"
    },
    {
      "Istat": "079023",
      "Comune": "Catanzaro",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88100,
      "CodFisco": "C352",
      "Abitanti": 93124,
      "Link": "http://www.comuni-italiani.it/079/023/"
    },
    {
      "Istat": "086006",
      "Comune": "Catenanuova",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "C353",
      "Abitanti": 5079,
      "Link": "http://www.comuni-italiani.it/086/006/"
    },
    {
      "Istat": "068010",
      "Comune": "Catignano",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65011,
      "CodFisco": "C354",
      "Abitanti": 1480,
      "Link": "http://www.comuni-italiani.it/068/010/"
    },
    {
      "Istat": "099002",
      "Comune": "Cattolica",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47841,
      "CodFisco": "C357",
      "Abitanti": 16899,
      "Link": "http://www.comuni-italiani.it/099/002/"
    },
    {
      "Istat": "084014",
      "Comune": "Cattolica Eraclea",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92011,
      "CodFisco": "C356",
      "Abitanti": 3994,
      "Link": "http://www.comuni-italiani.it/084/014/"
    },
    {
      "Istat": "080025",
      "Comune": "Caulonia",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89041,
      "CodFisco": "C285",
      "Abitanti": 7407,
      "Link": "http://www.comuni-italiani.it/080/025/"
    },
    {
      "Istat": "062021",
      "Comune": "Cautano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "C359",
      "Abitanti": 2116,
      "Link": "http://www.comuni-italiani.it/062/021/"
    },
    {
      "Istat": "065037",
      "Comune": "Cava de' Tirreni",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84013,
      "CodFisco": "C361",
      "Abitanti": 53520,
      "Link": "http://www.comuni-italiani.it/065/037/"
    },
    {
      "Istat": "018041",
      "Comune": "Cava Manara",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27051,
      "CodFisco": "C360",
      "Abitanti": 6650,
      "Link": "http://www.comuni-italiani.it/018/041/"
    },
    {
      "Istat": "098016",
      "Comune": "Cavacurta",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26844,
      "CodFisco": "C362",
      "Abitanti": 876,
      "Link": "http://www.comuni-italiani.it/098/016/"
    },
    {
      "Istat": "096016",
      "Comune": "Cavagli‡",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13881,
      "CodFisco": "C363",
      "Abitanti": 3623,
      "Link": "http://www.comuni-italiani.it/096/016/"
    },
    {
      "Istat": "003044",
      "Comune": "Cavaglietto",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "C364",
      "Abitanti": 415,
      "Link": "http://www.comuni-italiani.it/003/044/"
    },
    {
      "Istat": "003045",
      "Comune": "Cavaglio d'Agogna",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "C365",
      "Abitanti": 1282,
      "Link": "http://www.comuni-italiani.it/003/045/"
    },
    {
      "Istat": 103020,
      "Comune": "Cavaglio-Spoccia",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28825,
      "CodFisco": "C367",
      "Abitanti": 271,
      "Link": "http://www.comuni-italiani.it/103/020/"
    },
    {
      "Istat": "001069",
      "Comune": "Cavagnolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "C369",
      "Abitanti": 2383,
      "Link": "http://www.comuni-italiani.it/001/069/"
    },
    {
      "Istat": "023023",
      "Comune": "Cavaion Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "C370",
      "Abitanti": 5443,
      "Link": "http://www.comuni-italiani.it/023/023/"
    },
    {
      "Istat": "022050",
      "Comune": "Cavalese",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38033,
      "CodFisco": "C372",
      "Abitanti": 4014,
      "Link": "http://www.comuni-italiani.it/022/050/"
    },
    {
      "Istat": "013061",
      "Comune": "Cavallasca",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "C374",
      "Abitanti": 2971,
      "Link": "http://www.comuni-italiani.it/013/061/"
    },
    {
      "Istat": "004058",
      "Comune": "Cavallerleone",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "C375",
      "Abitanti": 646,
      "Link": "http://www.comuni-italiani.it/004/058/"
    },
    {
      "Istat": "004059",
      "Comune": "Cavallermaggiore",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "C376",
      "Abitanti": 5501,
      "Link": "http://www.comuni-italiani.it/004/059/"
    },
    {
      "Istat": "075020",
      "Comune": "Cavallino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73020,
      "CodFisco": "C377",
      "Abitanti": 12428,
      "Link": "http://www.comuni-italiani.it/075/020/"
    },
    {
      "Istat": "027044",
      "Comune": "Cavallino-Treporti",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30013,
      "CodFisco": "M308",
      "Abitanti": 13493,
      "Link": "http://www.comuni-italiani.it/027/044/"
    },
    {
      "Istat": "003047",
      "Comune": "Cavallirio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 28010,
      "CodFisco": "C378",
      "Abitanti": 1271,
      "Link": "http://www.comuni-italiani.it/003/047/"
    },
    {
      "Istat": "022051",
      "Comune": "Cavareno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38011,
      "CodFisco": "C380",
      "Abitanti": 1048,
      "Link": "http://www.comuni-italiani.it/022/051/"
    },
    {
      "Istat": "013062",
      "Comune": "Cavargna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "C381",
      "Abitanti": 248,
      "Link": "http://www.comuni-italiani.it/013/062/"
    },
    {
      "Istat": "012048",
      "Comune": "Cavaria con Premezzo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21044,
      "CodFisco": "C382",
      "Abitanti": 5718,
      "Link": "http://www.comuni-italiani.it/012/048/"
    },
    {
      "Istat": "027006",
      "Comune": "Cavarzere",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 30014,
      "CodFisco": "C383",
      "Abitanti": 14974,
      "Link": "http://www.comuni-italiani.it/027/006/"
    },
    {
      "Istat": "026014",
      "Comune": "Cavaso del Tomba",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31034,
      "CodFisco": "C384",
      "Abitanti": 3011,
      "Link": "http://www.comuni-italiani.it/026/014/"
    },
    {
      "Istat": "093012",
      "Comune": "Cavasso Nuovo",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33092,
      "CodFisco": "C385",
      "Abitanti": 1637,
      "Link": "http://www.comuni-italiani.it/093/012/"
    },
    {
      "Istat": "006055",
      "Comune": "Cavatore",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "C387",
      "Abitanti": 315,
      "Link": "http://www.comuni-italiani.it/006/055/"
    },
    {
      "Istat": "030021",
      "Comune": "Cavazzo Carnico",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "C389",
      "Abitanti": 1102,
      "Link": "http://www.comuni-italiani.it/030/021/"
    },
    {
      "Istat": "058026",
      "Comune": "Cave",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00033",
      "CodFisco": "C390",
      "Abitanti": 10924,
      "Link": "http://www.comuni-italiani.it/058/026/"
    },
    {
      "Istat": "022052",
      "Comune": "Cavedago",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "C392",
      "Abitanti": 535,
      "Link": "http://www.comuni-italiani.it/022/052/"
    },
    {
      "Istat": "022053",
      "Comune": "Cavedine",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38073,
      "CodFisco": "C393",
      "Abitanti": 2935,
      "Link": "http://www.comuni-italiani.it/022/053/"
    },
    {
      "Istat": "098017",
      "Comune": "Cavenago d'Adda",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26824,
      "CodFisco": "C394",
      "Abitanti": 2294,
      "Link": "http://www.comuni-italiani.it/098/017/"
    },
    {
      "Istat": 108017,
      "Comune": "Cavenago di Brianza",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20873,
      "CodFisco": "C395",
      "Abitanti": 6807,
      "Link": "http://www.comuni-italiani.it/108/017/"
    },
    {
      "Istat": "016066",
      "Comune": "Cavernago",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "C396",
      "Abitanti": 2450,
      "Link": "http://www.comuni-italiani.it/016/066/"
    },
    {
      "Istat": "036009",
      "Comune": "Cavezzo",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41032,
      "CodFisco": "C398",
      "Abitanti": 7390,
      "Link": "http://www.comuni-italiani.it/036/009/"
    },
    {
      "Istat": "022054",
      "Comune": "Cavizzana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38022,
      "CodFisco": "C400",
      "Abitanti": 260,
      "Link": "http://www.comuni-italiani.it/022/054/"
    },
    {
      "Istat": "001070",
      "Comune": "Cavour",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10061,
      "CodFisco": "C404",
      "Abitanti": 5642,
      "Link": "http://www.comuni-italiani.it/001/070/"
    },
    {
      "Istat": "035017",
      "Comune": "Cavriago",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42025,
      "CodFisco": "C405",
      "Abitanti": 9750,
      "Link": "http://www.comuni-italiani.it/035/017/"
    },
    {
      "Istat": "020018",
      "Comune": "Cavriana",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "C406",
      "Abitanti": 3913,
      "Link": "http://www.comuni-italiani.it/020/018/"
    },
    {
      "Istat": "051013",
      "Comune": "Cavriglia",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52022,
      "CodFisco": "C407",
      "Abitanti": 9522,
      "Link": "http://www.comuni-italiani.it/051/013/"
    },
    {
      "Istat": "012049",
      "Comune": "Cazzago Brabbia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "C409",
      "Abitanti": 820,
      "Link": "http://www.comuni-italiani.it/012/049/"
    },
    {
      "Istat": "017046",
      "Comune": "Cazzago San Martino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25046,
      "CodFisco": "C408",
      "Abitanti": 11060,
      "Link": "http://www.comuni-italiani.it/017/046/"
    },
    {
      "Istat": "023024",
      "Comune": "Cazzano di Tramigna",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "C412",
      "Abitanti": 1549,
      "Link": "http://www.comuni-italiani.it/023/024/"
    },
    {
      "Istat": "016067",
      "Comune": "Cazzano Sant'Andrea",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24026,
      "CodFisco": "C410",
      "Abitanti": 1604,
      "Link": "http://www.comuni-italiani.it/016/067/"
    },
    {
      "Istat": "060024",
      "Comune": "Ceccano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03023",
      "CodFisco": "C413",
      "Abitanti": 23003,
      "Link": "http://www.comuni-italiani.it/060/024/"
    },
    {
      "Istat": "018042",
      "Comune": "Cecima",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "C414",
      "Abitanti": 237,
      "Link": "http://www.comuni-italiani.it/018/042/"
    },
    {
      "Istat": "049007",
      "Comune": "Cecina",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 57023,
      "CodFisco": "C415",
      "Abitanti": 28573,
      "Link": "http://www.comuni-italiani.it/049/007/"
    },
    {
      "Istat": "017047",
      "Comune": "Cedegolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25051,
      "CodFisco": "C417",
      "Abitanti": 1256,
      "Link": "http://www.comuni-italiani.it/017/047/"
    },
    {
      "Istat": "014016",
      "Comune": "Cedrasco",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "C418",
      "Abitanti": 471,
      "Link": "http://www.comuni-italiani.it/014/016/"
    },
    {
      "Istat": "082026",
      "Comune": "Cefal‡ Diana",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "C420",
      "Abitanti": 1014,
      "Link": "http://www.comuni-italiani.it/082/026/"
    },
    {
      "Istat": "082027",
      "Comune": "Cefal˘",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90015,
      "CodFisco": "C421",
      "Abitanti": 13807,
      "Link": "http://www.comuni-italiani.it/082/027/"
    },
    {
      "Istat": "027007",
      "Comune": "Ceggia",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30022,
      "CodFisco": "C422",
      "Abitanti": 6266,
      "Link": "http://www.comuni-italiani.it/027/007/"
    },
    {
      "Istat": "074003",
      "Comune": "Ceglie Messapica",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72013,
      "CodFisco": "C424",
      "Abitanti": 20690,
      "Link": "http://www.comuni-italiani.it/074/003/"
    },
    {
      "Istat": "066032",
      "Comune": "Celano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67043,
      "CodFisco": "C426",
      "Abitanti": 11184,
      "Link": "http://www.comuni-italiani.it/066/032/"
    },
    {
      "Istat": "069021",
      "Comune": "Celenza sul Trigno",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "C428",
      "Abitanti": 985,
      "Link": "http://www.comuni-italiani.it/069/021/"
    },
    {
      "Istat": "071018",
      "Comune": "Celenza Valfortore",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71035,
      "CodFisco": "C429",
      "Abitanti": 1741,
      "Link": "http://www.comuni-italiani.it/071/018/"
    },
    {
      "Istat": "078034",
      "Comune": "Celico",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87053,
      "CodFisco": "C430",
      "Abitanti": 3046,
      "Link": "http://www.comuni-italiani.it/078/034/"
    },
    {
      "Istat": "019028",
      "Comune": "Cella Dati",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26040,
      "CodFisco": "C435",
      "Abitanti": 558,
      "Link": "http://www.comuni-italiani.it/019/028/"
    },
    {
      "Istat": "006056",
      "Comune": "Cella Monte",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15034,
      "CodFisco": "C432",
      "Abitanti": 527,
      "Link": "http://www.comuni-italiani.it/006/056/"
    },
    {
      "Istat": "072018",
      "Comune": "Cellamare",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "C436",
      "Abitanti": 5812,
      "Link": "http://www.comuni-italiani.it/072/018/"
    },
    {
      "Istat": "078035",
      "Comune": "Cellara",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "C437",
      "Abitanti": 508,
      "Link": "http://www.comuni-italiani.it/078/035/"
    },
    {
      "Istat": "005033",
      "Comune": "Cellarengo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "C438",
      "Abitanti": 721,
      "Link": "http://www.comuni-italiani.it/005/033/"
    },
    {
      "Istat": "017048",
      "Comune": "Cellatica",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "C439",
      "Abitanti": 4943,
      "Link": "http://www.comuni-italiani.it/017/048/"
    },
    {
      "Istat": "065038",
      "Comune": "Celle di Bulgheria",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84040,
      "CodFisco": "C444",
      "Abitanti": 1934,
      "Link": "http://www.comuni-italiani.it/065/038/"
    },
    {
      "Istat": "004060",
      "Comune": "Celle di Macra",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "C441",
      "Abitanti": 102,
      "Link": "http://www.comuni-italiani.it/004/060/"
    },
    {
      "Istat": "071019",
      "Comune": "Celle di San Vito",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71020,
      "CodFisco": "C442",
      "Abitanti": 173,
      "Link": "http://www.comuni-italiani.it/071/019/"
    },
    {
      "Istat": "005034",
      "Comune": "Celle Enomondo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "C440",
      "Abitanti": 481,
      "Link": "http://www.comuni-italiani.it/005/034/"
    },
    {
      "Istat": "009022",
      "Comune": "Celle Ligure",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17015,
      "CodFisco": "C443",
      "Abitanti": 5431,
      "Link": "http://www.comuni-italiani.it/009/022/"
    },
    {
      "Istat": "056019",
      "Comune": "Celleno",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01020",
      "CodFisco": "C446",
      "Abitanti": 1362,
      "Link": "http://www.comuni-italiani.it/056/019/"
    },
    {
      "Istat": "056020",
      "Comune": "Cellere",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "C447",
      "Abitanti": 1280,
      "Link": "http://www.comuni-italiani.it/056/020/"
    },
    {
      "Istat": "067015",
      "Comune": "Cellino Attanasio",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64036,
      "CodFisco": "C449",
      "Abitanti": 2659,
      "Link": "http://www.comuni-italiani.it/067/015/"
    },
    {
      "Istat": "074004",
      "Comune": "Cellino San Marco",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72020,
      "CodFisco": "C448",
      "Abitanti": 6755,
      "Link": "http://www.comuni-italiani.it/074/004/"
    },
    {
      "Istat": "002038",
      "Comune": "Cellio",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13024,
      "CodFisco": "C450",
      "Abitanti": 863,
      "Link": "http://www.comuni-italiani.it/002/038/"
    },
    {
      "Istat": "061102",
      "Comune": "Cellole",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81030,
      "CodFisco": "M262",
      "Abitanti": 7873,
      "Link": "http://www.comuni-italiani.it/061/102/"
    },
    {
      "Istat": "022055",
      "Comune": "Cembra",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38034,
      "CodFisco": "C452",
      "Abitanti": 1856,
      "Link": "http://www.comuni-italiani.it/022/055/"
    },
    {
      "Istat": "079024",
      "Comune": "Cenadi",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88067,
      "CodFisco": "C453",
      "Abitanti": 593,
      "Link": "http://www.comuni-italiani.it/079/024/"
    },
    {
      "Istat": "016068",
      "Comune": "Cenate Sopra",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "C456",
      "Abitanti": 2524,
      "Link": "http://www.comuni-italiani.it/016/068/"
    },
    {
      "Istat": "016069",
      "Comune": "Cenate Sotto",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24069,
      "CodFisco": "C457",
      "Abitanti": 3463,
      "Link": "http://www.comuni-italiani.it/016/069/"
    },
    {
      "Istat": "025010",
      "Comune": "Cencenighe Agordino",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "C458",
      "Abitanti": 1418,
      "Link": "http://www.comuni-italiani.it/025/010/"
    },
    {
      "Istat": "016070",
      "Comune": "Cene",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "C459",
      "Abitanti": 4225,
      "Link": "http://www.comuni-italiani.it/016/070/"
    },
    {
      "Istat": "029014",
      "Comune": "Ceneselli",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "C461",
      "Abitanti": 1875,
      "Link": "http://www.comuni-italiani.it/029/014/"
    },
    {
      "Istat": "009023",
      "Comune": "Cengio",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17056,
      "CodFisco": "C463",
      "Abitanti": 3749,
      "Link": "http://www.comuni-italiani.it/009/023/"
    },
    {
      "Istat": "022056",
      "Comune": "Centa San NicolÚ",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38040,
      "CodFisco": "C467",
      "Abitanti": 626,
      "Link": "http://www.comuni-italiani.it/022/056/"
    },
    {
      "Istat": "004061",
      "Comune": "Centallo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12044,
      "CodFisco": "C466",
      "Abitanti": 6733,
      "Link": "http://www.comuni-italiani.it/004/061/"
    },
    {
      "Istat": "038004",
      "Comune": "Cento",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 44042,
      "CodFisco": "C469",
      "Abitanti": 35582,
      "Link": "http://www.comuni-italiani.it/038/004/"
    },
    {
      "Istat": "065039",
      "Comune": "Centola",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84051,
      "CodFisco": "C470",
      "Abitanti": 5004,
      "Link": "http://www.comuni-italiani.it/065/039/"
    },
    {
      "Istat": "079025",
      "Comune": "Centrache",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88067,
      "CodFisco": "C472",
      "Abitanti": 411,
      "Link": "http://www.comuni-italiani.it/079/025/"
    },
    {
      "Istat": "086007",
      "Comune": "Centuripe",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "C471",
      "Abitanti": 5645,
      "Link": "http://www.comuni-italiani.it/086/007/"
    },
    {
      "Istat": "068011",
      "Comune": "Cepagatti",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65012,
      "CodFisco": "C474",
      "Abitanti": 10536,
      "Link": "http://www.comuni-italiani.it/068/011/"
    },
    {
      "Istat": "062022",
      "Comune": "Ceppaloni",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82010,
      "CodFisco": "C476",
      "Abitanti": 3375,
      "Link": "http://www.comuni-italiani.it/062/022/"
    },
    {
      "Istat": 103021,
      "Comune": "Ceppo Morelli",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28875,
      "CodFisco": "C478",
      "Abitanti": 347,
      "Link": "http://www.comuni-italiani.it/103/021/"
    },
    {
      "Istat": "060025",
      "Comune": "Ceprano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03024",
      "CodFisco": "C479",
      "Abitanti": 8610,
      "Link": "http://www.comuni-italiani.it/060/025/"
    },
    {
      "Istat": "086008",
      "Comune": "Cerami",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "C480",
      "Abitanti": 2196,
      "Link": "http://www.comuni-italiani.it/086/008/"
    },
    {
      "Istat": "010014",
      "Comune": "Ceranesi",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16014,
      "CodFisco": "C481",
      "Abitanti": 4035,
      "Link": "http://www.comuni-italiani.it/010/014/"
    },
    {
      "Istat": "003049",
      "Comune": "Cerano",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28065,
      "CodFisco": "C483",
      "Abitanti": 6980,
      "Link": "http://www.comuni-italiani.it/003/049/"
    },
    {
      "Istat": "013063",
      "Comune": "Cerano d'Intelvi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "C482",
      "Abitanti": 550,
      "Link": "http://www.comuni-italiani.it/013/063/"
    },
    {
      "Istat": "018043",
      "Comune": "Ceranova",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "C484",
      "Abitanti": 1834,
      "Link": "http://www.comuni-italiani.it/018/043/"
    },
    {
      "Istat": "065040",
      "Comune": "Ceraso",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84052,
      "CodFisco": "C485",
      "Abitanti": 2532,
      "Link": "http://www.comuni-italiani.it/065/040/"
    },
    {
      "Istat": "070017",
      "Comune": "Cercemaggiore",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86012,
      "CodFisco": "C486",
      "Abitanti": 4043,
      "Link": "http://www.comuni-italiani.it/070/017/"
    },
    {
      "Istat": "001071",
      "Comune": "Cercenasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "C487",
      "Abitanti": 1864,
      "Link": "http://www.comuni-italiani.it/001/071/"
    },
    {
      "Istat": "070018",
      "Comune": "Cercepiccola",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "C488",
      "Abitanti": 697,
      "Link": "http://www.comuni-italiani.it/070/018/"
    },
    {
      "Istat": "078036",
      "Comune": "Cerchiara di Calabria",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "C489",
      "Abitanti": 2524,
      "Link": "http://www.comuni-italiani.it/078/036/"
    },
    {
      "Istat": "066033",
      "Comune": "Cerchio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67044,
      "CodFisco": "C492",
      "Abitanti": 1708,
      "Link": "http://www.comuni-italiani.it/066/033/"
    },
    {
      "Istat": "014017",
      "Comune": "Cercino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23016,
      "CodFisco": "C493",
      "Abitanti": 757,
      "Link": "http://www.comuni-italiani.it/014/017/"
    },
    {
      "Istat": "030022",
      "Comune": "Cercivento",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "C494",
      "Abitanti": 705,
      "Link": "http://www.comuni-italiani.it/030/022/"
    },
    {
      "Istat": "063026",
      "Comune": "Cercola",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "C495",
      "Abitanti": 19336,
      "Link": "http://www.comuni-italiani.it/063/026/"
    },
    {
      "Istat": "082028",
      "Comune": "Cerda",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90010,
      "CodFisco": "C496",
      "Abitanti": 5369,
      "Link": "http://www.comuni-italiani.it/082/028/"
    },
    {
      "Istat": "023025",
      "Comune": "Cerea",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37053,
      "CodFisco": "C498",
      "Abitanti": 16360,
      "Link": "http://www.comuni-italiani.it/023/025/"
    },
    {
      "Istat": "029015",
      "Comune": "Ceregnano",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45010,
      "CodFisco": "C500",
      "Abitanti": 3806,
      "Link": "http://www.comuni-italiani.it/029/015/"
    },
    {
      "Istat": 101006,
      "Comune": "Cerenzia",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 88833,
      "CodFisco": "C501",
      "Abitanti": 1255,
      "Link": "http://www.comuni-italiani.it/101/006/"
    },
    {
      "Istat": "001072",
      "Comune": "Ceres",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "C497",
      "Abitanti": 1080,
      "Link": "http://www.comuni-italiani.it/001/072/"
    },
    {
      "Istat": "020019",
      "Comune": "Ceresara",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "C502",
      "Abitanti": 2708,
      "Link": "http://www.comuni-italiani.it/020/019/"
    },
    {
      "Istat": "006057",
      "Comune": "Cereseto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "C503",
      "Abitanti": 468,
      "Link": "http://www.comuni-italiani.it/006/057/"
    },
    {
      "Istat": "004062",
      "Comune": "Ceresole Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "C504",
      "Abitanti": 2149,
      "Link": "http://www.comuni-italiani.it/004/062/"
    },
    {
      "Istat": "001073",
      "Comune": "Ceresole Reale",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "C505",
      "Abitanti": 164,
      "Link": "http://www.comuni-italiani.it/001/073/"
    },
    {
      "Istat": "016071",
      "Comune": "Cerete",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "C506",
      "Abitanti": 1669,
      "Link": "http://www.comuni-italiani.it/016/071/"
    },
    {
      "Istat": "018044",
      "Comune": "Ceretto Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "C508",
      "Abitanti": 208,
      "Link": "http://www.comuni-italiani.it/018/044/"
    },
    {
      "Istat": "018045",
      "Comune": "Cergnago",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "C509",
      "Abitanti": 769,
      "Link": "http://www.comuni-italiani.it/018/045/"
    },
    {
      "Istat": "009024",
      "Comune": "Ceriale",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17023,
      "CodFisco": "C510",
      "Abitanti": 6049,
      "Link": "http://www.comuni-italiani.it/009/024/"
    },
    {
      "Istat": "008016",
      "Comune": "Ceriana",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18034,
      "CodFisco": "C511",
      "Abitanti": 1297,
      "Link": "http://www.comuni-italiani.it/008/016/"
    },
    {
      "Istat": 108018,
      "Comune": "Ceriano Laghetto",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20816,
      "CodFisco": "C512",
      "Abitanti": 6392,
      "Link": "http://www.comuni-italiani.it/108/018/"
    },
    {
      "Istat": "033015",
      "Comune": "Cerignale",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "C513",
      "Abitanti": 170,
      "Link": "http://www.comuni-italiani.it/033/015/"
    },
    {
      "Istat": "071020",
      "Comune": "Cerignola",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71042,
      "CodFisco": "C514",
      "Abitanti": 59103,
      "Link": "http://www.comuni-italiani.it/071/020/"
    },
    {
      "Istat": "078037",
      "Comune": "Cerisano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87044,
      "CodFisco": "C515",
      "Abitanti": 3306,
      "Link": "http://www.comuni-italiani.it/078/037/"
    },
    {
      "Istat": "013064",
      "Comune": "Cermenate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22072,
      "CodFisco": "C516",
      "Abitanti": 9097,
      "Link": "http://www.comuni-italiani.it/013/064/"
    },
    {
      "Istat": "021020",
      "Comune": "Cermes",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "A022",
      "Abitanti": 1404,
      "Link": "http://www.comuni-italiani.it/021/020/"
    },
    {
      "Istat": "067016",
      "Comune": "Cermignano",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64037,
      "CodFisco": "C517",
      "Abitanti": 1794,
      "Link": "http://www.comuni-italiani.it/067/016/"
    },
    {
      "Istat": "013065",
      "Comune": "Cernobbio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22012,
      "CodFisco": "C520",
      "Abitanti": 7059,
      "Link": "http://www.comuni-italiani.it/013/065/"
    },
    {
      "Istat": "097020",
      "Comune": "Cernusco Lombardone",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23870,
      "CodFisco": "C521",
      "Abitanti": 3870,
      "Link": "http://www.comuni-italiani.it/097/020/"
    },
    {
      "Istat": "015070",
      "Comune": "Cernusco sul Naviglio",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20063,
      "CodFisco": "C523",
      "Abitanti": 31058,
      "Link": "http://www.comuni-italiani.it/015/070/"
    },
    {
      "Istat": "096017",
      "Comune": "Cerreto Castello",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13852,
      "CodFisco": "C526",
      "Abitanti": 640,
      "Link": "http://www.comuni-italiani.it/096/017/"
    },
    {
      "Istat": "005035",
      "Comune": "Cerreto d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "C528",
      "Abitanti": 216,
      "Link": "http://www.comuni-italiani.it/005/035/"
    },
    {
      "Istat": "042013",
      "Comune": "Cerreto d'Esi",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0732",
      "CAP": 60043,
      "CodFisco": "C524",
      "Abitanti": 4009,
      "Link": "http://www.comuni-italiani.it/042/013/"
    },
    {
      "Istat": "054010",
      "Comune": "Cerreto di Spoleto",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06041",
      "CodFisco": "C527",
      "Abitanti": 1149,
      "Link": "http://www.comuni-italiani.it/054/010/"
    },
    {
      "Istat": "006058",
      "Comune": "Cerreto Grue",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "C507",
      "Abitanti": 322,
      "Link": "http://www.comuni-italiani.it/006/058/"
    },
    {
      "Istat": "048011",
      "Comune": "Cerreto Guidi",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50050,
      "CodFisco": "C529",
      "Abitanti": 10721,
      "Link": "http://www.comuni-italiani.it/048/011/"
    },
    {
      "Istat": "058027",
      "Comune": "Cerreto Laziale",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "C518",
      "Abitanti": 1206,
      "Link": "http://www.comuni-italiani.it/058/027/"
    },
    {
      "Istat": "062023",
      "Comune": "Cerreto Sannita",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82032,
      "CodFisco": "C525",
      "Abitanti": 4159,
      "Link": "http://www.comuni-italiani.it/062/023/"
    },
    {
      "Istat": "004063",
      "Comune": "Cerretto Langhe",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "C530",
      "Abitanti": 466,
      "Link": "http://www.comuni-italiani.it/004/063/"
    },
    {
      "Istat": "006059",
      "Comune": "Cerrina Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "C531",
      "Abitanti": 1536,
      "Link": "http://www.comuni-italiani.it/006/059/"
    },
    {
      "Istat": "096018",
      "Comune": "Cerrione",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13882,
      "CodFisco": "C532",
      "Abitanti": 2926,
      "Link": "http://www.comuni-italiani.it/096/018/"
    },
    {
      "Istat": "015071",
      "Comune": "Cerro al Lambro",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20070,
      "CodFisco": "C536",
      "Abitanti": 4925,
      "Link": "http://www.comuni-italiani.it/015/071/"
    },
    {
      "Istat": "094014",
      "Comune": "Cerro al Volturno",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86072,
      "CodFisco": "C534",
      "Abitanti": 1363,
      "Link": "http://www.comuni-italiani.it/094/014/"
    },
    {
      "Istat": "015072",
      "Comune": "Cerro Maggiore",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20023,
      "CodFisco": "C537",
      "Abitanti": 14794,
      "Link": "http://www.comuni-italiani.it/015/072/"
    },
    {
      "Istat": "005036",
      "Comune": "Cerro Tanaro",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "C533",
      "Abitanti": 665,
      "Link": "http://www.comuni-italiani.it/005/036/"
    },
    {
      "Istat": "023026",
      "Comune": "Cerro Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "C538",
      "Abitanti": 2478,
      "Link": "http://www.comuni-italiani.it/023/026/"
    },
    {
      "Istat": "076027",
      "Comune": "Cersosimo",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "C539",
      "Abitanti": 734,
      "Link": "http://www.comuni-italiani.it/076/027/"
    },
    {
      "Istat": "048012",
      "Comune": "Certaldo",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50052,
      "CodFisco": "C540",
      "Abitanti": 16343,
      "Link": "http://www.comuni-italiani.it/048/012/"
    },
    {
      "Istat": "018046",
      "Comune": "Certosa di Pavia",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27012,
      "CodFisco": "C541",
      "Abitanti": 4967,
      "Link": "http://www.comuni-italiani.it/018/046/"
    },
    {
      "Istat": "079027",
      "Comune": "Cerva",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "C542",
      "Abitanti": 1281,
      "Link": "http://www.comuni-italiani.it/079/027/"
    },
    {
      "Istat": "058028",
      "Comune": "Cervara di Roma",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "C543",
      "Abitanti": 502,
      "Link": "http://www.comuni-italiani.it/058/028/"
    },
    {
      "Istat": "028030",
      "Comune": "Cervarese Santa Croce",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "C544",
      "Abitanti": 5749,
      "Link": "http://www.comuni-italiani.it/028/030/"
    },
    {
      "Istat": "060026",
      "Comune": "Cervaro",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03044",
      "CodFisco": "C545",
      "Abitanti": 7209,
      "Link": "http://www.comuni-italiani.it/060/026/"
    },
    {
      "Istat": "004064",
      "Comune": "Cervasca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "C547",
      "Abitanti": 4783,
      "Link": "http://www.comuni-italiani.it/004/064/"
    },
    {
      "Istat": "002041",
      "Comune": "Cervatto",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13025,
      "CodFisco": "C548",
      "Abitanti": 49,
      "Link": "http://www.comuni-italiani.it/002/041/"
    },
    {
      "Istat": "017049",
      "Comune": "Cerveno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "C549",
      "Abitanti": 674,
      "Link": "http://www.comuni-italiani.it/017/049/"
    },
    {
      "Istat": "004065",
      "Comune": "Cervere",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "C550",
      "Abitanti": 2179,
      "Link": "http://www.comuni-italiani.it/004/065/"
    },
    {
      "Istat": "018047",
      "Comune": "Cervesina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "C551",
      "Abitanti": 1216,
      "Link": "http://www.comuni-italiani.it/018/047/"
    },
    {
      "Istat": "058029",
      "Comune": "Cerveteri",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00052",
      "CodFisco": "C552",
      "Abitanti": 36229,
      "Link": "http://www.comuni-italiani.it/058/029/"
    },
    {
      "Istat": "039007",
      "Comune": "Cervia",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0544",
      "CAP": 48015,
      "CodFisco": "C553",
      "Abitanti": 29180,
      "Link": "http://www.comuni-italiani.it/039/007/"
    },
    {
      "Istat": "078038",
      "Comune": "Cervicati",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "C554",
      "Abitanti": 924,
      "Link": "http://www.comuni-italiani.it/078/038/"
    },
    {
      "Istat": "098018",
      "Comune": "Cervignano d'Adda",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26832,
      "CodFisco": "C555",
      "Abitanti": 2084,
      "Link": "http://www.comuni-italiani.it/098/018/"
    },
    {
      "Istat": "030023",
      "Comune": "Cervignano del Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33052,
      "CodFisco": "C556",
      "Abitanti": 13590,
      "Link": "http://www.comuni-italiani.it/030/023/"
    },
    {
      "Istat": "064025",
      "Comune": "Cervinara",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 83012,
      "CodFisco": "C557",
      "Abitanti": 9926,
      "Link": "http://www.comuni-italiani.it/064/025/"
    },
    {
      "Istat": "061028",
      "Comune": "Cervino",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81023,
      "CodFisco": "C558",
      "Abitanti": 5062,
      "Link": "http://www.comuni-italiani.it/061/028/"
    },
    {
      "Istat": "008017",
      "Comune": "Cervo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18010,
      "CodFisco": "C559",
      "Abitanti": 1173,
      "Link": "http://www.comuni-italiani.it/008/017/"
    },
    {
      "Istat": "078039",
      "Comune": "Cerzeto",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "C560",
      "Abitanti": 1257,
      "Link": "http://www.comuni-italiani.it/078/039/"
    },
    {
      "Istat": "061029",
      "Comune": "Cesa",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "C561",
      "Abitanti": 8241,
      "Link": "http://www.comuni-italiani.it/061/029/"
    },
    {
      "Istat": "097021",
      "Comune": "Cesana Brianza",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23861,
      "CodFisco": "C563",
      "Abitanti": 2343,
      "Link": "http://www.comuni-italiani.it/097/021/"
    },
    {
      "Istat": "001074",
      "Comune": "Cesana Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10054,
      "CodFisco": "C564",
      "Abitanti": 1040,
      "Link": "http://www.comuni-italiani.it/001/074/"
    },
    {
      "Istat": "015074",
      "Comune": "Cesano Boscone",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "C565",
      "Abitanti": 23935,
      "Link": "http://www.comuni-italiani.it/015/074/"
    },
    {
      "Istat": 108019,
      "Comune": "Cesano Maderno",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20811,
      "CodFisco": "C566",
      "Abitanti": 37291,
      "Link": "http://www.comuni-italiani.it/108/019/"
    },
    {
      "Istat": 103022,
      "Comune": "Cesara",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28891,
      "CodFisco": "C567",
      "Abitanti": 595,
      "Link": "http://www.comuni-italiani.it/103/022/"
    },
    {
      "Istat": "083017",
      "Comune": "CesarÚ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 98033,
      "CodFisco": "C568",
      "Abitanti": 2585,
      "Link": "http://www.comuni-italiani.it/083/017/"
    },
    {
      "Istat": "015076",
      "Comune": "Cesate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20020,
      "CodFisco": "C569",
      "Abitanti": 13977,
      "Link": "http://www.comuni-italiani.it/015/076/"
    },
    {
      "Istat": "040007",
      "Comune": "Cesena",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": "4752x",
      "CodFisco": "C573",
      "Abitanti": 97056,
      "Link": "http://www.comuni-italiani.it/040/007/"
    },
    {
      "Istat": "040008",
      "Comune": "Cesenatico",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": 47042,
      "CodFisco": "C574",
      "Abitanti": 25633,
      "Link": "http://www.comuni-italiani.it/040/008/"
    },
    {
      "Istat": "064026",
      "Comune": "Cesinali",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83020,
      "CodFisco": "C576",
      "Abitanti": 2570,
      "Link": "http://www.comuni-italiani.it/064/026/"
    },
    {
      "Istat": "008018",
      "Comune": "Cesio",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18022,
      "CodFisco": "C578",
      "Abitanti": 295,
      "Link": "http://www.comuni-italiani.it/008/018/"
    },
    {
      "Istat": "025011",
      "Comune": "Cesiomaggiore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "C577",
      "Abitanti": 4187,
      "Link": "http://www.comuni-italiani.it/025/011/"
    },
    {
      "Istat": "026015",
      "Comune": "Cessalto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 31040,
      "CodFisco": "C580",
      "Abitanti": 3855,
      "Link": "http://www.comuni-italiani.it/026/015/"
    },
    {
      "Istat": 102006,
      "Comune": "Cessaniti",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89816,
      "CodFisco": "C581",
      "Abitanti": 3440,
      "Link": "http://www.comuni-italiani.it/102/006/"
    },
    {
      "Istat": "043011",
      "Comune": "Cessapalombo",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "C582",
      "Abitanti": 533,
      "Link": "http://www.comuni-italiani.it/043/011/"
    },
    {
      "Istat": "005037",
      "Comune": "Cessole",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14050,
      "CodFisco": "C583",
      "Abitanti": 430,
      "Link": "http://www.comuni-italiani.it/005/037/"
    },
    {
      "Istat": "065041",
      "Comune": "Cetara",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "C584",
      "Abitanti": 2317,
      "Link": "http://www.comuni-italiani.it/065/041/"
    },
    {
      "Istat": "017050",
      "Comune": "Ceto",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "C585",
      "Abitanti": 1963,
      "Link": "http://www.comuni-italiani.it/017/050/"
    },
    {
      "Istat": "052008",
      "Comune": "Cetona",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53040,
      "CodFisco": "C587",
      "Abitanti": 2913,
      "Link": "http://www.comuni-italiani.it/052/008/"
    },
    {
      "Istat": "078040",
      "Comune": "Cetraro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87022,
      "CodFisco": "C588",
      "Abitanti": 10125,
      "Link": "http://www.comuni-italiani.it/078/040/"
    },
    {
      "Istat": "004066",
      "Comune": "Ceva",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12073,
      "CodFisco": "C589",
      "Abitanti": 5862,
      "Link": "http://www.comuni-italiani.it/004/066/"
    },
    {
      "Istat": "017051",
      "Comune": "Cevo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "C591",
      "Abitanti": 949,
      "Link": "http://www.comuni-italiani.it/017/051/"
    },
    {
      "Istat": "007013",
      "Comune": "Challand-Saint-Anselme",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "C593",
      "Abitanti": 762,
      "Link": "http://www.comuni-italiani.it/007/013/"
    },
    {
      "Istat": "007014",
      "Comune": "Challand-Saint-Victor",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "C594",
      "Abitanti": 605,
      "Link": "http://www.comuni-italiani.it/007/014/"
    },
    {
      "Istat": "007015",
      "Comune": "Chambave",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11023,
      "CodFisco": "C595",
      "Abitanti": 947,
      "Link": "http://www.comuni-italiani.it/007/015/"
    },
    {
      "Istat": "007016",
      "Comune": "Chamois",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "B491",
      "Abitanti": 100,
      "Link": "http://www.comuni-italiani.it/007/016/"
    },
    {
      "Istat": "007017",
      "Comune": "Champdepraz",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "C596",
      "Abitanti": 710,
      "Link": "http://www.comuni-italiani.it/007/017/"
    },
    {
      "Istat": "007018",
      "Comune": "Champorcher",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "B540",
      "Abitanti": 404,
      "Link": "http://www.comuni-italiani.it/007/018/"
    },
    {
      "Istat": "007019",
      "Comune": "Charvensod",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "C598",
      "Abitanti": 2527,
      "Link": "http://www.comuni-italiani.it/007/019/"
    },
    {
      "Istat": "007020",
      "Comune": "Chatillon",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11024,
      "CodFisco": "C294",
      "Abitanti": 4966,
      "Link": "http://www.comuni-italiani.it/007/020/"
    },
    {
      "Istat": "004067",
      "Comune": "Cherasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12062,
      "CodFisco": "C599",
      "Abitanti": 8623,
      "Link": "http://www.comuni-italiani.it/004/067/"
    },
    {
      "Istat": "090024",
      "Comune": "Cheremule",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "C600",
      "Abitanti": 469,
      "Link": "http://www.comuni-italiani.it/090/024/"
    },
    {
      "Istat": "001075",
      "Comune": "Chialamberto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "C604",
      "Abitanti": 360,
      "Link": "http://www.comuni-italiani.it/001/075/"
    },
    {
      "Istat": "024029",
      "Comune": "Chiampo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36072,
      "CodFisco": "C605",
      "Abitanti": 12946,
      "Link": "http://www.comuni-italiani.it/024/029/"
    },
    {
      "Istat": "064027",
      "Comune": "Chianche",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "C606",
      "Abitanti": 571,
      "Link": "http://www.comuni-italiani.it/064/027/"
    },
    {
      "Istat": "052009",
      "Comune": "Chianciano Terme",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53042,
      "CodFisco": "C608",
      "Abitanti": 7447,
      "Link": "http://www.comuni-italiani.it/052/009/"
    },
    {
      "Istat": "050012",
      "Comune": "Chianni",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56034,
      "CodFisco": "C609",
      "Abitanti": 1505,
      "Link": "http://www.comuni-italiani.it/050/012/"
    },
    {
      "Istat": "001076",
      "Comune": "Chianocco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "C610",
      "Abitanti": 1695,
      "Link": "http://www.comuni-italiani.it/001/076/"
    },
    {
      "Istat": "088002",
      "Comune": "Chiaramonte Gulfi",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97012,
      "CodFisco": "C612",
      "Abitanti": 8218,
      "Link": "http://www.comuni-italiani.it/088/002/"
    },
    {
      "Istat": "090025",
      "Comune": "Chiaramonti",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "C613",
      "Abitanti": 1748,
      "Link": "http://www.comuni-italiani.it/090/025/"
    },
    {
      "Istat": "026016",
      "Comune": "Chiarano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "C614",
      "Abitanti": 3728,
      "Link": "http://www.comuni-italiani.it/026/016/"
    },
    {
      "Istat": "042014",
      "Comune": "Chiaravalle",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60033,
      "CodFisco": "C615",
      "Abitanti": 15036,
      "Link": "http://www.comuni-italiani.it/042/014/"
    },
    {
      "Istat": "079029",
      "Comune": "Chiaravalle Centrale",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88064,
      "CodFisco": "C616",
      "Abitanti": 6645,
      "Link": "http://www.comuni-italiani.it/079/029/"
    },
    {
      "Istat": "017052",
      "Comune": "Chiari",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25032,
      "CodFisco": "C618",
      "Abitanti": 18887,
      "Link": "http://www.comuni-italiani.it/017/052/"
    },
    {
      "Istat": "076028",
      "Comune": "Chiaromonte",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85032,
      "CodFisco": "C619",
      "Abitanti": 2015,
      "Link": "http://www.comuni-italiani.it/076/028/"
    },
    {
      "Istat": "094015",
      "Comune": "Chiauci",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86097,
      "CodFisco": "C620",
      "Abitanti": 273,
      "Link": "http://www.comuni-italiani.it/094/015/"
    },
    {
      "Istat": "010015",
      "Comune": "Chiavari",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16043,
      "CodFisco": "C621",
      "Abitanti": 27815,
      "Link": "http://www.comuni-italiani.it/010/015/"
    },
    {
      "Istat": "014018",
      "Comune": "Chiavenna",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23022,
      "CodFisco": "C623",
      "Abitanti": 7358,
      "Link": "http://www.comuni-italiani.it/014/018/"
    },
    {
      "Istat": "001077",
      "Comune": "Chiaverano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "C624",
      "Abitanti": 2150,
      "Link": "http://www.comuni-italiani.it/001/077/"
    },
    {
      "Istat": "021021",
      "Comune": "Chienes",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "C625",
      "Abitanti": 2727,
      "Link": "http://www.comuni-italiani.it/021/021/"
    },
    {
      "Istat": "001078",
      "Comune": "Chieri",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10023,
      "CodFisco": "C627",
      "Abitanti": 36168,
      "Link": "http://www.comuni-italiani.it/001/078/"
    },
    {
      "Istat": "025012",
      "Comune": "Chies d'Alpago",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "C630",
      "Abitanti": 1440,
      "Link": "http://www.comuni-italiani.it/025/012/"
    },
    {
      "Istat": "014019",
      "Comune": "Chiesa in Valmalenco",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23023,
      "CodFisco": "C628",
      "Abitanti": 2626,
      "Link": "http://www.comuni-italiani.it/014/019/"
    },
    {
      "Istat": "001079",
      "Comune": "Chiesanuova",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "C629",
      "Abitanti": 216,
      "Link": "http://www.comuni-italiani.it/001/079/"
    },
    {
      "Istat": "047022",
      "Comune": "Chiesina Uzzanese",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51013,
      "CodFisco": "C631",
      "Abitanti": 4462,
      "Link": "http://www.comuni-italiani.it/047/022/"
    },
    {
      "Istat": "069022",
      "Comune": "Chieti",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66100,
      "CodFisco": "C632",
      "Abitanti": 53937,
      "Link": "http://www.comuni-italiani.it/069/022/"
    },
    {
      "Istat": "071021",
      "Comune": "Chieuti",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71010,
      "CodFisco": "C633",
      "Abitanti": 1772,
      "Link": "http://www.comuni-italiani.it/071/021/"
    },
    {
      "Istat": "019029",
      "Comune": "Chieve",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "C634",
      "Abitanti": 2255,
      "Link": "http://www.comuni-italiani.it/019/029/"
    },
    {
      "Istat": "016072",
      "Comune": "Chignolo d'Isola",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "C635",
      "Abitanti": 3239,
      "Link": "http://www.comuni-italiani.it/016/072/"
    },
    {
      "Istat": "018048",
      "Comune": "Chignolo Po",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27013,
      "CodFisco": "C637",
      "Abitanti": 4109,
      "Link": "http://www.comuni-italiani.it/018/048/"
    },
    {
      "Istat": "027008",
      "Comune": "Chioggia",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30015,
      "CodFisco": "C638",
      "Abitanti": 50674,
      "Link": "http://www.comuni-italiani.it/027/008/"
    },
    {
      "Istat": "001080",
      "Comune": "Chiomonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "C639",
      "Abitanti": 942,
      "Link": "http://www.comuni-italiani.it/001/080/"
    },
    {
      "Istat": "093013",
      "Comune": "Chions",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33083,
      "CodFisco": "C640",
      "Abitanti": 5260,
      "Link": "http://www.comuni-italiani.it/093/013/"
    },
    {
      "Istat": "030024",
      "Comune": "Chiopris-Viscone",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33048,
      "CodFisco": "C641",
      "Abitanti": 649,
      "Link": "http://www.comuni-italiani.it/030/024/"
    },
    {
      "Istat": "051014",
      "Comune": "Chitignano",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "C648",
      "Abitanti": 951,
      "Link": "http://www.comuni-italiani.it/051/014/"
    },
    {
      "Istat": "016073",
      "Comune": "Chiuduno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "C649",
      "Abitanti": 5861,
      "Link": "http://www.comuni-italiani.it/016/073/"
    },
    {
      "Istat": "024030",
      "Comune": "Chiuppano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "C650",
      "Abitanti": 2635,
      "Link": "http://www.comuni-italiani.it/024/030/"
    },
    {
      "Istat": "014020",
      "Comune": "Chiuro",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "C651",
      "Abitanti": 2553,
      "Link": "http://www.comuni-italiani.it/014/020/"
    },
    {
      "Istat": "021022",
      "Comune": "Chiusa",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39043,
      "CodFisco": "C652",
      "Abitanti": 5132,
      "Link": "http://www.comuni-italiani.it/021/022/"
    },
    {
      "Istat": "004068",
      "Comune": "Chiusa di Pesio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12013,
      "CodFisco": "C653",
      "Abitanti": 3783,
      "Link": "http://www.comuni-italiani.it/004/068/"
    },
    {
      "Istat": "001081",
      "Comune": "Chiusa di San Michele",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "C655",
      "Abitanti": 1687,
      "Link": "http://www.comuni-italiani.it/001/081/"
    },
    {
      "Istat": "082029",
      "Comune": "Chiusa Sclafani",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90033,
      "CodFisco": "C654",
      "Abitanti": 2994,
      "Link": "http://www.comuni-italiani.it/082/029/"
    },
    {
      "Istat": "030025",
      "Comune": "Chiusaforte",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33010,
      "CodFisco": "C656",
      "Abitanti": 705,
      "Link": "http://www.comuni-italiani.it/030/025/"
    },
    {
      "Istat": "008019",
      "Comune": "Chiusanico",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18027,
      "CodFisco": "C657",
      "Abitanti": 611,
      "Link": "http://www.comuni-italiani.it/008/019/"
    },
    {
      "Istat": "005038",
      "Comune": "Chiusano d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14025,
      "CodFisco": "C658",
      "Abitanti": 232,
      "Link": "http://www.comuni-italiani.it/005/038/"
    },
    {
      "Istat": "064028",
      "Comune": "Chiusano di San Domenico",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83040,
      "CodFisco": "C659",
      "Abitanti": 2388,
      "Link": "http://www.comuni-italiani.it/064/028/"
    },
    {
      "Istat": "008020",
      "Comune": "Chiusavecchia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18027,
      "CodFisco": "C660",
      "Abitanti": 584,
      "Link": "http://www.comuni-italiani.it/008/020/"
    },
    {
      "Istat": "052010",
      "Comune": "Chiusdino",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53012,
      "CodFisco": "C661",
      "Abitanti": 2036,
      "Link": "http://www.comuni-italiani.it/052/010/"
    },
    {
      "Istat": "052011",
      "Comune": "Chiusi",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53043,
      "CodFisco": "C662",
      "Abitanti": 8838,
      "Link": "http://www.comuni-italiani.it/052/011/"
    },
    {
      "Istat": "051015",
      "Comune": "Chiusi della Verna",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "C663",
      "Abitanti": 2083,
      "Link": "http://www.comuni-italiani.it/051/015/"
    },
    {
      "Istat": "001082",
      "Comune": "Chivasso",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10034,
      "CodFisco": "C665",
      "Abitanti": 26368,
      "Link": "http://www.comuni-italiani.it/001/082/"
    },
    {
      "Istat": "058118",
      "Comune": "Ciampino",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00043",
      "CodFisco": "M272",
      "Abitanti": 38529,
      "Link": "http://www.comuni-italiani.it/058/118/"
    },
    {
      "Istat": "084015",
      "Comune": "Cianciana",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92012,
      "CodFisco": "C668",
      "Abitanti": 3539,
      "Link": "http://www.comuni-italiani.it/084/015/"
    },
    {
      "Istat": "025013",
      "Comune": "Cibiana di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "C672",
      "Abitanti": 447,
      "Link": "http://www.comuni-italiani.it/025/013/"
    },
    {
      "Istat": "010016",
      "Comune": "Cicagna",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16044,
      "CodFisco": "C673",
      "Abitanti": 2633,
      "Link": "http://www.comuni-italiani.it/010/016/"
    },
    {
      "Istat": "079030",
      "Comune": "Cicala",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "C674",
      "Abitanti": 991,
      "Link": "http://www.comuni-italiani.it/079/030/"
    },
    {
      "Istat": "063027",
      "Comune": "Cicciano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80033,
      "CodFisco": "C675",
      "Abitanti": 12290,
      "Link": "http://www.comuni-italiani.it/063/027/"
    },
    {
      "Istat": "065042",
      "Comune": "Cicerale",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84053,
      "CodFisco": "C676",
      "Abitanti": 1272,
      "Link": "http://www.comuni-italiani.it/065/042/"
    },
    {
      "Istat": "058030",
      "Comune": "Ciciliano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "C677",
      "Abitanti": 1459,
      "Link": "http://www.comuni-italiani.it/058/030/"
    },
    {
      "Istat": "019030",
      "Comune": "Cicognolo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "C678",
      "Abitanti": 932,
      "Link": "http://www.comuni-italiani.it/019/030/"
    },
    {
      "Istat": "001083",
      "Comune": "Ciconio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "C679",
      "Abitanti": 365,
      "Link": "http://www.comuni-italiani.it/001/083/"
    },
    {
      "Istat": "002042",
      "Comune": "Cigliano",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13043,
      "CodFisco": "C680",
      "Abitanti": 4586,
      "Link": "http://www.comuni-italiani.it/002/042/"
    },
    {
      "Istat": "004069",
      "Comune": "CigliË",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "C681",
      "Abitanti": 199,
      "Link": "http://www.comuni-italiani.it/004/069/"
    },
    {
      "Istat": "018049",
      "Comune": "Cigognola",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "C684",
      "Abitanti": 1363,
      "Link": "http://www.comuni-italiani.it/018/049/"
    },
    {
      "Istat": "017053",
      "Comune": "Cigole",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "C685",
      "Abitanti": 1669,
      "Link": "http://www.comuni-italiani.it/017/053/"
    },
    {
      "Istat": "018050",
      "Comune": "Cilavegna",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0381",
      "CAP": 27024,
      "CodFisco": "C686",
      "Abitanti": 5653,
      "Link": "http://www.comuni-italiani.it/018/050/"
    },
    {
      "Istat": "026017",
      "Comune": "Cimadolmo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31010,
      "CodFisco": "C689",
      "Abitanti": 3488,
      "Link": "http://www.comuni-italiani.it/026/017/"
    },
    {
      "Istat": "017054",
      "Comune": "Cimbergo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "C691",
      "Abitanti": 579,
      "Link": "http://www.comuni-italiani.it/017/054/"
    },
    {
      "Istat": "022057",
      "Comune": "Cimego",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38082,
      "CodFisco": "C694",
      "Abitanti": 413,
      "Link": "http://www.comuni-italiani.it/022/057/"
    },
    {
      "Istat": "080026",
      "Comune": "Cimin‡",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "C695",
      "Abitanti": 598,
      "Link": "http://www.comuni-italiani.it/080/026/"
    },
    {
      "Istat": "082030",
      "Comune": "Ciminna",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90023,
      "CodFisco": "C696",
      "Abitanti": 3877,
      "Link": "http://www.comuni-italiani.it/082/030/"
    },
    {
      "Istat": "063028",
      "Comune": "Cimitile",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "C697",
      "Abitanti": 7356,
      "Link": "http://www.comuni-italiani.it/063/028/"
    },
    {
      "Istat": "093014",
      "Comune": "Cimolais",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "C699",
      "Abitanti": 431,
      "Link": "http://www.comuni-italiani.it/093/014/"
    },
    {
      "Istat": "022058",
      "Comune": "Cimone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38060,
      "CodFisco": "C700",
      "Abitanti": 662,
      "Link": "http://www.comuni-italiani.it/022/058/"
    },
    {
      "Istat": "005039",
      "Comune": "Cinaglio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "C701",
      "Abitanti": 452,
      "Link": "http://www.comuni-italiani.it/005/039/"
    },
    {
      "Istat": "058031",
      "Comune": "Cineto Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "C702",
      "Abitanti": 668,
      "Link": "http://www.comuni-italiani.it/058/031/"
    },
    {
      "Istat": "019031",
      "Comune": "Cingia de' Botti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26042,
      "CodFisco": "C703",
      "Abitanti": 1325,
      "Link": "http://www.comuni-italiani.it/019/031/"
    },
    {
      "Istat": "043012",
      "Comune": "Cingoli",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62011,
      "CodFisco": "C704",
      "Abitanti": 10735,
      "Link": "http://www.comuni-italiani.it/043/012/"
    },
    {
      "Istat": "053007",
      "Comune": "Cinigiano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58044,
      "CodFisco": "C705",
      "Abitanti": 2778,
      "Link": "http://www.comuni-italiani.it/053/007/"
    },
    {
      "Istat": "015077",
      "Comune": "Cinisello Balsamo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20092,
      "CodFisco": "C707",
      "Abitanti": 74150,
      "Link": "http://www.comuni-italiani.it/015/077/"
    },
    {
      "Istat": "082031",
      "Comune": "Cinisi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90045,
      "CodFisco": "C708",
      "Abitanti": 12047,
      "Link": "http://www.comuni-italiani.it/082/031/"
    },
    {
      "Istat": "014021",
      "Comune": "Cino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "C709",
      "Abitanti": 379,
      "Link": "http://www.comuni-italiani.it/014/021/"
    },
    {
      "Istat": "080027",
      "Comune": "Cinquefrondi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89021,
      "CodFisco": "C710",
      "Abitanti": 6643,
      "Link": "http://www.comuni-italiani.it/080/027/"
    },
    {
      "Istat": "001084",
      "Comune": "Cintano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "C711",
      "Abitanti": 264,
      "Link": "http://www.comuni-italiani.it/001/084/"
    },
    {
      "Istat": "022059",
      "Comune": "Cinte Tesino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "C712",
      "Abitanti": 389,
      "Link": "http://www.comuni-italiani.it/022/059/"
    },
    {
      "Istat": "027009",
      "Comune": "Cinto Caomaggiore",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "C714",
      "Abitanti": 3327,
      "Link": "http://www.comuni-italiani.it/027/009/"
    },
    {
      "Istat": "028031",
      "Comune": "Cinto Euganeo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35030,
      "CodFisco": "C713",
      "Abitanti": 2067,
      "Link": "http://www.comuni-italiani.it/028/031/"
    },
    {
      "Istat": "001085",
      "Comune": "Cinzano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "C715",
      "Abitanti": 389,
      "Link": "http://www.comuni-italiani.it/001/085/"
    },
    {
      "Istat": "061030",
      "Comune": "Ciorlano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "C716",
      "Abitanti": 443,
      "Link": "http://www.comuni-italiani.it/061/030/"
    },
    {
      "Istat": "008021",
      "Comune": "Cipressa",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18017,
      "CodFisco": "C718",
      "Abitanti": 1370,
      "Link": "http://www.comuni-italiani.it/008/021/"
    },
    {
      "Istat": "062024",
      "Comune": "Circello",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "C719",
      "Abitanti": 2501,
      "Link": "http://www.comuni-italiani.it/062/024/"
    },
    {
      "Istat": "001086",
      "Comune": "CiriË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10073,
      "CodFisco": "C722",
      "Abitanti": 18973,
      "Link": "http://www.comuni-italiani.it/001/086/"
    },
    {
      "Istat": "077005",
      "Comune": "Cirigliano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "C723",
      "Abitanti": 397,
      "Link": "http://www.comuni-italiani.it/077/005/"
    },
    {
      "Istat": "013068",
      "Comune": "Cirimido",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "C724",
      "Abitanti": 2164,
      "Link": "http://www.comuni-italiani.it/013/068/"
    },
    {
      "Istat": 101007,
      "Comune": "CirÚ",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88813,
      "CodFisco": "C725",
      "Abitanti": 3228,
      "Link": "http://www.comuni-italiani.it/101/007/"
    },
    {
      "Istat": 101008,
      "Comune": "CirÚ Marina",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88811,
      "CodFisco": "C726",
      "Abitanti": 14957,
      "Link": "http://www.comuni-italiani.it/101/008/"
    },
    {
      "Istat": "022060",
      "Comune": "Cis",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "C727",
      "Abitanti": 311,
      "Link": "http://www.comuni-italiani.it/022/060/"
    },
    {
      "Istat": "016074",
      "Comune": "Cisano Bergamasco",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24034,
      "CodFisco": "C728",
      "Abitanti": 6316,
      "Link": "http://www.comuni-italiani.it/016/074/"
    },
    {
      "Istat": "009025",
      "Comune": "Cisano sul Neva",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17035,
      "CodFisco": "C729",
      "Abitanti": 1947,
      "Link": "http://www.comuni-italiani.it/009/025/"
    },
    {
      "Istat": "016075",
      "Comune": "Ciserano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "C730",
      "Abitanti": 5769,
      "Link": "http://www.comuni-italiani.it/016/075/"
    },
    {
      "Istat": "012050",
      "Comune": "Cislago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 21040,
      "CodFisco": "C732",
      "Abitanti": 10063,
      "Link": "http://www.comuni-italiani.it/012/050/"
    },
    {
      "Istat": "015078",
      "Comune": "Cisliano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "C733",
      "Abitanti": 4057,
      "Link": "http://www.comuni-italiani.it/015/078/"
    },
    {
      "Istat": "024031",
      "Comune": "Cismon del Grappa",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36020,
      "CodFisco": "C734",
      "Abitanti": 976,
      "Link": "http://www.comuni-italiani.it/024/031/"
    },
    {
      "Istat": "026018",
      "Comune": "Cison di Valmarino",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31030,
      "CodFisco": "C735",
      "Abitanti": 2730,
      "Link": "http://www.comuni-italiani.it/026/018/"
    },
    {
      "Istat": "004070",
      "Comune": "Cissone",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "C738",
      "Abitanti": 82,
      "Link": "http://www.comuni-italiani.it/004/070/"
    },
    {
      "Istat": "005040",
      "Comune": "Cisterna d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "C739",
      "Abitanti": 1317,
      "Link": "http://www.comuni-italiani.it/005/040/"
    },
    {
      "Istat": "059005",
      "Comune": "Cisterna di Latina",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "04012",
      "CodFisco": "C740",
      "Abitanti": 35480,
      "Link": "http://www.comuni-italiani.it/059/005/"
    },
    {
      "Istat": "074005",
      "Comune": "Cisternino",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 72014,
      "CodFisco": "C741",
      "Abitanti": 11884,
      "Link": "http://www.comuni-italiani.it/074/005/"
    },
    {
      "Istat": "054011",
      "Comune": "Citerna",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06010",
      "CodFisco": "C742",
      "Abitanti": 3517,
      "Link": "http://www.comuni-italiani.it/054/011/"
    },
    {
      "Istat": "054012",
      "Comune": "Citt‡ della Pieve",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0578",
      "CAP": "06062",
      "CodFisco": "C744",
      "Abitanti": 7836,
      "Link": "http://www.comuni-italiani.it/054/012/"
    },
    {
      "Istat": "054013",
      "Comune": "Citt‡ di Castello",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06012",
      "CodFisco": "C745",
      "Abitanti": 40567,
      "Link": "http://www.comuni-italiani.it/054/013/"
    },
    {
      "Istat": "068012",
      "Comune": "Citt‡ Sant'Angelo",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65013,
      "CodFisco": "C750",
      "Abitanti": 14553,
      "Link": "http://www.comuni-italiani.it/068/012/"
    },
    {
      "Istat": "028032",
      "Comune": "Cittadella",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35013,
      "CodFisco": "C743",
      "Abitanti": 19970,
      "Link": "http://www.comuni-italiani.it/028/032/"
    },
    {
      "Istat": "057016",
      "Comune": "Cittaducale",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02015",
      "CodFisco": "C746",
      "Abitanti": 7018,
      "Link": "http://www.comuni-italiani.it/057/016/"
    },
    {
      "Istat": "080028",
      "Comune": "Cittanova",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89022,
      "CodFisco": "C747",
      "Abitanti": 10512,
      "Link": "http://www.comuni-italiani.it/080/028/"
    },
    {
      "Istat": "057017",
      "Comune": "Cittareale",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "C749",
      "Abitanti": 485,
      "Link": "http://www.comuni-italiani.it/057/017/"
    },
    {
      "Istat": "012051",
      "Comune": "Cittiglio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21033,
      "CodFisco": "C751",
      "Abitanti": 4012,
      "Link": "http://www.comuni-italiani.it/012/051/"
    },
    {
      "Istat": "097022",
      "Comune": "Civate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23862,
      "CodFisco": "C752",
      "Abitanti": 4062,
      "Link": "http://www.comuni-italiani.it/097/022/"
    },
    {
      "Istat": "013070",
      "Comune": "Civenna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "C754",
      "Abitanti": 720,
      "Link": "http://www.comuni-italiani.it/013/070/"
    },
    {
      "Istat": "008022",
      "Comune": "Civezza",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18017,
      "CodFisco": "C755",
      "Abitanti": 643,
      "Link": "http://www.comuni-italiani.it/008/022/"
    },
    {
      "Istat": "022061",
      "Comune": "Civezzano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38045,
      "CodFisco": "C756",
      "Abitanti": 3909,
      "Link": "http://www.comuni-italiani.it/022/061/"
    },
    {
      "Istat": "002043",
      "Comune": "Civiasco",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13010,
      "CodFisco": "C757",
      "Abitanti": 262,
      "Link": "http://www.comuni-italiani.it/002/043/"
    },
    {
      "Istat": "030026",
      "Comune": "Cividale del Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33043,
      "CodFisco": "C758",
      "Abitanti": 11615,
      "Link": "http://www.comuni-italiani.it/030/026/"
    },
    {
      "Istat": "016076",
      "Comune": "Cividate al Piano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "C759",
      "Abitanti": 5194,
      "Link": "http://www.comuni-italiani.it/016/076/"
    },
    {
      "Istat": "017055",
      "Comune": "Cividate Camuno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "C760",
      "Abitanti": 2776,
      "Link": "http://www.comuni-italiani.it/017/055/"
    },
    {
      "Istat": "078041",
      "Comune": "Civita",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "C763",
      "Abitanti": 969,
      "Link": "http://www.comuni-italiani.it/078/041/"
    },
    {
      "Istat": "056021",
      "Comune": "Civita Castellana",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01033",
      "CodFisco": "C765",
      "Abitanti": 16777,
      "Link": "http://www.comuni-italiani.it/056/021/"
    },
    {
      "Istat": "066034",
      "Comune": "Civita d'Antino",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "C766",
      "Abitanti": 1016,
      "Link": "http://www.comuni-italiani.it/066/034/"
    },
    {
      "Istat": "070019",
      "Comune": "Civitacampomarano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "C764",
      "Abitanti": 545,
      "Link": "http://www.comuni-italiani.it/070/019/"
    },
    {
      "Istat": "069023",
      "Comune": "Civitaluparella",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "C768",
      "Abitanti": 375,
      "Link": "http://www.comuni-italiani.it/069/023/"
    },
    {
      "Istat": "094016",
      "Comune": "Civitanova del Sannio",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86094,
      "CodFisco": "C769",
      "Abitanti": 952,
      "Link": "http://www.comuni-italiani.it/094/016/"
    },
    {
      "Istat": "043013",
      "Comune": "Civitanova Marche",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62012,
      "CodFisco": "C770",
      "Abitanti": 40816,
      "Link": "http://www.comuni-italiani.it/043/013/"
    },
    {
      "Istat": "068013",
      "Comune": "Civitaquana",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "C771",
      "Abitanti": 1376,
      "Link": "http://www.comuni-italiani.it/068/013/"
    },
    {
      "Istat": "058032",
      "Comune": "Civitavecchia",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "00053",
      "CodFisco": "C773",
      "Abitanti": 52294,
      "Link": "http://www.comuni-italiani.it/058/032/"
    },
    {
      "Istat": "066035",
      "Comune": "Civitella Alfedena",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "C778",
      "Abitanti": 315,
      "Link": "http://www.comuni-italiani.it/066/035/"
    },
    {
      "Istat": "068014",
      "Comune": "Civitella Casanova",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "C779",
      "Abitanti": 1947,
      "Link": "http://www.comuni-italiani.it/068/014/"
    },
    {
      "Istat": "056022",
      "Comune": "Civitella d'Agliano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01020",
      "CodFisco": "C780",
      "Abitanti": 1696,
      "Link": "http://www.comuni-italiani.it/056/022/"
    },
    {
      "Istat": "067017",
      "Comune": "Civitella del Tronto",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "C781",
      "Abitanti": 5442,
      "Link": "http://www.comuni-italiani.it/067/017/"
    },
    {
      "Istat": "040009",
      "Comune": "Civitella di Romagna",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47012,
      "CodFisco": "C777",
      "Abitanti": 3870,
      "Link": "http://www.comuni-italiani.it/040/009/"
    },
    {
      "Istat": "051016",
      "Comune": "Civitella in Val di Chiana",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52041,
      "CodFisco": "C774",
      "Abitanti": 9183,
      "Link": "http://www.comuni-italiani.it/051/016/"
    },
    {
      "Istat": "069024",
      "Comune": "Civitella Messer Raimondo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66010,
      "CodFisco": "C776",
      "Abitanti": 899,
      "Link": "http://www.comuni-italiani.it/069/024/"
    },
    {
      "Istat": "053008",
      "Comune": "Civitella Paganico",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58045,
      "CodFisco": "C782",
      "Abitanti": 3249,
      "Link": "http://www.comuni-italiani.it/053/008/"
    },
    {
      "Istat": "066036",
      "Comune": "Civitella Roveto",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67054,
      "CodFisco": "C783",
      "Abitanti": 3395,
      "Link": "http://www.comuni-italiani.it/066/036/"
    },
    {
      "Istat": "058033",
      "Comune": "Civitella San Paolo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "00060",
      "CodFisco": "C784",
      "Abitanti": 2009,
      "Link": "http://www.comuni-italiani.it/058/033/"
    },
    {
      "Istat": "014022",
      "Comune": "Civo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "C785",
      "Abitanti": 1093,
      "Link": "http://www.comuni-italiani.it/014/022/"
    },
    {
      "Istat": "013071",
      "Comune": "Claino con Osteno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "C787",
      "Abitanti": 554,
      "Link": "http://www.comuni-italiani.it/013/071/"
    },
    {
      "Istat": "093015",
      "Comune": "Claut",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "C790",
      "Abitanti": 1027,
      "Link": "http://www.comuni-italiani.it/093/015/"
    },
    {
      "Istat": "093016",
      "Comune": "Clauzetto",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "C791",
      "Abitanti": 402,
      "Link": "http://www.comuni-italiani.it/093/016/"
    },
    {
      "Istat": "004071",
      "Comune": "Clavesana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "C792",
      "Abitanti": 913,
      "Link": "http://www.comuni-italiani.it/004/071/"
    },
    {
      "Istat": "001087",
      "Comune": "Claviere",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "C793",
      "Abitanti": 212,
      "Link": "http://www.comuni-italiani.it/001/087/"
    },
    {
      "Istat": "022062",
      "Comune": "Cles",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38023,
      "CodFisco": "C794",
      "Abitanti": 6781,
      "Link": "http://www.comuni-italiani.it/022/062/"
    },
    {
      "Istat": "078042",
      "Comune": "Cleto",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87030,
      "CodFisco": "C795",
      "Abitanti": 1348,
      "Link": "http://www.comuni-italiani.it/078/042/"
    },
    {
      "Istat": "012052",
      "Comune": "Clivio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "C796",
      "Abitanti": 1944,
      "Link": "http://www.comuni-italiani.it/012/052/"
    },
    {
      "Istat": "022063",
      "Comune": "Cloz",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "C797",
      "Abitanti": 734,
      "Link": "http://www.comuni-italiani.it/022/063/"
    },
    {
      "Istat": "016077",
      "Comune": "Clusone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24023,
      "CodFisco": "C800",
      "Abitanti": 8793,
      "Link": "http://www.comuni-italiani.it/016/077/"
    },
    {
      "Istat": "001088",
      "Comune": "Coassolo Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "C801",
      "Abitanti": 1571,
      "Link": "http://www.comuni-italiani.it/001/088/"
    },
    {
      "Istat": "001089",
      "Comune": "Coazze",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "C803",
      "Abitanti": 3339,
      "Link": "http://www.comuni-italiani.it/001/089/"
    },
    {
      "Istat": "005041",
      "Comune": "Coazzolo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14054,
      "CodFisco": "C804",
      "Abitanti": 323,
      "Link": "http://www.comuni-italiani.it/005/041/"
    },
    {
      "Istat": "017056",
      "Comune": "Coccaglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "C806",
      "Abitanti": 8575,
      "Link": "http://www.comuni-italiani.it/017/056/"
    },
    {
      "Istat": "005042",
      "Comune": "Cocconato",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14023,
      "CodFisco": "C807",
      "Abitanti": 1609,
      "Link": "http://www.comuni-italiani.it/005/042/"
    },
    {
      "Istat": "012053",
      "Comune": "Cocquio-Trevisago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21034,
      "CodFisco": "C810",
      "Abitanti": 4813,
      "Link": "http://www.comuni-italiani.it/012/053/"
    },
    {
      "Istat": "066037",
      "Comune": "Cocullo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "C811",
      "Abitanti": 259,
      "Link": "http://www.comuni-italiani.it/066/037/"
    },
    {
      "Istat": "028033",
      "Comune": "Codevigo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "C812",
      "Abitanti": 6403,
      "Link": "http://www.comuni-italiani.it/028/033/"
    },
    {
      "Istat": "018051",
      "Comune": "Codevilla",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "C813",
      "Abitanti": 1010,
      "Link": "http://www.comuni-italiani.it/018/051/"
    },
    {
      "Istat": "038005",
      "Comune": "Codigoro",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44021,
      "CodFisco": "C814",
      "Abitanti": 12653,
      "Link": "http://www.comuni-italiani.it/038/005/"
    },
    {
      "Istat": "026019",
      "Comune": "CodognË",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31013,
      "CodFisco": "C815",
      "Abitanti": 5386,
      "Link": "http://www.comuni-italiani.it/026/019/"
    },
    {
      "Istat": "098019",
      "Comune": "Codogno",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26845,
      "CodFisco": "C816",
      "Abitanti": 15765,
      "Link": "http://www.comuni-italiani.it/098/019/"
    },
    {
      "Istat": "030027",
      "Comune": "Codroipo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33033,
      "CodFisco": "C817",
      "Abitanti": 15887,
      "Link": "http://www.comuni-italiani.it/030/027/"
    },
    {
      "Istat": "090026",
      "Comune": "Codrongianos",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "C818",
      "Abitanti": 1374,
      "Link": "http://www.comuni-italiani.it/090/026/"
    },
    {
      "Istat": "096019",
      "Comune": "Coggiola",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13863,
      "CodFisco": "C819",
      "Abitanti": 2025,
      "Link": "http://www.comuni-italiani.it/096/019/"
    },
    {
      "Istat": 108020,
      "Comune": "Cogliate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20815,
      "CodFisco": "C820",
      "Abitanti": 8409,
      "Link": "http://www.comuni-italiani.it/108/020/"
    },
    {
      "Istat": "007021",
      "Comune": "Cogne",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11012,
      "CodFisco": "C821",
      "Abitanti": 1483,
      "Link": "http://www.comuni-italiani.it/007/021/"
    },
    {
      "Istat": "010017",
      "Comune": "Cogoleto",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16016,
      "CodFisco": "C823",
      "Abitanti": 9209,
      "Link": "http://www.comuni-italiani.it/010/017/"
    },
    {
      "Istat": "024032",
      "Comune": "Cogollo del Cengio",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "C824",
      "Abitanti": 3434,
      "Link": "http://www.comuni-italiani.it/024/032/"
    },
    {
      "Istat": "010018",
      "Comune": "Cogorno",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "C826",
      "Abitanti": 5672,
      "Link": "http://www.comuni-italiani.it/010/018/"
    },
    {
      "Istat": "003051",
      "Comune": "Colazza",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "C829",
      "Abitanti": 486,
      "Link": "http://www.comuni-italiani.it/003/051/"
    },
    {
      "Istat": "041012",
      "Comune": "Colbordolo",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61022,
      "CodFisco": "C830",
      "Abitanti": 6236,
      "Link": "http://www.comuni-italiani.it/041/012/"
    },
    {
      "Istat": "016078",
      "Comune": "Colere",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "C835",
      "Abitanti": 1141,
      "Link": "http://www.comuni-italiani.it/016/078/"
    },
    {
      "Istat": "060027",
      "Comune": "Colfelice",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "C836",
      "Abitanti": 1893,
      "Link": "http://www.comuni-italiani.it/060/027/"
    },
    {
      "Istat": "033016",
      "Comune": "Coli",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "C838",
      "Abitanti": 1001,
      "Link": "http://www.comuni-italiani.it/033/016/"
    },
    {
      "Istat": "097023",
      "Comune": "Colico",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23823,
      "CodFisco": "C839",
      "Abitanti": 7561,
      "Link": "http://www.comuni-italiani.it/097/023/"
    },
    {
      "Istat": "035019",
      "Comune": "Collagna",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42037,
      "CodFisco": "C840",
      "Abitanti": 984,
      "Link": "http://www.comuni-italiani.it/035/019/"
    },
    {
      "Istat": "057018",
      "Comune": "Collalto Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02022",
      "CodFisco": "C841",
      "Abitanti": 458,
      "Link": "http://www.comuni-italiani.it/057/018/"
    },
    {
      "Istat": "066038",
      "Comune": "Collarmele",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67040,
      "CodFisco": "C844",
      "Abitanti": 971,
      "Link": "http://www.comuni-italiani.it/066/038/"
    },
    {
      "Istat": "054014",
      "Comune": "Collazzone",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06050",
      "CodFisco": "C845",
      "Abitanti": 3565,
      "Link": "http://www.comuni-italiani.it/054/014/"
    },
    {
      "Istat": "097024",
      "Comune": "Colle Brianza",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23886,
      "CodFisco": "C851",
      "Abitanti": 1762,
      "Link": "http://www.comuni-italiani.it/097/024/"
    },
    {
      "Istat": "070020",
      "Comune": "Colle d'Anchise",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "C854",
      "Abitanti": 813,
      "Link": "http://www.comuni-italiani.it/070/020/"
    },
    {
      "Istat": "057019",
      "Comune": "Colle di Tora",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "C857",
      "Abitanti": 380,
      "Link": "http://www.comuni-italiani.it/057/019/"
    },
    {
      "Istat": "052012",
      "Comune": "Colle di Val d'Elsa",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53034,
      "CodFisco": "C847",
      "Abitanti": 21629,
      "Link": "http://www.comuni-italiani.it/052/012/"
    },
    {
      "Istat": "060029",
      "Comune": "Colle San Magno",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "C870",
      "Abitanti": 755,
      "Link": "http://www.comuni-italiani.it/060/029/"
    },
    {
      "Istat": "062025",
      "Comune": "Colle Sannita",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82024,
      "CodFisco": "C846",
      "Abitanti": 2680,
      "Link": "http://www.comuni-italiani.it/062/025/"
    },
    {
      "Istat": "025014",
      "Comune": "Colle Santa Lucia",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "C872",
      "Abitanti": 395,
      "Link": "http://www.comuni-italiani.it/025/014/"
    },
    {
      "Istat": "026020",
      "Comune": "Colle Umberto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31014,
      "CodFisco": "C848",
      "Abitanti": 5180,
      "Link": "http://www.comuni-italiani.it/026/020/"
    },
    {
      "Istat": "017057",
      "Comune": "Collebeato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "C850",
      "Abitanti": 4771,
      "Link": "http://www.comuni-italiani.it/017/057/"
    },
    {
      "Istat": "034009",
      "Comune": "Collecchio",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43044,
      "CodFisco": "C852",
      "Abitanti": 14120,
      "Link": "http://www.comuni-italiani.it/034/009/"
    },
    {
      "Istat": "068015",
      "Comune": "Collecorvino",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "C853",
      "Abitanti": 5989,
      "Link": "http://www.comuni-italiani.it/068/015/"
    },
    {
      "Istat": "067018",
      "Comune": "Colledara",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64042,
      "CodFisco": "C311",
      "Abitanti": 2260,
      "Link": "http://www.comuni-italiani.it/067/018/"
    },
    {
      "Istat": "069025",
      "Comune": "Colledimacine",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66010,
      "CodFisco": "C855",
      "Abitanti": 245,
      "Link": "http://www.comuni-italiani.it/069/025/"
    },
    {
      "Istat": "069026",
      "Comune": "Colledimezzo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "C856",
      "Abitanti": 557,
      "Link": "http://www.comuni-italiani.it/069/026/"
    },
    {
      "Istat": "058034",
      "Comune": "Colleferro",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00034",
      "CodFisco": "C858",
      "Abitanti": 22142,
      "Link": "http://www.comuni-italiani.it/058/034/"
    },
    {
      "Istat": "057020",
      "Comune": "Collegiove",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "C859",
      "Abitanti": 190,
      "Link": "http://www.comuni-italiani.it/057/020/"
    },
    {
      "Istat": "001090",
      "Comune": "Collegno",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10093,
      "CodFisco": "C860",
      "Abitanti": 50137,
      "Link": "http://www.comuni-italiani.it/001/090/"
    },
    {
      "Istat": "066039",
      "Comune": "Collelongo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "C862",
      "Abitanti": 1355,
      "Link": "http://www.comuni-italiani.it/066/039/"
    },
    {
      "Istat": "060028",
      "Comune": "Collepardo",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "C864",
      "Abitanti": 985,
      "Link": "http://www.comuni-italiani.it/060/028/"
    },
    {
      "Istat": "075021",
      "Comune": "Collepasso",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "C865",
      "Abitanti": 6428,
      "Link": "http://www.comuni-italiani.it/075/021/"
    },
    {
      "Istat": "066040",
      "Comune": "Collepietro",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "C866",
      "Abitanti": 249,
      "Link": "http://www.comuni-italiani.it/066/040/"
    },
    {
      "Istat": "001091",
      "Comune": "Colleretto Castelnuovo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "C867",
      "Abitanti": 358,
      "Link": "http://www.comuni-italiani.it/001/091/"
    },
    {
      "Istat": "001092",
      "Comune": "Colleretto Giacosa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "C868",
      "Abitanti": 610,
      "Link": "http://www.comuni-italiani.it/001/092/"
    },
    {
      "Istat": "049008",
      "Comune": "Collesalvetti",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 57014,
      "CodFisco": "C869",
      "Abitanti": 16919,
      "Link": "http://www.comuni-italiani.it/049/008/"
    },
    {
      "Istat": "082032",
      "Comune": "Collesano",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90016,
      "CodFisco": "C871",
      "Abitanti": 4118,
      "Link": "http://www.comuni-italiani.it/082/032/"
    },
    {
      "Istat": "070021",
      "Comune": "Colletorto",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86044,
      "CodFisco": "C875",
      "Abitanti": 2145,
      "Link": "http://www.comuni-italiani.it/070/021/"
    },
    {
      "Istat": "057021",
      "Comune": "Collevecchio",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02042",
      "CodFisco": "C876",
      "Abitanti": 1650,
      "Link": "http://www.comuni-italiani.it/057/021/"
    },
    {
      "Istat": "094017",
      "Comune": "Colli a Volturno",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86073,
      "CodFisco": "C878",
      "Abitanti": 1409,
      "Link": "http://www.comuni-italiani.it/094/017/"
    },
    {
      "Istat": "044014",
      "Comune": "Colli del Tronto",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63079,
      "CodFisco": "C877",
      "Abitanti": 3526,
      "Link": "http://www.comuni-italiani.it/044/014/"
    },
    {
      "Istat": "057022",
      "Comune": "Colli sul Velino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "C880",
      "Abitanti": 513,
      "Link": "http://www.comuni-italiani.it/057/022/"
    },
    {
      "Istat": "065043",
      "Comune": "Colliano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "C879",
      "Abitanti": 3800,
      "Link": "http://www.comuni-italiani.it/065/043/"
    },
    {
      "Istat": 106003,
      "Comune": "Collinas",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "C882",
      "Abitanti": 897,
      "Link": "http://www.comuni-italiani.it/106/003/"
    },
    {
      "Istat": "017058",
      "Comune": "Collio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "C883",
      "Abitanti": 2292,
      "Link": "http://www.comuni-italiani.it/017/058/"
    },
    {
      "Istat": "002045",
      "Comune": "Collobiano",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "C884",
      "Abitanti": 108,
      "Link": "http://www.comuni-italiani.it/002/045/"
    },
    {
      "Istat": "030028",
      "Comune": "Colloredo di Monte Albano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "C885",
      "Abitanti": 2245,
      "Link": "http://www.comuni-italiani.it/030/028/"
    },
    {
      "Istat": "043014",
      "Comune": "Colmurano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "C886",
      "Abitanti": 1289,
      "Link": "http://www.comuni-italiani.it/043/014/"
    },
    {
      "Istat": "077006",
      "Comune": "Colobraro",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75021,
      "CodFisco": "C888",
      "Abitanti": 1378,
      "Link": "http://www.comuni-italiani.it/077/006/"
    },
    {
      "Istat": "023027",
      "Comune": "Cologna Veneta",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37044,
      "CodFisco": "C890",
      "Abitanti": 8665,
      "Link": "http://www.comuni-italiani.it/023/027/"
    },
    {
      "Istat": "017059",
      "Comune": "Cologne",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25033,
      "CodFisco": "C893",
      "Abitanti": 7600,
      "Link": "http://www.comuni-italiani.it/017/059/"
    },
    {
      "Istat": "016079",
      "Comune": "Cologno al Serio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24055,
      "CodFisco": "C894",
      "Abitanti": 10759,
      "Link": "http://www.comuni-italiani.it/016/079/"
    },
    {
      "Istat": "015081",
      "Comune": "Cologno Monzese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20093,
      "CodFisco": "C895",
      "Abitanti": 47794,
      "Link": "http://www.comuni-italiani.it/015/081/"
    },
    {
      "Istat": "023028",
      "Comune": "Colognola ai Colli",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "C897",
      "Abitanti": 8403,
      "Link": "http://www.comuni-italiani.it/023/028/"
    },
    {
      "Istat": "058035",
      "Comune": "Colonna",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "C900",
      "Abitanti": 4016,
      "Link": "http://www.comuni-italiani.it/058/035/"
    },
    {
      "Istat": "067019",
      "Comune": "Colonnella",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "C901",
      "Abitanti": 3747,
      "Link": "http://www.comuni-italiani.it/067/019/"
    },
    {
      "Istat": "013074",
      "Comune": "Colonno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22010,
      "CodFisco": "C902",
      "Abitanti": 539,
      "Link": "http://www.comuni-italiani.it/013/074/"
    },
    {
      "Istat": "014023",
      "Comune": "Colorina",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "C903",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/014/023/"
    },
    {
      "Istat": "034010",
      "Comune": "Colorno",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43052,
      "CodFisco": "C904",
      "Abitanti": 9096,
      "Link": "http://www.comuni-italiani.it/034/010/"
    },
    {
      "Istat": "078043",
      "Comune": "Colosimi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "C905",
      "Abitanti": 1333,
      "Link": "http://www.comuni-italiani.it/078/043/"
    },
    {
      "Istat": "015082",
      "Comune": "Colturano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "C908",
      "Abitanti": 1975,
      "Link": "http://www.comuni-italiani.it/015/082/"
    },
    {
      "Istat": "016080",
      "Comune": "Colzate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "C910",
      "Abitanti": 1674,
      "Link": "http://www.comuni-italiani.it/016/080/"
    },
    {
      "Istat": "012054",
      "Comune": "Comabbio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21020,
      "CodFisco": "C911",
      "Abitanti": 1181,
      "Link": "http://www.comuni-italiani.it/012/054/"
    },
    {
      "Istat": "038006",
      "Comune": "Comacchio",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44022,
      "CodFisco": "C912",
      "Abitanti": 23122,
      "Link": "http://www.comuni-italiani.it/038/006/"
    },
    {
      "Istat": "045005",
      "Comune": "Comano",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54015,
      "CodFisco": "C914",
      "Abitanti": 774,
      "Link": "http://www.comuni-italiani.it/045/005/"
    },
    {
      "Istat": "022228",
      "Comune": "Comano Terme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38077,
      "CodFisco": "M314",
      "Abitanti": 2930,
      "Link": "http://www.comuni-italiani.it/022/228/"
    },
    {
      "Istat": "098020",
      "Comune": "Comazzo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26833,
      "CodFisco": "C917",
      "Abitanti": 2155,
      "Link": "http://www.comuni-italiani.it/098/020/"
    },
    {
      "Istat": "030029",
      "Comune": "Comeglians",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33023,
      "CodFisco": "C918",
      "Abitanti": 540,
      "Link": "http://www.comuni-italiani.it/030/029/"
    },
    {
      "Istat": "025015",
      "Comune": "Comelico Superiore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "C920",
      "Abitanti": 2366,
      "Link": "http://www.comuni-italiani.it/025/015/"
    },
    {
      "Istat": "012055",
      "Comune": "Comerio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21025,
      "CodFisco": "C922",
      "Abitanti": 2668,
      "Link": "http://www.comuni-italiani.it/012/055/"
    },
    {
      "Istat": "017060",
      "Comune": "Comezzano-Cizzago",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "C925",
      "Abitanti": 3702,
      "Link": "http://www.comuni-italiani.it/017/060/"
    },
    {
      "Istat": "003052",
      "Comune": "Comignago",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28060,
      "CodFisco": "C926",
      "Abitanti": 1228,
      "Link": "http://www.comuni-italiani.it/003/052/"
    },
    {
      "Istat": "088003",
      "Comune": "Comiso",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97013,
      "CodFisco": "C927",
      "Abitanti": 30577,
      "Link": "http://www.comuni-italiani.it/088/003/"
    },
    {
      "Istat": "084016",
      "Comune": "Comitini",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "C928",
      "Abitanti": 946,
      "Link": "http://www.comuni-italiani.it/084/016/"
    },
    {
      "Istat": "063029",
      "Comune": "Comiziano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "C929",
      "Abitanti": 1821,
      "Link": "http://www.comuni-italiani.it/063/029/"
    },
    {
      "Istat": "020020",
      "Comune": "Commessaggio",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "C930",
      "Abitanti": 1180,
      "Link": "http://www.comuni-italiani.it/020/020/"
    },
    {
      "Istat": "022064",
      "Comune": "Commezzadura",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "C931",
      "Abitanti": 1004,
      "Link": "http://www.comuni-italiani.it/022/064/"
    },
    {
      "Istat": "013075",
      "Comune": "Como",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22100,
      "CodFisco": "C933",
      "Abitanti": 85263,
      "Link": "http://www.comuni-italiani.it/013/075/"
    },
    {
      "Istat": "034011",
      "Comune": "Compiano",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43053,
      "CodFisco": "C934",
      "Abitanti": 1131,
      "Link": "http://www.comuni-italiani.it/034/011/"
    },
    {
      "Istat": "016081",
      "Comune": "Comun Nuovo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "C937",
      "Abitanti": 4144,
      "Link": "http://www.comuni-italiani.it/016/081/"
    },
    {
      "Istat": "044015",
      "Comune": "Comunanza",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63087,
      "CodFisco": "C935",
      "Abitanti": 3214,
      "Link": "http://www.comuni-italiani.it/044/015/"
    },
    {
      "Istat": "027010",
      "Comune": "Cona",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 30010,
      "CodFisco": "C938",
      "Abitanti": 3199,
      "Link": "http://www.comuni-italiani.it/027/010/"
    },
    {
      "Istat": "094018",
      "Comune": "Conca Casale",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "C941",
      "Abitanti": 218,
      "Link": "http://www.comuni-italiani.it/094/018/"
    },
    {
      "Istat": "065044",
      "Comune": "Conca dei Marini",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "C940",
      "Abitanti": 739,
      "Link": "http://www.comuni-italiani.it/065/044/"
    },
    {
      "Istat": "061031",
      "Comune": "Conca della Campania",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81044,
      "CodFisco": "C939",
      "Abitanti": 1292,
      "Link": "http://www.comuni-italiani.it/061/031/"
    },
    {
      "Istat": "023029",
      "Comune": "Concamarise",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37050,
      "CodFisco": "C943",
      "Abitanti": 1066,
      "Link": "http://www.comuni-italiani.it/023/029/"
    },
    {
      "Istat": "057023",
      "Comune": "Concerviano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "C946",
      "Abitanti": 319,
      "Link": "http://www.comuni-italiani.it/057/023/"
    },
    {
      "Istat": "017061",
      "Comune": "Concesio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25062,
      "CodFisco": "C948",
      "Abitanti": 15005,
      "Link": "http://www.comuni-italiani.it/017/061/"
    },
    {
      "Istat": "024033",
      "Comune": "Conco",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36062,
      "CodFisco": "C949",
      "Abitanti": 2200,
      "Link": "http://www.comuni-italiani.it/024/033/"
    },
    {
      "Istat": "027011",
      "Comune": "Concordia Sagittaria",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30023,
      "CodFisco": "C950",
      "Abitanti": 10716,
      "Link": "http://www.comuni-italiani.it/027/011/"
    },
    {
      "Istat": "036010",
      "Comune": "Concordia sulla Secchia",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41033,
      "CodFisco": "C951",
      "Abitanti": 9059,
      "Link": "http://www.comuni-italiani.it/036/010/"
    },
    {
      "Istat": 108021,
      "Comune": "Concorezzo",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20863,
      "CodFisco": "C952",
      "Abitanti": 15371,
      "Link": "http://www.comuni-italiani.it/108/021/"
    },
    {
      "Istat": "022066",
      "Comune": "Condino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38083,
      "CodFisco": "C953",
      "Abitanti": 1512,
      "Link": "http://www.comuni-italiani.it/022/066/"
    },
    {
      "Istat": "080029",
      "Comune": "Condofuri",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89030,
      "CodFisco": "C954",
      "Abitanti": 4977,
      "Link": "http://www.comuni-italiani.it/080/029/"
    },
    {
      "Istat": "001093",
      "Comune": "Condove",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10055,
      "CodFisco": "C955",
      "Abitanti": 4704,
      "Link": "http://www.comuni-italiani.it/001/093/"
    },
    {
      "Istat": "083018",
      "Comune": "CondrÚ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "C956",
      "Abitanti": 495,
      "Link": "http://www.comuni-italiani.it/083/018/"
    },
    {
      "Istat": "026021",
      "Comune": "Conegliano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31015,
      "CodFisco": "C957",
      "Abitanti": 35748,
      "Link": "http://www.comuni-italiani.it/026/021/"
    },
    {
      "Istat": "018052",
      "Comune": "Confienza",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "C958",
      "Abitanti": 1663,
      "Link": "http://www.comuni-italiani.it/018/052/"
    },
    {
      "Istat": "057024",
      "Comune": "Configni",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02040",
      "CodFisco": "C959",
      "Abitanti": 692,
      "Link": "http://www.comuni-italiani.it/057/024/"
    },
    {
      "Istat": "079033",
      "Comune": "Conflenti",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "C960",
      "Abitanti": 1470,
      "Link": "http://www.comuni-italiani.it/079/033/"
    },
    {
      "Istat": "006060",
      "Comune": "Coniolo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "C962",
      "Abitanti": 467,
      "Link": "http://www.comuni-italiani.it/006/060/"
    },
    {
      "Istat": "039008",
      "Comune": "Conselice",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48017,
      "CodFisco": "C963",
      "Abitanti": 10028,
      "Link": "http://www.comuni-italiani.it/039/008/"
    },
    {
      "Istat": "028034",
      "Comune": "Conselve",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35026,
      "CodFisco": "C964",
      "Abitanti": 10486,
      "Link": "http://www.comuni-italiani.it/028/034/"
    },
    {
      "Istat": "082033",
      "Comune": "Contessa Entellina",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "C968",
      "Abitanti": 1917,
      "Link": "http://www.comuni-italiani.it/082/033/"
    },
    {
      "Istat": "057025",
      "Comune": "Contigliano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02043",
      "CodFisco": "C969",
      "Abitanti": 3676,
      "Link": "http://www.comuni-italiani.it/057/025/"
    },
    {
      "Istat": "064029",
      "Comune": "Contrada",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83020,
      "CodFisco": "C971",
      "Abitanti": 3020,
      "Link": "http://www.comuni-italiani.it/064/029/"
    },
    {
      "Istat": "067020",
      "Comune": "Controguerra",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "C972",
      "Abitanti": 2491,
      "Link": "http://www.comuni-italiani.it/067/020/"
    },
    {
      "Istat": "065045",
      "Comune": "Controne",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "C973",
      "Abitanti": 909,
      "Link": "http://www.comuni-italiani.it/065/045/"
    },
    {
      "Istat": "065046",
      "Comune": "Contursi Terme",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84024,
      "CodFisco": "C974",
      "Abitanti": 3367,
      "Link": "http://www.comuni-italiani.it/065/046/"
    },
    {
      "Istat": "072019",
      "Comune": "Conversano",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70014,
      "CodFisco": "C975",
      "Abitanti": 25760,
      "Link": "http://www.comuni-italiani.it/072/019/"
    },
    {
      "Istat": "064030",
      "Comune": "Conza della Campania",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "C976",
      "Abitanti": 1447,
      "Link": "http://www.comuni-italiani.it/064/030/"
    },
    {
      "Istat": "006061",
      "Comune": "Conzano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "C977",
      "Abitanti": 1009,
      "Link": "http://www.comuni-italiani.it/006/061/"
    },
    {
      "Istat": "075022",
      "Comune": "Copertino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73043,
      "CodFisco": "C978",
      "Abitanti": 24527,
      "Link": "http://www.comuni-italiani.it/075/022/"
    },
    {
      "Istat": "018053",
      "Comune": "Copiano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "C979",
      "Abitanti": 1801,
      "Link": "http://www.comuni-italiani.it/018/053/"
    },
    {
      "Istat": "038007",
      "Comune": "Copparo",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44034,
      "CodFisco": "C980",
      "Abitanti": 17245,
      "Link": "http://www.comuni-italiani.it/038/007/"
    },
    {
      "Istat": "018054",
      "Comune": "Corana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "C982",
      "Abitanti": 781,
      "Link": "http://www.comuni-italiani.it/018/054/"
    },
    {
      "Istat": "072020",
      "Comune": "Corato",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70033,
      "CodFisco": "C983",
      "Abitanti": 48101,
      "Link": "http://www.comuni-italiani.it/072/020/"
    },
    {
      "Istat": "065047",
      "Comune": "Corbara",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84010,
      "CodFisco": "C984",
      "Abitanti": 2594,
      "Link": "http://www.comuni-italiani.it/065/047/"
    },
    {
      "Istat": "015085",
      "Comune": "Corbetta",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20011,
      "CodFisco": "C986",
      "Abitanti": 17365,
      "Link": "http://www.comuni-italiani.it/015/085/"
    },
    {
      "Istat": "029017",
      "Comune": "Corbola",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45015,
      "CodFisco": "C987",
      "Abitanti": 2538,
      "Link": "http://www.comuni-italiani.it/029/017/"
    },
    {
      "Istat": "056023",
      "Comune": "Corchiano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "C988",
      "Abitanti": 3838,
      "Link": "http://www.comuni-italiani.it/056/023/"
    },
    {
      "Istat": "054015",
      "Comune": "Corciano",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06073",
      "CodFisco": "C990",
      "Abitanti": 20972,
      "Link": "http://www.comuni-italiani.it/054/015/"
    },
    {
      "Istat": "093017",
      "Comune": "Cordenons",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33084,
      "CodFisco": "C991",
      "Abitanti": 18470,
      "Link": "http://www.comuni-italiani.it/093/017/"
    },
    {
      "Istat": "026022",
      "Comune": "Cordignano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31016,
      "CodFisco": "C992",
      "Abitanti": 7141,
      "Link": "http://www.comuni-italiani.it/026/022/"
    },
    {
      "Istat": "093018",
      "Comune": "Cordovado",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33075,
      "CodFisco": "C993",
      "Abitanti": 2759,
      "Link": "http://www.comuni-italiani.it/093/018/"
    },
    {
      "Istat": "022067",
      "Comune": "Coredo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "C994",
      "Abitanti": 1645,
      "Link": "http://www.comuni-italiani.it/022/067/"
    },
    {
      "Istat": "046011",
      "Comune": "Coreglia Antelminelli",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55025,
      "CodFisco": "C996",
      "Abitanti": 5327,
      "Link": "http://www.comuni-italiani.it/046/011/"
    },
    {
      "Istat": "010019",
      "Comune": "Coreglia Ligure",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "C995",
      "Abitanti": 273,
      "Link": "http://www.comuni-italiani.it/010/019/"
    },
    {
      "Istat": "060030",
      "Comune": "Coreno Ausonio",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "C998",
      "Abitanti": 1691,
      "Link": "http://www.comuni-italiani.it/060/030/"
    },
    {
      "Istat": "066041",
      "Comune": "Corfinio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "C999",
      "Abitanti": 1067,
      "Link": "http://www.comuni-italiani.it/066/041/"
    },
    {
      "Istat": "059006",
      "Comune": "Cori",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "04010",
      "CodFisco": "D003",
      "Abitanti": 11284,
      "Link": "http://www.comuni-italiani.it/059/006/"
    },
    {
      "Istat": "099003",
      "Comune": "Coriano",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47853,
      "CodFisco": "D004",
      "Abitanti": 10200,
      "Link": "http://www.comuni-italiani.it/099/003/"
    },
    {
      "Istat": "078044",
      "Comune": "Corigliano Calabro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87064,
      "CodFisco": "D005",
      "Abitanti": 40548,
      "Link": "http://www.comuni-italiani.it/078/044/"
    },
    {
      "Istat": "075023",
      "Comune": "Corigliano d'Otranto",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73022,
      "CodFisco": "D006",
      "Abitanti": 5889,
      "Link": "http://www.comuni-italiani.it/075/023/"
    },
    {
      "Istat": "042015",
      "Comune": "Corinaldo",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60013,
      "CodFisco": "D007",
      "Abitanti": 5152,
      "Link": "http://www.comuni-italiani.it/042/015/"
    },
    {
      "Istat": "001094",
      "Comune": "Corio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "D008",
      "Abitanti": 3410,
      "Link": "http://www.comuni-italiani.it/001/094/"
    },
    {
      "Istat": "082034",
      "Comune": "Corleone",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90034,
      "CodFisco": "D009",
      "Abitanti": 11373,
      "Link": "http://www.comuni-italiani.it/082/034/"
    },
    {
      "Istat": "065048",
      "Comune": "Corleto Monforte",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "D011",
      "Abitanti": 672,
      "Link": "http://www.comuni-italiani.it/065/048/"
    },
    {
      "Istat": "076029",
      "Comune": "Corleto Perticara",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85012,
      "CodFisco": "D010",
      "Abitanti": 2628,
      "Link": "http://www.comuni-italiani.it/076/029/"
    },
    {
      "Istat": "015086",
      "Comune": "Cormano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20032,
      "CodFisco": "D013",
      "Abitanti": 20270,
      "Link": "http://www.comuni-italiani.it/015/086/"
    },
    {
      "Istat": "031002",
      "Comune": "Cormons",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34071,
      "CodFisco": "D014",
      "Abitanti": 7698,
      "Link": "http://www.comuni-italiani.it/031/002/"
    },
    {
      "Istat": "016082",
      "Comune": "Corna Imagna",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "D015",
      "Abitanti": 969,
      "Link": "http://www.comuni-italiani.it/016/082/"
    },
    {
      "Istat": "016249",
      "Comune": "Cornalba",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24017,
      "CodFisco": "D016",
      "Abitanti": 310,
      "Link": "http://www.comuni-italiani.it/016/249/"
    },
    {
      "Istat": "018055",
      "Comune": "Cornale",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "D017",
      "Abitanti": 747,
      "Link": "http://www.comuni-italiani.it/018/055/"
    },
    {
      "Istat": "015087",
      "Comune": "Cornaredo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "D018",
      "Abitanti": 20546,
      "Link": "http://www.comuni-italiani.it/015/087/"
    },
    {
      "Istat": 108053,
      "Comune": "Cornate d'Adda",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20872,
      "CodFisco": "D019",
      "Abitanti": 10464,
      "Link": "http://www.comuni-italiani.it/108/053/"
    },
    {
      "Istat": "021023",
      "Comune": "Cornedo all'Isarco",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39053,
      "CodFisco": "B799",
      "Abitanti": 3307,
      "Link": "http://www.comuni-italiani.it/021/023/"
    },
    {
      "Istat": "024034",
      "Comune": "Cornedo Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36073,
      "CodFisco": "D020",
      "Abitanti": 12042,
      "Link": "http://www.comuni-italiani.it/024/034/"
    },
    {
      "Istat": "098021",
      "Comune": "Cornegliano Laudense",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26854,
      "CodFisco": "D021",
      "Abitanti": 2881,
      "Link": "http://www.comuni-italiani.it/098/021/"
    },
    {
      "Istat": "004072",
      "Comune": "Corneliano d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "D022",
      "Abitanti": 2091,
      "Link": "http://www.comuni-italiani.it/004/072/"
    },
    {
      "Istat": "034012",
      "Comune": "Corniglio",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43021,
      "CodFisco": "D026",
      "Abitanti": 2071,
      "Link": "http://www.comuni-italiani.it/034/012/"
    },
    {
      "Istat": "030030",
      "Comune": "Corno di Rosazzo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "D027",
      "Abitanti": 3284,
      "Link": "http://www.comuni-italiani.it/030/030/"
    },
    {
      "Istat": "098022",
      "Comune": "Corno Giovine",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26846,
      "CodFisco": "D028",
      "Abitanti": 1196,
      "Link": "http://www.comuni-italiani.it/098/022/"
    },
    {
      "Istat": "098023",
      "Comune": "Cornovecchio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26842,
      "CodFisco": "D029",
      "Abitanti": 239,
      "Link": "http://www.comuni-italiani.it/098/023/"
    },
    {
      "Istat": "026023",
      "Comune": "Cornuda",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31041,
      "CodFisco": "D030",
      "Abitanti": 6312,
      "Link": "http://www.comuni-italiani.it/026/023/"
    },
    {
      "Istat": "035020",
      "Comune": "Correggio",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42015,
      "CodFisco": "D037",
      "Abitanti": 25395,
      "Link": "http://www.comuni-italiani.it/035/020/"
    },
    {
      "Istat": 108022,
      "Comune": "Correzzana",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20856,
      "CodFisco": "D038",
      "Abitanti": 2648,
      "Link": "http://www.comuni-italiani.it/108/022/"
    },
    {
      "Istat": "028035",
      "Comune": "Correzzola",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "D040",
      "Abitanti": 5574,
      "Link": "http://www.comuni-italiani.it/028/035/"
    },
    {
      "Istat": "013077",
      "Comune": "Corrido",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "D041",
      "Abitanti": 823,
      "Link": "http://www.comuni-italiani.it/013/077/"
    },
    {
      "Istat": "043015",
      "Comune": "Corridonia",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62014,
      "CodFisco": "D042",
      "Abitanti": 15505,
      "Link": "http://www.comuni-italiani.it/043/015/"
    },
    {
      "Istat": "067021",
      "Comune": "Corropoli",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64013,
      "CodFisco": "D043",
      "Abitanti": 4753,
      "Link": "http://www.comuni-italiani.it/067/021/"
    },
    {
      "Istat": "075024",
      "Comune": "Corsano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73033,
      "CodFisco": "D044",
      "Abitanti": 5693,
      "Link": "http://www.comuni-italiani.it/075/024/"
    },
    {
      "Istat": "015093",
      "Comune": "Corsico",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20094,
      "CodFisco": "D045",
      "Abitanti": 34507,
      "Link": "http://www.comuni-italiani.it/015/093/"
    },
    {
      "Istat": "005044",
      "Comune": "Corsione",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "D046",
      "Abitanti": 209,
      "Link": "http://www.comuni-italiani.it/005/044/"
    },
    {
      "Istat": "021024",
      "Comune": "Cortaccia sulla Strada del Vino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "D048",
      "Abitanti": 2254,
      "Link": "http://www.comuni-italiani.it/021/024/"
    },
    {
      "Istat": "079034",
      "Comune": "Cortale",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88020,
      "CodFisco": "D049",
      "Abitanti": 2309,
      "Link": "http://www.comuni-italiani.it/079/034/"
    },
    {
      "Istat": "005045",
      "Comune": "Cortandone",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14013,
      "CodFisco": "D050",
      "Abitanti": 316,
      "Link": "http://www.comuni-italiani.it/005/045/"
    },
    {
      "Istat": "005046",
      "Comune": "Cortanze",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "D051",
      "Abitanti": 282,
      "Link": "http://www.comuni-italiani.it/005/046/"
    },
    {
      "Istat": "005047",
      "Comune": "Cortazzone",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "D052",
      "Abitanti": 682,
      "Link": "http://www.comuni-italiani.it/005/047/"
    },
    {
      "Istat": "033017",
      "Comune": "Corte Brugnatella",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "D054",
      "Abitanti": 688,
      "Link": "http://www.comuni-italiani.it/033/017/"
    },
    {
      "Istat": "019032",
      "Comune": "Corte de' Cortesi con Cignone",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26020,
      "CodFisco": "D056",
      "Abitanti": 1137,
      "Link": "http://www.comuni-italiani.it/019/032/"
    },
    {
      "Istat": "019033",
      "Comune": "Corte de' Frati",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26010,
      "CodFisco": "D057",
      "Abitanti": 1453,
      "Link": "http://www.comuni-italiani.it/019/033/"
    },
    {
      "Istat": "017062",
      "Comune": "Corte Franca",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25040,
      "CodFisco": "D058",
      "Abitanti": 7189,
      "Link": "http://www.comuni-italiani.it/017/062/"
    },
    {
      "Istat": "098024",
      "Comune": "Corte Palasio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26834,
      "CodFisco": "D068",
      "Abitanti": 1597,
      "Link": "http://www.comuni-italiani.it/098/024/"
    },
    {
      "Istat": "033018",
      "Comune": "Cortemaggiore",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29016,
      "CodFisco": "D061",
      "Abitanti": 4552,
      "Link": "http://www.comuni-italiani.it/033/018/"
    },
    {
      "Istat": "004073",
      "Comune": "Cortemilia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12074,
      "CodFisco": "D062",
      "Abitanti": 2454,
      "Link": "http://www.comuni-italiani.it/004/073/"
    },
    {
      "Istat": "017063",
      "Comune": "Corteno Golgi",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "D064",
      "Abitanti": 2028,
      "Link": "http://www.comuni-italiani.it/017/063/"
    },
    {
      "Istat": "097025",
      "Comune": "Cortenova",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23813,
      "CodFisco": "D065",
      "Abitanti": 1295,
      "Link": "http://www.comuni-italiani.it/097/025/"
    },
    {
      "Istat": "016083",
      "Comune": "Cortenuova",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "D066",
      "Abitanti": 1959,
      "Link": "http://www.comuni-italiani.it/016/083/"
    },
    {
      "Istat": "018056",
      "Comune": "Corteolona",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27014,
      "CodFisco": "D067",
      "Abitanti": 2201,
      "Link": "http://www.comuni-italiani.it/018/056/"
    },
    {
      "Istat": "005048",
      "Comune": "Cortiglione",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "D072",
      "Abitanti": 613,
      "Link": "http://www.comuni-italiani.it/005/048/"
    },
    {
      "Istat": "025016",
      "Comune": "Cortina d'Ampezzo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0436",
      "CAP": 32043,
      "CodFisco": "A266",
      "Abitanti": 6097,
      "Link": "http://www.comuni-italiani.it/025/016/"
    },
    {
      "Istat": "021025",
      "Comune": "Cortina sulla Strada del Vino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "D075",
      "Abitanti": 645,
      "Link": "http://www.comuni-italiani.it/021/025/"
    },
    {
      "Istat": "067022",
      "Comune": "Cortino",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64040,
      "CodFisco": "D076",
      "Abitanti": 709,
      "Link": "http://www.comuni-italiani.it/067/022/"
    },
    {
      "Istat": "051017",
      "Comune": "Cortona",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52044,
      "CodFisco": "D077",
      "Abitanti": 23036,
      "Link": "http://www.comuni-italiani.it/051/017/"
    },
    {
      "Istat": "068016",
      "Comune": "Corvara",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "D078",
      "Abitanti": 288,
      "Link": "http://www.comuni-italiani.it/068/016/"
    },
    {
      "Istat": "021026",
      "Comune": "Corvara in Badia",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39033,
      "CodFisco": "D079",
      "Abitanti": 1335,
      "Link": "http://www.comuni-italiani.it/021/026/"
    },
    {
      "Istat": "018057",
      "Comune": "Corvino San Quirico",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "D081",
      "Abitanti": 1053,
      "Link": "http://www.comuni-italiani.it/018/057/"
    },
    {
      "Istat": "017064",
      "Comune": "Corzano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "D082",
      "Abitanti": 1358,
      "Link": "http://www.comuni-italiani.it/017/064/"
    },
    {
      "Istat": "030031",
      "Comune": "Coseano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "D085",
      "Abitanti": 2262,
      "Link": "http://www.comuni-italiani.it/030/031/"
    },
    {
      "Istat": "078045",
      "Comune": "Cosenza",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87100,
      "CodFisco": "D086",
      "Abitanti": 70068,
      "Link": "http://www.comuni-italiani.it/078/045/"
    },
    {
      "Istat": "008023",
      "Comune": "Cosio d'Arroscia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18023,
      "CodFisco": "D087",
      "Abitanti": 252,
      "Link": "http://www.comuni-italiani.it/008/023/"
    },
    {
      "Istat": "014024",
      "Comune": "Cosio Valtellino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23013,
      "CodFisco": "D088",
      "Abitanti": 5429,
      "Link": "http://www.comuni-italiani.it/014/024/"
    },
    {
      "Istat": "080030",
      "Comune": "Cosoleto",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89050,
      "CodFisco": "D089",
      "Abitanti": 942,
      "Link": "http://www.comuni-italiani.it/080/030/"
    },
    {
      "Istat": "004074",
      "Comune": "Cossano Belbo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 12054,
      "CodFisco": "D093",
      "Abitanti": 1042,
      "Link": "http://www.comuni-italiani.it/004/074/"
    },
    {
      "Istat": "001095",
      "Comune": "Cossano Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "D092",
      "Abitanti": 528,
      "Link": "http://www.comuni-italiani.it/001/095/"
    },
    {
      "Istat": "096020",
      "Comune": "Cossato",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13836,
      "CodFisco": "D094",
      "Abitanti": 15010,
      "Link": "http://www.comuni-italiani.it/096/020/"
    },
    {
      "Istat": "009026",
      "Comune": "Cosseria",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17017,
      "CodFisco": "D095",
      "Abitanti": 1084,
      "Link": "http://www.comuni-italiani.it/009/026/"
    },
    {
      "Istat": "044016",
      "Comune": "Cossignano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63067,
      "CodFisco": "D096",
      "Abitanti": 1019,
      "Link": "http://www.comuni-italiani.it/044/016/"
    },
    {
      "Istat": 103023,
      "Comune": "Cossogno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28801,
      "CodFisco": "D099",
      "Abitanti": 595,
      "Link": "http://www.comuni-italiani.it/103/023/"
    },
    {
      "Istat": "090027",
      "Comune": "Cossoine",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "D100",
      "Abitanti": 914,
      "Link": "http://www.comuni-italiani.it/090/027/"
    },
    {
      "Istat": "005049",
      "Comune": "Cossombrato",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "D101",
      "Abitanti": 521,
      "Link": "http://www.comuni-italiani.it/005/049/"
    },
    {
      "Istat": "018058",
      "Comune": "Costa de' Nobili",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "D109",
      "Abitanti": 360,
      "Link": "http://www.comuni-italiani.it/018/058/"
    },
    {
      "Istat": "016084",
      "Comune": "Costa di Mezzate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D110",
      "Abitanti": 3369,
      "Link": "http://www.comuni-italiani.it/016/084/"
    },
    {
      "Istat": "029018",
      "Comune": "Costa di Rovigo",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45023,
      "CodFisco": "D105",
      "Abitanti": 2739,
      "Link": "http://www.comuni-italiani.it/029/018/"
    },
    {
      "Istat": "097026",
      "Comune": "Costa Masnaga",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23845,
      "CodFisco": "D112",
      "Abitanti": 4806,
      "Link": "http://www.comuni-italiani.it/097/026/"
    },
    {
      "Istat": "016247",
      "Comune": "Costa Serina",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "D111",
      "Abitanti": 980,
      "Link": "http://www.comuni-italiani.it/016/247/"
    },
    {
      "Istat": "016085",
      "Comune": "Costa Valle Imagna",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "D103",
      "Abitanti": 624,
      "Link": "http://www.comuni-italiani.it/016/085/"
    },
    {
      "Istat": "006062",
      "Comune": "Costa Vescovato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "D102",
      "Abitanti": 369,
      "Link": "http://www.comuni-italiani.it/006/062/"
    },
    {
      "Istat": "016086",
      "Comune": "Costa Volpino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24062,
      "CodFisco": "D117",
      "Abitanti": 9333,
      "Link": "http://www.comuni-italiani.it/016/086/"
    },
    {
      "Istat": "024035",
      "Comune": "Costabissara",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36030,
      "CodFisco": "D107",
      "Abitanti": 7131,
      "Link": "http://www.comuni-italiani.it/024/035/"
    },
    {
      "Istat": "054016",
      "Comune": "Costacciaro",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06021",
      "CodFisco": "D108",
      "Abitanti": 1319,
      "Link": "http://www.comuni-italiani.it/054/016/"
    },
    {
      "Istat": "002047",
      "Comune": "Costanzana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13033,
      "CodFisco": "D113",
      "Abitanti": 831,
      "Link": "http://www.comuni-italiani.it/002/047/"
    },
    {
      "Istat": "008024",
      "Comune": "Costarainera",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18017,
      "CodFisco": "D114",
      "Abitanti": 836,
      "Link": "http://www.comuni-italiani.it/008/024/"
    },
    {
      "Istat": "023030",
      "Comune": "Costermano",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "D118",
      "Abitanti": 3614,
      "Link": "http://www.comuni-italiani.it/023/030/"
    },
    {
      "Istat": "005050",
      "Comune": "Costigliole d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14055,
      "CodFisco": "D119",
      "Abitanti": 6111,
      "Link": "http://www.comuni-italiani.it/005/050/"
    },
    {
      "Istat": "004075",
      "Comune": "Costigliole Saluzzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12024,
      "CodFisco": "D120",
      "Abitanti": 3349,
      "Link": "http://www.comuni-italiani.it/004/075/"
    },
    {
      "Istat": "039009",
      "Comune": "Cotignola",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48010,
      "CodFisco": "D121",
      "Abitanti": 7414,
      "Link": "http://www.comuni-italiani.it/039/009/"
    },
    {
      "Istat": 101009,
      "Comune": "Cotronei",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88836,
      "CodFisco": "D123",
      "Abitanti": 5490,
      "Link": "http://www.comuni-italiani.it/101/009/"
    },
    {
      "Istat": "057026",
      "Comune": "Cottanello",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02040",
      "CodFisco": "D124",
      "Abitanti": 572,
      "Link": "http://www.comuni-italiani.it/057/026/"
    },
    {
      "Istat": "007022",
      "Comune": "Courmayeur",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11013,
      "CodFisco": "D012",
      "Abitanti": 2877,
      "Link": "http://www.comuni-italiani.it/007/022/"
    },
    {
      "Istat": "016087",
      "Comune": "Covo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "D126",
      "Abitanti": 4140,
      "Link": "http://www.comuni-italiani.it/016/087/"
    },
    {
      "Istat": "018059",
      "Comune": "Cozzo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "D127",
      "Abitanti": 372,
      "Link": "http://www.comuni-italiani.it/018/059/"
    },
    {
      "Istat": "077007",
      "Comune": "Craco",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "D128",
      "Abitanti": 775,
      "Link": "http://www.comuni-italiani.it/077/007/"
    },
    {
      "Istat": "097027",
      "Comune": "Crandola Valsassina",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23832,
      "CodFisco": "D131",
      "Abitanti": 267,
      "Link": "http://www.comuni-italiani.it/097/027/"
    },
    {
      "Istat": "002048",
      "Comune": "Cravagliana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "D132",
      "Abitanti": 272,
      "Link": "http://www.comuni-italiani.it/002/048/"
    },
    {
      "Istat": "004076",
      "Comune": "Cravanzana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "D133",
      "Abitanti": 399,
      "Link": "http://www.comuni-italiani.it/004/076/"
    },
    {
      "Istat": 103024,
      "Comune": "Craveggia",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28852,
      "CodFisco": "D134",
      "Abitanti": 754,
      "Link": "http://www.comuni-italiani.it/103/024/"
    },
    {
      "Istat": "024036",
      "Comune": "Creazzo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36051,
      "CodFisco": "D136",
      "Abitanti": 11231,
      "Link": "http://www.comuni-italiani.it/024/036/"
    },
    {
      "Istat": "069027",
      "Comune": "Crecchio",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66014,
      "CodFisco": "D137",
      "Abitanti": 3033,
      "Link": "http://www.comuni-italiani.it/069/027/"
    },
    {
      "Istat": "016088",
      "Comune": "Credaro",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D139",
      "Abitanti": 3400,
      "Link": "http://www.comuni-italiani.it/016/088/"
    },
    {
      "Istat": "019034",
      "Comune": "Credera Rubbiano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "D141",
      "Abitanti": 1654,
      "Link": "http://www.comuni-italiani.it/019/034/"
    },
    {
      "Istat": "019035",
      "Comune": "Crema",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26013,
      "CodFisco": "D142",
      "Abitanti": 34144,
      "Link": "http://www.comuni-italiani.it/019/035/"
    },
    {
      "Istat": "097028",
      "Comune": "Cremella",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23894,
      "CodFisco": "D143",
      "Abitanti": 1794,
      "Link": "http://www.comuni-italiani.it/097/028/"
    },
    {
      "Istat": "012056",
      "Comune": "Cremenaga",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "D144",
      "Abitanti": 801,
      "Link": "http://www.comuni-italiani.it/012/056/"
    },
    {
      "Istat": "097029",
      "Comune": "Cremeno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23814,
      "CodFisco": "D145",
      "Abitanti": 1428,
      "Link": "http://www.comuni-italiani.it/097/029/"
    },
    {
      "Istat": "013083",
      "Comune": "Cremia",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "D147",
      "Abitanti": 718,
      "Link": "http://www.comuni-italiani.it/013/083/"
    },
    {
      "Istat": "006063",
      "Comune": "Cremolino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15010,
      "CodFisco": "D149",
      "Abitanti": 1099,
      "Link": "http://www.comuni-italiani.it/006/063/"
    },
    {
      "Istat": "019036",
      "Comune": "Cremona",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26100,
      "CodFisco": "D150",
      "Abitanti": 72147,
      "Link": "http://www.comuni-italiani.it/019/036/"
    },
    {
      "Istat": "019037",
      "Comune": "Cremosano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "D151",
      "Abitanti": 1555,
      "Link": "http://www.comuni-italiani.it/019/037/"
    },
    {
      "Istat": "002049",
      "Comune": "Crescentino",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13044,
      "CodFisco": "D154",
      "Abitanti": 8086,
      "Link": "http://www.comuni-italiani.it/002/049/"
    },
    {
      "Istat": "024037",
      "Comune": "Crespadoro",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36070,
      "CodFisco": "D156",
      "Abitanti": 1512,
      "Link": "http://www.comuni-italiani.it/024/037/"
    },
    {
      "Istat": "026024",
      "Comune": "Crespano del Grappa",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31017,
      "CodFisco": "D157",
      "Abitanti": 4729,
      "Link": "http://www.comuni-italiani.it/026/024/"
    },
    {
      "Istat": "037023",
      "Comune": "Crespellano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40056,
      "CodFisco": "D158",
      "Abitanti": 9982,
      "Link": "http://www.comuni-italiani.it/037/023/"
    },
    {
      "Istat": "098025",
      "Comune": "Crespiatica",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26835,
      "CodFisco": "D159",
      "Abitanti": 2186,
      "Link": "http://www.comuni-italiani.it/098/025/"
    },
    {
      "Istat": "050013",
      "Comune": "Crespina",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56040,
      "CodFisco": "D160",
      "Abitanti": 4131,
      "Link": "http://www.comuni-italiani.it/050/013/"
    },
    {
      "Istat": "029019",
      "Comune": "Crespino",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "D161",
      "Abitanti": 2030,
      "Link": "http://www.comuni-italiani.it/029/019/"
    },
    {
      "Istat": "003055",
      "Comune": "Cressa",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28012,
      "CodFisco": "D162",
      "Abitanti": 1543,
      "Link": "http://www.comuni-italiani.it/003/055/"
    },
    {
      "Istat": "096021",
      "Comune": "Crevacuore",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13864,
      "CodFisco": "D165",
      "Abitanti": 1638,
      "Link": "http://www.comuni-italiani.it/096/021/"
    },
    {
      "Istat": "037024",
      "Comune": "Crevalcore",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40014,
      "CodFisco": "D166",
      "Abitanti": 13686,
      "Link": "http://www.comuni-italiani.it/037/024/"
    },
    {
      "Istat": 103025,
      "Comune": "Crevoladossola",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28865,
      "CodFisco": "D168",
      "Abitanti": 4765,
      "Link": "http://www.comuni-italiani.it/103/025/"
    },
    {
      "Istat": "063030",
      "Comune": "Crispano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80020,
      "CodFisco": "D170",
      "Abitanti": 12585,
      "Link": "http://www.comuni-italiani.it/063/030/"
    },
    {
      "Istat": "073004",
      "Comune": "Crispiano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74012,
      "CodFisco": "D171",
      "Abitanti": 13668,
      "Link": "http://www.comuni-italiani.it/073/004/"
    },
    {
      "Istat": "004077",
      "Comune": "Crissolo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "D172",
      "Abitanti": 174,
      "Link": "http://www.comuni-italiani.it/004/077/"
    },
    {
      "Istat": "010020",
      "Comune": "Crocefieschi",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "D175",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/010/020/"
    },
    {
      "Istat": "026025",
      "Comune": "Crocetta del Montello",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31035,
      "CodFisco": "C670",
      "Abitanti": 6090,
      "Link": "http://www.comuni-italiani.it/026/025/"
    },
    {
      "Istat": 103026,
      "Comune": "Crodo",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28862,
      "CodFisco": "D177",
      "Abitanti": 1482,
      "Link": "http://www.comuni-italiani.it/103/026/"
    },
    {
      "Istat": "067023",
      "Comune": "Crognaleto",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64043,
      "CodFisco": "D179",
      "Abitanti": 1451,
      "Link": "http://www.comuni-italiani.it/067/023/"
    },
    {
      "Istat": "078046",
      "Comune": "Cropalati",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "D180",
      "Abitanti": 1117,
      "Link": "http://www.comuni-italiani.it/078/046/"
    },
    {
      "Istat": "079036",
      "Comune": "Cropani",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88051,
      "CodFisco": "D181",
      "Abitanti": 4362,
      "Link": "http://www.comuni-italiani.it/079/036/"
    },
    {
      "Istat": "096022",
      "Comune": "Crosa",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13853,
      "CodFisco": "D182",
      "Abitanti": 352,
      "Link": "http://www.comuni-italiani.it/096/022/"
    },
    {
      "Istat": "078047",
      "Comune": "Crosia",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "D184",
      "Abitanti": 9532,
      "Link": "http://www.comuni-italiani.it/078/047/"
    },
    {
      "Istat": "012057",
      "Comune": "Crosio della Valle",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "D185",
      "Abitanti": 618,
      "Link": "http://www.comuni-italiani.it/012/057/"
    },
    {
      "Istat": 101010,
      "Comune": "Crotone",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88900,
      "CodFisco": "D122",
      "Abitanti": 61798,
      "Link": "http://www.comuni-italiani.it/101/010/"
    },
    {
      "Istat": "019038",
      "Comune": "Crotta d'Adda",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26020,
      "CodFisco": "D186",
      "Abitanti": 678,
      "Link": "http://www.comuni-italiani.it/019/038/"
    },
    {
      "Istat": "002052",
      "Comune": "Crova",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "D187",
      "Abitanti": 423,
      "Link": "http://www.comuni-italiani.it/002/052/"
    },
    {
      "Istat": "022068",
      "Comune": "Croviana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38027,
      "CodFisco": "D188",
      "Abitanti": 688,
      "Link": "http://www.comuni-italiani.it/022/068/"
    },
    {
      "Istat": 101011,
      "Comune": "Crucoli",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88812,
      "CodFisco": "D189",
      "Abitanti": 3326,
      "Link": "http://www.comuni-italiani.it/101/011/"
    },
    {
      "Istat": "012058",
      "Comune": "Cuasso al Monte",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "D192",
      "Abitanti": 3592,
      "Link": "http://www.comuni-italiani.it/012/058/"
    },
    {
      "Istat": "006064",
      "Comune": "Cuccaro Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "D194",
      "Abitanti": 346,
      "Link": "http://www.comuni-italiani.it/006/064/"
    },
    {
      "Istat": "065049",
      "Comune": "Cuccaro Vetere",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84050,
      "CodFisco": "D195",
      "Abitanti": 584,
      "Link": "http://www.comuni-italiani.it/065/049/"
    },
    {
      "Istat": "013084",
      "Comune": "Cucciago",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "D196",
      "Abitanti": 3472,
      "Link": "http://www.comuni-italiani.it/013/084/"
    },
    {
      "Istat": "001096",
      "Comune": "Cuceglio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10090,
      "CodFisco": "D197",
      "Abitanti": 1000,
      "Link": "http://www.comuni-italiani.it/001/096/"
    },
    {
      "Istat": "015096",
      "Comune": "Cuggiono",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20012,
      "CodFisco": "D198",
      "Abitanti": 8227,
      "Link": "http://www.comuni-italiani.it/015/096/"
    },
    {
      "Istat": "012059",
      "Comune": "Cugliate-Fabiasco",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "D199",
      "Abitanti": 3109,
      "Link": "http://www.comuni-italiani.it/012/059/"
    },
    {
      "Istat": "095019",
      "Comune": "Cuglieri",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09073",
      "CodFisco": "D200",
      "Abitanti": 2902,
      "Link": "http://www.comuni-italiani.it/095/019/"
    },
    {
      "Istat": "068017",
      "Comune": "Cugnoli",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "D201",
      "Abitanti": 1603,
      "Link": "http://www.comuni-italiani.it/068/017/"
    },
    {
      "Istat": "001097",
      "Comune": "Cumiana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "D202",
      "Abitanti": 7882,
      "Link": "http://www.comuni-italiani.it/001/097/"
    },
    {
      "Istat": "019039",
      "Comune": "Cumignano sul Naviglio",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "D203",
      "Abitanti": 442,
      "Link": "http://www.comuni-italiani.it/019/039/"
    },
    {
      "Istat": "012060",
      "Comune": "Cunardo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21035,
      "CodFisco": "D204",
      "Abitanti": 2939,
      "Link": "http://www.comuni-italiani.it/012/060/"
    },
    {
      "Istat": "004078",
      "Comune": "Cuneo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12100,
      "CodFisco": "D205",
      "Abitanti": 55714,
      "Link": "http://www.comuni-italiani.it/004/078/"
    },
    {
      "Istat": "022069",
      "Comune": "Cunevo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "D206",
      "Abitanti": 573,
      "Link": "http://www.comuni-italiani.it/022/069/"
    },
    {
      "Istat": "005051",
      "Comune": "Cunico",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14026,
      "CodFisco": "D207",
      "Abitanti": 545,
      "Link": "http://www.comuni-italiani.it/005/051/"
    },
    {
      "Istat": "001098",
      "Comune": "CuorgnË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10082,
      "CodFisco": "D208",
      "Abitanti": 10141,
      "Link": "http://www.comuni-italiani.it/001/098/"
    },
    {
      "Istat": "069028",
      "Comune": "Cupello",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66051,
      "CodFisco": "D209",
      "Abitanti": 4904,
      "Link": "http://www.comuni-italiani.it/069/028/"
    },
    {
      "Istat": "044017",
      "Comune": "Cupra Marittima",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63064,
      "CodFisco": "D210",
      "Abitanti": 5389,
      "Link": "http://www.comuni-italiani.it/044/017/"
    },
    {
      "Istat": "042016",
      "Comune": "Cupramontana",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60034,
      "CodFisco": "D211",
      "Abitanti": 4929,
      "Link": "http://www.comuni-italiani.it/042/016/"
    },
    {
      "Istat": "018060",
      "Comune": "Cura Carpignano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "B824",
      "Abitanti": 4215,
      "Link": "http://www.comuni-italiani.it/018/060/"
    },
    {
      "Istat": "095077",
      "Comune": "Curcuris",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "D214",
      "Abitanti": 314,
      "Link": "http://www.comuni-italiani.it/095/077/"
    },
    {
      "Istat": "003058",
      "Comune": "Cureggio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28060,
      "CodFisco": "D216",
      "Abitanti": 2603,
      "Link": "http://www.comuni-italiani.it/003/058/"
    },
    {
      "Istat": "012061",
      "Comune": "Curiglia con Monteviasco",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "D217",
      "Abitanti": 189,
      "Link": "http://www.comuni-italiani.it/012/061/"
    },
    {
      "Istat": "079039",
      "Comune": "Curinga",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88022,
      "CodFisco": "D218",
      "Abitanti": 6802,
      "Link": "http://www.comuni-italiani.it/079/039/"
    },
    {
      "Istat": "096023",
      "Comune": "Curino",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13865,
      "CodFisco": "D219",
      "Abitanti": 460,
      "Link": "http://www.comuni-italiani.it/096/023/"
    },
    {
      "Istat": "016089",
      "Comune": "Curno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24035,
      "CodFisco": "D221",
      "Abitanti": 7752,
      "Link": "http://www.comuni-italiani.it/016/089/"
    },
    {
      "Istat": "021027",
      "Comune": "Curon Venosta",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39027,
      "CodFisco": "D222",
      "Abitanti": 2447,
      "Link": "http://www.comuni-italiani.it/021/027/"
    },
    {
      "Istat": "075025",
      "Comune": "Cursi",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "D223",
      "Abitanti": 4280,
      "Link": "http://www.comuni-italiani.it/075/025/"
    },
    {
      "Istat": 103027,
      "Comune": "Cursolo-Orasso",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28827,
      "CodFisco": "D225",
      "Abitanti": 107,
      "Link": "http://www.comuni-italiani.it/103/027/"
    },
    {
      "Istat": "028036",
      "Comune": "Curtarolo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "D226",
      "Abitanti": 7227,
      "Link": "http://www.comuni-italiani.it/028/036/"
    },
    {
      "Istat": "020021",
      "Comune": "Curtatone",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "D227",
      "Abitanti": 14482,
      "Link": "http://www.comuni-italiani.it/020/021/"
    },
    {
      "Istat": "061032",
      "Comune": "Curti",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "D228",
      "Abitanti": 7234,
      "Link": "http://www.comuni-italiani.it/061/032/"
    },
    {
      "Istat": "015097",
      "Comune": "Cusago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "D229",
      "Abitanti": 3547,
      "Link": "http://www.comuni-italiani.it/015/097/"
    },
    {
      "Istat": "015098",
      "Comune": "Cusano Milanino",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20095,
      "CodFisco": "D231",
      "Abitanti": 19547,
      "Link": "http://www.comuni-italiani.it/015/098/"
    },
    {
      "Istat": "062026",
      "Comune": "Cusano Mutri",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82033,
      "CodFisco": "D230",
      "Abitanti": 4226,
      "Link": "http://www.comuni-italiani.it/062/026/"
    },
    {
      "Istat": "013085",
      "Comune": "Cusino",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "D232",
      "Abitanti": 245,
      "Link": "http://www.comuni-italiani.it/013/085/"
    },
    {
      "Istat": "016090",
      "Comune": "Cusio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "D233",
      "Abitanti": 265,
      "Link": "http://www.comuni-italiani.it/016/090/"
    },
    {
      "Istat": "081007",
      "Comune": "Custonaci",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91015,
      "CodFisco": "D234",
      "Abitanti": 5449,
      "Link": "http://www.comuni-italiani.it/081/007/"
    },
    {
      "Istat": "047004",
      "Comune": "Cutigliano",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51024,
      "CodFisco": "D235",
      "Abitanti": 1580,
      "Link": "http://www.comuni-italiani.it/047/004/"
    },
    {
      "Istat": 101012,
      "Comune": "Cutro",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88842,
      "CodFisco": "D236",
      "Abitanti": 10426,
      "Link": "http://www.comuni-italiani.it/101/012/"
    },
    {
      "Istat": "075026",
      "Comune": "Cutrofiano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "D237",
      "Abitanti": 9292,
      "Link": "http://www.comuni-italiani.it/075/026/"
    },
    {
      "Istat": "012062",
      "Comune": "Cuveglio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "D238",
      "Abitanti": 3443,
      "Link": "http://www.comuni-italiani.it/012/062/"
    },
    {
      "Istat": "012063",
      "Comune": "Cuvio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "D239",
      "Abitanti": 1688,
      "Link": "http://www.comuni-italiani.it/012/063/"
    },
    {
      "Istat": "022070",
      "Comune": "Daiano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "D243",
      "Abitanti": 709,
      "Link": "http://www.comuni-italiani.it/022/070/"
    },
    {
      "Istat": "015099",
      "Comune": "Dairago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "D244",
      "Abitanti": 5780,
      "Link": "http://www.comuni-italiani.it/015/099/"
    },
    {
      "Istat": "016091",
      "Comune": "Dalmine",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24044,
      "CodFisco": "D245",
      "Abitanti": 23266,
      "Link": "http://www.comuni-italiani.it/016/091/"
    },
    {
      "Istat": "022071",
      "Comune": "Dambel",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "D246",
      "Abitanti": 434,
      "Link": "http://www.comuni-italiani.it/022/071/"
    },
    {
      "Istat": "025017",
      "Comune": "Danta di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "D247",
      "Abitanti": 514,
      "Link": "http://www.comuni-italiani.it/025/017/"
    },
    {
      "Istat": "022072",
      "Comune": "Daone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "D248",
      "Abitanti": 603,
      "Link": "http://www.comuni-italiani.it/022/072/"
    },
    {
      "Istat": "022073",
      "Comune": "DarË",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "D250",
      "Abitanti": 259,
      "Link": "http://www.comuni-italiani.it/022/073/"
    },
    {
      "Istat": "017065",
      "Comune": "Darfo Boario Terme",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25047,
      "CodFisco": "D251",
      "Abitanti": 15751,
      "Link": "http://www.comuni-italiani.it/017/065/"
    },
    {
      "Istat": 102007,
      "Comune": "Das‡",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89832,
      "CodFisco": "D253",
      "Abitanti": 1216,
      "Link": "http://www.comuni-italiani.it/102/007/"
    },
    {
      "Istat": "010021",
      "Comune": "Davagna",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16022,
      "CodFisco": "D255",
      "Abitanti": 2018,
      "Link": "http://www.comuni-italiani.it/010/021/"
    },
    {
      "Istat": "012064",
      "Comune": "Daverio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "D256",
      "Abitanti": 3073,
      "Link": "http://www.comuni-italiani.it/012/064/"
    },
    {
      "Istat": "079042",
      "Comune": "Davoli",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "D257",
      "Abitanti": 5493,
      "Link": "http://www.comuni-italiani.it/079/042/"
    },
    {
      "Istat": "014025",
      "Comune": "Dazio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "D258",
      "Abitanti": 438,
      "Link": "http://www.comuni-italiani.it/014/025/"
    },
    {
      "Istat": "092015",
      "Comune": "Decimomannu",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09033",
      "CodFisco": "D259",
      "Abitanti": 7833,
      "Link": "http://www.comuni-italiani.it/092/015/"
    },
    {
      "Istat": "092016",
      "Comune": "Decimoputzu",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09010",
      "CodFisco": "D260",
      "Abitanti": 4278,
      "Link": "http://www.comuni-italiani.it/092/016/"
    },
    {
      "Istat": "079043",
      "Comune": "Decollatura",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88041,
      "CodFisco": "D261",
      "Abitanti": 3301,
      "Link": "http://www.comuni-italiani.it/079/043/"
    },
    {
      "Istat": "009027",
      "Comune": "Dego",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17058,
      "CodFisco": "D264",
      "Abitanti": 2006,
      "Link": "http://www.comuni-italiani.it/009/027/"
    },
    {
      "Istat": "011012",
      "Comune": "Deiva Marina",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19013,
      "CodFisco": "D265",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/011/012/"
    },
    {
      "Istat": "014026",
      "Comune": "Delebio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23014,
      "CodFisco": "D266",
      "Abitanti": 3215,
      "Link": "http://www.comuni-italiani.it/014/026/"
    },
    {
      "Istat": "085006",
      "Comune": "Delia",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 93010,
      "CodFisco": "D267",
      "Abitanti": 4558,
      "Link": "http://www.comuni-italiani.it/085/006/"
    },
    {
      "Istat": "080031",
      "Comune": "Delianuova",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89012,
      "CodFisco": "D268",
      "Abitanti": 3507,
      "Link": "http://www.comuni-italiani.it/080/031/"
    },
    {
      "Istat": "071022",
      "Comune": "Deliceto",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71026,
      "CodFisco": "D269",
      "Abitanti": 3947,
      "Link": "http://www.comuni-italiani.it/071/022/"
    },
    {
      "Istat": "017066",
      "Comune": "Dello",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "D270",
      "Abitanti": 5575,
      "Link": "http://www.comuni-italiani.it/017/066/"
    },
    {
      "Istat": "004079",
      "Comune": "Demonte",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12014,
      "CodFisco": "D271",
      "Abitanti": 2057,
      "Link": "http://www.comuni-italiani.it/004/079/"
    },
    {
      "Istat": "006065",
      "Comune": "Denice",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "D272",
      "Abitanti": 199,
      "Link": "http://www.comuni-italiani.it/006/065/"
    },
    {
      "Istat": "022074",
      "Comune": "Denno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "D273",
      "Abitanti": 1244,
      "Link": "http://www.comuni-italiani.it/022/074/"
    },
    {
      "Istat": "006066",
      "Comune": "Dernice",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15056,
      "CodFisco": "D277",
      "Abitanti": 237,
      "Link": "http://www.comuni-italiani.it/006/066/"
    },
    {
      "Istat": "019040",
      "Comune": "Derovere",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26040,
      "CodFisco": "D278",
      "Abitanti": 316,
      "Link": "http://www.comuni-italiani.it/019/040/"
    },
    {
      "Istat": "054017",
      "Comune": "Deruta",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06053",
      "CodFisco": "D279",
      "Abitanti": 9622,
      "Link": "http://www.comuni-italiani.it/054/017/"
    },
    {
      "Istat": "097030",
      "Comune": "Dervio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23824,
      "CodFisco": "D280",
      "Abitanti": 2774,
      "Link": "http://www.comuni-italiani.it/097/030/"
    },
    {
      "Istat": "002054",
      "Comune": "Desana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13034,
      "CodFisco": "D281",
      "Abitanti": 1083,
      "Link": "http://www.comuni-italiani.it/002/054/"
    },
    {
      "Istat": "017067",
      "Comune": "Desenzano del Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25015,
      "CodFisco": "D284",
      "Abitanti": 27229,
      "Link": "http://www.comuni-italiani.it/017/067/"
    },
    {
      "Istat": 108023,
      "Comune": "Desio",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20832,
      "CodFisco": "D286",
      "Abitanti": 40661,
      "Link": "http://www.comuni-italiani.it/108/023/"
    },
    {
      "Istat": "091016",
      "Comune": "Desulo",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08032",
      "CodFisco": "D287",
      "Abitanti": 2500,
      "Link": "http://www.comuni-italiani.it/091/016/"
    },
    {
      "Istat": "078048",
      "Comune": "Diamante",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87023,
      "CodFisco": "D289",
      "Abitanti": 5447,
      "Link": "http://www.comuni-italiani.it/078/048/"
    },
    {
      "Istat": "008025",
      "Comune": "Diano Arentino",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18013,
      "CodFisco": "D293",
      "Abitanti": 742,
      "Link": "http://www.comuni-italiani.it/008/025/"
    },
    {
      "Istat": "008026",
      "Comune": "Diano Castello",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18013,
      "CodFisco": "D296",
      "Abitanti": 2349,
      "Link": "http://www.comuni-italiani.it/008/026/"
    },
    {
      "Istat": "004080",
      "Comune": "Diano d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12055,
      "CodFisco": "D291",
      "Abitanti": 3460,
      "Link": "http://www.comuni-italiani.it/004/080/"
    },
    {
      "Istat": "008027",
      "Comune": "Diano Marina",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18013,
      "CodFisco": "D297",
      "Abitanti": 6285,
      "Link": "http://www.comuni-italiani.it/008/027/"
    },
    {
      "Istat": "008028",
      "Comune": "Diano San Pietro",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18013,
      "CodFisco": "D298",
      "Abitanti": 1075,
      "Link": "http://www.comuni-italiani.it/008/028/"
    },
    {
      "Istat": "048013",
      "Comune": "Dicomano",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50062,
      "CodFisco": "D299",
      "Abitanti": 5777,
      "Link": "http://www.comuni-italiani.it/048/013/"
    },
    {
      "Istat": "030032",
      "Comune": "Dignano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "D300",
      "Abitanti": 2430,
      "Link": "http://www.comuni-italiani.it/030/032/"
    },
    {
      "Istat": "022075",
      "Comune": "Dimaro",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38025,
      "CodFisco": "D302",
      "Abitanti": 1263,
      "Link": "http://www.comuni-italiani.it/022/075/"
    },
    {
      "Istat": 102008,
      "Comune": "Dinami",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89833,
      "CodFisco": "D303",
      "Abitanti": 2526,
      "Link": "http://www.comuni-italiani.it/102/008/"
    },
    {
      "Istat": "078049",
      "Comune": "Dipignano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87045,
      "CodFisco": "D304",
      "Abitanti": 4506,
      "Link": "http://www.comuni-italiani.it/078/049/"
    },
    {
      "Istat": "075027",
      "Comune": "Diso",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73030,
      "CodFisco": "D305",
      "Abitanti": 3137,
      "Link": "http://www.comuni-italiani.it/075/027/"
    },
    {
      "Istat": "003060",
      "Comune": "Divignano",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28010,
      "CodFisco": "D309",
      "Abitanti": 1472,
      "Link": "http://www.comuni-italiani.it/003/060/"
    },
    {
      "Istat": "013087",
      "Comune": "Dizzasco",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "D310",
      "Abitanti": 578,
      "Link": "http://www.comuni-italiani.it/013/087/"
    },
    {
      "Istat": "021028",
      "Comune": "Dobbiaco",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39034,
      "CodFisco": "D311",
      "Abitanti": 3292,
      "Link": "http://www.comuni-italiani.it/021/028/"
    },
    {
      "Istat": "031003",
      "Comune": "DoberdÚ del Lago",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "D312",
      "Abitanti": 1462,
      "Link": "http://www.comuni-italiani.it/031/003/"
    },
    {
      "Istat": "004081",
      "Comune": "Dogliani",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12063,
      "CodFisco": "D314",
      "Abitanti": 4868,
      "Link": "http://www.comuni-italiani.it/004/081/"
    },
    {
      "Istat": "069029",
      "Comune": "Dogliola",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "D315",
      "Abitanti": 399,
      "Link": "http://www.comuni-italiani.it/069/029/"
    },
    {
      "Istat": "030033",
      "Comune": "Dogna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0428",
      "CAP": 33010,
      "CodFisco": "D316",
      "Abitanti": 200,
      "Link": "http://www.comuni-italiani.it/030/033/"
    },
    {
      "Istat": "023031",
      "Comune": "DolcË",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "D317",
      "Abitanti": 2621,
      "Link": "http://www.comuni-italiani.it/023/031/"
    },
    {
      "Istat": "008029",
      "Comune": "Dolceacqua",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18035,
      "CodFisco": "D318",
      "Abitanti": 2060,
      "Link": "http://www.comuni-italiani.it/008/029/"
    },
    {
      "Istat": "008030",
      "Comune": "Dolcedo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "D319",
      "Abitanti": 1472,
      "Link": "http://www.comuni-italiani.it/008/030/"
    },
    {
      "Istat": "031004",
      "Comune": "Dolegna del Collio",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "D321",
      "Abitanti": 387,
      "Link": "http://www.comuni-italiani.it/031/004/"
    },
    {
      "Istat": "092017",
      "Comune": "Dolianova",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09041",
      "CodFisco": "D323",
      "Abitanti": 9336,
      "Link": "http://www.comuni-italiani.it/092/017/"
    },
    {
      "Istat": "027012",
      "Comune": "Dolo",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30031,
      "CodFisco": "D325",
      "Abitanti": 15188,
      "Link": "http://www.comuni-italiani.it/027/012/"
    },
    {
      "Istat": "097031",
      "Comune": "Dolzago",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23843,
      "CodFisco": "D327",
      "Abitanti": 2304,
      "Link": "http://www.comuni-italiani.it/097/031/"
    },
    {
      "Istat": "078050",
      "Comune": "Domanico",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87030,
      "CodFisco": "D328",
      "Abitanti": 970,
      "Link": "http://www.comuni-italiani.it/078/050/"
    },
    {
      "Istat": "013089",
      "Comune": "Domaso",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22013,
      "CodFisco": "D329",
      "Abitanti": 1462,
      "Link": "http://www.comuni-italiani.it/013/089/"
    },
    {
      "Istat": "025018",
      "Comune": "Domegge di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "D330",
      "Abitanti": 2600,
      "Link": "http://www.comuni-italiani.it/025/018/"
    },
    {
      "Istat": "064031",
      "Comune": "Domicella",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "D331",
      "Abitanti": 1915,
      "Link": "http://www.comuni-italiani.it/064/031/"
    },
    {
      "Istat": 103028,
      "Comune": "Domodossola",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28845,
      "CodFisco": "D332",
      "Abitanti": 18475,
      "Link": "http://www.comuni-italiani.it/103/028/"
    },
    {
      "Istat": "092018",
      "Comune": "Domus De Maria",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09010",
      "CodFisco": "D333",
      "Abitanti": 1711,
      "Link": "http://www.comuni-italiani.it/092/018/"
    },
    {
      "Istat": 107005,
      "Comune": "Domusnovas",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09015",
      "CodFisco": "D334",
      "Abitanti": 6430,
      "Link": "http://www.comuni-italiani.it/107/005/"
    },
    {
      "Istat": "022076",
      "Comune": "Don",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38011,
      "CodFisco": "D336",
      "Abitanti": 248,
      "Link": "http://www.comuni-italiani.it/022/076/"
    },
    {
      "Istat": "096024",
      "Comune": "Donato",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13893,
      "CodFisco": "D339",
      "Abitanti": 719,
      "Link": "http://www.comuni-italiani.it/096/024/"
    },
    {
      "Istat": "013090",
      "Comune": "Dongo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22014,
      "CodFisco": "D341",
      "Abitanti": 3491,
      "Link": "http://www.comuni-italiani.it/013/090/"
    },
    {
      "Istat": "007023",
      "Comune": "Donnas",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "D338",
      "Abitanti": 2658,
      "Link": "http://www.comuni-italiani.it/007/023/"
    },
    {
      "Istat": "092020",
      "Comune": "Donori",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "D344",
      "Abitanti": 2121,
      "Link": "http://www.comuni-italiani.it/092/020/"
    },
    {
      "Istat": "091017",
      "Comune": "Dorgali",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08022",
      "CodFisco": "D345",
      "Abitanti": 8544,
      "Link": "http://www.comuni-italiani.it/091/017/"
    },
    {
      "Istat": "097032",
      "Comune": "Dorio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23824,
      "CodFisco": "D346",
      "Abitanti": 353,
      "Link": "http://www.comuni-italiani.it/097/032/"
    },
    {
      "Istat": "003062",
      "Comune": "Dormelletto",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28040,
      "CodFisco": "D347",
      "Abitanti": 2699,
      "Link": "http://www.comuni-italiani.it/003/062/"
    },
    {
      "Istat": "018061",
      "Comune": "Dorno",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "D348",
      "Abitanti": 4654,
      "Link": "http://www.comuni-italiani.it/018/061/"
    },
    {
      "Istat": "022077",
      "Comune": "Dorsino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38070,
      "CodFisco": "D349",
      "Abitanti": 439,
      "Link": "http://www.comuni-italiani.it/022/077/"
    },
    {
      "Istat": "096025",
      "Comune": "Dorzano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13881,
      "CodFisco": "D350",
      "Abitanti": 500,
      "Link": "http://www.comuni-italiani.it/096/025/"
    },
    {
      "Istat": "020022",
      "Comune": "Dosolo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 46030,
      "CodFisco": "D351",
      "Abitanti": 3449,
      "Link": "http://www.comuni-italiani.it/020/022/"
    },
    {
      "Istat": "016092",
      "Comune": "Dossena",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "D352",
      "Abitanti": 966,
      "Link": "http://www.comuni-italiani.it/016/092/"
    },
    {
      "Istat": "013092",
      "Comune": "Dosso del Liro",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "D355",
      "Abitanti": 275,
      "Link": "http://www.comuni-italiani.it/013/092/"
    },
    {
      "Istat": "007024",
      "Comune": "Doues",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "D356",
      "Abitanti": 474,
      "Link": "http://www.comuni-italiani.it/007/024/"
    },
    {
      "Istat": "040011",
      "Comune": "Dovadola",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47013,
      "CodFisco": "D357",
      "Abitanti": 1708,
      "Link": "http://www.comuni-italiani.it/040/011/"
    },
    {
      "Istat": "019041",
      "Comune": "Dovera",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "D358",
      "Abitanti": 3957,
      "Link": "http://www.comuni-italiani.it/019/041/"
    },
    {
      "Istat": "037025",
      "Comune": "Dozza",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40060,
      "CodFisco": "D360",
      "Abitanti": 6516,
      "Link": "http://www.comuni-italiani.it/037/025/"
    },
    {
      "Istat": "061033",
      "Comune": "Dragoni",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "D361",
      "Abitanti": 2154,
      "Link": "http://www.comuni-italiani.it/061/033/"
    },
    {
      "Istat": 102009,
      "Comune": "Drapia",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89862,
      "CodFisco": "D364",
      "Abitanti": 2192,
      "Link": "http://www.comuni-italiani.it/102/009/"
    },
    {
      "Istat": "022078",
      "Comune": "Drena",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38074,
      "CodFisco": "D365",
      "Abitanti": 558,
      "Link": "http://www.comuni-italiani.it/022/078/"
    },
    {
      "Istat": "030034",
      "Comune": "Drenchia",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "D366",
      "Abitanti": 141,
      "Link": "http://www.comuni-italiani.it/030/034/"
    },
    {
      "Istat": "015101",
      "Comune": "Dresano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20070,
      "CodFisco": "D367",
      "Abitanti": 2992,
      "Link": "http://www.comuni-italiani.it/015/101/"
    },
    {
      "Istat": "013093",
      "Comune": "Drezzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "D369",
      "Abitanti": 1218,
      "Link": "http://www.comuni-italiani.it/013/093/"
    },
    {
      "Istat": "019042",
      "Comune": "Drizzona",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26034,
      "CodFisco": "D370",
      "Abitanti": 565,
      "Link": "http://www.comuni-italiani.it/019/042/"
    },
    {
      "Istat": "022079",
      "Comune": "Dro",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38074,
      "CodFisco": "D371",
      "Abitanti": 4435,
      "Link": "http://www.comuni-italiani.it/022/079/"
    },
    {
      "Istat": "004082",
      "Comune": "Dronero",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12025,
      "CodFisco": "D372",
      "Abitanti": 7317,
      "Link": "http://www.comuni-italiani.it/004/082/"
    },
    {
      "Istat": "001099",
      "Comune": "Druento",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "D373",
      "Abitanti": 8513,
      "Link": "http://www.comuni-italiani.it/001/099/"
    },
    {
      "Istat": 103029,
      "Comune": "Druogno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28853,
      "CodFisco": "D374",
      "Abitanti": 976,
      "Link": "http://www.comuni-italiani.it/103/029/"
    },
    {
      "Istat": "091018",
      "Comune": "Dualchi",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "D376",
      "Abitanti": 684,
      "Link": "http://www.comuni-italiani.it/091/018/"
    },
    {
      "Istat": "014027",
      "Comune": "Dubino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23015,
      "CodFisco": "D377",
      "Abitanti": 3549,
      "Link": "http://www.comuni-italiani.it/014/027/"
    },
    {
      "Istat": "028106",
      "Comune": "Due Carrare",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "M300",
      "Abitanti": 8965,
      "Link": "http://www.comuni-italiani.it/028/106/"
    },
    {
      "Istat": "024038",
      "Comune": "Dueville",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36031,
      "CodFisco": "D379",
      "Abitanti": 14065,
      "Link": "http://www.comuni-italiani.it/024/038/"
    },
    {
      "Istat": "062027",
      "Comune": "Dugenta",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "D380",
      "Abitanti": 2733,
      "Link": "http://www.comuni-italiani.it/062/027/"
    },
    {
      "Istat": "032001",
      "Comune": "Duino-Aurisina",
      "Provincia": "TS",
      "Regione": "FVG",
      "Prefisso": "040",
      "CAP": 34011,
      "CodFisco": "D383",
      "Abitanti": 8717,
      "Link": "http://www.comuni-italiani.it/032/001/"
    },
    {
      "Istat": "012065",
      "Comune": "Dumenza",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "D384",
      "Abitanti": 1463,
      "Link": "http://www.comuni-italiani.it/012/065/"
    },
    {
      "Istat": "012066",
      "Comune": "Duno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "D385",
      "Abitanti": 162,
      "Link": "http://www.comuni-italiani.it/012/066/"
    },
    {
      "Istat": "062028",
      "Comune": "Durazzano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82015,
      "CodFisco": "D386",
      "Abitanti": 2261,
      "Link": "http://www.comuni-italiani.it/062/028/"
    },
    {
      "Istat": "070022",
      "Comune": "Duronia",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "C772",
      "Abitanti": 442,
      "Link": "http://www.comuni-italiani.it/070/022/"
    },
    {
      "Istat": "005052",
      "Comune": "Dusino San Michele",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "D388",
      "Abitanti": 1045,
      "Link": "http://www.comuni-italiani.it/005/052/"
    },
    {
      "Istat": "065050",
      "Comune": "Eboli",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84025,
      "CodFisco": "D390",
      "Abitanti": 38470,
      "Link": "http://www.comuni-italiani.it/065/050/"
    },
    {
      "Istat": "017068",
      "Comune": "Edolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25048,
      "CodFisco": "D391",
      "Abitanti": 4558,
      "Link": "http://www.comuni-italiani.it/017/068/"
    },
    {
      "Istat": "021029",
      "Comune": "Egna",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39044,
      "CodFisco": "D392",
      "Abitanti": 5009,
      "Link": "http://www.comuni-italiani.it/021/029/"
    },
    {
      "Istat": "068018",
      "Comune": "Elice",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "D394",
      "Abitanti": 1724,
      "Link": "http://www.comuni-italiani.it/068/018/"
    },
    {
      "Istat": 105005,
      "Comune": "Elini",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "D395",
      "Abitanti": 555,
      "Link": "http://www.comuni-italiani.it/105/005/"
    },
    {
      "Istat": "097033",
      "Comune": "Ello",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23848,
      "CodFisco": "D398",
      "Abitanti": 1281,
      "Link": "http://www.comuni-italiani.it/097/033/"
    },
    {
      "Istat": "092108",
      "Comune": "Elmas",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09030",
      "CodFisco": "D399",
      "Abitanti": 9112,
      "Link": "http://www.comuni-italiani.it/092/108/"
    },
    {
      "Istat": "004083",
      "Comune": "Elva",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "D401",
      "Abitanti": 102,
      "Link": "http://www.comuni-italiani.it/004/083/"
    },
    {
      "Istat": "007025",
      "Comune": "EmarËse",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "D402",
      "Abitanti": 223,
      "Link": "http://www.comuni-italiani.it/007/025/"
    },
    {
      "Istat": "048014",
      "Comune": "Empoli",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50053,
      "CodFisco": "D403",
      "Abitanti": 47955,
      "Link": "http://www.comuni-italiani.it/048/014/"
    },
    {
      "Istat": "016093",
      "Comune": "Endine Gaiano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D406",
      "Abitanti": 3553,
      "Link": "http://www.comuni-italiani.it/016/093/"
    },
    {
      "Istat": "024039",
      "Comune": "Enego",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36052,
      "CodFisco": "D407",
      "Abitanti": 1872,
      "Link": "http://www.comuni-italiani.it/024/039/"
    },
    {
      "Istat": "030035",
      "Comune": "Enemonzo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "D408",
      "Abitanti": 1355,
      "Link": "http://www.comuni-italiani.it/030/035/"
    },
    {
      "Istat": "086009",
      "Comune": "Enna",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94100,
      "CodFisco": "C342",
      "Abitanti": 27850,
      "Link": "http://www.comuni-italiani.it/086/009/"
    },
    {
      "Istat": "004084",
      "Comune": "Entracque",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "D410",
      "Abitanti": 845,
      "Link": "http://www.comuni-italiani.it/004/084/"
    },
    {
      "Istat": "016094",
      "Comune": "Entratico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D411",
      "Abitanti": 1887,
      "Link": "http://www.comuni-italiani.it/016/094/"
    },
    {
      "Istat": "004085",
      "Comune": "Envie",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "D412",
      "Abitanti": 2074,
      "Link": "http://www.comuni-italiani.it/004/085/"
    },
    {
      "Istat": "076030",
      "Comune": "Episcopia",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85033,
      "CodFisco": "D414",
      "Abitanti": 1488,
      "Link": "http://www.comuni-italiani.it/076/030/"
    },
    {
      "Istat": "027013",
      "Comune": "Eraclea",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "D415",
      "Abitanti": 12799,
      "Link": "http://www.comuni-italiani.it/027/013/"
    },
    {
      "Istat": "013095",
      "Comune": "Erba",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22036,
      "CodFisco": "D416",
      "Abitanti": 16949,
      "Link": "http://www.comuni-italiani.it/013/095/"
    },
    {
      "Istat": "023032",
      "Comune": "ErbË",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "D419",
      "Abitanti": 1812,
      "Link": "http://www.comuni-italiani.it/023/032/"
    },
    {
      "Istat": "023033",
      "Comune": "Erbezzo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "D420",
      "Abitanti": 787,
      "Link": "http://www.comuni-italiani.it/023/033/"
    },
    {
      "Istat": "017069",
      "Comune": "Erbusco",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "D421",
      "Abitanti": 8656,
      "Link": "http://www.comuni-italiani.it/017/069/"
    },
    {
      "Istat": "074006",
      "Comune": "Erchie",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72020,
      "CodFisco": "D422",
      "Abitanti": 8947,
      "Link": "http://www.comuni-italiani.it/074/006/"
    },
    {
      "Istat": "063064",
      "Comune": "Ercolano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80056,
      "CodFisco": "H243",
      "Abitanti": 54779,
      "Link": "http://www.comuni-italiani.it/063/064/"
    },
    {
      "Istat": "081008",
      "Comune": "Erice",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91016,
      "CodFisco": "D423",
      "Abitanti": 28583,
      "Link": "http://www.comuni-italiani.it/081/008/"
    },
    {
      "Istat": "009028",
      "Comune": "Erli",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17030,
      "CodFisco": "D424",
      "Abitanti": 267,
      "Link": "http://www.comuni-italiani.it/009/028/"
    },
    {
      "Istat": "093019",
      "Comune": "Erto e Casso",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "D426",
      "Abitanti": 389,
      "Link": "http://www.comuni-italiani.it/093/019/"
    },
    {
      "Istat": "090088",
      "Comune": "Erula",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "M292",
      "Abitanti": 769,
      "Link": "http://www.comuni-italiani.it/090/088/"
    },
    {
      "Istat": "097034",
      "Comune": "Erve",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23805,
      "CodFisco": "D428",
      "Abitanti": 779,
      "Link": "http://www.comuni-italiani.it/097/034/"
    },
    {
      "Istat": "043016",
      "Comune": "Esanatoglia",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62024,
      "CodFisco": "D429",
      "Abitanti": 2194,
      "Link": "http://www.comuni-italiani.it/043/016/"
    },
    {
      "Istat": "092110",
      "Comune": "Escalaplano",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "08043",
      "CodFisco": "D430",
      "Abitanti": 2295,
      "Link": "http://www.comuni-italiani.it/092/110/"
    },
    {
      "Istat": "092111",
      "Comune": "Escolca",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "D431",
      "Abitanti": 622,
      "Link": "http://www.comuni-italiani.it/092/111/"
    },
    {
      "Istat": "017070",
      "Comune": "Esine",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "D434",
      "Abitanti": 5384,
      "Link": "http://www.comuni-italiani.it/017/070/"
    },
    {
      "Istat": "097035",
      "Comune": "Esino Lario",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23825,
      "CodFisco": "D436",
      "Abitanti": 766,
      "Link": "http://www.comuni-italiani.it/097/035/"
    },
    {
      "Istat": "060031",
      "Comune": "Esperia",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03045",
      "CodFisco": "D440",
      "Abitanti": 3978,
      "Link": "http://www.comuni-italiani.it/060/031/"
    },
    {
      "Istat": "090028",
      "Comune": "Esporlatu",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "D441",
      "Abitanti": 431,
      "Link": "http://www.comuni-italiani.it/090/028/"
    },
    {
      "Istat": "028037",
      "Comune": "Este",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35042,
      "CodFisco": "D442",
      "Abitanti": 16806,
      "Link": "http://www.comuni-italiani.it/028/037/"
    },
    {
      "Istat": "092112",
      "Comune": "Esterzili",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "D443",
      "Abitanti": 743,
      "Link": "http://www.comuni-italiani.it/092/112/"
    },
    {
      "Istat": "007026",
      "Comune": "Etroubles",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11014,
      "CodFisco": "D444",
      "Abitanti": 491,
      "Link": "http://www.comuni-italiani.it/007/026/"
    },
    {
      "Istat": "013097",
      "Comune": "Eupilio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "D445",
      "Abitanti": 2778,
      "Link": "http://www.comuni-italiani.it/013/097/"
    },
    {
      "Istat": "001100",
      "Comune": "Exilles",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "D433",
      "Abitanti": 270,
      "Link": "http://www.comuni-italiani.it/001/100/"
    },
    {
      "Istat": "006067",
      "Comune": "Fabbrica Curone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15054,
      "CodFisco": "D447",
      "Abitanti": 732,
      "Link": "http://www.comuni-italiani.it/006/067/"
    },
    {
      "Istat": "046012",
      "Comune": "Fabbriche di Vallico",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55020,
      "CodFisco": "D449",
      "Abitanti": 504,
      "Link": "http://www.comuni-italiani.it/046/012/"
    },
    {
      "Istat": "035021",
      "Comune": "Fabbrico",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42042,
      "CodFisco": "D450",
      "Abitanti": 6750,
      "Link": "http://www.comuni-italiani.it/035/021/"
    },
    {
      "Istat": "042017",
      "Comune": "Fabriano",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0732",
      "CAP": 60044,
      "CodFisco": "D451",
      "Abitanti": 31971,
      "Link": "http://www.comuni-italiani.it/042/017/"
    },
    {
      "Istat": "056024",
      "Comune": "Fabrica di Roma",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01034",
      "CodFisco": "D452",
      "Abitanti": 8537,
      "Link": "http://www.comuni-italiani.it/056/024/"
    },
    {
      "Istat": 102010,
      "Comune": "Fabrizia",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89823,
      "CodFisco": "D453",
      "Abitanti": 2437,
      "Link": "http://www.comuni-italiani.it/102/010/"
    },
    {
      "Istat": "055011",
      "Comune": "Fabro",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05015",
      "CodFisco": "D454",
      "Abitanti": 2951,
      "Link": "http://www.comuni-italiani.it/055/011/"
    },
    {
      "Istat": "030036",
      "Comune": "Faedis",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "D455",
      "Abitanti": 3039,
      "Link": "http://www.comuni-italiani.it/030/036/"
    },
    {
      "Istat": "022080",
      "Comune": "Faedo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "D457",
      "Abitanti": 622,
      "Link": "http://www.comuni-italiani.it/022/080/"
    },
    {
      "Istat": "014028",
      "Comune": "Faedo Valtellino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "D456",
      "Abitanti": 558,
      "Link": "http://www.comuni-italiani.it/014/028/"
    },
    {
      "Istat": "039010",
      "Comune": "Faenza",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 48018,
      "CodFisco": "D458",
      "Abitanti": 58150,
      "Link": "http://www.comuni-italiani.it/039/010/"
    },
    {
      "Istat": "071023",
      "Comune": "Faeto",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71020,
      "CodFisco": "D459",
      "Abitanti": 643,
      "Link": "http://www.comuni-italiani.it/071/023/"
    },
    {
      "Istat": "030037",
      "Comune": "Fagagna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33034,
      "CodFisco": "D461",
      "Abitanti": 6363,
      "Link": "http://www.comuni-italiani.it/030/037/"
    },
    {
      "Istat": "013098",
      "Comune": "Faggeto Lario",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "D462",
      "Abitanti": 1253,
      "Link": "http://www.comuni-italiani.it/013/098/"
    },
    {
      "Istat": "073005",
      "Comune": "Faggiano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "D463",
      "Abitanti": 3561,
      "Link": "http://www.comuni-italiani.it/073/005/"
    },
    {
      "Istat": "066042",
      "Comune": "Fagnano Alto",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "D465",
      "Abitanti": 446,
      "Link": "http://www.comuni-italiani.it/066/042/"
    },
    {
      "Istat": "078051",
      "Comune": "Fagnano Castello",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87013,
      "CodFisco": "D464",
      "Abitanti": 3948,
      "Link": "http://www.comuni-italiani.it/078/051/"
    },
    {
      "Istat": "012067",
      "Comune": "Fagnano Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21054,
      "CodFisco": "D467",
      "Abitanti": 12146,
      "Link": "http://www.comuni-italiani.it/012/067/"
    },
    {
      "Istat": "022081",
      "Comune": "Fai della Paganella",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "D468",
      "Abitanti": 917,
      "Link": "http://www.comuni-italiani.it/022/081/"
    },
    {
      "Istat": "062029",
      "Comune": "Faicchio",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "D469",
      "Abitanti": 3746,
      "Link": "http://www.comuni-italiani.it/062/029/"
    },
    {
      "Istat": "025019",
      "Comune": "Falcade",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "D470",
      "Abitanti": 2065,
      "Link": "http://www.comuni-italiani.it/025/019/"
    },
    {
      "Istat": "061101",
      "Comune": "Falciano del Massico",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81030,
      "CodFisco": "D471",
      "Abitanti": 3762,
      "Link": "http://www.comuni-italiani.it/061/101/"
    },
    {
      "Istat": "078052",
      "Comune": "Falconara Albanese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87030,
      "CodFisco": "D473",
      "Abitanti": 1409,
      "Link": "http://www.comuni-italiani.it/078/052/"
    },
    {
      "Istat": "042018",
      "Comune": "Falconara Marittima",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60015,
      "CodFisco": "D472",
      "Abitanti": 27781,
      "Link": "http://www.comuni-italiani.it/042/018/"
    },
    {
      "Istat": "083019",
      "Comune": "Falcone",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "D474",
      "Abitanti": 2927,
      "Link": "http://www.comuni-italiani.it/083/019/"
    },
    {
      "Istat": "056025",
      "Comune": "Faleria",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "D475",
      "Abitanti": 2303,
      "Link": "http://www.comuni-italiani.it/056/025/"
    },
    {
      "Istat": "079047",
      "Comune": "Falerna",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88042,
      "CodFisco": "D476",
      "Abitanti": 4043,
      "Link": "http://www.comuni-italiani.it/079/047/"
    },
    {
      "Istat": 109005,
      "Comune": "Falerone",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63837,
      "CodFisco": "D477",
      "Abitanti": 3459,
      "Link": "http://www.comuni-italiani.it/109/005/"
    },
    {
      "Istat": "069104",
      "Comune": "Fallo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "D480",
      "Abitanti": 155,
      "Link": "http://www.comuni-italiani.it/069/104/"
    },
    {
      "Istat": 103030,
      "Comune": "Falmenta",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28827,
      "CodFisco": "D481",
      "Abitanti": 162,
      "Link": "http://www.comuni-italiani.it/103/030/"
    },
    {
      "Istat": "013099",
      "Comune": "Faloppio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "D482",
      "Abitanti": 4192,
      "Link": "http://www.comuni-italiani.it/013/099/"
    },
    {
      "Istat": "060032",
      "Comune": "Falvaterra",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "D483",
      "Abitanti": 594,
      "Link": "http://www.comuni-italiani.it/060/032/"
    },
    {
      "Istat": "021030",
      "Comune": "Falzes",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "D484",
      "Abitanti": 2619,
      "Link": "http://www.comuni-italiani.it/021/030/"
    },
    {
      "Istat": "036011",
      "Comune": "Fanano",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41021,
      "CodFisco": "D486",
      "Abitanti": 3121,
      "Link": "http://www.comuni-italiani.it/036/011/"
    },
    {
      "Istat": "093020",
      "Comune": "Fanna",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33092,
      "CodFisco": "D487",
      "Abitanti": 1572,
      "Link": "http://www.comuni-italiani.it/093/020/"
    },
    {
      "Istat": "041013",
      "Comune": "Fano",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61032,
      "CodFisco": "D488",
      "Abitanti": 64100,
      "Link": "http://www.comuni-italiani.it/041/013/"
    },
    {
      "Istat": "067024",
      "Comune": "Fano Adriano",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64044,
      "CodFisco": "D489",
      "Abitanti": 382,
      "Link": "http://www.comuni-italiani.it/067/024/"
    },
    {
      "Istat": "069030",
      "Comune": "Fara Filiorum Petri",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "D494",
      "Abitanti": 1940,
      "Link": "http://www.comuni-italiani.it/069/030/"
    },
    {
      "Istat": "016096",
      "Comune": "Fara Gera d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24045,
      "CodFisco": "D490",
      "Abitanti": 7970,
      "Link": "http://www.comuni-italiani.it/016/096/"
    },
    {
      "Istat": "057027",
      "Comune": "Fara in Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02032",
      "CodFisco": "D493",
      "Abitanti": 13350,
      "Link": "http://www.comuni-italiani.it/057/027/"
    },
    {
      "Istat": "003065",
      "Comune": "Fara Novarese",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28073,
      "CodFisco": "D492",
      "Abitanti": 2093,
      "Link": "http://www.comuni-italiani.it/003/065/"
    },
    {
      "Istat": "016097",
      "Comune": "Fara Olivana con Sola",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24058,
      "CodFisco": "D491",
      "Abitanti": 1329,
      "Link": "http://www.comuni-italiani.it/016/097/"
    },
    {
      "Istat": "069031",
      "Comune": "Fara San Martino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66015,
      "CodFisco": "D495",
      "Abitanti": 1528,
      "Link": "http://www.comuni-italiani.it/069/031/"
    },
    {
      "Istat": "024040",
      "Comune": "Fara Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "D496",
      "Abitanti": 3998,
      "Link": "http://www.comuni-italiani.it/024/040/"
    },
    {
      "Istat": "076031",
      "Comune": "Fardella",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85034,
      "CodFisco": "D497",
      "Abitanti": 653,
      "Link": "http://www.comuni-italiani.it/076/031/"
    },
    {
      "Istat": "004086",
      "Comune": "Farigliano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "D499",
      "Abitanti": 1770,
      "Link": "http://www.comuni-italiani.it/004/086/"
    },
    {
      "Istat": "068019",
      "Comune": "Farindola",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "D501",
      "Abitanti": 1642,
      "Link": "http://www.comuni-italiani.it/068/019/"
    },
    {
      "Istat": "033019",
      "Comune": "Farini",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29023,
      "CodFisco": "D502",
      "Abitanti": 1489,
      "Link": "http://www.comuni-italiani.it/033/019/"
    },
    {
      "Istat": "056026",
      "Comune": "Farnese",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "D503",
      "Abitanti": 1667,
      "Link": "http://www.comuni-italiani.it/056/026/"
    },
    {
      "Istat": "025020",
      "Comune": "Farra d'Alpago",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32016,
      "CodFisco": "D506",
      "Abitanti": 2811,
      "Link": "http://www.comuni-italiani.it/025/020/"
    },
    {
      "Istat": "026026",
      "Comune": "Farra di Soligo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31010,
      "CodFisco": "D505",
      "Abitanti": 8955,
      "Link": "http://www.comuni-italiani.it/026/026/"
    },
    {
      "Istat": "031005",
      "Comune": "Farra d'Isonzo",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34072,
      "CodFisco": "D504",
      "Abitanti": 1754,
      "Link": "http://www.comuni-italiani.it/031/005/"
    },
    {
      "Istat": "074007",
      "Comune": "Fasano",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 72015,
      "CodFisco": "D508",
      "Abitanti": 38657,
      "Link": "http://www.comuni-italiani.it/074/007/"
    },
    {
      "Istat": "010022",
      "Comune": "Fascia",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16020,
      "CodFisco": "D509",
      "Abitanti": 105,
      "Link": "http://www.comuni-italiani.it/010/022/"
    },
    {
      "Istat": "050014",
      "Comune": "Fauglia",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56043,
      "CodFisco": "D510",
      "Abitanti": 3601,
      "Link": "http://www.comuni-italiani.it/050/014/"
    },
    {
      "Istat": "004087",
      "Comune": "Faule",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 12030,
      "CodFisco": "D511",
      "Abitanti": 484,
      "Link": "http://www.comuni-italiani.it/004/087/"
    },
    {
      "Istat": "010023",
      "Comune": "Favale di Malvaro",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "D512",
      "Abitanti": 512,
      "Link": "http://www.comuni-italiani.it/010/023/"
    },
    {
      "Istat": "084017",
      "Comune": "Favara",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92026,
      "CodFisco": "D514",
      "Abitanti": 33857,
      "Link": "http://www.comuni-italiani.it/084/017/"
    },
    {
      "Istat": "022082",
      "Comune": "Faver",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "D516",
      "Abitanti": 828,
      "Link": "http://www.comuni-italiani.it/022/082/"
    },
    {
      "Istat": "081009",
      "Comune": "Favignana",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91023,
      "CodFisco": "D518",
      "Abitanti": 4314,
      "Link": "http://www.comuni-italiani.it/081/009/"
    },
    {
      "Istat": "001101",
      "Comune": "Favria",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10083,
      "CodFisco": "D520",
      "Abitanti": 5320,
      "Link": "http://www.comuni-italiani.it/001/101/"
    },
    {
      "Istat": "004088",
      "Comune": "Feisoglio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "D523",
      "Abitanti": 355,
      "Link": "http://www.comuni-italiani.it/004/088/"
    },
    {
      "Istat": "001102",
      "Comune": "Feletto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "D524",
      "Abitanti": 2410,
      "Link": "http://www.comuni-italiani.it/001/102/"
    },
    {
      "Istat": "034013",
      "Comune": "Felino",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43035,
      "CodFisco": "D526",
      "Abitanti": 8546,
      "Link": "http://www.comuni-italiani.it/034/013/"
    },
    {
      "Istat": "065051",
      "Comune": "Felitto",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84055,
      "CodFisco": "D527",
      "Abitanti": 1332,
      "Link": "http://www.comuni-italiani.it/065/051/"
    },
    {
      "Istat": "006068",
      "Comune": "Felizzano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15023,
      "CodFisco": "D528",
      "Abitanti": 2475,
      "Link": "http://www.comuni-italiani.it/006/068/"
    },
    {
      "Istat": "020023",
      "Comune": "Felonica",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46022,
      "CodFisco": "D529",
      "Abitanti": 1480,
      "Link": "http://www.comuni-italiani.it/020/023/"
    },
    {
      "Istat": "025021",
      "Comune": "Feltre",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32032,
      "CodFisco": "D530",
      "Abitanti": 20924,
      "Link": "http://www.comuni-italiani.it/025/021/"
    },
    {
      "Istat": "013100",
      "Comune": "FenegrÚ",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "D531",
      "Abitanti": 3138,
      "Link": "http://www.comuni-italiani.it/013/100/"
    },
    {
      "Istat": "001103",
      "Comune": "Fenestrelle",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "D532",
      "Abitanti": 571,
      "Link": "http://www.comuni-italiani.it/001/103/"
    },
    {
      "Istat": "007027",
      "Comune": "FÈnis",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "D537",
      "Abitanti": 1759,
      "Link": "http://www.comuni-italiani.it/007/027/"
    },
    {
      "Istat": "055012",
      "Comune": "Ferentillo",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05034",
      "CodFisco": "D538",
      "Abitanti": 1981,
      "Link": "http://www.comuni-italiani.it/055/012/"
    },
    {
      "Istat": "060033",
      "Comune": "Ferentino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03013",
      "CodFisco": "D539",
      "Abitanti": 21258,
      "Link": "http://www.comuni-italiani.it/060/033/"
    },
    {
      "Istat": "089008",
      "Comune": "Ferla",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "D540",
      "Abitanti": 2599,
      "Link": "http://www.comuni-italiani.it/089/008/"
    },
    {
      "Istat": "041014",
      "Comune": "Fermignano",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61033,
      "CodFisco": "D541",
      "Abitanti": 8668,
      "Link": "http://www.comuni-italiani.it/041/014/"
    },
    {
      "Istat": 109006,
      "Comune": "Fermo",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63900,
      "CodFisco": "D542",
      "Abitanti": 37834,
      "Link": "http://www.comuni-italiani.it/109/006/"
    },
    {
      "Istat": "012068",
      "Comune": "Ferno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21010,
      "CodFisco": "D543",
      "Abitanti": 6876,
      "Link": "http://www.comuni-italiani.it/012/068/"
    },
    {
      "Istat": "079048",
      "Comune": "Feroleto Antico",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "D544",
      "Abitanti": 2109,
      "Link": "http://www.comuni-italiani.it/079/048/"
    },
    {
      "Istat": "080032",
      "Comune": "Feroleto della Chiesa",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89050,
      "CodFisco": "D545",
      "Abitanti": 1791,
      "Link": "http://www.comuni-italiani.it/080/032/"
    },
    {
      "Istat": "077008",
      "Comune": "Ferrandina",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75013,
      "CodFisco": "D547",
      "Abitanti": 9072,
      "Link": "http://www.comuni-italiani.it/077/008/"
    },
    {
      "Istat": "038008",
      "Comune": "Ferrara",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": "4412x",
      "CodFisco": "D548",
      "Abitanti": 135369,
      "Link": "http://www.comuni-italiani.it/038/008/"
    },
    {
      "Istat": "023034",
      "Comune": "Ferrara di Monte Baldo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "D549",
      "Abitanti": 226,
      "Link": "http://www.comuni-italiani.it/023/034/"
    },
    {
      "Istat": "070023",
      "Comune": "Ferrazzano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "D550",
      "Abitanti": 3345,
      "Link": "http://www.comuni-italiani.it/070/023/"
    },
    {
      "Istat": "012069",
      "Comune": "Ferrera di Varese",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "D551",
      "Abitanti": 696,
      "Link": "http://www.comuni-italiani.it/012/069/"
    },
    {
      "Istat": "018062",
      "Comune": "Ferrera Erbognone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27032,
      "CodFisco": "D552",
      "Abitanti": 1137,
      "Link": "http://www.comuni-italiani.it/018/062/"
    },
    {
      "Istat": "005053",
      "Comune": "Ferrere",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14012,
      "CodFisco": "D554",
      "Abitanti": 1631,
      "Link": "http://www.comuni-italiani.it/005/053/"
    },
    {
      "Istat": "033020",
      "Comune": "Ferriere",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29024,
      "CodFisco": "D555",
      "Abitanti": 1551,
      "Link": "http://www.comuni-italiani.it/033/020/"
    },
    {
      "Istat": "080033",
      "Comune": "Ferruzzano",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "D557",
      "Abitanti": 795,
      "Link": "http://www.comuni-italiani.it/080/033/"
    },
    {
      "Istat": "057028",
      "Comune": "Fiamignano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02023",
      "CodFisco": "D560",
      "Abitanti": 1525,
      "Link": "http://www.comuni-italiani.it/057/028/"
    },
    {
      "Istat": "001104",
      "Comune": "Fiano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "D562",
      "Abitanti": 2744,
      "Link": "http://www.comuni-italiani.it/001/104/"
    },
    {
      "Istat": "058036",
      "Comune": "Fiano Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "00065",
      "CodFisco": "D561",
      "Abitanti": 13978,
      "Link": "http://www.comuni-italiani.it/058/036/"
    },
    {
      "Istat": "043017",
      "Comune": "Fiastra",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62035,
      "CodFisco": "D564",
      "Abitanti": 577,
      "Link": "http://www.comuni-italiani.it/043/017/"
    },
    {
      "Istat": "022083",
      "Comune": "FiavË",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38075,
      "CodFisco": "D565",
      "Abitanti": 1127,
      "Link": "http://www.comuni-italiani.it/022/083/"
    },
    {
      "Istat": "082035",
      "Comune": "Ficarazzi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90010,
      "CodFisco": "D567",
      "Abitanti": 11997,
      "Link": "http://www.comuni-italiani.it/082/035/"
    },
    {
      "Istat": "029021",
      "Comune": "Ficarolo",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45036,
      "CodFisco": "D568",
      "Abitanti": 2627,
      "Link": "http://www.comuni-italiani.it/029/021/"
    },
    {
      "Istat": "083020",
      "Comune": "Ficarra",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98062,
      "CodFisco": "D569",
      "Abitanti": 1593,
      "Link": "http://www.comuni-italiani.it/083/020/"
    },
    {
      "Istat": "055013",
      "Comune": "Ficulle",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05016",
      "CodFisco": "D570",
      "Abitanti": 1735,
      "Link": "http://www.comuni-italiani.it/055/013/"
    },
    {
      "Istat": "034014",
      "Comune": "Fidenza",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43036,
      "CodFisco": "B034",
      "Abitanti": 26196,
      "Link": "http://www.comuni-italiani.it/034/014/"
    },
    {
      "Istat": "021031",
      "Comune": "FiË allo Sciliar",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39050,
      "CodFisco": "D571",
      "Abitanti": 3469,
      "Link": "http://www.comuni-italiani.it/021/031/"
    },
    {
      "Istat": "022084",
      "Comune": "Fiera di Primiero",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38054,
      "CodFisco": "D572",
      "Abitanti": 533,
      "Link": "http://www.comuni-italiani.it/022/084/"
    },
    {
      "Istat": "022085",
      "Comune": "Fierozzo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "D573",
      "Abitanti": 477,
      "Link": "http://www.comuni-italiani.it/022/085/"
    },
    {
      "Istat": "019043",
      "Comune": "Fiesco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26010,
      "CodFisco": "D574",
      "Abitanti": 1179,
      "Link": "http://www.comuni-italiani.it/019/043/"
    },
    {
      "Istat": "048015",
      "Comune": "Fiesole",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50014,
      "CodFisco": "D575",
      "Abitanti": 14341,
      "Link": "http://www.comuni-italiani.it/048/015/"
    },
    {
      "Istat": "017071",
      "Comune": "Fiesse",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "D576",
      "Abitanti": 2198,
      "Link": "http://www.comuni-italiani.it/017/071/"
    },
    {
      "Istat": "027014",
      "Comune": "Fiesso d'Artico",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30032,
      "CodFisco": "D578",
      "Abitanti": 7786,
      "Link": "http://www.comuni-italiani.it/027/014/"
    },
    {
      "Istat": "029022",
      "Comune": "Fiesso Umbertiano",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45024,
      "CodFisco": "D577",
      "Abitanti": 4343,
      "Link": "http://www.comuni-italiani.it/029/022/"
    },
    {
      "Istat": "013101",
      "Comune": "Figino Serenza",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "D579",
      "Abitanti": 5243,
      "Link": "http://www.comuni-italiani.it/013/101/"
    },
    {
      "Istat": "048016",
      "Comune": "Figline Valdarno",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50063,
      "CodFisco": "D583",
      "Abitanti": 17050,
      "Link": "http://www.comuni-italiani.it/048/016/"
    },
    {
      "Istat": "078053",
      "Comune": "Figline Vegliaturo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "D582",
      "Abitanti": 1117,
      "Link": "http://www.comuni-italiani.it/078/053/"
    },
    {
      "Istat": "058037",
      "Comune": "Filacciano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "00060",
      "CodFisco": "D586",
      "Abitanti": 526,
      "Link": "http://www.comuni-italiani.it/058/037/"
    },
    {
      "Istat": 102011,
      "Comune": "Filadelfia",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 89814,
      "CodFisco": "D587",
      "Abitanti": 5684,
      "Link": "http://www.comuni-italiani.it/102/011/"
    },
    {
      "Istat": "016098",
      "Comune": "Filago",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "D588",
      "Abitanti": 3224,
      "Link": "http://www.comuni-italiani.it/016/098/"
    },
    {
      "Istat": 102012,
      "Comune": "Filandari",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89841,
      "CodFisco": "D589",
      "Abitanti": 1884,
      "Link": "http://www.comuni-italiani.it/102/012/"
    },
    {
      "Istat": "045006",
      "Comune": "Filattiera",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54023,
      "CodFisco": "D590",
      "Abitanti": 2406,
      "Link": "http://www.comuni-italiani.it/045/006/"
    },
    {
      "Istat": "060034",
      "Comune": "Filettino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "D591",
      "Abitanti": 550,
      "Link": "http://www.comuni-italiani.it/060/034/"
    },
    {
      "Istat": "069032",
      "Comune": "Filetto",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66030,
      "CodFisco": "D592",
      "Abitanti": 1008,
      "Link": "http://www.comuni-italiani.it/069/032/"
    },
    {
      "Istat": "076032",
      "Comune": "Filiano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85020,
      "CodFisco": "D593",
      "Abitanti": 3116,
      "Link": "http://www.comuni-italiani.it/076/032/"
    },
    {
      "Istat": "018063",
      "Comune": "Filighera",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "D594",
      "Abitanti": 846,
      "Link": "http://www.comuni-italiani.it/018/063/"
    },
    {
      "Istat": "094019",
      "Comune": "Filignano",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86074,
      "CodFisco": "D595",
      "Abitanti": 728,
      "Link": "http://www.comuni-italiani.it/094/019/"
    },
    {
      "Istat": 102013,
      "Comune": "Filogaso",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89843,
      "CodFisco": "D596",
      "Abitanti": 1454,
      "Link": "http://www.comuni-italiani.it/102/013/"
    },
    {
      "Istat": "042019",
      "Comune": "Filottrano",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60024,
      "CodFisco": "D597",
      "Abitanti": 9745,
      "Link": "http://www.comuni-italiani.it/042/019/"
    },
    {
      "Istat": "036012",
      "Comune": "Finale Emilia",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41034,
      "CodFisco": "D599",
      "Abitanti": 16072,
      "Link": "http://www.comuni-italiani.it/036/012/"
    },
    {
      "Istat": "009029",
      "Comune": "Finale Ligure",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17024,
      "CodFisco": "D600",
      "Abitanti": 11638,
      "Link": "http://www.comuni-italiani.it/009/029/"
    },
    {
      "Istat": "016099",
      "Comune": "Fino del Monte",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "D604",
      "Abitanti": 1153,
      "Link": "http://www.comuni-italiani.it/016/099/"
    },
    {
      "Istat": "013102",
      "Comune": "Fino Mornasco",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22073,
      "CodFisco": "D605",
      "Abitanti": 9614,
      "Link": "http://www.comuni-italiani.it/013/102/"
    },
    {
      "Istat": "016100",
      "Comune": "Fiorano al Serio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "D606",
      "Abitanti": 3097,
      "Link": "http://www.comuni-italiani.it/016/100/"
    },
    {
      "Istat": "001105",
      "Comune": "Fiorano Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "D608",
      "Abitanti": 859,
      "Link": "http://www.comuni-italiani.it/001/105/"
    },
    {
      "Istat": "036013",
      "Comune": "Fiorano Modenese",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41042,
      "CodFisco": "D607",
      "Abitanti": 17041,
      "Link": "http://www.comuni-italiani.it/036/013/"
    },
    {
      "Istat": "043018",
      "Comune": "Fiordimonte",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62035,
      "CodFisco": "D609",
      "Abitanti": 214,
      "Link": "http://www.comuni-italiani.it/043/018/"
    },
    {
      "Istat": "033021",
      "Comune": "Fiorenzuola d'Arda",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29017,
      "CodFisco": "D611",
      "Abitanti": 15204,
      "Link": "http://www.comuni-italiani.it/033/021/"
    },
    {
      "Istat": "048017",
      "Comune": "Firenze",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": "501xx",
      "CodFisco": "D612",
      "Abitanti": 371282,
      "Link": "http://www.comuni-italiani.it/048/017/"
    },
    {
      "Istat": "048018",
      "Comune": "Firenzuola",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50033,
      "CodFisco": "D613",
      "Abitanti": 4950,
      "Link": "http://www.comuni-italiani.it/048/018/"
    },
    {
      "Istat": "078054",
      "Comune": "Firmo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "D614",
      "Abitanti": 2257,
      "Link": "http://www.comuni-italiani.it/078/054/"
    },
    {
      "Istat": "065052",
      "Comune": "Fisciano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84084,
      "CodFisco": "D615",
      "Abitanti": 13652,
      "Link": "http://www.comuni-italiani.it/065/052/"
    },
    {
      "Istat": "060035",
      "Comune": "Fiuggi",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03014",
      "CodFisco": "A310",
      "Abitanti": 9755,
      "Link": "http://www.comuni-italiani.it/060/035/"
    },
    {
      "Istat": "036014",
      "Comune": "Fiumalbo",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41022,
      "CodFisco": "D617",
      "Abitanti": 1313,
      "Link": "http://www.comuni-italiani.it/036/014/"
    },
    {
      "Istat": "080034",
      "Comune": "Fiumara",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89050,
      "CodFisco": "D619",
      "Abitanti": 1068,
      "Link": "http://www.comuni-italiani.it/080/034/"
    },
    {
      "Istat": "093021",
      "Comune": "Fiume Veneto",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33080,
      "CodFisco": "D621",
      "Abitanti": 11494,
      "Link": "http://www.comuni-italiani.it/093/021/"
    },
    {
      "Istat": "083021",
      "Comune": "Fiumedinisi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98022,
      "CodFisco": "D622",
      "Abitanti": 1545,
      "Link": "http://www.comuni-italiani.it/083/021/"
    },
    {
      "Istat": "078055",
      "Comune": "Fiumefreddo Bruzio",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87030,
      "CodFisco": "D624",
      "Abitanti": 3156,
      "Link": "http://www.comuni-italiani.it/078/055/"
    },
    {
      "Istat": "087016",
      "Comune": "Fiumefreddo di Sicilia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95013,
      "CodFisco": "D623",
      "Abitanti": 9835,
      "Link": "http://www.comuni-italiani.it/087/016/"
    },
    {
      "Istat": "030038",
      "Comune": "Fiumicello",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "D627",
      "Abitanti": 5063,
      "Link": "http://www.comuni-italiani.it/030/038/"
    },
    {
      "Istat": "058120",
      "Comune": "Fiumicino",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00054",
      "CodFisco": "M297",
      "Abitanti": 70985,
      "Link": "http://www.comuni-italiani.it/058/120/"
    },
    {
      "Istat": "043019",
      "Comune": "Fiuminata",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62025,
      "CodFisco": "D628",
      "Abitanti": 1547,
      "Link": "http://www.comuni-italiani.it/043/019/"
    },
    {
      "Istat": "045007",
      "Comune": "Fivizzano",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0585",
      "CAP": 54013,
      "CodFisco": "D629",
      "Abitanti": 8524,
      "Link": "http://www.comuni-italiani.it/045/007/"
    },
    {
      "Istat": "030039",
      "Comune": "Flaibano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "D630",
      "Abitanti": 1209,
      "Link": "http://www.comuni-italiani.it/030/039/"
    },
    {
      "Istat": "022086",
      "Comune": "Flavon",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "D631",
      "Abitanti": 545,
      "Link": "http://www.comuni-italiani.it/022/086/"
    },
    {
      "Istat": "017072",
      "Comune": "Flero",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "D634",
      "Abitanti": 8525,
      "Link": "http://www.comuni-italiani.it/017/072/"
    },
    {
      "Istat": "083022",
      "Comune": "Floresta",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98030,
      "CodFisco": "D635",
      "Abitanti": 542,
      "Link": "http://www.comuni-italiani.it/083/022/"
    },
    {
      "Istat": "089009",
      "Comune": "Floridia",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96014,
      "CodFisco": "D636",
      "Abitanti": 23050,
      "Link": "http://www.comuni-italiani.it/089/009/"
    },
    {
      "Istat": "090029",
      "Comune": "Florinas",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "D637",
      "Abitanti": 1556,
      "Link": "http://www.comuni-italiani.it/090/029/"
    },
    {
      "Istat": "064032",
      "Comune": "Flumeri",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83040,
      "CodFisco": "D638",
      "Abitanti": 3112,
      "Link": "http://www.comuni-italiani.it/064/032/"
    },
    {
      "Istat": 107006,
      "Comune": "Fluminimaggiore",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "D639",
      "Abitanti": 2992,
      "Link": "http://www.comuni-italiani.it/107/006/"
    },
    {
      "Istat": "095080",
      "Comune": "Flussio",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "D640",
      "Abitanti": 468,
      "Link": "http://www.comuni-italiani.it/095/080/"
    },
    {
      "Istat": "002057",
      "Comune": "Fobello",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13025,
      "CodFisco": "D641",
      "Abitanti": 220,
      "Link": "http://www.comuni-italiani.it/002/057/"
    },
    {
      "Istat": "071024",
      "Comune": "Foggia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": "7112x",
      "CodFisco": "D643",
      "Abitanti": 152747,
      "Link": "http://www.comuni-italiani.it/071/024/"
    },
    {
      "Istat": "062030",
      "Comune": "Foglianise",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "D644",
      "Abitanti": 3520,
      "Link": "http://www.comuni-italiani.it/062/030/"
    },
    {
      "Istat": "031006",
      "Comune": "Fogliano Redipuglia",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "D645",
      "Abitanti": 3071,
      "Link": "http://www.comuni-italiani.it/031/006/"
    },
    {
      "Istat": "001106",
      "Comune": "Foglizzo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "D646",
      "Abitanti": 2372,
      "Link": "http://www.comuni-italiani.it/001/106/"
    },
    {
      "Istat": "051018",
      "Comune": "Foiano della Chiana",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52045,
      "CodFisco": "D649",
      "Abitanti": 9622,
      "Link": "http://www.comuni-italiani.it/051/018/"
    },
    {
      "Istat": "062031",
      "Comune": "Foiano di Val Fortore",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "D650",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/062/031/"
    },
    {
      "Istat": "022087",
      "Comune": "Folgaria",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38064,
      "CodFisco": "D651",
      "Abitanti": 3118,
      "Link": "http://www.comuni-italiani.it/022/087/"
    },
    {
      "Istat": "044020",
      "Comune": "Folignano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63084,
      "CodFisco": "D652",
      "Abitanti": 9433,
      "Link": "http://www.comuni-italiani.it/044/020/"
    },
    {
      "Istat": "054018",
      "Comune": "Foligno",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06034",
      "CodFisco": "D653",
      "Abitanti": 58162,
      "Link": "http://www.comuni-italiani.it/054/018/"
    },
    {
      "Istat": "026027",
      "Comune": "Follina",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31051,
      "CodFisco": "D654",
      "Abitanti": 3997,
      "Link": "http://www.comuni-italiani.it/026/027/"
    },
    {
      "Istat": "011013",
      "Comune": "Follo",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "D655",
      "Abitanti": 6436,
      "Link": "http://www.comuni-italiani.it/011/013/"
    },
    {
      "Istat": "053009",
      "Comune": "Follonica",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0566",
      "CAP": 58022,
      "CodFisco": "D656",
      "Abitanti": 22113,
      "Link": "http://www.comuni-italiani.it/053/009/"
    },
    {
      "Istat": "098026",
      "Comune": "Fombio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26861,
      "CodFisco": "D660",
      "Abitanti": 2278,
      "Link": "http://www.comuni-italiani.it/098/026/"
    },
    {
      "Istat": "083023",
      "Comune": "Fondachelli-Fantina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98050,
      "CodFisco": "D661",
      "Abitanti": 1113,
      "Link": "http://www.comuni-italiani.it/083/023/"
    },
    {
      "Istat": "059007",
      "Comune": "Fondi",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04022",
      "CodFisco": "D662",
      "Abitanti": 37770,
      "Link": "http://www.comuni-italiani.it/059/007/"
    },
    {
      "Istat": "022088",
      "Comune": "Fondo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38013,
      "CodFisco": "D663",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/022/088/"
    },
    {
      "Istat": "091024",
      "Comune": "Fonni",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08023",
      "CodFisco": "D665",
      "Abitanti": 4104,
      "Link": "http://www.comuni-italiani.it/091/024/"
    },
    {
      "Istat": "007028",
      "Comune": "Fontainemore",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "D666",
      "Abitanti": 453,
      "Link": "http://www.comuni-italiani.it/007/028/"
    },
    {
      "Istat": "060036",
      "Comune": "Fontana Liri",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03035",
      "CodFisco": "D667",
      "Abitanti": 3063,
      "Link": "http://www.comuni-italiani.it/060/036/"
    },
    {
      "Istat": "093022",
      "Comune": "Fontanafredda",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33074,
      "CodFisco": "D670",
      "Abitanti": 11686,
      "Link": "http://www.comuni-italiani.it/093/022/"
    },
    {
      "Istat": "064033",
      "Comune": "Fontanarosa",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83040,
      "CodFisco": "D671",
      "Abitanti": 3329,
      "Link": "http://www.comuni-italiani.it/064/033/"
    },
    {
      "Istat": "037026",
      "Comune": "Fontanelice",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40025,
      "CodFisco": "D668",
      "Abitanti": 1921,
      "Link": "http://www.comuni-italiani.it/037/026/"
    },
    {
      "Istat": "016101",
      "Comune": "Fontanella",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24056,
      "CodFisco": "D672",
      "Abitanti": 4339,
      "Link": "http://www.comuni-italiani.it/016/101/"
    },
    {
      "Istat": "034015",
      "Comune": "Fontanellato",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43012,
      "CodFisco": "D673",
      "Abitanti": 7080,
      "Link": "http://www.comuni-italiani.it/034/015/"
    },
    {
      "Istat": "026028",
      "Comune": "Fontanelle",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31043,
      "CodFisco": "D674",
      "Abitanti": 5778,
      "Link": "http://www.comuni-italiani.it/026/028/"
    },
    {
      "Istat": "003066",
      "Comune": "Fontaneto d'Agogna",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "D675",
      "Abitanti": 2741,
      "Link": "http://www.comuni-italiani.it/003/066/"
    },
    {
      "Istat": "002058",
      "Comune": "Fontanetto Po",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "D676",
      "Abitanti": 1242,
      "Link": "http://www.comuni-italiani.it/002/058/"
    },
    {
      "Istat": "010024",
      "Comune": "Fontanigorda",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16023,
      "CodFisco": "D677",
      "Abitanti": 282,
      "Link": "http://www.comuni-italiani.it/010/024/"
    },
    {
      "Istat": "005054",
      "Comune": "Fontanile",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14044,
      "CodFisco": "D678",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/005/054/"
    },
    {
      "Istat": "028038",
      "Comune": "Fontaniva",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35014,
      "CodFisco": "D679",
      "Abitanti": 8249,
      "Link": "http://www.comuni-italiani.it/028/038/"
    },
    {
      "Istat": "026029",
      "Comune": "Fonte",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31010,
      "CodFisco": "D680",
      "Abitanti": 6156,
      "Link": "http://www.comuni-italiani.it/026/029/"
    },
    {
      "Istat": "058122",
      "Comune": "Fonte Nuova",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00013",
      "CodFisco": "M309",
      "Abitanti": 28620,
      "Link": "http://www.comuni-italiani.it/058/122/"
    },
    {
      "Istat": "066043",
      "Comune": "Fontecchio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "D681",
      "Abitanti": 412,
      "Link": "http://www.comuni-italiani.it/066/043/"
    },
    {
      "Istat": "060037",
      "Comune": "Fontechiari",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "D682",
      "Abitanti": 1315,
      "Link": "http://www.comuni-italiani.it/060/037/"
    },
    {
      "Istat": "061034",
      "Comune": "Fontegreca",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81014,
      "CodFisco": "D683",
      "Abitanti": 857,
      "Link": "http://www.comuni-italiani.it/061/034/"
    },
    {
      "Istat": "016102",
      "Comune": "Fonteno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D684",
      "Abitanti": 691,
      "Link": "http://www.comuni-italiani.it/016/102/"
    },
    {
      "Istat": "034016",
      "Comune": "Fontevivo",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43010,
      "CodFisco": "D685",
      "Abitanti": 5572,
      "Link": "http://www.comuni-italiani.it/034/016/"
    },
    {
      "Istat": "025022",
      "Comune": "Fonzaso",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "D686",
      "Abitanti": 3329,
      "Link": "http://www.comuni-italiani.it/025/022/"
    },
    {
      "Istat": "016103",
      "Comune": "Foppolo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "D688",
      "Abitanti": 206,
      "Link": "http://www.comuni-italiani.it/016/103/"
    },
    {
      "Istat": "057029",
      "Comune": "Forano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02044",
      "CodFisco": "D689",
      "Abitanti": 3102,
      "Link": "http://www.comuni-italiani.it/057/029/"
    },
    {
      "Istat": "044021",
      "Comune": "Force",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63086,
      "CodFisco": "D691",
      "Abitanti": 1444,
      "Link": "http://www.comuni-italiani.it/044/021/"
    },
    {
      "Istat": "062032",
      "Comune": "Forchia",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82011,
      "CodFisco": "D693",
      "Abitanti": 1226,
      "Link": "http://www.comuni-italiani.it/062/032/"
    },
    {
      "Istat": "014029",
      "Comune": "Forcola",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "D694",
      "Abitanti": 842,
      "Link": "http://www.comuni-italiani.it/014/029/"
    },
    {
      "Istat": "095020",
      "Comune": "Fordongianus",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09083",
      "CodFisco": "D695",
      "Abitanti": 951,
      "Link": "http://www.comuni-italiani.it/095/020/"
    },
    {
      "Istat": "076033",
      "Comune": "Forenza",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85023,
      "CodFisco": "D696",
      "Abitanti": 2254,
      "Link": "http://www.comuni-italiani.it/076/033/"
    },
    {
      "Istat": "016104",
      "Comune": "Foresto Sparso",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D697",
      "Abitanti": 3151,
      "Link": "http://www.comuni-italiani.it/016/104/"
    },
    {
      "Istat": "030137",
      "Comune": "Forgaria nel Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33030,
      "CodFisco": "D700",
      "Abitanti": 1854,
      "Link": "http://www.comuni-italiani.it/030/137/"
    },
    {
      "Istat": "064034",
      "Comune": "Forino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83020,
      "CodFisco": "D701",
      "Abitanti": 5396,
      "Link": "http://www.comuni-italiani.it/064/034/"
    },
    {
      "Istat": "063031",
      "Comune": "Forio",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80075,
      "CodFisco": "D702",
      "Abitanti": 17600,
      "Link": "http://www.comuni-italiani.it/063/031/"
    },
    {
      "Istat": "040012",
      "Comune": "ForlÏ",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": "4712x",
      "CodFisco": "D704",
      "Abitanti": 118167,
      "Link": "http://www.comuni-italiani.it/040/012/"
    },
    {
      "Istat": "094020",
      "Comune": "ForlÏ del Sannio",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86084,
      "CodFisco": "D703",
      "Abitanti": 740,
      "Link": "http://www.comuni-italiani.it/094/020/"
    },
    {
      "Istat": "040013",
      "Comune": "Forlimpopoli",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47034,
      "CodFisco": "D705",
      "Abitanti": 13063,
      "Link": "http://www.comuni-italiani.it/040/013/"
    },
    {
      "Istat": 103031,
      "Comune": "Formazza",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28863,
      "CodFisco": "D706",
      "Abitanti": 443,
      "Link": "http://www.comuni-italiani.it/103/031/"
    },
    {
      "Istat": "058038",
      "Comune": "Formello",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "D707",
      "Abitanti": 12802,
      "Link": "http://www.comuni-italiani.it/058/038/"
    },
    {
      "Istat": "059008",
      "Comune": "Formia",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04023",
      "CodFisco": "D708",
      "Abitanti": 37571,
      "Link": "http://www.comuni-italiani.it/059/008/"
    },
    {
      "Istat": "061035",
      "Comune": "Formicola",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "D709",
      "Abitanti": 1580,
      "Link": "http://www.comuni-italiani.it/061/035/"
    },
    {
      "Istat": "019044",
      "Comune": "Formigara",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "D710",
      "Abitanti": 1146,
      "Link": "http://www.comuni-italiani.it/019/044/"
    },
    {
      "Istat": "036015",
      "Comune": "Formigine",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41043,
      "CodFisco": "D711",
      "Abitanti": 33832,
      "Link": "http://www.comuni-italiani.it/036/015/"
    },
    {
      "Istat": "002059",
      "Comune": "Formigliana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "D712",
      "Abitanti": 569,
      "Link": "http://www.comuni-italiani.it/002/059/"
    },
    {
      "Istat": "038009",
      "Comune": "Formignana",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44035,
      "CodFisco": "D713",
      "Abitanti": 2810,
      "Link": "http://www.comuni-italiani.it/038/009/"
    },
    {
      "Istat": "022089",
      "Comune": "Fornace",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38040,
      "CodFisco": "D714",
      "Abitanti": 1323,
      "Link": "http://www.comuni-italiani.it/022/089/"
    },
    {
      "Istat": "094021",
      "Comune": "Fornelli",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "D715",
      "Abitanti": 2005,
      "Link": "http://www.comuni-italiani.it/094/021/"
    },
    {
      "Istat": "030040",
      "Comune": "Forni Avoltri",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "D718",
      "Abitanti": 653,
      "Link": "http://www.comuni-italiani.it/030/040/"
    },
    {
      "Istat": "030041",
      "Comune": "Forni di Sopra",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33024,
      "CodFisco": "D719",
      "Abitanti": 1071,
      "Link": "http://www.comuni-italiani.it/030/041/"
    },
    {
      "Istat": "030042",
      "Comune": "Forni di Sotto",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "D720",
      "Abitanti": 660,
      "Link": "http://www.comuni-italiani.it/030/042/"
    },
    {
      "Istat": "001107",
      "Comune": "Forno Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10084,
      "CodFisco": "D725",
      "Abitanti": 3691,
      "Link": "http://www.comuni-italiani.it/001/107/"
    },
    {
      "Istat": "025024",
      "Comune": "Forno di Zoldo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32012,
      "CodFisco": "D726",
      "Abitanti": 2560,
      "Link": "http://www.comuni-italiani.it/025/024/"
    },
    {
      "Istat": "034017",
      "Comune": "Fornovo di Taro",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43045,
      "CodFisco": "D728",
      "Abitanti": 6294,
      "Link": "http://www.comuni-italiani.it/034/017/"
    },
    {
      "Istat": "016105",
      "Comune": "Fornovo San Giovanni",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "D727",
      "Abitanti": 3305,
      "Link": "http://www.comuni-italiani.it/016/105/"
    },
    {
      "Istat": "046013",
      "Comune": "Forte dei Marmi",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55042,
      "CodFisco": "D730",
      "Abitanti": 7752,
      "Link": "http://www.comuni-italiani.it/046/013/"
    },
    {
      "Istat": "021032",
      "Comune": "Fortezza",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39045,
      "CodFisco": "D731",
      "Abitanti": 975,
      "Link": "http://www.comuni-italiani.it/021/032/"
    },
    {
      "Istat": "018064",
      "Comune": "Fortunago",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "D732",
      "Abitanti": 391,
      "Link": "http://www.comuni-italiani.it/018/064/"
    },
    {
      "Istat": "083024",
      "Comune": "Forza d'AgrÚ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "D733",
      "Abitanti": 922,
      "Link": "http://www.comuni-italiani.it/083/024/"
    },
    {
      "Istat": "046014",
      "Comune": "Fosciandora",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55020,
      "CodFisco": "D734",
      "Abitanti": 629,
      "Link": "http://www.comuni-italiani.it/046/014/"
    },
    {
      "Istat": "045008",
      "Comune": "Fosdinovo",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54035,
      "CodFisco": "D735",
      "Abitanti": 5054,
      "Link": "http://www.comuni-italiani.it/045/008/"
    },
    {
      "Istat": "066044",
      "Comune": "Fossa",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "D736",
      "Abitanti": 704,
      "Link": "http://www.comuni-italiani.it/066/044/"
    },
    {
      "Istat": "069033",
      "Comune": "Fossacesia",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66022,
      "CodFisco": "D738",
      "Abitanti": 6283,
      "Link": "http://www.comuni-italiani.it/069/033/"
    },
    {
      "Istat": "027015",
      "Comune": "Fossalta di Piave",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "D740",
      "Abitanti": 4222,
      "Link": "http://www.comuni-italiani.it/027/015/"
    },
    {
      "Istat": "027016",
      "Comune": "Fossalta di Portogruaro",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30025,
      "CodFisco": "D741",
      "Abitanti": 6080,
      "Link": "http://www.comuni-italiani.it/027/016/"
    },
    {
      "Istat": "070024",
      "Comune": "Fossalto",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "D737",
      "Abitanti": 1526,
      "Link": "http://www.comuni-italiani.it/070/024/"
    },
    {
      "Istat": "004089",
      "Comune": "Fossano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12045,
      "CodFisco": "D742",
      "Abitanti": 24854,
      "Link": "http://www.comuni-italiani.it/004/089/"
    },
    {
      "Istat": "054019",
      "Comune": "Fossato di Vico",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06022",
      "CodFisco": "D745",
      "Abitanti": 2893,
      "Link": "http://www.comuni-italiani.it/054/019/"
    },
    {
      "Istat": "079052",
      "Comune": "Fossato Serralta",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "D744",
      "Abitanti": 607,
      "Link": "http://www.comuni-italiani.it/079/052/"
    },
    {
      "Istat": "027017",
      "Comune": "FossÚ",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30030,
      "CodFisco": "D748",
      "Abitanti": 6814,
      "Link": "http://www.comuni-italiani.it/027/017/"
    },
    {
      "Istat": "041015",
      "Comune": "Fossombrone",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61034,
      "CodFisco": "D749",
      "Abitanti": 9823,
      "Link": "http://www.comuni-italiani.it/041/015/"
    },
    {
      "Istat": "024041",
      "Comune": "Foza",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36010,
      "CodFisco": "D750",
      "Abitanti": 726,
      "Link": "http://www.comuni-italiani.it/024/041/"
    },
    {
      "Istat": "004090",
      "Comune": "Frabosa Soprana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12082,
      "CodFisco": "D751",
      "Abitanti": 823,
      "Link": "http://www.comuni-italiani.it/004/090/"
    },
    {
      "Istat": "004091",
      "Comune": "Frabosa Sottana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12083,
      "CodFisco": "D752",
      "Abitanti": 1605,
      "Link": "http://www.comuni-italiani.it/004/091/"
    },
    {
      "Istat": "006069",
      "Comune": "Fraconalto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "010",
      "CAP": 15060,
      "CodFisco": "D559",
      "Abitanti": 364,
      "Link": "http://www.comuni-italiani.it/006/069/"
    },
    {
      "Istat": "073006",
      "Comune": "Fragagnano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74022,
      "CodFisco": "D754",
      "Abitanti": 5417,
      "Link": "http://www.comuni-italiani.it/073/006/"
    },
    {
      "Istat": "062033",
      "Comune": "Fragneto L'Abate",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "D755",
      "Abitanti": 1094,
      "Link": "http://www.comuni-italiani.it/062/033/"
    },
    {
      "Istat": "062034",
      "Comune": "Fragneto Monforte",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "D756",
      "Abitanti": 1880,
      "Link": "http://www.comuni-italiani.it/062/034/"
    },
    {
      "Istat": "069034",
      "Comune": "Fraine",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "D757",
      "Abitanti": 403,
      "Link": "http://www.comuni-italiani.it/069/034/"
    },
    {
      "Istat": "011014",
      "Comune": "Framura",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19014,
      "CodFisco": "D758",
      "Abitanti": 716,
      "Link": "http://www.comuni-italiani.it/011/014/"
    },
    {
      "Istat": "069035",
      "Comune": "Francavilla al Mare",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 66023,
      "CodFisco": "D763",
      "Abitanti": 24649,
      "Link": "http://www.comuni-italiani.it/069/035/"
    },
    {
      "Istat": 102014,
      "Comune": "Francavilla Angitola",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 89815,
      "CodFisco": "D762",
      "Abitanti": 2051,
      "Link": "http://www.comuni-italiani.it/102/014/"
    },
    {
      "Istat": "006070",
      "Comune": "Francavilla Bisio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "D759",
      "Abitanti": 512,
      "Link": "http://www.comuni-italiani.it/006/070/"
    },
    {
      "Istat": 109007,
      "Comune": "Francavilla d'Ete",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63816,
      "CodFisco": "D760",
      "Abitanti": 1016,
      "Link": "http://www.comuni-italiani.it/109/007/"
    },
    {
      "Istat": "083025",
      "Comune": "Francavilla di Sicilia",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98034,
      "CodFisco": "D765",
      "Abitanti": 4084,
      "Link": "http://www.comuni-italiani.it/083/025/"
    },
    {
      "Istat": "074008",
      "Comune": "Francavilla Fontana",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72021,
      "CodFisco": "D761",
      "Abitanti": 36593,
      "Link": "http://www.comuni-italiani.it/074/008/"
    },
    {
      "Istat": "076034",
      "Comune": "Francavilla in Sinni",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85034,
      "CodFisco": "D766",
      "Abitanti": 4303,
      "Link": "http://www.comuni-italiani.it/076/034/"
    },
    {
      "Istat": "078056",
      "Comune": "Francavilla Marittima",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87072,
      "CodFisco": "D764",
      "Abitanti": 2994,
      "Link": "http://www.comuni-italiani.it/078/056/"
    },
    {
      "Istat": 102015,
      "Comune": "Francica",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89851,
      "CodFisco": "D767",
      "Abitanti": 1711,
      "Link": "http://www.comuni-italiani.it/102/015/"
    },
    {
      "Istat": "089010",
      "Comune": "Francofonte",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 96015,
      "CodFisco": "D768",
      "Abitanti": 12392,
      "Link": "http://www.comuni-italiani.it/089/010/"
    },
    {
      "Istat": "061036",
      "Comune": "Francolise",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "D769",
      "Abitanti": 5025,
      "Link": "http://www.comuni-italiani.it/061/036/"
    },
    {
      "Istat": "006071",
      "Comune": "Frascaro",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15010,
      "CodFisco": "D770",
      "Abitanti": 458,
      "Link": "http://www.comuni-italiani.it/006/071/"
    },
    {
      "Istat": "018065",
      "Comune": "Frascarolo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "D771",
      "Abitanti": 1241,
      "Link": "http://www.comuni-italiani.it/018/065/"
    },
    {
      "Istat": "058039",
      "Comune": "Frascati",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00044",
      "CodFisco": "D773",
      "Abitanti": 21285,
      "Link": "http://www.comuni-italiani.it/058/039/"
    },
    {
      "Istat": "078057",
      "Comune": "Frascineto",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "D774",
      "Abitanti": 2289,
      "Link": "http://www.comuni-italiani.it/078/057/"
    },
    {
      "Istat": "022090",
      "Comune": "Frassilongo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "D775",
      "Abitanti": 331,
      "Link": "http://www.comuni-italiani.it/022/090/"
    },
    {
      "Istat": "029023",
      "Comune": "Frassinelle Polesine",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "D776",
      "Abitanti": 1531,
      "Link": "http://www.comuni-italiani.it/029/023/"
    },
    {
      "Istat": "006072",
      "Comune": "Frassinello Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15035,
      "CodFisco": "D777",
      "Abitanti": 549,
      "Link": "http://www.comuni-italiani.it/006/072/"
    },
    {
      "Istat": "006073",
      "Comune": "Frassineto Po",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "D780",
      "Abitanti": 1463,
      "Link": "http://www.comuni-italiani.it/006/073/"
    },
    {
      "Istat": "001108",
      "Comune": "Frassinetto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "D781",
      "Abitanti": 277,
      "Link": "http://www.comuni-italiani.it/001/108/"
    },
    {
      "Istat": "004092",
      "Comune": "Frassino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "D782",
      "Abitanti": 291,
      "Link": "http://www.comuni-italiani.it/004/092/"
    },
    {
      "Istat": "036016",
      "Comune": "Frassinoro",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41044,
      "CodFisco": "D783",
      "Abitanti": 2046,
      "Link": "http://www.comuni-italiani.it/036/016/"
    },
    {
      "Istat": "057030",
      "Comune": "Frasso Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02030",
      "CodFisco": "D785",
      "Abitanti": 709,
      "Link": "http://www.comuni-italiani.it/057/030/"
    },
    {
      "Istat": "062035",
      "Comune": "Frasso Telesino",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "D784",
      "Abitanti": 2448,
      "Link": "http://www.comuni-italiani.it/062/035/"
    },
    {
      "Istat": "029024",
      "Comune": "Fratta Polesine",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45025,
      "CodFisco": "D788",
      "Abitanti": 2780,
      "Link": "http://www.comuni-italiani.it/029/024/"
    },
    {
      "Istat": "054020",
      "Comune": "Fratta Todina",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06054",
      "CodFisco": "D787",
      "Abitanti": 1896,
      "Link": "http://www.comuni-italiani.it/054/020/"
    },
    {
      "Istat": "063032",
      "Comune": "Frattamaggiore",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80027,
      "CodFisco": "D789",
      "Abitanti": 30108,
      "Link": "http://www.comuni-italiani.it/063/032/"
    },
    {
      "Istat": "063033",
      "Comune": "Frattaminore",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80020,
      "CodFisco": "D790",
      "Abitanti": 16067,
      "Link": "http://www.comuni-italiani.it/063/033/"
    },
    {
      "Istat": "041016",
      "Comune": "Fratte Rosa",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "D791",
      "Abitanti": 1015,
      "Link": "http://www.comuni-italiani.it/041/016/"
    },
    {
      "Istat": "083026",
      "Comune": "FrazzanÚ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "D793",
      "Abitanti": 804,
      "Link": "http://www.comuni-italiani.it/083/026/"
    },
    {
      "Istat": "026030",
      "Comune": "Fregona",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31010,
      "CodFisco": "D794",
      "Abitanti": 3203,
      "Link": "http://www.comuni-italiani.it/026/030/"
    },
    {
      "Istat": "069036",
      "Comune": "Fresagrandinaria",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "D796",
      "Abitanti": 1085,
      "Link": "http://www.comuni-italiani.it/069/036/"
    },
    {
      "Istat": "006074",
      "Comune": "Fresonara",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15064,
      "CodFisco": "D797",
      "Abitanti": 730,
      "Link": "http://www.comuni-italiani.it/006/074/"
    },
    {
      "Istat": "064035",
      "Comune": "Frigento",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83040,
      "CodFisco": "D798",
      "Abitanti": 4017,
      "Link": "http://www.comuni-italiani.it/064/035/"
    },
    {
      "Istat": "061037",
      "Comune": "Frignano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "D799",
      "Abitanti": 8659,
      "Link": "http://www.comuni-italiani.it/061/037/"
    },
    {
      "Istat": "005055",
      "Comune": "Frinco",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "D802",
      "Abitanti": 751,
      "Link": "http://www.comuni-italiani.it/005/055/"
    },
    {
      "Istat": "069037",
      "Comune": "Frisa",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66030,
      "CodFisco": "D803",
      "Abitanti": 1910,
      "Link": "http://www.comuni-italiani.it/069/037/"
    },
    {
      "Istat": "093024",
      "Comune": "Frisanco",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "D804",
      "Abitanti": 683,
      "Link": "http://www.comuni-italiani.it/093/024/"
    },
    {
      "Istat": "001109",
      "Comune": "Front",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "D805",
      "Abitanti": 1753,
      "Link": "http://www.comuni-italiani.it/001/109/"
    },
    {
      "Istat": "041017",
      "Comune": "Frontino",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61021,
      "CodFisco": "D807",
      "Abitanti": 314,
      "Link": "http://www.comuni-italiani.it/041/017/"
    },
    {
      "Istat": "041018",
      "Comune": "Frontone",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "D808",
      "Abitanti": 1367,
      "Link": "http://www.comuni-italiani.it/041/018/"
    },
    {
      "Istat": "060038",
      "Comune": "Frosinone",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03100",
      "CodFisco": "D810",
      "Abitanti": 48122,
      "Link": "http://www.comuni-italiani.it/060/038/"
    },
    {
      "Istat": "094022",
      "Comune": "Frosolone",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86095,
      "CodFisco": "D811",
      "Abitanti": 3248,
      "Link": "http://www.comuni-italiani.it/094/022/"
    },
    {
      "Istat": "001110",
      "Comune": "Frossasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "D812",
      "Abitanti": 2870,
      "Link": "http://www.comuni-italiani.it/001/110/"
    },
    {
      "Istat": "006075",
      "Comune": "Frugarolo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15065,
      "CodFisco": "D813",
      "Abitanti": 1997,
      "Link": "http://www.comuni-italiani.it/006/075/"
    },
    {
      "Istat": "006076",
      "Comune": "Fubine",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15043,
      "CodFisco": "D814",
      "Abitanti": 1679,
      "Link": "http://www.comuni-italiani.it/006/076/"
    },
    {
      "Istat": "048019",
      "Comune": "Fucecchio",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50054,
      "CodFisco": "D815",
      "Abitanti": 23496,
      "Link": "http://www.comuni-italiani.it/048/019/"
    },
    {
      "Istat": "016106",
      "Comune": "Fuipiano Valle Imagna",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "D817",
      "Abitanti": 226,
      "Link": "http://www.comuni-italiani.it/016/106/"
    },
    {
      "Istat": "023035",
      "Comune": "Fumane",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37022,
      "CodFisco": "D818",
      "Abitanti": 4148,
      "Link": "http://www.comuni-italiani.it/023/035/"
    },
    {
      "Istat": "060039",
      "Comune": "Fumone",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "D819",
      "Abitanti": 2210,
      "Link": "http://www.comuni-italiani.it/060/039/"
    },
    {
      "Istat": "021033",
      "Comune": "Funes",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "D821",
      "Abitanti": 2556,
      "Link": "http://www.comuni-italiani.it/021/033/"
    },
    {
      "Istat": "069038",
      "Comune": "Furci",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "D823",
      "Abitanti": 1115,
      "Link": "http://www.comuni-italiani.it/069/038/"
    },
    {
      "Istat": "083027",
      "Comune": "Furci Siculo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98023,
      "CodFisco": "D824",
      "Abitanti": 3405,
      "Link": "http://www.comuni-italiani.it/083/027/"
    },
    {
      "Istat": "083028",
      "Comune": "Furnari",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98054,
      "CodFisco": "D825",
      "Abitanti": 3671,
      "Link": "http://www.comuni-italiani.it/083/028/"
    },
    {
      "Istat": "065053",
      "Comune": "Furore",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "D826",
      "Abitanti": 850,
      "Link": "http://www.comuni-italiani.it/065/053/"
    },
    {
      "Istat": 106004,
      "Comune": "Furtei",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "D827",
      "Abitanti": 1690,
      "Link": "http://www.comuni-italiani.it/106/004/"
    },
    {
      "Istat": "078058",
      "Comune": "Fuscaldo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87024,
      "CodFisco": "D828",
      "Abitanti": 8334,
      "Link": "http://www.comuni-italiani.it/078/058/"
    },
    {
      "Istat": "039011",
      "Comune": "Fusignano",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48010,
      "CodFisco": "D829",
      "Abitanti": 8444,
      "Link": "http://www.comuni-italiani.it/039/011/"
    },
    {
      "Istat": "014030",
      "Comune": "Fusine",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "D830",
      "Abitanti": 633,
      "Link": "http://www.comuni-italiani.it/014/030/"
    },
    {
      "Istat": "065054",
      "Comune": "Futani",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84050,
      "CodFisco": "D832",
      "Abitanti": 1296,
      "Link": "http://www.comuni-italiani.it/065/054/"
    },
    {
      "Istat": "019045",
      "Comune": "Gabbioneta-Binanuova",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "D834",
      "Abitanti": 955,
      "Link": "http://www.comuni-italiani.it/019/045/"
    },
    {
      "Istat": "006077",
      "Comune": "Gabiano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "D835",
      "Abitanti": 1250,
      "Link": "http://www.comuni-italiani.it/006/077/"
    },
    {
      "Istat": "041019",
      "Comune": "Gabicce Mare",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0541",
      "CAP": 61011,
      "CodFisco": "D836",
      "Abitanti": 5976,
      "Link": "http://www.comuni-italiani.it/041/019/"
    },
    {
      "Istat": "007029",
      "Comune": "Gaby",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "D839",
      "Abitanti": 484,
      "Link": "http://www.comuni-italiani.it/007/029/"
    },
    {
      "Istat": "019046",
      "Comune": "Gadesco-Pieve Delmona",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "D841",
      "Abitanti": 2028,
      "Link": "http://www.comuni-italiani.it/019/046/"
    },
    {
      "Istat": "091025",
      "Comune": "Gadoni",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08030",
      "CodFisco": "D842",
      "Abitanti": 897,
      "Link": "http://www.comuni-italiani.it/091/025/"
    },
    {
      "Istat": "059009",
      "Comune": "Gaeta",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04024",
      "CodFisco": "D843",
      "Abitanti": 21546,
      "Link": "http://www.comuni-italiani.it/059/009/"
    },
    {
      "Istat": "083029",
      "Comune": "Gaggi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "D844",
      "Abitanti": 3149,
      "Link": "http://www.comuni-italiani.it/083/029/"
    },
    {
      "Istat": "015103",
      "Comune": "Gaggiano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20083,
      "CodFisco": "D845",
      "Abitanti": 8975,
      "Link": "http://www.comuni-italiani.it/015/103/"
    },
    {
      "Istat": "037027",
      "Comune": "Gaggio Montano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40041,
      "CodFisco": "D847",
      "Abitanti": 5131,
      "Link": "http://www.comuni-italiani.it/037/027/"
    },
    {
      "Istat": "096026",
      "Comune": "Gaglianico",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13894,
      "CodFisco": "D848",
      "Abitanti": 3931,
      "Link": "http://www.comuni-italiani.it/096/026/"
    },
    {
      "Istat": "066045",
      "Comune": "Gagliano Aterno",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67020,
      "CodFisco": "D850",
      "Abitanti": 287,
      "Link": "http://www.comuni-italiani.it/066/045/"
    },
    {
      "Istat": "086010",
      "Comune": "Gagliano Castelferrato",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "D849",
      "Abitanti": 3731,
      "Link": "http://www.comuni-italiani.it/086/010/"
    },
    {
      "Istat": "075028",
      "Comune": "Gagliano del Capo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73034,
      "CodFisco": "D851",
      "Abitanti": 5485,
      "Link": "http://www.comuni-italiani.it/075/028/"
    },
    {
      "Istat": "079055",
      "Comune": "Gagliato",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "D852",
      "Abitanti": 539,
      "Link": "http://www.comuni-italiani.it/079/055/"
    },
    {
      "Istat": "043020",
      "Comune": "Gagliole",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62022,
      "CodFisco": "D853",
      "Abitanti": 647,
      "Link": "http://www.comuni-italiani.it/043/020/"
    },
    {
      "Istat": "026031",
      "Comune": "Gaiarine",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0434",
      "CAP": 31018,
      "CodFisco": "D854",
      "Abitanti": 6163,
      "Link": "http://www.comuni-italiani.it/026/031/"
    },
    {
      "Istat": "029025",
      "Comune": "Gaiba",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "D855",
      "Abitanti": 1123,
      "Link": "http://www.comuni-italiani.it/029/025/"
    },
    {
      "Istat": "004093",
      "Comune": "Gaiola",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "D856",
      "Abitanti": 592,
      "Link": "http://www.comuni-italiani.it/004/093/"
    },
    {
      "Istat": "052013",
      "Comune": "Gaiole in Chianti",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53013,
      "CodFisco": "D858",
      "Abitanti": 2769,
      "Link": "http://www.comuni-italiani.it/052/013/"
    },
    {
      "Istat": 105006,
      "Comune": "Gairo",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "D859",
      "Abitanti": 1572,
      "Link": "http://www.comuni-italiani.it/105/006/"
    },
    {
      "Istat": "021034",
      "Comune": "Gais",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "D860",
      "Abitanti": 3160,
      "Link": "http://www.comuni-italiani.it/021/034/"
    },
    {
      "Istat": "083030",
      "Comune": "Galati Mamertino",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "D861",
      "Abitanti": 2843,
      "Link": "http://www.comuni-italiani.it/083/030/"
    },
    {
      "Istat": "075029",
      "Comune": "Galatina",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73013,
      "CodFisco": "D862",
      "Abitanti": 27299,
      "Link": "http://www.comuni-italiani.it/075/029/"
    },
    {
      "Istat": "075030",
      "Comune": "Galatone",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73044,
      "CodFisco": "D863",
      "Abitanti": 15834,
      "Link": "http://www.comuni-italiani.it/075/030/"
    },
    {
      "Istat": "080035",
      "Comune": "Galatro",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89054,
      "CodFisco": "D864",
      "Abitanti": 1837,
      "Link": "http://www.comuni-italiani.it/080/035/"
    },
    {
      "Istat": "097036",
      "Comune": "Galbiate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23851,
      "CodFisco": "D865",
      "Abitanti": 8663,
      "Link": "http://www.comuni-italiani.it/097/036/"
    },
    {
      "Istat": "040014",
      "Comune": "Galeata",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47010,
      "CodFisco": "D867",
      "Abitanti": 2532,
      "Link": "http://www.comuni-italiani.it/040/014/"
    },
    {
      "Istat": "098027",
      "Comune": "Galgagnano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26832,
      "CodFisco": "D868",
      "Abitanti": 1214,
      "Link": "http://www.comuni-italiani.it/098/027/"
    },
    {
      "Istat": "012070",
      "Comune": "Gallarate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21013,
      "CodFisco": "D869",
      "Abitanti": 51751,
      "Link": "http://www.comuni-italiani.it/012/070/"
    },
    {
      "Istat": "056027",
      "Comune": "Gallese",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01035",
      "CodFisco": "D870",
      "Abitanti": 2982,
      "Link": "http://www.comuni-italiani.it/056/027/"
    },
    {
      "Istat": "003068",
      "Comune": "Galliate",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28066,
      "CodFisco": "D872",
      "Abitanti": 15412,
      "Link": "http://www.comuni-italiani.it/003/068/"
    },
    {
      "Istat": "012071",
      "Comune": "Galliate Lombardo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "D871",
      "Abitanti": 993,
      "Link": "http://www.comuni-italiani.it/012/071/"
    },
    {
      "Istat": "018066",
      "Comune": "Galliavola",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27034,
      "CodFisco": "D873",
      "Abitanti": 227,
      "Link": "http://www.comuni-italiani.it/018/066/"
    },
    {
      "Istat": "046015",
      "Comune": "Gallicano",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55027,
      "CodFisco": "D874",
      "Abitanti": 3931,
      "Link": "http://www.comuni-italiani.it/046/015/"
    },
    {
      "Istat": "058040",
      "Comune": "Gallicano nel Lazio",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00010",
      "CodFisco": "D875",
      "Abitanti": 6058,
      "Link": "http://www.comuni-italiani.it/058/040/"
    },
    {
      "Istat": "076035",
      "Comune": "Gallicchio",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "D876",
      "Abitanti": 907,
      "Link": "http://www.comuni-italiani.it/076/035/"
    },
    {
      "Istat": "037028",
      "Comune": "Galliera",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40015,
      "CodFisco": "D878",
      "Abitanti": 5555,
      "Link": "http://www.comuni-italiani.it/037/028/"
    },
    {
      "Istat": "028039",
      "Comune": "Galliera Veneta",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35015,
      "CodFisco": "D879",
      "Abitanti": 7141,
      "Link": "http://www.comuni-italiani.it/028/039/"
    },
    {
      "Istat": "060040",
      "Comune": "Gallinaro",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "D881",
      "Abitanti": 1279,
      "Link": "http://www.comuni-italiani.it/060/040/"
    },
    {
      "Istat": "024042",
      "Comune": "Gallio",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36032,
      "CodFisco": "D882",
      "Abitanti": 2483,
      "Link": "http://www.comuni-italiani.it/024/042/"
    },
    {
      "Istat": "075031",
      "Comune": "Gallipoli",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73014,
      "CodFisco": "D883",
      "Abitanti": 21139,
      "Link": "http://www.comuni-italiani.it/075/031/"
    },
    {
      "Istat": "061038",
      "Comune": "Gallo Matese",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "D884",
      "Abitanti": 678,
      "Link": "http://www.comuni-italiani.it/061/038/"
    },
    {
      "Istat": "083031",
      "Comune": "Gallodoro",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "D885",
      "Abitanti": 389,
      "Link": "http://www.comuni-italiani.it/083/031/"
    },
    {
      "Istat": "061039",
      "Comune": "Galluccio",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81044,
      "CodFisco": "D886",
      "Abitanti": 2275,
      "Link": "http://www.comuni-italiani.it/061/039/"
    },
    {
      "Istat": "091027",
      "Comune": "GaltellÏ",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "D888",
      "Abitanti": 2484,
      "Link": "http://www.comuni-italiani.it/091/027/"
    },
    {
      "Istat": "028040",
      "Comune": "Galzignano Terme",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "D889",
      "Abitanti": 4436,
      "Link": "http://www.comuni-italiani.it/028/040/"
    },
    {
      "Istat": "006078",
      "Comune": "Gamalero",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15010,
      "CodFisco": "D890",
      "Abitanti": 852,
      "Link": "http://www.comuni-italiani.it/006/078/"
    },
    {
      "Istat": "017073",
      "Comune": "Gambara",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "D891",
      "Abitanti": 4809,
      "Link": "http://www.comuni-italiani.it/017/073/"
    },
    {
      "Istat": "018067",
      "Comune": "Gambarana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "D892",
      "Abitanti": 254,
      "Link": "http://www.comuni-italiani.it/018/067/"
    },
    {
      "Istat": "004094",
      "Comune": "Gambasca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "D894",
      "Abitanti": 403,
      "Link": "http://www.comuni-italiani.it/004/094/"
    },
    {
      "Istat": "048020",
      "Comune": "Gambassi Terme",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50050,
      "CodFisco": "D895",
      "Abitanti": 4930,
      "Link": "http://www.comuni-italiani.it/048/020/"
    },
    {
      "Istat": "070025",
      "Comune": "Gambatesa",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86013,
      "CodFisco": "D896",
      "Abitanti": 1502,
      "Link": "http://www.comuni-italiani.it/070/025/"
    },
    {
      "Istat": "024043",
      "Comune": "Gambellara",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36053,
      "CodFisco": "D897",
      "Abitanti": 3400,
      "Link": "http://www.comuni-italiani.it/024/043/"
    },
    {
      "Istat": "069039",
      "Comune": "Gamberale",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "D898",
      "Abitanti": 331,
      "Link": "http://www.comuni-italiani.it/069/039/"
    },
    {
      "Istat": "040015",
      "Comune": "Gambettola",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": 47035,
      "CodFisco": "D899",
      "Abitanti": 10431,
      "Link": "http://www.comuni-italiani.it/040/015/"
    },
    {
      "Istat": "018068",
      "Comune": "GambolÚ",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0381",
      "CAP": 27025,
      "CodFisco": "D901",
      "Abitanti": 10231,
      "Link": "http://www.comuni-italiani.it/018/068/"
    },
    {
      "Istat": "024044",
      "Comune": "Gambugliano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "D902",
      "Abitanti": 842,
      "Link": "http://www.comuni-italiani.it/024/044/"
    },
    {
      "Istat": "016107",
      "Comune": "Gandellino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "D903",
      "Abitanti": 1065,
      "Link": "http://www.comuni-italiani.it/016/107/"
    },
    {
      "Istat": "016108",
      "Comune": "Gandino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24024,
      "CodFisco": "D905",
      "Abitanti": 5697,
      "Link": "http://www.comuni-italiani.it/016/108/"
    },
    {
      "Istat": "016109",
      "Comune": "Gandosso",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D906",
      "Abitanti": 1527,
      "Link": "http://www.comuni-italiani.it/016/109/"
    },
    {
      "Istat": "082036",
      "Comune": "Gangi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90024,
      "CodFisco": "D907",
      "Abitanti": 7102,
      "Link": "http://www.comuni-italiani.it/082/036/"
    },
    {
      "Istat": "077009",
      "Comune": "Garaguso",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "D909",
      "Abitanti": 1155,
      "Link": "http://www.comuni-italiani.it/077/009/"
    },
    {
      "Istat": "006079",
      "Comune": "Garbagna",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "D910",
      "Abitanti": 721,
      "Link": "http://www.comuni-italiani.it/006/079/"
    },
    {
      "Istat": "003069",
      "Comune": "Garbagna Novarese",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28070,
      "CodFisco": "D911",
      "Abitanti": 1342,
      "Link": "http://www.comuni-italiani.it/003/069/"
    },
    {
      "Istat": "015105",
      "Comune": "Garbagnate Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20024,
      "CodFisco": "D912",
      "Abitanti": 27193,
      "Link": "http://www.comuni-italiani.it/015/105/"
    },
    {
      "Istat": "097037",
      "Comune": "Garbagnate Monastero",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23846,
      "CodFisco": "D913",
      "Abitanti": 2431,
      "Link": "http://www.comuni-italiani.it/097/037/"
    },
    {
      "Istat": "023036",
      "Comune": "Garda",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37016,
      "CodFisco": "D915",
      "Abitanti": 4016,
      "Link": "http://www.comuni-italiani.it/023/036/"
    },
    {
      "Istat": "017074",
      "Comune": "Gardone Riviera",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25083,
      "CodFisco": "D917",
      "Abitanti": 2757,
      "Link": "http://www.comuni-italiani.it/017/074/"
    },
    {
      "Istat": "017075",
      "Comune": "Gardone Val Trompia",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25063,
      "CodFisco": "D918",
      "Abitanti": 11938,
      "Link": "http://www.comuni-italiani.it/017/075/"
    },
    {
      "Istat": "004095",
      "Comune": "Garessio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12075,
      "CodFisco": "D920",
      "Abitanti": 3432,
      "Link": "http://www.comuni-italiani.it/004/095/"
    },
    {
      "Istat": "003070",
      "Comune": "Gargallo",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "D921",
      "Abitanti": 1835,
      "Link": "http://www.comuni-italiani.it/003/070/"
    },
    {
      "Istat": "021035",
      "Comune": "Gargazzone",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "D923",
      "Abitanti": 1638,
      "Link": "http://www.comuni-italiani.it/021/035/"
    },
    {
      "Istat": "017076",
      "Comune": "Gargnano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25084,
      "CodFisco": "D924",
      "Abitanti": 3050,
      "Link": "http://www.comuni-italiani.it/017/076/"
    },
    {
      "Istat": "018069",
      "Comune": "Garlasco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27026,
      "CodFisco": "D925",
      "Abitanti": 9888,
      "Link": "http://www.comuni-italiani.it/018/069/"
    },
    {
      "Istat": "097038",
      "Comune": "Garlate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23852,
      "CodFisco": "D926",
      "Abitanti": 2675,
      "Link": "http://www.comuni-italiani.it/097/038/"
    },
    {
      "Istat": "009030",
      "Comune": "Garlenda",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17033,
      "CodFisco": "D927",
      "Abitanti": 1291,
      "Link": "http://www.comuni-italiani.it/009/030/"
    },
    {
      "Istat": "022091",
      "Comune": "Garniga Terme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38060,
      "CodFisco": "D928",
      "Abitanti": 392,
      "Link": "http://www.comuni-italiani.it/022/091/"
    },
    {
      "Istat": "013106",
      "Comune": "Garzeno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "D930",
      "Abitanti": 862,
      "Link": "http://www.comuni-italiani.it/013/106/"
    },
    {
      "Istat": "001111",
      "Comune": "Garzigliana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "D931",
      "Abitanti": 556,
      "Link": "http://www.comuni-italiani.it/001/111/"
    },
    {
      "Istat": "079056",
      "Comune": "Gasperina",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "D932",
      "Abitanti": 2206,
      "Link": "http://www.comuni-italiani.it/079/056/"
    },
    {
      "Istat": "001112",
      "Comune": "Gassino Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "D933",
      "Abitanti": 9553,
      "Link": "http://www.comuni-italiani.it/001/112/"
    },
    {
      "Istat": "035022",
      "Comune": "Gattatico",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42043,
      "CodFisco": "D934",
      "Abitanti": 5935,
      "Link": "http://www.comuni-italiani.it/035/022/"
    },
    {
      "Istat": "040016",
      "Comune": "Gatteo",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47043,
      "CodFisco": "D935",
      "Abitanti": 8863,
      "Link": "http://www.comuni-italiani.it/040/016/"
    },
    {
      "Istat": "003071",
      "Comune": "Gattico",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28013,
      "CodFisco": "D937",
      "Abitanti": 3402,
      "Link": "http://www.comuni-italiani.it/003/071/"
    },
    {
      "Istat": "002061",
      "Comune": "Gattinara",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13045,
      "CodFisco": "D938",
      "Abitanti": 8340,
      "Link": "http://www.comuni-italiani.it/002/061/"
    },
    {
      "Istat": "017077",
      "Comune": "Gavardo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25085,
      "CodFisco": "D940",
      "Abitanti": 11686,
      "Link": "http://www.comuni-italiani.it/017/077/"
    },
    {
      "Istat": "006080",
      "Comune": "Gavazzana",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15063,
      "CodFisco": "D941",
      "Abitanti": 171,
      "Link": "http://www.comuni-italiani.it/006/080/"
    },
    {
      "Istat": "029026",
      "Comune": "Gavello",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45010,
      "CodFisco": "D942",
      "Abitanti": 1683,
      "Link": "http://www.comuni-italiani.it/029/026/"
    },
    {
      "Istat": "016110",
      "Comune": "Gaverina Terme",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "D943",
      "Abitanti": 931,
      "Link": "http://www.comuni-italiani.it/016/110/"
    },
    {
      "Istat": "006081",
      "Comune": "Gavi",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15066,
      "CodFisco": "D944",
      "Abitanti": 4744,
      "Link": "http://www.comuni-italiani.it/006/081/"
    },
    {
      "Istat": "058041",
      "Comune": "Gavignano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "D945",
      "Abitanti": 1987,
      "Link": "http://www.comuni-italiani.it/058/041/"
    },
    {
      "Istat": "012072",
      "Comune": "Gavirate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21026,
      "CodFisco": "D946",
      "Abitanti": 9338,
      "Link": "http://www.comuni-italiani.it/012/072/"
    },
    {
      "Istat": "091028",
      "Comune": "Gavoi",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "D947",
      "Abitanti": 2819,
      "Link": "http://www.comuni-italiani.it/091/028/"
    },
    {
      "Istat": "053010",
      "Comune": "Gavorrano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0566",
      "CAP": 58023,
      "CodFisco": "D948",
      "Abitanti": 8982,
      "Link": "http://www.comuni-italiani.it/053/010/"
    },
    {
      "Istat": "020024",
      "Comune": "Gazoldo degli Ippoliti",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "D949",
      "Abitanti": 3033,
      "Link": "http://www.comuni-italiani.it/020/024/"
    },
    {
      "Istat": "012073",
      "Comune": "Gazzada Schianno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21045,
      "CodFisco": "D951",
      "Abitanti": 4681,
      "Link": "http://www.comuni-italiani.it/012/073/"
    },
    {
      "Istat": "016111",
      "Comune": "Gazzaniga",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24025,
      "CodFisco": "D952",
      "Abitanti": 5161,
      "Link": "http://www.comuni-italiani.it/016/111/"
    },
    {
      "Istat": "028041",
      "Comune": "Gazzo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "D956",
      "Abitanti": 4282,
      "Link": "http://www.comuni-italiani.it/028/041/"
    },
    {
      "Istat": "023037",
      "Comune": "Gazzo Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37060,
      "CodFisco": "D957",
      "Abitanti": 5558,
      "Link": "http://www.comuni-italiani.it/023/037/"
    },
    {
      "Istat": "033022",
      "Comune": "Gazzola",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "D958",
      "Abitanti": 2024,
      "Link": "http://www.comuni-italiani.it/033/022/"
    },
    {
      "Istat": "020025",
      "Comune": "Gazzuolo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "D959",
      "Abitanti": 2427,
      "Link": "http://www.comuni-italiani.it/020/025/"
    },
    {
      "Istat": "085007",
      "Comune": "Gela",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 93012,
      "CodFisco": "D960",
      "Abitanti": 77360,
      "Link": "http://www.comuni-italiani.it/085/007/"
    },
    {
      "Istat": "099004",
      "Comune": "Gemmano",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47855,
      "CodFisco": "D961",
      "Abitanti": 1161,
      "Link": "http://www.comuni-italiani.it/099/004/"
    },
    {
      "Istat": "030043",
      "Comune": "Gemona del Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33013,
      "CodFisco": "D962",
      "Abitanti": 11241,
      "Link": "http://www.comuni-italiani.it/030/043/"
    },
    {
      "Istat": "012074",
      "Comune": "Gemonio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21036,
      "CodFisco": "D963",
      "Abitanti": 2907,
      "Link": "http://www.comuni-italiani.it/012/074/"
    },
    {
      "Istat": "058042",
      "Comune": "Genazzano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "D964",
      "Abitanti": 6036,
      "Link": "http://www.comuni-italiani.it/058/042/"
    },
    {
      "Istat": "042020",
      "Comune": "Genga",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0732",
      "CAP": 60040,
      "CodFisco": "D965",
      "Abitanti": 1973,
      "Link": "http://www.comuni-italiani.it/042/020/"
    },
    {
      "Istat": "019047",
      "Comune": "Genivolta",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "D966",
      "Abitanti": 1188,
      "Link": "http://www.comuni-italiani.it/019/047/"
    },
    {
      "Istat": "004096",
      "Comune": "Genola",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "D967",
      "Abitanti": 2567,
      "Link": "http://www.comuni-italiani.it/004/096/"
    },
    {
      "Istat": "095081",
      "Comune": "Genoni",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "D968",
      "Abitanti": 903,
      "Link": "http://www.comuni-italiani.it/095/081/"
    },
    {
      "Istat": "010025",
      "Comune": "Genova",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": "161xx",
      "CodFisco": "D969",
      "Abitanti": 607906,
      "Link": "http://www.comuni-italiani.it/010/025/"
    },
    {
      "Istat": 106005,
      "Comune": "Genuri",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "D970",
      "Abitanti": 353,
      "Link": "http://www.comuni-italiani.it/106/005/"
    },
    {
      "Istat": "076036",
      "Comune": "Genzano di Lucania",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85013,
      "CodFisco": "D971",
      "Abitanti": 6072,
      "Link": "http://www.comuni-italiani.it/076/036/"
    },
    {
      "Istat": "058043",
      "Comune": "Genzano di Roma",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00045",
      "CodFisco": "D972",
      "Abitanti": 24364,
      "Link": "http://www.comuni-italiani.it/058/043/"
    },
    {
      "Istat": "018070",
      "Comune": "Genzone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27014,
      "CodFisco": "D973",
      "Abitanti": 374,
      "Link": "http://www.comuni-italiani.it/018/070/"
    },
    {
      "Istat": "013107",
      "Comune": "Gera Lario",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "D974",
      "Abitanti": 969,
      "Link": "http://www.comuni-italiani.it/013/107/"
    },
    {
      "Istat": "080036",
      "Comune": "Gerace",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "D975",
      "Abitanti": 2836,
      "Link": "http://www.comuni-italiani.it/080/036/"
    },
    {
      "Istat": "082037",
      "Comune": "Geraci Siculo",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "D977",
      "Abitanti": 1943,
      "Link": "http://www.comuni-italiani.it/082/037/"
    },
    {
      "Istat": "058044",
      "Comune": "Gerano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00025",
      "CodFisco": "D978",
      "Abitanti": 1253,
      "Link": "http://www.comuni-italiani.it/058/044/"
    },
    {
      "Istat": "018071",
      "Comune": "Gerenzago",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "D980",
      "Abitanti": 1379,
      "Link": "http://www.comuni-italiani.it/018/071/"
    },
    {
      "Istat": "012075",
      "Comune": "Gerenzano",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 21040,
      "CodFisco": "D981",
      "Abitanti": 10401,
      "Link": "http://www.comuni-italiani.it/012/075/"
    },
    {
      "Istat": "092113",
      "Comune": "Gergei",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "D982",
      "Abitanti": 1315,
      "Link": "http://www.comuni-italiani.it/092/113/"
    },
    {
      "Istat": "001113",
      "Comune": "Germagnano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "D983",
      "Abitanti": 1293,
      "Link": "http://www.comuni-italiani.it/001/113/"
    },
    {
      "Istat": 103032,
      "Comune": "Germagno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28887,
      "CodFisco": "D984",
      "Abitanti": 187,
      "Link": "http://www.comuni-italiani.it/103/032/"
    },
    {
      "Istat": "012076",
      "Comune": "Germignaga",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "D987",
      "Abitanti": 3809,
      "Link": "http://www.comuni-italiani.it/012/076/"
    },
    {
      "Istat": 102016,
      "Comune": "Gerocarne",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89831,
      "CodFisco": "D988",
      "Abitanti": 2383,
      "Link": "http://www.comuni-italiani.it/102/016/"
    },
    {
      "Istat": "014031",
      "Comune": "Gerola Alta",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "D990",
      "Abitanti": 197,
      "Link": "http://www.comuni-italiani.it/014/031/"
    },
    {
      "Istat": "016112",
      "Comune": "Gerosa",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "D991",
      "Abitanti": 360,
      "Link": "http://www.comuni-italiani.it/016/112/"
    },
    {
      "Istat": "019048",
      "Comune": "Gerre de' Caprioli",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26040,
      "CodFisco": "D993",
      "Abitanti": 1322,
      "Link": "http://www.comuni-italiani.it/019/048/"
    },
    {
      "Istat": "092024",
      "Comune": "Gesico",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "D994",
      "Abitanti": 894,
      "Link": "http://www.comuni-italiani.it/092/024/"
    },
    {
      "Istat": "015106",
      "Comune": "Gessate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "D995",
      "Abitanti": 8690,
      "Link": "http://www.comuni-italiani.it/015/106/"
    },
    {
      "Istat": "069040",
      "Comune": "Gessopalena",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66010,
      "CodFisco": "D996",
      "Abitanti": 1614,
      "Link": "http://www.comuni-italiani.it/069/040/"
    },
    {
      "Istat": 106006,
      "Comune": "Gesturi",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "D997",
      "Abitanti": 1286,
      "Link": "http://www.comuni-italiani.it/106/006/"
    },
    {
      "Istat": "064036",
      "Comune": "Gesualdo",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83040,
      "CodFisco": "D998",
      "Abitanti": 3692,
      "Link": "http://www.comuni-italiani.it/064/036/"
    },
    {
      "Istat": "017078",
      "Comune": "Ghedi",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25016,
      "CodFisco": "D999",
      "Abitanti": 18694,
      "Link": "http://www.comuni-italiani.it/017/078/"
    },
    {
      "Istat": "003073",
      "Comune": "Ghemme",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 28074,
      "CodFisco": "E001",
      "Abitanti": 3752,
      "Link": "http://www.comuni-italiani.it/003/073/"
    },
    {
      "Istat": 103033,
      "Comune": "Ghiffa",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28823,
      "CodFisco": "E003",
      "Abitanti": 2412,
      "Link": "http://www.comuni-italiani.it/103/033/"
    },
    {
      "Istat": "095021",
      "Comune": "Ghilarza",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09074",
      "CodFisco": "E004",
      "Abitanti": 4700,
      "Link": "http://www.comuni-italiani.it/095/021/"
    },
    {
      "Istat": "016113",
      "Comune": "Ghisalba",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "E006",
      "Abitanti": 5945,
      "Link": "http://www.comuni-italiani.it/016/113/"
    },
    {
      "Istat": "002062",
      "Comune": "Ghislarengo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "E007",
      "Abitanti": 908,
      "Link": "http://www.comuni-italiani.it/002/062/"
    },
    {
      "Istat": "029027",
      "Comune": "Giacciano con Baruchella",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "E008",
      "Abitanti": 2246,
      "Link": "http://www.comuni-italiani.it/029/027/"
    },
    {
      "Istat": "001114",
      "Comune": "Giaglione",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "E009",
      "Abitanti": 661,
      "Link": "http://www.comuni-italiani.it/001/114/"
    },
    {
      "Istat": "017079",
      "Comune": "Gianico",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "E010",
      "Abitanti": 2219,
      "Link": "http://www.comuni-italiani.it/017/079/"
    },
    {
      "Istat": "054021",
      "Comune": "Giano dell'Umbria",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06030",
      "CodFisco": "E012",
      "Abitanti": 3826,
      "Link": "http://www.comuni-italiani.it/054/021/"
    },
    {
      "Istat": "061040",
      "Comune": "Giano Vetusto",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81042,
      "CodFisco": "E011",
      "Abitanti": 670,
      "Link": "http://www.comuni-italiani.it/061/040/"
    },
    {
      "Istat": "082038",
      "Comune": "Giardinello",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "E013",
      "Abitanti": 2260,
      "Link": "http://www.comuni-italiani.it/082/038/"
    },
    {
      "Istat": "083032",
      "Comune": "Giardini-Naxos",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98035,
      "CodFisco": "E014",
      "Abitanti": 9647,
      "Link": "http://www.comuni-italiani.it/083/032/"
    },
    {
      "Istat": "006082",
      "Comune": "Giarole",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15036,
      "CodFisco": "E015",
      "Abitanti": 743,
      "Link": "http://www.comuni-italiani.it/006/082/"
    },
    {
      "Istat": "088004",
      "Comune": "Giarratana",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97010,
      "CodFisco": "E016",
      "Abitanti": 3172,
      "Link": "http://www.comuni-italiani.it/088/004/"
    },
    {
      "Istat": "087017",
      "Comune": "Giarre",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95014,
      "CodFisco": "E017",
      "Abitanti": 27785,
      "Link": "http://www.comuni-italiani.it/087/017/"
    },
    {
      "Istat": "090030",
      "Comune": "Giave",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "E019",
      "Abitanti": 603,
      "Link": "http://www.comuni-italiani.it/090/030/"
    },
    {
      "Istat": "001115",
      "Comune": "Giaveno",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10094,
      "CodFisco": "E020",
      "Abitanti": 16730,
      "Link": "http://www.comuni-italiani.it/001/115/"
    },
    {
      "Istat": "026032",
      "Comune": "Giavera del Montello",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "E021",
      "Abitanti": 5211,
      "Link": "http://www.comuni-italiani.it/026/032/"
    },
    {
      "Istat": 107007,
      "Comune": "Giba",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "E022",
      "Abitanti": 2151,
      "Link": "http://www.comuni-italiani.it/107/007/"
    },
    {
      "Istat": "081010",
      "Comune": "Gibellina",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91024,
      "CodFisco": "E023",
      "Abitanti": 4298,
      "Link": "http://www.comuni-italiani.it/081/010/"
    },
    {
      "Istat": "096027",
      "Comune": "Gifflenga",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13874,
      "CodFisco": "E024",
      "Abitanti": 135,
      "Link": "http://www.comuni-italiani.it/096/027/"
    },
    {
      "Istat": "080037",
      "Comune": "Giffone",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "E025",
      "Abitanti": 1961,
      "Link": "http://www.comuni-italiani.it/080/037/"
    },
    {
      "Istat": "065055",
      "Comune": "Giffoni Sei Casali",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84090,
      "CodFisco": "E026",
      "Abitanti": 5322,
      "Link": "http://www.comuni-italiani.it/065/055/"
    },
    {
      "Istat": "065056",
      "Comune": "Giffoni Valle Piana",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84095,
      "CodFisco": "E027",
      "Abitanti": 12079,
      "Link": "http://www.comuni-italiani.it/065/056/"
    },
    {
      "Istat": 103034,
      "Comune": "Gignese",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28836,
      "CodFisco": "E028",
      "Abitanti": 973,
      "Link": "http://www.comuni-italiani.it/103/034/"
    },
    {
      "Istat": "007030",
      "Comune": "Gignod",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "E029",
      "Abitanti": 1612,
      "Link": "http://www.comuni-italiani.it/007/030/"
    },
    {
      "Istat": "070026",
      "Comune": "Gildone",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "E030",
      "Abitanti": 884,
      "Link": "http://www.comuni-italiani.it/070/026/"
    },
    {
      "Istat": "079058",
      "Comune": "Gimigliano",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88045,
      "CodFisco": "E031",
      "Abitanti": 3444,
      "Link": "http://www.comuni-italiani.it/079/058/"
    },
    {
      "Istat": "076099",
      "Comune": "Ginestra",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85020,
      "CodFisco": "E033",
      "Abitanti": 748,
      "Link": "http://www.comuni-italiani.it/076/099/"
    },
    {
      "Istat": "062036",
      "Comune": "Ginestra degli Schiavoni",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "E034",
      "Abitanti": 526,
      "Link": "http://www.comuni-italiani.it/062/036/"
    },
    {
      "Istat": "073007",
      "Comune": "Ginosa",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74013,
      "CodFisco": "E036",
      "Abitanti": 22802,
      "Link": "http://www.comuni-italiani.it/073/007/"
    },
    {
      "Istat": "065057",
      "Comune": "Gioi",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84056,
      "CodFisco": "E037",
      "Abitanti": 1366,
      "Link": "http://www.comuni-italiani.it/065/057/"
    },
    {
      "Istat": "066046",
      "Comune": "Gioia dei Marsi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67055,
      "CodFisco": "E040",
      "Abitanti": 2231,
      "Link": "http://www.comuni-italiani.it/066/046/"
    },
    {
      "Istat": "072021",
      "Comune": "Gioia del Colle",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70023,
      "CodFisco": "E038",
      "Abitanti": 28100,
      "Link": "http://www.comuni-italiani.it/072/021/"
    },
    {
      "Istat": "061041",
      "Comune": "Gioia Sannitica",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "E039",
      "Abitanti": 3575,
      "Link": "http://www.comuni-italiani.it/061/041/"
    },
    {
      "Istat": "080038",
      "Comune": "Gioia Tauro",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89013,
      "CodFisco": "E041",
      "Abitanti": 18683,
      "Link": "http://www.comuni-italiani.it/080/038/"
    },
    {
      "Istat": "080039",
      "Comune": "Gioiosa Ionica",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89042,
      "CodFisco": "E044",
      "Abitanti": 7254,
      "Link": "http://www.comuni-italiani.it/080/039/"
    },
    {
      "Istat": "083033",
      "Comune": "Gioiosa Marea",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98063,
      "CodFisco": "E043",
      "Abitanti": 7209,
      "Link": "http://www.comuni-italiani.it/083/033/"
    },
    {
      "Istat": "055014",
      "Comune": "Giove",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05024",
      "CodFisco": "E045",
      "Abitanti": 1941,
      "Link": "http://www.comuni-italiani.it/055/014/"
    },
    {
      "Istat": "072022",
      "Comune": "Giovinazzo",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70054,
      "CodFisco": "E047",
      "Abitanti": 20593,
      "Link": "http://www.comuni-italiani.it/072/022/"
    },
    {
      "Istat": "022092",
      "Comune": "Giovo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "E048",
      "Abitanti": 2490,
      "Link": "http://www.comuni-italiani.it/022/092/"
    },
    {
      "Istat": 105007,
      "Comune": "Girasole",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "E049",
      "Abitanti": 1161,
      "Link": "http://www.comuni-italiani.it/105/007/"
    },
    {
      "Istat": "079059",
      "Comune": "Girifalco",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88024,
      "CodFisco": "E050",
      "Abitanti": 6164,
      "Link": "http://www.comuni-italiani.it/079/059/"
    },
    {
      "Istat": "013109",
      "Comune": "Gironico",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "E051",
      "Abitanti": 2276,
      "Link": "http://www.comuni-italiani.it/013/109/"
    },
    {
      "Istat": "069041",
      "Comune": "Gissi",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66052,
      "CodFisco": "E052",
      "Abitanti": 3006,
      "Link": "http://www.comuni-italiani.it/069/041/"
    },
    {
      "Istat": "075032",
      "Comune": "Giuggianello",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73030,
      "CodFisco": "E053",
      "Abitanti": 1256,
      "Link": "http://www.comuni-italiani.it/075/032/"
    },
    {
      "Istat": "063034",
      "Comune": "Giugliano in Campania",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80014,
      "CodFisco": "E054",
      "Abitanti": 117963,
      "Link": "http://www.comuni-italiani.it/063/034/"
    },
    {
      "Istat": "082039",
      "Comune": "Giuliana",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "E055",
      "Abitanti": 2087,
      "Link": "http://www.comuni-italiani.it/082/039/"
    },
    {
      "Istat": "060041",
      "Comune": "Giuliano di Roma",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "E057",
      "Abitanti": 2373,
      "Link": "http://www.comuni-italiani.it/060/041/"
    },
    {
      "Istat": "069042",
      "Comune": "Giuliano Teatino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "E056",
      "Abitanti": 1316,
      "Link": "http://www.comuni-italiani.it/069/042/"
    },
    {
      "Istat": "067025",
      "Comune": "Giulianova",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64021,
      "CodFisco": "E058",
      "Abitanti": 23606,
      "Link": "http://www.comuni-italiani.it/067/025/"
    },
    {
      "Istat": "046016",
      "Comune": "Giuncugnano",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55030,
      "CodFisco": "E059",
      "Abitanti": 483,
      "Link": "http://www.comuni-italiani.it/046/016/"
    },
    {
      "Istat": "065058",
      "Comune": "Giungano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84050,
      "CodFisco": "E060",
      "Abitanti": 1257,
      "Link": "http://www.comuni-italiani.it/065/058/"
    },
    {
      "Istat": "075033",
      "Comune": "Giurdignano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "E061",
      "Abitanti": 1917,
      "Link": "http://www.comuni-italiani.it/075/033/"
    },
    {
      "Istat": "018072",
      "Comune": "Giussago",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "E062",
      "Abitanti": 5038,
      "Link": "http://www.comuni-italiani.it/018/072/"
    },
    {
      "Istat": 108024,
      "Comune": "Giussano",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20833,
      "CodFisco": "E063",
      "Abitanti": 24672,
      "Link": "http://www.comuni-italiani.it/108/024/"
    },
    {
      "Istat": "009031",
      "Comune": "Giustenice",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17027,
      "CodFisco": "E064",
      "Abitanti": 959,
      "Link": "http://www.comuni-italiani.it/009/031/"
    },
    {
      "Istat": "022093",
      "Comune": "Giustino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38086,
      "CodFisco": "E065",
      "Abitanti": 750,
      "Link": "http://www.comuni-italiani.it/022/093/"
    },
    {
      "Istat": "009032",
      "Comune": "Giusvalla",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17010,
      "CodFisco": "E066",
      "Abitanti": 469,
      "Link": "http://www.comuni-italiani.it/009/032/"
    },
    {
      "Istat": "001116",
      "Comune": "Givoletto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "E067",
      "Abitanti": 3509,
      "Link": "http://www.comuni-italiani.it/001/116/"
    },
    {
      "Istat": "079060",
      "Comune": "Gizzeria",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "E068",
      "Abitanti": 4445,
      "Link": "http://www.comuni-italiani.it/079/060/"
    },
    {
      "Istat": "021036",
      "Comune": "Glorenza",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "E069",
      "Abitanti": 880,
      "Link": "http://www.comuni-italiani.it/021/036/"
    },
    {
      "Istat": "026033",
      "Comune": "Godega di Sant'Urbano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31010,
      "CodFisco": "E071",
      "Abitanti": 6173,
      "Link": "http://www.comuni-italiani.it/026/033/"
    },
    {
      "Istat": "018073",
      "Comune": "Godiasco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27052,
      "CodFisco": "E072",
      "Abitanti": 3218,
      "Link": "http://www.comuni-italiani.it/018/073/"
    },
    {
      "Istat": "082040",
      "Comune": "Godrano",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "E074",
      "Abitanti": 1175,
      "Link": "http://www.comuni-italiani.it/082/040/"
    },
    {
      "Istat": "020026",
      "Comune": "Goito",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46044,
      "CodFisco": "E078",
      "Abitanti": 10355,
      "Link": "http://www.comuni-italiani.it/020/026/"
    },
    {
      "Istat": "012077",
      "Comune": "Golasecca",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21010,
      "CodFisco": "E079",
      "Abitanti": 2694,
      "Link": "http://www.comuni-italiani.it/012/077/"
    },
    {
      "Istat": "018074",
      "Comune": "Golferenzo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27047,
      "CodFisco": "E081",
      "Abitanti": 216,
      "Link": "http://www.comuni-italiani.it/018/074/"
    },
    {
      "Istat": 104011,
      "Comune": "Golfo Aranci",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07020",
      "CodFisco": "M274",
      "Abitanti": 2414,
      "Link": "http://www.comuni-italiani.it/104/011/"
    },
    {
      "Istat": "019049",
      "Comune": "Gombito",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "E082",
      "Abitanti": 653,
      "Link": "http://www.comuni-italiani.it/019/049/"
    },
    {
      "Istat": "030044",
      "Comune": "Gonars",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "E083",
      "Abitanti": 4810,
      "Link": "http://www.comuni-italiani.it/030/044/"
    },
    {
      "Istat": "092027",
      "Comune": "Goni",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "E084",
      "Abitanti": 524,
      "Link": "http://www.comuni-italiani.it/092/027/"
    },
    {
      "Istat": 107008,
      "Comune": "Gonnesa",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "E086",
      "Abitanti": 5161,
      "Link": "http://www.comuni-italiani.it/107/008/"
    },
    {
      "Istat": "095022",
      "Comune": "Gonnoscodina",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "E087",
      "Abitanti": 514,
      "Link": "http://www.comuni-italiani.it/095/022/"
    },
    {
      "Istat": 106007,
      "Comune": "Gonnosfanadiga",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09035",
      "CodFisco": "E085",
      "Abitanti": 6917,
      "Link": "http://www.comuni-italiani.it/106/007/"
    },
    {
      "Istat": "095023",
      "Comune": "GonnosnÚ",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "D585",
      "Abitanti": 808,
      "Link": "http://www.comuni-italiani.it/095/023/"
    },
    {
      "Istat": "095024",
      "Comune": "Gonnostramatza",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09093",
      "CodFisco": "E088",
      "Abitanti": 939,
      "Link": "http://www.comuni-italiani.it/095/024/"
    },
    {
      "Istat": "020027",
      "Comune": "Gonzaga",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46023,
      "CodFisco": "E089",
      "Abitanti": 9301,
      "Link": "http://www.comuni-italiani.it/020/027/"
    },
    {
      "Istat": "014032",
      "Comune": "Gordona",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "E090",
      "Abitanti": 1841,
      "Link": "http://www.comuni-italiani.it/014/032/"
    },
    {
      "Istat": "058045",
      "Comune": "Gorga",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "E091",
      "Abitanti": 778,
      "Link": "http://www.comuni-italiani.it/058/045/"
    },
    {
      "Istat": "026034",
      "Comune": "Gorgo al Monticano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "E092",
      "Abitanti": 4203,
      "Link": "http://www.comuni-italiani.it/026/034/"
    },
    {
      "Istat": "077010",
      "Comune": "Gorgoglione",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "E093",
      "Abitanti": 1064,
      "Link": "http://www.comuni-italiani.it/077/010/"
    },
    {
      "Istat": "015108",
      "Comune": "Gorgonzola",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20064,
      "CodFisco": "E094",
      "Abitanti": 19649,
      "Link": "http://www.comuni-italiani.it/015/108/"
    },
    {
      "Istat": "066047",
      "Comune": "Goriano Sicoli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "E096",
      "Abitanti": 617,
      "Link": "http://www.comuni-italiani.it/066/047/"
    },
    {
      "Istat": "031007",
      "Comune": "Gorizia",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34170,
      "CodFisco": "E098",
      "Abitanti": 35798,
      "Link": "http://www.comuni-italiani.it/031/007/"
    },
    {
      "Istat": "012078",
      "Comune": "Gorla Maggiore",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21050,
      "CodFisco": "E101",
      "Abitanti": 5097,
      "Link": "http://www.comuni-italiani.it/012/078/"
    },
    {
      "Istat": "012079",
      "Comune": "Gorla Minore",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21055,
      "CodFisco": "E102",
      "Abitanti": 8512,
      "Link": "http://www.comuni-italiani.it/012/079/"
    },
    {
      "Istat": "016114",
      "Comune": "Gorlago",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "E100",
      "Abitanti": 5062,
      "Link": "http://www.comuni-italiani.it/016/114/"
    },
    {
      "Istat": "016115",
      "Comune": "Gorle",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "E103",
      "Abitanti": 6404,
      "Link": "http://www.comuni-italiani.it/016/115/"
    },
    {
      "Istat": "012080",
      "Comune": "Gornate-Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "E104",
      "Abitanti": 2232,
      "Link": "http://www.comuni-italiani.it/012/080/"
    },
    {
      "Istat": "016116",
      "Comune": "Gorno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "E106",
      "Abitanti": 1684,
      "Link": "http://www.comuni-italiani.it/016/116/"
    },
    {
      "Istat": "038025",
      "Comune": "Goro",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44020,
      "CodFisco": "E107",
      "Abitanti": 3945,
      "Link": "http://www.comuni-italiani.it/038/025/"
    },
    {
      "Istat": "010026",
      "Comune": "Gorreto",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16020,
      "CodFisco": "E109",
      "Abitanti": 113,
      "Link": "http://www.comuni-italiani.it/010/026/"
    },
    {
      "Istat": "004097",
      "Comune": "Gorzegno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12070,
      "CodFisco": "E111",
      "Abitanti": 332,
      "Link": "http://www.comuni-italiani.it/004/097/"
    },
    {
      "Istat": "025025",
      "Comune": "Gosaldo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "E113",
      "Abitanti": 738,
      "Link": "http://www.comuni-italiani.it/025/025/"
    },
    {
      "Istat": "033023",
      "Comune": "Gossolengo",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "E114",
      "Abitanti": 5328,
      "Link": "http://www.comuni-italiani.it/033/023/"
    },
    {
      "Istat": "004098",
      "Comune": "Gottasecca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "E115",
      "Abitanti": 183,
      "Link": "http://www.comuni-italiani.it/004/098/"
    },
    {
      "Istat": "017080",
      "Comune": "Gottolengo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25023,
      "CodFisco": "E116",
      "Abitanti": 5364,
      "Link": "http://www.comuni-italiani.it/017/080/"
    },
    {
      "Istat": "004099",
      "Comune": "Govone",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "E118",
      "Abitanti": 2132,
      "Link": "http://www.comuni-italiani.it/004/099/"
    },
    {
      "Istat": "003076",
      "Comune": "Gozzano",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28024,
      "CodFisco": "E120",
      "Abitanti": 5671,
      "Link": "http://www.comuni-italiani.it/003/076/"
    },
    {
      "Istat": "041020",
      "Comune": "Gradara",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0541",
      "CAP": 61012,
      "CodFisco": "E122",
      "Abitanti": 4764,
      "Link": "http://www.comuni-italiani.it/041/020/"
    },
    {
      "Istat": "031008",
      "Comune": "Gradisca d'Isonzo",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34072,
      "CodFisco": "E124",
      "Abitanti": 6617,
      "Link": "http://www.comuni-italiani.it/031/008/"
    },
    {
      "Istat": "031009",
      "Comune": "Grado",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 34073,
      "CodFisco": "E125",
      "Abitanti": 8611,
      "Link": "http://www.comuni-italiani.it/031/009/"
    },
    {
      "Istat": "056028",
      "Comune": "Gradoli",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "E126",
      "Abitanti": 1483,
      "Link": "http://www.comuni-italiani.it/056/028/"
    },
    {
      "Istat": "098028",
      "Comune": "Graffignana",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26813,
      "CodFisco": "E127",
      "Abitanti": 2680,
      "Link": "http://www.comuni-italiani.it/098/028/"
    },
    {
      "Istat": "056029",
      "Comune": "Graffignano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01020",
      "CodFisco": "E128",
      "Abitanti": 2355,
      "Link": "http://www.comuni-italiani.it/056/029/"
    },
    {
      "Istat": "096028",
      "Comune": "Graglia",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13895,
      "CodFisco": "E130",
      "Abitanti": 1606,
      "Link": "http://www.comuni-italiani.it/096/028/"
    },
    {
      "Istat": "063035",
      "Comune": "Gragnano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80054,
      "CodFisco": "E131",
      "Abitanti": 29719,
      "Link": "http://www.comuni-italiani.it/063/035/"
    },
    {
      "Istat": "033024",
      "Comune": "Gragnano Trebbiense",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "E132",
      "Abitanti": 4397,
      "Link": "http://www.comuni-italiani.it/033/024/"
    },
    {
      "Istat": "087018",
      "Comune": "Grammichele",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95042,
      "CodFisco": "E133",
      "Abitanti": 13404,
      "Link": "http://www.comuni-italiani.it/087/018/"
    },
    {
      "Istat": "005056",
      "Comune": "Grana",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14031,
      "CodFisco": "E134",
      "Abitanti": 638,
      "Link": "http://www.comuni-italiani.it/005/056/"
    },
    {
      "Istat": "037029",
      "Comune": "Granaglione",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40045,
      "CodFisco": "E135",
      "Abitanti": 2266,
      "Link": "http://www.comuni-italiani.it/037/029/"
    },
    {
      "Istat": "037030",
      "Comune": "Granarolo dell'Emilia",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40057,
      "CodFisco": "E136",
      "Abitanti": 10653,
      "Link": "http://www.comuni-italiani.it/037/030/"
    },
    {
      "Istat": "024045",
      "Comune": "Grancona",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "E138",
      "Abitanti": 1883,
      "Link": "http://www.comuni-italiani.it/024/045/"
    },
    {
      "Istat": "013110",
      "Comune": "Grandate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "E139",
      "Abitanti": 2921,
      "Link": "http://www.comuni-italiani.it/013/110/"
    },
    {
      "Istat": "013111",
      "Comune": "Grandola ed Uniti",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "E141",
      "Abitanti": 1358,
      "Link": "http://www.comuni-italiani.it/013/111/"
    },
    {
      "Istat": "083034",
      "Comune": "Graniti",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98036,
      "CodFisco": "E142",
      "Abitanti": 1547,
      "Link": "http://www.comuni-italiani.it/083/034/"
    },
    {
      "Istat": "003077",
      "Comune": "Granozzo con Monticello",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "E143",
      "Abitanti": 1432,
      "Link": "http://www.comuni-italiani.it/003/077/"
    },
    {
      "Istat": "012081",
      "Comune": "Grantola",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "E144",
      "Abitanti": 1289,
      "Link": "http://www.comuni-italiani.it/012/081/"
    },
    {
      "Istat": "028042",
      "Comune": "Grantorto",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "E145",
      "Abitanti": 4704,
      "Link": "http://www.comuni-italiani.it/028/042/"
    },
    {
      "Istat": "028043",
      "Comune": "Granze",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "E146",
      "Abitanti": 2024,
      "Link": "http://www.comuni-italiani.it/028/043/"
    },
    {
      "Istat": "077011",
      "Comune": "Grassano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75014,
      "CodFisco": "E147",
      "Abitanti": 5494,
      "Link": "http://www.comuni-italiani.it/077/011/"
    },
    {
      "Istat": "016117",
      "Comune": "Grassobbio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "E148",
      "Abitanti": 6322,
      "Link": "http://www.comuni-italiani.it/016/117/"
    },
    {
      "Istat": "082041",
      "Comune": "Gratteri",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "E149",
      "Abitanti": 1016,
      "Link": "http://www.comuni-italiani.it/082/041/"
    },
    {
      "Istat": "022094",
      "Comune": "Grauno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "E150",
      "Abitanti": 149,
      "Link": "http://www.comuni-italiani.it/022/094/"
    },
    {
      "Istat": "013249",
      "Comune": "Gravedona ed Uniti",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22015,
      "CodFisco": "M315",
      "Abitanti": 4226,
      "Link": "http://www.comuni-italiani.it/013/249/"
    },
    {
      "Istat": "018075",
      "Comune": "Gravellona Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0381",
      "CAP": 27020,
      "CodFisco": "E152",
      "Abitanti": 2651,
      "Link": "http://www.comuni-italiani.it/018/075/"
    },
    {
      "Istat": 103035,
      "Comune": "Gravellona Toce",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28883,
      "CodFisco": "E153",
      "Abitanti": 7868,
      "Link": "http://www.comuni-italiani.it/103/035/"
    },
    {
      "Istat": "001117",
      "Comune": "Gravere",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "E154",
      "Abitanti": 745,
      "Link": "http://www.comuni-italiani.it/001/117/"
    },
    {
      "Istat": "087019",
      "Comune": "Gravina di Catania",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "E156",
      "Abitanti": 27363,
      "Link": "http://www.comuni-italiani.it/087/019/"
    },
    {
      "Istat": "072023",
      "Comune": "Gravina in Puglia",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70024,
      "CodFisco": "E155",
      "Abitanti": 44383,
      "Link": "http://www.comuni-italiani.it/072/023/"
    },
    {
      "Istat": "061042",
      "Comune": "Grazzanise",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81046,
      "CodFisco": "E158",
      "Abitanti": 6812,
      "Link": "http://www.comuni-italiani.it/061/042/"
    },
    {
      "Istat": "005057",
      "Comune": "Grazzano Badoglio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14035,
      "CodFisco": "E159",
      "Abitanti": 615,
      "Link": "http://www.comuni-italiani.it/005/057/"
    },
    {
      "Istat": "057031",
      "Comune": "Greccio",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02045",
      "CodFisco": "E160",
      "Abitanti": 1558,
      "Link": "http://www.comuni-italiani.it/057/031/"
    },
    {
      "Istat": "064037",
      "Comune": "Greci",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "E161",
      "Abitanti": 772,
      "Link": "http://www.comuni-italiani.it/064/037/"
    },
    {
      "Istat": "002065",
      "Comune": "Greggio",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "E163",
      "Abitanti": 390,
      "Link": "http://www.comuni-italiani.it/002/065/"
    },
    {
      "Istat": "006083",
      "Comune": "Gremiasco",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15056,
      "CodFisco": "E164",
      "Abitanti": 362,
      "Link": "http://www.comuni-italiani.it/006/083/"
    },
    {
      "Istat": "007031",
      "Comune": "Gressan",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "E165",
      "Abitanti": 3327,
      "Link": "http://www.comuni-italiani.it/007/031/"
    },
    {
      "Istat": "007032",
      "Comune": "Gressoney-la-TrinitË",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "E167",
      "Abitanti": 309,
      "Link": "http://www.comuni-italiani.it/007/032/"
    },
    {
      "Istat": "007033",
      "Comune": "Gressoney-Saint-Jean",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11025,
      "CodFisco": "E168",
      "Abitanti": 821,
      "Link": "http://www.comuni-italiani.it/007/033/"
    },
    {
      "Istat": "048021",
      "Comune": "Greve in Chianti",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50022,
      "CodFisco": "E169",
      "Abitanti": 14351,
      "Link": "http://www.comuni-italiani.it/048/021/"
    },
    {
      "Istat": "015110",
      "Comune": "Grezzago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20056,
      "CodFisco": "E170",
      "Abitanti": 2804,
      "Link": "http://www.comuni-italiani.it/015/110/"
    },
    {
      "Istat": "023038",
      "Comune": "Grezzana",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37023,
      "CodFisco": "E171",
      "Abitanti": 10957,
      "Link": "http://www.comuni-italiani.it/023/038/"
    },
    {
      "Istat": "013113",
      "Comune": "Griante",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22011,
      "CodFisco": "E172",
      "Abitanti": 636,
      "Link": "http://www.comuni-italiani.it/013/113/"
    },
    {
      "Istat": "061043",
      "Comune": "Gricignano di Aversa",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "E173",
      "Abitanti": 10421,
      "Link": "http://www.comuni-italiani.it/061/043/"
    },
    {
      "Istat": "003079",
      "Comune": "Grignasco",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 28075,
      "CodFisco": "E177",
      "Abitanti": 4779,
      "Link": "http://www.comuni-italiani.it/003/079/"
    },
    {
      "Istat": "022095",
      "Comune": "Grigno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38055,
      "CodFisco": "E178",
      "Abitanti": 2299,
      "Link": "http://www.comuni-italiani.it/022/095/"
    },
    {
      "Istat": "030045",
      "Comune": "Grimacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "E179",
      "Abitanti": 395,
      "Link": "http://www.comuni-italiani.it/030/045/"
    },
    {
      "Istat": "078059",
      "Comune": "Grimaldi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87034,
      "CodFisco": "E180",
      "Abitanti": 1776,
      "Link": "http://www.comuni-italiani.it/078/059/"
    },
    {
      "Istat": "004100",
      "Comune": "Grinzane Cavour",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "E182",
      "Abitanti": 1928,
      "Link": "http://www.comuni-italiani.it/004/100/"
    },
    {
      "Istat": "024046",
      "Comune": "Grisignano di Zocco",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "E184",
      "Abitanti": 4330,
      "Link": "http://www.comuni-italiani.it/024/046/"
    },
    {
      "Istat": "078060",
      "Comune": "Grisolia",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "E185",
      "Abitanti": 2407,
      "Link": "http://www.comuni-italiani.it/078/060/"
    },
    {
      "Istat": "037031",
      "Comune": "Grizzana Morandi",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40030,
      "CodFisco": "E187",
      "Abitanti": 4060,
      "Link": "http://www.comuni-italiani.it/037/031/"
    },
    {
      "Istat": "006084",
      "Comune": "Grognardo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "E188",
      "Abitanti": 302,
      "Link": "http://www.comuni-italiani.it/006/084/"
    },
    {
      "Istat": "016118",
      "Comune": "Gromo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "E189",
      "Abitanti": 1252,
      "Link": "http://www.comuni-italiani.it/016/118/"
    },
    {
      "Istat": "006085",
      "Comune": "Grondona",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "E191",
      "Abitanti": 549,
      "Link": "http://www.comuni-italiani.it/006/085/"
    },
    {
      "Istat": "016119",
      "Comune": "Grone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "E192",
      "Abitanti": 934,
      "Link": "http://www.comuni-italiani.it/016/119/"
    },
    {
      "Istat": "019050",
      "Comune": "Grontardo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26044,
      "CodFisco": "E193",
      "Abitanti": 1465,
      "Link": "http://www.comuni-italiani.it/019/050/"
    },
    {
      "Istat": "018076",
      "Comune": "Gropello Cairoli",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27027,
      "CodFisco": "E195",
      "Abitanti": 4602,
      "Link": "http://www.comuni-italiani.it/018/076/"
    },
    {
      "Istat": "033025",
      "Comune": "Gropparello",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29025,
      "CodFisco": "E196",
      "Abitanti": 2468,
      "Link": "http://www.comuni-italiani.it/033/025/"
    },
    {
      "Istat": "001118",
      "Comune": "Groscavallo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "E199",
      "Abitanti": 205,
      "Link": "http://www.comuni-italiani.it/001/118/"
    },
    {
      "Istat": "014033",
      "Comune": "Grosio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23033,
      "CodFisco": "E200",
      "Abitanti": 4634,
      "Link": "http://www.comuni-italiani.it/014/033/"
    },
    {
      "Istat": "014034",
      "Comune": "Grosotto",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23034,
      "CodFisco": "E201",
      "Abitanti": 1633,
      "Link": "http://www.comuni-italiani.it/014/034/"
    },
    {
      "Istat": "053011",
      "Comune": "Grosseto",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58100,
      "CodFisco": "E202",
      "Abitanti": 81928,
      "Link": "http://www.comuni-italiani.it/053/011/"
    },
    {
      "Istat": "001119",
      "Comune": "Grosso",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "E203",
      "Abitanti": 1055,
      "Link": "http://www.comuni-italiani.it/001/119/"
    },
    {
      "Istat": "058046",
      "Comune": "Grottaferrata",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00046",
      "CodFisco": "E204",
      "Abitanti": 21039,
      "Link": "http://www.comuni-italiani.it/058/046/"
    },
    {
      "Istat": "073008",
      "Comune": "Grottaglie",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74023,
      "CodFisco": "E205",
      "Abitanti": 32791,
      "Link": "http://www.comuni-italiani.it/073/008/"
    },
    {
      "Istat": "064038",
      "Comune": "Grottaminarda",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83035,
      "CodFisco": "E206",
      "Abitanti": 8359,
      "Link": "http://www.comuni-italiani.it/064/038/"
    },
    {
      "Istat": "044023",
      "Comune": "Grottammare",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63066,
      "CodFisco": "E207",
      "Abitanti": 15652,
      "Link": "http://www.comuni-italiani.it/044/023/"
    },
    {
      "Istat": 109008,
      "Comune": "Grottazzolina",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63844,
      "CodFisco": "E208",
      "Abitanti": 3333,
      "Link": "http://www.comuni-italiani.it/109/008/"
    },
    {
      "Istat": "084018",
      "Comune": "Grotte",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "E209",
      "Abitanti": 5911,
      "Link": "http://www.comuni-italiani.it/084/018/"
    },
    {
      "Istat": "056030",
      "Comune": "Grotte di Castro",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0763",
      "CAP": "01025",
      "CodFisco": "E210",
      "Abitanti": 2833,
      "Link": "http://www.comuni-italiani.it/056/030/"
    },
    {
      "Istat": "080040",
      "Comune": "Grotteria",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89043,
      "CodFisco": "E212",
      "Abitanti": 3273,
      "Link": "http://www.comuni-italiani.it/080/040/"
    },
    {
      "Istat": "077012",
      "Comune": "Grottole",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "E213",
      "Abitanti": 2420,
      "Link": "http://www.comuni-italiani.it/077/012/"
    },
    {
      "Istat": "064039",
      "Comune": "Grottolella",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "E214",
      "Abitanti": 2020,
      "Link": "http://www.comuni-italiani.it/064/039/"
    },
    {
      "Istat": "027018",
      "Comune": "Gruaro",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "E215",
      "Abitanti": 2831,
      "Link": "http://www.comuni-italiani.it/027/018/"
    },
    {
      "Istat": "001120",
      "Comune": "Grugliasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10095,
      "CodFisco": "E216",
      "Abitanti": 37870,
      "Link": "http://www.comuni-italiani.it/001/120/"
    },
    {
      "Istat": "019051",
      "Comune": "Grumello Cremonese ed Uniti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26023,
      "CodFisco": "E217",
      "Abitanti": 1908,
      "Link": "http://www.comuni-italiani.it/019/051/"
    },
    {
      "Istat": "016120",
      "Comune": "Grumello del Monte",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24064,
      "CodFisco": "E219",
      "Abitanti": 7360,
      "Link": "http://www.comuni-italiani.it/016/120/"
    },
    {
      "Istat": "076037",
      "Comune": "Grumento Nova",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "E221",
      "Abitanti": 1727,
      "Link": "http://www.comuni-italiani.it/076/037/"
    },
    {
      "Istat": "022096",
      "Comune": "Grumes",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "E222",
      "Abitanti": 441,
      "Link": "http://www.comuni-italiani.it/022/096/"
    },
    {
      "Istat": "072024",
      "Comune": "Grumo Appula",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70025,
      "CodFisco": "E223",
      "Abitanti": 13145,
      "Link": "http://www.comuni-italiani.it/072/024/"
    },
    {
      "Istat": "063036",
      "Comune": "Grumo Nevano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80028,
      "CodFisco": "E224",
      "Abitanti": 18269,
      "Link": "http://www.comuni-italiani.it/063/036/"
    },
    {
      "Istat": "024047",
      "Comune": "Grumolo delle Abbadesse",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "E226",
      "Abitanti": 3798,
      "Link": "http://www.comuni-italiani.it/024/047/"
    },
    {
      "Istat": "075034",
      "Comune": "Guagnano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "E227",
      "Abitanti": 5943,
      "Link": "http://www.comuni-italiani.it/075/034/"
    },
    {
      "Istat": "043021",
      "Comune": "Gualdo",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "E228",
      "Abitanti": 906,
      "Link": "http://www.comuni-italiani.it/043/021/"
    },
    {
      "Istat": "054022",
      "Comune": "Gualdo Cattaneo",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06035",
      "CodFisco": "E229",
      "Abitanti": 6472,
      "Link": "http://www.comuni-italiani.it/054/022/"
    },
    {
      "Istat": "054023",
      "Comune": "Gualdo Tadino",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06023",
      "CodFisco": "E230",
      "Abitanti": 15740,
      "Link": "http://www.comuni-italiani.it/054/023/"
    },
    {
      "Istat": "035023",
      "Comune": "Gualtieri",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42044,
      "CodFisco": "E232",
      "Abitanti": 6696,
      "Link": "http://www.comuni-italiani.it/035/023/"
    },
    {
      "Istat": "083035",
      "Comune": "Gualtieri SicaminÚ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "E233",
      "Abitanti": 1846,
      "Link": "http://www.comuni-italiani.it/083/035/"
    },
    {
      "Istat": "092030",
      "Comune": "Guamaggiore",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "E234",
      "Abitanti": 1066,
      "Link": "http://www.comuni-italiani.it/092/030/"
    },
    {
      "Istat": "013114",
      "Comune": "Guanzate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "E235",
      "Abitanti": 5719,
      "Link": "http://www.comuni-italiani.it/013/114/"
    },
    {
      "Istat": "060042",
      "Comune": "Guarcino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03016",
      "CodFisco": "E236",
      "Abitanti": 1700,
      "Link": "http://www.comuni-italiani.it/060/042/"
    },
    {
      "Istat": "029028",
      "Comune": "Guarda Veneta",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "E240",
      "Abitanti": 1213,
      "Link": "http://www.comuni-italiani.it/029/028/"
    },
    {
      "Istat": "002066",
      "Comune": "Guardabosone",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13010,
      "CodFisco": "E237",
      "Abitanti": 356,
      "Link": "http://www.comuni-italiani.it/002/066/"
    },
    {
      "Istat": "098029",
      "Comune": "Guardamiglio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26862,
      "CodFisco": "E238",
      "Abitanti": 2722,
      "Link": "http://www.comuni-italiani.it/098/029/"
    },
    {
      "Istat": "079061",
      "Comune": "Guardavalle",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88065,
      "CodFisco": "E239",
      "Abitanti": 4929,
      "Link": "http://www.comuni-italiani.it/079/061/"
    },
    {
      "Istat": "055015",
      "Comune": "Guardea",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05025",
      "CodFisco": "E241",
      "Abitanti": 1878,
      "Link": "http://www.comuni-italiani.it/055/015/"
    },
    {
      "Istat": "064040",
      "Comune": "Guardia Lombardi",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "E245",
      "Abitanti": 1830,
      "Link": "http://www.comuni-italiani.it/064/040/"
    },
    {
      "Istat": "076038",
      "Comune": "Guardia Perticara",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "E246",
      "Abitanti": 629,
      "Link": "http://www.comuni-italiani.it/076/038/"
    },
    {
      "Istat": "078061",
      "Comune": "Guardia Piemontese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87020,
      "CodFisco": "E242",
      "Abitanti": 1546,
      "Link": "http://www.comuni-italiani.it/078/061/"
    },
    {
      "Istat": "062037",
      "Comune": "Guardia Sanframondi",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82034,
      "CodFisco": "E249",
      "Abitanti": 5283,
      "Link": "http://www.comuni-italiani.it/062/037/"
    },
    {
      "Istat": "069043",
      "Comune": "Guardiagrele",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66016,
      "CodFisco": "E243",
      "Abitanti": 9497,
      "Link": "http://www.comuni-italiani.it/069/043/"
    },
    {
      "Istat": "070027",
      "Comune": "Guardialfiera",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "E244",
      "Abitanti": 1167,
      "Link": "http://www.comuni-italiani.it/070/027/"
    },
    {
      "Istat": "070028",
      "Comune": "Guardiaregia",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86014,
      "CodFisco": "E248",
      "Abitanti": 765,
      "Link": "http://www.comuni-italiani.it/070/028/"
    },
    {
      "Istat": "050015",
      "Comune": "Guardistallo",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 56040,
      "CodFisco": "E250",
      "Abitanti": 1296,
      "Link": "http://www.comuni-italiani.it/050/015/"
    },
    {
      "Istat": "004101",
      "Comune": "Guarene",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "E251",
      "Abitanti": 3476,
      "Link": "http://www.comuni-italiani.it/004/101/"
    },
    {
      "Istat": "092031",
      "Comune": "Guasila",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "E252",
      "Abitanti": 2794,
      "Link": "http://www.comuni-italiani.it/092/031/"
    },
    {
      "Istat": "035024",
      "Comune": "Guastalla",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42016,
      "CodFisco": "E253",
      "Abitanti": 15191,
      "Link": "http://www.comuni-italiani.it/035/024/"
    },
    {
      "Istat": "006086",
      "Comune": "Guazzora",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "E255",
      "Abitanti": 322,
      "Link": "http://www.comuni-italiani.it/006/086/"
    },
    {
      "Istat": "054024",
      "Comune": "Gubbio",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06024",
      "CodFisco": "E256",
      "Abitanti": 32998,
      "Link": "http://www.comuni-italiani.it/054/024/"
    },
    {
      "Istat": "015112",
      "Comune": "Gudo Visconti",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20088,
      "CodFisco": "E258",
      "Abitanti": 1720,
      "Link": "http://www.comuni-italiani.it/015/112/"
    },
    {
      "Istat": "070029",
      "Comune": "Guglionesi",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86034,
      "CodFisco": "E259",
      "Abitanti": 5411,
      "Link": "http://www.comuni-italiani.it/070/029/"
    },
    {
      "Istat": "020028",
      "Comune": "Guidizzolo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "E261",
      "Abitanti": 6185,
      "Link": "http://www.comuni-italiani.it/020/028/"
    },
    {
      "Istat": "058047",
      "Comune": "Guidonia Montecelio",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00012",
      "CodFisco": "E263",
      "Abitanti": 83736,
      "Link": "http://www.comuni-italiani.it/058/047/"
    },
    {
      "Istat": "036017",
      "Comune": "Guiglia",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41052,
      "CodFisco": "E264",
      "Abitanti": 4095,
      "Link": "http://www.comuni-italiani.it/036/017/"
    },
    {
      "Istat": "069044",
      "Comune": "Guilmi",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66050,
      "CodFisco": "E266",
      "Abitanti": 440,
      "Link": "http://www.comuni-italiani.it/069/044/"
    },
    {
      "Istat": 103036,
      "Comune": "Gurro",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28828,
      "CodFisco": "E269",
      "Abitanti": 252,
      "Link": "http://www.comuni-italiani.it/103/036/"
    },
    {
      "Istat": 106008,
      "Comune": "Guspini",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09036",
      "CodFisco": "E270",
      "Abitanti": 12469,
      "Link": "http://www.comuni-italiani.it/106/008/"
    },
    {
      "Istat": "017081",
      "Comune": "Gussago",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25064,
      "CodFisco": "E271",
      "Abitanti": 16759,
      "Link": "http://www.comuni-italiani.it/017/081/"
    },
    {
      "Istat": "019052",
      "Comune": "Gussola",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26040,
      "CodFisco": "E272",
      "Abitanti": 2968,
      "Link": "http://www.comuni-italiani.it/019/052/"
    },
    {
      "Istat": "007034",
      "Comune": "Hone",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "E273",
      "Abitanti": 1183,
      "Link": "http://www.comuni-italiani.it/007/034/"
    },
    {
      "Istat": "017082",
      "Comune": "Idro",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25074,
      "CodFisco": "E280",
      "Abitanti": 1893,
      "Link": "http://www.comuni-italiani.it/017/082/"
    },
    {
      "Istat": 107009,
      "Comune": "Iglesias",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09016",
      "CodFisco": "E281",
      "Abitanti": 27493,
      "Link": "http://www.comuni-italiani.it/107/009/"
    },
    {
      "Istat": "004102",
      "Comune": "Igliano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "E282",
      "Abitanti": 85,
      "Link": "http://www.comuni-italiani.it/004/102/"
    },
    {
      "Istat": 105008,
      "Comune": "Ilbono",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "E283",
      "Abitanti": 2246,
      "Link": "http://www.comuni-italiani.it/105/008/"
    },
    {
      "Istat": "023039",
      "Comune": "Illasi",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37031,
      "CodFisco": "E284",
      "Abitanti": 5392,
      "Link": "http://www.comuni-italiani.it/023/039/"
    },
    {
      "Istat": "090031",
      "Comune": "Illorai",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "E285",
      "Abitanti": 979,
      "Link": "http://www.comuni-italiani.it/090/031/"
    },
    {
      "Istat": "097039",
      "Comune": "Imbersago",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23898,
      "CodFisco": "E287",
      "Abitanti": 2431,
      "Link": "http://www.comuni-italiani.it/097/039/"
    },
    {
      "Istat": "022097",
      "Comune": "Imer",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38050,
      "CodFisco": "E288",
      "Abitanti": 1206,
      "Link": "http://www.comuni-italiani.it/022/097/"
    },
    {
      "Istat": "037032",
      "Comune": "Imola",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40026,
      "CodFisco": "E289",
      "Abitanti": 69116,
      "Link": "http://www.comuni-italiani.it/037/032/"
    },
    {
      "Istat": "008031",
      "Comune": "Imperia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18100,
      "CodFisco": "E290",
      "Abitanti": 42667,
      "Link": "http://www.comuni-italiani.it/008/031/"
    },
    {
      "Istat": "048022",
      "Comune": "Impruneta",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50023,
      "CodFisco": "E291",
      "Abitanti": 14906,
      "Link": "http://www.comuni-italiani.it/048/022/"
    },
    {
      "Istat": "012082",
      "Comune": "Inarzo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "E292",
      "Abitanti": 1089,
      "Link": "http://www.comuni-italiani.it/012/082/"
    },
    {
      "Istat": "048023",
      "Comune": "Incisa in Val d'Arno",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50064,
      "CodFisco": "E296",
      "Abitanti": 6339,
      "Link": "http://www.comuni-italiani.it/048/023/"
    },
    {
      "Istat": "005058",
      "Comune": "Incisa Scapaccino",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14045,
      "CodFisco": "E295",
      "Abitanti": 2290,
      "Link": "http://www.comuni-italiani.it/005/058/"
    },
    {
      "Istat": "017083",
      "Comune": "Incudine",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "E297",
      "Abitanti": 400,
      "Link": "http://www.comuni-italiani.it/017/083/"
    },
    {
      "Istat": "012083",
      "Comune": "Induno Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21056,
      "CodFisco": "E299",
      "Abitanti": 10476,
      "Link": "http://www.comuni-italiani.it/012/083/"
    },
    {
      "Istat": "001121",
      "Comune": "Ingria",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "E301",
      "Abitanti": 47,
      "Link": "http://www.comuni-italiani.it/001/121/"
    },
    {
      "Istat": 103037,
      "Comune": "Intragna",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28816,
      "CodFisco": "E304",
      "Abitanti": 114,
      "Link": "http://www.comuni-italiani.it/103/037/"
    },
    {
      "Istat": "097040",
      "Comune": "Introbio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23815,
      "CodFisco": "E305",
      "Abitanti": 1983,
      "Link": "http://www.comuni-italiani.it/097/040/"
    },
    {
      "Istat": "007035",
      "Comune": "Introd",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "E306",
      "Abitanti": 635,
      "Link": "http://www.comuni-italiani.it/007/035/"
    },
    {
      "Istat": "066048",
      "Comune": "Introdacqua",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "E307",
      "Abitanti": 2184,
      "Link": "http://www.comuni-italiani.it/066/048/"
    },
    {
      "Istat": "097041",
      "Comune": "Introzzo",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23835,
      "CodFisco": "E308",
      "Abitanti": 122,
      "Link": "http://www.comuni-italiani.it/097/041/"
    },
    {
      "Istat": "013118",
      "Comune": "Inverigo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22044,
      "CodFisco": "E309",
      "Abitanti": 8981,
      "Link": "http://www.comuni-italiani.it/013/118/"
    },
    {
      "Istat": "018077",
      "Comune": "Inverno e Monteleone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "E310",
      "Abitanti": 1317,
      "Link": "http://www.comuni-italiani.it/018/077/"
    },
    {
      "Istat": "001122",
      "Comune": "Inverso Pinasca",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "E311",
      "Abitanti": 743,
      "Link": "http://www.comuni-italiani.it/001/122/"
    },
    {
      "Istat": "015113",
      "Comune": "Inveruno",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "E313",
      "Abitanti": 8686,
      "Link": "http://www.comuni-italiani.it/015/113/"
    },
    {
      "Istat": "003082",
      "Comune": "Invorio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28045,
      "CodFisco": "E314",
      "Abitanti": 4472,
      "Link": "http://www.comuni-italiani.it/003/082/"
    },
    {
      "Istat": "015114",
      "Comune": "Inzago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20065,
      "CodFisco": "E317",
      "Abitanti": 10541,
      "Link": "http://www.comuni-italiani.it/015/114/"
    },
    {
      "Istat": 102017,
      "Comune": "Ionadi",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89851,
      "CodFisco": "E321",
      "Abitanti": 3796,
      "Link": "http://www.comuni-italiani.it/102/017/"
    },
    {
      "Istat": "091033",
      "Comune": "Irgoli",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "E323",
      "Abitanti": 2348,
      "Link": "http://www.comuni-italiani.it/091/033/"
    },
    {
      "Istat": "017084",
      "Comune": "Irma",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25061,
      "CodFisco": "E325",
      "Abitanti": 152,
      "Link": "http://www.comuni-italiani.it/017/084/"
    },
    {
      "Istat": "077013",
      "Comune": "Irsina",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75022,
      "CodFisco": "E326",
      "Abitanti": 5189,
      "Link": "http://www.comuni-italiani.it/077/013/"
    },
    {
      "Istat": "004103",
      "Comune": "Isasca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "E327",
      "Abitanti": 78,
      "Link": "http://www.comuni-italiani.it/004/103/"
    },
    {
      "Istat": "079063",
      "Comune": "Isca sullo Ionio",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "E328",
      "Abitanti": 1666,
      "Link": "http://www.comuni-italiani.it/079/063/"
    },
    {
      "Istat": "063037",
      "Comune": "Ischia",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80077,
      "CodFisco": "E329",
      "Abitanti": 18828,
      "Link": "http://www.comuni-italiani.it/063/037/"
    },
    {
      "Istat": "056031",
      "Comune": "Ischia di Castro",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "E330",
      "Abitanti": 2412,
      "Link": "http://www.comuni-italiani.it/056/031/"
    },
    {
      "Istat": "071025",
      "Comune": "Ischitella",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71010,
      "CodFisco": "E332",
      "Abitanti": 4401,
      "Link": "http://www.comuni-italiani.it/071/025/"
    },
    {
      "Istat": "017085",
      "Comune": "Iseo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25049,
      "CodFisco": "E333",
      "Abitanti": 9205,
      "Link": "http://www.comuni-italiani.it/017/085/"
    },
    {
      "Istat": "022098",
      "Comune": "Isera",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "E334",
      "Abitanti": 2601,
      "Link": "http://www.comuni-italiani.it/022/098/"
    },
    {
      "Istat": "094023",
      "Comune": "Isernia",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86170,
      "CodFisco": "E335",
      "Abitanti": 22150,
      "Link": "http://www.comuni-italiani.it/094/023/"
    },
    {
      "Istat": "092114",
      "Comune": "Isili",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08033",
      "CodFisco": "E336",
      "Abitanti": 3030,
      "Link": "http://www.comuni-italiani.it/092/114/"
    },
    {
      "Istat": "082042",
      "Comune": "Isnello",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "E337",
      "Abitanti": 1638,
      "Link": "http://www.comuni-italiani.it/082/042/"
    },
    {
      "Istat": "005059",
      "Comune": "Isola d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14057,
      "CodFisco": "E338",
      "Abitanti": 2154,
      "Link": "http://www.comuni-italiani.it/005/059/"
    },
    {
      "Istat": "010027",
      "Comune": "Isola del Cantone",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16017,
      "CodFisco": "E341",
      "Abitanti": 1551,
      "Link": "http://www.comuni-italiani.it/010/027/"
    },
    {
      "Istat": "053012",
      "Comune": "Isola del Giglio",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58012,
      "CodFisco": "E348",
      "Abitanti": 1466,
      "Link": "http://www.comuni-italiani.it/053/012/"
    },
    {
      "Istat": "067026",
      "Comune": "Isola del Gran Sasso d'Italia",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64045,
      "CodFisco": "E343",
      "Abitanti": 4970,
      "Link": "http://www.comuni-italiani.it/067/026/"
    },
    {
      "Istat": "060043",
      "Comune": "Isola del Liri",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03036",
      "CodFisco": "E340",
      "Abitanti": 11991,
      "Link": "http://www.comuni-italiani.it/060/043/"
    },
    {
      "Istat": "041021",
      "Comune": "Isola del Piano",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "E351",
      "Abitanti": 660,
      "Link": "http://www.comuni-italiani.it/041/021/"
    },
    {
      "Istat": "023040",
      "Comune": "Isola della Scala",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37063,
      "CodFisco": "E349",
      "Abitanti": 11559,
      "Link": "http://www.comuni-italiani.it/023/040/"
    },
    {
      "Istat": "082043",
      "Comune": "Isola delle Femmine",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "E350",
      "Abitanti": 7336,
      "Link": "http://www.comuni-italiani.it/082/043/"
    },
    {
      "Istat": 101013,
      "Comune": "Isola di Capo Rizzuto",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88841,
      "CodFisco": "E339",
      "Abitanti": 15789,
      "Link": "http://www.comuni-italiani.it/101/013/"
    },
    {
      "Istat": "016121",
      "Comune": "Isola di Fondra",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "E353",
      "Abitanti": 189,
      "Link": "http://www.comuni-italiani.it/016/121/"
    },
    {
      "Istat": "019053",
      "Comune": "Isola Dovarese",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26031,
      "CodFisco": "E356",
      "Abitanti": 1223,
      "Link": "http://www.comuni-italiani.it/019/053/"
    },
    {
      "Istat": "023041",
      "Comune": "Isola Rizza",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37050,
      "CodFisco": "E358",
      "Abitanti": 3281,
      "Link": "http://www.comuni-italiani.it/023/041/"
    },
    {
      "Istat": "006087",
      "Comune": "Isola Sant'Antonio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "E360",
      "Abitanti": 744,
      "Link": "http://www.comuni-italiani.it/006/087/"
    },
    {
      "Istat": "024048",
      "Comune": "Isola Vicentina",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36033,
      "CodFisco": "E354",
      "Abitanti": 9538,
      "Link": "http://www.comuni-italiani.it/024/048/"
    },
    {
      "Istat": "001123",
      "Comune": "Isolabella",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10046,
      "CodFisco": "E345",
      "Abitanti": 394,
      "Link": "http://www.comuni-italiani.it/001/123/"
    },
    {
      "Istat": "008032",
      "Comune": "Isolabona",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18035,
      "CodFisco": "E346",
      "Abitanti": 720,
      "Link": "http://www.comuni-italiani.it/008/032/"
    },
    {
      "Istat": "071026",
      "Comune": "Isole Tremiti",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71040,
      "CodFisco": "E363",
      "Abitanti": 486,
      "Link": "http://www.comuni-italiani.it/071/026/"
    },
    {
      "Istat": "017086",
      "Comune": "Isorella",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "E364",
      "Abitanti": 4218,
      "Link": "http://www.comuni-italiani.it/017/086/"
    },
    {
      "Istat": "065059",
      "Comune": "Ispani",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0973",
      "CAP": 84050,
      "CodFisco": "E365",
      "Abitanti": 1017,
      "Link": "http://www.comuni-italiani.it/065/059/"
    },
    {
      "Istat": "088005",
      "Comune": "Ispica",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97014,
      "CodFisco": "E366",
      "Abitanti": 15554,
      "Link": "http://www.comuni-italiani.it/088/005/"
    },
    {
      "Istat": "012084",
      "Comune": "Ispra",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21027,
      "CodFisco": "E367",
      "Abitanti": 5238,
      "Link": "http://www.comuni-italiani.it/012/084/"
    },
    {
      "Istat": "001124",
      "Comune": "Issiglio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "E368",
      "Abitanti": 422,
      "Link": "http://www.comuni-italiani.it/001/124/"
    },
    {
      "Istat": "007036",
      "Comune": "Issime",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "E369",
      "Abitanti": 426,
      "Link": "http://www.comuni-italiani.it/007/036/"
    },
    {
      "Istat": "016122",
      "Comune": "Isso",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "E370",
      "Abitanti": 664,
      "Link": "http://www.comuni-italiani.it/016/122/"
    },
    {
      "Istat": "007037",
      "Comune": "Issogne",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "E371",
      "Abitanti": 1402,
      "Link": "http://www.comuni-italiani.it/007/037/"
    },
    {
      "Istat": "026035",
      "Comune": "Istrana",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31036,
      "CodFisco": "E373",
      "Abitanti": 9170,
      "Link": "http://www.comuni-italiani.it/026/035/"
    },
    {
      "Istat": "083036",
      "Comune": "Itala",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98025,
      "CodFisco": "E374",
      "Abitanti": 1675,
      "Link": "http://www.comuni-italiani.it/083/036/"
    },
    {
      "Istat": "059010",
      "Comune": "Itri",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04020",
      "CodFisco": "E375",
      "Abitanti": 10369,
      "Link": "http://www.comuni-italiani.it/059/010/"
    },
    {
      "Istat": "090032",
      "Comune": "Ittireddu",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "E376",
      "Abitanti": 583,
      "Link": "http://www.comuni-italiani.it/090/032/"
    },
    {
      "Istat": "090033",
      "Comune": "Ittiri",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07044",
      "CodFisco": "E377",
      "Abitanti": 8918,
      "Link": "http://www.comuni-italiani.it/090/033/"
    },
    {
      "Istat": "022099",
      "Comune": "Ivano-Fracena",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38059,
      "CodFisco": "E378",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/022/099/"
    },
    {
      "Istat": "001125",
      "Comune": "Ivrea",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10015,
      "CodFisco": "E379",
      "Abitanti": 24196,
      "Link": "http://www.comuni-italiani.it/001/125/"
    },
    {
      "Istat": "019054",
      "Comune": "Izano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "E380",
      "Abitanti": 2072,
      "Link": "http://www.comuni-italiani.it/019/054/"
    },
    {
      "Istat": "079065",
      "Comune": "Jacurso",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88020,
      "CodFisco": "E274",
      "Abitanti": 639,
      "Link": "http://www.comuni-italiani.it/079/065/"
    },
    {
      "Istat": "070030",
      "Comune": "Jelsi",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86015,
      "CodFisco": "E381",
      "Abitanti": 1842,
      "Link": "http://www.comuni-italiani.it/070/030/"
    },
    {
      "Istat": "058048",
      "Comune": "Jenne",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "E382",
      "Abitanti": 407,
      "Link": "http://www.comuni-italiani.it/058/048/"
    },
    {
      "Istat": "012085",
      "Comune": "Jerago con Orago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "E386",
      "Abitanti": 5124,
      "Link": "http://www.comuni-italiani.it/012/085/"
    },
    {
      "Istat": 105009,
      "Comune": "Jerzu",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08044",
      "CodFisco": "E387",
      "Abitanti": 3231,
      "Link": "http://www.comuni-italiani.it/105/009/"
    },
    {
      "Istat": "042021",
      "Comune": "Jesi",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60035,
      "CodFisco": "E388",
      "Abitanti": 40635,
      "Link": "http://www.comuni-italiani.it/042/021/"
    },
    {
      "Istat": "027019",
      "Comune": "Jesolo",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30016,
      "CodFisco": "C388",
      "Abitanti": 25601,
      "Link": "http://www.comuni-italiani.it/027/019/"
    },
    {
      "Istat": "038010",
      "Comune": "Jolanda di Savoia",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44037,
      "CodFisco": "E320",
      "Abitanti": 3089,
      "Link": "http://www.comuni-italiani.it/038/010/"
    },
    {
      "Istat": 102018,
      "Comune": "Joppolo",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89863,
      "CodFisco": "E389",
      "Abitanti": 2120,
      "Link": "http://www.comuni-italiani.it/102/018/"
    },
    {
      "Istat": "084019",
      "Comune": "Joppolo Giancaxio",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "E390",
      "Abitanti": 1243,
      "Link": "http://www.comuni-italiani.it/084/019/"
    },
    {
      "Istat": "007038",
      "Comune": "Jovencan",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "E391",
      "Abitanti": 765,
      "Link": "http://www.comuni-italiani.it/007/038/"
    },
    {
      "Istat": "001126",
      "Comune": "La Cassa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "E394",
      "Abitanti": 1821,
      "Link": "http://www.comuni-italiani.it/001/126/"
    },
    {
      "Istat": "001127",
      "Comune": "La Loggia",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "E423",
      "Abitanti": 8457,
      "Link": "http://www.comuni-italiani.it/001/127/"
    },
    {
      "Istat": 104012,
      "Comune": "La Maddalena",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07024",
      "CodFisco": "E425",
      "Abitanti": 11899,
      "Link": "http://www.comuni-italiani.it/104/012/"
    },
    {
      "Istat": "007039",
      "Comune": "La Magdeleine",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "A308",
      "Abitanti": 116,
      "Link": "http://www.comuni-italiani.it/007/039/"
    },
    {
      "Istat": "004105",
      "Comune": "La Morra",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12064,
      "CodFisco": "E430",
      "Abitanti": 2758,
      "Link": "http://www.comuni-italiani.it/004/105/"
    },
    {
      "Istat": "007040",
      "Comune": "La Salle",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11015,
      "CodFisco": "E458",
      "Abitanti": 2102,
      "Link": "http://www.comuni-italiani.it/007/040/"
    },
    {
      "Istat": "011015",
      "Comune": "La Spezia",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": "191xx",
      "CodFisco": "E463",
      "Abitanti": 95378,
      "Link": "http://www.comuni-italiani.it/011/015/"
    },
    {
      "Istat": "007041",
      "Comune": "La Thuile",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11016,
      "CodFisco": "E470",
      "Abitanti": 767,
      "Link": "http://www.comuni-italiani.it/007/041/"
    },
    {
      "Istat": "021117",
      "Comune": "La Valle",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39030,
      "CodFisco": "E491",
      "Abitanti": 1302,
      "Link": "http://www.comuni-italiani.it/021/117/"
    },
    {
      "Istat": "025027",
      "Comune": "La Valle Agordina",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "E490",
      "Abitanti": 1162,
      "Link": "http://www.comuni-italiani.it/025/027/"
    },
    {
      "Istat": "058049",
      "Comune": "Labico",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "E392",
      "Abitanti": 5982,
      "Link": "http://www.comuni-italiani.it/058/049/"
    },
    {
      "Istat": "057032",
      "Comune": "Labro",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "E393",
      "Abitanti": 370,
      "Link": "http://www.comuni-italiani.it/057/032/"
    },
    {
      "Istat": "015115",
      "Comune": "Lacchiarella",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20084,
      "CodFisco": "E395",
      "Abitanti": 8480,
      "Link": "http://www.comuni-italiani.it/015/115/"
    },
    {
      "Istat": "063038",
      "Comune": "Lacco Ameno",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80076,
      "CodFisco": "E396",
      "Abitanti": 4783,
      "Link": "http://www.comuni-italiani.it/063/038/"
    },
    {
      "Istat": "064041",
      "Comune": "Lacedonia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83046,
      "CodFisco": "E397",
      "Abitanti": 2825,
      "Link": "http://www.comuni-italiani.it/064/041/"
    },
    {
      "Istat": "021037",
      "Comune": "Laces",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39021,
      "CodFisco": "E398",
      "Abitanti": 5144,
      "Link": "http://www.comuni-italiani.it/021/037/"
    },
    {
      "Istat": "095082",
      "Comune": "Laconi",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08034",
      "CodFisco": "E400",
      "Abitanti": 2044,
      "Link": "http://www.comuni-italiani.it/095/082/"
    },
    {
      "Istat": "058116",
      "Comune": "Ladispoli",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00055",
      "CodFisco": "M212",
      "Abitanti": 41035,
      "Link": "http://www.comuni-italiani.it/058/116/"
    },
    {
      "Istat": "090034",
      "Comune": "Laerru",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "E401",
      "Abitanti": 966,
      "Link": "http://www.comuni-italiani.it/090/034/"
    },
    {
      "Istat": "080041",
      "Comune": "Laganadi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89050,
      "CodFisco": "E402",
      "Abitanti": 422,
      "Link": "http://www.comuni-italiani.it/080/041/"
    },
    {
      "Istat": "024049",
      "Comune": "Laghi",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "E403",
      "Abitanti": 127,
      "Link": "http://www.comuni-italiani.it/024/049/"
    },
    {
      "Istat": "013119",
      "Comune": "Laglio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22010,
      "CodFisco": "E405",
      "Abitanti": 957,
      "Link": "http://www.comuni-italiani.it/013/119/"
    },
    {
      "Istat": "004104",
      "Comune": "Lagnasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "E406",
      "Abitanti": 1388,
      "Link": "http://www.comuni-italiani.it/004/104/"
    },
    {
      "Istat": "078062",
      "Comune": "Lago",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87035,
      "CodFisco": "E407",
      "Abitanti": 2765,
      "Link": "http://www.comuni-italiani.it/078/062/"
    },
    {
      "Istat": "076039",
      "Comune": "Lagonegro",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85042,
      "CodFisco": "E409",
      "Abitanti": 5802,
      "Link": "http://www.comuni-italiani.it/076/039/"
    },
    {
      "Istat": "038011",
      "Comune": "Lagosanto",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44023,
      "CodFisco": "E410",
      "Abitanti": 4921,
      "Link": "http://www.comuni-italiani.it/038/011/"
    },
    {
      "Istat": "021038",
      "Comune": "Lagundo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39022,
      "CodFisco": "E412",
      "Abitanti": 4848,
      "Link": "http://www.comuni-italiani.it/021/038/"
    },
    {
      "Istat": "009033",
      "Comune": "Laigueglia",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17053,
      "CodFisco": "E414",
      "Abitanti": 1895,
      "Link": "http://www.comuni-italiani.it/009/033/"
    },
    {
      "Istat": "015116",
      "Comune": "Lainate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20020,
      "CodFisco": "E415",
      "Abitanti": 25343,
      "Link": "http://www.comuni-italiani.it/015/116/"
    },
    {
      "Istat": "013120",
      "Comune": "Laino",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "E416",
      "Abitanti": 524,
      "Link": "http://www.comuni-italiani.it/013/120/"
    },
    {
      "Istat": "078063",
      "Comune": "Laino Borgo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87014,
      "CodFisco": "E417",
      "Abitanti": 2077,
      "Link": "http://www.comuni-italiani.it/078/063/"
    },
    {
      "Istat": "078064",
      "Comune": "Laino Castello",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87015,
      "CodFisco": "E419",
      "Abitanti": 897,
      "Link": "http://www.comuni-italiani.it/078/064/"
    },
    {
      "Istat": "021039",
      "Comune": "Laion",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "E420",
      "Abitanti": 2616,
      "Link": "http://www.comuni-italiani.it/021/039/"
    },
    {
      "Istat": "021040",
      "Comune": "Laives",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39055,
      "CodFisco": "E421",
      "Abitanti": 17197,
      "Link": "http://www.comuni-italiani.it/021/040/"
    },
    {
      "Istat": "050016",
      "Comune": "Lajatico",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56030,
      "CodFisco": "E413",
      "Abitanti": 1376,
      "Link": "http://www.comuni-italiani.it/050/016/"
    },
    {
      "Istat": "016123",
      "Comune": "Lallio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "E422",
      "Abitanti": 4138,
      "Link": "http://www.comuni-italiani.it/016/123/"
    },
    {
      "Istat": "069045",
      "Comune": "Lama dei Peligni",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66010,
      "CodFisco": "E424",
      "Abitanti": 1407,
      "Link": "http://www.comuni-italiani.it/069/045/"
    },
    {
      "Istat": "036018",
      "Comune": "Lama Mocogno",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41023,
      "CodFisco": "E426",
      "Abitanti": 2912,
      "Link": "http://www.comuni-italiani.it/036/018/"
    },
    {
      "Istat": "013121",
      "Comune": "Lambrugo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22045,
      "CodFisco": "E428",
      "Abitanti": 2445,
      "Link": "http://www.comuni-italiani.it/013/121/"
    },
    {
      "Istat": "079160",
      "Comune": "Lamezia Terme",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88046,
      "CodFisco": "M208",
      "Abitanti": 71286,
      "Link": "http://www.comuni-italiani.it/079/160/"
    },
    {
      "Istat": "025026",
      "Comune": "Lamon",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32033,
      "CodFisco": "E429",
      "Abitanti": 3124,
      "Link": "http://www.comuni-italiani.it/025/026/"
    },
    {
      "Istat": "084020",
      "Comune": "Lampedusa e Linosa",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "E431",
      "Abitanti": 6299,
      "Link": "http://www.comuni-italiani.it/084/020/"
    },
    {
      "Istat": "047005",
      "Comune": "Lamporecchio",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51035,
      "CodFisco": "E432",
      "Abitanti": 7724,
      "Link": "http://www.comuni-italiani.it/047/005/"
    },
    {
      "Istat": "002067",
      "Comune": "Lamporo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13046,
      "CodFisco": "E433",
      "Abitanti": 547,
      "Link": "http://www.comuni-italiani.it/002/067/"
    },
    {
      "Istat": "021041",
      "Comune": "Lana",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39011,
      "CodFisco": "E434",
      "Abitanti": 11230,
      "Link": "http://www.comuni-italiani.it/021/041/"
    },
    {
      "Istat": "069046",
      "Comune": "Lanciano",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66034,
      "CodFisco": "E435",
      "Abitanti": 36304,
      "Link": "http://www.comuni-italiani.it/069/046/"
    },
    {
      "Istat": "003083",
      "Comune": "Landiona",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28064,
      "CodFisco": "E436",
      "Abitanti": 614,
      "Link": "http://www.comuni-italiani.it/003/083/"
    },
    {
      "Istat": "018078",
      "Comune": "Landriano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27015,
      "CodFisco": "E437",
      "Abitanti": 6121,
      "Link": "http://www.comuni-italiani.it/018/078/"
    },
    {
      "Istat": "034018",
      "Comune": "Langhirano",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43013,
      "CodFisco": "E438",
      "Abitanti": 9842,
      "Link": "http://www.comuni-italiani.it/034/018/"
    },
    {
      "Istat": "018079",
      "Comune": "Langosco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "E439",
      "Abitanti": 441,
      "Link": "http://www.comuni-italiani.it/018/079/"
    },
    {
      "Istat": 105010,
      "Comune": "Lanusei",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08045",
      "CodFisco": "E441",
      "Abitanti": 5655,
      "Link": "http://www.comuni-italiani.it/105/010/"
    },
    {
      "Istat": "058050",
      "Comune": "Lanuvio",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "C767",
      "Abitanti": 13147,
      "Link": "http://www.comuni-italiani.it/058/050/"
    },
    {
      "Istat": "014036",
      "Comune": "Lanzada",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "E443",
      "Abitanti": 1399,
      "Link": "http://www.comuni-italiani.it/014/036/"
    },
    {
      "Istat": "013122",
      "Comune": "Lanzo d'Intelvi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22024,
      "CodFisco": "E444",
      "Abitanti": 1452,
      "Link": "http://www.comuni-italiani.it/013/122/"
    },
    {
      "Istat": "001128",
      "Comune": "Lanzo Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10074,
      "CodFisco": "E445",
      "Abitanti": 5303,
      "Link": "http://www.comuni-italiani.it/001/128/"
    },
    {
      "Istat": 109009,
      "Comune": "Lapedona",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63823,
      "CodFisco": "E447",
      "Abitanti": 1177,
      "Link": "http://www.comuni-italiani.it/109/009/"
    },
    {
      "Istat": "064042",
      "Comune": "Lapio",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "E448",
      "Abitanti": 1648,
      "Link": "http://www.comuni-italiani.it/064/042/"
    },
    {
      "Istat": "078065",
      "Comune": "Lappano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "E450",
      "Abitanti": 983,
      "Link": "http://www.comuni-italiani.it/078/065/"
    },
    {
      "Istat": "066049",
      "Comune": "L'Aquila",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67100,
      "CodFisco": "A345",
      "Abitanti": 72511,
      "Link": "http://www.comuni-italiani.it/066/049/"
    },
    {
      "Istat": "047006",
      "Comune": "Larciano",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51036,
      "CodFisco": "E451",
      "Abitanti": 6392,
      "Link": "http://www.comuni-italiani.it/047/006/"
    },
    {
      "Istat": "022100",
      "Comune": "Lardaro",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38087,
      "CodFisco": "E452",
      "Abitanti": 217,
      "Link": "http://www.comuni-italiani.it/022/100/"
    },
    {
      "Istat": "018080",
      "Comune": "Lardirago",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27016,
      "CodFisco": "E454",
      "Abitanti": 1227,
      "Link": "http://www.comuni-italiani.it/018/080/"
    },
    {
      "Istat": "050017",
      "Comune": "Lari",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56035,
      "CodFisco": "E455",
      "Abitanti": 8841,
      "Link": "http://www.comuni-italiani.it/050/017/"
    },
    {
      "Istat": "058115",
      "Comune": "Lariano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "M207",
      "Abitanti": 13011,
      "Link": "http://www.comuni-italiani.it/058/115/"
    },
    {
      "Istat": "070031",
      "Comune": "Larino",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86035,
      "CodFisco": "E456",
      "Abitanti": 7095,
      "Link": "http://www.comuni-italiani.it/070/031/"
    },
    {
      "Istat": 106009,
      "Comune": "Las Plassas",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "E464",
      "Abitanti": 266,
      "Link": "http://www.comuni-italiani.it/106/009/"
    },
    {
      "Istat": "021042",
      "Comune": "Lasa",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39023,
      "CodFisco": "E457",
      "Abitanti": 3937,
      "Link": "http://www.comuni-italiani.it/021/042/"
    },
    {
      "Istat": "082044",
      "Comune": "Lascari",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "E459",
      "Abitanti": 3489,
      "Link": "http://www.comuni-italiani.it/082/044/"
    },
    {
      "Istat": "022101",
      "Comune": "Lasino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38076,
      "CodFisco": "E461",
      "Abitanti": 1305,
      "Link": "http://www.comuni-italiani.it/022/101/"
    },
    {
      "Istat": "013123",
      "Comune": "Lasnigo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "E462",
      "Abitanti": 458,
      "Link": "http://www.comuni-italiani.it/013/123/"
    },
    {
      "Istat": "024050",
      "Comune": "Lastebasse",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36040,
      "CodFisco": "E465",
      "Abitanti": 231,
      "Link": "http://www.comuni-italiani.it/024/050/"
    },
    {
      "Istat": "048024",
      "Comune": "Lastra a Signa",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50055,
      "CodFisco": "E466",
      "Abitanti": 19829,
      "Link": "http://www.comuni-italiani.it/048/024/"
    },
    {
      "Istat": "056032",
      "Comune": "Latera",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "E467",
      "Abitanti": 951,
      "Link": "http://www.comuni-italiani.it/056/032/"
    },
    {
      "Istat": "051019",
      "Comune": "Laterina",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52020,
      "CodFisco": "E468",
      "Abitanti": 3606,
      "Link": "http://www.comuni-italiani.it/051/019/"
    },
    {
      "Istat": "073009",
      "Comune": "Laterza",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74014,
      "CodFisco": "E469",
      "Abitanti": 15282,
      "Link": "http://www.comuni-italiani.it/073/009/"
    },
    {
      "Istat": "074009",
      "Comune": "Latiano",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72022,
      "CodFisco": "E471",
      "Abitanti": 15020,
      "Link": "http://www.comuni-italiani.it/074/009/"
    },
    {
      "Istat": "059011",
      "Comune": "Latina",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04100",
      "CodFisco": "E472",
      "Abitanti": 119804,
      "Link": "http://www.comuni-italiani.it/059/011/"
    },
    {
      "Istat": "030046",
      "Comune": "Latisana",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33053,
      "CodFisco": "E473",
      "Abitanti": 13953,
      "Link": "http://www.comuni-italiani.it/030/046/"
    },
    {
      "Istat": "076040",
      "Comune": "Latronico",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85043,
      "CodFisco": "E474",
      "Abitanti": 4811,
      "Link": "http://www.comuni-italiani.it/076/040/"
    },
    {
      "Istat": "078066",
      "Comune": "Lattarico",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "E475",
      "Abitanti": 4246,
      "Link": "http://www.comuni-italiani.it/078/066/"
    },
    {
      "Istat": "030047",
      "Comune": "Lauco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33029,
      "CodFisco": "E476",
      "Abitanti": 805,
      "Link": "http://www.comuni-italiani.it/030/047/"
    },
    {
      "Istat": "065060",
      "Comune": "Laureana Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84050,
      "CodFisco": "E480",
      "Abitanti": 1185,
      "Link": "http://www.comuni-italiani.it/065/060/"
    },
    {
      "Istat": "080042",
      "Comune": "Laureana di Borrello",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89023,
      "CodFisco": "E479",
      "Abitanti": 5397,
      "Link": "http://www.comuni-italiani.it/080/042/"
    },
    {
      "Istat": "021043",
      "Comune": "Lauregno",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 39040,
      "CodFisco": "E481",
      "Abitanti": 346,
      "Link": "http://www.comuni-italiani.it/021/043/"
    },
    {
      "Istat": "076041",
      "Comune": "Laurenzana",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85014,
      "CodFisco": "E482",
      "Abitanti": 1975,
      "Link": "http://www.comuni-italiani.it/076/041/"
    },
    {
      "Istat": "076042",
      "Comune": "Lauria",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85044,
      "CodFisco": "E483",
      "Abitanti": 13392,
      "Link": "http://www.comuni-italiani.it/076/042/"
    },
    {
      "Istat": "001129",
      "Comune": "Lauriano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "E484",
      "Abitanti": 1546,
      "Link": "http://www.comuni-italiani.it/001/129/"
    },
    {
      "Istat": "065061",
      "Comune": "Laurino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84057,
      "CodFisco": "E485",
      "Abitanti": 1741,
      "Link": "http://www.comuni-italiani.it/065/061/"
    },
    {
      "Istat": "065062",
      "Comune": "Laurito",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84050,
      "CodFisco": "E486",
      "Abitanti": 870,
      "Link": "http://www.comuni-italiani.it/065/062/"
    },
    {
      "Istat": "064043",
      "Comune": "Lauro",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83023,
      "CodFisco": "E487",
      "Abitanti": 3638,
      "Link": "http://www.comuni-italiani.it/064/043/"
    },
    {
      "Istat": "010028",
      "Comune": "Lavagna",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16033,
      "CodFisco": "E488",
      "Abitanti": 13013,
      "Link": "http://www.comuni-italiani.it/010/028/"
    },
    {
      "Istat": "023042",
      "Comune": "Lavagno",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "E489",
      "Abitanti": 8103,
      "Link": "http://www.comuni-italiani.it/023/042/"
    },
    {
      "Istat": "022102",
      "Comune": "Lavarone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38046,
      "CodFisco": "E492",
      "Abitanti": 1107,
      "Link": "http://www.comuni-italiani.it/022/102/"
    },
    {
      "Istat": "076043",
      "Comune": "Lavello",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85024,
      "CodFisco": "E493",
      "Abitanti": 13945,
      "Link": "http://www.comuni-italiani.it/076/043/"
    },
    {
      "Istat": "012086",
      "Comune": "Lavena Ponte Tresa",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21037,
      "CodFisco": "E494",
      "Abitanti": 5622,
      "Link": "http://www.comuni-italiani.it/012/086/"
    },
    {
      "Istat": "012087",
      "Comune": "Laveno-Mombello",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21014,
      "CodFisco": "E496",
      "Abitanti": 9053,
      "Link": "http://www.comuni-italiani.it/012/087/"
    },
    {
      "Istat": "017087",
      "Comune": "Lavenone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25074,
      "CodFisco": "E497",
      "Abitanti": 624,
      "Link": "http://www.comuni-italiani.it/017/087/"
    },
    {
      "Istat": "065063",
      "Comune": "Laviano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "E498",
      "Abitanti": 1496,
      "Link": "http://www.comuni-italiani.it/065/063/"
    },
    {
      "Istat": "022103",
      "Comune": "Lavis",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38015,
      "CodFisco": "E500",
      "Abitanti": 8635,
      "Link": "http://www.comuni-italiani.it/022/103/"
    },
    {
      "Istat": "023043",
      "Comune": "Lazise",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37017,
      "CodFisco": "E502",
      "Abitanti": 6968,
      "Link": "http://www.comuni-italiani.it/023/043/"
    },
    {
      "Istat": 108025,
      "Comune": "Lazzate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20824,
      "CodFisco": "E504",
      "Abitanti": 7605,
      "Link": "http://www.comuni-italiani.it/108/025/"
    },
    {
      "Istat": "075035",
      "Comune": "Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73100,
      "CodFisco": "E506",
      "Abitanti": 95520,
      "Link": "http://www.comuni-italiani.it/075/035/"
    },
    {
      "Istat": "066050",
      "Comune": "Lecce nei Marsi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "E505",
      "Abitanti": 1762,
      "Link": "http://www.comuni-italiani.it/066/050/"
    },
    {
      "Istat": "097042",
      "Comune": "Lecco",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23900,
      "CodFisco": "E507",
      "Abitanti": 48114,
      "Link": "http://www.comuni-italiani.it/097/042/"
    },
    {
      "Istat": "022229",
      "Comune": "Ledro",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38067,
      "CodFisco": "M313",
      "Abitanti": 5523,
      "Link": "http://www.comuni-italiani.it/022/229/"
    },
    {
      "Istat": "016124",
      "Comune": "Leffe",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24026,
      "CodFisco": "E509",
      "Abitanti": 4730,
      "Link": "http://www.comuni-italiani.it/016/124/"
    },
    {
      "Istat": "012088",
      "Comune": "Leggiuno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21038,
      "CodFisco": "E510",
      "Abitanti": 3582,
      "Link": "http://www.comuni-italiani.it/012/088/"
    },
    {
      "Istat": "023044",
      "Comune": "Legnago",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37045,
      "CodFisco": "E512",
      "Abitanti": 25600,
      "Link": "http://www.comuni-italiani.it/023/044/"
    },
    {
      "Istat": "015118",
      "Comune": "Legnano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20025,
      "CodFisco": "E514",
      "Abitanti": 59147,
      "Link": "http://www.comuni-italiani.it/015/118/"
    },
    {
      "Istat": "028044",
      "Comune": "Legnaro",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "E515",
      "Abitanti": 8594,
      "Link": "http://www.comuni-italiani.it/028/044/"
    },
    {
      "Istat": "091038",
      "Comune": "Lei",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "E517",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/091/038/"
    },
    {
      "Istat": "001130",
      "Comune": "Leini",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "E518",
      "Abitanti": 15451,
      "Link": "http://www.comuni-italiani.it/001/130/"
    },
    {
      "Istat": "010029",
      "Comune": "Leivi",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "E519",
      "Abitanti": 2353,
      "Link": "http://www.comuni-italiani.it/010/029/"
    },
    {
      "Istat": "001131",
      "Comune": "Lemie",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "E520",
      "Abitanti": 190,
      "Link": "http://www.comuni-italiani.it/001/131/"
    },
    {
      "Istat": "029029",
      "Comune": "Lendinara",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45026,
      "CodFisco": "E522",
      "Abitanti": 12181,
      "Link": "http://www.comuni-italiani.it/029/029/"
    },
    {
      "Istat": "083037",
      "Comune": "Leni",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98050,
      "CodFisco": "E523",
      "Abitanti": 697,
      "Link": "http://www.comuni-italiani.it/083/037/"
    },
    {
      "Istat": "016125",
      "Comune": "Lenna",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "E524",
      "Abitanti": 640,
      "Link": "http://www.comuni-italiani.it/016/125/"
    },
    {
      "Istat": "013125",
      "Comune": "Lenno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22016,
      "CodFisco": "E525",
      "Abitanti": 1859,
      "Link": "http://www.comuni-italiani.it/013/125/"
    },
    {
      "Istat": "017088",
      "Comune": "Leno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25024,
      "CodFisco": "E526",
      "Abitanti": 14561,
      "Link": "http://www.comuni-italiani.it/017/088/"
    },
    {
      "Istat": "059012",
      "Comune": "Lenola",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04025",
      "CodFisco": "E527",
      "Abitanti": 4180,
      "Link": "http://www.comuni-italiani.it/059/012/"
    },
    {
      "Istat": "002068",
      "Comune": "Lenta",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13035,
      "CodFisco": "E528",
      "Abitanti": 905,
      "Link": "http://www.comuni-italiani.it/002/068/"
    },
    {
      "Istat": 108054,
      "Comune": "Lentate sul Seveso",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20823,
      "CodFisco": "E530",
      "Abitanti": 15572,
      "Link": "http://www.comuni-italiani.it/108/054/"
    },
    {
      "Istat": "069047",
      "Comune": "Lentella",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "E531",
      "Abitanti": 729,
      "Link": "http://www.comuni-italiani.it/069/047/"
    },
    {
      "Istat": "025028",
      "Comune": "Lentiai",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "C562",
      "Abitanti": 2997,
      "Link": "http://www.comuni-italiani.it/025/028/"
    },
    {
      "Istat": "089011",
      "Comune": "Lentini",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 96016,
      "CodFisco": "E532",
      "Abitanti": 24017,
      "Link": "http://www.comuni-italiani.it/089/011/"
    },
    {
      "Istat": "057033",
      "Comune": "Leonessa",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02016",
      "CodFisco": "E535",
      "Abitanti": 2604,
      "Link": "http://www.comuni-italiani.it/057/033/"
    },
    {
      "Istat": "086011",
      "Comune": "Leonforte",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94013,
      "CodFisco": "E536",
      "Abitanti": 13954,
      "Link": "http://www.comuni-italiani.it/086/011/"
    },
    {
      "Istat": "073010",
      "Comune": "Leporano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "E537",
      "Abitanti": 7861,
      "Link": "http://www.comuni-italiani.it/073/010/"
    },
    {
      "Istat": "075036",
      "Comune": "Lequile",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "E538",
      "Abitanti": 8617,
      "Link": "http://www.comuni-italiani.it/075/036/"
    },
    {
      "Istat": "004106",
      "Comune": "Lequio Berria",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "E540",
      "Abitanti": 499,
      "Link": "http://www.comuni-italiani.it/004/106/"
    },
    {
      "Istat": "004107",
      "Comune": "Lequio Tanaro",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12060,
      "CodFisco": "E539",
      "Abitanti": 806,
      "Link": "http://www.comuni-italiani.it/004/107/"
    },
    {
      "Istat": "082045",
      "Comune": "Lercara Friddi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90025,
      "CodFisco": "E541",
      "Abitanti": 6984,
      "Link": "http://www.comuni-italiani.it/082/045/"
    },
    {
      "Istat": "011016",
      "Comune": "Lerici",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19032,
      "CodFisco": "E542",
      "Abitanti": 10284,
      "Link": "http://www.comuni-italiani.it/011/016/"
    },
    {
      "Istat": "006088",
      "Comune": "Lerma",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15070,
      "CodFisco": "E543",
      "Abitanti": 879,
      "Link": "http://www.comuni-italiani.it/006/088/"
    },
    {
      "Istat": "003084",
      "Comune": "Lesa",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28040,
      "CodFisco": "E544",
      "Abitanti": 2351,
      "Link": "http://www.comuni-italiani.it/003/084/"
    },
    {
      "Istat": "004108",
      "Comune": "Lesegno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12076,
      "CodFisco": "E546",
      "Abitanti": 871,
      "Link": "http://www.comuni-italiani.it/004/108/"
    },
    {
      "Istat": "034019",
      "Comune": "Lesignano de' Bagni",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43037,
      "CodFisco": "E547",
      "Abitanti": 4795,
      "Link": "http://www.comuni-italiani.it/034/019/"
    },
    {
      "Istat": "071027",
      "Comune": "Lesina",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71010,
      "CodFisco": "E549",
      "Abitanti": 6397,
      "Link": "http://www.comuni-italiani.it/071/027/"
    },
    {
      "Istat": 108026,
      "Comune": "Lesmo",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20855,
      "CodFisco": "E550",
      "Abitanti": 8065,
      "Link": "http://www.comuni-italiani.it/108/026/"
    },
    {
      "Istat": "001132",
      "Comune": "Lessolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "E551",
      "Abitanti": 1994,
      "Link": "http://www.comuni-italiani.it/001/132/"
    },
    {
      "Istat": "096029",
      "Comune": "Lessona",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13853,
      "CodFisco": "E552",
      "Abitanti": 2492,
      "Link": "http://www.comuni-italiani.it/096/029/"
    },
    {
      "Istat": "030048",
      "Comune": "Lestizza",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "E553",
      "Abitanti": 3937,
      "Link": "http://www.comuni-italiani.it/030/048/"
    },
    {
      "Istat": "061044",
      "Comune": "Letino",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "E554",
      "Abitanti": 780,
      "Link": "http://www.comuni-italiani.it/061/044/"
    },
    {
      "Istat": "083038",
      "Comune": "Letojanni",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98037,
      "CodFisco": "E555",
      "Abitanti": 2767,
      "Link": "http://www.comuni-italiani.it/083/038/"
    },
    {
      "Istat": "063039",
      "Comune": "Lettere",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80050,
      "CodFisco": "E557",
      "Abitanti": 6228,
      "Link": "http://www.comuni-italiani.it/063/039/"
    },
    {
      "Istat": "068020",
      "Comune": "Lettomanoppello",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "E558",
      "Abitanti": 3024,
      "Link": "http://www.comuni-italiani.it/068/020/"
    },
    {
      "Istat": "069048",
      "Comune": "Lettopalena",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66010,
      "CodFisco": "E559",
      "Abitanti": 378,
      "Link": "http://www.comuni-italiani.it/069/048/"
    },
    {
      "Istat": "011017",
      "Comune": "Levanto",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19015,
      "CodFisco": "E560",
      "Abitanti": 5592,
      "Link": "http://www.comuni-italiani.it/011/017/"
    },
    {
      "Istat": "016126",
      "Comune": "Levate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "E562",
      "Abitanti": 3813,
      "Link": "http://www.comuni-italiani.it/016/126/"
    },
    {
      "Istat": "075037",
      "Comune": "Leverano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73045,
      "CodFisco": "E563",
      "Abitanti": 14267,
      "Link": "http://www.comuni-italiani.it/075/037/"
    },
    {
      "Istat": "004109",
      "Comune": "Levice",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12070,
      "CodFisco": "E564",
      "Abitanti": 244,
      "Link": "http://www.comuni-italiani.it/004/109/"
    },
    {
      "Istat": "022104",
      "Comune": "Levico Terme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38056,
      "CodFisco": "E565",
      "Abitanti": 7474,
      "Link": "http://www.comuni-italiani.it/022/104/"
    },
    {
      "Istat": "001133",
      "Comune": "Levone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10070,
      "CodFisco": "E566",
      "Abitanti": 459,
      "Link": "http://www.comuni-italiani.it/001/133/"
    },
    {
      "Istat": "013126",
      "Comune": "Lezzeno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22025,
      "CodFisco": "E569",
      "Abitanti": 2054,
      "Link": "http://www.comuni-italiani.it/013/126/"
    },
    {
      "Istat": "061045",
      "Comune": "Liberi",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "E570",
      "Abitanti": 1176,
      "Link": "http://www.comuni-italiani.it/061/045/"
    },
    {
      "Istat": "083039",
      "Comune": "Librizzi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98064,
      "CodFisco": "E571",
      "Abitanti": 1812,
      "Link": "http://www.comuni-italiani.it/083/039/"
    },
    {
      "Istat": "084021",
      "Comune": "Licata",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92027,
      "CodFisco": "E573",
      "Abitanti": 39082,
      "Link": "http://www.comuni-italiani.it/084/021/"
    },
    {
      "Istat": "045009",
      "Comune": "Licciana Nardi",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54016,
      "CodFisco": "E574",
      "Abitanti": 4987,
      "Link": "http://www.comuni-italiani.it/045/009/"
    },
    {
      "Istat": "058051",
      "Comune": "Licenza",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00026",
      "CodFisco": "E576",
      "Abitanti": 1021,
      "Link": "http://www.comuni-italiani.it/058/051/"
    },
    {
      "Istat": "087020",
      "Comune": "Licodia Eubea",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95040,
      "CodFisco": "E578",
      "Abitanti": 3058,
      "Link": "http://www.comuni-italiani.it/087/020/"
    },
    {
      "Istat": "097043",
      "Comune": "Lierna",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23827,
      "CodFisco": "E581",
      "Abitanti": 2242,
      "Link": "http://www.comuni-italiani.it/097/043/"
    },
    {
      "Istat": "002070",
      "Comune": "Lignana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13034,
      "CodFisco": "E583",
      "Abitanti": 584,
      "Link": "http://www.comuni-italiani.it/002/070/"
    },
    {
      "Istat": "030049",
      "Comune": "Lignano Sabbiadoro",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33054,
      "CodFisco": "E584",
      "Abitanti": 6813,
      "Link": "http://www.comuni-italiani.it/030/049/"
    },
    {
      "Istat": "035025",
      "Comune": "Ligonchio",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42039,
      "CodFisco": "E585",
      "Abitanti": 875,
      "Link": "http://www.comuni-italiani.it/035/025/"
    },
    {
      "Istat": "030050",
      "Comune": "Ligosullo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "E586",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/030/050/"
    },
    {
      "Istat": "007042",
      "Comune": "Lillianes",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "E587",
      "Abitanti": 480,
      "Link": "http://www.comuni-italiani.it/007/042/"
    },
    {
      "Istat": "025029",
      "Comune": "Limana",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "E588",
      "Abitanti": 5027,
      "Link": "http://www.comuni-italiani.it/025/029/"
    },
    {
      "Istat": "062038",
      "Comune": "Limatola",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82030,
      "CodFisco": "E589",
      "Abitanti": 3916,
      "Link": "http://www.comuni-italiani.it/062/038/"
    },
    {
      "Istat": 102019,
      "Comune": "Limbadi",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89844,
      "CodFisco": "E590",
      "Abitanti": 3741,
      "Link": "http://www.comuni-italiani.it/102/019/"
    },
    {
      "Istat": 108027,
      "Comune": "Limbiate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20812,
      "CodFisco": "E591",
      "Abitanti": 35168,
      "Link": "http://www.comuni-italiani.it/108/027/"
    },
    {
      "Istat": "028045",
      "Comune": "Limena",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "E592",
      "Abitanti": 7740,
      "Link": "http://www.comuni-italiani.it/028/045/"
    },
    {
      "Istat": "013128",
      "Comune": "Limido Comasco",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "E593",
      "Abitanti": 3732,
      "Link": "http://www.comuni-italiani.it/013/128/"
    },
    {
      "Istat": "083040",
      "Comune": "Limina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "E594",
      "Abitanti": 912,
      "Link": "http://www.comuni-italiani.it/083/040/"
    },
    {
      "Istat": "004110",
      "Comune": "Limone Piemonte",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12015,
      "CodFisco": "E597",
      "Abitanti": 1527,
      "Link": "http://www.comuni-italiani.it/004/110/"
    },
    {
      "Istat": "017089",
      "Comune": "Limone sul Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25010,
      "CodFisco": "E596",
      "Abitanti": 1164,
      "Link": "http://www.comuni-italiani.it/017/089/"
    },
    {
      "Istat": "070032",
      "Comune": "Limosano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86022,
      "CodFisco": "E599",
      "Abitanti": 843,
      "Link": "http://www.comuni-italiani.it/070/032/"
    },
    {
      "Istat": "018081",
      "Comune": "Linarolo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "E600",
      "Abitanti": 2716,
      "Link": "http://www.comuni-italiani.it/018/081/"
    },
    {
      "Istat": "087021",
      "Comune": "Linguaglossa",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95015,
      "CodFisco": "E602",
      "Abitanti": 5462,
      "Link": "http://www.comuni-italiani.it/087/021/"
    },
    {
      "Istat": "064044",
      "Comune": "Lioni",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83047,
      "CodFisco": "E605",
      "Abitanti": 6420,
      "Link": "http://www.comuni-italiani.it/064/044/"
    },
    {
      "Istat": "083041",
      "Comune": "Lipari",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98055,
      "CodFisco": "E606",
      "Abitanti": 11386,
      "Link": "http://www.comuni-italiani.it/083/041/"
    },
    {
      "Istat": "013129",
      "Comune": "Lipomo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "E607",
      "Abitanti": 5860,
      "Link": "http://www.comuni-italiani.it/013/129/"
    },
    {
      "Istat": "018082",
      "Comune": "Lirio",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "E608",
      "Abitanti": 146,
      "Link": "http://www.comuni-italiani.it/018/082/"
    },
    {
      "Istat": "015122",
      "Comune": "Liscate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "E610",
      "Abitanti": 4060,
      "Link": "http://www.comuni-italiani.it/015/122/"
    },
    {
      "Istat": "069049",
      "Comune": "Liscia",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "E611",
      "Abitanti": 730,
      "Link": "http://www.comuni-italiani.it/069/049/"
    },
    {
      "Istat": "054025",
      "Comune": "Lisciano Niccone",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06060",
      "CodFisco": "E613",
      "Abitanti": 627,
      "Link": "http://www.comuni-italiani.it/054/025/"
    },
    {
      "Istat": "022105",
      "Comune": "Lisignago",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "E614",
      "Abitanti": 504,
      "Link": "http://www.comuni-italiani.it/022/105/"
    },
    {
      "Istat": "004111",
      "Comune": "Lisio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "E615",
      "Abitanti": 226,
      "Link": "http://www.comuni-italiani.it/004/111/"
    },
    {
      "Istat": 108028,
      "Comune": "Lissone",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20851,
      "CodFisco": "E617",
      "Abitanti": 42474,
      "Link": "http://www.comuni-italiani.it/108/028/"
    },
    {
      "Istat": "063040",
      "Comune": "Liveri",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "E620",
      "Abitanti": 1669,
      "Link": "http://www.comuni-italiani.it/063/040/"
    },
    {
      "Istat": "014037",
      "Comune": "Livigno",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "E621",
      "Abitanti": 5991,
      "Link": "http://www.comuni-italiani.it/014/037/"
    },
    {
      "Istat": "025030",
      "Comune": "Livinallongo del Col di Lana",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0436",
      "CAP": 32020,
      "CodFisco": "E622",
      "Abitanti": 1405,
      "Link": "http://www.comuni-italiani.it/025/030/"
    },
    {
      "Istat": "022106",
      "Comune": "Livo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "E624",
      "Abitanti": 905,
      "Link": "http://www.comuni-italiani.it/022/106/"
    },
    {
      "Istat": "013130",
      "Comune": "Livo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "E623",
      "Abitanti": 198,
      "Link": "http://www.comuni-italiani.it/013/130/"
    },
    {
      "Istat": "049009",
      "Comune": "Livorno",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": "571xx",
      "CodFisco": "E625",
      "Abitanti": 161131,
      "Link": "http://www.comuni-italiani.it/049/009/"
    },
    {
      "Istat": "002071",
      "Comune": "Livorno Ferraris",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13046,
      "CodFisco": "E626",
      "Abitanti": 4529,
      "Link": "http://www.comuni-italiani.it/002/071/"
    },
    {
      "Istat": "098030",
      "Comune": "Livraga",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26814,
      "CodFisco": "E627",
      "Abitanti": 2611,
      "Link": "http://www.comuni-italiani.it/098/030/"
    },
    {
      "Istat": "075038",
      "Comune": "Lizzanello",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73023,
      "CodFisco": "E629",
      "Abitanti": 11788,
      "Link": "http://www.comuni-italiani.it/075/038/"
    },
    {
      "Istat": "073011",
      "Comune": "Lizzano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "E630",
      "Abitanti": 10282,
      "Link": "http://www.comuni-italiani.it/073/011/"
    },
    {
      "Istat": "037033",
      "Comune": "Lizzano in Belvedere",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40042,
      "CodFisco": "A771",
      "Abitanti": 2380,
      "Link": "http://www.comuni-italiani.it/037/033/"
    },
    {
      "Istat": "009034",
      "Comune": "Loano",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17025,
      "CodFisco": "E632",
      "Abitanti": 12034,
      "Link": "http://www.comuni-italiani.it/009/034/"
    },
    {
      "Istat": "005060",
      "Comune": "Loazzolo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14051,
      "CodFisco": "E633",
      "Abitanti": 342,
      "Link": "http://www.comuni-italiani.it/005/060/"
    },
    {
      "Istat": "001134",
      "Comune": "Locana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "E635",
      "Abitanti": 1642,
      "Link": "http://www.comuni-italiani.it/001/134/"
    },
    {
      "Istat": "015125",
      "Comune": "Locate di Triulzi",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20085,
      "CodFisco": "E639",
      "Abitanti": 9707,
      "Link": "http://www.comuni-italiani.it/015/125/"
    },
    {
      "Istat": "013131",
      "Comune": "Locate Varesino",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 22070,
      "CodFisco": "E638",
      "Abitanti": 4249,
      "Link": "http://www.comuni-italiani.it/013/131/"
    },
    {
      "Istat": "016127",
      "Comune": "Locatello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "E640",
      "Abitanti": 853,
      "Link": "http://www.comuni-italiani.it/016/127/"
    },
    {
      "Istat": 105011,
      "Comune": "Loceri",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "E644",
      "Abitanti": 1267,
      "Link": "http://www.comuni-italiani.it/105/011/"
    },
    {
      "Istat": "072025",
      "Comune": "Locorotondo",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "E645",
      "Abitanti": 14231,
      "Link": "http://www.comuni-italiani.it/072/025/"
    },
    {
      "Istat": "080043",
      "Comune": "Locri",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89044,
      "CodFisco": "D976",
      "Abitanti": 12877,
      "Link": "http://www.comuni-italiani.it/080/043/"
    },
    {
      "Istat": "091040",
      "Comune": "Loculi",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "E646",
      "Abitanti": 513,
      "Link": "http://www.comuni-italiani.it/091/040/"
    },
    {
      "Istat": "091041",
      "Comune": "LodË",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "E647",
      "Abitanti": 1935,
      "Link": "http://www.comuni-italiani.it/091/041/"
    },
    {
      "Istat": "098031",
      "Comune": "Lodi",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26900,
      "CodFisco": "E648",
      "Abitanti": 44401,
      "Link": "http://www.comuni-italiani.it/098/031/"
    },
    {
      "Istat": "098032",
      "Comune": "Lodi Vecchio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26855,
      "CodFisco": "E651",
      "Abitanti": 7401,
      "Link": "http://www.comuni-italiani.it/098/032/"
    },
    {
      "Istat": "091104",
      "Comune": "Lodine",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "E649",
      "Abitanti": 380,
      "Link": "http://www.comuni-italiani.it/091/104/"
    },
    {
      "Istat": "017090",
      "Comune": "Lodrino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "E652",
      "Abitanti": 1795,
      "Link": "http://www.comuni-italiani.it/017/090/"
    },
    {
      "Istat": "017091",
      "Comune": "Lograto",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "E654",
      "Abitanti": 3888,
      "Link": "http://www.comuni-italiani.it/017/091/"
    },
    {
      "Istat": "037034",
      "Comune": "Loiano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "E655",
      "Abitanti": 4511,
      "Link": "http://www.comuni-italiani.it/037/034/"
    },
    {
      "Istat": 104013,
      "Comune": "Loiri Porto San Paolo",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07020",
      "CodFisco": "M275",
      "Abitanti": 3270,
      "Link": "http://www.comuni-italiani.it/104/013/"
    },
    {
      "Istat": "097044",
      "Comune": "Lomagna",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23871,
      "CodFisco": "E656",
      "Abitanti": 4849,
      "Link": "http://www.comuni-italiani.it/097/044/"
    },
    {
      "Istat": "013133",
      "Comune": "Lomazzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 22074,
      "CodFisco": "E659",
      "Abitanti": 9564,
      "Link": "http://www.comuni-italiani.it/013/133/"
    },
    {
      "Istat": "001135",
      "Comune": "Lombardore",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "E660",
      "Abitanti": 1712,
      "Link": "http://www.comuni-italiani.it/001/135/"
    },
    {
      "Istat": "001136",
      "Comune": "Lombriasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "E661",
      "Abitanti": 1075,
      "Link": "http://www.comuni-italiani.it/001/136/"
    },
    {
      "Istat": "018083",
      "Comune": "Lomello",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27034,
      "CodFisco": "E662",
      "Abitanti": 2322,
      "Link": "http://www.comuni-italiani.it/018/083/"
    },
    {
      "Istat": "022108",
      "Comune": "Lona-Lases",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38040,
      "CodFisco": "E664",
      "Abitanti": 854,
      "Link": "http://www.comuni-italiani.it/022/108/"
    },
    {
      "Istat": "012089",
      "Comune": "Lonate Ceppino",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21050,
      "CodFisco": "E665",
      "Abitanti": 4909,
      "Link": "http://www.comuni-italiani.it/012/089/"
    },
    {
      "Istat": "012090",
      "Comune": "Lonate Pozzolo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21015,
      "CodFisco": "E666",
      "Abitanti": 12059,
      "Link": "http://www.comuni-italiani.it/012/090/"
    },
    {
      "Istat": "017092",
      "Comune": "Lonato del Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25017,
      "CodFisco": "M312",
      "Abitanti": 15744,
      "Link": "http://www.comuni-italiani.it/017/092/"
    },
    {
      "Istat": "048025",
      "Comune": "Londa",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50060,
      "CodFisco": "E668",
      "Abitanti": 1874,
      "Link": "http://www.comuni-italiani.it/048/025/"
    },
    {
      "Istat": "094024",
      "Comune": "Longano",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86090,
      "CodFisco": "E669",
      "Abitanti": 739,
      "Link": "http://www.comuni-italiani.it/094/024/"
    },
    {
      "Istat": "024051",
      "Comune": "Longare",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36023,
      "CodFisco": "E671",
      "Abitanti": 5646,
      "Link": "http://www.comuni-italiani.it/024/051/"
    },
    {
      "Istat": "025031",
      "Comune": "Longarone",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32013,
      "CodFisco": "E672",
      "Abitanti": 4021,
      "Link": "http://www.comuni-italiani.it/025/031/"
    },
    {
      "Istat": "017093",
      "Comune": "Longhena",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "E673",
      "Abitanti": 620,
      "Link": "http://www.comuni-italiani.it/017/093/"
    },
    {
      "Istat": "083042",
      "Comune": "Longi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "E674",
      "Abitanti": 1583,
      "Link": "http://www.comuni-italiani.it/083/042/"
    },
    {
      "Istat": "040018",
      "Comune": "Longiano",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": 47020,
      "CodFisco": "E675",
      "Abitanti": 6966,
      "Link": "http://www.comuni-italiani.it/040/018/"
    },
    {
      "Istat": "078067",
      "Comune": "Longobardi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87030,
      "CodFisco": "E677",
      "Abitanti": 2358,
      "Link": "http://www.comuni-italiani.it/078/067/"
    },
    {
      "Istat": "078068",
      "Comune": "Longobucco",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87066,
      "CodFisco": "E678",
      "Abitanti": 3612,
      "Link": "http://www.comuni-italiani.it/078/068/"
    },
    {
      "Istat": "013134",
      "Comune": "Longone al Segrino",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "E679",
      "Abitanti": 1789,
      "Link": "http://www.comuni-italiani.it/013/134/"
    },
    {
      "Istat": "057034",
      "Comune": "Longone Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "E681",
      "Abitanti": 604,
      "Link": "http://www.comuni-italiani.it/057/034/"
    },
    {
      "Istat": "024052",
      "Comune": "Lonigo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36045,
      "CodFisco": "E682",
      "Abitanti": 16322,
      "Link": "http://www.comuni-italiani.it/024/052/"
    },
    {
      "Istat": "001137",
      "Comune": "LoranzË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "E683",
      "Abitanti": 1106,
      "Link": "http://www.comuni-italiani.it/001/137/"
    },
    {
      "Istat": "028046",
      "Comune": "Loreggia",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "E684",
      "Abitanti": 7259,
      "Link": "http://www.comuni-italiani.it/028/046/"
    },
    {
      "Istat": 103038,
      "Comune": "Loreglia",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28893,
      "CodFisco": "E685",
      "Abitanti": 270,
      "Link": "http://www.comuni-italiani.it/103/038/"
    },
    {
      "Istat": "025032",
      "Comune": "Lorenzago di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "E687",
      "Abitanti": 579,
      "Link": "http://www.comuni-italiani.it/025/032/"
    },
    {
      "Istat": "050018",
      "Comune": "Lorenzana",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56043,
      "CodFisco": "E688",
      "Abitanti": 1203,
      "Link": "http://www.comuni-italiani.it/050/018/"
    },
    {
      "Istat": "029030",
      "Comune": "Loreo",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45017,
      "CodFisco": "E689",
      "Abitanti": 3735,
      "Link": "http://www.comuni-italiani.it/029/030/"
    },
    {
      "Istat": "042022",
      "Comune": "Loreto",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60025,
      "CodFisco": "E690",
      "Abitanti": 12543,
      "Link": "http://www.comuni-italiani.it/042/022/"
    },
    {
      "Istat": "068021",
      "Comune": "Loreto Aprutino",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65014,
      "CodFisco": "E691",
      "Abitanti": 7741,
      "Link": "http://www.comuni-italiani.it/068/021/"
    },
    {
      "Istat": "026036",
      "Comune": "Loria",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31037,
      "CodFisco": "E692",
      "Abitanti": 9116,
      "Link": "http://www.comuni-italiani.it/026/036/"
    },
    {
      "Istat": "051020",
      "Comune": "Loro Ciuffenna",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52024,
      "CodFisco": "E693",
      "Abitanti": 5925,
      "Link": "http://www.comuni-italiani.it/051/020/"
    },
    {
      "Istat": "043022",
      "Comune": "Loro Piceno",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "E694",
      "Abitanti": 2497,
      "Link": "http://www.comuni-italiani.it/043/022/"
    },
    {
      "Istat": "010030",
      "Comune": "Lorsica",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16045,
      "CodFisco": "E695",
      "Abitanti": 529,
      "Link": "http://www.comuni-italiani.it/010/030/"
    },
    {
      "Istat": "017094",
      "Comune": "Losine",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "E698",
      "Abitanti": 596,
      "Link": "http://www.comuni-italiani.it/017/094/"
    },
    {
      "Istat": 105012,
      "Comune": "Lotzorai",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "E700",
      "Abitanti": 2164,
      "Link": "http://www.comuni-italiani.it/105/012/"
    },
    {
      "Istat": "016128",
      "Comune": "Lovere",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24065,
      "CodFisco": "E704",
      "Abitanti": 5428,
      "Link": "http://www.comuni-italiani.it/016/128/"
    },
    {
      "Istat": "014038",
      "Comune": "Lovero",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "E705",
      "Abitanti": 670,
      "Link": "http://www.comuni-italiani.it/014/038/"
    },
    {
      "Istat": "017095",
      "Comune": "Lozio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "E706",
      "Abitanti": 416,
      "Link": "http://www.comuni-italiani.it/017/095/"
    },
    {
      "Istat": "012091",
      "Comune": "Lozza",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21040,
      "CodFisco": "E707",
      "Abitanti": 1238,
      "Link": "http://www.comuni-italiani.it/012/091/"
    },
    {
      "Istat": "028047",
      "Comune": "Lozzo Atestino",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35034,
      "CodFisco": "E709",
      "Abitanti": 3233,
      "Link": "http://www.comuni-italiani.it/028/047/"
    },
    {
      "Istat": "025033",
      "Comune": "Lozzo di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "E708",
      "Abitanti": 1528,
      "Link": "http://www.comuni-italiani.it/025/033/"
    },
    {
      "Istat": "002072",
      "Comune": "Lozzolo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13045,
      "CodFisco": "E711",
      "Abitanti": 822,
      "Link": "http://www.comuni-italiani.it/002/072/"
    },
    {
      "Istat": "006089",
      "Comune": "Lu",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "E712",
      "Abitanti": 1170,
      "Link": "http://www.comuni-italiani.it/006/089/"
    },
    {
      "Istat": "056033",
      "Comune": "Lubriano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01020",
      "CodFisco": "E713",
      "Abitanti": 941,
      "Link": "http://www.comuni-italiani.it/056/033/"
    },
    {
      "Istat": "046017",
      "Comune": "Lucca",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55100,
      "CodFisco": "E715",
      "Abitanti": 84939,
      "Link": "http://www.comuni-italiani.it/046/017/"
    },
    {
      "Istat": "084022",
      "Comune": "Lucca Sicula",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92010,
      "CodFisco": "E714",
      "Abitanti": 1905,
      "Link": "http://www.comuni-italiani.it/084/022/"
    },
    {
      "Istat": "071028",
      "Comune": "Lucera",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71036,
      "CodFisco": "E716",
      "Abitanti": 34513,
      "Link": "http://www.comuni-italiani.it/071/028/"
    },
    {
      "Istat": "051021",
      "Comune": "Lucignano",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52046,
      "CodFisco": "E718",
      "Abitanti": 3613,
      "Link": "http://www.comuni-italiani.it/051/021/"
    },
    {
      "Istat": "008033",
      "Comune": "Lucinasco",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "E719",
      "Abitanti": 287,
      "Link": "http://www.comuni-italiani.it/008/033/"
    },
    {
      "Istat": "070033",
      "Comune": "Lucito",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "E722",
      "Abitanti": 763,
      "Link": "http://www.comuni-italiani.it/070/033/"
    },
    {
      "Istat": "066051",
      "Comune": "Luco dei Marsi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67056,
      "CodFisco": "E723",
      "Abitanti": 5950,
      "Link": "http://www.comuni-italiani.it/066/051/"
    },
    {
      "Istat": "066052",
      "Comune": "Lucoli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67045,
      "CodFisco": "E724",
      "Abitanti": 1029,
      "Link": "http://www.comuni-italiani.it/066/052/"
    },
    {
      "Istat": "033026",
      "Comune": "Lugagnano Val D'Arda",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29018,
      "CodFisco": "E726",
      "Abitanti": 4293,
      "Link": "http://www.comuni-italiani.it/033/026/"
    },
    {
      "Istat": "001138",
      "Comune": "Lugnacco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "E727",
      "Abitanti": 380,
      "Link": "http://www.comuni-italiani.it/001/138/"
    },
    {
      "Istat": "055016",
      "Comune": "Lugnano in Teverina",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05020",
      "CodFisco": "E729",
      "Abitanti": 1569,
      "Link": "http://www.comuni-italiani.it/055/016/"
    },
    {
      "Istat": "039012",
      "Comune": "Lugo",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48022,
      "CodFisco": "E730",
      "Abitanti": 32777,
      "Link": "http://www.comuni-italiani.it/039/012/"
    },
    {
      "Istat": "024053",
      "Comune": "Lugo di Vicenza",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "E731",
      "Abitanti": 3769,
      "Link": "http://www.comuni-italiani.it/024/053/"
    },
    {
      "Istat": "012092",
      "Comune": "Luino",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21016,
      "CodFisco": "E734",
      "Abitanti": 14471,
      "Link": "http://www.comuni-italiani.it/012/092/"
    },
    {
      "Istat": "013135",
      "Comune": "Luisago",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "E735",
      "Abitanti": 2702,
      "Link": "http://www.comuni-italiani.it/013/135/"
    },
    {
      "Istat": "091043",
      "Comune": "Lula",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "E736",
      "Abitanti": 1521,
      "Link": "http://www.comuni-italiani.it/091/043/"
    },
    {
      "Istat": "010031",
      "Comune": "Lumarzo",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16024,
      "CodFisco": "E737",
      "Abitanti": 1618,
      "Link": "http://www.comuni-italiani.it/010/031/"
    },
    {
      "Istat": "017096",
      "Comune": "Lumezzane",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25065,
      "CodFisco": "E738",
      "Abitanti": 23828,
      "Link": "http://www.comuni-italiani.it/017/096/"
    },
    {
      "Istat": 106010,
      "Comune": "Lunamatrona",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09022",
      "CodFisco": "E742",
      "Abitanti": 1799,
      "Link": "http://www.comuni-italiani.it/106/010/"
    },
    {
      "Istat": "041022",
      "Comune": "Lunano",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61026,
      "CodFisco": "E743",
      "Abitanti": 1523,
      "Link": "http://www.comuni-italiani.it/041/022/"
    },
    {
      "Istat": "018084",
      "Comune": "Lungavilla",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27053,
      "CodFisco": "B387",
      "Abitanti": 2417,
      "Link": "http://www.comuni-italiani.it/018/084/"
    },
    {
      "Istat": "078069",
      "Comune": "Lungro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "E745",
      "Abitanti": 2812,
      "Link": "http://www.comuni-italiani.it/078/069/"
    },
    {
      "Istat": "064045",
      "Comune": "Luogosano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "E746",
      "Abitanti": 1239,
      "Link": "http://www.comuni-italiani.it/064/045/"
    },
    {
      "Istat": 104014,
      "Comune": "Luogosanto",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07020",
      "CodFisco": "E747",
      "Abitanti": 1902,
      "Link": "http://www.comuni-italiani.it/104/014/"
    },
    {
      "Istat": "070034",
      "Comune": "Lupara",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "E748",
      "Abitanti": 553,
      "Link": "http://www.comuni-italiani.it/070/034/"
    },
    {
      "Istat": "013136",
      "Comune": "Lurago d'Erba",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22040,
      "CodFisco": "E749",
      "Abitanti": 5350,
      "Link": "http://www.comuni-italiani.it/013/136/"
    },
    {
      "Istat": "013137",
      "Comune": "Lurago Marinone",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "E750",
      "Abitanti": 2463,
      "Link": "http://www.comuni-italiani.it/013/137/"
    },
    {
      "Istat": "016129",
      "Comune": "Lurano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "E751",
      "Abitanti": 2566,
      "Link": "http://www.comuni-italiani.it/016/129/"
    },
    {
      "Istat": 104015,
      "Comune": "Luras",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07025",
      "CodFisco": "E752",
      "Abitanti": 2715,
      "Link": "http://www.comuni-italiani.it/104/015/"
    },
    {
      "Istat": "013138",
      "Comune": "Lurate Caccivio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22075,
      "CodFisco": "E753",
      "Abitanti": 10083,
      "Link": "http://www.comuni-italiani.it/013/138/"
    },
    {
      "Istat": "061046",
      "Comune": "Lusciano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "E754",
      "Abitanti": 14553,
      "Link": "http://www.comuni-italiani.it/061/046/"
    },
    {
      "Istat": "022109",
      "Comune": "Luserna",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38040,
      "CodFisco": "E757",
      "Abitanti": 297,
      "Link": "http://www.comuni-italiani.it/022/109/"
    },
    {
      "Istat": "001139",
      "Comune": "Luserna San Giovanni",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10062,
      "CodFisco": "E758",
      "Abitanti": 7676,
      "Link": "http://www.comuni-italiani.it/001/139/"
    },
    {
      "Istat": "001140",
      "Comune": "Lusernetta",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "E759",
      "Abitanti": 531,
      "Link": "http://www.comuni-italiani.it/001/140/"
    },
    {
      "Istat": "030051",
      "Comune": "Lusevera",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "E760",
      "Abitanti": 711,
      "Link": "http://www.comuni-italiani.it/030/051/"
    },
    {
      "Istat": "029031",
      "Comune": "Lusia",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "E761",
      "Abitanti": 3629,
      "Link": "http://www.comuni-italiani.it/029/031/"
    },
    {
      "Istat": "024054",
      "Comune": "Lusiana",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36046,
      "CodFisco": "E762",
      "Abitanti": 2818,
      "Link": "http://www.comuni-italiani.it/024/054/"
    },
    {
      "Istat": "001141",
      "Comune": "LusigliË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "E763",
      "Abitanti": 561,
      "Link": "http://www.comuni-italiani.it/001/141/"
    },
    {
      "Istat": "021044",
      "Comune": "Luson",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "E764",
      "Abitanti": 1539,
      "Link": "http://www.comuni-italiani.it/021/044/"
    },
    {
      "Istat": "065064",
      "Comune": "Lustra",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84050,
      "CodFisco": "E767",
      "Abitanti": 1110,
      "Link": "http://www.comuni-italiani.it/065/064/"
    },
    {
      "Istat": "012093",
      "Comune": "Luvinate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "E769",
      "Abitanti": 1334,
      "Link": "http://www.comuni-italiani.it/012/093/"
    },
    {
      "Istat": "016130",
      "Comune": "Luzzana",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24069,
      "CodFisco": "E770",
      "Abitanti": 868,
      "Link": "http://www.comuni-italiani.it/016/130/"
    },
    {
      "Istat": "035026",
      "Comune": "Luzzara",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42045,
      "CodFisco": "E772",
      "Abitanti": 9134,
      "Link": "http://www.comuni-italiani.it/035/026/"
    },
    {
      "Istat": "078070",
      "Comune": "Luzzi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "E773",
      "Abitanti": 9997,
      "Link": "http://www.comuni-italiani.it/078/070/"
    },
    {
      "Istat": "012094",
      "Comune": "Maccagno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "E775",
      "Abitanti": 2041,
      "Link": "http://www.comuni-italiani.it/012/094/"
    },
    {
      "Istat": "098033",
      "Comune": "Maccastorna",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26843,
      "CodFisco": "E777",
      "Abitanti": 68,
      "Link": "http://www.comuni-italiani.it/098/033/"
    },
    {
      "Istat": "094025",
      "Comune": "Macchia d'Isernia",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "E778",
      "Abitanti": 982,
      "Link": "http://www.comuni-italiani.it/094/025/"
    },
    {
      "Istat": "070035",
      "Comune": "Macchia Valfortore",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "E780",
      "Abitanti": 638,
      "Link": "http://www.comuni-italiani.it/070/035/"
    },
    {
      "Istat": "094026",
      "Comune": "Macchiagodena",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86096,
      "CodFisco": "E779",
      "Abitanti": 1877,
      "Link": "http://www.comuni-italiani.it/094/026/"
    },
    {
      "Istat": "001142",
      "Comune": "Macello",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "E782",
      "Abitanti": 1247,
      "Link": "http://www.comuni-italiani.it/001/142/"
    },
    {
      "Istat": "043023",
      "Comune": "Macerata",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62100,
      "CodFisco": "E783",
      "Abitanti": 43019,
      "Link": "http://www.comuni-italiani.it/043/023/"
    },
    {
      "Istat": "061047",
      "Comune": "Macerata Campania",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81047,
      "CodFisco": "E784",
      "Abitanti": 10845,
      "Link": "http://www.comuni-italiani.it/061/047/"
    },
    {
      "Istat": "041023",
      "Comune": "Macerata Feltria",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61023,
      "CodFisco": "E785",
      "Abitanti": 2133,
      "Link": "http://www.comuni-italiani.it/041/023/"
    },
    {
      "Istat": 108029,
      "Comune": "Macherio",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20846,
      "CodFisco": "E786",
      "Abitanti": 7222,
      "Link": "http://www.comuni-italiani.it/108/029/"
    },
    {
      "Istat": "017097",
      "Comune": "Maclodio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "E787",
      "Abitanti": 1514,
      "Link": "http://www.comuni-italiani.it/017/097/"
    },
    {
      "Istat": "091044",
      "Comune": "Macomer",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08015",
      "CodFisco": "E788",
      "Abitanti": 10672,
      "Link": "http://www.comuni-italiani.it/091/044/"
    },
    {
      "Istat": "004112",
      "Comune": "Macra",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "E789",
      "Abitanti": 55,
      "Link": "http://www.comuni-italiani.it/004/112/"
    },
    {
      "Istat": 103039,
      "Comune": "Macugnaga",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28876,
      "CodFisco": "E790",
      "Abitanti": 613,
      "Link": "http://www.comuni-italiani.it/103/039/"
    },
    {
      "Istat": "061048",
      "Comune": "Maddaloni",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81024,
      "CodFisco": "E791",
      "Abitanti": 38690,
      "Link": "http://www.comuni-italiani.it/061/048/"
    },
    {
      "Istat": "014035",
      "Comune": "Madesimo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23024,
      "CodFisco": "E342",
      "Abitanti": 566,
      "Link": "http://www.comuni-italiani.it/014/035/"
    },
    {
      "Istat": "019055",
      "Comune": "Madignano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26020,
      "CodFisco": "E793",
      "Abitanti": 2992,
      "Link": "http://www.comuni-italiani.it/019/055/"
    },
    {
      "Istat": "016131",
      "Comune": "Madone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "E794",
      "Abitanti": 4031,
      "Link": "http://www.comuni-italiani.it/016/131/"
    },
    {
      "Istat": 103040,
      "Comune": "Madonna del Sasso",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28894,
      "CodFisco": "E795",
      "Abitanti": 412,
      "Link": "http://www.comuni-italiani.it/103/040/"
    },
    {
      "Istat": "059013",
      "Comune": "Maenza",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "E798",
      "Abitanti": 3169,
      "Link": "http://www.comuni-italiani.it/059/013/"
    },
    {
      "Istat": "070036",
      "Comune": "Mafalda",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86030,
      "CodFisco": "E799",
      "Abitanti": 1308,
      "Link": "http://www.comuni-italiani.it/070/036/"
    },
    {
      "Istat": "017098",
      "Comune": "Magasa",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "E800",
      "Abitanti": 152,
      "Link": "http://www.comuni-italiani.it/017/098/"
    },
    {
      "Istat": "015130",
      "Comune": "Magenta",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20013,
      "CodFisco": "E801",
      "Abitanti": 23513,
      "Link": "http://www.comuni-italiani.it/015/130/"
    },
    {
      "Istat": "003088",
      "Comune": "Maggiora",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28014,
      "CodFisco": "E803",
      "Abitanti": 1794,
      "Link": "http://www.comuni-italiani.it/003/088/"
    },
    {
      "Istat": "018085",
      "Comune": "Magherno",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "E804",
      "Abitanti": 1619,
      "Link": "http://www.comuni-italiani.it/018/085/"
    },
    {
      "Istat": "054026",
      "Comune": "Magione",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06063",
      "CodFisco": "E805",
      "Abitanti": 14799,
      "Link": "http://www.comuni-italiani.it/054/026/"
    },
    {
      "Istat": "079068",
      "Comune": "Magisano",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "E806",
      "Abitanti": 1285,
      "Link": "http://www.comuni-italiani.it/079/068/"
    },
    {
      "Istat": "004113",
      "Comune": "Magliano Alfieri",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "E809",
      "Abitanti": 1984,
      "Link": "http://www.comuni-italiani.it/004/113/"
    },
    {
      "Istat": "004114",
      "Comune": "Magliano Alpi",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "E808",
      "Abitanti": 2217,
      "Link": "http://www.comuni-italiani.it/004/114/"
    },
    {
      "Istat": "066053",
      "Comune": "Magliano de' Marsi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67062,
      "CodFisco": "E811",
      "Abitanti": 3884,
      "Link": "http://www.comuni-italiani.it/066/053/"
    },
    {
      "Istat": 109010,
      "Comune": "Magliano di Tenna",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63832,
      "CodFisco": "E807",
      "Abitanti": 1450,
      "Link": "http://www.comuni-italiani.it/109/010/"
    },
    {
      "Istat": "053013",
      "Comune": "Magliano in Toscana",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58051,
      "CodFisco": "E810",
      "Abitanti": 3746,
      "Link": "http://www.comuni-italiani.it/053/013/"
    },
    {
      "Istat": "058052",
      "Comune": "Magliano Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "E813",
      "Abitanti": 1519,
      "Link": "http://www.comuni-italiani.it/058/052/"
    },
    {
      "Istat": "057035",
      "Comune": "Magliano Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0744",
      "CAP": "02046",
      "CodFisco": "E812",
      "Abitanti": 3892,
      "Link": "http://www.comuni-italiani.it/057/035/"
    },
    {
      "Istat": "065065",
      "Comune": "Magliano Vetere",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84050,
      "CodFisco": "E814",
      "Abitanti": 778,
      "Link": "http://www.comuni-italiani.it/065/065/"
    },
    {
      "Istat": "075039",
      "Comune": "Maglie",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73024,
      "CodFisco": "E815",
      "Abitanti": 14981,
      "Link": "http://www.comuni-italiani.it/075/039/"
    },
    {
      "Istat": "009035",
      "Comune": "Magliolo",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17020,
      "CodFisco": "E816",
      "Abitanti": 882,
      "Link": "http://www.comuni-italiani.it/009/035/"
    },
    {
      "Istat": "001143",
      "Comune": "Maglione",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 10030,
      "CodFisco": "E817",
      "Abitanti": 467,
      "Link": "http://www.comuni-italiani.it/001/143/"
    },
    {
      "Istat": "020029",
      "Comune": "Magnacavallo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "E818",
      "Abitanti": 1696,
      "Link": "http://www.comuni-italiani.it/020/029/"
    },
    {
      "Istat": "015131",
      "Comune": "Magnago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "E819",
      "Abitanti": 9090,
      "Link": "http://www.comuni-italiani.it/015/131/"
    },
    {
      "Istat": "096030",
      "Comune": "Magnano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13887,
      "CodFisco": "E821",
      "Abitanti": 387,
      "Link": "http://www.comuni-italiani.it/096/030/"
    },
    {
      "Istat": "030052",
      "Comune": "Magnano in Riviera",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "E820",
      "Abitanti": 2393,
      "Link": "http://www.comuni-italiani.it/030/052/"
    },
    {
      "Istat": "095083",
      "Comune": "Magomadas",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "E825",
      "Abitanti": 655,
      "Link": "http://www.comuni-italiani.it/095/083/"
    },
    {
      "Istat": "021045",
      "Comune": "MagrË sulla Strada del Vino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "E829",
      "Abitanti": 1302,
      "Link": "http://www.comuni-italiani.it/021/045/"
    },
    {
      "Istat": "013139",
      "Comune": "Magreglio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "E830",
      "Abitanti": 666,
      "Link": "http://www.comuni-italiani.it/013/139/"
    },
    {
      "Istat": "079069",
      "Comune": "Maida",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88025,
      "CodFisco": "E834",
      "Abitanti": 4453,
      "Link": "http://www.comuni-italiani.it/079/069/"
    },
    {
      "Istat": "078071",
      "Comune": "Maier‡",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "E835",
      "Abitanti": 1254,
      "Link": "http://www.comuni-italiani.it/078/071/"
    },
    {
      "Istat": 102020,
      "Comune": "Maierato",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89843,
      "CodFisco": "E836",
      "Abitanti": 2279,
      "Link": "http://www.comuni-italiani.it/102/020/"
    },
    {
      "Istat": "042023",
      "Comune": "Maiolati Spontini",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "E837",
      "Abitanti": 6199,
      "Link": "http://www.comuni-italiani.it/042/023/"
    },
    {
      "Istat": "099022",
      "Comune": "Maiolo",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47862,
      "CodFisco": "E838",
      "Abitanti": 854,
      "Link": "http://www.comuni-italiani.it/099/022/"
    },
    {
      "Istat": "065066",
      "Comune": "Maiori",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "E839",
      "Abitanti": 5626,
      "Link": "http://www.comuni-italiani.it/065/066/"
    },
    {
      "Istat": "098034",
      "Comune": "Mairago",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26825,
      "CodFisco": "E840",
      "Abitanti": 1429,
      "Link": "http://www.comuni-italiani.it/098/034/"
    },
    {
      "Istat": "017099",
      "Comune": "Mairano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "E841",
      "Abitanti": 3327,
      "Link": "http://www.comuni-italiani.it/017/099/"
    },
    {
      "Istat": "011018",
      "Comune": "Maissana",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19010,
      "CodFisco": "E842",
      "Abitanti": 665,
      "Link": "http://www.comuni-italiani.it/011/018/"
    },
    {
      "Istat": "030053",
      "Comune": "Majano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "E833",
      "Abitanti": 6080,
      "Link": "http://www.comuni-italiani.it/030/053/"
    },
    {
      "Istat": "019056",
      "Comune": "Malagnino",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "E843",
      "Abitanti": 1457,
      "Link": "http://www.comuni-italiani.it/019/056/"
    },
    {
      "Istat": "037035",
      "Comune": "Malalbergo",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40051,
      "CodFisco": "E844",
      "Abitanti": 8810,
      "Link": "http://www.comuni-italiani.it/037/035/"
    },
    {
      "Istat": "030054",
      "Comune": "Malborghetto Valbruna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0428",
      "CAP": 33010,
      "CodFisco": "E847",
      "Abitanti": 965,
      "Link": "http://www.comuni-italiani.it/030/054/"
    },
    {
      "Istat": "023045",
      "Comune": "Malcesine",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37018,
      "CodFisco": "E848",
      "Abitanti": 3748,
      "Link": "http://www.comuni-italiani.it/023/045/"
    },
    {
      "Istat": "022110",
      "Comune": "MalË",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38027,
      "CodFisco": "E850",
      "Abitanti": 2133,
      "Link": "http://www.comuni-italiani.it/022/110/"
    },
    {
      "Istat": "017100",
      "Comune": "Malegno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25053,
      "CodFisco": "E851",
      "Abitanti": 2115,
      "Link": "http://www.comuni-italiani.it/017/100/"
    },
    {
      "Istat": "098035",
      "Comune": "Maleo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26847,
      "CodFisco": "E852",
      "Abitanti": 3280,
      "Link": "http://www.comuni-italiani.it/098/035/"
    },
    {
      "Istat": 103041,
      "Comune": "Malesco",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28854,
      "CodFisco": "E853",
      "Abitanti": 1478,
      "Link": "http://www.comuni-italiani.it/103/041/"
    },
    {
      "Istat": "087022",
      "Comune": "Maletto",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95035,
      "CodFisco": "E854",
      "Abitanti": 4061,
      "Link": "http://www.comuni-italiani.it/087/022/"
    },
    {
      "Istat": "083043",
      "Comune": "Malfa",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98050,
      "CodFisco": "E855",
      "Abitanti": 943,
      "Link": "http://www.comuni-italiani.it/083/043/"
    },
    {
      "Istat": "012095",
      "Comune": "Malgesso",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "E856",
      "Abitanti": 1320,
      "Link": "http://www.comuni-italiani.it/012/095/"
    },
    {
      "Istat": "097045",
      "Comune": "Malgrate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23864,
      "CodFisco": "E858",
      "Abitanti": 4327,
      "Link": "http://www.comuni-italiani.it/097/045/"
    },
    {
      "Istat": "078072",
      "Comune": "Malito",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87030,
      "CodFisco": "E859",
      "Abitanti": 843,
      "Link": "http://www.comuni-italiani.it/078/072/"
    },
    {
      "Istat": "009036",
      "Comune": "Mallare",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17045,
      "CodFisco": "E860",
      "Abitanti": 1214,
      "Link": "http://www.comuni-italiani.it/009/036/"
    },
    {
      "Istat": "021046",
      "Comune": "Malles Venosta",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39024,
      "CodFisco": "E862",
      "Abitanti": 5093,
      "Link": "http://www.comuni-italiani.it/021/046/"
    },
    {
      "Istat": "012096",
      "Comune": "Malnate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21046,
      "CodFisco": "E863",
      "Abitanti": 16641,
      "Link": "http://www.comuni-italiani.it/012/096/"
    },
    {
      "Istat": "024055",
      "Comune": "Malo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36034,
      "CodFisco": "E864",
      "Abitanti": 14583,
      "Link": "http://www.comuni-italiani.it/024/055/"
    },
    {
      "Istat": "017101",
      "Comune": "Malonno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "E865",
      "Abitanti": 3357,
      "Link": "http://www.comuni-italiani.it/017/101/"
    },
    {
      "Istat": "022111",
      "Comune": "Malosco",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38013,
      "CodFisco": "E866",
      "Abitanti": 453,
      "Link": "http://www.comuni-italiani.it/022/111/"
    },
    {
      "Istat": "044027",
      "Comune": "Maltignano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63085,
      "CodFisco": "E868",
      "Abitanti": 2522,
      "Link": "http://www.comuni-italiani.it/044/027/"
    },
    {
      "Istat": "083044",
      "Comune": "Malvagna",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "E869",
      "Abitanti": 821,
      "Link": "http://www.comuni-italiani.it/083/044/"
    },
    {
      "Istat": "006090",
      "Comune": "Malvicino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15015,
      "CodFisco": "E870",
      "Abitanti": 96,
      "Link": "http://www.comuni-italiani.it/006/090/"
    },
    {
      "Istat": "078073",
      "Comune": "Malvito",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "E872",
      "Abitanti": 1888,
      "Link": "http://www.comuni-italiani.it/078/073/"
    },
    {
      "Istat": "080044",
      "Comune": "Mammola",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89045,
      "CodFisco": "E873",
      "Abitanti": 3049,
      "Link": "http://www.comuni-italiani.it/080/044/"
    },
    {
      "Istat": "091046",
      "Comune": "Mamoiada",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08024",
      "CodFisco": "E874",
      "Abitanti": 2592,
      "Link": "http://www.comuni-italiani.it/091/046/"
    },
    {
      "Istat": "053014",
      "Comune": "Manciano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58014,
      "CodFisco": "E875",
      "Abitanti": 7633,
      "Link": "http://www.comuni-italiani.it/053/014/"
    },
    {
      "Istat": "083045",
      "Comune": "Mandanici",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98020,
      "CodFisco": "E876",
      "Abitanti": 653,
      "Link": "http://www.comuni-italiani.it/083/045/"
    },
    {
      "Istat": "092036",
      "Comune": "Mandas",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "E877",
      "Abitanti": 2268,
      "Link": "http://www.comuni-italiani.it/092/036/"
    },
    {
      "Istat": "078074",
      "Comune": "Mandatoriccio",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "E878",
      "Abitanti": 2854,
      "Link": "http://www.comuni-italiani.it/078/074/"
    },
    {
      "Istat": "058053",
      "Comune": "Mandela",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "B632",
      "Abitanti": 947,
      "Link": "http://www.comuni-italiani.it/058/053/"
    },
    {
      "Istat": "097046",
      "Comune": "Mandello del Lario",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23826,
      "CodFisco": "E879",
      "Abitanti": 10628,
      "Link": "http://www.comuni-italiani.it/097/046/"
    },
    {
      "Istat": "003090",
      "Comune": "Mandello Vitta",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "E880",
      "Abitanti": 248,
      "Link": "http://www.comuni-italiani.it/003/090/"
    },
    {
      "Istat": "073012",
      "Comune": "Manduria",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74024,
      "CodFisco": "E882",
      "Abitanti": 31843,
      "Link": "http://www.comuni-italiani.it/073/012/"
    },
    {
      "Istat": "017102",
      "Comune": "Manerba del Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "E883",
      "Abitanti": 4985,
      "Link": "http://www.comuni-italiani.it/017/102/"
    },
    {
      "Istat": "017103",
      "Comune": "Manerbio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25025,
      "CodFisco": "E884",
      "Abitanti": 13273,
      "Link": "http://www.comuni-italiani.it/017/103/"
    },
    {
      "Istat": "071029",
      "Comune": "Manfredonia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71043,
      "CodFisco": "E885",
      "Abitanti": 57455,
      "Link": "http://www.comuni-italiani.it/071/029/"
    },
    {
      "Istat": "004115",
      "Comune": "Mango",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 12056,
      "CodFisco": "E887",
      "Abitanti": 1344,
      "Link": "http://www.comuni-italiani.it/004/115/"
    },
    {
      "Istat": "078075",
      "Comune": "Mangone",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "E888",
      "Abitanti": 1885,
      "Link": "http://www.comuni-italiani.it/078/075/"
    },
    {
      "Istat": "087057",
      "Comune": "Maniace",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "M283",
      "Abitanti": 3682,
      "Link": "http://www.comuni-italiani.it/087/057/"
    },
    {
      "Istat": "093025",
      "Comune": "Maniago",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33085,
      "CodFisco": "E889",
      "Abitanti": 11968,
      "Link": "http://www.comuni-italiani.it/093/025/"
    },
    {
      "Istat": "064046",
      "Comune": "Manocalzati",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "E891",
      "Abitanti": 3280,
      "Link": "http://www.comuni-italiani.it/064/046/"
    },
    {
      "Istat": "068022",
      "Comune": "Manoppello",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65024,
      "CodFisco": "E892",
      "Abitanti": 6952,
      "Link": "http://www.comuni-italiani.it/068/022/"
    },
    {
      "Istat": "026037",
      "Comune": "MansuË",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "E893",
      "Abitanti": 4989,
      "Link": "http://www.comuni-italiani.it/026/037/"
    },
    {
      "Istat": "004116",
      "Comune": "Manta",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "E894",
      "Abitanti": 3716,
      "Link": "http://www.comuni-italiani.it/004/116/"
    },
    {
      "Istat": "014039",
      "Comune": "Mantello",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23016,
      "CodFisco": "E896",
      "Abitanti": 755,
      "Link": "http://www.comuni-italiani.it/014/039/"
    },
    {
      "Istat": "020030",
      "Comune": "Mantova",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46100,
      "CodFisco": "E897",
      "Abitanti": 48612,
      "Link": "http://www.comuni-italiani.it/020/030/"
    },
    {
      "Istat": "030055",
      "Comune": "Manzano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33044,
      "CodFisco": "E899",
      "Abitanti": 6730,
      "Link": "http://www.comuni-italiani.it/030/055/"
    },
    {
      "Istat": "058054",
      "Comune": "Manziana",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00066",
      "CodFisco": "E900",
      "Abitanti": 6951,
      "Link": "http://www.comuni-italiani.it/058/054/"
    },
    {
      "Istat": "016132",
      "Comune": "Mapello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "E901",
      "Abitanti": 6446,
      "Link": "http://www.comuni-italiani.it/016/132/"
    },
    {
      "Istat": "090038",
      "Comune": "Mara",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "E902",
      "Abitanti": 688,
      "Link": "http://www.comuni-italiani.it/090/038/"
    },
    {
      "Istat": "092037",
      "Comune": "Maracalagonis",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "E903",
      "Abitanti": 7744,
      "Link": "http://www.comuni-italiani.it/092/037/"
    },
    {
      "Istat": "036019",
      "Comune": "Maranello",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41053,
      "CodFisco": "E904",
      "Abitanti": 16969,
      "Link": "http://www.comuni-italiani.it/036/019/"
    },
    {
      "Istat": "063041",
      "Comune": "Marano di Napoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80016,
      "CodFisco": "E906",
      "Abitanti": 59472,
      "Link": "http://www.comuni-italiani.it/063/041/"
    },
    {
      "Istat": "023046",
      "Comune": "Marano di Valpolicella",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "E911",
      "Abitanti": 3121,
      "Link": "http://www.comuni-italiani.it/023/046/"
    },
    {
      "Istat": "058055",
      "Comune": "Marano Equo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "E908",
      "Abitanti": 825,
      "Link": "http://www.comuni-italiani.it/058/055/"
    },
    {
      "Istat": "030056",
      "Comune": "Marano Lagunare",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "E910",
      "Abitanti": 1965,
      "Link": "http://www.comuni-italiani.it/030/056/"
    },
    {
      "Istat": "078076",
      "Comune": "Marano Marchesato",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "E914",
      "Abitanti": 3540,
      "Link": "http://www.comuni-italiani.it/078/076/"
    },
    {
      "Istat": "078077",
      "Comune": "Marano Principato",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "E915",
      "Abitanti": 3120,
      "Link": "http://www.comuni-italiani.it/078/077/"
    },
    {
      "Istat": "036020",
      "Comune": "Marano sul Panaro",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41054,
      "CodFisco": "E905",
      "Abitanti": 4716,
      "Link": "http://www.comuni-italiani.it/036/020/"
    },
    {
      "Istat": "003091",
      "Comune": "Marano Ticino",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28040,
      "CodFisco": "E907",
      "Abitanti": 1572,
      "Link": "http://www.comuni-italiani.it/003/091/"
    },
    {
      "Istat": "024056",
      "Comune": "Marano Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36035,
      "CodFisco": "E912",
      "Abitanti": 9835,
      "Link": "http://www.comuni-italiani.it/024/056/"
    },
    {
      "Istat": "005061",
      "Comune": "Maranzana",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "E917",
      "Abitanti": 325,
      "Link": "http://www.comuni-italiani.it/005/061/"
    },
    {
      "Istat": "076044",
      "Comune": "Maratea",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85046,
      "CodFisco": "E919",
      "Abitanti": 5210,
      "Link": "http://www.comuni-italiani.it/076/044/"
    },
    {
      "Istat": "015134",
      "Comune": "Marcallo con Casone",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "E921",
      "Abitanti": 6048,
      "Link": "http://www.comuni-italiani.it/015/134/"
    },
    {
      "Istat": "020031",
      "Comune": "Marcaria",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "E922",
      "Abitanti": 7027,
      "Link": "http://www.comuni-italiani.it/020/031/"
    },
    {
      "Istat": "079071",
      "Comune": "Marcedusa",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "E923",
      "Abitanti": 453,
      "Link": "http://www.comuni-italiani.it/079/071/"
    },
    {
      "Istat": "058056",
      "Comune": "Marcellina",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "E924",
      "Abitanti": 7101,
      "Link": "http://www.comuni-italiani.it/058/056/"
    },
    {
      "Istat": "079072",
      "Comune": "Marcellinara",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88044,
      "CodFisco": "E925",
      "Abitanti": 2275,
      "Link": "http://www.comuni-italiani.it/079/072/"
    },
    {
      "Istat": "057036",
      "Comune": "Marcetelli",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "E927",
      "Abitanti": 111,
      "Link": "http://www.comuni-italiani.it/057/036/"
    },
    {
      "Istat": "017104",
      "Comune": "Marcheno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "E928",
      "Abitanti": 4492,
      "Link": "http://www.comuni-italiani.it/017/104/"
    },
    {
      "Istat": "012097",
      "Comune": "Marchirolo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "E929",
      "Abitanti": 3423,
      "Link": "http://www.comuni-italiani.it/012/097/"
    },
    {
      "Istat": "049010",
      "Comune": "Marciana",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57030,
      "CodFisco": "E930",
      "Abitanti": 2217,
      "Link": "http://www.comuni-italiani.it/049/010/"
    },
    {
      "Istat": "049011",
      "Comune": "Marciana Marina",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57033,
      "CodFisco": "E931",
      "Abitanti": 1993,
      "Link": "http://www.comuni-italiani.it/049/011/"
    },
    {
      "Istat": "061049",
      "Comune": "Marcianise",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81025,
      "CodFisco": "E932",
      "Abitanti": 40439,
      "Link": "http://www.comuni-italiani.it/061/049/"
    },
    {
      "Istat": "051022",
      "Comune": "Marciano della Chiana",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52047,
      "CodFisco": "E933",
      "Abitanti": 3378,
      "Link": "http://www.comuni-italiani.it/051/022/"
    },
    {
      "Istat": "018086",
      "Comune": "Marcignago",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "E934",
      "Abitanti": 2488,
      "Link": "http://www.comuni-italiani.it/018/086/"
    },
    {
      "Istat": "027020",
      "Comune": "Marcon",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30020,
      "CodFisco": "E936",
      "Abitanti": 15938,
      "Link": "http://www.comuni-italiani.it/027/020/"
    },
    {
      "Istat": "021047",
      "Comune": "Marebbe",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "E938",
      "Abitanti": 2912,
      "Link": "http://www.comuni-italiani.it/021/047/"
    },
    {
      "Istat": "004117",
      "Comune": "Marene",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "E939",
      "Abitanti": 3047,
      "Link": "http://www.comuni-italiani.it/004/117/"
    },
    {
      "Istat": "026038",
      "Comune": "Mareno di Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31010,
      "CodFisco": "E940",
      "Abitanti": 9640,
      "Link": "http://www.comuni-italiani.it/026/038/"
    },
    {
      "Istat": "001144",
      "Comune": "Marentino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "E941",
      "Abitanti": 1409,
      "Link": "http://www.comuni-italiani.it/001/144/"
    },
    {
      "Istat": "005062",
      "Comune": "Maretto",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14018,
      "CodFisco": "E944",
      "Abitanti": 403,
      "Link": "http://www.comuni-italiani.it/005/062/"
    },
    {
      "Istat": "004118",
      "Comune": "Margarita",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12040,
      "CodFisco": "E945",
      "Abitanti": 1450,
      "Link": "http://www.comuni-italiani.it/004/118/"
    },
    {
      "Istat": 110005,
      "Comune": "Margherita di Savoia",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76016,
      "CodFisco": "E946",
      "Abitanti": 12465,
      "Link": "http://www.comuni-italiani.it/110/005/"
    },
    {
      "Istat": "097047",
      "Comune": "Margno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23832,
      "CodFisco": "E947",
      "Abitanti": 366,
      "Link": "http://www.comuni-italiani.it/097/047/"
    },
    {
      "Istat": "020032",
      "Comune": "Mariana Mantovana",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "E949",
      "Abitanti": 729,
      "Link": "http://www.comuni-italiani.it/020/032/"
    },
    {
      "Istat": "013143",
      "Comune": "Mariano Comense",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22066,
      "CodFisco": "E951",
      "Abitanti": 23890,
      "Link": "http://www.comuni-italiani.it/013/143/"
    },
    {
      "Istat": "031010",
      "Comune": "Mariano del Friuli",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "E952",
      "Abitanti": 1592,
      "Link": "http://www.comuni-italiani.it/031/010/"
    },
    {
      "Istat": "085008",
      "Comune": "Marianopoli",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "E953",
      "Abitanti": 2054,
      "Link": "http://www.comuni-italiani.it/085/008/"
    },
    {
      "Istat": "063042",
      "Comune": "Mariglianella",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "E954",
      "Abitanti": 7468,
      "Link": "http://www.comuni-italiani.it/063/042/"
    },
    {
      "Istat": "063043",
      "Comune": "Marigliano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80034,
      "CodFisco": "E955",
      "Abitanti": 30370,
      "Link": "http://www.comuni-italiani.it/063/043/"
    },
    {
      "Istat": "080045",
      "Comune": "Marina di Gioiosa Ionica",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89046,
      "CodFisco": "E956",
      "Abitanti": 6610,
      "Link": "http://www.comuni-italiani.it/080/045/"
    },
    {
      "Istat": "082046",
      "Comune": "Marineo",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90035,
      "CodFisco": "E957",
      "Abitanti": 6791,
      "Link": "http://www.comuni-italiani.it/082/046/"
    },
    {
      "Istat": "058057",
      "Comune": "Marino",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00047",
      "CodFisco": "E958",
      "Abitanti": 39976,
      "Link": "http://www.comuni-italiani.it/058/057/"
    },
    {
      "Istat": "021048",
      "Comune": "Marlengo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "E959",
      "Abitanti": 2524,
      "Link": "http://www.comuni-italiani.it/021/048/"
    },
    {
      "Istat": "047007",
      "Comune": "Marliana",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51010,
      "CodFisco": "E960",
      "Abitanti": 3233,
      "Link": "http://www.comuni-italiani.it/047/007/"
    },
    {
      "Istat": "017105",
      "Comune": "Marmentino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "E961",
      "Abitanti": 691,
      "Link": "http://www.comuni-italiani.it/017/105/"
    },
    {
      "Istat": "020033",
      "Comune": "Marmirolo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46045,
      "CodFisco": "E962",
      "Abitanti": 7768,
      "Link": "http://www.comuni-italiani.it/020/033/"
    },
    {
      "Istat": "004119",
      "Comune": "Marmora",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "E963",
      "Abitanti": 77,
      "Link": "http://www.comuni-italiani.it/004/119/"
    },
    {
      "Istat": "012098",
      "Comune": "Marnate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21050,
      "CodFisco": "E965",
      "Abitanti": 7211,
      "Link": "http://www.comuni-italiani.it/012/098/"
    },
    {
      "Istat": "017106",
      "Comune": "Marone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25054,
      "CodFisco": "E967",
      "Abitanti": 3328,
      "Link": "http://www.comuni-italiani.it/017/106/"
    },
    {
      "Istat": "080046",
      "Comune": "Maropati",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "E968",
      "Abitanti": 1611,
      "Link": "http://www.comuni-italiani.it/080/046/"
    },
    {
      "Istat": "024057",
      "Comune": "Marostica",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36063,
      "CodFisco": "E970",
      "Abitanti": 13824,
      "Link": "http://www.comuni-italiani.it/024/057/"
    },
    {
      "Istat": "048026",
      "Comune": "Marradi",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50034,
      "CodFisco": "E971",
      "Abitanti": 3303,
      "Link": "http://www.comuni-italiani.it/048/026/"
    },
    {
      "Istat": "095025",
      "Comune": "Marrubiu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09094",
      "CodFisco": "E972",
      "Abitanti": 4988,
      "Link": "http://www.comuni-italiani.it/095/025/"
    },
    {
      "Istat": "004120",
      "Comune": "Marsaglia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "E973",
      "Abitanti": 277,
      "Link": "http://www.comuni-italiani.it/004/120/"
    },
    {
      "Istat": "081011",
      "Comune": "Marsala",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91025,
      "CodFisco": "E974",
      "Abitanti": 82774,
      "Link": "http://www.comuni-italiani.it/081/011/"
    },
    {
      "Istat": "054027",
      "Comune": "Marsciano",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06055",
      "CodFisco": "E975",
      "Abitanti": 18770,
      "Link": "http://www.comuni-italiani.it/054/027/"
    },
    {
      "Istat": "076045",
      "Comune": "Marsico Nuovo",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85052,
      "CodFisco": "E976",
      "Abitanti": 4585,
      "Link": "http://www.comuni-italiani.it/076/045/"
    },
    {
      "Istat": "076046",
      "Comune": "Marsicovetere",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "E977",
      "Abitanti": 5411,
      "Link": "http://www.comuni-italiani.it/076/046/"
    },
    {
      "Istat": "056034",
      "Comune": "Marta",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "E978",
      "Abitanti": 3553,
      "Link": "http://www.comuni-italiani.it/056/034/"
    },
    {
      "Istat": "075040",
      "Comune": "Martano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73025,
      "CodFisco": "E979",
      "Abitanti": 9485,
      "Link": "http://www.comuni-italiani.it/075/040/"
    },
    {
      "Istat": "027021",
      "Comune": "Martellago",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30030,
      "CodFisco": "E980",
      "Abitanti": 21279,
      "Link": "http://www.comuni-italiani.it/027/021/"
    },
    {
      "Istat": "021049",
      "Comune": "Martello",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "E981",
      "Abitanti": 883,
      "Link": "http://www.comuni-italiani.it/021/049/"
    },
    {
      "Istat": "030057",
      "Comune": "Martignacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33035,
      "CodFisco": "E982",
      "Abitanti": 6752,
      "Link": "http://www.comuni-italiani.it/030/057/"
    },
    {
      "Istat": "019057",
      "Comune": "Martignana di Po",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26040,
      "CodFisco": "E983",
      "Abitanti": 1851,
      "Link": "http://www.comuni-italiani.it/019/057/"
    },
    {
      "Istat": "075041",
      "Comune": "Martignano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73020,
      "CodFisco": "E984",
      "Abitanti": 1748,
      "Link": "http://www.comuni-italiani.it/075/041/"
    },
    {
      "Istat": "073013",
      "Comune": "Martina Franca",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 74015,
      "CodFisco": "E986",
      "Abitanti": 49780,
      "Link": "http://www.comuni-italiani.it/073/013/"
    },
    {
      "Istat": "016133",
      "Comune": "Martinengo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24057,
      "CodFisco": "E987",
      "Abitanti": 10121,
      "Link": "http://www.comuni-italiani.it/016/133/"
    },
    {
      "Istat": "004121",
      "Comune": "Martiniana Po",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "E988",
      "Abitanti": 767,
      "Link": "http://www.comuni-italiani.it/004/121/"
    },
    {
      "Istat": "067047",
      "Comune": "Martinsicuro",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64014,
      "CodFisco": "E989",
      "Abitanti": 17078,
      "Link": "http://www.comuni-italiani.it/067/047/"
    },
    {
      "Istat": "079073",
      "Comune": "Martirano",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "E990",
      "Abitanti": 953,
      "Link": "http://www.comuni-italiani.it/079/073/"
    },
    {
      "Istat": "079074",
      "Comune": "Martirano Lombardo",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "E991",
      "Abitanti": 1214,
      "Link": "http://www.comuni-italiani.it/079/074/"
    },
    {
      "Istat": "090039",
      "Comune": "Martis",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "E992",
      "Abitanti": 560,
      "Link": "http://www.comuni-italiani.it/090/039/"
    },
    {
      "Istat": "080047",
      "Comune": "Martone",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "E993",
      "Abitanti": 569,
      "Link": "http://www.comuni-italiani.it/080/047/"
    },
    {
      "Istat": "098036",
      "Comune": "Marudo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26866,
      "CodFisco": "E994",
      "Abitanti": 1556,
      "Link": "http://www.comuni-italiani.it/098/036/"
    },
    {
      "Istat": "073014",
      "Comune": "Maruggio",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "E995",
      "Abitanti": 5514,
      "Link": "http://www.comuni-italiani.it/073/014/"
    },
    {
      "Istat": "037036",
      "Comune": "Marzabotto",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40043,
      "CodFisco": "B689",
      "Abitanti": 6833,
      "Link": "http://www.comuni-italiani.it/037/036/"
    },
    {
      "Istat": "018087",
      "Comune": "Marzano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "E999",
      "Abitanti": 1608,
      "Link": "http://www.comuni-italiani.it/018/087/"
    },
    {
      "Istat": "061050",
      "Comune": "Marzano Appio",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81035,
      "CodFisco": "E998",
      "Abitanti": 2373,
      "Link": "http://www.comuni-italiani.it/061/050/"
    },
    {
      "Istat": "064047",
      "Comune": "Marzano di Nola",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "E997",
      "Abitanti": 1722,
      "Link": "http://www.comuni-italiani.it/064/047/"
    },
    {
      "Istat": "078078",
      "Comune": "Marzi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "F001",
      "Abitanti": 993,
      "Link": "http://www.comuni-italiani.it/078/078/"
    },
    {
      "Istat": "012099",
      "Comune": "Marzio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "F002",
      "Abitanti": 308,
      "Link": "http://www.comuni-italiani.it/012/099/"
    },
    {
      "Istat": 107010,
      "Comune": "Masainas",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "M270",
      "Abitanti": 1353,
      "Link": "http://www.comuni-italiani.it/107/010/"
    },
    {
      "Istat": "015136",
      "Comune": "Masate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "F003",
      "Abitanti": 3363,
      "Link": "http://www.comuni-italiani.it/015/136/"
    },
    {
      "Istat": "087023",
      "Comune": "Mascali",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95016,
      "CodFisco": "F004",
      "Abitanti": 13864,
      "Link": "http://www.comuni-italiani.it/087/023/"
    },
    {
      "Istat": "087024",
      "Comune": "Mascalucia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "F005",
      "Abitanti": 29056,
      "Link": "http://www.comuni-italiani.it/087/024/"
    },
    {
      "Istat": "076047",
      "Comune": "Maschito",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85020,
      "CodFisco": "F006",
      "Abitanti": 1735,
      "Link": "http://www.comuni-italiani.it/076/047/"
    },
    {
      "Istat": "012100",
      "Comune": "Masciago Primo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "F007",
      "Abitanti": 289,
      "Link": "http://www.comuni-italiani.it/012/100/"
    },
    {
      "Istat": "026039",
      "Comune": "Maser",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31010,
      "CodFisco": "F009",
      "Abitanti": 4980,
      "Link": "http://www.comuni-italiani.it/026/039/"
    },
    {
      "Istat": 103042,
      "Comune": "Masera",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28855,
      "CodFisco": "F010",
      "Abitanti": 1521,
      "Link": "http://www.comuni-italiani.it/103/042/"
    },
    {
      "Istat": "028048",
      "Comune": "Maser‡ di Padova",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "F011",
      "Abitanti": 9071,
      "Link": "http://www.comuni-italiani.it/028/048/"
    },
    {
      "Istat": "026040",
      "Comune": "Maserada sul Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31052,
      "CodFisco": "F012",
      "Abitanti": 9394,
      "Link": "http://www.comuni-italiani.it/026/040/"
    },
    {
      "Istat": "028049",
      "Comune": "Masi",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35040,
      "CodFisco": "F013",
      "Abitanti": 1796,
      "Link": "http://www.comuni-italiani.it/028/049/"
    },
    {
      "Istat": "038012",
      "Comune": "Masi Torello",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44020,
      "CodFisco": "F016",
      "Abitanti": 2386,
      "Link": "http://www.comuni-italiani.it/038/012/"
    },
    {
      "Istat": "006091",
      "Comune": "Masio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15024,
      "CodFisco": "F015",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/006/091/"
    },
    {
      "Istat": "013144",
      "Comune": "Maslianico",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22026,
      "CodFisco": "F017",
      "Abitanti": 3387,
      "Link": "http://www.comuni-italiani.it/013/144/"
    },
    {
      "Istat": "024058",
      "Comune": "Mason Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36064,
      "CodFisco": "F019",
      "Abitanti": 3537,
      "Link": "http://www.comuni-italiani.it/024/058/"
    },
    {
      "Istat": "010032",
      "Comune": "Masone",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "F020",
      "Abitanti": 3795,
      "Link": "http://www.comuni-italiani.it/010/032/"
    },
    {
      "Istat": "045010",
      "Comune": "Massa",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0585",
      "CAP": 54100,
      "CodFisco": "F023",
      "Abitanti": 70973,
      "Link": "http://www.comuni-italiani.it/045/010/"
    },
    {
      "Istat": "066054",
      "Comune": "Massa d'Albe",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "F022",
      "Abitanti": 1571,
      "Link": "http://www.comuni-italiani.it/066/054/"
    },
    {
      "Istat": "063092",
      "Comune": "Massa di Somma",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "M289",
      "Abitanti": 5751,
      "Link": "http://www.comuni-italiani.it/063/092/"
    },
    {
      "Istat": "047008",
      "Comune": "Massa e Cozzile",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51010,
      "CodFisco": "F025",
      "Abitanti": 7872,
      "Link": "http://www.comuni-italiani.it/047/008/"
    },
    {
      "Istat": 109011,
      "Comune": "Massa Fermana",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63834,
      "CodFisco": "F021",
      "Abitanti": 988,
      "Link": "http://www.comuni-italiani.it/109/011/"
    },
    {
      "Istat": "038013",
      "Comune": "Massa Fiscaglia",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44025,
      "CodFisco": "F026",
      "Abitanti": 3621,
      "Link": "http://www.comuni-italiani.it/038/013/"
    },
    {
      "Istat": "039013",
      "Comune": "Massa Lombarda",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48024,
      "CodFisco": "F029",
      "Abitanti": 10700,
      "Link": "http://www.comuni-italiani.it/039/013/"
    },
    {
      "Istat": "063044",
      "Comune": "Massa Lubrense",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80061,
      "CodFisco": "F030",
      "Abitanti": 13985,
      "Link": "http://www.comuni-italiani.it/063/044/"
    },
    {
      "Istat": "053015",
      "Comune": "Massa Marittima",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0566",
      "CAP": 58024,
      "CodFisco": "F032",
      "Abitanti": 8781,
      "Link": "http://www.comuni-italiani.it/053/015/"
    },
    {
      "Istat": "054028",
      "Comune": "Massa Martana",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06056",
      "CodFisco": "F024",
      "Abitanti": 3947,
      "Link": "http://www.comuni-italiani.it/054/028/"
    },
    {
      "Istat": "073015",
      "Comune": "Massafra",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74016,
      "CodFisco": "F027",
      "Abitanti": 32448,
      "Link": "http://www.comuni-italiani.it/073/015/"
    },
    {
      "Istat": "098037",
      "Comune": "Massalengo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26815,
      "CodFisco": "F028",
      "Abitanti": 4257,
      "Link": "http://www.comuni-italiani.it/098/037/"
    },
    {
      "Istat": "028050",
      "Comune": "Massanzago",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "F033",
      "Abitanti": 5885,
      "Link": "http://www.comuni-italiani.it/028/050/"
    },
    {
      "Istat": "046018",
      "Comune": "Massarosa",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55054,
      "CodFisco": "F035",
      "Abitanti": 23004,
      "Link": "http://www.comuni-italiani.it/046/018/"
    },
    {
      "Istat": "096031",
      "Comune": "Massazza",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13873,
      "CodFisco": "F037",
      "Abitanti": 553,
      "Link": "http://www.comuni-italiani.it/096/031/"
    },
    {
      "Istat": "001145",
      "Comune": "Massello",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "F041",
      "Abitanti": 61,
      "Link": "http://www.comuni-italiani.it/001/145/"
    },
    {
      "Istat": "096032",
      "Comune": "Masserano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13866,
      "CodFisco": "F042",
      "Abitanti": 2264,
      "Link": "http://www.comuni-italiani.it/096/032/"
    },
    {
      "Istat": "044029",
      "Comune": "Massignano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63061,
      "CodFisco": "F044",
      "Abitanti": 1684,
      "Link": "http://www.comuni-italiani.it/044/029/"
    },
    {
      "Istat": "022112",
      "Comune": "Massimeno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38086,
      "CodFisco": "F045",
      "Abitanti": 117,
      "Link": "http://www.comuni-italiani.it/022/112/"
    },
    {
      "Istat": "009037",
      "Comune": "Massimino",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 12071,
      "CodFisco": "F046",
      "Abitanti": 127,
      "Link": "http://www.comuni-italiani.it/009/037/"
    },
    {
      "Istat": "003093",
      "Comune": "Massino Visconti",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28040,
      "CodFisco": "F047",
      "Abitanti": 1126,
      "Link": "http://www.comuni-italiani.it/003/093/"
    },
    {
      "Istat": 103043,
      "Comune": "Massiola",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28895,
      "CodFisco": "F048",
      "Abitanti": 143,
      "Link": "http://www.comuni-italiani.it/103/043/"
    },
    {
      "Istat": "095026",
      "Comune": "Masullas",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "F050",
      "Abitanti": 1152,
      "Link": "http://www.comuni-italiani.it/095/026/"
    },
    {
      "Istat": "043024",
      "Comune": "Matelica",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62024,
      "CodFisco": "F051",
      "Abitanti": 10325,
      "Link": "http://www.comuni-italiani.it/043/024/"
    },
    {
      "Istat": "077014",
      "Comune": "Matera",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75100,
      "CodFisco": "F052",
      "Abitanti": 60818,
      "Link": "http://www.comuni-italiani.it/077/014/"
    },
    {
      "Istat": "001146",
      "Comune": "Mathi",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10075,
      "CodFisco": "F053",
      "Abitanti": 4074,
      "Link": "http://www.comuni-italiani.it/001/146/"
    },
    {
      "Istat": "075042",
      "Comune": "Matino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73046,
      "CodFisco": "F054",
      "Abitanti": 11795,
      "Link": "http://www.comuni-italiani.it/075/042/"
    },
    {
      "Istat": "070037",
      "Comune": "Matrice",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "F055",
      "Abitanti": 1114,
      "Link": "http://www.comuni-italiani.it/070/037/"
    },
    {
      "Istat": "001147",
      "Comune": "Mattie",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "F058",
      "Abitanti": 715,
      "Link": "http://www.comuni-italiani.it/001/147/"
    },
    {
      "Istat": "071031",
      "Comune": "Mattinata",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71030,
      "CodFisco": "F059",
      "Abitanti": 6523,
      "Link": "http://www.comuni-italiani.it/071/031/"
    },
    {
      "Istat": "081012",
      "Comune": "Mazara del Vallo",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91026,
      "CodFisco": "F061",
      "Abitanti": 51492,
      "Link": "http://www.comuni-italiani.it/081/012/"
    },
    {
      "Istat": "017107",
      "Comune": "Mazzano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "F063",
      "Abitanti": 11713,
      "Link": "http://www.comuni-italiani.it/017/107/"
    },
    {
      "Istat": "058058",
      "Comune": "Mazzano Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "F064",
      "Abitanti": 3064,
      "Link": "http://www.comuni-italiani.it/058/058/"
    },
    {
      "Istat": "085009",
      "Comune": "Mazzarino",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93013,
      "CodFisco": "F065",
      "Abitanti": 11946,
      "Link": "http://www.comuni-italiani.it/085/009/"
    },
    {
      "Istat": "083046",
      "Comune": "Mazzarr‡ Sant'Andrea",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98056,
      "CodFisco": "F066",
      "Abitanti": 1589,
      "Link": "http://www.comuni-italiani.it/083/046/"
    },
    {
      "Istat": "087056",
      "Comune": "Mazzarrone",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95040,
      "CodFisco": "M271",
      "Abitanti": 4001,
      "Link": "http://www.comuni-italiani.it/087/056/"
    },
    {
      "Istat": "001148",
      "Comune": "MazzË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10035,
      "CodFisco": "F067",
      "Abitanti": 4177,
      "Link": "http://www.comuni-italiani.it/001/148/"
    },
    {
      "Istat": "022113",
      "Comune": "Mazzin",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "F068",
      "Abitanti": 498,
      "Link": "http://www.comuni-italiani.it/022/113/"
    },
    {
      "Istat": "014040",
      "Comune": "Mazzo di Valtellina",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "F070",
      "Abitanti": 1061,
      "Link": "http://www.comuni-italiani.it/014/040/"
    },
    {
      "Istat": "001149",
      "Comune": "Meana di Susa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "F074",
      "Abitanti": 903,
      "Link": "http://www.comuni-italiani.it/001/149/"
    },
    {
      "Istat": "091047",
      "Comune": "Meana Sardo",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08030",
      "CodFisco": "F073",
      "Abitanti": 1942,
      "Link": "http://www.comuni-italiani.it/091/047/"
    },
    {
      "Istat": 108030,
      "Comune": "Meda",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20821,
      "CodFisco": "F078",
      "Abitanti": 23221,
      "Link": "http://www.comuni-italiani.it/108/030/"
    },
    {
      "Istat": "018088",
      "Comune": "Mede",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27035,
      "CodFisco": "F080",
      "Abitanti": 7085,
      "Link": "http://www.comuni-italiani.it/018/088/"
    },
    {
      "Istat": "031011",
      "Comune": "Medea",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34076,
      "CodFisco": "F081",
      "Abitanti": 979,
      "Link": "http://www.comuni-italiani.it/031/011/"
    },
    {
      "Istat": "034020",
      "Comune": "Medesano",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43014,
      "CodFisco": "F082",
      "Abitanti": 10749,
      "Link": "http://www.comuni-italiani.it/034/020/"
    },
    {
      "Istat": "037037",
      "Comune": "Medicina",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40059,
      "CodFisco": "F083",
      "Abitanti": 16675,
      "Link": "http://www.comuni-italiani.it/037/037/"
    },
    {
      "Istat": "015139",
      "Comune": "Mediglia",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "F084",
      "Abitanti": 12187,
      "Link": "http://www.comuni-italiani.it/015/139/"
    },
    {
      "Istat": "016250",
      "Comune": "Medolago",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "F085",
      "Abitanti": 2388,
      "Link": "http://www.comuni-italiani.it/016/250/"
    },
    {
      "Istat": "020034",
      "Comune": "Medole",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46046,
      "CodFisco": "F086",
      "Abitanti": 4026,
      "Link": "http://www.comuni-italiani.it/020/034/"
    },
    {
      "Istat": "036021",
      "Comune": "Medolla",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41036,
      "CodFisco": "F087",
      "Abitanti": 6331,
      "Link": "http://www.comuni-italiani.it/036/021/"
    },
    {
      "Istat": "026041",
      "Comune": "Meduna di Livenza",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "F088",
      "Abitanti": 2969,
      "Link": "http://www.comuni-italiani.it/026/041/"
    },
    {
      "Istat": "093026",
      "Comune": "Meduno",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33092,
      "CodFisco": "F089",
      "Abitanti": 1701,
      "Link": "http://www.comuni-italiani.it/093/026/"
    },
    {
      "Istat": "028051",
      "Comune": "Megliadino San Fidenzio",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "F091",
      "Abitanti": 1993,
      "Link": "http://www.comuni-italiani.it/028/051/"
    },
    {
      "Istat": "028052",
      "Comune": "Megliadino San Vitale",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "F092",
      "Abitanti": 2051,
      "Link": "http://www.comuni-italiani.it/028/052/"
    },
    {
      "Istat": "003095",
      "Comune": "Meina",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28046,
      "CodFisco": "F093",
      "Abitanti": 2584,
      "Link": "http://www.comuni-italiani.it/003/095/"
    },
    {
      "Istat": "025034",
      "Comune": "Mel",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32026,
      "CodFisco": "F094",
      "Abitanti": 6304,
      "Link": "http://www.comuni-italiani.it/025/034/"
    },
    {
      "Istat": "029032",
      "Comune": "Melara",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45037,
      "CodFisco": "F095",
      "Abitanti": 1914,
      "Link": "http://www.comuni-italiani.it/029/032/"
    },
    {
      "Istat": "006092",
      "Comune": "Melazzo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "F096",
      "Abitanti": 1327,
      "Link": "http://www.comuni-italiani.it/006/092/"
    },
    {
      "Istat": "040019",
      "Comune": "Meldola",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47014,
      "CodFisco": "F097",
      "Abitanti": 10193,
      "Link": "http://www.comuni-italiani.it/040/019/"
    },
    {
      "Istat": "010033",
      "Comune": "Mele",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "F098",
      "Abitanti": 2721,
      "Link": "http://www.comuni-italiani.it/010/033/"
    },
    {
      "Istat": "015140",
      "Comune": "Melegnano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20077,
      "CodFisco": "F100",
      "Abitanti": 17260,
      "Link": "http://www.comuni-italiani.it/015/140/"
    },
    {
      "Istat": "075043",
      "Comune": "Melendugno",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73026,
      "CodFisco": "F101",
      "Abitanti": 9838,
      "Link": "http://www.comuni-italiani.it/075/043/"
    },
    {
      "Istat": "098038",
      "Comune": "Meleti",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26843,
      "CodFisco": "F102",
      "Abitanti": 475,
      "Link": "http://www.comuni-italiani.it/098/038/"
    },
    {
      "Istat": "076048",
      "Comune": "Melfi",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85025,
      "CodFisco": "F104",
      "Abitanti": 17554,
      "Link": "http://www.comuni-italiani.it/076/048/"
    },
    {
      "Istat": "080048",
      "Comune": "Melicucc‡",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "F105",
      "Abitanti": 1004,
      "Link": "http://www.comuni-italiani.it/080/048/"
    },
    {
      "Istat": "080049",
      "Comune": "Melicucco",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "F106",
      "Abitanti": 5206,
      "Link": "http://www.comuni-italiani.it/080/049/"
    },
    {
      "Istat": "089012",
      "Comune": "Melilli",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "F107",
      "Abitanti": 13304,
      "Link": "http://www.comuni-italiani.it/089/012/"
    },
    {
      "Istat": 101014,
      "Comune": "Melissa",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88814,
      "CodFisco": "F108",
      "Abitanti": 3609,
      "Link": "http://www.comuni-italiani.it/101/014/"
    },
    {
      "Istat": "075044",
      "Comune": "Melissano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "F109",
      "Abitanti": 7357,
      "Link": "http://www.comuni-italiani.it/075/044/"
    },
    {
      "Istat": "063045",
      "Comune": "Melito di Napoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80017,
      "CodFisco": "F111",
      "Abitanti": 38163,
      "Link": "http://www.comuni-italiani.it/063/045/"
    },
    {
      "Istat": "080050",
      "Comune": "Melito di Porto Salvo",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89063,
      "CodFisco": "F112",
      "Abitanti": 11594,
      "Link": "http://www.comuni-italiani.it/080/050/"
    },
    {
      "Istat": "064048",
      "Comune": "Melito Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "F110",
      "Abitanti": 1968,
      "Link": "http://www.comuni-italiani.it/064/048/"
    },
    {
      "Istat": "062039",
      "Comune": "Melizzano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "F113",
      "Abitanti": 1908,
      "Link": "http://www.comuni-italiani.it/062/039/"
    },
    {
      "Istat": "004122",
      "Comune": "Melle",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "F114",
      "Abitanti": 328,
      "Link": "http://www.comuni-italiani.it/004/122/"
    },
    {
      "Istat": "014041",
      "Comune": "Mello",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "F115",
      "Abitanti": 996,
      "Link": "http://www.comuni-italiani.it/014/041/"
    },
    {
      "Istat": "075045",
      "Comune": "Melpignano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "F117",
      "Abitanti": 2217,
      "Link": "http://www.comuni-italiani.it/075/045/"
    },
    {
      "Istat": "021050",
      "Comune": "Meltina",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39010,
      "CodFisco": "F118",
      "Abitanti": 1625,
      "Link": "http://www.comuni-italiani.it/021/050/"
    },
    {
      "Istat": "015142",
      "Comune": "Melzo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20066,
      "CodFisco": "F119",
      "Abitanti": 18513,
      "Link": "http://www.comuni-italiani.it/015/142/"
    },
    {
      "Istat": "013145",
      "Comune": "Menaggio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22017,
      "CodFisco": "F120",
      "Abitanti": 3273,
      "Link": "http://www.comuni-italiani.it/013/145/"
    },
    {
      "Istat": "014042",
      "Comune": "Menarola",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "F121",
      "Abitanti": 46,
      "Link": "http://www.comuni-italiani.it/014/042/"
    },
    {
      "Istat": "018089",
      "Comune": "Menconico",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "F122",
      "Abitanti": 400,
      "Link": "http://www.comuni-italiani.it/018/089/"
    },
    {
      "Istat": "008034",
      "Comune": "Mendatica",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18025,
      "CodFisco": "F123",
      "Abitanti": 226,
      "Link": "http://www.comuni-italiani.it/008/034/"
    },
    {
      "Istat": "078079",
      "Comune": "Mendicino",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "F125",
      "Abitanti": 9462,
      "Link": "http://www.comuni-italiani.it/078/079/"
    },
    {
      "Istat": "084023",
      "Comune": "Menfi",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92013,
      "CodFisco": "F126",
      "Abitanti": 12812,
      "Link": "http://www.comuni-italiani.it/084/023/"
    },
    {
      "Istat": "058059",
      "Comune": "Mentana",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00013",
      "CodFisco": "F127",
      "Abitanti": 21602,
      "Link": "http://www.comuni-italiani.it/058/059/"
    },
    {
      "Istat": "027022",
      "Comune": "Meolo",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "F130",
      "Abitanti": 6566,
      "Link": "http://www.comuni-italiani.it/027/022/"
    },
    {
      "Istat": "006093",
      "Comune": "Merana",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "F131",
      "Abitanti": 190,
      "Link": "http://www.comuni-italiani.it/006/093/"
    },
    {
      "Istat": "021051",
      "Comune": "Merano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39012,
      "CodFisco": "F132",
      "Abitanti": 38229,
      "Link": "http://www.comuni-italiani.it/021/051/"
    },
    {
      "Istat": "097048",
      "Comune": "Merate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23807,
      "CodFisco": "F133",
      "Abitanti": 14943,
      "Link": "http://www.comuni-italiani.it/097/048/"
    },
    {
      "Istat": "012101",
      "Comune": "Mercallo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21020,
      "CodFisco": "F134",
      "Abitanti": 1816,
      "Link": "http://www.comuni-italiani.it/012/101/"
    },
    {
      "Istat": "041025",
      "Comune": "Mercatello sul Metauro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61040,
      "CodFisco": "F135",
      "Abitanti": 1481,
      "Link": "http://www.comuni-italiani.it/041/025/"
    },
    {
      "Istat": "041026",
      "Comune": "Mercatino Conca",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0541",
      "CAP": 61013,
      "CodFisco": "F136",
      "Abitanti": 1131,
      "Link": "http://www.comuni-italiani.it/041/026/"
    },
    {
      "Istat": "065067",
      "Comune": "Mercato San Severino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84085,
      "CodFisco": "F138",
      "Abitanti": 21814,
      "Link": "http://www.comuni-italiani.it/065/067/"
    },
    {
      "Istat": "040020",
      "Comune": "Mercato Saraceno",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": 47025,
      "CodFisco": "F139",
      "Abitanti": 7087,
      "Link": "http://www.comuni-italiani.it/040/020/"
    },
    {
      "Istat": "001150",
      "Comune": "Mercenasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "F140",
      "Abitanti": 1279,
      "Link": "http://www.comuni-italiani.it/001/150/"
    },
    {
      "Istat": "064049",
      "Comune": "Mercogliano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83013,
      "CodFisco": "F141",
      "Abitanti": 12471,
      "Link": "http://www.comuni-italiani.it/064/049/"
    },
    {
      "Istat": "030058",
      "Comune": "Mereto di Tomba",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33036,
      "CodFisco": "F144",
      "Abitanti": 2736,
      "Link": "http://www.comuni-italiani.it/030/058/"
    },
    {
      "Istat": "042024",
      "Comune": "Mergo",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "F145",
      "Abitanti": 1098,
      "Link": "http://www.comuni-italiani.it/042/024/"
    },
    {
      "Istat": 103044,
      "Comune": "Mergozzo",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28802,
      "CodFisco": "F146",
      "Abitanti": 2167,
      "Link": "http://www.comuni-italiani.it/103/044/"
    },
    {
      "Istat": "083047",
      "Comune": "MerÏ",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "F147",
      "Abitanti": 2407,
      "Link": "http://www.comuni-italiani.it/083/047/"
    },
    {
      "Istat": "028053",
      "Comune": "Merlara",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "F148",
      "Abitanti": 2856,
      "Link": "http://www.comuni-italiani.it/028/053/"
    },
    {
      "Istat": "098039",
      "Comune": "Merlino",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26833,
      "CodFisco": "F149",
      "Abitanti": 1778,
      "Link": "http://www.comuni-italiani.it/098/039/"
    },
    {
      "Istat": "013147",
      "Comune": "Merone",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22046,
      "CodFisco": "F151",
      "Abitanti": 4124,
      "Link": "http://www.comuni-italiani.it/013/147/"
    },
    {
      "Istat": "074010",
      "Comune": "Mesagne",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72023,
      "CodFisco": "F152",
      "Abitanti": 27860,
      "Link": "http://www.comuni-italiani.it/074/010/"
    },
    {
      "Istat": "014043",
      "Comune": "Mese",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "F153",
      "Abitanti": 1740,
      "Link": "http://www.comuni-italiani.it/014/043/"
    },
    {
      "Istat": "012102",
      "Comune": "Mesenzana",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "F154",
      "Abitanti": 1473,
      "Link": "http://www.comuni-italiani.it/012/102/"
    },
    {
      "Istat": "015144",
      "Comune": "Mesero",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "F155",
      "Abitanti": 3915,
      "Link": "http://www.comuni-italiani.it/015/144/"
    },
    {
      "Istat": "038014",
      "Comune": "Mesola",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44026,
      "CodFisco": "F156",
      "Abitanti": 7190,
      "Link": "http://www.comuni-italiani.it/038/014/"
    },
    {
      "Istat": 101015,
      "Comune": "Mesoraca",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88838,
      "CodFisco": "F157",
      "Abitanti": 6787,
      "Link": "http://www.comuni-italiani.it/101/015/"
    },
    {
      "Istat": "083048",
      "Comune": "Messina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": "981xx",
      "CodFisco": "F158",
      "Abitanti": 242503,
      "Link": "http://www.comuni-italiani.it/083/048/"
    },
    {
      "Istat": "028054",
      "Comune": "Mestrino",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35035,
      "CodFisco": "F161",
      "Abitanti": 10986,
      "Link": "http://www.comuni-italiani.it/028/054/"
    },
    {
      "Istat": "063046",
      "Comune": "Meta",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80062,
      "CodFisco": "F162",
      "Abitanti": 8041,
      "Link": "http://www.comuni-italiani.it/063/046/"
    },
    {
      "Istat": "001151",
      "Comune": "Meugliano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "F164",
      "Abitanti": 96,
      "Link": "http://www.comuni-italiani.it/001/151/"
    },
    {
      "Istat": 108031,
      "Comune": "Mezzago",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20883,
      "CodFisco": "F165",
      "Abitanti": 4163,
      "Link": "http://www.comuni-italiani.it/108/031/"
    },
    {
      "Istat": "022114",
      "Comune": "Mezzana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "F168",
      "Abitanti": 872,
      "Link": "http://www.comuni-italiani.it/022/114/"
    },
    {
      "Istat": "018090",
      "Comune": "Mezzana Bigli",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "F170",
      "Abitanti": 1144,
      "Link": "http://www.comuni-italiani.it/018/090/"
    },
    {
      "Istat": "096033",
      "Comune": "Mezzana Mortigliengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13831,
      "CodFisco": "F167",
      "Abitanti": 559,
      "Link": "http://www.comuni-italiani.it/096/033/"
    },
    {
      "Istat": "018091",
      "Comune": "Mezzana Rabattone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27030,
      "CodFisco": "F171",
      "Abitanti": 514,
      "Link": "http://www.comuni-italiani.it/018/091/"
    },
    {
      "Istat": "023047",
      "Comune": "Mezzane di Sotto",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "F172",
      "Abitanti": 2419,
      "Link": "http://www.comuni-italiani.it/023/047/"
    },
    {
      "Istat": "010034",
      "Comune": "Mezzanego",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16046,
      "CodFisco": "F173",
      "Abitanti": 1648,
      "Link": "http://www.comuni-italiani.it/010/034/"
    },
    {
      "Istat": "034021",
      "Comune": "Mezzani",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43055,
      "CodFisco": "F174",
      "Abitanti": 3449,
      "Link": "http://www.comuni-italiani.it/034/021/"
    },
    {
      "Istat": "018092",
      "Comune": "Mezzanino",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "F175",
      "Abitanti": 1509,
      "Link": "http://www.comuni-italiani.it/018/092/"
    },
    {
      "Istat": "022115",
      "Comune": "Mezzano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38050,
      "CodFisco": "F176",
      "Abitanti": 1635,
      "Link": "http://www.comuni-italiani.it/022/115/"
    },
    {
      "Istat": "013148",
      "Comune": "Mezzegra",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "F181",
      "Abitanti": 1043,
      "Link": "http://www.comuni-italiani.it/013/148/"
    },
    {
      "Istat": "001152",
      "Comune": "Mezzenile",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "F182",
      "Abitanti": 844,
      "Link": "http://www.comuni-italiani.it/001/152/"
    },
    {
      "Istat": "022116",
      "Comune": "Mezzocorona",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38016,
      "CodFisco": "F183",
      "Abitanti": 5179,
      "Link": "http://www.comuni-italiani.it/022/116/"
    },
    {
      "Istat": "082047",
      "Comune": "Mezzojuso",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "F184",
      "Abitanti": 2985,
      "Link": "http://www.comuni-italiani.it/082/047/"
    },
    {
      "Istat": "016134",
      "Comune": "Mezzoldo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "F186",
      "Abitanti": 196,
      "Link": "http://www.comuni-italiani.it/016/134/"
    },
    {
      "Istat": "022117",
      "Comune": "Mezzolombardo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38017,
      "CodFisco": "F187",
      "Abitanti": 6914,
      "Link": "http://www.comuni-italiani.it/022/117/"
    },
    {
      "Istat": "003097",
      "Comune": "Mezzomerico",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28040,
      "CodFisco": "F188",
      "Abitanti": 1175,
      "Link": "http://www.comuni-italiani.it/003/097/"
    },
    {
      "Istat": "096034",
      "Comune": "Miagliano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13816,
      "CodFisco": "F189",
      "Abitanti": 665,
      "Link": "http://www.comuni-italiani.it/096/034/"
    },
    {
      "Istat": "026042",
      "Comune": "Miane",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31050,
      "CodFisco": "F190",
      "Abitanti": 3545,
      "Link": "http://www.comuni-italiani.it/026/042/"
    },
    {
      "Istat": "003098",
      "Comune": "Miasino",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "F191",
      "Abitanti": 915,
      "Link": "http://www.comuni-italiani.it/003/098/"
    },
    {
      "Istat": 103045,
      "Comune": "Miazzina",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28817,
      "CodFisco": "F192",
      "Abitanti": 418,
      "Link": "http://www.comuni-italiani.it/103/045/"
    },
    {
      "Istat": "057037",
      "Comune": "Micigliano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "F193",
      "Abitanti": 142,
      "Link": "http://www.comuni-italiani.it/057/037/"
    },
    {
      "Istat": "075046",
      "Comune": "Miggiano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73035,
      "CodFisco": "F194",
      "Abitanti": 3685,
      "Link": "http://www.comuni-italiani.it/075/046/"
    },
    {
      "Istat": "069050",
      "Comune": "Miglianico",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "F196",
      "Abitanti": 4846,
      "Link": "http://www.comuni-italiani.it/069/050/"
    },
    {
      "Istat": "038015",
      "Comune": "Migliarino",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44027,
      "CodFisco": "F198",
      "Abitanti": 3739,
      "Link": "http://www.comuni-italiani.it/038/015/"
    },
    {
      "Istat": "038026",
      "Comune": "Migliaro",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44020,
      "CodFisco": "F199",
      "Abitanti": 2239,
      "Link": "http://www.comuni-italiani.it/038/026/"
    },
    {
      "Istat": "079077",
      "Comune": "Miglierina",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88040,
      "CodFisco": "F200",
      "Abitanti": 811,
      "Link": "http://www.comuni-italiani.it/079/077/"
    },
    {
      "Istat": "077015",
      "Comune": "Miglionico",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "F201",
      "Abitanti": 2573,
      "Link": "http://www.comuni-italiani.it/077/015/"
    },
    {
      "Istat": "010035",
      "Comune": "Mignanego",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16018,
      "CodFisco": "F202",
      "Abitanti": 3764,
      "Link": "http://www.comuni-italiani.it/010/035/"
    },
    {
      "Istat": "061051",
      "Comune": "Mignano Monte Lungo",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81049,
      "CodFisco": "F203",
      "Abitanti": 3290,
      "Link": "http://www.comuni-italiani.it/061/051/"
    },
    {
      "Istat": "015146",
      "Comune": "Milano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": "201xx",
      "CodFisco": "F205",
      "Abitanti": 1324110,
      "Link": "http://www.comuni-italiani.it/015/146/"
    },
    {
      "Istat": "083049",
      "Comune": "Milazzo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98057,
      "CodFisco": "F206",
      "Abitanti": 32601,
      "Link": "http://www.comuni-italiani.it/083/049/"
    },
    {
      "Istat": "085010",
      "Comune": "Milena",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "E618",
      "Abitanti": 3201,
      "Link": "http://www.comuni-italiani.it/085/010/"
    },
    {
      "Istat": 102021,
      "Comune": "Mileto",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89852,
      "CodFisco": "F207",
      "Abitanti": 7017,
      "Link": "http://www.comuni-italiani.it/102/021/"
    },
    {
      "Istat": "095027",
      "Comune": "Milis",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "F208",
      "Abitanti": 1615,
      "Link": "http://www.comuni-italiani.it/095/027/"
    },
    {
      "Istat": "087025",
      "Comune": "Militello in Val di Catania",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95043,
      "CodFisco": "F209",
      "Abitanti": 7933,
      "Link": "http://www.comuni-italiani.it/087/025/"
    },
    {
      "Istat": "083050",
      "Comune": "Militello Rosmarino",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "F210",
      "Abitanti": 1337,
      "Link": "http://www.comuni-italiani.it/083/050/"
    },
    {
      "Istat": "009038",
      "Comune": "Millesimo",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17017,
      "CodFisco": "F213",
      "Abitanti": 3516,
      "Link": "http://www.comuni-italiani.it/009/038/"
    },
    {
      "Istat": "087026",
      "Comune": "Milo",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95010,
      "CodFisco": "F214",
      "Abitanti": 1089,
      "Link": "http://www.comuni-italiani.it/087/026/"
    },
    {
      "Istat": "017108",
      "Comune": "Milzano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "F216",
      "Abitanti": 1815,
      "Link": "http://www.comuni-italiani.it/017/108/"
    },
    {
      "Istat": "087027",
      "Comune": "Mineo",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95044,
      "CodFisco": "F217",
      "Abitanti": 5349,
      "Link": "http://www.comuni-italiani.it/087/027/"
    },
    {
      "Istat": "023048",
      "Comune": "Minerbe",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37046,
      "CodFisco": "F218",
      "Abitanti": 4759,
      "Link": "http://www.comuni-italiani.it/023/048/"
    },
    {
      "Istat": "037038",
      "Comune": "Minerbio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40061,
      "CodFisco": "F219",
      "Abitanti": 8700,
      "Link": "http://www.comuni-italiani.it/037/038/"
    },
    {
      "Istat": "075047",
      "Comune": "Minervino di Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73027,
      "CodFisco": "F221",
      "Abitanti": 3787,
      "Link": "http://www.comuni-italiani.it/075/047/"
    },
    {
      "Istat": 110006,
      "Comune": "Minervino Murge",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76013,
      "CodFisco": "F220",
      "Abitanti": 9598,
      "Link": "http://www.comuni-italiani.it/110/006/"
    },
    {
      "Istat": "065068",
      "Comune": "Minori",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "F223",
      "Abitanti": 2836,
      "Link": "http://www.comuni-italiani.it/065/068/"
    },
    {
      "Istat": "059014",
      "Comune": "Minturno",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04026",
      "CodFisco": "F224",
      "Abitanti": 19059,
      "Link": "http://www.comuni-italiani.it/059/014/"
    },
    {
      "Istat": "046019",
      "Comune": "Minucciano",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55034,
      "CodFisco": "F225",
      "Abitanti": 2278,
      "Link": "http://www.comuni-italiani.it/046/019/"
    },
    {
      "Istat": "009039",
      "Comune": "Mioglia",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17040,
      "CodFisco": "F226",
      "Abitanti": 546,
      "Link": "http://www.comuni-italiani.it/009/039/"
    },
    {
      "Istat": "027023",
      "Comune": "Mira",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30034,
      "CodFisco": "F229",
      "Abitanti": 38952,
      "Link": "http://www.comuni-italiani.it/027/023/"
    },
    {
      "Istat": "064050",
      "Comune": "Mirabella Eclano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83036,
      "CodFisco": "F230",
      "Abitanti": 8042,
      "Link": "http://www.comuni-italiani.it/064/050/"
    },
    {
      "Istat": "087028",
      "Comune": "Mirabella Imbaccari",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95040,
      "CodFisco": "F231",
      "Abitanti": 5435,
      "Link": "http://www.comuni-italiani.it/087/028/"
    },
    {
      "Istat": "038016",
      "Comune": "Mirabello",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44043,
      "CodFisco": "F235",
      "Abitanti": 3541,
      "Link": "http://www.comuni-italiani.it/038/016/"
    },
    {
      "Istat": "006094",
      "Comune": "Mirabello Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "F232",
      "Abitanti": 1399,
      "Link": "http://www.comuni-italiani.it/006/094/"
    },
    {
      "Istat": "070038",
      "Comune": "Mirabello Sannitico",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "F233",
      "Abitanti": 2165,
      "Link": "http://www.comuni-italiani.it/070/038/"
    },
    {
      "Istat": "018093",
      "Comune": "Miradolo Terme",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "F238",
      "Abitanti": 3817,
      "Link": "http://www.comuni-italiani.it/018/093/"
    },
    {
      "Istat": "094027",
      "Comune": "Miranda",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "F239",
      "Abitanti": 1070,
      "Link": "http://www.comuni-italiani.it/094/027/"
    },
    {
      "Istat": "036022",
      "Comune": "Mirandola",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41037,
      "CodFisco": "F240",
      "Abitanti": 24602,
      "Link": "http://www.comuni-italiani.it/036/022/"
    },
    {
      "Istat": "027024",
      "Comune": "Mirano",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30035,
      "CodFisco": "F241",
      "Abitanti": 27077,
      "Link": "http://www.comuni-italiani.it/027/024/"
    },
    {
      "Istat": "083051",
      "Comune": "Mirto",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "F242",
      "Abitanti": 1016,
      "Link": "http://www.comuni-italiani.it/083/051/"
    },
    {
      "Istat": "099005",
      "Comune": "Misano Adriatico",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47843,
      "CodFisco": "F244",
      "Abitanti": 12359,
      "Link": "http://www.comuni-italiani.it/099/005/"
    },
    {
      "Istat": "016135",
      "Comune": "Misano di Gera d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "F243",
      "Abitanti": 2986,
      "Link": "http://www.comuni-italiani.it/016/135/"
    },
    {
      "Istat": "082048",
      "Comune": "Misilmeri",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90036,
      "CodFisco": "F246",
      "Abitanti": 28074,
      "Link": "http://www.comuni-italiani.it/082/048/"
    },
    {
      "Istat": 108032,
      "Comune": "Misinto",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20826,
      "CodFisco": "F247",
      "Abitanti": 5078,
      "Link": "http://www.comuni-italiani.it/108/032/"
    },
    {
      "Istat": "097049",
      "Comune": "Missaglia",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23873,
      "CodFisco": "F248",
      "Abitanti": 8555,
      "Link": "http://www.comuni-italiani.it/097/049/"
    },
    {
      "Istat": "076049",
      "Comune": "Missanello",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "F249",
      "Abitanti": 555,
      "Link": "http://www.comuni-italiani.it/076/049/"
    },
    {
      "Istat": "087029",
      "Comune": "Misterbianco",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95045,
      "CodFisco": "F250",
      "Abitanti": 49424,
      "Link": "http://www.comuni-italiani.it/087/029/"
    },
    {
      "Istat": "083052",
      "Comune": "Mistretta",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98073,
      "CodFisco": "F251",
      "Abitanti": 5079,
      "Link": "http://www.comuni-italiani.it/083/052/"
    },
    {
      "Istat": "005063",
      "Comune": "Moasca",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14050,
      "CodFisco": "F254",
      "Abitanti": 462,
      "Link": "http://www.comuni-italiani.it/005/063/"
    },
    {
      "Istat": "010036",
      "Comune": "Moconesi",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16047,
      "CodFisco": "F256",
      "Abitanti": 2738,
      "Link": "http://www.comuni-italiani.it/010/036/"
    },
    {
      "Istat": "036023",
      "Comune": "Modena",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": "4112x",
      "CodFisco": "F257",
      "Abitanti": 184663,
      "Link": "http://www.comuni-italiani.it/036/023/"
    },
    {
      "Istat": "088006",
      "Comune": "Modica",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97015,
      "CodFisco": "F258",
      "Abitanti": 55196,
      "Link": "http://www.comuni-italiani.it/088/006/"
    },
    {
      "Istat": "040022",
      "Comune": "Modigliana",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 47015,
      "CodFisco": "F259",
      "Abitanti": 4815,
      "Link": "http://www.comuni-italiani.it/040/022/"
    },
    {
      "Istat": "095084",
      "Comune": "Modolo",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08019",
      "CodFisco": "F261",
      "Abitanti": 170,
      "Link": "http://www.comuni-italiani.it/095/084/"
    },
    {
      "Istat": "072027",
      "Comune": "Modugno",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70026,
      "CodFisco": "F262",
      "Abitanti": 38826,
      "Link": "http://www.comuni-italiani.it/072/027/"
    },
    {
      "Istat": "022118",
      "Comune": "Moena",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38035,
      "CodFisco": "F263",
      "Abitanti": 2709,
      "Link": "http://www.comuni-italiani.it/022/118/"
    },
    {
      "Istat": "097050",
      "Comune": "Moggio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23817,
      "CodFisco": "F265",
      "Abitanti": 521,
      "Link": "http://www.comuni-italiani.it/097/050/"
    },
    {
      "Istat": "030059",
      "Comune": "Moggio Udinese",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33015,
      "CodFisco": "F266",
      "Abitanti": 1842,
      "Link": "http://www.comuni-italiani.it/030/059/"
    },
    {
      "Istat": "020035",
      "Comune": "Moglia",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46024,
      "CodFisco": "F267",
      "Abitanti": 6064,
      "Link": "http://www.comuni-italiani.it/020/035/"
    },
    {
      "Istat": "043025",
      "Comune": "Mogliano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F268",
      "Abitanti": 4901,
      "Link": "http://www.comuni-italiani.it/043/025/"
    },
    {
      "Istat": "026043",
      "Comune": "Mogliano Veneto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 31021,
      "CodFisco": "F269",
      "Abitanti": 28115,
      "Link": "http://www.comuni-italiani.it/026/043/"
    },
    {
      "Istat": "095028",
      "Comune": "Mogorella",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "F270",
      "Abitanti": 471,
      "Link": "http://www.comuni-italiani.it/095/028/"
    },
    {
      "Istat": "095029",
      "Comune": "Mogoro",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09095",
      "CodFisco": "F272",
      "Abitanti": 4441,
      "Link": "http://www.comuni-italiani.it/095/029/"
    },
    {
      "Istat": "062040",
      "Comune": "Moiano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82010,
      "CodFisco": "F274",
      "Abitanti": 4142,
      "Link": "http://www.comuni-italiani.it/062/040/"
    },
    {
      "Istat": "030060",
      "Comune": "Moimacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "F275",
      "Abitanti": 1636,
      "Link": "http://www.comuni-italiani.it/030/060/"
    },
    {
      "Istat": "083053",
      "Comune": "Moio Alcantara",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "F277",
      "Abitanti": 753,
      "Link": "http://www.comuni-italiani.it/083/053/"
    },
    {
      "Istat": "016136",
      "Comune": "Moio de' Calvi",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "F276",
      "Abitanti": 208,
      "Link": "http://www.comuni-italiani.it/016/136/"
    },
    {
      "Istat": "065069",
      "Comune": "Moio della Civitella",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "F278",
      "Abitanti": 1927,
      "Link": "http://www.comuni-italiani.it/065/069/"
    },
    {
      "Istat": "004123",
      "Comune": "Moiola",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "F279",
      "Abitanti": 272,
      "Link": "http://www.comuni-italiani.it/004/123/"
    },
    {
      "Istat": "072028",
      "Comune": "Mola di Bari",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70042,
      "CodFisco": "F280",
      "Abitanti": 26348,
      "Link": "http://www.comuni-italiani.it/072/028/"
    },
    {
      "Istat": "006095",
      "Comune": "Molare",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15074,
      "CodFisco": "F281",
      "Abitanti": 2255,
      "Link": "http://www.comuni-italiani.it/006/095/"
    },
    {
      "Istat": "046020",
      "Comune": "Molazzana",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55020,
      "CodFisco": "F283",
      "Abitanti": 1152,
      "Link": "http://www.comuni-italiani.it/046/020/"
    },
    {
      "Istat": "072029",
      "Comune": "Molfetta",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70056,
      "CodFisco": "F284",
      "Abitanti": 60159,
      "Link": "http://www.comuni-italiani.it/072/029/"
    },
    {
      "Istat": "066055",
      "Comune": "Molina Aterno",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67020,
      "CodFisco": "M255",
      "Abitanti": 414,
      "Link": "http://www.comuni-italiani.it/066/055/"
    },
    {
      "Istat": "062041",
      "Comune": "Molinara",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "F287",
      "Abitanti": 1681,
      "Link": "http://www.comuni-italiani.it/062/041/"
    },
    {
      "Istat": "037039",
      "Comune": "Molinella",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40062,
      "CodFisco": "F288",
      "Abitanti": 15821,
      "Link": "http://www.comuni-italiani.it/037/039/"
    },
    {
      "Istat": "008035",
      "Comune": "Molini di Triora",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "F290",
      "Abitanti": 699,
      "Link": "http://www.comuni-italiani.it/008/035/"
    },
    {
      "Istat": "006096",
      "Comune": "Molino dei Torti",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "F293",
      "Abitanti": 687,
      "Link": "http://www.comuni-italiani.it/006/096/"
    },
    {
      "Istat": "070039",
      "Comune": "Molise",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "F294",
      "Abitanti": 164,
      "Link": "http://www.comuni-italiani.it/070/039/"
    },
    {
      "Istat": "076050",
      "Comune": "Moliterno",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85047,
      "CodFisco": "F295",
      "Abitanti": 4289,
      "Link": "http://www.comuni-italiani.it/076/050/"
    },
    {
      "Istat": "002078",
      "Comune": "Mollia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "F297",
      "Abitanti": 106,
      "Link": "http://www.comuni-italiani.it/002/078/"
    },
    {
      "Istat": "080051",
      "Comune": "Molochio",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89010,
      "CodFisco": "F301",
      "Abitanti": 2628,
      "Link": "http://www.comuni-italiani.it/080/051/"
    },
    {
      "Istat": "097051",
      "Comune": "Molteno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23847,
      "CodFisco": "F304",
      "Abitanti": 3545,
      "Link": "http://www.comuni-italiani.it/097/051/"
    },
    {
      "Istat": "013152",
      "Comune": "Moltrasio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22010,
      "CodFisco": "F305",
      "Abitanti": 1710,
      "Link": "http://www.comuni-italiani.it/013/152/"
    },
    {
      "Istat": "024059",
      "Comune": "Molvena",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36060,
      "CodFisco": "F306",
      "Abitanti": 2639,
      "Link": "http://www.comuni-italiani.it/024/059/"
    },
    {
      "Istat": "022120",
      "Comune": "Molveno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38018,
      "CodFisco": "F307",
      "Abitanti": 1130,
      "Link": "http://www.comuni-italiani.it/022/120/"
    },
    {
      "Istat": "005064",
      "Comune": "Mombaldone",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14050,
      "CodFisco": "F308",
      "Abitanti": 225,
      "Link": "http://www.comuni-italiani.it/005/064/"
    },
    {
      "Istat": "004124",
      "Comune": "Mombarcaro",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "F309",
      "Abitanti": 281,
      "Link": "http://www.comuni-italiani.it/004/124/"
    },
    {
      "Istat": "041027",
      "Comune": "Mombaroccio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61024,
      "CodFisco": "F310",
      "Abitanti": 2157,
      "Link": "http://www.comuni-italiani.it/041/027/"
    },
    {
      "Istat": "005065",
      "Comune": "Mombaruzzo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14046,
      "CodFisco": "F311",
      "Abitanti": 1146,
      "Link": "http://www.comuni-italiani.it/005/065/"
    },
    {
      "Istat": "004125",
      "Comune": "Mombasiglio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "F312",
      "Abitanti": 616,
      "Link": "http://www.comuni-italiani.it/004/125/"
    },
    {
      "Istat": "001153",
      "Comune": "Mombello di Torino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "F315",
      "Abitanti": 411,
      "Link": "http://www.comuni-italiani.it/001/153/"
    },
    {
      "Istat": "006097",
      "Comune": "Mombello Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "F313",
      "Abitanti": 1099,
      "Link": "http://www.comuni-italiani.it/006/097/"
    },
    {
      "Istat": "005066",
      "Comune": "Mombercelli",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14047,
      "CodFisco": "F316",
      "Abitanti": 2394,
      "Link": "http://www.comuni-italiani.it/005/066/"
    },
    {
      "Istat": "003100",
      "Comune": "Momo",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28015,
      "CodFisco": "F317",
      "Abitanti": 2731,
      "Link": "http://www.comuni-italiani.it/003/100/"
    },
    {
      "Istat": "001154",
      "Comune": "Mompantero",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10059,
      "CodFisco": "F318",
      "Abitanti": 669,
      "Link": "http://www.comuni-italiani.it/001/154/"
    },
    {
      "Istat": "057038",
      "Comune": "Mompeo",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "F319",
      "Abitanti": 546,
      "Link": "http://www.comuni-italiani.it/057/038/"
    },
    {
      "Istat": "006098",
      "Comune": "Momperone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "F320",
      "Abitanti": 218,
      "Link": "http://www.comuni-italiani.it/006/098/"
    },
    {
      "Istat": "070040",
      "Comune": "Monacilioni",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "F322",
      "Abitanti": 600,
      "Link": "http://www.comuni-italiani.it/070/040/"
    },
    {
      "Istat": "005067",
      "Comune": "Monale",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14013,
      "CodFisco": "F323",
      "Abitanti": 1050,
      "Link": "http://www.comuni-italiani.it/005/067/"
    },
    {
      "Istat": "080052",
      "Comune": "Monasterace",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "F324",
      "Abitanti": 3538,
      "Link": "http://www.comuni-italiani.it/080/052/"
    },
    {
      "Istat": "005068",
      "Comune": "Monastero Bormida",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14058,
      "CodFisco": "F325",
      "Abitanti": 1002,
      "Link": "http://www.comuni-italiani.it/005/068/"
    },
    {
      "Istat": "001155",
      "Comune": "Monastero di Lanzo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "F327",
      "Abitanti": 396,
      "Link": "http://www.comuni-italiani.it/001/155/"
    },
    {
      "Istat": "004126",
      "Comune": "Monastero di Vasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "F326",
      "Abitanti": 1307,
      "Link": "http://www.comuni-italiani.it/004/126/"
    },
    {
      "Istat": "004127",
      "Comune": "Monasterolo Casotto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "F329",
      "Abitanti": 107,
      "Link": "http://www.comuni-italiani.it/004/127/"
    },
    {
      "Istat": "016137",
      "Comune": "Monasterolo del Castello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "F328",
      "Abitanti": 1119,
      "Link": "http://www.comuni-italiani.it/016/137/"
    },
    {
      "Istat": "004128",
      "Comune": "Monasterolo di Savigliano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "F330",
      "Abitanti": 1312,
      "Link": "http://www.comuni-italiani.it/004/128/"
    },
    {
      "Istat": "026044",
      "Comune": "Monastier di Treviso",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31050,
      "CodFisco": "F332",
      "Abitanti": 4105,
      "Link": "http://www.comuni-italiani.it/026/044/"
    },
    {
      "Istat": "092038",
      "Comune": "Monastir",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09023",
      "CodFisco": "F333",
      "Abitanti": 4576,
      "Link": "http://www.comuni-italiani.it/092/038/"
    },
    {
      "Istat": "001156",
      "Comune": "Moncalieri",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10024,
      "CodFisco": "F335",
      "Abitanti": 58320,
      "Link": "http://www.comuni-italiani.it/001/156/"
    },
    {
      "Istat": "005069",
      "Comune": "Moncalvo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14036,
      "CodFisco": "F336",
      "Abitanti": 3275,
      "Link": "http://www.comuni-italiani.it/005/069/"
    },
    {
      "Istat": "001157",
      "Comune": "Moncenisio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "D553",
      "Abitanti": 42,
      "Link": "http://www.comuni-italiani.it/001/157/"
    },
    {
      "Istat": "006099",
      "Comune": "Moncestino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "F337",
      "Abitanti": 242,
      "Link": "http://www.comuni-italiani.it/006/099/"
    },
    {
      "Istat": "004129",
      "Comune": "Monchiero",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "F338",
      "Abitanti": 574,
      "Link": "http://www.comuni-italiani.it/004/129/"
    },
    {
      "Istat": "034022",
      "Comune": "Monchio delle Corti",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43010,
      "CodFisco": "F340",
      "Abitanti": 1024,
      "Link": "http://www.comuni-italiani.it/034/022/"
    },
    {
      "Istat": "022121",
      "Comune": "Monclassico",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "F341",
      "Abitanti": 872,
      "Link": "http://www.comuni-italiani.it/022/121/"
    },
    {
      "Istat": "002079",
      "Comune": "Moncrivello",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "F342",
      "Abitanti": 1444,
      "Link": "http://www.comuni-italiani.it/002/079/"
    },
    {
      "Istat": "005070",
      "Comune": "Moncucco Torinese",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 14024,
      "CodFisco": "F343",
      "Abitanti": 891,
      "Link": "http://www.comuni-italiani.it/005/070/"
    },
    {
      "Istat": "099006",
      "Comune": "Mondaino",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47836,
      "CodFisco": "F346",
      "Abitanti": 1478,
      "Link": "http://www.comuni-italiani.it/099/006/"
    },
    {
      "Istat": "041028",
      "Comune": "Mondavio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "F347",
      "Abitanti": 3974,
      "Link": "http://www.comuni-italiani.it/041/028/"
    },
    {
      "Istat": "041029",
      "Comune": "Mondolfo",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61037,
      "CodFisco": "F348",
      "Abitanti": 11971,
      "Link": "http://www.comuni-italiani.it/041/029/"
    },
    {
      "Istat": "004130",
      "Comune": "MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12084,
      "CodFisco": "F351",
      "Abitanti": 22605,
      "Link": "http://www.comuni-italiani.it/004/130/"
    },
    {
      "Istat": "061052",
      "Comune": "Mondragone",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81034,
      "CodFisco": "F352",
      "Abitanti": 27405,
      "Link": "http://www.comuni-italiani.it/061/052/"
    },
    {
      "Istat": "010037",
      "Comune": "Moneglia",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "F354",
      "Abitanti": 2898,
      "Link": "http://www.comuni-italiani.it/010/037/"
    },
    {
      "Istat": "004131",
      "Comune": "Monesiglio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12077,
      "CodFisco": "F355",
      "Abitanti": 733,
      "Link": "http://www.comuni-italiani.it/004/131/"
    },
    {
      "Istat": "031012",
      "Comune": "Monfalcone",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34074,
      "CodFisco": "F356",
      "Abitanti": 27877,
      "Link": "http://www.comuni-italiani.it/031/012/"
    },
    {
      "Istat": "004132",
      "Comune": "Monforte d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12065,
      "CodFisco": "F358",
      "Abitanti": 2079,
      "Link": "http://www.comuni-italiani.it/004/132/"
    },
    {
      "Istat": "083054",
      "Comune": "Monforte San Giorgio",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98041,
      "CodFisco": "F359",
      "Abitanti": 2911,
      "Link": "http://www.comuni-italiani.it/083/054/"
    },
    {
      "Istat": "026045",
      "Comune": "Monfumo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31010,
      "CodFisco": "F360",
      "Abitanti": 1454,
      "Link": "http://www.comuni-italiani.it/026/045/"
    },
    {
      "Istat": "005071",
      "Comune": "Mongardino",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "F361",
      "Abitanti": 950,
      "Link": "http://www.comuni-italiani.it/005/071/"
    },
    {
      "Istat": "037040",
      "Comune": "Monghidoro",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40063,
      "CodFisco": "F363",
      "Abitanti": 3871,
      "Link": "http://www.comuni-italiani.it/037/040/"
    },
    {
      "Istat": 102022,
      "Comune": "Mongiana",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89823,
      "CodFisco": "F364",
      "Abitanti": 822,
      "Link": "http://www.comuni-italiani.it/102/022/"
    },
    {
      "Istat": "006100",
      "Comune": "Mongiardino Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "F365",
      "Abitanti": 182,
      "Link": "http://www.comuni-italiani.it/006/100/"
    },
    {
      "Istat": "083055",
      "Comune": "Mongiuffi Melia",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "F368",
      "Abitanti": 670,
      "Link": "http://www.comuni-italiani.it/083/055/"
    },
    {
      "Istat": "096035",
      "Comune": "Mongrando",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13888,
      "CodFisco": "F369",
      "Abitanti": 4009,
      "Link": "http://www.comuni-italiani.it/096/035/"
    },
    {
      "Istat": "078080",
      "Comune": "Mongrassano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "F370",
      "Abitanti": 1650,
      "Link": "http://www.comuni-italiani.it/078/080/"
    },
    {
      "Istat": "021052",
      "Comune": "Monguelfo-Tesido",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39035,
      "CodFisco": "F371",
      "Abitanti": 2804,
      "Link": "http://www.comuni-italiani.it/021/052/"
    },
    {
      "Istat": "013153",
      "Comune": "Monguzzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22040,
      "CodFisco": "F372",
      "Abitanti": 2207,
      "Link": "http://www.comuni-italiani.it/013/153/"
    },
    {
      "Istat": "017109",
      "Comune": "Moniga del Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "F373",
      "Abitanti": 2457,
      "Link": "http://www.comuni-italiani.it/017/109/"
    },
    {
      "Istat": "006101",
      "Comune": "Monleale",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15059,
      "CodFisco": "F374",
      "Abitanti": 599,
      "Link": "http://www.comuni-italiani.it/006/101/"
    },
    {
      "Istat": "017110",
      "Comune": "Monno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "F375",
      "Abitanti": 568,
      "Link": "http://www.comuni-italiani.it/017/110/"
    },
    {
      "Istat": "072030",
      "Comune": "Monopoli",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70043,
      "CodFisco": "F376",
      "Abitanti": 49622,
      "Link": "http://www.comuni-italiani.it/072/030/"
    },
    {
      "Istat": "082049",
      "Comune": "Monreale",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90046,
      "CodFisco": "F377",
      "Abitanti": 38204,
      "Link": "http://www.comuni-italiani.it/082/049/"
    },
    {
      "Istat": "032002",
      "Comune": "Monrupino",
      "Provincia": "TS",
      "Regione": "FVG",
      "Prefisso": "040",
      "CAP": 34016,
      "CodFisco": "F378",
      "Abitanti": 890,
      "Link": "http://www.comuni-italiani.it/032/002/"
    },
    {
      "Istat": 109012,
      "Comune": "Monsampietro Morico",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63842,
      "CodFisco": "F379",
      "Abitanti": 692,
      "Link": "http://www.comuni-italiani.it/109/012/"
    },
    {
      "Istat": "044031",
      "Comune": "Monsampolo del Tronto",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63077,
      "CodFisco": "F380",
      "Abitanti": 4657,
      "Link": "http://www.comuni-italiani.it/044/031/"
    },
    {
      "Istat": "042025",
      "Comune": "Monsano",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "F381",
      "Abitanti": 3271,
      "Link": "http://www.comuni-italiani.it/042/025/"
    },
    {
      "Istat": "028055",
      "Comune": "Monselice",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35043,
      "CodFisco": "F382",
      "Abitanti": 17616,
      "Link": "http://www.comuni-italiani.it/028/055/"
    },
    {
      "Istat": "092109",
      "Comune": "Monserrato",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09042",
      "CodFisco": "F383",
      "Abitanti": 20240,
      "Link": "http://www.comuni-italiani.it/092/109/"
    },
    {
      "Istat": "047009",
      "Comune": "Monsummano Terme",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51015,
      "CodFisco": "F384",
      "Abitanti": 21374,
      "Link": "http://www.comuni-italiani.it/047/009/"
    },
    {
      "Istat": "004133",
      "Comune": "Mont‡",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12046,
      "CodFisco": "F385",
      "Abitanti": 4712,
      "Link": "http://www.comuni-italiani.it/004/133/"
    },
    {
      "Istat": "005072",
      "Comune": "Montabone",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "F386",
      "Abitanti": 364,
      "Link": "http://www.comuni-italiani.it/005/072/"
    },
    {
      "Istat": "006102",
      "Comune": "Montacuto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "F387",
      "Abitanti": 308,
      "Link": "http://www.comuni-italiani.it/006/102/"
    },
    {
      "Istat": "005073",
      "Comune": "Montafia",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14014,
      "CodFisco": "F390",
      "Abitanti": 956,
      "Link": "http://www.comuni-italiani.it/005/073/"
    },
    {
      "Istat": "070041",
      "Comune": "Montagano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86023,
      "CodFisco": "F391",
      "Abitanti": 1155,
      "Link": "http://www.comuni-italiani.it/070/041/"
    },
    {
      "Istat": "021053",
      "Comune": "Montagna",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "F392",
      "Abitanti": 1633,
      "Link": "http://www.comuni-italiani.it/021/053/"
    },
    {
      "Istat": "014044",
      "Comune": "Montagna in Valtellina",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "F393",
      "Abitanti": 3059,
      "Link": "http://www.comuni-italiani.it/014/044/"
    },
    {
      "Istat": "028056",
      "Comune": "Montagnana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35044,
      "CodFisco": "F394",
      "Abitanti": 9505,
      "Link": "http://www.comuni-italiani.it/028/056/"
    },
    {
      "Istat": "083056",
      "Comune": "Montagnareale",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "F395",
      "Abitanti": 1676,
      "Link": "http://www.comuni-italiani.it/083/056/"
    },
    {
      "Istat": "022122",
      "Comune": "Montagne",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38070,
      "CodFisco": "F396",
      "Abitanti": 244,
      "Link": "http://www.comuni-italiani.it/022/122/"
    },
    {
      "Istat": "064051",
      "Comune": "Montaguto",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "F397",
      "Abitanti": 478,
      "Link": "http://www.comuni-italiani.it/064/051/"
    },
    {
      "Istat": "048027",
      "Comune": "Montaione",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50050,
      "CodFisco": "F398",
      "Abitanti": 3773,
      "Link": "http://www.comuni-italiani.it/048/027/"
    },
    {
      "Istat": "083057",
      "Comune": "Montalbano Elicona",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98065,
      "CodFisco": "F400",
      "Abitanti": 2488,
      "Link": "http://www.comuni-italiani.it/083/057/"
    },
    {
      "Istat": "077016",
      "Comune": "Montalbano Jonico",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75023,
      "CodFisco": "F399",
      "Abitanti": 7552,
      "Link": "http://www.comuni-italiani.it/077/016/"
    },
    {
      "Istat": "052014",
      "Comune": "Montalcino",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53024,
      "CodFisco": "F402",
      "Abitanti": 5272,
      "Link": "http://www.comuni-italiani.it/052/014/"
    },
    {
      "Istat": "006103",
      "Comune": "Montaldeo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "F403",
      "Abitanti": 292,
      "Link": "http://www.comuni-italiani.it/006/103/"
    },
    {
      "Istat": "006104",
      "Comune": "Montaldo Bormida",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15010,
      "CodFisco": "F404",
      "Abitanti": 709,
      "Link": "http://www.comuni-italiani.it/006/104/"
    },
    {
      "Istat": "004134",
      "Comune": "Montaldo di MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "F405",
      "Abitanti": 582,
      "Link": "http://www.comuni-italiani.it/004/134/"
    },
    {
      "Istat": "004135",
      "Comune": "Montaldo Roero",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "F408",
      "Abitanti": 872,
      "Link": "http://www.comuni-italiani.it/004/135/"
    },
    {
      "Istat": "005074",
      "Comune": "Montaldo Scarampi",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14048,
      "CodFisco": "F409",
      "Abitanti": 791,
      "Link": "http://www.comuni-italiani.it/005/074/"
    },
    {
      "Istat": "001158",
      "Comune": "Montaldo Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "F407",
      "Abitanti": 714,
      "Link": "http://www.comuni-italiani.it/001/158/"
    },
    {
      "Istat": "047010",
      "Comune": "Montale",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51037,
      "CodFisco": "F410",
      "Abitanti": 10766,
      "Link": "http://www.comuni-italiani.it/047/010/"
    },
    {
      "Istat": "001159",
      "Comune": "Montalenghe",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "F411",
      "Abitanti": 990,
      "Link": "http://www.comuni-italiani.it/001/159/"
    },
    {
      "Istat": "084024",
      "Comune": "Montallegro",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "F414",
      "Abitanti": 2557,
      "Link": "http://www.comuni-italiani.it/084/024/"
    },
    {
      "Istat": "044032",
      "Comune": "Montalto delle Marche",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63068,
      "CodFisco": "F415",
      "Abitanti": 2266,
      "Link": "http://www.comuni-italiani.it/044/032/"
    },
    {
      "Istat": "056035",
      "Comune": "Montalto di Castro",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "01014",
      "CodFisco": "F419",
      "Abitanti": 8976,
      "Link": "http://www.comuni-italiani.it/056/035/"
    },
    {
      "Istat": "001160",
      "Comune": "Montalto Dora",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10016,
      "CodFisco": "F420",
      "Abitanti": 3493,
      "Link": "http://www.comuni-italiani.it/001/160/"
    },
    {
      "Istat": "008036",
      "Comune": "Montalto Ligure",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "F406",
      "Abitanti": 372,
      "Link": "http://www.comuni-italiani.it/008/036/"
    },
    {
      "Istat": "018094",
      "Comune": "Montalto Pavese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "F417",
      "Abitanti": 931,
      "Link": "http://www.comuni-italiani.it/018/094/"
    },
    {
      "Istat": "078081",
      "Comune": "Montalto Uffugo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87046,
      "CodFisco": "F416",
      "Abitanti": 20844,
      "Link": "http://www.comuni-italiani.it/078/081/"
    },
    {
      "Istat": "001161",
      "Comune": "Montanaro",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10017,
      "CodFisco": "F422",
      "Abitanti": 5405,
      "Link": "http://www.comuni-italiani.it/001/161/"
    },
    {
      "Istat": "098040",
      "Comune": "Montanaso Lombardo",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26836,
      "CodFisco": "F423",
      "Abitanti": 2203,
      "Link": "http://www.comuni-italiani.it/098/040/"
    },
    {
      "Istat": "004136",
      "Comune": "Montanera",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12040,
      "CodFisco": "F424",
      "Abitanti": 741,
      "Link": "http://www.comuni-italiani.it/004/136/"
    },
    {
      "Istat": "065070",
      "Comune": "Montano Antilia",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "F426",
      "Abitanti": 2002,
      "Link": "http://www.comuni-italiani.it/065/070/"
    },
    {
      "Istat": "013154",
      "Comune": "Montano Lucino",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "F427",
      "Abitanti": 4755,
      "Link": "http://www.comuni-italiani.it/013/154/"
    },
    {
      "Istat": 109013,
      "Comune": "Montappone",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63835,
      "CodFisco": "F428",
      "Abitanti": 1758,
      "Link": "http://www.comuni-italiani.it/109/013/"
    },
    {
      "Istat": "094028",
      "Comune": "Montaquila",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "F429",
      "Abitanti": 2501,
      "Link": "http://www.comuni-italiani.it/094/028/"
    },
    {
      "Istat": "057039",
      "Comune": "Montasola",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02040",
      "CodFisco": "F430",
      "Abitanti": 420,
      "Link": "http://www.comuni-italiani.it/057/039/"
    },
    {
      "Istat": "079080",
      "Comune": "Montauro",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "F432",
      "Abitanti": 1514,
      "Link": "http://www.comuni-italiani.it/079/080/"
    },
    {
      "Istat": "069051",
      "Comune": "Montazzoli",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66030,
      "CodFisco": "F433",
      "Abitanti": 1040,
      "Link": "http://www.comuni-italiani.it/069/051/"
    },
    {
      "Istat": "053016",
      "Comune": "Monte Argentario",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58019,
      "CodFisco": "F437",
      "Abitanti": 12997,
      "Link": "http://www.comuni-italiani.it/053/016/"
    },
    {
      "Istat": "054029",
      "Comune": "Monte Castello di Vibio",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06057",
      "CodFisco": "F456",
      "Abitanti": 1633,
      "Link": "http://www.comuni-italiani.it/054/029/"
    },
    {
      "Istat": "043027",
      "Comune": "Monte Cavallo",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62036,
      "CodFisco": "F460",
      "Abitanti": 155,
      "Link": "http://www.comuni-italiani.it/043/027/"
    },
    {
      "Istat": "041031",
      "Comune": "Monte Cerignone",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0541",
      "CAP": 61010,
      "CodFisco": "F467",
      "Abitanti": 690,
      "Link": "http://www.comuni-italiani.it/041/031/"
    },
    {
      "Istat": "099007",
      "Comune": "Monte Colombo",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47854,
      "CodFisco": "F476",
      "Abitanti": 3302,
      "Link": "http://www.comuni-italiani.it/099/007/"
    },
    {
      "Istat": "058060",
      "Comune": "Monte Compatri",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "F477",
      "Abitanti": 10716,
      "Link": "http://www.comuni-italiani.it/058/060/"
    },
    {
      "Istat": "019058",
      "Comune": "Monte Cremasco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "F434",
      "Abitanti": 2351,
      "Link": "http://www.comuni-italiani.it/019/058/"
    },
    {
      "Istat": "024063",
      "Comune": "Monte di Malo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "F486",
      "Abitanti": 2912,
      "Link": "http://www.comuni-italiani.it/024/063/"
    },
    {
      "Istat": "063047",
      "Comune": "Monte di Procida",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80070,
      "CodFisco": "F488",
      "Abitanti": 13341,
      "Link": "http://www.comuni-italiani.it/063/047/"
    },
    {
      "Istat": 109016,
      "Comune": "Monte Giberto",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63846,
      "CodFisco": "F517",
      "Abitanti": 840,
      "Link": "http://www.comuni-italiani.it/109/016/"
    },
    {
      "Istat": "041035",
      "Comune": "Monte Grimano Terme",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0541",
      "CAP": 61010,
      "CodFisco": "F524",
      "Abitanti": 1213,
      "Link": "http://www.comuni-italiani.it/041/035/"
    },
    {
      "Istat": "017111",
      "Comune": "Monte Isola",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "F532",
      "Abitanti": 1807,
      "Link": "http://www.comuni-italiani.it/017/111/"
    },
    {
      "Istat": "097052",
      "Comune": "Monte Marenzo",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23804,
      "CodFisco": "F561",
      "Abitanti": 1994,
      "Link": "http://www.comuni-italiani.it/097/052/"
    },
    {
      "Istat": "041038",
      "Comune": "Monte Porzio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "F589",
      "Abitanti": 2812,
      "Link": "http://www.comuni-italiani.it/041/038/"
    },
    {
      "Istat": "058064",
      "Comune": "Monte Porzio Catone",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "F590",
      "Abitanti": 8989,
      "Link": "http://www.comuni-italiani.it/058/064/"
    },
    {
      "Istat": 109021,
      "Comune": "Monte Rinaldo",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63852,
      "CodFisco": "F599",
      "Abitanti": 410,
      "Link": "http://www.comuni-italiani.it/109/021/"
    },
    {
      "Istat": "042029",
      "Comune": "Monte Roberto",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "F600",
      "Abitanti": 3021,
      "Link": "http://www.comuni-italiani.it/042/029/"
    },
    {
      "Istat": "056037",
      "Comune": "Monte Romano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "01010",
      "CodFisco": "F603",
      "Abitanti": 1994,
      "Link": "http://www.comuni-italiani.it/056/037/"
    },
    {
      "Istat": "059015",
      "Comune": "Monte San Biagio",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04020",
      "CodFisco": "F616",
      "Abitanti": 6188,
      "Link": "http://www.comuni-italiani.it/059/015/"
    },
    {
      "Istat": "065075",
      "Comune": "Monte San Giacomo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "F618",
      "Abitanti": 1662,
      "Link": "http://www.comuni-italiani.it/065/075/"
    },
    {
      "Istat": "060044",
      "Comune": "Monte San Giovanni Campano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03025",
      "CodFisco": "F620",
      "Abitanti": 12796,
      "Link": "http://www.comuni-italiani.it/060/044/"
    },
    {
      "Istat": "057043",
      "Comune": "Monte San Giovanni in Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "F619",
      "Abitanti": 772,
      "Link": "http://www.comuni-italiani.it/057/043/"
    },
    {
      "Istat": "043031",
      "Comune": "Monte San Giusto",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62015,
      "CodFisco": "F621",
      "Abitanti": 8116,
      "Link": "http://www.comuni-italiani.it/043/031/"
    },
    {
      "Istat": "043032",
      "Comune": "Monte San Martino",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "F622",
      "Abitanti": 783,
      "Link": "http://www.comuni-italiani.it/043/032/"
    },
    {
      "Istat": 109023,
      "Comune": "Monte San Pietrangeli",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63815,
      "CodFisco": "F626",
      "Abitanti": 2577,
      "Link": "http://www.comuni-italiani.it/109/023/"
    },
    {
      "Istat": "037042",
      "Comune": "Monte San Pietro",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "F627",
      "Abitanti": 10956,
      "Link": "http://www.comuni-italiani.it/037/042/"
    },
    {
      "Istat": "051025",
      "Comune": "Monte San Savino",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52048,
      "CodFisco": "F628",
      "Abitanti": 8754,
      "Link": "http://www.comuni-italiani.it/051/025/"
    },
    {
      "Istat": "042030",
      "Comune": "Monte San Vito",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60037,
      "CodFisco": "F634",
      "Abitanti": 6666,
      "Link": "http://www.comuni-italiani.it/042/030/"
    },
    {
      "Istat": "054032",
      "Comune": "Monte Santa Maria Tiberina",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06010",
      "CodFisco": "F629",
      "Abitanti": 1215,
      "Link": "http://www.comuni-italiani.it/054/032/"
    },
    {
      "Istat": "071033",
      "Comune": "Monte Sant'Angelo",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71037,
      "CodFisco": "F631",
      "Abitanti": 13221,
      "Link": "http://www.comuni-italiani.it/071/033/"
    },
    {
      "Istat": 109024,
      "Comune": "Monte Urano",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63813,
      "CodFisco": "F653",
      "Abitanti": 8471,
      "Link": "http://www.comuni-italiani.it/109/024/"
    },
    {
      "Istat": 109025,
      "Comune": "Monte Vidon Combatte",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63847,
      "CodFisco": "F664",
      "Abitanti": 473,
      "Link": "http://www.comuni-italiani.it/109/025/"
    },
    {
      "Istat": 109026,
      "Comune": "Monte Vidon Corrado",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63836,
      "CodFisco": "F665",
      "Abitanti": 768,
      "Link": "http://www.comuni-italiani.it/109/026/"
    },
    {
      "Istat": "018095",
      "Comune": "Montebello della Battaglia",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27054,
      "CodFisco": "F440",
      "Abitanti": 1712,
      "Link": "http://www.comuni-italiani.it/018/095/"
    },
    {
      "Istat": "068023",
      "Comune": "Montebello di Bertona",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "F441",
      "Abitanti": 1061,
      "Link": "http://www.comuni-italiani.it/068/023/"
    },
    {
      "Istat": "080053",
      "Comune": "Montebello Ionico",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89064,
      "CodFisco": "D746",
      "Abitanti": 6463,
      "Link": "http://www.comuni-italiani.it/080/053/"
    },
    {
      "Istat": "069009",
      "Comune": "Montebello sul Sangro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "B268",
      "Abitanti": 102,
      "Link": "http://www.comuni-italiani.it/069/009/"
    },
    {
      "Istat": "024060",
      "Comune": "Montebello Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36054,
      "CodFisco": "F442",
      "Abitanti": 6550,
      "Link": "http://www.comuni-italiani.it/024/060/"
    },
    {
      "Istat": "026046",
      "Comune": "Montebelluna",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31044,
      "CodFisco": "F443",
      "Abitanti": 31181,
      "Link": "http://www.comuni-italiani.it/026/046/"
    },
    {
      "Istat": "010038",
      "Comune": "Montebruno",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16025,
      "CodFisco": "F445",
      "Abitanti": 217,
      "Link": "http://www.comuni-italiani.it/010/038/"
    },
    {
      "Istat": "057040",
      "Comune": "Montebuono",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "F446",
      "Abitanti": 938,
      "Link": "http://www.comuni-italiani.it/057/040/"
    },
    {
      "Istat": "041030",
      "Comune": "Montecalvo in Foglia",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61020,
      "CodFisco": "F450",
      "Abitanti": 2734,
      "Link": "http://www.comuni-italiani.it/041/030/"
    },
    {
      "Istat": "064052",
      "Comune": "Montecalvo Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83037,
      "CodFisco": "F448",
      "Abitanti": 3940,
      "Link": "http://www.comuni-italiani.it/064/052/"
    },
    {
      "Istat": "018096",
      "Comune": "Montecalvo Versiggia",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27047,
      "CodFisco": "F449",
      "Abitanti": 588,
      "Link": "http://www.comuni-italiani.it/018/096/"
    },
    {
      "Istat": "046021",
      "Comune": "Montecarlo",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55015,
      "CodFisco": "F452",
      "Abitanti": 4538,
      "Link": "http://www.comuni-italiani.it/046/021/"
    },
    {
      "Istat": "042026",
      "Comune": "Montecarotto",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60036,
      "CodFisco": "F453",
      "Abitanti": 2083,
      "Link": "http://www.comuni-italiani.it/042/026/"
    },
    {
      "Istat": "043026",
      "Comune": "Montecassiano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F454",
      "Abitanti": 7195,
      "Link": "http://www.comuni-italiani.it/043/026/"
    },
    {
      "Istat": "006105",
      "Comune": "Montecastello",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "F455",
      "Abitanti": 321,
      "Link": "http://www.comuni-italiani.it/006/105/"
    },
    {
      "Istat": "055017",
      "Comune": "Montecastrilli",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05026",
      "CodFisco": "F457",
      "Abitanti": 5265,
      "Link": "http://www.comuni-italiani.it/055/017/"
    },
    {
      "Istat": "050019",
      "Comune": "Montecatini Val di Cecina",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0588",
      "CAP": 56040,
      "CodFisco": "F458",
      "Abitanti": 1883,
      "Link": "http://www.comuni-italiani.it/050/019/"
    },
    {
      "Istat": "047011",
      "Comune": "Montecatini-Terme",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51016,
      "CodFisco": "A561",
      "Abitanti": 21374,
      "Link": "http://www.comuni-italiani.it/047/011/"
    },
    {
      "Istat": "023049",
      "Comune": "Montecchia di Crosara",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "F461",
      "Abitanti": 4585,
      "Link": "http://www.comuni-italiani.it/023/049/"
    },
    {
      "Istat": "055018",
      "Comune": "Montecchio",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05020",
      "CodFisco": "F462",
      "Abitanti": 1778,
      "Link": "http://www.comuni-italiani.it/055/018/"
    },
    {
      "Istat": "035027",
      "Comune": "Montecchio Emilia",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42027,
      "CodFisco": "F463",
      "Abitanti": 10416,
      "Link": "http://www.comuni-italiani.it/035/027/"
    },
    {
      "Istat": "024061",
      "Comune": "Montecchio Maggiore",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36075,
      "CodFisco": "F464",
      "Abitanti": 23743,
      "Link": "http://www.comuni-italiani.it/024/061/"
    },
    {
      "Istat": "024062",
      "Comune": "Montecchio Precalcino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "F465",
      "Abitanti": 4966,
      "Link": "http://www.comuni-italiani.it/024/062/"
    },
    {
      "Istat": "006106",
      "Comune": "Montechiaro d'Acqui",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "F469",
      "Abitanti": 580,
      "Link": "http://www.comuni-italiani.it/006/106/"
    },
    {
      "Istat": "005075",
      "Comune": "Montechiaro d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14025,
      "CodFisco": "F468",
      "Abitanti": 1432,
      "Link": "http://www.comuni-italiani.it/005/075/"
    },
    {
      "Istat": "034023",
      "Comune": "Montechiarugolo",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43022,
      "CodFisco": "F473",
      "Abitanti": 10626,
      "Link": "http://www.comuni-italiani.it/034/023/"
    },
    {
      "Istat": "041032",
      "Comune": "Monteciccardo",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61024,
      "CodFisco": "F474",
      "Abitanti": 1698,
      "Link": "http://www.comuni-italiani.it/041/032/"
    },
    {
      "Istat": "070042",
      "Comune": "Montecilfone",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86032,
      "CodFisco": "F475",
      "Abitanti": 1460,
      "Link": "http://www.comuni-italiani.it/070/042/"
    },
    {
      "Istat": "041033",
      "Comune": "Montecopiolo",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61014,
      "CodFisco": "F478",
      "Abitanti": 1214,
      "Link": "http://www.comuni-italiani.it/041/033/"
    },
    {
      "Istat": "065071",
      "Comune": "Montecorice",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "F479",
      "Abitanti": 2589,
      "Link": "http://www.comuni-italiani.it/065/071/"
    },
    {
      "Istat": "065072",
      "Comune": "Montecorvino Pugliano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84090,
      "CodFisco": "F480",
      "Abitanti": 10190,
      "Link": "http://www.comuni-italiani.it/065/072/"
    },
    {
      "Istat": "065073",
      "Comune": "Montecorvino Rovella",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84096,
      "CodFisco": "F481",
      "Abitanti": 12633,
      "Link": "http://www.comuni-italiani.it/065/073/"
    },
    {
      "Istat": "043028",
      "Comune": "Montecosaro",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F482",
      "Abitanti": 6826,
      "Link": "http://www.comuni-italiani.it/043/028/"
    },
    {
      "Istat": 103046,
      "Comune": "Montecrestese",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28864,
      "CodFisco": "F483",
      "Abitanti": 1241,
      "Link": "http://www.comuni-italiani.it/103/046/"
    },
    {
      "Istat": "036024",
      "Comune": "Montecreto",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41025,
      "CodFisco": "F484",
      "Abitanti": 995,
      "Link": "http://www.comuni-italiani.it/036/024/"
    },
    {
      "Istat": "044034",
      "Comune": "Montedinove",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63069,
      "CodFisco": "F487",
      "Abitanti": 535,
      "Link": "http://www.comuni-italiani.it/044/034/"
    },
    {
      "Istat": "085011",
      "Comune": "Montedoro",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "F489",
      "Abitanti": 1670,
      "Link": "http://www.comuni-italiani.it/085/011/"
    },
    {
      "Istat": "064053",
      "Comune": "Montefalcione",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "F491",
      "Abitanti": 3461,
      "Link": "http://www.comuni-italiani.it/064/053/"
    },
    {
      "Istat": "054030",
      "Comune": "Montefalco",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06036",
      "CodFisco": "F492",
      "Abitanti": 5763,
      "Link": "http://www.comuni-italiani.it/054/030/"
    },
    {
      "Istat": 109014,
      "Comune": "Montefalcone Appennino",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63855,
      "CodFisco": "F493",
      "Abitanti": 466,
      "Link": "http://www.comuni-italiani.it/109/014/"
    },
    {
      "Istat": "062042",
      "Comune": "Montefalcone di Val Fortore",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82025,
      "CodFisco": "F494",
      "Abitanti": 1661,
      "Link": "http://www.comuni-italiani.it/062/042/"
    },
    {
      "Istat": "070043",
      "Comune": "Montefalcone nel Sannio",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86033,
      "CodFisco": "F495",
      "Abitanti": 1678,
      "Link": "http://www.comuni-italiani.it/070/043/"
    },
    {
      "Istat": "043029",
      "Comune": "Montefano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F496",
      "Abitanti": 3592,
      "Link": "http://www.comuni-italiani.it/043/029/"
    },
    {
      "Istat": "041034",
      "Comune": "Montefelcino",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "F497",
      "Abitanti": 2769,
      "Link": "http://www.comuni-italiani.it/041/034/"
    },
    {
      "Istat": "069052",
      "Comune": "Monteferrante",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "F498",
      "Abitanti": 140,
      "Link": "http://www.comuni-italiani.it/069/052/"
    },
    {
      "Istat": "056036",
      "Comune": "Montefiascone",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01027",
      "CodFisco": "F499",
      "Abitanti": 13712,
      "Link": "http://www.comuni-italiani.it/056/036/"
    },
    {
      "Istat": "067027",
      "Comune": "Montefino",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64030,
      "CodFisco": "F500",
      "Abitanti": 1112,
      "Link": "http://www.comuni-italiani.it/067/027/"
    },
    {
      "Istat": "099008",
      "Comune": "Montefiore Conca",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47834,
      "CodFisco": "F502",
      "Abitanti": 2231,
      "Link": "http://www.comuni-italiani.it/099/008/"
    },
    {
      "Istat": "044036",
      "Comune": "Montefiore dell'Aso",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63062,
      "CodFisco": "F501",
      "Abitanti": 2230,
      "Link": "http://www.comuni-italiani.it/044/036/"
    },
    {
      "Istat": "036025",
      "Comune": "Montefiorino",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41045,
      "CodFisco": "F503",
      "Abitanti": 2258,
      "Link": "http://www.comuni-italiani.it/036/025/"
    },
    {
      "Istat": "058061",
      "Comune": "Monteflavio",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "F504",
      "Abitanti": 1420,
      "Link": "http://www.comuni-italiani.it/058/061/"
    },
    {
      "Istat": "065074",
      "Comune": "Monteforte Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "F507",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/065/074/"
    },
    {
      "Istat": "023050",
      "Comune": "Monteforte d'Alpone",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37032,
      "CodFisco": "F508",
      "Abitanti": 8518,
      "Link": "http://www.comuni-italiani.it/023/050/"
    },
    {
      "Istat": "064054",
      "Comune": "Monteforte Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83024,
      "CodFisco": "F506",
      "Abitanti": 12646,
      "Link": "http://www.comuni-italiani.it/064/054/"
    },
    {
      "Istat": 109015,
      "Comune": "Montefortino",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63858,
      "CodFisco": "F509",
      "Abitanti": 1240,
      "Link": "http://www.comuni-italiani.it/109/015/"
    },
    {
      "Istat": "055019",
      "Comune": "Montefranco",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05030",
      "CodFisco": "F510",
      "Abitanti": 1326,
      "Link": "http://www.comuni-italiani.it/055/019/"
    },
    {
      "Istat": "064055",
      "Comune": "Montefredane",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "F511",
      "Abitanti": 2310,
      "Link": "http://www.comuni-italiani.it/064/055/"
    },
    {
      "Istat": "064056",
      "Comune": "Montefusco",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "F512",
      "Abitanti": 1432,
      "Link": "http://www.comuni-italiani.it/064/056/"
    },
    {
      "Istat": "055020",
      "Comune": "Montegabbione",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05010",
      "CodFisco": "F513",
      "Abitanti": 1256,
      "Link": "http://www.comuni-italiani.it/055/020/"
    },
    {
      "Istat": "024064",
      "Comune": "Montegalda",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36047,
      "CodFisco": "F514",
      "Abitanti": 3356,
      "Link": "http://www.comuni-italiani.it/024/064/"
    },
    {
      "Istat": "024065",
      "Comune": "Montegaldella",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36047,
      "CodFisco": "F515",
      "Abitanti": 1813,
      "Link": "http://www.comuni-italiani.it/024/065/"
    },
    {
      "Istat": "044038",
      "Comune": "Montegallo",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63094,
      "CodFisco": "F516",
      "Abitanti": 600,
      "Link": "http://www.comuni-italiani.it/044/038/"
    },
    {
      "Istat": "006107",
      "Comune": "Montegioco",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "F518",
      "Abitanti": 340,
      "Link": "http://www.comuni-italiani.it/006/107/"
    },
    {
      "Istat": "078082",
      "Comune": "Montegiordano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "F519",
      "Abitanti": 2020,
      "Link": "http://www.comuni-italiani.it/078/082/"
    },
    {
      "Istat": 109017,
      "Comune": "Montegiorgio",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63833,
      "CodFisco": "F520",
      "Abitanti": 7108,
      "Link": "http://www.comuni-italiani.it/109/017/"
    },
    {
      "Istat": 109018,
      "Comune": "Montegranaro",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63812,
      "CodFisco": "F522",
      "Abitanti": 13394,
      "Link": "http://www.comuni-italiani.it/109/018/"
    },
    {
      "Istat": "099009",
      "Comune": "Montegridolfo",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47837,
      "CodFisco": "F523",
      "Abitanti": 1044,
      "Link": "http://www.comuni-italiani.it/099/009/"
    },
    {
      "Istat": "012103",
      "Comune": "Montegrino Valtravaglia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "F526",
      "Abitanti": 1416,
      "Link": "http://www.comuni-italiani.it/012/103/"
    },
    {
      "Istat": "005076",
      "Comune": "Montegrosso d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14048,
      "CodFisco": "F527",
      "Abitanti": 2293,
      "Link": "http://www.comuni-italiani.it/005/076/"
    },
    {
      "Istat": "008037",
      "Comune": "Montegrosso Pian Latte",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18025,
      "CodFisco": "F528",
      "Abitanti": 124,
      "Link": "http://www.comuni-italiani.it/008/037/"
    },
    {
      "Istat": "028057",
      "Comune": "Montegrotto Terme",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35036,
      "CodFisco": "F529",
      "Abitanti": 11181,
      "Link": "http://www.comuni-italiani.it/028/057/"
    },
    {
      "Istat": "073016",
      "Comune": "Monteiasi",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "F531",
      "Abitanti": 5522,
      "Link": "http://www.comuni-italiani.it/073/016/"
    },
    {
      "Istat": "041036",
      "Comune": "Montelabbate",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61025,
      "CodFisco": "F533",
      "Abitanti": 6754,
      "Link": "http://www.comuni-italiani.it/041/036/"
    },
    {
      "Istat": "058062",
      "Comune": "Montelanico",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "F534",
      "Abitanti": 2156,
      "Link": "http://www.comuni-italiani.it/058/062/"
    },
    {
      "Istat": "069053",
      "Comune": "Montelapiano",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "F535",
      "Abitanti": 77,
      "Link": "http://www.comuni-italiani.it/069/053/"
    },
    {
      "Istat": 109019,
      "Comune": "Monteleone di Fermo",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63841,
      "CodFisco": "F536",
      "Abitanti": 436,
      "Link": "http://www.comuni-italiani.it/109/019/"
    },
    {
      "Istat": "071032",
      "Comune": "Monteleone di Puglia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71020,
      "CodFisco": "F538",
      "Abitanti": 1085,
      "Link": "http://www.comuni-italiani.it/071/032/"
    },
    {
      "Istat": "054031",
      "Comune": "Monteleone di Spoleto",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06045",
      "CodFisco": "F540",
      "Abitanti": 636,
      "Link": "http://www.comuni-italiani.it/054/031/"
    },
    {
      "Istat": "055021",
      "Comune": "Monteleone d'Orvieto",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05017",
      "CodFisco": "F543",
      "Abitanti": 1576,
      "Link": "http://www.comuni-italiani.it/055/021/"
    },
    {
      "Istat": "090040",
      "Comune": "Monteleone Rocca Doria",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "F542",
      "Abitanti": 126,
      "Link": "http://www.comuni-italiani.it/090/040/"
    },
    {
      "Istat": "057041",
      "Comune": "Monteleone Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02033",
      "CodFisco": "F541",
      "Abitanti": 1278,
      "Link": "http://www.comuni-italiani.it/057/041/"
    },
    {
      "Istat": "082050",
      "Comune": "Montelepre",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "F544",
      "Abitanti": 6459,
      "Link": "http://www.comuni-italiani.it/082/050/"
    },
    {
      "Istat": "058063",
      "Comune": "Montelibretti",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "F545",
      "Abitanti": 5130,
      "Link": "http://www.comuni-italiani.it/058/063/"
    },
    {
      "Istat": "064057",
      "Comune": "Montella",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83048,
      "CodFisco": "F546",
      "Abitanti": 8013,
      "Link": "http://www.comuni-italiani.it/064/057/"
    },
    {
      "Istat": "016139",
      "Comune": "Montello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "F547",
      "Abitanti": 3253,
      "Link": "http://www.comuni-italiani.it/016/139/"
    },
    {
      "Istat": "070044",
      "Comune": "Montelongo",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "F548",
      "Abitanti": 398,
      "Link": "http://www.comuni-italiani.it/070/044/"
    },
    {
      "Istat": 109020,
      "Comune": "Montelparo",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63853,
      "CodFisco": "F549",
      "Abitanti": 877,
      "Link": "http://www.comuni-italiani.it/109/020/"
    },
    {
      "Istat": "004137",
      "Comune": "Montelupo Albese",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "F550",
      "Abitanti": 536,
      "Link": "http://www.comuni-italiani.it/004/137/"
    },
    {
      "Istat": "048028",
      "Comune": "Montelupo Fiorentino",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50056,
      "CodFisco": "F551",
      "Abitanti": 13691,
      "Link": "http://www.comuni-italiani.it/048/028/"
    },
    {
      "Istat": "043030",
      "Comune": "Montelupone",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F552",
      "Abitanti": 3686,
      "Link": "http://www.comuni-italiani.it/043/030/"
    },
    {
      "Istat": "041037",
      "Comune": "Montemaggiore al Metauro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "F555",
      "Abitanti": 2795,
      "Link": "http://www.comuni-italiani.it/041/037/"
    },
    {
      "Istat": "082051",
      "Comune": "Montemaggiore Belsito",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "F553",
      "Abitanti": 3574,
      "Link": "http://www.comuni-italiani.it/082/051/"
    },
    {
      "Istat": "005077",
      "Comune": "Montemagno",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "F556",
      "Abitanti": 1203,
      "Link": "http://www.comuni-italiani.it/005/077/"
    },
    {
      "Istat": "004138",
      "Comune": "Montemale di Cuneo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12025,
      "CodFisco": "F558",
      "Abitanti": 220,
      "Link": "http://www.comuni-italiani.it/004/138/"
    },
    {
      "Istat": "064058",
      "Comune": "Montemarano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "F559",
      "Abitanti": 3090,
      "Link": "http://www.comuni-italiani.it/064/058/"
    },
    {
      "Istat": "042027",
      "Comune": "Montemarciano",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60018,
      "CodFisco": "F560",
      "Abitanti": 10236,
      "Link": "http://www.comuni-italiani.it/042/027/"
    },
    {
      "Istat": "006108",
      "Comune": "Montemarzino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "F562",
      "Abitanti": 349,
      "Link": "http://www.comuni-italiani.it/006/108/"
    },
    {
      "Istat": "073017",
      "Comune": "Montemesola",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "F563",
      "Abitanti": 4156,
      "Link": "http://www.comuni-italiani.it/073/017/"
    },
    {
      "Istat": "013155",
      "Comune": "Montemezzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "F564",
      "Abitanti": 261,
      "Link": "http://www.comuni-italiani.it/013/155/"
    },
    {
      "Istat": "051023",
      "Comune": "Montemignaio",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "F565",
      "Abitanti": 622,
      "Link": "http://www.comuni-italiani.it/051/023/"
    },
    {
      "Istat": "064059",
      "Comune": "Montemiletto",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83038,
      "CodFisco": "F566",
      "Abitanti": 5464,
      "Link": "http://www.comuni-italiani.it/064/059/"
    },
    {
      "Istat": "076051",
      "Comune": "Montemilone",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85020,
      "CodFisco": "F568",
      "Abitanti": 1763,
      "Link": "http://www.comuni-italiani.it/076/051/"
    },
    {
      "Istat": "070045",
      "Comune": "Montemitro",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "F569",
      "Abitanti": 460,
      "Link": "http://www.comuni-italiani.it/070/045/"
    },
    {
      "Istat": "044044",
      "Comune": "Montemonaco",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63088,
      "CodFisco": "F570",
      "Abitanti": 661,
      "Link": "http://www.comuni-italiani.it/044/044/"
    },
    {
      "Istat": 100003,
      "Comune": "Montemurlo",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "0574",
      "CAP": 59013,
      "CodFisco": "F572",
      "Abitanti": 18438,
      "Link": "http://www.comuni-italiani.it/100/003/"
    },
    {
      "Istat": "076052",
      "Comune": "Montemurro",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85053,
      "CodFisco": "F573",
      "Abitanti": 1347,
      "Link": "http://www.comuni-italiani.it/076/052/"
    },
    {
      "Istat": "030061",
      "Comune": "Montenars",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "F574",
      "Abitanti": 558,
      "Link": "http://www.comuni-italiani.it/030/061/"
    },
    {
      "Istat": "070046",
      "Comune": "Montenero di Bisaccia",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86036,
      "CodFisco": "F576",
      "Abitanti": 6781,
      "Link": "http://www.comuni-italiani.it/070/046/"
    },
    {
      "Istat": "057042",
      "Comune": "Montenero Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "F579",
      "Abitanti": 310,
      "Link": "http://www.comuni-italiani.it/057/042/"
    },
    {
      "Istat": "094029",
      "Comune": "Montenero Val Cocchiara",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "F580",
      "Abitanti": 575,
      "Link": "http://www.comuni-italiani.it/094/029/"
    },
    {
      "Istat": "069054",
      "Comune": "Montenerodomo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66010,
      "CodFisco": "F578",
      "Abitanti": 775,
      "Link": "http://www.comuni-italiani.it/069/054/"
    },
    {
      "Istat": "069055",
      "Comune": "Monteodorisio",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "F582",
      "Abitanti": 2598,
      "Link": "http://www.comuni-italiani.it/069/055/"
    },
    {
      "Istat": "079081",
      "Comune": "Montepaone",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "F586",
      "Abitanti": 4900,
      "Link": "http://www.comuni-italiani.it/079/081/"
    },
    {
      "Istat": "073018",
      "Comune": "Monteparano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "F587",
      "Abitanti": 2414,
      "Link": "http://www.comuni-italiani.it/073/018/"
    },
    {
      "Istat": "044045",
      "Comune": "Monteprandone",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63076,
      "CodFisco": "F591",
      "Abitanti": 12376,
      "Link": "http://www.comuni-italiani.it/044/045/"
    },
    {
      "Istat": "052015",
      "Comune": "Montepulciano",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53045,
      "CodFisco": "F592",
      "Abitanti": 14558,
      "Link": "http://www.comuni-italiani.it/052/015/"
    },
    {
      "Istat": "042028",
      "Comune": "Monterado",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "F593",
      "Abitanti": 2128,
      "Link": "http://www.comuni-italiani.it/042/028/"
    },
    {
      "Istat": "051024",
      "Comune": "Monterchi",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52035,
      "CodFisco": "F594",
      "Abitanti": 1847,
      "Link": "http://www.comuni-italiani.it/051/024/"
    },
    {
      "Istat": "066056",
      "Comune": "Montereale",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67015,
      "CodFisco": "F595",
      "Abitanti": 2867,
      "Link": "http://www.comuni-italiani.it/066/056/"
    },
    {
      "Istat": "093027",
      "Comune": "Montereale Valcellina",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33086,
      "CodFisco": "F596",
      "Abitanti": 4570,
      "Link": "http://www.comuni-italiani.it/093/027/"
    },
    {
      "Istat": "037041",
      "Comune": "Monterenzio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "F597",
      "Abitanti": 6090,
      "Link": "http://www.comuni-italiani.it/037/041/"
    },
    {
      "Istat": "052016",
      "Comune": "Monteriggioni",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53035,
      "CodFisco": "F598",
      "Abitanti": 9165,
      "Link": "http://www.comuni-italiani.it/052/016/"
    },
    {
      "Istat": "094030",
      "Comune": "Monteroduni",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86075,
      "CodFisco": "F601",
      "Abitanti": 2319,
      "Link": "http://www.comuni-italiani.it/094/030/"
    },
    {
      "Istat": "052017",
      "Comune": "Monteroni d'Arbia",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53014,
      "CodFisco": "F605",
      "Abitanti": 8812,
      "Link": "http://www.comuni-italiani.it/052/017/"
    },
    {
      "Istat": "075048",
      "Comune": "Monteroni di Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73047,
      "CodFisco": "F604",
      "Abitanti": 13964,
      "Link": "http://www.comuni-italiani.it/075/048/"
    },
    {
      "Istat": "056038",
      "Comune": "Monterosi",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "F606",
      "Abitanti": 4082,
      "Link": "http://www.comuni-italiani.it/056/038/"
    },
    {
      "Istat": "011019",
      "Comune": "Monterosso al Mare",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19016,
      "CodFisco": "F609",
      "Abitanti": 1521,
      "Link": "http://www.comuni-italiani.it/011/019/"
    },
    {
      "Istat": "088007",
      "Comune": "Monterosso Almo",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97010,
      "CodFisco": "F610",
      "Abitanti": 3229,
      "Link": "http://www.comuni-italiani.it/088/007/"
    },
    {
      "Istat": 102023,
      "Comune": "Monterosso Calabro",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89819,
      "CodFisco": "F607",
      "Abitanti": 1851,
      "Link": "http://www.comuni-italiani.it/102/023/"
    },
    {
      "Istat": "004139",
      "Comune": "Monterosso Grana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "F608",
      "Abitanti": 546,
      "Link": "http://www.comuni-italiani.it/004/139/"
    },
    {
      "Istat": "058065",
      "Comune": "Monterotondo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00015",
      "CodFisco": "F611",
      "Abitanti": 39588,
      "Link": "http://www.comuni-italiani.it/058/065/"
    },
    {
      "Istat": "053027",
      "Comune": "Monterotondo Marittimo",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0566",
      "CAP": 58025,
      "CodFisco": "F612",
      "Abitanti": 1392,
      "Link": "http://www.comuni-italiani.it/053/027/"
    },
    {
      "Istat": 109022,
      "Comune": "Monterubbiano",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63825,
      "CodFisco": "F614",
      "Abitanti": 2432,
      "Link": "http://www.comuni-italiani.it/109/022/"
    },
    {
      "Istat": "075049",
      "Comune": "Montesano Salentino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73030,
      "CodFisco": "F623",
      "Abitanti": 2696,
      "Link": "http://www.comuni-italiani.it/075/049/"
    },
    {
      "Istat": "065076",
      "Comune": "Montesano sulla Marcellana",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84033,
      "CodFisco": "F625",
      "Abitanti": 6683,
      "Link": "http://www.comuni-italiani.it/065/076/"
    },
    {
      "Istat": "062043",
      "Comune": "Montesarchio",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82016,
      "CodFisco": "F636",
      "Abitanti": 13707,
      "Link": "http://www.comuni-italiani.it/062/043/"
    },
    {
      "Istat": "077017",
      "Comune": "Montescaglioso",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75024,
      "CodFisco": "F637",
      "Abitanti": 10107,
      "Link": "http://www.comuni-italiani.it/077/017/"
    },
    {
      "Istat": "018097",
      "Comune": "Montescano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "F638",
      "Abitanti": 395,
      "Link": "http://www.comuni-italiani.it/018/097/"
    },
    {
      "Istat": 103047,
      "Comune": "Montescheno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28843,
      "CodFisco": "F639",
      "Abitanti": 437,
      "Link": "http://www.comuni-italiani.it/103/047/"
    },
    {
      "Istat": "050020",
      "Comune": "Montescudaio",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 56040,
      "CodFisco": "F640",
      "Abitanti": 1946,
      "Link": "http://www.comuni-italiani.it/050/020/"
    },
    {
      "Istat": "099010",
      "Comune": "Montescudo",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47854,
      "CodFisco": "F641",
      "Abitanti": 3303,
      "Link": "http://www.comuni-italiani.it/099/010/"
    },
    {
      "Istat": "036026",
      "Comune": "Montese",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41055,
      "CodFisco": "F642",
      "Abitanti": 3407,
      "Link": "http://www.comuni-italiani.it/036/026/"
    },
    {
      "Istat": "018098",
      "Comune": "Montesegale",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27052,
      "CodFisco": "F644",
      "Abitanti": 321,
      "Link": "http://www.comuni-italiani.it/018/098/"
    },
    {
      "Istat": "068024",
      "Comune": "Montesilvano",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65015,
      "CodFisco": "F646",
      "Abitanti": 51565,
      "Link": "http://www.comuni-italiani.it/068/024/"
    },
    {
      "Istat": "048030",
      "Comune": "Montespertoli",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50025,
      "CodFisco": "F648",
      "Abitanti": 13452,
      "Link": "http://www.comuni-italiani.it/048/030/"
    },
    {
      "Istat": "001162",
      "Comune": "Monteu da Po",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "F651",
      "Abitanti": 897,
      "Link": "http://www.comuni-italiani.it/001/162/"
    },
    {
      "Istat": "004140",
      "Comune": "Monteu Roero",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "F654",
      "Abitanti": 1672,
      "Link": "http://www.comuni-italiani.it/004/140/"
    },
    {
      "Istat": "084025",
      "Comune": "Montevago",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92010,
      "CodFisco": "F655",
      "Abitanti": 3039,
      "Link": "http://www.comuni-italiani.it/084/025/"
    },
    {
      "Istat": "051026",
      "Comune": "Montevarchi",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52025,
      "CodFisco": "F656",
      "Abitanti": 24166,
      "Link": "http://www.comuni-italiani.it/051/026/"
    },
    {
      "Istat": "097053",
      "Comune": "Montevecchia",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23874,
      "CodFisco": "F657",
      "Abitanti": 2494,
      "Link": "http://www.comuni-italiani.it/097/053/"
    },
    {
      "Istat": "037043",
      "Comune": "Monteveglio",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40050,
      "CodFisco": "F659",
      "Abitanti": 5286,
      "Link": "http://www.comuni-italiani.it/037/043/"
    },
    {
      "Istat": "064060",
      "Comune": "Monteverde",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83049,
      "CodFisco": "F660",
      "Abitanti": 842,
      "Link": "http://www.comuni-italiani.it/064/060/"
    },
    {
      "Istat": "050021",
      "Comune": "Monteverdi Marittimo",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 56040,
      "CodFisco": "F661",
      "Abitanti": 784,
      "Link": "http://www.comuni-italiani.it/050/021/"
    },
    {
      "Istat": "024066",
      "Comune": "Monteviale",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "F662",
      "Abitanti": 2569,
      "Link": "http://www.comuni-italiani.it/024/066/"
    },
    {
      "Istat": "004141",
      "Comune": "Montezemolo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "F666",
      "Abitanti": 283,
      "Link": "http://www.comuni-italiani.it/004/141/"
    },
    {
      "Istat": 104016,
      "Comune": "Monti",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07020",
      "CodFisco": "F667",
      "Abitanti": 2483,
      "Link": "http://www.comuni-italiani.it/104/016/"
    },
    {
      "Istat": "040028",
      "Comune": "Montiano",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": 47020,
      "CodFisco": "F668",
      "Abitanti": 1710,
      "Link": "http://www.comuni-italiani.it/040/028/"
    },
    {
      "Istat": "017112",
      "Comune": "Monticelli Brusati",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25040,
      "CodFisco": "F672",
      "Abitanti": 4388,
      "Link": "http://www.comuni-italiani.it/017/112/"
    },
    {
      "Istat": "033027",
      "Comune": "Monticelli d'Ongina",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "F671",
      "Abitanti": 5471,
      "Link": "http://www.comuni-italiani.it/033/027/"
    },
    {
      "Istat": "018099",
      "Comune": "Monticelli Pavese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "F670",
      "Abitanti": 737,
      "Link": "http://www.comuni-italiani.it/018/099/"
    },
    {
      "Istat": "097054",
      "Comune": "Monticello Brianza",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23876,
      "CodFisco": "F674",
      "Abitanti": 4218,
      "Link": "http://www.comuni-italiani.it/097/054/"
    },
    {
      "Istat": "024067",
      "Comune": "Monticello Conte Otto",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36010,
      "CodFisco": "F675",
      "Abitanti": 9280,
      "Link": "http://www.comuni-italiani.it/024/067/"
    },
    {
      "Istat": "004142",
      "Comune": "Monticello d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12066,
      "CodFisco": "F669",
      "Abitanti": 2220,
      "Link": "http://www.comuni-italiani.it/004/142/"
    },
    {
      "Istat": "017113",
      "Comune": "Montichiari",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25018,
      "CodFisco": "F471",
      "Abitanti": 23339,
      "Link": "http://www.comuni-italiani.it/017/113/"
    },
    {
      "Istat": "052018",
      "Comune": "Monticiano",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53015,
      "CodFisco": "F676",
      "Abitanti": 1565,
      "Link": "http://www.comuni-italiani.it/052/018/"
    },
    {
      "Istat": "053017",
      "Comune": "Montieri",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0566",
      "CAP": 58026,
      "CodFisco": "F677",
      "Abitanti": 1250,
      "Link": "http://www.comuni-italiani.it/053/017/"
    },
    {
      "Istat": "005121",
      "Comune": "Montiglio Monferrato",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14026,
      "CodFisco": "M302",
      "Abitanti": 1689,
      "Link": "http://www.comuni-italiani.it/005/121/"
    },
    {
      "Istat": "045011",
      "Comune": "Montignoso",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0585",
      "CAP": 54038,
      "CodFisco": "F679",
      "Abitanti": 10549,
      "Link": "http://www.comuni-italiani.it/045/011/"
    },
    {
      "Istat": "017114",
      "Comune": "Montirone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "F680",
      "Abitanti": 5094,
      "Link": "http://www.comuni-italiani.it/017/114/"
    },
    {
      "Istat": "007043",
      "Comune": "Montjovet",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "F367",
      "Abitanti": 1849,
      "Link": "http://www.comuni-italiani.it/007/043/"
    },
    {
      "Istat": "019059",
      "Comune": "Montodine",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "F681",
      "Abitanti": 2609,
      "Link": "http://www.comuni-italiani.it/019/059/"
    },
    {
      "Istat": "010039",
      "Comune": "Montoggio",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16026,
      "CodFisco": "F682",
      "Abitanti": 2111,
      "Link": "http://www.comuni-italiani.it/010/039/"
    },
    {
      "Istat": "054033",
      "Comune": "Montone",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06014",
      "CodFisco": "F685",
      "Abitanti": 1675,
      "Link": "http://www.comuni-italiani.it/054/033/"
    },
    {
      "Istat": "057044",
      "Comune": "Montopoli di Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02034",
      "CodFisco": "F687",
      "Abitanti": 4274,
      "Link": "http://www.comuni-italiani.it/057/044/"
    },
    {
      "Istat": "050022",
      "Comune": "Montopoli in Val d'Arno",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 56020,
      "CodFisco": "F686",
      "Abitanti": 11219,
      "Link": "http://www.comuni-italiani.it/050/022/"
    },
    {
      "Istat": "013157",
      "Comune": "Montorfano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "F688",
      "Abitanti": 2696,
      "Link": "http://www.comuni-italiani.it/013/157/"
    },
    {
      "Istat": "067028",
      "Comune": "Montorio al Vomano",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64046,
      "CodFisco": "F690",
      "Abitanti": 8283,
      "Link": "http://www.comuni-italiani.it/067/028/"
    },
    {
      "Istat": "070047",
      "Comune": "Montorio nei Frentani",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "F689",
      "Abitanti": 474,
      "Link": "http://www.comuni-italiani.it/070/047/"
    },
    {
      "Istat": "058066",
      "Comune": "Montorio Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "F692",
      "Abitanti": 2035,
      "Link": "http://www.comuni-italiani.it/058/066/"
    },
    {
      "Istat": "064061",
      "Comune": "Montoro Inferiore",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83025,
      "CodFisco": "F693",
      "Abitanti": 10529,
      "Link": "http://www.comuni-italiani.it/064/061/"
    },
    {
      "Istat": "064062",
      "Comune": "Montoro Superiore",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83026,
      "CodFisco": "F694",
      "Abitanti": 8844,
      "Link": "http://www.comuni-italiani.it/064/062/"
    },
    {
      "Istat": "024068",
      "Comune": "Montorso Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "F696",
      "Abitanti": 3186,
      "Link": "http://www.comuni-italiani.it/024/068/"
    },
    {
      "Istat": 109027,
      "Comune": "Montottone",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63843,
      "CodFisco": "F697",
      "Abitanti": 1013,
      "Link": "http://www.comuni-italiani.it/109/027/"
    },
    {
      "Istat": "095085",
      "Comune": "Montresta",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "F698",
      "Abitanti": 559,
      "Link": "http://www.comuni-italiani.it/095/085/"
    },
    {
      "Istat": "018100",
      "Comune": "Mont˘ Beccaria",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "F701",
      "Abitanti": 1772,
      "Link": "http://www.comuni-italiani.it/018/100/"
    },
    {
      "Istat": "012104",
      "Comune": "Monvalle",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "F703",
      "Abitanti": 1991,
      "Link": "http://www.comuni-italiani.it/012/104/"
    },
    {
      "Istat": 108033,
      "Comune": "Monza",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20900,
      "CodFisco": "F704",
      "Abitanti": 122712,
      "Link": "http://www.comuni-italiani.it/108/033/"
    },
    {
      "Istat": "020036",
      "Comune": "Monzambano",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "F705",
      "Abitanti": 4859,
      "Link": "http://www.comuni-italiani.it/020/036/"
    },
    {
      "Istat": "037044",
      "Comune": "Monzuno",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40036,
      "CodFisco": "F706",
      "Abitanti": 6500,
      "Link": "http://www.comuni-italiani.it/037/044/"
    },
    {
      "Istat": "078083",
      "Comune": "Morano Calabro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87016,
      "CodFisco": "F708",
      "Abitanti": 4795,
      "Link": "http://www.comuni-italiani.it/078/083/"
    },
    {
      "Istat": "006109",
      "Comune": "Morano sul Po",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15025,
      "CodFisco": "F707",
      "Abitanti": 1526,
      "Link": "http://www.comuni-italiani.it/006/109/"
    },
    {
      "Istat": "005079",
      "Comune": "Moransengo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14023,
      "CodFisco": "F709",
      "Abitanti": 206,
      "Link": "http://www.comuni-italiani.it/005/079/"
    },
    {
      "Istat": "031013",
      "Comune": "Moraro",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "F710",
      "Abitanti": 761,
      "Link": "http://www.comuni-italiani.it/031/013/"
    },
    {
      "Istat": "012105",
      "Comune": "Morazzone",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21040,
      "CodFisco": "F711",
      "Abitanti": 4375,
      "Link": "http://www.comuni-italiani.it/012/105/"
    },
    {
      "Istat": "014045",
      "Comune": "Morbegno",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23017,
      "CodFisco": "F712",
      "Abitanti": 12071,
      "Link": "http://www.comuni-italiani.it/014/045/"
    },
    {
      "Istat": "006110",
      "Comune": "Morbello",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "F713",
      "Abitanti": 434,
      "Link": "http://www.comuni-italiani.it/006/110/"
    },
    {
      "Istat": "075050",
      "Comune": "Morciano di Leuca",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "F716",
      "Abitanti": 3460,
      "Link": "http://www.comuni-italiani.it/075/050/"
    },
    {
      "Istat": "099011",
      "Comune": "Morciano di Romagna",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47833,
      "CodFisco": "F715",
      "Abitanti": 6988,
      "Link": "http://www.comuni-italiani.it/099/011/"
    },
    {
      "Istat": "062044",
      "Comune": "Morcone",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82026,
      "CodFisco": "F717",
      "Abitanti": 5150,
      "Link": "http://www.comuni-italiani.it/062/044/"
    },
    {
      "Istat": "037045",
      "Comune": "Mordano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0542",
      "CAP": 40027,
      "CodFisco": "F718",
      "Abitanti": 4725,
      "Link": "http://www.comuni-italiani.it/037/045/"
    },
    {
      "Istat": "016140",
      "Comune": "Morengo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "F720",
      "Abitanti": 2600,
      "Link": "http://www.comuni-italiani.it/016/140/"
    },
    {
      "Istat": "090042",
      "Comune": "Mores",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07013",
      "CodFisco": "F721",
      "Abitanti": 1982,
      "Link": "http://www.comuni-italiani.it/090/042/"
    },
    {
      "Istat": 109028,
      "Comune": "Moresco",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63826,
      "CodFisco": "F722",
      "Abitanti": 621,
      "Link": "http://www.comuni-italiani.it/109/028/"
    },
    {
      "Istat": "004143",
      "Comune": "Moretta",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12033,
      "CodFisco": "F723",
      "Abitanti": 4274,
      "Link": "http://www.comuni-italiani.it/004/143/"
    },
    {
      "Istat": "033028",
      "Comune": "Morfasso",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "F724",
      "Abitanti": 1131,
      "Link": "http://www.comuni-italiani.it/033/028/"
    },
    {
      "Istat": "026047",
      "Comune": "Morgano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31050,
      "CodFisco": "F725",
      "Abitanti": 4343,
      "Link": "http://www.comuni-italiani.it/026/047/"
    },
    {
      "Istat": "007044",
      "Comune": "Morgex",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11017,
      "CodFisco": "F726",
      "Abitanti": 2069,
      "Link": "http://www.comuni-italiani.it/007/044/"
    },
    {
      "Istat": "095030",
      "Comune": "Morgongiori",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "F727",
      "Abitanti": 797,
      "Link": "http://www.comuni-italiani.it/095/030/"
    },
    {
      "Istat": "022123",
      "Comune": "Mori",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38065,
      "CodFisco": "F728",
      "Abitanti": 9538,
      "Link": "http://www.comuni-italiani.it/022/123/"
    },
    {
      "Istat": "026048",
      "Comune": "Moriago della Battaglia",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31010,
      "CodFisco": "F729",
      "Abitanti": 2782,
      "Link": "http://www.comuni-italiani.it/026/048/"
    },
    {
      "Istat": "058067",
      "Comune": "Moricone",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "F730",
      "Abitanti": 2748,
      "Link": "http://www.comuni-italiani.it/058/067/"
    },
    {
      "Istat": "065077",
      "Comune": "Morigerati",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84030,
      "CodFisco": "F731",
      "Abitanti": 736,
      "Link": "http://www.comuni-italiani.it/065/077/"
    },
    {
      "Istat": "015150",
      "Comune": "Morimondo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20081,
      "CodFisco": "D033",
      "Abitanti": 1211,
      "Link": "http://www.comuni-italiani.it/015/150/"
    },
    {
      "Istat": "066057",
      "Comune": "Morino",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "F732",
      "Abitanti": 1531,
      "Link": "http://www.comuni-italiani.it/066/057/"
    },
    {
      "Istat": "001163",
      "Comune": "Moriondo Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "F733",
      "Abitanti": 810,
      "Link": "http://www.comuni-italiani.it/001/163/"
    },
    {
      "Istat": "058068",
      "Comune": "Morlupo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00067",
      "CodFisco": "F734",
      "Abitanti": 8486,
      "Link": "http://www.comuni-italiani.it/058/068/"
    },
    {
      "Istat": "078084",
      "Comune": "Mormanno",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87026,
      "CodFisco": "F735",
      "Abitanti": 3326,
      "Link": "http://www.comuni-italiani.it/078/084/"
    },
    {
      "Istat": "012106",
      "Comune": "Mornago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21020,
      "CodFisco": "F736",
      "Abitanti": 4873,
      "Link": "http://www.comuni-italiani.it/012/106/"
    },
    {
      "Istat": "006111",
      "Comune": "Mornese",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15075,
      "CodFisco": "F737",
      "Abitanti": 738,
      "Link": "http://www.comuni-italiani.it/006/111/"
    },
    {
      "Istat": "016141",
      "Comune": "Mornico al Serio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "F738",
      "Abitanti": 2897,
      "Link": "http://www.comuni-italiani.it/016/141/"
    },
    {
      "Istat": "018101",
      "Comune": "Mornico Losana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "F739",
      "Abitanti": 732,
      "Link": "http://www.comuni-italiani.it/018/101/"
    },
    {
      "Istat": "060045",
      "Comune": "Morolo",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03017",
      "CodFisco": "F740",
      "Abitanti": 3299,
      "Link": "http://www.comuni-italiani.it/060/045/"
    },
    {
      "Istat": "004144",
      "Comune": "Morozzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12040,
      "CodFisco": "F743",
      "Abitanti": 2120,
      "Link": "http://www.comuni-italiani.it/004/144/"
    },
    {
      "Istat": "064063",
      "Comune": "Morra De Sanctis",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83040,
      "CodFisco": "F744",
      "Abitanti": 1328,
      "Link": "http://www.comuni-italiani.it/064/063/"
    },
    {
      "Istat": "042031",
      "Comune": "Morro d'Alba",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "F745",
      "Abitanti": 1970,
      "Link": "http://www.comuni-italiani.it/042/031/"
    },
    {
      "Istat": "067029",
      "Comune": "Morro d'Oro",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64020,
      "CodFisco": "F747",
      "Abitanti": 3698,
      "Link": "http://www.comuni-italiani.it/067/029/"
    },
    {
      "Istat": "057045",
      "Comune": "Morro Reatino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "F746",
      "Abitanti": 371,
      "Link": "http://www.comuni-italiani.it/057/045/"
    },
    {
      "Istat": "070048",
      "Comune": "Morrone del Sannio",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "F748",
      "Abitanti": 666,
      "Link": "http://www.comuni-italiani.it/070/048/"
    },
    {
      "Istat": "043033",
      "Comune": "Morrovalle",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F749",
      "Abitanti": 10339,
      "Link": "http://www.comuni-italiani.it/043/033/"
    },
    {
      "Istat": "093028",
      "Comune": "Morsano al Tagliamento",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33075,
      "CodFisco": "F750",
      "Abitanti": 2887,
      "Link": "http://www.comuni-italiani.it/093/028/"
    },
    {
      "Istat": "006112",
      "Comune": "Morsasco",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "F751",
      "Abitanti": 704,
      "Link": "http://www.comuni-italiani.it/006/112/"
    },
    {
      "Istat": "018102",
      "Comune": "Mortara",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27036,
      "CodFisco": "F754",
      "Abitanti": 15673,
      "Link": "http://www.comuni-italiani.it/018/102/"
    },
    {
      "Istat": "030062",
      "Comune": "Mortegliano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "F756",
      "Abitanti": 5093,
      "Link": "http://www.comuni-italiani.it/030/062/"
    },
    {
      "Istat": "097055",
      "Comune": "Morterone",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23811,
      "CodFisco": "F758",
      "Abitanti": 37,
      "Link": "http://www.comuni-italiani.it/097/055/"
    },
    {
      "Istat": "030063",
      "Comune": "Moruzzo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "F760",
      "Abitanti": 2366,
      "Link": "http://www.comuni-italiani.it/030/063/"
    },
    {
      "Istat": "019060",
      "Comune": "Moscazzano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "F761",
      "Abitanti": 841,
      "Link": "http://www.comuni-italiani.it/019/060/"
    },
    {
      "Istat": "064064",
      "Comune": "Moschiano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "F762",
      "Abitanti": 1701,
      "Link": "http://www.comuni-italiani.it/064/064/"
    },
    {
      "Istat": "067030",
      "Comune": "Mosciano Sant'Angelo",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64023,
      "CodFisco": "F764",
      "Abitanti": 9230,
      "Link": "http://www.comuni-italiani.it/067/030/"
    },
    {
      "Istat": "068025",
      "Comune": "Moscufo",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "F765",
      "Abitanti": 3274,
      "Link": "http://www.comuni-italiani.it/068/025/"
    },
    {
      "Istat": "021054",
      "Comune": "Moso in Passiria",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39013,
      "CodFisco": "F766",
      "Abitanti": 2168,
      "Link": "http://www.comuni-italiani.it/021/054/"
    },
    {
      "Istat": "031014",
      "Comune": "Mossa",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "F767",
      "Abitanti": 1674,
      "Link": "http://www.comuni-italiani.it/031/014/"
    },
    {
      "Istat": "024069",
      "Comune": "Mossano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36024,
      "CodFisco": "F768",
      "Abitanti": 1791,
      "Link": "http://www.comuni-italiani.it/024/069/"
    },
    {
      "Istat": "096084",
      "Comune": "Mosso",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13822,
      "CodFisco": "M304",
      "Abitanti": 1659,
      "Link": "http://www.comuni-italiani.it/096/084/"
    },
    {
      "Istat": "019061",
      "Comune": "Motta Baluffi",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26045,
      "CodFisco": "F771",
      "Abitanti": 1000,
      "Link": "http://www.comuni-italiani.it/019/061/"
    },
    {
      "Istat": "083058",
      "Comune": "Motta Camastra",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "F772",
      "Abitanti": 894,
      "Link": "http://www.comuni-italiani.it/083/058/"
    },
    {
      "Istat": "083059",
      "Comune": "Motta d'Affermo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98070,
      "CodFisco": "F773",
      "Abitanti": 850,
      "Link": "http://www.comuni-italiani.it/083/059/"
    },
    {
      "Istat": "002082",
      "Comune": "Motta de' Conti",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13010,
      "CodFisco": "F774",
      "Abitanti": 812,
      "Link": "http://www.comuni-italiani.it/002/082/"
    },
    {
      "Istat": "026049",
      "Comune": "Motta di Livenza",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31045,
      "CodFisco": "F770",
      "Abitanti": 10738,
      "Link": "http://www.comuni-italiani.it/026/049/"
    },
    {
      "Istat": "071034",
      "Comune": "Motta Montecorvino",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71030,
      "CodFisco": "F777",
      "Abitanti": 798,
      "Link": "http://www.comuni-italiani.it/071/034/"
    },
    {
      "Istat": "080054",
      "Comune": "Motta San Giovanni",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89065,
      "CodFisco": "F779",
      "Abitanti": 6317,
      "Link": "http://www.comuni-italiani.it/080/054/"
    },
    {
      "Istat": "079083",
      "Comune": "Motta Santa Lucia",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "F780",
      "Abitanti": 878,
      "Link": "http://www.comuni-italiani.it/079/083/"
    },
    {
      "Istat": "087030",
      "Comune": "Motta Sant'Anastasia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95040,
      "CodFisco": "F781",
      "Abitanti": 11924,
      "Link": "http://www.comuni-italiani.it/087/030/"
    },
    {
      "Istat": "015151",
      "Comune": "Motta Visconti",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20086,
      "CodFisco": "F783",
      "Abitanti": 7665,
      "Link": "http://www.comuni-italiani.it/015/151/"
    },
    {
      "Istat": "078085",
      "Comune": "Mottafollone",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "F775",
      "Abitanti": 1317,
      "Link": "http://www.comuni-italiani.it/078/085/"
    },
    {
      "Istat": "096037",
      "Comune": "Mottalciata",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13874,
      "CodFisco": "F776",
      "Abitanti": 1469,
      "Link": "http://www.comuni-italiani.it/096/037/"
    },
    {
      "Istat": "020037",
      "Comune": "Motteggiana",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46020,
      "CodFisco": "B012",
      "Abitanti": 2576,
      "Link": "http://www.comuni-italiani.it/020/037/"
    },
    {
      "Istat": "073019",
      "Comune": "Mottola",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74017,
      "CodFisco": "F784",
      "Abitanti": 16333,
      "Link": "http://www.comuni-italiani.it/073/019/"
    },
    {
      "Istat": "069056",
      "Comune": "Mozzagrogna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66030,
      "CodFisco": "F785",
      "Abitanti": 2308,
      "Link": "http://www.comuni-italiani.it/069/056/"
    },
    {
      "Istat": "016142",
      "Comune": "Mozzanica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "F786",
      "Abitanti": 4619,
      "Link": "http://www.comuni-italiani.it/016/142/"
    },
    {
      "Istat": "013159",
      "Comune": "Mozzate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 22076,
      "CodFisco": "F788",
      "Abitanti": 8367,
      "Link": "http://www.comuni-italiani.it/013/159/"
    },
    {
      "Istat": "023051",
      "Comune": "Mozzecane",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "F789",
      "Abitanti": 7019,
      "Link": "http://www.comuni-italiani.it/023/051/"
    },
    {
      "Istat": "016143",
      "Comune": "Mozzo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "F791",
      "Abitanti": 7488,
      "Link": "http://www.comuni-italiani.it/016/143/"
    },
    {
      "Istat": "043034",
      "Comune": "Muccia",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62034,
      "CodFisco": "F793",
      "Abitanti": 930,
      "Link": "http://www.comuni-italiani.it/043/034/"
    },
    {
      "Istat": "032003",
      "Comune": "Muggia",
      "Provincia": "TS",
      "Regione": "FVG",
      "Prefisso": "040",
      "CAP": 34015,
      "CodFisco": "F795",
      "Abitanti": 13410,
      "Link": "http://www.comuni-italiani.it/032/003/"
    },
    {
      "Istat": 108034,
      "Comune": "MuggiÚ",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20835,
      "CodFisco": "F797",
      "Abitanti": 23433,
      "Link": "http://www.comuni-italiani.it/108/034/"
    },
    {
      "Istat": "064065",
      "Comune": "Mugnano del Cardinale",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83027,
      "CodFisco": "F798",
      "Abitanti": 5376,
      "Link": "http://www.comuni-italiani.it/064/065/"
    },
    {
      "Istat": "063048",
      "Comune": "Mugnano di Napoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80018,
      "CodFisco": "F799",
      "Abitanti": 34582,
      "Link": "http://www.comuni-italiani.it/063/048/"
    },
    {
      "Istat": "098041",
      "Comune": "Mulazzano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26837,
      "CodFisco": "F801",
      "Abitanti": 5768,
      "Link": "http://www.comuni-italiani.it/098/041/"
    },
    {
      "Istat": "045012",
      "Comune": "Mulazzo",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54026,
      "CodFisco": "F802",
      "Abitanti": 2642,
      "Link": "http://www.comuni-italiani.it/045/012/"
    },
    {
      "Istat": "017115",
      "Comune": "Mura",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "F806",
      "Abitanti": 791,
      "Link": "http://www.comuni-italiani.it/017/115/"
    },
    {
      "Istat": "092039",
      "Comune": "Muravera",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09043",
      "CodFisco": "F808",
      "Abitanti": 5283,
      "Link": "http://www.comuni-italiani.it/092/039/"
    },
    {
      "Istat": "004145",
      "Comune": "Murazzano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "F809",
      "Abitanti": 870,
      "Link": "http://www.comuni-italiani.it/004/145/"
    },
    {
      "Istat": "004146",
      "Comune": "Murello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "F811",
      "Abitanti": 962,
      "Link": "http://www.comuni-italiani.it/004/146/"
    },
    {
      "Istat": "009040",
      "Comune": "Murialdo",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17013,
      "CodFisco": "F813",
      "Abitanti": 864,
      "Link": "http://www.comuni-italiani.it/009/040/"
    },
    {
      "Istat": "006113",
      "Comune": "Murisengo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 15020,
      "CodFisco": "F814",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/006/113/"
    },
    {
      "Istat": "052019",
      "Comune": "Murlo",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53016,
      "CodFisco": "F815",
      "Abitanti": 2427,
      "Link": "http://www.comuni-italiani.it/052/019/"
    },
    {
      "Istat": "075051",
      "Comune": "Muro Leccese",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73036,
      "CodFisco": "F816",
      "Abitanti": 5123,
      "Link": "http://www.comuni-italiani.it/075/051/"
    },
    {
      "Istat": "076053",
      "Comune": "Muro Lucano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85054,
      "CodFisco": "F817",
      "Abitanti": 5711,
      "Link": "http://www.comuni-italiani.it/076/053/"
    },
    {
      "Istat": "090043",
      "Comune": "Muros",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "F818",
      "Abitanti": 844,
      "Link": "http://www.comuni-italiani.it/090/043/"
    },
    {
      "Istat": "017116",
      "Comune": "Muscoline",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "F820",
      "Abitanti": 2573,
      "Link": "http://www.comuni-italiani.it/017/116/"
    },
    {
      "Istat": 107011,
      "Comune": "Musei",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "F822",
      "Abitanti": 1522,
      "Link": "http://www.comuni-italiani.it/107/011/"
    },
    {
      "Istat": "027025",
      "Comune": "Musile di Piave",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30024,
      "CodFisco": "F826",
      "Abitanti": 11578,
      "Link": "http://www.comuni-italiani.it/027/025/"
    },
    {
      "Istat": "013160",
      "Comune": "Musso",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "F828",
      "Abitanti": 1017,
      "Link": "http://www.comuni-italiani.it/013/160/"
    },
    {
      "Istat": "024070",
      "Comune": "Mussolente",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36065,
      "CodFisco": "F829",
      "Abitanti": 7760,
      "Link": "http://www.comuni-italiani.it/024/070/"
    },
    {
      "Istat": "085012",
      "Comune": "Mussomeli",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93014,
      "CodFisco": "F830",
      "Abitanti": 11145,
      "Link": "http://www.comuni-italiani.it/085/012/"
    },
    {
      "Istat": "030064",
      "Comune": "Muzzana del Turgnano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33055,
      "CodFisco": "F832",
      "Abitanti": 2673,
      "Link": "http://www.comuni-italiani.it/030/064/"
    },
    {
      "Istat": "096038",
      "Comune": "Muzzano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13895,
      "CodFisco": "F833",
      "Abitanti": 613,
      "Link": "http://www.comuni-italiani.it/096/038/"
    },
    {
      "Istat": "022124",
      "Comune": "Nago-Torbole",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38069,
      "CodFisco": "F835",
      "Abitanti": 2793,
      "Link": "http://www.comuni-italiani.it/022/124/"
    },
    {
      "Istat": "021055",
      "Comune": "Nalles",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39010,
      "CodFisco": "F836",
      "Abitanti": 1826,
      "Link": "http://www.comuni-italiani.it/021/055/"
    },
    {
      "Istat": "022125",
      "Comune": "Nanno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "F837",
      "Abitanti": 632,
      "Link": "http://www.comuni-italiani.it/022/125/"
    },
    {
      "Istat": "024071",
      "Comune": "Nanto",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36024,
      "CodFisco": "F838",
      "Abitanti": 3059,
      "Link": "http://www.comuni-italiani.it/024/071/"
    },
    {
      "Istat": "063049",
      "Comune": "Napoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": "801xx",
      "CodFisco": "F839",
      "Abitanti": 959574,
      "Link": "http://www.comuni-italiani.it/063/049/"
    },
    {
      "Istat": "095031",
      "Comune": "Narbolia",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "F840",
      "Abitanti": 1802,
      "Link": "http://www.comuni-italiani.it/095/031/"
    },
    {
      "Istat": 107012,
      "Comune": "Narcao",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "F841",
      "Abitanti": 3390,
      "Link": "http://www.comuni-italiani.it/107/012/"
    },
    {
      "Istat": "075052",
      "Comune": "NardÚ",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73048,
      "CodFisco": "F842",
      "Abitanti": 31952,
      "Link": "http://www.comuni-italiani.it/075/052/"
    },
    {
      "Istat": 102024,
      "Comune": "Nardodipace",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89824,
      "CodFisco": "F843",
      "Abitanti": 1414,
      "Link": "http://www.comuni-italiani.it/102/024/"
    },
    {
      "Istat": "055022",
      "Comune": "Narni",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05035",
      "CodFisco": "F844",
      "Abitanti": 20331,
      "Link": "http://www.comuni-italiani.it/055/022/"
    },
    {
      "Istat": "084026",
      "Comune": "Naro",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92028,
      "CodFisco": "F845",
      "Abitanti": 8245,
      "Link": "http://www.comuni-italiani.it/084/026/"
    },
    {
      "Istat": "004147",
      "Comune": "Narzole",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12068,
      "CodFisco": "F846",
      "Abitanti": 3572,
      "Link": "http://www.comuni-italiani.it/004/147/"
    },
    {
      "Istat": "009041",
      "Comune": "Nasino",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17030,
      "CodFisco": "F847",
      "Abitanti": 227,
      "Link": "http://www.comuni-italiani.it/009/041/"
    },
    {
      "Istat": "083060",
      "Comune": "Naso",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98074,
      "CodFisco": "F848",
      "Abitanti": 4107,
      "Link": "http://www.comuni-italiani.it/083/060/"
    },
    {
      "Istat": "021056",
      "Comune": "Naturno",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39025,
      "CodFisco": "F849",
      "Abitanti": 5554,
      "Link": "http://www.comuni-italiani.it/021/056/"
    },
    {
      "Istat": "017117",
      "Comune": "Nave",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25075,
      "CodFisco": "F851",
      "Abitanti": 11142,
      "Link": "http://www.comuni-italiani.it/017/117/"
    },
    {
      "Istat": "022126",
      "Comune": "Nave San Rocco",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "F853",
      "Abitanti": 1399,
      "Link": "http://www.comuni-italiani.it/022/126/"
    },
    {
      "Istat": "066058",
      "Comune": "Navelli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "F852",
      "Abitanti": 594,
      "Link": "http://www.comuni-italiani.it/066/058/"
    },
    {
      "Istat": "021057",
      "Comune": "Naz-Sciaves",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "F856",
      "Abitanti": 2894,
      "Link": "http://www.comuni-italiani.it/021/057/"
    },
    {
      "Istat": "058069",
      "Comune": "Nazzano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "00060",
      "CodFisco": "F857",
      "Abitanti": 1368,
      "Link": "http://www.comuni-italiani.it/058/069/"
    },
    {
      "Istat": "010040",
      "Comune": "Ne",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "F858",
      "Abitanti": 2432,
      "Link": "http://www.comuni-italiani.it/010/040/"
    },
    {
      "Istat": "003103",
      "Comune": "Nebbiuno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "F859",
      "Abitanti": 1854,
      "Link": "http://www.comuni-italiani.it/003/103/"
    },
    {
      "Istat": "023052",
      "Comune": "Negrar",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37024,
      "CodFisco": "F861",
      "Abitanti": 17232,
      "Link": "http://www.comuni-italiani.it/023/052/"
    },
    {
      "Istat": "010041",
      "Comune": "Neirone",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "F862",
      "Abitanti": 970,
      "Link": "http://www.comuni-italiani.it/010/041/"
    },
    {
      "Istat": "004148",
      "Comune": "Neive",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12052,
      "CodFisco": "F863",
      "Abitanti": 3374,
      "Link": "http://www.comuni-italiani.it/004/148/"
    },
    {
      "Istat": "016144",
      "Comune": "Nembro",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24027,
      "CodFisco": "F864",
      "Abitanti": 11636,
      "Link": "http://www.comuni-italiani.it/016/144/"
    },
    {
      "Istat": "058070",
      "Comune": "Nemi",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "F865",
      "Abitanti": 2026,
      "Link": "http://www.comuni-italiani.it/058/070/"
    },
    {
      "Istat": "076054",
      "Comune": "Nemoli",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85040,
      "CodFisco": "F866",
      "Abitanti": 1519,
      "Link": "http://www.comuni-italiani.it/076/054/"
    },
    {
      "Istat": "095032",
      "Comune": "Neoneli",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "F867",
      "Abitanti": 717,
      "Link": "http://www.comuni-italiani.it/095/032/"
    },
    {
      "Istat": "056039",
      "Comune": "Nepi",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01036",
      "CodFisco": "F868",
      "Abitanti": 9684,
      "Link": "http://www.comuni-italiani.it/056/039/"
    },
    {
      "Istat": "067031",
      "Comune": "Nereto",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64015,
      "CodFisco": "F870",
      "Abitanti": 5141,
      "Link": "http://www.comuni-italiani.it/067/031/"
    },
    {
      "Istat": "058071",
      "Comune": "Nerola",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00017",
      "CodFisco": "F871",
      "Abitanti": 1811,
      "Link": "http://www.comuni-italiani.it/058/071/"
    },
    {
      "Istat": "026050",
      "Comune": "Nervesa della Battaglia",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "F872",
      "Abitanti": 6948,
      "Link": "http://www.comuni-italiani.it/026/050/"
    },
    {
      "Istat": "015154",
      "Comune": "Nerviano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20014,
      "CodFisco": "F874",
      "Abitanti": 17499,
      "Link": "http://www.comuni-italiani.it/015/154/"
    },
    {
      "Istat": "057046",
      "Comune": "Nespolo",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "F876",
      "Abitanti": 281,
      "Link": "http://www.comuni-italiani.it/057/046/"
    },
    {
      "Istat": "013161",
      "Comune": "Nesso",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "F877",
      "Abitanti": 1273,
      "Link": "http://www.comuni-italiani.it/013/161/"
    },
    {
      "Istat": "096039",
      "Comune": "Netro",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13896,
      "CodFisco": "F878",
      "Abitanti": 995,
      "Link": "http://www.comuni-italiani.it/096/039/"
    },
    {
      "Istat": "058072",
      "Comune": "Nettuno",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00048",
      "CodFisco": "F880",
      "Abitanti": 47332,
      "Link": "http://www.comuni-italiani.it/058/072/"
    },
    {
      "Istat": "075053",
      "Comune": "Neviano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73040,
      "CodFisco": "F881",
      "Abitanti": 5533,
      "Link": "http://www.comuni-italiani.it/075/053/"
    },
    {
      "Istat": "034024",
      "Comune": "Neviano degli Arduini",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43024,
      "CodFisco": "F882",
      "Abitanti": 3750,
      "Link": "http://www.comuni-italiani.it/034/024/"
    },
    {
      "Istat": "004149",
      "Comune": "Neviglie",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "F883",
      "Abitanti": 428,
      "Link": "http://www.comuni-italiani.it/004/149/"
    },
    {
      "Istat": "017118",
      "Comune": "Niardo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "F884",
      "Abitanti": 1944,
      "Link": "http://www.comuni-italiani.it/017/118/"
    },
    {
      "Istat": "033029",
      "Comune": "Nibbiano",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "F885",
      "Abitanti": 2294,
      "Link": "http://www.comuni-italiani.it/033/029/"
    },
    {
      "Istat": "003104",
      "Comune": "Nibbiola",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28070,
      "CodFisco": "F886",
      "Abitanti": 781,
      "Link": "http://www.comuni-italiani.it/003/104/"
    },
    {
      "Istat": "097056",
      "Comune": "Nibionno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23895,
      "CodFisco": "F887",
      "Abitanti": 3628,
      "Link": "http://www.comuni-italiani.it/097/056/"
    },
    {
      "Istat": "001164",
      "Comune": "Nichelino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10042,
      "CodFisco": "F889",
      "Abitanti": 48946,
      "Link": "http://www.comuni-italiani.it/001/164/"
    },
    {
      "Istat": "087031",
      "Comune": "Nicolosi",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "F890",
      "Abitanti": 7229,
      "Link": "http://www.comuni-italiani.it/087/031/"
    },
    {
      "Istat": "018103",
      "Comune": "Nicorvo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "F891",
      "Abitanti": 382,
      "Link": "http://www.comuni-italiani.it/018/103/"
    },
    {
      "Istat": "086012",
      "Comune": "Nicosia",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94014,
      "CodFisco": "F892",
      "Abitanti": 14547,
      "Link": "http://www.comuni-italiani.it/086/012/"
    },
    {
      "Istat": 102025,
      "Comune": "Nicotera",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89844,
      "CodFisco": "F893",
      "Abitanti": 6346,
      "Link": "http://www.comuni-italiani.it/102/025/"
    },
    {
      "Istat": "004150",
      "Comune": "Niella Belbo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "F894",
      "Abitanti": 409,
      "Link": "http://www.comuni-italiani.it/004/150/"
    },
    {
      "Istat": "004151",
      "Comune": "Niella Tanaro",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "F895",
      "Abitanti": 1060,
      "Link": "http://www.comuni-italiani.it/004/151/"
    },
    {
      "Istat": "030065",
      "Comune": "Nimis",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33045,
      "CodFisco": "F898",
      "Abitanti": 2789,
      "Link": "http://www.comuni-italiani.it/030/065/"
    },
    {
      "Istat": "085013",
      "Comune": "Niscemi",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 93015,
      "CodFisco": "F899",
      "Abitanti": 26496,
      "Link": "http://www.comuni-italiani.it/085/013/"
    },
    {
      "Istat": "086013",
      "Comune": "Nissoria",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "F900",
      "Abitanti": 3011,
      "Link": "http://www.comuni-italiani.it/086/013/"
    },
    {
      "Istat": "083061",
      "Comune": "Nizza di Sicilia",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98026,
      "CodFisco": "F901",
      "Abitanti": 3782,
      "Link": "http://www.comuni-italiani.it/083/061/"
    },
    {
      "Istat": "005080",
      "Comune": "Nizza Monferrato",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14049,
      "CodFisco": "F902",
      "Abitanti": 10391,
      "Link": "http://www.comuni-italiani.it/005/080/"
    },
    {
      "Istat": "027026",
      "Comune": "Noale",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30033,
      "CodFisco": "F904",
      "Abitanti": 15855,
      "Link": "http://www.comuni-italiani.it/027/026/"
    },
    {
      "Istat": "001165",
      "Comune": "Noasca",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "F906",
      "Abitanti": 185,
      "Link": "http://www.comuni-italiani.it/001/165/"
    },
    {
      "Istat": "078086",
      "Comune": "Nocara",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "F907",
      "Abitanti": 443,
      "Link": "http://www.comuni-italiani.it/078/086/"
    },
    {
      "Istat": "068026",
      "Comune": "Nocciano",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "F908",
      "Abitanti": 1851,
      "Link": "http://www.comuni-italiani.it/068/026/"
    },
    {
      "Istat": "065078",
      "Comune": "Nocera Inferiore",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84014,
      "CodFisco": "F912",
      "Abitanti": 45707,
      "Link": "http://www.comuni-italiani.it/065/078/"
    },
    {
      "Istat": "065079",
      "Comune": "Nocera Superiore",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84015,
      "CodFisco": "F913",
      "Abitanti": 24255,
      "Link": "http://www.comuni-italiani.it/065/079/"
    },
    {
      "Istat": "079087",
      "Comune": "Nocera Terinese",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88047,
      "CodFisco": "F910",
      "Abitanti": 4843,
      "Link": "http://www.comuni-italiani.it/079/087/"
    },
    {
      "Istat": "054034",
      "Comune": "Nocera Umbra",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06025",
      "CodFisco": "F911",
      "Abitanti": 6175,
      "Link": "http://www.comuni-italiani.it/054/034/"
    },
    {
      "Istat": "034025",
      "Comune": "Noceto",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43015,
      "CodFisco": "F914",
      "Abitanti": 12724,
      "Link": "http://www.comuni-italiani.it/034/025/"
    },
    {
      "Istat": "072031",
      "Comune": "Noci",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70015,
      "CodFisco": "F915",
      "Abitanti": 19477,
      "Link": "http://www.comuni-italiani.it/072/031/"
    },
    {
      "Istat": "075054",
      "Comune": "Nociglia",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "F916",
      "Abitanti": 2482,
      "Link": "http://www.comuni-italiani.it/075/054/"
    },
    {
      "Istat": "076055",
      "Comune": "Noepoli",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85035,
      "CodFisco": "F917",
      "Abitanti": 988,
      "Link": "http://www.comuni-italiani.it/076/055/"
    },
    {
      "Istat": "023053",
      "Comune": "Nogara",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37054,
      "CodFisco": "F918",
      "Abitanti": 8719,
      "Link": "http://www.comuni-italiani.it/023/053/"
    },
    {
      "Istat": "022127",
      "Comune": "Nogaredo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "F920",
      "Abitanti": 1959,
      "Link": "http://www.comuni-italiani.it/022/127/"
    },
    {
      "Istat": "023054",
      "Comune": "Nogarole Rocca",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "F921",
      "Abitanti": 3448,
      "Link": "http://www.comuni-italiani.it/023/054/"
    },
    {
      "Istat": "024072",
      "Comune": "Nogarole Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36070,
      "CodFisco": "F922",
      "Abitanti": 1142,
      "Link": "http://www.comuni-italiani.it/024/072/"
    },
    {
      "Istat": "072032",
      "Comune": "Noic‡ttaro",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70016,
      "CodFisco": "F923",
      "Abitanti": 25835,
      "Link": "http://www.comuni-italiani.it/072/032/"
    },
    {
      "Istat": "063050",
      "Comune": "Nola",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80035,
      "CodFisco": "F924",
      "Abitanti": 32761,
      "Link": "http://www.comuni-italiani.it/063/050/"
    },
    {
      "Istat": "001166",
      "Comune": "Nole",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10076,
      "CodFisco": "F925",
      "Abitanti": 6907,
      "Link": "http://www.comuni-italiani.it/001/166/"
    },
    {
      "Istat": "009042",
      "Comune": "Noli",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17026,
      "CodFisco": "F926",
      "Abitanti": 2861,
      "Link": "http://www.comuni-italiani.it/009/042/"
    },
    {
      "Istat": "001167",
      "Comune": "Nomaglio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "F927",
      "Abitanti": 320,
      "Link": "http://www.comuni-italiani.it/001/167/"
    },
    {
      "Istat": "022128",
      "Comune": "Nomi",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "F929",
      "Abitanti": 1317,
      "Link": "http://www.comuni-italiani.it/022/128/"
    },
    {
      "Istat": "036027",
      "Comune": "Nonantola",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41015,
      "CodFisco": "F930",
      "Abitanti": 15489,
      "Link": "http://www.comuni-italiani.it/036/027/"
    },
    {
      "Istat": "001168",
      "Comune": "None",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "F931",
      "Abitanti": 8034,
      "Link": "http://www.comuni-italiani.it/001/168/"
    },
    {
      "Istat": 103048,
      "Comune": "Nonio",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28891,
      "CodFisco": "F932",
      "Abitanti": 898,
      "Link": "http://www.comuni-italiani.it/103/048/"
    },
    {
      "Istat": "091050",
      "Comune": "Noragugume",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "F933",
      "Abitanti": 342,
      "Link": "http://www.comuni-italiani.it/091/050/"
    },
    {
      "Istat": "095033",
      "Comune": "Norbello",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09070",
      "CodFisco": "F934",
      "Abitanti": 1179,
      "Link": "http://www.comuni-italiani.it/095/033/"
    },
    {
      "Istat": "054035",
      "Comune": "Norcia",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06046",
      "CodFisco": "F935",
      "Abitanti": 4995,
      "Link": "http://www.comuni-italiani.it/054/035/"
    },
    {
      "Istat": "059016",
      "Comune": "Norma",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "F937",
      "Abitanti": 4103,
      "Link": "http://www.comuni-italiani.it/059/016/"
    },
    {
      "Istat": "015155",
      "Comune": "Nosate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "F939",
      "Abitanti": 689,
      "Link": "http://www.comuni-italiani.it/015/155/"
    },
    {
      "Istat": "067032",
      "Comune": "Notaresco",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64024,
      "CodFisco": "F942",
      "Abitanti": 6979,
      "Link": "http://www.comuni-italiani.it/067/032/"
    },
    {
      "Istat": "089013",
      "Comune": "Noto",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96017,
      "CodFisco": "F943",
      "Abitanti": 24047,
      "Link": "http://www.comuni-italiani.it/089/013/"
    },
    {
      "Istat": "021058",
      "Comune": "Nova Levante",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39056,
      "CodFisco": "F949",
      "Abitanti": 1910,
      "Link": "http://www.comuni-italiani.it/021/058/"
    },
    {
      "Istat": 108035,
      "Comune": "Nova Milanese",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20834,
      "CodFisco": "F944",
      "Abitanti": 23152,
      "Link": "http://www.comuni-italiani.it/108/035/"
    },
    {
      "Istat": "021059",
      "Comune": "Nova Ponente",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39050,
      "CodFisco": "F950",
      "Abitanti": 3902,
      "Link": "http://www.comuni-italiani.it/021/059/"
    },
    {
      "Istat": "077018",
      "Comune": "Nova Siri",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75020,
      "CodFisco": "A942",
      "Abitanti": 6807,
      "Link": "http://www.comuni-italiani.it/077/018/"
    },
    {
      "Istat": "099023",
      "Comune": "Novafeltria",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47863,
      "CodFisco": "F137",
      "Abitanti": 7380,
      "Link": "http://www.comuni-italiani.it/099/023/"
    },
    {
      "Istat": "022129",
      "Comune": "Novaledo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "F947",
      "Abitanti": 1040,
      "Link": "http://www.comuni-italiani.it/022/129/"
    },
    {
      "Istat": "001169",
      "Comune": "Novalesa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "F948",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/001/169/"
    },
    {
      "Istat": "003106",
      "Comune": "Novara",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28100,
      "CodFisco": "F952",
      "Abitanti": 105024,
      "Link": "http://www.comuni-italiani.it/003/106/"
    },
    {
      "Istat": "083062",
      "Comune": "Novara di Sicilia",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98058,
      "CodFisco": "F951",
      "Abitanti": 1447,
      "Link": "http://www.comuni-italiani.it/083/062/"
    },
    {
      "Istat": "014046",
      "Comune": "Novate Mezzola",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23025,
      "CodFisco": "F956",
      "Abitanti": 1863,
      "Link": "http://www.comuni-italiani.it/014/046/"
    },
    {
      "Istat": "015157",
      "Comune": "Novate Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20026,
      "CodFisco": "F955",
      "Abitanti": 20201,
      "Link": "http://www.comuni-italiani.it/015/157/"
    },
    {
      "Istat": "024073",
      "Comune": "Nove",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36055,
      "CodFisco": "F957",
      "Abitanti": 5090,
      "Link": "http://www.comuni-italiani.it/024/073/"
    },
    {
      "Istat": "013163",
      "Comune": "Novedrate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22060,
      "CodFisco": "F958",
      "Abitanti": 2932,
      "Link": "http://www.comuni-italiani.it/013/163/"
    },
    {
      "Istat": "035028",
      "Comune": "Novellara",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42017,
      "CodFisco": "F960",
      "Abitanti": 13858,
      "Link": "http://www.comuni-italiani.it/035/028/"
    },
    {
      "Istat": "004152",
      "Comune": "Novello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "F961",
      "Abitanti": 1034,
      "Link": "http://www.comuni-italiani.it/004/152/"
    },
    {
      "Istat": "027027",
      "Comune": "Noventa di Piave",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "F963",
      "Abitanti": 6849,
      "Link": "http://www.comuni-italiani.it/027/027/"
    },
    {
      "Istat": "028058",
      "Comune": "Noventa Padovana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35027,
      "CodFisco": "F962",
      "Abitanti": 10922,
      "Link": "http://www.comuni-italiani.it/028/058/"
    },
    {
      "Istat": "024074",
      "Comune": "Noventa Vicentina",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36025,
      "CodFisco": "F964",
      "Abitanti": 8923,
      "Link": "http://www.comuni-italiani.it/024/074/"
    },
    {
      "Istat": "036028",
      "Comune": "Novi di Modena",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41016,
      "CodFisco": "F966",
      "Abitanti": 11476,
      "Link": "http://www.comuni-italiani.it/036/028/"
    },
    {
      "Istat": "006114",
      "Comune": "Novi Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15067,
      "CodFisco": "F965",
      "Abitanti": 28744,
      "Link": "http://www.comuni-italiani.it/006/114/"
    },
    {
      "Istat": "065080",
      "Comune": "Novi Velia",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "F967",
      "Abitanti": 2263,
      "Link": "http://www.comuni-italiani.it/065/080/"
    },
    {
      "Istat": "015158",
      "Comune": "Noviglio",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20082,
      "CodFisco": "F968",
      "Abitanti": 4459,
      "Link": "http://www.comuni-italiani.it/015/158/"
    },
    {
      "Istat": "075055",
      "Comune": "Novoli",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73051,
      "CodFisco": "F970",
      "Abitanti": 8200,
      "Link": "http://www.comuni-italiani.it/075/055/"
    },
    {
      "Istat": "004153",
      "Comune": "Nucetto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "F972",
      "Abitanti": 436,
      "Link": "http://www.comuni-italiani.it/004/153/"
    },
    {
      "Istat": "090044",
      "Comune": "Nughedu San NicolÚ",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "F975",
      "Abitanti": 888,
      "Link": "http://www.comuni-italiani.it/090/044/"
    },
    {
      "Istat": "095034",
      "Comune": "Nughedu Santa Vittoria",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "F974",
      "Abitanti": 520,
      "Link": "http://www.comuni-italiani.it/095/034/"
    },
    {
      "Istat": "090045",
      "Comune": "Nule",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "F976",
      "Abitanti": 1443,
      "Link": "http://www.comuni-italiani.it/090/045/"
    },
    {
      "Istat": "090046",
      "Comune": "Nulvi",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07032",
      "CodFisco": "F977",
      "Abitanti": 2877,
      "Link": "http://www.comuni-italiani.it/090/046/"
    },
    {
      "Istat": "042032",
      "Comune": "Numana",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60026,
      "CodFisco": "F978",
      "Abitanti": 3875,
      "Link": "http://www.comuni-italiani.it/042/032/"
    },
    {
      "Istat": "091051",
      "Comune": "Nuoro",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08100",
      "CodFisco": "F979",
      "Abitanti": 36347,
      "Link": "http://www.comuni-italiani.it/091/051/"
    },
    {
      "Istat": "095035",
      "Comune": "Nurachi",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "F980",
      "Abitanti": 1803,
      "Link": "http://www.comuni-italiani.it/095/035/"
    },
    {
      "Istat": "092115",
      "Comune": "Nuragus",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "F981",
      "Abitanti": 968,
      "Link": "http://www.comuni-italiani.it/092/115/"
    },
    {
      "Istat": "092116",
      "Comune": "Nurallao",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "F982",
      "Abitanti": 1356,
      "Link": "http://www.comuni-italiani.it/092/116/"
    },
    {
      "Istat": "092042",
      "Comune": "Nuraminis",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09024",
      "CodFisco": "F983",
      "Abitanti": 2556,
      "Link": "http://www.comuni-italiani.it/092/042/"
    },
    {
      "Istat": "095036",
      "Comune": "Nureci",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "F985",
      "Abitanti": 354,
      "Link": "http://www.comuni-italiani.it/095/036/"
    },
    {
      "Istat": "092117",
      "Comune": "Nurri",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08035",
      "CodFisco": "F986",
      "Abitanti": 2299,
      "Link": "http://www.comuni-italiani.it/092/117/"
    },
    {
      "Istat": "007045",
      "Comune": "Nus",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "F987",
      "Abitanti": 2965,
      "Link": "http://www.comuni-italiani.it/007/045/"
    },
    {
      "Istat": "064066",
      "Comune": "Nusco",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83051,
      "CodFisco": "F988",
      "Abitanti": 4295,
      "Link": "http://www.comuni-italiani.it/064/066/"
    },
    {
      "Istat": "017119",
      "Comune": "Nuvolento",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "F989",
      "Abitanti": 4073,
      "Link": "http://www.comuni-italiani.it/017/119/"
    },
    {
      "Istat": "017120",
      "Comune": "Nuvolera",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "F990",
      "Abitanti": 4651,
      "Link": "http://www.comuni-italiani.it/017/120/"
    },
    {
      "Istat": 107013,
      "Comune": "Nuxis",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "F991",
      "Abitanti": 1657,
      "Link": "http://www.comuni-italiani.it/107/013/"
    },
    {
      "Istat": "096040",
      "Comune": "Occhieppo Inferiore",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13897,
      "CodFisco": "F992",
      "Abitanti": 4009,
      "Link": "http://www.comuni-italiani.it/096/040/"
    },
    {
      "Istat": "096041",
      "Comune": "Occhieppo Superiore",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13898,
      "CodFisco": "F993",
      "Abitanti": 2844,
      "Link": "http://www.comuni-italiani.it/096/041/"
    },
    {
      "Istat": "029033",
      "Comune": "Occhiobello",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "F994",
      "Abitanti": 11569,
      "Link": "http://www.comuni-italiani.it/029/033/"
    },
    {
      "Istat": "006115",
      "Comune": "Occimiano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "F995",
      "Abitanti": 1380,
      "Link": "http://www.comuni-italiani.it/006/115/"
    },
    {
      "Istat": "066059",
      "Comune": "Ocre",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67040,
      "CodFisco": "F996",
      "Abitanti": 1116,
      "Link": "http://www.comuni-italiani.it/066/059/"
    },
    {
      "Istat": "006116",
      "Comune": "Odalengo Grande",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "F997",
      "Abitanti": 520,
      "Link": "http://www.comuni-italiani.it/006/116/"
    },
    {
      "Istat": "006117",
      "Comune": "Odalengo Piccolo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 15020,
      "CodFisco": "F998",
      "Abitanti": 267,
      "Link": "http://www.comuni-italiani.it/006/117/"
    },
    {
      "Istat": "026051",
      "Comune": "Oderzo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31046,
      "CodFisco": "F999",
      "Abitanti": 20272,
      "Link": "http://www.comuni-italiani.it/026/051/"
    },
    {
      "Istat": "017121",
      "Comune": "Odolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25076,
      "CodFisco": "G001",
      "Abitanti": 2104,
      "Link": "http://www.comuni-italiani.it/017/121/"
    },
    {
      "Istat": "066060",
      "Comune": "Ofena",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67025,
      "CodFisco": "G002",
      "Abitanti": 562,
      "Link": "http://www.comuni-italiani.it/066/060/"
    },
    {
      "Istat": "042033",
      "Comune": "Offagna",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60020,
      "CodFisco": "G003",
      "Abitanti": 1895,
      "Link": "http://www.comuni-italiani.it/042/033/"
    },
    {
      "Istat": "019062",
      "Comune": "Offanengo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "G004",
      "Abitanti": 5893,
      "Link": "http://www.comuni-italiani.it/019/062/"
    },
    {
      "Istat": "044054",
      "Comune": "Offida",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63073,
      "CodFisco": "G005",
      "Abitanti": 5277,
      "Link": "http://www.comuni-italiani.it/044/054/"
    },
    {
      "Istat": "017122",
      "Comune": "Offlaga",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "G006",
      "Abitanti": 4297,
      "Link": "http://www.comuni-italiani.it/017/122/"
    },
    {
      "Istat": 103049,
      "Comune": "Oggebbio",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28824,
      "CodFisco": "G007",
      "Abitanti": 889,
      "Link": "http://www.comuni-italiani.it/103/049/"
    },
    {
      "Istat": "012107",
      "Comune": "Oggiona con Santo Stefano",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "G008",
      "Abitanti": 4329,
      "Link": "http://www.comuni-italiani.it/012/107/"
    },
    {
      "Istat": "097057",
      "Comune": "Oggiono",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23848,
      "CodFisco": "G009",
      "Abitanti": 8887,
      "Link": "http://www.comuni-italiani.it/097/057/"
    },
    {
      "Istat": "001170",
      "Comune": "Oglianico",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "G010",
      "Abitanti": 1448,
      "Link": "http://www.comuni-italiani.it/001/170/"
    },
    {
      "Istat": "065081",
      "Comune": "Ogliastro Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84061,
      "CodFisco": "G011",
      "Abitanti": 2274,
      "Link": "http://www.comuni-italiani.it/065/081/"
    },
    {
      "Istat": 104017,
      "Comune": "Olbia",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07026",
      "CodFisco": "G015",
      "Abitanti": 56066,
      "Link": "http://www.comuni-italiani.it/104/017/"
    },
    {
      "Istat": "002088",
      "Comune": "Olcenengo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13047,
      "CodFisco": "G016",
      "Abitanti": 745,
      "Link": "http://www.comuni-italiani.it/002/088/"
    },
    {
      "Istat": "002089",
      "Comune": "Oldenico",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "G018",
      "Abitanti": 258,
      "Link": "http://www.comuni-italiani.it/002/089/"
    },
    {
      "Istat": "003108",
      "Comune": "Oleggio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28047,
      "CodFisco": "G019",
      "Abitanti": 13616,
      "Link": "http://www.comuni-italiani.it/003/108/"
    },
    {
      "Istat": "003109",
      "Comune": "Oleggio Castello",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28040,
      "CodFisco": "G020",
      "Abitanti": 1960,
      "Link": "http://www.comuni-italiani.it/003/109/"
    },
    {
      "Istat": "018104",
      "Comune": "Olevano di Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "G021",
      "Abitanti": 806,
      "Link": "http://www.comuni-italiani.it/018/104/"
    },
    {
      "Istat": "058073",
      "Comune": "Olevano Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00035",
      "CodFisco": "G022",
      "Abitanti": 6914,
      "Link": "http://www.comuni-italiani.it/058/073/"
    },
    {
      "Istat": "065082",
      "Comune": "Olevano sul Tusciano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84062,
      "CodFisco": "G023",
      "Abitanti": 6993,
      "Link": "http://www.comuni-italiani.it/065/082/"
    },
    {
      "Istat": "013165",
      "Comune": "Olgiate Comasco",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22077,
      "CodFisco": "G025",
      "Abitanti": 11364,
      "Link": "http://www.comuni-italiani.it/013/165/"
    },
    {
      "Istat": "097058",
      "Comune": "Olgiate Molgora",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23887,
      "CodFisco": "G026",
      "Abitanti": 6311,
      "Link": "http://www.comuni-italiani.it/097/058/"
    },
    {
      "Istat": "012108",
      "Comune": "Olgiate Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21057,
      "CodFisco": "G028",
      "Abitanti": 12243,
      "Link": "http://www.comuni-italiani.it/012/108/"
    },
    {
      "Istat": "097059",
      "Comune": "Olginate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23854,
      "CodFisco": "G030",
      "Abitanti": 7194,
      "Link": "http://www.comuni-italiani.it/097/059/"
    },
    {
      "Istat": "091055",
      "Comune": "Oliena",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08025",
      "CodFisco": "G031",
      "Abitanti": 7418,
      "Link": "http://www.comuni-italiani.it/091/055/"
    },
    {
      "Istat": "018105",
      "Comune": "Oliva Gessi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "G032",
      "Abitanti": 179,
      "Link": "http://www.comuni-italiani.it/018/105/"
    },
    {
      "Istat": "079088",
      "Comune": "Olivadi",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88067,
      "CodFisco": "G034",
      "Abitanti": 609,
      "Link": "http://www.comuni-italiani.it/079/088/"
    },
    {
      "Istat": "083063",
      "Comune": "Oliveri",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "G036",
      "Abitanti": 2168,
      "Link": "http://www.comuni-italiani.it/083/063/"
    },
    {
      "Istat": "065083",
      "Comune": "Oliveto Citra",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "G039",
      "Abitanti": 3972,
      "Link": "http://www.comuni-italiani.it/065/083/"
    },
    {
      "Istat": "097060",
      "Comune": "Oliveto Lario",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23865,
      "CodFisco": "G040",
      "Abitanti": 1212,
      "Link": "http://www.comuni-italiani.it/097/060/"
    },
    {
      "Istat": "077019",
      "Comune": "Oliveto Lucano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "G037",
      "Abitanti": 506,
      "Link": "http://www.comuni-italiani.it/077/019/"
    },
    {
      "Istat": "008038",
      "Comune": "Olivetta San Michele",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18030,
      "CodFisco": "G041",
      "Abitanti": 240,
      "Link": "http://www.comuni-italiani.it/008/038/"
    },
    {
      "Istat": "006118",
      "Comune": "Olivola",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "G042",
      "Abitanti": 124,
      "Link": "http://www.comuni-italiani.it/006/118/"
    },
    {
      "Istat": "095037",
      "Comune": "Ollastra",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09088",
      "CodFisco": "G043",
      "Abitanti": 1253,
      "Link": "http://www.comuni-italiani.it/095/037/"
    },
    {
      "Istat": "091056",
      "Comune": "Ollolai",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G044",
      "Abitanti": 1395,
      "Link": "http://www.comuni-italiani.it/091/056/"
    },
    {
      "Istat": "007046",
      "Comune": "Ollomont",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "G045",
      "Abitanti": 164,
      "Link": "http://www.comuni-italiani.it/007/046/"
    },
    {
      "Istat": "090048",
      "Comune": "Olmedo",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "G046",
      "Abitanti": 3937,
      "Link": "http://www.comuni-italiani.it/090/048/"
    },
    {
      "Istat": "019063",
      "Comune": "Olmeneta",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26010,
      "CodFisco": "G047",
      "Abitanti": 957,
      "Link": "http://www.comuni-italiani.it/019/063/"
    },
    {
      "Istat": "016145",
      "Comune": "Olmo al Brembo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "G049",
      "Abitanti": 525,
      "Link": "http://www.comuni-italiani.it/016/145/"
    },
    {
      "Istat": "005081",
      "Comune": "Olmo Gentile",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14050,
      "CodFisco": "G048",
      "Abitanti": 89,
      "Link": "http://www.comuni-italiani.it/005/081/"
    },
    {
      "Istat": "016146",
      "Comune": "Oltre il Colle",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24013,
      "CodFisco": "G050",
      "Abitanti": 1062,
      "Link": "http://www.comuni-italiani.it/016/146/"
    },
    {
      "Istat": "016147",
      "Comune": "Oltressenda Alta",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "G054",
      "Abitanti": 186,
      "Link": "http://www.comuni-italiani.it/016/147/"
    },
    {
      "Istat": "013169",
      "Comune": "Oltrona di San Mamette",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "G056",
      "Abitanti": 2293,
      "Link": "http://www.comuni-italiani.it/013/169/"
    },
    {
      "Istat": "091057",
      "Comune": "Olzai",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G058",
      "Abitanti": 929,
      "Link": "http://www.comuni-italiani.it/091/057/"
    },
    {
      "Istat": "017123",
      "Comune": "Ome",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "G061",
      "Abitanti": 3273,
      "Link": "http://www.comuni-italiani.it/017/123/"
    },
    {
      "Istat": 103050,
      "Comune": "Omegna",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28887,
      "CodFisco": "G062",
      "Abitanti": 15991,
      "Link": "http://www.comuni-italiani.it/103/050/"
    },
    {
      "Istat": "065084",
      "Comune": "Omignano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "G063",
      "Abitanti": 1570,
      "Link": "http://www.comuni-italiani.it/065/084/"
    },
    {
      "Istat": "091058",
      "Comune": "OnanÏ",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G064",
      "Abitanti": 457,
      "Link": "http://www.comuni-italiani.it/091/058/"
    },
    {
      "Istat": "056040",
      "Comune": "Onano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0763",
      "CAP": "01010",
      "CodFisco": "G065",
      "Abitanti": 1040,
      "Link": "http://www.comuni-italiani.it/056/040/"
    },
    {
      "Istat": "004154",
      "Comune": "Oncino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "G066",
      "Abitanti": 81,
      "Link": "http://www.comuni-italiani.it/004/154/"
    },
    {
      "Istat": "016148",
      "Comune": "Oneta",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "G068",
      "Abitanti": 661,
      "Link": "http://www.comuni-italiani.it/016/148/"
    },
    {
      "Istat": "091059",
      "Comune": "Onifai",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G070",
      "Abitanti": 757,
      "Link": "http://www.comuni-italiani.it/091/059/"
    },
    {
      "Istat": "091060",
      "Comune": "Oniferi",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G071",
      "Abitanti": 930,
      "Link": "http://www.comuni-italiani.it/091/060/"
    },
    {
      "Istat": "017124",
      "Comune": "Ono San Pietro",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "G074",
      "Abitanti": 991,
      "Link": "http://www.comuni-italiani.it/017/124/"
    },
    {
      "Istat": "016149",
      "Comune": "Onore",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "G075",
      "Abitanti": 835,
      "Link": "http://www.comuni-italiani.it/016/149/"
    },
    {
      "Istat": "009043",
      "Comune": "Onzo",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17037,
      "CodFisco": "G076",
      "Abitanti": 233,
      "Link": "http://www.comuni-italiani.it/009/043/"
    },
    {
      "Istat": "015159",
      "Comune": "Opera",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "G078",
      "Abitanti": 13840,
      "Link": "http://www.comuni-italiani.it/015/159/"
    },
    {
      "Istat": "066061",
      "Comune": "Opi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67030,
      "CodFisco": "G079",
      "Abitanti": 441,
      "Link": "http://www.comuni-italiani.it/066/061/"
    },
    {
      "Istat": "023055",
      "Comune": "Oppeano",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37050,
      "CodFisco": "G080",
      "Abitanti": 9446,
      "Link": "http://www.comuni-italiani.it/023/055/"
    },
    {
      "Istat": "076056",
      "Comune": "Oppido Lucano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85015,
      "CodFisco": "G081",
      "Abitanti": 3891,
      "Link": "http://www.comuni-italiani.it/076/056/"
    },
    {
      "Istat": "080055",
      "Comune": "Oppido Mamertina",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89014,
      "CodFisco": "G082",
      "Abitanti": 5442,
      "Link": "http://www.comuni-italiani.it/080/055/"
    },
    {
      "Istat": "021060",
      "Comune": "Ora",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "G083",
      "Abitanti": 3537,
      "Link": "http://www.comuni-italiani.it/021/060/"
    },
    {
      "Istat": "091061",
      "Comune": "Orani",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08026",
      "CodFisco": "G084",
      "Abitanti": 3044,
      "Link": "http://www.comuni-italiani.it/091/061/"
    },
    {
      "Istat": "070049",
      "Comune": "Oratino",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "G086",
      "Abitanti": 1529,
      "Link": "http://www.comuni-italiani.it/070/049/"
    },
    {
      "Istat": "001171",
      "Comune": "Orbassano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10043,
      "CodFisco": "G087",
      "Abitanti": 22345,
      "Link": "http://www.comuni-italiani.it/001/171/"
    },
    {
      "Istat": "053018",
      "Comune": "Orbetello",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58015,
      "CodFisco": "G088",
      "Abitanti": 15246,
      "Link": "http://www.comuni-italiani.it/053/018/"
    },
    {
      "Istat": "041040",
      "Comune": "Orciano di Pesaro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61038,
      "CodFisco": "G089",
      "Abitanti": 2176,
      "Link": "http://www.comuni-italiani.it/041/040/"
    },
    {
      "Istat": "050023",
      "Comune": "Orciano Pisano",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56040,
      "CodFisco": "G090",
      "Abitanti": 623,
      "Link": "http://www.comuni-italiani.it/050/023/"
    },
    {
      "Istat": "009044",
      "Comune": "Orco Feglino",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17024,
      "CodFisco": "D522",
      "Abitanti": 917,
      "Link": "http://www.comuni-italiani.it/009/044/"
    },
    {
      "Istat": "071063",
      "Comune": "Ordona",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71040,
      "CodFisco": "M266",
      "Abitanti": 2720,
      "Link": "http://www.comuni-italiani.it/071/063/"
    },
    {
      "Istat": "010042",
      "Comune": "Orero",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "G093",
      "Abitanti": 606,
      "Link": "http://www.comuni-italiani.it/010/042/"
    },
    {
      "Istat": "024075",
      "Comune": "Orgiano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "G095",
      "Abitanti": 3184,
      "Link": "http://www.comuni-italiani.it/024/075/"
    },
    {
      "Istat": "091062",
      "Comune": "Orgosolo",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08027",
      "CodFisco": "G097",
      "Abitanti": 4418,
      "Link": "http://www.comuni-italiani.it/091/062/"
    },
    {
      "Istat": "074011",
      "Comune": "Oria",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72024,
      "CodFisco": "G098",
      "Abitanti": 15436,
      "Link": "http://www.comuni-italiani.it/074/011/"
    },
    {
      "Istat": "066062",
      "Comune": "Oricola",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67063,
      "CodFisco": "G102",
      "Abitanti": 1162,
      "Link": "http://www.comuni-italiani.it/066/062/"
    },
    {
      "Istat": "012109",
      "Comune": "Origgio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 21040,
      "CodFisco": "G103",
      "Abitanti": 7400,
      "Link": "http://www.comuni-italiani.it/012/109/"
    },
    {
      "Istat": "012110",
      "Comune": "Orino",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "G105",
      "Abitanti": 866,
      "Link": "http://www.comuni-italiani.it/012/110/"
    },
    {
      "Istat": "016150",
      "Comune": "Orio al Serio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "G108",
      "Abitanti": 1752,
      "Link": "http://www.comuni-italiani.it/016/150/"
    },
    {
      "Istat": "001172",
      "Comune": "Orio Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10010,
      "CodFisco": "G109",
      "Abitanti": 820,
      "Link": "http://www.comuni-italiani.it/001/172/"
    },
    {
      "Istat": "098042",
      "Comune": "Orio Litta",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26863,
      "CodFisco": "G107",
      "Abitanti": 2036,
      "Link": "http://www.comuni-italiani.it/098/042/"
    },
    {
      "Istat": "078087",
      "Comune": "Oriolo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87073,
      "CodFisco": "G110",
      "Abitanti": 2556,
      "Link": "http://www.comuni-italiani.it/078/087/"
    },
    {
      "Istat": "056041",
      "Comune": "Oriolo Romano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "01010",
      "CodFisco": "G111",
      "Abitanti": 3759,
      "Link": "http://www.comuni-italiani.it/056/041/"
    },
    {
      "Istat": "095038",
      "Comune": "Oristano",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09170",
      "CodFisco": "G113",
      "Abitanti": 32015,
      "Link": "http://www.comuni-italiani.it/095/038/"
    },
    {
      "Istat": "004155",
      "Comune": "Ormea",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12078,
      "CodFisco": "G114",
      "Abitanti": 1783,
      "Link": "http://www.comuni-italiani.it/004/155/"
    },
    {
      "Istat": "026052",
      "Comune": "Ormelle",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31024,
      "CodFisco": "G115",
      "Abitanti": 4482,
      "Link": "http://www.comuni-italiani.it/026/052/"
    },
    {
      "Istat": 108036,
      "Comune": "Ornago",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20876,
      "CodFisco": "G116",
      "Abitanti": 4670,
      "Link": "http://www.comuni-italiani.it/108/036/"
    },
    {
      "Istat": 103051,
      "Comune": "Ornavasso",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28877,
      "CodFisco": "G117",
      "Abitanti": 3419,
      "Link": "http://www.comuni-italiani.it/103/051/"
    },
    {
      "Istat": "016151",
      "Comune": "Ornica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "G118",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/016/151/"
    },
    {
      "Istat": "091063",
      "Comune": "Orosei",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08028",
      "CodFisco": "G119",
      "Abitanti": 6904,
      "Link": "http://www.comuni-italiani.it/091/063/"
    },
    {
      "Istat": "091064",
      "Comune": "Orotelli",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G120",
      "Abitanti": 2157,
      "Link": "http://www.comuni-italiani.it/091/064/"
    },
    {
      "Istat": "065085",
      "Comune": "Orria",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "G121",
      "Abitanti": 1195,
      "Link": "http://www.comuni-italiani.it/065/085/"
    },
    {
      "Istat": "092118",
      "Comune": "Orroli",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "G122",
      "Abitanti": 2430,
      "Link": "http://www.comuni-italiani.it/092/118/"
    },
    {
      "Istat": "026053",
      "Comune": "Orsago",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31010,
      "CodFisco": "G123",
      "Abitanti": 3876,
      "Link": "http://www.comuni-italiani.it/026/053/"
    },
    {
      "Istat": "006119",
      "Comune": "Orsara Bormida",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "G124",
      "Abitanti": 405,
      "Link": "http://www.comuni-italiani.it/006/119/"
    },
    {
      "Istat": "071035",
      "Comune": "Orsara di Puglia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71027,
      "CodFisco": "G125",
      "Abitanti": 2990,
      "Link": "http://www.comuni-italiani.it/071/035/"
    },
    {
      "Istat": "013170",
      "Comune": "Orsenigo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "G126",
      "Abitanti": 2758,
      "Link": "http://www.comuni-italiani.it/013/170/"
    },
    {
      "Istat": "069057",
      "Comune": "Orsogna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66036,
      "CodFisco": "G128",
      "Abitanti": 4160,
      "Link": "http://www.comuni-italiani.it/069/057/"
    },
    {
      "Istat": "078088",
      "Comune": "Orsomarso",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "G129",
      "Abitanti": 1356,
      "Link": "http://www.comuni-italiani.it/078/088/"
    },
    {
      "Istat": "061053",
      "Comune": "Orta di Atella",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "G130",
      "Abitanti": 24547,
      "Link": "http://www.comuni-italiani.it/061/053/"
    },
    {
      "Istat": "071036",
      "Comune": "Orta Nova",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71045,
      "CodFisco": "G131",
      "Abitanti": 17868,
      "Link": "http://www.comuni-italiani.it/071/036/"
    },
    {
      "Istat": "003112",
      "Comune": "Orta San Giulio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28016,
      "CodFisco": "G134",
      "Abitanti": 1167,
      "Link": "http://www.comuni-italiani.it/003/112/"
    },
    {
      "Istat": "092044",
      "Comune": "Ortacesus",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "G133",
      "Abitanti": 933,
      "Link": "http://www.comuni-italiani.it/092/044/"
    },
    {
      "Istat": "056042",
      "Comune": "Orte",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01028",
      "CodFisco": "G135",
      "Abitanti": 9069,
      "Link": "http://www.comuni-italiani.it/056/042/"
    },
    {
      "Istat": "075056",
      "Comune": "Ortelle",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73030,
      "CodFisco": "G136",
      "Abitanti": 2413,
      "Link": "http://www.comuni-italiani.it/075/056/"
    },
    {
      "Istat": 109029,
      "Comune": "Ortezzano",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63851,
      "CodFisco": "G137",
      "Abitanti": 816,
      "Link": "http://www.comuni-italiani.it/109/029/"
    },
    {
      "Istat": "051027",
      "Comune": "Ortignano Raggiolo",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "G139",
      "Abitanti": 880,
      "Link": "http://www.comuni-italiani.it/051/027/"
    },
    {
      "Istat": "021061",
      "Comune": "Ortisei",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39046,
      "CodFisco": "G140",
      "Abitanti": 4636,
      "Link": "http://www.comuni-italiani.it/021/061/"
    },
    {
      "Istat": "069058",
      "Comune": "Ortona",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 66026,
      "CodFisco": "G141",
      "Abitanti": 23911,
      "Link": "http://www.comuni-italiani.it/069/058/"
    },
    {
      "Istat": "066063",
      "Comune": "Ortona dei Marsi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "G142",
      "Abitanti": 654,
      "Link": "http://www.comuni-italiani.it/066/063/"
    },
    {
      "Istat": "011020",
      "Comune": "Ortonovo",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19034,
      "CodFisco": "G143",
      "Abitanti": 8483,
      "Link": "http://www.comuni-italiani.it/011/020/"
    },
    {
      "Istat": "009045",
      "Comune": "Ortovero",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17037,
      "CodFisco": "G144",
      "Abitanti": 1564,
      "Link": "http://www.comuni-italiani.it/009/045/"
    },
    {
      "Istat": "066064",
      "Comune": "Ortucchio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "G145",
      "Abitanti": 1901,
      "Link": "http://www.comuni-italiani.it/066/064/"
    },
    {
      "Istat": "091066",
      "Comune": "Ortueri",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08036",
      "CodFisco": "G146",
      "Abitanti": 1270,
      "Link": "http://www.comuni-italiani.it/091/066/"
    },
    {
      "Istat": "091067",
      "Comune": "Orune",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G147",
      "Abitanti": 2596,
      "Link": "http://www.comuni-italiani.it/091/067/"
    },
    {
      "Istat": "055023",
      "Comune": "Orvieto",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05018",
      "CodFisco": "G148",
      "Abitanti": 21130,
      "Link": "http://www.comuni-italiani.it/055/023/"
    },
    {
      "Istat": "057047",
      "Comune": "Orvinio",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02035",
      "CodFisco": "B595",
      "Abitanti": 472,
      "Link": "http://www.comuni-italiani.it/057/047/"
    },
    {
      "Istat": "017125",
      "Comune": "Orzinuovi",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25034,
      "CodFisco": "G149",
      "Abitanti": 12549,
      "Link": "http://www.comuni-italiani.it/017/125/"
    },
    {
      "Istat": "017126",
      "Comune": "Orzivecchi",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "G150",
      "Abitanti": 2506,
      "Link": "http://www.comuni-italiani.it/017/126/"
    },
    {
      "Istat": "001173",
      "Comune": "Osasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G151",
      "Abitanti": 1138,
      "Link": "http://www.comuni-italiani.it/001/173/"
    },
    {
      "Istat": "001174",
      "Comune": "Osasio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "G152",
      "Abitanti": 897,
      "Link": "http://www.comuni-italiani.it/001/174/"
    },
    {
      "Istat": 104018,
      "Comune": "Oschiri",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07027",
      "CodFisco": "G153",
      "Abitanti": 3472,
      "Link": "http://www.comuni-italiani.it/104/018/"
    },
    {
      "Istat": "091068",
      "Comune": "Osidda",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "08020",
      "CodFisco": "G154",
      "Abitanti": 266,
      "Link": "http://www.comuni-italiani.it/091/068/"
    },
    {
      "Istat": "009046",
      "Comune": "Osiglia",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17010,
      "CodFisco": "G155",
      "Abitanti": 480,
      "Link": "http://www.comuni-italiani.it/009/046/"
    },
    {
      "Istat": "090050",
      "Comune": "Osilo",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07033",
      "CodFisco": "G156",
      "Abitanti": 3228,
      "Link": "http://www.comuni-italiani.it/090/050/"
    },
    {
      "Istat": "042034",
      "Comune": "Osimo",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60027,
      "CodFisco": "G157",
      "Abitanti": 33737,
      "Link": "http://www.comuni-italiani.it/042/034/"
    },
    {
      "Istat": 105013,
      "Comune": "Osini",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "G158",
      "Abitanti": 826,
      "Link": "http://www.comuni-italiani.it/105/013/"
    },
    {
      "Istat": "016152",
      "Comune": "Osio Sopra",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "G159",
      "Abitanti": 5115,
      "Link": "http://www.comuni-italiani.it/016/152/"
    },
    {
      "Istat": "016153",
      "Comune": "Osio Sotto",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24046,
      "CodFisco": "G160",
      "Abitanti": 11890,
      "Link": "http://www.comuni-italiani.it/016/153/"
    },
    {
      "Istat": "012111",
      "Comune": "Osmate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21018,
      "CodFisco": "E529",
      "Abitanti": 764,
      "Link": "http://www.comuni-italiani.it/012/111/"
    },
    {
      "Istat": "097061",
      "Comune": "Osnago",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23875,
      "CodFisco": "G161",
      "Abitanti": 4843,
      "Link": "http://www.comuni-italiani.it/097/061/"
    },
    {
      "Istat": "030066",
      "Comune": "Osoppo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "G163",
      "Abitanti": 3033,
      "Link": "http://www.comuni-italiani.it/030/066/"
    },
    {
      "Istat": "008039",
      "Comune": "Ospedaletti",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18014,
      "CodFisco": "G164",
      "Abitanti": 3647,
      "Link": "http://www.comuni-italiani.it/008/039/"
    },
    {
      "Istat": "022130",
      "Comune": "Ospedaletto",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "G168",
      "Abitanti": 809,
      "Link": "http://www.comuni-italiani.it/022/130/"
    },
    {
      "Istat": "064067",
      "Comune": "Ospedaletto d'Alpinolo",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83014,
      "CodFisco": "G165",
      "Abitanti": 1934,
      "Link": "http://www.comuni-italiani.it/064/067/"
    },
    {
      "Istat": "028059",
      "Comune": "Ospedaletto Euganeo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35045,
      "CodFisco": "G167",
      "Abitanti": 5943,
      "Link": "http://www.comuni-italiani.it/028/059/"
    },
    {
      "Istat": "098043",
      "Comune": "Ospedaletto Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26864,
      "CodFisco": "G166",
      "Abitanti": 1947,
      "Link": "http://www.comuni-italiani.it/098/043/"
    },
    {
      "Istat": "025035",
      "Comune": "Ospitale di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "G169",
      "Abitanti": 336,
      "Link": "http://www.comuni-italiani.it/025/035/"
    },
    {
      "Istat": "017127",
      "Comune": "Ospitaletto",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25035,
      "CodFisco": "G170",
      "Abitanti": 13974,
      "Link": "http://www.comuni-italiani.it/017/127/"
    },
    {
      "Istat": "098044",
      "Comune": "Ossago Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26816,
      "CodFisco": "G171",
      "Abitanti": 1442,
      "Link": "http://www.comuni-italiani.it/098/044/"
    },
    {
      "Istat": "022131",
      "Comune": "Ossana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38026,
      "CodFisco": "G173",
      "Abitanti": 839,
      "Link": "http://www.comuni-italiani.it/022/131/"
    },
    {
      "Istat": "090051",
      "Comune": "Ossi",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07045",
      "CodFisco": "G178",
      "Abitanti": 5974,
      "Link": "http://www.comuni-italiani.it/090/051/"
    },
    {
      "Istat": "017128",
      "Comune": "Ossimo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "G179",
      "Abitanti": 1462,
      "Link": "http://www.comuni-italiani.it/017/128/"
    },
    {
      "Istat": "015164",
      "Comune": "Ossona",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "G181",
      "Abitanti": 4150,
      "Link": "http://www.comuni-italiani.it/015/164/"
    },
    {
      "Istat": "013172",
      "Comune": "Ossuccio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "G182",
      "Abitanti": 1008,
      "Link": "http://www.comuni-italiani.it/013/172/"
    },
    {
      "Istat": "004156",
      "Comune": "Ostana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "G183",
      "Abitanti": 73,
      "Link": "http://www.comuni-italiani.it/004/156/"
    },
    {
      "Istat": "038017",
      "Comune": "Ostellato",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44020,
      "CodFisco": "G184",
      "Abitanti": 6558,
      "Link": "http://www.comuni-italiani.it/038/017/"
    },
    {
      "Istat": "019064",
      "Comune": "Ostiano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26032,
      "CodFisco": "G185",
      "Abitanti": 3067,
      "Link": "http://www.comuni-italiani.it/019/064/"
    },
    {
      "Istat": "020038",
      "Comune": "Ostiglia",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46035,
      "CodFisco": "G186",
      "Abitanti": 7221,
      "Link": "http://www.comuni-italiani.it/020/038/"
    },
    {
      "Istat": "042035",
      "Comune": "Ostra",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "F401",
      "Abitanti": 6837,
      "Link": "http://www.comuni-italiani.it/042/035/"
    },
    {
      "Istat": "042036",
      "Comune": "Ostra Vetere",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "F581",
      "Abitanti": 3485,
      "Link": "http://www.comuni-italiani.it/042/036/"
    },
    {
      "Istat": "074012",
      "Comune": "Ostuni",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72017,
      "CodFisco": "G187",
      "Abitanti": 32316,
      "Link": "http://www.comuni-italiani.it/074/012/"
    },
    {
      "Istat": "075057",
      "Comune": "Otranto",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73028,
      "CodFisco": "G188",
      "Abitanti": 5548,
      "Link": "http://www.comuni-italiani.it/075/057/"
    },
    {
      "Istat": "055024",
      "Comune": "Otricoli",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05030",
      "CodFisco": "G189",
      "Abitanti": 1984,
      "Link": "http://www.comuni-italiani.it/055/024/"
    },
    {
      "Istat": "091070",
      "Comune": "Ottana",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G191",
      "Abitanti": 2422,
      "Link": "http://www.comuni-italiani.it/091/070/"
    },
    {
      "Istat": "065086",
      "Comune": "Ottati",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "G192",
      "Abitanti": 761,
      "Link": "http://www.comuni-italiani.it/065/086/"
    },
    {
      "Istat": "063051",
      "Comune": "Ottaviano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80044,
      "CodFisco": "G190",
      "Abitanti": 24072,
      "Link": "http://www.comuni-italiani.it/063/051/"
    },
    {
      "Istat": "006120",
      "Comune": "Ottiglio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15038,
      "CodFisco": "G193",
      "Abitanti": 672,
      "Link": "http://www.comuni-italiani.it/006/120/"
    },
    {
      "Istat": "018106",
      "Comune": "Ottobiano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "G194",
      "Abitanti": 1187,
      "Link": "http://www.comuni-italiani.it/018/106/"
    },
    {
      "Istat": "033030",
      "Comune": "Ottone",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29026,
      "CodFisco": "G195",
      "Abitanti": 601,
      "Link": "http://www.comuni-italiani.it/033/030/"
    },
    {
      "Istat": "001175",
      "Comune": "Oulx",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10056,
      "CodFisco": "G196",
      "Abitanti": 3209,
      "Link": "http://www.comuni-italiani.it/001/175/"
    },
    {
      "Istat": "006121",
      "Comune": "Ovada",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15076,
      "CodFisco": "G197",
      "Abitanti": 11965,
      "Link": "http://www.comuni-italiani.it/006/121/"
    },
    {
      "Istat": "030067",
      "Comune": "Ovaro",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33025,
      "CodFisco": "G198",
      "Abitanti": 2064,
      "Link": "http://www.comuni-italiani.it/030/067/"
    },
    {
      "Istat": "006122",
      "Comune": "Oviglio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15026,
      "CodFisco": "G199",
      "Abitanti": 1322,
      "Link": "http://www.comuni-italiani.it/006/122/"
    },
    {
      "Istat": "066065",
      "Comune": "Ovindoli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67046,
      "CodFisco": "G200",
      "Abitanti": 1254,
      "Link": "http://www.comuni-italiani.it/066/065/"
    },
    {
      "Istat": "091071",
      "Comune": "Ovodda",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G201",
      "Abitanti": 1651,
      "Link": "http://www.comuni-italiani.it/091/071/"
    },
    {
      "Istat": "007047",
      "Comune": "Oyace",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "G012",
      "Abitanti": 225,
      "Link": "http://www.comuni-italiani.it/007/047/"
    },
    {
      "Istat": "001176",
      "Comune": "Ozegna",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "G202",
      "Abitanti": 1258,
      "Link": "http://www.comuni-italiani.it/001/176/"
    },
    {
      "Istat": "090052",
      "Comune": "Ozieri",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07014",
      "CodFisco": "G203",
      "Abitanti": 10991,
      "Link": "http://www.comuni-italiani.it/090/052/"
    },
    {
      "Istat": "037046",
      "Comune": "Ozzano dell'Emilia",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40064,
      "CodFisco": "G205",
      "Abitanti": 12850,
      "Link": "http://www.comuni-italiani.it/037/046/"
    },
    {
      "Istat": "006123",
      "Comune": "Ozzano Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15039,
      "CodFisco": "G204",
      "Abitanti": 1501,
      "Link": "http://www.comuni-italiani.it/006/123/"
    },
    {
      "Istat": "015165",
      "Comune": "Ozzero",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "G206",
      "Abitanti": 1480,
      "Link": "http://www.comuni-italiani.it/015/165/"
    },
    {
      "Istat": 106011,
      "Comune": "Pabillonis",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09030",
      "CodFisco": "G207",
      "Abitanti": 2947,
      "Link": "http://www.comuni-italiani.it/106/011/"
    },
    {
      "Istat": "083064",
      "Comune": "Pace del Mela",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98042,
      "CodFisco": "G209",
      "Abitanti": 6414,
      "Link": "http://www.comuni-italiani.it/083/064/"
    },
    {
      "Istat": "081013",
      "Comune": "Paceco",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91027,
      "CodFisco": "G208",
      "Abitanti": 11429,
      "Link": "http://www.comuni-italiani.it/081/013/"
    },
    {
      "Istat": "066066",
      "Comune": "Pacentro",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "G210",
      "Abitanti": 1250,
      "Link": "http://www.comuni-italiani.it/066/066/"
    },
    {
      "Istat": "089014",
      "Comune": "Pachino",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96018,
      "CodFisco": "G211",
      "Abitanti": 21990,
      "Link": "http://www.comuni-italiani.it/089/014/"
    },
    {
      "Istat": "054036",
      "Comune": "Paciano",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06060",
      "CodFisco": "G212",
      "Abitanti": 974,
      "Link": "http://www.comuni-italiani.it/054/036/"
    },
    {
      "Istat": "017129",
      "Comune": "Padenghe sul Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "G213",
      "Abitanti": 4350,
      "Link": "http://www.comuni-italiani.it/017/129/"
    },
    {
      "Istat": "022132",
      "Comune": "Padergnone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38070,
      "CodFisco": "G214",
      "Abitanti": 727,
      "Link": "http://www.comuni-italiani.it/022/132/"
    },
    {
      "Istat": "006124",
      "Comune": "Paderna",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "G215",
      "Abitanti": 233,
      "Link": "http://www.comuni-italiani.it/006/124/"
    },
    {
      "Istat": "097062",
      "Comune": "Paderno d'Adda",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23877,
      "CodFisco": "G218",
      "Abitanti": 3936,
      "Link": "http://www.comuni-italiani.it/097/062/"
    },
    {
      "Istat": "026054",
      "Comune": "Paderno del Grappa",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31017,
      "CodFisco": "G221",
      "Abitanti": 2195,
      "Link": "http://www.comuni-italiani.it/026/054/"
    },
    {
      "Istat": "015166",
      "Comune": "Paderno Dugnano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20037,
      "CodFisco": "G220",
      "Abitanti": 47695,
      "Link": "http://www.comuni-italiani.it/015/166/"
    },
    {
      "Istat": "017130",
      "Comune": "Paderno Franciacorta",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "G217",
      "Abitanti": 3765,
      "Link": "http://www.comuni-italiani.it/017/130/"
    },
    {
      "Istat": "019065",
      "Comune": "Paderno Ponchielli",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26024,
      "CodFisco": "G222",
      "Abitanti": 1499,
      "Link": "http://www.comuni-italiani.it/019/065/"
    },
    {
      "Istat": "028060",
      "Comune": "Padova",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": "351xx",
      "CodFisco": "G224",
      "Abitanti": 214198,
      "Link": "http://www.comuni-italiani.it/028/060/"
    },
    {
      "Istat": "090053",
      "Comune": "Padria",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07015",
      "CodFisco": "G225",
      "Abitanti": 710,
      "Link": "http://www.comuni-italiani.it/090/053/"
    },
    {
      "Istat": 104019,
      "Comune": "Padru",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07020",
      "CodFisco": "M301",
      "Abitanti": 2174,
      "Link": "http://www.comuni-italiani.it/104/019/"
    },
    {
      "Istat": "065087",
      "Comune": "Padula",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84034,
      "CodFisco": "G226",
      "Abitanti": 5523,
      "Link": "http://www.comuni-italiani.it/065/087/"
    },
    {
      "Istat": "062045",
      "Comune": "Paduli",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "G227",
      "Abitanti": 4127,
      "Link": "http://www.comuni-italiani.it/062/045/"
    },
    {
      "Istat": "004157",
      "Comune": "Paesana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12034,
      "CodFisco": "G228",
      "Abitanti": 2937,
      "Link": "http://www.comuni-italiani.it/004/157/"
    },
    {
      "Istat": "026055",
      "Comune": "Paese",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31038,
      "CodFisco": "G229",
      "Abitanti": 21947,
      "Link": "http://www.comuni-italiani.it/026/055/"
    },
    {
      "Istat": "065088",
      "Comune": "Pagani",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84016,
      "CodFisco": "G230",
      "Abitanti": 35962,
      "Link": "http://www.comuni-italiani.it/065/088/"
    },
    {
      "Istat": "057048",
      "Comune": "Paganico Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "G232",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/057/048/"
    },
    {
      "Istat": "016154",
      "Comune": "Pagazzano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "G233",
      "Abitanti": 2103,
      "Link": "http://www.comuni-italiani.it/016/154/"
    },
    {
      "Istat": "083065",
      "Comune": "Pagliara",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98020,
      "CodFisco": "G234",
      "Abitanti": 1251,
      "Link": "http://www.comuni-italiani.it/083/065/"
    },
    {
      "Istat": "069059",
      "Comune": "Paglieta",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66020,
      "CodFisco": "G237",
      "Abitanti": 4531,
      "Link": "http://www.comuni-italiani.it/069/059/"
    },
    {
      "Istat": "030068",
      "Comune": "Pagnacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "G238",
      "Abitanti": 5037,
      "Link": "http://www.comuni-italiani.it/030/068/"
    },
    {
      "Istat": "004158",
      "Comune": "Pagno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "G240",
      "Abitanti": 584,
      "Link": "http://www.comuni-italiani.it/004/158/"
    },
    {
      "Istat": "097063",
      "Comune": "Pagnona",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23833,
      "CodFisco": "G241",
      "Abitanti": 407,
      "Link": "http://www.comuni-italiani.it/097/063/"
    },
    {
      "Istat": "064068",
      "Comune": "Pago del Vallo di Lauro",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "G242",
      "Abitanti": 1875,
      "Link": "http://www.comuni-italiani.it/064/068/"
    },
    {
      "Istat": "062046",
      "Comune": "Pago Veiano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "G243",
      "Abitanti": 2567,
      "Link": "http://www.comuni-italiani.it/062/046/"
    },
    {
      "Istat": "017131",
      "Comune": "Paisco Loveno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "G247",
      "Abitanti": 200,
      "Link": "http://www.comuni-italiani.it/017/131/"
    },
    {
      "Istat": "017132",
      "Comune": "Paitone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "G248",
      "Abitanti": 2043,
      "Link": "http://www.comuni-italiani.it/017/132/"
    },
    {
      "Istat": "016155",
      "Comune": "Paladina",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "G249",
      "Abitanti": 4002,
      "Link": "http://www.comuni-italiani.it/016/155/"
    },
    {
      "Istat": "036029",
      "Comune": "Palagano",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41046,
      "CodFisco": "G250",
      "Abitanti": 2417,
      "Link": "http://www.comuni-italiani.it/036/029/"
    },
    {
      "Istat": "073020",
      "Comune": "Palagianello",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74018,
      "CodFisco": "G251",
      "Abitanti": 7871,
      "Link": "http://www.comuni-italiani.it/073/020/"
    },
    {
      "Istat": "073021",
      "Comune": "Palagiano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74019,
      "CodFisco": "G252",
      "Abitanti": 16064,
      "Link": "http://www.comuni-italiani.it/073/021/"
    },
    {
      "Istat": "087032",
      "Comune": "Palagonia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95046,
      "CodFisco": "G253",
      "Abitanti": 16547,
      "Link": "http://www.comuni-italiani.it/087/032/"
    },
    {
      "Istat": "050024",
      "Comune": "Palaia",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56036,
      "CodFisco": "G254",
      "Abitanti": 4622,
      "Link": "http://www.comuni-italiani.it/050/024/"
    },
    {
      "Istat": "034026",
      "Comune": "Palanzano",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43025,
      "CodFisco": "G255",
      "Abitanti": 1221,
      "Link": "http://www.comuni-italiani.it/034/026/"
    },
    {
      "Istat": "070050",
      "Comune": "Palata",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86037,
      "CodFisco": "G257",
      "Abitanti": 1788,
      "Link": "http://www.comuni-italiani.it/070/050/"
    },
    {
      "Istat": 104020,
      "Comune": "Palau",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07020",
      "CodFisco": "G258",
      "Abitanti": 4440,
      "Link": "http://www.comuni-italiani.it/104/020/"
    },
    {
      "Istat": "016156",
      "Comune": "Palazzago",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "G259",
      "Abitanti": 4215,
      "Link": "http://www.comuni-italiani.it/016/156/"
    },
    {
      "Istat": "082052",
      "Comune": "Palazzo Adriano",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "G263",
      "Abitanti": 2262,
      "Link": "http://www.comuni-italiani.it/082/052/"
    },
    {
      "Istat": "001177",
      "Comune": "Palazzo Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "G262",
      "Abitanti": 850,
      "Link": "http://www.comuni-italiani.it/001/177/"
    },
    {
      "Istat": "019066",
      "Comune": "Palazzo Pignano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26020,
      "CodFisco": "G260",
      "Abitanti": 3916,
      "Link": "http://www.comuni-italiani.it/019/066/"
    },
    {
      "Istat": "076057",
      "Comune": "Palazzo San Gervasio",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85026,
      "CodFisco": "G261",
      "Abitanti": 5048,
      "Link": "http://www.comuni-italiani.it/076/057/"
    },
    {
      "Istat": "089015",
      "Comune": "Palazzolo Acreide",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "G267",
      "Abitanti": 9061,
      "Link": "http://www.comuni-italiani.it/089/015/"
    },
    {
      "Istat": "030069",
      "Comune": "Palazzolo dello Stella",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33056,
      "CodFisco": "G268",
      "Abitanti": 3042,
      "Link": "http://www.comuni-italiani.it/030/069/"
    },
    {
      "Istat": "017133",
      "Comune": "Palazzolo sull'Oglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25036,
      "CodFisco": "G264",
      "Abitanti": 19862,
      "Link": "http://www.comuni-italiani.it/017/133/"
    },
    {
      "Istat": "002090",
      "Comune": "Palazzolo Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "G266",
      "Abitanti": 1290,
      "Link": "http://www.comuni-italiani.it/002/090/"
    },
    {
      "Istat": "048031",
      "Comune": "Palazzuolo sul Senio",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50035,
      "CodFisco": "G270",
      "Abitanti": 1198,
      "Link": "http://www.comuni-italiani.it/048/031/"
    },
    {
      "Istat": "069060",
      "Comune": "Palena",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66017,
      "CodFisco": "G271",
      "Abitanti": 1424,
      "Link": "http://www.comuni-italiani.it/069/060/"
    },
    {
      "Istat": "079089",
      "Comune": "Palermiti",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "G272",
      "Abitanti": 1293,
      "Link": "http://www.comuni-italiani.it/079/089/"
    },
    {
      "Istat": "082053",
      "Comune": "Palermo",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": "901xx",
      "CodFisco": "G273",
      "Abitanti": 655875,
      "Link": "http://www.comuni-italiani.it/082/053/"
    },
    {
      "Istat": "058074",
      "Comune": "Palestrina",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00036",
      "CodFisco": "G274",
      "Abitanti": 21602,
      "Link": "http://www.comuni-italiani.it/058/074/"
    },
    {
      "Istat": "018107",
      "Comune": "Palestro",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "G275",
      "Abitanti": 2001,
      "Link": "http://www.comuni-italiani.it/018/107/"
    },
    {
      "Istat": "060046",
      "Comune": "Paliano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03018",
      "CodFisco": "G276",
      "Abitanti": 8330,
      "Link": "http://www.comuni-italiani.it/060/046/"
    },
    {
      "Istat": "080056",
      "Comune": "Palizzi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89038,
      "CodFisco": "G277",
      "Abitanti": 2387,
      "Link": "http://www.comuni-italiani.it/080/056/"
    },
    {
      "Istat": 101016,
      "Comune": "Pallagorio",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88818,
      "CodFisco": "G278",
      "Abitanti": 1383,
      "Link": "http://www.comuni-italiani.it/101/016/"
    },
    {
      "Istat": 103052,
      "Comune": "Pallanzeno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28884,
      "CodFisco": "G280",
      "Abitanti": 1177,
      "Link": "http://www.comuni-italiani.it/103/052/"
    },
    {
      "Istat": "009047",
      "Comune": "Pallare",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17043,
      "CodFisco": "G281",
      "Abitanti": 956,
      "Link": "http://www.comuni-italiani.it/009/047/"
    },
    {
      "Istat": "063052",
      "Comune": "Palma Campania",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80036,
      "CodFisco": "G283",
      "Abitanti": 15187,
      "Link": "http://www.comuni-italiani.it/063/052/"
    },
    {
      "Istat": "084027",
      "Comune": "Palma di Montechiaro",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "G282",
      "Abitanti": 24109,
      "Link": "http://www.comuni-italiani.it/084/027/"
    },
    {
      "Istat": "030070",
      "Comune": "Palmanova",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33057,
      "CodFisco": "G284",
      "Abitanti": 5453,
      "Link": "http://www.comuni-italiani.it/030/070/"
    },
    {
      "Istat": "075058",
      "Comune": "Palmariggi",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "G285",
      "Abitanti": 1580,
      "Link": "http://www.comuni-italiani.it/075/058/"
    },
    {
      "Istat": "095039",
      "Comune": "Palmas Arborea",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "G286",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/095/039/"
    },
    {
      "Istat": "080057",
      "Comune": "Palmi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89015,
      "CodFisco": "G288",
      "Abitanti": 19320,
      "Link": "http://www.comuni-italiani.it/080/057/"
    },
    {
      "Istat": "044056",
      "Comune": "Palmiano",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63092,
      "CodFisco": "G289",
      "Abitanti": 208,
      "Link": "http://www.comuni-italiani.it/044/056/"
    },
    {
      "Istat": "069061",
      "Comune": "Palmoli",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "G290",
      "Abitanti": 1002,
      "Link": "http://www.comuni-italiani.it/069/061/"
    },
    {
      "Istat": "072033",
      "Comune": "Palo del Colle",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70027,
      "CodFisco": "G291",
      "Abitanti": 21786,
      "Link": "http://www.comuni-italiani.it/072/033/"
    },
    {
      "Istat": "058075",
      "Comune": "Palombara Sabina",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00018",
      "CodFisco": "G293",
      "Abitanti": 12991,
      "Link": "http://www.comuni-italiani.it/058/075/"
    },
    {
      "Istat": "069062",
      "Comune": "Palombaro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "G294",
      "Abitanti": 1131,
      "Link": "http://www.comuni-italiani.it/069/062/"
    },
    {
      "Istat": "065089",
      "Comune": "Palomonte",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "G292",
      "Abitanti": 4151,
      "Link": "http://www.comuni-italiani.it/065/089/"
    },
    {
      "Istat": "016157",
      "Comune": "Palosco",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "G295",
      "Abitanti": 5801,
      "Link": "http://www.comuni-italiani.it/016/157/"
    },
    {
      "Istat": "023056",
      "Comune": "Pal˘",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37050,
      "CodFisco": "G297",
      "Abitanti": 1285,
      "Link": "http://www.comuni-italiani.it/023/056/"
    },
    {
      "Istat": "022133",
      "Comune": "Pal˘ del Fersina",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "G296",
      "Abitanti": 177,
      "Link": "http://www.comuni-italiani.it/022/133/"
    },
    {
      "Istat": "078089",
      "Comune": "Paludi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "G298",
      "Abitanti": 1168,
      "Link": "http://www.comuni-italiani.it/078/089/"
    },
    {
      "Istat": "030071",
      "Comune": "Paluzza",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33026,
      "CodFisco": "G300",
      "Abitanti": 2403,
      "Link": "http://www.comuni-italiani.it/030/071/"
    },
    {
      "Istat": "004159",
      "Comune": "Pamparato",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12087,
      "CodFisco": "G302",
      "Abitanti": 336,
      "Link": "http://www.comuni-italiani.it/004/159/"
    },
    {
      "Istat": "001178",
      "Comune": "Pancalieri",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "G303",
      "Abitanti": 2019,
      "Link": "http://www.comuni-italiani.it/001/178/"
    },
    {
      "Istat": "018108",
      "Comune": "Pancarana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "G304",
      "Abitanti": 336,
      "Link": "http://www.comuni-italiani.it/018/108/"
    },
    {
      "Istat": "022134",
      "Comune": "Panchi‡",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "G305",
      "Abitanti": 764,
      "Link": "http://www.comuni-italiani.it/022/134/"
    },
    {
      "Istat": "019067",
      "Comune": "Pandino",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26025,
      "CodFisco": "G306",
      "Abitanti": 9147,
      "Link": "http://www.comuni-italiani.it/019/067/"
    },
    {
      "Istat": "078090",
      "Comune": "Panettieri",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 87050,
      "CodFisco": "G307",
      "Abitanti": 344,
      "Link": "http://www.comuni-italiani.it/078/090/"
    },
    {
      "Istat": "054037",
      "Comune": "Panicale",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06064",
      "CodFisco": "G308",
      "Abitanti": 5983,
      "Link": "http://www.comuni-italiani.it/054/037/"
    },
    {
      "Istat": "062047",
      "Comune": "Pannarano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82017,
      "CodFisco": "G311",
      "Abitanti": 2045,
      "Link": "http://www.comuni-italiani.it/062/047/"
    },
    {
      "Istat": "071037",
      "Comune": "Panni",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71020,
      "CodFisco": "G312",
      "Abitanti": 865,
      "Link": "http://www.comuni-italiani.it/071/037/"
    },
    {
      "Istat": "081014",
      "Comune": "Pantelleria",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91017,
      "CodFisco": "G315",
      "Abitanti": 7846,
      "Link": "http://www.comuni-italiani.it/081/014/"
    },
    {
      "Istat": "015167",
      "Comune": "Pantigliate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "G316",
      "Abitanti": 5983,
      "Link": "http://www.comuni-italiani.it/015/167/"
    },
    {
      "Istat": "078091",
      "Comune": "Paola",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87027,
      "CodFisco": "G317",
      "Abitanti": 16866,
      "Link": "http://www.comuni-italiani.it/078/091/"
    },
    {
      "Istat": "062048",
      "Comune": "Paolisi",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82011,
      "CodFisco": "G318",
      "Abitanti": 2018,
      "Link": "http://www.comuni-italiani.it/062/048/"
    },
    {
      "Istat": "078092",
      "Comune": "Papasidero",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87020,
      "CodFisco": "G320",
      "Abitanti": 855,
      "Link": "http://www.comuni-italiani.it/078/092/"
    },
    {
      "Istat": "029034",
      "Comune": "Papozze",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45010,
      "CodFisco": "G323",
      "Abitanti": 1676,
      "Link": "http://www.comuni-italiani.it/029/034/"
    },
    {
      "Istat": "015168",
      "Comune": "Parabiago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20015,
      "CodFisco": "G324",
      "Abitanti": 26952,
      "Link": "http://www.comuni-italiani.it/015/168/"
    },
    {
      "Istat": "075059",
      "Comune": "Parabita",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73052,
      "CodFisco": "G325",
      "Abitanti": 9374,
      "Link": "http://www.comuni-italiani.it/075/059/"
    },
    {
      "Istat": "017134",
      "Comune": "Paratico",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 25030,
      "CodFisco": "G327",
      "Abitanti": 4444,
      "Link": "http://www.comuni-italiani.it/017/134/"
    },
    {
      "Istat": "021062",
      "Comune": "Parcines",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "G328",
      "Abitanti": 3533,
      "Link": "http://www.comuni-italiani.it/021/062/"
    },
    {
      "Istat": "013175",
      "Comune": "ParË",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "G329",
      "Abitanti": 1763,
      "Link": "http://www.comuni-italiani.it/013/175/"
    },
    {
      "Istat": "001179",
      "Comune": "Parella",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "G330",
      "Abitanti": 479,
      "Link": "http://www.comuni-italiani.it/001/179/"
    },
    {
      "Istat": "078093",
      "Comune": "Parenti",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "G331",
      "Abitanti": 2287,
      "Link": "http://www.comuni-italiani.it/078/093/"
    },
    {
      "Istat": "061054",
      "Comune": "Parete",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "G333",
      "Abitanti": 11001,
      "Link": "http://www.comuni-italiani.it/061/054/"
    },
    {
      "Istat": "006125",
      "Comune": "Pareto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "019",
      "CAP": 15010,
      "CodFisco": "G334",
      "Abitanti": 634,
      "Link": "http://www.comuni-italiani.it/006/125/"
    },
    {
      "Istat": 102026,
      "Comune": "Parghelia",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89861,
      "CodFisco": "G335",
      "Abitanti": 1348,
      "Link": "http://www.comuni-italiani.it/102/026/"
    },
    {
      "Istat": "097064",
      "Comune": "Parlasco",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23837,
      "CodFisco": "G336",
      "Abitanti": 141,
      "Link": "http://www.comuni-italiani.it/097/064/"
    },
    {
      "Istat": "034027",
      "Comune": "Parma",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": "4312x",
      "CodFisco": "G337",
      "Abitanti": 186690,
      "Link": "http://www.comuni-italiani.it/034/027/"
    },
    {
      "Istat": "006126",
      "Comune": "Parodi Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "G338",
      "Abitanti": 747,
      "Link": "http://www.comuni-italiani.it/006/126/"
    },
    {
      "Istat": "004160",
      "Comune": "Paroldo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "G339",
      "Abitanti": 239,
      "Link": "http://www.comuni-italiani.it/004/160/"
    },
    {
      "Istat": "064069",
      "Comune": "Parolise",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "G340",
      "Abitanti": 706,
      "Link": "http://www.comuni-italiani.it/064/069/"
    },
    {
      "Istat": "018109",
      "Comune": "Parona",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "G342",
      "Abitanti": 2052,
      "Link": "http://www.comuni-italiani.it/018/109/"
    },
    {
      "Istat": "055025",
      "Comune": "Parrano",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05010",
      "CodFisco": "G344",
      "Abitanti": 600,
      "Link": "http://www.comuni-italiani.it/055/025/"
    },
    {
      "Istat": "016158",
      "Comune": "Parre",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "G346",
      "Abitanti": 2815,
      "Link": "http://www.comuni-italiani.it/016/158/"
    },
    {
      "Istat": "081015",
      "Comune": "Partanna",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91028,
      "CodFisco": "G347",
      "Abitanti": 11168,
      "Link": "http://www.comuni-italiani.it/081/015/"
    },
    {
      "Istat": "082054",
      "Comune": "Partinico",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90047,
      "CodFisco": "G348",
      "Abitanti": 31885,
      "Link": "http://www.comuni-italiani.it/082/054/"
    },
    {
      "Istat": "003114",
      "Comune": "Paruzzaro",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28040,
      "CodFisco": "G349",
      "Abitanti": 2064,
      "Link": "http://www.comuni-italiani.it/003/114/"
    },
    {
      "Istat": "016159",
      "Comune": "Parzanica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "G350",
      "Abitanti": 375,
      "Link": "http://www.comuni-italiani.it/016/159/"
    },
    {
      "Istat": "030072",
      "Comune": "Pasian di Prato",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33037,
      "CodFisco": "G352",
      "Abitanti": 9317,
      "Link": "http://www.comuni-italiani.it/030/072/"
    },
    {
      "Istat": "093029",
      "Comune": "Pasiano di Pordenone",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33087,
      "CodFisco": "G353",
      "Abitanti": 7901,
      "Link": "http://www.comuni-italiani.it/093/029/"
    },
    {
      "Istat": "017135",
      "Comune": "Paspardo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "G354",
      "Abitanti": 654,
      "Link": "http://www.comuni-italiani.it/017/135/"
    },
    {
      "Istat": "005082",
      "Comune": "Passerano Marmorito",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "G358",
      "Abitanti": 451,
      "Link": "http://www.comuni-italiani.it/005/082/"
    },
    {
      "Istat": "054038",
      "Comune": "Passignano sul Trasimeno",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06065",
      "CodFisco": "G359",
      "Abitanti": 5713,
      "Link": "http://www.comuni-italiani.it/054/038/"
    },
    {
      "Istat": "017136",
      "Comune": "Passirano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "G361",
      "Abitanti": 7191,
      "Link": "http://www.comuni-italiani.it/017/136/"
    },
    {
      "Istat": "060047",
      "Comune": "Pastena",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03020",
      "CodFisco": "G362",
      "Abitanti": 1534,
      "Link": "http://www.comuni-italiani.it/060/047/"
    },
    {
      "Istat": "061055",
      "Comune": "Pastorano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "G364",
      "Abitanti": 2941,
      "Link": "http://www.comuni-italiani.it/061/055/"
    },
    {
      "Istat": "023057",
      "Comune": "Pastrengo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "G365",
      "Abitanti": 2883,
      "Link": "http://www.comuni-italiani.it/023/057/"
    },
    {
      "Istat": "006127",
      "Comune": "Pasturana",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "G367",
      "Abitanti": 1217,
      "Link": "http://www.comuni-italiani.it/006/127/"
    },
    {
      "Istat": "097065",
      "Comune": "Pasturo",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23818,
      "CodFisco": "G368",
      "Abitanti": 1957,
      "Link": "http://www.comuni-italiani.it/097/065/"
    },
    {
      "Istat": "076100",
      "Comune": "Paterno",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "M269",
      "Abitanti": 3452,
      "Link": "http://www.comuni-italiani.it/076/100/"
    },
    {
      "Istat": "087033",
      "Comune": "PaternÚ",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95047,
      "CodFisco": "G371",
      "Abitanti": 49578,
      "Link": "http://www.comuni-italiani.it/087/033/"
    },
    {
      "Istat": "078094",
      "Comune": "Paterno Calabro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "G372",
      "Abitanti": 1352,
      "Link": "http://www.comuni-italiani.it/078/094/"
    },
    {
      "Istat": "064070",
      "Comune": "Paternopoli",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83052,
      "CodFisco": "G370",
      "Abitanti": 2603,
      "Link": "http://www.comuni-italiani.it/064/070/"
    },
    {
      "Istat": "060048",
      "Comune": "Patrica",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "G374",
      "Abitanti": 3147,
      "Link": "http://www.comuni-italiani.it/060/048/"
    },
    {
      "Istat": "090055",
      "Comune": "Pattada",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07016",
      "CodFisco": "G376",
      "Abitanti": 3283,
      "Link": "http://www.comuni-italiani.it/090/055/"
    },
    {
      "Istat": "083066",
      "Comune": "Patti",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98066,
      "CodFisco": "G377",
      "Abitanti": 13611,
      "Link": "http://www.comuni-italiani.it/083/066/"
    },
    {
      "Istat": "075060",
      "Comune": "Pat˘",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73053,
      "CodFisco": "G378",
      "Abitanti": 1740,
      "Link": "http://www.comuni-italiani.it/075/060/"
    },
    {
      "Istat": "095040",
      "Comune": "Pau",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "G379",
      "Abitanti": 309,
      "Link": "http://www.comuni-italiani.it/095/040/"
    },
    {
      "Istat": "030073",
      "Comune": "Paularo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33027,
      "CodFisco": "G381",
      "Abitanti": 2782,
      "Link": "http://www.comuni-italiani.it/030/073/"
    },
    {
      "Istat": 106012,
      "Comune": "Pauli Arbarei",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "G382",
      "Abitanti": 648,
      "Link": "http://www.comuni-italiani.it/106/012/"
    },
    {
      "Istat": "095041",
      "Comune": "Paulilatino",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09070",
      "CodFisco": "G384",
      "Abitanti": 2377,
      "Link": "http://www.comuni-italiani.it/095/041/"
    },
    {
      "Istat": "015169",
      "Comune": "Paullo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20067,
      "CodFisco": "G385",
      "Abitanti": 11132,
      "Link": "http://www.comuni-italiani.it/015/169/"
    },
    {
      "Istat": "062049",
      "Comune": "Paupisi",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "G386",
      "Abitanti": 1515,
      "Link": "http://www.comuni-italiani.it/062/049/"
    },
    {
      "Istat": "001180",
      "Comune": "Pavarolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "G387",
      "Abitanti": 1107,
      "Link": "http://www.comuni-italiani.it/001/180/"
    },
    {
      "Istat": "018110",
      "Comune": "Pavia",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27100,
      "CodFisco": "G388",
      "Abitanti": 71142,
      "Link": "http://www.comuni-italiani.it/018/110/"
    },
    {
      "Istat": "030074",
      "Comune": "Pavia di Udine",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "G389",
      "Abitanti": 5736,
      "Link": "http://www.comuni-italiani.it/030/074/"
    },
    {
      "Istat": "001181",
      "Comune": "Pavone Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10018,
      "CodFisco": "G392",
      "Abitanti": 3899,
      "Link": "http://www.comuni-italiani.it/001/181/"
    },
    {
      "Istat": "017137",
      "Comune": "Pavone del Mella",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "G391",
      "Abitanti": 2831,
      "Link": "http://www.comuni-italiani.it/017/137/"
    },
    {
      "Istat": "036030",
      "Comune": "Pavullo nel Frignano",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41026,
      "CodFisco": "G393",
      "Abitanti": 17350,
      "Link": "http://www.comuni-italiani.it/036/030/"
    },
    {
      "Istat": "080058",
      "Comune": "Pazzano",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "G394",
      "Abitanti": 677,
      "Link": "http://www.comuni-italiani.it/080/058/"
    },
    {
      "Istat": "050025",
      "Comune": "Peccioli",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56037,
      "CodFisco": "G395",
      "Abitanti": 4966,
      "Link": "http://www.comuni-italiani.it/050/025/"
    },
    {
      "Istat": "001182",
      "Comune": "Pecco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "G396",
      "Abitanti": 218,
      "Link": "http://www.comuni-italiani.it/001/182/"
    },
    {
      "Istat": "006128",
      "Comune": "Pecetto di Valenza",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "G397",
      "Abitanti": 1260,
      "Link": "http://www.comuni-italiani.it/006/128/"
    },
    {
      "Istat": "001183",
      "Comune": "Pecetto Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "G398",
      "Abitanti": 3954,
      "Link": "http://www.comuni-italiani.it/001/183/"
    },
    {
      "Istat": "033031",
      "Comune": "Pecorara",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "G399",
      "Abitanti": 813,
      "Link": "http://www.comuni-italiani.it/033/031/"
    },
    {
      "Istat": "078095",
      "Comune": "Pedace",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "G400",
      "Abitanti": 2031,
      "Link": "http://www.comuni-italiani.it/078/095/"
    },
    {
      "Istat": "087034",
      "Comune": "Pedara",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "G402",
      "Abitanti": 13087,
      "Link": "http://www.comuni-italiani.it/087/034/"
    },
    {
      "Istat": 109030,
      "Comune": "Pedaso",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63827,
      "CodFisco": "G403",
      "Abitanti": 2716,
      "Link": "http://www.comuni-italiani.it/109/030/"
    },
    {
      "Istat": "025036",
      "Comune": "Pedavena",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32034,
      "CodFisco": "G404",
      "Abitanti": 4435,
      "Link": "http://www.comuni-italiani.it/025/036/"
    },
    {
      "Istat": "024076",
      "Comune": "Pedemonte",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36040,
      "CodFisco": "G406",
      "Abitanti": 769,
      "Link": "http://www.comuni-italiani.it/024/076/"
    },
    {
      "Istat": "026056",
      "Comune": "Pederobba",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31040,
      "CodFisco": "G408",
      "Abitanti": 7602,
      "Link": "http://www.comuni-italiani.it/026/056/"
    },
    {
      "Istat": "014047",
      "Comune": "Pedesina",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "G410",
      "Abitanti": 34,
      "Link": "http://www.comuni-italiani.it/014/047/"
    },
    {
      "Istat": "078096",
      "Comune": "Pedivigliano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "G411",
      "Abitanti": 893,
      "Link": "http://www.comuni-italiani.it/078/096/"
    },
    {
      "Istat": "016160",
      "Comune": "Pedrengo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24066,
      "CodFisco": "G412",
      "Abitanti": 5777,
      "Link": "http://www.comuni-italiani.it/016/160/"
    },
    {
      "Istat": "013178",
      "Comune": "Peglio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "G415",
      "Abitanti": 199,
      "Link": "http://www.comuni-italiani.it/013/178/"
    },
    {
      "Istat": "041041",
      "Comune": "Peglio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61049,
      "CodFisco": "G416",
      "Abitanti": 738,
      "Link": "http://www.comuni-italiani.it/041/041/"
    },
    {
      "Istat": "020039",
      "Comune": "Pegognaga",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46020,
      "CodFisco": "G417",
      "Abitanti": 7299,
      "Link": "http://www.comuni-italiani.it/020/039/"
    },
    {
      "Istat": "016161",
      "Comune": "Peia",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "G418",
      "Abitanti": 1857,
      "Link": "http://www.comuni-italiani.it/016/161/"
    },
    {
      "Istat": "022136",
      "Comune": "Peio",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38024,
      "CodFisco": "G419",
      "Abitanti": 1910,
      "Link": "http://www.comuni-italiani.it/022/136/"
    },
    {
      "Istat": "048032",
      "Comune": "Pelago",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50060,
      "CodFisco": "G420",
      "Abitanti": 7702,
      "Link": "http://www.comuni-italiani.it/048/032/"
    },
    {
      "Istat": "003115",
      "Comune": "Pella",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "G421",
      "Abitanti": 1082,
      "Link": "http://www.comuni-italiani.it/003/115/"
    },
    {
      "Istat": "034028",
      "Comune": "Pellegrino Parmense",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43047,
      "CodFisco": "G424",
      "Abitanti": 1097,
      "Link": "http://www.comuni-italiani.it/034/028/"
    },
    {
      "Istat": "065090",
      "Comune": "Pellezzano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84080,
      "CodFisco": "G426",
      "Abitanti": 10714,
      "Link": "http://www.comuni-italiani.it/065/090/"
    },
    {
      "Istat": "013179",
      "Comune": "Pellio Intelvi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "G427",
      "Abitanti": 1053,
      "Link": "http://www.comuni-italiani.it/013/179/"
    },
    {
      "Istat": "022137",
      "Comune": "Pellizzano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "G428",
      "Abitanti": 784,
      "Link": "http://www.comuni-italiani.it/022/137/"
    },
    {
      "Istat": "022138",
      "Comune": "Pelugo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38079,
      "CodFisco": "G429",
      "Abitanti": 391,
      "Link": "http://www.comuni-italiani.it/022/138/"
    },
    {
      "Istat": "005083",
      "Comune": "Penango",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "G430",
      "Abitanti": 525,
      "Link": "http://www.comuni-italiani.it/005/083/"
    },
    {
      "Istat": "055026",
      "Comune": "Penna in Teverina",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05028",
      "CodFisco": "G432",
      "Abitanti": 1129,
      "Link": "http://www.comuni-italiani.it/055/026/"
    },
    {
      "Istat": "043035",
      "Comune": "Penna San Giovanni",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "G436",
      "Abitanti": 1176,
      "Link": "http://www.comuni-italiani.it/043/035/"
    },
    {
      "Istat": "067033",
      "Comune": "Penna Sant'Andrea",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64039,
      "CodFisco": "G437",
      "Abitanti": 1828,
      "Link": "http://www.comuni-italiani.it/067/033/"
    },
    {
      "Istat": "099024",
      "Comune": "Pennabilli",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47864,
      "CodFisco": "G433",
      "Abitanti": 3002,
      "Link": "http://www.comuni-italiani.it/099/024/"
    },
    {
      "Istat": "069063",
      "Comune": "Pennadomo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "G434",
      "Abitanti": 326,
      "Link": "http://www.comuni-italiani.it/069/063/"
    },
    {
      "Istat": "069064",
      "Comune": "Pennapiedimonte",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "G435",
      "Abitanti": 515,
      "Link": "http://www.comuni-italiani.it/069/064/"
    },
    {
      "Istat": "068027",
      "Comune": "Penne",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65017,
      "CodFisco": "G438",
      "Abitanti": 12873,
      "Link": "http://www.comuni-italiani.it/068/027/"
    },
    {
      "Istat": "079092",
      "Comune": "Pentone",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "G439",
      "Abitanti": 2227,
      "Link": "http://www.comuni-italiani.it/079/092/"
    },
    {
      "Istat": "069065",
      "Comune": "Perano",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "G441",
      "Abitanti": 1717,
      "Link": "http://www.comuni-italiani.it/069/065/"
    },
    {
      "Istat": "025037",
      "Comune": "Perarolo di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32010,
      "CodFisco": "G442",
      "Abitanti": 388,
      "Link": "http://www.comuni-italiani.it/025/037/"
    },
    {
      "Istat": "021063",
      "Comune": "Perca",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "G443",
      "Abitanti": 1455,
      "Link": "http://www.comuni-italiani.it/021/063/"
    },
    {
      "Istat": "058076",
      "Comune": "Percile",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "G444",
      "Abitanti": 238,
      "Link": "http://www.comuni-italiani.it/058/076/"
    },
    {
      "Istat": 105014,
      "Comune": "Perdasdefogu",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08046",
      "CodFisco": "G445",
      "Abitanti": 2122,
      "Link": "http://www.comuni-italiani.it/105/014/"
    },
    {
      "Istat": 107014,
      "Comune": "Perdaxius",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "G446",
      "Abitanti": 1476,
      "Link": "http://www.comuni-italiani.it/107/014/"
    },
    {
      "Istat": "065091",
      "Comune": "Perdifumo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "G447",
      "Abitanti": 1784,
      "Link": "http://www.comuni-italiani.it/065/091/"
    },
    {
      "Istat": "097066",
      "Comune": "Perego",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23888,
      "CodFisco": "G448",
      "Abitanti": 1757,
      "Link": "http://www.comuni-italiani.it/097/066/"
    },
    {
      "Istat": "066067",
      "Comune": "Pereto",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67064,
      "CodFisco": "G449",
      "Abitanti": 760,
      "Link": "http://www.comuni-italiani.it/066/067/"
    },
    {
      "Istat": "090056",
      "Comune": "Perfugas",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07034",
      "CodFisco": "G450",
      "Abitanti": 2486,
      "Link": "http://www.comuni-italiani.it/090/056/"
    },
    {
      "Istat": "051028",
      "Comune": "Pergine Valdarno",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52020,
      "CodFisco": "G451",
      "Abitanti": 3270,
      "Link": "http://www.comuni-italiani.it/051/028/"
    },
    {
      "Istat": "022139",
      "Comune": "Pergine Valsugana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38057,
      "CodFisco": "G452",
      "Abitanti": 20582,
      "Link": "http://www.comuni-italiani.it/022/139/"
    },
    {
      "Istat": "041043",
      "Comune": "Pergola",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61045,
      "CodFisco": "G453",
      "Abitanti": 6667,
      "Link": "http://www.comuni-italiani.it/041/043/"
    },
    {
      "Istat": "008040",
      "Comune": "Perinaldo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18032,
      "CodFisco": "G454",
      "Abitanti": 886,
      "Link": "http://www.comuni-italiani.it/008/040/"
    },
    {
      "Istat": "065092",
      "Comune": "Perito",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "G455",
      "Abitanti": 1022,
      "Link": "http://www.comuni-italiani.it/065/092/"
    },
    {
      "Istat": "097067",
      "Comune": "Perledo",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23828,
      "CodFisco": "G456",
      "Abitanti": 945,
      "Link": "http://www.comuni-italiani.it/097/067/"
    },
    {
      "Istat": "004161",
      "Comune": "Perletto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12070,
      "CodFisco": "G457",
      "Abitanti": 306,
      "Link": "http://www.comuni-italiani.it/004/161/"
    },
    {
      "Istat": "004162",
      "Comune": "Perlo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "G458",
      "Abitanti": 127,
      "Link": "http://www.comuni-italiani.it/004/162/"
    },
    {
      "Istat": "007048",
      "Comune": "Perloz",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "G459",
      "Abitanti": 467,
      "Link": "http://www.comuni-italiani.it/007/048/"
    },
    {
      "Istat": "028061",
      "Comune": "Pernumia",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35020,
      "CodFisco": "G461",
      "Abitanti": 3968,
      "Link": "http://www.comuni-italiani.it/028/061/"
    },
    {
      "Istat": "015170",
      "Comune": "Pero",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20016,
      "CodFisco": "C013",
      "Abitanti": 10749,
      "Link": "http://www.comuni-italiani.it/015/170/"
    },
    {
      "Istat": "001184",
      "Comune": "Perosa Argentina",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10063,
      "CodFisco": "G463",
      "Abitanti": 3438,
      "Link": "http://www.comuni-italiani.it/001/184/"
    },
    {
      "Istat": "001185",
      "Comune": "Perosa Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "G462",
      "Abitanti": 578,
      "Link": "http://www.comuni-italiani.it/001/185/"
    },
    {
      "Istat": "001186",
      "Comune": "Perrero",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G465",
      "Abitanti": 737,
      "Link": "http://www.comuni-italiani.it/001/186/"
    },
    {
      "Istat": "019068",
      "Comune": "Persico Dosimo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26043,
      "CodFisco": "G469",
      "Abitanti": 3370,
      "Link": "http://www.comuni-italiani.it/019/068/"
    },
    {
      "Istat": "002091",
      "Comune": "Pertengo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "G471",
      "Abitanti": 332,
      "Link": "http://www.comuni-italiani.it/002/091/"
    },
    {
      "Istat": "017139",
      "Comune": "Pertica Alta",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "G474",
      "Abitanti": 612,
      "Link": "http://www.comuni-italiani.it/017/139/"
    },
    {
      "Istat": "017140",
      "Comune": "Pertica Bassa",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25078,
      "CodFisco": "G475",
      "Abitanti": 698,
      "Link": "http://www.comuni-italiani.it/017/140/"
    },
    {
      "Istat": "065093",
      "Comune": "Pertosa",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "G476",
      "Abitanti": 714,
      "Link": "http://www.comuni-italiani.it/065/093/"
    },
    {
      "Istat": "001187",
      "Comune": "Pertusio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "G477",
      "Abitanti": 772,
      "Link": "http://www.comuni-italiani.it/001/187/"
    },
    {
      "Istat": "054039",
      "Comune": "Perugia",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "061xx",
      "CodFisco": "G478",
      "Abitanti": 168169,
      "Link": "http://www.comuni-italiani.it/054/039/"
    },
    {
      "Istat": "041044",
      "Comune": "Pesaro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": "6112x",
      "CodFisco": "G479",
      "Abitanti": 95011,
      "Link": "http://www.comuni-italiani.it/041/044/"
    },
    {
      "Istat": "046022",
      "Comune": "Pescaglia",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55064,
      "CodFisco": "G480",
      "Abitanti": 3758,
      "Link": "http://www.comuni-italiani.it/046/022/"
    },
    {
      "Istat": "023058",
      "Comune": "Pescantina",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37026,
      "CodFisco": "G481",
      "Abitanti": 16390,
      "Link": "http://www.comuni-italiani.it/023/058/"
    },
    {
      "Istat": "068028",
      "Comune": "Pescara",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": "6512x",
      "CodFisco": "G482",
      "Abitanti": 123077,
      "Link": "http://www.comuni-italiani.it/068/028/"
    },
    {
      "Istat": "019069",
      "Comune": "Pescarolo ed Uniti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26033,
      "CodFisco": "G483",
      "Abitanti": 1608,
      "Link": "http://www.comuni-italiani.it/019/069/"
    },
    {
      "Istat": "066068",
      "Comune": "Pescasseroli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67032,
      "CodFisco": "G484",
      "Abitanti": 2271,
      "Link": "http://www.comuni-italiani.it/066/068/"
    },
    {
      "Istat": "097068",
      "Comune": "Pescate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23855,
      "CodFisco": "G485",
      "Abitanti": 2188,
      "Link": "http://www.comuni-italiani.it/097/068/"
    },
    {
      "Istat": "094031",
      "Comune": "Pesche",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86090,
      "CodFisco": "G486",
      "Abitanti": 1595,
      "Link": "http://www.comuni-italiani.it/094/031/"
    },
    {
      "Istat": "071038",
      "Comune": "Peschici",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71010,
      "CodFisco": "G487",
      "Abitanti": 4411,
      "Link": "http://www.comuni-italiani.it/071/038/"
    },
    {
      "Istat": "015171",
      "Comune": "Peschiera Borromeo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20068,
      "CodFisco": "G488",
      "Abitanti": 22774,
      "Link": "http://www.comuni-italiani.it/015/171/"
    },
    {
      "Istat": "023059",
      "Comune": "Peschiera del Garda",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37019,
      "CodFisco": "G489",
      "Abitanti": 9984,
      "Link": "http://www.comuni-italiani.it/023/059/"
    },
    {
      "Istat": "047012",
      "Comune": "Pescia",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51017,
      "CodFisco": "G491",
      "Abitanti": 19851,
      "Link": "http://www.comuni-italiani.it/047/012/"
    },
    {
      "Istat": "066069",
      "Comune": "Pescina",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67057,
      "CodFisco": "G492",
      "Abitanti": 4319,
      "Link": "http://www.comuni-italiani.it/066/069/"
    },
    {
      "Istat": "062050",
      "Comune": "Pesco Sannita",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "G494",
      "Abitanti": 2081,
      "Link": "http://www.comuni-italiani.it/062/050/"
    },
    {
      "Istat": "066070",
      "Comune": "Pescocostanzo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67033,
      "CodFisco": "G493",
      "Abitanti": 1179,
      "Link": "http://www.comuni-italiani.it/066/070/"
    },
    {
      "Istat": "094032",
      "Comune": "Pescolanciano",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86097,
      "CodFisco": "G495",
      "Abitanti": 890,
      "Link": "http://www.comuni-italiani.it/094/032/"
    },
    {
      "Istat": "076058",
      "Comune": "Pescopagano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85020,
      "CodFisco": "G496",
      "Abitanti": 2029,
      "Link": "http://www.comuni-italiani.it/076/058/"
    },
    {
      "Istat": "094033",
      "Comune": "Pescopennataro",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "G497",
      "Abitanti": 310,
      "Link": "http://www.comuni-italiani.it/094/033/"
    },
    {
      "Istat": "057049",
      "Comune": "Pescorocchiano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02024",
      "CodFisco": "G498",
      "Abitanti": 2290,
      "Link": "http://www.comuni-italiani.it/057/049/"
    },
    {
      "Istat": "068029",
      "Comune": "Pescosansonesco",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "G499",
      "Abitanti": 527,
      "Link": "http://www.comuni-italiani.it/068/029/"
    },
    {
      "Istat": "060049",
      "Comune": "Pescosolido",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "G500",
      "Abitanti": 1582,
      "Link": "http://www.comuni-italiani.it/060/049/"
    },
    {
      "Istat": "015172",
      "Comune": "Pessano con Bornago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "G502",
      "Abitanti": 9121,
      "Link": "http://www.comuni-italiani.it/015/172/"
    },
    {
      "Istat": "019070",
      "Comune": "Pessina Cremonese",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "G504",
      "Abitanti": 693,
      "Link": "http://www.comuni-italiani.it/019/070/"
    },
    {
      "Istat": "001188",
      "Comune": "Pessinetto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "G505",
      "Abitanti": 614,
      "Link": "http://www.comuni-italiani.it/001/188/"
    },
    {
      "Istat": "070051",
      "Comune": "Petacciato",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86038,
      "CodFisco": "G506",
      "Abitanti": 3656,
      "Link": "http://www.comuni-italiani.it/070/051/"
    },
    {
      "Istat": 101017,
      "Comune": "Petilia Policastro",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88837,
      "CodFisco": "G508",
      "Abitanti": 9311,
      "Link": "http://www.comuni-italiani.it/101/017/"
    },
    {
      "Istat": "065094",
      "Comune": "Petina",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "G509",
      "Abitanti": 1213,
      "Link": "http://www.comuni-italiani.it/065/094/"
    },
    {
      "Istat": "082055",
      "Comune": "Petralia Soprana",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90026,
      "CodFisco": "G510",
      "Abitanti": 3469,
      "Link": "http://www.comuni-italiani.it/082/055/"
    },
    {
      "Istat": "082056",
      "Comune": "Petralia Sottana",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90027,
      "CodFisco": "G511",
      "Abitanti": 2980,
      "Link": "http://www.comuni-italiani.it/082/056/"
    },
    {
      "Istat": "057050",
      "Comune": "Petrella Salto",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02025",
      "CodFisco": "G513",
      "Abitanti": 1271,
      "Link": "http://www.comuni-italiani.it/057/050/"
    },
    {
      "Istat": "070052",
      "Comune": "Petrella Tifernina",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86024,
      "CodFisco": "G512",
      "Abitanti": 1245,
      "Link": "http://www.comuni-italiani.it/070/052/"
    },
    {
      "Istat": "041045",
      "Comune": "Petriano",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61020,
      "CodFisco": "G514",
      "Abitanti": 2925,
      "Link": "http://www.comuni-italiani.it/041/045/"
    },
    {
      "Istat": "043036",
      "Comune": "Petriolo",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62014,
      "CodFisco": "G515",
      "Abitanti": 2070,
      "Link": "http://www.comuni-italiani.it/043/036/"
    },
    {
      "Istat": 109031,
      "Comune": "Petritoli",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63848,
      "CodFisco": "G516",
      "Abitanti": 2483,
      "Link": "http://www.comuni-italiani.it/109/031/"
    },
    {
      "Istat": "079094",
      "Comune": "Petrizzi",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "G517",
      "Abitanti": 1167,
      "Link": "http://www.comuni-italiani.it/079/094/"
    },
    {
      "Istat": "079095",
      "Comune": "Petron‡",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "G518",
      "Abitanti": 2697,
      "Link": "http://www.comuni-italiani.it/079/095/"
    },
    {
      "Istat": "081024",
      "Comune": "Petrosino",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91020,
      "CodFisco": "M281",
      "Abitanti": 7674,
      "Link": "http://www.comuni-italiani.it/081/024/"
    },
    {
      "Istat": "064071",
      "Comune": "Petruro Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "G519",
      "Abitanti": 359,
      "Link": "http://www.comuni-italiani.it/064/071/"
    },
    {
      "Istat": "003116",
      "Comune": "Pettenasco",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28028,
      "CodFisco": "G520",
      "Abitanti": 1392,
      "Link": "http://www.comuni-italiani.it/003/116/"
    },
    {
      "Istat": "096042",
      "Comune": "Pettinengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13843,
      "CodFisco": "G521",
      "Abitanti": 1564,
      "Link": "http://www.comuni-italiani.it/096/042/"
    },
    {
      "Istat": "083067",
      "Comune": "Pettineo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98070,
      "CodFisco": "G522",
      "Abitanti": 1454,
      "Link": "http://www.comuni-italiani.it/083/067/"
    },
    {
      "Istat": "094034",
      "Comune": "Pettoranello del Molise",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86090,
      "CodFisco": "G523",
      "Abitanti": 481,
      "Link": "http://www.comuni-italiani.it/094/034/"
    },
    {
      "Istat": "066071",
      "Comune": "Pettorano sul Gizio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67034,
      "CodFisco": "G524",
      "Abitanti": 1373,
      "Link": "http://www.comuni-italiani.it/066/071/"
    },
    {
      "Istat": "029035",
      "Comune": "Pettorazza Grimani",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45010,
      "CodFisco": "G525",
      "Abitanti": 1699,
      "Link": "http://www.comuni-italiani.it/029/035/"
    },
    {
      "Istat": "004163",
      "Comune": "Peveragno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12016,
      "CodFisco": "G526",
      "Abitanti": 5496,
      "Link": "http://www.comuni-italiani.it/004/163/"
    },
    {
      "Istat": "002093",
      "Comune": "Pezzana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13010,
      "CodFisco": "G528",
      "Abitanti": 1338,
      "Link": "http://www.comuni-italiani.it/002/093/"
    },
    {
      "Istat": "017141",
      "Comune": "Pezzaze",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "G529",
      "Abitanti": 1616,
      "Link": "http://www.comuni-italiani.it/017/141/"
    },
    {
      "Istat": "004164",
      "Comune": "Pezzolo Valle Uzzone",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12070,
      "CodFisco": "G532",
      "Abitanti": 353,
      "Link": "http://www.comuni-italiani.it/004/164/"
    },
    {
      "Istat": "033032",
      "Comune": "Piacenza",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": "2912x",
      "CodFisco": "G535",
      "Abitanti": 103206,
      "Link": "http://www.comuni-italiani.it/033/032/"
    },
    {
      "Istat": "028062",
      "Comune": "Piacenza d'Adige",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35040,
      "CodFisco": "G534",
      "Abitanti": 1407,
      "Link": "http://www.comuni-italiani.it/028/062/"
    },
    {
      "Istat": "019071",
      "Comune": "Piadena",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26034,
      "CodFisco": "G536",
      "Abitanti": 3645,
      "Link": "http://www.comuni-italiani.it/019/071/"
    },
    {
      "Istat": "041046",
      "Comune": "Piagge",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "G537",
      "Abitanti": 1029,
      "Link": "http://www.comuni-italiani.it/041/046/"
    },
    {
      "Istat": "065095",
      "Comune": "Piaggine",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84065,
      "CodFisco": "G538",
      "Abitanti": 1478,
      "Link": "http://www.comuni-italiani.it/065/095/"
    },
    {
      "Istat": "017142",
      "Comune": "Pian Camuno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "G546",
      "Abitanti": 4369,
      "Link": "http://www.comuni-italiani.it/017/142/"
    },
    {
      "Istat": "051029",
      "Comune": "Pian di Sco",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52026,
      "CodFisco": "G552",
      "Abitanti": 6407,
      "Link": "http://www.comuni-italiani.it/051/029/"
    },
    {
      "Istat": "009048",
      "Comune": "Piana Crixia",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17058,
      "CodFisco": "G542",
      "Abitanti": 896,
      "Link": "http://www.comuni-italiani.it/009/048/"
    },
    {
      "Istat": "082057",
      "Comune": "Piana degli Albanesi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90037,
      "CodFisco": "G543",
      "Abitanti": 6018,
      "Link": "http://www.comuni-italiani.it/082/057/"
    },
    {
      "Istat": "061056",
      "Comune": "Piana di Monte Verna",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81013,
      "CodFisco": "G541",
      "Abitanti": 2402,
      "Link": "http://www.comuni-italiani.it/061/056/"
    },
    {
      "Istat": "052020",
      "Comune": "Piancastagnaio",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53025,
      "CodFisco": "G547",
      "Abitanti": 4187,
      "Link": "http://www.comuni-italiani.it/052/020/"
    },
    {
      "Istat": "017206",
      "Comune": "Piancogno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25052,
      "CodFisco": "G549",
      "Abitanti": 4707,
      "Link": "http://www.comuni-italiani.it/017/206/"
    },
    {
      "Istat": "041047",
      "Comune": "Piandimeleto",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61026,
      "CodFisco": "G551",
      "Abitanti": 2150,
      "Link": "http://www.comuni-italiani.it/041/047/"
    },
    {
      "Istat": "078097",
      "Comune": "Piane Crati",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "G553",
      "Abitanti": 1454,
      "Link": "http://www.comuni-italiani.it/078/097/"
    },
    {
      "Istat": "068030",
      "Comune": "Pianella",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65019,
      "CodFisco": "G555",
      "Abitanti": 8467,
      "Link": "http://www.comuni-italiani.it/068/030/"
    },
    {
      "Istat": "013183",
      "Comune": "Pianello del Lario",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "G556",
      "Abitanti": 1050,
      "Link": "http://www.comuni-italiani.it/013/183/"
    },
    {
      "Istat": "033033",
      "Comune": "Pianello Val Tidone",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "G557",
      "Abitanti": 2296,
      "Link": "http://www.comuni-italiani.it/033/033/"
    },
    {
      "Istat": "019072",
      "Comune": "Pianengo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "G558",
      "Abitanti": 2617,
      "Link": "http://www.comuni-italiani.it/019/072/"
    },
    {
      "Istat": "001189",
      "Comune": "Pianezza",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10044,
      "CodFisco": "G559",
      "Abitanti": 14088,
      "Link": "http://www.comuni-italiani.it/001/189/"
    },
    {
      "Istat": "024077",
      "Comune": "Pianezze",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36060,
      "CodFisco": "G560",
      "Abitanti": 2080,
      "Link": "http://www.comuni-italiani.it/024/077/"
    },
    {
      "Istat": "004165",
      "Comune": "Pianfei",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "G561",
      "Abitanti": 2200,
      "Link": "http://www.comuni-italiani.it/004/165/"
    },
    {
      "Istat": "016162",
      "Comune": "Pianico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "G564",
      "Abitanti": 1544,
      "Link": "http://www.comuni-italiani.it/016/162/"
    },
    {
      "Istat": "027028",
      "Comune": "Pianiga",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30030,
      "CodFisco": "G565",
      "Abitanti": 11927,
      "Link": "http://www.comuni-italiani.it/027/028/"
    },
    {
      "Istat": "063053",
      "Comune": "Piano di Sorrento",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80063,
      "CodFisco": "G568",
      "Abitanti": 13136,
      "Link": "http://www.comuni-italiani.it/063/053/"
    },
    {
      "Istat": "079096",
      "Comune": "Pianopoli",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "D546",
      "Abitanti": 2553,
      "Link": "http://www.comuni-italiani.it/079/096/"
    },
    {
      "Istat": "037047",
      "Comune": "Pianoro",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40065,
      "CodFisco": "G570",
      "Abitanti": 17268,
      "Link": "http://www.comuni-italiani.it/037/047/"
    },
    {
      "Istat": "056043",
      "Comune": "Piansano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "G571",
      "Abitanti": 2189,
      "Link": "http://www.comuni-italiani.it/056/043/"
    },
    {
      "Istat": "014048",
      "Comune": "Piantedo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "G572",
      "Abitanti": 1302,
      "Link": "http://www.comuni-italiani.it/014/048/"
    },
    {
      "Istat": "016163",
      "Comune": "Piario",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "G574",
      "Abitanti": 1106,
      "Link": "http://www.comuni-italiani.it/016/163/"
    },
    {
      "Istat": "004166",
      "Comune": "Piasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12026,
      "CodFisco": "G575",
      "Abitanti": 2855,
      "Link": "http://www.comuni-italiani.it/004/166/"
    },
    {
      "Istat": "014049",
      "Comune": "Piateda",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "G576",
      "Abitanti": 2340,
      "Link": "http://www.comuni-italiani.it/014/049/"
    },
    {
      "Istat": "096043",
      "Comune": "Piatto",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13844,
      "CodFisco": "G577",
      "Abitanti": 559,
      "Link": "http://www.comuni-italiani.it/096/043/"
    },
    {
      "Istat": "046023",
      "Comune": "Piazza al Serchio",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55035,
      "CodFisco": "G582",
      "Abitanti": 2501,
      "Link": "http://www.comuni-italiani.it/046/023/"
    },
    {
      "Istat": "086014",
      "Comune": "Piazza Armerina",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94015,
      "CodFisco": "G580",
      "Abitanti": 20998,
      "Link": "http://www.comuni-italiani.it/086/014/"
    },
    {
      "Istat": "016164",
      "Comune": "Piazza Brembana",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24014,
      "CodFisco": "G579",
      "Abitanti": 1256,
      "Link": "http://www.comuni-italiani.it/016/164/"
    },
    {
      "Istat": "016165",
      "Comune": "Piazzatorre",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "G583",
      "Abitanti": 444,
      "Link": "http://www.comuni-italiani.it/016/165/"
    },
    {
      "Istat": "028063",
      "Comune": "Piazzola sul Brenta",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35016,
      "CodFisco": "G587",
      "Abitanti": 11207,
      "Link": "http://www.comuni-italiani.it/028/063/"
    },
    {
      "Istat": "016166",
      "Comune": "Piazzolo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "G588",
      "Abitanti": 86,
      "Link": "http://www.comuni-italiani.it/016/166/"
    },
    {
      "Istat": "068031",
      "Comune": "Picciano",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "G589",
      "Abitanti": 1347,
      "Link": "http://www.comuni-italiani.it/068/031/"
    },
    {
      "Istat": "076059",
      "Comune": "Picerno",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85055,
      "CodFisco": "G590",
      "Abitanti": 6090,
      "Link": "http://www.comuni-italiani.it/076/059/"
    },
    {
      "Istat": "060050",
      "Comune": "Picinisco",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "G591",
      "Abitanti": 1256,
      "Link": "http://www.comuni-italiani.it/060/050/"
    },
    {
      "Istat": "060051",
      "Comune": "Pico",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03020",
      "CodFisco": "G592",
      "Abitanti": 3065,
      "Link": "http://www.comuni-italiani.it/060/051/"
    },
    {
      "Istat": "005084",
      "Comune": "Piea",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "G593",
      "Abitanti": 636,
      "Link": "http://www.comuni-italiani.it/005/084/"
    },
    {
      "Istat": "096044",
      "Comune": "Piedicavallo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13812,
      "CodFisco": "G594",
      "Abitanti": 210,
      "Link": "http://www.comuni-italiani.it/096/044/"
    },
    {
      "Istat": "087035",
      "Comune": "Piedimonte Etneo",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95017,
      "CodFisco": "G597",
      "Abitanti": 4106,
      "Link": "http://www.comuni-italiani.it/087/035/"
    },
    {
      "Istat": "061057",
      "Comune": "Piedimonte Matese",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81016,
      "CodFisco": "G596",
      "Abitanti": 11428,
      "Link": "http://www.comuni-italiani.it/061/057/"
    },
    {
      "Istat": "060052",
      "Comune": "Piedimonte San Germano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "G598",
      "Abitanti": 6267,
      "Link": "http://www.comuni-italiani.it/060/052/"
    },
    {
      "Istat": 103053,
      "Comune": "Piedimulera",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28885,
      "CodFisco": "G600",
      "Abitanti": 1606,
      "Link": "http://www.comuni-italiani.it/103/053/"
    },
    {
      "Istat": "054040",
      "Comune": "Piegaro",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06066",
      "CodFisco": "G601",
      "Abitanti": 3847,
      "Link": "http://www.comuni-italiani.it/054/040/"
    },
    {
      "Istat": "052021",
      "Comune": "Pienza",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53026,
      "CodFisco": "G602",
      "Abitanti": 2186,
      "Link": "http://www.comuni-italiani.it/052/021/"
    },
    {
      "Istat": "019073",
      "Comune": "Pieranica",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26017,
      "CodFisco": "G603",
      "Abitanti": 1181,
      "Link": "http://www.comuni-italiani.it/019/073/"
    },
    {
      "Istat": "018111",
      "Comune": "Pietra de' Giorgi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "G612",
      "Abitanti": 939,
      "Link": "http://www.comuni-italiani.it/018/111/"
    },
    {
      "Istat": "009049",
      "Comune": "Pietra Ligure",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17027,
      "CodFisco": "G605",
      "Abitanti": 9401,
      "Link": "http://www.comuni-italiani.it/009/049/"
    },
    {
      "Istat": "006129",
      "Comune": "Pietra Marazzi",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "G619",
      "Abitanti": 914,
      "Link": "http://www.comuni-italiani.it/006/129/"
    },
    {
      "Istat": "094035",
      "Comune": "Pietrabbondante",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86085,
      "CodFisco": "G606",
      "Abitanti": 822,
      "Link": "http://www.comuni-italiani.it/094/035/"
    },
    {
      "Istat": "008041",
      "Comune": "Pietrabruna",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18010,
      "CodFisco": "G607",
      "Abitanti": 558,
      "Link": "http://www.comuni-italiani.it/008/041/"
    },
    {
      "Istat": "067034",
      "Comune": "Pietracamela",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64047,
      "CodFisco": "G608",
      "Abitanti": 298,
      "Link": "http://www.comuni-italiani.it/067/034/"
    },
    {
      "Istat": "070053",
      "Comune": "Pietracatella",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "G609",
      "Abitanti": 1479,
      "Link": "http://www.comuni-italiani.it/070/053/"
    },
    {
      "Istat": "070054",
      "Comune": "Pietracupa",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "G610",
      "Abitanti": 234,
      "Link": "http://www.comuni-italiani.it/070/054/"
    },
    {
      "Istat": "064072",
      "Comune": "Pietradefusi",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "G611",
      "Abitanti": 2472,
      "Link": "http://www.comuni-italiani.it/064/072/"
    },
    {
      "Istat": "069103",
      "Comune": "Pietraferrazzana",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "G613",
      "Abitanti": 130,
      "Link": "http://www.comuni-italiani.it/069/103/"
    },
    {
      "Istat": "078098",
      "Comune": "Pietrafitta",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "G615",
      "Abitanti": 1397,
      "Link": "http://www.comuni-italiani.it/078/098/"
    },
    {
      "Istat": "076060",
      "Comune": "Pietragalla",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85016,
      "CodFisco": "G616",
      "Abitanti": 4332,
      "Link": "http://www.comuni-italiani.it/076/060/"
    },
    {
      "Istat": "054041",
      "Comune": "Pietralunga",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06026",
      "CodFisco": "G618",
      "Abitanti": 2270,
      "Link": "http://www.comuni-italiani.it/054/041/"
    },
    {
      "Istat": "061058",
      "Comune": "Pietramelara",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81051,
      "CodFisco": "G620",
      "Abitanti": 4810,
      "Link": "http://www.comuni-italiani.it/061/058/"
    },
    {
      "Istat": "071039",
      "Comune": "Pietramontecorvino",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71038,
      "CodFisco": "G604",
      "Abitanti": 2765,
      "Link": "http://www.comuni-italiani.it/071/039/"
    },
    {
      "Istat": "068032",
      "Comune": "Pietranico",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "G621",
      "Abitanti": 523,
      "Link": "http://www.comuni-italiani.it/068/032/"
    },
    {
      "Istat": "078099",
      "Comune": "Pietrapaola",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "G622",
      "Abitanti": 1197,
      "Link": "http://www.comuni-italiani.it/078/099/"
    },
    {
      "Istat": "076061",
      "Comune": "Pietrapertosa",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "G623",
      "Abitanti": 1129,
      "Link": "http://www.comuni-italiani.it/076/061/"
    },
    {
      "Istat": "086015",
      "Comune": "Pietraperzia",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 94016,
      "CodFisco": "G624",
      "Abitanti": 7277,
      "Link": "http://www.comuni-italiani.it/086/015/"
    },
    {
      "Istat": "004167",
      "Comune": "Pietraporzio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "G625",
      "Abitanti": 94,
      "Link": "http://www.comuni-italiani.it/004/167/"
    },
    {
      "Istat": "062051",
      "Comune": "Pietraroja",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "G626",
      "Abitanti": 600,
      "Link": "http://www.comuni-italiani.it/062/051/"
    },
    {
      "Istat": "041048",
      "Comune": "Pietrarubbia",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61023,
      "CodFisco": "G627",
      "Abitanti": 711,
      "Link": "http://www.comuni-italiani.it/041/048/"
    },
    {
      "Istat": "046024",
      "Comune": "Pietrasanta",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55045,
      "CodFisco": "G628",
      "Abitanti": 24931,
      "Link": "http://www.comuni-italiani.it/046/024/"
    },
    {
      "Istat": "064073",
      "Comune": "Pietrastornina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83015,
      "CodFisco": "G629",
      "Abitanti": 1567,
      "Link": "http://www.comuni-italiani.it/064/073/"
    },
    {
      "Istat": "061059",
      "Comune": "Pietravairano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "G630",
      "Abitanti": 3090,
      "Link": "http://www.comuni-italiani.it/061/059/"
    },
    {
      "Istat": "062052",
      "Comune": "Pietrelcina",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "G631",
      "Abitanti": 3083,
      "Link": "http://www.comuni-italiani.it/062/052/"
    },
    {
      "Istat": "047013",
      "Comune": "Pieve a Nievole",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51018,
      "CodFisco": "G636",
      "Abitanti": 9632,
      "Link": "http://www.comuni-italiani.it/047/013/"
    },
    {
      "Istat": "018112",
      "Comune": "Pieve Albignola",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27030,
      "CodFisco": "G635",
      "Abitanti": 924,
      "Link": "http://www.comuni-italiani.it/018/112/"
    },
    {
      "Istat": "025038",
      "Comune": "Pieve d'Alpago",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "G638",
      "Abitanti": 1969,
      "Link": "http://www.comuni-italiani.it/025/038/"
    },
    {
      "Istat": "018113",
      "Comune": "Pieve del Cairo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27037,
      "CodFisco": "G639",
      "Abitanti": 2168,
      "Link": "http://www.comuni-italiani.it/018/113/"
    },
    {
      "Istat": "022140",
      "Comune": "Pieve di Bono",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38085,
      "CodFisco": "G641",
      "Abitanti": 1380,
      "Link": "http://www.comuni-italiani.it/022/140/"
    },
    {
      "Istat": "025039",
      "Comune": "Pieve di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32044,
      "CodFisco": "G642",
      "Abitanti": 4017,
      "Link": "http://www.comuni-italiani.it/025/039/"
    },
    {
      "Istat": "037048",
      "Comune": "Pieve di Cento",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40066,
      "CodFisco": "G643",
      "Abitanti": 6959,
      "Link": "http://www.comuni-italiani.it/037/048/"
    },
    {
      "Istat": "020040",
      "Comune": "Pieve di Coriano",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "G633",
      "Abitanti": 1069,
      "Link": "http://www.comuni-italiani.it/020/040/"
    },
    {
      "Istat": "026057",
      "Comune": "Pieve di Soligo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31053,
      "CodFisco": "G645",
      "Abitanti": 12159,
      "Link": "http://www.comuni-italiani.it/026/057/"
    },
    {
      "Istat": "008042",
      "Comune": "Pieve di Teco",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18026,
      "CodFisco": "G632",
      "Abitanti": 1421,
      "Link": "http://www.comuni-italiani.it/008/042/"
    },
    {
      "Istat": "019074",
      "Comune": "Pieve d'Olmi",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26040,
      "CodFisco": "G647",
      "Abitanti": 1297,
      "Link": "http://www.comuni-italiani.it/019/074/"
    },
    {
      "Istat": "015173",
      "Comune": "Pieve Emanuele",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "G634",
      "Abitanti": 14887,
      "Link": "http://www.comuni-italiani.it/015/173/"
    },
    {
      "Istat": "098045",
      "Comune": "Pieve Fissiraga",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26854,
      "CodFisco": "G096",
      "Abitanti": 1680,
      "Link": "http://www.comuni-italiani.it/098/045/"
    },
    {
      "Istat": "046025",
      "Comune": "Pieve Fosciana",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55036,
      "CodFisco": "G648",
      "Abitanti": 2450,
      "Link": "http://www.comuni-italiani.it/046/025/"
    },
    {
      "Istat": "010043",
      "Comune": "Pieve Ligure",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16030,
      "CodFisco": "G646",
      "Abitanti": 2545,
      "Link": "http://www.comuni-italiani.it/010/043/"
    },
    {
      "Istat": "018114",
      "Comune": "Pieve Porto Morone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27017,
      "CodFisco": "G650",
      "Abitanti": 2801,
      "Link": "http://www.comuni-italiani.it/018/114/"
    },
    {
      "Istat": "019075",
      "Comune": "Pieve San Giacomo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26035,
      "CodFisco": "G651",
      "Abitanti": 1625,
      "Link": "http://www.comuni-italiani.it/019/075/"
    },
    {
      "Istat": "051030",
      "Comune": "Pieve Santo Stefano",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52036,
      "CodFisco": "G653",
      "Abitanti": 3249,
      "Link": "http://www.comuni-italiani.it/051/030/"
    },
    {
      "Istat": "022142",
      "Comune": "Pieve Tesino",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "G656",
      "Abitanti": 679,
      "Link": "http://www.comuni-italiani.it/022/142/"
    },
    {
      "Istat": "043038",
      "Comune": "Pieve Torina",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62036,
      "CodFisco": "G657",
      "Abitanti": 1501,
      "Link": "http://www.comuni-italiani.it/043/038/"
    },
    {
      "Istat": 103054,
      "Comune": "Pieve Vergonte",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28886,
      "CodFisco": "G658",
      "Abitanti": 2682,
      "Link": "http://www.comuni-italiani.it/103/054/"
    },
    {
      "Istat": "043037",
      "Comune": "Pievebovigliana",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62035,
      "CodFisco": "G637",
      "Abitanti": 877,
      "Link": "http://www.comuni-italiani.it/043/037/"
    },
    {
      "Istat": "036031",
      "Comune": "Pievepelago",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41027,
      "CodFisco": "G649",
      "Abitanti": 2304,
      "Link": "http://www.comuni-italiani.it/036/031/"
    },
    {
      "Istat": "060053",
      "Comune": "Piglio",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "G659",
      "Abitanti": 4775,
      "Link": "http://www.comuni-italiani.it/060/053/"
    },
    {
      "Istat": "008043",
      "Comune": "Pigna",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18037,
      "CodFisco": "G660",
      "Abitanti": 919,
      "Link": "http://www.comuni-italiani.it/008/043/"
    },
    {
      "Istat": "060054",
      "Comune": "Pignataro Interamna",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "G662",
      "Abitanti": 2595,
      "Link": "http://www.comuni-italiani.it/060/054/"
    },
    {
      "Istat": "061060",
      "Comune": "Pignataro Maggiore",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81052,
      "CodFisco": "G661",
      "Abitanti": 6281,
      "Link": "http://www.comuni-italiani.it/061/060/"
    },
    {
      "Istat": "076062",
      "Comune": "Pignola",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "G663",
      "Abitanti": 6671,
      "Link": "http://www.comuni-italiani.it/076/062/"
    },
    {
      "Istat": "011021",
      "Comune": "Pignone",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "G664",
      "Abitanti": 611,
      "Link": "http://www.comuni-italiani.it/011/021/"
    },
    {
      "Istat": "013184",
      "Comune": "Pigra",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "G665",
      "Abitanti": 270,
      "Link": "http://www.comuni-italiani.it/013/184/"
    },
    {
      "Istat": "002096",
      "Comune": "Pila",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "G666",
      "Abitanti": 140,
      "Link": "http://www.comuni-italiani.it/002/096/"
    },
    {
      "Istat": "092048",
      "Comune": "Pimentel",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "G669",
      "Abitanti": 1201,
      "Link": "http://www.comuni-italiani.it/092/048/"
    },
    {
      "Istat": "063054",
      "Comune": "Pimonte",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80050,
      "CodFisco": "G670",
      "Abitanti": 6033,
      "Link": "http://www.comuni-italiani.it/063/054/"
    },
    {
      "Istat": "018115",
      "Comune": "Pinarolo Po",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27040,
      "CodFisco": "G671",
      "Abitanti": 1730,
      "Link": "http://www.comuni-italiani.it/018/115/"
    },
    {
      "Istat": "001190",
      "Comune": "Pinasca",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G672",
      "Abitanti": 3073,
      "Link": "http://www.comuni-italiani.it/001/190/"
    },
    {
      "Istat": "029036",
      "Comune": "Pincara",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "G673",
      "Abitanti": 1281,
      "Link": "http://www.comuni-italiani.it/029/036/"
    },
    {
      "Istat": "001191",
      "Comune": "Pinerolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10064,
      "CodFisco": "G674",
      "Abitanti": 36158,
      "Link": "http://www.comuni-italiani.it/001/191/"
    },
    {
      "Istat": "067035",
      "Comune": "Pineto",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64025,
      "CodFisco": "F831",
      "Abitanti": 14707,
      "Link": "http://www.comuni-italiani.it/067/035/"
    },
    {
      "Istat": "005085",
      "Comune": "Pino d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 14020,
      "CodFisco": "G676",
      "Abitanti": 242,
      "Link": "http://www.comuni-italiani.it/005/085/"
    },
    {
      "Istat": "012112",
      "Comune": "Pino sulla Sponda del Lago Maggiore",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "G677",
      "Abitanti": 224,
      "Link": "http://www.comuni-italiani.it/012/112/"
    },
    {
      "Istat": "001192",
      "Comune": "Pino Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10025,
      "CodFisco": "G678",
      "Abitanti": 8648,
      "Link": "http://www.comuni-italiani.it/001/192/"
    },
    {
      "Istat": "093030",
      "Comune": "Pinzano al Tagliamento",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33094,
      "CodFisco": "G680",
      "Abitanti": 1613,
      "Link": "http://www.comuni-italiani.it/093/030/"
    },
    {
      "Istat": "022143",
      "Comune": "Pinzolo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38086,
      "CodFisco": "G681",
      "Abitanti": 3157,
      "Link": "http://www.comuni-italiani.it/022/143/"
    },
    {
      "Istat": "041049",
      "Comune": "Piobbico",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61046,
      "CodFisco": "G682",
      "Abitanti": 2118,
      "Link": "http://www.comuni-italiani.it/041/049/"
    },
    {
      "Istat": "004168",
      "Comune": "Piobesi d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "G683",
      "Abitanti": 1279,
      "Link": "http://www.comuni-italiani.it/004/168/"
    },
    {
      "Istat": "001193",
      "Comune": "Piobesi Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "G684",
      "Abitanti": 3723,
      "Link": "http://www.comuni-italiani.it/001/193/"
    },
    {
      "Istat": "002097",
      "Comune": "Piode",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "G685",
      "Abitanti": 195,
      "Link": "http://www.comuni-italiani.it/002/097/"
    },
    {
      "Istat": "015175",
      "Comune": "Pioltello",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20096,
      "CodFisco": "G686",
      "Abitanti": 36369,
      "Link": "http://www.comuni-italiani.it/015/175/"
    },
    {
      "Istat": "049012",
      "Comune": "Piombino",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57025,
      "CodFisco": "G687",
      "Abitanti": 35075,
      "Link": "http://www.comuni-italiani.it/049/012/"
    },
    {
      "Istat": "028064",
      "Comune": "Piombino Dese",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35017,
      "CodFisco": "G688",
      "Abitanti": 9443,
      "Link": "http://www.comuni-italiani.it/028/064/"
    },
    {
      "Istat": "043039",
      "Comune": "Pioraco",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62025,
      "CodFisco": "G690",
      "Abitanti": 1307,
      "Link": "http://www.comuni-italiani.it/043/039/"
    },
    {
      "Istat": "001194",
      "Comune": "Piossasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10045,
      "CodFisco": "G691",
      "Abitanti": 18279,
      "Link": "http://www.comuni-italiani.it/001/194/"
    },
    {
      "Istat": "005086",
      "Comune": "Piov‡ Massaia",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14026,
      "CodFisco": "G692",
      "Abitanti": 684,
      "Link": "http://www.comuni-italiani.it/005/086/"
    },
    {
      "Istat": "028065",
      "Comune": "Piove di Sacco",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35028,
      "CodFisco": "G693",
      "Abitanti": 19413,
      "Link": "http://www.comuni-italiani.it/028/065/"
    },
    {
      "Istat": "024078",
      "Comune": "Piovene Rocchette",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36013,
      "CodFisco": "G694",
      "Abitanti": 8361,
      "Link": "http://www.comuni-italiani.it/024/078/"
    },
    {
      "Istat": "006130",
      "Comune": "Piovera",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "G695",
      "Abitanti": 818,
      "Link": "http://www.comuni-italiani.it/006/130/"
    },
    {
      "Istat": "033034",
      "Comune": "Piozzano",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "G696",
      "Abitanti": 646,
      "Link": "http://www.comuni-italiani.it/033/034/"
    },
    {
      "Istat": "004169",
      "Comune": "Piozzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "G697",
      "Abitanti": 1003,
      "Link": "http://www.comuni-italiani.it/004/169/"
    },
    {
      "Istat": "083068",
      "Comune": "Piraino",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "G699",
      "Abitanti": 4044,
      "Link": "http://www.comuni-italiani.it/083/068/"
    },
    {
      "Istat": "050026",
      "Comune": "Pisa",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": "5612x",
      "CodFisco": "G702",
      "Abitanti": 88217,
      "Link": "http://www.comuni-italiani.it/050/026/"
    },
    {
      "Istat": "003119",
      "Comune": "Pisano",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "G703",
      "Abitanti": 807,
      "Link": "http://www.comuni-italiani.it/003/119/"
    },
    {
      "Istat": "001195",
      "Comune": "Piscina",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G705",
      "Abitanti": 3435,
      "Link": "http://www.comuni-italiani.it/001/195/"
    },
    {
      "Istat": 107015,
      "Comune": "Piscinas",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "M291",
      "Abitanti": 862,
      "Link": "http://www.comuni-italiani.it/107/015/"
    },
    {
      "Istat": "065096",
      "Comune": "Pisciotta",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84066,
      "CodFisco": "G707",
      "Abitanti": 2880,
      "Link": "http://www.comuni-italiani.it/065/096/"
    },
    {
      "Istat": "017143",
      "Comune": "Pisogne",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25055,
      "CodFisco": "G710",
      "Abitanti": 8115,
      "Link": "http://www.comuni-italiani.it/017/143/"
    },
    {
      "Istat": "058077",
      "Comune": "Pisoniano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00020",
      "CodFisco": "G704",
      "Abitanti": 829,
      "Link": "http://www.comuni-italiani.it/058/077/"
    },
    {
      "Istat": "077020",
      "Comune": "Pisticci",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75015,
      "CodFisco": "G712",
      "Abitanti": 17927,
      "Link": "http://www.comuni-italiani.it/077/020/"
    },
    {
      "Istat": "047014",
      "Comune": "Pistoia",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51100,
      "CodFisco": "G713",
      "Abitanti": 90288,
      "Link": "http://www.comuni-italiani.it/047/014/"
    },
    {
      "Istat": "047015",
      "Comune": "Piteglio",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51020,
      "CodFisco": "G715",
      "Abitanti": 1840,
      "Link": "http://www.comuni-italiani.it/047/015/"
    },
    {
      "Istat": "053019",
      "Comune": "Pitigliano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58017,
      "CodFisco": "G716",
      "Abitanti": 3927,
      "Link": "http://www.comuni-italiani.it/053/019/"
    },
    {
      "Istat": "020041",
      "Comune": "Piubega",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "G717",
      "Abitanti": 1775,
      "Link": "http://www.comuni-italiani.it/020/041/"
    },
    {
      "Istat": "014050",
      "Comune": "Piuro",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "G718",
      "Abitanti": 1975,
      "Link": "http://www.comuni-italiani.it/014/050/"
    },
    {
      "Istat": "001196",
      "Comune": "Piverone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "G719",
      "Abitanti": 1381,
      "Link": "http://www.comuni-italiani.it/001/196/"
    },
    {
      "Istat": "018116",
      "Comune": "Pizzale",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "G720",
      "Abitanti": 733,
      "Link": "http://www.comuni-italiani.it/018/116/"
    },
    {
      "Istat": "019076",
      "Comune": "Pizzighettone",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26026,
      "CodFisco": "G721",
      "Abitanti": 6730,
      "Link": "http://www.comuni-italiani.it/019/076/"
    },
    {
      "Istat": 102027,
      "Comune": "Pizzo",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89812,
      "CodFisco": "G722",
      "Abitanti": 9258,
      "Link": "http://www.comuni-italiani.it/102/027/"
    },
    {
      "Istat": "069066",
      "Comune": "Pizzoferrato",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "G724",
      "Abitanti": 1160,
      "Link": "http://www.comuni-italiani.it/069/066/"
    },
    {
      "Istat": "066072",
      "Comune": "Pizzoli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67017,
      "CodFisco": "G726",
      "Abitanti": 3879,
      "Link": "http://www.comuni-italiani.it/066/072/"
    },
    {
      "Istat": "094036",
      "Comune": "Pizzone",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86071,
      "CodFisco": "G727",
      "Abitanti": 329,
      "Link": "http://www.comuni-italiani.it/094/036/"
    },
    {
      "Istat": 102028,
      "Comune": "Pizzoni",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89834,
      "CodFisco": "G728",
      "Abitanti": 1247,
      "Link": "http://www.comuni-italiani.it/102/028/"
    },
    {
      "Istat": "080059",
      "Comune": "Placanica",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "G729",
      "Abitanti": 1246,
      "Link": "http://www.comuni-italiani.it/080/059/"
    },
    {
      "Istat": "078100",
      "Comune": "Plataci",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "G733",
      "Abitanti": 830,
      "Link": "http://www.comuni-italiani.it/078/100/"
    },
    {
      "Istat": "079099",
      "Comune": "Platania",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "G734",
      "Abitanti": 2267,
      "Link": "http://www.comuni-italiani.it/079/099/"
    },
    {
      "Istat": "080060",
      "Comune": "PlatÏ",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89039,
      "CodFisco": "G735",
      "Abitanti": 3780,
      "Link": "http://www.comuni-italiani.it/080/060/"
    },
    {
      "Istat": "021064",
      "Comune": "Plaus",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39025,
      "CodFisco": "G299",
      "Abitanti": 686,
      "Link": "http://www.comuni-italiani.it/021/064/"
    },
    {
      "Istat": "013185",
      "Comune": "Plesio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "G737",
      "Abitanti": 853,
      "Link": "http://www.comuni-italiani.it/013/185/"
    },
    {
      "Istat": "090057",
      "Comune": "Ploaghe",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07017",
      "CodFisco": "G740",
      "Abitanti": 4726,
      "Link": "http://www.comuni-italiani.it/090/057/"
    },
    {
      "Istat": "009050",
      "Comune": "Plodio",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17043,
      "CodFisco": "G741",
      "Abitanti": 650,
      "Link": "http://www.comuni-italiani.it/009/050/"
    },
    {
      "Istat": "004170",
      "Comune": "Pocapaglia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12060,
      "CodFisco": "G742",
      "Abitanti": 3281,
      "Link": "http://www.comuni-italiani.it/004/170/"
    },
    {
      "Istat": "030075",
      "Comune": "Pocenia",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "G743",
      "Abitanti": 2619,
      "Link": "http://www.comuni-italiani.it/030/075/"
    },
    {
      "Istat": "045013",
      "Comune": "Podenzana",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54010,
      "CodFisco": "G746",
      "Abitanti": 2178,
      "Link": "http://www.comuni-italiani.it/045/013/"
    },
    {
      "Istat": "033035",
      "Comune": "Podenzano",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29027,
      "CodFisco": "G747",
      "Abitanti": 9081,
      "Link": "http://www.comuni-italiani.it/033/035/"
    },
    {
      "Istat": "060055",
      "Comune": "Pofi",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03026",
      "CodFisco": "G749",
      "Abitanti": 4423,
      "Link": "http://www.comuni-italiani.it/060/055/"
    },
    {
      "Istat": "075061",
      "Comune": "Poggiardo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73037,
      "CodFisco": "G751",
      "Abitanti": 6140,
      "Link": "http://www.comuni-italiani.it/075/061/"
    },
    {
      "Istat": "052022",
      "Comune": "Poggibonsi",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53036,
      "CodFisco": "G752",
      "Abitanti": 29634,
      "Link": "http://www.comuni-italiani.it/052/022/"
    },
    {
      "Istat": 100004,
      "Comune": "Poggio a Caiano",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 59016,
      "CodFisco": "G754",
      "Abitanti": 9959,
      "Link": "http://www.comuni-italiani.it/100/004/"
    },
    {
      "Istat": "099012",
      "Comune": "Poggio Berni",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47824,
      "CodFisco": "G755",
      "Abitanti": 3412,
      "Link": "http://www.comuni-italiani.it/099/012/"
    },
    {
      "Istat": "057051",
      "Comune": "Poggio Bustone",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02018",
      "CodFisco": "G756",
      "Abitanti": 2163,
      "Link": "http://www.comuni-italiani.it/057/051/"
    },
    {
      "Istat": "057052",
      "Comune": "Poggio Catino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "G757",
      "Abitanti": 1377,
      "Link": "http://www.comuni-italiani.it/057/052/"
    },
    {
      "Istat": "071040",
      "Comune": "Poggio Imperiale",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71010,
      "CodFisco": "G761",
      "Abitanti": 2835,
      "Link": "http://www.comuni-italiani.it/071/040/"
    },
    {
      "Istat": "057053",
      "Comune": "Poggio Mirteto",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02047",
      "CodFisco": "G763",
      "Abitanti": 6157,
      "Link": "http://www.comuni-italiani.it/057/053/"
    },
    {
      "Istat": "057054",
      "Comune": "Poggio Moiano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02037",
      "CodFisco": "G764",
      "Abitanti": 2959,
      "Link": "http://www.comuni-italiani.it/057/054/"
    },
    {
      "Istat": "057055",
      "Comune": "Poggio Nativo",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02030",
      "CodFisco": "G765",
      "Abitanti": 2527,
      "Link": "http://www.comuni-italiani.it/057/055/"
    },
    {
      "Istat": "066073",
      "Comune": "Poggio Picenze",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67026,
      "CodFisco": "G766",
      "Abitanti": 1067,
      "Link": "http://www.comuni-italiani.it/066/073/"
    },
    {
      "Istat": "038018",
      "Comune": "Poggio Renatico",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44028,
      "CodFisco": "G768",
      "Abitanti": 9634,
      "Link": "http://www.comuni-italiani.it/038/018/"
    },
    {
      "Istat": "020042",
      "Comune": "Poggio Rusco",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46025,
      "CodFisco": "G753",
      "Abitanti": 6649,
      "Link": "http://www.comuni-italiani.it/020/042/"
    },
    {
      "Istat": "057056",
      "Comune": "Poggio San Lorenzo",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02030",
      "CodFisco": "G770",
      "Abitanti": 589,
      "Link": "http://www.comuni-italiani.it/057/056/"
    },
    {
      "Istat": "042037",
      "Comune": "Poggio San Marcello",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "G771",
      "Abitanti": 772,
      "Link": "http://www.comuni-italiani.it/042/037/"
    },
    {
      "Istat": "043040",
      "Comune": "Poggio San Vicino",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62021,
      "CodFisco": "D566",
      "Abitanti": 314,
      "Link": "http://www.comuni-italiani.it/043/040/"
    },
    {
      "Istat": "094037",
      "Comune": "Poggio Sannita",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86086,
      "CodFisco": "B317",
      "Abitanti": 795,
      "Link": "http://www.comuni-italiani.it/094/037/"
    },
    {
      "Istat": "054042",
      "Comune": "Poggiodomo",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06040",
      "CodFisco": "G758",
      "Abitanti": 146,
      "Link": "http://www.comuni-italiani.it/054/042/"
    },
    {
      "Istat": "069067",
      "Comune": "Poggiofiorito",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66030,
      "CodFisco": "G760",
      "Abitanti": 963,
      "Link": "http://www.comuni-italiani.it/069/067/"
    },
    {
      "Istat": "063055",
      "Comune": "Poggiomarino",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "G762",
      "Abitanti": 21353,
      "Link": "http://www.comuni-italiani.it/063/055/"
    },
    {
      "Istat": "081016",
      "Comune": "Poggioreale",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91020,
      "CodFisco": "G767",
      "Abitanti": 1576,
      "Link": "http://www.comuni-italiani.it/081/016/"
    },
    {
      "Istat": "072034",
      "Comune": "Poggiorsini",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70020,
      "CodFisco": "G769",
      "Abitanti": 1452,
      "Link": "http://www.comuni-italiani.it/072/034/"
    },
    {
      "Istat": "014051",
      "Comune": "Poggiridenti",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "G431",
      "Abitanti": 1910,
      "Link": "http://www.comuni-italiani.it/014/051/"
    },
    {
      "Istat": "015176",
      "Comune": "Pogliano Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "G772",
      "Abitanti": 8257,
      "Link": "http://www.comuni-italiani.it/015/176/"
    },
    {
      "Istat": "013186",
      "Comune": "Pognana Lario",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "G773",
      "Abitanti": 793,
      "Link": "http://www.comuni-italiani.it/013/186/"
    },
    {
      "Istat": "016167",
      "Comune": "Pognano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "G774",
      "Abitanti": 1571,
      "Link": "http://www.comuni-italiani.it/016/167/"
    },
    {
      "Istat": "003120",
      "Comune": "Pogno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28076,
      "CodFisco": "G775",
      "Abitanti": 1568,
      "Link": "http://www.comuni-italiani.it/003/120/"
    },
    {
      "Istat": "001197",
      "Comune": "Poirino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10046,
      "CodFisco": "G777",
      "Abitanti": 10245,
      "Link": "http://www.comuni-italiani.it/001/197/"
    },
    {
      "Istat": "024079",
      "Comune": "Pojana Maggiore",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36026,
      "CodFisco": "G776",
      "Abitanti": 4517,
      "Link": "http://www.comuni-italiani.it/024/079/"
    },
    {
      "Istat": "017144",
      "Comune": "Polaveno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "G779",
      "Abitanti": 2715,
      "Link": "http://www.comuni-italiani.it/017/144/"
    },
    {
      "Istat": "093031",
      "Comune": "Polcenigo",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33070,
      "CodFisco": "G780",
      "Abitanti": 3237,
      "Link": "http://www.comuni-italiani.it/093/031/"
    },
    {
      "Istat": "029037",
      "Comune": "Polesella",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45038,
      "CodFisco": "G782",
      "Abitanti": 4201,
      "Link": "http://www.comuni-italiani.it/029/037/"
    },
    {
      "Istat": "034029",
      "Comune": "Polesine Parmense",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43010,
      "CodFisco": "G783",
      "Abitanti": 1522,
      "Link": "http://www.comuni-italiani.it/034/029/"
    },
    {
      "Istat": "058078",
      "Comune": "Poli",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00010",
      "CodFisco": "G784",
      "Abitanti": 2479,
      "Link": "http://www.comuni-italiani.it/058/078/"
    },
    {
      "Istat": 102029,
      "Comune": "Polia",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89813,
      "CodFisco": "G785",
      "Abitanti": 1040,
      "Link": "http://www.comuni-italiani.it/102/029/"
    },
    {
      "Istat": "077021",
      "Comune": "Policoro",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75025,
      "CodFisco": "G786",
      "Abitanti": 16407,
      "Link": "http://www.comuni-italiani.it/077/021/"
    },
    {
      "Istat": "072035",
      "Comune": "Polignano a Mare",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70044,
      "CodFisco": "G787",
      "Abitanti": 17797,
      "Link": "http://www.comuni-italiani.it/072/035/"
    },
    {
      "Istat": "036032",
      "Comune": "Polinago",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41040,
      "CodFisco": "G789",
      "Abitanti": 1775,
      "Link": "http://www.comuni-italiani.it/036/032/"
    },
    {
      "Istat": "055027",
      "Comune": "Polino",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05030",
      "CodFisco": "G790",
      "Abitanti": 266,
      "Link": "http://www.comuni-italiani.it/055/027/"
    },
    {
      "Istat": "080061",
      "Comune": "Polistena",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89024,
      "CodFisco": "G791",
      "Abitanti": 11541,
      "Link": "http://www.comuni-italiani.it/080/061/"
    },
    {
      "Istat": "082058",
      "Comune": "Polizzi Generosa",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90028,
      "CodFisco": "G792",
      "Abitanti": 3656,
      "Link": "http://www.comuni-italiani.it/082/058/"
    },
    {
      "Istat": "065097",
      "Comune": "Polla",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84035,
      "CodFisco": "G793",
      "Abitanti": 5316,
      "Link": "http://www.comuni-italiani.it/065/097/"
    },
    {
      "Istat": "007049",
      "Comune": "Pollein",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "G794",
      "Abitanti": 1528,
      "Link": "http://www.comuni-italiani.it/007/049/"
    },
    {
      "Istat": "063056",
      "Comune": "Pollena Trocchia",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "G795",
      "Abitanti": 13646,
      "Link": "http://www.comuni-italiani.it/063/056/"
    },
    {
      "Istat": "043041",
      "Comune": "Pollenza",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "F567",
      "Abitanti": 6617,
      "Link": "http://www.comuni-italiani.it/043/041/"
    },
    {
      "Istat": "065098",
      "Comune": "Pollica",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84068,
      "CodFisco": "G796",
      "Abitanti": 2460,
      "Link": "http://www.comuni-italiani.it/065/098/"
    },
    {
      "Istat": "082059",
      "Comune": "Pollina",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "G797",
      "Abitanti": 3070,
      "Link": "http://www.comuni-italiani.it/082/059/"
    },
    {
      "Istat": "096046",
      "Comune": "Pollone",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13814,
      "CodFisco": "G798",
      "Abitanti": 2192,
      "Link": "http://www.comuni-italiani.it/096/046/"
    },
    {
      "Istat": "069068",
      "Comune": "Pollutri",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66020,
      "CodFisco": "G799",
      "Abitanti": 2339,
      "Link": "http://www.comuni-italiani.it/069/068/"
    },
    {
      "Istat": "004171",
      "Comune": "Polonghera",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 12030,
      "CodFisco": "G800",
      "Abitanti": 1196,
      "Link": "http://www.comuni-italiani.it/004/171/"
    },
    {
      "Istat": "017145",
      "Comune": "Polpenazze del Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "G801",
      "Abitanti": 2572,
      "Link": "http://www.comuni-italiani.it/017/145/"
    },
    {
      "Istat": "028066",
      "Comune": "Polverara",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "G802",
      "Abitanti": 3048,
      "Link": "http://www.comuni-italiani.it/028/066/"
    },
    {
      "Istat": "042038",
      "Comune": "Polverigi",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60020,
      "CodFisco": "G803",
      "Abitanti": 4325,
      "Link": "http://www.comuni-italiani.it/042/038/"
    },
    {
      "Istat": "050027",
      "Comune": "Pomarance",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0588",
      "CAP": 56045,
      "CodFisco": "G804",
      "Abitanti": 6054,
      "Link": "http://www.comuni-italiani.it/050/027/"
    },
    {
      "Istat": "001198",
      "Comune": "Pomaretto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10063,
      "CodFisco": "G805",
      "Abitanti": 1087,
      "Link": "http://www.comuni-italiani.it/001/198/"
    },
    {
      "Istat": "077022",
      "Comune": "Pomarico",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75016,
      "CodFisco": "G806",
      "Abitanti": 4268,
      "Link": "http://www.comuni-italiani.it/077/022/"
    },
    {
      "Istat": "006131",
      "Comune": "Pomaro Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "G807",
      "Abitanti": 386,
      "Link": "http://www.comuni-italiani.it/006/131/"
    },
    {
      "Istat": "022144",
      "Comune": "Pomarolo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "G808",
      "Abitanti": 2384,
      "Link": "http://www.comuni-italiani.it/022/144/"
    },
    {
      "Istat": "003121",
      "Comune": "Pombia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28050,
      "CodFisco": "G809",
      "Abitanti": 2174,
      "Link": "http://www.comuni-italiani.it/003/121/"
    },
    {
      "Istat": "058079",
      "Comune": "Pomezia",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "G811",
      "Abitanti": 61106,
      "Link": "http://www.comuni-italiani.it/058/079/"
    },
    {
      "Istat": "063057",
      "Comune": "Pomigliano d'Arco",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80038,
      "CodFisco": "G812",
      "Abitanti": 39215,
      "Link": "http://www.comuni-italiani.it/063/057/"
    },
    {
      "Istat": "063058",
      "Comune": "Pompei",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80045,
      "CodFisco": "G813",
      "Abitanti": 25620,
      "Link": "http://www.comuni-italiani.it/063/058/"
    },
    {
      "Istat": "008044",
      "Comune": "Pompeiana",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18015,
      "CodFisco": "G814",
      "Abitanti": 844,
      "Link": "http://www.comuni-italiani.it/008/044/"
    },
    {
      "Istat": "017146",
      "Comune": "Pompiano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "G815",
      "Abitanti": 3911,
      "Link": "http://www.comuni-italiani.it/017/146/"
    },
    {
      "Istat": "020043",
      "Comune": "Pomponesco",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 46030,
      "CodFisco": "G816",
      "Abitanti": 1775,
      "Link": "http://www.comuni-italiani.it/020/043/"
    },
    {
      "Istat": "095042",
      "Comune": "Pompu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09093",
      "CodFisco": "G817",
      "Abitanti": 282,
      "Link": "http://www.comuni-italiani.it/095/042/"
    },
    {
      "Istat": "017147",
      "Comune": "Poncarale",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "G818",
      "Abitanti": 5269,
      "Link": "http://www.comuni-italiani.it/017/147/"
    },
    {
      "Istat": "096047",
      "Comune": "Ponderano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13875,
      "CodFisco": "G820",
      "Abitanti": 4008,
      "Link": "http://www.comuni-italiani.it/096/047/"
    },
    {
      "Istat": "013187",
      "Comune": "Ponna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "G821",
      "Abitanti": 266,
      "Link": "http://www.comuni-italiani.it/013/187/"
    },
    {
      "Istat": "050028",
      "Comune": "Ponsacco",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56038,
      "CodFisco": "G822",
      "Abitanti": 15511,
      "Link": "http://www.comuni-italiani.it/050/028/"
    },
    {
      "Istat": "028067",
      "Comune": "Ponso",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "G823",
      "Abitanti": 2445,
      "Link": "http://www.comuni-italiani.it/028/067/"
    },
    {
      "Istat": "048033",
      "Comune": "Pontassieve",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50065,
      "CodFisco": "G825",
      "Abitanti": 20709,
      "Link": "http://www.comuni-italiani.it/048/033/"
    },
    {
      "Istat": "007050",
      "Comune": "Pontboset",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11020,
      "CodFisco": "G545",
      "Abitanti": 197,
      "Link": "http://www.comuni-italiani.it/007/050/"
    },
    {
      "Istat": "001199",
      "Comune": "Pont-Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10085,
      "CodFisco": "G826",
      "Abitanti": 3756,
      "Link": "http://www.comuni-italiani.it/001/199/"
    },
    {
      "Istat": "062053",
      "Comune": "Ponte",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "G827",
      "Abitanti": 2695,
      "Link": "http://www.comuni-italiani.it/062/053/"
    },
    {
      "Istat": "047016",
      "Comune": "Ponte Buggianese",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51019,
      "CodFisco": "G833",
      "Abitanti": 8840,
      "Link": "http://www.comuni-italiani.it/047/016/"
    },
    {
      "Istat": "033036",
      "Comune": "Ponte dell'Olio",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29028,
      "CodFisco": "G842",
      "Abitanti": 5055,
      "Link": "http://www.comuni-italiani.it/033/036/"
    },
    {
      "Istat": "017148",
      "Comune": "Ponte di Legno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25056,
      "CodFisco": "G844",
      "Abitanti": 1771,
      "Link": "http://www.comuni-italiani.it/017/148/"
    },
    {
      "Istat": "026058",
      "Comune": "Ponte di Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31047,
      "CodFisco": "G846",
      "Abitanti": 8309,
      "Link": "http://www.comuni-italiani.it/026/058/"
    },
    {
      "Istat": "021065",
      "Comune": "Ponte Gardena",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "G830",
      "Abitanti": 198,
      "Link": "http://www.comuni-italiani.it/021/065/"
    },
    {
      "Istat": "014052",
      "Comune": "Ponte in Valtellina",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23026,
      "CodFisco": "G829",
      "Abitanti": 2326,
      "Link": "http://www.comuni-italiani.it/014/052/"
    },
    {
      "Istat": "013188",
      "Comune": "Ponte Lambro",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22037,
      "CodFisco": "G847",
      "Abitanti": 4483,
      "Link": "http://www.comuni-italiani.it/013/188/"
    },
    {
      "Istat": "025040",
      "Comune": "Ponte nelle Alpi",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32014,
      "CodFisco": "B662",
      "Abitanti": 8533,
      "Link": "http://www.comuni-italiani.it/025/040/"
    },
    {
      "Istat": "018117",
      "Comune": "Ponte Nizza",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "G851",
      "Abitanti": 822,
      "Link": "http://www.comuni-italiani.it/018/117/"
    },
    {
      "Istat": "016168",
      "Comune": "Ponte Nossa",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24028,
      "CodFisco": "F941",
      "Abitanti": 1923,
      "Link": "http://www.comuni-italiani.it/016/168/"
    },
    {
      "Istat": "028069",
      "Comune": "Ponte San NicolÚ",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "G855",
      "Abitanti": 13325,
      "Link": "http://www.comuni-italiani.it/028/069/"
    },
    {
      "Istat": "016170",
      "Comune": "Ponte San Pietro",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24036,
      "CodFisco": "G856",
      "Abitanti": 11543,
      "Link": "http://www.comuni-italiani.it/016/170/"
    },
    {
      "Istat": "030076",
      "Comune": "Pontebba",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0428",
      "CAP": 33016,
      "CodFisco": "G831",
      "Abitanti": 1535,
      "Link": "http://www.comuni-italiani.it/030/076/"
    },
    {
      "Istat": "065099",
      "Comune": "Pontecagnano Faiano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84098,
      "CodFisco": "G834",
      "Abitanti": 25307,
      "Link": "http://www.comuni-italiani.it/065/099/"
    },
    {
      "Istat": "029038",
      "Comune": "Pontecchio Polesine",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "G836",
      "Abitanti": 2047,
      "Link": "http://www.comuni-italiani.it/029/038/"
    },
    {
      "Istat": "004172",
      "Comune": "Pontechianale",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "G837",
      "Abitanti": 187,
      "Link": "http://www.comuni-italiani.it/004/172/"
    },
    {
      "Istat": "060056",
      "Comune": "Pontecorvo",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03037",
      "CodFisco": "G838",
      "Abitanti": 13388,
      "Link": "http://www.comuni-italiani.it/060/056/"
    },
    {
      "Istat": "006132",
      "Comune": "Pontecurone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15055,
      "CodFisco": "G839",
      "Abitanti": 3867,
      "Link": "http://www.comuni-italiani.it/006/132/"
    },
    {
      "Istat": "008045",
      "Comune": "Pontedassio",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18027,
      "CodFisco": "G840",
      "Abitanti": 2367,
      "Link": "http://www.comuni-italiani.it/008/045/"
    },
    {
      "Istat": "050029",
      "Comune": "Pontedera",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56025,
      "CodFisco": "G843",
      "Abitanti": 28350,
      "Link": "http://www.comuni-italiani.it/050/029/"
    },
    {
      "Istat": "062054",
      "Comune": "Pontelandolfo",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82027,
      "CodFisco": "G848",
      "Abitanti": 2352,
      "Link": "http://www.comuni-italiani.it/062/054/"
    },
    {
      "Istat": "061061",
      "Comune": "Pontelatone",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "G849",
      "Abitanti": 1819,
      "Link": "http://www.comuni-italiani.it/061/061/"
    },
    {
      "Istat": "028068",
      "Comune": "Pontelongo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35029,
      "CodFisco": "G850",
      "Abitanti": 3938,
      "Link": "http://www.comuni-italiani.it/028/068/"
    },
    {
      "Istat": "033037",
      "Comune": "Pontenure",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "G852",
      "Abitanti": 6365,
      "Link": "http://www.comuni-italiani.it/033/037/"
    },
    {
      "Istat": "016169",
      "Comune": "Ponteranica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24010,
      "CodFisco": "G853",
      "Abitanti": 6825,
      "Link": "http://www.comuni-italiani.it/016/169/"
    },
    {
      "Istat": "006133",
      "Comune": "Pontestura",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15027,
      "CodFisco": "G858",
      "Abitanti": 1485,
      "Link": "http://www.comuni-italiani.it/006/133/"
    },
    {
      "Istat": "017149",
      "Comune": "Pontevico",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25026,
      "CodFisco": "G859",
      "Abitanti": 7221,
      "Link": "http://www.comuni-italiani.it/017/149/"
    },
    {
      "Istat": "007051",
      "Comune": "Pontey",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11024,
      "CodFisco": "G860",
      "Abitanti": 835,
      "Link": "http://www.comuni-italiani.it/007/051/"
    },
    {
      "Istat": "006134",
      "Comune": "Ponti",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "G861",
      "Abitanti": 639,
      "Link": "http://www.comuni-italiani.it/006/134/"
    },
    {
      "Istat": "020044",
      "Comune": "Ponti sul Mincio",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "G862",
      "Abitanti": 2322,
      "Link": "http://www.comuni-italiani.it/020/044/"
    },
    {
      "Istat": "016171",
      "Comune": "Pontida",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "G864",
      "Abitanti": 3229,
      "Link": "http://www.comuni-italiani.it/016/171/"
    },
    {
      "Istat": "059017",
      "Comune": "Pontinia",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04014",
      "CodFisco": "G865",
      "Abitanti": 14209,
      "Link": "http://www.comuni-italiani.it/059/017/"
    },
    {
      "Istat": "009051",
      "Comune": "Pontinvrea",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17042,
      "CodFisco": "G866",
      "Abitanti": 857,
      "Link": "http://www.comuni-italiani.it/009/051/"
    },
    {
      "Istat": "016172",
      "Comune": "Pontirolo Nuovo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24040,
      "CodFisco": "G867",
      "Abitanti": 5102,
      "Link": "http://www.comuni-italiani.it/016/172/"
    },
    {
      "Istat": "017150",
      "Comune": "Pontoglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25037,
      "CodFisco": "G869",
      "Abitanti": 7009,
      "Link": "http://www.comuni-italiani.it/017/150/"
    },
    {
      "Istat": "045014",
      "Comune": "Pontremoli",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54027,
      "CodFisco": "G870",
      "Abitanti": 7770,
      "Link": "http://www.comuni-italiani.it/045/014/"
    },
    {
      "Istat": "007052",
      "Comune": "Pont-Saint-Martin",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11026,
      "CodFisco": "G854",
      "Abitanti": 4029,
      "Link": "http://www.comuni-italiani.it/007/052/"
    },
    {
      "Istat": "059018",
      "Comune": "Ponza",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04027",
      "CodFisco": "G871",
      "Abitanti": 3360,
      "Link": "http://www.comuni-italiani.it/059/018/"
    },
    {
      "Istat": 109032,
      "Comune": "Ponzano di Fermo",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63845,
      "CodFisco": "G873",
      "Abitanti": 1733,
      "Link": "http://www.comuni-italiani.it/109/032/"
    },
    {
      "Istat": "006135",
      "Comune": "Ponzano Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 15020,
      "CodFisco": "G872",
      "Abitanti": 383,
      "Link": "http://www.comuni-italiani.it/006/135/"
    },
    {
      "Istat": "058080",
      "Comune": "Ponzano Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "00060",
      "CodFisco": "G874",
      "Abitanti": 1183,
      "Link": "http://www.comuni-italiani.it/058/080/"
    },
    {
      "Istat": "026059",
      "Comune": "Ponzano Veneto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31050,
      "CodFisco": "G875",
      "Abitanti": 12321,
      "Link": "http://www.comuni-italiani.it/026/059/"
    },
    {
      "Istat": "006136",
      "Comune": "Ponzone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "G877",
      "Abitanti": 1114,
      "Link": "http://www.comuni-italiani.it/006/136/"
    },
    {
      "Istat": "068033",
      "Comune": "Popoli",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65026,
      "CodFisco": "G878",
      "Abitanti": 5525,
      "Link": "http://www.comuni-italiani.it/068/033/"
    },
    {
      "Istat": "051031",
      "Comune": "Poppi",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52014,
      "CodFisco": "G879",
      "Abitanti": 6396,
      "Link": "http://www.comuni-italiani.it/051/031/"
    },
    {
      "Istat": "055028",
      "Comune": "Porano",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0763",
      "CAP": "05010",
      "CodFisco": "G881",
      "Abitanti": 1971,
      "Link": "http://www.comuni-italiani.it/055/028/"
    },
    {
      "Istat": "046026",
      "Comune": "Porcari",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55016,
      "CodFisco": "G882",
      "Abitanti": 8735,
      "Link": "http://www.comuni-italiani.it/046/026/"
    },
    {
      "Istat": "093032",
      "Comune": "Porcia",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33080,
      "CodFisco": "G886",
      "Abitanti": 15443,
      "Link": "http://www.comuni-italiani.it/093/032/"
    },
    {
      "Istat": "093033",
      "Comune": "Pordenone",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33170,
      "CodFisco": "G888",
      "Abitanti": 51723,
      "Link": "http://www.comuni-italiani.it/093/033/"
    },
    {
      "Istat": "013189",
      "Comune": "Porlezza",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22018,
      "CodFisco": "G889",
      "Abitanti": 4648,
      "Link": "http://www.comuni-italiani.it/013/189/"
    },
    {
      "Istat": "008046",
      "Comune": "Pornassio",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18024,
      "CodFisco": "G890",
      "Abitanti": 617,
      "Link": "http://www.comuni-italiani.it/008/046/"
    },
    {
      "Istat": "030077",
      "Comune": "Porpetto",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "G891",
      "Abitanti": 2673,
      "Link": "http://www.comuni-italiani.it/030/077/"
    },
    {
      "Istat": "037049",
      "Comune": "Porretta Terme",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40046,
      "CodFisco": "A558",
      "Abitanti": 4778,
      "Link": "http://www.comuni-italiani.it/037/049/"
    },
    {
      "Istat": "005087",
      "Comune": "Portacomaro",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14037,
      "CodFisco": "G894",
      "Abitanti": 2003,
      "Link": "http://www.comuni-italiani.it/005/087/"
    },
    {
      "Istat": "018118",
      "Comune": "Portalbera",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "G895",
      "Abitanti": 1577,
      "Link": "http://www.comuni-italiani.it/018/118/"
    },
    {
      "Istat": "001200",
      "Comune": "Porte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G900",
      "Abitanti": 1124,
      "Link": "http://www.comuni-italiani.it/001/200/"
    },
    {
      "Istat": "063059",
      "Comune": "Portici",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80055,
      "CodFisco": "G902",
      "Abitanti": 53981,
      "Link": "http://www.comuni-italiani.it/063/059/"
    },
    {
      "Istat": "061062",
      "Comune": "Portico di Caserta",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "G903",
      "Abitanti": 7737,
      "Link": "http://www.comuni-italiani.it/061/062/"
    },
    {
      "Istat": "040031",
      "Comune": "Portico e San Benedetto",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47010,
      "CodFisco": "G904",
      "Abitanti": 801,
      "Link": "http://www.comuni-italiani.it/040/031/"
    },
    {
      "Istat": "080062",
      "Comune": "Portigliola",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "G905",
      "Abitanti": 1261,
      "Link": "http://www.comuni-italiani.it/080/062/"
    },
    {
      "Istat": "049013",
      "Comune": "Porto Azzurro",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57036,
      "CodFisco": "E680",
      "Abitanti": 3578,
      "Link": "http://www.comuni-italiani.it/049/013/"
    },
    {
      "Istat": "012113",
      "Comune": "Porto Ceresio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "G906",
      "Abitanti": 3075,
      "Link": "http://www.comuni-italiani.it/012/113/"
    },
    {
      "Istat": "075097",
      "Comune": "Porto Cesareo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73010,
      "CodFisco": "M263",
      "Abitanti": 5675,
      "Link": "http://www.comuni-italiani.it/075/097/"
    },
    {
      "Istat": "084028",
      "Comune": "Porto Empedocle",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92014,
      "CodFisco": "F299",
      "Abitanti": 17261,
      "Link": "http://www.comuni-italiani.it/084/028/"
    },
    {
      "Istat": "020045",
      "Comune": "Porto Mantovano",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46047,
      "CodFisco": "G917",
      "Abitanti": 16123,
      "Link": "http://www.comuni-italiani.it/020/045/"
    },
    {
      "Istat": "043042",
      "Comune": "Porto Recanati",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 62017,
      "CodFisco": "G919",
      "Abitanti": 12264,
      "Link": "http://www.comuni-italiani.it/043/042/"
    },
    {
      "Istat": 109033,
      "Comune": "Porto San Giorgio",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63822,
      "CodFisco": "G920",
      "Abitanti": 16384,
      "Link": "http://www.comuni-italiani.it/109/033/"
    },
    {
      "Istat": 109034,
      "Comune": "Porto Sant'Elpidio",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63821,
      "CodFisco": "G921",
      "Abitanti": 25684,
      "Link": "http://www.comuni-italiani.it/109/034/"
    },
    {
      "Istat": "029039",
      "Comune": "Porto Tolle",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45018,
      "CodFisco": "G923",
      "Abitanti": 10131,
      "Link": "http://www.comuni-italiani.it/029/039/"
    },
    {
      "Istat": "090058",
      "Comune": "Porto Torres",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07046",
      "CodFisco": "G924",
      "Abitanti": 22567,
      "Link": "http://www.comuni-italiani.it/090/058/"
    },
    {
      "Istat": "012114",
      "Comune": "Porto Valtravaglia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "G907",
      "Abitanti": 2440,
      "Link": "http://www.comuni-italiani.it/012/114/"
    },
    {
      "Istat": "029052",
      "Comune": "Porto Viro",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45014,
      "CodFisco": "G926",
      "Abitanti": 14761,
      "Link": "http://www.comuni-italiani.it/029/052/"
    },
    {
      "Istat": "026060",
      "Comune": "PortobuffolË",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "G909",
      "Abitanti": 804,
      "Link": "http://www.comuni-italiani.it/026/060/"
    },
    {
      "Istat": "070055",
      "Comune": "Portocannone",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86045,
      "CodFisco": "G910",
      "Abitanti": 2574,
      "Link": "http://www.comuni-italiani.it/070/055/"
    },
    {
      "Istat": "049014",
      "Comune": "Portoferraio",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57037,
      "CodFisco": "G912",
      "Abitanti": 12253,
      "Link": "http://www.comuni-italiani.it/049/014/"
    },
    {
      "Istat": "010044",
      "Comune": "Portofino",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16034,
      "CodFisco": "G913",
      "Abitanti": 479,
      "Link": "http://www.comuni-italiani.it/010/044/"
    },
    {
      "Istat": "027029",
      "Comune": "Portogruaro",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30026,
      "CodFisco": "G914",
      "Abitanti": 25440,
      "Link": "http://www.comuni-italiani.it/027/029/"
    },
    {
      "Istat": "038019",
      "Comune": "Portomaggiore",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44015,
      "CodFisco": "G916",
      "Abitanti": 12445,
      "Link": "http://www.comuni-italiani.it/038/019/"
    },
    {
      "Istat": "089020",
      "Comune": "Portopalo di Capo Passero",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "M257",
      "Abitanti": 3818,
      "Link": "http://www.comuni-italiani.it/089/020/"
    },
    {
      "Istat": 107016,
      "Comune": "Portoscuso",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "G922",
      "Abitanti": 5268,
      "Link": "http://www.comuni-italiani.it/107/016/"
    },
    {
      "Istat": "011022",
      "Comune": "Portovenere",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19025,
      "CodFisco": "G925",
      "Abitanti": 3906,
      "Link": "http://www.comuni-italiani.it/011/022/"
    },
    {
      "Istat": "096048",
      "Comune": "Portula",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13833,
      "CodFisco": "G927",
      "Abitanti": 1398,
      "Link": "http://www.comuni-italiani.it/096/048/"
    },
    {
      "Istat": "091073",
      "Comune": "Posada",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "G929",
      "Abitanti": 2915,
      "Link": "http://www.comuni-italiani.it/091/073/"
    },
    {
      "Istat": "024080",
      "Comune": "Posina",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "G931",
      "Abitanti": 594,
      "Link": "http://www.comuni-italiani.it/024/080/"
    },
    {
      "Istat": "065100",
      "Comune": "Positano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84017,
      "CodFisco": "G932",
      "Abitanti": 3983,
      "Link": "http://www.comuni-italiani.it/065/100/"
    },
    {
      "Istat": "026061",
      "Comune": "Possagno",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31054,
      "CodFisco": "G933",
      "Abitanti": 2234,
      "Link": "http://www.comuni-italiani.it/026/061/"
    },
    {
      "Istat": "057057",
      "Comune": "Posta",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02019",
      "CodFisco": "G934",
      "Abitanti": 724,
      "Link": "http://www.comuni-italiani.it/057/057/"
    },
    {
      "Istat": "060057",
      "Comune": "Posta Fibreno",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "G935",
      "Abitanti": 1216,
      "Link": "http://www.comuni-italiani.it/060/057/"
    },
    {
      "Istat": "021066",
      "Comune": "Postal",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39014,
      "CodFisco": "G936",
      "Abitanti": 1742,
      "Link": "http://www.comuni-italiani.it/021/066/"
    },
    {
      "Istat": "014053",
      "Comune": "Postalesio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "G937",
      "Abitanti": 662,
      "Link": "http://www.comuni-italiani.it/014/053/"
    },
    {
      "Istat": "065101",
      "Comune": "Postiglione",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84026,
      "CodFisco": "G939",
      "Abitanti": 2289,
      "Link": "http://www.comuni-italiani.it/065/101/"
    },
    {
      "Istat": "002102",
      "Comune": "Postua",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13010,
      "CodFisco": "G940",
      "Abitanti": 590,
      "Link": "http://www.comuni-italiani.it/002/102/"
    },
    {
      "Istat": "076063",
      "Comune": "Potenza",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85100,
      "CodFisco": "G942",
      "Abitanti": 68297,
      "Link": "http://www.comuni-italiani.it/076/063/"
    },
    {
      "Istat": "043043",
      "Comune": "Potenza Picena",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62018,
      "CodFisco": "F632",
      "Abitanti": 16081,
      "Link": "http://www.comuni-italiani.it/043/043/"
    },
    {
      "Istat": "024081",
      "Comune": "Pove del Grappa",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36020,
      "CodFisco": "G943",
      "Abitanti": 3144,
      "Link": "http://www.comuni-italiani.it/024/081/"
    },
    {
      "Istat": "026062",
      "Comune": "Povegliano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31050,
      "CodFisco": "G944",
      "Abitanti": 5091,
      "Link": "http://www.comuni-italiani.it/026/062/"
    },
    {
      "Istat": "023060",
      "Comune": "Povegliano Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37064,
      "CodFisco": "G945",
      "Abitanti": 7091,
      "Link": "http://www.comuni-italiani.it/023/060/"
    },
    {
      "Istat": "035029",
      "Comune": "Poviglio",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42028,
      "CodFisco": "G947",
      "Abitanti": 7320,
      "Link": "http://www.comuni-italiani.it/035/029/"
    },
    {
      "Istat": "030078",
      "Comune": "Povoletto",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "G949",
      "Abitanti": 5588,
      "Link": "http://www.comuni-italiani.it/030/078/"
    },
    {
      "Istat": "022145",
      "Comune": "Pozza di Fassa",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38036,
      "CodFisco": "G950",
      "Abitanti": 2110,
      "Link": "http://www.comuni-italiani.it/022/145/"
    },
    {
      "Istat": "057058",
      "Comune": "Pozzaglia Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02030",
      "CodFisco": "G951",
      "Abitanti": 368,
      "Link": "http://www.comuni-italiani.it/057/058/"
    },
    {
      "Istat": "019077",
      "Comune": "Pozzaglio ed Uniti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26010,
      "CodFisco": "B914",
      "Abitanti": 1451,
      "Link": "http://www.comuni-italiani.it/019/077/"
    },
    {
      "Istat": "088008",
      "Comune": "Pozzallo",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97016,
      "CodFisco": "G953",
      "Abitanti": 19234,
      "Link": "http://www.comuni-italiani.it/088/008/"
    },
    {
      "Istat": "094038",
      "Comune": "Pozzilli",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86077,
      "CodFisco": "G954",
      "Abitanti": 2350,
      "Link": "http://www.comuni-italiani.it/094/038/"
    },
    {
      "Istat": "015177",
      "Comune": "Pozzo d'Adda",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "G955",
      "Abitanti": 5523,
      "Link": "http://www.comuni-italiani.it/015/177/"
    },
    {
      "Istat": "006137",
      "Comune": "Pozzol Groppo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "G960",
      "Abitanti": 378,
      "Link": "http://www.comuni-italiani.it/006/137/"
    },
    {
      "Istat": "017151",
      "Comune": "Pozzolengo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "G959",
      "Abitanti": 3436,
      "Link": "http://www.comuni-italiani.it/017/151/"
    },
    {
      "Istat": "024082",
      "Comune": "Pozzoleone",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "G957",
      "Abitanti": 2825,
      "Link": "http://www.comuni-italiani.it/024/082/"
    },
    {
      "Istat": "006138",
      "Comune": "Pozzolo Formigaro",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15068,
      "CodFisco": "G961",
      "Abitanti": 4886,
      "Link": "http://www.comuni-italiani.it/006/138/"
    },
    {
      "Istat": "090059",
      "Comune": "Pozzomaggiore",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07018",
      "CodFisco": "G962",
      "Abitanti": 2755,
      "Link": "http://www.comuni-italiani.it/090/059/"
    },
    {
      "Istat": "028070",
      "Comune": "Pozzonovo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35020,
      "CodFisco": "G963",
      "Abitanti": 3694,
      "Link": "http://www.comuni-italiani.it/028/070/"
    },
    {
      "Istat": "063060",
      "Comune": "Pozzuoli",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80078,
      "CodFisco": "G964",
      "Abitanti": 83459,
      "Link": "http://www.comuni-italiani.it/063/060/"
    },
    {
      "Istat": "030079",
      "Comune": "Pozzuolo del Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "G966",
      "Abitanti": 6909,
      "Link": "http://www.comuni-italiani.it/030/079/"
    },
    {
      "Istat": "015178",
      "Comune": "Pozzuolo Martesana",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "G965",
      "Abitanti": 7991,
      "Link": "http://www.comuni-italiani.it/015/178/"
    },
    {
      "Istat": "016173",
      "Comune": "Pradalunga",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "G968",
      "Abitanti": 4593,
      "Link": "http://www.comuni-italiani.it/016/173/"
    },
    {
      "Istat": "030080",
      "Comune": "Pradamano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "G969",
      "Abitanti": 3566,
      "Link": "http://www.comuni-italiani.it/030/080/"
    },
    {
      "Istat": "004173",
      "Comune": "Pradleves",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12027,
      "CodFisco": "G970",
      "Abitanti": 276,
      "Link": "http://www.comuni-italiani.it/004/173/"
    },
    {
      "Istat": "001201",
      "Comune": "Pragelato",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10060,
      "CodFisco": "G973",
      "Abitanti": 794,
      "Link": "http://www.comuni-italiani.it/001/201/"
    },
    {
      "Istat": "078101",
      "Comune": "Praia a Mare",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87028,
      "CodFisco": "G975",
      "Abitanti": 6802,
      "Link": "http://www.comuni-italiani.it/078/101/"
    },
    {
      "Istat": "065102",
      "Comune": "Praiano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "G976",
      "Abitanti": 2081,
      "Link": "http://www.comuni-italiani.it/065/102/"
    },
    {
      "Istat": "017152",
      "Comune": "Pralboino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "G977",
      "Abitanti": 2981,
      "Link": "http://www.comuni-italiani.it/017/152/"
    },
    {
      "Istat": "001202",
      "Comune": "Prali",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G978",
      "Abitanti": 266,
      "Link": "http://www.comuni-italiani.it/001/202/"
    },
    {
      "Istat": "001203",
      "Comune": "Pralormo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "G979",
      "Abitanti": 1939,
      "Link": "http://www.comuni-italiani.it/001/203/"
    },
    {
      "Istat": "096049",
      "Comune": "Pralungo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13899,
      "CodFisco": "G980",
      "Abitanti": 2684,
      "Link": "http://www.comuni-italiani.it/096/049/"
    },
    {
      "Istat": "027030",
      "Comune": "Pramaggiore",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "G981",
      "Abitanti": 4714,
      "Link": "http://www.comuni-italiani.it/027/030/"
    },
    {
      "Istat": "001204",
      "Comune": "Pramollo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10065,
      "CodFisco": "G982",
      "Abitanti": 248,
      "Link": "http://www.comuni-italiani.it/001/204/"
    },
    {
      "Istat": "002104",
      "Comune": "Prarolo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13012,
      "CodFisco": "G985",
      "Abitanti": 666,
      "Link": "http://www.comuni-italiani.it/002/104/"
    },
    {
      "Istat": "001205",
      "Comune": "Prarostino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "G986",
      "Abitanti": 1302,
      "Link": "http://www.comuni-italiani.it/001/205/"
    },
    {
      "Istat": "006139",
      "Comune": "Prasco",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "G987",
      "Abitanti": 565,
      "Link": "http://www.comuni-italiani.it/006/139/"
    },
    {
      "Istat": "001206",
      "Comune": "Prascorsano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "G988",
      "Abitanti": 798,
      "Link": "http://www.comuni-italiani.it/001/206/"
    },
    {
      "Istat": "022146",
      "Comune": "Praso",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "G989",
      "Abitanti": 342,
      "Link": "http://www.comuni-italiani.it/022/146/"
    },
    {
      "Istat": "014054",
      "Comune": "Prata Camportaccio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "G993",
      "Abitanti": 2903,
      "Link": "http://www.comuni-italiani.it/014/054/"
    },
    {
      "Istat": "066074",
      "Comune": "Prata d'Ansidonia",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "G992",
      "Abitanti": 533,
      "Link": "http://www.comuni-italiani.it/066/074/"
    },
    {
      "Istat": "093034",
      "Comune": "Prata di Pordenone",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33080,
      "CodFisco": "G994",
      "Abitanti": 8569,
      "Link": "http://www.comuni-italiani.it/093/034/"
    },
    {
      "Istat": "064074",
      "Comune": "Prata di Principato Ultra",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "G990",
      "Abitanti": 2975,
      "Link": "http://www.comuni-italiani.it/064/074/"
    },
    {
      "Istat": "061063",
      "Comune": "Prata Sannita",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "G991",
      "Abitanti": 1605,
      "Link": "http://www.comuni-italiani.it/061/063/"
    },
    {
      "Istat": "061064",
      "Comune": "Pratella",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "G995",
      "Abitanti": 1635,
      "Link": "http://www.comuni-italiani.it/061/064/"
    },
    {
      "Istat": "001207",
      "Comune": "Pratiglione",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "G997",
      "Abitanti": 564,
      "Link": "http://www.comuni-italiani.it/001/207/"
    },
    {
      "Istat": 100005,
      "Comune": "Prato",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "0574",
      "CAP": 59100,
      "CodFisco": "G999",
      "Abitanti": 188011,
      "Link": "http://www.comuni-italiani.it/100/005/"
    },
    {
      "Istat": "021067",
      "Comune": "Prato allo Stelvio",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39026,
      "CodFisco": "H004",
      "Abitanti": 3381,
      "Link": "http://www.comuni-italiani.it/021/067/"
    },
    {
      "Istat": "030081",
      "Comune": "Prato Carnico",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "H002",
      "Abitanti": 958,
      "Link": "http://www.comuni-italiani.it/030/081/"
    },
    {
      "Istat": "003122",
      "Comune": "Prato Sesia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 28077,
      "CodFisco": "H001",
      "Abitanti": 2023,
      "Link": "http://www.comuni-italiani.it/003/122/"
    },
    {
      "Istat": "066075",
      "Comune": "Pratola Peligna",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67035,
      "CodFisco": "H007",
      "Abitanti": 7890,
      "Link": "http://www.comuni-italiani.it/066/075/"
    },
    {
      "Istat": "064075",
      "Comune": "Pratola Serra",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83039,
      "CodFisco": "H006",
      "Abitanti": 3700,
      "Link": "http://www.comuni-italiani.it/064/075/"
    },
    {
      "Istat": "051032",
      "Comune": "Pratovecchio",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52015,
      "CodFisco": "H008",
      "Abitanti": 3150,
      "Link": "http://www.comuni-italiani.it/051/032/"
    },
    {
      "Istat": "093035",
      "Comune": "Pravisdomini",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33076,
      "CodFisco": "H010",
      "Abitanti": 3532,
      "Link": "http://www.comuni-italiani.it/093/035/"
    },
    {
      "Istat": "096050",
      "Comune": "Pray",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13867,
      "CodFisco": "G974",
      "Abitanti": 2360,
      "Link": "http://www.comuni-italiani.it/096/050/"
    },
    {
      "Istat": "004174",
      "Comune": "Prazzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12028,
      "CodFisco": "H011",
      "Abitanti": 185,
      "Link": "http://www.comuni-italiani.it/004/174/"
    },
    {
      "Istat": "030082",
      "Comune": "Precenicco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "H014",
      "Abitanti": 1498,
      "Link": "http://www.comuni-italiani.it/030/082/"
    },
    {
      "Istat": "054043",
      "Comune": "Preci",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06047",
      "CodFisco": "H015",
      "Abitanti": 778,
      "Link": "http://www.comuni-italiani.it/054/043/"
    },
    {
      "Istat": "040032",
      "Comune": "Predappio",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47016,
      "CodFisco": "H017",
      "Abitanti": 6545,
      "Link": "http://www.comuni-italiani.it/040/032/"
    },
    {
      "Istat": "022147",
      "Comune": "Predazzo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38037,
      "CodFisco": "H018",
      "Abitanti": 4537,
      "Link": "http://www.comuni-italiani.it/022/147/"
    },
    {
      "Istat": "021068",
      "Comune": "Predoi",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "H019",
      "Abitanti": 597,
      "Link": "http://www.comuni-italiani.it/021/068/"
    },
    {
      "Istat": "016174",
      "Comune": "Predore",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "H020",
      "Abitanti": 1895,
      "Link": "http://www.comuni-italiani.it/016/174/"
    },
    {
      "Istat": "006140",
      "Comune": "Predosa",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15077,
      "CodFisco": "H021",
      "Abitanti": 2128,
      "Link": "http://www.comuni-italiani.it/006/140/"
    },
    {
      "Istat": "026063",
      "Comune": "Preganziol",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31022,
      "CodFisco": "H022",
      "Abitanti": 17025,
      "Link": "http://www.comuni-italiani.it/026/063/"
    },
    {
      "Istat": "015179",
      "Comune": "Pregnana Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "H026",
      "Abitanti": 6919,
      "Link": "http://www.comuni-italiani.it/015/179/"
    },
    {
      "Istat": "008047",
      "Comune": "Prel‡",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "H027",
      "Abitanti": 514,
      "Link": "http://www.comuni-italiani.it/008/047/"
    },
    {
      "Istat": "097069",
      "Comune": "Premana",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23834,
      "CodFisco": "H028",
      "Abitanti": 2312,
      "Link": "http://www.comuni-italiani.it/097/069/"
    },
    {
      "Istat": "030083",
      "Comune": "Premariacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "H029",
      "Abitanti": 4222,
      "Link": "http://www.comuni-italiani.it/030/083/"
    },
    {
      "Istat": 103055,
      "Comune": "Premeno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28818,
      "CodFisco": "H030",
      "Abitanti": 773,
      "Link": "http://www.comuni-italiani.it/103/055/"
    },
    {
      "Istat": 103056,
      "Comune": "Premia",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28866,
      "CodFisco": "H033",
      "Abitanti": 582,
      "Link": "http://www.comuni-italiani.it/103/056/"
    },
    {
      "Istat": "040033",
      "Comune": "Premilcuore",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47010,
      "CodFisco": "H034",
      "Abitanti": 824,
      "Link": "http://www.comuni-italiani.it/040/033/"
    },
    {
      "Istat": "016175",
      "Comune": "Premolo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "H036",
      "Abitanti": 1166,
      "Link": "http://www.comuni-italiani.it/016/175/"
    },
    {
      "Istat": 103057,
      "Comune": "Premosello-Chiovenda",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28803,
      "CodFisco": "H037",
      "Abitanti": 2052,
      "Link": "http://www.comuni-italiani.it/103/057/"
    },
    {
      "Istat": "030084",
      "Comune": "Preone",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "H038",
      "Abitanti": 279,
      "Link": "http://www.comuni-italiani.it/030/084/"
    },
    {
      "Istat": "022148",
      "Comune": "Preore",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38070,
      "CodFisco": "H039",
      "Abitanti": 400,
      "Link": "http://www.comuni-italiani.it/022/148/"
    },
    {
      "Istat": "030085",
      "Comune": "Prepotto",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "H040",
      "Abitanti": 829,
      "Link": "http://www.comuni-italiani.it/030/085/"
    },
    {
      "Istat": "007053",
      "Comune": "PrË-Saint-Didier",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H042",
      "Abitanti": 992,
      "Link": "http://www.comuni-italiani.it/007/053/"
    },
    {
      "Istat": "017153",
      "Comune": "Preseglie",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "H043",
      "Abitanti": 1595,
      "Link": "http://www.comuni-italiani.it/017/153/"
    },
    {
      "Istat": "061065",
      "Comune": "Presenzano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "H045",
      "Abitanti": 1773,
      "Link": "http://www.comuni-italiani.it/061/065/"
    },
    {
      "Istat": "016176",
      "Comune": "Presezzo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "H046",
      "Abitanti": 4896,
      "Link": "http://www.comuni-italiani.it/016/176/"
    },
    {
      "Istat": "075062",
      "Comune": "Presicce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73054,
      "CodFisco": "H047",
      "Abitanti": 5621,
      "Link": "http://www.comuni-italiani.it/075/062/"
    },
    {
      "Istat": "023061",
      "Comune": "Pressana",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "H048",
      "Abitanti": 2576,
      "Link": "http://www.comuni-italiani.it/023/061/"
    },
    {
      "Istat": "017154",
      "Comune": "Prestine",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "H050",
      "Abitanti": 402,
      "Link": "http://www.comuni-italiani.it/017/154/"
    },
    {
      "Istat": "069069",
      "Comune": "Pretoro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "H052",
      "Abitanti": 1022,
      "Link": "http://www.comuni-italiani.it/069/069/"
    },
    {
      "Istat": "017155",
      "Comune": "Prevalle",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "H055",
      "Abitanti": 6995,
      "Link": "http://www.comuni-italiani.it/017/155/"
    },
    {
      "Istat": "066076",
      "Comune": "Prezza",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "H056",
      "Abitanti": 1053,
      "Link": "http://www.comuni-italiani.it/066/076/"
    },
    {
      "Istat": "022149",
      "Comune": "Prezzo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38085,
      "CodFisco": "H057",
      "Abitanti": 210,
      "Link": "http://www.comuni-italiani.it/022/149/"
    },
    {
      "Istat": "004175",
      "Comune": "Priero",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "H059",
      "Abitanti": 511,
      "Link": "http://www.comuni-italiani.it/004/175/"
    },
    {
      "Istat": "065103",
      "Comune": "Prignano Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "H062",
      "Abitanti": 975,
      "Link": "http://www.comuni-italiani.it/065/103/"
    },
    {
      "Istat": "036033",
      "Comune": "Prignano sulla Secchia",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41048,
      "CodFisco": "H061",
      "Abitanti": 3813,
      "Link": "http://www.comuni-italiani.it/036/033/"
    },
    {
      "Istat": "097070",
      "Comune": "Primaluna",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23819,
      "CodFisco": "H063",
      "Abitanti": 2170,
      "Link": "http://www.comuni-italiani.it/097/070/"
    },
    {
      "Istat": "004176",
      "Comune": "Priocca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "H068",
      "Abitanti": 1984,
      "Link": "http://www.comuni-italiani.it/004/176/"
    },
    {
      "Istat": "004177",
      "Comune": "Priola",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "H069",
      "Abitanti": 741,
      "Link": "http://www.comuni-italiani.it/004/177/"
    },
    {
      "Istat": "089021",
      "Comune": "Priolo Gargallo",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "M279",
      "Abitanti": 12148,
      "Link": "http://www.comuni-italiani.it/089/021/"
    },
    {
      "Istat": "059019",
      "Comune": "Priverno",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04015",
      "CodFisco": "G698",
      "Abitanti": 14369,
      "Link": "http://www.comuni-italiani.it/059/019/"
    },
    {
      "Istat": "082060",
      "Comune": "Prizzi",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90038,
      "CodFisco": "H070",
      "Abitanti": 5152,
      "Link": "http://www.comuni-italiani.it/082/060/"
    },
    {
      "Istat": "056044",
      "Comune": "Proceno",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0763",
      "CAP": "01020",
      "CodFisco": "H071",
      "Abitanti": 623,
      "Link": "http://www.comuni-italiani.it/056/044/"
    },
    {
      "Istat": "063061",
      "Comune": "Procida",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80079,
      "CodFisco": "H072",
      "Abitanti": 10596,
      "Link": "http://www.comuni-italiani.it/063/061/"
    },
    {
      "Istat": "010045",
      "Comune": "Propata",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16027,
      "CodFisco": "H073",
      "Abitanti": 165,
      "Link": "http://www.comuni-italiani.it/010/045/"
    },
    {
      "Istat": "013192",
      "Comune": "Proserpio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "H074",
      "Abitanti": 912,
      "Link": "http://www.comuni-italiani.it/013/192/"
    },
    {
      "Istat": "059020",
      "Comune": "Prossedi",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "H076",
      "Abitanti": 1238,
      "Link": "http://www.comuni-italiani.it/059/020/"
    },
    {
      "Istat": "017156",
      "Comune": "Provaglio d'Iseo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "H078",
      "Abitanti": 7137,
      "Link": "http://www.comuni-italiani.it/017/156/"
    },
    {
      "Istat": "017157",
      "Comune": "Provaglio Val Sabbia",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "H077",
      "Abitanti": 969,
      "Link": "http://www.comuni-italiani.it/017/157/"
    },
    {
      "Istat": "021069",
      "Comune": "Proves",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 39040,
      "CodFisco": "H081",
      "Abitanti": 274,
      "Link": "http://www.comuni-italiani.it/021/069/"
    },
    {
      "Istat": "070056",
      "Comune": "Provvidenti",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "H083",
      "Abitanti": 128,
      "Link": "http://www.comuni-italiani.it/070/056/"
    },
    {
      "Istat": "004178",
      "Comune": "Prunetto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12077,
      "CodFisco": "H085",
      "Abitanti": 478,
      "Link": "http://www.comuni-italiani.it/004/178/"
    },
    {
      "Istat": "017158",
      "Comune": "Puegnago sul Garda",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "H086",
      "Abitanti": 3258,
      "Link": "http://www.comuni-italiani.it/017/158/"
    },
    {
      "Istat": "062055",
      "Comune": "Puglianello",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "H087",
      "Abitanti": 1400,
      "Link": "http://www.comuni-italiani.it/062/055/"
    },
    {
      "Istat": "092050",
      "Comune": "Pula",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09010",
      "CodFisco": "H088",
      "Abitanti": 7405,
      "Link": "http://www.comuni-italiani.it/092/050/"
    },
    {
      "Istat": "030086",
      "Comune": "Pulfero",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33046,
      "CodFisco": "H089",
      "Abitanti": 1052,
      "Link": "http://www.comuni-italiani.it/030/086/"
    },
    {
      "Istat": "073022",
      "Comune": "Pulsano",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74026,
      "CodFisco": "H090",
      "Abitanti": 11002,
      "Link": "http://www.comuni-italiani.it/073/022/"
    },
    {
      "Istat": "016177",
      "Comune": "Pumenengo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "H091",
      "Abitanti": 1694,
      "Link": "http://www.comuni-italiani.it/016/177/"
    },
    {
      "Istat": "025041",
      "Comune": "Puos d'Alpago",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32015,
      "CodFisco": "H092",
      "Abitanti": 2519,
      "Link": "http://www.comuni-italiani.it/025/041/"
    },
    {
      "Istat": "013193",
      "Comune": "Pusiano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "H094",
      "Abitanti": 1327,
      "Link": "http://www.comuni-italiani.it/013/193/"
    },
    {
      "Istat": "090060",
      "Comune": "Putifigari",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "H095",
      "Abitanti": 753,
      "Link": "http://www.comuni-italiani.it/090/060/"
    },
    {
      "Istat": "072036",
      "Comune": "Putignano",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70017,
      "CodFisco": "H096",
      "Abitanti": 27394,
      "Link": "http://www.comuni-italiani.it/072/036/"
    },
    {
      "Istat": "064076",
      "Comune": "Quadrelle",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "H097",
      "Abitanti": 1957,
      "Link": "http://www.comuni-italiani.it/064/076/"
    },
    {
      "Istat": "069070",
      "Comune": "Quadri",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "H098",
      "Abitanti": 881,
      "Link": "http://www.comuni-italiani.it/069/070/"
    },
    {
      "Istat": "001208",
      "Comune": "Quagliuzzo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H100",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/001/208/"
    },
    {
      "Istat": "063062",
      "Comune": "Qualiano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80019,
      "CodFisco": "H101",
      "Abitanti": 25313,
      "Link": "http://www.comuni-italiani.it/063/062/"
    },
    {
      "Istat": "005088",
      "Comune": "Quaranti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "H102",
      "Abitanti": 200,
      "Link": "http://www.comuni-italiani.it/005/088/"
    },
    {
      "Istat": "096051",
      "Comune": "Quaregna",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13854,
      "CodFisco": "H103",
      "Abitanti": 1399,
      "Link": "http://www.comuni-italiani.it/096/051/"
    },
    {
      "Istat": "006141",
      "Comune": "Quargnento",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15044,
      "CodFisco": "H104",
      "Abitanti": 1420,
      "Link": "http://www.comuni-italiani.it/006/141/"
    },
    {
      "Istat": 103058,
      "Comune": "Quarna Sopra",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28898,
      "CodFisco": "H106",
      "Abitanti": 283,
      "Link": "http://www.comuni-italiani.it/103/058/"
    },
    {
      "Istat": 103059,
      "Comune": "Quarna Sotto",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28896,
      "CodFisco": "H107",
      "Abitanti": 430,
      "Link": "http://www.comuni-italiani.it/103/059/"
    },
    {
      "Istat": "002107",
      "Comune": "Quarona",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13017,
      "CodFisco": "H108",
      "Abitanti": 4292,
      "Link": "http://www.comuni-italiani.it/002/107/"
    },
    {
      "Istat": "047017",
      "Comune": "Quarrata",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51039,
      "CodFisco": "H109",
      "Abitanti": 25379,
      "Link": "http://www.comuni-italiani.it/047/017/"
    },
    {
      "Istat": "007054",
      "Comune": "Quart",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "H110",
      "Abitanti": 3823,
      "Link": "http://www.comuni-italiani.it/007/054/"
    },
    {
      "Istat": "063063",
      "Comune": "Quarto",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80010,
      "CodFisco": "H114",
      "Abitanti": 40154,
      "Link": "http://www.comuni-italiani.it/063/063/"
    },
    {
      "Istat": "027031",
      "Comune": "Quarto d'Altino",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 30020,
      "CodFisco": "H117",
      "Abitanti": 8212,
      "Link": "http://www.comuni-italiani.it/027/031/"
    },
    {
      "Istat": "092051",
      "Comune": "Quartu Sant'Elena",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09045",
      "CodFisco": "H118",
      "Abitanti": 71779,
      "Link": "http://www.comuni-italiani.it/092/051/"
    },
    {
      "Istat": "092105",
      "Comune": "Quartucciu",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09044",
      "CodFisco": "H119",
      "Abitanti": 12844,
      "Link": "http://www.comuni-italiani.it/092/105/"
    },
    {
      "Istat": "001209",
      "Comune": "Quassolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H120",
      "Abitanti": 370,
      "Link": "http://www.comuni-italiani.it/001/209/"
    },
    {
      "Istat": "006142",
      "Comune": "Quattordio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15028,
      "CodFisco": "H121",
      "Abitanti": 1682,
      "Link": "http://www.comuni-italiani.it/006/142/"
    },
    {
      "Istat": "035030",
      "Comune": "Quattro Castella",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42020,
      "CodFisco": "H122",
      "Abitanti": 13139,
      "Link": "http://www.comuni-italiani.it/035/030/"
    },
    {
      "Istat": "025042",
      "Comune": "Quero",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "H124",
      "Abitanti": 2554,
      "Link": "http://www.comuni-italiani.it/025/042/"
    },
    {
      "Istat": "009052",
      "Comune": "Quiliano",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17047,
      "CodFisco": "H126",
      "Abitanti": 7424,
      "Link": "http://www.comuni-italiani.it/009/052/"
    },
    {
      "Istat": "001210",
      "Comune": "Quincinetto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H127",
      "Abitanti": 1065,
      "Link": "http://www.comuni-italiani.it/001/210/"
    },
    {
      "Istat": "064077",
      "Comune": "Quindici",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "H128",
      "Abitanti": 2447,
      "Link": "http://www.comuni-italiani.it/064/077/"
    },
    {
      "Istat": "020046",
      "Comune": "Quingentole",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "H129",
      "Abitanti": 1220,
      "Link": "http://www.comuni-italiani.it/020/046/"
    },
    {
      "Istat": "019078",
      "Comune": "Quintano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26017,
      "CodFisco": "H130",
      "Abitanti": 927,
      "Link": "http://www.comuni-italiani.it/019/078/"
    },
    {
      "Istat": "026064",
      "Comune": "Quinto di Treviso",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31055,
      "CodFisco": "H131",
      "Abitanti": 9846,
      "Link": "http://www.comuni-italiani.it/026/064/"
    },
    {
      "Istat": "002108",
      "Comune": "Quinto Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "H132",
      "Abitanti": 417,
      "Link": "http://www.comuni-italiani.it/002/108/"
    },
    {
      "Istat": "024083",
      "Comune": "Quinto Vicentino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "H134",
      "Abitanti": 5763,
      "Link": "http://www.comuni-italiani.it/024/083/"
    },
    {
      "Istat": "017159",
      "Comune": "Quinzano d'Oglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25027,
      "CodFisco": "H140",
      "Abitanti": 6483,
      "Link": "http://www.comuni-italiani.it/017/159/"
    },
    {
      "Istat": "020047",
      "Comune": "Quistello",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46026,
      "CodFisco": "H143",
      "Abitanti": 5856,
      "Link": "http://www.comuni-italiani.it/020/047/"
    },
    {
      "Istat": "096052",
      "Comune": "Quittengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13812,
      "CodFisco": "H145",
      "Abitanti": 220,
      "Link": "http://www.comuni-italiani.it/096/052/"
    },
    {
      "Istat": "022150",
      "Comune": "Rabbi",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "H146",
      "Abitanti": 1409,
      "Link": "http://www.comuni-italiani.it/022/150/"
    },
    {
      "Istat": "075063",
      "Comune": "Racale",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73055,
      "CodFisco": "H147",
      "Abitanti": 10892,
      "Link": "http://www.comuni-italiani.it/075/063/"
    },
    {
      "Istat": "084029",
      "Comune": "Racalmuto",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "H148",
      "Abitanti": 8962,
      "Link": "http://www.comuni-italiani.it/084/029/"
    },
    {
      "Istat": "004179",
      "Comune": "Racconigi",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12035,
      "CodFisco": "H150",
      "Abitanti": 10161,
      "Link": "http://www.comuni-italiani.it/004/179/"
    },
    {
      "Istat": "083069",
      "Comune": "Raccuja",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98067,
      "CodFisco": "H151",
      "Abitanti": 1147,
      "Link": "http://www.comuni-italiani.it/083/069/"
    },
    {
      "Istat": "021070",
      "Comune": "Racines",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "H152",
      "Abitanti": 4369,
      "Link": "http://www.comuni-italiani.it/021/070/"
    },
    {
      "Istat": "052023",
      "Comune": "Radda in Chianti",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53017,
      "CodFisco": "H153",
      "Abitanti": 1690,
      "Link": "http://www.comuni-italiani.it/052/023/"
    },
    {
      "Istat": "087036",
      "Comune": "Raddusa",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95040,
      "CodFisco": "H154",
      "Abitanti": 3285,
      "Link": "http://www.comuni-italiani.it/087/036/"
    },
    {
      "Istat": "052024",
      "Comune": "Radicofani",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53040,
      "CodFisco": "H156",
      "Abitanti": 1165,
      "Link": "http://www.comuni-italiani.it/052/024/"
    },
    {
      "Istat": "052025",
      "Comune": "Radicondoli",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53030,
      "CodFisco": "H157",
      "Abitanti": 954,
      "Link": "http://www.comuni-italiani.it/052/025/"
    },
    {
      "Istat": "084030",
      "Comune": "Raffadali",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92015,
      "CodFisco": "H159",
      "Abitanti": 12924,
      "Link": "http://www.comuni-italiani.it/084/030/"
    },
    {
      "Istat": "087058",
      "Comune": "Ragalna",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "M287",
      "Abitanti": 3649,
      "Link": "http://www.comuni-italiani.it/087/058/"
    },
    {
      "Istat": "030087",
      "Comune": "Ragogna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "H161",
      "Abitanti": 2985,
      "Link": "http://www.comuni-italiani.it/030/087/"
    },
    {
      "Istat": "022151",
      "Comune": "Ragoli",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38070,
      "CodFisco": "H162",
      "Abitanti": 770,
      "Link": "http://www.comuni-italiani.it/022/151/"
    },
    {
      "Istat": "088009",
      "Comune": "Ragusa",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97100,
      "CodFisco": "H163",
      "Abitanti": 73743,
      "Link": "http://www.comuni-italiani.it/088/009/"
    },
    {
      "Istat": "066077",
      "Comune": "Raiano",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67027,
      "CodFisco": "H166",
      "Abitanti": 2908,
      "Link": "http://www.comuni-italiani.it/066/077/"
    },
    {
      "Istat": "087037",
      "Comune": "Ramacca",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95040,
      "CodFisco": "H168",
      "Abitanti": 10859,
      "Link": "http://www.comuni-italiani.it/087/037/"
    },
    {
      "Istat": "035031",
      "Comune": "Ramiseto",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42030,
      "CodFisco": "G654",
      "Abitanti": 1307,
      "Link": "http://www.comuni-italiani.it/035/031/"
    },
    {
      "Istat": "013194",
      "Comune": "Ramponio Verna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "H171",
      "Abitanti": 410,
      "Link": "http://www.comuni-italiani.it/013/194/"
    },
    {
      "Istat": "012115",
      "Comune": "Rancio Valcuvia",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21030,
      "CodFisco": "H173",
      "Abitanti": 940,
      "Link": "http://www.comuni-italiani.it/012/115/"
    },
    {
      "Istat": "012116",
      "Comune": "Ranco",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21020,
      "CodFisco": "H174",
      "Abitanti": 1371,
      "Link": "http://www.comuni-italiani.it/012/116/"
    },
    {
      "Istat": "087038",
      "Comune": "Randazzo",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95036,
      "CodFisco": "H175",
      "Abitanti": 11186,
      "Link": "http://www.comuni-italiani.it/087/038/"
    },
    {
      "Istat": "016178",
      "Comune": "Ranica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "H176",
      "Abitanti": 6018,
      "Link": "http://www.comuni-italiani.it/016/178/"
    },
    {
      "Istat": "016179",
      "Comune": "Ranzanico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "H177",
      "Abitanti": 1310,
      "Link": "http://www.comuni-italiani.it/016/179/"
    },
    {
      "Istat": "008048",
      "Comune": "Ranzo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "H180",
      "Abitanti": 576,
      "Link": "http://www.comuni-italiani.it/008/048/"
    },
    {
      "Istat": 109035,
      "Comune": "Rapagnano",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63831,
      "CodFisco": "H182",
      "Abitanti": 2056,
      "Link": "http://www.comuni-italiani.it/109/035/"
    },
    {
      "Istat": "010046",
      "Comune": "Rapallo",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16035,
      "CodFisco": "H183",
      "Abitanti": 30785,
      "Link": "http://www.comuni-italiani.it/010/046/"
    },
    {
      "Istat": "069071",
      "Comune": "Rapino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "H184",
      "Abitanti": 1403,
      "Link": "http://www.comuni-italiani.it/069/071/"
    },
    {
      "Istat": "052026",
      "Comune": "Rapolano Terme",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53040,
      "CodFisco": "H185",
      "Abitanti": 5308,
      "Link": "http://www.comuni-italiani.it/052/026/"
    },
    {
      "Istat": "076064",
      "Comune": "Rapolla",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85027,
      "CodFisco": "H186",
      "Abitanti": 4506,
      "Link": "http://www.comuni-italiani.it/076/064/"
    },
    {
      "Istat": "076065",
      "Comune": "Rapone",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85020,
      "CodFisco": "H187",
      "Abitanti": 1029,
      "Link": "http://www.comuni-italiani.it/076/065/"
    },
    {
      "Istat": "002110",
      "Comune": "Rassa",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "H188",
      "Abitanti": 73,
      "Link": "http://www.comuni-italiani.it/002/110/"
    },
    {
      "Istat": "021071",
      "Comune": "Rasun Anterselva",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "H189",
      "Abitanti": 2878,
      "Link": "http://www.comuni-italiani.it/021/071/"
    },
    {
      "Istat": "014055",
      "Comune": "Rasura",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "H192",
      "Abitanti": 297,
      "Link": "http://www.comuni-italiani.it/014/055/"
    },
    {
      "Istat": "084031",
      "Comune": "Ravanusa",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92029,
      "CodFisco": "H194",
      "Abitanti": 12751,
      "Link": "http://www.comuni-italiani.it/084/031/"
    },
    {
      "Istat": "036034",
      "Comune": "Ravarino",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41017,
      "CodFisco": "H195",
      "Abitanti": 6318,
      "Link": "http://www.comuni-italiani.it/036/034/"
    },
    {
      "Istat": "030088",
      "Comune": "Ravascletto",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "H196",
      "Abitanti": 569,
      "Link": "http://www.comuni-italiani.it/030/088/"
    },
    {
      "Istat": "065104",
      "Comune": "Ravello",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "H198",
      "Abitanti": 2508,
      "Link": "http://www.comuni-italiani.it/065/104/"
    },
    {
      "Istat": "039014",
      "Comune": "Ravenna",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0544",
      "CAP": "4812x",
      "CodFisco": "H199",
      "Abitanti": 158739,
      "Link": "http://www.comuni-italiani.it/039/014/"
    },
    {
      "Istat": "030089",
      "Comune": "Raveo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33029,
      "CodFisco": "H200",
      "Abitanti": 506,
      "Link": "http://www.comuni-italiani.it/030/089/"
    },
    {
      "Istat": "061066",
      "Comune": "Raviscanina",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81017,
      "CodFisco": "H202",
      "Abitanti": 1383,
      "Link": "http://www.comuni-italiani.it/061/066/"
    },
    {
      "Istat": 103060,
      "Comune": "Re",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28856,
      "CodFisco": "H203",
      "Abitanti": 765,
      "Link": "http://www.comuni-italiani.it/103/060/"
    },
    {
      "Istat": "018119",
      "Comune": "Rea",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "H204",
      "Abitanti": 429,
      "Link": "http://www.comuni-italiani.it/018/119/"
    },
    {
      "Istat": "084032",
      "Comune": "Realmonte",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "H205",
      "Abitanti": 4583,
      "Link": "http://www.comuni-italiani.it/084/032/"
    },
    {
      "Istat": "030090",
      "Comune": "Reana del Rojale",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "H206",
      "Abitanti": 5044,
      "Link": "http://www.comuni-italiani.it/030/090/"
    },
    {
      "Istat": "001211",
      "Comune": "Reano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "H207",
      "Abitanti": 1688,
      "Link": "http://www.comuni-italiani.it/001/211/"
    },
    {
      "Istat": "061067",
      "Comune": "Recale",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "H210",
      "Abitanti": 7557,
      "Link": "http://www.comuni-italiani.it/061/067/"
    },
    {
      "Istat": "043044",
      "Comune": "Recanati",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 62019,
      "CodFisco": "H211",
      "Abitanti": 21830,
      "Link": "http://www.comuni-italiani.it/043/044/"
    },
    {
      "Istat": "010047",
      "Comune": "Recco",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16036,
      "CodFisco": "H212",
      "Abitanti": 10178,
      "Link": "http://www.comuni-italiani.it/010/047/"
    },
    {
      "Istat": "003129",
      "Comune": "Recetto",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "H213",
      "Abitanti": 948,
      "Link": "http://www.comuni-italiani.it/003/129/"
    },
    {
      "Istat": "024084",
      "Comune": "Recoaro Terme",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36076,
      "CodFisco": "H214",
      "Abitanti": 6835,
      "Link": "http://www.comuni-italiani.it/024/084/"
    },
    {
      "Istat": "018120",
      "Comune": "Redavalle",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27050,
      "CodFisco": "H216",
      "Abitanti": 1078,
      "Link": "http://www.comuni-italiani.it/018/120/"
    },
    {
      "Istat": "020048",
      "Comune": "Redondesco",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "H218",
      "Abitanti": 1329,
      "Link": "http://www.comuni-italiani.it/020/048/"
    },
    {
      "Istat": "005089",
      "Comune": "Refrancore",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "H219",
      "Abitanti": 1678,
      "Link": "http://www.comuni-italiani.it/005/089/"
    },
    {
      "Istat": "026065",
      "Comune": "Refrontolo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "H220",
      "Abitanti": 1863,
      "Link": "http://www.comuni-italiani.it/026/065/"
    },
    {
      "Istat": "086016",
      "Comune": "Regalbuto",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94017,
      "CodFisco": "H221",
      "Abitanti": 7512,
      "Link": "http://www.comuni-italiani.it/086/016/"
    },
    {
      "Istat": "048035",
      "Comune": "Reggello",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50066,
      "CodFisco": "H222",
      "Abitanti": 16296,
      "Link": "http://www.comuni-italiani.it/048/035/"
    },
    {
      "Istat": "080063",
      "Comune": "Reggio Calabria",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": "891xx",
      "CodFisco": "H224",
      "Abitanti": 186547,
      "Link": "http://www.comuni-italiani.it/080/063/"
    },
    {
      "Istat": "035033",
      "Comune": "Reggio Emilia",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": "4212x",
      "CodFisco": "H223",
      "Abitanti": 170086,
      "Link": "http://www.comuni-italiani.it/035/033/"
    },
    {
      "Istat": "035032",
      "Comune": "Reggiolo",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42046,
      "CodFisco": "H225",
      "Abitanti": 9362,
      "Link": "http://www.comuni-italiani.it/035/032/"
    },
    {
      "Istat": "062056",
      "Comune": "Reino",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "H227",
      "Abitanti": 1281,
      "Link": "http://www.comuni-italiani.it/062/056/"
    },
    {
      "Istat": "083070",
      "Comune": "Reitano",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98070,
      "CodFisco": "H228",
      "Abitanti": 878,
      "Link": "http://www.comuni-italiani.it/083/070/"
    },
    {
      "Istat": "030091",
      "Comune": "Remanzacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33047,
      "CodFisco": "H229",
      "Abitanti": 6075,
      "Link": "http://www.comuni-italiani.it/030/091/"
    },
    {
      "Istat": "017160",
      "Comune": "Remedello",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "H230",
      "Abitanti": 3404,
      "Link": "http://www.comuni-italiani.it/017/160/"
    },
    {
      "Istat": 108037,
      "Comune": "Renate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20838,
      "CodFisco": "H233",
      "Abitanti": 4214,
      "Link": "http://www.comuni-italiani.it/108/037/"
    },
    {
      "Istat": "078102",
      "Comune": "Rende",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87036,
      "CodFisco": "H235",
      "Abitanti": 35488,
      "Link": "http://www.comuni-italiani.it/078/102/"
    },
    {
      "Istat": "021072",
      "Comune": "Renon",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39054,
      "CodFisco": "H236",
      "Abitanti": 7600,
      "Link": "http://www.comuni-italiani.it/021/072/"
    },
    {
      "Istat": "026066",
      "Comune": "Resana",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31023,
      "CodFisco": "H238",
      "Abitanti": 9351,
      "Link": "http://www.comuni-italiani.it/026/066/"
    },
    {
      "Istat": "015181",
      "Comune": "Rescaldina",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20027,
      "CodFisco": "H240",
      "Abitanti": 14103,
      "Link": "http://www.comuni-italiani.it/015/181/"
    },
    {
      "Istat": "030092",
      "Comune": "Resia",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33010,
      "CodFisco": "H242",
      "Abitanti": 1101,
      "Link": "http://www.comuni-italiani.it/030/092/"
    },
    {
      "Istat": "030093",
      "Comune": "Resiutta",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33010,
      "CodFisco": "H244",
      "Abitanti": 320,
      "Link": "http://www.comuni-italiani.it/030/093/"
    },
    {
      "Istat": "085014",
      "Comune": "Resuttano",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "H245",
      "Abitanti": 2173,
      "Link": "http://www.comuni-italiani.it/085/014/"
    },
    {
      "Istat": "018121",
      "Comune": "Retorbido",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "H246",
      "Abitanti": 1474,
      "Link": "http://www.comuni-italiani.it/018/121/"
    },
    {
      "Istat": "004180",
      "Comune": "Revello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12036,
      "CodFisco": "H247",
      "Abitanti": 4226,
      "Link": "http://www.comuni-italiani.it/004/180/"
    },
    {
      "Istat": "020049",
      "Comune": "Revere",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46036,
      "CodFisco": "H248",
      "Abitanti": 2594,
      "Link": "http://www.comuni-italiani.it/020/049/"
    },
    {
      "Istat": "005090",
      "Comune": "Revigliasco d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "H250",
      "Abitanti": 840,
      "Link": "http://www.comuni-italiani.it/005/090/"
    },
    {
      "Istat": "026067",
      "Comune": "Revine Lago",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "H253",
      "Abitanti": 2263,
      "Link": "http://www.comuni-italiani.it/026/067/"
    },
    {
      "Istat": "022152",
      "Comune": "RevÚ",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38028,
      "CodFisco": "H254",
      "Abitanti": 1268,
      "Link": "http://www.comuni-italiani.it/022/152/"
    },
    {
      "Istat": "013195",
      "Comune": "Rezzago",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "H255",
      "Abitanti": 317,
      "Link": "http://www.comuni-italiani.it/013/195/"
    },
    {
      "Istat": "017161",
      "Comune": "Rezzato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25086,
      "CodFisco": "H256",
      "Abitanti": 13429,
      "Link": "http://www.comuni-italiani.it/017/161/"
    },
    {
      "Istat": "008049",
      "Comune": "Rezzo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18026,
      "CodFisco": "H257",
      "Abitanti": 383,
      "Link": "http://www.comuni-italiani.it/008/049/"
    },
    {
      "Istat": "010048",
      "Comune": "Rezzoaglio",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16048,
      "CodFisco": "H258",
      "Abitanti": 1074,
      "Link": "http://www.comuni-italiani.it/010/048/"
    },
    {
      "Istat": "007055",
      "Comune": "Rhemes-Notre-Dame",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H262",
      "Abitanti": 116,
      "Link": "http://www.comuni-italiani.it/007/055/"
    },
    {
      "Istat": "007056",
      "Comune": "Rhemes-Saint-Georges",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H263",
      "Abitanti": 209,
      "Link": "http://www.comuni-italiani.it/007/056/"
    },
    {
      "Istat": "015182",
      "Comune": "Rho",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20017,
      "CodFisco": "H264",
      "Abitanti": 50686,
      "Link": "http://www.comuni-italiani.it/015/182/"
    },
    {
      "Istat": "080064",
      "Comune": "Riace",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "H265",
      "Abitanti": 1977,
      "Link": "http://www.comuni-italiani.it/080/064/"
    },
    {
      "Istat": "009053",
      "Comune": "Rialto",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17020,
      "CodFisco": "H266",
      "Abitanti": 583,
      "Link": "http://www.comuni-italiani.it/009/053/"
    },
    {
      "Istat": "058081",
      "Comune": "Riano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "H267",
      "Abitanti": 9902,
      "Link": "http://www.comuni-italiani.it/058/081/"
    },
    {
      "Istat": "061068",
      "Comune": "Riardo",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81053,
      "CodFisco": "H268",
      "Abitanti": 2442,
      "Link": "http://www.comuni-italiani.it/061/068/"
    },
    {
      "Istat": "084033",
      "Comune": "Ribera",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92016,
      "CodFisco": "H269",
      "Abitanti": 19589,
      "Link": "http://www.comuni-italiani.it/084/033/"
    },
    {
      "Istat": "001212",
      "Comune": "Ribordone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "H270",
      "Abitanti": 69,
      "Link": "http://www.comuni-italiani.it/001/212/"
    },
    {
      "Istat": 102030,
      "Comune": "Ricadi",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89866,
      "CodFisco": "H271",
      "Abitanti": 4954,
      "Link": "http://www.comuni-italiani.it/102/030/"
    },
    {
      "Istat": "006143",
      "Comune": "Ricaldone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "H272",
      "Abitanti": 685,
      "Link": "http://www.comuni-italiani.it/006/143/"
    },
    {
      "Istat": "070057",
      "Comune": "Riccia",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86016,
      "CodFisco": "H273",
      "Abitanti": 5503,
      "Link": "http://www.comuni-italiani.it/070/057/"
    },
    {
      "Istat": "099013",
      "Comune": "Riccione",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47838,
      "CodFisco": "H274",
      "Abitanti": 35815,
      "Link": "http://www.comuni-italiani.it/099/013/"
    },
    {
      "Istat": "011023",
      "Comune": "RiccÚ del Golfo di Spezia",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "H275",
      "Abitanti": 3552,
      "Link": "http://www.comuni-italiani.it/011/023/"
    },
    {
      "Istat": "019079",
      "Comune": "Ricengo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "H276",
      "Abitanti": 1795,
      "Link": "http://www.comuni-italiani.it/019/079/"
    },
    {
      "Istat": "065105",
      "Comune": "Ricigliano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "H277",
      "Abitanti": 1241,
      "Link": "http://www.comuni-italiani.it/065/105/"
    },
    {
      "Istat": "026068",
      "Comune": "Riese Pio X",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31039,
      "CodFisco": "H280",
      "Abitanti": 10976,
      "Link": "http://www.comuni-italiani.it/026/068/"
    },
    {
      "Istat": "085015",
      "Comune": "Riesi",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93016,
      "CodFisco": "H281",
      "Abitanti": 11232,
      "Link": "http://www.comuni-italiani.it/085/015/"
    },
    {
      "Istat": "057059",
      "Comune": "Rieti",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02100",
      "CodFisco": "H282",
      "Abitanti": 47774,
      "Link": "http://www.comuni-italiani.it/057/059/"
    },
    {
      "Istat": "021073",
      "Comune": "Rifiano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "H284",
      "Abitanti": 1317,
      "Link": "http://www.comuni-italiani.it/021/073/"
    },
    {
      "Istat": "004181",
      "Comune": "Rifreddo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "H285",
      "Abitanti": 1077,
      "Link": "http://www.comuni-italiani.it/004/181/"
    },
    {
      "Istat": "058082",
      "Comune": "Rignano Flaminio",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "00068",
      "CodFisco": "H288",
      "Abitanti": 9740,
      "Link": "http://www.comuni-italiani.it/058/082/"
    },
    {
      "Istat": "071041",
      "Comune": "Rignano Garganico",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71010,
      "CodFisco": "H287",
      "Abitanti": 2216,
      "Link": "http://www.comuni-italiani.it/071/041/"
    },
    {
      "Istat": "048036",
      "Comune": "Rignano sull'Arno",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50067,
      "CodFisco": "H286",
      "Abitanti": 8758,
      "Link": "http://www.comuni-italiani.it/048/036/"
    },
    {
      "Istat": "030094",
      "Comune": "Rigolato",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "H289",
      "Abitanti": 513,
      "Link": "http://www.comuni-italiani.it/030/094/"
    },
    {
      "Istat": "002111",
      "Comune": "Rima San Giuseppe",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13026,
      "CodFisco": "H291",
      "Abitanti": 69,
      "Link": "http://www.comuni-italiani.it/002/111/"
    },
    {
      "Istat": "002112",
      "Comune": "Rimasco",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13026,
      "CodFisco": "H292",
      "Abitanti": 119,
      "Link": "http://www.comuni-italiani.it/002/112/"
    },
    {
      "Istat": "002113",
      "Comune": "Rimella",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "H293",
      "Abitanti": 135,
      "Link": "http://www.comuni-italiani.it/002/113/"
    },
    {
      "Istat": "099014",
      "Comune": "Rimini",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": "4792x",
      "CodFisco": "H294",
      "Abitanti": 143321,
      "Link": "http://www.comuni-italiani.it/099/014/"
    },
    {
      "Istat": "021074",
      "Comune": "Rio di Pusteria",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39037,
      "CodFisco": "H299",
      "Abitanti": 2961,
      "Link": "http://www.comuni-italiani.it/021/074/"
    },
    {
      "Istat": "049015",
      "Comune": "Rio Marina",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57038,
      "CodFisco": "H305",
      "Abitanti": 2274,
      "Link": "http://www.comuni-italiani.it/049/015/"
    },
    {
      "Istat": "049016",
      "Comune": "Rio nell'Elba",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57039,
      "CodFisco": "H297",
      "Abitanti": 1244,
      "Link": "http://www.comuni-italiani.it/049/016/"
    },
    {
      "Istat": "035034",
      "Comune": "Rio Saliceto",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42010,
      "CodFisco": "H298",
      "Abitanti": 6048,
      "Link": "http://www.comuni-italiani.it/035/034/"
    },
    {
      "Istat": "058083",
      "Comune": "Riofreddo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "H300",
      "Abitanti": 790,
      "Link": "http://www.comuni-italiani.it/058/083/"
    },
    {
      "Istat": "095043",
      "Comune": "Riola Sardo",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "H301",
      "Abitanti": 2163,
      "Link": "http://www.comuni-italiani.it/095/043/"
    },
    {
      "Istat": "039015",
      "Comune": "Riolo Terme",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 48025,
      "CodFisco": "H302",
      "Abitanti": 5813,
      "Link": "http://www.comuni-italiani.it/039/015/"
    },
    {
      "Istat": "036035",
      "Comune": "Riolunato",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41020,
      "CodFisco": "H303",
      "Abitanti": 759,
      "Link": "http://www.comuni-italiani.it/036/035/"
    },
    {
      "Istat": "011024",
      "Comune": "Riomaggiore",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19017,
      "CodFisco": "H304",
      "Abitanti": 1693,
      "Link": "http://www.comuni-italiani.it/011/024/"
    },
    {
      "Istat": "076066",
      "Comune": "Rionero in Vulture",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85028,
      "CodFisco": "H307",
      "Abitanti": 13533,
      "Link": "http://www.comuni-italiani.it/076/066/"
    },
    {
      "Istat": "094039",
      "Comune": "Rionero Sannitico",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86087,
      "CodFisco": "H308",
      "Abitanti": 1174,
      "Link": "http://www.comuni-italiani.it/094/039/"
    },
    {
      "Istat": "069072",
      "Comune": "Ripa Teatina",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "H320",
      "Abitanti": 4279,
      "Link": "http://www.comuni-italiani.it/069/072/"
    },
    {
      "Istat": "070058",
      "Comune": "Ripabottoni",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "H311",
      "Abitanti": 553,
      "Link": "http://www.comuni-italiani.it/070/058/"
    },
    {
      "Istat": "076067",
      "Comune": "Ripacandida",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85020,
      "CodFisco": "H312",
      "Abitanti": 1639,
      "Link": "http://www.comuni-italiani.it/076/067/"
    },
    {
      "Istat": "070059",
      "Comune": "Ripalimosani",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86025,
      "CodFisco": "H313",
      "Abitanti": 2980,
      "Link": "http://www.comuni-italiani.it/070/059/"
    },
    {
      "Istat": "019080",
      "Comune": "Ripalta Arpina",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "H314",
      "Abitanti": 1054,
      "Link": "http://www.comuni-italiani.it/019/080/"
    },
    {
      "Istat": "019081",
      "Comune": "Ripalta Cremasca",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "H315",
      "Abitanti": 3409,
      "Link": "http://www.comuni-italiani.it/019/081/"
    },
    {
      "Istat": "019082",
      "Comune": "Ripalta Guerina",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "H316",
      "Abitanti": 529,
      "Link": "http://www.comuni-italiani.it/019/082/"
    },
    {
      "Istat": "050030",
      "Comune": "Riparbella",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 56046,
      "CodFisco": "H319",
      "Abitanti": 1646,
      "Link": "http://www.comuni-italiani.it/050/030/"
    },
    {
      "Istat": "044063",
      "Comune": "Ripatransone",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63065,
      "CodFisco": "H321",
      "Abitanti": 4395,
      "Link": "http://www.comuni-italiani.it/044/063/"
    },
    {
      "Istat": "042039",
      "Comune": "Ripe",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60010,
      "CodFisco": "H322",
      "Abitanti": 4371,
      "Link": "http://www.comuni-italiani.it/042/039/"
    },
    {
      "Istat": "043045",
      "Comune": "Ripe San Ginesio",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "H323",
      "Abitanti": 872,
      "Link": "http://www.comuni-italiani.it/043/045/"
    },
    {
      "Istat": "060058",
      "Comune": "Ripi",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03027",
      "CodFisco": "H324",
      "Abitanti": 5494,
      "Link": "http://www.comuni-italiani.it/060/058/"
    },
    {
      "Istat": "087039",
      "Comune": "Riposto",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95018,
      "CodFisco": "H325",
      "Abitanti": 14981,
      "Link": "http://www.comuni-italiani.it/087/039/"
    },
    {
      "Istat": "004182",
      "Comune": "Rittana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "H326",
      "Abitanti": 140,
      "Link": "http://www.comuni-italiani.it/004/182/"
    },
    {
      "Istat": "022153",
      "Comune": "Riva del Garda",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38066,
      "CodFisco": "H330",
      "Abitanti": 16170,
      "Link": "http://www.comuni-italiani.it/022/153/"
    },
    {
      "Istat": "016180",
      "Comune": "Riva di Solto",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "H331",
      "Abitanti": 867,
      "Link": "http://www.comuni-italiani.it/016/180/"
    },
    {
      "Istat": "008050",
      "Comune": "Riva Ligure",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18015,
      "CodFisco": "H328",
      "Abitanti": 2941,
      "Link": "http://www.comuni-italiani.it/008/050/"
    },
    {
      "Istat": "001215",
      "Comune": "Riva Presso Chieri",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "H337",
      "Abitanti": 4199,
      "Link": "http://www.comuni-italiani.it/001/215/"
    },
    {
      "Istat": "002114",
      "Comune": "Riva Valdobbia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "H329",
      "Abitanti": 250,
      "Link": "http://www.comuni-italiani.it/002/114/"
    },
    {
      "Istat": "001213",
      "Comune": "Rivalba",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "H333",
      "Abitanti": 1162,
      "Link": "http://www.comuni-italiani.it/001/213/"
    },
    {
      "Istat": "006144",
      "Comune": "Rivalta Bormida",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "H334",
      "Abitanti": 1447,
      "Link": "http://www.comuni-italiani.it/006/144/"
    },
    {
      "Istat": "001214",
      "Comune": "Rivalta di Torino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "H335",
      "Abitanti": 19422,
      "Link": "http://www.comuni-italiani.it/001/214/"
    },
    {
      "Istat": "025043",
      "Comune": "Rivamonte Agordino",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "H327",
      "Abitanti": 678,
      "Link": "http://www.comuni-italiani.it/025/043/"
    },
    {
      "Istat": "018122",
      "Comune": "Rivanazzano Terme",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27055,
      "CodFisco": "H336",
      "Abitanti": 5144,
      "Link": "http://www.comuni-italiani.it/018/122/"
    },
    {
      "Istat": "001216",
      "Comune": "Rivara",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "H338",
      "Abitanti": 2721,
      "Link": "http://www.comuni-italiani.it/001/216/"
    },
    {
      "Istat": "001217",
      "Comune": "Rivarolo Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10086,
      "CodFisco": "H340",
      "Abitanti": 12370,
      "Link": "http://www.comuni-italiani.it/001/217/"
    },
    {
      "Istat": "019083",
      "Comune": "Rivarolo del Re ed Uniti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26036,
      "CodFisco": "H341",
      "Abitanti": 2091,
      "Link": "http://www.comuni-italiani.it/019/083/"
    },
    {
      "Istat": "020050",
      "Comune": "Rivarolo Mantovano",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46017,
      "CodFisco": "H342",
      "Abitanti": 2661,
      "Link": "http://www.comuni-italiani.it/020/050/"
    },
    {
      "Istat": "006145",
      "Comune": "Rivarone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15040,
      "CodFisco": "H343",
      "Abitanti": 371,
      "Link": "http://www.comuni-italiani.it/006/145/"
    },
    {
      "Istat": "001218",
      "Comune": "Rivarossa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "H344",
      "Abitanti": 1658,
      "Link": "http://www.comuni-italiani.it/001/218/"
    },
    {
      "Istat": "002115",
      "Comune": "Rive",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "H346",
      "Abitanti": 473,
      "Link": "http://www.comuni-italiani.it/002/115/"
    },
    {
      "Istat": "030095",
      "Comune": "Rive D'Arcano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "H347",
      "Abitanti": 2492,
      "Link": "http://www.comuni-italiani.it/030/095/"
    },
    {
      "Istat": "076068",
      "Comune": "Rivello",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85040,
      "CodFisco": "H348",
      "Abitanti": 2865,
      "Link": "http://www.comuni-italiani.it/076/068/"
    },
    {
      "Istat": "033038",
      "Comune": "Rivergaro",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29029,
      "CodFisco": "H350",
      "Abitanti": 6878,
      "Link": "http://www.comuni-italiani.it/033/038/"
    },
    {
      "Istat": "030096",
      "Comune": "Rivignano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "H352",
      "Abitanti": 4453,
      "Link": "http://www.comuni-italiani.it/030/096/"
    },
    {
      "Istat": "066078",
      "Comune": "Rivisondoli",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67036,
      "CodFisco": "H353",
      "Abitanti": 688,
      "Link": "http://www.comuni-italiani.it/066/078/"
    },
    {
      "Istat": "057060",
      "Comune": "Rivodutri",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02010",
      "CodFisco": "H354",
      "Abitanti": 1310,
      "Link": "http://www.comuni-italiani.it/057/060/"
    },
    {
      "Istat": "001219",
      "Comune": "Rivoli",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10098,
      "CodFisco": "H355",
      "Abitanti": 49591,
      "Link": "http://www.comuni-italiani.it/001/219/"
    },
    {
      "Istat": "023062",
      "Comune": "Rivoli Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "H356",
      "Abitanti": 2134,
      "Link": "http://www.comuni-italiani.it/023/062/"
    },
    {
      "Istat": "019084",
      "Comune": "Rivolta d'Adda",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 26027,
      "CodFisco": "H357",
      "Abitanti": 8056,
      "Link": "http://www.comuni-italiani.it/019/084/"
    },
    {
      "Istat": "080065",
      "Comune": "Rizziconi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89016,
      "CodFisco": "H359",
      "Abitanti": 8046,
      "Link": "http://www.comuni-italiani.it/080/065/"
    },
    {
      "Istat": "038020",
      "Comune": "Ro",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44030,
      "CodFisco": "H360",
      "Abitanti": 3460,
      "Link": "http://www.comuni-italiani.it/038/020/"
    },
    {
      "Istat": "024085",
      "Comune": "Roana",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36010,
      "CodFisco": "H361",
      "Abitanti": 4286,
      "Link": "http://www.comuni-italiani.it/024/085/"
    },
    {
      "Istat": "004183",
      "Comune": "Roaschia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "H362",
      "Abitanti": 151,
      "Link": "http://www.comuni-italiani.it/004/183/"
    },
    {
      "Istat": "004184",
      "Comune": "Roascio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12073,
      "CodFisco": "H363",
      "Abitanti": 82,
      "Link": "http://www.comuni-italiani.it/004/184/"
    },
    {
      "Istat": "002116",
      "Comune": "Roasio",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13060,
      "CodFisco": "H365",
      "Abitanti": 2477,
      "Link": "http://www.comuni-italiani.it/002/116/"
    },
    {
      "Istat": "005091",
      "Comune": "Roatto",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14018,
      "CodFisco": "H366",
      "Abitanti": 396,
      "Link": "http://www.comuni-italiani.it/005/091/"
    },
    {
      "Istat": "001220",
      "Comune": "Robassomero",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "H367",
      "Abitanti": 3023,
      "Link": "http://www.comuni-italiani.it/001/220/"
    },
    {
      "Istat": "097071",
      "Comune": "Robbiate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23899,
      "CodFisco": "G223",
      "Abitanti": 6106,
      "Link": "http://www.comuni-italiani.it/097/071/"
    },
    {
      "Istat": "018123",
      "Comune": "Robbio",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27038,
      "CodFisco": "H369",
      "Abitanti": 6156,
      "Link": "http://www.comuni-italiani.it/018/123/"
    },
    {
      "Istat": "015183",
      "Comune": "Robecchetto con Induno",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "H371",
      "Abitanti": 4896,
      "Link": "http://www.comuni-italiani.it/015/183/"
    },
    {
      "Istat": "019085",
      "Comune": "Robecco d'Oglio",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26010,
      "CodFisco": "H372",
      "Abitanti": 2393,
      "Link": "http://www.comuni-italiani.it/019/085/"
    },
    {
      "Istat": "018124",
      "Comune": "Robecco Pavese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27042,
      "CodFisco": "H375",
      "Abitanti": 571,
      "Link": "http://www.comuni-italiani.it/018/124/"
    },
    {
      "Istat": "015184",
      "Comune": "Robecco sul Naviglio",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20087,
      "CodFisco": "H373",
      "Abitanti": 6929,
      "Link": "http://www.comuni-italiani.it/015/184/"
    },
    {
      "Istat": "005092",
      "Comune": "Robella",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "H376",
      "Abitanti": 503,
      "Link": "http://www.comuni-italiani.it/005/092/"
    },
    {
      "Istat": "004185",
      "Comune": "Robilante",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12017,
      "CodFisco": "H377",
      "Abitanti": 2447,
      "Link": "http://www.comuni-italiani.it/004/185/"
    },
    {
      "Istat": "004186",
      "Comune": "Roburent",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "H378",
      "Abitanti": 541,
      "Link": "http://www.comuni-italiani.it/004/186/"
    },
    {
      "Istat": "001221",
      "Comune": "Rocca Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "H386",
      "Abitanti": 1781,
      "Link": "http://www.comuni-italiani.it/001/221/"
    },
    {
      "Istat": "058084",
      "Comune": "Rocca Canterano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "H387",
      "Abitanti": 202,
      "Link": "http://www.comuni-italiani.it/058/084/"
    },
    {
      "Istat": "004188",
      "Comune": "Rocca CigliË",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12060,
      "CodFisco": "H391",
      "Abitanti": 159,
      "Link": "http://www.comuni-italiani.it/004/188/"
    },
    {
      "Istat": "005093",
      "Comune": "Rocca d'Arazzo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "H392",
      "Abitanti": 954,
      "Link": "http://www.comuni-italiani.it/005/093/"
    },
    {
      "Istat": "060059",
      "Comune": "Rocca d'Arce",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "H393",
      "Abitanti": 990,
      "Link": "http://www.comuni-italiani.it/060/059/"
    },
    {
      "Istat": "004189",
      "Comune": "Rocca de' Baldi",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12047,
      "CodFisco": "H395",
      "Abitanti": 1685,
      "Link": "http://www.comuni-italiani.it/004/189/"
    },
    {
      "Istat": "018125",
      "Comune": "Rocca de' Giorgi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "H396",
      "Abitanti": 74,
      "Link": "http://www.comuni-italiani.it/018/125/"
    },
    {
      "Istat": "061069",
      "Comune": "Rocca D'Evandro",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81040,
      "CodFisco": "H398",
      "Abitanti": 3437,
      "Link": "http://www.comuni-italiani.it/061/069/"
    },
    {
      "Istat": "066080",
      "Comune": "Rocca di Botte",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67066,
      "CodFisco": "H399",
      "Abitanti": 910,
      "Link": "http://www.comuni-italiani.it/066/080/"
    },
    {
      "Istat": "066081",
      "Comune": "Rocca di Cambio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67047,
      "CodFisco": "H400",
      "Abitanti": 538,
      "Link": "http://www.comuni-italiani.it/066/081/"
    },
    {
      "Istat": "058085",
      "Comune": "Rocca di Cave",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "H401",
      "Abitanti": 385,
      "Link": "http://www.comuni-italiani.it/058/085/"
    },
    {
      "Istat": "066082",
      "Comune": "Rocca di Mezzo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67048,
      "CodFisco": "H402",
      "Abitanti": 1556,
      "Link": "http://www.comuni-italiani.it/066/082/"
    },
    {
      "Istat": 101019,
      "Comune": "Rocca di Neto",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88821,
      "CodFisco": "H403",
      "Abitanti": 5657,
      "Link": "http://www.comuni-italiani.it/101/019/"
    },
    {
      "Istat": "058086",
      "Comune": "Rocca di Papa",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "H404",
      "Abitanti": 16149,
      "Link": "http://www.comuni-italiani.it/058/086/"
    },
    {
      "Istat": "006147",
      "Comune": "Rocca Grimalda",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15078,
      "CodFisco": "H414",
      "Abitanti": 1548,
      "Link": "http://www.comuni-italiani.it/006/147/"
    },
    {
      "Istat": "078103",
      "Comune": "Rocca Imperiale",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87074,
      "CodFisco": "H416",
      "Abitanti": 3361,
      "Link": "http://www.comuni-italiani.it/078/103/"
    },
    {
      "Istat": "059022",
      "Comune": "Rocca Massima",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "04010",
      "CodFisco": "H421",
      "Abitanti": 1088,
      "Link": "http://www.comuni-italiani.it/059/022/"
    },
    {
      "Istat": "066083",
      "Comune": "Rocca Pia",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "H429",
      "Abitanti": 171,
      "Link": "http://www.comuni-italiani.it/066/083/"
    },
    {
      "Istat": "025044",
      "Comune": "Rocca Pietore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "H379",
      "Abitanti": 1324,
      "Link": "http://www.comuni-italiani.it/025/044/"
    },
    {
      "Istat": "058088",
      "Comune": "Rocca Priora",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00040",
      "CodFisco": "H432",
      "Abitanti": 11987,
      "Link": "http://www.comuni-italiani.it/058/088/"
    },
    {
      "Istat": "040036",
      "Comune": "Rocca San Casciano",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47017,
      "CodFisco": "H437",
      "Abitanti": 2031,
      "Link": "http://www.comuni-italiani.it/040/036/"
    },
    {
      "Istat": "064079",
      "Comune": "Rocca San Felice",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "H438",
      "Abitanti": 889,
      "Link": "http://www.comuni-italiani.it/064/079/"
    },
    {
      "Istat": "069074",
      "Comune": "Rocca San Giovanni",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66020,
      "CodFisco": "H439",
      "Abitanti": 2379,
      "Link": "http://www.comuni-italiani.it/069/074/"
    },
    {
      "Istat": "067036",
      "Comune": "Rocca Santa Maria",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "H440",
      "Abitanti": 590,
      "Link": "http://www.comuni-italiani.it/067/036/"
    },
    {
      "Istat": "058089",
      "Comune": "Rocca Santo Stefano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "H441",
      "Abitanti": 1040,
      "Link": "http://www.comuni-italiani.it/058/089/"
    },
    {
      "Istat": "057062",
      "Comune": "Rocca Sinibalda",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02026",
      "CodFisco": "H446",
      "Abitanti": 844,
      "Link": "http://www.comuni-italiani.it/057/062/"
    },
    {
      "Istat": "018126",
      "Comune": "Rocca Susella",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27052,
      "CodFisco": "H450",
      "Abitanti": 237,
      "Link": "http://www.comuni-italiani.it/018/126/"
    },
    {
      "Istat": "064078",
      "Comune": "Roccabascerana",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83016,
      "CodFisco": "H382",
      "Abitanti": 2358,
      "Link": "http://www.comuni-italiani.it/064/078/"
    },
    {
      "Istat": 101018,
      "Comune": "Roccabernarda",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88835,
      "CodFisco": "H383",
      "Abitanti": 3374,
      "Link": "http://www.comuni-italiani.it/101/018/"
    },
    {
      "Istat": "034030",
      "Comune": "Roccabianca",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43010,
      "CodFisco": "H384",
      "Abitanti": 3110,
      "Link": "http://www.comuni-italiani.it/034/030/"
    },
    {
      "Istat": "004187",
      "Comune": "Roccabruna",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "H385",
      "Abitanti": 1603,
      "Link": "http://www.comuni-italiani.it/004/187/"
    },
    {
      "Istat": "066079",
      "Comune": "Roccacasale",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "H389",
      "Abitanti": 726,
      "Link": "http://www.comuni-italiani.it/066/079/"
    },
    {
      "Istat": "065106",
      "Comune": "Roccadaspide",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84069,
      "CodFisco": "H394",
      "Abitanti": 7462,
      "Link": "http://www.comuni-italiani.it/065/106/"
    },
    {
      "Istat": "083071",
      "Comune": "Roccafiorita",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "H405",
      "Abitanti": 232,
      "Link": "http://www.comuni-italiani.it/083/071/"
    },
    {
      "Istat": "044064",
      "Comune": "Roccafluvione",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63093,
      "CodFisco": "H390",
      "Abitanti": 2116,
      "Link": "http://www.comuni-italiani.it/044/064/"
    },
    {
      "Istat": "080066",
      "Comune": "Roccaforte del Greco",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89060,
      "CodFisco": "H408",
      "Abitanti": 594,
      "Link": "http://www.comuni-italiani.it/080/066/"
    },
    {
      "Istat": "006146",
      "Comune": "Roccaforte Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "H406",
      "Abitanti": 158,
      "Link": "http://www.comuni-italiani.it/006/146/"
    },
    {
      "Istat": "004190",
      "Comune": "Roccaforte MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12088,
      "CodFisco": "H407",
      "Abitanti": 2127,
      "Link": "http://www.comuni-italiani.it/004/190/"
    },
    {
      "Istat": "073023",
      "Comune": "Roccaforzata",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "H409",
      "Abitanti": 1846,
      "Link": "http://www.comuni-italiani.it/073/023/"
    },
    {
      "Istat": "017162",
      "Comune": "Roccafranca",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "H410",
      "Abitanti": 4819,
      "Link": "http://www.comuni-italiani.it/017/162/"
    },
    {
      "Istat": "058087",
      "Comune": "Roccagiovine",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "H411",
      "Abitanti": 290,
      "Link": "http://www.comuni-italiani.it/058/087/"
    },
    {
      "Istat": "065107",
      "Comune": "Roccagloriosa",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84060,
      "CodFisco": "H412",
      "Abitanti": 1650,
      "Link": "http://www.comuni-italiani.it/065/107/"
    },
    {
      "Istat": "059021",
      "Comune": "Roccagorga",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "H413",
      "Abitanti": 4766,
      "Link": "http://www.comuni-italiani.it/059/021/"
    },
    {
      "Istat": "053020",
      "Comune": "Roccalbegna",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58053,
      "CodFisco": "H417",
      "Abitanti": 1136,
      "Link": "http://www.comuni-italiani.it/053/020/"
    },
    {
      "Istat": "083072",
      "Comune": "Roccalumera",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98027,
      "CodFisco": "H418",
      "Abitanti": 4270,
      "Link": "http://www.comuni-italiani.it/083/072/"
    },
    {
      "Istat": "094040",
      "Comune": "Roccamandolfi",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86092,
      "CodFisco": "H420",
      "Abitanti": 1003,
      "Link": "http://www.comuni-italiani.it/094/040/"
    },
    {
      "Istat": "082061",
      "Comune": "Roccamena",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "H422",
      "Abitanti": 1604,
      "Link": "http://www.comuni-italiani.it/082/061/"
    },
    {
      "Istat": "061070",
      "Comune": "Roccamonfina",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81035,
      "CodFisco": "H423",
      "Abitanti": 3688,
      "Link": "http://www.comuni-italiani.it/061/070/"
    },
    {
      "Istat": "069073",
      "Comune": "Roccamontepiano",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "H424",
      "Abitanti": 1822,
      "Link": "http://www.comuni-italiani.it/069/073/"
    },
    {
      "Istat": "068034",
      "Comune": "Roccamorice",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "H425",
      "Abitanti": 989,
      "Link": "http://www.comuni-italiani.it/068/034/"
    },
    {
      "Istat": "076069",
      "Comune": "Roccanova",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85036,
      "CodFisco": "H426",
      "Abitanti": 1640,
      "Link": "http://www.comuni-italiani.it/076/069/"
    },
    {
      "Istat": "057061",
      "Comune": "Roccantica",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "H427",
      "Abitanti": 627,
      "Link": "http://www.comuni-italiani.it/057/061/"
    },
    {
      "Istat": "082062",
      "Comune": "Roccapalumba",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "H428",
      "Abitanti": 2680,
      "Link": "http://www.comuni-italiani.it/082/062/"
    },
    {
      "Istat": "065108",
      "Comune": "Roccapiemonte",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84086,
      "CodFisco": "H431",
      "Abitanti": 9068,
      "Link": "http://www.comuni-italiani.it/065/108/"
    },
    {
      "Istat": "063065",
      "Comune": "Roccarainola",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "H433",
      "Abitanti": 7294,
      "Link": "http://www.comuni-italiani.it/063/065/"
    },
    {
      "Istat": "066084",
      "Comune": "Roccaraso",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67037,
      "CodFisco": "H434",
      "Abitanti": 1677,
      "Link": "http://www.comuni-italiani.it/066/084/"
    },
    {
      "Istat": "061071",
      "Comune": "Roccaromana",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81051,
      "CodFisco": "H436",
      "Abitanti": 997,
      "Link": "http://www.comuni-italiani.it/061/071/"
    },
    {
      "Istat": "069075",
      "Comune": "Roccascalegna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "H442",
      "Abitanti": 1362,
      "Link": "http://www.comuni-italiani.it/069/075/"
    },
    {
      "Istat": "060060",
      "Comune": "Roccasecca",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03038",
      "CodFisco": "H443",
      "Abitanti": 7583,
      "Link": "http://www.comuni-italiani.it/060/060/"
    },
    {
      "Istat": "059023",
      "Comune": "Roccasecca dei Volsci",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "H444",
      "Abitanti": 1144,
      "Link": "http://www.comuni-italiani.it/059/023/"
    },
    {
      "Istat": "094041",
      "Comune": "Roccasicura",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "H445",
      "Abitanti": 587,
      "Link": "http://www.comuni-italiani.it/094/041/"
    },
    {
      "Istat": "004191",
      "Comune": "Roccasparvera",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "H447",
      "Abitanti": 737,
      "Link": "http://www.comuni-italiani.it/004/191/"
    },
    {
      "Istat": "069076",
      "Comune": "Roccaspinalveti",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "H448",
      "Abitanti": 1465,
      "Link": "http://www.comuni-italiani.it/069/076/"
    },
    {
      "Istat": "053021",
      "Comune": "Roccastrada",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58036,
      "CodFisco": "H449",
      "Abitanti": 9540,
      "Link": "http://www.comuni-italiani.it/053/021/"
    },
    {
      "Istat": "083073",
      "Comune": "Roccavaldina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "H380",
      "Abitanti": 1173,
      "Link": "http://www.comuni-italiani.it/083/073/"
    },
    {
      "Istat": "005094",
      "Comune": "Roccaverano",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14050,
      "CodFisco": "H451",
      "Abitanti": 443,
      "Link": "http://www.comuni-italiani.it/005/094/"
    },
    {
      "Istat": "009054",
      "Comune": "Roccavignale",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17017,
      "CodFisco": "H452",
      "Abitanti": 750,
      "Link": "http://www.comuni-italiani.it/009/054/"
    },
    {
      "Istat": "004192",
      "Comune": "Roccavione",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12018,
      "CodFisco": "H453",
      "Abitanti": 2889,
      "Link": "http://www.comuni-italiani.it/004/192/"
    },
    {
      "Istat": "070060",
      "Comune": "Roccavivara",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "H454",
      "Abitanti": 875,
      "Link": "http://www.comuni-italiani.it/070/060/"
    },
    {
      "Istat": "080067",
      "Comune": "Roccella Ionica",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89047,
      "CodFisco": "H456",
      "Abitanti": 6750,
      "Link": "http://www.comuni-italiani.it/080/067/"
    },
    {
      "Istat": "083074",
      "Comune": "Roccella Valdemone",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "H455",
      "Abitanti": 714,
      "Link": "http://www.comuni-italiani.it/083/074/"
    },
    {
      "Istat": "094042",
      "Comune": "Rocchetta a Volturno",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "H458",
      "Abitanti": 1080,
      "Link": "http://www.comuni-italiani.it/094/042/"
    },
    {
      "Istat": "004193",
      "Comune": "Rocchetta Belbo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 12050,
      "CodFisco": "H462",
      "Abitanti": 178,
      "Link": "http://www.comuni-italiani.it/004/193/"
    },
    {
      "Istat": "011025",
      "Comune": "Rocchetta di Vara",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "H461",
      "Abitanti": 819,
      "Link": "http://www.comuni-italiani.it/011/025/"
    },
    {
      "Istat": "061072",
      "Comune": "Rocchetta e Croce",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81042,
      "CodFisco": "H459",
      "Abitanti": 486,
      "Link": "http://www.comuni-italiani.it/061/072/"
    },
    {
      "Istat": "006148",
      "Comune": "Rocchetta Ligure",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "H465",
      "Abitanti": 216,
      "Link": "http://www.comuni-italiani.it/006/148/"
    },
    {
      "Istat": "008051",
      "Comune": "Rocchetta Nervina",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18030,
      "CodFisco": "H460",
      "Abitanti": 275,
      "Link": "http://www.comuni-italiani.it/008/051/"
    },
    {
      "Istat": "005095",
      "Comune": "Rocchetta Palafea",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14042,
      "CodFisco": "H466",
      "Abitanti": 359,
      "Link": "http://www.comuni-italiani.it/005/095/"
    },
    {
      "Istat": "071042",
      "Comune": "Rocchetta Sant'Antonio",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71020,
      "CodFisco": "H467",
      "Abitanti": 1982,
      "Link": "http://www.comuni-italiani.it/071/042/"
    },
    {
      "Istat": "005096",
      "Comune": "Rocchetta Tanaro",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "H468",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/005/096/"
    },
    {
      "Istat": "015185",
      "Comune": "Rodano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "H470",
      "Abitanti": 4457,
      "Link": "http://www.comuni-italiani.it/015/185/"
    },
    {
      "Istat": "004194",
      "Comune": "Roddi",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "H472",
      "Abitanti": 1578,
      "Link": "http://www.comuni-italiani.it/004/194/"
    },
    {
      "Istat": "004195",
      "Comune": "Roddino",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "H473",
      "Abitanti": 377,
      "Link": "http://www.comuni-italiani.it/004/195/"
    },
    {
      "Istat": "004196",
      "Comune": "Rodello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "H474",
      "Abitanti": 986,
      "Link": "http://www.comuni-italiani.it/004/196/"
    },
    {
      "Istat": "021075",
      "Comune": "Rodengo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39037,
      "CodFisco": "H475",
      "Abitanti": 1190,
      "Link": "http://www.comuni-italiani.it/021/075/"
    },
    {
      "Istat": "017163",
      "Comune": "Rodengo Saiano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "H477",
      "Abitanti": 8858,
      "Link": "http://www.comuni-italiani.it/017/163/"
    },
    {
      "Istat": "013197",
      "Comune": "Rodero",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "H478",
      "Abitanti": 1202,
      "Link": "http://www.comuni-italiani.it/013/197/"
    },
    {
      "Istat": "071043",
      "Comune": "Rodi Garganico",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71012,
      "CodFisco": "H480",
      "Abitanti": 3673,
      "Link": "http://www.comuni-italiani.it/071/043/"
    },
    {
      "Istat": "083075",
      "Comune": "RodÏ Milici",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98059,
      "CodFisco": "H479",
      "Abitanti": 2213,
      "Link": "http://www.comuni-italiani.it/083/075/"
    },
    {
      "Istat": "020051",
      "Comune": "Rodigo",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "H481",
      "Abitanti": 5399,
      "Link": "http://www.comuni-italiani.it/020/051/"
    },
    {
      "Istat": "017164",
      "Comune": "RoË Volciano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25077,
      "CodFisco": "H484",
      "Abitanti": 4496,
      "Link": "http://www.comuni-italiani.it/017/164/"
    },
    {
      "Istat": "065109",
      "Comune": "Rofrano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "H485",
      "Abitanti": 1710,
      "Link": "http://www.comuni-italiani.it/065/109/"
    },
    {
      "Istat": "097072",
      "Comune": "Rogeno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23849,
      "CodFisco": "H486",
      "Abitanti": 3239,
      "Link": "http://www.comuni-italiani.it/097/072/"
    },
    {
      "Istat": "078104",
      "Comune": "Roggiano Gravina",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87017,
      "CodFisco": "H488",
      "Abitanti": 7371,
      "Link": "http://www.comuni-italiani.it/078/104/"
    },
    {
      "Istat": "080068",
      "Comune": "Roghudi",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89060,
      "CodFisco": "H489",
      "Abitanti": 1203,
      "Link": "http://www.comuni-italiani.it/080/068/"
    },
    {
      "Istat": "078105",
      "Comune": "Rogliano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87054,
      "CodFisco": "H490",
      "Abitanti": 5828,
      "Link": "http://www.comuni-italiani.it/078/105/"
    },
    {
      "Istat": "018127",
      "Comune": "Rognano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "H491",
      "Abitanti": 618,
      "Link": "http://www.comuni-italiani.it/018/127/"
    },
    {
      "Istat": "016182",
      "Comune": "Rogno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "H492",
      "Abitanti": 3966,
      "Link": "http://www.comuni-italiani.it/016/182/"
    },
    {
      "Istat": "014056",
      "Comune": "Rogolo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "H493",
      "Abitanti": 566,
      "Link": "http://www.comuni-italiani.it/014/056/"
    },
    {
      "Istat": "058090",
      "Comune": "Roiate",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "H494",
      "Abitanti": 770,
      "Link": "http://www.comuni-italiani.it/058/090/"
    },
    {
      "Istat": "069077",
      "Comune": "Roio del Sangro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "H495",
      "Abitanti": 111,
      "Link": "http://www.comuni-italiani.it/069/077/"
    },
    {
      "Istat": "007057",
      "Comune": "Roisan",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H497",
      "Abitanti": 1037,
      "Link": "http://www.comuni-italiani.it/007/057/"
    },
    {
      "Istat": "001222",
      "Comune": "Roletto",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "H498",
      "Abitanti": 2028,
      "Link": "http://www.comuni-italiani.it/001/222/"
    },
    {
      "Istat": "035035",
      "Comune": "Rolo",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42047,
      "CodFisco": "H500",
      "Abitanti": 4090,
      "Link": "http://www.comuni-italiani.it/035/035/"
    },
    {
      "Istat": "058091",
      "Comune": "Roma",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "001xx",
      "CodFisco": "H501",
      "Abitanti": 2761477,
      "Link": "http://www.comuni-italiani.it/058/091/"
    },
    {
      "Istat": "065110",
      "Comune": "Romagnano al Monte",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "H503",
      "Abitanti": 399,
      "Link": "http://www.comuni-italiani.it/065/110/"
    },
    {
      "Istat": "003130",
      "Comune": "Romagnano Sesia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 28078,
      "CodFisco": "H502",
      "Abitanti": 4103,
      "Link": "http://www.comuni-italiani.it/003/130/"
    },
    {
      "Istat": "018128",
      "Comune": "Romagnese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "H505",
      "Abitanti": 752,
      "Link": "http://www.comuni-italiani.it/018/128/"
    },
    {
      "Istat": "022154",
      "Comune": "Romallo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38028,
      "CodFisco": "H506",
      "Abitanti": 610,
      "Link": "http://www.comuni-italiani.it/022/154/"
    },
    {
      "Istat": "090061",
      "Comune": "Romana",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "H507",
      "Abitanti": 585,
      "Link": "http://www.comuni-italiani.it/090/061/"
    },
    {
      "Istat": "019086",
      "Comune": "Romanengo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26014,
      "CodFisco": "H508",
      "Abitanti": 3078,
      "Link": "http://www.comuni-italiani.it/019/086/"
    },
    {
      "Istat": "001223",
      "Comune": "Romano Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10090,
      "CodFisco": "H511",
      "Abitanti": 2957,
      "Link": "http://www.comuni-italiani.it/001/223/"
    },
    {
      "Istat": "024086",
      "Comune": "Romano d'Ezzelino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36060,
      "CodFisco": "H512",
      "Abitanti": 14621,
      "Link": "http://www.comuni-italiani.it/024/086/"
    },
    {
      "Istat": "016183",
      "Comune": "Romano di Lombardia",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24058,
      "CodFisco": "H509",
      "Abitanti": 19049,
      "Link": "http://www.comuni-italiani.it/016/183/"
    },
    {
      "Istat": "031015",
      "Comune": "Romans d'Isonzo",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34076,
      "CodFisco": "H514",
      "Abitanti": 3732,
      "Link": "http://www.comuni-italiani.it/031/015/"
    },
    {
      "Istat": 102031,
      "Comune": "Rombiolo",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89841,
      "CodFisco": "H516",
      "Abitanti": 4742,
      "Link": "http://www.comuni-italiani.it/102/031/"
    },
    {
      "Istat": "022155",
      "Comune": "Romeno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "H517",
      "Abitanti": 1387,
      "Link": "http://www.comuni-italiani.it/022/155/"
    },
    {
      "Istat": "003131",
      "Comune": "Romentino",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28068,
      "CodFisco": "H518",
      "Abitanti": 5386,
      "Link": "http://www.comuni-italiani.it/003/131/"
    },
    {
      "Istat": "083076",
      "Comune": "Rometta",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98043,
      "CodFisco": "H519",
      "Abitanti": 6694,
      "Link": "http://www.comuni-italiani.it/083/076/"
    },
    {
      "Istat": "013199",
      "Comune": "Ronago",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22027,
      "CodFisco": "H521",
      "Abitanti": 1769,
      "Link": "http://www.comuni-italiani.it/013/199/"
    },
    {
      "Istat": "023063",
      "Comune": "Ronc‡",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "H522",
      "Abitanti": 3766,
      "Link": "http://www.comuni-italiani.it/023/063/"
    },
    {
      "Istat": "026069",
      "Comune": "Roncade",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31056,
      "CodFisco": "H523",
      "Abitanti": 14148,
      "Link": "http://www.comuni-italiani.it/026/069/"
    },
    {
      "Istat": "017165",
      "Comune": "Roncadelle",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "H525",
      "Abitanti": 9369,
      "Link": "http://www.comuni-italiani.it/017/165/"
    },
    {
      "Istat": "018129",
      "Comune": "Roncaro",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "H527",
      "Abitanti": 1327,
      "Link": "http://www.comuni-italiani.it/018/129/"
    },
    {
      "Istat": "022156",
      "Comune": "Roncegno Terme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "H528",
      "Abitanti": 2818,
      "Link": "http://www.comuni-italiani.it/022/156/"
    },
    {
      "Istat": 108055,
      "Comune": "Roncello",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20877,
      "CodFisco": "H529",
      "Abitanti": 3908,
      "Link": "http://www.comuni-italiani.it/108/055/"
    },
    {
      "Istat": "031016",
      "Comune": "Ronchi dei Legionari",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34077,
      "CodFisco": "H531",
      "Abitanti": 12130,
      "Link": "http://www.comuni-italiani.it/031/016/"
    },
    {
      "Istat": "022157",
      "Comune": "Ronchi Valsugana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "H532",
      "Abitanti": 424,
      "Link": "http://www.comuni-italiani.it/022/157/"
    },
    {
      "Istat": "030097",
      "Comune": "Ronchis",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "H533",
      "Abitanti": 2077,
      "Link": "http://www.comuni-italiani.it/030/097/"
    },
    {
      "Istat": "056045",
      "Comune": "Ronciglione",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01037",
      "CodFisco": "H534",
      "Abitanti": 8908,
      "Link": "http://www.comuni-italiani.it/056/045/"
    },
    {
      "Istat": "023064",
      "Comune": "Ronco all'Adige",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37055,
      "CodFisco": "H540",
      "Abitanti": 6221,
      "Link": "http://www.comuni-italiani.it/023/064/"
    },
    {
      "Istat": "096053",
      "Comune": "Ronco Biellese",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13845,
      "CodFisco": "H538",
      "Abitanti": 1509,
      "Link": "http://www.comuni-italiani.it/096/053/"
    },
    {
      "Istat": 108038,
      "Comune": "Ronco Briantino",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20885,
      "CodFisco": "H537",
      "Abitanti": 3452,
      "Link": "http://www.comuni-italiani.it/108/038/"
    },
    {
      "Istat": "001224",
      "Comune": "Ronco Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "H539",
      "Abitanti": 319,
      "Link": "http://www.comuni-italiani.it/001/224/"
    },
    {
      "Istat": "010049",
      "Comune": "Ronco Scrivia",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16019,
      "CodFisco": "H536",
      "Abitanti": 4555,
      "Link": "http://www.comuni-italiani.it/010/049/"
    },
    {
      "Istat": "016184",
      "Comune": "Roncobello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "H535",
      "Abitanti": 436,
      "Link": "http://www.comuni-italiani.it/016/184/"
    },
    {
      "Istat": "020052",
      "Comune": "Roncoferraro",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46037,
      "CodFisco": "H541",
      "Abitanti": 7309,
      "Link": "http://www.comuni-italiani.it/020/052/"
    },
    {
      "Istat": "040037",
      "Comune": "Roncofreddo",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47020,
      "CodFisco": "H542",
      "Abitanti": 3371,
      "Link": "http://www.comuni-italiani.it/040/037/"
    },
    {
      "Istat": "016185",
      "Comune": "Roncola",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "H544",
      "Abitanti": 754,
      "Link": "http://www.comuni-italiani.it/016/185/"
    },
    {
      "Istat": "022158",
      "Comune": "Roncone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38087,
      "CodFisco": "H545",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/022/158/"
    },
    {
      "Istat": "010050",
      "Comune": "Rondanina",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16025,
      "CodFisco": "H546",
      "Abitanti": 78,
      "Link": "http://www.comuni-italiani.it/010/050/"
    },
    {
      "Istat": "001225",
      "Comune": "Rondissone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10030,
      "CodFisco": "H547",
      "Abitanti": 1843,
      "Link": "http://www.comuni-italiani.it/001/225/"
    },
    {
      "Istat": "002118",
      "Comune": "Ronsecco",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13036,
      "CodFisco": "H549",
      "Abitanti": 603,
      "Link": "http://www.comuni-italiani.it/002/118/"
    },
    {
      "Istat": "022135",
      "Comune": "Ronzo-Chienis",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "M303",
      "Abitanti": 1022,
      "Link": "http://www.comuni-italiani.it/022/135/"
    },
    {
      "Istat": "022159",
      "Comune": "Ronzone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "H552",
      "Abitanti": 396,
      "Link": "http://www.comuni-italiani.it/022/159/"
    },
    {
      "Istat": "096054",
      "Comune": "Roppolo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13883,
      "CodFisco": "H553",
      "Abitanti": 934,
      "Link": "http://www.comuni-italiani.it/096/054/"
    },
    {
      "Istat": "001226",
      "Comune": "Ror‡",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "H554",
      "Abitanti": 251,
      "Link": "http://www.comuni-italiani.it/001/226/"
    },
    {
      "Istat": "024087",
      "Comune": "Ros‡",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36027,
      "CodFisco": "H556",
      "Abitanti": 14138,
      "Link": "http://www.comuni-italiani.it/024/087/"
    },
    {
      "Istat": "080069",
      "Comune": "Rosarno",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89025,
      "CodFisco": "H558",
      "Abitanti": 14836,
      "Link": "http://www.comuni-italiani.it/080/069/"
    },
    {
      "Istat": "018130",
      "Comune": "Rosasco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "H559",
      "Abitanti": 647,
      "Link": "http://www.comuni-italiani.it/018/130/"
    },
    {
      "Istat": "015188",
      "Comune": "Rosate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20088,
      "CodFisco": "H560",
      "Abitanti": 5442,
      "Link": "http://www.comuni-italiani.it/015/188/"
    },
    {
      "Istat": "096055",
      "Comune": "Rosazza",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13815,
      "CodFisco": "H561",
      "Abitanti": 95,
      "Link": "http://www.comuni-italiani.it/096/055/"
    },
    {
      "Istat": "068035",
      "Comune": "Rosciano",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "H562",
      "Abitanti": 3597,
      "Link": "http://www.comuni-italiani.it/068/035/"
    },
    {
      "Istat": "065111",
      "Comune": "Roscigno",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "H564",
      "Abitanti": 860,
      "Link": "http://www.comuni-italiani.it/065/111/"
    },
    {
      "Istat": "078106",
      "Comune": "Rose",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "H565",
      "Abitanti": 4396,
      "Link": "http://www.comuni-italiani.it/078/106/"
    },
    {
      "Istat": "069078",
      "Comune": "Rosello",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66040,
      "CodFisco": "H566",
      "Abitanti": 269,
      "Link": "http://www.comuni-italiani.it/069/078/"
    },
    {
      "Istat": "078107",
      "Comune": "Roseto Capo Spulico",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "H572",
      "Abitanti": 1910,
      "Link": "http://www.comuni-italiani.it/078/107/"
    },
    {
      "Istat": "067037",
      "Comune": "Roseto degli Abruzzi",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64026,
      "CodFisco": "F585",
      "Abitanti": 25072,
      "Link": "http://www.comuni-italiani.it/067/037/"
    },
    {
      "Istat": "071044",
      "Comune": "Roseto Valfortore",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71039,
      "CodFisco": "H568",
      "Abitanti": 1205,
      "Link": "http://www.comuni-italiani.it/071/044/"
    },
    {
      "Istat": "049017",
      "Comune": "Rosignano Marittimo",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0586",
      "CAP": 57016,
      "CodFisco": "H570",
      "Abitanti": 32488,
      "Link": "http://www.comuni-italiani.it/049/017/"
    },
    {
      "Istat": "006149",
      "Comune": "Rosignano Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "H569",
      "Abitanti": 1670,
      "Link": "http://www.comuni-italiani.it/006/149/"
    },
    {
      "Istat": "029040",
      "Comune": "Rosolina",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45010,
      "CodFisco": "H573",
      "Abitanti": 6511,
      "Link": "http://www.comuni-italiani.it/029/040/"
    },
    {
      "Istat": "089016",
      "Comune": "Rosolini",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96019,
      "CodFisco": "H574",
      "Abitanti": 21798,
      "Link": "http://www.comuni-italiani.it/089/016/"
    },
    {
      "Istat": "042040",
      "Comune": "Rosora",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "H575",
      "Abitanti": 1952,
      "Link": "http://www.comuni-italiani.it/042/040/"
    },
    {
      "Istat": "002121",
      "Comune": "Rossa",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "H577",
      "Abitanti": 194,
      "Link": "http://www.comuni-italiani.it/002/121/"
    },
    {
      "Istat": "004197",
      "Comune": "Rossana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "H578",
      "Abitanti": 929,
      "Link": "http://www.comuni-italiani.it/004/197/"
    },
    {
      "Istat": "078108",
      "Comune": "Rossano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87067,
      "CodFisco": "H579",
      "Abitanti": 38422,
      "Link": "http://www.comuni-italiani.it/078/108/"
    },
    {
      "Istat": "024088",
      "Comune": "Rossano Veneto",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36028,
      "CodFisco": "H580",
      "Abitanti": 7959,
      "Link": "http://www.comuni-italiani.it/024/088/"
    },
    {
      "Istat": "010051",
      "Comune": "Rossiglione",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "H581",
      "Abitanti": 2959,
      "Link": "http://www.comuni-italiani.it/010/051/"
    },
    {
      "Istat": "001228",
      "Comune": "Rosta",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "H583",
      "Abitanti": 4623,
      "Link": "http://www.comuni-italiani.it/001/228/"
    },
    {
      "Istat": "016186",
      "Comune": "Rota d'Imagna",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24037,
      "CodFisco": "H584",
      "Abitanti": 924,
      "Link": "http://www.comuni-italiani.it/016/186/"
    },
    {
      "Istat": "078109",
      "Comune": "Rota Greca",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "H585",
      "Abitanti": 1197,
      "Link": "http://www.comuni-italiani.it/078/109/"
    },
    {
      "Istat": "044065",
      "Comune": "Rotella",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63071,
      "CodFisco": "H588",
      "Abitanti": 959,
      "Link": "http://www.comuni-italiani.it/044/065/"
    },
    {
      "Istat": "070061",
      "Comune": "Rotello",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "H589",
      "Abitanti": 1257,
      "Link": "http://www.comuni-italiani.it/070/061/"
    },
    {
      "Istat": "076070",
      "Comune": "Rotonda",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85048,
      "CodFisco": "H590",
      "Abitanti": 3584,
      "Link": "http://www.comuni-italiani.it/076/070/"
    },
    {
      "Istat": "077023",
      "Comune": "Rotondella",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75026,
      "CodFisco": "H591",
      "Abitanti": 2846,
      "Link": "http://www.comuni-italiani.it/077/023/"
    },
    {
      "Istat": "064080",
      "Comune": "Rotondi",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 83017,
      "CodFisco": "H592",
      "Abitanti": 3647,
      "Link": "http://www.comuni-italiani.it/064/080/"
    },
    {
      "Istat": "033039",
      "Comune": "Rottofreno",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "H593",
      "Abitanti": 11524,
      "Link": "http://www.comuni-italiani.it/033/039/"
    },
    {
      "Istat": "024089",
      "Comune": "Rotzo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36010,
      "CodFisco": "H594",
      "Abitanti": 633,
      "Link": "http://www.comuni-italiani.it/024/089/"
    },
    {
      "Istat": "001227",
      "Comune": "Roure",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "H555",
      "Abitanti": 897,
      "Link": "http://www.comuni-italiani.it/001/227/"
    },
    {
      "Istat": "097073",
      "Comune": "Rovagnate",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23888,
      "CodFisco": "H596",
      "Abitanti": 2953,
      "Link": "http://www.comuni-italiani.it/097/073/"
    },
    {
      "Istat": "002122",
      "Comune": "Rovasenda",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "H364",
      "Abitanti": 998,
      "Link": "http://www.comuni-italiani.it/002/122/"
    },
    {
      "Istat": "017166",
      "Comune": "Rovato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25038,
      "CodFisco": "H598",
      "Abitanti": 18352,
      "Link": "http://www.comuni-italiani.it/017/166/"
    },
    {
      "Istat": "010052",
      "Comune": "Rovegno",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16028,
      "CodFisco": "H599",
      "Abitanti": 578,
      "Link": "http://www.comuni-italiani.it/010/052/"
    },
    {
      "Istat": "013201",
      "Comune": "Rovellasca",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 22069,
      "CodFisco": "H601",
      "Abitanti": 7608,
      "Link": "http://www.comuni-italiani.it/013/201/"
    },
    {
      "Istat": "013202",
      "Comune": "Rovello Porro",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 22070,
      "CodFisco": "H602",
      "Abitanti": 6105,
      "Link": "http://www.comuni-italiani.it/013/202/"
    },
    {
      "Istat": "020053",
      "Comune": "Roverbella",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46048,
      "CodFisco": "H604",
      "Abitanti": 8649,
      "Link": "http://www.comuni-italiani.it/020/053/"
    },
    {
      "Istat": "023065",
      "Comune": "Roverchiara",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37050,
      "CodFisco": "H606",
      "Abitanti": 2839,
      "Link": "http://www.comuni-italiani.it/023/065/"
    },
    {
      "Istat": "022160",
      "Comune": "RoverË della Luna",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "H607",
      "Abitanti": 1602,
      "Link": "http://www.comuni-italiani.it/022/160/"
    },
    {
      "Istat": "023067",
      "Comune": "RoverË Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37028,
      "CodFisco": "H608",
      "Abitanti": 2206,
      "Link": "http://www.comuni-italiani.it/023/067/"
    },
    {
      "Istat": "023066",
      "Comune": "Roveredo di Gu‡",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "H610",
      "Abitanti": 1546,
      "Link": "http://www.comuni-italiani.it/023/066/"
    },
    {
      "Istat": "093036",
      "Comune": "Roveredo in Piano",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33080,
      "CodFisco": "H609",
      "Abitanti": 5746,
      "Link": "http://www.comuni-italiani.it/093/036/"
    },
    {
      "Istat": "022161",
      "Comune": "Rovereto",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38068,
      "CodFisco": "H612",
      "Abitanti": 38167,
      "Link": "http://www.comuni-italiani.it/022/161/"
    },
    {
      "Istat": "018131",
      "Comune": "Rovescala",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "H614",
      "Abitanti": 951,
      "Link": "http://www.comuni-italiani.it/018/131/"
    },
    {
      "Istat": "016187",
      "Comune": "Rovetta",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "H615",
      "Abitanti": 3966,
      "Link": "http://www.comuni-italiani.it/016/187/"
    },
    {
      "Istat": "058092",
      "Comune": "Roviano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00027",
      "CodFisco": "H618",
      "Abitanti": 1429,
      "Link": "http://www.comuni-italiani.it/058/092/"
    },
    {
      "Istat": "029041",
      "Comune": "Rovigo",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45100,
      "CodFisco": "H620",
      "Abitanti": 52793,
      "Link": "http://www.comuni-italiani.it/029/041/"
    },
    {
      "Istat": "078110",
      "Comune": "Rovito",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "H621",
      "Abitanti": 3213,
      "Link": "http://www.comuni-italiani.it/078/110/"
    },
    {
      "Istat": "028071",
      "Comune": "Rovolon",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "H622",
      "Abitanti": 4787,
      "Link": "http://www.comuni-italiani.it/028/071/"
    },
    {
      "Istat": "015189",
      "Comune": "Rozzano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20089,
      "CodFisco": "H623",
      "Abitanti": 41363,
      "Link": "http://www.comuni-italiani.it/015/189/"
    },
    {
      "Istat": "028072",
      "Comune": "Rubano",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "H625",
      "Abitanti": 15606,
      "Link": "http://www.comuni-italiani.it/028/072/"
    },
    {
      "Istat": "001229",
      "Comune": "Rubiana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "H627",
      "Abitanti": 2399,
      "Link": "http://www.comuni-italiani.it/001/229/"
    },
    {
      "Istat": "035036",
      "Comune": "Rubiera",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42048,
      "CodFisco": "H628",
      "Abitanti": 14559,
      "Link": "http://www.comuni-italiani.it/035/036/"
    },
    {
      "Istat": "030098",
      "Comune": "Ruda",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "H629",
      "Abitanti": 3003,
      "Link": "http://www.comuni-italiani.it/030/098/"
    },
    {
      "Istat": "017167",
      "Comune": "Rudiano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "H630",
      "Abitanti": 5861,
      "Link": "http://www.comuni-italiani.it/017/167/"
    },
    {
      "Istat": "001230",
      "Comune": "Rueglio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H631",
      "Abitanti": 765,
      "Link": "http://www.comuni-italiani.it/001/230/"
    },
    {
      "Istat": "075064",
      "Comune": "Ruffano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73049,
      "CodFisco": "H632",
      "Abitanti": 9724,
      "Link": "http://www.comuni-italiani.it/075/064/"
    },
    {
      "Istat": "004198",
      "Comune": "Ruffia",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "H633",
      "Abitanti": 365,
      "Link": "http://www.comuni-italiani.it/004/198/"
    },
    {
      "Istat": "022162",
      "Comune": "RuffrË-Mendola",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "H634",
      "Abitanti": 416,
      "Link": "http://www.comuni-italiani.it/022/162/"
    },
    {
      "Istat": "048037",
      "Comune": "Rufina",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50068,
      "CodFisco": "H635",
      "Abitanti": 7461,
      "Link": "http://www.comuni-italiani.it/048/037/"
    },
    {
      "Istat": "095044",
      "Comune": "Ruinas",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09085",
      "CodFisco": "F271",
      "Abitanti": 732,
      "Link": "http://www.comuni-italiani.it/095/044/"
    },
    {
      "Istat": "018132",
      "Comune": "Ruino",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "H637",
      "Abitanti": 755,
      "Link": "http://www.comuni-italiani.it/018/132/"
    },
    {
      "Istat": "022163",
      "Comune": "Rumo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38020,
      "CodFisco": "H639",
      "Abitanti": 837,
      "Link": "http://www.comuni-italiani.it/022/163/"
    },
    {
      "Istat": "076071",
      "Comune": "Ruoti",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85056,
      "CodFisco": "H641",
      "Abitanti": 3551,
      "Link": "http://www.comuni-italiani.it/076/071/"
    },
    {
      "Istat": "039016",
      "Comune": "Russi",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0544",
      "CAP": 48026,
      "CodFisco": "H642",
      "Abitanti": 12286,
      "Link": "http://www.comuni-italiani.it/039/016/"
    },
    {
      "Istat": "072037",
      "Comune": "Rutigliano",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70018,
      "CodFisco": "H643",
      "Abitanti": 18108,
      "Link": "http://www.comuni-italiani.it/072/037/"
    },
    {
      "Istat": "065112",
      "Comune": "Rutino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "H644",
      "Abitanti": 895,
      "Link": "http://www.comuni-italiani.it/065/112/"
    },
    {
      "Istat": "061073",
      "Comune": "Ruviano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "H165",
      "Abitanti": 1839,
      "Link": "http://www.comuni-italiani.it/061/073/"
    },
    {
      "Istat": "076072",
      "Comune": "Ruvo del Monte",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85020,
      "CodFisco": "H646",
      "Abitanti": 1113,
      "Link": "http://www.comuni-italiani.it/076/072/"
    },
    {
      "Istat": "072038",
      "Comune": "Ruvo di Puglia",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70037,
      "CodFisco": "H645",
      "Abitanti": 25786,
      "Link": "http://www.comuni-italiani.it/072/038/"
    },
    {
      "Istat": "059024",
      "Comune": "Sabaudia",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04016",
      "CodFisco": "H647",
      "Abitanti": 19664,
      "Link": "http://www.comuni-italiani.it/059/024/"
    },
    {
      "Istat": "002123",
      "Comune": "Sabbia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "H648",
      "Abitanti": 69,
      "Link": "http://www.comuni-italiani.it/002/123/"
    },
    {
      "Istat": "017168",
      "Comune": "Sabbio Chiese",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "H650",
      "Abitanti": 3807,
      "Link": "http://www.comuni-italiani.it/017/168/"
    },
    {
      "Istat": "020054",
      "Comune": "Sabbioneta",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 46018,
      "CodFisco": "H652",
      "Abitanti": 4357,
      "Link": "http://www.comuni-italiani.it/020/054/"
    },
    {
      "Istat": "065113",
      "Comune": "Sacco",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "H654",
      "Abitanti": 582,
      "Link": "http://www.comuni-italiani.it/065/113/"
    },
    {
      "Istat": "028073",
      "Comune": "Saccolongo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "H655",
      "Abitanti": 4916,
      "Link": "http://www.comuni-italiani.it/028/073/"
    },
    {
      "Istat": "093037",
      "Comune": "Sacile",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33077,
      "CodFisco": "H657",
      "Abitanti": 20227,
      "Link": "http://www.comuni-italiani.it/093/037/"
    },
    {
      "Istat": "058093",
      "Comune": "Sacrofano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00060",
      "CodFisco": "H658",
      "Abitanti": 7508,
      "Link": "http://www.comuni-italiani.it/058/093/"
    },
    {
      "Istat": "092119",
      "Comune": "Sadali",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "H659",
      "Abitanti": 928,
      "Link": "http://www.comuni-italiani.it/092/119/"
    },
    {
      "Istat": "095086",
      "Comune": "Sagama",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "H661",
      "Abitanti": 190,
      "Link": "http://www.comuni-italiani.it/095/086/"
    },
    {
      "Istat": "096056",
      "Comune": "Sagliano Micca",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13816,
      "CodFisco": "H662",
      "Abitanti": 1679,
      "Link": "http://www.comuni-italiani.it/096/056/"
    },
    {
      "Istat": "031017",
      "Comune": "Sagrado",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34078,
      "CodFisco": "H665",
      "Abitanti": 2267,
      "Link": "http://www.comuni-italiani.it/031/017/"
    },
    {
      "Istat": "022164",
      "Comune": "Sagron Mis",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38050,
      "CodFisco": "H666",
      "Abitanti": 203,
      "Link": "http://www.comuni-italiani.it/022/164/"
    },
    {
      "Istat": "007058",
      "Comune": "Saint-Christophe",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "H669",
      "Abitanti": 3285,
      "Link": "http://www.comuni-italiani.it/007/058/"
    },
    {
      "Istat": "007059",
      "Comune": "Saint-Denis",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11023,
      "CodFisco": "H670",
      "Abitanti": 369,
      "Link": "http://www.comuni-italiani.it/007/059/"
    },
    {
      "Istat": "007060",
      "Comune": "Saint-Marcel",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11020,
      "CodFisco": "H671",
      "Abitanti": 1262,
      "Link": "http://www.comuni-italiani.it/007/060/"
    },
    {
      "Istat": "007061",
      "Comune": "Saint-Nicolas",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H672",
      "Abitanti": 325,
      "Link": "http://www.comuni-italiani.it/007/061/"
    },
    {
      "Istat": "007062",
      "Comune": "Saint-Oyen",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11014,
      "CodFisco": "H673",
      "Abitanti": 214,
      "Link": "http://www.comuni-italiani.it/007/062/"
    },
    {
      "Istat": "007063",
      "Comune": "Saint-Pierre",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H674",
      "Abitanti": 3162,
      "Link": "http://www.comuni-italiani.it/007/063/"
    },
    {
      "Istat": "007064",
      "Comune": "Saint-RhÈmy-en-Bosses",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "H675",
      "Abitanti": 377,
      "Link": "http://www.comuni-italiani.it/007/064/"
    },
    {
      "Istat": "007065",
      "Comune": "Saint-Vincent",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11027,
      "CodFisco": "H676",
      "Abitanti": 4787,
      "Link": "http://www.comuni-italiani.it/007/065/"
    },
    {
      "Istat": "034031",
      "Comune": "Sala Baganza",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43038,
      "CodFisco": "H682",
      "Abitanti": 5394,
      "Link": "http://www.comuni-italiani.it/034/031/"
    },
    {
      "Istat": "096057",
      "Comune": "Sala Biellese",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13884,
      "CodFisco": "H681",
      "Abitanti": 627,
      "Link": "http://www.comuni-italiani.it/096/057/"
    },
    {
      "Istat": "037050",
      "Comune": "Sala Bolognese",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40010,
      "CodFisco": "H678",
      "Abitanti": 8286,
      "Link": "http://www.comuni-italiani.it/037/050/"
    },
    {
      "Istat": "013203",
      "Comune": "Sala Comacina",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "H679",
      "Abitanti": 598,
      "Link": "http://www.comuni-italiani.it/013/203/"
    },
    {
      "Istat": "065114",
      "Comune": "Sala Consilina",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84036,
      "CodFisco": "H683",
      "Abitanti": 12716,
      "Link": "http://www.comuni-italiani.it/065/114/"
    },
    {
      "Istat": "006150",
      "Comune": "Sala Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "H677",
      "Abitanti": 386,
      "Link": "http://www.comuni-italiani.it/006/150/"
    },
    {
      "Istat": "077024",
      "Comune": "Salandra",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75017,
      "CodFisco": "H687",
      "Abitanti": 2974,
      "Link": "http://www.comuni-italiani.it/077/024/"
    },
    {
      "Istat": "081017",
      "Comune": "Salaparuta",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91020,
      "CodFisco": "H688",
      "Abitanti": 1741,
      "Link": "http://www.comuni-italiani.it/081/017/"
    },
    {
      "Istat": "029042",
      "Comune": "Salara",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "H689",
      "Abitanti": 1203,
      "Link": "http://www.comuni-italiani.it/029/042/"
    },
    {
      "Istat": "002126",
      "Comune": "Salasco",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "H690",
      "Abitanti": 229,
      "Link": "http://www.comuni-italiani.it/002/126/"
    },
    {
      "Istat": "001231",
      "Comune": "Salassa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "H691",
      "Abitanti": 1771,
      "Link": "http://www.comuni-italiani.it/001/231/"
    },
    {
      "Istat": "001232",
      "Comune": "Salbertrand",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "H684",
      "Abitanti": 572,
      "Link": "http://www.comuni-italiani.it/001/232/"
    },
    {
      "Istat": "024090",
      "Comune": "Salcedo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36040,
      "CodFisco": "F810",
      "Abitanti": 1021,
      "Link": "http://www.comuni-italiani.it/024/090/"
    },
    {
      "Istat": "070062",
      "Comune": "Salcito",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86026,
      "CodFisco": "H693",
      "Abitanti": 695,
      "Link": "http://www.comuni-italiani.it/070/062/"
    },
    {
      "Istat": "006151",
      "Comune": "Sale",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15045,
      "CodFisco": "H694",
      "Abitanti": 4297,
      "Link": "http://www.comuni-italiani.it/006/151/"
    },
    {
      "Istat": "004199",
      "Comune": "Sale delle Langhe",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "H695",
      "Abitanti": 524,
      "Link": "http://www.comuni-italiani.it/004/199/"
    },
    {
      "Istat": "017169",
      "Comune": "Sale Marasino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25057,
      "CodFisco": "H699",
      "Abitanti": 3387,
      "Link": "http://www.comuni-italiani.it/017/169/"
    },
    {
      "Istat": "004200",
      "Comune": "Sale San Giovanni",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "H704",
      "Abitanti": 180,
      "Link": "http://www.comuni-italiani.it/004/200/"
    },
    {
      "Istat": "081018",
      "Comune": "Salemi",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91018,
      "CodFisco": "H700",
      "Abitanti": 10998,
      "Link": "http://www.comuni-italiani.it/081/018/"
    },
    {
      "Istat": "065115",
      "Comune": "Salento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "H686",
      "Abitanti": 2046,
      "Link": "http://www.comuni-italiani.it/065/115/"
    },
    {
      "Istat": "001233",
      "Comune": "Salerano Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H702",
      "Abitanti": 543,
      "Link": "http://www.comuni-italiani.it/001/233/"
    },
    {
      "Istat": "098046",
      "Comune": "Salerano sul Lambro",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26857,
      "CodFisco": "H701",
      "Abitanti": 2683,
      "Link": "http://www.comuni-italiani.it/098/046/"
    },
    {
      "Istat": "065116",
      "Comune": "Salerno",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": "841xx",
      "CodFisco": "H703",
      "Abitanti": 139019,
      "Link": "http://www.comuni-italiani.it/065/116/"
    },
    {
      "Istat": "028074",
      "Comune": "Saletto",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35046,
      "CodFisco": "H705",
      "Abitanti": 2751,
      "Link": "http://www.comuni-italiani.it/028/074/"
    },
    {
      "Istat": "026070",
      "Comune": "Salgareda",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31040,
      "CodFisco": "H706",
      "Abitanti": 6692,
      "Link": "http://www.comuni-italiani.it/026/070/"
    },
    {
      "Istat": "002127",
      "Comune": "Sali Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "H707",
      "Abitanti": 120,
      "Link": "http://www.comuni-italiani.it/002/127/"
    },
    {
      "Istat": "075065",
      "Comune": "Salice Salentino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73015,
      "CodFisco": "H708",
      "Abitanti": 8767,
      "Link": "http://www.comuni-italiani.it/075/065/"
    },
    {
      "Istat": "004201",
      "Comune": "Saliceto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12079,
      "CodFisco": "H710",
      "Abitanti": 1403,
      "Link": "http://www.comuni-italiani.it/004/201/"
    },
    {
      "Istat": "057063",
      "Comune": "Salisano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "H713",
      "Abitanti": 565,
      "Link": "http://www.comuni-italiani.it/057/063/"
    },
    {
      "Istat": "023068",
      "Comune": "Salizzole",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37056,
      "CodFisco": "H714",
      "Abitanti": 3746,
      "Link": "http://www.comuni-italiani.it/023/068/"
    },
    {
      "Istat": "068036",
      "Comune": "Salle",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "H715",
      "Abitanti": 323,
      "Link": "http://www.comuni-italiani.it/068/036/"
    },
    {
      "Istat": "004202",
      "Comune": "Salmour",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "H716",
      "Abitanti": 725,
      "Link": "http://www.comuni-italiani.it/004/202/"
    },
    {
      "Istat": "017170",
      "Comune": "SalÚ",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25087,
      "CodFisco": "H717",
      "Abitanti": 10740,
      "Link": "http://www.comuni-italiani.it/017/170/"
    },
    {
      "Istat": "021076",
      "Comune": "Salorno",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "H719",
      "Abitanti": 3536,
      "Link": "http://www.comuni-italiani.it/021/076/"
    },
    {
      "Istat": "034032",
      "Comune": "Salsomaggiore Terme",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43039,
      "CodFisco": "H720",
      "Abitanti": 20051,
      "Link": "http://www.comuni-italiani.it/034/032/"
    },
    {
      "Istat": "041050",
      "Comune": "Saltara",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "H721",
      "Abitanti": 6870,
      "Link": "http://www.comuni-italiani.it/041/050/"
    },
    {
      "Istat": "012117",
      "Comune": "Saltrio",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21050,
      "CodFisco": "H723",
      "Abitanti": 3062,
      "Link": "http://www.comuni-italiani.it/012/117/"
    },
    {
      "Istat": "099015",
      "Comune": "Saludecio",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47835,
      "CodFisco": "H724",
      "Abitanti": 2998,
      "Link": "http://www.comuni-italiani.it/099/015/"
    },
    {
      "Istat": "002128",
      "Comune": "Saluggia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13040,
      "CodFisco": "H725",
      "Abitanti": 4201,
      "Link": "http://www.comuni-italiani.it/002/128/"
    },
    {
      "Istat": "096058",
      "Comune": "Salussola",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13885,
      "CodFisco": "H726",
      "Abitanti": 2085,
      "Link": "http://www.comuni-italiani.it/096/058/"
    },
    {
      "Istat": "004203",
      "Comune": "Saluzzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12037,
      "CodFisco": "H727",
      "Abitanti": 17067,
      "Link": "http://www.comuni-italiani.it/004/203/"
    },
    {
      "Istat": "075066",
      "Comune": "Salve",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73050,
      "CodFisco": "H729",
      "Abitanti": 4708,
      "Link": "http://www.comuni-italiani.it/075/066/"
    },
    {
      "Istat": "019087",
      "Comune": "Salvirola",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "H731",
      "Abitanti": 1176,
      "Link": "http://www.comuni-italiani.it/019/087/"
    },
    {
      "Istat": "065117",
      "Comune": "Salvitelle",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84020,
      "CodFisco": "H732",
      "Abitanti": 600,
      "Link": "http://www.comuni-italiani.it/065/117/"
    },
    {
      "Istat": "001234",
      "Comune": "Salza di Pinerolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "H734",
      "Abitanti": 79,
      "Link": "http://www.comuni-italiani.it/001/234/"
    },
    {
      "Istat": "064081",
      "Comune": "Salza Irpina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "H733",
      "Abitanti": 788,
      "Link": "http://www.comuni-italiani.it/064/081/"
    },
    {
      "Istat": "027032",
      "Comune": "Salzano",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30030,
      "CodFisco": "H735",
      "Abitanti": 12727,
      "Link": "http://www.comuni-italiani.it/027/032/"
    },
    {
      "Istat": "012118",
      "Comune": "Samarate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21017,
      "CodFisco": "H736",
      "Abitanti": 16362,
      "Link": "http://www.comuni-italiani.it/012/118/"
    },
    {
      "Istat": 106013,
      "Comune": "Samassi",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09030",
      "CodFisco": "H738",
      "Abitanti": 5338,
      "Link": "http://www.comuni-italiani.it/106/013/"
    },
    {
      "Istat": "092053",
      "Comune": "Samatzai",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "H739",
      "Abitanti": 1749,
      "Link": "http://www.comuni-italiani.it/092/053/"
    },
    {
      "Istat": "084034",
      "Comune": "Sambuca di Sicilia",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92017,
      "CodFisco": "H743",
      "Abitanti": 6207,
      "Link": "http://www.comuni-italiani.it/084/034/"
    },
    {
      "Istat": "047018",
      "Comune": "Sambuca Pistoiese",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51020,
      "CodFisco": "H744",
      "Abitanti": 1749,
      "Link": "http://www.comuni-italiani.it/047/018/"
    },
    {
      "Istat": "058094",
      "Comune": "Sambuci",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "H745",
      "Abitanti": 966,
      "Link": "http://www.comuni-italiani.it/058/094/"
    },
    {
      "Istat": "004204",
      "Comune": "Sambuco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "H746",
      "Abitanti": 99,
      "Link": "http://www.comuni-italiani.it/004/204/"
    },
    {
      "Istat": "072039",
      "Comune": "Sammichele di Bari",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "H749",
      "Abitanti": 6658,
      "Link": "http://www.comuni-italiani.it/072/039/"
    },
    {
      "Istat": "080070",
      "Comune": "Samo",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "H013",
      "Abitanti": 918,
      "Link": "http://www.comuni-italiani.it/080/070/"
    },
    {
      "Istat": "014057",
      "Comune": "Samolaco",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23027,
      "CodFisco": "H752",
      "Abitanti": 2914,
      "Link": "http://www.comuni-italiani.it/014/057/"
    },
    {
      "Istat": "001235",
      "Comune": "Samone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H753",
      "Abitanti": 1661,
      "Link": "http://www.comuni-italiani.it/001/235/"
    },
    {
      "Istat": "022165",
      "Comune": "Samone",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38059,
      "CodFisco": "H754",
      "Abitanti": 547,
      "Link": "http://www.comuni-italiani.it/022/165/"
    },
    {
      "Istat": "004205",
      "Comune": "Sampeyre",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "H755",
      "Abitanti": 1083,
      "Link": "http://www.comuni-italiani.it/004/205/"
    },
    {
      "Istat": "095045",
      "Comune": "Samugheo",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09086",
      "CodFisco": "H756",
      "Abitanti": 3250,
      "Link": "http://www.comuni-italiani.it/095/045/"
    },
    {
      "Istat": "008052",
      "Comune": "San Bartolomeo al Mare",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18016,
      "CodFisco": "H763",
      "Abitanti": 3150,
      "Link": "http://www.comuni-italiani.it/008/052/"
    },
    {
      "Istat": "062057",
      "Comune": "San Bartolomeo in Galdo",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82028,
      "CodFisco": "H764",
      "Abitanti": 5204,
      "Link": "http://www.comuni-italiani.it/062/057/"
    },
    {
      "Istat": "013204",
      "Comune": "San Bartolomeo Val Cavargna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "H760",
      "Abitanti": 1049,
      "Link": "http://www.comuni-italiani.it/013/204/"
    },
    {
      "Istat": "078111",
      "Comune": "San Basile",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "H765",
      "Abitanti": 1072,
      "Link": "http://www.comuni-italiani.it/078/111/"
    },
    {
      "Istat": "092054",
      "Comune": "San Basilio",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "H766",
      "Abitanti": 1284,
      "Link": "http://www.comuni-italiani.it/092/054/"
    },
    {
      "Istat": "019088",
      "Comune": "San Bassano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "H767",
      "Abitanti": 2227,
      "Link": "http://www.comuni-italiani.it/019/088/"
    },
    {
      "Istat": "029043",
      "Comune": "San Bellino",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "H768",
      "Abitanti": 1206,
      "Link": "http://www.comuni-italiani.it/029/043/"
    },
    {
      "Istat": "004206",
      "Comune": "San Benedetto Belbo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "H770",
      "Abitanti": 196,
      "Link": "http://www.comuni-italiani.it/004/206/"
    },
    {
      "Istat": "066085",
      "Comune": "San Benedetto dei Marsi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67058,
      "CodFisco": "H772",
      "Abitanti": 3977,
      "Link": "http://www.comuni-italiani.it/066/085/"
    },
    {
      "Istat": "044066",
      "Comune": "San Benedetto del Tronto",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0735",
      "CAP": 63074,
      "CodFisco": "H769",
      "Abitanti": 48262,
      "Link": "http://www.comuni-italiani.it/044/066/"
    },
    {
      "Istat": "066086",
      "Comune": "San Benedetto in Perillis",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "H773",
      "Abitanti": 128,
      "Link": "http://www.comuni-italiani.it/066/086/"
    },
    {
      "Istat": "020055",
      "Comune": "San Benedetto Po",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46027,
      "CodFisco": "H771",
      "Abitanti": 7791,
      "Link": "http://www.comuni-italiani.it/020/055/"
    },
    {
      "Istat": "078112",
      "Comune": "San Benedetto Ullano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "H774",
      "Abitanti": 1625,
      "Link": "http://www.comuni-italiani.it/078/112/"
    },
    {
      "Istat": "037051",
      "Comune": "San Benedetto Val di Sambro",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "0534",
      "CAP": 40048,
      "CodFisco": "G566",
      "Abitanti": 4495,
      "Link": "http://www.comuni-italiani.it/037/051/"
    },
    {
      "Istat": "001236",
      "Comune": "San Benigno Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10080,
      "CodFisco": "H775",
      "Abitanti": 5675,
      "Link": "http://www.comuni-italiani.it/001/236/"
    },
    {
      "Istat": 103061,
      "Comune": "San Bernardino Verbano",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28804,
      "CodFisco": "H777",
      "Abitanti": 1380,
      "Link": "http://www.comuni-italiani.it/103/061/"
    },
    {
      "Istat": "008053",
      "Comune": "San Biagio della Cima",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18036,
      "CodFisco": "H780",
      "Abitanti": 1339,
      "Link": "http://www.comuni-italiani.it/008/053/"
    },
    {
      "Istat": "026071",
      "Comune": "San Biagio di Callalta",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31048,
      "CodFisco": "H781",
      "Abitanti": 13279,
      "Link": "http://www.comuni-italiani.it/026/071/"
    },
    {
      "Istat": "084035",
      "Comune": "San Biagio Platani",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "H778",
      "Abitanti": 3547,
      "Link": "http://www.comuni-italiani.it/084/035/"
    },
    {
      "Istat": "060061",
      "Comune": "San Biagio Saracinisco",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "H779",
      "Abitanti": 364,
      "Link": "http://www.comuni-italiani.it/060/061/"
    },
    {
      "Istat": "070063",
      "Comune": "San Biase",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "H782",
      "Abitanti": 212,
      "Link": "http://www.comuni-italiani.it/070/063/"
    },
    {
      "Istat": "023069",
      "Comune": "San Bonifacio",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37047,
      "CodFisco": "H783",
      "Abitanti": 20774,
      "Link": "http://www.comuni-italiani.it/023/069/"
    },
    {
      "Istat": "069079",
      "Comune": "San Buono",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "H784",
      "Abitanti": 1042,
      "Link": "http://www.comuni-italiani.it/069/079/"
    },
    {
      "Istat": 102032,
      "Comune": "San Calogero",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89842,
      "CodFisco": "H785",
      "Abitanti": 4565,
      "Link": "http://www.comuni-italiani.it/102/032/"
    },
    {
      "Istat": "021077",
      "Comune": "San Candido",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39038,
      "CodFisco": "H786",
      "Abitanti": 3172,
      "Link": "http://www.comuni-italiani.it/021/077/"
    },
    {
      "Istat": "031018",
      "Comune": "San Canzian d'Isonzo",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34075,
      "CodFisco": "H787",
      "Abitanti": 6383,
      "Link": "http://www.comuni-italiani.it/031/018/"
    },
    {
      "Istat": "001237",
      "Comune": "San Carlo Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "H789",
      "Abitanti": 3943,
      "Link": "http://www.comuni-italiani.it/001/237/"
    },
    {
      "Istat": "052027",
      "Comune": "San Casciano dei Bagni",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53040,
      "CodFisco": "H790",
      "Abitanti": 1703,
      "Link": "http://www.comuni-italiani.it/052/027/"
    },
    {
      "Istat": "048038",
      "Comune": "San Casciano in Val di Pesa",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50026,
      "CodFisco": "H791",
      "Abitanti": 17216,
      "Link": "http://www.comuni-italiani.it/048/038/"
    },
    {
      "Istat": "075095",
      "Comune": "San Cassiano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "M264",
      "Abitanti": 2126,
      "Link": "http://www.comuni-italiani.it/075/095/"
    },
    {
      "Istat": "085016",
      "Comune": "San Cataldo",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93017,
      "CodFisco": "H792",
      "Abitanti": 23318,
      "Link": "http://www.comuni-italiani.it/085/016/"
    },
    {
      "Istat": "058119",
      "Comune": "San Cesareo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "M295",
      "Abitanti": 14175,
      "Link": "http://www.comuni-italiani.it/058/119/"
    },
    {
      "Istat": "075068",
      "Comune": "San Cesario di Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73016,
      "CodFisco": "H793",
      "Abitanti": 8398,
      "Link": "http://www.comuni-italiani.it/075/068/"
    },
    {
      "Istat": "036036",
      "Comune": "San Cesario sul Panaro",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41018,
      "CodFisco": "H794",
      "Abitanti": 6057,
      "Link": "http://www.comuni-italiani.it/036/036/"
    },
    {
      "Istat": "076073",
      "Comune": "San Chirico Nuovo",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "H795",
      "Abitanti": 1495,
      "Link": "http://www.comuni-italiani.it/076/073/"
    },
    {
      "Istat": "076074",
      "Comune": "San Chirico Raparo",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "H796",
      "Abitanti": 1177,
      "Link": "http://www.comuni-italiani.it/076/074/"
    },
    {
      "Istat": "082063",
      "Comune": "San Cipirello",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "H797",
      "Abitanti": 5473,
      "Link": "http://www.comuni-italiani.it/082/063/"
    },
    {
      "Istat": "061074",
      "Comune": "San Cipriano d'Aversa",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81036,
      "CodFisco": "H798",
      "Abitanti": 13085,
      "Link": "http://www.comuni-italiani.it/061/074/"
    },
    {
      "Istat": "065118",
      "Comune": "San Cipriano Picentino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84099,
      "CodFisco": "H800",
      "Abitanti": 6721,
      "Link": "http://www.comuni-italiani.it/065/118/"
    },
    {
      "Istat": "018133",
      "Comune": "San Cipriano Po",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27043,
      "CodFisco": "H799",
      "Abitanti": 503,
      "Link": "http://www.comuni-italiani.it/018/133/"
    },
    {
      "Istat": "099016",
      "Comune": "San Clemente",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47832,
      "CodFisco": "H801",
      "Abitanti": 5153,
      "Link": "http://www.comuni-italiani.it/099/016/"
    },
    {
      "Istat": "015191",
      "Comune": "San Colombano al Lambro",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 20078,
      "CodFisco": "H803",
      "Abitanti": 7468,
      "Link": "http://www.comuni-italiani.it/015/191/"
    },
    {
      "Istat": "001238",
      "Comune": "San Colombano Belmonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "H804",
      "Abitanti": 375,
      "Link": "http://www.comuni-italiani.it/001/238/"
    },
    {
      "Istat": "010053",
      "Comune": "San Colombano Certenoli",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16040,
      "CodFisco": "H802",
      "Abitanti": 2667,
      "Link": "http://www.comuni-italiani.it/010/053/"
    },
    {
      "Istat": "087040",
      "Comune": "San Cono",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95040,
      "CodFisco": "H805",
      "Abitanti": 2883,
      "Link": "http://www.comuni-italiani.it/087/040/"
    },
    {
      "Istat": "078113",
      "Comune": "San Cosmo Albanese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "H806",
      "Abitanti": 641,
      "Link": "http://www.comuni-italiani.it/078/113/"
    },
    {
      "Istat": "076075",
      "Comune": "San Costantino Albanese",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "H808",
      "Abitanti": 805,
      "Link": "http://www.comuni-italiani.it/076/075/"
    },
    {
      "Istat": 102033,
      "Comune": "San Costantino Calabro",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89851,
      "CodFisco": "H807",
      "Abitanti": 2303,
      "Link": "http://www.comuni-italiani.it/102/033/"
    },
    {
      "Istat": "041051",
      "Comune": "San Costanzo",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61039,
      "CodFisco": "H809",
      "Abitanti": 4976,
      "Link": "http://www.comuni-italiani.it/041/051/"
    },
    {
      "Istat": "006152",
      "Comune": "San Cristoforo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "H810",
      "Abitanti": 604,
      "Link": "http://www.comuni-italiani.it/006/152/"
    },
    {
      "Istat": "018134",
      "Comune": "San Damiano al Colle",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "H814",
      "Abitanti": 740,
      "Link": "http://www.comuni-italiani.it/018/134/"
    },
    {
      "Istat": "005097",
      "Comune": "San Damiano d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14015,
      "CodFisco": "H811",
      "Abitanti": 8401,
      "Link": "http://www.comuni-italiani.it/005/097/"
    },
    {
      "Istat": "004207",
      "Comune": "San Damiano Macra",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12029,
      "CodFisco": "H812",
      "Abitanti": 451,
      "Link": "http://www.comuni-italiani.it/004/207/"
    },
    {
      "Istat": "030099",
      "Comune": "San Daniele del Friuli",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33038,
      "CodFisco": "H816",
      "Abitanti": 8210,
      "Link": "http://www.comuni-italiani.it/030/099/"
    },
    {
      "Istat": "019089",
      "Comune": "San Daniele Po",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26046,
      "CodFisco": "H815",
      "Abitanti": 1472,
      "Link": "http://www.comuni-italiani.it/019/089/"
    },
    {
      "Istat": "078114",
      "Comune": "San Demetrio Corone",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87069,
      "CodFisco": "H818",
      "Abitanti": 3693,
      "Link": "http://www.comuni-italiani.it/078/114/"
    },
    {
      "Istat": "066087",
      "Comune": "San Demetrio ne' Vestini",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67028,
      "CodFisco": "H819",
      "Abitanti": 1839,
      "Link": "http://www.comuni-italiani.it/066/087/"
    },
    {
      "Istat": "001239",
      "Comune": "San Didero",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "H820",
      "Abitanti": 574,
      "Link": "http://www.comuni-italiani.it/001/239/"
    },
    {
      "Istat": "027033",
      "Comune": "San Don‡ di Piave",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30027,
      "CodFisco": "H823",
      "Abitanti": 41592,
      "Link": "http://www.comuni-italiani.it/027/033/"
    },
    {
      "Istat": "074013",
      "Comune": "San Donaci",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72025,
      "CodFisco": "H822",
      "Abitanti": 7050,
      "Link": "http://www.comuni-italiani.it/074/013/"
    },
    {
      "Istat": "075069",
      "Comune": "San Donato di Lecce",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "H826",
      "Abitanti": 5871,
      "Link": "http://www.comuni-italiani.it/075/069/"
    },
    {
      "Istat": "078115",
      "Comune": "San Donato di Ninea",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "H825",
      "Abitanti": 1514,
      "Link": "http://www.comuni-italiani.it/078/115/"
    },
    {
      "Istat": "015192",
      "Comune": "San Donato Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20097,
      "CodFisco": "H827",
      "Abitanti": 32702,
      "Link": "http://www.comuni-italiani.it/015/192/"
    },
    {
      "Istat": "060062",
      "Comune": "San Donato Val di Comino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03046",
      "CodFisco": "H824",
      "Abitanti": 2133,
      "Link": "http://www.comuni-italiani.it/060/062/"
    },
    {
      "Istat": "032004",
      "Comune": "San Dorligo della Valle - Dolina",
      "Provincia": "TS",
      "Regione": "FVG",
      "Prefisso": "040",
      "CAP": 34018,
      "CodFisco": "D324",
      "Abitanti": 5913,
      "Link": "http://www.comuni-italiani.it/032/004/"
    },
    {
      "Istat": "013205",
      "Comune": "San Fedele Intelvi",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22028,
      "CodFisco": "H830",
      "Abitanti": 1778,
      "Link": "http://www.comuni-italiani.it/013/205/"
    },
    {
      "Istat": "076076",
      "Comune": "San Fele",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0976",
      "CAP": 85020,
      "CodFisco": "H831",
      "Abitanti": 3273,
      "Link": "http://www.comuni-italiani.it/076/076/"
    },
    {
      "Istat": "061075",
      "Comune": "San Felice a Cancello",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81027,
      "CodFisco": "H834",
      "Abitanti": 17675,
      "Link": "http://www.comuni-italiani.it/061/075/"
    },
    {
      "Istat": "059025",
      "Comune": "San Felice Circeo",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04017",
      "CodFisco": "H836",
      "Abitanti": 8603,
      "Link": "http://www.comuni-italiani.it/059/025/"
    },
    {
      "Istat": "017171",
      "Comune": "San Felice del Benaco",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25010,
      "CodFisco": "H838",
      "Abitanti": 3424,
      "Link": "http://www.comuni-italiani.it/017/171/"
    },
    {
      "Istat": "070064",
      "Comune": "San Felice del Molise",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86030,
      "CodFisco": "H833",
      "Abitanti": 708,
      "Link": "http://www.comuni-italiani.it/070/064/"
    },
    {
      "Istat": "036037",
      "Comune": "San Felice sul Panaro",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41038,
      "CodFisco": "H835",
      "Abitanti": 11135,
      "Link": "http://www.comuni-italiani.it/036/037/"
    },
    {
      "Istat": "080097",
      "Comune": "San Ferdinando",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89026,
      "CodFisco": "M277",
      "Abitanti": 4435,
      "Link": "http://www.comuni-italiani.it/080/097/"
    },
    {
      "Istat": 110007,
      "Comune": "San Ferdinando di Puglia",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76017,
      "CodFisco": "H839",
      "Abitanti": 14894,
      "Link": "http://www.comuni-italiani.it/110/007/"
    },
    {
      "Istat": "013206",
      "Comune": "San Fermo della Battaglia",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "H840",
      "Abitanti": 4489,
      "Link": "http://www.comuni-italiani.it/013/206/"
    },
    {
      "Istat": "078116",
      "Comune": "San Fili",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87037,
      "CodFisco": "H841",
      "Abitanti": 2800,
      "Link": "http://www.comuni-italiani.it/078/116/"
    },
    {
      "Istat": "083077",
      "Comune": "San Filippo del Mela",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98044,
      "CodFisco": "H842",
      "Abitanti": 7291,
      "Link": "http://www.comuni-italiani.it/083/077/"
    },
    {
      "Istat": "026072",
      "Comune": "San Fior",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "H843",
      "Abitanti": 6940,
      "Link": "http://www.comuni-italiani.it/026/072/"
    },
    {
      "Istat": "098047",
      "Comune": "San Fiorano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26848,
      "CodFisco": "H844",
      "Abitanti": 1811,
      "Link": "http://www.comuni-italiani.it/098/047/"
    },
    {
      "Istat": "031019",
      "Comune": "San Floriano del Collio",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "H845",
      "Abitanti": 811,
      "Link": "http://www.comuni-italiani.it/031/019/"
    },
    {
      "Istat": "079108",
      "Comune": "San Floro",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88021,
      "CodFisco": "H846",
      "Abitanti": 713,
      "Link": "http://www.comuni-italiani.it/079/108/"
    },
    {
      "Istat": "001240",
      "Comune": "San Francesco al Campo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "H847",
      "Abitanti": 4877,
      "Link": "http://www.comuni-italiani.it/001/240/"
    },
    {
      "Istat": "083078",
      "Comune": "San Fratello",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98075,
      "CodFisco": "H850",
      "Abitanti": 4003,
      "Link": "http://www.comuni-italiani.it/083/078/"
    },
    {
      "Istat": 106014,
      "Comune": "San Gavino Monreale",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09037",
      "CodFisco": "H856",
      "Abitanti": 8960,
      "Link": "http://www.comuni-italiani.it/106/014/"
    },
    {
      "Istat": "055029",
      "Comune": "San Gemini",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05029",
      "CodFisco": "H857",
      "Abitanti": 4947,
      "Link": "http://www.comuni-italiani.it/055/029/"
    },
    {
      "Istat": "021079",
      "Comune": "San Genesio Atesino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39050,
      "CodFisco": "H858",
      "Abitanti": 2939,
      "Link": "http://www.comuni-italiani.it/021/079/"
    },
    {
      "Istat": "018135",
      "Comune": "San Genesio ed Uniti",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "H859",
      "Abitanti": 3791,
      "Link": "http://www.comuni-italiani.it/018/135/"
    },
    {
      "Istat": "063066",
      "Comune": "San Gennaro Vesuviano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "H860",
      "Abitanti": 11235,
      "Link": "http://www.comuni-italiani.it/063/066/"
    },
    {
      "Istat": "001242",
      "Comune": "San Germano Chisone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10065,
      "CodFisco": "H862",
      "Abitanti": 1878,
      "Link": "http://www.comuni-italiani.it/001/242/"
    },
    {
      "Istat": "024092",
      "Comune": "San Germano dei Berici",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "H863",
      "Abitanti": 1166,
      "Link": "http://www.comuni-italiani.it/024/092/"
    },
    {
      "Istat": "002131",
      "Comune": "San Germano Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13047,
      "CodFisco": "H861",
      "Abitanti": 1784,
      "Link": "http://www.comuni-italiani.it/002/131/"
    },
    {
      "Istat": "017172",
      "Comune": "San Gervasio Bresciano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "H865",
      "Abitanti": 2470,
      "Link": "http://www.comuni-italiani.it/017/172/"
    },
    {
      "Istat": "070065",
      "Comune": "San Giacomo degli Schiavoni",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86030,
      "CodFisco": "H867",
      "Abitanti": 1428,
      "Link": "http://www.comuni-italiani.it/070/065/"
    },
    {
      "Istat": "020056",
      "Comune": "San Giacomo delle Segnate",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46020,
      "CodFisco": "H870",
      "Abitanti": 1774,
      "Link": "http://www.comuni-italiani.it/020/056/"
    },
    {
      "Istat": "014058",
      "Comune": "San Giacomo Filippo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "H868",
      "Abitanti": 415,
      "Link": "http://www.comuni-italiani.it/014/058/"
    },
    {
      "Istat": "002035",
      "Comune": "San Giacomo Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "B952",
      "Abitanti": 330,
      "Link": "http://www.comuni-italiani.it/002/035/"
    },
    {
      "Istat": "001243",
      "Comune": "San Gillio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "H873",
      "Abitanti": 3035,
      "Link": "http://www.comuni-italiani.it/001/243/"
    },
    {
      "Istat": "052028",
      "Comune": "San Gimignano",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53037,
      "CodFisco": "H875",
      "Abitanti": 7806,
      "Link": "http://www.comuni-italiani.it/052/028/"
    },
    {
      "Istat": "043046",
      "Comune": "San Ginesio",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62026,
      "CodFisco": "H876",
      "Abitanti": 3773,
      "Link": "http://www.comuni-italiani.it/043/046/"
    },
    {
      "Istat": "063067",
      "Comune": "San Giorgio a Cremano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80046,
      "CodFisco": "H892",
      "Abitanti": 47244,
      "Link": "http://www.comuni-italiani.it/063/067/"
    },
    {
      "Istat": "060063",
      "Comune": "San Giorgio a Liri",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03047",
      "CodFisco": "H880",
      "Abitanti": 3168,
      "Link": "http://www.comuni-italiani.it/060/063/"
    },
    {
      "Istat": "078118",
      "Comune": "San Giorgio Albanese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "H881",
      "Abitanti": 1579,
      "Link": "http://www.comuni-italiani.it/078/118/"
    },
    {
      "Istat": "001244",
      "Comune": "San Giorgio Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10090,
      "CodFisco": "H890",
      "Abitanti": 2721,
      "Link": "http://www.comuni-italiani.it/001/244/"
    },
    {
      "Istat": "062058",
      "Comune": "San Giorgio del Sannio",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82018,
      "CodFisco": "H894",
      "Abitanti": 9902,
      "Link": "http://www.comuni-italiani.it/062/058/"
    },
    {
      "Istat": "093038",
      "Comune": "San Giorgio della Richinvelda",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33095,
      "CodFisco": "H891",
      "Abitanti": 4758,
      "Link": "http://www.comuni-italiani.it/093/038/"
    },
    {
      "Istat": "028075",
      "Comune": "San Giorgio delle Pertiche",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "H893",
      "Abitanti": 10029,
      "Link": "http://www.comuni-italiani.it/028/075/"
    },
    {
      "Istat": "018136",
      "Comune": "San Giorgio di Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "H885",
      "Abitanti": 1189,
      "Link": "http://www.comuni-italiani.it/018/136/"
    },
    {
      "Istat": "020057",
      "Comune": "San Giorgio di Mantova",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46030,
      "CodFisco": "H883",
      "Abitanti": 9482,
      "Link": "http://www.comuni-italiani.it/020/057/"
    },
    {
      "Istat": "030100",
      "Comune": "San Giorgio di Nogaro",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33058,
      "CodFisco": "H895",
      "Abitanti": 7755,
      "Link": "http://www.comuni-italiani.it/030/100/"
    },
    {
      "Istat": "041052",
      "Comune": "San Giorgio di Pesaro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "H886",
      "Abitanti": 1449,
      "Link": "http://www.comuni-italiani.it/041/052/"
    },
    {
      "Istat": "037052",
      "Comune": "San Giorgio di Piano",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40016,
      "CodFisco": "H896",
      "Abitanti": 8289,
      "Link": "http://www.comuni-italiani.it/037/052/"
    },
    {
      "Istat": "028076",
      "Comune": "San Giorgio in Bosco",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "H897",
      "Abitanti": 6289,
      "Link": "http://www.comuni-italiani.it/028/076/"
    },
    {
      "Istat": "073024",
      "Comune": "San Giorgio Ionico",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74027,
      "CodFisco": "H882",
      "Abitanti": 15992,
      "Link": "http://www.comuni-italiani.it/073/024/"
    },
    {
      "Istat": "062059",
      "Comune": "San Giorgio La Molara",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "H898",
      "Abitanti": 3069,
      "Link": "http://www.comuni-italiani.it/062/059/"
    },
    {
      "Istat": "077025",
      "Comune": "San Giorgio Lucano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75027,
      "CodFisco": "H888",
      "Abitanti": 1334,
      "Link": "http://www.comuni-italiani.it/077/025/"
    },
    {
      "Istat": "006153",
      "Comune": "San Giorgio Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "H878",
      "Abitanti": 1290,
      "Link": "http://www.comuni-italiani.it/006/153/"
    },
    {
      "Istat": "080071",
      "Comune": "San Giorgio Morgeto",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89017,
      "CodFisco": "H889",
      "Abitanti": 3271,
      "Link": "http://www.comuni-italiani.it/080/071/"
    },
    {
      "Istat": "033040",
      "Comune": "San Giorgio Piacentino",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29019,
      "CodFisco": "H887",
      "Abitanti": 5859,
      "Link": "http://www.comuni-italiani.it/033/040/"
    },
    {
      "Istat": "005098",
      "Comune": "San Giorgio Scarampi",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14059,
      "CodFisco": "H899",
      "Abitanti": 123,
      "Link": "http://www.comuni-italiani.it/005/098/"
    },
    {
      "Istat": "015194",
      "Comune": "San Giorgio su Legnano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20010,
      "CodFisco": "H884",
      "Abitanti": 6776,
      "Link": "http://www.comuni-italiani.it/015/194/"
    },
    {
      "Istat": "001245",
      "Comune": "San Giorio di Susa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "H900",
      "Abitanti": 1041,
      "Link": "http://www.comuni-italiani.it/001/245/"
    },
    {
      "Istat": "065119",
      "Comune": "San Giovanni a Piro",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "H907",
      "Abitanti": 3868,
      "Link": "http://www.comuni-italiani.it/065/119/"
    },
    {
      "Istat": "030101",
      "Comune": "San Giovanni al Natisone",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33048,
      "CodFisco": "H906",
      "Abitanti": 6192,
      "Link": "http://www.comuni-italiani.it/030/101/"
    },
    {
      "Istat": "016188",
      "Comune": "San Giovanni Bianco",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24015,
      "CodFisco": "H910",
      "Abitanti": 5094,
      "Link": "http://www.comuni-italiani.it/016/188/"
    },
    {
      "Istat": "052029",
      "Comune": "San Giovanni d'Asso",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53020,
      "CodFisco": "H911",
      "Abitanti": 912,
      "Link": "http://www.comuni-italiani.it/052/029/"
    },
    {
      "Istat": "020058",
      "Comune": "San Giovanni del Dosso",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "H912",
      "Abitanti": 1389,
      "Link": "http://www.comuni-italiani.it/020/058/"
    },
    {
      "Istat": "080072",
      "Comune": "San Giovanni di Gerace",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "H903",
      "Abitanti": 552,
      "Link": "http://www.comuni-italiani.it/080/072/"
    },
    {
      "Istat": "084036",
      "Comune": "San Giovanni Gemini",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "H914",
      "Abitanti": 8159,
      "Link": "http://www.comuni-italiani.it/084/036/"
    },
    {
      "Istat": "023070",
      "Comune": "San Giovanni Ilarione",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37035,
      "CodFisco": "H916",
      "Abitanti": 5183,
      "Link": "http://www.comuni-italiani.it/023/070/"
    },
    {
      "Istat": "019090",
      "Comune": "San Giovanni in Croce",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26037,
      "CodFisco": "H918",
      "Abitanti": 1880,
      "Link": "http://www.comuni-italiani.it/019/090/"
    },
    {
      "Istat": "078119",
      "Comune": "San Giovanni in Fiore",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87055,
      "CodFisco": "H919",
      "Abitanti": 18049,
      "Link": "http://www.comuni-italiani.it/078/119/"
    },
    {
      "Istat": "070066",
      "Comune": "San Giovanni in Galdo",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "H920",
      "Abitanti": 639,
      "Link": "http://www.comuni-italiani.it/070/066/"
    },
    {
      "Istat": "099017",
      "Comune": "San Giovanni in Marignano",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47842,
      "CodFisco": "H921",
      "Abitanti": 9090,
      "Link": "http://www.comuni-italiani.it/099/017/"
    },
    {
      "Istat": "037053",
      "Comune": "San Giovanni in Persiceto",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40017,
      "CodFisco": "G467",
      "Abitanti": 27227,
      "Link": "http://www.comuni-italiani.it/037/053/"
    },
    {
      "Istat": "060064",
      "Comune": "San Giovanni Incarico",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03028",
      "CodFisco": "H917",
      "Abitanti": 3395,
      "Link": "http://www.comuni-italiani.it/060/064/"
    },
    {
      "Istat": "087041",
      "Comune": "San Giovanni la Punta",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95037,
      "CodFisco": "H922",
      "Abitanti": 22490,
      "Link": "http://www.comuni-italiani.it/087/041/"
    },
    {
      "Istat": "069080",
      "Comune": "San Giovanni Lipioni",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "H923",
      "Abitanti": 218,
      "Link": "http://www.comuni-italiani.it/069/080/"
    },
    {
      "Istat": "023071",
      "Comune": "San Giovanni Lupatoto",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37057,
      "CodFisco": "H924",
      "Abitanti": 24214,
      "Link": "http://www.comuni-italiani.it/023/071/"
    },
    {
      "Istat": "071046",
      "Comune": "San Giovanni Rotondo",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71013,
      "CodFisco": "H926",
      "Abitanti": 27327,
      "Link": "http://www.comuni-italiani.it/071/046/"
    },
    {
      "Istat": 107017,
      "Comune": "San Giovanni Suergiu",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "G287",
      "Abitanti": 6040,
      "Link": "http://www.comuni-italiani.it/107/017/"
    },
    {
      "Istat": "069081",
      "Comune": "San Giovanni Teatino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 66020,
      "CodFisco": "D690",
      "Abitanti": 12489,
      "Link": "http://www.comuni-italiani.it/069/081/"
    },
    {
      "Istat": "051033",
      "Comune": "San Giovanni Valdarno",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52027,
      "CodFisco": "H901",
      "Abitanti": 17118,
      "Link": "http://www.comuni-italiani.it/051/033/"
    },
    {
      "Istat": "070067",
      "Comune": "San Giuliano del Sannio",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "H928",
      "Abitanti": 1065,
      "Link": "http://www.comuni-italiani.it/070/067/"
    },
    {
      "Istat": "070068",
      "Comune": "San Giuliano di Puglia",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86040,
      "CodFisco": "H929",
      "Abitanti": 1094,
      "Link": "http://www.comuni-italiani.it/070/068/"
    },
    {
      "Istat": "015195",
      "Comune": "San Giuliano Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20098,
      "CodFisco": "H930",
      "Abitanti": 36871,
      "Link": "http://www.comuni-italiani.it/015/195/"
    },
    {
      "Istat": "050031",
      "Comune": "San Giuliano Terme",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56017,
      "CodFisco": "A562",
      "Abitanti": 31822,
      "Link": "http://www.comuni-italiani.it/050/031/"
    },
    {
      "Istat": "082064",
      "Comune": "San Giuseppe Jato",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90048,
      "CodFisco": "H933",
      "Abitanti": 8799,
      "Link": "http://www.comuni-italiani.it/082/064/"
    },
    {
      "Istat": "063068",
      "Comune": "San Giuseppe Vesuviano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80047,
      "CodFisco": "H931",
      "Abitanti": 28084,
      "Link": "http://www.comuni-italiani.it/063/068/"
    },
    {
      "Istat": "054044",
      "Comune": "San Giustino",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06016",
      "CodFisco": "H935",
      "Abitanti": 11428,
      "Link": "http://www.comuni-italiani.it/054/044/"
    },
    {
      "Istat": "001246",
      "Comune": "San Giusto Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10090,
      "CodFisco": "H936",
      "Abitanti": 3383,
      "Link": "http://www.comuni-italiani.it/001/246/"
    },
    {
      "Istat": "048039",
      "Comune": "San Godenzo",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50060,
      "CodFisco": "H937",
      "Abitanti": 1258,
      "Link": "http://www.comuni-italiani.it/048/039/"
    },
    {
      "Istat": "058095",
      "Comune": "San Gregorio da Sassola",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "H942",
      "Abitanti": 1578,
      "Link": "http://www.comuni-italiani.it/058/095/"
    },
    {
      "Istat": "087042",
      "Comune": "San Gregorio di Catania",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95027,
      "CodFisco": "H940",
      "Abitanti": 11604,
      "Link": "http://www.comuni-italiani.it/087/042/"
    },
    {
      "Istat": 102034,
      "Comune": "San Gregorio d'Ippona",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89853,
      "CodFisco": "H941",
      "Abitanti": 2272,
      "Link": "http://www.comuni-italiani.it/102/034/"
    },
    {
      "Istat": "065120",
      "Comune": "San Gregorio Magno",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "H943",
      "Abitanti": 4457,
      "Link": "http://www.comuni-italiani.it/065/120/"
    },
    {
      "Istat": "061076",
      "Comune": "San Gregorio Matese",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "H939",
      "Abitanti": 986,
      "Link": "http://www.comuni-italiani.it/061/076/"
    },
    {
      "Istat": "025045",
      "Comune": "San Gregorio nelle Alpi",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32030,
      "CodFisco": "H938",
      "Abitanti": 1616,
      "Link": "http://www.comuni-italiani.it/025/045/"
    },
    {
      "Istat": "037054",
      "Comune": "San Lazzaro di Savena",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40068,
      "CodFisco": "H945",
      "Abitanti": 31457,
      "Link": "http://www.comuni-italiani.it/037/054/"
    },
    {
      "Istat": "099025",
      "Comune": "San Leo",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47865,
      "CodFisco": "H949",
      "Abitanti": 3074,
      "Link": "http://www.comuni-italiani.it/099/025/"
    },
    {
      "Istat": "030102",
      "Comune": "San Leonardo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "H951",
      "Abitanti": 1210,
      "Link": "http://www.comuni-italiani.it/030/102/"
    },
    {
      "Istat": "021080",
      "Comune": "San Leonardo in Passiria",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39015,
      "CodFisco": "H952",
      "Abitanti": 3542,
      "Link": "http://www.comuni-italiani.it/021/080/"
    },
    {
      "Istat": "062060",
      "Comune": "San Leucio del Sannio",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82010,
      "CodFisco": "H953",
      "Abitanti": 3207,
      "Link": "http://www.comuni-italiani.it/062/060/"
    },
    {
      "Istat": "062061",
      "Comune": "San Lorenzello",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "H955",
      "Abitanti": 2364,
      "Link": "http://www.comuni-italiani.it/062/061/"
    },
    {
      "Istat": "080073",
      "Comune": "San Lorenzo",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89069,
      "CodFisco": "H959",
      "Abitanti": 2857,
      "Link": "http://www.comuni-italiani.it/080/073/"
    },
    {
      "Istat": "008054",
      "Comune": "San Lorenzo al Mare",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18017,
      "CodFisco": "H957",
      "Abitanti": 1384,
      "Link": "http://www.comuni-italiani.it/008/054/"
    },
    {
      "Istat": "078120",
      "Comune": "San Lorenzo Bellizzi",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87070,
      "CodFisco": "H961",
      "Abitanti": 765,
      "Link": "http://www.comuni-italiani.it/078/120/"
    },
    {
      "Istat": "078121",
      "Comune": "San Lorenzo del Vallo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87040,
      "CodFisco": "H962",
      "Abitanti": 3515,
      "Link": "http://www.comuni-italiani.it/078/121/"
    },
    {
      "Istat": "021081",
      "Comune": "San Lorenzo di Sebato",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "H956",
      "Abitanti": 3756,
      "Link": "http://www.comuni-italiani.it/021/081/"
    },
    {
      "Istat": "022166",
      "Comune": "San Lorenzo in Banale",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38078,
      "CodFisco": "H966",
      "Abitanti": 1183,
      "Link": "http://www.comuni-italiani.it/022/166/"
    },
    {
      "Istat": "041054",
      "Comune": "San Lorenzo in Campo",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61047,
      "CodFisco": "H958",
      "Abitanti": 3551,
      "Link": "http://www.comuni-italiani.it/041/054/"
    },
    {
      "Istat": "031020",
      "Comune": "San Lorenzo Isontino",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "H964",
      "Abitanti": 1558,
      "Link": "http://www.comuni-italiani.it/031/020/"
    },
    {
      "Istat": "062062",
      "Comune": "San Lorenzo Maggiore",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82034,
      "CodFisco": "H967",
      "Abitanti": 2213,
      "Link": "http://www.comuni-italiani.it/062/062/"
    },
    {
      "Istat": "056047",
      "Comune": "San Lorenzo Nuovo",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0763",
      "CAP": "01020",
      "CodFisco": "H969",
      "Abitanti": 2193,
      "Link": "http://www.comuni-italiani.it/056/047/"
    },
    {
      "Istat": "080074",
      "Comune": "San Luca",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "H970",
      "Abitanti": 4088,
      "Link": "http://www.comuni-italiani.it/080/074/"
    },
    {
      "Istat": "078122",
      "Comune": "San Lucido",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87038,
      "CodFisco": "H971",
      "Abitanti": 6008,
      "Link": "http://www.comuni-italiani.it/078/122/"
    },
    {
      "Istat": "062063",
      "Comune": "San Lupo",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82034,
      "CodFisco": "H973",
      "Abitanti": 886,
      "Link": "http://www.comuni-italiani.it/062/063/"
    },
    {
      "Istat": "079110",
      "Comune": "San Mango d'Aquino",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "H976",
      "Abitanti": 1746,
      "Link": "http://www.comuni-italiani.it/079/110/"
    },
    {
      "Istat": "065121",
      "Comune": "San Mango Piemonte",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84090,
      "CodFisco": "H977",
      "Abitanti": 2644,
      "Link": "http://www.comuni-italiani.it/065/121/"
    },
    {
      "Istat": "064082",
      "Comune": "San Mango sul Calore",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "H975",
      "Abitanti": 1210,
      "Link": "http://www.comuni-italiani.it/064/082/"
    },
    {
      "Istat": "061077",
      "Comune": "San Marcellino",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "H978",
      "Abitanti": 13308,
      "Link": "http://www.comuni-italiani.it/061/077/"
    },
    {
      "Istat": "042041",
      "Comune": "San Marcello",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "H979",
      "Abitanti": 2093,
      "Link": "http://www.comuni-italiani.it/042/041/"
    },
    {
      "Istat": "047019",
      "Comune": "San Marcello Pistoiese",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51028,
      "CodFisco": "H980",
      "Abitanti": 6818,
      "Link": "http://www.comuni-italiani.it/047/019/"
    },
    {
      "Istat": "078123",
      "Comune": "San Marco Argentano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87018,
      "CodFisco": "H981",
      "Abitanti": 7635,
      "Link": "http://www.comuni-italiani.it/078/123/"
    },
    {
      "Istat": "083079",
      "Comune": "San Marco D'Alunzio",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "H982",
      "Abitanti": 2082,
      "Link": "http://www.comuni-italiani.it/083/079/"
    },
    {
      "Istat": "062064",
      "Comune": "San Marco dei Cavoti",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82029,
      "CodFisco": "H984",
      "Abitanti": 3596,
      "Link": "http://www.comuni-italiani.it/062/064/"
    },
    {
      "Istat": "061104",
      "Comune": "San Marco Evangelista",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "F043",
      "Abitanti": 6483,
      "Link": "http://www.comuni-italiani.it/061/104/"
    },
    {
      "Istat": "071047",
      "Comune": "San Marco in Lamis",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71014,
      "CodFisco": "H985",
      "Abitanti": 14444,
      "Link": "http://www.comuni-italiani.it/071/047/"
    },
    {
      "Istat": "071048",
      "Comune": "San Marco la Catola",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71030,
      "CodFisco": "H986",
      "Abitanti": 1108,
      "Link": "http://www.comuni-italiani.it/071/048/"
    },
    {
      "Istat": "093039",
      "Comune": "San Martino al Tagliamento",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33098,
      "CodFisco": "H999",
      "Abitanti": 1556,
      "Link": "http://www.comuni-italiani.it/093/039/"
    },
    {
      "Istat": "005099",
      "Comune": "San Martino Alfieri",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "H987",
      "Abitanti": 712,
      "Link": "http://www.comuni-italiani.it/005/099/"
    },
    {
      "Istat": "023073",
      "Comune": "San Martino Buon Albergo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37036,
      "CodFisco": "I003",
      "Abitanti": 14295,
      "Link": "http://www.comuni-italiani.it/023/073/"
    },
    {
      "Istat": "001247",
      "Comune": "San Martino Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "H997",
      "Abitanti": 865,
      "Link": "http://www.comuni-italiani.it/001/247/"
    },
    {
      "Istat": "076077",
      "Comune": "San Martino d'Agri",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "H994",
      "Abitanti": 850,
      "Link": "http://www.comuni-italiani.it/076/077/"
    },
    {
      "Istat": "020059",
      "Comune": "San Martino dall'Argine",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46010,
      "CodFisco": "I005",
      "Abitanti": 1829,
      "Link": "http://www.comuni-italiani.it/020/059/"
    },
    {
      "Istat": "019091",
      "Comune": "San Martino del Lago",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26040,
      "CodFisco": "I007",
      "Abitanti": 489,
      "Link": "http://www.comuni-italiani.it/019/091/"
    },
    {
      "Istat": "078124",
      "Comune": "San Martino di Finita",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "H992",
      "Abitanti": 1216,
      "Link": "http://www.comuni-italiani.it/078/124/"
    },
    {
      "Istat": "028077",
      "Comune": "San Martino di Lupari",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35018,
      "CodFisco": "I008",
      "Abitanti": 13233,
      "Link": "http://www.comuni-italiani.it/028/077/"
    },
    {
      "Istat": "029044",
      "Comune": "San Martino di Venezze",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "H996",
      "Abitanti": 4046,
      "Link": "http://www.comuni-italiani.it/029/044/"
    },
    {
      "Istat": "021082",
      "Comune": "San Martino in Badia",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "H988",
      "Abitanti": 1726,
      "Link": "http://www.comuni-italiani.it/021/082/"
    },
    {
      "Istat": "021083",
      "Comune": "San Martino in Passiria",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "H989",
      "Abitanti": 3143,
      "Link": "http://www.comuni-italiani.it/021/083/"
    },
    {
      "Istat": "070069",
      "Comune": "San Martino in Pensilis",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86046,
      "CodFisco": "H990",
      "Abitanti": 4877,
      "Link": "http://www.comuni-italiani.it/070/069/"
    },
    {
      "Istat": "035037",
      "Comune": "San Martino in Rio",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42018,
      "CodFisco": "I011",
      "Abitanti": 8010,
      "Link": "http://www.comuni-italiani.it/035/037/"
    },
    {
      "Istat": "098048",
      "Comune": "San Martino in Strada",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26817,
      "CodFisco": "I012",
      "Abitanti": 3624,
      "Link": "http://www.comuni-italiani.it/098/048/"
    },
    {
      "Istat": "062065",
      "Comune": "San Martino Sannita",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82010,
      "CodFisco": "I002",
      "Abitanti": 1287,
      "Link": "http://www.comuni-italiani.it/062/065/"
    },
    {
      "Istat": "018137",
      "Comune": "San Martino Siccomario",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27028,
      "CodFisco": "I014",
      "Abitanti": 5766,
      "Link": "http://www.comuni-italiani.it/018/137/"
    },
    {
      "Istat": "069082",
      "Comune": "San Martino sulla Marrucina",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "H991",
      "Abitanti": 1025,
      "Link": "http://www.comuni-italiani.it/069/082/"
    },
    {
      "Istat": "064083",
      "Comune": "San Martino Valle Caudina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 83018,
      "CodFisco": "I016",
      "Abitanti": 4730,
      "Link": "http://www.comuni-italiani.it/064/083/"
    },
    {
      "Istat": "073025",
      "Comune": "San Marzano di San Giuseppe",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "I018",
      "Abitanti": 9284,
      "Link": "http://www.comuni-italiani.it/073/025/"
    },
    {
      "Istat": "005100",
      "Comune": "San Marzano Oliveto",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14050,
      "CodFisco": "I017",
      "Abitanti": 1075,
      "Link": "http://www.comuni-italiani.it/005/100/"
    },
    {
      "Istat": "065122",
      "Comune": "San Marzano sul Sarno",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84010,
      "CodFisco": "I019",
      "Abitanti": 10205,
      "Link": "http://www.comuni-italiani.it/065/122/"
    },
    {
      "Istat": "070070",
      "Comune": "San Massimo",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86027,
      "CodFisco": "I023",
      "Abitanti": 818,
      "Link": "http://www.comuni-italiani.it/070/070/"
    },
    {
      "Istat": "001248",
      "Comune": "San Maurizio Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10077,
      "CodFisco": "I024",
      "Abitanti": 9763,
      "Link": "http://www.comuni-italiani.it/001/248/"
    },
    {
      "Istat": "003133",
      "Comune": "San Maurizio d'Opaglio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28017,
      "CodFisco": "I025",
      "Abitanti": 3199,
      "Link": "http://www.comuni-italiani.it/003/133/"
    },
    {
      "Istat": "082065",
      "Comune": "San Mauro Castelverde",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90010,
      "CodFisco": "I028",
      "Abitanti": 1896,
      "Link": "http://www.comuni-italiani.it/082/065/"
    },
    {
      "Istat": "065123",
      "Comune": "San Mauro Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "I031",
      "Abitanti": 982,
      "Link": "http://www.comuni-italiani.it/065/123/"
    },
    {
      "Istat": "023074",
      "Comune": "San Mauro di Saline",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "H712",
      "Abitanti": 573,
      "Link": "http://www.comuni-italiani.it/023/074/"
    },
    {
      "Istat": "077026",
      "Comune": "San Mauro Forte",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75010,
      "CodFisco": "I029",
      "Abitanti": 1718,
      "Link": "http://www.comuni-italiani.it/077/026/"
    },
    {
      "Istat": "065124",
      "Comune": "San Mauro La Bruca",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "I032",
      "Abitanti": 676,
      "Link": "http://www.comuni-italiani.it/065/124/"
    },
    {
      "Istat": 101020,
      "Comune": "San Mauro Marchesato",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88831,
      "CodFisco": "I026",
      "Abitanti": 2246,
      "Link": "http://www.comuni-italiani.it/101/020/"
    },
    {
      "Istat": "040041",
      "Comune": "San Mauro Pascoli",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47030,
      "CodFisco": "I027",
      "Abitanti": 11106,
      "Link": "http://www.comuni-italiani.it/040/041/"
    },
    {
      "Istat": "001249",
      "Comune": "San Mauro Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10099,
      "CodFisco": "I030",
      "Abitanti": 19311,
      "Link": "http://www.comuni-italiani.it/001/249/"
    },
    {
      "Istat": "027034",
      "Comune": "San Michele al Tagliamento",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0431",
      "CAP": 30028,
      "CodFisco": "I040",
      "Abitanti": 12103,
      "Link": "http://www.comuni-italiani.it/027/034/"
    },
    {
      "Istat": "022167",
      "Comune": "San Michele all'Adige",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "I042",
      "Abitanti": 2875,
      "Link": "http://www.comuni-italiani.it/022/167/"
    },
    {
      "Istat": "087043",
      "Comune": "San Michele di Ganzaria",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95040,
      "CodFisco": "I035",
      "Abitanti": 3580,
      "Link": "http://www.comuni-italiani.it/087/043/"
    },
    {
      "Istat": "064084",
      "Comune": "San Michele di Serino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83020,
      "CodFisco": "I034",
      "Abitanti": 2602,
      "Link": "http://www.comuni-italiani.it/064/084/"
    },
    {
      "Istat": "004210",
      "Comune": "San Michele MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "I037",
      "Abitanti": 2072,
      "Link": "http://www.comuni-italiani.it/004/210/"
    },
    {
      "Istat": "074014",
      "Comune": "San Michele Salentino",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72018,
      "CodFisco": "I045",
      "Abitanti": 6420,
      "Link": "http://www.comuni-italiani.it/074/014/"
    },
    {
      "Istat": "050032",
      "Comune": "San Miniato",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 56028,
      "CodFisco": "I046",
      "Abitanti": 28257,
      "Link": "http://www.comuni-italiani.it/050/032/"
    },
    {
      "Istat": "024093",
      "Comune": "San Nazario",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36020,
      "CodFisco": "I047",
      "Abitanti": 1786,
      "Link": "http://www.comuni-italiani.it/024/093/"
    },
    {
      "Istat": "062066",
      "Comune": "San Nazzaro",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82018,
      "CodFisco": "I049",
      "Abitanti": 934,
      "Link": "http://www.comuni-italiani.it/062/066/"
    },
    {
      "Istat": "003134",
      "Comune": "San Nazzaro Sesia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "I052",
      "Abitanti": 702,
      "Link": "http://www.comuni-italiani.it/003/134/"
    },
    {
      "Istat": "013207",
      "Comune": "San Nazzaro Val Cavargna",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "I051",
      "Abitanti": 348,
      "Link": "http://www.comuni-italiani.it/013/207/"
    },
    {
      "Istat": "071049",
      "Comune": "San Nicandro Garganico",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71015,
      "CodFisco": "I054",
      "Abitanti": 16054,
      "Link": "http://www.comuni-italiani.it/071/049/"
    },
    {
      "Istat": "078125",
      "Comune": "San Nicola Arcella",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "I060",
      "Abitanti": 1804,
      "Link": "http://www.comuni-italiani.it/078/125/"
    },
    {
      "Istat": "064085",
      "Comune": "San Nicola Baronia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "I061",
      "Abitanti": 793,
      "Link": "http://www.comuni-italiani.it/064/085/"
    },
    {
      "Istat": 102035,
      "Comune": "San Nicola da Crissa",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89821,
      "CodFisco": "I058",
      "Abitanti": 1437,
      "Link": "http://www.comuni-italiani.it/102/035/"
    },
    {
      "Istat": 101021,
      "Comune": "San Nicola dell'Alto",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88817,
      "CodFisco": "I057",
      "Abitanti": 916,
      "Link": "http://www.comuni-italiani.it/101/021/"
    },
    {
      "Istat": "061078",
      "Comune": "San Nicola la Strada",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "I056",
      "Abitanti": 21746,
      "Link": "http://www.comuni-italiani.it/061/078/"
    },
    {
      "Istat": "062067",
      "Comune": "San Nicola Manfredi",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82010,
      "CodFisco": "I062",
      "Abitanti": 3642,
      "Link": "http://www.comuni-italiani.it/062/067/"
    },
    {
      "Istat": "095046",
      "Comune": "San NicolÚ d'Arcidano",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09097",
      "CodFisco": "A368",
      "Abitanti": 2837,
      "Link": "http://www.comuni-italiani.it/095/046/"
    },
    {
      "Istat": "025046",
      "Comune": "San NicolÚ di Comelico",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "I063",
      "Abitanti": 407,
      "Link": "http://www.comuni-italiani.it/025/046/"
    },
    {
      "Istat": "092058",
      "Comune": "San NicolÚ Gerrei",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "G383",
      "Abitanti": 881,
      "Link": "http://www.comuni-italiani.it/092/058/"
    },
    {
      "Istat": "021084",
      "Comune": "San Pancrazio",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "I065",
      "Abitanti": 1588,
      "Link": "http://www.comuni-italiani.it/021/084/"
    },
    {
      "Istat": "074015",
      "Comune": "San Pancrazio Salentino",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72026,
      "CodFisco": "I066",
      "Abitanti": 10342,
      "Link": "http://www.comuni-italiani.it/074/015/"
    },
    {
      "Istat": "017138",
      "Comune": "San Paolo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "G407",
      "Abitanti": 4553,
      "Link": "http://www.comuni-italiani.it/017/138/"
    },
    {
      "Istat": "076020",
      "Comune": "San Paolo Albanese",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "B906",
      "Abitanti": 327,
      "Link": "http://www.comuni-italiani.it/076/020/"
    },
    {
      "Istat": "063069",
      "Comune": "San Paolo Bel Sito",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "I073",
      "Abitanti": 3546,
      "Link": "http://www.comuni-italiani.it/063/069/"
    },
    {
      "Istat": "096060",
      "Comune": "San Paolo Cervo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13812,
      "CodFisco": "I074",
      "Abitanti": 147,
      "Link": "http://www.comuni-italiani.it/096/060/"
    },
    {
      "Istat": "016189",
      "Comune": "San Paolo d'Argon",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "B310",
      "Abitanti": 5435,
      "Link": "http://www.comuni-italiani.it/016/189/"
    },
    {
      "Istat": "071050",
      "Comune": "San Paolo di Civitate",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71010,
      "CodFisco": "I072",
      "Abitanti": 6018,
      "Link": "http://www.comuni-italiani.it/071/050/"
    },
    {
      "Istat": "042042",
      "Comune": "San Paolo di Jesi",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60038,
      "CodFisco": "I071",
      "Abitanti": 916,
      "Link": "http://www.comuni-italiani.it/042/042/"
    },
    {
      "Istat": "005101",
      "Comune": "San Paolo Solbrito",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "I076",
      "Abitanti": 1231,
      "Link": "http://www.comuni-italiani.it/005/101/"
    },
    {
      "Istat": "016190",
      "Comune": "San Pellegrino Terme",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24016,
      "CodFisco": "I079",
      "Abitanti": 4974,
      "Link": "http://www.comuni-italiani.it/016/190/"
    },
    {
      "Istat": "031021",
      "Comune": "San Pier d'Isonzo",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "I082",
      "Abitanti": 2017,
      "Link": "http://www.comuni-italiani.it/031/021/"
    },
    {
      "Istat": "083080",
      "Comune": "San Pier Niceto",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98045,
      "CodFisco": "I084",
      "Abitanti": 2976,
      "Link": "http://www.comuni-italiani.it/083/080/"
    },
    {
      "Istat": "048040",
      "Comune": "San Piero a Sieve",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50037,
      "CodFisco": "I085",
      "Abitanti": 4305,
      "Link": "http://www.comuni-italiani.it/048/040/"
    },
    {
      "Istat": "083081",
      "Comune": "San Piero Patti",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98068,
      "CodFisco": "I086",
      "Abitanti": 3136,
      "Link": "http://www.comuni-italiani.it/083/081/"
    },
    {
      "Istat": "079114",
      "Comune": "San Pietro a Maida",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88025,
      "CodFisco": "I093",
      "Abitanti": 4296,
      "Link": "http://www.comuni-italiani.it/079/114/"
    },
    {
      "Istat": "030103",
      "Comune": "San Pietro al Natisone",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33049,
      "CodFisco": "I092",
      "Abitanti": 2207,
      "Link": "http://www.comuni-italiani.it/030/103/"
    },
    {
      "Istat": "065125",
      "Comune": "San Pietro al Tanagro",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "I089",
      "Abitanti": 1733,
      "Link": "http://www.comuni-italiani.it/065/125/"
    },
    {
      "Istat": "079115",
      "Comune": "San Pietro Apostolo",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88040,
      "CodFisco": "I095",
      "Abitanti": 1769,
      "Link": "http://www.comuni-italiani.it/079/115/"
    },
    {
      "Istat": "094043",
      "Comune": "San Pietro Avellana",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86088,
      "CodFisco": "I096",
      "Abitanti": 539,
      "Link": "http://www.comuni-italiani.it/094/043/"
    },
    {
      "Istat": "087044",
      "Comune": "San Pietro Clarenza",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "I098",
      "Abitanti": 7160,
      "Link": "http://www.comuni-italiani.it/087/044/"
    },
    {
      "Istat": "025047",
      "Comune": "San Pietro di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "I088",
      "Abitanti": 1719,
      "Link": "http://www.comuni-italiani.it/025/047/"
    },
    {
      "Istat": "080075",
      "Comune": "San Pietro di Carid‡",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "I102",
      "Abitanti": 1326,
      "Link": "http://www.comuni-italiani.it/080/075/"
    },
    {
      "Istat": "026073",
      "Comune": "San Pietro di Feletto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "I103",
      "Abitanti": 5416,
      "Link": "http://www.comuni-italiani.it/026/073/"
    },
    {
      "Istat": "023075",
      "Comune": "San Pietro di Morubio",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37050,
      "CodFisco": "I105",
      "Abitanti": 3024,
      "Link": "http://www.comuni-italiani.it/023/075/"
    },
    {
      "Istat": "078126",
      "Comune": "San Pietro in Amantea",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87030,
      "CodFisco": "I108",
      "Abitanti": 540,
      "Link": "http://www.comuni-italiani.it/078/126/"
    },
    {
      "Istat": "023076",
      "Comune": "San Pietro in Cariano",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37029,
      "CodFisco": "I109",
      "Abitanti": 13110,
      "Link": "http://www.comuni-italiani.it/023/076/"
    },
    {
      "Istat": "037055",
      "Comune": "San Pietro in Casale",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40018,
      "CodFisco": "I110",
      "Abitanti": 11815,
      "Link": "http://www.comuni-italiani.it/037/055/"
    },
    {
      "Istat": "033041",
      "Comune": "San Pietro in Cerro",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "G788",
      "Abitanti": 932,
      "Link": "http://www.comuni-italiani.it/033/041/"
    },
    {
      "Istat": "028078",
      "Comune": "San Pietro in Gu",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "I107",
      "Abitanti": 4623,
      "Link": "http://www.comuni-italiani.it/028/078/"
    },
    {
      "Istat": "078127",
      "Comune": "San Pietro in Guarano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87047,
      "CodFisco": "I114",
      "Abitanti": 3684,
      "Link": "http://www.comuni-italiani.it/078/127/"
    },
    {
      "Istat": "075071",
      "Comune": "San Pietro in Lama",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "I115",
      "Abitanti": 3628,
      "Link": "http://www.comuni-italiani.it/075/071/"
    },
    {
      "Istat": "061079",
      "Comune": "San Pietro Infine",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81049,
      "CodFisco": "I113",
      "Abitanti": 988,
      "Link": "http://www.comuni-italiani.it/061/079/"
    },
    {
      "Istat": "003135",
      "Comune": "San Pietro Mosezzo",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "I116",
      "Abitanti": 1992,
      "Link": "http://www.comuni-italiani.it/003/135/"
    },
    {
      "Istat": "024094",
      "Comune": "San Pietro Mussolino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36070,
      "CodFisco": "I117",
      "Abitanti": 1619,
      "Link": "http://www.comuni-italiani.it/024/094/"
    },
    {
      "Istat": "001250",
      "Comune": "San Pietro Val Lemina",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "I090",
      "Abitanti": 1471,
      "Link": "http://www.comuni-italiani.it/001/250/"
    },
    {
      "Istat": "074016",
      "Comune": "San Pietro Vernotico",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72027,
      "CodFisco": "I119",
      "Abitanti": 14380,
      "Link": "http://www.comuni-italiani.it/074/016/"
    },
    {
      "Istat": "028079",
      "Comune": "San Pietro Viminario",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35020,
      "CodFisco": "I120",
      "Abitanti": 3000,
      "Link": "http://www.comuni-italiani.it/028/079/"
    },
    {
      "Istat": "066088",
      "Comune": "San Pio delle Camere",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "I121",
      "Abitanti": 634,
      "Link": "http://www.comuni-italiani.it/066/088/"
    },
    {
      "Istat": "058096",
      "Comune": "San Polo dei Cavalieri",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "I125",
      "Abitanti": 2973,
      "Link": "http://www.comuni-italiani.it/058/096/"
    },
    {
      "Istat": "035038",
      "Comune": "San Polo d'Enza",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42020,
      "CodFisco": "I123",
      "Abitanti": 5858,
      "Link": "http://www.comuni-italiani.it/035/038/"
    },
    {
      "Istat": "026074",
      "Comune": "San Polo di Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31020,
      "CodFisco": "I124",
      "Abitanti": 5021,
      "Link": "http://www.comuni-italiani.it/026/074/"
    },
    {
      "Istat": "070071",
      "Comune": "San Polo Matese",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "I122",
      "Abitanti": 478,
      "Link": "http://www.comuni-italiani.it/070/071/"
    },
    {
      "Istat": "001251",
      "Comune": "San Ponso",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "I126",
      "Abitanti": 285,
      "Link": "http://www.comuni-italiani.it/001/251/"
    },
    {
      "Istat": "036038",
      "Comune": "San Possidonio",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0535",
      "CAP": 41039,
      "CodFisco": "I128",
      "Abitanti": 3828,
      "Link": "http://www.comuni-italiani.it/036/038/"
    },
    {
      "Istat": "061080",
      "Comune": "San Potito Sannitico",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81016,
      "CodFisco": "I130",
      "Abitanti": 2011,
      "Link": "http://www.comuni-italiani.it/061/080/"
    },
    {
      "Istat": "064086",
      "Comune": "San Potito Ultra",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "I129",
      "Abitanti": 1603,
      "Link": "http://www.comuni-italiani.it/064/086/"
    },
    {
      "Istat": "061081",
      "Comune": "San Prisco",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81054,
      "CodFisco": "I131",
      "Abitanti": 12196,
      "Link": "http://www.comuni-italiani.it/061/081/"
    },
    {
      "Istat": "080076",
      "Comune": "San Procopio",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "I132",
      "Abitanti": 563,
      "Link": "http://www.comuni-italiani.it/080/076/"
    },
    {
      "Istat": "036039",
      "Comune": "San Prospero",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41030,
      "CodFisco": "I133",
      "Abitanti": 5888,
      "Link": "http://www.comuni-italiani.it/036/039/"
    },
    {
      "Istat": "052030",
      "Comune": "San Quirico d'Orcia",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53027,
      "CodFisco": "I135",
      "Abitanti": 2774,
      "Link": "http://www.comuni-italiani.it/052/030/"
    },
    {
      "Istat": "093040",
      "Comune": "San Quirino",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33080,
      "CodFisco": "I136",
      "Abitanti": 4310,
      "Link": "http://www.comuni-italiani.it/093/040/"
    },
    {
      "Istat": "001252",
      "Comune": "San Raffaele Cimena",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "I137",
      "Abitanti": 3122,
      "Link": "http://www.comuni-italiani.it/001/252/"
    },
    {
      "Istat": "080077",
      "Comune": "San Roberto",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89050,
      "CodFisco": "I139",
      "Abitanti": 1912,
      "Link": "http://www.comuni-italiani.it/080/077/"
    },
    {
      "Istat": "098049",
      "Comune": "San Rocco al Porto",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26865,
      "CodFisco": "I140",
      "Abitanti": 3578,
      "Link": "http://www.comuni-italiani.it/098/049/"
    },
    {
      "Istat": "046027",
      "Comune": "San Romano in Garfagnana",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55038,
      "CodFisco": "I142",
      "Abitanti": 1486,
      "Link": "http://www.comuni-italiani.it/046/027/"
    },
    {
      "Istat": "065126",
      "Comune": "San Rufo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "I143",
      "Abitanti": 1760,
      "Link": "http://www.comuni-italiani.it/065/126/"
    },
    {
      "Istat": "083082",
      "Comune": "San Salvatore di Fitalia",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "I147",
      "Abitanti": 1424,
      "Link": "http://www.comuni-italiani.it/083/082/"
    },
    {
      "Istat": "006154",
      "Comune": "San Salvatore Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15046,
      "CodFisco": "I144",
      "Abitanti": 4478,
      "Link": "http://www.comuni-italiani.it/006/154/"
    },
    {
      "Istat": "062068",
      "Comune": "San Salvatore Telesino",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "I145",
      "Abitanti": 4080,
      "Link": "http://www.comuni-italiani.it/062/068/"
    },
    {
      "Istat": "069083",
      "Comune": "San Salvo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "I148",
      "Abitanti": 19401,
      "Link": "http://www.comuni-italiani.it/069/083/"
    },
    {
      "Istat": "063070",
      "Comune": "San Sebastiano al Vesuvio",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "I151",
      "Abitanti": 9561,
      "Link": "http://www.comuni-italiani.it/063/070/"
    },
    {
      "Istat": "006155",
      "Comune": "San Sebastiano Curone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15056,
      "CodFisco": "I150",
      "Abitanti": 632,
      "Link": "http://www.comuni-italiani.it/006/155/"
    },
    {
      "Istat": "001253",
      "Comune": "San Sebastiano da Po",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10020,
      "CodFisco": "I152",
      "Abitanti": 1920,
      "Link": "http://www.comuni-italiani.it/001/253/"
    },
    {
      "Istat": "001254",
      "Comune": "San Secondo di Pinerolo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "I154",
      "Abitanti": 3615,
      "Link": "http://www.comuni-italiani.it/001/254/"
    },
    {
      "Istat": "034033",
      "Comune": "San Secondo Parmense",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43017,
      "CodFisco": "I153",
      "Abitanti": 5648,
      "Link": "http://www.comuni-italiani.it/034/033/"
    },
    {
      "Istat": "076078",
      "Comune": "San Severino Lucano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "I157",
      "Abitanti": 1711,
      "Link": "http://www.comuni-italiani.it/076/078/"
    },
    {
      "Istat": "043047",
      "Comune": "San Severino Marche",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62027,
      "CodFisco": "I156",
      "Abitanti": 13169,
      "Link": "http://www.comuni-italiani.it/043/047/"
    },
    {
      "Istat": "071051",
      "Comune": "San Severo",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71016,
      "CodFisco": "I158",
      "Abitanti": 55321,
      "Link": "http://www.comuni-italiani.it/071/051/"
    },
    {
      "Istat": "013248",
      "Comune": "San Siro",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "I162",
      "Abitanti": 1804,
      "Link": "http://www.comuni-italiani.it/013/248/"
    },
    {
      "Istat": "064087",
      "Comune": "San Sossio Baronia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "I163",
      "Abitanti": 1738,
      "Link": "http://www.comuni-italiani.it/064/087/"
    },
    {
      "Istat": "079116",
      "Comune": "San Sostene",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "I164",
      "Abitanti": 1347,
      "Link": "http://www.comuni-italiani.it/079/116/"
    },
    {
      "Istat": "078128",
      "Comune": "San Sosti",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "I165",
      "Abitanti": 2156,
      "Link": "http://www.comuni-italiani.it/078/128/"
    },
    {
      "Istat": "092059",
      "Comune": "San Sperate",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09026",
      "CodFisco": "I166",
      "Abitanti": 7881,
      "Link": "http://www.comuni-italiani.it/092/059/"
    },
    {
      "Istat": "061085",
      "Comune": "San Tammaro",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "I261",
      "Abitanti": 5115,
      "Link": "http://www.comuni-italiani.it/061/085/"
    },
    {
      "Istat": "083090",
      "Comune": "San Teodoro",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 98030,
      "CodFisco": "I328",
      "Abitanti": 1426,
      "Link": "http://www.comuni-italiani.it/083/090/"
    },
    {
      "Istat": 104023,
      "Comune": "San Teodoro",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "I329",
      "Abitanti": 4342,
      "Link": "http://www.comuni-italiani.it/104/023/"
    },
    {
      "Istat": "025049",
      "Comune": "San Tomaso Agordino",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "I347",
      "Abitanti": 696,
      "Link": "http://www.comuni-italiani.it/025/049/"
    },
    {
      "Istat": "068038",
      "Comune": "San Valentino in Abruzzo Citeriore",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "I376",
      "Abitanti": 1949,
      "Link": "http://www.comuni-italiani.it/068/038/"
    },
    {
      "Istat": "065132",
      "Comune": "San Valentino Torio",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84010,
      "CodFisco": "I377",
      "Abitanti": 10313,
      "Link": "http://www.comuni-italiani.it/065/132/"
    },
    {
      "Istat": "055030",
      "Comune": "San Venanzo",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "05010",
      "CodFisco": "I381",
      "Abitanti": 2370,
      "Link": "http://www.comuni-italiani.it/055/030/"
    },
    {
      "Istat": "026076",
      "Comune": "San Vendemiano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "I382",
      "Abitanti": 10091,
      "Link": "http://www.comuni-italiani.it/026/076/"
    },
    {
      "Istat": "095050",
      "Comune": "San Vero Milis",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "I384",
      "Abitanti": 2547,
      "Link": "http://www.comuni-italiani.it/095/050/"
    },
    {
      "Istat": "049018",
      "Comune": "San Vincenzo",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57027,
      "CodFisco": "I390",
      "Abitanti": 7000,
      "Link": "http://www.comuni-italiani.it/049/018/"
    },
    {
      "Istat": "078135",
      "Comune": "San Vincenzo la Costa",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87030,
      "CodFisco": "I388",
      "Abitanti": 2199,
      "Link": "http://www.comuni-italiani.it/078/135/"
    },
    {
      "Istat": "066092",
      "Comune": "San Vincenzo Valle Roveto",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "I389",
      "Abitanti": 2460,
      "Link": "http://www.comuni-italiani.it/066/092/"
    },
    {
      "Istat": "063075",
      "Comune": "San Vitaliano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "I391",
      "Abitanti": 6189,
      "Link": "http://www.comuni-italiani.it/063/075/"
    },
    {
      "Istat": "092064",
      "Comune": "San Vito",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I402",
      "Abitanti": 3860,
      "Link": "http://www.comuni-italiani.it/092/064/"
    },
    {
      "Istat": "093041",
      "Comune": "San Vito al Tagliamento",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33078,
      "CodFisco": "I403",
      "Abitanti": 15015,
      "Link": "http://www.comuni-italiani.it/093/041/"
    },
    {
      "Istat": "030105",
      "Comune": "San Vito al Torre",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "I404",
      "Abitanti": 1358,
      "Link": "http://www.comuni-italiani.it/030/105/"
    },
    {
      "Istat": "069086",
      "Comune": "San Vito Chietino",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66038,
      "CodFisco": "I394",
      "Abitanti": 5321,
      "Link": "http://www.comuni-italiani.it/069/086/"
    },
    {
      "Istat": "074017",
      "Comune": "San Vito dei Normanni",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72019,
      "CodFisco": "I396",
      "Abitanti": 19801,
      "Link": "http://www.comuni-italiani.it/074/017/"
    },
    {
      "Istat": "025051",
      "Comune": "San Vito di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0436",
      "CAP": 32046,
      "CodFisco": "I392",
      "Abitanti": 1827,
      "Link": "http://www.comuni-italiani.it/025/051/"
    },
    {
      "Istat": "030106",
      "Comune": "San Vito di Fagagna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "I405",
      "Abitanti": 1692,
      "Link": "http://www.comuni-italiani.it/030/106/"
    },
    {
      "Istat": "024096",
      "Comune": "San Vito di Leguzzano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "I401",
      "Abitanti": 3579,
      "Link": "http://www.comuni-italiani.it/024/096/"
    },
    {
      "Istat": "081020",
      "Comune": "San Vito Lo Capo",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91010,
      "CodFisco": "I407",
      "Abitanti": 4366,
      "Link": "http://www.comuni-italiani.it/081/020/"
    },
    {
      "Istat": "058100",
      "Comune": "San Vito Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00030",
      "CodFisco": "I400",
      "Abitanti": 3429,
      "Link": "http://www.comuni-italiani.it/058/100/"
    },
    {
      "Istat": "079122",
      "Comune": "San Vito sullo Ionio",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88067,
      "CodFisco": "I393",
      "Abitanti": 1858,
      "Link": "http://www.comuni-italiani.it/079/122/"
    },
    {
      "Istat": "060070",
      "Comune": "San Vittore del Lazio",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "I408",
      "Abitanti": 2735,
      "Link": "http://www.comuni-italiani.it/060/070/"
    },
    {
      "Istat": "015201",
      "Comune": "San Vittore Olona",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20028,
      "CodFisco": "I409",
      "Abitanti": 8285,
      "Link": "http://www.comuni-italiani.it/015/201/"
    },
    {
      "Istat": "023079",
      "Comune": "San Zeno di Montagna",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "I414",
      "Abitanti": 1363,
      "Link": "http://www.comuni-italiani.it/023/079/"
    },
    {
      "Istat": "017173",
      "Comune": "San Zeno Naviglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "I412",
      "Abitanti": 4671,
      "Link": "http://www.comuni-italiani.it/017/173/"
    },
    {
      "Istat": "015202",
      "Comune": "San Zenone al Lambro",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20070,
      "CodFisco": "I415",
      "Abitanti": 4193,
      "Link": "http://www.comuni-italiani.it/015/202/"
    },
    {
      "Istat": "018145",
      "Comune": "San Zenone al Po",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "I416",
      "Abitanti": 625,
      "Link": "http://www.comuni-italiani.it/018/145/"
    },
    {
      "Istat": "026077",
      "Comune": "San Zenone degli Ezzelini",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31020,
      "CodFisco": "I417",
      "Abitanti": 7459,
      "Link": "http://www.comuni-italiani.it/026/077/"
    },
    {
      "Istat": "075067",
      "Comune": "Sanarica",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73030,
      "CodFisco": "H757",
      "Abitanti": 1490,
      "Link": "http://www.comuni-italiani.it/075/067/"
    },
    {
      "Istat": "096059",
      "Comune": "Sandigliano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13876,
      "CodFisco": "H821",
      "Abitanti": 2731,
      "Link": "http://www.comuni-italiani.it/096/059/"
    },
    {
      "Istat": "024091",
      "Comune": "Sandrigo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36066,
      "CodFisco": "H829",
      "Abitanti": 8665,
      "Link": "http://www.comuni-italiani.it/024/091/"
    },
    {
      "Istat": "004208",
      "Comune": "SanfrË",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "H851",
      "Abitanti": 2910,
      "Link": "http://www.comuni-italiani.it/004/208/"
    },
    {
      "Istat": "004209",
      "Comune": "Sanfront",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "H852",
      "Abitanti": 2598,
      "Link": "http://www.comuni-italiani.it/004/209/"
    },
    {
      "Istat": "001241",
      "Comune": "Sangano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "H855",
      "Abitanti": 3777,
      "Link": "http://www.comuni-italiani.it/001/241/"
    },
    {
      "Istat": "012141",
      "Comune": "Sangiano",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21038,
      "CodFisco": "H872",
      "Abitanti": 1507,
      "Link": "http://www.comuni-italiani.it/012/141/"
    },
    {
      "Istat": "078117",
      "Comune": "Sangineto",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87020,
      "CodFisco": "H877",
      "Abitanti": 1404,
      "Link": "http://www.comuni-italiani.it/078/117/"
    },
    {
      "Istat": "023072",
      "Comune": "Sanguinetto",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37058,
      "CodFisco": "H944",
      "Abitanti": 4205,
      "Link": "http://www.comuni-italiani.it/023/072/"
    },
    {
      "Istat": 106015,
      "Comune": "Sanluri",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09025",
      "CodFisco": "H974",
      "Abitanti": 8530,
      "Link": "http://www.comuni-italiani.it/106/015/"
    },
    {
      "Istat": "018138",
      "Comune": "Sannazzaro de' Burgondi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27039,
      "CodFisco": "I048",
      "Abitanti": 5869,
      "Link": "http://www.comuni-italiani.it/018/138/"
    },
    {
      "Istat": "072040",
      "Comune": "Sannicandro di Bari",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70028,
      "CodFisco": "I053",
      "Abitanti": 9794,
      "Link": "http://www.comuni-italiani.it/072/040/"
    },
    {
      "Istat": "075070",
      "Comune": "Sannicola",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73017,
      "CodFisco": "I059",
      "Abitanti": 5965,
      "Link": "http://www.comuni-italiani.it/075/070/"
    },
    {
      "Istat": "008055",
      "Comune": "Sanremo",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18038,
      "CodFisco": "I138",
      "Abitanti": 56962,
      "Link": "http://www.comuni-italiani.it/008/055/"
    },
    {
      "Istat": "051034",
      "Comune": "Sansepolcro",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52037,
      "CodFisco": "I155",
      "Abitanti": 16380,
      "Link": "http://www.comuni-italiani.it/051/034/"
    },
    {
      "Istat": "016191",
      "Comune": "Santa Brigida",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "I168",
      "Abitanti": 586,
      "Link": "http://www.comuni-italiani.it/016/191/"
    },
    {
      "Istat": "078129",
      "Comune": "Santa Caterina Albanese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "I171",
      "Abitanti": 1292,
      "Link": "http://www.comuni-italiani.it/078/129/"
    },
    {
      "Istat": "079117",
      "Comune": "Santa Caterina dello Ionio",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "I170",
      "Abitanti": 2100,
      "Link": "http://www.comuni-italiani.it/079/117/"
    },
    {
      "Istat": "085017",
      "Comune": "Santa Caterina Villarmosa",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93018,
      "CodFisco": "I169",
      "Abitanti": 5751,
      "Link": "http://www.comuni-italiani.it/085/017/"
    },
    {
      "Istat": "075072",
      "Comune": "Santa Cesarea Terme",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "I172",
      "Abitanti": 3051,
      "Link": "http://www.comuni-italiani.it/075/072/"
    },
    {
      "Istat": "080078",
      "Comune": "Santa Cristina d'Aspromonte",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89056,
      "CodFisco": "I176",
      "Abitanti": 1054,
      "Link": "http://www.comuni-italiani.it/080/078/"
    },
    {
      "Istat": "018139",
      "Comune": "Santa Cristina e Bissone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "I175",
      "Abitanti": 2026,
      "Link": "http://www.comuni-italiani.it/018/139/"
    },
    {
      "Istat": "082066",
      "Comune": "Santa Cristina Gela",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "I174",
      "Abitanti": 927,
      "Link": "http://www.comuni-italiani.it/082/066/"
    },
    {
      "Istat": "021085",
      "Comune": "Santa Cristina Valgardena",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39047,
      "CodFisco": "I173",
      "Abitanti": 1900,
      "Link": "http://www.comuni-italiani.it/021/085/"
    },
    {
      "Istat": "088010",
      "Comune": "Santa Croce Camerina",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97017,
      "CodFisco": "I178",
      "Abitanti": 9945,
      "Link": "http://www.comuni-italiani.it/088/010/"
    },
    {
      "Istat": "062069",
      "Comune": "Santa Croce del Sannio",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82020,
      "CodFisco": "I179",
      "Abitanti": 991,
      "Link": "http://www.comuni-italiani.it/062/069/"
    },
    {
      "Istat": "070072",
      "Comune": "Santa Croce di Magliano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86047,
      "CodFisco": "I181",
      "Abitanti": 4727,
      "Link": "http://www.comuni-italiani.it/070/072/"
    },
    {
      "Istat": "050033",
      "Comune": "Santa Croce sull'Arno",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 56029,
      "CodFisco": "I177",
      "Abitanti": 14356,
      "Link": "http://www.comuni-italiani.it/050/033/"
    },
    {
      "Istat": "078130",
      "Comune": "Santa Domenica Talao",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "I183",
      "Abitanti": 1290,
      "Link": "http://www.comuni-italiani.it/078/130/"
    },
    {
      "Istat": "083083",
      "Comune": "Santa Domenica Vittoria",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 98030,
      "CodFisco": "I184",
      "Abitanti": 1079,
      "Link": "http://www.comuni-italiani.it/083/083/"
    },
    {
      "Istat": "084037",
      "Comune": "Santa Elisabetta",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "I185",
      "Abitanti": 2769,
      "Link": "http://www.comuni-italiani.it/084/037/"
    },
    {
      "Istat": "053022",
      "Comune": "Santa Fiora",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58037,
      "CodFisco": "I187",
      "Abitanti": 2773,
      "Link": "http://www.comuni-italiani.it/053/022/"
    },
    {
      "Istat": "082067",
      "Comune": "Santa Flavia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90017,
      "CodFisco": "I188",
      "Abitanti": 10957,
      "Link": "http://www.comuni-italiani.it/082/067/"
    },
    {
      "Istat": "018140",
      "Comune": "Santa Giuletta",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27046,
      "CodFisco": "I203",
      "Abitanti": 1679,
      "Link": "http://www.comuni-italiani.it/018/140/"
    },
    {
      "Istat": "095047",
      "Comune": "Santa Giusta",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09096",
      "CodFisco": "I205",
      "Abitanti": 4837,
      "Link": "http://www.comuni-italiani.it/095/047/"
    },
    {
      "Istat": "025048",
      "Comune": "Santa Giustina",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32035,
      "CodFisco": "I206",
      "Abitanti": 6800,
      "Link": "http://www.comuni-italiani.it/025/048/"
    },
    {
      "Istat": "028080",
      "Comune": "Santa Giustina in Colle",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "I207",
      "Abitanti": 7168,
      "Link": "http://www.comuni-italiani.it/028/080/"
    },
    {
      "Istat": "050034",
      "Comune": "Santa Luce",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56040,
      "CodFisco": "I217",
      "Abitanti": 1715,
      "Link": "http://www.comuni-italiani.it/050/034/"
    },
    {
      "Istat": "083086",
      "Comune": "Santa Lucia del Mela",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98046,
      "CodFisco": "I220",
      "Abitanti": 4794,
      "Link": "http://www.comuni-italiani.it/083/086/"
    },
    {
      "Istat": "026075",
      "Comune": "Santa Lucia di Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31025,
      "CodFisco": "I221",
      "Abitanti": 9032,
      "Link": "http://www.comuni-italiani.it/026/075/"
    },
    {
      "Istat": "064088",
      "Comune": "Santa Lucia di Serino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83020,
      "CodFisco": "I219",
      "Abitanti": 1460,
      "Link": "http://www.comuni-italiani.it/064/088/"
    },
    {
      "Istat": "028081",
      "Comune": "Santa Margherita d'Adige",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "I226",
      "Abitanti": 2358,
      "Link": "http://www.comuni-italiani.it/028/081/"
    },
    {
      "Istat": "084038",
      "Comune": "Santa Margherita di Belice",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92018,
      "CodFisco": "I224",
      "Abitanti": 6647,
      "Link": "http://www.comuni-italiani.it/084/038/"
    },
    {
      "Istat": "018142",
      "Comune": "Santa Margherita di Staffora",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "I230",
      "Abitanti": 530,
      "Link": "http://www.comuni-italiani.it/018/142/"
    },
    {
      "Istat": "010054",
      "Comune": "Santa Margherita Ligure",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16038,
      "CodFisco": "I225",
      "Abitanti": 9915,
      "Link": "http://www.comuni-italiani.it/010/054/"
    },
    {
      "Istat": "050035",
      "Comune": "Santa Maria a Monte",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56020,
      "CodFisco": "I232",
      "Abitanti": 12813,
      "Link": "http://www.comuni-italiani.it/050/035/"
    },
    {
      "Istat": "061082",
      "Comune": "Santa Maria a Vico",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81028,
      "CodFisco": "I233",
      "Abitanti": 14210,
      "Link": "http://www.comuni-italiani.it/061/082/"
    },
    {
      "Istat": "061083",
      "Comune": "Santa Maria Capua Vetere",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81055,
      "CodFisco": "I234",
      "Abitanti": 33742,
      "Link": "http://www.comuni-italiani.it/061/083/"
    },
    {
      "Istat": "090087",
      "Comune": "Santa Maria Coghinas",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "M284",
      "Abitanti": 1454,
      "Link": "http://www.comuni-italiani.it/090/087/"
    },
    {
      "Istat": "078132",
      "Comune": "Santa Maria del Cedro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "C717",
      "Abitanti": 4957,
      "Link": "http://www.comuni-italiani.it/078/132/"
    },
    {
      "Istat": "094045",
      "Comune": "Santa Maria del Molise",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86096,
      "CodFisco": "I238",
      "Abitanti": 660,
      "Link": "http://www.comuni-italiani.it/094/045/"
    },
    {
      "Istat": "018143",
      "Comune": "Santa Maria della Versa",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27047,
      "CodFisco": "I237",
      "Abitanti": 2519,
      "Link": "http://www.comuni-italiani.it/018/143/"
    },
    {
      "Istat": "087047",
      "Comune": "Santa Maria di Licodia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95038,
      "CodFisco": "I240",
      "Abitanti": 7108,
      "Link": "http://www.comuni-italiani.it/087/047/"
    },
    {
      "Istat": "027035",
      "Comune": "Santa Maria di Sala",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30036,
      "CodFisco": "I242",
      "Abitanti": 17278,
      "Link": "http://www.comuni-italiani.it/027/035/"
    },
    {
      "Istat": "097074",
      "Comune": "Santa Maria HoË",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23889,
      "CodFisco": "I243",
      "Abitanti": 2256,
      "Link": "http://www.comuni-italiani.it/097/074/"
    },
    {
      "Istat": "069084",
      "Comune": "Santa Maria Imbaro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66030,
      "CodFisco": "I244",
      "Abitanti": 1862,
      "Link": "http://www.comuni-italiani.it/069/084/"
    },
    {
      "Istat": "063090",
      "Comune": "Santa Maria la Carit‡",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80050,
      "CodFisco": "M273",
      "Abitanti": 11718,
      "Link": "http://www.comuni-italiani.it/063/090/"
    },
    {
      "Istat": "061084",
      "Comune": "Santa Maria La Fossa",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81050,
      "CodFisco": "I247",
      "Abitanti": 2745,
      "Link": "http://www.comuni-italiani.it/061/084/"
    },
    {
      "Istat": "030104",
      "Comune": "Santa Maria La Longa",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "I248",
      "Abitanti": 2445,
      "Link": "http://www.comuni-italiani.it/030/104/"
    },
    {
      "Istat": 103062,
      "Comune": "Santa Maria Maggiore",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28857,
      "CodFisco": "I249",
      "Abitanti": 1271,
      "Link": "http://www.comuni-italiani.it/103/062/"
    },
    {
      "Istat": "042043",
      "Comune": "Santa Maria Nuova",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "I251",
      "Abitanti": 4263,
      "Link": "http://www.comuni-italiani.it/042/043/"
    },
    {
      "Istat": "065127",
      "Comune": "Santa Marina",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "I253",
      "Abitanti": 3222,
      "Link": "http://www.comuni-italiani.it/065/127/"
    },
    {
      "Istat": "083087",
      "Comune": "Santa Marina Salina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98050,
      "CodFisco": "I254",
      "Abitanti": 894,
      "Link": "http://www.comuni-italiani.it/083/087/"
    },
    {
      "Istat": "058097",
      "Comune": "Santa Marinella",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "00058",
      "CodFisco": "I255",
      "Abitanti": 18397,
      "Link": "http://www.comuni-italiani.it/058/097/"
    },
    {
      "Istat": "081019",
      "Comune": "Santa Ninfa",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91029,
      "CodFisco": "I291",
      "Abitanti": 5125,
      "Link": "http://www.comuni-italiani.it/081/019/"
    },
    {
      "Istat": "064093",
      "Comune": "Santa Paolina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "I301",
      "Abitanti": 1440,
      "Link": "http://www.comuni-italiani.it/064/093/"
    },
    {
      "Istat": 101022,
      "Comune": "Santa Severina",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88832,
      "CodFisco": "I308",
      "Abitanti": 2234,
      "Link": "http://www.comuni-italiani.it/101/022/"
    },
    {
      "Istat": "040043",
      "Comune": "Santa Sofia",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47018,
      "CodFisco": "I310",
      "Abitanti": 4240,
      "Link": "http://www.comuni-italiani.it/040/043/"
    },
    {
      "Istat": "078133",
      "Comune": "Santa Sofia D'Epiro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87048,
      "CodFisco": "I309",
      "Abitanti": 2934,
      "Link": "http://www.comuni-italiani.it/078/133/"
    },
    {
      "Istat": "083089",
      "Comune": "Santa Teresa di Riva",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98028,
      "CodFisco": "I311",
      "Abitanti": 9296,
      "Link": "http://www.comuni-italiani.it/083/089/"
    },
    {
      "Istat": 104022,
      "Comune": "Santa Teresa Gallura",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07028",
      "CodFisco": "I312",
      "Abitanti": 5225,
      "Link": "http://www.comuni-italiani.it/104/022/"
    },
    {
      "Istat": "087048",
      "Comune": "Santa Venerina",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95010,
      "CodFisco": "I314",
      "Abitanti": 8405,
      "Link": "http://www.comuni-italiani.it/087/048/"
    },
    {
      "Istat": "004212",
      "Comune": "Santa Vittoria d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12069,
      "CodFisco": "I316",
      "Abitanti": 2754,
      "Link": "http://www.comuni-italiani.it/004/212/"
    },
    {
      "Istat": 109036,
      "Comune": "Santa Vittoria in Matenano",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63854,
      "CodFisco": "I315",
      "Abitanti": 1460,
      "Link": "http://www.comuni-italiani.it/109/036/"
    },
    {
      "Istat": 107018,
      "Comune": "Santadi",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "I182",
      "Abitanti": 3604,
      "Link": "http://www.comuni-italiani.it/107/018/"
    },
    {
      "Istat": "094044",
      "Comune": "Sant'Agapito",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "I189",
      "Abitanti": 1397,
      "Link": "http://www.comuni-italiani.it/094/044/"
    },
    {
      "Istat": "037056",
      "Comune": "Sant'Agata Bolognese",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40019,
      "CodFisco": "I191",
      "Abitanti": 7392,
      "Link": "http://www.comuni-italiani.it/037/056/"
    },
    {
      "Istat": "062070",
      "Comune": "Sant'Agata De' Goti",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 82019,
      "CodFisco": "I197",
      "Abitanti": 11473,
      "Link": "http://www.comuni-italiani.it/062/070/"
    },
    {
      "Istat": "080079",
      "Comune": "Sant'Agata del Bianco",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "I198",
      "Abitanti": 683,
      "Link": "http://www.comuni-italiani.it/080/079/"
    },
    {
      "Istat": "078131",
      "Comune": "Sant'Agata di Esaro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "I192",
      "Abitanti": 2007,
      "Link": "http://www.comuni-italiani.it/078/131/"
    },
    {
      "Istat": "083084",
      "Comune": "Sant'Agata di Militello",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98076,
      "CodFisco": "I199",
      "Abitanti": 13190,
      "Link": "http://www.comuni-italiani.it/083/084/"
    },
    {
      "Istat": "071052",
      "Comune": "Sant'Agata di Puglia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71028,
      "CodFisco": "I193",
      "Abitanti": 2149,
      "Link": "http://www.comuni-italiani.it/071/052/"
    },
    {
      "Istat": "099026",
      "Comune": "Sant'Agata Feltria",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47866,
      "CodFisco": "I201",
      "Abitanti": 2281,
      "Link": "http://www.comuni-italiani.it/099/026/"
    },
    {
      "Istat": "006156",
      "Comune": "Sant'Agata Fossili",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "I190",
      "Abitanti": 449,
      "Link": "http://www.comuni-italiani.it/006/156/"
    },
    {
      "Istat": "087045",
      "Comune": "Sant'Agata Li Battiati",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "I202",
      "Abitanti": 9396,
      "Link": "http://www.comuni-italiani.it/087/045/"
    },
    {
      "Istat": "039017",
      "Comune": "Sant'Agata sul Santerno",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0545",
      "CAP": 48020,
      "CodFisco": "I196",
      "Abitanti": 2849,
      "Link": "http://www.comuni-italiani.it/039/017/"
    },
    {
      "Istat": "063071",
      "Comune": "Sant'Agnello",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80065,
      "CodFisco": "I208",
      "Abitanti": 9079,
      "Link": "http://www.comuni-italiani.it/063/071/"
    },
    {
      "Istat": "038021",
      "Comune": "Sant'Agostino",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44047,
      "CodFisco": "I209",
      "Abitanti": 7106,
      "Link": "http://www.comuni-italiani.it/038/021/"
    },
    {
      "Istat": "004211",
      "Comune": "Sant'Albano Stura",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "I210",
      "Abitanti": 2394,
      "Link": "http://www.comuni-italiani.it/004/211/"
    },
    {
      "Istat": "018141",
      "Comune": "Sant'Alessio con Vialone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27016,
      "CodFisco": "I213",
      "Abitanti": 845,
      "Link": "http://www.comuni-italiani.it/018/141/"
    },
    {
      "Istat": "080080",
      "Comune": "Sant'Alessio in Aspromonte",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89050,
      "CodFisco": "I214",
      "Abitanti": 357,
      "Link": "http://www.comuni-italiani.it/080/080/"
    },
    {
      "Istat": "083085",
      "Comune": "Sant'Alessio Siculo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98030,
      "CodFisco": "I215",
      "Abitanti": 1525,
      "Link": "http://www.comuni-italiani.it/083/085/"
    },
    {
      "Istat": "087046",
      "Comune": "Sant'Alfio",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95010,
      "CodFisco": "I216",
      "Abitanti": 1663,
      "Link": "http://www.comuni-italiani.it/087/046/"
    },
    {
      "Istat": "001255",
      "Comune": "Sant'Ambrogio di Torino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10057,
      "CodFisco": "I258",
      "Abitanti": 4843,
      "Link": "http://www.comuni-italiani.it/001/255/"
    },
    {
      "Istat": "023077",
      "Comune": "Sant'Ambrogio di Valpolicella",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37015,
      "CodFisco": "I259",
      "Abitanti": 11635,
      "Link": "http://www.comuni-italiani.it/023/077/"
    },
    {
      "Istat": "060065",
      "Comune": "Sant'Ambrogio sul Garigliano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "I256",
      "Abitanti": 976,
      "Link": "http://www.comuni-italiani.it/060/065/"
    },
    {
      "Istat": "063072",
      "Comune": "Sant'Anastasia",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80048,
      "CodFisco": "I262",
      "Abitanti": 28827,
      "Link": "http://www.comuni-italiani.it/063/072/"
    },
    {
      "Istat": "054045",
      "Comune": "Sant'Anatolia di Narco",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06040",
      "CodFisco": "I263",
      "Abitanti": 592,
      "Link": "http://www.comuni-italiani.it/054/045/"
    },
    {
      "Istat": "079118",
      "Comune": "Sant'Andrea Apostolo dello Ionio",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "I266",
      "Abitanti": 2131,
      "Link": "http://www.comuni-italiani.it/079/118/"
    },
    {
      "Istat": "060066",
      "Comune": "Sant'Andrea del Garigliano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "I265",
      "Abitanti": 1601,
      "Link": "http://www.comuni-italiani.it/060/066/"
    },
    {
      "Istat": "064089",
      "Comune": "Sant'Andrea di Conza",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83053,
      "CodFisco": "I264",
      "Abitanti": 1696,
      "Link": "http://www.comuni-italiani.it/064/089/"
    },
    {
      "Istat": "092061",
      "Comune": "Sant'Andrea Frius",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I271",
      "Abitanti": 1839,
      "Link": "http://www.comuni-italiani.it/092/061/"
    },
    {
      "Istat": "062071",
      "Comune": "Sant'Angelo a Cupolo",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82010,
      "CodFisco": "I277",
      "Abitanti": 4314,
      "Link": "http://www.comuni-italiani.it/062/071/"
    },
    {
      "Istat": "065128",
      "Comune": "Sant'Angelo a Fasanella",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84027,
      "CodFisco": "I278",
      "Abitanti": 730,
      "Link": "http://www.comuni-italiani.it/065/128/"
    },
    {
      "Istat": "064091",
      "Comune": "Sant'Angelo a Scala",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "I280",
      "Abitanti": 736,
      "Link": "http://www.comuni-italiani.it/064/091/"
    },
    {
      "Istat": "064090",
      "Comune": "Sant'Angelo all'Esca",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "I279",
      "Abitanti": 852,
      "Link": "http://www.comuni-italiani.it/064/090/"
    },
    {
      "Istat": "061086",
      "Comune": "Sant'Angelo d'Alife",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81017,
      "CodFisco": "I273",
      "Abitanti": 2320,
      "Link": "http://www.comuni-italiani.it/061/086/"
    },
    {
      "Istat": "064092",
      "Comune": "Sant'Angelo dei Lombardi",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83054,
      "CodFisco": "I281",
      "Abitanti": 4431,
      "Link": "http://www.comuni-italiani.it/064/092/"
    },
    {
      "Istat": "094046",
      "Comune": "Sant'Angelo del Pesco",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86080,
      "CodFisco": "I282",
      "Abitanti": 370,
      "Link": "http://www.comuni-italiani.it/094/046/"
    },
    {
      "Istat": "083088",
      "Comune": "Sant'Angelo di Brolo",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "I283",
      "Abitanti": 3330,
      "Link": "http://www.comuni-italiani.it/083/088/"
    },
    {
      "Istat": "028082",
      "Comune": "Sant'Angelo di Piove di Sacco",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "I275",
      "Abitanti": 7249,
      "Link": "http://www.comuni-italiani.it/028/082/"
    },
    {
      "Istat": "041056",
      "Comune": "Sant'Angelo in Lizzola",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61020,
      "CodFisco": "I285",
      "Abitanti": 8749,
      "Link": "http://www.comuni-italiani.it/041/056/"
    },
    {
      "Istat": "043048",
      "Comune": "Sant'Angelo in Pontano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "I286",
      "Abitanti": 1537,
      "Link": "http://www.comuni-italiani.it/043/048/"
    },
    {
      "Istat": "041057",
      "Comune": "Sant'Angelo in Vado",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61048,
      "CodFisco": "I287",
      "Abitanti": 4138,
      "Link": "http://www.comuni-italiani.it/041/057/"
    },
    {
      "Istat": "076079",
      "Comune": "Sant'Angelo Le Fratte",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "I288",
      "Abitanti": 1486,
      "Link": "http://www.comuni-italiani.it/076/079/"
    },
    {
      "Istat": "070073",
      "Comune": "Sant'Angelo Limosano",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "I289",
      "Abitanti": 349,
      "Link": "http://www.comuni-italiani.it/070/073/"
    },
    {
      "Istat": "098050",
      "Comune": "Sant'Angelo Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26866,
      "CodFisco": "I274",
      "Abitanti": 13279,
      "Link": "http://www.comuni-italiani.it/098/050/"
    },
    {
      "Istat": "018144",
      "Comune": "Sant'Angelo Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "I276",
      "Abitanti": 902,
      "Link": "http://www.comuni-italiani.it/018/144/"
    },
    {
      "Istat": "084039",
      "Comune": "Sant'Angelo Muxaro",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "I290",
      "Abitanti": 1512,
      "Link": "http://www.comuni-italiani.it/084/039/"
    },
    {
      "Istat": "058098",
      "Comune": "Sant'Angelo Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00010",
      "CodFisco": "I284",
      "Abitanti": 4778,
      "Link": "http://www.comuni-italiani.it/058/098/"
    },
    {
      "Istat": 107019,
      "Comune": "Sant'Anna Arresi",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "M209",
      "Abitanti": 2709,
      "Link": "http://www.comuni-italiani.it/107/019/"
    },
    {
      "Istat": "023078",
      "Comune": "Sant'Anna d'Alfaedo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37020,
      "CodFisco": "I292",
      "Abitanti": 2657,
      "Link": "http://www.comuni-italiani.it/023/078/"
    },
    {
      "Istat": "063073",
      "Comune": "Sant'Antimo",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80029,
      "CodFisco": "I293",
      "Abitanti": 31081,
      "Link": "http://www.comuni-italiani.it/063/073/"
    },
    {
      "Istat": 107020,
      "Comune": "Sant'Antioco",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09017",
      "CodFisco": "I294",
      "Abitanti": 11630,
      "Link": "http://www.comuni-italiani.it/107/020/"
    },
    {
      "Istat": "001256",
      "Comune": "Sant'Antonino di Susa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "I296",
      "Abitanti": 4407,
      "Link": "http://www.comuni-italiani.it/001/256/"
    },
    {
      "Istat": "063074",
      "Comune": "Sant'Antonio Abate",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80057,
      "CodFisco": "I300",
      "Abitanti": 19693,
      "Link": "http://www.comuni-italiani.it/063/074/"
    },
    {
      "Istat": 104021,
      "Comune": "Sant'Antonio di Gallura",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "M276",
      "Abitanti": 1692,
      "Link": "http://www.comuni-italiani.it/104/021/"
    },
    {
      "Istat": "060067",
      "Comune": "Sant'Apollinare",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03048",
      "CodFisco": "I302",
      "Abitanti": 2015,
      "Link": "http://www.comuni-italiani.it/060/067/"
    },
    {
      "Istat": "076080",
      "Comune": "Sant'Arcangelo",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85037,
      "CodFisco": "I305",
      "Abitanti": 6524,
      "Link": "http://www.comuni-italiani.it/076/080/"
    },
    {
      "Istat": "099018",
      "Comune": "Santarcangelo di Romagna",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47822,
      "CodFisco": "I304",
      "Abitanti": 21409,
      "Link": "http://www.comuni-italiani.it/099/018/"
    },
    {
      "Istat": "062078",
      "Comune": "Sant'Arcangelo Trimonte",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82021,
      "CodFisco": "F557",
      "Abitanti": 643,
      "Link": "http://www.comuni-italiani.it/062/078/"
    },
    {
      "Istat": "061087",
      "Comune": "Sant'Arpino",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "I306",
      "Abitanti": 14267,
      "Link": "http://www.comuni-italiani.it/061/087/"
    },
    {
      "Istat": "065129",
      "Comune": "Sant'Arsenio",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84037,
      "CodFisco": "I307",
      "Abitanti": 2807,
      "Link": "http://www.comuni-italiani.it/065/129/"
    },
    {
      "Istat": "066089",
      "Comune": "Sante Marie",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67067,
      "CodFisco": "I326",
      "Abitanti": 1251,
      "Link": "http://www.comuni-italiani.it/066/089/"
    },
    {
      "Istat": "067038",
      "Comune": "Sant'Egidio alla Vibrata",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64016,
      "CodFisco": "I318",
      "Abitanti": 9844,
      "Link": "http://www.comuni-italiani.it/067/038/"
    },
    {
      "Istat": "065130",
      "Comune": "Sant'Egidio del Monte Albino",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84010,
      "CodFisco": "I317",
      "Abitanti": 8942,
      "Link": "http://www.comuni-italiani.it/065/130/"
    },
    {
      "Istat": "028083",
      "Comune": "Sant'Elena",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "I319",
      "Abitanti": 2375,
      "Link": "http://www.comuni-italiani.it/028/083/"
    },
    {
      "Istat": "094047",
      "Comune": "Sant'Elena Sannita",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86095,
      "CodFisco": "B466",
      "Abitanti": 260,
      "Link": "http://www.comuni-italiani.it/094/047/"
    },
    {
      "Istat": "070074",
      "Comune": "Sant'Elia a Pianisi",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86048,
      "CodFisco": "I320",
      "Abitanti": 1970,
      "Link": "http://www.comuni-italiani.it/070/074/"
    },
    {
      "Istat": "060068",
      "Comune": "Sant'Elia Fiumerapido",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03049",
      "CodFisco": "I321",
      "Abitanti": 6283,
      "Link": "http://www.comuni-italiani.it/060/068/"
    },
    {
      "Istat": 109037,
      "Comune": "Sant'Elpidio a Mare",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63811,
      "CodFisco": "I324",
      "Abitanti": 17185,
      "Link": "http://www.comuni-italiani.it/109/037/"
    },
    {
      "Istat": "001257",
      "Comune": "Santena",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10026,
      "CodFisco": "I327",
      "Abitanti": 10740,
      "Link": "http://www.comuni-italiani.it/001/257/"
    },
    {
      "Istat": "072041",
      "Comune": "Santeramo in Colle",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70029,
      "CodFisco": "I330",
      "Abitanti": 26854,
      "Link": "http://www.comuni-italiani.it/072/041/"
    },
    {
      "Istat": "068037",
      "Comune": "Sant'Eufemia a Maiella",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "I332",
      "Abitanti": 305,
      "Link": "http://www.comuni-italiani.it/068/037/"
    },
    {
      "Istat": "080081",
      "Comune": "Sant'Eufemia d'Aspromonte",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89027,
      "CodFisco": "I333",
      "Abitanti": 4164,
      "Link": "http://www.comuni-italiani.it/080/081/"
    },
    {
      "Istat": "069085",
      "Comune": "Sant'Eusanio del Sangro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66037,
      "CodFisco": "I335",
      "Abitanti": 2522,
      "Link": "http://www.comuni-italiani.it/069/085/"
    },
    {
      "Istat": "066090",
      "Comune": "Sant'Eusanio Forconese",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "I336",
      "Abitanti": 413,
      "Link": "http://www.comuni-italiani.it/066/090/"
    },
    {
      "Istat": "002133",
      "Comune": "Santhi‡",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13048,
      "CodFisco": "I337",
      "Abitanti": 8994,
      "Link": "http://www.comuni-italiani.it/002/133/"
    },
    {
      "Istat": "059026",
      "Comune": "Santi Cosma e Damiano",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04020",
      "CodFisco": "I339",
      "Abitanti": 6886,
      "Link": "http://www.comuni-italiani.it/059/026/"
    },
    {
      "Istat": "080082",
      "Comune": "Sant'Ilario dello Ionio",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "I341",
      "Abitanti": 1374,
      "Link": "http://www.comuni-italiani.it/080/082/"
    },
    {
      "Istat": "035039",
      "Comune": "Sant'Ilario d'Enza",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42049,
      "CodFisco": "I342",
      "Abitanti": 11021,
      "Link": "http://www.comuni-italiani.it/035/039/"
    },
    {
      "Istat": "041058",
      "Comune": "Sant'Ippolito",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "I344",
      "Abitanti": 1604,
      "Link": "http://www.comuni-italiani.it/041/058/"
    },
    {
      "Istat": "008056",
      "Comune": "Santo Stefano al Mare",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "I365",
      "Abitanti": 2322,
      "Link": "http://www.comuni-italiani.it/008/056/"
    },
    {
      "Istat": "004213",
      "Comune": "Santo Stefano Belbo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 12058,
      "CodFisco": "I367",
      "Abitanti": 4109,
      "Link": "http://www.comuni-italiani.it/004/213/"
    },
    {
      "Istat": "010056",
      "Comune": "Santo Stefano d'Aveto",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16049,
      "CodFisco": "I368",
      "Abitanti": 1251,
      "Link": "http://www.comuni-italiani.it/010/056/"
    },
    {
      "Istat": "064095",
      "Comune": "Santo Stefano del Sole",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "I357",
      "Abitanti": 2218,
      "Link": "http://www.comuni-italiani.it/064/095/"
    },
    {
      "Istat": "025050",
      "Comune": "Santo Stefano di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32045,
      "CodFisco": "C919",
      "Abitanti": 2680,
      "Link": "http://www.comuni-italiani.it/025/050/"
    },
    {
      "Istat": "083091",
      "Comune": "Santo Stefano di Camastra",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98077,
      "CodFisco": "I370",
      "Abitanti": 4533,
      "Link": "http://www.comuni-italiani.it/083/091/"
    },
    {
      "Istat": "011026",
      "Comune": "Santo Stefano di Magra",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19037,
      "CodFisco": "I363",
      "Abitanti": 8751,
      "Link": "http://www.comuni-italiani.it/011/026/"
    },
    {
      "Istat": "078134",
      "Comune": "Santo Stefano di Rogliano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87056,
      "CodFisco": "I359",
      "Abitanti": 1648,
      "Link": "http://www.comuni-italiani.it/078/134/"
    },
    {
      "Istat": "066091",
      "Comune": "Santo Stefano di Sessanio",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "I360",
      "Abitanti": 117,
      "Link": "http://www.comuni-italiani.it/066/091/"
    },
    {
      "Istat": "080083",
      "Comune": "Santo Stefano in Aspromonte",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89057,
      "CodFisco": "I371",
      "Abitanti": 1330,
      "Link": "http://www.comuni-italiani.it/080/083/"
    },
    {
      "Istat": "098051",
      "Comune": "Santo Stefano Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26849,
      "CodFisco": "I362",
      "Abitanti": 1929,
      "Link": "http://www.comuni-italiani.it/098/051/"
    },
    {
      "Istat": "084040",
      "Comune": "Santo Stefano Quisquina",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92020,
      "CodFisco": "I356",
      "Abitanti": 4965,
      "Link": "http://www.comuni-italiani.it/084/040/"
    },
    {
      "Istat": "004214",
      "Comune": "Santo Stefano Roero",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "I372",
      "Abitanti": 1432,
      "Link": "http://www.comuni-italiani.it/004/214/"
    },
    {
      "Istat": "015200",
      "Comune": "Santo Stefano Ticino",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "I361",
      "Abitanti": 4731,
      "Link": "http://www.comuni-italiani.it/015/200/"
    },
    {
      "Istat": "027036",
      "Comune": "Santo Stino di Livenza",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30029,
      "CodFisco": "I373",
      "Abitanti": 13088,
      "Link": "http://www.comuni-italiani.it/027/036/"
    },
    {
      "Istat": "010055",
      "Comune": "Sant'Olcese",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "I346",
      "Abitanti": 5999,
      "Link": "http://www.comuni-italiani.it/010/055/"
    },
    {
      "Istat": "065131",
      "Comune": "Santomenna",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "I260",
      "Abitanti": 482,
      "Link": "http://www.comuni-italiani.it/065/131/"
    },
    {
      "Istat": "067039",
      "Comune": "Sant'Omero",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64027,
      "CodFisco": "I348",
      "Abitanti": 5423,
      "Link": "http://www.comuni-italiani.it/067/039/"
    },
    {
      "Istat": "016192",
      "Comune": "Sant'Omobono Terme",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24038,
      "CodFisco": "I349",
      "Abitanti": 3571,
      "Link": "http://www.comuni-italiani.it/016/192/"
    },
    {
      "Istat": 102036,
      "Comune": "Sant'Onofrio",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89843,
      "CodFisco": "I350",
      "Abitanti": 3028,
      "Link": "http://www.comuni-italiani.it/102/036/"
    },
    {
      "Istat": "060069",
      "Comune": "Santopadre",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "I351",
      "Abitanti": 1450,
      "Link": "http://www.comuni-italiani.it/060/069/"
    },
    {
      "Istat": "058099",
      "Comune": "Sant'Oreste",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "00060",
      "CodFisco": "I352",
      "Abitanti": 3870,
      "Link": "http://www.comuni-italiani.it/058/099/"
    },
    {
      "Istat": "024095",
      "Comune": "Santorso",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36014,
      "CodFisco": "I353",
      "Abitanti": 5856,
      "Link": "http://www.comuni-italiani.it/024/095/"
    },
    {
      "Istat": "022168",
      "Comune": "Sant'Orsola Terme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "I354",
      "Abitanti": 1076,
      "Link": "http://www.comuni-italiani.it/022/168/"
    },
    {
      "Istat": "095049",
      "Comune": "Santu Lussurgiu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09075",
      "CodFisco": "I374",
      "Abitanti": 2471,
      "Link": "http://www.comuni-italiani.it/095/049/"
    },
    {
      "Istat": "028084",
      "Comune": "Sant'Urbano",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "I375",
      "Abitanti": 2178,
      "Link": "http://www.comuni-italiani.it/028/084/"
    },
    {
      "Istat": "065133",
      "Comune": "Sanza",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84030,
      "CodFisco": "I410",
      "Abitanti": 2754,
      "Link": "http://www.comuni-italiani.it/065/133/"
    },
    {
      "Istat": "022169",
      "Comune": "Sanzeno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "I411",
      "Abitanti": 938,
      "Link": "http://www.comuni-italiani.it/022/169/"
    },
    {
      "Istat": "028085",
      "Comune": "Saonara",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "I418",
      "Abitanti": 10073,
      "Link": "http://www.comuni-italiani.it/028/085/"
    },
    {
      "Istat": "083092",
      "Comune": "Saponara",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98047,
      "CodFisco": "I420",
      "Abitanti": 4089,
      "Link": "http://www.comuni-italiani.it/083/092/"
    },
    {
      "Istat": "025052",
      "Comune": "Sappada",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32047,
      "CodFisco": "I421",
      "Abitanti": 1307,
      "Link": "http://www.comuni-italiani.it/025/052/"
    },
    {
      "Istat": "065134",
      "Comune": "Sapri",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0973",
      "CAP": 84073,
      "CodFisco": "I422",
      "Abitanti": 7038,
      "Link": "http://www.comuni-italiani.it/065/134/"
    },
    {
      "Istat": "078136",
      "Comune": "Saracena",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "I423",
      "Abitanti": 4061,
      "Link": "http://www.comuni-italiani.it/078/136/"
    },
    {
      "Istat": "058101",
      "Comune": "Saracinesco",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "I424",
      "Abitanti": 169,
      "Link": "http://www.comuni-italiani.it/058/101/"
    },
    {
      "Istat": "024097",
      "Comune": "Sarcedo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "I425",
      "Abitanti": 5316,
      "Link": "http://www.comuni-italiani.it/024/097/"
    },
    {
      "Istat": "076081",
      "Comune": "Sarconi",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "I426",
      "Abitanti": 1392,
      "Link": "http://www.comuni-italiani.it/076/081/"
    },
    {
      "Istat": 106016,
      "Comune": "Sardara",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09030",
      "CodFisco": "I428",
      "Abitanti": 4217,
      "Link": "http://www.comuni-italiani.it/106/016/"
    },
    {
      "Istat": "006157",
      "Comune": "Sardigliano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "I429",
      "Abitanti": 452,
      "Link": "http://www.comuni-italiani.it/006/157/"
    },
    {
      "Istat": "024098",
      "Comune": "Sarego",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "I430",
      "Abitanti": 6587,
      "Link": "http://www.comuni-italiani.it/024/098/"
    },
    {
      "Istat": "021086",
      "Comune": "Sarentino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39058,
      "CodFisco": "I431",
      "Abitanti": 6903,
      "Link": "http://www.comuni-italiani.it/021/086/"
    },
    {
      "Istat": "006158",
      "Comune": "Sarezzano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "I432",
      "Abitanti": 1205,
      "Link": "http://www.comuni-italiani.it/006/158/"
    },
    {
      "Istat": "017174",
      "Comune": "Sarezzo",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25068,
      "CodFisco": "I433",
      "Abitanti": 13430,
      "Link": "http://www.comuni-italiani.it/017/174/"
    },
    {
      "Istat": "033042",
      "Comune": "Sarmato",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "I434",
      "Abitanti": 2868,
      "Link": "http://www.comuni-italiani.it/033/042/"
    },
    {
      "Istat": "026078",
      "Comune": "Sarmede",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31026,
      "CodFisco": "I435",
      "Abitanti": 3202,
      "Link": "http://www.comuni-italiani.it/026/078/"
    },
    {
      "Istat": "043049",
      "Comune": "Sarnano",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62028,
      "CodFisco": "I436",
      "Abitanti": 3424,
      "Link": "http://www.comuni-italiani.it/043/049/"
    },
    {
      "Istat": "016193",
      "Comune": "Sarnico",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24067,
      "CodFisco": "I437",
      "Abitanti": 6652,
      "Link": "http://www.comuni-italiani.it/016/193/"
    },
    {
      "Istat": "065135",
      "Comune": "Sarno",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84087,
      "CodFisco": "I438",
      "Abitanti": 31423,
      "Link": "http://www.comuni-italiani.it/065/135/"
    },
    {
      "Istat": "022170",
      "Comune": "Sarnonico",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38011,
      "CodFisco": "I439",
      "Abitanti": 749,
      "Link": "http://www.comuni-italiani.it/022/170/"
    },
    {
      "Istat": "012119",
      "Comune": "Saronno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 21047,
      "CodFisco": "I441",
      "Abitanti": 39161,
      "Link": "http://www.comuni-italiani.it/012/119/"
    },
    {
      "Istat": "007066",
      "Comune": "Sarre",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "I442",
      "Abitanti": 4878,
      "Link": "http://www.comuni-italiani.it/007/066/"
    },
    {
      "Istat": "092066",
      "Comune": "Sarroch",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09018",
      "CodFisco": "I443",
      "Abitanti": 5273,
      "Link": "http://www.comuni-italiani.it/092/066/"
    },
    {
      "Istat": "040044",
      "Comune": "Sarsina",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0547",
      "CAP": 47027,
      "CodFisco": "I444",
      "Abitanti": 3652,
      "Link": "http://www.comuni-italiani.it/040/044/"
    },
    {
      "Istat": "052031",
      "Comune": "Sarteano",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0578",
      "CAP": 53047,
      "CodFisco": "I445",
      "Abitanti": 4905,
      "Link": "http://www.comuni-italiani.it/052/031/"
    },
    {
      "Istat": "018146",
      "Comune": "Sartirana Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "I447",
      "Abitanti": 1787,
      "Link": "http://www.comuni-italiani.it/018/146/"
    },
    {
      "Istat": "091077",
      "Comune": "Sarule",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "I448",
      "Abitanti": 1802,
      "Link": "http://www.comuni-italiani.it/091/077/"
    },
    {
      "Istat": "011027",
      "Comune": "Sarzana",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19038,
      "CodFisco": "I449",
      "Abitanti": 21979,
      "Link": "http://www.comuni-italiani.it/011/027/"
    },
    {
      "Istat": "065136",
      "Comune": "Sassano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84038,
      "CodFisco": "I451",
      "Abitanti": 5119,
      "Link": "http://www.comuni-italiani.it/065/136/"
    },
    {
      "Istat": "090064",
      "Comune": "Sassari",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07100",
      "CodFisco": "I452",
      "Abitanti": 130658,
      "Link": "http://www.comuni-italiani.it/090/064/"
    },
    {
      "Istat": "009055",
      "Comune": "Sassello",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17046,
      "CodFisco": "I453",
      "Abitanti": 1861,
      "Link": "http://www.comuni-italiani.it/009/055/"
    },
    {
      "Istat": "049019",
      "Comune": "Sassetta",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57020,
      "CodFisco": "I454",
      "Abitanti": 567,
      "Link": "http://www.comuni-italiani.it/049/019/"
    },
    {
      "Istat": "062072",
      "Comune": "Sassinoro",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82026,
      "CodFisco": "I455",
      "Abitanti": 595,
      "Link": "http://www.comuni-italiani.it/062/072/"
    },
    {
      "Istat": "076082",
      "Comune": "Sasso di Castalda",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "I457",
      "Abitanti": 852,
      "Link": "http://www.comuni-italiani.it/076/082/"
    },
    {
      "Istat": "037057",
      "Comune": "Sasso Marconi",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40037,
      "CodFisco": "G972",
      "Abitanti": 14727,
      "Link": "http://www.comuni-italiani.it/037/057/"
    },
    {
      "Istat": "041059",
      "Comune": "Sassocorvaro",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61028,
      "CodFisco": "I459",
      "Abitanti": 3506,
      "Link": "http://www.comuni-italiani.it/041/059/"
    },
    {
      "Istat": "041060",
      "Comune": "Sassofeltrio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0541",
      "CAP": 61013,
      "CodFisco": "I460",
      "Abitanti": 1476,
      "Link": "http://www.comuni-italiani.it/041/060/"
    },
    {
      "Istat": "042044",
      "Comune": "Sassoferrato",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0732",
      "CAP": 60041,
      "CodFisco": "I461",
      "Abitanti": 7595,
      "Link": "http://www.comuni-italiani.it/042/044/"
    },
    {
      "Istat": "036040",
      "Comune": "Sassuolo",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41049,
      "CodFisco": "I462",
      "Abitanti": 41290,
      "Link": "http://www.comuni-italiani.it/036/040/"
    },
    {
      "Istat": "079123",
      "Comune": "Satriano",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "I463",
      "Abitanti": 3364,
      "Link": "http://www.comuni-italiani.it/079/123/"
    },
    {
      "Istat": "076083",
      "Comune": "Satriano di Lucania",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85050,
      "CodFisco": "G614",
      "Abitanti": 2408,
      "Link": "http://www.comuni-italiani.it/076/083/"
    },
    {
      "Istat": "030107",
      "Comune": "Sauris",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "I464",
      "Abitanti": 429,
      "Link": "http://www.comuni-italiani.it/030/107/"
    },
    {
      "Istat": "001258",
      "Comune": "Sauze di Cesana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10054,
      "CodFisco": "I465",
      "Abitanti": 252,
      "Link": "http://www.comuni-italiani.it/001/258/"
    },
    {
      "Istat": "001259",
      "Comune": "Sauze d'Oulx",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "I466",
      "Abitanti": 1171,
      "Link": "http://www.comuni-italiani.it/001/259/"
    },
    {
      "Istat": "073026",
      "Comune": "Sava",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74028,
      "CodFisco": "I467",
      "Abitanti": 16776,
      "Link": "http://www.comuni-italiani.it/073/026/"
    },
    {
      "Istat": 101023,
      "Comune": "Savelli",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 88825,
      "CodFisco": "I468",
      "Abitanti": 1334,
      "Link": "http://www.comuni-italiani.it/101/023/"
    },
    {
      "Istat": "063076",
      "Comune": "Saviano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80039,
      "CodFisco": "I469",
      "Abitanti": 15410,
      "Link": "http://www.comuni-italiani.it/063/076/"
    },
    {
      "Istat": "004215",
      "Comune": "Savigliano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12038,
      "CodFisco": "I470",
      "Abitanti": 21065,
      "Link": "http://www.comuni-italiani.it/004/215/"
    },
    {
      "Istat": "064096",
      "Comune": "Savignano Irpino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "I471",
      "Abitanti": 1204,
      "Link": "http://www.comuni-italiani.it/064/096/"
    },
    {
      "Istat": "036041",
      "Comune": "Savignano sul Panaro",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41056,
      "CodFisco": "I473",
      "Abitanti": 9452,
      "Link": "http://www.comuni-italiani.it/036/041/"
    },
    {
      "Istat": "040045",
      "Comune": "Savignano sul Rubicone",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47039,
      "CodFisco": "I472",
      "Abitanti": 17653,
      "Link": "http://www.comuni-italiani.it/040/045/"
    },
    {
      "Istat": "037058",
      "Comune": "Savigno",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40060,
      "CodFisco": "I474",
      "Abitanti": 2788,
      "Link": "http://www.comuni-italiani.it/037/058/"
    },
    {
      "Istat": "010057",
      "Comune": "Savignone",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "I475",
      "Abitanti": 3249,
      "Link": "http://www.comuni-italiani.it/010/057/"
    },
    {
      "Istat": "017175",
      "Comune": "Saviore dell'Adamello",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25040,
      "CodFisco": "I476",
      "Abitanti": 1021,
      "Link": "http://www.comuni-italiani.it/017/175/"
    },
    {
      "Istat": "083093",
      "Comune": "Savoca",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98038,
      "CodFisco": "I477",
      "Abitanti": 1824,
      "Link": "http://www.comuni-italiani.it/083/093/"
    },
    {
      "Istat": "030108",
      "Comune": "Savogna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "I478",
      "Abitanti": 506,
      "Link": "http://www.comuni-italiani.it/030/108/"
    },
    {
      "Istat": "031022",
      "Comune": "Savogna d'Isonzo",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "I479",
      "Abitanti": 1739,
      "Link": "http://www.comuni-italiani.it/031/022/"
    },
    {
      "Istat": "076084",
      "Comune": "Savoia di Lucania",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85050,
      "CodFisco": "H730",
      "Abitanti": 1166,
      "Link": "http://www.comuni-italiani.it/076/084/"
    },
    {
      "Istat": "009056",
      "Comune": "Savona",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17100,
      "CodFisco": "I480",
      "Abitanti": 62553,
      "Link": "http://www.comuni-italiani.it/009/056/"
    },
    {
      "Istat": "068039",
      "Comune": "Scafa",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65027,
      "CodFisco": "I482",
      "Abitanti": 3900,
      "Link": "http://www.comuni-italiani.it/068/039/"
    },
    {
      "Istat": "065137",
      "Comune": "Scafati",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84018,
      "CodFisco": "I483",
      "Abitanti": 50794,
      "Link": "http://www.comuni-italiani.it/065/137/"
    },
    {
      "Istat": "004216",
      "Comune": "Scagnello",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "I484",
      "Abitanti": 210,
      "Link": "http://www.comuni-italiani.it/004/216/"
    },
    {
      "Istat": "065138",
      "Comune": "Scala",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "I486",
      "Abitanti": 1541,
      "Link": "http://www.comuni-italiani.it/065/138/"
    },
    {
      "Istat": "078137",
      "Comune": "Scala Coeli",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "I485",
      "Abitanti": 1181,
      "Link": "http://www.comuni-italiani.it/078/137/"
    },
    {
      "Istat": "018147",
      "Comune": "Scaldasole",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "I487",
      "Abitanti": 998,
      "Link": "http://www.comuni-italiani.it/018/147/"
    },
    {
      "Istat": "078138",
      "Comune": "Scalea",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87029,
      "CodFisco": "I489",
      "Abitanti": 10948,
      "Link": "http://www.comuni-italiani.it/078/138/"
    },
    {
      "Istat": "001260",
      "Comune": "Scalenghe",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "I490",
      "Abitanti": 3311,
      "Link": "http://www.comuni-italiani.it/001/260/"
    },
    {
      "Istat": "083094",
      "Comune": "Scaletta Zanclea",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98029,
      "CodFisco": "I492",
      "Abitanti": 2345,
      "Link": "http://www.comuni-italiani.it/083/094/"
    },
    {
      "Istat": "064097",
      "Comune": "Scampitella",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "I493",
      "Abitanti": 1288,
      "Link": "http://www.comuni-italiani.it/064/097/"
    },
    {
      "Istat": 101024,
      "Comune": "Scandale",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88831,
      "CodFisco": "I494",
      "Abitanti": 3300,
      "Link": "http://www.comuni-italiani.it/101/024/"
    },
    {
      "Istat": "035040",
      "Comune": "Scandiano",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42019,
      "CodFisco": "I496",
      "Abitanti": 25074,
      "Link": "http://www.comuni-italiani.it/035/040/"
    },
    {
      "Istat": "048041",
      "Comune": "Scandicci",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50018,
      "CodFisco": "B962",
      "Abitanti": 50309,
      "Link": "http://www.comuni-italiani.it/048/041/"
    },
    {
      "Istat": "019092",
      "Comune": "Scandolara Ravara",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26040,
      "CodFisco": "I497",
      "Abitanti": 1520,
      "Link": "http://www.comuni-italiani.it/019/092/"
    },
    {
      "Istat": "019093",
      "Comune": "Scandolara Ripa d'Oglio",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26047,
      "CodFisco": "I498",
      "Abitanti": 626,
      "Link": "http://www.comuni-italiani.it/019/093/"
    },
    {
      "Istat": "057064",
      "Comune": "Scandriglia",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02038",
      "CodFisco": "I499",
      "Abitanti": 3140,
      "Link": "http://www.comuni-italiani.it/057/064/"
    },
    {
      "Istat": "066093",
      "Comune": "Scanno",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67038,
      "CodFisco": "I501",
      "Abitanti": 1966,
      "Link": "http://www.comuni-italiani.it/066/093/"
    },
    {
      "Istat": "095051",
      "Comune": "Scano di Montiferro",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09078",
      "CodFisco": "I503",
      "Abitanti": 1592,
      "Link": "http://www.comuni-italiani.it/095/051/"
    },
    {
      "Istat": "053023",
      "Comune": "Scansano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58054,
      "CodFisco": "I504",
      "Abitanti": 4636,
      "Link": "http://www.comuni-italiani.it/053/023/"
    },
    {
      "Istat": "077031",
      "Comune": "Scanzano Jonico",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75020,
      "CodFisco": "M256",
      "Abitanti": 7255,
      "Link": "http://www.comuni-italiani.it/077/031/"
    },
    {
      "Istat": "016194",
      "Comune": "Scanzorosciate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "I506",
      "Abitanti": 9843,
      "Link": "http://www.comuni-italiani.it/016/194/"
    },
    {
      "Istat": "094048",
      "Comune": "Scapoli",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86070,
      "CodFisco": "I507",
      "Abitanti": 776,
      "Link": "http://www.comuni-italiani.it/094/048/"
    },
    {
      "Istat": "053024",
      "Comune": "Scarlino",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0566",
      "CAP": 58020,
      "CodFisco": "I510",
      "Abitanti": 3718,
      "Link": "http://www.comuni-italiani.it/053/024/"
    },
    {
      "Istat": "001261",
      "Comune": "Scarmagno",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "I511",
      "Abitanti": 811,
      "Link": "http://www.comuni-italiani.it/001/261/"
    },
    {
      "Istat": "004217",
      "Comune": "Scarnafigi",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12030,
      "CodFisco": "I512",
      "Abitanti": 2092,
      "Link": "http://www.comuni-italiani.it/004/217/"
    },
    {
      "Istat": "048042",
      "Comune": "Scarperia",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50038,
      "CodFisco": "I514",
      "Abitanti": 7809,
      "Link": "http://www.comuni-italiani.it/048/042/"
    },
    {
      "Istat": "021087",
      "Comune": "Scena",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39017,
      "CodFisco": "I519",
      "Abitanti": 2844,
      "Link": "http://www.comuni-italiani.it/021/087/"
    },
    {
      "Istat": "069087",
      "Comune": "Scerni",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66020,
      "CodFisco": "I520",
      "Abitanti": 3458,
      "Link": "http://www.comuni-italiani.it/069/087/"
    },
    {
      "Istat": "054046",
      "Comune": "Scheggia e Pascelupo",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06027",
      "CodFisco": "I522",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/054/046/"
    },
    {
      "Istat": "054047",
      "Comune": "Scheggino",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06040",
      "CodFisco": "I523",
      "Abitanti": 484,
      "Link": "http://www.comuni-italiani.it/054/047/"
    },
    {
      "Istat": "069088",
      "Comune": "Schiavi di Abruzzo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66045,
      "CodFisco": "I526",
      "Abitanti": 990,
      "Link": "http://www.comuni-italiani.it/069/088/"
    },
    {
      "Istat": "024099",
      "Comune": "Schiavon",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36060,
      "CodFisco": "I527",
      "Abitanti": 2598,
      "Link": "http://www.comuni-italiani.it/024/099/"
    },
    {
      "Istat": "013211",
      "Comune": "Schignano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "I529",
      "Abitanti": 914,
      "Link": "http://www.comuni-italiani.it/013/211/"
    },
    {
      "Istat": "016195",
      "Comune": "Schilpario",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "I530",
      "Abitanti": 1265,
      "Link": "http://www.comuni-italiani.it/016/195/"
    },
    {
      "Istat": "024100",
      "Comune": "Schio",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36015,
      "CodFisco": "I531",
      "Abitanti": 39566,
      "Link": "http://www.comuni-italiani.it/024/100/"
    },
    {
      "Istat": "020060",
      "Comune": "Schivenoglia",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "I532",
      "Abitanti": 1281,
      "Link": "http://www.comuni-italiani.it/020/060/"
    },
    {
      "Istat": "084041",
      "Comune": "Sciacca",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92019,
      "CodFisco": "I533",
      "Abitanti": 41066,
      "Link": "http://www.comuni-italiani.it/084/041/"
    },
    {
      "Istat": "082068",
      "Comune": "Sciara",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "I534",
      "Abitanti": 2856,
      "Link": "http://www.comuni-italiani.it/082/068/"
    },
    {
      "Istat": "088011",
      "Comune": "Scicli",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97018,
      "CodFisco": "I535",
      "Abitanti": 26556,
      "Link": "http://www.comuni-italiani.it/088/011/"
    },
    {
      "Istat": "080084",
      "Comune": "Scido",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89010,
      "CodFisco": "I536",
      "Abitanti": 998,
      "Link": "http://www.comuni-italiani.it/080/084/"
    },
    {
      "Istat": "078139",
      "Comune": "Scigliano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87057,
      "CodFisco": "D290",
      "Abitanti": 1371,
      "Link": "http://www.comuni-italiani.it/078/139/"
    },
    {
      "Istat": "080085",
      "Comune": "Scilla",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89058,
      "CodFisco": "I537",
      "Abitanti": 5139,
      "Link": "http://www.comuni-italiani.it/080/085/"
    },
    {
      "Istat": "082081",
      "Comune": "Scillato",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90020,
      "CodFisco": "I538",
      "Abitanti": 637,
      "Link": "http://www.comuni-italiani.it/082/081/"
    },
    {
      "Istat": "001262",
      "Comune": "Sciolze",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "I539",
      "Abitanti": 1515,
      "Link": "http://www.comuni-italiani.it/001/262/"
    },
    {
      "Istat": "063077",
      "Comune": "Scisciano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "I540",
      "Abitanti": 5769,
      "Link": "http://www.comuni-italiani.it/063/077/"
    },
    {
      "Istat": "082069",
      "Comune": "Sclafani Bagni",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90020,
      "CodFisco": "I541",
      "Abitanti": 454,
      "Link": "http://www.comuni-italiani.it/082/069/"
    },
    {
      "Istat": "066094",
      "Comune": "Scontrone",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "I543",
      "Abitanti": 596,
      "Link": "http://www.comuni-italiani.it/066/094/"
    },
    {
      "Istat": "002134",
      "Comune": "Scopa",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13027,
      "CodFisco": "I544",
      "Abitanti": 406,
      "Link": "http://www.comuni-italiani.it/002/134/"
    },
    {
      "Istat": "002135",
      "Comune": "Scopello",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13028,
      "CodFisco": "I545",
      "Abitanti": 418,
      "Link": "http://www.comuni-italiani.it/002/135/"
    },
    {
      "Istat": "066095",
      "Comune": "Scoppito",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67019,
      "CodFisco": "I546",
      "Abitanti": 3286,
      "Link": "http://www.comuni-italiani.it/066/095/"
    },
    {
      "Istat": "087049",
      "Comune": "Scordia",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95048,
      "CodFisco": "I548",
      "Abitanti": 17266,
      "Link": "http://www.comuni-italiani.it/087/049/"
    },
    {
      "Istat": "075073",
      "Comune": "Scorrano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "I549",
      "Abitanti": 7027,
      "Link": "http://www.comuni-italiani.it/075/073/"
    },
    {
      "Istat": "027037",
      "Comune": "ScorzË",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30037,
      "CodFisco": "I551",
      "Abitanti": 19052,
      "Link": "http://www.comuni-italiani.it/027/037/"
    },
    {
      "Istat": "066096",
      "Comune": "Scurcola Marsicana",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67068,
      "CodFisco": "I553",
      "Abitanti": 2813,
      "Link": "http://www.comuni-italiani.it/066/096/"
    },
    {
      "Istat": "022171",
      "Comune": "Scurelle",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "I554",
      "Abitanti": 1403,
      "Link": "http://www.comuni-italiani.it/022/171/"
    },
    {
      "Istat": "005103",
      "Comune": "Scurzolengo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "I555",
      "Abitanti": 610,
      "Link": "http://www.comuni-italiani.it/005/103/"
    },
    {
      "Istat": "008057",
      "Comune": "Seborga",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18012,
      "CodFisco": "I556",
      "Abitanti": 319,
      "Link": "http://www.comuni-italiani.it/008/057/"
    },
    {
      "Istat": "066097",
      "Comune": "Secinaro",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67029,
      "CodFisco": "I558",
      "Abitanti": 415,
      "Link": "http://www.comuni-italiani.it/066/097/"
    },
    {
      "Istat": "075074",
      "Comune": "SeclÏ",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73050,
      "CodFisco": "I559",
      "Abitanti": 1938,
      "Link": "http://www.comuni-italiani.it/075/074/"
    },
    {
      "Istat": "098052",
      "Comune": "Secugnago",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26826,
      "CodFisco": "I561",
      "Abitanti": 2023,
      "Link": "http://www.comuni-italiani.it/098/052/"
    },
    {
      "Istat": "030109",
      "Comune": "Sedegliano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33039,
      "CodFisco": "I562",
      "Abitanti": 3926,
      "Link": "http://www.comuni-italiani.it/030/109/"
    },
    {
      "Istat": "025053",
      "Comune": "Sedico",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32036,
      "CodFisco": "I563",
      "Abitanti": 9928,
      "Link": "http://www.comuni-italiani.it/025/053/"
    },
    {
      "Istat": "095052",
      "Comune": "Sedilo",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09076",
      "CodFisco": "I564",
      "Abitanti": 2256,
      "Link": "http://www.comuni-italiani.it/095/052/"
    },
    {
      "Istat": "090065",
      "Comune": "Sedini",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07035",
      "CodFisco": "I565",
      "Abitanti": 1412,
      "Link": "http://www.comuni-italiani.it/090/065/"
    },
    {
      "Istat": "015204",
      "Comune": "Sedriano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20018,
      "CodFisco": "I566",
      "Abitanti": 11277,
      "Link": "http://www.comuni-italiani.it/015/204/"
    },
    {
      "Istat": "016196",
      "Comune": "Sedrina",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "I567",
      "Abitanti": 2559,
      "Link": "http://www.comuni-italiani.it/016/196/"
    },
    {
      "Istat": "043050",
      "Comune": "Sefro",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62025,
      "CodFisco": "I569",
      "Abitanti": 446,
      "Link": "http://www.comuni-italiani.it/043/050/"
    },
    {
      "Istat": 106017,
      "Comune": "Segariu",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I570",
      "Abitanti": 1315,
      "Link": "http://www.comuni-italiani.it/106/017/"
    },
    {
      "Istat": "053025",
      "Comune": "Seggiano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58038,
      "CodFisco": "I571",
      "Abitanti": 998,
      "Link": "http://www.comuni-italiani.it/053/025/"
    },
    {
      "Istat": "058102",
      "Comune": "Segni",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00037",
      "CodFisco": "I573",
      "Abitanti": 9439,
      "Link": "http://www.comuni-italiani.it/058/102/"
    },
    {
      "Istat": "022172",
      "Comune": "Segonzano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38047,
      "CodFisco": "I576",
      "Abitanti": 1536,
      "Link": "http://www.comuni-italiani.it/022/172/"
    },
    {
      "Istat": "015205",
      "Comune": "Segrate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "I577",
      "Abitanti": 34352,
      "Link": "http://www.comuni-italiani.it/015/205/"
    },
    {
      "Istat": "026079",
      "Comune": "Segusino",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31040,
      "CodFisco": "I578",
      "Abitanti": 1974,
      "Link": "http://www.comuni-italiani.it/026/079/"
    },
    {
      "Istat": "092068",
      "Comune": "Selargius",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09047",
      "CodFisco": "I580",
      "Abitanti": 29169,
      "Link": "http://www.comuni-italiani.it/092/068/"
    },
    {
      "Istat": "057065",
      "Comune": "Selci",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "I581",
      "Abitanti": 1112,
      "Link": "http://www.comuni-italiani.it/057/065/"
    },
    {
      "Istat": "092069",
      "Comune": "Selegas",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I582",
      "Abitanti": 1438,
      "Link": "http://www.comuni-italiani.it/092/069/"
    },
    {
      "Istat": "054048",
      "Comune": "Sellano",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06030",
      "CodFisco": "I585",
      "Abitanti": 1151,
      "Link": "http://www.comuni-italiani.it/054/048/"
    },
    {
      "Istat": "017176",
      "Comune": "Sellero",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "I588",
      "Abitanti": 1497,
      "Link": "http://www.comuni-italiani.it/017/176/"
    },
    {
      "Istat": "079126",
      "Comune": "Sellia",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "I589",
      "Abitanti": 537,
      "Link": "http://www.comuni-italiani.it/079/126/"
    },
    {
      "Istat": "079127",
      "Comune": "Sellia Marina",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "I590",
      "Abitanti": 6619,
      "Link": "http://www.comuni-italiani.it/079/127/"
    },
    {
      "Istat": "021088",
      "Comune": "Selva dei Molini",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "I593",
      "Abitanti": 1474,
      "Link": "http://www.comuni-italiani.it/021/088/"
    },
    {
      "Istat": "025054",
      "Comune": "Selva di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "I592",
      "Abitanti": 520,
      "Link": "http://www.comuni-italiani.it/025/054/"
    },
    {
      "Istat": "023080",
      "Comune": "Selva di Progno",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "I594",
      "Abitanti": 954,
      "Link": "http://www.comuni-italiani.it/023/080/"
    },
    {
      "Istat": "021089",
      "Comune": "Selva di Val Gardena",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39048,
      "CodFisco": "I591",
      "Abitanti": 2642,
      "Link": "http://www.comuni-italiani.it/021/089/"
    },
    {
      "Istat": "028086",
      "Comune": "Selvazzano Dentro",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "I595",
      "Abitanti": 22305,
      "Link": "http://www.comuni-italiani.it/028/086/"
    },
    {
      "Istat": "096061",
      "Comune": "Selve Marcone",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13841,
      "CodFisco": "I596",
      "Abitanti": 100,
      "Link": "http://www.comuni-italiani.it/096/061/"
    },
    {
      "Istat": "016197",
      "Comune": "Selvino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "I597",
      "Abitanti": 2015,
      "Link": "http://www.comuni-italiani.it/016/197/"
    },
    {
      "Istat": "090066",
      "Comune": "Semestene",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "I598",
      "Abitanti": 174,
      "Link": "http://www.comuni-italiani.it/090/066/"
    },
    {
      "Istat": "018148",
      "Comune": "Semiana",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "I599",
      "Abitanti": 251,
      "Link": "http://www.comuni-italiani.it/018/148/"
    },
    {
      "Istat": "080086",
      "Comune": "Seminara",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89028,
      "CodFisco": "I600",
      "Abitanti": 2993,
      "Link": "http://www.comuni-italiani.it/080/086/"
    },
    {
      "Istat": "053028",
      "Comune": "Semproniano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58055,
      "CodFisco": "I601",
      "Abitanti": 1192,
      "Link": "http://www.comuni-italiani.it/053/028/"
    },
    {
      "Istat": "015206",
      "Comune": "Senago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20030,
      "CodFisco": "I602",
      "Abitanti": 21273,
      "Link": "http://www.comuni-italiani.it/015/206/"
    },
    {
      "Istat": "021091",
      "Comune": "Senales",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "I604",
      "Abitanti": 1337,
      "Link": "http://www.comuni-italiani.it/021/091/"
    },
    {
      "Istat": "021118",
      "Comune": "Senale-San Felice",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 39010,
      "CodFisco": "I603",
      "Abitanti": 773,
      "Link": "http://www.comuni-italiani.it/021/118/"
    },
    {
      "Istat": "095053",
      "Comune": "Seneghe",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "I605",
      "Abitanti": 1873,
      "Link": "http://www.comuni-italiani.it/095/053/"
    },
    {
      "Istat": "064098",
      "Comune": "Senerchia",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "I606",
      "Abitanti": 845,
      "Link": "http://www.comuni-italiani.it/064/098/"
    },
    {
      "Istat": "017177",
      "Comune": "Seniga",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25020,
      "CodFisco": "I607",
      "Abitanti": 1615,
      "Link": "http://www.comuni-italiani.it/017/177/"
    },
    {
      "Istat": "042045",
      "Comune": "Senigallia",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60019,
      "CodFisco": "I608",
      "Abitanti": 45027,
      "Link": "http://www.comuni-italiani.it/042/045/"
    },
    {
      "Istat": "095054",
      "Comune": "Senis",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "I609",
      "Abitanti": 484,
      "Link": "http://www.comuni-italiani.it/095/054/"
    },
    {
      "Istat": "076085",
      "Comune": "Senise",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85038,
      "CodFisco": "I610",
      "Abitanti": 7304,
      "Link": "http://www.comuni-italiani.it/076/085/"
    },
    {
      "Istat": "013212",
      "Comune": "Senna Comasco",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "I611",
      "Abitanti": 3211,
      "Link": "http://www.comuni-italiani.it/013/212/"
    },
    {
      "Istat": "098053",
      "Comune": "Senna Lodigiana",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26856,
      "CodFisco": "I612",
      "Abitanti": 2051,
      "Link": "http://www.comuni-italiani.it/098/053/"
    },
    {
      "Istat": "095055",
      "Comune": "Sennariolo",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09078",
      "CodFisco": "I613",
      "Abitanti": 188,
      "Link": "http://www.comuni-italiani.it/095/055/"
    },
    {
      "Istat": "090067",
      "Comune": "Sennori",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07036",
      "CodFisco": "I614",
      "Abitanti": 7405,
      "Link": "http://www.comuni-italiani.it/090/067/"
    },
    {
      "Istat": "092070",
      "Comune": "SenorbÏ",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I615",
      "Abitanti": 4745,
      "Link": "http://www.comuni-italiani.it/092/070/"
    },
    {
      "Istat": "070075",
      "Comune": "Sepino",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86017,
      "CodFisco": "I618",
      "Abitanti": 2041,
      "Link": "http://www.comuni-italiani.it/070/075/"
    },
    {
      "Istat": 103063,
      "Comune": "Seppiana",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28843,
      "CodFisco": "I619",
      "Abitanti": 159,
      "Link": "http://www.comuni-italiani.it/103/063/"
    },
    {
      "Istat": "093042",
      "Comune": "Sequals",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "I621",
      "Abitanti": 2258,
      "Link": "http://www.comuni-italiani.it/093/042/"
    },
    {
      "Istat": "046028",
      "Comune": "Seravezza",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55047,
      "CodFisco": "I622",
      "Abitanti": 13315,
      "Link": "http://www.comuni-italiani.it/046/028/"
    },
    {
      "Istat": "092071",
      "Comune": "Serdiana",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I624",
      "Abitanti": 2633,
      "Link": "http://www.comuni-italiani.it/092/071/"
    },
    {
      "Istat": 108039,
      "Comune": "Seregno",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20831,
      "CodFisco": "I625",
      "Abitanti": 43163,
      "Link": "http://www.comuni-italiani.it/108/039/"
    },
    {
      "Istat": "025055",
      "Comune": "Seren del Grappa",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "I626",
      "Abitanti": 2611,
      "Link": "http://www.comuni-italiani.it/025/055/"
    },
    {
      "Istat": "019094",
      "Comune": "Sergnano",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "I627",
      "Abitanti": 3675,
      "Link": "http://www.comuni-italiani.it/019/094/"
    },
    {
      "Istat": "016198",
      "Comune": "Seriate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24068,
      "CodFisco": "I628",
      "Abitanti": 24297,
      "Link": "http://www.comuni-italiani.it/016/198/"
    },
    {
      "Istat": "016199",
      "Comune": "Serina",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24017,
      "CodFisco": "I629",
      "Abitanti": 2187,
      "Link": "http://www.comuni-italiani.it/016/199/"
    },
    {
      "Istat": "064099",
      "Comune": "Serino",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83028,
      "CodFisco": "I630",
      "Abitanti": 7254,
      "Link": "http://www.comuni-italiani.it/064/099/"
    },
    {
      "Istat": "017178",
      "Comune": "Serle",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25080,
      "CodFisco": "I631",
      "Abitanti": 3119,
      "Link": "http://www.comuni-italiani.it/017/178/"
    },
    {
      "Istat": "020061",
      "Comune": "Sermide",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46028,
      "CodFisco": "I632",
      "Abitanti": 6428,
      "Link": "http://www.comuni-italiani.it/020/061/"
    },
    {
      "Istat": "059027",
      "Comune": "Sermoneta",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04013",
      "CodFisco": "I634",
      "Abitanti": 9156,
      "Link": "http://www.comuni-italiani.it/059/027/"
    },
    {
      "Istat": "026080",
      "Comune": "Sernaglia della Battaglia",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "I635",
      "Abitanti": 6358,
      "Link": "http://www.comuni-italiani.it/026/080/"
    },
    {
      "Istat": "014059",
      "Comune": "Sernio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "I636",
      "Abitanti": 503,
      "Link": "http://www.comuni-italiani.it/014/059/"
    },
    {
      "Istat": "005104",
      "Comune": "Serole",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14050,
      "CodFisco": "I637",
      "Abitanti": 141,
      "Link": "http://www.comuni-italiani.it/005/104/"
    },
    {
      "Istat": "078140",
      "Comune": "Serra d'Aiello",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0982",
      "CAP": 87030,
      "CodFisco": "I642",
      "Abitanti": 659,
      "Link": "http://www.comuni-italiani.it/078/140/"
    },
    {
      "Istat": "042046",
      "Comune": "Serra de' Conti",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60030,
      "CodFisco": "I643",
      "Abitanti": 3741,
      "Link": "http://www.comuni-italiani.it/042/046/"
    },
    {
      "Istat": "078141",
      "Comune": "Serra Pedace",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "I650",
      "Abitanti": 1006,
      "Link": "http://www.comuni-italiani.it/078/141/"
    },
    {
      "Istat": "010058",
      "Comune": "Serra RiccÚ",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "I640",
      "Abitanti": 7994,
      "Link": "http://www.comuni-italiani.it/010/058/"
    },
    {
      "Istat": 102037,
      "Comune": "Serra San Bruno",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89822,
      "CodFisco": "I639",
      "Abitanti": 6971,
      "Link": "http://www.comuni-italiani.it/102/037/"
    },
    {
      "Istat": "042047",
      "Comune": "Serra San Quirico",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60048,
      "CodFisco": "I653",
      "Abitanti": 3036,
      "Link": "http://www.comuni-italiani.it/042/047/"
    },
    {
      "Istat": "041061",
      "Comune": "Serra Sant'Abbondio",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61040,
      "CodFisco": "I654",
      "Abitanti": 1113,
      "Link": "http://www.comuni-italiani.it/041/061/"
    },
    {
      "Istat": "071053",
      "Comune": "Serracapriola",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71010,
      "CodFisco": "I641",
      "Abitanti": 4106,
      "Link": "http://www.comuni-italiani.it/071/053/"
    },
    {
      "Istat": "085018",
      "Comune": "Serradifalco",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "I644",
      "Abitanti": 6371,
      "Link": "http://www.comuni-italiani.it/085/018/"
    },
    {
      "Istat": "004218",
      "Comune": "Serralunga d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "I646",
      "Abitanti": 535,
      "Link": "http://www.comuni-italiani.it/004/218/"
    },
    {
      "Istat": "006159",
      "Comune": "Serralunga di Crea",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "I645",
      "Abitanti": 578,
      "Link": "http://www.comuni-italiani.it/006/159/"
    },
    {
      "Istat": 106018,
      "Comune": "Serramanna",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09038",
      "CodFisco": "I647",
      "Abitanti": 9333,
      "Link": "http://www.comuni-italiani.it/106/018/"
    },
    {
      "Istat": "036042",
      "Comune": "Serramazzoni",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41028,
      "CodFisco": "F357",
      "Abitanti": 8300,
      "Link": "http://www.comuni-italiani.it/036/042/"
    },
    {
      "Istat": "065139",
      "Comune": "Serramezzana",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "I648",
      "Abitanti": 355,
      "Link": "http://www.comuni-italiani.it/065/139/"
    },
    {
      "Istat": "068040",
      "Comune": "Serramonacesca",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65025,
      "CodFisco": "I649",
      "Abitanti": 625,
      "Link": "http://www.comuni-italiani.it/068/040/"
    },
    {
      "Istat": "043051",
      "Comune": "Serrapetrona",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62020,
      "CodFisco": "I651",
      "Abitanti": 1019,
      "Link": "http://www.comuni-italiani.it/043/051/"
    },
    {
      "Istat": "063078",
      "Comune": "Serrara Fontana",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80070,
      "CodFisco": "I652",
      "Abitanti": 3205,
      "Link": "http://www.comuni-italiani.it/063/078/"
    },
    {
      "Istat": "079129",
      "Comune": "Serrastretta",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88040,
      "CodFisco": "I655",
      "Abitanti": 3299,
      "Link": "http://www.comuni-italiani.it/079/129/"
    },
    {
      "Istat": "080087",
      "Comune": "Serrata",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "I656",
      "Abitanti": 922,
      "Link": "http://www.comuni-italiani.it/080/087/"
    },
    {
      "Istat": "020062",
      "Comune": "Serravalle a Po",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46030,
      "CodFisco": "I662",
      "Abitanti": 1650,
      "Link": "http://www.comuni-italiani.it/020/062/"
    },
    {
      "Istat": "043052",
      "Comune": "Serravalle di Chienti",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62038,
      "CodFisco": "I661",
      "Abitanti": 1115,
      "Link": "http://www.comuni-italiani.it/043/052/"
    },
    {
      "Istat": "004219",
      "Comune": "Serravalle Langhe",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "I659",
      "Abitanti": 326,
      "Link": "http://www.comuni-italiani.it/004/219/"
    },
    {
      "Istat": "047020",
      "Comune": "Serravalle Pistoiese",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0573",
      "CAP": 51030,
      "CodFisco": "I660",
      "Abitanti": 11561,
      "Link": "http://www.comuni-italiani.it/047/020/"
    },
    {
      "Istat": "006160",
      "Comune": "Serravalle Scrivia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15069,
      "CodFisco": "I657",
      "Abitanti": 6445,
      "Link": "http://www.comuni-italiani.it/006/160/"
    },
    {
      "Istat": "002137",
      "Comune": "Serravalle Sesia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13037,
      "CodFisco": "I663",
      "Abitanti": 5162,
      "Link": "http://www.comuni-italiani.it/002/137/"
    },
    {
      "Istat": "065140",
      "Comune": "Serre",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84028,
      "CodFisco": "I666",
      "Abitanti": 4045,
      "Link": "http://www.comuni-italiani.it/065/140/"
    },
    {
      "Istat": 106019,
      "Comune": "Serrenti",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09027",
      "CodFisco": "I667",
      "Abitanti": 5078,
      "Link": "http://www.comuni-italiani.it/106/019/"
    },
    {
      "Istat": "092120",
      "Comune": "Serri",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "I668",
      "Abitanti": 686,
      "Link": "http://www.comuni-italiani.it/092/120/"
    },
    {
      "Istat": "060071",
      "Comune": "Serrone",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "I669",
      "Abitanti": 3138,
      "Link": "http://www.comuni-italiani.it/060/071/"
    },
    {
      "Istat": "041062",
      "Comune": "Serrungarina",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61030,
      "CodFisco": "I670",
      "Abitanti": 2592,
      "Link": "http://www.comuni-italiani.it/041/062/"
    },
    {
      "Istat": "079130",
      "Comune": "Sersale",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88054,
      "CodFisco": "I671",
      "Abitanti": 4827,
      "Link": "http://www.comuni-italiani.it/079/130/"
    },
    {
      "Istat": 109038,
      "Comune": "Servigliano",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63839,
      "CodFisco": "C070",
      "Abitanti": 2368,
      "Link": "http://www.comuni-italiani.it/109/038/"
    },
    {
      "Istat": "061088",
      "Comune": "Sessa Aurunca",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81037,
      "CodFisco": "I676",
      "Abitanti": 22603,
      "Link": "http://www.comuni-italiani.it/061/088/"
    },
    {
      "Istat": "065141",
      "Comune": "Sessa Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84074,
      "CodFisco": "I677",
      "Abitanti": 1381,
      "Link": "http://www.comuni-italiani.it/065/141/"
    },
    {
      "Istat": "005105",
      "Comune": "Sessame",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14058,
      "CodFisco": "I678",
      "Abitanti": 286,
      "Link": "http://www.comuni-italiani.it/005/105/"
    },
    {
      "Istat": "094049",
      "Comune": "Sessano del Molise",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86097,
      "CodFisco": "I679",
      "Abitanti": 763,
      "Link": "http://www.comuni-italiani.it/094/049/"
    },
    {
      "Istat": "011028",
      "Comune": "Sesta Godano",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "E070",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/011/028/"
    },
    {
      "Istat": "051035",
      "Comune": "Sestino",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52038,
      "CodFisco": "I681",
      "Abitanti": 1451,
      "Link": "http://www.comuni-italiani.it/051/035/"
    },
    {
      "Istat": "021092",
      "Comune": "Sesto",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "I687",
      "Abitanti": 1952,
      "Link": "http://www.comuni-italiani.it/021/092/"
    },
    {
      "Istat": "093043",
      "Comune": "Sesto al Reghena",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33079,
      "CodFisco": "I686",
      "Abitanti": 6296,
      "Link": "http://www.comuni-italiani.it/093/043/"
    },
    {
      "Istat": "012120",
      "Comune": "Sesto Calende",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21018,
      "CodFisco": "I688",
      "Abitanti": 10830,
      "Link": "http://www.comuni-italiani.it/012/120/"
    },
    {
      "Istat": "094050",
      "Comune": "Sesto Campano",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86078,
      "CodFisco": "I682",
      "Abitanti": 2419,
      "Link": "http://www.comuni-italiani.it/094/050/"
    },
    {
      "Istat": "019095",
      "Comune": "Sesto ed Uniti",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26028,
      "CodFisco": "I683",
      "Abitanti": 3000,
      "Link": "http://www.comuni-italiani.it/019/095/"
    },
    {
      "Istat": "048043",
      "Comune": "Sesto Fiorentino",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50019,
      "CodFisco": "I684",
      "Abitanti": 47623,
      "Link": "http://www.comuni-italiani.it/048/043/"
    },
    {
      "Istat": "015209",
      "Comune": "Sesto San Giovanni",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20099,
      "CodFisco": "I690",
      "Abitanti": 81130,
      "Link": "http://www.comuni-italiani.it/015/209/"
    },
    {
      "Istat": "036043",
      "Comune": "Sestola",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "0536",
      "CAP": 41029,
      "CodFisco": "I689",
      "Abitanti": 2642,
      "Link": "http://www.comuni-italiani.it/036/043/"
    },
    {
      "Istat": "010059",
      "Comune": "Sestri Levante",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16039,
      "CodFisco": "I693",
      "Abitanti": 18794,
      "Link": "http://www.comuni-italiani.it/010/059/"
    },
    {
      "Istat": "001263",
      "Comune": "Sestriere",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10058,
      "CodFisco": "I692",
      "Abitanti": 889,
      "Link": "http://www.comuni-italiani.it/001/263/"
    },
    {
      "Istat": "092074",
      "Comune": "Sestu",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09028",
      "CodFisco": "I695",
      "Abitanti": 19921,
      "Link": "http://www.comuni-italiani.it/092/074/"
    },
    {
      "Istat": "015210",
      "Comune": "Settala",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "I696",
      "Abitanti": 7465,
      "Link": "http://www.comuni-italiani.it/015/210/"
    },
    {
      "Istat": "060072",
      "Comune": "Settefrati",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "I697",
      "Abitanti": 809,
      "Link": "http://www.comuni-italiani.it/060/072/"
    },
    {
      "Istat": "005106",
      "Comune": "Settime",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "I698",
      "Abitanti": 603,
      "Link": "http://www.comuni-italiani.it/005/106/"
    },
    {
      "Istat": "015211",
      "Comune": "Settimo Milanese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20019,
      "CodFisco": "I700",
      "Abitanti": 19464,
      "Link": "http://www.comuni-italiani.it/015/211/"
    },
    {
      "Istat": "001264",
      "Comune": "Settimo Rottaro",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "I701",
      "Abitanti": 523,
      "Link": "http://www.comuni-italiani.it/001/264/"
    },
    {
      "Istat": "092075",
      "Comune": "Settimo San Pietro",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I699",
      "Abitanti": 6576,
      "Link": "http://www.comuni-italiani.it/092/075/"
    },
    {
      "Istat": "001265",
      "Comune": "Settimo Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10036,
      "CodFisco": "I703",
      "Abitanti": 47790,
      "Link": "http://www.comuni-italiani.it/001/265/"
    },
    {
      "Istat": "001266",
      "Comune": "Settimo Vittone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "I702",
      "Abitanti": 1576,
      "Link": "http://www.comuni-italiani.it/001/266/"
    },
    {
      "Istat": "079131",
      "Comune": "Settingiano",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88040,
      "CodFisco": "I704",
      "Abitanti": 2902,
      "Link": "http://www.comuni-italiani.it/079/131/"
    },
    {
      "Istat": 106020,
      "Comune": "Setzu",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09029",
      "CodFisco": "I705",
      "Abitanti": 146,
      "Link": "http://www.comuni-italiani.it/106/020/"
    },
    {
      "Istat": 105015,
      "Comune": "Seui",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08037",
      "CodFisco": "I706",
      "Abitanti": 1392,
      "Link": "http://www.comuni-italiani.it/105/015/"
    },
    {
      "Istat": "092121",
      "Comune": "Seulo",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "I707",
      "Abitanti": 909,
      "Link": "http://www.comuni-italiani.it/092/121/"
    },
    {
      "Istat": 108040,
      "Comune": "Seveso",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20822,
      "CodFisco": "I709",
      "Abitanti": 22877,
      "Link": "http://www.comuni-italiani.it/108/040/"
    },
    {
      "Istat": "006161",
      "Comune": "Sezzadio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15079,
      "CodFisco": "I711",
      "Abitanti": 1295,
      "Link": "http://www.comuni-italiani.it/006/161/"
    },
    {
      "Istat": "059028",
      "Comune": "Sezze",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04018",
      "CodFisco": "I712",
      "Abitanti": 24790,
      "Link": "http://www.comuni-italiani.it/059/028/"
    },
    {
      "Istat": "022173",
      "Comune": "Sfruz",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "I714",
      "Abitanti": 331,
      "Link": "http://www.comuni-italiani.it/022/173/"
    },
    {
      "Istat": "032005",
      "Comune": "Sgonico",
      "Provincia": "TS",
      "Regione": "FVG",
      "Prefisso": "040",
      "CAP": 34010,
      "CodFisco": "I715",
      "Abitanti": 2091,
      "Link": "http://www.comuni-italiani.it/032/005/"
    },
    {
      "Istat": "060073",
      "Comune": "Sgurgola",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "I716",
      "Abitanti": 2709,
      "Link": "http://www.comuni-italiani.it/060/073/"
    },
    {
      "Istat": "095056",
      "Comune": "Siamaggiore",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "I717",
      "Abitanti": 983,
      "Link": "http://www.comuni-italiani.it/095/056/"
    },
    {
      "Istat": "095057",
      "Comune": "Siamanna",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "I718",
      "Abitanti": 837,
      "Link": "http://www.comuni-italiani.it/095/057/"
    },
    {
      "Istat": "065142",
      "Comune": "Siano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 84088,
      "CodFisco": "I720",
      "Abitanti": 10324,
      "Link": "http://www.comuni-italiani.it/065/142/"
    },
    {
      "Istat": "095076",
      "Comune": "Siapiccia",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "I721",
      "Abitanti": 373,
      "Link": "http://www.comuni-italiani.it/095/076/"
    },
    {
      "Istat": "065143",
      "Comune": "Sicignano degli Alburni",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84029,
      "CodFisco": "M253",
      "Abitanti": 3311,
      "Link": "http://www.comuni-italiani.it/065/143/"
    },
    {
      "Istat": "084042",
      "Comune": "Siculiana",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 92010,
      "CodFisco": "I723",
      "Abitanti": 4587,
      "Link": "http://www.comuni-italiani.it/084/042/"
    },
    {
      "Istat": 106021,
      "Comune": "Siddi",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "I724",
      "Abitanti": 708,
      "Link": "http://www.comuni-italiani.it/106/021/"
    },
    {
      "Istat": "080088",
      "Comune": "Siderno",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89048,
      "CodFisco": "I725",
      "Abitanti": 18176,
      "Link": "http://www.comuni-italiani.it/080/088/"
    },
    {
      "Istat": "052032",
      "Comune": "Siena",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53100,
      "CodFisco": "I726",
      "Abitanti": 54543,
      "Link": "http://www.comuni-italiani.it/052/032/"
    },
    {
      "Istat": "054049",
      "Comune": "Sigillo",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06028",
      "CodFisco": "I727",
      "Abitanti": 2532,
      "Link": "http://www.comuni-italiani.it/054/049/"
    },
    {
      "Istat": "048044",
      "Comune": "Signa",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50058,
      "CodFisco": "I728",
      "Abitanti": 18510,
      "Link": "http://www.comuni-italiani.it/048/044/"
    },
    {
      "Istat": "021093",
      "Comune": "Silandro",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39028,
      "CodFisco": "I729",
      "Abitanti": 5998,
      "Link": "http://www.comuni-italiani.it/021/093/"
    },
    {
      "Istat": "091083",
      "Comune": "Silanus",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08017",
      "CodFisco": "I730",
      "Abitanti": 2210,
      "Link": "http://www.comuni-italiani.it/091/083/"
    },
    {
      "Istat": "026081",
      "Comune": "Silea",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31057,
      "CodFisco": "F116",
      "Abitanti": 10118,
      "Link": "http://www.comuni-italiani.it/026/081/"
    },
    {
      "Istat": "090068",
      "Comune": "Siligo",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "I732",
      "Abitanti": 943,
      "Link": "http://www.comuni-italiani.it/090/068/"
    },
    {
      "Istat": "092078",
      "Comune": "Siliqua",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "I734",
      "Abitanti": 3973,
      "Link": "http://www.comuni-italiani.it/092/078/"
    },
    {
      "Istat": "092079",
      "Comune": "Silius",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I735",
      "Abitanti": 1280,
      "Link": "http://www.comuni-italiani.it/092/079/"
    },
    {
      "Istat": "046029",
      "Comune": "Sillano",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55030,
      "CodFisco": "I737",
      "Abitanti": 706,
      "Link": "http://www.comuni-italiani.it/046/029/"
    },
    {
      "Istat": "003138",
      "Comune": "Sillavengo",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28064,
      "CodFisco": "I736",
      "Abitanti": 587,
      "Link": "http://www.comuni-italiani.it/003/138/"
    },
    {
      "Istat": "006162",
      "Comune": "Silvano d'Orba",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "I738",
      "Abitanti": 2042,
      "Link": "http://www.comuni-italiani.it/006/162/"
    },
    {
      "Istat": "018149",
      "Comune": "Silvano Pietra",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "I739",
      "Abitanti": 693,
      "Link": "http://www.comuni-italiani.it/018/149/"
    },
    {
      "Istat": "067040",
      "Comune": "Silvi",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 64028,
      "CodFisco": "I741",
      "Abitanti": 15766,
      "Link": "http://www.comuni-italiani.it/067/040/"
    },
    {
      "Istat": "095058",
      "Comune": "Simala",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "I742",
      "Abitanti": 358,
      "Link": "http://www.comuni-italiani.it/095/058/"
    },
    {
      "Istat": "095059",
      "Comune": "Simaxis",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09088",
      "CodFisco": "I743",
      "Abitanti": 2286,
      "Link": "http://www.comuni-italiani.it/095/059/"
    },
    {
      "Istat": 102038,
      "Comune": "Simbario",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89822,
      "CodFisco": "I744",
      "Abitanti": 989,
      "Link": "http://www.comuni-italiani.it/102/038/"
    },
    {
      "Istat": "079133",
      "Comune": "Simeri Crichi",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "I745",
      "Abitanti": 4550,
      "Link": "http://www.comuni-italiani.it/079/133/"
    },
    {
      "Istat": "083095",
      "Comune": "Sinagra",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98069,
      "CodFisco": "I747",
      "Abitanti": 2781,
      "Link": "http://www.comuni-italiani.it/083/095/"
    },
    {
      "Istat": "052033",
      "Comune": "Sinalunga",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53048,
      "CodFisco": "A468",
      "Abitanti": 12926,
      "Link": "http://www.comuni-italiani.it/052/033/"
    },
    {
      "Istat": "091084",
      "Comune": "Sindia",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08018",
      "CodFisco": "I748",
      "Abitanti": 1845,
      "Link": "http://www.comuni-italiani.it/091/084/"
    },
    {
      "Istat": "095060",
      "Comune": "Sini",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "I749",
      "Abitanti": 523,
      "Link": "http://www.comuni-italiani.it/095/060/"
    },
    {
      "Istat": "004220",
      "Comune": "Sinio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "I750",
      "Abitanti": 525,
      "Link": "http://www.comuni-italiani.it/004/220/"
    },
    {
      "Istat": "091085",
      "Comune": "Siniscola",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08029",
      "CodFisco": "I751",
      "Abitanti": 11687,
      "Link": "http://www.comuni-italiani.it/091/085/"
    },
    {
      "Istat": "092080",
      "Comune": "Sinnai",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09048",
      "CodFisco": "I752",
      "Abitanti": 16785,
      "Link": "http://www.comuni-italiani.it/092/080/"
    },
    {
      "Istat": "080089",
      "Comune": "Sinopoli",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89020,
      "CodFisco": "I753",
      "Abitanti": 2181,
      "Link": "http://www.comuni-italiani.it/080/089/"
    },
    {
      "Istat": "089017",
      "Comune": "Siracusa",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96100,
      "CodFisco": "I754",
      "Abitanti": 123850,
      "Link": "http://www.comuni-italiani.it/089/017/"
    },
    {
      "Istat": "064100",
      "Comune": "Sirignano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "I756",
      "Abitanti": 3028,
      "Link": "http://www.comuni-italiani.it/064/100/"
    },
    {
      "Istat": "095061",
      "Comune": "Siris",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "I757",
      "Abitanti": 231,
      "Link": "http://www.comuni-italiani.it/095/061/"
    },
    {
      "Istat": "017179",
      "Comune": "Sirmione",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25019,
      "CodFisco": "I633",
      "Abitanti": 8150,
      "Link": "http://www.comuni-italiani.it/017/179/"
    },
    {
      "Istat": "042048",
      "Comune": "Sirolo",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "071",
      "CAP": 60020,
      "CodFisco": "I758",
      "Abitanti": 3885,
      "Link": "http://www.comuni-italiani.it/042/048/"
    },
    {
      "Istat": "097075",
      "Comune": "Sirone",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23844,
      "CodFisco": "I759",
      "Abitanti": 2372,
      "Link": "http://www.comuni-italiani.it/097/075/"
    },
    {
      "Istat": "022174",
      "Comune": "Siror",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38054,
      "CodFisco": "I760",
      "Abitanti": 1285,
      "Link": "http://www.comuni-italiani.it/022/174/"
    },
    {
      "Istat": "097076",
      "Comune": "Sirtori",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23896,
      "CodFisco": "I761",
      "Abitanti": 2950,
      "Link": "http://www.comuni-italiani.it/097/076/"
    },
    {
      "Istat": "034034",
      "Comune": "Sissa",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43018,
      "CodFisco": "I763",
      "Abitanti": 4311,
      "Link": "http://www.comuni-italiani.it/034/034/"
    },
    {
      "Istat": "092081",
      "Comune": "Siurgus Donigala",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I765",
      "Abitanti": 2110,
      "Link": "http://www.comuni-italiani.it/092/081/"
    },
    {
      "Istat": "018150",
      "Comune": "Siziano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "E265",
      "Abitanti": 5908,
      "Link": "http://www.comuni-italiani.it/018/150/"
    },
    {
      "Istat": "003139",
      "Comune": "Sizzano",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28070,
      "CodFisco": "I767",
      "Abitanti": 1468,
      "Link": "http://www.comuni-italiani.it/003/139/"
    },
    {
      "Istat": "021094",
      "Comune": "Sluderno",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "I771",
      "Abitanti": 1823,
      "Link": "http://www.comuni-italiani.it/021/094/"
    },
    {
      "Istat": "022175",
      "Comune": "Smarano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "I772",
      "Abitanti": 492,
      "Link": "http://www.comuni-italiani.it/022/175/"
    },
    {
      "Istat": 109039,
      "Comune": "Smerillo",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63856,
      "CodFisco": "I774",
      "Abitanti": 387,
      "Link": "http://www.comuni-italiani.it/109/039/"
    },
    {
      "Istat": "023081",
      "Comune": "Soave",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37038,
      "CodFisco": "I775",
      "Abitanti": 6976,
      "Link": "http://www.comuni-italiani.it/023/081/"
    },
    {
      "Istat": "030110",
      "Comune": "Socchieve",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "I777",
      "Abitanti": 938,
      "Link": "http://www.comuni-italiani.it/030/110/"
    },
    {
      "Istat": "095078",
      "Comune": "SoddÏ",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09080",
      "CodFisco": "I778",
      "Abitanti": 122,
      "Link": "http://www.comuni-italiani.it/095/078/"
    },
    {
      "Istat": "040046",
      "Comune": "Sogliano al Rubicone",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47030,
      "CodFisco": "I779",
      "Abitanti": 3293,
      "Link": "http://www.comuni-italiani.it/040/046/"
    },
    {
      "Istat": "075075",
      "Comune": "Sogliano Cavour",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73010,
      "CodFisco": "I780",
      "Abitanti": 4122,
      "Link": "http://www.comuni-italiani.it/075/075/"
    },
    {
      "Istat": "005107",
      "Comune": "Soglio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "I781",
      "Abitanti": 155,
      "Link": "http://www.comuni-italiani.it/005/107/"
    },
    {
      "Istat": "017180",
      "Comune": "Soiano del Lago",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "I782",
      "Abitanti": 1863,
      "Link": "http://www.comuni-italiani.it/017/180/"
    },
    {
      "Istat": "024101",
      "Comune": "Solagna",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36020,
      "CodFisco": "I783",
      "Abitanti": 1894,
      "Link": "http://www.comuni-italiani.it/024/101/"
    },
    {
      "Istat": "089018",
      "Comune": "Solarino",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "I785",
      "Abitanti": 7820,
      "Link": "http://www.comuni-italiani.it/089/018/"
    },
    {
      "Istat": "015213",
      "Comune": "Solaro",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20020,
      "CodFisco": "I786",
      "Abitanti": 14203,
      "Link": "http://www.comuni-italiani.it/015/213/"
    },
    {
      "Istat": "039018",
      "Comune": "Solarolo",
      "Provincia": "RA",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 48027,
      "CodFisco": "I787",
      "Abitanti": 4438,
      "Link": "http://www.comuni-italiani.it/039/018/"
    },
    {
      "Istat": "019096",
      "Comune": "Solarolo Rainerio",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26030,
      "CodFisco": "I790",
      "Abitanti": 1017,
      "Link": "http://www.comuni-italiani.it/019/096/"
    },
    {
      "Istat": "095062",
      "Comune": "Solarussa",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09077",
      "CodFisco": "I791",
      "Abitanti": 2514,
      "Link": "http://www.comuni-italiani.it/095/062/"
    },
    {
      "Istat": "013215",
      "Comune": "Solbiate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "I792",
      "Abitanti": 2561,
      "Link": "http://www.comuni-italiani.it/013/215/"
    },
    {
      "Istat": "012121",
      "Comune": "Solbiate Arno",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21048,
      "CodFisco": "I793",
      "Abitanti": 4356,
      "Link": "http://www.comuni-italiani.it/012/121/"
    },
    {
      "Istat": "012122",
      "Comune": "Solbiate Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21058,
      "CodFisco": "I794",
      "Abitanti": 5627,
      "Link": "http://www.comuni-italiani.it/012/122/"
    },
    {
      "Istat": "008058",
      "Comune": "Soldano",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18036,
      "CodFisco": "I796",
      "Abitanti": 982,
      "Link": "http://www.comuni-italiani.it/008/058/"
    },
    {
      "Istat": "092082",
      "Comune": "Soleminis",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I797",
      "Abitanti": 1854,
      "Link": "http://www.comuni-italiani.it/092/082/"
    },
    {
      "Istat": "006163",
      "Comune": "Solero",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15029,
      "CodFisco": "I798",
      "Abitanti": 1710,
      "Link": "http://www.comuni-italiani.it/006/163/"
    },
    {
      "Istat": "028087",
      "Comune": "Solesino",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35047,
      "CodFisco": "I799",
      "Abitanti": 7239,
      "Link": "http://www.comuni-italiani.it/028/087/"
    },
    {
      "Istat": "075076",
      "Comune": "Soleto",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73010,
      "CodFisco": "I800",
      "Abitanti": 5572,
      "Link": "http://www.comuni-italiani.it/075/076/"
    },
    {
      "Istat": "020063",
      "Comune": "Solferino",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46040,
      "CodFisco": "I801",
      "Abitanti": 2628,
      "Link": "http://www.comuni-italiani.it/020/063/"
    },
    {
      "Istat": "036044",
      "Comune": "Soliera",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41019,
      "CodFisco": "I802",
      "Abitanti": 15289,
      "Link": "http://www.comuni-italiani.it/036/044/"
    },
    {
      "Istat": "034035",
      "Comune": "Solignano",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43040,
      "CodFisco": "I803",
      "Abitanti": 1858,
      "Link": "http://www.comuni-italiani.it/034/035/"
    },
    {
      "Istat": "064101",
      "Comune": "Solofra",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83029,
      "CodFisco": "I805",
      "Abitanti": 12313,
      "Link": "http://www.comuni-italiani.it/064/101/"
    },
    {
      "Istat": "006164",
      "Comune": "Solonghello",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "I808",
      "Abitanti": 232,
      "Link": "http://www.comuni-italiani.it/006/164/"
    },
    {
      "Istat": "062073",
      "Comune": "Solopaca",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82036,
      "CodFisco": "I809",
      "Abitanti": 4051,
      "Link": "http://www.comuni-italiani.it/062/073/"
    },
    {
      "Istat": "016200",
      "Comune": "Solto Collina",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "I812",
      "Abitanti": 1709,
      "Link": "http://www.comuni-italiani.it/016/200/"
    },
    {
      "Istat": "016251",
      "Comune": "Solza",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "I813",
      "Abitanti": 1948,
      "Link": "http://www.comuni-italiani.it/016/251/"
    },
    {
      "Istat": "098054",
      "Comune": "Somaglia",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26867,
      "CodFisco": "I815",
      "Abitanti": 3708,
      "Link": "http://www.comuni-italiani.it/098/054/"
    },
    {
      "Istat": "004221",
      "Comune": "Somano",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12060,
      "CodFisco": "I817",
      "Abitanti": 370,
      "Link": "http://www.comuni-italiani.it/004/221/"
    },
    {
      "Istat": "012123",
      "Comune": "Somma Lombardo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21019,
      "CodFisco": "I819",
      "Abitanti": 17437,
      "Link": "http://www.comuni-italiani.it/012/123/"
    },
    {
      "Istat": "063079",
      "Comune": "Somma Vesuviana",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80049,
      "CodFisco": "I820",
      "Abitanti": 35260,
      "Link": "http://www.comuni-italiani.it/063/079/"
    },
    {
      "Istat": "023082",
      "Comune": "Sommacampagna",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37066,
      "CodFisco": "I821",
      "Abitanti": 14824,
      "Link": "http://www.comuni-italiani.it/023/082/"
    },
    {
      "Istat": "004222",
      "Comune": "Sommariva del Bosco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12048,
      "CodFisco": "I822",
      "Abitanti": 6426,
      "Link": "http://www.comuni-italiani.it/004/222/"
    },
    {
      "Istat": "004223",
      "Comune": "Sommariva Perno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12040,
      "CodFisco": "I823",
      "Abitanti": 2847,
      "Link": "http://www.comuni-italiani.it/004/223/"
    },
    {
      "Istat": "085019",
      "Comune": "Sommatino",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0922",
      "CAP": 93019,
      "CodFisco": "I824",
      "Abitanti": 7374,
      "Link": "http://www.comuni-italiani.it/085/019/"
    },
    {
      "Istat": "018151",
      "Comune": "Sommo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27048,
      "CodFisco": "I825",
      "Abitanti": 1132,
      "Link": "http://www.comuni-italiani.it/018/151/"
    },
    {
      "Istat": "023083",
      "Comune": "Sona",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "I826",
      "Abitanti": 17156,
      "Link": "http://www.comuni-italiani.it/023/083/"
    },
    {
      "Istat": "019097",
      "Comune": "Soncino",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26029,
      "CodFisco": "I827",
      "Abitanti": 7767,
      "Link": "http://www.comuni-italiani.it/019/097/"
    },
    {
      "Istat": "014060",
      "Comune": "Sondalo",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23035,
      "CodFisco": "I828",
      "Abitanti": 4281,
      "Link": "http://www.comuni-italiani.it/014/060/"
    },
    {
      "Istat": "014061",
      "Comune": "Sondrio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23100,
      "CodFisco": "I829",
      "Abitanti": 22365,
      "Link": "http://www.comuni-italiani.it/014/061/"
    },
    {
      "Istat": "016201",
      "Comune": "Songavazzo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "I830",
      "Abitanti": 701,
      "Link": "http://www.comuni-italiani.it/016/201/"
    },
    {
      "Istat": "017181",
      "Comune": "Sonico",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25048,
      "CodFisco": "I831",
      "Abitanti": 1269,
      "Link": "http://www.comuni-italiani.it/017/181/"
    },
    {
      "Istat": "059029",
      "Comune": "Sonnino",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04010",
      "CodFisco": "I832",
      "Abitanti": 7267,
      "Link": "http://www.comuni-italiani.it/059/029/"
    },
    {
      "Istat": "096062",
      "Comune": "Soprana",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13834,
      "CodFisco": "I835",
      "Abitanti": 773,
      "Link": "http://www.comuni-italiani.it/096/062/"
    },
    {
      "Istat": "060074",
      "Comune": "Sora",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03039",
      "CodFisco": "I838",
      "Abitanti": 26589,
      "Link": "http://www.comuni-italiani.it/060/074/"
    },
    {
      "Istat": "022176",
      "Comune": "Soraga",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "I839",
      "Abitanti": 715,
      "Link": "http://www.comuni-italiani.it/022/176/"
    },
    {
      "Istat": "034036",
      "Comune": "Soragna",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43019,
      "CodFisco": "I840",
      "Abitanti": 4883,
      "Link": "http://www.comuni-italiani.it/034/036/"
    },
    {
      "Istat": "053026",
      "Comune": "Sorano",
      "Provincia": "GR",
      "Regione": "TOS",
      "Prefisso": "0564",
      "CAP": 58010,
      "CodFisco": "I841",
      "Abitanti": 3656,
      "Link": "http://www.comuni-italiani.it/053/026/"
    },
    {
      "Istat": "079134",
      "Comune": "Sorbo San Basile",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "I844",
      "Abitanti": 886,
      "Link": "http://www.comuni-italiani.it/079/134/"
    },
    {
      "Istat": "064102",
      "Comune": "Sorbo Serpico",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "I843",
      "Abitanti": 595,
      "Link": "http://www.comuni-italiani.it/064/102/"
    },
    {
      "Istat": "034037",
      "Comune": "Sorbolo",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43058,
      "CodFisco": "I845",
      "Abitanti": 9648,
      "Link": "http://www.comuni-italiani.it/034/037/"
    },
    {
      "Istat": "096063",
      "Comune": "Sordevolo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13817,
      "CodFisco": "I847",
      "Abitanti": 1334,
      "Link": "http://www.comuni-italiani.it/096/063/"
    },
    {
      "Istat": "098055",
      "Comune": "Sordio",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26858,
      "CodFisco": "I848",
      "Abitanti": 3120,
      "Link": "http://www.comuni-italiani.it/098/055/"
    },
    {
      "Istat": "019098",
      "Comune": "Soresina",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26015,
      "CodFisco": "I849",
      "Abitanti": 9345,
      "Link": "http://www.comuni-italiani.it/019/098/"
    },
    {
      "Istat": "023084",
      "Comune": "Sorg‡",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "I850",
      "Abitanti": 3172,
      "Link": "http://www.comuni-italiani.it/023/084/"
    },
    {
      "Istat": "091086",
      "Comune": "Sorgono",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08038",
      "CodFisco": "I851",
      "Abitanti": 1761,
      "Link": "http://www.comuni-italiani.it/091/086/"
    },
    {
      "Istat": "010060",
      "Comune": "Sori",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "I852",
      "Abitanti": 4343,
      "Link": "http://www.comuni-italiani.it/010/060/"
    },
    {
      "Istat": 102039,
      "Comune": "Sorianello",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89831,
      "CodFisco": "I853",
      "Abitanti": 1214,
      "Link": "http://www.comuni-italiani.it/102/039/"
    },
    {
      "Istat": 102040,
      "Comune": "Soriano Calabro",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89831,
      "CodFisco": "I854",
      "Abitanti": 2775,
      "Link": "http://www.comuni-italiani.it/102/040/"
    },
    {
      "Istat": "056048",
      "Comune": "Soriano nel Cimino",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01038",
      "CodFisco": "I855",
      "Abitanti": 8722,
      "Link": "http://www.comuni-italiani.it/056/048/"
    },
    {
      "Istat": "013216",
      "Comune": "Sorico",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "I856",
      "Abitanti": 1255,
      "Link": "http://www.comuni-italiani.it/013/216/"
    },
    {
      "Istat": "003140",
      "Comune": "Soriso",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "I857",
      "Abitanti": 777,
      "Link": "http://www.comuni-italiani.it/003/140/"
    },
    {
      "Istat": "016202",
      "Comune": "Sorisole",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24010,
      "CodFisco": "I858",
      "Abitanti": 9120,
      "Link": "http://www.comuni-italiani.it/016/202/"
    },
    {
      "Istat": "013217",
      "Comune": "Sormano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22030,
      "CodFisco": "I860",
      "Abitanti": 682,
      "Link": "http://www.comuni-italiani.it/013/217/"
    },
    {
      "Istat": "095063",
      "Comune": "Sorradile",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "I861",
      "Abitanti": 433,
      "Link": "http://www.comuni-italiani.it/095/063/"
    },
    {
      "Istat": "063080",
      "Comune": "Sorrento",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80067,
      "CodFisco": "I862",
      "Abitanti": 16589,
      "Link": "http://www.comuni-italiani.it/063/080/"
    },
    {
      "Istat": "090069",
      "Comune": "Sorso",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07037",
      "CodFisco": "I863",
      "Abitanti": 14841,
      "Link": "http://www.comuni-italiani.it/090/069/"
    },
    {
      "Istat": "089019",
      "Comune": "Sortino",
      "Provincia": "SR",
      "Regione": "SIC",
      "Prefisso": "0931",
      "CAP": 96010,
      "CodFisco": "I864",
      "Abitanti": 8955,
      "Link": "http://www.comuni-italiani.it/089/019/"
    },
    {
      "Istat": "019099",
      "Comune": "Sospiro",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26048,
      "CodFisco": "I865",
      "Abitanti": 3086,
      "Link": "http://www.comuni-italiani.it/019/099/"
    },
    {
      "Istat": "025056",
      "Comune": "Sospirolo",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32037,
      "CodFisco": "I866",
      "Abitanti": 3246,
      "Link": "http://www.comuni-italiani.it/025/056/"
    },
    {
      "Istat": "024102",
      "Comune": "Sossano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "I867",
      "Abitanti": 4477,
      "Link": "http://www.comuni-italiani.it/024/102/"
    },
    {
      "Istat": "096064",
      "Comune": "Sostegno",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13868,
      "CodFisco": "I868",
      "Abitanti": 761,
      "Link": "http://www.comuni-italiani.it/096/064/"
    },
    {
      "Istat": "016203",
      "Comune": "Sotto il Monte Giovanni XXIII",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24039,
      "CodFisco": "I869",
      "Abitanti": 4239,
      "Link": "http://www.comuni-italiani.it/016/203/"
    },
    {
      "Istat": "022177",
      "Comune": "Sover",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38048,
      "CodFisco": "I871",
      "Abitanti": 890,
      "Link": "http://www.comuni-italiani.it/022/177/"
    },
    {
      "Istat": "079137",
      "Comune": "Soverato",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88068,
      "CodFisco": "I872",
      "Abitanti": 9590,
      "Link": "http://www.comuni-italiani.it/079/137/"
    },
    {
      "Istat": "016204",
      "Comune": "Sovere",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "I873",
      "Abitanti": 5486,
      "Link": "http://www.comuni-italiani.it/016/204/"
    },
    {
      "Istat": "079138",
      "Comune": "Soveria Mannelli",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0968",
      "CAP": 88049,
      "CodFisco": "I874",
      "Abitanti": 3206,
      "Link": "http://www.comuni-italiani.it/079/138/"
    },
    {
      "Istat": "079139",
      "Comune": "Soveria Simeri",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "I875",
      "Abitanti": 1678,
      "Link": "http://www.comuni-italiani.it/079/139/"
    },
    {
      "Istat": "025057",
      "Comune": "Soverzene",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "I876",
      "Abitanti": 424,
      "Link": "http://www.comuni-italiani.it/025/057/"
    },
    {
      "Istat": "052034",
      "Comune": "Sovicille",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53018,
      "CodFisco": "I877",
      "Abitanti": 9925,
      "Link": "http://www.comuni-italiani.it/052/034/"
    },
    {
      "Istat": 108041,
      "Comune": "Sovico",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20845,
      "CodFisco": "I878",
      "Abitanti": 8121,
      "Link": "http://www.comuni-italiani.it/108/041/"
    },
    {
      "Istat": "024103",
      "Comune": "Sovizzo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "I879",
      "Abitanti": 7004,
      "Link": "http://www.comuni-italiani.it/024/103/"
    },
    {
      "Istat": "025058",
      "Comune": "Sovramonte",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "I673",
      "Abitanti": 1541,
      "Link": "http://www.comuni-italiani.it/025/058/"
    },
    {
      "Istat": "003141",
      "Comune": "Sozzago",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "I880",
      "Abitanti": 1062,
      "Link": "http://www.comuni-italiani.it/003/141/"
    },
    {
      "Istat": "083096",
      "Comune": "Spadafora",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98048,
      "CodFisco": "I881",
      "Abitanti": 5247,
      "Link": "http://www.comuni-italiani.it/083/096/"
    },
    {
      "Istat": 102041,
      "Comune": "Spadola",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89822,
      "CodFisco": "I884",
      "Abitanti": 871,
      "Link": "http://www.comuni-italiani.it/102/041/"
    },
    {
      "Istat": "061089",
      "Comune": "Sparanise",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81056,
      "CodFisco": "I885",
      "Abitanti": 7447,
      "Link": "http://www.comuni-italiani.it/061/089/"
    },
    {
      "Istat": "001267",
      "Comune": "Sparone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "I886",
      "Abitanti": 1115,
      "Link": "http://www.comuni-italiani.it/001/267/"
    },
    {
      "Istat": "075077",
      "Comune": "Specchia",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "I887",
      "Abitanti": 4912,
      "Link": "http://www.comuni-italiani.it/075/077/"
    },
    {
      "Istat": "054050",
      "Comune": "Spello",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06038",
      "CodFisco": "I888",
      "Abitanti": 8712,
      "Link": "http://www.comuni-italiani.it/054/050/"
    },
    {
      "Istat": "022178",
      "Comune": "Spera",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38059,
      "CodFisco": "I889",
      "Abitanti": 609,
      "Link": "http://www.comuni-italiani.it/022/178/"
    },
    {
      "Istat": "086017",
      "Comune": "Sperlinga",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "I891",
      "Abitanti": 895,
      "Link": "http://www.comuni-italiani.it/086/017/"
    },
    {
      "Istat": "059030",
      "Comune": "Sperlonga",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04029",
      "CodFisco": "I892",
      "Abitanti": 3277,
      "Link": "http://www.comuni-italiani.it/059/030/"
    },
    {
      "Istat": "064103",
      "Comune": "Sperone",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "I893",
      "Abitanti": 3726,
      "Link": "http://www.comuni-italiani.it/064/103/"
    },
    {
      "Istat": "018152",
      "Comune": "Spessa",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "I894",
      "Abitanti": 597,
      "Link": "http://www.comuni-italiani.it/018/152/"
    },
    {
      "Istat": "078142",
      "Comune": "Spezzano Albanese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87019,
      "CodFisco": "I895",
      "Abitanti": 7270,
      "Link": "http://www.comuni-italiani.it/078/142/"
    },
    {
      "Istat": "078143",
      "Comune": "Spezzano della Sila",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87058,
      "CodFisco": "I896",
      "Abitanti": 4688,
      "Link": "http://www.comuni-italiani.it/078/143/"
    },
    {
      "Istat": "078144",
      "Comune": "Spezzano Piccolo",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "I898",
      "Abitanti": 2160,
      "Link": "http://www.comuni-italiani.it/078/144/"
    },
    {
      "Istat": "022179",
      "Comune": "Spiazzo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38088,
      "CodFisco": "I899",
      "Abitanti": 1274,
      "Link": "http://www.comuni-italiani.it/022/179/"
    },
    {
      "Istat": "006165",
      "Comune": "Spigno Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15018,
      "CodFisco": "I901",
      "Abitanti": 1148,
      "Link": "http://www.comuni-italiani.it/006/165/"
    },
    {
      "Istat": "059031",
      "Comune": "Spigno Saturnia",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04020",
      "CodFisco": "I902",
      "Abitanti": 2962,
      "Link": "http://www.comuni-italiani.it/059/031/"
    },
    {
      "Istat": "036045",
      "Comune": "Spilamberto",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41057,
      "CodFisco": "I903",
      "Abitanti": 12318,
      "Link": "http://www.comuni-italiani.it/036/045/"
    },
    {
      "Istat": "093044",
      "Comune": "Spilimbergo",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33097,
      "CodFisco": "I904",
      "Abitanti": 12220,
      "Link": "http://www.comuni-italiani.it/093/044/"
    },
    {
      "Istat": 102042,
      "Comune": "Spilinga",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89864,
      "CodFisco": "I905",
      "Abitanti": 1503,
      "Link": "http://www.comuni-italiani.it/102/042/"
    },
    {
      "Istat": "019100",
      "Comune": "Spinadesco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26020,
      "CodFisco": "I906",
      "Abitanti": 1582,
      "Link": "http://www.comuni-italiani.it/019/100/"
    },
    {
      "Istat": 110008,
      "Comune": "Spinazzola",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76014,
      "CodFisco": "I907",
      "Abitanti": 6908,
      "Link": "http://www.comuni-italiani.it/110/008/"
    },
    {
      "Istat": "027038",
      "Comune": "Spinea",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": 30038,
      "CodFisco": "I908",
      "Abitanti": 27041,
      "Link": "http://www.comuni-italiani.it/027/038/"
    },
    {
      "Istat": "019101",
      "Comune": "Spineda",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 26030,
      "CodFisco": "I909",
      "Abitanti": 641,
      "Link": "http://www.comuni-italiani.it/019/101/"
    },
    {
      "Istat": "070076",
      "Comune": "Spinete",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86020,
      "CodFisco": "I910",
      "Abitanti": 1384,
      "Link": "http://www.comuni-italiani.it/070/076/"
    },
    {
      "Istat": "006166",
      "Comune": "Spineto Scrivia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "I911",
      "Abitanti": 379,
      "Link": "http://www.comuni-italiani.it/006/166/"
    },
    {
      "Istat": "044071",
      "Comune": "Spinetoli",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63078,
      "CodFisco": "I912",
      "Abitanti": 7160,
      "Link": "http://www.comuni-italiani.it/044/071/"
    },
    {
      "Istat": "019102",
      "Comune": "Spino d'Adda",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26016,
      "CodFisco": "I914",
      "Abitanti": 7007,
      "Link": "http://www.comuni-italiani.it/019/102/"
    },
    {
      "Istat": "016205",
      "Comune": "Spinone al Lago",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "I916",
      "Abitanti": 1037,
      "Link": "http://www.comuni-italiani.it/016/205/"
    },
    {
      "Istat": "076086",
      "Comune": "Spinoso",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85039,
      "CodFisco": "I917",
      "Abitanti": 1602,
      "Link": "http://www.comuni-italiani.it/076/086/"
    },
    {
      "Istat": "016206",
      "Comune": "Spirano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "I919",
      "Abitanti": 5652,
      "Link": "http://www.comuni-italiani.it/016/206/"
    },
    {
      "Istat": "054051",
      "Comune": "Spoleto",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06049",
      "CodFisco": "I921",
      "Abitanti": 39574,
      "Link": "http://www.comuni-italiani.it/054/051/"
    },
    {
      "Istat": "068041",
      "Comune": "Spoltore",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "I922",
      "Abitanti": 18533,
      "Link": "http://www.comuni-italiani.it/068/041/"
    },
    {
      "Istat": "075078",
      "Comune": "Spongano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73038,
      "CodFisco": "I923",
      "Abitanti": 3803,
      "Link": "http://www.comuni-italiani.it/075/078/"
    },
    {
      "Istat": "022180",
      "Comune": "Spormaggiore",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "I924",
      "Abitanti": 1292,
      "Link": "http://www.comuni-italiani.it/022/180/"
    },
    {
      "Istat": "022181",
      "Comune": "Sporminore",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "I925",
      "Abitanti": 727,
      "Link": "http://www.comuni-italiani.it/022/181/"
    },
    {
      "Istat": "009057",
      "Comune": "Spotorno",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17028,
      "CodFisco": "I926",
      "Abitanti": 4005,
      "Link": "http://www.comuni-italiani.it/009/057/"
    },
    {
      "Istat": "026082",
      "Comune": "Spresiano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31027,
      "CodFisco": "I927",
      "Abitanti": 11715,
      "Link": "http://www.comuni-italiani.it/026/082/"
    },
    {
      "Istat": "014062",
      "Comune": "Spriana",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "I928",
      "Abitanti": 105,
      "Link": "http://www.comuni-italiani.it/014/062/"
    },
    {
      "Istat": "079142",
      "Comune": "Squillace",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88069,
      "CodFisco": "I929",
      "Abitanti": 3450,
      "Link": "http://www.comuni-italiani.it/079/142/"
    },
    {
      "Istat": "075079",
      "Comune": "Squinzano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73018,
      "CodFisco": "I930",
      "Abitanti": 14537,
      "Link": "http://www.comuni-italiani.it/075/079/"
    },
    {
      "Istat": "042049",
      "Comune": "Staffolo",
      "Provincia": "AN",
      "Regione": "MAR",
      "Prefisso": "0731",
      "CAP": 60039,
      "CodFisco": "I932",
      "Abitanti": 2372,
      "Link": "http://www.comuni-italiani.it/042/049/"
    },
    {
      "Istat": "019103",
      "Comune": "Stagno Lombardo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26049,
      "CodFisco": "I935",
      "Abitanti": 1544,
      "Link": "http://www.comuni-italiani.it/019/103/"
    },
    {
      "Istat": "080090",
      "Comune": "Staiti",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89030,
      "CodFisco": "I936",
      "Abitanti": 291,
      "Link": "http://www.comuni-italiani.it/080/090/"
    },
    {
      "Istat": "079143",
      "Comune": "StalettÏ",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88069,
      "CodFisco": "I937",
      "Abitanti": 2494,
      "Link": "http://www.comuni-italiani.it/079/143/"
    },
    {
      "Istat": "028088",
      "Comune": "Stanghella",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35048,
      "CodFisco": "I938",
      "Abitanti": 4403,
      "Link": "http://www.comuni-italiani.it/028/088/"
    },
    {
      "Istat": "031023",
      "Comune": "Staranzano",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34079,
      "CodFisco": "I939",
      "Abitanti": 7257,
      "Link": "http://www.comuni-italiani.it/031/023/"
    },
    {
      "Istat": "073029",
      "Comune": "Statte",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74010,
      "CodFisco": "M298",
      "Abitanti": 14494,
      "Link": "http://www.comuni-italiani.it/073/029/"
    },
    {
      "Istat": "006167",
      "Comune": "Stazzano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "I941",
      "Abitanti": 2424,
      "Link": "http://www.comuni-italiani.it/006/167/"
    },
    {
      "Istat": "046030",
      "Comune": "Stazzema",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55040,
      "CodFisco": "I942",
      "Abitanti": 3362,
      "Link": "http://www.comuni-italiani.it/046/030/"
    },
    {
      "Istat": "013218",
      "Comune": "Stazzona",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "I943",
      "Abitanti": 647,
      "Link": "http://www.comuni-italiani.it/013/218/"
    },
    {
      "Istat": 102043,
      "Comune": "Stefanaconi",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89843,
      "CodFisco": "I945",
      "Abitanti": 2523,
      "Link": "http://www.comuni-italiani.it/102/043/"
    },
    {
      "Istat": "009058",
      "Comune": "Stella",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17044,
      "CodFisco": "I946",
      "Abitanti": 3082,
      "Link": "http://www.comuni-italiani.it/009/058/"
    },
    {
      "Istat": "065144",
      "Comune": "Stella Cilento",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "G887",
      "Abitanti": 785,
      "Link": "http://www.comuni-italiani.it/065/144/"
    },
    {
      "Istat": "009059",
      "Comune": "Stellanello",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17020,
      "CodFisco": "I947",
      "Abitanti": 877,
      "Link": "http://www.comuni-italiani.it/009/059/"
    },
    {
      "Istat": "021095",
      "Comune": "Stelvio",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39029,
      "CodFisco": "I948",
      "Abitanti": 1215,
      "Link": "http://www.comuni-italiani.it/021/095/"
    },
    {
      "Istat": "022182",
      "Comune": "Stenico",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38070,
      "CodFisco": "I949",
      "Abitanti": 1160,
      "Link": "http://www.comuni-italiani.it/022/182/"
    },
    {
      "Istat": "075080",
      "Comune": "Sternatia",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73010,
      "CodFisco": "I950",
      "Abitanti": 2459,
      "Link": "http://www.comuni-italiani.it/075/080/"
    },
    {
      "Istat": "016207",
      "Comune": "Stezzano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "I951",
      "Abitanti": 12867,
      "Link": "http://www.comuni-italiani.it/016/207/"
    },
    {
      "Istat": "051036",
      "Comune": "Stia",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52017,
      "CodFisco": "I952",
      "Abitanti": 2954,
      "Link": "http://www.comuni-italiani.it/051/036/"
    },
    {
      "Istat": "029045",
      "Comune": "Stienta",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45039,
      "CodFisco": "I953",
      "Abitanti": 3354,
      "Link": "http://www.comuni-italiani.it/029/045/"
    },
    {
      "Istat": "077027",
      "Comune": "Stigliano",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75018,
      "CodFisco": "I954",
      "Abitanti": 4794,
      "Link": "http://www.comuni-italiani.it/077/027/"
    },
    {
      "Istat": "080091",
      "Comune": "Stignano",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89040,
      "CodFisco": "I955",
      "Abitanti": 1386,
      "Link": "http://www.comuni-italiani.it/080/091/"
    },
    {
      "Istat": "080092",
      "Comune": "Stilo",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0964",
      "CAP": 89049,
      "CodFisco": "I956",
      "Abitanti": 2746,
      "Link": "http://www.comuni-italiani.it/080/092/"
    },
    {
      "Istat": "057066",
      "Comune": "Stimigliano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02048",
      "CodFisco": "I959",
      "Abitanti": 2206,
      "Link": "http://www.comuni-italiani.it/057/066/"
    },
    {
      "Istat": "090089",
      "Comune": "Stintino",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "M290",
      "Abitanti": 1335,
      "Link": "http://www.comuni-italiani.it/090/089/"
    },
    {
      "Istat": "065145",
      "Comune": "Stio",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84075,
      "CodFisco": "I960",
      "Abitanti": 972,
      "Link": "http://www.comuni-italiani.it/065/145/"
    },
    {
      "Istat": "071054",
      "Comune": "Stornara",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71047,
      "CodFisco": "I962",
      "Abitanti": 5114,
      "Link": "http://www.comuni-italiani.it/071/054/"
    },
    {
      "Istat": "071055",
      "Comune": "Stornarella",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0885",
      "CAP": 71048,
      "CodFisco": "I963",
      "Abitanti": 5137,
      "Link": "http://www.comuni-italiani.it/071/055/"
    },
    {
      "Istat": "022183",
      "Comune": "Storo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38089,
      "CodFisco": "I964",
      "Abitanti": 4704,
      "Link": "http://www.comuni-italiani.it/022/183/"
    },
    {
      "Istat": "027039",
      "Comune": "Stra",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 30039,
      "CodFisco": "I965",
      "Abitanti": 7574,
      "Link": "http://www.comuni-italiani.it/027/039/"
    },
    {
      "Istat": "018153",
      "Comune": "Stradella",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27049,
      "CodFisco": "I968",
      "Abitanti": 11674,
      "Link": "http://www.comuni-italiani.it/018/153/"
    },
    {
      "Istat": "001268",
      "Comune": "Strambinello",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "I969",
      "Abitanti": 273,
      "Link": "http://www.comuni-italiani.it/001/268/"
    },
    {
      "Istat": "001269",
      "Comune": "Strambino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10019,
      "CodFisco": "I970",
      "Abitanti": 6437,
      "Link": "http://www.comuni-italiani.it/001/269/"
    },
    {
      "Istat": "060075",
      "Comune": "Strangolagalli",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "I973",
      "Abitanti": 2544,
      "Link": "http://www.comuni-italiani.it/060/075/"
    },
    {
      "Istat": "030111",
      "Comune": "Stregna",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "I974",
      "Abitanti": 413,
      "Link": "http://www.comuni-italiani.it/030/111/"
    },
    {
      "Istat": "022184",
      "Comune": "Strembo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "I975",
      "Abitanti": 562,
      "Link": "http://www.comuni-italiani.it/022/184/"
    },
    {
      "Istat": 103064,
      "Comune": "Stresa",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28838,
      "CodFisco": "I976",
      "Abitanti": 5226,
      "Link": "http://www.comuni-italiani.it/103/064/"
    },
    {
      "Istat": "006168",
      "Comune": "Strevi",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15019,
      "CodFisco": "I977",
      "Abitanti": 2083,
      "Link": "http://www.comuni-italiani.it/006/168/"
    },
    {
      "Istat": "063081",
      "Comune": "Striano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "I978",
      "Abitanti": 8323,
      "Link": "http://www.comuni-italiani.it/063/081/"
    },
    {
      "Istat": "022185",
      "Comune": "Strigno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38059,
      "CodFisco": "I979",
      "Abitanti": 1432,
      "Link": "http://www.comuni-italiani.it/022/185/"
    },
    {
      "Istat": "096065",
      "Comune": "Strona",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13823,
      "CodFisco": "I980",
      "Abitanti": 1166,
      "Link": "http://www.comuni-italiani.it/096/065/"
    },
    {
      "Istat": "055031",
      "Comune": "Stroncone",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05039",
      "CodFisco": "I981",
      "Abitanti": 5035,
      "Link": "http://www.comuni-italiani.it/055/031/"
    },
    {
      "Istat": 101025,
      "Comune": "Strongoli",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88816,
      "CodFisco": "I982",
      "Abitanti": 6383,
      "Link": "http://www.comuni-italiani.it/101/025/"
    },
    {
      "Istat": "002142",
      "Comune": "Stroppiana",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13010,
      "CodFisco": "I984",
      "Abitanti": 1262,
      "Link": "http://www.comuni-italiani.it/002/142/"
    },
    {
      "Istat": "004224",
      "Comune": "Stroppo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "I985",
      "Abitanti": 114,
      "Link": "http://www.comuni-italiani.it/004/224/"
    },
    {
      "Istat": "016208",
      "Comune": "Strozza",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "I986",
      "Abitanti": 1075,
      "Link": "http://www.comuni-italiani.it/016/208/"
    },
    {
      "Istat": "064104",
      "Comune": "Sturno",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83055,
      "CodFisco": "I990",
      "Abitanti": 3171,
      "Link": "http://www.comuni-italiani.it/064/104/"
    },
    {
      "Istat": "018154",
      "Comune": "Suardi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "B014",
      "Abitanti": 673,
      "Link": "http://www.comuni-italiani.it/018/154/"
    },
    {
      "Istat": "051037",
      "Comune": "Subbiano",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "I991",
      "Abitanti": 6408,
      "Link": "http://www.comuni-italiani.it/051/037/"
    },
    {
      "Istat": "058103",
      "Comune": "Subiaco",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00028",
      "CodFisco": "I992",
      "Abitanti": 9406,
      "Link": "http://www.comuni-italiani.it/058/103/"
    },
    {
      "Istat": "061090",
      "Comune": "Succivo",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "I993",
      "Abitanti": 8040,
      "Link": "http://www.comuni-italiani.it/061/090/"
    },
    {
      "Istat": "097077",
      "Comune": "Sueglio",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23835,
      "CodFisco": "I994",
      "Abitanti": 151,
      "Link": "http://www.comuni-italiani.it/097/077/"
    },
    {
      "Istat": "092083",
      "Comune": "Suelli",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "I995",
      "Abitanti": 1149,
      "Link": "http://www.comuni-italiani.it/092/083/"
    },
    {
      "Istat": "097078",
      "Comune": "Suello",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 23867,
      "CodFisco": "I996",
      "Abitanti": 1689,
      "Link": "http://www.comuni-italiani.it/097/078/"
    },
    {
      "Istat": "016209",
      "Comune": "Suisio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "I997",
      "Abitanti": 3888,
      "Link": "http://www.comuni-italiani.it/016/209/"
    },
    {
      "Istat": 108042,
      "Comune": "Sulbiate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20884,
      "CodFisco": "I998",
      "Abitanti": 3995,
      "Link": "http://www.comuni-italiani.it/108/042/"
    },
    {
      "Istat": "066098",
      "Comune": "Sulmona",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67039,
      "CodFisco": "I804",
      "Abitanti": 25159,
      "Link": "http://www.comuni-italiani.it/066/098/"
    },
    {
      "Istat": "017182",
      "Comune": "Sulzano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25058,
      "CodFisco": "L002",
      "Abitanti": 1956,
      "Link": "http://www.comuni-italiani.it/017/182/"
    },
    {
      "Istat": "012124",
      "Comune": "Sumirago",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "L003",
      "Abitanti": 6274,
      "Link": "http://www.comuni-italiani.it/012/124/"
    },
    {
      "Istat": "064105",
      "Comune": "Summonte",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "L004",
      "Abitanti": 1662,
      "Link": "http://www.comuni-italiani.it/064/105/"
    },
    {
      "Istat": "095087",
      "Comune": "Suni",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "L006",
      "Abitanti": 1131,
      "Link": "http://www.comuni-italiani.it/095/087/"
    },
    {
      "Istat": "003143",
      "Comune": "Suno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28019,
      "CodFisco": "L007",
      "Abitanti": 2840,
      "Link": "http://www.comuni-italiani.it/003/143/"
    },
    {
      "Istat": "075081",
      "Comune": "Supersano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73040,
      "CodFisco": "L008",
      "Abitanti": 4510,
      "Link": "http://www.comuni-italiani.it/075/081/"
    },
    {
      "Istat": "060076",
      "Comune": "Supino",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03019",
      "CodFisco": "L009",
      "Abitanti": 4958,
      "Link": "http://www.comuni-italiani.it/060/076/"
    },
    {
      "Istat": "075082",
      "Comune": "Surano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73030,
      "CodFisco": "L010",
      "Abitanti": 1724,
      "Link": "http://www.comuni-italiani.it/075/082/"
    },
    {
      "Istat": "075083",
      "Comune": "Surbo",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "L011",
      "Abitanti": 14876,
      "Link": "http://www.comuni-italiani.it/075/083/"
    },
    {
      "Istat": "001270",
      "Comune": "Susa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10059,
      "CodFisco": "L013",
      "Abitanti": 6727,
      "Link": "http://www.comuni-italiani.it/001/270/"
    },
    {
      "Istat": "026083",
      "Comune": "Susegana",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31058,
      "CodFisco": "L014",
      "Abitanti": 12055,
      "Link": "http://www.comuni-italiani.it/026/083/"
    },
    {
      "Istat": "020064",
      "Comune": "Sustinente",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46030,
      "CodFisco": "L015",
      "Abitanti": 2238,
      "Link": "http://www.comuni-italiani.it/020/064/"
    },
    {
      "Istat": "085020",
      "Comune": "Sutera",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "L016",
      "Abitanti": 1471,
      "Link": "http://www.comuni-italiani.it/085/020/"
    },
    {
      "Istat": "056049",
      "Comune": "Sutri",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01015",
      "CodFisco": "L017",
      "Abitanti": 6671,
      "Link": "http://www.comuni-italiani.it/056/049/"
    },
    {
      "Istat": "030112",
      "Comune": "Sutrio",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "L018",
      "Abitanti": 1376,
      "Link": "http://www.comuni-italiani.it/030/112/"
    },
    {
      "Istat": "049020",
      "Comune": "Suvereto",
      "Provincia": "LI",
      "Regione": "TOS",
      "Prefisso": "0565",
      "CAP": 57028,
      "CodFisco": "L019",
      "Abitanti": 3171,
      "Link": "http://www.comuni-italiani.it/049/020/"
    },
    {
      "Istat": "020065",
      "Comune": "Suzzara",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46029,
      "CodFisco": "L020",
      "Abitanti": 20648,
      "Link": "http://www.comuni-italiani.it/020/065/"
    },
    {
      "Istat": "097079",
      "Comune": "Taceno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23837,
      "CodFisco": "L022",
      "Abitanti": 540,
      "Link": "http://www.comuni-italiani.it/097/079/"
    },
    {
      "Istat": "095064",
      "Comune": "Tadasuni",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09080",
      "CodFisco": "L023",
      "Abitanti": 184,
      "Link": "http://www.comuni-italiani.it/095/064/"
    },
    {
      "Istat": "008059",
      "Comune": "Taggia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18018,
      "CodFisco": "L024",
      "Abitanti": 14502,
      "Link": "http://www.comuni-italiani.it/008/059/"
    },
    {
      "Istat": "066099",
      "Comune": "Tagliacozzo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67069,
      "CodFisco": "L025",
      "Abitanti": 7036,
      "Link": "http://www.comuni-italiani.it/066/099/"
    },
    {
      "Istat": "029046",
      "Comune": "Taglio di Po",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0426",
      "CAP": 45019,
      "CodFisco": "L026",
      "Abitanti": 8537,
      "Link": "http://www.comuni-italiani.it/029/046/"
    },
    {
      "Istat": "006169",
      "Comune": "Tagliolo Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15070,
      "CodFisco": "L027",
      "Abitanti": 1571,
      "Link": "http://www.comuni-italiani.it/006/169/"
    },
    {
      "Istat": "025059",
      "Comune": "Taibon Agordino",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32027,
      "CodFisco": "L030",
      "Abitanti": 1787,
      "Link": "http://www.comuni-italiani.it/025/059/"
    },
    {
      "Istat": "012125",
      "Comune": "Taino",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21020,
      "CodFisco": "L032",
      "Abitanti": 3798,
      "Link": "http://www.comuni-italiani.it/012/125/"
    },
    {
      "Istat": "022186",
      "Comune": "Taio",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38012,
      "CodFisco": "L033",
      "Abitanti": 2924,
      "Link": "http://www.comuni-italiani.it/022/186/"
    },
    {
      "Istat": "030113",
      "Comune": "Taipana",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "G736",
      "Abitanti": 699,
      "Link": "http://www.comuni-italiani.it/030/113/"
    },
    {
      "Istat": "099027",
      "Comune": "Talamello",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47867,
      "CodFisco": "L034",
      "Abitanti": 1080,
      "Link": "http://www.comuni-italiani.it/099/027/"
    },
    {
      "Istat": "014063",
      "Comune": "Talamona",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23018,
      "CodFisco": "L035",
      "Abitanti": 4767,
      "Link": "http://www.comuni-italiani.it/014/063/"
    },
    {
      "Istat": 105016,
      "Comune": "Talana",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "L036",
      "Abitanti": 1088,
      "Link": "http://www.comuni-italiani.it/105/016/"
    },
    {
      "Istat": "016210",
      "Comune": "Taleggio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "L037",
      "Abitanti": 625,
      "Link": "http://www.comuni-italiani.it/016/210/"
    },
    {
      "Istat": "051038",
      "Comune": "Talla",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "0575",
      "CAP": 52010,
      "CodFisco": "L038",
      "Abitanti": 1151,
      "Link": "http://www.comuni-italiani.it/051/038/"
    },
    {
      "Istat": "030114",
      "Comune": "Talmassons",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "L039",
      "Abitanti": 4167,
      "Link": "http://www.comuni-italiani.it/030/114/"
    },
    {
      "Istat": "025060",
      "Comune": "Tambre",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "L040",
      "Abitanti": 1421,
      "Link": "http://www.comuni-italiani.it/025/060/"
    },
    {
      "Istat": "083097",
      "Comune": "Taormina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0942",
      "CAP": 98039,
      "CodFisco": "L042",
      "Abitanti": 11076,
      "Link": "http://www.comuni-italiani.it/083/097/"
    },
    {
      "Istat": "057067",
      "Comune": "Tarano",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02040",
      "CodFisco": "L046",
      "Abitanti": 1495,
      "Link": "http://www.comuni-italiani.it/057/067/"
    },
    {
      "Istat": "069089",
      "Comune": "Taranta Peligna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66018,
      "CodFisco": "L047",
      "Abitanti": 425,
      "Link": "http://www.comuni-italiani.it/069/089/"
    },
    {
      "Istat": "004225",
      "Comune": "Tarantasca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "L048",
      "Abitanti": 2042,
      "Link": "http://www.comuni-italiani.it/004/225/"
    },
    {
      "Istat": "073027",
      "Comune": "Taranto",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": "7412x",
      "CodFisco": "L049",
      "Abitanti": 191810,
      "Link": "http://www.comuni-italiani.it/073/027/"
    },
    {
      "Istat": "030116",
      "Comune": "Tarcento",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33017,
      "CodFisco": "L050",
      "Abitanti": 9148,
      "Link": "http://www.comuni-italiani.it/030/116/"
    },
    {
      "Istat": "056050",
      "Comune": "Tarquinia",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "01016",
      "CodFisco": "D024",
      "Abitanti": 16724,
      "Link": "http://www.comuni-italiani.it/056/050/"
    },
    {
      "Istat": "078145",
      "Comune": "Tarsia",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87040,
      "CodFisco": "L055",
      "Abitanti": 2164,
      "Link": "http://www.comuni-italiani.it/078/145/"
    },
    {
      "Istat": "014064",
      "Comune": "Tartano",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "L056",
      "Abitanti": 194,
      "Link": "http://www.comuni-italiani.it/014/064/"
    },
    {
      "Istat": "030117",
      "Comune": "Tarvisio",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0428",
      "CAP": 33018,
      "CodFisco": "L057",
      "Abitanti": 4683,
      "Link": "http://www.comuni-italiani.it/030/117/"
    },
    {
      "Istat": "026084",
      "Comune": "Tarzo",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31020,
      "CodFisco": "L058",
      "Abitanti": 4621,
      "Link": "http://www.comuni-italiani.it/026/084/"
    },
    {
      "Istat": "006170",
      "Comune": "Tassarolo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "L059",
      "Abitanti": 647,
      "Link": "http://www.comuni-italiani.it/006/170/"
    },
    {
      "Istat": "022187",
      "Comune": "Tassullo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "L060",
      "Abitanti": 1950,
      "Link": "http://www.comuni-italiani.it/022/187/"
    },
    {
      "Istat": "064106",
      "Comune": "Taurano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 83020,
      "CodFisco": "L061",
      "Abitanti": 1615,
      "Link": "http://www.comuni-italiani.it/064/106/"
    },
    {
      "Istat": "064107",
      "Comune": "Taurasi",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83030,
      "CodFisco": "L062",
      "Abitanti": 2519,
      "Link": "http://www.comuni-italiani.it/064/107/"
    },
    {
      "Istat": "080093",
      "Comune": "Taurianova",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89029,
      "CodFisco": "L063",
      "Abitanti": 15824,
      "Link": "http://www.comuni-italiani.it/080/093/"
    },
    {
      "Istat": "075084",
      "Comune": "Taurisano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73056,
      "CodFisco": "L064",
      "Abitanti": 12675,
      "Link": "http://www.comuni-italiani.it/075/084/"
    },
    {
      "Istat": "030118",
      "Comune": "Tavagnacco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "L065",
      "Abitanti": 14441,
      "Link": "http://www.comuni-italiani.it/030/118/"
    },
    {
      "Istat": "001271",
      "Comune": "Tavagnasco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10010,
      "CodFisco": "L066",
      "Abitanti": 807,
      "Link": "http://www.comuni-italiani.it/001/271/"
    },
    {
      "Istat": "048045",
      "Comune": "Tavarnelle Val di Pesa",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50028,
      "CodFisco": "L067",
      "Abitanti": 7755,
      "Link": "http://www.comuni-italiani.it/048/045/"
    },
    {
      "Istat": "098056",
      "Comune": "Tavazzano con Villavesco",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26838,
      "CodFisco": "F260",
      "Abitanti": 6125,
      "Link": "http://www.comuni-italiani.it/098/056/"
    },
    {
      "Istat": "070077",
      "Comune": "Tavenna",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86030,
      "CodFisco": "L069",
      "Abitanti": 827,
      "Link": "http://www.comuni-italiani.it/070/077/"
    },
    {
      "Istat": "079146",
      "Comune": "Taverna",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88055,
      "CodFisco": "L070",
      "Abitanti": 2712,
      "Link": "http://www.comuni-italiani.it/079/146/"
    },
    {
      "Istat": "013222",
      "Comune": "Tavernerio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22038,
      "CodFisco": "L071",
      "Abitanti": 5823,
      "Link": "http://www.comuni-italiani.it/013/222/"
    },
    {
      "Istat": "016211",
      "Comune": "Tavernola Bergamasca",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "L073",
      "Abitanti": 2146,
      "Link": "http://www.comuni-italiani.it/016/211/"
    },
    {
      "Istat": "017183",
      "Comune": "Tavernole sul Mella",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25060,
      "CodFisco": "C698",
      "Abitanti": 1411,
      "Link": "http://www.comuni-italiani.it/017/183/"
    },
    {
      "Istat": "075085",
      "Comune": "Taviano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73057,
      "CodFisco": "L074",
      "Abitanti": 12632,
      "Link": "http://www.comuni-italiani.it/075/085/"
    },
    {
      "Istat": "096066",
      "Comune": "Tavigliano",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13811,
      "CodFisco": "L075",
      "Abitanti": 973,
      "Link": "http://www.comuni-italiani.it/096/066/"
    },
    {
      "Istat": "041064",
      "Comune": "Tavoleto",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61020,
      "CodFisco": "L078",
      "Abitanti": 926,
      "Link": "http://www.comuni-italiani.it/041/064/"
    },
    {
      "Istat": "041065",
      "Comune": "Tavullia",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0721",
      "CAP": 61010,
      "CodFisco": "L081",
      "Abitanti": 7820,
      "Link": "http://www.comuni-italiani.it/041/065/"
    },
    {
      "Istat": "076087",
      "Comune": "Teana",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85032,
      "CodFisco": "L082",
      "Abitanti": 669,
      "Link": "http://www.comuni-italiani.it/076/087/"
    },
    {
      "Istat": "061091",
      "Comune": "Teano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81057,
      "CodFisco": "L083",
      "Abitanti": 12536,
      "Link": "http://www.comuni-italiani.it/061/091/"
    },
    {
      "Istat": "065146",
      "Comune": "Teggiano",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0975",
      "CAP": 84039,
      "CodFisco": "D292",
      "Abitanti": 8232,
      "Link": "http://www.comuni-italiani.it/065/146/"
    },
    {
      "Istat": "014065",
      "Comune": "Teglio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23036,
      "CodFisco": "L084",
      "Abitanti": 4769,
      "Link": "http://www.comuni-italiani.it/014/065/"
    },
    {
      "Istat": "027040",
      "Comune": "Teglio Veneto",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30025,
      "CodFisco": "L085",
      "Abitanti": 2316,
      "Link": "http://www.comuni-italiani.it/027/040/"
    },
    {
      "Istat": "062074",
      "Comune": "Telese Terme",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82037,
      "CodFisco": "L086",
      "Abitanti": 7028,
      "Link": "http://www.comuni-italiani.it/062/074/"
    },
    {
      "Istat": "016212",
      "Comune": "Telgate",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "L087",
      "Abitanti": 4847,
      "Link": "http://www.comuni-italiani.it/016/212/"
    },
    {
      "Istat": 104024,
      "Comune": "Telti",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "0789",
      "CAP": "07020",
      "CodFisco": "L088",
      "Abitanti": 2217,
      "Link": "http://www.comuni-italiani.it/104/024/"
    },
    {
      "Istat": "022188",
      "Comune": "Telve",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "L089",
      "Abitanti": 2003,
      "Link": "http://www.comuni-italiani.it/022/188/"
    },
    {
      "Istat": "022189",
      "Comune": "Telve di Sopra",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "L090",
      "Abitanti": 625,
      "Link": "http://www.comuni-italiani.it/022/189/"
    },
    {
      "Istat": 104025,
      "Comune": "Tempio Pausania",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07029",
      "CodFisco": "L093",
      "Abitanti": 14290,
      "Link": "http://www.comuni-italiani.it/104/025/"
    },
    {
      "Istat": "017184",
      "Comune": "Tem˘",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "L094",
      "Abitanti": 1085,
      "Link": "http://www.comuni-italiani.it/017/184/"
    },
    {
      "Istat": "022190",
      "Comune": "Tenna",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "L096",
      "Abitanti": 983,
      "Link": "http://www.comuni-italiani.it/022/190/"
    },
    {
      "Istat": "022191",
      "Comune": "Tenno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "L097",
      "Abitanti": 2029,
      "Link": "http://www.comuni-italiani.it/022/191/"
    },
    {
      "Istat": "028089",
      "Comune": "Teolo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35037,
      "CodFisco": "L100",
      "Abitanti": 8949,
      "Link": "http://www.comuni-italiani.it/028/089/"
    },
    {
      "Istat": "030119",
      "Comune": "Teor",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "L101",
      "Abitanti": 1997,
      "Link": "http://www.comuni-italiani.it/030/119/"
    },
    {
      "Istat": "064108",
      "Comune": "Teora",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83056,
      "CodFisco": "L102",
      "Abitanti": 1579,
      "Link": "http://www.comuni-italiani.it/064/108/"
    },
    {
      "Istat": "067041",
      "Comune": "Teramo",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64100,
      "CodFisco": "L103",
      "Abitanti": 54957,
      "Link": "http://www.comuni-italiani.it/067/041/"
    },
    {
      "Istat": "003144",
      "Comune": "Terdobbiate",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28070,
      "CodFisco": "L104",
      "Abitanti": 511,
      "Link": "http://www.comuni-italiani.it/003/144/"
    },
    {
      "Istat": "060077",
      "Comune": "Terelle",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "L105",
      "Abitanti": 487,
      "Link": "http://www.comuni-italiani.it/060/077/"
    },
    {
      "Istat": "021096",
      "Comune": "Terento",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39030,
      "CodFisco": "L106",
      "Abitanti": 1720,
      "Link": "http://www.comuni-italiani.it/021/096/"
    },
    {
      "Istat": "034038",
      "Comune": "Terenzo",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43040,
      "CodFisco": "E548",
      "Abitanti": 1239,
      "Link": "http://www.comuni-italiani.it/034/038/"
    },
    {
      "Istat": "090086",
      "Comune": "Tergu",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "M282",
      "Abitanti": 622,
      "Link": "http://www.comuni-italiani.it/090/086/"
    },
    {
      "Istat": "022192",
      "Comune": "Terlago",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38070,
      "CodFisco": "L107",
      "Abitanti": 1882,
      "Link": "http://www.comuni-italiani.it/022/192/"
    },
    {
      "Istat": "021097",
      "Comune": "Terlano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39018,
      "CodFisco": "L108",
      "Abitanti": 4137,
      "Link": "http://www.comuni-italiani.it/021/097/"
    },
    {
      "Istat": "072043",
      "Comune": "Terlizzi",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70038,
      "CodFisco": "L109",
      "Abitanti": 27290,
      "Link": "http://www.comuni-italiani.it/072/043/"
    },
    {
      "Istat": "083106",
      "Comune": "Terme Vigliatore",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98050,
      "CodFisco": "M210",
      "Abitanti": 7203,
      "Link": "http://www.comuni-italiani.it/083/106/"
    },
    {
      "Istat": "021098",
      "Comune": "Termeno sulla Strada del Vino",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "L111",
      "Abitanti": 3301,
      "Link": "http://www.comuni-italiani.it/021/098/"
    },
    {
      "Istat": "082070",
      "Comune": "Termini Imerese",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90018,
      "CodFisco": "L112",
      "Abitanti": 27702,
      "Link": "http://www.comuni-italiani.it/082/070/"
    },
    {
      "Istat": "070078",
      "Comune": "Termoli",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0875",
      "CAP": 86039,
      "CodFisco": "L113",
      "Abitanti": 32873,
      "Link": "http://www.comuni-italiani.it/070/078/"
    },
    {
      "Istat": "012126",
      "Comune": "Ternate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "L115",
      "Abitanti": 2467,
      "Link": "http://www.comuni-italiani.it/012/126/"
    },
    {
      "Istat": "096067",
      "Comune": "Ternengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13844,
      "CodFisco": "L116",
      "Abitanti": 300,
      "Link": "http://www.comuni-italiani.it/096/067/"
    },
    {
      "Istat": "055032",
      "Comune": "Terni",
      "Provincia": "TR",
      "Regione": "UMB",
      "Prefisso": "0744",
      "CAP": "05100",
      "CodFisco": "L117",
      "Abitanti": 113324,
      "Link": "http://www.comuni-italiani.it/055/032/"
    },
    {
      "Istat": "016213",
      "Comune": "Terno d'Isola",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "L118",
      "Abitanti": 7665,
      "Link": "http://www.comuni-italiani.it/016/213/"
    },
    {
      "Istat": "059032",
      "Comune": "Terracina",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0773",
      "CAP": "04019",
      "CodFisco": "L120",
      "Abitanti": 44480,
      "Link": "http://www.comuni-italiani.it/059/032/"
    },
    {
      "Istat": "022193",
      "Comune": "Terragnolo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "L121",
      "Abitanti": 763,
      "Link": "http://www.comuni-italiani.it/022/193/"
    },
    {
      "Istat": "095065",
      "Comune": "Terralba",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09098",
      "CodFisco": "L122",
      "Abitanti": 10305,
      "Link": "http://www.comuni-italiani.it/095/065/"
    },
    {
      "Istat": "078146",
      "Comune": "Terranova da Sibari",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87010,
      "CodFisco": "L124",
      "Abitanti": 5261,
      "Link": "http://www.comuni-italiani.it/078/146/"
    },
    {
      "Istat": "098057",
      "Comune": "Terranova dei Passerini",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26827,
      "CodFisco": "L125",
      "Abitanti": 914,
      "Link": "http://www.comuni-italiani.it/098/057/"
    },
    {
      "Istat": "076088",
      "Comune": "Terranova di Pollino",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85030,
      "CodFisco": "L126",
      "Abitanti": 1362,
      "Link": "http://www.comuni-italiani.it/076/088/"
    },
    {
      "Istat": "080094",
      "Comune": "Terranova Sappo Minulio",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89010,
      "CodFisco": "L127",
      "Abitanti": 534,
      "Link": "http://www.comuni-italiani.it/080/094/"
    },
    {
      "Istat": "051039",
      "Comune": "Terranuova Bracciolini",
      "Provincia": "AR",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 52028,
      "CodFisco": "L123",
      "Abitanti": 12340,
      "Link": "http://www.comuni-italiani.it/051/039/"
    },
    {
      "Istat": "082071",
      "Comune": "Terrasini",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90049,
      "CodFisco": "L131",
      "Abitanti": 11696,
      "Link": "http://www.comuni-italiani.it/082/071/"
    },
    {
      "Istat": "028090",
      "Comune": "Terrassa Padovana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "L132",
      "Abitanti": 2606,
      "Link": "http://www.comuni-italiani.it/028/090/"
    },
    {
      "Istat": "078147",
      "Comune": "Terravecchia",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "L134",
      "Abitanti": 856,
      "Link": "http://www.comuni-italiani.it/078/147/"
    },
    {
      "Istat": "023085",
      "Comune": "Terrazzo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "L136",
      "Abitanti": 2335,
      "Link": "http://www.comuni-italiani.it/023/085/"
    },
    {
      "Istat": "022194",
      "Comune": "Terres",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "L137",
      "Abitanti": 323,
      "Link": "http://www.comuni-italiani.it/022/194/"
    },
    {
      "Istat": "050036",
      "Comune": "Terricciola",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0587",
      "CAP": 56030,
      "CodFisco": "L138",
      "Abitanti": 4556,
      "Link": "http://www.comuni-italiani.it/050/036/"
    },
    {
      "Istat": "006171",
      "Comune": "Terruggia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "L139",
      "Abitanti": 898,
      "Link": "http://www.comuni-italiani.it/006/171/"
    },
    {
      "Istat": 105017,
      "Comune": "Tertenia",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08047",
      "CodFisco": "L140",
      "Abitanti": 3840,
      "Link": "http://www.comuni-italiani.it/105/017/"
    },
    {
      "Istat": "063082",
      "Comune": "Terzigno",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "L142",
      "Abitanti": 17866,
      "Link": "http://www.comuni-italiani.it/063/082/"
    },
    {
      "Istat": "006172",
      "Comune": "Terzo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "L143",
      "Abitanti": 906,
      "Link": "http://www.comuni-italiani.it/006/172/"
    },
    {
      "Istat": "030120",
      "Comune": "Terzo d'Aquileia",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "L144",
      "Abitanti": 2900,
      "Link": "http://www.comuni-italiani.it/030/120/"
    },
    {
      "Istat": "022195",
      "Comune": "Terzolas",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38027,
      "CodFisco": "L145",
      "Abitanti": 606,
      "Link": "http://www.comuni-italiani.it/022/195/"
    },
    {
      "Istat": "008060",
      "Comune": "Terzorio",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "L146",
      "Abitanti": 239,
      "Link": "http://www.comuni-italiani.it/008/060/"
    },
    {
      "Istat": "022196",
      "Comune": "Tesero",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38038,
      "CodFisco": "L147",
      "Abitanti": 2871,
      "Link": "http://www.comuni-italiani.it/022/196/"
    },
    {
      "Istat": "021099",
      "Comune": "Tesimo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "L149",
      "Abitanti": 1853,
      "Link": "http://www.comuni-italiani.it/021/099/"
    },
    {
      "Istat": "056051",
      "Comune": "Tessennano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "L150",
      "Abitanti": 367,
      "Link": "http://www.comuni-italiani.it/056/051/"
    },
    {
      "Istat": "009060",
      "Comune": "Testico",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17020,
      "CodFisco": "L152",
      "Abitanti": 219,
      "Link": "http://www.comuni-italiani.it/009/060/"
    },
    {
      "Istat": "091090",
      "Comune": "Teti",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08030",
      "CodFisco": "L153",
      "Abitanti": 722,
      "Link": "http://www.comuni-italiani.it/091/090/"
    },
    {
      "Istat": "092084",
      "Comune": "Teulada",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09019",
      "CodFisco": "L154",
      "Abitanti": 3793,
      "Link": "http://www.comuni-italiani.it/092/084/"
    },
    {
      "Istat": "061092",
      "Comune": "Teverola",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "L155",
      "Abitanti": 13707,
      "Link": "http://www.comuni-italiani.it/061/092/"
    },
    {
      "Istat": "024104",
      "Comune": "Tezze sul Brenta",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36056,
      "CodFisco": "L156",
      "Abitanti": 12568,
      "Link": "http://www.comuni-italiani.it/024/104/"
    },
    {
      "Istat": "024105",
      "Comune": "Thiene",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36016,
      "CodFisco": "L157",
      "Abitanti": 23505,
      "Link": "http://www.comuni-italiani.it/024/105/"
    },
    {
      "Istat": "090071",
      "Comune": "Thiesi",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07047",
      "CodFisco": "L158",
      "Abitanti": 3010,
      "Link": "http://www.comuni-italiani.it/090/071/"
    },
    {
      "Istat": "091091",
      "Comune": "Tiana",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "L160",
      "Abitanti": 529,
      "Link": "http://www.comuni-italiani.it/091/091/"
    },
    {
      "Istat": "019104",
      "Comune": "Ticengo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26020,
      "CodFisco": "L164",
      "Abitanti": 444,
      "Link": "http://www.comuni-italiani.it/019/104/"
    },
    {
      "Istat": "006173",
      "Comune": "Ticineto",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "L165",
      "Abitanti": 1399,
      "Link": "http://www.comuni-italiani.it/006/173/"
    },
    {
      "Istat": "075086",
      "Comune": "Tiggiano",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73030,
      "CodFisco": "L166",
      "Abitanti": 2931,
      "Link": "http://www.comuni-italiani.it/075/086/"
    },
    {
      "Istat": "010061",
      "Comune": "Tiglieto",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "L167",
      "Abitanti": 594,
      "Link": "http://www.comuni-italiani.it/010/061/"
    },
    {
      "Istat": "005108",
      "Comune": "Tigliole",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14016,
      "CodFisco": "L168",
      "Abitanti": 1714,
      "Link": "http://www.comuni-italiani.it/005/108/"
    },
    {
      "Istat": "017185",
      "Comune": "Tignale",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "L169",
      "Abitanti": 1312,
      "Link": "http://www.comuni-italiani.it/017/185/"
    },
    {
      "Istat": "095088",
      "Comune": "Tinnura",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "08010",
      "CodFisco": "L172",
      "Abitanti": 266,
      "Link": "http://www.comuni-italiani.it/095/088/"
    },
    {
      "Istat": "066100",
      "Comune": "Tione degli Abruzzi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "L173",
      "Abitanti": 334,
      "Link": "http://www.comuni-italiani.it/066/100/"
    },
    {
      "Istat": "022199",
      "Comune": "Tione di Trento",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38079,
      "CodFisco": "L174",
      "Abitanti": 3637,
      "Link": "http://www.comuni-italiani.it/022/199/"
    },
    {
      "Istat": "014066",
      "Comune": "Tirano",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23037,
      "CodFisco": "L175",
      "Abitanti": 9238,
      "Link": "http://www.comuni-italiani.it/014/066/"
    },
    {
      "Istat": "021100",
      "Comune": "Tires",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39050,
      "CodFisco": "L176",
      "Abitanti": 978,
      "Link": "http://www.comuni-italiani.it/021/100/"
    },
    {
      "Istat": "079147",
      "Comune": "Tiriolo",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88056,
      "CodFisco": "L177",
      "Abitanti": 3975,
      "Link": "http://www.comuni-italiani.it/079/147/"
    },
    {
      "Istat": "021101",
      "Comune": "Tirolo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39019,
      "CodFisco": "L178",
      "Abitanti": 2465,
      "Link": "http://www.comuni-italiani.it/021/101/"
    },
    {
      "Istat": "090072",
      "Comune": "Tissi",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "L180",
      "Abitanti": 2300,
      "Link": "http://www.comuni-italiani.it/090/072/"
    },
    {
      "Istat": "076089",
      "Comune": "Tito",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85050,
      "CodFisco": "L181",
      "Abitanti": 7197,
      "Link": "http://www.comuni-italiani.it/076/089/"
    },
    {
      "Istat": "058104",
      "Comune": "Tivoli",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00019",
      "CodFisco": "L182",
      "Abitanti": 56531,
      "Link": "http://www.comuni-italiani.it/058/104/"
    },
    {
      "Istat": "034039",
      "Comune": "Tizzano Val Parma",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43028,
      "CodFisco": "L183",
      "Abitanti": 2163,
      "Link": "http://www.comuni-italiani.it/034/039/"
    },
    {
      "Istat": "035041",
      "Comune": "Toano",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42010,
      "CodFisco": "L184",
      "Abitanti": 4541,
      "Link": "http://www.comuni-italiani.it/035/041/"
    },
    {
      "Istat": "062075",
      "Comune": "Tocco Caudio",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "L185",
      "Abitanti": 1570,
      "Link": "http://www.comuni-italiani.it/062/075/"
    },
    {
      "Istat": "068042",
      "Comune": "Tocco da Casauria",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65028,
      "CodFisco": "L186",
      "Abitanti": 2782,
      "Link": "http://www.comuni-italiani.it/068/042/"
    },
    {
      "Istat": 103065,
      "Comune": "Toceno",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28858,
      "CodFisco": "L187",
      "Abitanti": 779,
      "Link": "http://www.comuni-italiani.it/103/065/"
    },
    {
      "Istat": "054052",
      "Comune": "Todi",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06059",
      "CodFisco": "L188",
      "Abitanti": 17399,
      "Link": "http://www.comuni-italiani.it/054/052/"
    },
    {
      "Istat": "057068",
      "Comune": "Toffia",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02039",
      "CodFisco": "L189",
      "Abitanti": 1085,
      "Link": "http://www.comuni-italiani.it/057/068/"
    },
    {
      "Istat": "009061",
      "Comune": "Toirano",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17055,
      "CodFisco": "L190",
      "Abitanti": 2690,
      "Link": "http://www.comuni-italiani.it/009/061/"
    },
    {
      "Istat": "043053",
      "Comune": "Tolentino",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62029,
      "CodFisco": "L191",
      "Abitanti": 20769,
      "Link": "http://www.comuni-italiani.it/043/053/"
    },
    {
      "Istat": "058105",
      "Comune": "Tolfa",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0766",
      "CAP": "00059",
      "CodFisco": "L192",
      "Abitanti": 5252,
      "Link": "http://www.comuni-italiani.it/058/105/"
    },
    {
      "Istat": "096068",
      "Comune": "Tollegno",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13818,
      "CodFisco": "L193",
      "Abitanti": 2667,
      "Link": "http://www.comuni-italiani.it/096/068/"
    },
    {
      "Istat": "069090",
      "Comune": "Tollo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "L194",
      "Abitanti": 4183,
      "Link": "http://www.comuni-italiani.it/069/090/"
    },
    {
      "Istat": "030121",
      "Comune": "Tolmezzo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33028,
      "CodFisco": "L195",
      "Abitanti": 10659,
      "Link": "http://www.comuni-italiani.it/030/121/"
    },
    {
      "Istat": "076090",
      "Comune": "Tolve",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85017,
      "CodFisco": "L197",
      "Abitanti": 3396,
      "Link": "http://www.comuni-italiani.it/076/090/"
    },
    {
      "Istat": "028091",
      "Comune": "Tombolo",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35019,
      "CodFisco": "L199",
      "Abitanti": 8288,
      "Link": "http://www.comuni-italiani.it/028/091/"
    },
    {
      "Istat": "022200",
      "Comune": "Ton",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "L200",
      "Abitanti": 1316,
      "Link": "http://www.comuni-italiani.it/022/200/"
    },
    {
      "Istat": "022201",
      "Comune": "Tonadico",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38054,
      "CodFisco": "L201",
      "Abitanti": 1470,
      "Link": "http://www.comuni-italiani.it/022/201/"
    },
    {
      "Istat": "091093",
      "Comune": "Tonara",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08039",
      "CodFisco": "L202",
      "Abitanti": 2145,
      "Link": "http://www.comuni-italiani.it/091/093/"
    },
    {
      "Istat": "005109",
      "Comune": "Tonco",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14039,
      "CodFisco": "L203",
      "Abitanti": 911,
      "Link": "http://www.comuni-italiani.it/005/109/"
    },
    {
      "Istat": "005110",
      "Comune": "Tonengo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14023,
      "CodFisco": "L204",
      "Abitanti": 200,
      "Link": "http://www.comuni-italiani.it/005/110/"
    },
    {
      "Istat": "024106",
      "Comune": "Tonezza del Cimone",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36040,
      "CodFisco": "D717",
      "Abitanti": 589,
      "Link": "http://www.comuni-italiani.it/024/106/"
    },
    {
      "Istat": "061093",
      "Comune": "Tora e Piccilli",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81044,
      "CodFisco": "L205",
      "Abitanti": 970,
      "Link": "http://www.comuni-italiani.it/061/093/"
    },
    {
      "Istat": "078148",
      "Comune": "Torano Castello",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87010,
      "CodFisco": "L206",
      "Abitanti": 4789,
      "Link": "http://www.comuni-italiani.it/078/148/"
    },
    {
      "Istat": "067042",
      "Comune": "Torano Nuovo",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "L207",
      "Abitanti": 1692,
      "Link": "http://www.comuni-italiani.it/067/042/"
    },
    {
      "Istat": "017186",
      "Comune": "Torbole Casaglia",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "L210",
      "Abitanti": 6332,
      "Link": "http://www.comuni-italiani.it/017/186/"
    },
    {
      "Istat": "022202",
      "Comune": "Torcegno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38050,
      "CodFisco": "L211",
      "Abitanti": 709,
      "Link": "http://www.comuni-italiani.it/022/202/"
    },
    {
      "Istat": "065147",
      "Comune": "Torchiara",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84076,
      "CodFisco": "L212",
      "Abitanti": 1808,
      "Link": "http://www.comuni-italiani.it/065/147/"
    },
    {
      "Istat": "074018",
      "Comune": "Torchiarolo",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72020,
      "CodFisco": "L213",
      "Abitanti": 5179,
      "Link": "http://www.comuni-italiani.it/074/018/"
    },
    {
      "Istat": "064109",
      "Comune": "Torella dei Lombardi",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83057,
      "CodFisco": "L214",
      "Abitanti": 2235,
      "Link": "http://www.comuni-italiani.it/064/109/"
    },
    {
      "Istat": "070079",
      "Comune": "Torella del Sannio",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86028,
      "CodFisco": "L215",
      "Abitanti": 801,
      "Link": "http://www.comuni-italiani.it/070/079/"
    },
    {
      "Istat": "054053",
      "Comune": "Torgiano",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06089",
      "CodFisco": "L216",
      "Abitanti": 6585,
      "Link": "http://www.comuni-italiani.it/054/053/"
    },
    {
      "Istat": "007067",
      "Comune": "Torgnon",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "L217",
      "Abitanti": 518,
      "Link": "http://www.comuni-italiani.it/007/067/"
    },
    {
      "Istat": "001272",
      "Comune": "Torino",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": "101xx",
      "CodFisco": "L219",
      "Abitanti": 907563,
      "Link": "http://www.comuni-italiani.it/001/272/"
    },
    {
      "Istat": "069091",
      "Comune": "Torino di Sangro",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66020,
      "CodFisco": "L218",
      "Abitanti": 3084,
      "Link": "http://www.comuni-italiani.it/069/091/"
    },
    {
      "Istat": "072044",
      "Comune": "Toritto",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70020,
      "CodFisco": "L220",
      "Abitanti": 8645,
      "Link": "http://www.comuni-italiani.it/072/044/"
    },
    {
      "Istat": "019105",
      "Comune": "Torlino Vimercati",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26017,
      "CodFisco": "L221",
      "Abitanti": 445,
      "Link": "http://www.comuni-italiani.it/019/105/"
    },
    {
      "Istat": "003146",
      "Comune": "Tornaco",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28070,
      "CodFisco": "L223",
      "Abitanti": 864,
      "Link": "http://www.comuni-italiani.it/003/146/"
    },
    {
      "Istat": "069092",
      "Comune": "Tornareccio",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66046,
      "CodFisco": "L224",
      "Abitanti": 1937,
      "Link": "http://www.comuni-italiani.it/069/092/"
    },
    {
      "Istat": "019106",
      "Comune": "Tornata",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26030,
      "CodFisco": "L225",
      "Abitanti": 500,
      "Link": "http://www.comuni-italiani.it/019/106/"
    },
    {
      "Istat": "066101",
      "Comune": "Tornimparte",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67049,
      "CodFisco": "L227",
      "Abitanti": 3042,
      "Link": "http://www.comuni-italiani.it/066/101/"
    },
    {
      "Istat": "013223",
      "Comune": "Torno",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "L228",
      "Abitanti": 1214,
      "Link": "http://www.comuni-italiani.it/013/223/"
    },
    {
      "Istat": "034040",
      "Comune": "Tornolo",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43059,
      "CodFisco": "L229",
      "Abitanti": 1145,
      "Link": "http://www.comuni-italiani.it/034/040/"
    },
    {
      "Istat": "070080",
      "Comune": "Toro",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86018,
      "CodFisco": "L230",
      "Abitanti": 1513,
      "Link": "http://www.comuni-italiani.it/070/080/"
    },
    {
      "Istat": "091094",
      "Comune": "TorpË",
      "Provincia": "NU",
      "Regione": "SAR",
      "Prefisso": "0784",
      "CAP": "08020",
      "CodFisco": "L231",
      "Abitanti": 2943,
      "Link": "http://www.comuni-italiani.it/091/094/"
    },
    {
      "Istat": "065148",
      "Comune": "Torraca",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0973",
      "CAP": 84030,
      "CodFisco": "L233",
      "Abitanti": 1304,
      "Link": "http://www.comuni-italiani.it/065/148/"
    },
    {
      "Istat": "090073",
      "Comune": "Torralba",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07048",
      "CodFisco": "L235",
      "Abitanti": 1001,
      "Link": "http://www.comuni-italiani.it/090/073/"
    },
    {
      "Istat": "018155",
      "Comune": "Torrazza Coste",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "L237",
      "Abitanti": 1681,
      "Link": "http://www.comuni-italiani.it/018/155/"
    },
    {
      "Istat": "001273",
      "Comune": "Torrazza Piemonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10037,
      "CodFisco": "L238",
      "Abitanti": 2748,
      "Link": "http://www.comuni-italiani.it/001/273/"
    },
    {
      "Istat": "096069",
      "Comune": "Torrazzo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13884,
      "CodFisco": "L239",
      "Abitanti": 227,
      "Link": "http://www.comuni-italiani.it/096/069/"
    },
    {
      "Istat": "063083",
      "Comune": "Torre Annunziata",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80058,
      "CodFisco": "L245",
      "Abitanti": 43699,
      "Link": "http://www.comuni-italiani.it/063/083/"
    },
    {
      "Istat": "018156",
      "Comune": "Torre Beretti e Castellaro",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "L250",
      "Abitanti": 606,
      "Link": "http://www.comuni-italiani.it/018/156/"
    },
    {
      "Istat": "016214",
      "Comune": "Torre Boldone",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "L251",
      "Abitanti": 8334,
      "Link": "http://www.comuni-italiani.it/016/214/"
    },
    {
      "Istat": "004226",
      "Comune": "Torre Bormida",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "L252",
      "Abitanti": 210,
      "Link": "http://www.comuni-italiani.it/004/226/"
    },
    {
      "Istat": "060078",
      "Comune": "Torre Cajetani",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "L243",
      "Abitanti": 1464,
      "Link": "http://www.comuni-italiani.it/060/078/"
    },
    {
      "Istat": "001274",
      "Comune": "Torre Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10010,
      "CodFisco": "L247",
      "Abitanti": 591,
      "Link": "http://www.comuni-italiani.it/001/274/"
    },
    {
      "Istat": "018157",
      "Comune": "Torre d'Arese",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "L256",
      "Abitanti": 980,
      "Link": "http://www.comuni-italiani.it/018/157/"
    },
    {
      "Istat": "097080",
      "Comune": "Torre de' Busi",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 23806,
      "CodFisco": "L257",
      "Abitanti": 1970,
      "Link": "http://www.comuni-italiani.it/097/080/"
    },
    {
      "Istat": "018158",
      "Comune": "Torre de' Negri",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27011,
      "CodFisco": "L262",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/018/158/"
    },
    {
      "Istat": "068043",
      "Comune": "Torre De' Passeri",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65029,
      "CodFisco": "L263",
      "Abitanti": 3229,
      "Link": "http://www.comuni-italiani.it/068/043/"
    },
    {
      "Istat": "019107",
      "Comune": "Torre de' Picenardi",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26038,
      "CodFisco": "L258",
      "Abitanti": 1801,
      "Link": "http://www.comuni-italiani.it/019/107/"
    },
    {
      "Istat": "016216",
      "Comune": "Torre de' Roveri",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "L265",
      "Abitanti": 2326,
      "Link": "http://www.comuni-italiani.it/016/216/"
    },
    {
      "Istat": "063084",
      "Comune": "Torre del Greco",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80059,
      "CodFisco": "L259",
      "Abitanti": 87197,
      "Link": "http://www.comuni-italiani.it/063/084/"
    },
    {
      "Istat": "027041",
      "Comune": "Torre di Mosto",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 30020,
      "CodFisco": "L267",
      "Abitanti": 4766,
      "Link": "http://www.comuni-italiani.it/027/041/"
    },
    {
      "Istat": "079148",
      "Comune": "Torre di Ruggiero",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0967",
      "CAP": 88060,
      "CodFisco": "L240",
      "Abitanti": 1136,
      "Link": "http://www.comuni-italiani.it/079/148/"
    },
    {
      "Istat": "014067",
      "Comune": "Torre di Santa Maria",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "L244",
      "Abitanti": 839,
      "Link": "http://www.comuni-italiani.it/014/067/"
    },
    {
      "Istat": "018159",
      "Comune": "Torre d'Isola",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "L269",
      "Abitanti": 2359,
      "Link": "http://www.comuni-italiani.it/018/159/"
    },
    {
      "Istat": "064110",
      "Comune": "Torre Le Nocelle",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "L272",
      "Abitanti": 1376,
      "Link": "http://www.comuni-italiani.it/064/110/"
    },
    {
      "Istat": "004227",
      "Comune": "Torre MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "L241",
      "Abitanti": 511,
      "Link": "http://www.comuni-italiani.it/004/227/"
    },
    {
      "Istat": "065149",
      "Comune": "Torre Orsaia",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84077,
      "CodFisco": "L274",
      "Abitanti": 2259,
      "Link": "http://www.comuni-italiani.it/065/149/"
    },
    {
      "Istat": "016217",
      "Comune": "Torre Pallavicina",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24050,
      "CodFisco": "L276",
      "Abitanti": 1138,
      "Link": "http://www.comuni-italiani.it/016/217/"
    },
    {
      "Istat": "001275",
      "Comune": "Torre Pellice",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10066,
      "CodFisco": "L277",
      "Abitanti": 4735,
      "Link": "http://www.comuni-italiani.it/001/275/"
    },
    {
      "Istat": "004228",
      "Comune": "Torre San Giorgio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "L278",
      "Abitanti": 721,
      "Link": "http://www.comuni-italiani.it/004/228/"
    },
    {
      "Istat": 109040,
      "Comune": "Torre San Patrizio",
      "Provincia": "FM",
      "Regione": "MAR",
      "Prefisso": "0734",
      "CAP": 63814,
      "CodFisco": "L279",
      "Abitanti": 2131,
      "Link": "http://www.comuni-italiani.it/109/040/"
    },
    {
      "Istat": "074019",
      "Comune": "Torre Santa Susanna",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72028,
      "CodFisco": "L280",
      "Abitanti": 10552,
      "Link": "http://www.comuni-italiani.it/074/019/"
    },
    {
      "Istat": "030122",
      "Comune": "Torreano",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "L246",
      "Abitanti": 2266,
      "Link": "http://www.comuni-italiani.it/030/122/"
    },
    {
      "Istat": "024107",
      "Comune": "Torrebelvicino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36036,
      "CodFisco": "L248",
      "Abitanti": 6061,
      "Link": "http://www.comuni-italiani.it/024/107/"
    },
    {
      "Istat": "069093",
      "Comune": "Torrebruna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "L253",
      "Abitanti": 956,
      "Link": "http://www.comuni-italiani.it/069/093/"
    },
    {
      "Istat": "062076",
      "Comune": "Torrecuso",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82030,
      "CodFisco": "L254",
      "Abitanti": 3496,
      "Link": "http://www.comuni-italiani.it/062/076/"
    },
    {
      "Istat": "028092",
      "Comune": "Torreglia",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35038,
      "CodFisco": "L270",
      "Abitanti": 6277,
      "Link": "http://www.comuni-italiani.it/028/092/"
    },
    {
      "Istat": "083098",
      "Comune": "Torregrotta",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "L271",
      "Abitanti": 7400,
      "Link": "http://www.comuni-italiani.it/083/098/"
    },
    {
      "Istat": "071056",
      "Comune": "Torremaggiore",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0882",
      "CAP": 71017,
      "CodFisco": "L273",
      "Abitanti": 17434,
      "Link": "http://www.comuni-italiani.it/071/056/"
    },
    {
      "Istat": "083108",
      "Comune": "Torrenova",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98070,
      "CodFisco": "M286",
      "Abitanti": 4242,
      "Link": "http://www.comuni-italiani.it/083/108/"
    },
    {
      "Istat": "004229",
      "Comune": "Torresina",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "L281",
      "Abitanti": 62,
      "Link": "http://www.comuni-italiani.it/004/229/"
    },
    {
      "Istat": "082072",
      "Comune": "Torretta",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "L282",
      "Abitanti": 4157,
      "Link": "http://www.comuni-italiani.it/082/072/"
    },
    {
      "Istat": "018160",
      "Comune": "Torrevecchia Pia",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "L285",
      "Abitanti": 3409,
      "Link": "http://www.comuni-italiani.it/018/160/"
    },
    {
      "Istat": "069094",
      "Comune": "Torrevecchia Teatina",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "L284",
      "Abitanti": 4153,
      "Link": "http://www.comuni-italiani.it/069/094/"
    },
    {
      "Istat": "023086",
      "Comune": "Torri del Benaco",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37010,
      "CodFisco": "L287",
      "Abitanti": 2963,
      "Link": "http://www.comuni-italiani.it/023/086/"
    },
    {
      "Istat": "024108",
      "Comune": "Torri di Quartesolo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36040,
      "CodFisco": "L297",
      "Abitanti": 11939,
      "Link": "http://www.comuni-italiani.it/024/108/"
    },
    {
      "Istat": "057070",
      "Comune": "Torri in Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02049",
      "CodFisco": "L286",
      "Abitanti": 1310,
      "Link": "http://www.comuni-italiani.it/057/070/"
    },
    {
      "Istat": "099019",
      "Comune": "Torriana",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47825,
      "CodFisco": "I550",
      "Abitanti": 1577,
      "Link": "http://www.comuni-italiani.it/099/019/"
    },
    {
      "Istat": "060079",
      "Comune": "Torrice",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "L290",
      "Abitanti": 4647,
      "Link": "http://www.comuni-italiani.it/060/079/"
    },
    {
      "Istat": "073028",
      "Comune": "Torricella",
      "Provincia": "TA",
      "Regione": "PUG",
      "Prefisso": "099",
      "CAP": 74020,
      "CodFisco": "L294",
      "Abitanti": 4216,
      "Link": "http://www.comuni-italiani.it/073/028/"
    },
    {
      "Istat": "019108",
      "Comune": "Torricella del Pizzo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26040,
      "CodFisco": "L296",
      "Abitanti": 693,
      "Link": "http://www.comuni-italiani.it/019/108/"
    },
    {
      "Istat": "057069",
      "Comune": "Torricella in Sabina",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02030",
      "CodFisco": "L293",
      "Abitanti": 1404,
      "Link": "http://www.comuni-italiani.it/057/069/"
    },
    {
      "Istat": "069095",
      "Comune": "Torricella Peligna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66019,
      "CodFisco": "L291",
      "Abitanti": 1426,
      "Link": "http://www.comuni-italiani.it/069/095/"
    },
    {
      "Istat": "067043",
      "Comune": "Torricella Sicura",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "L295",
      "Abitanti": 2727,
      "Link": "http://www.comuni-italiani.it/067/043/"
    },
    {
      "Istat": "018161",
      "Comune": "Torricella Verzate",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "L292",
      "Abitanti": 849,
      "Link": "http://www.comuni-italiani.it/018/161/"
    },
    {
      "Istat": "010062",
      "Comune": "Torriglia",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16029,
      "CodFisco": "L298",
      "Abitanti": 2468,
      "Link": "http://www.comuni-italiani.it/010/062/"
    },
    {
      "Istat": "034041",
      "Comune": "Torrile",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43056,
      "CodFisco": "L299",
      "Abitanti": 7804,
      "Link": "http://www.comuni-italiani.it/034/041/"
    },
    {
      "Istat": "064111",
      "Comune": "Torrioni",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "L301",
      "Abitanti": 586,
      "Link": "http://www.comuni-italiani.it/064/111/"
    },
    {
      "Istat": "052035",
      "Comune": "Torrita di Siena",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53049,
      "CodFisco": "L303",
      "Abitanti": 7522,
      "Link": "http://www.comuni-italiani.it/052/035/"
    },
    {
      "Istat": "058106",
      "Comune": "Torrita Tiberina",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "00060",
      "CodFisco": "L302",
      "Abitanti": 1076,
      "Link": "http://www.comuni-italiani.it/058/106/"
    },
    {
      "Istat": 105018,
      "Comune": "TortolÏ",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08048",
      "CodFisco": "A355",
      "Abitanti": 10838,
      "Link": "http://www.comuni-italiani.it/105/018/"
    },
    {
      "Istat": "006174",
      "Comune": "Tortona",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15057,
      "CodFisco": "L304",
      "Abitanti": 27706,
      "Link": "http://www.comuni-italiani.it/006/174/"
    },
    {
      "Istat": "078149",
      "Comune": "Tortora",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "L305",
      "Abitanti": 6186,
      "Link": "http://www.comuni-italiani.it/078/149/"
    },
    {
      "Istat": "065150",
      "Comune": "Tortorella",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0973",
      "CAP": 84030,
      "CodFisco": "L306",
      "Abitanti": 572,
      "Link": "http://www.comuni-italiani.it/065/150/"
    },
    {
      "Istat": "067044",
      "Comune": "Tortoreto",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64018,
      "CodFisco": "L307",
      "Abitanti": 10202,
      "Link": "http://www.comuni-italiani.it/067/044/"
    },
    {
      "Istat": "083099",
      "Comune": "Tortorici",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98078,
      "CodFisco": "L308",
      "Abitanti": 6785,
      "Link": "http://www.comuni-italiani.it/083/099/"
    },
    {
      "Istat": "030123",
      "Comune": "Torviscosa",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33050,
      "CodFisco": "L309",
      "Abitanti": 3024,
      "Link": "http://www.comuni-italiani.it/030/123/"
    },
    {
      "Istat": "017187",
      "Comune": "Toscolano-Maderno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25088,
      "CodFisco": "L312",
      "Abitanti": 8111,
      "Link": "http://www.comuni-italiani.it/017/187/"
    },
    {
      "Istat": "067045",
      "Comune": "Tossicia",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64049,
      "CodFisco": "L314",
      "Abitanti": 1457,
      "Link": "http://www.comuni-italiani.it/067/045/"
    },
    {
      "Istat": "014068",
      "Comune": "Tovo di Sant'Agata",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "L316",
      "Abitanti": 630,
      "Link": "http://www.comuni-italiani.it/014/068/"
    },
    {
      "Istat": "009062",
      "Comune": "Tovo San Giacomo",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17020,
      "CodFisco": "L315",
      "Abitanti": 2508,
      "Link": "http://www.comuni-italiani.it/009/062/"
    },
    {
      "Istat": "082073",
      "Comune": "Trabia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90019,
      "CodFisco": "L317",
      "Abitanti": 9682,
      "Link": "http://www.comuni-italiani.it/082/073/"
    },
    {
      "Istat": "012127",
      "Comune": "Tradate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21049,
      "CodFisco": "L319",
      "Abitanti": 17901,
      "Link": "http://www.comuni-italiani.it/012/127/"
    },
    {
      "Istat": "095066",
      "Comune": "Tramatza",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "L321",
      "Abitanti": 992,
      "Link": "http://www.comuni-italiani.it/095/066/"
    },
    {
      "Istat": "022203",
      "Comune": "Trambileno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38068,
      "CodFisco": "L322",
      "Abitanti": 1373,
      "Link": "http://www.comuni-italiani.it/022/203/"
    },
    {
      "Istat": "065151",
      "Comune": "Tramonti",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84010,
      "CodFisco": "L323",
      "Abitanti": 4145,
      "Link": "http://www.comuni-italiani.it/065/151/"
    },
    {
      "Istat": "093045",
      "Comune": "Tramonti di Sopra",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "L324",
      "Abitanti": 385,
      "Link": "http://www.comuni-italiani.it/093/045/"
    },
    {
      "Istat": "093046",
      "Comune": "Tramonti di Sotto",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "L325",
      "Abitanti": 423,
      "Link": "http://www.comuni-italiani.it/093/046/"
    },
    {
      "Istat": "076091",
      "Comune": "Tramutola",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85057,
      "CodFisco": "L326",
      "Abitanti": 3195,
      "Link": "http://www.comuni-italiani.it/076/091/"
    },
    {
      "Istat": "001276",
      "Comune": "Trana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "L327",
      "Abitanti": 3874,
      "Link": "http://www.comuni-italiani.it/001/276/"
    },
    {
      "Istat": 110009,
      "Comune": "Trani",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76125,
      "CodFisco": "L328",
      "Abitanti": 53940,
      "Link": "http://www.comuni-italiani.it/110/009/"
    },
    {
      "Istat": "022204",
      "Comune": "Transacqua",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0439",
      "CAP": 38054,
      "CodFisco": "L329",
      "Abitanti": 2128,
      "Link": "http://www.comuni-italiani.it/022/204/"
    },
    {
      "Istat": "014069",
      "Comune": "Traona",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23019,
      "CodFisco": "L330",
      "Abitanti": 2534,
      "Link": "http://www.comuni-italiani.it/014/069/"
    },
    {
      "Istat": "081021",
      "Comune": "Trapani",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91100,
      "CodFisco": "L331",
      "Abitanti": 70622,
      "Link": "http://www.comuni-italiani.it/081/021/"
    },
    {
      "Istat": "082074",
      "Comune": "Trappeto",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90040,
      "CodFisco": "L332",
      "Abitanti": 3250,
      "Link": "http://www.comuni-italiani.it/082/074/"
    },
    {
      "Istat": 103066,
      "Comune": "Trarego Viggiona",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28826,
      "CodFisco": "L333",
      "Abitanti": 398,
      "Link": "http://www.comuni-italiani.it/103/066/"
    },
    {
      "Istat": "066102",
      "Comune": "Trasacco",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67059,
      "CodFisco": "L334",
      "Abitanti": 6252,
      "Link": "http://www.comuni-italiani.it/066/102/"
    },
    {
      "Istat": "030124",
      "Comune": "Trasaghis",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "L335",
      "Abitanti": 2337,
      "Link": "http://www.comuni-italiani.it/030/124/"
    },
    {
      "Istat": 103067,
      "Comune": "Trasquera",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28868,
      "CodFisco": "L336",
      "Abitanti": 222,
      "Link": "http://www.comuni-italiani.it/103/067/"
    },
    {
      "Istat": 107021,
      "Comune": "Tratalias",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "L337",
      "Abitanti": 1102,
      "Link": "http://www.comuni-italiani.it/107/021/"
    },
    {
      "Istat": "001277",
      "Comune": "Trausella",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "L338",
      "Abitanti": 135,
      "Link": "http://www.comuni-italiani.it/001/277/"
    },
    {
      "Istat": "018162",
      "Comune": "TravacÚ Siccomario",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "I236",
      "Abitanti": 4280,
      "Link": "http://www.comuni-italiani.it/018/162/"
    },
    {
      "Istat": "017188",
      "Comune": "Travagliato",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25039,
      "CodFisco": "L339",
      "Abitanti": 13415,
      "Link": "http://www.comuni-italiani.it/017/188/"
    },
    {
      "Istat": "012128",
      "Comune": "Travedona-Monate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21028,
      "CodFisco": "L342",
      "Abitanti": 4029,
      "Link": "http://www.comuni-italiani.it/012/128/"
    },
    {
      "Istat": "001278",
      "Comune": "Traversella",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "L345",
      "Abitanti": 350,
      "Link": "http://www.comuni-italiani.it/001/278/"
    },
    {
      "Istat": "034042",
      "Comune": "Traversetolo",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43029,
      "CodFisco": "L346",
      "Abitanti": 9339,
      "Link": "http://www.comuni-italiani.it/034/042/"
    },
    {
      "Istat": "001279",
      "Comune": "Traves",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "L340",
      "Abitanti": 564,
      "Link": "http://www.comuni-italiani.it/001/279/"
    },
    {
      "Istat": "093047",
      "Comune": "Travesio",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "L347",
      "Abitanti": 1861,
      "Link": "http://www.comuni-italiani.it/093/047/"
    },
    {
      "Istat": "033043",
      "Comune": "Travo",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "L348",
      "Abitanti": 2009,
      "Link": "http://www.comuni-italiani.it/033/043/"
    },
    {
      "Istat": "028093",
      "Comune": "Trebaseleghe",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "L349",
      "Abitanti": 12656,
      "Link": "http://www.comuni-italiani.it/028/093/"
    },
    {
      "Istat": "078150",
      "Comune": "Trebisacce",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87075,
      "CodFisco": "L353",
      "Abitanti": 9405,
      "Link": "http://www.comuni-italiani.it/078/150/"
    },
    {
      "Istat": "034043",
      "Comune": "Trecasali",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0521",
      "CAP": 43010,
      "CodFisco": "L354",
      "Abitanti": 3679,
      "Link": "http://www.comuni-italiani.it/034/043/"
    },
    {
      "Istat": "063091",
      "Comune": "Trecase",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "M280",
      "Abitanti": 9311,
      "Link": "http://www.comuni-italiani.it/063/091/"
    },
    {
      "Istat": "087050",
      "Comune": "Trecastagni",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95039,
      "CodFisco": "L355",
      "Abitanti": 10475,
      "Link": "http://www.comuni-italiani.it/087/050/"
    },
    {
      "Istat": "003149",
      "Comune": "Trecate",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28069,
      "CodFisco": "L356",
      "Abitanti": 20190,
      "Link": "http://www.comuni-italiani.it/003/149/"
    },
    {
      "Istat": "076092",
      "Comune": "Trecchina",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85049,
      "CodFisco": "L357",
      "Abitanti": 2369,
      "Link": "http://www.comuni-italiani.it/076/092/"
    },
    {
      "Istat": "029047",
      "Comune": "Trecenta",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45027,
      "CodFisco": "L359",
      "Abitanti": 3012,
      "Link": "http://www.comuni-italiani.it/029/047/"
    },
    {
      "Istat": "040049",
      "Comune": "Tredozio",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0546",
      "CAP": 47019,
      "CodFisco": "L361",
      "Abitanti": 1283,
      "Link": "http://www.comuni-italiani.it/040/049/"
    },
    {
      "Istat": "069096",
      "Comune": "Treglio",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66030,
      "CodFisco": "L363",
      "Abitanti": 1610,
      "Link": "http://www.comuni-italiani.it/069/096/"
    },
    {
      "Istat": "023087",
      "Comune": "Tregnago",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37039,
      "CodFisco": "L364",
      "Abitanti": 4938,
      "Link": "http://www.comuni-italiani.it/023/087/"
    },
    {
      "Istat": "043054",
      "Comune": "Treia",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "L366",
      "Abitanti": 9740,
      "Link": "http://www.comuni-italiani.it/043/054/"
    },
    {
      "Istat": "004230",
      "Comune": "Treiso",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "L367",
      "Abitanti": 828,
      "Link": "http://www.comuni-italiani.it/004/230/"
    },
    {
      "Istat": "097081",
      "Comune": "Tremenico",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23836,
      "CodFisco": "L368",
      "Abitanti": 191,
      "Link": "http://www.comuni-italiani.it/097/081/"
    },
    {
      "Istat": "087051",
      "Comune": "Tremestieri Etneo",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95030,
      "CodFisco": "L369",
      "Abitanti": 21460,
      "Link": "http://www.comuni-italiani.it/087/051/"
    },
    {
      "Istat": "013225",
      "Comune": "Tremezzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22019,
      "CodFisco": "L371",
      "Abitanti": 1260,
      "Link": "http://www.comuni-italiani.it/013/225/"
    },
    {
      "Istat": "017189",
      "Comune": "Tremosine",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25010,
      "CodFisco": "L372",
      "Abitanti": 2154,
      "Link": "http://www.comuni-italiani.it/017/189/"
    },
    {
      "Istat": "078151",
      "Comune": "Trenta",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87050,
      "CodFisco": "L375",
      "Abitanti": 2764,
      "Link": "http://www.comuni-italiani.it/078/151/"
    },
    {
      "Istat": "065152",
      "Comune": "Trentinara",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84070,
      "CodFisco": "L377",
      "Abitanti": 1724,
      "Link": "http://www.comuni-italiani.it/065/152/"
    },
    {
      "Istat": "022205",
      "Comune": "Trento",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": "3812x",
      "CodFisco": "L378",
      "Abitanti": 116298,
      "Link": "http://www.comuni-italiani.it/022/205/"
    },
    {
      "Istat": "061094",
      "Comune": "Trentola-Ducenta",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81038,
      "CodFisco": "L379",
      "Abitanti": 17794,
      "Link": "http://www.comuni-italiani.it/061/094/"
    },
    {
      "Istat": "017190",
      "Comune": "Trenzano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "L380",
      "Abitanti": 5551,
      "Link": "http://www.comuni-italiani.it/017/190/"
    },
    {
      "Istat": "030125",
      "Comune": "Treppo Carnico",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "L381",
      "Abitanti": 659,
      "Link": "http://www.comuni-italiani.it/030/125/"
    },
    {
      "Istat": "030126",
      "Comune": "Treppo Grande",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "L382",
      "Abitanti": 1765,
      "Link": "http://www.comuni-italiani.it/030/126/"
    },
    {
      "Istat": "075087",
      "Comune": "Trepuzzi",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73019,
      "CodFisco": "L383",
      "Abitanti": 14688,
      "Link": "http://www.comuni-italiani.it/075/087/"
    },
    {
      "Istat": "052036",
      "Comune": "Trequanda",
      "Provincia": "SI",
      "Regione": "TOS",
      "Prefisso": "0577",
      "CAP": 53020,
      "CodFisco": "L384",
      "Abitanti": 1380,
      "Link": "http://www.comuni-italiani.it/052/036/"
    },
    {
      "Istat": "022206",
      "Comune": "Tres",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "L385",
      "Abitanti": 716,
      "Link": "http://www.comuni-italiani.it/022/206/"
    },
    {
      "Istat": "045015",
      "Comune": "Tresana",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54012,
      "CodFisco": "L386",
      "Abitanti": 2093,
      "Link": "http://www.comuni-italiani.it/045/015/"
    },
    {
      "Istat": "016218",
      "Comune": "Trescore Balneario",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24069,
      "CodFisco": "L388",
      "Abitanti": 9685,
      "Link": "http://www.comuni-italiani.it/016/218/"
    },
    {
      "Istat": "019109",
      "Comune": "Trescore Cremasco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26017,
      "CodFisco": "L389",
      "Abitanti": 2931,
      "Link": "http://www.comuni-italiani.it/019/109/"
    },
    {
      "Istat": "038024",
      "Comune": "Tresigallo",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0533",
      "CAP": 44039,
      "CodFisco": "L390",
      "Abitanti": 4617,
      "Link": "http://www.comuni-italiani.it/038/024/"
    },
    {
      "Istat": "014070",
      "Comune": "Tresivio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23020,
      "CodFisco": "L392",
      "Abitanti": 2024,
      "Link": "http://www.comuni-italiani.it/014/070/"
    },
    {
      "Istat": "095067",
      "Comune": "Tresnuraghes",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0785",
      "CAP": "09079",
      "CodFisco": "L393",
      "Abitanti": 1236,
      "Link": "http://www.comuni-italiani.it/095/067/"
    },
    {
      "Istat": "023088",
      "Comune": "Trevenzuolo",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37060,
      "CodFisco": "L396",
      "Abitanti": 2715,
      "Link": "http://www.comuni-italiani.it/023/088/"
    },
    {
      "Istat": "054054",
      "Comune": "Trevi",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06039",
      "CodFisco": "L397",
      "Abitanti": 8405,
      "Link": "http://www.comuni-italiani.it/054/054/"
    },
    {
      "Istat": "060080",
      "Comune": "Trevi nel Lazio",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "L398",
      "Abitanti": 1754,
      "Link": "http://www.comuni-italiani.it/060/080/"
    },
    {
      "Istat": "064112",
      "Comune": "Trevico",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83058,
      "CodFisco": "L399",
      "Abitanti": 1085,
      "Link": "http://www.comuni-italiani.it/064/112/"
    },
    {
      "Istat": "016219",
      "Comune": "Treviglio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 24047,
      "CodFisco": "L400",
      "Abitanti": 29034,
      "Link": "http://www.comuni-italiani.it/016/219/"
    },
    {
      "Istat": "026085",
      "Comune": "Trevignano",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31040,
      "CodFisco": "L402",
      "Abitanti": 10613,
      "Link": "http://www.comuni-italiani.it/026/085/"
    },
    {
      "Istat": "058107",
      "Comune": "Trevignano Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00069",
      "CodFisco": "L401",
      "Abitanti": 5949,
      "Link": "http://www.comuni-italiani.it/058/107/"
    },
    {
      "Istat": "006175",
      "Comune": "Treville",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "L403",
      "Abitanti": 279,
      "Link": "http://www.comuni-italiani.it/006/175/"
    },
    {
      "Istat": "016220",
      "Comune": "Treviolo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24048,
      "CodFisco": "L404",
      "Abitanti": 10297,
      "Link": "http://www.comuni-italiani.it/016/220/"
    },
    {
      "Istat": "026086",
      "Comune": "Treviso",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31100,
      "CodFisco": "L407",
      "Abitanti": 82807,
      "Link": "http://www.comuni-italiani.it/026/086/"
    },
    {
      "Istat": "017191",
      "Comune": "Treviso Bresciano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25070,
      "CodFisco": "L406",
      "Abitanti": 568,
      "Link": "http://www.comuni-italiani.it/017/191/"
    },
    {
      "Istat": "015219",
      "Comune": "Trezzano Rosa",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "L408",
      "Abitanti": 4804,
      "Link": "http://www.comuni-italiani.it/015/219/"
    },
    {
      "Istat": "015220",
      "Comune": "Trezzano sul Naviglio",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "L409",
      "Abitanti": 19350,
      "Link": "http://www.comuni-italiani.it/015/220/"
    },
    {
      "Istat": "015221",
      "Comune": "Trezzo sull'Adda",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20056,
      "CodFisco": "L411",
      "Abitanti": 12249,
      "Link": "http://www.comuni-italiani.it/015/221/"
    },
    {
      "Istat": "004231",
      "Comune": "Trezzo Tinella",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12050,
      "CodFisco": "L410",
      "Abitanti": 359,
      "Link": "http://www.comuni-italiani.it/004/231/"
    },
    {
      "Istat": "013226",
      "Comune": "Trezzone",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "L413",
      "Abitanti": 239,
      "Link": "http://www.comuni-italiani.it/013/226/"
    },
    {
      "Istat": "028094",
      "Comune": "Tribano",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35020,
      "CodFisco": "L414",
      "Abitanti": 4465,
      "Link": "http://www.comuni-italiani.it/028/094/"
    },
    {
      "Istat": "015222",
      "Comune": "Tribiano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20067,
      "CodFisco": "L415",
      "Abitanti": 3330,
      "Link": "http://www.comuni-italiani.it/015/222/"
    },
    {
      "Istat": "010063",
      "Comune": "Tribogna",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "L416",
      "Abitanti": 628,
      "Link": "http://www.comuni-italiani.it/010/063/"
    },
    {
      "Istat": "077028",
      "Comune": "Tricarico",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75019,
      "CodFisco": "L418",
      "Abitanti": 5808,
      "Link": "http://www.comuni-italiani.it/077/028/"
    },
    {
      "Istat": "075088",
      "Comune": "Tricase",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73039,
      "CodFisco": "L419",
      "Abitanti": 17792,
      "Link": "http://www.comuni-italiani.it/075/088/"
    },
    {
      "Istat": "002147",
      "Comune": "Tricerro",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13038,
      "CodFisco": "L420",
      "Abitanti": 723,
      "Link": "http://www.comuni-italiani.it/002/147/"
    },
    {
      "Istat": "030127",
      "Comune": "Tricesimo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33019,
      "CodFisco": "L421",
      "Abitanti": 7721,
      "Link": "http://www.comuni-italiani.it/030/127/"
    },
    {
      "Istat": "025061",
      "Comune": "Trichiana",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32028,
      "CodFisco": "L422",
      "Abitanti": 4810,
      "Link": "http://www.comuni-italiani.it/025/061/"
    },
    {
      "Istat": 105019,
      "Comune": "Triei",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "L423",
      "Abitanti": 1130,
      "Link": "http://www.comuni-italiani.it/105/019/"
    },
    {
      "Istat": "032006",
      "Comune": "Trieste",
      "Provincia": "TS",
      "Regione": "FVG",
      "Prefisso": "040",
      "CAP": "341xx",
      "CodFisco": "L424",
      "Abitanti": 205535,
      "Link": "http://www.comuni-italiani.it/032/006/"
    },
    {
      "Istat": "072046",
      "Comune": "Triggiano",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70019,
      "CodFisco": "L425",
      "Abitanti": 27553,
      "Link": "http://www.comuni-italiani.it/072/046/"
    },
    {
      "Istat": "019110",
      "Comune": "Trigolo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0374",
      "CAP": 26018,
      "CodFisco": "L426",
      "Abitanti": 1772,
      "Link": "http://www.comuni-italiani.it/019/110/"
    },
    {
      "Istat": "004232",
      "Comune": "Trinit‡",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12049,
      "CodFisco": "L427",
      "Abitanti": 2181,
      "Link": "http://www.comuni-italiani.it/004/232/"
    },
    {
      "Istat": 104026,
      "Comune": "Trinit‡ d'Agultu e Vignola",
      "Provincia": "OT",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07038",
      "CodFisco": "L428",
      "Abitanti": 2184,
      "Link": "http://www.comuni-italiani.it/104/026/"
    },
    {
      "Istat": 110010,
      "Comune": "Trinitapoli",
      "Provincia": "BT",
      "Regione": "PUG",
      "Prefisso": "0883",
      "CAP": 76015,
      "CodFisco": "B915",
      "Abitanti": 14551,
      "Link": "http://www.comuni-italiani.it/110/010/"
    },
    {
      "Istat": "002148",
      "Comune": "Trino",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13039,
      "CodFisco": "L429",
      "Abitanti": 7627,
      "Link": "http://www.comuni-italiani.it/002/148/"
    },
    {
      "Istat": "008061",
      "Comune": "Triora",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18010,
      "CodFisco": "L430",
      "Abitanti": 398,
      "Link": "http://www.comuni-italiani.it/008/061/"
    },
    {
      "Istat": "083100",
      "Comune": "Tripi",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "L431",
      "Abitanti": 943,
      "Link": "http://www.comuni-italiani.it/083/100/"
    },
    {
      "Istat": "006176",
      "Comune": "Trisobbio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15070,
      "CodFisco": "L432",
      "Abitanti": 664,
      "Link": "http://www.comuni-italiani.it/006/176/"
    },
    {
      "Istat": "024110",
      "Comune": "Trissino",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36070,
      "CodFisco": "L433",
      "Abitanti": 8679,
      "Link": "http://www.comuni-italiani.it/024/110/"
    },
    {
      "Istat": 108043,
      "Comune": "Triuggio",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20844,
      "CodFisco": "L434",
      "Abitanti": 8396,
      "Link": "http://www.comuni-italiani.it/108/043/"
    },
    {
      "Istat": "070081",
      "Comune": "Trivento",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86029,
      "CodFisco": "L435",
      "Abitanti": 4907,
      "Link": "http://www.comuni-italiani.it/070/081/"
    },
    {
      "Istat": "096070",
      "Comune": "Trivero",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13835,
      "CodFisco": "L436",
      "Abitanti": 6204,
      "Link": "http://www.comuni-italiani.it/096/070/"
    },
    {
      "Istat": "060081",
      "Comune": "Trivigliano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "L437",
      "Abitanti": 1743,
      "Link": "http://www.comuni-italiani.it/060/081/"
    },
    {
      "Istat": "030128",
      "Comune": "Trivignano Udinese",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33050,
      "CodFisco": "L438",
      "Abitanti": 1700,
      "Link": "http://www.comuni-italiani.it/030/128/"
    },
    {
      "Istat": "076093",
      "Comune": "Trivigno",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85018,
      "CodFisco": "L439",
      "Abitanti": 722,
      "Link": "http://www.comuni-italiani.it/076/093/"
    },
    {
      "Istat": "018163",
      "Comune": "Trivolzio",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "L440",
      "Abitanti": 1986,
      "Link": "http://www.comuni-italiani.it/018/163/"
    },
    {
      "Istat": "021102",
      "Comune": "Trodena nel parco naturale",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39040,
      "CodFisco": "L444",
      "Abitanti": 1009,
      "Link": "http://www.comuni-italiani.it/021/102/"
    },
    {
      "Istat": "001280",
      "Comune": "Trofarello",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10028,
      "CodFisco": "L445",
      "Abitanti": 11018,
      "Link": "http://www.comuni-italiani.it/001/280/"
    },
    {
      "Istat": "071058",
      "Comune": "Troia",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71029,
      "CodFisco": "L447",
      "Abitanti": 7411,
      "Link": "http://www.comuni-italiani.it/071/058/"
    },
    {
      "Istat": "086018",
      "Comune": "Troina",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94018,
      "CodFisco": "L448",
      "Abitanti": 9704,
      "Link": "http://www.comuni-italiani.it/086/018/"
    },
    {
      "Istat": "018164",
      "Comune": "Tromello",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "L449",
      "Abitanti": 3873,
      "Link": "http://www.comuni-italiani.it/018/164/"
    },
    {
      "Istat": 103068,
      "Comune": "Trontano",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28859,
      "CodFisco": "L450",
      "Abitanti": 1693,
      "Link": "http://www.comuni-italiani.it/103/068/"
    },
    {
      "Istat": "012129",
      "Comune": "Tronzano Lago Maggiore",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "A705",
      "Abitanti": 262,
      "Link": "http://www.comuni-italiani.it/012/129/"
    },
    {
      "Istat": "002150",
      "Comune": "Tronzano Vercellese",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13049,
      "CodFisco": "L451",
      "Abitanti": 3598,
      "Link": "http://www.comuni-italiani.it/002/150/"
    },
    {
      "Istat": 102044,
      "Comune": "Tropea",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89861,
      "CodFisco": "L452",
      "Abitanti": 6725,
      "Link": "http://www.comuni-italiani.it/102/044/"
    },
    {
      "Istat": "018165",
      "Comune": "Trovo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "L453",
      "Abitanti": 1013,
      "Link": "http://www.comuni-italiani.it/018/165/"
    },
    {
      "Istat": "015224",
      "Comune": "Truccazzano",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "L454",
      "Abitanti": 5982,
      "Link": "http://www.comuni-italiani.it/015/224/"
    },
    {
      "Istat": "021103",
      "Comune": "Tubre",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39020,
      "CodFisco": "L455",
      "Abitanti": 962,
      "Link": "http://www.comuni-italiani.it/021/103/"
    },
    {
      "Istat": "022207",
      "Comune": "Tuenno",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38019,
      "CodFisco": "L457",
      "Abitanti": 2394,
      "Link": "http://www.comuni-italiani.it/022/207/"
    },
    {
      "Istat": "070082",
      "Comune": "Tufara",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86010,
      "CodFisco": "L458",
      "Abitanti": 1000,
      "Link": "http://www.comuni-italiani.it/070/082/"
    },
    {
      "Istat": "069097",
      "Comune": "Tufillo",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66050,
      "CodFisco": "L459",
      "Abitanti": 482,
      "Link": "http://www.comuni-italiani.it/069/097/"
    },
    {
      "Istat": "063085",
      "Comune": "Tufino",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "L460",
      "Abitanti": 3745,
      "Link": "http://www.comuni-italiani.it/063/085/"
    },
    {
      "Istat": "064113",
      "Comune": "Tufo",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83010,
      "CodFisco": "L461",
      "Abitanti": 941,
      "Link": "http://www.comuni-italiani.it/064/113/"
    },
    {
      "Istat": "075089",
      "Comune": "Tuglie",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73058,
      "CodFisco": "L462",
      "Abitanti": 5320,
      "Link": "http://www.comuni-italiani.it/075/089/"
    },
    {
      "Istat": 106022,
      "Comune": "Tuili",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09029",
      "CodFisco": "L463",
      "Abitanti": 1080,
      "Link": "http://www.comuni-italiani.it/106/022/"
    },
    {
      "Istat": "090075",
      "Comune": "Tula",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07010",
      "CodFisco": "L464",
      "Abitanti": 1611,
      "Link": "http://www.comuni-italiani.it/090/075/"
    },
    {
      "Istat": "054055",
      "Comune": "Tuoro sul Trasimeno",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06069",
      "CodFisco": "L466",
      "Abitanti": 3886,
      "Link": "http://www.comuni-italiani.it/054/055/"
    },
    {
      "Istat": "057071",
      "Comune": "Turania",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "G507",
      "Abitanti": 247,
      "Link": "http://www.comuni-italiani.it/057/071/"
    },
    {
      "Istat": "098058",
      "Comune": "Turano Lodigiano",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0377",
      "CAP": 26828,
      "CodFisco": "L469",
      "Abitanti": 1576,
      "Link": "http://www.comuni-italiani.it/098/058/"
    },
    {
      "Istat": "013227",
      "Comune": "Turate",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 22078,
      "CodFisco": "L470",
      "Abitanti": 9016,
      "Link": "http://www.comuni-italiani.it/013/227/"
    },
    {
      "Istat": "015226",
      "Comune": "Turbigo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20029,
      "CodFisco": "L471",
      "Abitanti": 7485,
      "Link": "http://www.comuni-italiani.it/015/226/"
    },
    {
      "Istat": "072047",
      "Comune": "Turi",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "L472",
      "Abitanti": 12413,
      "Link": "http://www.comuni-italiani.it/072/047/"
    },
    {
      "Istat": 106023,
      "Comune": "Turri",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09020",
      "CodFisco": "L473",
      "Abitanti": 447,
      "Link": "http://www.comuni-italiani.it/106/023/"
    },
    {
      "Istat": "031024",
      "Comune": "Turriaco",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "L474",
      "Abitanti": 2756,
      "Link": "http://www.comuni-italiani.it/031/024/"
    },
    {
      "Istat": "068044",
      "Comune": "Turrivalignani",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65020,
      "CodFisco": "L475",
      "Abitanti": 886,
      "Link": "http://www.comuni-italiani.it/068/044/"
    },
    {
      "Istat": "077029",
      "Comune": "Tursi",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75028,
      "CodFisco": "L477",
      "Abitanti": 5217,
      "Link": "http://www.comuni-italiani.it/077/029/"
    },
    {
      "Istat": "083101",
      "Comune": "Tusa",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 98079,
      "CodFisco": "L478",
      "Abitanti": 3082,
      "Link": "http://www.comuni-italiani.it/083/101/"
    },
    {
      "Istat": "056052",
      "Comune": "Tuscania",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01017",
      "CodFisco": "L310",
      "Abitanti": 8300,
      "Link": "http://www.comuni-italiani.it/056/052/"
    },
    {
      "Istat": "016221",
      "Comune": "Ubiale Clanezzo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "C789",
      "Abitanti": 1395,
      "Link": "http://www.comuni-italiani.it/016/221/"
    },
    {
      "Istat": "012130",
      "Comune": "Uboldo",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 21040,
      "CodFisco": "L480",
      "Abitanti": 10489,
      "Link": "http://www.comuni-italiani.it/012/130/"
    },
    {
      "Istat": "083102",
      "Comune": "Ucria",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "0941",
      "CAP": 98060,
      "CodFisco": "L482",
      "Abitanti": 1133,
      "Link": "http://www.comuni-italiani.it/083/102/"
    },
    {
      "Istat": "030129",
      "Comune": "Udine",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33100,
      "CodFisco": "L483",
      "Abitanti": 99627,
      "Link": "http://www.comuni-italiani.it/030/129/"
    },
    {
      "Istat": "075090",
      "Comune": "Ugento",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0833",
      "CAP": 73059,
      "CodFisco": "L484",
      "Abitanti": 12266,
      "Link": "http://www.comuni-italiani.it/075/090/"
    },
    {
      "Istat": "075091",
      "Comune": "Uggiano La Chiesa",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73020,
      "CodFisco": "L485",
      "Abitanti": 4444,
      "Link": "http://www.comuni-italiani.it/075/091/"
    },
    {
      "Istat": "013228",
      "Comune": "Uggiate-Trevano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22029,
      "CodFisco": "L487",
      "Abitanti": 4431,
      "Link": "http://www.comuni-italiani.it/013/228/"
    },
    {
      "Istat": "095068",
      "Comune": "Ul‡ Tirso",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "L488",
      "Abitanti": 589,
      "Link": "http://www.comuni-italiani.it/095/068/"
    },
    {
      "Istat": 105020,
      "Comune": "Ulassai",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "L489",
      "Abitanti": 1550,
      "Link": "http://www.comuni-italiani.it/105/020/"
    },
    {
      "Istat": "021104",
      "Comune": "Ultimo",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39016,
      "CodFisco": "L490",
      "Abitanti": 2932,
      "Link": "http://www.comuni-italiani.it/021/104/"
    },
    {
      "Istat": "054056",
      "Comune": "Umbertide",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06019",
      "CodFisco": "D786",
      "Abitanti": 16890,
      "Link": "http://www.comuni-italiani.it/054/056/"
    },
    {
      "Istat": 101026,
      "Comune": "Umbriatico",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88823,
      "CodFisco": "L492",
      "Abitanti": 953,
      "Link": "http://www.comuni-italiani.it/101/026/"
    },
    {
      "Istat": "017192",
      "Comune": "Urago d'Oglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "L494",
      "Abitanti": 4052,
      "Link": "http://www.comuni-italiani.it/017/192/"
    },
    {
      "Istat": "095069",
      "Comune": "Uras",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09099",
      "CodFisco": "L496",
      "Abitanti": 2980,
      "Link": "http://www.comuni-italiani.it/095/069/"
    },
    {
      "Istat": "028095",
      "Comune": "Urbana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "L497",
      "Abitanti": 2202,
      "Link": "http://www.comuni-italiani.it/028/095/"
    },
    {
      "Istat": "041066",
      "Comune": "Urbania",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61049,
      "CodFisco": "L498",
      "Abitanti": 7119,
      "Link": "http://www.comuni-italiani.it/041/066/"
    },
    {
      "Istat": "009063",
      "Comune": "Urbe",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17048,
      "CodFisco": "L499",
      "Abitanti": 793,
      "Link": "http://www.comuni-italiani.it/009/063/"
    },
    {
      "Istat": "041067",
      "Comune": "Urbino",
      "Provincia": "PU",
      "Regione": "MAR",
      "Prefisso": "0722",
      "CAP": 61029,
      "CodFisco": "L500",
      "Abitanti": 15627,
      "Link": "http://www.comuni-italiani.it/041/067/"
    },
    {
      "Istat": "043055",
      "Comune": "Urbisaglia",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0733",
      "CAP": 62010,
      "CodFisco": "L501",
      "Abitanti": 2754,
      "Link": "http://www.comuni-italiani.it/043/055/"
    },
    {
      "Istat": "016222",
      "Comune": "Urgnano",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24059,
      "CodFisco": "L502",
      "Abitanti": 9592,
      "Link": "http://www.comuni-italiani.it/016/222/"
    },
    {
      "Istat": "090076",
      "Comune": "Uri",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07040",
      "CodFisco": "L503",
      "Abitanti": 3053,
      "Link": "http://www.comuni-italiani.it/090/076/"
    },
    {
      "Istat": "070083",
      "Comune": "Ururi",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86049,
      "CodFisco": "L505",
      "Abitanti": 2839,
      "Link": "http://www.comuni-italiani.it/070/083/"
    },
    {
      "Istat": 105021,
      "Comune": "Urzulei",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "L506",
      "Abitanti": 1326,
      "Link": "http://www.comuni-italiani.it/105/021/"
    },
    {
      "Istat": "010064",
      "Comune": "Uscio",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "L507",
      "Abitanti": 2402,
      "Link": "http://www.comuni-italiani.it/010/064/"
    },
    {
      "Istat": "095070",
      "Comune": "Usellus",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "L508",
      "Abitanti": 868,
      "Link": "http://www.comuni-italiani.it/095/070/"
    },
    {
      "Istat": "090077",
      "Comune": "Usini",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07049",
      "CodFisco": "L509",
      "Abitanti": 4302,
      "Link": "http://www.comuni-italiani.it/090/077/"
    },
    {
      "Istat": 108044,
      "Comune": "Usmate Velate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20865,
      "CodFisco": "L511",
      "Abitanti": 10019,
      "Link": "http://www.comuni-italiani.it/108/044/"
    },
    {
      "Istat": "092088",
      "Comune": "Ussana",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "L512",
      "Abitanti": 4257,
      "Link": "http://www.comuni-italiani.it/092/088/"
    },
    {
      "Istat": 106024,
      "Comune": "Ussaramanna",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09020",
      "CodFisco": "L513",
      "Abitanti": 572,
      "Link": "http://www.comuni-italiani.it/106/024/"
    },
    {
      "Istat": 105022,
      "Comune": "Ussassai",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08040",
      "CodFisco": "L514",
      "Abitanti": 625,
      "Link": "http://www.comuni-italiani.it/105/022/"
    },
    {
      "Istat": "001281",
      "Comune": "Usseaux",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "L515",
      "Abitanti": 191,
      "Link": "http://www.comuni-italiani.it/001/281/"
    },
    {
      "Istat": "001282",
      "Comune": "Usseglio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "L516",
      "Abitanti": 224,
      "Link": "http://www.comuni-italiani.it/001/282/"
    },
    {
      "Istat": "043056",
      "Comune": "Ussita",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62039,
      "CodFisco": "L517",
      "Abitanti": 436,
      "Link": "http://www.comuni-italiani.it/043/056/"
    },
    {
      "Istat": "082075",
      "Comune": "Ustica",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90010,
      "CodFisco": "L519",
      "Abitanti": 1332,
      "Link": "http://www.comuni-italiani.it/082/075/"
    },
    {
      "Istat": "092090",
      "Comune": "Uta",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09010",
      "CodFisco": "L521",
      "Abitanti": 7831,
      "Link": "http://www.comuni-italiani.it/092/090/"
    },
    {
      "Istat": "047021",
      "Comune": "Uzzano",
      "Provincia": "PT",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 51010,
      "CodFisco": "L522",
      "Abitanti": 5671,
      "Link": "http://www.comuni-italiani.it/047/021/"
    },
    {
      "Istat": "078152",
      "Comune": "Vaccarizzo Albanese",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0983",
      "CAP": 87060,
      "CodFisco": "L524",
      "Abitanti": 1183,
      "Link": "http://www.comuni-italiani.it/078/152/"
    },
    {
      "Istat": "057072",
      "Comune": "Vacone",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0746",
      "CAP": "02040",
      "CodFisco": "L525",
      "Abitanti": 265,
      "Link": "http://www.comuni-italiani.it/057/072/"
    },
    {
      "Istat": "069098",
      "Comune": "Vacri",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "L526",
      "Abitanti": 1739,
      "Link": "http://www.comuni-italiani.it/069/098/"
    },
    {
      "Istat": "021105",
      "Comune": "Vadena",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0471",
      "CAP": 39051,
      "CodFisco": "L527",
      "Abitanti": 1003,
      "Link": "http://www.comuni-italiani.it/021/105/"
    },
    {
      "Istat": "009064",
      "Comune": "Vado Ligure",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17047,
      "CodFisco": "L528",
      "Abitanti": 8523,
      "Link": "http://www.comuni-italiani.it/009/064/"
    },
    {
      "Istat": "046031",
      "Comune": "Vagli Sotto",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55030,
      "CodFisco": "L533",
      "Abitanti": 995,
      "Link": "http://www.comuni-italiani.it/046/031/"
    },
    {
      "Istat": "048046",
      "Comune": "Vaglia",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50036,
      "CodFisco": "L529",
      "Abitanti": 5183,
      "Link": "http://www.comuni-italiani.it/048/046/"
    },
    {
      "Istat": "076094",
      "Comune": "Vaglio Basilicata",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85010,
      "CodFisco": "L532",
      "Abitanti": 2128,
      "Link": "http://www.comuni-italiani.it/076/094/"
    },
    {
      "Istat": "005111",
      "Comune": "Vaglio Serra",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14049,
      "CodFisco": "L531",
      "Abitanti": 285,
      "Link": "http://www.comuni-italiani.it/005/111/"
    },
    {
      "Istat": 100006,
      "Comune": "Vaiano",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "0574",
      "CAP": 59021,
      "CodFisco": "L537",
      "Abitanti": 9990,
      "Link": "http://www.comuni-italiani.it/100/006/"
    },
    {
      "Istat": "019111",
      "Comune": "Vaiano Cremasco",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0373",
      "CAP": 26010,
      "CodFisco": "L535",
      "Abitanti": 3919,
      "Link": "http://www.comuni-italiani.it/019/111/"
    },
    {
      "Istat": "001283",
      "Comune": "Vaie",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "L538",
      "Abitanti": 1472,
      "Link": "http://www.comuni-italiani.it/001/283/"
    },
    {
      "Istat": "019112",
      "Comune": "Vailate",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0363",
      "CAP": 26019,
      "CodFisco": "L539",
      "Abitanti": 4499,
      "Link": "http://www.comuni-italiani.it/019/112/"
    },
    {
      "Istat": "061095",
      "Comune": "Vairano Patenora",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81058,
      "CodFisco": "L540",
      "Abitanti": 6470,
      "Link": "http://www.comuni-italiani.it/061/095/"
    },
    {
      "Istat": "093052",
      "Comune": "Vajont",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33080,
      "CodFisco": "M265",
      "Abitanti": 1785,
      "Link": "http://www.comuni-italiani.it/093/052/"
    },
    {
      "Istat": "001284",
      "Comune": "Val della Torre",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "L555",
      "Abitanti": 3850,
      "Link": "http://www.comuni-italiani.it/001/284/"
    },
    {
      "Istat": "018166",
      "Comune": "Val di Nizza",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "L562",
      "Abitanti": 682,
      "Link": "http://www.comuni-italiani.it/018/166/"
    },
    {
      "Istat": "021107",
      "Comune": "Val di Vizze",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39049,
      "CodFisco": "L564",
      "Abitanti": 2761,
      "Link": "http://www.comuni-italiani.it/021/107/"
    },
    {
      "Istat": "014074",
      "Comune": "Val Masino",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23010,
      "CodFisco": "L638",
      "Abitanti": 946,
      "Link": "http://www.comuni-italiani.it/014/074/"
    },
    {
      "Istat": "013233",
      "Comune": "Val Rezzo",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "H259",
      "Abitanti": 182,
      "Link": "http://www.comuni-italiani.it/013/233/"
    },
    {
      "Istat": "016223",
      "Comune": "Valbondione",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "L544",
      "Abitanti": 1097,
      "Link": "http://www.comuni-italiani.it/016/223/"
    },
    {
      "Istat": "016224",
      "Comune": "Valbrembo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "L545",
      "Abitanti": 3753,
      "Link": "http://www.comuni-italiani.it/016/224/"
    },
    {
      "Istat": "010065",
      "Comune": "Valbrevenna",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "L546",
      "Abitanti": 811,
      "Link": "http://www.comuni-italiani.it/010/065/"
    },
    {
      "Istat": "013229",
      "Comune": "Valbrona",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22039,
      "CodFisco": "L547",
      "Abitanti": 2737,
      "Link": "http://www.comuni-italiani.it/013/229/"
    },
    {
      "Istat": "022208",
      "Comune": "Valda",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38030,
      "CodFisco": "L550",
      "Abitanti": 227,
      "Link": "http://www.comuni-italiani.it/022/208/"
    },
    {
      "Istat": "024111",
      "Comune": "Valdagno",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36078,
      "CodFisco": "L551",
      "Abitanti": 26889,
      "Link": "http://www.comuni-italiani.it/024/111/"
    },
    {
      "Istat": "021106",
      "Comune": "Valdaora",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "L552",
      "Abitanti": 3056,
      "Link": "http://www.comuni-italiani.it/021/106/"
    },
    {
      "Istat": "024112",
      "Comune": "Valdastico",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36040,
      "CodFisco": "L554",
      "Abitanti": 1424,
      "Link": "http://www.comuni-italiani.it/024/112/"
    },
    {
      "Istat": "096071",
      "Comune": "Valdengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13855,
      "CodFisco": "L556",
      "Abitanti": 2518,
      "Link": "http://www.comuni-italiani.it/096/071/"
    },
    {
      "Istat": "081022",
      "Comune": "Valderice",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0923",
      "CAP": 91019,
      "CodFisco": "G319",
      "Abitanti": 12175,
      "Link": "http://www.comuni-italiani.it/081/022/"
    },
    {
      "Istat": "014071",
      "Comune": "Valdidentro",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23038,
      "CodFisco": "L557",
      "Abitanti": 4068,
      "Link": "http://www.comuni-italiani.it/014/071/"
    },
    {
      "Istat": "004233",
      "Comune": "Valdieri",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "L558",
      "Abitanti": 949,
      "Link": "http://www.comuni-italiani.it/004/233/"
    },
    {
      "Istat": "083103",
      "Comune": "Valdina",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "L561",
      "Abitanti": 1290,
      "Link": "http://www.comuni-italiani.it/083/103/"
    },
    {
      "Istat": "014072",
      "Comune": "Valdisotto",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "L563",
      "Abitanti": 3508,
      "Link": "http://www.comuni-italiani.it/014/072/"
    },
    {
      "Istat": "026087",
      "Comune": "Valdobbiadene",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31049,
      "CodFisco": "L565",
      "Abitanti": 10831,
      "Link": "http://www.comuni-italiani.it/026/087/"
    },
    {
      "Istat": "002152",
      "Comune": "Valduggia",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13018,
      "CodFisco": "L566",
      "Abitanti": 2161,
      "Link": "http://www.comuni-italiani.it/002/152/"
    },
    {
      "Istat": "018167",
      "Comune": "Valeggio",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "L568",
      "Abitanti": 233,
      "Link": "http://www.comuni-italiani.it/018/167/"
    },
    {
      "Istat": "023089",
      "Comune": "Valeggio sul Mincio",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37067,
      "CodFisco": "L567",
      "Abitanti": 14456,
      "Link": "http://www.comuni-italiani.it/023/089/"
    },
    {
      "Istat": "056053",
      "Comune": "Valentano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01018",
      "CodFisco": "L569",
      "Abitanti": 2975,
      "Link": "http://www.comuni-italiani.it/056/053/"
    },
    {
      "Istat": "006177",
      "Comune": "Valenza",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15048,
      "CodFisco": "L570",
      "Abitanti": 20169,
      "Link": "http://www.comuni-italiani.it/006/177/"
    },
    {
      "Istat": "072048",
      "Comune": "Valenzano",
      "Provincia": "BA",
      "Regione": "PUG",
      "Prefisso": "080",
      "CAP": 70010,
      "CodFisco": "L571",
      "Abitanti": 18305,
      "Link": "http://www.comuni-italiani.it/072/048/"
    },
    {
      "Istat": "098059",
      "Comune": "Valera Fratta",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26859,
      "CodFisco": "L572",
      "Abitanti": 1653,
      "Link": "http://www.comuni-italiani.it/098/059/"
    },
    {
      "Istat": "054057",
      "Comune": "Valfabbrica",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "075",
      "CAP": "06029",
      "CodFisco": "L573",
      "Abitanti": 3560,
      "Link": "http://www.comuni-italiani.it/054/057/"
    },
    {
      "Istat": "005112",
      "Comune": "Valfenera",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14017,
      "CodFisco": "L574",
      "Abitanti": 2533,
      "Link": "http://www.comuni-italiani.it/005/112/"
    },
    {
      "Istat": "022209",
      "Comune": "Valfloriana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38040,
      "CodFisco": "L575",
      "Abitanti": 554,
      "Link": "http://www.comuni-italiani.it/022/209/"
    },
    {
      "Istat": "014073",
      "Comune": "Valfurva",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "L576",
      "Abitanti": 2732,
      "Link": "http://www.comuni-italiani.it/014/073/"
    },
    {
      "Istat": "012131",
      "Comune": "Valganna",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21039,
      "CodFisco": "L577",
      "Abitanti": 1622,
      "Link": "http://www.comuni-italiani.it/012/131/"
    },
    {
      "Istat": "001285",
      "Comune": "Valgioie",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10094,
      "CodFisco": "L578",
      "Abitanti": 952,
      "Link": "http://www.comuni-italiani.it/001/285/"
    },
    {
      "Istat": "016225",
      "Comune": "Valgoglio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "L579",
      "Abitanti": 616,
      "Link": "http://www.comuni-italiani.it/016/225/"
    },
    {
      "Istat": "004234",
      "Comune": "Valgrana",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "L580",
      "Abitanti": 805,
      "Link": "http://www.comuni-italiani.it/004/234/"
    },
    {
      "Istat": "097082",
      "Comune": "Valgreghentino",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23857,
      "CodFisco": "L581",
      "Abitanti": 3389,
      "Link": "http://www.comuni-italiani.it/097/082/"
    },
    {
      "Istat": "007068",
      "Comune": "Valgrisenche",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "L582",
      "Abitanti": 195,
      "Link": "http://www.comuni-italiani.it/007/068/"
    },
    {
      "Istat": "086019",
      "Comune": "Valguarnera Caropepe",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94019,
      "CodFisco": "L583",
      "Abitanti": 8281,
      "Link": "http://www.comuni-italiani.it/086/019/"
    },
    {
      "Istat": "025062",
      "Comune": "Vallada Agordina",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "L584",
      "Abitanti": 515,
      "Link": "http://www.comuni-italiani.it/025/062/"
    },
    {
      "Istat": "096072",
      "Comune": "Vallanzengo",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13847,
      "CodFisco": "L586",
      "Abitanti": 238,
      "Link": "http://www.comuni-italiani.it/096/072/"
    },
    {
      "Istat": "022210",
      "Comune": "Vallarsa",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "L588",
      "Abitanti": 1355,
      "Link": "http://www.comuni-italiani.it/022/210/"
    },
    {
      "Istat": "064114",
      "Comune": "Vallata",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83059,
      "CodFisco": "L589",
      "Abitanti": 2874,
      "Link": "http://www.comuni-italiani.it/064/114/"
    },
    {
      "Istat": "061096",
      "Comune": "Valle Agricola",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81010,
      "CodFisco": "L594",
      "Abitanti": 989,
      "Link": "http://www.comuni-italiani.it/061/096/"
    },
    {
      "Istat": "021108",
      "Comune": "Valle Aurina",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "L595",
      "Abitanti": 5884,
      "Link": "http://www.comuni-italiani.it/021/108/"
    },
    {
      "Istat": "067046",
      "Comune": "Valle Castellana",
      "Provincia": "TE",
      "Regione": "ABR",
      "Prefisso": "0861",
      "CAP": 64010,
      "CodFisco": "L597",
      "Abitanti": 1045,
      "Link": "http://www.comuni-italiani.it/067/046/"
    },
    {
      "Istat": "065153",
      "Comune": "Valle dell'Angelo",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84070,
      "CodFisco": "G540",
      "Abitanti": 314,
      "Link": "http://www.comuni-italiani.it/065/153/"
    },
    {
      "Istat": "025063",
      "Comune": "Valle di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "L590",
      "Abitanti": 2080,
      "Link": "http://www.comuni-italiani.it/025/063/"
    },
    {
      "Istat": "021109",
      "Comune": "Valle di Casies",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39030,
      "CodFisco": "L601",
      "Abitanti": 2261,
      "Link": "http://www.comuni-italiani.it/021/109/"
    },
    {
      "Istat": "061097",
      "Comune": "Valle di Maddaloni",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81020,
      "CodFisco": "L591",
      "Abitanti": 2796,
      "Link": "http://www.comuni-italiani.it/061/097/"
    },
    {
      "Istat": "018168",
      "Comune": "Valle Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "L593",
      "Abitanti": 2241,
      "Link": "http://www.comuni-italiani.it/018/168/"
    },
    {
      "Istat": "096073",
      "Comune": "Valle Mosso",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13825,
      "CodFisco": "L606",
      "Abitanti": 3640,
      "Link": "http://www.comuni-italiani.it/096/073/"
    },
    {
      "Istat": "018169",
      "Comune": "Valle Salimbene",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "L617",
      "Abitanti": 1494,
      "Link": "http://www.comuni-italiani.it/018/169/"
    },
    {
      "Istat": "096074",
      "Comune": "Valle San Nicolao",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13847,
      "CodFisco": "L620",
      "Abitanti": 1126,
      "Link": "http://www.comuni-italiani.it/096/074/"
    },
    {
      "Istat": "008062",
      "Comune": "Vallebona",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18012,
      "CodFisco": "L596",
      "Abitanti": 1327,
      "Link": "http://www.comuni-italiani.it/008/062/"
    },
    {
      "Istat": "060082",
      "Comune": "Vallecorsa",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "L598",
      "Abitanti": 2844,
      "Link": "http://www.comuni-italiani.it/060/082/"
    },
    {
      "Istat": "008063",
      "Comune": "Vallecrosia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18019,
      "CodFisco": "L599",
      "Abitanti": 7235,
      "Link": "http://www.comuni-italiani.it/008/063/"
    },
    {
      "Istat": "082076",
      "Comune": "Valledolmo",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "0921",
      "CAP": 90029,
      "CodFisco": "L603",
      "Abitanti": 3753,
      "Link": "http://www.comuni-italiani.it/082/076/"
    },
    {
      "Istat": "090079",
      "Comune": "Valledoria",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07039",
      "CodFisco": "L604",
      "Abitanti": 4198,
      "Link": "http://www.comuni-italiani.it/090/079/"
    },
    {
      "Istat": "079151",
      "Comune": "Vallefiorita",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "I322",
      "Abitanti": 1912,
      "Link": "http://www.comuni-italiani.it/079/151/"
    },
    {
      "Istat": 102045,
      "Comune": "Vallelonga",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89821,
      "CodFisco": "L607",
      "Abitanti": 712,
      "Link": "http://www.comuni-italiani.it/102/045/"
    },
    {
      "Istat": "085021",
      "Comune": "Vallelunga Pratameno",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "L609",
      "Abitanti": 3687,
      "Link": "http://www.comuni-italiani.it/085/021/"
    },
    {
      "Istat": "060083",
      "Comune": "Vallemaio",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "L605",
      "Abitanti": 984,
      "Link": "http://www.comuni-italiani.it/060/083/"
    },
    {
      "Istat": "058108",
      "Comune": "Vallepietra",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "L611",
      "Abitanti": 308,
      "Link": "http://www.comuni-italiani.it/058/108/"
    },
    {
      "Istat": "056054",
      "Comune": "Vallerano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "L612",
      "Abitanti": 2667,
      "Link": "http://www.comuni-italiani.it/056/054/"
    },
    {
      "Istat": "092091",
      "Comune": "Vallermosa",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "L613",
      "Abitanti": 1966,
      "Link": "http://www.comuni-italiani.it/092/091/"
    },
    {
      "Istat": "060084",
      "Comune": "Vallerotonda",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "L614",
      "Abitanti": 1736,
      "Link": "http://www.comuni-italiani.it/060/084/"
    },
    {
      "Istat": "064115",
      "Comune": "Vallesaccarda",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0827",
      "CAP": 83050,
      "CodFisco": "L616",
      "Abitanti": 1357,
      "Link": "http://www.comuni-italiani.it/064/115/"
    },
    {
      "Istat": "016226",
      "Comune": "Valleve",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "L623",
      "Abitanti": 137,
      "Link": "http://www.comuni-italiani.it/016/226/"
    },
    {
      "Istat": "024113",
      "Comune": "Valli del Pasubio",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "L624",
      "Abitanti": 3386,
      "Link": "http://www.comuni-italiani.it/024/113/"
    },
    {
      "Istat": "058109",
      "Comune": "Vallinfreda",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "L625",
      "Abitanti": 316,
      "Link": "http://www.comuni-italiani.it/058/109/"
    },
    {
      "Istat": "017193",
      "Comune": "Vallio Terme",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "L626",
      "Abitanti": 1337,
      "Link": "http://www.comuni-italiani.it/017/193/"
    },
    {
      "Istat": "065154",
      "Comune": "Vallo della Lucania",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0974",
      "CAP": 84078,
      "CodFisco": "L628",
      "Abitanti": 8865,
      "Link": "http://www.comuni-italiani.it/065/154/"
    },
    {
      "Istat": "054058",
      "Comune": "Vallo di Nera",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0743",
      "CAP": "06040",
      "CodFisco": "L627",
      "Abitanti": 407,
      "Link": "http://www.comuni-italiani.it/054/058/"
    },
    {
      "Istat": "001286",
      "Comune": "Vallo Torinese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "L629",
      "Abitanti": 804,
      "Link": "http://www.comuni-italiani.it/001/286/"
    },
    {
      "Istat": "004235",
      "Comune": "Valloriate",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "L631",
      "Abitanti": 126,
      "Link": "http://www.comuni-italiani.it/004/235/"
    },
    {
      "Istat": "006178",
      "Comune": "Valmacca",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15040,
      "CodFisco": "L633",
      "Abitanti": 1060,
      "Link": "http://www.comuni-italiani.it/006/178/"
    },
    {
      "Istat": "097083",
      "Comune": "Valmadrera",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23868,
      "CodFisco": "L634",
      "Abitanti": 11668,
      "Link": "http://www.comuni-italiani.it/097/083/"
    },
    {
      "Istat": "004236",
      "Comune": "Valmala",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "L636",
      "Abitanti": 66,
      "Link": "http://www.comuni-italiani.it/004/236/"
    },
    {
      "Istat": "058110",
      "Comune": "Valmontone",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00038",
      "CodFisco": "L639",
      "Abitanti": 15469,
      "Link": "http://www.comuni-italiani.it/058/110/"
    },
    {
      "Istat": "013232",
      "Comune": "Valmorea",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "L640",
      "Abitanti": 2687,
      "Link": "http://www.comuni-italiani.it/013/232/"
    },
    {
      "Istat": "034044",
      "Comune": "Valmozzola",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43050,
      "CodFisco": "L641",
      "Abitanti": 585,
      "Link": "http://www.comuni-italiani.it/034/044/"
    },
    {
      "Istat": "016227",
      "Comune": "Valnegra",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "L642",
      "Abitanti": 209,
      "Link": "http://www.comuni-italiani.it/016/227/"
    },
    {
      "Istat": "007069",
      "Comune": "Valpelline",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "L643",
      "Abitanti": 659,
      "Link": "http://www.comuni-italiani.it/007/069/"
    },
    {
      "Istat": "001287",
      "Comune": "Valperga",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10087,
      "CodFisco": "L644",
      "Abitanti": 3176,
      "Link": "http://www.comuni-italiani.it/001/287/"
    },
    {
      "Istat": "001288",
      "Comune": "Valprato Soana",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0124",
      "CAP": 10080,
      "CodFisco": "B510",
      "Abitanti": 113,
      "Link": "http://www.comuni-italiani.it/001/288/"
    },
    {
      "Istat": "007070",
      "Comune": "Valsavarenche",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11010,
      "CodFisco": "L647",
      "Abitanti": 176,
      "Link": "http://www.comuni-italiani.it/007/070/"
    },
    {
      "Istat": "016228",
      "Comune": "Valsecca",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "L649",
      "Abitanti": 425,
      "Link": "http://www.comuni-italiani.it/016/228/"
    },
    {
      "Istat": "077030",
      "Comune": "Valsinni",
      "Provincia": "MT",
      "Regione": "BAS",
      "Prefisso": "0835",
      "CAP": 75029,
      "CodFisco": "D513",
      "Abitanti": 1677,
      "Link": "http://www.comuni-italiani.it/077/030/"
    },
    {
      "Istat": "013234",
      "Comune": "Valsolda",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22010,
      "CodFisco": "C936",
      "Abitanti": 1639,
      "Link": "http://www.comuni-italiani.it/013/234/"
    },
    {
      "Istat": "024114",
      "Comune": "Valstagna",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0424",
      "CAP": 36020,
      "CodFisco": "L650",
      "Abitanti": 1900,
      "Link": "http://www.comuni-italiani.it/024/114/"
    },
    {
      "Istat": 103069,
      "Comune": "Valstrona",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28897,
      "CodFisco": "L651",
      "Abitanti": 1254,
      "Link": "http://www.comuni-italiani.it/103/069/"
    },
    {
      "Istat": "054059",
      "Comune": "Valtopina",
      "Provincia": "PG",
      "Regione": "UMB",
      "Prefisso": "0742",
      "CAP": "06030",
      "CodFisco": "L653",
      "Abitanti": 1465,
      "Link": "http://www.comuni-italiani.it/054/059/"
    },
    {
      "Istat": "016229",
      "Comune": "Valtorta",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "L655",
      "Abitanti": 295,
      "Link": "http://www.comuni-italiani.it/016/229/"
    },
    {
      "Istat": "007071",
      "Comune": "Valtournenche",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11028,
      "CodFisco": "L654",
      "Abitanti": 2162,
      "Link": "http://www.comuni-italiani.it/007/071/"
    },
    {
      "Istat": "065155",
      "Comune": "Valva",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0828",
      "CAP": 84020,
      "CodFisco": "L656",
      "Abitanti": 1760,
      "Link": "http://www.comuni-italiani.it/065/155/"
    },
    {
      "Istat": "093048",
      "Comune": "Valvasone",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33098,
      "CodFisco": "L657",
      "Abitanti": 2232,
      "Link": "http://www.comuni-italiani.it/093/048/"
    },
    {
      "Istat": "018170",
      "Comune": "Valverde",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27050,
      "CodFisco": "L659",
      "Abitanti": 314,
      "Link": "http://www.comuni-italiani.it/018/170/"
    },
    {
      "Istat": "087052",
      "Comune": "Valverde",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95028,
      "CodFisco": "L658",
      "Abitanti": 7760,
      "Link": "http://www.comuni-italiani.it/087/052/"
    },
    {
      "Istat": "017194",
      "Comune": "Valvestino",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25080,
      "CodFisco": "L468",
      "Abitanti": 214,
      "Link": "http://www.comuni-italiani.it/017/194/"
    },
    {
      "Istat": "021110",
      "Comune": "Vandoies",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39030,
      "CodFisco": "L660",
      "Abitanti": 3263,
      "Link": "http://www.comuni-italiani.it/021/110/"
    },
    {
      "Istat": "015249",
      "Comune": "Vanzaghello",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "L664",
      "Abitanti": 5307,
      "Link": "http://www.comuni-italiani.it/015/249/"
    },
    {
      "Istat": "015229",
      "Comune": "Vanzago",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "L665",
      "Abitanti": 8894,
      "Link": "http://www.comuni-italiani.it/015/229/"
    },
    {
      "Istat": 103070,
      "Comune": "Vanzone con San Carlo",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28879,
      "CodFisco": "L666",
      "Abitanti": 445,
      "Link": "http://www.comuni-italiani.it/103/070/"
    },
    {
      "Istat": "015230",
      "Comune": "Vaprio d'Adda",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20069,
      "CodFisco": "L667",
      "Abitanti": 8045,
      "Link": "http://www.comuni-italiani.it/015/230/"
    },
    {
      "Istat": "003153",
      "Comune": "Vaprio d'Agogna",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28010,
      "CodFisco": "L668",
      "Abitanti": 1022,
      "Link": "http://www.comuni-italiani.it/003/153/"
    },
    {
      "Istat": "002156",
      "Comune": "Varallo",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13019,
      "CodFisco": "L669",
      "Abitanti": 7593,
      "Link": "http://www.comuni-italiani.it/002/156/"
    },
    {
      "Istat": "003154",
      "Comune": "Varallo Pombia",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28040,
      "CodFisco": "L670",
      "Abitanti": 4999,
      "Link": "http://www.comuni-italiani.it/003/154/"
    },
    {
      "Istat": "012132",
      "Comune": "Varano Borghi",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21020,
      "CodFisco": "L671",
      "Abitanti": 2383,
      "Link": "http://www.comuni-italiani.it/012/132/"
    },
    {
      "Istat": "034045",
      "Comune": "Varano de' Melegari",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43040,
      "CodFisco": "L672",
      "Abitanti": 2704,
      "Link": "http://www.comuni-italiani.it/034/045/"
    },
    {
      "Istat": "080095",
      "Comune": "Varapodio",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0966",
      "CAP": 89010,
      "CodFisco": "L673",
      "Abitanti": 2245,
      "Link": "http://www.comuni-italiani.it/080/095/"
    },
    {
      "Istat": "009065",
      "Comune": "Varazze",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17019,
      "CodFisco": "L675",
      "Abitanti": 13708,
      "Link": "http://www.comuni-italiani.it/009/065/"
    },
    {
      "Istat": "057073",
      "Comune": "Varco Sabino",
      "Provincia": "RI",
      "Regione": "LAZ",
      "Prefisso": "0765",
      "CAP": "02020",
      "CodFisco": "L676",
      "Abitanti": 221,
      "Link": "http://www.comuni-italiani.it/057/073/"
    },
    {
      "Istat": 108045,
      "Comune": "Varedo",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20814,
      "CodFisco": "L677",
      "Abitanti": 12899,
      "Link": "http://www.comuni-italiani.it/108/045/"
    },
    {
      "Istat": "022211",
      "Comune": "Varena",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "L678",
      "Abitanti": 831,
      "Link": "http://www.comuni-italiani.it/022/211/"
    },
    {
      "Istat": "097084",
      "Comune": "Varenna",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23829,
      "CodFisco": "L680",
      "Abitanti": 812,
      "Link": "http://www.comuni-italiani.it/097/084/"
    },
    {
      "Istat": "012133",
      "Comune": "Varese",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21100,
      "CodFisco": "L682",
      "Abitanti": 81579,
      "Link": "http://www.comuni-italiani.it/012/133/"
    },
    {
      "Istat": "011029",
      "Comune": "Varese Ligure",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19028,
      "CodFisco": "L681",
      "Abitanti": 2151,
      "Link": "http://www.comuni-italiani.it/011/029/"
    },
    {
      "Istat": "001289",
      "Comune": "Varisella",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "L685",
      "Abitanti": 829,
      "Link": "http://www.comuni-italiani.it/001/289/"
    },
    {
      "Istat": "030130",
      "Comune": "Varmo",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33030,
      "CodFisco": "L686",
      "Abitanti": 2892,
      "Link": "http://www.comuni-italiani.it/030/130/"
    },
    {
      "Istat": "021111",
      "Comune": "Varna",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "L687",
      "Abitanti": 4236,
      "Link": "http://www.comuni-italiani.it/021/111/"
    },
    {
      "Istat": "034046",
      "Comune": "Varsi",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0525",
      "CAP": 43049,
      "CodFisco": "L689",
      "Abitanti": 1300,
      "Link": "http://www.comuni-italiani.it/034/046/"
    },
    {
      "Istat": "018171",
      "Comune": "Varzi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27057,
      "CodFisco": "L690",
      "Abitanti": 3420,
      "Link": "http://www.comuni-italiani.it/018/171/"
    },
    {
      "Istat": 103071,
      "Comune": "Varzo",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28868,
      "CodFisco": "L691",
      "Abitanti": 2137,
      "Link": "http://www.comuni-italiani.it/103/071/"
    },
    {
      "Istat": "025064",
      "Comune": "Vas",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0439",
      "CAP": 32030,
      "CodFisco": "L692",
      "Abitanti": 854,
      "Link": "http://www.comuni-italiani.it/025/064/"
    },
    {
      "Istat": "056055",
      "Comune": "Vasanello",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "A701",
      "Abitanti": 4249,
      "Link": "http://www.comuni-italiani.it/056/055/"
    },
    {
      "Istat": "008064",
      "Comune": "Vasia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18020,
      "CodFisco": "L693",
      "Abitanti": 440,
      "Link": "http://www.comuni-italiani.it/008/064/"
    },
    {
      "Istat": "069099",
      "Comune": "Vasto",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66054,
      "CodFisco": "E372",
      "Abitanti": 40381,
      "Link": "http://www.comuni-italiani.it/069/099/"
    },
    {
      "Istat": "094051",
      "Comune": "Vastogirardi",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86089,
      "CodFisco": "L696",
      "Abitanti": 762,
      "Link": "http://www.comuni-italiani.it/094/051/"
    },
    {
      "Istat": "022212",
      "Comune": "Vattaro",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38049,
      "CodFisco": "L697",
      "Abitanti": 1144,
      "Link": "http://www.comuni-italiani.it/022/212/"
    },
    {
      "Istat": "001290",
      "Comune": "Vauda Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "L698",
      "Abitanti": 1496,
      "Link": "http://www.comuni-italiani.it/001/290/"
    },
    {
      "Istat": 102046,
      "Comune": "Vazzano",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89834,
      "CodFisco": "L699",
      "Abitanti": 1116,
      "Link": "http://www.comuni-italiani.it/102/046/"
    },
    {
      "Istat": "026088",
      "Comune": "Vazzola",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31028,
      "CodFisco": "L700",
      "Abitanti": 7107,
      "Link": "http://www.comuni-italiani.it/026/088/"
    },
    {
      "Istat": "050037",
      "Comune": "Vecchiano",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56019,
      "CodFisco": "L702",
      "Abitanti": 12472,
      "Link": "http://www.comuni-italiani.it/050/037/"
    },
    {
      "Istat": 108046,
      "Comune": "Vedano al Lambro",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20854,
      "CodFisco": "L704",
      "Abitanti": 7669,
      "Link": "http://www.comuni-italiani.it/108/046/"
    },
    {
      "Istat": "012134",
      "Comune": "Vedano Olona",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21040,
      "CodFisco": "L703",
      "Abitanti": 7370,
      "Link": "http://www.comuni-italiani.it/012/134/"
    },
    {
      "Istat": "012135",
      "Comune": "Veddasca",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21010,
      "CodFisco": "L705",
      "Abitanti": 277,
      "Link": "http://www.comuni-italiani.it/012/135/"
    },
    {
      "Istat": "026089",
      "Comune": "Vedelago",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31050,
      "CodFisco": "L706",
      "Abitanti": 16678,
      "Link": "http://www.comuni-italiani.it/026/089/"
    },
    {
      "Istat": "016230",
      "Comune": "Vedeseta",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24010,
      "CodFisco": "L707",
      "Abitanti": 217,
      "Link": "http://www.comuni-italiani.it/016/230/"
    },
    {
      "Istat": 108047,
      "Comune": "Veduggio con Colzano",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20837,
      "CodFisco": "L709",
      "Abitanti": 4417,
      "Link": "http://www.comuni-italiani.it/108/047/"
    },
    {
      "Istat": "028096",
      "Comune": "Veggiano",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "L710",
      "Abitanti": 4509,
      "Link": "http://www.comuni-italiani.it/028/096/"
    },
    {
      "Istat": "075092",
      "Comune": "Veglie",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73010,
      "CodFisco": "L711",
      "Abitanti": 14369,
      "Link": "http://www.comuni-italiani.it/075/092/"
    },
    {
      "Istat": "096075",
      "Comune": "Veglio",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13824,
      "CodFisco": "L712",
      "Abitanti": 591,
      "Link": "http://www.comuni-italiani.it/096/075/"
    },
    {
      "Istat": "056056",
      "Comune": "Vejano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "L713",
      "Abitanti": 2337,
      "Link": "http://www.comuni-italiani.it/056/056/"
    },
    {
      "Istat": "013236",
      "Comune": "Veleso",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "L715",
      "Abitanti": 278,
      "Link": "http://www.comuni-italiani.it/013/236/"
    },
    {
      "Istat": "018172",
      "Comune": "Velezzo Lomellina",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27020,
      "CodFisco": "L716",
      "Abitanti": 103,
      "Link": "http://www.comuni-italiani.it/018/172/"
    },
    {
      "Istat": "058111",
      "Comune": "Velletri",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00049",
      "CodFisco": "L719",
      "Abitanti": 53544,
      "Link": "http://www.comuni-italiani.it/058/111/"
    },
    {
      "Istat": "018173",
      "Comune": "Vellezzo Bellini",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "L720",
      "Abitanti": 3044,
      "Link": "http://www.comuni-italiani.it/018/173/"
    },
    {
      "Istat": "024115",
      "Comune": "Velo d'Astico",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "L723",
      "Abitanti": 2446,
      "Link": "http://www.comuni-italiani.it/024/115/"
    },
    {
      "Istat": "023090",
      "Comune": "Velo Veronese",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "L722",
      "Abitanti": 787,
      "Link": "http://www.comuni-italiani.it/023/090/"
    },
    {
      "Istat": "021116",
      "Comune": "Velturno",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "L724",
      "Abitanti": 2749,
      "Link": "http://www.comuni-italiani.it/021/116/"
    },
    {
      "Istat": "094052",
      "Comune": "Venafro",
      "Provincia": "IS",
      "Regione": "MOL",
      "Prefisso": "0865",
      "CAP": 86079,
      "CodFisco": "L725",
      "Abitanti": 11535,
      "Link": "http://www.comuni-italiani.it/094/052/"
    },
    {
      "Istat": "001292",
      "Comune": "Venaria Reale",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10078,
      "CodFisco": "L727",
      "Abitanti": 34859,
      "Link": "http://www.comuni-italiani.it/001/292/"
    },
    {
      "Istat": "044073",
      "Comune": "Venarotta",
      "Provincia": "AP",
      "Regione": "MAR",
      "Prefisso": "0736",
      "CAP": 63091,
      "CodFisco": "L728",
      "Abitanti": 2189,
      "Link": "http://www.comuni-italiani.it/044/073/"
    },
    {
      "Istat": "004237",
      "Comune": "Venasca",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12020,
      "CodFisco": "L729",
      "Abitanti": 1484,
      "Link": "http://www.comuni-italiani.it/004/237/"
    },
    {
      "Istat": "001291",
      "Comune": "Venaus",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0122",
      "CAP": 10050,
      "CodFisco": "L726",
      "Abitanti": 959,
      "Link": "http://www.comuni-italiani.it/001/291/"
    },
    {
      "Istat": "009066",
      "Comune": "Vendone",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17032,
      "CodFisco": "L730",
      "Abitanti": 416,
      "Link": "http://www.comuni-italiani.it/009/066/"
    },
    {
      "Istat": "097085",
      "Comune": "Vendrogno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23838,
      "CodFisco": "L731",
      "Abitanti": 317,
      "Link": "http://www.comuni-italiani.it/097/085/"
    },
    {
      "Istat": "012136",
      "Comune": "Venegono Inferiore",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "L733",
      "Abitanti": 6370,
      "Link": "http://www.comuni-italiani.it/012/136/"
    },
    {
      "Istat": "012137",
      "Comune": "Venegono Superiore",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21040,
      "CodFisco": "L734",
      "Abitanti": 7185,
      "Link": "http://www.comuni-italiani.it/012/137/"
    },
    {
      "Istat": "083104",
      "Comune": "Venetico",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98040,
      "CodFisco": "L735",
      "Abitanti": 3855,
      "Link": "http://www.comuni-italiani.it/083/104/"
    },
    {
      "Istat": "027042",
      "Comune": "Venezia",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "041",
      "CAP": "301xx",
      "CodFisco": "L736",
      "Abitanti": 270884,
      "Link": "http://www.comuni-italiani.it/027/042/"
    },
    {
      "Istat": "013238",
      "Comune": "Veniano",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "L737",
      "Abitanti": 2859,
      "Link": "http://www.comuni-italiani.it/013/238/"
    },
    {
      "Istat": "076095",
      "Comune": "Venosa",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0972",
      "CAP": 85029,
      "CodFisco": "L738",
      "Abitanti": 12231,
      "Link": "http://www.comuni-italiani.it/076/095/"
    },
    {
      "Istat": "064116",
      "Comune": "Venticano",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "L739",
      "Abitanti": 2599,
      "Link": "http://www.comuni-italiani.it/064/116/"
    },
    {
      "Istat": "008065",
      "Comune": "Ventimiglia",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0184",
      "CAP": 18039,
      "CodFisco": "L741",
      "Abitanti": 25675,
      "Link": "http://www.comuni-italiani.it/008/065/"
    },
    {
      "Istat": "082077",
      "Comune": "Ventimiglia di Sicilia",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "L740",
      "Abitanti": 2108,
      "Link": "http://www.comuni-italiani.it/082/077/"
    },
    {
      "Istat": "059033",
      "Comune": "Ventotene",
      "Provincia": "LT",
      "Regione": "LAZ",
      "Prefisso": "0771",
      "CAP": "04020",
      "CodFisco": "L742",
      "Abitanti": 745,
      "Link": "http://www.comuni-italiani.it/059/033/"
    },
    {
      "Istat": "030131",
      "Comune": "Venzone",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33010,
      "CodFisco": "L743",
      "Abitanti": 2223,
      "Link": "http://www.comuni-italiani.it/030/131/"
    },
    {
      "Istat": "021112",
      "Comune": "Verano",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0473",
      "CAP": 39010,
      "CodFisco": "L745",
      "Abitanti": 926,
      "Link": "http://www.comuni-italiani.it/021/112/"
    },
    {
      "Istat": 108048,
      "Comune": "Verano Brianza",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "0362",
      "CAP": 20843,
      "CodFisco": "L744",
      "Abitanti": 9342,
      "Link": "http://www.comuni-italiani.it/108/048/"
    },
    {
      "Istat": 103072,
      "Comune": "Verbania",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": "2892x",
      "CodFisco": "L746",
      "Abitanti": 31243,
      "Link": "http://www.comuni-italiani.it/103/072/"
    },
    {
      "Istat": "078153",
      "Comune": "Verbicaro",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0985",
      "CAP": 87020,
      "CodFisco": "L747",
      "Abitanti": 3238,
      "Link": "http://www.comuni-italiani.it/078/153/"
    },
    {
      "Istat": "013239",
      "Comune": "Vercana",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "0344",
      "CAP": 22013,
      "CodFisco": "L748",
      "Abitanti": 754,
      "Link": "http://www.comuni-italiani.it/013/239/"
    },
    {
      "Istat": "014075",
      "Comune": "Verceia",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23020,
      "CodFisco": "L749",
      "Abitanti": 1106,
      "Link": "http://www.comuni-italiani.it/014/075/"
    },
    {
      "Istat": "002158",
      "Comune": "Vercelli",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13100,
      "CodFisco": "L750",
      "Abitanti": 46979,
      "Link": "http://www.comuni-italiani.it/002/158/"
    },
    {
      "Istat": "097086",
      "Comune": "Vercurago",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23808,
      "CodFisco": "L751",
      "Abitanti": 2896,
      "Link": "http://www.comuni-italiani.it/097/086/"
    },
    {
      "Istat": "016232",
      "Comune": "Verdellino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24040,
      "CodFisco": "L752",
      "Abitanti": 7790,
      "Link": "http://www.comuni-italiani.it/016/232/"
    },
    {
      "Istat": "016233",
      "Comune": "Verdello",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24049,
      "CodFisco": "L753",
      "Abitanti": 7829,
      "Link": "http://www.comuni-italiani.it/016/233/"
    },
    {
      "Istat": "097087",
      "Comune": "Verderio Inferiore",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23879,
      "CodFisco": "L755",
      "Abitanti": 2967,
      "Link": "http://www.comuni-italiani.it/097/087/"
    },
    {
      "Istat": "097088",
      "Comune": "Verderio Superiore",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23878,
      "CodFisco": "L756",
      "Abitanti": 2719,
      "Link": "http://www.comuni-italiani.it/097/088/"
    },
    {
      "Istat": "004238",
      "Comune": "Verduno",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12060,
      "CodFisco": "L758",
      "Abitanti": 564,
      "Link": "http://www.comuni-italiani.it/004/238/"
    },
    {
      "Istat": "037059",
      "Comune": "Vergato",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40038,
      "CodFisco": "L762",
      "Abitanti": 7854,
      "Link": "http://www.comuni-italiani.it/037/059/"
    },
    {
      "Istat": "046032",
      "Comune": "Vergemoli",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55020,
      "CodFisco": "L763",
      "Abitanti": 336,
      "Link": "http://www.comuni-italiani.it/046/032/"
    },
    {
      "Istat": "040050",
      "Comune": "Verghereto",
      "Provincia": "FC",
      "Regione": "EMR",
      "Prefisso": "0543",
      "CAP": 47028,
      "CodFisco": "L764",
      "Abitanti": 1992,
      "Link": "http://www.comuni-italiani.it/040/050/"
    },
    {
      "Istat": "012138",
      "Comune": "Vergiate",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21029,
      "CodFisco": "L765",
      "Abitanti": 9014,
      "Link": "http://www.comuni-italiani.it/012/138/"
    },
    {
      "Istat": "015235",
      "Comune": "Vermezzo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "L768",
      "Abitanti": 3897,
      "Link": "http://www.comuni-italiani.it/015/235/"
    },
    {
      "Istat": "022213",
      "Comune": "Vermiglio",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38029,
      "CodFisco": "L769",
      "Abitanti": 1901,
      "Link": "http://www.comuni-italiani.it/022/213/"
    },
    {
      "Istat": "004239",
      "Comune": "Vernante",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12019,
      "CodFisco": "L771",
      "Abitanti": 1251,
      "Link": "http://www.comuni-italiani.it/004/239/"
    },
    {
      "Istat": "033044",
      "Comune": "Vernasca",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "L772",
      "Abitanti": 2313,
      "Link": "http://www.comuni-italiani.it/033/044/"
    },
    {
      "Istat": "015236",
      "Comune": "Vernate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "L773",
      "Abitanti": 3189,
      "Link": "http://www.comuni-italiani.it/015/236/"
    },
    {
      "Istat": "011030",
      "Comune": "Vernazza",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19018,
      "CodFisco": "L774",
      "Abitanti": 975,
      "Link": "http://www.comuni-italiani.it/011/030/"
    },
    {
      "Istat": 100007,
      "Comune": "Vernio",
      "Provincia": "PO",
      "Regione": "TOS",
      "Prefisso": "0574",
      "CAP": 59024,
      "CodFisco": "L775",
      "Abitanti": 6095,
      "Link": "http://www.comuni-italiani.it/100/007/"
    },
    {
      "Istat": "075093",
      "Comune": "Vernole",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0832",
      "CAP": 73029,
      "CodFisco": "L776",
      "Abitanti": 7404,
      "Link": "http://www.comuni-italiani.it/075/093/"
    },
    {
      "Istat": "017195",
      "Comune": "Verolanuova",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25028,
      "CodFisco": "L777",
      "Abitanti": 8183,
      "Link": "http://www.comuni-italiani.it/017/195/"
    },
    {
      "Istat": "017196",
      "Comune": "Verolavecchia",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25029,
      "CodFisco": "L778",
      "Abitanti": 3903,
      "Link": "http://www.comuni-italiani.it/017/196/"
    },
    {
      "Istat": "001293",
      "Comune": "Verolengo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10038,
      "CodFisco": "L779",
      "Abitanti": 5037,
      "Link": "http://www.comuni-italiani.it/001/293/"
    },
    {
      "Istat": "060085",
      "Comune": "Veroli",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03029",
      "CodFisco": "L780",
      "Abitanti": 20798,
      "Link": "http://www.comuni-italiani.it/060/085/"
    },
    {
      "Istat": "023091",
      "Comune": "Verona",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": "371xx",
      "CodFisco": "L781",
      "Abitanti": 263964,
      "Link": "http://www.comuni-italiani.it/023/091/"
    },
    {
      "Istat": "023092",
      "Comune": "Veronella",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "D193",
      "Abitanti": 4710,
      "Link": "http://www.comuni-italiani.it/023/092/"
    },
    {
      "Istat": "007072",
      "Comune": "Verrayes",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0166",
      "CAP": 11020,
      "CodFisco": "L783",
      "Abitanti": 1351,
      "Link": "http://www.comuni-italiani.it/007/072/"
    },
    {
      "Istat": "007073",
      "Comune": "VerrËs",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0125",
      "CAP": 11029,
      "CodFisco": "C282",
      "Abitanti": 2758,
      "Link": "http://www.comuni-italiani.it/007/073/"
    },
    {
      "Istat": "018174",
      "Comune": "Verretto",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27053,
      "CodFisco": "L784",
      "Abitanti": 377,
      "Link": "http://www.comuni-italiani.it/018/174/"
    },
    {
      "Istat": "096076",
      "Comune": "Verrone",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13871,
      "CodFisco": "L785",
      "Abitanti": 1213,
      "Link": "http://www.comuni-italiani.it/096/076/"
    },
    {
      "Istat": "018175",
      "Comune": "Verrua Po",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27040,
      "CodFisco": "L788",
      "Abitanti": 1288,
      "Link": "http://www.comuni-italiani.it/018/175/"
    },
    {
      "Istat": "001294",
      "Comune": "Verrua Savoia",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 10020,
      "CodFisco": "L787",
      "Abitanti": 1472,
      "Link": "http://www.comuni-italiani.it/001/294/"
    },
    {
      "Istat": "013242",
      "Comune": "Vertemate con Minoprio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22070,
      "CodFisco": "L792",
      "Abitanti": 4025,
      "Link": "http://www.comuni-italiani.it/013/242/"
    },
    {
      "Istat": "016234",
      "Comune": "Vertova",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24029,
      "CodFisco": "L795",
      "Abitanti": 4871,
      "Link": "http://www.comuni-italiani.it/016/234/"
    },
    {
      "Istat": "099020",
      "Comune": "Verucchio",
      "Provincia": "RN",
      "Regione": "EMR",
      "Prefisso": "0541",
      "CAP": 47826,
      "CodFisco": "L797",
      "Abitanti": 10079,
      "Link": "http://www.comuni-italiani.it/099/020/"
    },
    {
      "Istat": "003157",
      "Comune": "Veruno",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0322",
      "CAP": 28010,
      "CodFisco": "L798",
      "Abitanti": 1874,
      "Link": "http://www.comuni-italiani.it/003/157/"
    },
    {
      "Istat": "014076",
      "Comune": "Vervio",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "L799",
      "Abitanti": 216,
      "Link": "http://www.comuni-italiani.it/014/076/"
    },
    {
      "Istat": "022214",
      "Comune": "VervÚ",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0463",
      "CAP": 38010,
      "CodFisco": "L800",
      "Abitanti": 729,
      "Link": "http://www.comuni-italiani.it/022/214/"
    },
    {
      "Istat": "030132",
      "Comune": "Verzegnis",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "L801",
      "Abitanti": 929,
      "Link": "http://www.comuni-italiani.it/030/132/"
    },
    {
      "Istat": 101027,
      "Comune": "Verzino",
      "Provincia": "KR",
      "Regione": "CAL",
      "Prefisso": "0962",
      "CAP": 88819,
      "CodFisco": "L802",
      "Abitanti": 2022,
      "Link": "http://www.comuni-italiani.it/101/027/"
    },
    {
      "Istat": "004240",
      "Comune": "Verzuolo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0175",
      "CAP": 12039,
      "CodFisco": "L804",
      "Abitanti": 6507,
      "Link": "http://www.comuni-italiani.it/004/240/"
    },
    {
      "Istat": "028097",
      "Comune": "Vescovana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 35040,
      "CodFisco": "L805",
      "Abitanti": 1702,
      "Link": "http://www.comuni-italiani.it/028/097/"
    },
    {
      "Istat": "019113",
      "Comune": "Vescovato",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26039,
      "CodFisco": "L806",
      "Abitanti": 4040,
      "Link": "http://www.comuni-italiani.it/019/113/"
    },
    {
      "Istat": "005113",
      "Comune": "Vesime",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 14059,
      "CodFisco": "L807",
      "Abitanti": 661,
      "Link": "http://www.comuni-italiani.it/005/113/"
    },
    {
      "Istat": "003158",
      "Comune": "Vespolate",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28079,
      "CodFisco": "L808",
      "Abitanti": 2085,
      "Link": "http://www.comuni-italiani.it/003/158/"
    },
    {
      "Istat": "008066",
      "Comune": "Vessalico",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18026,
      "CodFisco": "L809",
      "Abitanti": 315,
      "Link": "http://www.comuni-italiani.it/008/066/"
    },
    {
      "Istat": "023093",
      "Comune": "Vestenanova",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37030,
      "CodFisco": "L810",
      "Abitanti": 2677,
      "Link": "http://www.comuni-italiani.it/023/093/"
    },
    {
      "Istat": "001295",
      "Comune": "VestignË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10030,
      "CodFisco": "L811",
      "Abitanti": 834,
      "Link": "http://www.comuni-italiani.it/001/295/"
    },
    {
      "Istat": "017197",
      "Comune": "Vestone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25078,
      "CodFisco": "L812",
      "Abitanti": 4509,
      "Link": "http://www.comuni-italiani.it/017/197/"
    },
    {
      "Istat": "097089",
      "Comune": "Vestreno",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "0341",
      "CAP": 23822,
      "CodFisco": "L813",
      "Abitanti": 315,
      "Link": "http://www.comuni-italiani.it/097/089/"
    },
    {
      "Istat": "056057",
      "Comune": "Vetralla",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01019",
      "CodFisco": "L814",
      "Abitanti": 13508,
      "Link": "http://www.comuni-italiani.it/056/057/"
    },
    {
      "Istat": "035042",
      "Comune": "Vetto",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42020,
      "CodFisco": "L815",
      "Abitanti": 2001,
      "Link": "http://www.comuni-italiani.it/035/042/"
    },
    {
      "Istat": "004241",
      "Comune": "Vezza d'Alba",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0173",
      "CAP": 12040,
      "CodFisco": "L817",
      "Abitanti": 2230,
      "Link": "http://www.comuni-italiani.it/004/241/"
    },
    {
      "Istat": "017198",
      "Comune": "Vezza d'Oglio",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25059,
      "CodFisco": "L816",
      "Abitanti": 1475,
      "Link": "http://www.comuni-italiani.it/017/198/"
    },
    {
      "Istat": "022215",
      "Comune": "Vezzano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38070,
      "CodFisco": "L821",
      "Abitanti": 2192,
      "Link": "http://www.comuni-italiani.it/022/215/"
    },
    {
      "Istat": "011031",
      "Comune": "Vezzano Ligure",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "L819",
      "Abitanti": 7344,
      "Link": "http://www.comuni-italiani.it/011/031/"
    },
    {
      "Istat": "035043",
      "Comune": "Vezzano sul Crostolo",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42030,
      "CodFisco": "L820",
      "Abitanti": 4270,
      "Link": "http://www.comuni-italiani.it/035/043/"
    },
    {
      "Istat": "009067",
      "Comune": "Vezzi Portio",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "019",
      "CAP": 17028,
      "CodFisco": "L823",
      "Abitanti": 825,
      "Link": "http://www.comuni-italiani.it/009/067/"
    },
    {
      "Istat": "020066",
      "Comune": "Viadana",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 46019,
      "CodFisco": "L826",
      "Abitanti": 19785,
      "Link": "http://www.comuni-italiani.it/020/066/"
    },
    {
      "Istat": "016235",
      "Comune": "Viadanica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "L827",
      "Abitanti": 1142,
      "Link": "http://www.comuni-italiani.it/016/235/"
    },
    {
      "Istat": "087053",
      "Comune": "Viagrande",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95029,
      "CodFisco": "L828",
      "Abitanti": 8090,
      "Link": "http://www.comuni-italiani.it/087/053/"
    },
    {
      "Istat": "005114",
      "Comune": "Viale",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14010,
      "CodFisco": "L829",
      "Abitanti": 264,
      "Link": "http://www.comuni-italiani.it/005/114/"
    },
    {
      "Istat": "001296",
      "Comune": "VialfrË",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10090,
      "CodFisco": "L830",
      "Abitanti": 247,
      "Link": "http://www.comuni-italiani.it/001/296/"
    },
    {
      "Istat": "035044",
      "Comune": "Viano",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42030,
      "CodFisco": "L831",
      "Abitanti": 3423,
      "Link": "http://www.comuni-italiani.it/035/044/"
    },
    {
      "Istat": "046033",
      "Comune": "Viareggio",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0584",
      "CAP": 55049,
      "CodFisco": "L833",
      "Abitanti": 64503,
      "Link": "http://www.comuni-italiani.it/046/033/"
    },
    {
      "Istat": "005115",
      "Comune": "Viarigi",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14030,
      "CodFisco": "L834",
      "Abitanti": 971,
      "Link": "http://www.comuni-italiani.it/005/115/"
    },
    {
      "Istat": 102047,
      "Comune": "Vibo Valentia",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89900,
      "CodFisco": "F537",
      "Abitanti": 33853,
      "Link": "http://www.comuni-italiani.it/102/047/"
    },
    {
      "Istat": "065156",
      "Comune": "Vibonati",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "0973",
      "CAP": 84079,
      "CodFisco": "L835",
      "Abitanti": 3278,
      "Link": "http://www.comuni-italiani.it/065/156/"
    },
    {
      "Istat": "060086",
      "Comune": "Vicalvi",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "L836",
      "Abitanti": 840,
      "Link": "http://www.comuni-italiani.it/060/086/"
    },
    {
      "Istat": "082078",
      "Comune": "Vicari",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90020,
      "CodFisco": "L837",
      "Abitanti": 2962,
      "Link": "http://www.comuni-italiani.it/082/078/"
    },
    {
      "Istat": "048049",
      "Comune": "Vicchio",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "055",
      "CAP": 50039,
      "CodFisco": "L838",
      "Abitanti": 8262,
      "Link": "http://www.comuni-italiani.it/048/049/"
    },
    {
      "Istat": "024116",
      "Comune": "Vicenza",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36100,
      "CodFisco": "L840",
      "Abitanti": 115927,
      "Link": "http://www.comuni-italiani.it/024/116/"
    },
    {
      "Istat": "001297",
      "Comune": "Vico Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "L548",
      "Abitanti": 885,
      "Link": "http://www.comuni-italiani.it/001/297/"
    },
    {
      "Istat": "071059",
      "Comune": "Vico del Gargano",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71018,
      "CodFisco": "L842",
      "Abitanti": 7982,
      "Link": "http://www.comuni-italiani.it/071/059/"
    },
    {
      "Istat": "063086",
      "Comune": "Vico Equense",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80069,
      "CodFisco": "L845",
      "Abitanti": 20980,
      "Link": "http://www.comuni-italiani.it/063/086/"
    },
    {
      "Istat": "060087",
      "Comune": "Vico nel Lazio",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03010",
      "CodFisco": "L843",
      "Abitanti": 2314,
      "Link": "http://www.comuni-italiani.it/060/087/"
    },
    {
      "Istat": "004242",
      "Comune": "Vicoforte",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12080,
      "CodFisco": "L841",
      "Abitanti": 3266,
      "Link": "http://www.comuni-italiani.it/004/242/"
    },
    {
      "Istat": "068045",
      "Comune": "Vicoli",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "L846",
      "Abitanti": 399,
      "Link": "http://www.comuni-italiani.it/068/045/"
    },
    {
      "Istat": "003159",
      "Comune": "Vicolungo",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0321",
      "CAP": 28060,
      "CodFisco": "L847",
      "Abitanti": 898,
      "Link": "http://www.comuni-italiani.it/003/159/"
    },
    {
      "Istat": "050038",
      "Comune": "Vicopisano",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "050",
      "CAP": 56010,
      "CodFisco": "L850",
      "Abitanti": 8466,
      "Link": "http://www.comuni-italiani.it/050/038/"
    },
    {
      "Istat": "058112",
      "Comune": "Vicovaro",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00029",
      "CodFisco": "L851",
      "Abitanti": 4108,
      "Link": "http://www.comuni-italiani.it/058/112/"
    },
    {
      "Istat": "090082",
      "Comune": "Viddalba",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07030",
      "CodFisco": "M259",
      "Abitanti": 1708,
      "Link": "http://www.comuni-italiani.it/090/082/"
    },
    {
      "Istat": "018176",
      "Comune": "Vidigulfo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27018,
      "CodFisco": "L854",
      "Abitanti": 6016,
      "Link": "http://www.comuni-italiani.it/018/176/"
    },
    {
      "Istat": "026090",
      "Comune": "Vidor",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31020,
      "CodFisco": "L856",
      "Abitanti": 3819,
      "Link": "http://www.comuni-italiani.it/026/090/"
    },
    {
      "Istat": "001298",
      "Comune": "Vidracco",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "L857",
      "Abitanti": 499,
      "Link": "http://www.comuni-italiani.it/001/298/"
    },
    {
      "Istat": "071060",
      "Comune": "Vieste",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71019,
      "CodFisco": "L858",
      "Abitanti": 13963,
      "Link": "http://www.comuni-italiani.it/071/060/"
    },
    {
      "Istat": "076096",
      "Comune": "Vietri di Potenza",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0971",
      "CAP": 85058,
      "CodFisco": "L859",
      "Abitanti": 2933,
      "Link": "http://www.comuni-italiani.it/076/096/"
    },
    {
      "Istat": "065157",
      "Comune": "Vietri sul Mare",
      "Provincia": "SA",
      "Regione": "CAM",
      "Prefisso": "089",
      "CAP": 84019,
      "CodFisco": "L860",
      "Abitanti": 8293,
      "Link": "http://www.comuni-italiani.it/065/157/"
    },
    {
      "Istat": 103073,
      "Comune": "Viganella",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28841,
      "CodFisco": "L864",
      "Abitanti": 204,
      "Link": "http://www.comuni-italiani.it/103/073/"
    },
    {
      "Istat": "097090",
      "Comune": "ViganÚ",
      "Provincia": "LC",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 23897,
      "CodFisco": "L866",
      "Abitanti": 2015,
      "Link": "http://www.comuni-italiani.it/097/090/"
    },
    {
      "Istat": "016236",
      "Comune": "Vigano San Martino",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "L865",
      "Abitanti": 1260,
      "Link": "http://www.comuni-italiani.it/016/236/"
    },
    {
      "Istat": "038022",
      "Comune": "Vigarano Mainarda",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44049,
      "CodFisco": "L868",
      "Abitanti": 7520,
      "Link": "http://www.comuni-italiani.it/038/022/"
    },
    {
      "Istat": "023094",
      "Comune": "Vigasio",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37068,
      "CodFisco": "L869",
      "Abitanti": 9438,
      "Link": "http://www.comuni-italiani.it/023/094/"
    },
    {
      "Istat": "018177",
      "Comune": "Vigevano",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0381",
      "CAP": 27029,
      "CodFisco": "L872",
      "Abitanti": 63700,
      "Link": "http://www.comuni-italiani.it/018/177/"
    },
    {
      "Istat": "076097",
      "Comune": "Viggianello",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0973",
      "CAP": 85040,
      "CodFisco": "L873",
      "Abitanti": 3209,
      "Link": "http://www.comuni-italiani.it/076/097/"
    },
    {
      "Istat": "076098",
      "Comune": "Viggiano",
      "Provincia": "PZ",
      "Regione": "BAS",
      "Prefisso": "0975",
      "CAP": 85059,
      "CodFisco": "L874",
      "Abitanti": 3170,
      "Link": "http://www.comuni-italiani.it/076/098/"
    },
    {
      "Istat": "012139",
      "Comune": "Viggi˘",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0332",
      "CAP": 21059,
      "CodFisco": "L876",
      "Abitanti": 5247,
      "Link": "http://www.comuni-italiani.it/012/139/"
    },
    {
      "Istat": "028098",
      "Comune": "Vighizzolo d'Este",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "L878",
      "Abitanti": 955,
      "Link": "http://www.comuni-italiani.it/028/098/"
    },
    {
      "Istat": "096077",
      "Comune": "Vigliano Biellese",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13856,
      "CodFisco": "L880",
      "Abitanti": 8343,
      "Link": "http://www.comuni-italiani.it/096/077/"
    },
    {
      "Istat": "005116",
      "Comune": "Vigliano d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "L879",
      "Abitanti": 880,
      "Link": "http://www.comuni-italiani.it/005/116/"
    },
    {
      "Istat": "006179",
      "Comune": "Vignale Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15049,
      "CodFisco": "L881",
      "Abitanti": 1084,
      "Link": "http://www.comuni-italiani.it/006/179/"
    },
    {
      "Istat": "056058",
      "Comune": "Vignanello",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01039",
      "CodFisco": "L882",
      "Abitanti": 4823,
      "Link": "http://www.comuni-italiani.it/056/058/"
    },
    {
      "Istat": "015237",
      "Comune": "Vignate",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20060,
      "CodFisco": "L883",
      "Abitanti": 9064,
      "Link": "http://www.comuni-italiani.it/015/237/"
    },
    {
      "Istat": "036046",
      "Comune": "Vignola",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41058,
      "CodFisco": "L885",
      "Abitanti": 24802,
      "Link": "http://www.comuni-italiani.it/036/046/"
    },
    {
      "Istat": "022216",
      "Comune": "Vignola-Falesina",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38057,
      "CodFisco": "L886",
      "Abitanti": 154,
      "Link": "http://www.comuni-italiani.it/022/216/"
    },
    {
      "Istat": "006180",
      "Comune": "Vignole Borbera",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0143",
      "CAP": 15060,
      "CodFisco": "L887",
      "Abitanti": 2279,
      "Link": "http://www.comuni-italiani.it/006/180/"
    },
    {
      "Istat": "004243",
      "Comune": "Vignolo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "L888",
      "Abitanti": 2467,
      "Link": "http://www.comuni-italiani.it/004/243/"
    },
    {
      "Istat": 103074,
      "Comune": "Vignone",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0323",
      "CAP": 28819,
      "CodFisco": "L889",
      "Abitanti": 1219,
      "Link": "http://www.comuni-italiani.it/103/074/"
    },
    {
      "Istat": "025065",
      "Comune": "Vigo di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "L890",
      "Abitanti": 1553,
      "Link": "http://www.comuni-italiani.it/025/065/"
    },
    {
      "Istat": "022217",
      "Comune": "Vigo di Fassa",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38039,
      "CodFisco": "L893",
      "Abitanti": 1177,
      "Link": "http://www.comuni-italiani.it/022/217/"
    },
    {
      "Istat": "022220",
      "Comune": "Vigo Rendena",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "L903",
      "Abitanti": 517,
      "Link": "http://www.comuni-italiani.it/022/220/"
    },
    {
      "Istat": "028099",
      "Comune": "Vigodarzere",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "L892",
      "Abitanti": 12873,
      "Link": "http://www.comuni-italiani.it/028/099/"
    },
    {
      "Istat": "016237",
      "Comune": "Vigolo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "L894",
      "Abitanti": 603,
      "Link": "http://www.comuni-italiani.it/016/237/"
    },
    {
      "Istat": "022219",
      "Comune": "Vigolo Vattaro",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38049,
      "CodFisco": "L896",
      "Abitanti": 2201,
      "Link": "http://www.comuni-italiani.it/022/219/"
    },
    {
      "Istat": "033045",
      "Comune": "Vigolzone",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "L897",
      "Abitanti": 4314,
      "Link": "http://www.comuni-italiani.it/033/045/"
    },
    {
      "Istat": "001299",
      "Comune": "Vigone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10067,
      "CodFisco": "L898",
      "Abitanti": 5268,
      "Link": "http://www.comuni-italiani.it/001/299/"
    },
    {
      "Istat": "027043",
      "Comune": "Vigonovo",
      "Provincia": "VE",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 30030,
      "CodFisco": "L899",
      "Abitanti": 10078,
      "Link": "http://www.comuni-italiani.it/027/043/"
    },
    {
      "Istat": "028100",
      "Comune": "Vigonza",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "L900",
      "Abitanti": 22075,
      "Link": "http://www.comuni-italiani.it/028/100/"
    },
    {
      "Istat": "006181",
      "Comune": "Viguzzolo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15058,
      "CodFisco": "L904",
      "Abitanti": 3261,
      "Link": "http://www.comuni-italiani.it/006/181/"
    },
    {
      "Istat": "022221",
      "Comune": "Villa Agnedo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38059,
      "CodFisco": "L910",
      "Abitanti": 1012,
      "Link": "http://www.comuni-italiani.it/022/221/"
    },
    {
      "Istat": "023095",
      "Comune": "Villa Bartolomea",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37049,
      "CodFisco": "L912",
      "Abitanti": 5875,
      "Link": "http://www.comuni-italiani.it/023/095/"
    },
    {
      "Istat": "046034",
      "Comune": "Villa Basilica",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0572",
      "CAP": 55019,
      "CodFisco": "L913",
      "Abitanti": 1736,
      "Link": "http://www.comuni-italiani.it/046/034/"
    },
    {
      "Istat": "018178",
      "Comune": "Villa Biscossi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27035,
      "CodFisco": "L917",
      "Abitanti": 76,
      "Link": "http://www.comuni-italiani.it/018/178/"
    },
    {
      "Istat": "017199",
      "Comune": "Villa Carcina",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25069,
      "CodFisco": "L919",
      "Abitanti": 10997,
      "Link": "http://www.comuni-italiani.it/017/199/"
    },
    {
      "Istat": "074020",
      "Comune": "Villa Castelli",
      "Provincia": "BR",
      "Regione": "PUG",
      "Prefisso": "0831",
      "CAP": 72029,
      "CodFisco": "L920",
      "Abitanti": 9260,
      "Link": "http://www.comuni-italiani.it/074/020/"
    },
    {
      "Istat": "068046",
      "Comune": "Villa Celiera",
      "Provincia": "PE",
      "Regione": "ABR",
      "Prefisso": "085",
      "CAP": 65010,
      "CodFisco": "L922",
      "Abitanti": 765,
      "Link": "http://www.comuni-italiani.it/068/046/"
    },
    {
      "Istat": "046035",
      "Comune": "Villa Collemandina",
      "Provincia": "LU",
      "Regione": "TOS",
      "Prefisso": "0583",
      "CAP": 55030,
      "CodFisco": "L926",
      "Abitanti": 1404,
      "Link": "http://www.comuni-italiani.it/046/035/"
    },
    {
      "Istat": "015248",
      "Comune": "Villa Cortese",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 20020,
      "CodFisco": "L928",
      "Abitanti": 6216,
      "Link": "http://www.comuni-italiani.it/015/248/"
    },
    {
      "Istat": "016238",
      "Comune": "Villa d'Adda",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24030,
      "CodFisco": "L929",
      "Abitanti": 4729,
      "Link": "http://www.comuni-italiani.it/016/238/"
    },
    {
      "Istat": "016239",
      "Comune": "Villa d'AlmË",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24018,
      "CodFisco": "A215",
      "Abitanti": 6844,
      "Link": "http://www.comuni-italiani.it/016/239/"
    },
    {
      "Istat": "096078",
      "Comune": "Villa del Bosco",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13868,
      "CodFisco": "L933",
      "Abitanti": 379,
      "Link": "http://www.comuni-italiani.it/096/078/"
    },
    {
      "Istat": "028101",
      "Comune": "Villa del Conte",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "L934",
      "Abitanti": 5530,
      "Link": "http://www.comuni-italiani.it/028/101/"
    },
    {
      "Istat": "061098",
      "Comune": "Villa di Briano",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81030,
      "CodFisco": "D801",
      "Abitanti": 6522,
      "Link": "http://www.comuni-italiani.it/061/098/"
    },
    {
      "Istat": "014077",
      "Comune": "Villa di Chiavenna",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0343",
      "CAP": 23029,
      "CodFisco": "L907",
      "Abitanti": 1054,
      "Link": "http://www.comuni-italiani.it/014/077/"
    },
    {
      "Istat": "016240",
      "Comune": "Villa di Serio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24020,
      "CodFisco": "L936",
      "Abitanti": 6608,
      "Link": "http://www.comuni-italiani.it/016/240/"
    },
    {
      "Istat": "014078",
      "Comune": "Villa di Tirano",
      "Provincia": "SO",
      "Regione": "LOM",
      "Prefisso": "0342",
      "CAP": 23030,
      "CodFisco": "L908",
      "Abitanti": 2984,
      "Link": "http://www.comuni-italiani.it/014/078/"
    },
    {
      "Istat": "016241",
      "Comune": "Villa d'Ogna",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "L938",
      "Abitanti": 1982,
      "Link": "http://www.comuni-italiani.it/016/241/"
    },
    {
      "Istat": "028102",
      "Comune": "Villa Estense",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "0429",
      "CAP": 35040,
      "CodFisco": "L937",
      "Abitanti": 2345,
      "Link": "http://www.comuni-italiani.it/028/102/"
    },
    {
      "Istat": "008067",
      "Comune": "Villa Faraldi",
      "Provincia": "IM",
      "Regione": "LIG",
      "Prefisso": "0183",
      "CAP": 18010,
      "CodFisco": "L943",
      "Abitanti": 500,
      "Link": "http://www.comuni-italiani.it/008/067/"
    },
    {
      "Istat": "013245",
      "Comune": "Villa Guardia",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22079,
      "CodFisco": "L956",
      "Abitanti": 7759,
      "Link": "http://www.comuni-italiani.it/013/245/"
    },
    {
      "Istat": "022222",
      "Comune": "Villa Lagarina",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "L957",
      "Abitanti": 3684,
      "Link": "http://www.comuni-italiani.it/022/222/"
    },
    {
      "Istat": "060088",
      "Comune": "Villa Latina",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "A081",
      "Abitanti": 1240,
      "Link": "http://www.comuni-italiani.it/060/088/"
    },
    {
      "Istat": "061099",
      "Comune": "Villa Literno",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 81039,
      "CodFisco": "L844",
      "Abitanti": 11676,
      "Link": "http://www.comuni-italiani.it/061/099/"
    },
    {
      "Istat": "035045",
      "Comune": "Villa Minozzo",
      "Provincia": "RE",
      "Regione": "EMR",
      "Prefisso": "0522",
      "CAP": 42030,
      "CodFisco": "L969",
      "Abitanti": 3988,
      "Link": "http://www.comuni-italiani.it/035/045/"
    },
    {
      "Istat": "020067",
      "Comune": "Villa Poma",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0386",
      "CAP": 46020,
      "CodFisco": "F804",
      "Abitanti": 2037,
      "Link": "http://www.comuni-italiani.it/020/067/"
    },
    {
      "Istat": "022223",
      "Comune": "Villa Rendena",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38080,
      "CodFisco": "M006",
      "Abitanti": 992,
      "Link": "http://www.comuni-italiani.it/022/223/"
    },
    {
      "Istat": "080096",
      "Comune": "Villa San Giovanni",
      "Provincia": "RC",
      "Regione": "CAL",
      "Prefisso": "0965",
      "CAP": 89018,
      "CodFisco": "M018",
      "Abitanti": 13792,
      "Link": "http://www.comuni-italiani.it/080/096/"
    },
    {
      "Istat": "056046",
      "Comune": "Villa San Giovanni in Tuscia",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01010",
      "CodFisco": "H913",
      "Abitanti": 1364,
      "Link": "http://www.comuni-italiani.it/056/046/"
    },
    {
      "Istat": "092099",
      "Comune": "Villa San Pietro",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09010",
      "CodFisco": "I118",
      "Abitanti": 2010,
      "Link": "http://www.comuni-italiani.it/092/099/"
    },
    {
      "Istat": "005119",
      "Comune": "Villa San Secondo",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14020,
      "CodFisco": "M019",
      "Abitanti": 393,
      "Link": "http://www.comuni-italiani.it/005/119/"
    },
    {
      "Istat": "060089",
      "Comune": "Villa Santa Lucia",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03030",
      "CodFisco": "L905",
      "Abitanti": 2696,
      "Link": "http://www.comuni-italiani.it/060/089/"
    },
    {
      "Istat": "066104",
      "Comune": "Villa Santa Lucia degli Abruzzi",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "M021",
      "Abitanti": 148,
      "Link": "http://www.comuni-italiani.it/066/104/"
    },
    {
      "Istat": "069102",
      "Comune": "Villa Santa Maria",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0872",
      "CAP": 66047,
      "CodFisco": "M022",
      "Abitanti": 1439,
      "Link": "http://www.comuni-italiani.it/069/102/"
    },
    {
      "Istat": "066105",
      "Comune": "Villa Sant'Angelo",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0862",
      "CAP": 67020,
      "CodFisco": "M023",
      "Abitanti": 429,
      "Link": "http://www.comuni-italiani.it/066/105/"
    },
    {
      "Istat": "095048",
      "Comune": "Villa Sant'Antonio",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "I298",
      "Abitanti": 394,
      "Link": "http://www.comuni-italiani.it/095/048/"
    },
    {
      "Istat": "030133",
      "Comune": "Villa Santina",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33029,
      "CodFisco": "L909",
      "Abitanti": 2223,
      "Link": "http://www.comuni-italiani.it/030/133/"
    },
    {
      "Istat": "060090",
      "Comune": "Villa Santo Stefano",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0775",
      "CAP": "03020",
      "CodFisco": "I364",
      "Abitanti": 1750,
      "Link": "http://www.comuni-italiani.it/060/090/"
    },
    {
      "Istat": "095073",
      "Comune": "Villa Verde",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09090",
      "CodFisco": "A609",
      "Abitanti": 343,
      "Link": "http://www.comuni-italiani.it/095/073/"
    },
    {
      "Istat": "030134",
      "Comune": "Villa Vicentina",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0431",
      "CAP": 33059,
      "CodFisco": "M034",
      "Abitanti": 1405,
      "Link": "http://www.comuni-italiani.it/030/134/"
    },
    {
      "Istat": "021113",
      "Comune": "Villabassa",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0474",
      "CAP": 39039,
      "CodFisco": "L915",
      "Abitanti": 1473,
      "Link": "http://www.comuni-italiani.it/021/113/"
    },
    {
      "Istat": "082079",
      "Comune": "Villabate",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90039,
      "CodFisco": "L916",
      "Abitanti": 20434,
      "Link": "http://www.comuni-italiani.it/082/079/"
    },
    {
      "Istat": "017200",
      "Comune": "Villachiara",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25030,
      "CodFisco": "L923",
      "Abitanti": 1456,
      "Link": "http://www.comuni-italiani.it/017/200/"
    },
    {
      "Istat": 106025,
      "Comune": "Villacidro",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09039",
      "CodFisco": "L924",
      "Abitanti": 14454,
      "Link": "http://www.comuni-italiani.it/106/025/"
    },
    {
      "Istat": "006182",
      "Comune": "Villadeati",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 15020,
      "CodFisco": "L931",
      "Abitanti": 513,
      "Link": "http://www.comuni-italiani.it/006/182/"
    },
    {
      "Istat": "029048",
      "Comune": "Villadose",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45010,
      "CodFisco": "L939",
      "Abitanti": 5223,
      "Link": "http://www.comuni-italiani.it/029/048/"
    },
    {
      "Istat": 103075,
      "Comune": "Villadossola",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28844,
      "CodFisco": "L906",
      "Abitanti": 6912,
      "Link": "http://www.comuni-italiani.it/103/075/"
    },
    {
      "Istat": "004244",
      "Comune": "Villafalletto",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "L942",
      "Abitanti": 2925,
      "Link": "http://www.comuni-italiani.it/004/244/"
    },
    {
      "Istat": "005117",
      "Comune": "Villafranca d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14018,
      "CodFisco": "L945",
      "Abitanti": 3198,
      "Link": "http://www.comuni-italiani.it/005/117/"
    },
    {
      "Istat": "023096",
      "Comune": "Villafranca di Verona",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37069,
      "CodFisco": "L949",
      "Abitanti": 33117,
      "Link": "http://www.comuni-italiani.it/023/096/"
    },
    {
      "Istat": "045016",
      "Comune": "Villafranca in Lunigiana",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54028,
      "CodFisco": "L946",
      "Abitanti": 4877,
      "Link": "http://www.comuni-italiani.it/045/016/"
    },
    {
      "Istat": "028103",
      "Comune": "Villafranca Padovana",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "L947",
      "Abitanti": 9841,
      "Link": "http://www.comuni-italiani.it/028/103/"
    },
    {
      "Istat": "001300",
      "Comune": "Villafranca Piemonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10068,
      "CodFisco": "L948",
      "Abitanti": 4845,
      "Link": "http://www.comuni-italiani.it/001/300/"
    },
    {
      "Istat": "084043",
      "Comune": "Villafranca Sicula",
      "Provincia": "AG",
      "Regione": "SIC",
      "Prefisso": "0925",
      "CAP": 92020,
      "CodFisco": "L944",
      "Abitanti": 1436,
      "Link": "http://www.comuni-italiani.it/084/043/"
    },
    {
      "Istat": "083105",
      "Comune": "Villafranca Tirrena",
      "Provincia": "ME",
      "Regione": "SIC",
      "Prefisso": "090",
      "CAP": 98049,
      "CodFisco": "L950",
      "Abitanti": 8931,
      "Link": "http://www.comuni-italiani.it/083/105/"
    },
    {
      "Istat": "082080",
      "Comune": "Villafrati",
      "Provincia": "PA",
      "Regione": "SIC",
      "Prefisso": "091",
      "CAP": 90030,
      "CodFisco": "L951",
      "Abitanti": 3377,
      "Link": "http://www.comuni-italiani.it/082/080/"
    },
    {
      "Istat": "024117",
      "Comune": "Villaga",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36021,
      "CodFisco": "L952",
      "Abitanti": 1970,
      "Link": "http://www.comuni-italiani.it/024/117/"
    },
    {
      "Istat": 105023,
      "Comune": "Villagrande Strisaili",
      "Provincia": "OG",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08049",
      "CodFisco": "L953",
      "Abitanti": 3421,
      "Link": "http://www.comuni-italiani.it/105/023/"
    },
    {
      "Istat": "066103",
      "Comune": "Villalago",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "L958",
      "Abitanti": 613,
      "Link": "http://www.comuni-italiani.it/066/103/"
    },
    {
      "Istat": "085022",
      "Comune": "Villalba",
      "Provincia": "CL",
      "Regione": "SIC",
      "Prefisso": "0934",
      "CAP": 93010,
      "CodFisco": "L959",
      "Abitanti": 1752,
      "Link": "http://www.comuni-italiani.it/085/022/"
    },
    {
      "Istat": "069100",
      "Comune": "Villalfonsina",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0873",
      "CAP": 66020,
      "CodFisco": "L961",
      "Abitanti": 1008,
      "Link": "http://www.comuni-italiani.it/069/100/"
    },
    {
      "Istat": "006183",
      "Comune": "Villalvernia",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "L963",
      "Abitanti": 995,
      "Link": "http://www.comuni-italiani.it/006/183/"
    },
    {
      "Istat": "069101",
      "Comune": "Villamagna",
      "Provincia": "CH",
      "Regione": "ABR",
      "Prefisso": "0871",
      "CAP": 66010,
      "CodFisco": "L964",
      "Abitanti": 2449,
      "Link": "http://www.comuni-italiani.it/069/101/"
    },
    {
      "Istat": "064117",
      "Comune": "Villamaina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "L965",
      "Abitanti": 970,
      "Link": "http://www.comuni-italiani.it/064/117/"
    },
    {
      "Istat": 106026,
      "Comune": "Villamar",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "L966",
      "Abitanti": 2881,
      "Link": "http://www.comuni-italiani.it/106/026/"
    },
    {
      "Istat": "029049",
      "Comune": "Villamarzana",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "L967",
      "Abitanti": 1225,
      "Link": "http://www.comuni-italiani.it/029/049/"
    },
    {
      "Istat": 107022,
      "Comune": "Villamassargia",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "L968",
      "Abitanti": 3684,
      "Link": "http://www.comuni-italiani.it/107/022/"
    },
    {
      "Istat": "006184",
      "Comune": "Villamiroglio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15020,
      "CodFisco": "L970",
      "Abitanti": 338,
      "Link": "http://www.comuni-italiani.it/006/184/"
    },
    {
      "Istat": "021114",
      "Comune": "Villandro",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39040,
      "CodFisco": "L971",
      "Abitanti": 1906,
      "Link": "http://www.comuni-italiani.it/021/114/"
    },
    {
      "Istat": "096079",
      "Comune": "Villanova Biellese",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13877,
      "CodFisco": "L978",
      "Abitanti": 191,
      "Link": "http://www.comuni-italiani.it/096/079/"
    },
    {
      "Istat": "001301",
      "Comune": "Villanova Canavese",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10070,
      "CodFisco": "L982",
      "Abitanti": 1141,
      "Link": "http://www.comuni-italiani.it/001/301/"
    },
    {
      "Istat": "009068",
      "Comune": "Villanova d'Albenga",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17038,
      "CodFisco": "L975",
      "Abitanti": 2506,
      "Link": "http://www.comuni-italiani.it/009/068/"
    },
    {
      "Istat": "018179",
      "Comune": "Villanova d'Ardenghi",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27030,
      "CodFisco": "L983",
      "Abitanti": 786,
      "Link": "http://www.comuni-italiani.it/018/179/"
    },
    {
      "Istat": "005118",
      "Comune": "Villanova d'Asti",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14019,
      "CodFisco": "L984",
      "Abitanti": 5767,
      "Link": "http://www.comuni-italiani.it/005/118/"
    },
    {
      "Istat": "064118",
      "Comune": "Villanova del Battista",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "L973",
      "Abitanti": 1786,
      "Link": "http://www.comuni-italiani.it/064/118/"
    },
    {
      "Istat": "029050",
      "Comune": "Villanova del Ghebbo",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45020,
      "CodFisco": "L985",
      "Abitanti": 2192,
      "Link": "http://www.comuni-italiani.it/029/050/"
    },
    {
      "Istat": "098060",
      "Comune": "Villanova del Sillaro",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "0371",
      "CAP": 26818,
      "CodFisco": "L977",
      "Abitanti": 1824,
      "Link": "http://www.comuni-italiani.it/098/060/"
    },
    {
      "Istat": "028104",
      "Comune": "Villanova di Camposampiero",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35010,
      "CodFisco": "L979",
      "Abitanti": 5903,
      "Link": "http://www.comuni-italiani.it/028/104/"
    },
    {
      "Istat": "029051",
      "Comune": "Villanova Marchesana",
      "Provincia": "RO",
      "Regione": "VEN",
      "Prefisso": "0425",
      "CAP": 45030,
      "CodFisco": "L988",
      "Abitanti": 1047,
      "Link": "http://www.comuni-italiani.it/029/051/"
    },
    {
      "Istat": "004245",
      "Comune": "Villanova MondovÏ",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12089,
      "CodFisco": "L974",
      "Abitanti": 5838,
      "Link": "http://www.comuni-italiani.it/004/245/"
    },
    {
      "Istat": "006185",
      "Comune": "Villanova Monferrato",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0142",
      "CAP": 15030,
      "CodFisco": "L972",
      "Abitanti": 1861,
      "Link": "http://www.comuni-italiani.it/006/185/"
    },
    {
      "Istat": "090078",
      "Comune": "Villanova Monteleone",
      "Provincia": "SS",
      "Regione": "SAR",
      "Prefisso": "079",
      "CAP": "07019",
      "CodFisco": "L989",
      "Abitanti": 2405,
      "Link": "http://www.comuni-italiani.it/090/078/"
    },
    {
      "Istat": "004246",
      "Comune": "Villanova Solaro",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0172",
      "CAP": 12030,
      "CodFisco": "L990",
      "Abitanti": 783,
      "Link": "http://www.comuni-italiani.it/004/246/"
    },
    {
      "Istat": "033046",
      "Comune": "Villanova sull'Arda",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "L980",
      "Abitanti": 1969,
      "Link": "http://www.comuni-italiani.it/033/046/"
    },
    {
      "Istat": "095071",
      "Comune": "Villanova Truschedu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09084",
      "CodFisco": "L991",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/095/071/"
    },
    {
      "Istat": "092122",
      "Comune": "Villanova Tulo",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "0782",
      "CAP": "08030",
      "CodFisco": "L992",
      "Abitanti": 1164,
      "Link": "http://www.comuni-italiani.it/092/122/"
    },
    {
      "Istat": 106027,
      "Comune": "Villanovaforru",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "L986",
      "Abitanti": 683,
      "Link": "http://www.comuni-italiani.it/106/027/"
    },
    {
      "Istat": 106028,
      "Comune": "Villanovafranca",
      "Provincia": "VS",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09020",
      "CodFisco": "L987",
      "Abitanti": 1441,
      "Link": "http://www.comuni-italiani.it/106/028/"
    },
    {
      "Istat": "018180",
      "Comune": "Villanterio",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27019,
      "CodFisco": "L994",
      "Abitanti": 3226,
      "Link": "http://www.comuni-italiani.it/018/180/"
    },
    {
      "Istat": "017201",
      "Comune": "Villanuova sul Clisi",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25089,
      "CodFisco": "L995",
      "Abitanti": 5855,
      "Link": "http://www.comuni-italiani.it/017/201/"
    },
    {
      "Istat": 107023,
      "Comune": "Villaperuccio",
      "Provincia": "CI",
      "Regione": "SAR",
      "Prefisso": "0781",
      "CAP": "09010",
      "CodFisco": "M278",
      "Abitanti": 1121,
      "Link": "http://www.comuni-italiani.it/107/023/"
    },
    {
      "Istat": "078154",
      "Comune": "Villapiana",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0981",
      "CAP": 87076,
      "CodFisco": "B903",
      "Abitanti": 5513,
      "Link": "http://www.comuni-italiani.it/078/154/"
    },
    {
      "Istat": "092097",
      "Comune": "Villaputzu",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "L998",
      "Abitanti": 5078,
      "Link": "http://www.comuni-italiani.it/092/097/"
    },
    {
      "Istat": "001303",
      "Comune": "Villar Dora",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "L999",
      "Abitanti": 3043,
      "Link": "http://www.comuni-italiani.it/001/303/"
    },
    {
      "Istat": "001305",
      "Comune": "Villar Focchiardo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10050,
      "CodFisco": "M007",
      "Abitanti": 2079,
      "Link": "http://www.comuni-italiani.it/001/305/"
    },
    {
      "Istat": "001306",
      "Comune": "Villar Pellice",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10060,
      "CodFisco": "M013",
      "Abitanti": 1124,
      "Link": "http://www.comuni-italiani.it/001/306/"
    },
    {
      "Istat": "001307",
      "Comune": "Villar Perosa",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0121",
      "CAP": 10069,
      "CodFisco": "M014",
      "Abitanti": 4184,
      "Link": "http://www.comuni-italiani.it/001/307/"
    },
    {
      "Istat": "004247",
      "Comune": "Villar San Costanzo",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "M015",
      "Abitanti": 1513,
      "Link": "http://www.comuni-italiani.it/004/247/"
    },
    {
      "Istat": "001302",
      "Comune": "Villarbasse",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10090,
      "CodFisco": "M002",
      "Abitanti": 3334,
      "Link": "http://www.comuni-italiani.it/001/302/"
    },
    {
      "Istat": "002163",
      "Comune": "Villarboit",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13030,
      "CodFisco": "M003",
      "Abitanti": 491,
      "Link": "http://www.comuni-italiani.it/002/163/"
    },
    {
      "Istat": "001304",
      "Comune": "Villareggia",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 10030,
      "CodFisco": "M004",
      "Abitanti": 1015,
      "Link": "http://www.comuni-italiani.it/001/304/"
    },
    {
      "Istat": "063087",
      "Comune": "Villaricca",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80010,
      "CodFisco": "G309",
      "Abitanti": 30628,
      "Link": "http://www.comuni-italiani.it/063/087/"
    },
    {
      "Istat": "006186",
      "Comune": "Villaromagnano",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "M009",
      "Abitanti": 730,
      "Link": "http://www.comuni-italiani.it/006/186/"
    },
    {
      "Istat": "086020",
      "Comune": "Villarosa",
      "Provincia": "EN",
      "Regione": "SIC",
      "Prefisso": "0935",
      "CAP": 94010,
      "CodFisco": "M011",
      "Abitanti": 5313,
      "Link": "http://www.comuni-italiani.it/086/020/"
    },
    {
      "Istat": "092098",
      "Comune": "Villasalto",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09040",
      "CodFisco": "M016",
      "Abitanti": 1144,
      "Link": "http://www.comuni-italiani.it/092/098/"
    },
    {
      "Istat": 108049,
      "Comune": "Villasanta",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20852,
      "CodFisco": "M017",
      "Abitanti": 13590,
      "Link": "http://www.comuni-italiani.it/108/049/"
    },
    {
      "Istat": "092100",
      "Comune": "Villasimius",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09049",
      "CodFisco": "B738",
      "Abitanti": 3635,
      "Link": "http://www.comuni-italiani.it/092/100/"
    },
    {
      "Istat": "092101",
      "Comune": "Villasor",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09034",
      "CodFisco": "M025",
      "Abitanti": 7008,
      "Link": "http://www.comuni-italiani.it/092/101/"
    },
    {
      "Istat": "092102",
      "Comune": "Villaspeciosa",
      "Provincia": "CA",
      "Regione": "SAR",
      "Prefisso": "070",
      "CAP": "09010",
      "CodFisco": "M026",
      "Abitanti": 2391,
      "Link": "http://www.comuni-italiani.it/092/102/"
    },
    {
      "Istat": "001308",
      "Comune": "Villastellone",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10029,
      "CodFisco": "M027",
      "Abitanti": 4898,
      "Link": "http://www.comuni-italiani.it/001/308/"
    },
    {
      "Istat": "002164",
      "Comune": "Villata",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13010,
      "CodFisco": "M028",
      "Abitanti": 1631,
      "Link": "http://www.comuni-italiani.it/002/164/"
    },
    {
      "Istat": "095072",
      "Comune": "Villaurbana",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09080",
      "CodFisco": "M030",
      "Abitanti": 1758,
      "Link": "http://www.comuni-italiani.it/095/072/"
    },
    {
      "Istat": "066106",
      "Comune": "Villavallelonga",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0863",
      "CAP": 67050,
      "CodFisco": "M031",
      "Abitanti": 931,
      "Link": "http://www.comuni-italiani.it/066/106/"
    },
    {
      "Istat": "024118",
      "Comune": "Villaverla",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "M032",
      "Abitanti": 6244,
      "Link": "http://www.comuni-italiani.it/024/118/"
    },
    {
      "Istat": "007074",
      "Comune": "Villeneuve",
      "Provincia": "AO",
      "Regione": "VDA",
      "Prefisso": "0165",
      "CAP": 11018,
      "CodFisco": "L981",
      "Abitanti": 1267,
      "Link": "http://www.comuni-italiani.it/007/074/"
    },
    {
      "Istat": "031025",
      "Comune": "Villesse",
      "Provincia": "GO",
      "Regione": "FVG",
      "Prefisso": "0481",
      "CAP": 34070,
      "CodFisco": "M043",
      "Abitanti": 1729,
      "Link": "http://www.comuni-italiani.it/031/025/"
    },
    {
      "Istat": "066107",
      "Comune": "Villetta Barrea",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "M041",
      "Abitanti": 677,
      "Link": "http://www.comuni-italiani.it/066/107/"
    },
    {
      "Istat": 103076,
      "Comune": "Villette",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28856,
      "CodFisco": "M042",
      "Abitanti": 264,
      "Link": "http://www.comuni-italiani.it/103/076/"
    },
    {
      "Istat": "020068",
      "Comune": "Villimpenta",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46039,
      "CodFisco": "M044",
      "Abitanti": 2261,
      "Link": "http://www.comuni-italiani.it/020/068/"
    },
    {
      "Istat": "016242",
      "Comune": "Villongo",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "M045",
      "Abitanti": 7770,
      "Link": "http://www.comuni-italiani.it/016/242/"
    },
    {
      "Istat": "026091",
      "Comune": "Villorba",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31020,
      "CodFisco": "M048",
      "Abitanti": 18044,
      "Link": "http://www.comuni-italiani.it/026/091/"
    },
    {
      "Istat": "016243",
      "Comune": "Vilminore di Scalve",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0346",
      "CAP": 24020,
      "CodFisco": "M050",
      "Abitanti": 1532,
      "Link": "http://www.comuni-italiani.it/016/243/"
    },
    {
      "Istat": 108050,
      "Comune": "Vimercate",
      "Provincia": "MB",
      "Regione": "LOM",
      "Prefisso": "039",
      "CAP": 20871,
      "CodFisco": "M052",
      "Abitanti": 25758,
      "Link": "http://www.comuni-italiani.it/108/050/"
    },
    {
      "Istat": "015242",
      "Comune": "Vimodrone",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20090,
      "CodFisco": "M053",
      "Abitanti": 16612,
      "Link": "http://www.comuni-italiani.it/015/242/"
    },
    {
      "Istat": "004248",
      "Comune": "Vinadio",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12010,
      "CodFisco": "M055",
      "Abitanti": 701,
      "Link": "http://www.comuni-italiani.it/004/248/"
    },
    {
      "Istat": "070084",
      "Comune": "Vinchiaturo",
      "Provincia": "CB",
      "Regione": "MOL",
      "Prefisso": "0874",
      "CAP": 86019,
      "CodFisco": "M057",
      "Abitanti": 3185,
      "Link": "http://www.comuni-italiani.it/070/084/"
    },
    {
      "Istat": "005120",
      "Comune": "Vinchio",
      "Provincia": "AT",
      "Regione": "PIE",
      "Prefisso": "0141",
      "CAP": 14040,
      "CodFisco": "M058",
      "Abitanti": 667,
      "Link": "http://www.comuni-italiani.it/005/120/"
    },
    {
      "Istat": "048050",
      "Comune": "Vinci",
      "Provincia": "FI",
      "Regione": "TOS",
      "Prefisso": "0571",
      "CAP": 50059,
      "CodFisco": "M059",
      "Abitanti": 14582,
      "Link": "http://www.comuni-italiani.it/048/050/"
    },
    {
      "Istat": "001309",
      "Comune": "Vinovo",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10048,
      "CodFisco": "M060",
      "Abitanti": 14200,
      "Link": "http://www.comuni-italiani.it/001/309/"
    },
    {
      "Istat": "003164",
      "Comune": "Vinzaglio",
      "Provincia": "NO",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 28060,
      "CodFisco": "M062",
      "Abitanti": 591,
      "Link": "http://www.comuni-italiani.it/003/164/"
    },
    {
      "Istat": "004249",
      "Comune": "Viola",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0174",
      "CAP": 12070,
      "CodFisco": "M063",
      "Abitanti": 429,
      "Link": "http://www.comuni-italiani.it/004/249/"
    },
    {
      "Istat": "017202",
      "Comune": "Vione",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0364",
      "CAP": 25050,
      "CodFisco": "M065",
      "Abitanti": 729,
      "Link": "http://www.comuni-italiani.it/017/202/"
    },
    {
      "Istat": "021115",
      "Comune": "Vipiteno",
      "Provincia": "BZ",
      "Regione": "TAA",
      "Prefisso": "0472",
      "CAP": 39049,
      "CodFisco": "M067",
      "Abitanti": 6419,
      "Link": "http://www.comuni-italiani.it/021/115/"
    },
    {
      "Istat": "020069",
      "Comune": "Virgilio",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46030,
      "CodFisco": "H123",
      "Abitanti": 11293,
      "Link": "http://www.comuni-italiani.it/020/069/"
    },
    {
      "Istat": "001310",
      "Comune": "Virle Piemonte",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10060,
      "CodFisco": "M069",
      "Abitanti": 1212,
      "Link": "http://www.comuni-italiani.it/001/310/"
    },
    {
      "Istat": "017203",
      "Comune": "Visano",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25010,
      "CodFisco": "M070",
      "Abitanti": 1953,
      "Link": "http://www.comuni-italiani.it/017/203/"
    },
    {
      "Istat": "001311",
      "Comune": "Vische",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10030,
      "CodFisco": "M071",
      "Abitanti": 1342,
      "Link": "http://www.comuni-italiani.it/001/311/"
    },
    {
      "Istat": "063088",
      "Comune": "Visciano",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80030,
      "CodFisco": "M072",
      "Abitanti": 4559,
      "Link": "http://www.comuni-italiani.it/063/088/"
    },
    {
      "Istat": "030135",
      "Comune": "Visco",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0432",
      "CAP": 33040,
      "CodFisco": "M073",
      "Abitanti": 786,
      "Link": "http://www.comuni-italiani.it/030/135/"
    },
    {
      "Istat": "006187",
      "Comune": "Visone",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0144",
      "CAP": 15010,
      "CodFisco": "M077",
      "Abitanti": 1285,
      "Link": "http://www.comuni-italiani.it/006/187/"
    },
    {
      "Istat": "043057",
      "Comune": "Visso",
      "Provincia": "MC",
      "Regione": "MAR",
      "Prefisso": "0737",
      "CAP": 62039,
      "CodFisco": "M078",
      "Abitanti": 1229,
      "Link": "http://www.comuni-italiani.it/043/057/"
    },
    {
      "Istat": "018181",
      "Comune": "Vistarino",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "M079",
      "Abitanti": 1569,
      "Link": "http://www.comuni-italiani.it/018/181/"
    },
    {
      "Istat": "001312",
      "Comune": "Vistrorio",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0125",
      "CAP": 10080,
      "CodFisco": "M080",
      "Abitanti": 547,
      "Link": "http://www.comuni-italiani.it/001/312/"
    },
    {
      "Istat": "081023",
      "Comune": "Vita",
      "Provincia": "TP",
      "Regione": "SIC",
      "Prefisso": "0924",
      "CAP": 91010,
      "CodFisco": "M081",
      "Abitanti": 2169,
      "Link": "http://www.comuni-italiani.it/081/023/"
    },
    {
      "Istat": "056059",
      "Comune": "Viterbo",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01100",
      "CodFisco": "M082",
      "Abitanti": 63597,
      "Link": "http://www.comuni-italiani.it/056/059/"
    },
    {
      "Istat": "060091",
      "Comune": "Viticuso",
      "Provincia": "FR",
      "Regione": "LAZ",
      "Prefisso": "0776",
      "CAP": "03040",
      "CodFisco": "M083",
      "Abitanti": 388,
      "Link": "http://www.comuni-italiani.it/060/091/"
    },
    {
      "Istat": "093049",
      "Comune": "Vito d'Asio",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33090,
      "CodFisco": "M085",
      "Abitanti": 843,
      "Link": "http://www.comuni-italiani.it/093/049/"
    },
    {
      "Istat": "056060",
      "Comune": "Vitorchiano",
      "Provincia": "VT",
      "Regione": "LAZ",
      "Prefisso": "0761",
      "CAP": "01030",
      "CodFisco": "M086",
      "Abitanti": 4844,
      "Link": "http://www.comuni-italiani.it/056/060/"
    },
    {
      "Istat": "088012",
      "Comune": "Vittoria",
      "Provincia": "RG",
      "Regione": "SIC",
      "Prefisso": "0932",
      "CAP": 97019,
      "CodFisco": "M088",
      "Abitanti": 63332,
      "Link": "http://www.comuni-italiani.it/088/012/"
    },
    {
      "Istat": "026092",
      "Comune": "Vittorio Veneto",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0438",
      "CAP": 31029,
      "CodFisco": "M089",
      "Abitanti": 28964,
      "Link": "http://www.comuni-italiani.it/026/092/"
    },
    {
      "Istat": "066108",
      "Comune": "Vittorito",
      "Provincia": "AQ",
      "Regione": "ABR",
      "Prefisso": "0864",
      "CAP": 67030,
      "CodFisco": "M090",
      "Abitanti": 916,
      "Link": "http://www.comuni-italiani.it/066/108/"
    },
    {
      "Istat": "015243",
      "Comune": "Vittuone",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20010,
      "CodFisco": "M091",
      "Abitanti": 9082,
      "Link": "http://www.comuni-italiani.it/015/243/"
    },
    {
      "Istat": "062077",
      "Comune": "Vitulano",
      "Provincia": "BN",
      "Regione": "CAM",
      "Prefisso": "0824",
      "CAP": 82038,
      "CodFisco": "M093",
      "Abitanti": 3006,
      "Link": "http://www.comuni-italiani.it/062/077/"
    },
    {
      "Istat": "061100",
      "Comune": "Vitulazio",
      "Provincia": "CE",
      "Regione": "CAM",
      "Prefisso": "0823",
      "CAP": 81041,
      "CodFisco": "M092",
      "Abitanti": 6984,
      "Link": "http://www.comuni-italiani.it/061/100/"
    },
    {
      "Istat": "001313",
      "Comune": "Vi˘",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "0123",
      "CAP": 10070,
      "CodFisco": "M094",
      "Abitanti": 1141,
      "Link": "http://www.comuni-italiani.it/001/313/"
    },
    {
      "Istat": "093050",
      "Comune": "Vivaro",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0427",
      "CAP": 33099,
      "CodFisco": "M096",
      "Abitanti": 1388,
      "Link": "http://www.comuni-italiani.it/093/050/"
    },
    {
      "Istat": "058113",
      "Comune": "Vivaro Romano",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "0774",
      "CAP": "00020",
      "CodFisco": "M095",
      "Abitanti": 192,
      "Link": "http://www.comuni-italiani.it/058/113/"
    },
    {
      "Istat": "096080",
      "Comune": "Viverone",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "0161",
      "CAP": 13886,
      "CodFisco": "M098",
      "Abitanti": 1435,
      "Link": "http://www.comuni-italiani.it/096/080/"
    },
    {
      "Istat": "087054",
      "Comune": "Vizzini",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "0933",
      "CAP": 95049,
      "CodFisco": "M100",
      "Abitanti": 6656,
      "Link": "http://www.comuni-italiani.it/087/054/"
    },
    {
      "Istat": "012140",
      "Comune": "Vizzola Ticino",
      "Provincia": "VA",
      "Regione": "LOM",
      "Prefisso": "0331",
      "CAP": 21010,
      "CodFisco": "M101",
      "Abitanti": 586,
      "Link": "http://www.comuni-italiani.it/012/140/"
    },
    {
      "Istat": "015244",
      "Comune": "Vizzolo Predabissi",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20070,
      "CodFisco": "M102",
      "Abitanti": 3979,
      "Link": "http://www.comuni-italiani.it/015/244/"
    },
    {
      "Istat": "028105",
      "Comune": "Vo'",
      "Provincia": "PD",
      "Regione": "VEN",
      "Prefisso": "049",
      "CAP": 35030,
      "CodFisco": "M103",
      "Abitanti": 3396,
      "Link": "http://www.comuni-italiani.it/028/105/"
    },
    {
      "Istat": "017204",
      "Comune": "Vobarno",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "0365",
      "CAP": 25079,
      "CodFisco": "M104",
      "Abitanti": 8259,
      "Link": "http://www.comuni-italiani.it/017/204/"
    },
    {
      "Istat": "010066",
      "Comune": "Vobbia",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "010",
      "CAP": 16010,
      "CodFisco": "M105",
      "Abitanti": 470,
      "Link": "http://www.comuni-italiani.it/010/066/"
    },
    {
      "Istat": "002166",
      "Comune": "Vocca",
      "Provincia": "VC",
      "Regione": "PIE",
      "Prefisso": "0163",
      "CAP": 13020,
      "CodFisco": "M106",
      "Abitanti": 172,
      "Link": "http://www.comuni-italiani.it/002/166/"
    },
    {
      "Istat": "025066",
      "Comune": "Vodo Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0435",
      "CAP": 32040,
      "CodFisco": "M108",
      "Abitanti": 922,
      "Link": "http://www.comuni-italiani.it/025/066/"
    },
    {
      "Istat": "018182",
      "Comune": "Voghera",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27058,
      "CodFisco": "M109",
      "Abitanti": 39937,
      "Link": "http://www.comuni-italiani.it/018/182/"
    },
    {
      "Istat": "038023",
      "Comune": "Voghiera",
      "Provincia": "FE",
      "Regione": "EMR",
      "Prefisso": "0532",
      "CAP": 44019,
      "CodFisco": "M110",
      "Abitanti": 3918,
      "Link": "http://www.comuni-italiani.it/038/023/"
    },
    {
      "Istat": 103077,
      "Comune": "Vogogna",
      "Provincia": "VB",
      "Regione": "PIE",
      "Prefisso": "0324",
      "CAP": 28805,
      "CodFisco": "M111",
      "Abitanti": 1785,
      "Link": "http://www.comuni-italiani.it/103/077/"
    },
    {
      "Istat": "022224",
      "Comune": "Volano",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0464",
      "CAP": 38060,
      "CodFisco": "M113",
      "Abitanti": 3177,
      "Link": "http://www.comuni-italiani.it/022/224/"
    },
    {
      "Istat": "063089",
      "Comune": "Volla",
      "Provincia": "NA",
      "Regione": "CAM",
      "Prefisso": "081",
      "CAP": 80040,
      "CodFisco": "M115",
      "Abitanti": 23312,
      "Link": "http://www.comuni-italiani.it/063/089/"
    },
    {
      "Istat": "019114",
      "Comune": "Volongo",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0372",
      "CAP": 26030,
      "CodFisco": "M116",
      "Abitanti": 571,
      "Link": "http://www.comuni-italiani.it/019/114/"
    },
    {
      "Istat": "026093",
      "Comune": "Volpago del Montello",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0423",
      "CAP": 31040,
      "CodFisco": "M118",
      "Abitanti": 10024,
      "Link": "http://www.comuni-italiani.it/026/093/"
    },
    {
      "Istat": "018183",
      "Comune": "Volpara",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27047,
      "CodFisco": "M119",
      "Abitanti": 129,
      "Link": "http://www.comuni-italiani.it/018/183/"
    },
    {
      "Istat": "006188",
      "Comune": "Volpedo",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15059,
      "CodFisco": "M120",
      "Abitanti": 1261,
      "Link": "http://www.comuni-italiani.it/006/188/"
    },
    {
      "Istat": "006189",
      "Comune": "Volpeglino",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "0131",
      "CAP": 15050,
      "CodFisco": "M121",
      "Abitanti": 172,
      "Link": "http://www.comuni-italiani.it/006/189/"
    },
    {
      "Istat": "001314",
      "Comune": "Volpiano",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10088,
      "CodFisco": "M122",
      "Abitanti": 15097,
      "Link": "http://www.comuni-italiani.it/001/314/"
    },
    {
      "Istat": "020070",
      "Comune": "Volta Mantovana",
      "Provincia": "MN",
      "Regione": "LOM",
      "Prefisso": "0376",
      "CAP": 46049,
      "CodFisco": "M125",
      "Abitanti": 7377,
      "Link": "http://www.comuni-italiani.it/020/070/"
    },
    {
      "Istat": "006190",
      "Comune": "Voltaggio",
      "Provincia": "AL",
      "Regione": "PIE",
      "Prefisso": "010",
      "CAP": 15060,
      "CodFisco": "M123",
      "Abitanti": 776,
      "Link": "http://www.comuni-italiani.it/006/190/"
    },
    {
      "Istat": "025067",
      "Comune": "Voltago Agordino",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32020,
      "CodFisco": "M124",
      "Abitanti": 928,
      "Link": "http://www.comuni-italiani.it/025/067/"
    },
    {
      "Istat": "050039",
      "Comune": "Volterra",
      "Provincia": "PI",
      "Regione": "TOS",
      "Prefisso": "0588",
      "CAP": 56048,
      "CodFisco": "M126",
      "Abitanti": 11077,
      "Link": "http://www.comuni-italiani.it/050/039/"
    },
    {
      "Istat": "019115",
      "Comune": "Voltido",
      "Provincia": "CR",
      "Regione": "LOM",
      "Prefisso": "0375",
      "CAP": 26030,
      "CodFisco": "M127",
      "Abitanti": 413,
      "Link": "http://www.comuni-italiani.it/019/115/"
    },
    {
      "Istat": "071061",
      "Comune": "Volturara Appula",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71030,
      "CodFisco": "M131",
      "Abitanti": 496,
      "Link": "http://www.comuni-italiani.it/071/061/"
    },
    {
      "Istat": "064119",
      "Comune": "Volturara Irpina",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83050,
      "CodFisco": "M130",
      "Abitanti": 4103,
      "Link": "http://www.comuni-italiani.it/064/119/"
    },
    {
      "Istat": "071062",
      "Comune": "Volturino",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0881",
      "CAP": 71030,
      "CodFisco": "M132",
      "Abitanti": 1800,
      "Link": "http://www.comuni-italiani.it/071/062/"
    },
    {
      "Istat": "001315",
      "Comune": "Volvera",
      "Provincia": "TO",
      "Regione": "PIE",
      "Prefisso": "011",
      "CAP": 10040,
      "CodFisco": "M133",
      "Abitanti": 8707,
      "Link": "http://www.comuni-italiani.it/001/315/"
    },
    {
      "Istat": "004250",
      "Comune": "Vottignasco",
      "Provincia": "CN",
      "Regione": "PIE",
      "Prefisso": "0171",
      "CAP": 12020,
      "CodFisco": "M136",
      "Abitanti": 549,
      "Link": "http://www.comuni-italiani.it/004/250/"
    },
    {
      "Istat": 102048,
      "Comune": "Zaccanopoli",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89867,
      "CodFisco": "M138",
      "Abitanti": 810,
      "Link": "http://www.comuni-italiani.it/102/048/"
    },
    {
      "Istat": "087055",
      "Comune": "Zafferana Etnea",
      "Provincia": "CT",
      "Regione": "SIC",
      "Prefisso": "095",
      "CAP": 95019,
      "CodFisco": "M139",
      "Abitanti": 9376,
      "Link": "http://www.comuni-italiani.it/087/055/"
    },
    {
      "Istat": "079157",
      "Comune": "Zagarise",
      "Provincia": "CZ",
      "Regione": "CAL",
      "Prefisso": "0961",
      "CAP": 88050,
      "CodFisco": "M140",
      "Abitanti": 1760,
      "Link": "http://www.comuni-italiani.it/079/157/"
    },
    {
      "Istat": "058114",
      "Comune": "Zagarolo",
      "Provincia": "RM",
      "Regione": "LAZ",
      "Prefisso": "06",
      "CAP": "00039",
      "CodFisco": "M141",
      "Abitanti": 17628,
      "Link": "http://www.comuni-italiani.it/058/114/"
    },
    {
      "Istat": "022225",
      "Comune": "Zambana",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0461",
      "CAP": 38010,
      "CodFisco": "M142",
      "Abitanti": 1677,
      "Link": "http://www.comuni-italiani.it/022/225/"
    },
    {
      "Istat": 102049,
      "Comune": "Zambrone",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89868,
      "CodFisco": "M143",
      "Abitanti": 1829,
      "Link": "http://www.comuni-italiani.it/102/049/"
    },
    {
      "Istat": "016244",
      "Comune": "Zandobbio",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24060,
      "CodFisco": "M144",
      "Abitanti": 2746,
      "Link": "http://www.comuni-italiani.it/016/244/"
    },
    {
      "Istat": "024119",
      "Comune": "ZanË",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36010,
      "CodFisco": "M145",
      "Abitanti": 6599,
      "Link": "http://www.comuni-italiani.it/024/119/"
    },
    {
      "Istat": "016245",
      "Comune": "Zanica",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "035",
      "CAP": 24050,
      "CodFisco": "M147",
      "Abitanti": 8190,
      "Link": "http://www.comuni-italiani.it/016/245/"
    },
    {
      "Istat": "071064",
      "Comune": "Zapponeta",
      "Provincia": "FG",
      "Regione": "PUG",
      "Prefisso": "0884",
      "CAP": 71030,
      "CodFisco": "M267",
      "Abitanti": 3465,
      "Link": "http://www.comuni-italiani.it/071/064/"
    },
    {
      "Istat": "018184",
      "Comune": "Zavattarello",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0383",
      "CAP": 27059,
      "CodFisco": "M150",
      "Abitanti": 1052,
      "Link": "http://www.comuni-italiani.it/018/184/"
    },
    {
      "Istat": "018185",
      "Comune": "Zeccone",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27010,
      "CodFisco": "M152",
      "Abitanti": 1694,
      "Link": "http://www.comuni-italiani.it/018/185/"
    },
    {
      "Istat": "095074",
      "Comune": "Zeddiani",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "M153",
      "Abitanti": 1176,
      "Link": "http://www.comuni-italiani.it/095/074/"
    },
    {
      "Istat": "013246",
      "Comune": "Zelbio",
      "Provincia": "CO",
      "Regione": "LOM",
      "Prefisso": "031",
      "CAP": 22020,
      "CodFisco": "M156",
      "Abitanti": 218,
      "Link": "http://www.comuni-italiani.it/013/246/"
    },
    {
      "Istat": "098061",
      "Comune": "Zelo Buon Persico",
      "Provincia": "LO",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 26839,
      "CodFisco": "M158",
      "Abitanti": 6860,
      "Link": "http://www.comuni-italiani.it/098/061/"
    },
    {
      "Istat": "015246",
      "Comune": "Zelo Surrigone",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "M160",
      "Abitanti": 1377,
      "Link": "http://www.comuni-italiani.it/015/246/"
    },
    {
      "Istat": "018186",
      "Comune": "Zeme",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0384",
      "CAP": 27030,
      "CodFisco": "M161",
      "Abitanti": 1134,
      "Link": "http://www.comuni-italiani.it/018/186/"
    },
    {
      "Istat": "018187",
      "Comune": "Zenevredo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0385",
      "CAP": 27049,
      "CodFisco": "M162",
      "Abitanti": 483,
      "Link": "http://www.comuni-italiani.it/018/187/"
    },
    {
      "Istat": "026094",
      "Comune": "Zenson di Piave",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0421",
      "CAP": 31050,
      "CodFisco": "M163",
      "Abitanti": 1825,
      "Link": "http://www.comuni-italiani.it/026/094/"
    },
    {
      "Istat": "033047",
      "Comune": "Zerba",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29020,
      "CodFisco": "M165",
      "Abitanti": 94,
      "Link": "http://www.comuni-italiani.it/033/047/"
    },
    {
      "Istat": "018188",
      "Comune": "Zerbo",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27017,
      "CodFisco": "M166",
      "Abitanti": 444,
      "Link": "http://www.comuni-italiani.it/018/188/"
    },
    {
      "Istat": "018189",
      "Comune": "ZerbolÚ",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27020,
      "CodFisco": "M167",
      "Abitanti": 1642,
      "Link": "http://www.comuni-italiani.it/018/189/"
    },
    {
      "Istat": "095075",
      "Comune": "Zerfaliu",
      "Provincia": "OR",
      "Regione": "SAR",
      "Prefisso": "0783",
      "CAP": "09070",
      "CodFisco": "M168",
      "Abitanti": 1181,
      "Link": "http://www.comuni-italiani.it/095/075/"
    },
    {
      "Istat": "045017",
      "Comune": "Zeri",
      "Provincia": "MS",
      "Regione": "TOS",
      "Prefisso": "0187",
      "CAP": 54029,
      "CodFisco": "M169",
      "Abitanti": 1217,
      "Link": "http://www.comuni-italiani.it/045/017/"
    },
    {
      "Istat": "024120",
      "Comune": "Zermeghedo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36050,
      "CodFisco": "M170",
      "Abitanti": 1380,
      "Link": "http://www.comuni-italiani.it/024/120/"
    },
    {
      "Istat": "026095",
      "Comune": "Zero Branco",
      "Provincia": "TV",
      "Regione": "VEN",
      "Prefisso": "0422",
      "CAP": 31059,
      "CodFisco": "M171",
      "Abitanti": 10977,
      "Link": "http://www.comuni-italiani.it/026/095/"
    },
    {
      "Istat": "023097",
      "Comune": "Zevio",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "045",
      "CAP": 37059,
      "CodFisco": "M172",
      "Abitanti": 14522,
      "Link": "http://www.comuni-italiani.it/023/097/"
    },
    {
      "Istat": "022226",
      "Comune": "Ziano di Fiemme",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0462",
      "CAP": 38030,
      "CodFisco": "M173",
      "Abitanti": 1659,
      "Link": "http://www.comuni-italiani.it/022/226/"
    },
    {
      "Istat": "033048",
      "Comune": "Ziano Piacentino",
      "Provincia": "PC",
      "Regione": "EMR",
      "Prefisso": "0523",
      "CAP": 29010,
      "CodFisco": "L848",
      "Abitanti": 2671,
      "Link": "http://www.comuni-italiani.it/033/048/"
    },
    {
      "Istat": "034048",
      "Comune": "Zibello",
      "Provincia": "PR",
      "Regione": "EMR",
      "Prefisso": "0524",
      "CAP": 43010,
      "CodFisco": "M174",
      "Abitanti": 1863,
      "Link": "http://www.comuni-italiani.it/034/048/"
    },
    {
      "Istat": "015247",
      "Comune": "Zibido San Giacomo",
      "Provincia": "MI",
      "Regione": "LOM",
      "Prefisso": "02",
      "CAP": 20080,
      "CodFisco": "M176",
      "Abitanti": 6734,
      "Link": "http://www.comuni-italiani.it/015/247/"
    },
    {
      "Istat": "011032",
      "Comune": "Zignago",
      "Provincia": "SP",
      "Regione": "LIG",
      "Prefisso": "0187",
      "CAP": 19020,
      "CodFisco": "M177",
      "Abitanti": 544,
      "Link": "http://www.comuni-italiani.it/011/032/"
    },
    {
      "Istat": "023098",
      "Comune": "Zimella",
      "Provincia": "VR",
      "Regione": "VEN",
      "Prefisso": "0442",
      "CAP": 37040,
      "CodFisco": "M178",
      "Abitanti": 4931,
      "Link": "http://www.comuni-italiani.it/023/098/"
    },
    {
      "Istat": "096081",
      "Comune": "Zimone",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13887,
      "CodFisco": "M179",
      "Abitanti": 425,
      "Link": "http://www.comuni-italiani.it/096/081/"
    },
    {
      "Istat": "018190",
      "Comune": "Zinasco",
      "Provincia": "PV",
      "Regione": "LOM",
      "Prefisso": "0382",
      "CAP": 27030,
      "CodFisco": "M180",
      "Abitanti": 3264,
      "Link": "http://www.comuni-italiani.it/018/190/"
    },
    {
      "Istat": "010067",
      "Comune": "Zoagli",
      "Provincia": "GE",
      "Regione": "LIG",
      "Prefisso": "0185",
      "CAP": 16030,
      "CodFisco": "M182",
      "Abitanti": 2592,
      "Link": "http://www.comuni-italiani.it/010/067/"
    },
    {
      "Istat": "036047",
      "Comune": "Zocca",
      "Provincia": "MO",
      "Regione": "EMR",
      "Prefisso": "059",
      "CAP": 41059,
      "CodFisco": "M183",
      "Abitanti": 5024,
      "Link": "http://www.comuni-italiani.it/036/047/"
    },
    {
      "Istat": "016246",
      "Comune": "Zogno",
      "Provincia": "BG",
      "Regione": "LOM",
      "Prefisso": "0345",
      "CAP": 24019,
      "CodFisco": "M184",
      "Abitanti": 9097,
      "Link": "http://www.comuni-italiani.it/016/246/"
    },
    {
      "Istat": "037060",
      "Comune": "Zola Predosa",
      "Provincia": "BO",
      "Regione": "EMR",
      "Prefisso": "051",
      "CAP": 40069,
      "CodFisco": "M185",
      "Abitanti": 18314,
      "Link": "http://www.comuni-italiani.it/037/060/"
    },
    {
      "Istat": "025068",
      "Comune": "Zoldo Alto",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "I345",
      "Abitanti": 1034,
      "Link": "http://www.comuni-italiani.it/025/068/"
    },
    {
      "Istat": "075094",
      "Comune": "Zollino",
      "Provincia": "LE",
      "Regione": "PUG",
      "Prefisso": "0836",
      "CAP": 73010,
      "CodFisco": "M187",
      "Abitanti": 2072,
      "Link": "http://www.comuni-italiani.it/075/094/"
    },
    {
      "Istat": "017205",
      "Comune": "Zone",
      "Provincia": "BS",
      "Regione": "LOM",
      "Prefisso": "030",
      "CAP": 25050,
      "CodFisco": "M188",
      "Abitanti": 1110,
      "Link": "http://www.comuni-italiani.it/017/205/"
    },
    {
      "Istat": "025069",
      "Comune": "ZoppË di Cadore",
      "Provincia": "BL",
      "Regione": "VEN",
      "Prefisso": "0437",
      "CAP": 32010,
      "CodFisco": "M189",
      "Abitanti": 271,
      "Link": "http://www.comuni-italiani.it/025/069/"
    },
    {
      "Istat": "093051",
      "Comune": "Zoppola",
      "Provincia": "PN",
      "Regione": "FVG",
      "Prefisso": "0434",
      "CAP": 33080,
      "CodFisco": "M190",
      "Abitanti": 8565,
      "Link": "http://www.comuni-italiani.it/093/051/"
    },
    {
      "Istat": "024121",
      "Comune": "Zovencedo",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0444",
      "CAP": 36020,
      "CodFisco": "M194",
      "Abitanti": 814,
      "Link": "http://www.comuni-italiani.it/024/121/"
    },
    {
      "Istat": "096082",
      "Comune": "Zubiena",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13888,
      "CodFisco": "M196",
      "Abitanti": 1257,
      "Link": "http://www.comuni-italiani.it/096/082/"
    },
    {
      "Istat": "009069",
      "Comune": "Zuccarello",
      "Provincia": "SV",
      "Regione": "LIG",
      "Prefisso": "0182",
      "CAP": 17039,
      "CodFisco": "M197",
      "Abitanti": 339,
      "Link": "http://www.comuni-italiani.it/009/069/"
    },
    {
      "Istat": "022227",
      "Comune": "Zuclo",
      "Provincia": "TN",
      "Regione": "TAA",
      "Prefisso": "0465",
      "CAP": 38079,
      "CodFisco": "M198",
      "Abitanti": 347,
      "Link": "http://www.comuni-italiani.it/022/227/"
    },
    {
      "Istat": "024122",
      "Comune": "Zugliano",
      "Provincia": "VI",
      "Regione": "VEN",
      "Prefisso": "0445",
      "CAP": 36030,
      "CodFisco": "M199",
      "Abitanti": 6753,
      "Link": "http://www.comuni-italiani.it/024/122/"
    },
    {
      "Istat": "030136",
      "Comune": "Zuglio",
      "Provincia": "UD",
      "Regione": "FVG",
      "Prefisso": "0433",
      "CAP": 33020,
      "CodFisco": "M200",
      "Abitanti": 607,
      "Link": "http://www.comuni-italiani.it/030/136/"
    },
    {
      "Istat": "096083",
      "Comune": "Zumaglia",
      "Provincia": "BI",
      "Regione": "PIE",
      "Prefisso": "015",
      "CAP": 13848,
      "CodFisco": "M201",
      "Abitanti": 1152,
      "Link": "http://www.comuni-italiani.it/096/083/"
    },
    {
      "Istat": "078155",
      "Comune": "Zumpano",
      "Provincia": "CS",
      "Regione": "CAL",
      "Prefisso": "0984",
      "CAP": 87040,
      "CodFisco": "M202",
      "Abitanti": 2413,
      "Link": "http://www.comuni-italiani.it/078/155/"
    },
    {
      "Istat": "064120",
      "Comune": "Zungoli",
      "Provincia": "AV",
      "Regione": "CAM",
      "Prefisso": "0825",
      "CAP": 83030,
      "CodFisco": "M203",
      "Abitanti": 1232,
      "Link": "http://www.comuni-italiani.it/064/120/"
    },
    {
      "Istat": 102050,
      "Comune": "Zungri",
      "Provincia": "VV",
      "Regione": "CAL",
      "Prefisso": "0963",
      "CAP": 89867,
      "CodFisco": "M204",
      "Abitanti": 2055,
      "Link": "http://www.comuni-italiani.it/102/050/"
    }
  ];
export default ComuniItalianiJson;