import React from "react";
import {navigate, A} from 'hookrouter'
import {useSelector} from 'react-redux'
import Intestazione from "./Intestazione";

export default function Toolbar(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    return(
            <nav className="navbar sticky-top navbar-expand-lg navbar-light " style={{backgroundColor: "#e3f2fd"}}>
                <Intestazione />
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="true" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mr-auto">
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CLIENTI')>=0))&& 
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='ricanag'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-anag/',true)}>Ric. Anagrafica</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CALENDARIOEPRENOTAZIONI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='calendari'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-prods/')}>Calendari</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CASSA')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='cassa'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-cassa/',true)}>Cassa</a>
                    </li>}
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('REPORT')>=0))&&
                        <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='report'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-dashboard/',true)}>Report e Stat.</a>
                    </li>
                    }
                {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('SCHEDESALAATTREZZI')>=0))&&
                    <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#appadminannotatio" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Schede
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li><a className={"nav-link "+(props.viewselected==='schedeSA'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-sa/')}>Sala Attrezzi</a></li>
                        <li><a className={"nav-link "+(props.viewselected==='schedeCC'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-cc/')}>Composizione Corporea e Diario Alimentare</a></li>
                        </ul>
                    </li>}
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('CONTROLLOACCESSI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='accessi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-accessi/')}>Controllo Accessi</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('ARCHIVI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-arch/')}>Archivi</a>
                    </li>
                    }
                    {palestra&&(palestra.amministratore||(palestra.funzionalita&&palestra.funzionalita.indexOf('COLLABORATORI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-collab/')}>Collaboratori</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('TESSERAMENTI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#" onClick={()=> navigate('/annotatio-tess/')}>Tesseramenti</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('FATTURE')>=0))&&
                    <li className="nav-item dropdown ms-auto">
                         <A className="nav-link dropdown-toggle" href="/annotatio-fatt/" id="navbarfattDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                            Fatture
                        </A>
                        <ul className="dropdown-menu">
                            <li><A className={"nav-link"} href="/annotatio-ric-fatt/" >Ricerca</A></li>
                            <li><A className={"nav-link"} href="/annotatio-ins-fatt/">Inserimento</A></li>
                        </ul>
                    </li>
                   
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('ALIMENTI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-alimenti/',true)}>Gestione Alimenti</a>
                    </li>
                    }
                    {palestra&&((palestra.funzionalita&&palestra.funzionalita.indexOf('DOCUMENTI')>=0))&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-doc/',true)}>Gestione Template Documenti</a>
                    </li>
                    }
                    {palestra&&palestra.amministratore&&
                    <li className="nav-item">
                        <a className={"nav-link "+(props.viewselected==='archivi'?"active":"")} href="#appadminannotatio" onClick={()=> navigate('/annotatio-dett-pal/',true)}>Dettaglio Attivita' commerciale</a>
                    </li>
                    }
                    
                    
                    </ul>
                </div>
                <a className="navbar-brand" href="#appadminannotatio" onClick={()=> navigate('/annotatio/',true)}>
                    <img src="https://annotatio.online/wp-content/uploads/2022/09/annotatioA512__1_-removebg-preview-1.png" width="30" height="30" alt="Annotatio" />nnotatio
                </a>
            </nav>
    );

}