import React from "react";
import {  Button,  Form, Col, InputGroup } from 'react-bootstrap';
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import { connect } from "react-redux";

class AbbonamentoUtente extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
            id:null,
            dataOperazione: null,
            prezzo: 500,
            prezzoIscrizione: 0,
            validoDa: "",
            validoA: "",
            dataScadenzaPagamento: "",
            idlistino: "",
            palestra: 0,
            fatture:[]
      };
      this.update=this.update.bind(this)
      this.aggiornaIscrizione=this.aggiornaIscrizione.bind(this)
    }

    componentDidMount(){
        this.setState({...this.props.iscrizione})
    }

    update(e) {
      if (e.target.name==='istesserato'){
        if (e.target.checked) {
            this.setState({ [e.target.name]: 1, visible: true });
        }
        else{
            this.setState({ [e.target.name]: 0, visible: true });
        }
      }
      else{
          this.setState({ [e.target.name]: e.target.value, visible: true });
      }
    }
    
    aggiornaIscrizione(){
        const { id,dataiscrizione,datascadenza,prezzo,datavaliditacertificatomedico,note} = this.state;
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('id', id);
	    formData.append('dataiscrizione',dataiscrizione);
		formData.append('datascadenza', datascadenza);
		formData.append('prezzo', prezzo);
		formData.append('datavaliditacertificatomedico', datavaliditacertificatomedico);
		formData.append('note', note);
    formData.append('numerotessera', this.state.numerotessera?this.state.numerotessera:'');
    formData.append('istesserato', this.state.istesserato?this.state.istesserato:0);
    formData.append('datatesserada', this.state.datatesserada?this.state.datatesserada:'');
    formData.append('datatesseraa', this.state.datatesseraa?this.state.datatesseraa:'');

      axios.post(global.config.server+'/salvaIscrizione', 
      		formData
      )
        .then((result) => {
        this.setState({messaggio:result.data});
		const Toast = Swal.mixin({
			  toast: true,
			  position: 'top-end',
			  showConfirmButton: false,
			  timer: 3000
			});
			Toast.fire({
			  type: 'success',
			  title: result.data
			})
          this.setState({ visible: false });
          this.props.refresh();
        }).catch(err => {
          console.log(err);
          if (err.response&&err.response.data){
			      alert(err.response.data.message);
          }
		});
	}
  
    componentDidCatch(error, info) {
          // You can also log the error to an error reporting service
          console.log(error, info);
        }
  
    render() {
      return (
            <Form>
              <Form.Row className="align-items-center">
                <Col>
                  <Form.Label htmlFor="inlineFormInput" srOnly>
                  Iscrizione valida da:
                  </Form.Label>
                  <Form.Control size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Data inizio iscrizione"
                    value={this.state.dataiscrizione}
                    name="dataiscrizione"
                    type="date"
                    onChange={this.update}
                  />
                </Col>
                <Col>
                  <Form.Label htmlFor="inlineFormInput" srOnly>
                  valida a:
                  </Form.Label>
                  <Form.Control size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Data scadenza"
                    value={this.state.datascadenza}
                    name="datascadenza"
                    type="date"
                    onChange={this.update}
                  />
                </Col>
                <Col>
                  <Form.Label htmlFor="inlineFormInput" srOnly>
                  Prezzo
                  </Form.Label>
                  <InputGroup className="mb-2 " size="sm">
                  <Form.Control
                    id="inlineFormInput"
                    placeholder="Prezzo"
                    value={this.state.prezzo}
                    name="prezzo"
                    type="number"
                    onChange={this.update}
                  />
                    <InputGroup.Prepend>
                      <InputGroup.Text>€</InputGroup.Text>
                    </InputGroup.Prepend>
                  
                  </InputGroup>
                </Col>
                <Col>
                  <Form.Label>
                  Validità Certificato medico
                  </Form.Label>
                  <Form.Control
                    className="mb-2" size="sm"
                    id="inlineFormInput"
                    placeholder="Data scadenza cert. med."
                    value={this.state.datavaliditacertificatomedico}
                    onChange={this.update}
                    type="date"
                    name="datavaliditacertificatomedico"
                  />
                </Col>
                <Col xs="auto">
                  <Form.Label htmlFor="inlineFormInput" srOnly>
                  Note
                  </Form.Label>
                  <Form.Control as="textarea" size="sm"
                    className="mb-2"
                    id="inlineFormInput"
                    placeholder="Note"
                    value={this.state.note}
                    name="note"
                    onChange={this.update}
                  />
                </Col>
                <div className="col-md-4">
                  <label>Tesserato
                    <input type="checkbox" name="istesserato" value={1} checked={this.state.istesserato===1} onChange={this.update} />
                  </label>
                </div>
                <div className="col-md-4">
                  <label>Numero tessera
                    <input type="text" name="numerotessera" value={this.state.numerotessera} onChange={this.update} />
                  </label>
                </div>
                <div className="col-md-4">
                  <label>Tessera valida DA
                    <input type="date" name="datatesserada" value={this.state.datatesserada} onChange={this.update} />
                  </label>
                </div>
                <div className="col-md-4">
                  <label>Tessera valida A
                    <input type="date" name="datatesseraa" value={this.state.datatesseraa} onChange={this.update} />
                  </label>
                </div>
                <Col xs="auto">
                <Button variant="primary" onClick={this.aggiornaIscrizione}>
                  Modifica Iscrizione
                </Button>
                </Col>
                </Form.Row>
            </Form>
      );
    }
  }

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(AbbonamentoUtente)