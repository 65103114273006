import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import { useSelector } from "react-redux";

export default function NuovaTipologiaAbbInput(props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [id,setid] = useState(0)
	const [descrizione, setdescrizione] = useState(null)
	const [tipiCorsoDTO, settipiCorsoDTO] = useState(null)
	const [numIngressiGiornalieri, setnumIngressiGiornalieri] = useState(null)
	const [numIngressiSett, setnumIngressiSett] = useState(null)
	const [numIngressiTotale, setnumIngressiTotale] = useState(null)
	const [visible, setvisible] = useState(false)
	const [messaggio, setmessaggio] = useState(null)

	useEffect(() =>{
		if (palestra&&palestra.id){
		const formData = new FormData();
		formData.append('website', palestra.website);
        formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		  axios.post(global.config.server+"/getTipologieCorsoRest", formData)
		.then(response2=>{
			settipiCorsoDTO(response2.data)
		}).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
		}
/* 		  console.log("tipiCorsoDTO:");
		  console.log(this.props.tipiCorso);
		this.setState({tipiCorsoDTO:this.props.tipiCorso}) */
	  }, [palestra]
	);

	function handleSubmit(event) {
		event.preventDefault();

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('numIngressiGiornalieri', numIngressiGiornalieri);
		formData.append('numIngressiSett', numIngressiSett);
		formData.append('numIngressiTotale', numIngressiTotale);

		if (tipiCorsoDTO){
			tipiCorsoDTO.map(tipoDTO=>{
			formData.append(tipoDTO.nome, tipoDTO[tipoDTO.nome]);
			})
		}
		formData.append('website', palestra.website);
        formData.append('email',email);
          formData.append('lic','000');
		  formData.append('palestra',palestra.id);
		  axios.post(global.config.server+'/aggiornaTipologiaAbbRest/'+id,formData)
	      .then((result) => {
			setmessaggio(result.data)
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          props.refresh();
				  setvisible(false)
	      }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
	  }

	function showNuovaTipologia(){
		setvisible(!visible)
	}
	  
		var style1={
				color:"white"
		}
		
		var style2={
				width:"80px"
		}
		
		var style3={
			backgroundColor: "#D0D0D0"
		}
		return (
			<form className="form-horizontal" method="post">
				<div className="row">
					<div className="col" style={style3}>
						<h4 onClick={showNuovaTipologia}>Nuova Tipologia</h4>
					</div>
				</div>
				{visible?
				<div className="row">
						<span>{messaggio}</span>
							<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
								<label className="control-label">Descrizione</label>
								<input className="form-control" type="text" onChange={(e) =>setdescrizione(e.target.value)} name="descrizione" value={descrizione} required="required" />
							</div>
							<div className="col">
								<label className="control-label">Ingressi Giornalieri</label>
								 <input className="form-control" type="number" onChange={(e) =>setnumIngressiGiornalieri(e.target.value)} name="numIngressiGiornalieri" value={numIngressiGiornalieri} required="required" />
							</div>
							<div className="col">
								<label className="control-label">Ingressi Sett. totali</label>
								 <input className="form-control" type="number" onChange={(e) =>setnumIngressiSett(e.target.value)} name="numIngressiSett" value={numIngressiSett} required="required" />
							</div>

							<div className="col">
								<label className="control-label">Ingressi Totali</label>
								 <input className="form-control" type="number" onChange={(e) =>setnumIngressiTotale(e.target.value)} name="numIngressiTotale" value={numIngressiTotale} required="required" />
							</div>
								<div className="col">
				  				<a type="button" className="btn btn-primary" onClick={handleSubmit} style={style1}>Salva modifiche</a>
				  				</div>
		  				</div>
			:null}
			</form>
	);
}