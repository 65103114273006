import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import { Button, Form } from "react-bootstrap";
import CorsiSelect from "../archivi/corsi/CorsiSelect";
import NuovoCorsoInput from "../archivi/corsi/NuovoCorsoInput";
import { connect } from "react-redux";

class NuovaSchedulazione extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		durata: 50,
	    		numMaxPrenotazioni: 10,
                corso:'',
                giorno:'',
                ora:null,
                lun:0,
                mar:0,
                mer:0,
                gio:0,
                ven:0,
                sab:0,
                dom:0,
				tipoPrenotazione:'Pagamento Posticipato o in loco',
				showNuovoProdotto:false,
				duratagiornaliera:0
	    	}

        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
		this.refreshCorsi=this.refreshCorsi.bind(this)
	  }

	refreshCorsi(idnuovocorso){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		axios.post(global.config.server+"/getCorsiRest", formData,
		{
			headers: {
			Accept: 'application/json',
			'Content-Type': 'multipart/form-data',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'POST',
			'Access-Control-Allow-Headers': 'Content-Type, Authorization',
			'Access-Control-Allow-Credentials': 'true',
			'Authorization' :'Bearer '+window.$t
			},
		},)
		.then(response=>{
			this.setState({listaCorsi: response.data});

			response.data.map(tipcorso =>{
				var corsotemp=tipcorso.corsi.filter((corso)=>parseInt(corso.id)===parseInt(idnuovocorso))
				var tipoPre="";
				
				if (corsotemp&&corsotemp[0]){
					if (corsotemp[0].free){
						tipoPre="Free"
					}
					else if (corsotemp[0].abb){
						tipoPre="Abbonamento"
					}
					else if (corsotemp[0].pp){
						tipoPre="Pagamento Posticipato o in loco"
					}
					else if (corsotemp[0].pa){
						tipoPre="Pagamento Anticipato"
					}
					this.setState({corso: idnuovocorso,durata:corsotemp[0].durata, numMaxPrenotazioni:corsotemp[0].utenti, tipoPrenotazione:tipoPre})
					return 0;//giusto per ritornare qualcosa perche' altrimenti dava un warning
				}
			})

			this.forceUpdate();
			console.log("refreshcorsi");
			console.log(response.data);
			
		}).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
		  });
	}

	update(e) {
		if (e.target.name==='lun'
		||e.target.name==='mar'
		||e.target.name==='mer'
		||e.target.name==='gio'
		||e.target.name==='ven'
		||e.target.name==='sab'
		||e.target.name==='dom'){
			this.setState({ [e.target.name]: e.target.checked?1:0});
		}
		else if(e.target.name==='corso'){
			this.state.listaCorsi&&this.state.listaCorsi.map(tipcorso =>{

				var corsotemp=tipcorso.corsi.filter((corso)=>parseInt(corso.id)===parseInt(e.target.value))
				var tipoPre="";
				if (corsotemp&&corsotemp[0]){
					if (corsotemp[0].free){
						tipoPre="Free"
					}
					else if (corsotemp[0].abb){
						tipoPre="Abbonamento"
					}
					else if (corsotemp[0].pp){
						tipoPre="Pagamento Posticipato o in loco"
					}
					else if (corsotemp[0].pa){
						tipoPre="Pagamento Anticipato"
					}
					this.setState({[e.target.name]: e.target.value,durata:corsotemp[0].durata, numMaxPrenotazioni:corsotemp[0].utenti, tipoPrenotazione:tipoPre})
					return 0;//giusto per ritornare qualcosa perche' altrimenti dava un warning
				}
			})
		}
		else if(e.target.name==='duratagiornaliera'){
			this.setState({ [e.target.name]: e.target.checked?1:0});
		}
		else{
		  this.setState({ [e.target.name]: e.target.value});
		}
	  }

	  componentDidMount(){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.palestra.id);
			axios.post(global.config.server+"/getCorsiRest", formData,
			{
				headers: {
				Accept: 'application/json',
				'Content-Type': 'multipart/form-data',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods': 'POST',
				'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				'Access-Control-Allow-Credentials': 'true',
				'Authorization' :'Bearer '+window.$t
				},
			},)
			.then(response=>{
				this.setState({listaCorsi: response.data, 
					data:this.props.giornoSelezionato, 
					ora:this.props.oraSelezionata,
					tipoPrenotazione:'Pagamento Posticipato o in loco'
				});
			}).catch(err => {
				if (err.response){
				alert(err.response.data.message);
				}
				else{
				alert(err.response);
				}
			});
			
	  }

      handleSubmit(event) {
		event.preventDefault();
	    const { durata,numMaxPrenotazioni,corso, ora, lun, mar, mer, gio, ven, sab, dom, finoa,finoalleore, tipoPrenotazione} = this.state;

		if (!tipoPrenotazione
			||tipoPrenotazione===''){
				alert('Tipo pagamento obbligatorio')
				return;
			}
		const formData = new FormData();
		
	    formData.append('durata', durata);
	    formData.append('numMaxPrenotazioni', numMaxPrenotazioni);
		formData.append('corso', corso);
		formData.append('data', this.props.giornoSelezionato);
        formData.append('ora', ora);
		formData.append('lun', lun);
		formData.append('mar', mar);
		formData.append('mer', mer);
		formData.append('gio', gio);
		formData.append('ven', ven);
		formData.append('sab', sab);
		formData.append('dom', dom);
		formData.append('duratagiornaliera', this.state.duratagiornaliera);
		formData.append('finoa', finoa?finoa:'');
		formData.append('finoalleore', finoalleore?finoalleore:'');
		formData.append('tipoPrenotazione', tipoPrenotazione);
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		formData.append('calendario',this.props.calendario);
		var that=this
		axios.post(global.config.server+'/salvaNuovoCalendario',formData)
	      .then((result) => {
				this.setState({messaggio:result.data})
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
					
					that.setState({ visible: false });
				  that.props.togglePopup()
				  that.props.refresh()
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
      }

	render() {
	    return (
			<div style={{backgroundColor:'green'}}>
				<h2>Nuova Schedulazione</h2>
				<a name="schedulazione" />
				<form>
						<div className="row">
							<div className="col">
								<Form.Group controlId="datapagamento">
									<Form.Label>Prodotto</Form.Label>
									<CorsiSelect onChange={this.update} corsi={this.state.listaCorsi} selected={this.state.corso} />
								</Form.Group>
							</div>
							<div className="col">
								Oppure inseriscine uno nuovo
								<NuovoCorsoInput
								email={this.props.email}
								refresh={this.refreshCorsi}
								/>
							</div>
						</div>
					<div className="row">
						<div className="col">
						<Form.Group controlId="importo">
							<Form.Label>Giorno</Form.Label>
							<Form.Control type="date" placeholder="giorno" 
							name="data" value={this.props.giornoSelezionato} required={true} onChange={this.update} />
						</Form.Group>
						</div>
						<div className="col">
						<Form.Group controlId="importo">
							<Form.Label>Ora</Form.Label>
							<Form.Control type="time" placeholder="ora" 
							name="ora" value={this.state.ora?this.state.ora:this.props.oraSelezionata} required={true} onChange={this.update} 
							min="9" max="22"/>
						</Form.Group>
						</div>
						<div className="col">
							<Form.Group controlId="importo">
								<Form.Label>Durata (minuti)</Form.Label>
								<Form.Control type="number" placeholder="Durata" 
								name="durata" value={this.state.durata} required={true} onChange={this.update} 
								min={5}/>
								<label>Tutto il giorno <input type={"checkbox"} name={"duratagiornaliera"} onChange={this.update}  /></label>
							</Form.Group>
						</div>
						<div className="col">
						<Form.Group controlId="importo">
							<Form.Label>Max prenotazioni</Form.Label>
							<Form.Control type="number" placeholder="Max Prenotazioni" 
							name="numMaxPrenotazioni" value={this.state.numMaxPrenotazioni} required={true} onChange={this.update} 
							/>
						</Form.Group>
						</div>
						<div className="col">
						<Form.Group>
							<Form.Label>Tipo pagamento</Form.Label>
							<select value={this.state.tipoPrenotazione} name="tipoPrenotazione" required={true} onChange={this.update}>
								<option value="">--tipo prenotazione--</option>
								<option value="Abbonamento">Abbonamento</option>
								<option value="Free">Free</option>
								<option value="Pagamento Anticipato">Pagamento Anticipato</option>
								<option selected={true} value="Pagamento Posticipato o in loco">Pagamento Posticipato</option>
							</select>
						</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col-4">
							<Form.Group controlId="importo">
							<Form.Label>Ripeti questa attività ogni</Form.Label>
							<label>LUN
							<Form.Control type="checkbox"
							name="lun" onChange={this.update} 
							/>
							</label>
							<label>MAR
							<Form.Control type="checkbox"
							name="mar" onChange={this.update} 
							/>
							</label>
							<label>MER
							<Form.Control type="checkbox"
							name="mer" onChange={this.update} 
							/>
							</label>
							<label>GIO
							<Form.Control type="checkbox"
							name="gio" onChange={this.update} 
							/>
							</label>
							<label>VEN
							<Form.Control type="checkbox"
							name="ven" onChange={this.update} 
							/>
							</label>
							<label>SAB
							<Form.Control type="checkbox"
							name="sab" onChange={this.update} 
							/>
							</label>
							<label>DOM
							<Form.Control type="checkbox"
							name="dom" onChange={this.update} 
							/>
							</label>
							</Form.Group>
						</div>
						<div className="col">
						<Form.Group controlId="importo">
							<Form.Label>Ripeti questa attività fino al giorno</Form.Label>
							<Form.Control type="date" placeholder="finoa" 
							name="finoa" value={this.state.finoa} onChange={this.update} 
							/>
						</Form.Group>
						</div>
						<div className="col">
						<Form.Group controlId="importo">
							<Form.Label>Ripeti questa attività fino alle ore</Form.Label>
							<Form.Control type="time" placeholder="finoalleore" 
							name="finoalleore" value={this.state.finoalleore} onChange={this.update} 
							/>
						</Form.Group>
						</div>
					</div>
					<div className="row">
						<div className="col">
						<Button variant="primary" onClick={this.handleSubmit}>
							Salva
						</Button>
						</div>
						<div className="col"><Button variant="secondary" onClick={this.props.togglePopup}>
							Close
						</Button>
						</div>
					</div>
					</form>
				
			</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato
		}
	}
	
	export default connect(mapStateToProps, null)(NuovaSchedulazione)