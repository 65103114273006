import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import ClienteAssociatoScheda from "./ClienteAssociatoScheda";
import NuovoEsercizioScheda from "./NuovoEsercizioScheda";
import EsercizioScheda from "./EsercizioScheda";
import {connect} from 'react-redux'

class DettaglioScheda extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            key:new Date().getTime(),
                id: this.props.id,
                dettaglio:null,
                idschedapartenza: null,
                esercizi:[],
                //templates:[],
                clientiAssociati:[],
                eserciziPalestra:[],
                nuovoEsercizioVisible:false
	    	}
        this.update = this.update.bind(this);
        this.salvaScheda = this.salvaScheda.bind(this);
        this.associa = this.associa.bind(this);
        this.disassocia = this.disassocia.bind(this);
        this.eliminaEsercizio = this.eliminaEsercizio.bind(this);
        this.refresh = this.refresh.bind(this);
	  }

	componentDidMount(){
        const formData = new FormData();
        formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','0001');
        formData.append('palestra',this.props.palestra.id);
        axios.post(global.config.server+"/getDettaglioSchedaSA/"+localStorage.getItem('idscheda'), formData)
        .then(response=>{
            axios.post(global.config.server+"/getEserciziRest", formData)
            .then(response4=>{
                axios.post(global.config.server+"/getEserciziScheda/"+localStorage.getItem('idscheda'), formData)
                .then(response2=>{
                    this.setState({dettaglio: response.data});
                    this.setState({descrizione: response.data.descrizione});
                    this.setState({template: response.data.template});
                    this.setState({validoDa: response.data.validoDa});
                    this.setState({eserciziPalestra: response4.data});
                    this.setState({esercizi: response2.data});
                    console.log(response2.data)
                    if (response.data
                        &&response.data.template
                        &&response.data.template===1){
                            this.setState({template:1})
                    }
                    else{
                        this.setState({template:0})
                    }
                    
                    this.setState({nuovoEsercizioVisible:false})
                });
            });
            
            
        });
    }

    salvaScheda(e) {
        let validita=this.state.validoDa;
        if (window.confirm('Vuoi aggiornare anche la data della scheda ad oggi?')){
            this.setState({validoDa:new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2)});
            validita=new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2);
        }
        e.preventDefault()
        const { id,descrizione,validoDa, template,clientiAssociati } = this.state;
	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','0001');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('id', localStorage.getItem('idscheda'));
	    formData.append('descrizione', descrizione);
		formData.append('schedaDataDa', validita);
        formData.append('template', template);
        clientiAssociati.forEach((item) => {
            formData.append('clientiAssociati', item.id);
        });

	      axios.post(global.config.server+'/aggiornaSchedaSalaAttrezzi/'+localStorage.getItem('idscheda'),formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
                    this.refresh()
	      });
    }

    refresh(){
        this.setState({key:new Date().getTime()});
        this.componentDidMount()
    }

	update(e) {
        if (e.target.name==='template'){
            if (e.target.checked) {
                this.setState({ [e.target.name]: 1});
            }
            else{
                this.setState({ [e.target.name]: 0});
            }
        }
        else{
          this.setState({ [e.target.name]: e.target.value});
        }
          
    }

    associa(idselezionato) {
        if (idselezionato&&idselezionato!==''){
            const id=idselezionato.split('___')[0]
            const name=idselezionato.split('___')[1]
            const cliente={id:id, name:name}
            if (!this.contains(this.state.clientiAssociati,cliente.id)){
                this.setState({ clientiAssociati: [...this.state.clientiAssociati, cliente] })
            }
        }
    }
    
    disassocia(e) {
        const nuovoArray=this.remove(this.state.clientiAssociati,e.target.name)
        this.setState({ clientiAssociati: nuovoArray })
    }

    eliminaEsercizio(et) {
        if (window.confirm('Sei sicuro di voler cancellare l\'esercizio selezionato?')){
            const formData2 = new FormData();
            formData2.append('website', global.config.domain);
            formData2.append('email',this.props.email);
            formData2.append('lic','0001');
            formData2.append('palestra',this.props.palestra.id);
            axios.post(global.config.server+'/eliminaEsercizioScheda/'+et.target.name, formData2)
            .then((result) => {
                    this.setState({messaggio:result.data});
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                        });
                        Toast.fire({
                        type: 'success',
                        title: result.data
                        })
                        this.refresh()
                        
          });
          
        }
    }

    contains(array,id){
        var trovato=false;
        if (array&&array.length>0){
            array.map(clienteTemp=>{
                if (clienteTemp.id===id) {
                    trovato=true;
                }
            })
        }
        return trovato;
    }

    remove(array,id){
        array = array.filter(cliente => cliente.id !== id)
        return array;
    }

	render() {
	    return (
           
	    		<span key={this.state.key}>
                     
                    <h3>Scheda Attività <u>{this.state.dettaglio&&this.state.dettaglio.anagrafica?this.state.dettaglio.anagrafica.nome + " "+ this.state.dettaglio.anagrafica.cognome:null}</u></h3>
                    <div className="row form-group">
                    <div className="col">
                            <label>Validità da</label>
                            <input className="form-control" type="date" name="validoDa" onChange={this.update} value={this.state.validoDa} />
                        </div>
                        <div className="col-md-2 col-lg-2 form-check">
                            <input id="template" className="form-check-input" type="checkbox" name="template" value="1" onChange={this.update} checked={this.state.template&&this.state.template===1?true:false} />
                            <label htmlFor="template" className="form-check-label">Usa questa scheda come predefinita</label>
                        </div>
                        <div className="col">
                            <label>Scheda di partenza</label> 
                            {this.state.dettaglio&&this.state.dettaglio.idschedapartenza?
                            <a href={"/dettaglioSchedaSalaAttrezzi?idScheda="+this.state.dettaglio.idschedapartenza}>{this.state.dettaglio.idschedapartenza}</a>
                        :<span>Nessuna scheda di partenza</span>}
                            </div>
                        <div className="col-xs col-sm w-100"></div>
                        
                    </div>
                    <div className="row form-group">
                        <label>Descrizione</label>
                        <textarea required={true} className="form-control" name="descrizione" onChange={this.update} value={this.state.descrizione}></textarea>
                    </div>
                    
                        <ClienteAssociatoScheda associa={this.associa}/>
                        <div className="row form-group">
                       {this.state.clientiAssociati&&this.state.clientiAssociati.length>0?
                       <ul>
                       {this.state.clientiAssociati.map(cliente=>(
                           <li>{cliente.id+" "+cliente.name} <button className="btn" name={cliente.id} onClick={this.disassocia}>Elimina</button></li>
                       ))}
                       </ul>
                       :null}
                    </div>
                    <button className="btn btn-info" onClick={this.salvaScheda}>Salva dettaglio scheda</button>
                    
                    {this.state.nuovoEsercizioVisible?
                    <div className="row form-group">
                        <NuovoEsercizioScheda  tipologieEsercizi={this.state.eserciziPalestra} idscheda={localStorage.getItem('idscheda')} refresh={this.refresh}/>
                    </div>
                     :<button className="btn btn-info" onClick={this.update} name="nuovoEsercizioVisible" value={true}>Nuovo Esercizio</button>}
                  
                    <div className="row form-group table-responsive">
                     <table className="table table-striped table-hover table-sm table-bordered">
                     <thead>
                         <tr>
                         <th rowSpan={2}>Giorno</th>
                         <th rowSpan={2}>Ordine</th>
                         <th rowSpan={2}>Serie</th>
                        <th rowSpan={2} >Recupero</th>
                         <th rowSpan={2}>T.U.T.</th>
                         
                         <td colSpan={3} style={{textAlign:"center"}}>Esercizio 1</td>
                         <td colSpan={3} style={{textAlign:"center"}}>Esercizio 2</td>
                         <td colSpan={3} style={{textAlign:"center"}}>Esercizio 3</td>
                         </tr>
                         <tr>
                         <th style={{backgroundColor:'#d2d5f7'}}>Esercizio 1</th>
                         <th style={{backgroundColor:'#d2d5f7'}}>Ripetizioni</th>
                         <th style={{backgroundColor:'#d2d5f7'}}>Peso</th>

                         <th style={{backgroundColor:'#d2d5f7'}}>Esercizio 2</th>
                         <th style={{backgroundColor:'#afb5fa', whiteSpace: 'nowrap'}}>Ripetizioni 2</th>
                         <th style={{backgroundColor:'#afb5fa', whiteSpace: 'nowrap'}}>Peso 2</th>

                         <th style={{backgroundColor:'#d2d5f7'}}>Esercizio 3</th>
                         <th style={{backgroundColor:'#7c86f7', whiteSpace: 'nowrap'}}>Ripetizioni 3</th>
                         <th style={{backgroundColor:'#7c86f7', whiteSpace: 'nowrap'}}>Peso 3</th>

                         </tr>
                     </thead>
                    <tbody style={{height: 400, overflow:"scroll"}}>
                       {this.state.esercizi&&this.state.esercizi.length>0?
                       this.state.esercizi.map((esercizio,index) =>(
                            
                              <EsercizioScheda esercizio={esercizio} key={index} 
                              tipologieEsercizi={this.state.eserciziPalestra} 
                              eliminaEsercizio={this.eliminaEsercizio}
                              refresh={this.refresh}/>
                             
                       ))
                      
                       :<tr><td colspan="14">Nessun esercizio</td></tr>}
                        </tbody>
                       </table>
                    </div>
			</span>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato
        }
    }
    
    export default connect(mapStateToProps, null)(DettaglioScheda)