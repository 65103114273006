import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import { connect } from "react-redux";

class NuovoPeriodoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: 0,
	    		descrizione: null,
	    		numGiorniScadenzaPagamento: null,
	    		numeroMesi: null,
	    		visible: false,
	    		messaggio:null
	    	}
	    this.update = this.update.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.showNuovoPeriodo=this.showNuovoPeriodo.bind(this);
	  }
	  
	  update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	  
	handleSubmit(event) {
		event.preventDefault();
	    const { id,descrizione, numGiorniScadenzaPagamento, numeroMesi } = this.state;

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('numGiorniScadenzaPagamento', numGiorniScadenzaPagamento);
		formData.append('numeroMesi', numeroMesi);
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaPeriodoRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 6000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
	  });
	  }

	showNuovoPeriodo(){
		this.setState({ visible: !this.state.visible });
	}
	  

	render() {
		var style1={
				color:"white"
		}
		
		var style3={
			backgroundColor: "#D0D0D0"
		}
		return (
			<form className="form-horizontal" method="post">
				<div className="row">
					<div className="col" style={style3}>
						<h4 onClick={this.showNuovoPeriodo}>Nuovo Periodo</h4>
					</div>
				</div>
				{this.state.visible?
				<div className="row">
					<span>{this.state.messaggio}</span>
					<div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
						<label className="control-label">Descrizione</label>
						<input className="form-control" type="text" onChange={this.update} name="descrizione" value={this.state.descrizione} required="required" />
					</div>
					<div className="col">
						<label className="control-label">Mesi</label>
							<input className="form-control" type="number" onChange={this.update} name="numeroMesi" value={this.state.numeroMesi} required="required" />
					</div>
					<div className="col">
						<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>Salva modifiche</a>
					</div>
				</div>
			:null}
			</form>
	);
	}
}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
    }
}

export default connect(mapStateToProps, null)(NuovoPeriodoInput)