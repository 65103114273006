import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../util/config'
import {navigate} from 'hookrouter'
import { useSelector } from "react-redux";

export default function Ingressi(props){
  const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
  const email = useSelector(state => state.gestoreUtente.emailloggato);
  const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata)

  const [ingressi, setingressi] = useState([])


  useEffect(() => {
        const formData = new FormData();
        formData.append('email',email);
		formData.append('website', palestra.website);
		formData.append('lic','000');
    formData.append('palestra',palestra.id);
    formData.append('id',idanagselezionata);
		  axios.post(global.config.server+"/getIngressi",formData)
		  .then(response=>{
			console.log("Ingressi: ")
			  console.log(response.data)
			  setingressi(response.data);
		  })
		  .catch(err => {
              if (err.response){
                alert("Ingressi: "+err.response.data.message);
              }
              else{
                alert("Ingressi: "+err.response);
              }
		});
      },[idanagselezionata])


    return (
        <span>

            <h2 className="entry-title"><a href="#">Ingressi</a></h2>
            <div className="row">
                <div className="col">
                    <div className="card">
                        <div className="card-body">
                            {ingressi&&Array.isArray(ingressi)&&ingressi.length>0?
                            ingressi.map(ingr=>(
                        <p className="card-text">
                            <div className="form-row">
                                <div className="form-group col-md-4">
                                    <input type="text" className="form-control" value={ingr.data} />
                                </div>
                            </div>
                        </p>
                        ))
                        :"Nessuna prenotazione trovata"}
                        </div>
                    </div>
                </div>
    </div>
    
</span>
);
}