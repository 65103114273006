import React from "react";
import { Chart } from "react-google-charts";

export function GraficoGauge(props) {

  return (
    <Chart
      chartType="Gauge"
      width="100%"
      data={[["Label", "Value"],[props.label,props.valore?props.valore:0]]}
      options={{
        redFrom: props.redFrom,
        redTo: props.redTo,
        yellowFrom: props.yellowFrom,
        yellowTo: props.yellowTo,
        greenFrom: props.greenFrom,
        greenTo: props.greenTo,
        minorTicks: 5,
        max: props.max,
        min: props.min,
      }}
    />
  );
}