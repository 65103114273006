import React from "react";
import axios from 'axios';
import NuovaRicevuta from './NuovaRicevuta'
import RicevutaEdit from './RicevutaEdit'
import '../util/config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { connect } from "react-redux";

class Ricevute extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
		idabbonamento:this.props.idabbonamento,
    	email:this.props.email,
    	idpagamento:this.props.idpagamento,
    	ricevute:this.props.selected,
		totRicevute:0,
		showModalRicevute:false,
    };
	this.togglePopup=this.togglePopup.bind(this)
}

togglePopup(){
  this.setState({showModalRicevute:!this.state.showModalRicevute})
}
  

componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
	    console.log(error, info);
	    
}

componentDidMount(){
	if (this.props.ricevute
		&&Array.isArray(this.props.ricevute)
		&&this.props.ricevute.length>=0){
			this.setState({ricevute: this.props.ricevute});
	}
	else{
				const formData = new FormData();
				formData.append('idabbonamento',this.props.idabbonamento);
				formData.append('website', global.config.domain);
				formData.append('email',this.props.email);
				formData.append('lic','000');
				formData.append('palestra',this.props.palestra.id);
				axios.post(global.config.server+"/getRicevute", formData)
			.then(response=>{
				this.setState({ricevute: response.data});
				console.log(response);
			}).catch(err => {
				if (err.response){
				alert("Ricevute: "+err.response.data.message);
				}
				else{
				alert("Ricevute: "+err.response);
				}
			})
		}
	}

  render() {
    return (
    		<span>
				<a type="button" className="btn btn-info btn-lg" onClick={this.togglePopup}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </a>
				<NuovaRicevuta 
				idabbonamento={this.props.idabbonamento}
				show={this.state.showModalRicevute}
				togglePopup={this.togglePopup}
				email={this.props.email}
				idpagamento={this.state.idpagamento}
				refresh={this.props.refresh}
				/>
	    		<div className="row">
		    		{
		    			(!this.state.ricevute)?
		    					<div className="col-sm">Nessuna ricevuta</div>
		    					:
		    				this.state.ricevute.map(item =>(
		    					<RicevutaEdit key={item.id} selected={item} refresh={this.props.refresh} 
								email={this.props.email} idUtente={this.props.idUtente} palestra={this.props.palestra.id}/>
		    				)
		    			)
		    		}
	    		</div>
    		</span>
    );
  }
}

const mapStateToProps = state => {
	return {
		palestra: state.gestoreUtente.palestraassociata.palestra,
		email: state.gestoreUtente.emailloggato,
		idanagselezionata: state.gestoreUtente.idanagselezionata
	}
}

export default connect(mapStateToProps, null)(Ricevute)