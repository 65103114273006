import React from "react";
import axios from 'axios';
import '../util/config'
import { Button } from "react-bootstrap";

export default class Collaboratore extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    		funzionalita:''
    };
    this.handleSubmit=this.handleSubmit.bind(this)
    this.update=this.update.bind(this)
    this.updateFunzionalitaUtente=this.updateFunzionalitaUtente.bind(this)
    this.eliminaCollaboratore=this.eliminaCollaboratore.bind(this)
}

updateFunzionalitaUtente(e){
    if (e.target.checked){
        this.setState({funzionalita:this.state.funzionalita+'||'+e.target.value})
    }
    else{
        this.setState({funzionalita:this.state.funzionalita.replace('||'+e.target.value,'')})
    }
}
  
  componentDidMount(){
	this.setState({...this.props.collaboratore})
  }

  update(e) {
      if (e.target.name==='amministratore'){
          this.setState({amministratore:e.target.checked})
      }
      else{
        this.setState({ [e.target.name]: e.target.value, visible: true });
      }
}

  handleSubmit(){
	const formData = new FormData();
	formData.append('website', global.config.domain);
  formData.append('email',this.props.email);
  formData.append('lic','000');
  formData.append('palestra',this.props.palestra.id);
    formData.append('amministratore',this.state.amministratore);
    formData.append('idsocio',this.state.id);
    formData.append('funzionalita',this.state.funzionalita);
	  axios.post(global.config.server+"/aggiornaFunzionalita", formData)
	  .then(response=>{
		  alert(response.data);
	  }).catch(err => {
		alert(err.response.data.message);
	});
  }
  
  eliminaCollaboratore(){
      if (window.confirm('Sei sicuro di voler eliminare il collaboratore '+this.state.utente.name+'?')){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('idsocio',this.state.id);
        axios.post(global.config.server+"/eliminaCollaboratore", formData)
        .then(response=>{
            alert(response.data);
            window.location.reload();
        }).catch(err => {
            if (err.response){
                alert(err.response.data.message);
            }
            else{
                alert(err.message)
            }
        });
    }
  }

  componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
	    console.log(error, info);
	  }
  
  render() {
    return (
            <tr style={{borderWidth:'1px', borderStyle: "double", borderColor:"black"}}>
                
                <td >{this.state.id} - </td>
                <td >{this.state.utente?this.state.utente.name:''}</td>
                <td >{this.state.utente?this.state.utente.nome:''}</td>
                <td >{this.state.utente?this.state.utente.email:''}</td>
                <td >{this.state.utente?this.state.utente.telefono:''}
                {this.state.utente&&this.state.utente.email===this.props.email?"<== questo sei tu "+(this.props.amministratore?"AMMINISTRATORE":"") :""}
                </td>
                <td>
                <form onSubmit={this.handleSubmit}>
                  <ul>
                {this.props.moduli&&this.props.moduli.length>0?
                this.props.moduli.map(modulo=>(
                  <li>
                    {/* {modulo.nome==='CALENDARIO'?

                    localStorage.getItem('calendari')?

                    localStorage.getItem('calendari').split(',').map(calendario=>(
                      <span>
                       <label> {modulo.nome==='CALENDARIO'?'FULL CONTROL':'READONLY'} {calendario} <input type="checkbox" name="funzionalita" value={modulo.nome+'__'+calendario} onChange={this.updateFunzionalitaUtente} defaultChecked={this.state.funzionalita&&this.state.funzionalita.indexOf(modulo.nome+'__'+calendario)>=0} />
                       </label>
                      </span>
                    ))
                    :null
                    :null} */}
                    <label>{modulo.nome}
                    <input type="checkbox" name="funzionalita" value={modulo.nome} onChange={this.updateFunzionalitaUtente} defaultChecked={this.state.funzionalita&&this.state.funzionalita.indexOf(modulo.nome)>=0} />
                    </label>
                  
                    
                    </li>
                ))
                :"<li>nessun modulo</li>"
                }
                </ul>
                </form>
                
                </td>
                <td >
                <label> Amministratore
                <input type="checkbox" name="amministratore" value={this.state.amministratore} onChange={this.update} checked={this.state.amministratore} />
                </label>
                </td>
                <td>
                {this.props.amministratore?
                    <button class="btn btn-info" onClick={this.handleSubmit}>Salva moduli per {this.state.utente.name}</button>
                :null}
                </td>
                <td>
                {this.props.amministratore?
                <Button onClick={this.eliminaCollaboratore} class="btn btn-danger">Elimina collaboratore {this.state.utente.name}</Button>
                :null}
                </td>
                
                </tr>

    );
  }
}