import React from "react";
import { useState } from "react";

export default function NuovaRiga(props) {
    const [val, setval] = useState('');
    const [descr, setdescr] = useState(props.riga.descrizione);
	
		return (
            <tr key={props.riga.idrigafattura}>
                
                <td className="col"> 
                {props.prodotti&&
                <>
                <select style={{width:'100%', margin:0, padding:0}} size={1} onChange={(e) => {setdescr(props.prodotti.filter(prod => prod.id===Number(e.target.value))[0].descrizione); props.aggiornarigadaprodotti(e.target.value,props.riga); setval(e.target.value)}} value={val}>
                     <option key={0} value={0}>--SELEZIONA UN SERVIZIO--</option>
                    {props.prodotti.map(prodotto => (
                        <option key={prodotto.id} value={prodotto.id}>{prodotto.descrizione}</option>
                    ))}
                </select>
                <br/>
                </>
                }
                <input style={{width:'100%', margin:0, padding:0}} type="text" name="descrizione" onChange={e=>{setdescr(e.target.value);props.updateRiga(e,props.riga.idrigafattura)}} value={descr}/>
                </td>
                <td className="col"> <input size={3} style={{width:'100%', margin:0, padding:0, textAlign:'right'}} type="number" name="quantita" onChange={e=>props.updateRiga(e,props.riga.idrigafattura)} value={props.riga.quantita}/></td>
                <td className="col"><input style={{width:'100%', margin:0, padding:0, textAlign:'right'}} type="number" name="costo" onChange={e=>props.updateRiga(e,props.riga.idrigafattura)} value={props.riga.costo}/></td>
                <td className="col" style={{textAlign:'right'}}>{props.riga.quantita*props.riga.costo}</td>
                <td className="col"><input style={{width:'100%', margin:0, padding:0,textAlign:'right'}} type="number" name="aliquotaiva" value={props.riga.aliquotaiva} onChange={e=>props.updateRiga(e,props.riga.idrigafattura)} /></td>
                <td className="col" style={{textAlign:'right'}}>{(props.riga.quantita*props.riga.costo*props.riga.aliquotaiva/100)}</td>
                <td className="col"><input style={{width:'100%', margin:0, padding:0, textAlign:'center'}} type="text" name="fenatura" value={props.riga.fenatura} /></td>
                <td className="col">
                <button className="btn btn-default" onClick={e=>{props.eliminaRiga(e,props.riga.idrigafattura)}}>Elimina riga</button>
                </td>
            </tr>

	);
}