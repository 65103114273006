import { useEffect } from "react";
import { useSelector } from "react-redux";
import { getallattivita } from "../../../DAO/attivitaquotidianaDAO";
import SelectText from "../../../util/UI/SelectText";
import { useState } from "react";

export default function ListaAttQuotSeelct({id, value, onChange, listaattquot}){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);

    const [attivita, setattivita] = useState([])

    useEffect (() => {
        console.log(listaattquot)
        if(!listaattquot){
            const result=getallattivita(palestra.id)
            let attres=[]
            result.map(res => attres.push({name:res.descrizione +" (iei "+res.iei+")", value:res.id}))
            setattivita(attres)
        }
        else{
            let attres=[]
            listaattquot.map(res => attres.push({name:res.descrizione, value:res.id}))
            setattivita(attres)
        }
    },[palestra,listaattquot,value])

    return (
        <SelectText bootstrapsize={"col-md-6"}
        id={id}
        label={"Att. Quot."}
        name={"attquot"}
        value={value}
        lista={attivita}
        onChange={onChange}
         />
    )
}