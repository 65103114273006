import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import ListaScadenzaRate from "../scadenzeRate/ListaScadenzaRate";
import { connect } from "react-redux";

class PeriodoInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		descrizione: this.props.selected.descrizione,
	    		numGiorniScadenzaPagamento: this.props.selected.numGiorniScadenzaPagamento?this.props.selected.numGiorniScadenzaPagamento:0,
	    		numeroMesi: this.props.selected.numeroMesi,
				visible: false,
				listaScadenze:this.props.selected.listaScadenze,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	  handleSubmit(event) {
		event.preventDefault();
	    const { id,descrizione, numGiorniScadenzaPagamento, numeroMesi} = this.state;

	    const formData = new FormData();
	    formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('numGiorniScadenzaPagamento', numGiorniScadenzaPagamento);
		formData.append('numeroMesi', numeroMesi);
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/aggiornaPeriodoRest/'+id,formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'bottom-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.props.refresh();
		          this.setState({ visible: false });
	      }).catch(err => {
			if (err.response){
			  alert(err.response.data.message);
			}
			else{
			  alert(err.response);
			}
	  });
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare il periodo selezionato?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
          formData.append('lic','000');
          formData.append('palestra',this.props.palestra.id);
		  axios.post(global.config.server+'/eliminaPeriodoRest/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			        this.props.refresh();
			          this.setState({ visible: false });
			        }).catch(err => {
						if (err.response){
						  alert(err.response.data.message);
						}
						else{
						  alert(err.response);
						}
				  });
			}
	  }

	render() {
		var style1={
				color:"white"
		}
		
		var scadenzaRataStyle={
				borderStyle:"solid",
				borderWith:"1px"
		}

	    return (
			<form className="form-horizontal">
				<span>{this.state.messaggio}</span>
				<div className="row">
					<input type="hidden" name="id" value={this.state.id} />
					<div className="col-xs-12 col-sm-12 col-md-3 col-lg-3">
						<label className="control-label">Descrizione</label>
						<input className="form-control" type="text" onChange={this.update} name="descrizione" value={this.state.descrizione} required="required" />
					</div>
					<div className="col">
						<label className="control-label">Mesi</label>
						<input className="form-control" type="number" onChange={this.update} name="numeroMesi" value={this.state.numeroMesi} required="required" />
					</div>
					{this.state.visible?
							<div className="col">
							<a type="button" className="btn btn-primary" onClick={this.handleSubmit} style={style1}>Salva modifiche</a>
							</div>
						:null}
					<div className="col">
						<a className="btn btn-danger" style={{color:"white"}} onClick={this.elimina}>Elimina Periodo</a>
					</div>
					<div className="col-sm-9" style={scadenzaRataStyle}>
							<ListaScadenzaRate periodo={this.state.id} 
							listaScadenze={this.props.selected.scadenze}
							email={this.props.email} />
					</div>
				</div>
				<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
					<div className="col-sm-12">
					<hr style={{borderTop: '8px solid #bbb', borderRadius: '5px', marginBottom:'25px'}} />
					</div>
				</div>
			</form>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(PeriodoInput)