import React from "react";
import RepFatturato from "./RepFatturato";

export default class ListaFatturato extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            fatturato:[]
	    };

	}

	componentDidMount(){
		this.setState({fatturato:this.props.fatturato})
	  }
	
	componentDidCatch(error, info) {
	    // You can also log the error to an error reporting service
		console.log("errore");
	    console.log(error, info);
	  }
	
	render() {
	    return (
	    		<div>
					<RepFatturato fatturato={this.props.fatturato}/>
		</div>
	    );
	}
}