import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import '../util/config'
import { connect } from "react-redux";

class RicevutaEdit extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.id,
	    		anno: this.props.selected.anno,
	    		data: this.props.selected.data,
	    		importo:this.props.selected.importo,
	    		numero:this.props.selected.numero,
				idpagamento:this.props.selected.idpagamento,
				idabbonamento:this.props.selected.idabbonamento,
	    		email:this.props.selected.email,
	    		visible: false,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.sendEmailRicevuta = this.sendEmailRicevuta.bind(this);
	    this.openRicevuta=this.openRicevuta.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }
	
	handleSubmit(event) {
		event.preventDefault();
	    const { id,anno,data, importo, numero, idpagamento,idabbonamento } = this.state;

	    const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
		formData.append('palestra',this.props.palestra.id);
	    formData.append('id', id);
	    formData.append('anno', anno);
		formData.append('data', data);
		formData.append('importo', importo);
		formData.append('numero', numero);
		formData.append('idpagamento', idpagamento?idpagamento:'');
		formData.append('idabbonamento', idabbonamento?idabbonamento:'');

	      axios.post(global.config.server+'/aggiornaRicevutaRest',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          this.setState({ visible: false });
				  this.props.refresh();
	      });
	  }
	
	openRicevuta(event){
		event.preventDefault()

		let windowName = 'w_' + Date.now() + Math.floor(Math.random() * 100000).toString();
		var form = document.createElement("form");
		form.setAttribute("method", "post");
		form.setAttribute("action", global.config.server+'/stampaRicevuta');
		
		form.setAttribute("target", windowName);
		
		var email = document.createElement("input"); 
		email.setAttribute("type", "hidden");
		email.setAttribute("name", "email");
		email.setAttribute("value", this.props.email);
		form.appendChild(email);

		var palestra = document.createElement("input"); 
		palestra.setAttribute("type", "hidden");
		palestra.setAttribute("name", "palestra");
		palestra.setAttribute("value", this.props.palestra);
		form.appendChild(palestra);

		var website = document.createElement("input"); 
		website.setAttribute("type", "hidden");
		website.setAttribute("name", "website");
		website.setAttribute("value", global.config.domain);
		form.appendChild(website);

		var idRicevuta = document.createElement("input"); 
		idRicevuta.setAttribute("type", "hidden");
		idRicevuta.setAttribute("name", "id");
		idRicevuta.setAttribute("value", this.state.id);
		form.appendChild(idRicevuta);

		document.body.appendChild(form);
		
		window.open('', windowName,'_blank');
		
		form.submit();
	}

	sendEmailRicevuta(event) {
		event.preventDefault();
	    const { id } = this.state;

	    const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
		formData.append('lic','000');
	    formData.append('id', id);
		formData.append('idanagrafica', this.props.idanagselezionata);
		formData.append('palestra',this.props.palestra.id);

	    axios.post(global.config.server+'/inviaEmailPdfRicevutaRest',formData)
	      .then((result) => {
	    	  console.log(result)
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  //timer: 3000
					});
					Toast.fire({
					  type: result.data.message?'success':'error',
					  title: result.data.message?result.data.message:result.data.errore
					})
		          this.setState({ visible: false });
	      })
	      .catch(err => {
			alert("Modifica ricevuta: "+err.response.data.message);
		});
	  }

	  elimina() {
		  if (window.confirm('Sei sicuro di voler cancellare la ricevuta selezionata?')){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.palestra.id);
			formData.append('id', this.state.id);
			

			  axios.post(global.config.server+'/eliminaRicevutaRest',formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
			          this.setState({ visible: false });
					  this.props.refresh();
			        }).catch(err => {
						alert("Elimina Ricevute: "+err.response.data.message);
					});
			}
	  }

	render() {
		var style1={
				color:"white"
		}
	    return (
	    		<div className="row">
					<div className="col-sm-12">
						<input type="hidden" name="id" value={this.state.id} readOnly={true} />
						<input type="hidden" name="idpagamento" value={this.state.idpagamento} readOnly={true} />
						<div className="row">
							<div className="col-sm">
								<label className="control-label">Anno</label>
								<input className="form-control" type="text" maxLength="5" readOnly={true} value={this.state.anno} />
							</div>
							<div className="col-sm">
								<label className="control-label">Data</label>
								<input className="form-control" type="date"  maxLength="50" name="data" value={this.state.data} onChange={this.update} />
							</div>
							<div className="col-sm">
								<label className="control-label">Importo</label>
								<input className="form-control" type="number" name="importo" value={this.state.importo} onChange={this.update} />
							</div>
								<div className="col-sm">
								<label className="control-label">Numero</label>
								<input className="form-control" type="text" name="numero" value={this.state.numero} onChange={this.update} />
							</div>
							{this.state.visible?
									<div className="col">
					  				<a type="button" className="btn btn-success" onClick={this.handleSubmit} style={style1}>Salva modifiche</a>
					  				</div>
					  			:null}
									<div className="col">
					  				<a type="button" className="btn btn-primary" onClick={this.sendEmailRicevuta} style={style1}>Invia tramite email</a>
					  				</div>
							<div className="col">
								<a className="btn btn-warning" style={{color:"white"}} onClick={this.openRicevuta}>Visualizza</a>
							</div>
							<div className="col">
								<a className="btn btn-danger" style={{color:"white"}} onClick={this.elimina}>Elimina</a>
							</div>
						</div>
					</div>
					<div className="row">
						<hr />
					</div>
				</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(RicevutaEdit)