import React, { useEffect, useState } from "react";
import SelectTipologiaCorso from "../tipologiaCorso/SelectTipologiaCorso";
import LuogoSelect from "./LuogoSelect";
import { useSelector } from "react-redux";
import { aggiornaCorso, eliminaCorso } from "../../DAO/corsiDAO";
import Ingredienti from "./Ingredienti";
import FileBase64 from 'react-file-base64';
import Allergeni from "./Allergeni";
import InputText from "../../util/UI/InputText";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import MyInput from "../../util/UI/MyInput";

export default function CorsoInput (props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
    
    const [nome, setnome] =useState(props.corso.nome)
    const [descrizione, setdescrizione] =useState(props.corso.descrizione)
    const [place, setplace] = useState(props.corso.luogo)
    const [idtipo, setidtipo] = useState(props.corso.tipoCorso)
    const [duratagiornaliera, setduratagiornaliera] = useState(false)
    const [durata, setdurata] = useState(props.corso.durata)
    const [utenti, setutenti] = useState(props.corso.utenti)
    const [abb, setabb] = useState(props.corso.abb)
    const [calendariopubblico, setcalendariopubblico] = useState(props.corso.calendariopubblico)
    const [prezzo, setprezzo] = useState(props.corso.prezzo)
    const [foto1, setfoto1] = useState(props.corso.foto1)
	const [file, setfile] = useState(null);
	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
	const [allergeni, setallergeni] = useState(props.corso.allergeni)
	const [html, sethtml] = useState(props.corso.descrizionehtml?props.corso.descrizionehtml:props.corso.descrizione?props.corso.descrizione:'')
    const [disponibileda, setdisponibileda] = useState(props.corso.disponibileda)
    const [disponibilea, setdisponibilea] = useState(props.corso.disponibilea)
    const [disponibile, setdisponibile] = useState(props.corso.disponibilita)
	const [ordine, setordine] = useState(props.corso.ordine)

	const [fenatura, setfenatura] = useState(props.corso.fenatura)
	const [aliquotaiva, setaliquotaiva] = useState(props.corso.aliquotaiva)

	const [messaggio, setmessaggio] = useState('');
	const [visible, setvisible] = useState(true);

	function handleInputChange(file){
		setfile({
			base64:file.base64,
			name:file.name,
			type:file.type
		})
	}
	  function salva(){
        async function salvaprod(){
            const result=await aggiornaCorso(props.corso.id,nome, descrizione?descrizione:nome, html?html:'', idtipo.id, utenti, durata, duratagiornaliera,
                place, abb, calendariopubblico, prezzo, disponibileda, disponibilea, disponibile,
                foto1, file, fenatura, aliquotaiva, emailloggato, palestra.palestra.id);
				setmessaggio(result)
            props.setkeytorefresh(new Date().getTime());
			setvisible(true)
			setkeytorefresh(new Date().getTime())
        }
		if (!nome){
			alert('Nome obbligatorio')
			return;
		}
		if (!idtipo){
			alert('Tipologia obbligatoria')
			return;
		}
		if (!place){
			alert('Luogo obbligatorio')
			return;
		}
		setvisible(false)
		setmessaggio("Attenere prego")
        salvaprod();
    }
	  
	function updateTipo(tipoSelezionato) {
		setidtipo(tipoSelezionato)
		setvisible(true)
	}

	function elimina() {
		async function eliminacor() {
            const message = await eliminaCorso(
                emailloggato, palestra.palestra.id,
                props.corso.id
            );
            alert(message);

            props.setkeytorefresh(new Date().getTime())
          }
		  if (window.confirm('Sei sicuro di voler cancellare '+nome+'?')){
			eliminacor()
			}
	  }

	  useEffect(() => {
		console.log("UseEffect CorsoInput "+props.corso.nome)
	  },[setkeytorefresh])

	    return (
			<div>
		<form key={keytorefresh} className="form-horizontal" method="post">
	    	<div className="row">
				<div className="col-md-6">
					<label htmlFor="cnome"className="control-label">Nome</label>
						<input className="form-control" id="cnome" type="text" onChange={(e)=> setnome(e.target.value)} maxLength="150" name="nome" value={nome} required="required" />
					</div>
				<InputText label={"Prezzo €"} 
				type="number" name="prezzo" value={prezzo} 
				onChange={(e)=>setprezzo(e.target.value)} 
				bootstrapsize={"col-md-6 input-group-lg"}
				
				step="0.1" />
					<div className="col-md-12">
					
						<label>Descrizione
					
					
                <MyInput sethtml={sethtml} defaultcontent={html} />
            
					</label>
					
					</div>
					<div className="col-md-2">
						<label className="control-label">Luogo</label>
						<LuogoSelect setselected={(value) => {setplace(value)}} selected={place}/>
					</div>

							<div className="col-md-2">
								<label htmlFor="ctipoc" className="control-label">Categoria</label>
								<SelectTipologiaCorso updateTipo={updateTipo} 
								id="ctipoc" 
								selected={idtipo} 
								 />
							</div>
							<div className="col-md-2">
								<label htmlFor ="cdurata" className="control-label">Durata</label>
								<input className="form-control" id="cdurata" type="number" onChange={(e) => setdurata(e.target.value)} name="durata" value={durata} required="required" />
								<label>Tutto il giorno <input type={"checkbox"} checked={duratagiornaliera} name={"duratagiornaliera"} onChange={(e) =>{e.target.checked?setduratagiornaliera(true):setduratagiornaliera(false)}}  /></label>
							</div>
							<div className="col-md-2">
								<label htmlFor="cmaxp" className="control-label">Max Prenotazioni</label>
								<input className="form-control" id="cmaxp" type="number" onChange={(e) => setutenti(e.target.value)} name="utenti" value={utenti} required="required" />
							</div>
							<InputText bootstrapsize={"col-md-2"}
							label={"Ordine"}
							onChange={(e) => setordine(e.target.value)}
							placeholder={"Numero d'ordine"}
							type={"number"}
							value={ordine} />

							<div className="col-md-2">
								<label>Solo abbonati
								<select className="form-control" name="abb" value={abb&&(abb===true||abb==='true')} onChange={(e)=>{setabb(e.target.value)}}>
									<option value="false" >Aperto a tutti</option>
									<option value="true" >Solo abbonati</option>
								</select>
								</label>
							</div>
							<div className="col-md-2">
								<label htmlFor="showPub" className="control-label">Mostra nel calendario pubblicamente</label>
								<select id="showPub" className="form-control" name="calendariopubblico" value={calendariopubblico&&(calendariopubblico===true||calendariopubblico==='true')} onChange={(e)=>{setcalendariopubblico(e.target.value)}}>
									<option value="true" >Mostra</option>
									<option value="false" >Nascondi</option>
								</select>
							</div>
								
		  				

			</div>
			</form>
			
			<Tabs>
				<TabList>
					<Tab>Disponibilità</Tab>
					<Tab>Allergeni</Tab>
					<Tab>Immagine</Tab>
					<Tab>Fattura Elettronica</Tab>
					<Tab>Ingredienti personalizzati</Tab>
				</TabList>
				<TabPanel>
				<div>
							<h5>Disponibilità</h5>
								<div className="row">
								<div className="col-md-4">
									<label>Disponibile <input type="radio" name="disponibile" value="disponibile" onChange={() => setdisponibile('disponibile') } checked={disponibile==='disponibile'} /></label>
									<label>Non Disponibile <input type="radio" label="Non Disponibile" name="disponibile" value="nondisponibie" onChange={(e) => {setdisponibile('nondisponibile')} } checked={disponibile!=='disponibile'}/></label>
								</div>
								<div className="col-md-4">
									<label>Disponibile dal <input type="date" name="disponibileda" value={disponibileda} onChange={(e) => {setdisponibileda(e.target.value); setdisponibilea(e.target.value)}} /></label>
								</div>
								<div className="col-md-4">
									<label>Disponibile fino a <input type="date" name="disponibilea" value={disponibilea} onChange={(e) => setdisponibilea(e.target.value)} /></label>
								</div>
								</div>
							</div>
				</TabPanel>
				<TabPanel>
				{
							(palestra.palestra&&palestra.palestra.descrizioneattivita&&(palestra.palestra.descrizioneattivita.toUpperCase().indexOf('RISTO')>=0||palestra.palestra.descrizioneattivita.toUpperCase().indexOf('ALIMENT')>=0))&&
								<>
								<h5>Allergeni</h5>
								<Allergeni allergeni={allergeni?allergeni.split(','):[]} setallergeni={setallergeni} idcorso={props.corso.id} />
								</>
							}
				</TabPanel>
				<TabPanel>
				
							<h5>Immagine</h5>
								<img src={foto1} style={{maxWidth:'100px'}} />
								<label>Link immagine
								<input type="text" name="foto1" value={foto1}  onChange={(e) => setfoto1(e.target.value)} />
								</label>
								<label>Oppure seleziona una immagine
								<FileBase64 multiple={ false } onDone={(file) => handleInputChange(file)} />
								</label>
				</TabPanel>
				<TabPanel>
				<h5>Fattura Elettronica</h5>
								{fenatura}
								<select name="fenatura" value={fenatura} onChange={(e) => setfenatura(e.target.value)} >
									<option value="">--Natura bene/servizio per Fattura Elettrovica</option>
									<option value="N1">N1 - escluse ex art. 15 del DPR 633/72</option>
									<option value="N2.1">N2.1 - non soggette ad IVA ai sensi degli artt. da 7 a 7-septies del DPR 633/72</option>	
									<option value="N2.2">N2.2 - non soggette - altri casi</option>
									<option value="N3.1">N3.1 - non imponibili - esportazioni</option>
									<option value="N3.2">N3.2 - non imponibili - cessioni intracomunitarie</option>
									<option value="N3.3">N3.3 - non imponibili - cessioni verso San Marino</option>
									<option value="N3.4">N3.4 - non imponibili - operazioni assimilate alle cessioni all'esportazione</option>
									<option value="N3.5">N3.5 - non imponibili - a seguito di dichiarazioni d'intento</option>			
									<option value="N3.6">N3.6 - non imponibili - altre operazioni che non concorrono alla formazione del plafond</option>
									<option value="N4">N4 - esenti</option>
									<option value="N5">N5 - regime del margine / IVA non esposta in fattura</option>
									<option value="N6.1">N6.1 - inversione contabile - cessione di rottami e altri materiali di recupero</option>
									<option value="N6.2">N6.2 - inversione contabile - cessione di oro e argento ai sensi della legge 7/2000 nonché di oreficeria usata ad OPO</option>
									<option value="N6.3">N6.3 - inversione contabile - subappalto nel settore edile</option>
									<option value="N6.4">N6.4 - inversione contabile - cessione di fabbricati</option>
									<option value="N6.5">N6.5 - inversione contabile - cessione di telefoni cellulari</option>
									<option value="N6.6">N6.6 - inversione contabile - cessione di prodotti elettronici</option>
									<option value="N6.7">N6.7 - inversione contabile - prestazioni comparto edile e settori connessi</option>
									<option value="N6.8">N6.8 - inversione contabile - operazioni settore energetico</option>
									<option value="N6.9">N6.9 - inversione contabile - altri casi</option>
									<option value="N7">N7 - IVA assolta in altro stato UE (prestazione di servizi di telecomunicazioni, tele-radiodiffusione ed elettronici ex art. 7-octies, comma 1 lett. a, b, art. 74-sexies DPR 633/72)</option>
								</select>
							
								<label>Aliquota iva per fattura
									<input type="number" step=".01" name="aliquotaiva" value={aliquotaiva} onChange={(e) => setaliquotaiva(e.target.value)} />
								</label>
				</TabPanel>
				<TabPanel>
				<Ingredienti prodottopadre={props.corso} />
				</TabPanel>
				</Tabs>
				<hr />
				<div className="row">
				<div className="col-md-2">
				  				{visible?<a type="button" className="btn btn-primary" onClick={salva}>Salva modifiche</a>:""}
								{messaggio}
				  				</div>

							  <div className="col-md-2">
								<a className="btn btn-danger" onClick={elimina}>Elimina</a>
							</div>
				</div>
			</div>
	    );
	}