import React from "react";
import axios from 'axios';
import '../../util/config'
import {  Form} from 'react-bootstrap';
import { connect } from "react-redux";

class ListinoSelect extends React.Component {
	 constructor(props) {
		    super(props);
		    this.state = {
		    		listino:[]
					};
		  }
	 
	 componentDidMount(){

			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.idpalestra);
			axios.post(global.config.server+"/listiniRest",formData)
			.then(response=>{
				this.setState({listino: response.data});
				//console.log(response);
			}).catch(err => {
				if (err.response){
				alert("Listini: "+err.response.data.message);
				}
				else{
				alert("Listini: "+err.response);
				}
		});

	  }
	
	render() {
	    return (
				<Form.Control as="select" className="form-control" name="listino" size="sm" value={this.props.selected} onChange={this.props.onChange}>
				<option key={-1} value=""></option>
				{
					this.state.listino&&Array.isArray(this.state.listino)&&this.state.listino.length>0?
					this.state.listino.map(item =>(
					<option key={item.id}  value={item.id}>{item.tipoAbbonamento.descrizione} {item.periodo.descrizione} - {item.prezzo}</option>
					)
					)
					:null
				}
				
				</Form.Control>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato,
			idanagselezionata: state.gestoreUtente.idanagselezionata
		}
	}
	
	export default connect(mapStateToProps, null)(ListinoSelect)