import React, { useEffect, useState } from "react";
import '../util/config'
import PalestraAssociataSelect from "./PalestraAssociataSelect";
import { useSelector } from "react-redux";

export default function PalestreAssociateSelect (props) {
  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
  const palestreassociate = useSelector(state => state.gestoreUtente.palestreassociate);

  const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
  const [toogle, settoogle] = useState(false)

      return (
          <span div key={keytorefresh}>
            
            {emailloggato&&palestreassociate&&Array.isArray(palestreassociate)&&palestreassociate.length>=0&&
            <div style={{backgroundColor:'#0356fc', color:'white'}}  className="col">
            <h3 style={{cursor:"pointer"}} onClick={() => settoogle(!toogle)}>Entra come gestore</h3>
            {toogle&&<button className="button btn btn-success" onClick={() => window.location.href=props.linknewpal?props.linknewpal:'/annotatio-ins-pal/'}>Nuova attivita' commerciale</button>}
              {(toogle||props.showlistpalestre)&&palestreassociate.map(palestraSocio =>(
               <PalestraAssociataSelect key={palestraSocio.id} soc={palestraSocio} link={props.link}
               setkeytorefresh={setkeytorefresh} />
              )
              )
            }
            
            </div>
            }
            {(!palestreassociate||palestreassociate.lenght===0)&&"Nessuna attivit&agrave; commerciale associata"}
            {!emailloggato&&
            <div>
             Please Log In
              </div>}
            </span>
        );
  }