import React from "react";
import axios from 'axios';
import '../util/config'
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

class AccessiGiornalieri extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
                accessi:null
        };
        this.update=this.update.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
      }

      componentDidMount(){
        const formData = new FormData();
		formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
    formData.append('lic','000');
    formData.append('palestra',this.props.palestra.id);
    axios.post(global.config.server+"/visualizzaAccessiOdierni",formData)
		  .then(response=>{
			console.log("Accessi aodierni: ")
			  console.log(response.data)
			  this.setState({accessi: response.data});
		  })
		  .catch(err => {
              if (err.response){
                alert("accessi: "+err.response.data.message);
              }
              else{
                alert("accessi: "+err.response);
              }
		});
      }

    update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
    }

    handleSubmit() {
       
        const formData = new FormData();
		formData.append('website', global.config.domain);
    formData.append('email',this.props.email);
    formData.append('lic','000');
    formData.append('palestra',this.props.palestra.id);
        formData.append('data',this.state.data);
      
        axios.post(global.config.server+"/visualizzaAccessiOdierni",formData)
        .then(response=>{
          console.log("Accessi aodierni: ")
            console.log(response.data)
            this.setState({accessi: response.data});
        })
        .catch(err => {
            if (err.response){
              alert("accessi: "+err.response.data.message);
            }
            else{
              alert("accessi: "+err.response);
            }
      });
  }

render() {
    return (
        <div>
            <div>
                <input type="date" name="data" onChange={this.update} />
                <Button onClick={this.handleSubmit}>Cerca accessi</Button>
            </div>
        <table id="zero_config" className="table table-striped table-bordered">
            <thead style={{borderWwidth: '1px', borderStyle: 'solid'}}>
                <th>Codice</th>
                <th>Data</th>
                <th>Descrizione</th>
                <th>Utente</th>
            </thead>
            <tbody>
                {
                    this.state.accessi?
                        this.state.accessi.map(accesso=>(
                            <tr>
                                <td>{accesso.code}</td>
                                <td>{accesso.data}</td>
                                <td>{accesso.descrizioneAccesso}</td>
                                <td>{accesso.nomeCognome}</td>
                            </tr>
                        ))
                    :"nessun accesso trovato"
                }
            </tbody>
        </table>
        </div>
);
  }
}

const mapStateToProps = state => {
  return {
    palestra: state.gestoreUtente.palestraassociata.palestra,
    email: state.gestoreUtente.emailloggato,
    idanagselezionata: state.gestoreUtente.idanagselezionata
  }
}

export default connect(mapStateToProps, null)(AccessiGiornalieri)