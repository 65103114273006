import React, { useState } from "react";
import { useSelector } from "react-redux";
import { updalimento } from "../DAO/alimentiDAO";

export default function NewAlimento(props) {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    

    const [nome, setnome] = useState('');
    const [acidofolico, setacidofolico] = useState(0);
    const [amido, setamido] = useState(0);
    const [caplusplus, setcaplusplus] = useState(0);
    const [carboidrati, setcarboidrati] = useState(0);
    const [colesterolo, setcolesterolo] = useState(0);
    const [feplusplus, setfeplusplus] = useState(0);
    const [fibra, setfibra] = useState(0);
    const [qtarif, setqtarif] = useState(0);
    const [h2o, seth2o] = useState(0);
    const [id, setid] = useState(0);
    const [indiceglicemico, setindiceglicemico] = useState(0);
    const [kcal, setkcal] = useState(0);
    const [kplus, setkplus] = useState(0);
    const [lattosio, setlattosio] = useState(0);
    const [lipidi, setlipidi] = useState(0);
    const [mg, setmg] = useState(0);
    const [naplus, setnaplus] = useState(0);
    const [niacinab3, setniacinab3] = useState(0);
    const [p, setp] = useState(0);
    const [proteine, setproteine] = useState(0);
    const [riboflavinab2, setriboflavinab2] = useState(0);
    const [saturi, setsaturi] = useState(0);
    const [tiaminab1, settiaminab1] = useState(0);
    const [vitaminaa, setvitaminaa] = useState(0);
    const [vitaminab12, setvitaminab12] = useState(0);
    const [vitaminac, setvitaminac] = useState(0);
    const [vitaminad, setvitaminad] = useState(0);
    const [vitaminae, setvitaminae] = useState(0);
    const [zn, setzn] = useState(0);
    const [zuccherisolubili, setzuccherisolubili] = useState(0);
    const [note, setnote] = useState('');

    function updalim(){
        async function upd(){
            const result = await updalimento(nome, 
                acidofolico, amido, caplusplus, carboidrati, colesterolo, feplusplus, fibra,
                qtarif, h2o, 0, palestra.id, indiceglicemico, kcal, kplus, lattosio, lipidi,
                mg, naplus, niacinab3, p, proteine, riboflavinab2, saturi, tiaminab1, vitaminaa, 
                vitaminab12, vitaminac, vitaminad, vitaminae, zn, zuccherisolubili, note);
            alert(result);
        }
        upd();
    }

    return (
        <tr style={{backgroundColor:'greenyellow'}}>
            <td>
                <input type="text" name="nome" value={nome} onChange={(e) => setnome(e.target.value)} />
                Nuovo Alimento
            </td>
            <td><input type="number" name="qtarif" step={.01} value={qtarif} onChange={(e) => setqtarif(e.target.value)} /></td>
            <td><textarea type="text" name="note" step={.01} value={note} onChange={(e) => setnote(e.target.value)} /></td>
            <td><input type="number" name="kcal" step={.01} value={kcal} onChange={(e) => setkcal(e.target.value)} /></td>
            <td><input type="number" name="h2o" step={.01} value={h2o} onChange={(e) => seth2o(e.target.value)} /></td>
            <td><input type="number" name="lipidi" step={.01} value={lipidi} onChange={(e) => setlipidi(e.target.value)} /></td>
            <td><input type="number" name="saturi" step={.01} value={saturi} onChange={(e) => setsaturi(e.target.value)} /></td>
            <td><input type="number" name="colesterolo" step={.01} value={colesterolo} onChange={(e) => setcolesterolo(e.target.value)} /></td>
            <td><input type="number" name="proteine" step={.01} value={proteine} onChange={(e) => setproteine(e.target.value)} /></td>
            <td><input type="number" name="carbo" step={.01} value={carboidrati} onChange={(e) => setcarboidrati(e.target.value)} /></td>
            <td><input type="number" name="amido" step={.01} value={amido} onChange={(e) => setamido(e.target.value)} /></td>
            <td><input type="number" name="zuccheri" step={.01} value={zuccherisolubili} onChange={(e) => setzuccherisolubili(e.target.value)} /></td>
            <td><input type="number" name="fibra" step={.01} value={fibra} onChange={(e) => setfibra(e.target.value)} /></td>
            <td><input type="number" name="latto" step={.01} value={lattosio} onChange={(e) => setlattosio(e.target.value)} /></td>
            <td><input type="number" name="glicemia" step={.01} value={indiceglicemico} onChange={(e) => setindiceglicemico(e.target.value)} /></td>
            <td><input type="number" name="nap" step={.01} value={naplus} onChange={(e) => setnaplus(e.target.value)} /></td>
            <td><input type="number" name="kp" step={.01} value={kplus} onChange={(e) => setkplus(e.target.value)} /></td>
            <td><input type="number" name="fe++" step={.01} value={feplusplus} onChange={(e) => setfeplusplus(e.target.value)} /></td>
            <td><input type="number" name="ca++" step={.01} value={caplusplus} onChange={(e) => setcaplusplus(e.target.value)} /></td>
            <td><input type="number" name="p" step={.01} value={p} onChange={(e) => setp(e.target.value)} /></td>
            <td><input type="number" name="mg" step={.01} value={mg} onChange={(e) => setmg(e.target.value)} /></td>
            <td><input type="number" name="zn" step={.01} value={zn} onChange={(e) => setzn(e.target.value)} /></td>								
            <td><input type="number" name="tiaminab1" step={.01} value={tiaminab1} onChange={(e) => settiaminab1(e.target.value)} /></td>
            <td><input type="number" name="riblo" step={.01} value={riboflavinab2} onChange={(e) => setriboflavinab2(e.target.value)} /></td>
            <td><input type="number" name="niacinab3" step={.01} value={niacinab3} onChange={(e) => setniacinab3(e.target.value)} /></td>
            <td><input type="number" name="acido" step={.01} value={acidofolico} onChange={(e) => setacidofolico(e.target.value)} /></td>
            <td><input type="number" name="b12" step={.01} value={vitaminab12} onChange={(e) => setvitaminab12(e.target.value)} /></td>
            <td><input type="number" name="vita" step={.01} value={vitaminaa} onChange={(e) => setvitaminaa(e.target.value)} /></td>
            <td><input type="number" name="vitc" step={.01} value={vitaminac} onChange={(e) => setvitaminac(e.target.value)} /></td>
            <td><input type="number" name="vite" step={.01} value={vitaminae} onChange={(e) => setvitaminae(e.target.value)} /></td>
            <td><input type="number" name="vitd" step={.01} value={vitaminad} onChange={(e) => setvitaminad(e.target.value)} /></td>
            <td><button onClick={updalim}>Inserisci nuovo</button></td>
        </tr>
                   
    );
    
}