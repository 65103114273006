import React from "react";
import axios from 'axios';
import '../util/config'
import Swal from "sweetalert2";
import NuovaSchedaSA from "./NuovaSchedaSA";
import RicercaSchedaSARiga from "./RicercaSchedaSARiga";
import {connect} from 'react-redux'

class RicercaSchedaSA extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            descrscheda:'',
            cognomenome:'',
               schede:[],
               dacancellare:[],
               nuovaSchedaVisible:false
	    	}
        this.update = this.update.bind(this);
        this.cercaScheda = this.cercaScheda.bind(this);
        this.showNuovaScheda = this.showNuovaScheda.bind(this);
        this.eliminaSchede = this.eliminaSchede.bind(this);
	  }

	componentDidMount(){
        
    }

    eliminaSchede(e){
        e.preventDefault()
        if (this.state.dacancellare.length===0){
            alert('Selezionare almeno una scheda da cancellare')
            return
        }
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','0001');
        formData.append('palestra',this.props.palestra.id);
          if (this.state.dacancellare){
            this.state.dacancellare.map(schedatemp=>{
                formData.append('idScheda', schedatemp);
            })
        }
        axios.post(global.config.server+'/eliminaSchedaSalaAttrezzi',formData)
	       .then((result) => {
             this.setState({messaggio:result.data});
             const Toast = Swal.mixin({
                   toast: true,
                   position: 'top-end',
                   showConfirmButton: false,
                   timer: 3000
                 });
                 Toast.fire({
                   type: 'success',
                   title: result.data
                 })
               this.props.refresh();
               this.setState({ visible: false });
	      });
    }

    showNuovaScheda(){
        this.setState({nuovaSchedaVisible:!this.state.nuovaSchedaVisible})
    }

    cercaScheda(e) {
        e.preventDefault()
        const { descrscheda,cognomenome} = this.state;
        if (descrscheda===''&&cognomenome===''){
            alert('Specificare almeno un filtro')
            return
        }
	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
          formData.append('lic','0001');
          formData.append('palestra',this.props.palestra.id);
          formData.append('descrscheda', descrscheda);
        formData.append('cognomenome', cognomenome);

	      axios.post(global.config.server+'/cercaSchedeSalaAttrezzi',formData)
	      .then((result) => {
		        this.setState({schede:result.data})
	      });
    }

	update(e) {
        if (e.target.name==='template'){
            if (e.target.checked) {
                this.setState({ [e.target.name]: 1});
            }
            else{
                this.setState({ [e.target.name]: 0});
            }
        }
        else{
          this.setState({ [e.target.name]: e.target.value});
        }
    }

	render() {
	    return (
            <div className="container-fluid">
                <NuovaSchedaSA email={this.props.email} setview={this.props.setview} />
                <a href="#appadminannotatio" onClick={()=>this.props.setview('tipoEserSA')}>Inserisci nuova tipologia esercizio</a><br />
                <hr />
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent22" aria-controls="navbarSupportedContent11" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent22">
                
                    <form className="form-inline mr-auto">
                    <input className="form-control" name="descrscheda" onChange={this.update} value={this.state.descrscheda} type="text" placeholder="descrizione scheda" aria-label="Search" />
                    <input className="form-control" name="cognomenome" onChange={this.update} value={this.state.cognomenome} type="text" placeholder="assegnata a" aria-label="Search" />
                    <button className="btn btn-mdb-color btn-rounded btn-sm my-0 ml-sm-2" onClick={this.cercaScheda}>Cerca</button>
                    </form>
                </div>
                <a className="navbar-brand" onClick={this.cercaScheda}>Cerca scheda</a>
                </nav>
                                    
          <article className="post">
          {this.state.schede&&this.state.schede.length>0?
                    <span>trovate {this.state.schede.length} schede</span>
                    :null}
        <div className="table-responsive">
            <table id="zero_config" className="table table-striped table-bordered">
                <thead>
                <tr><th>id</th><th>descrizione</th><th>Valido da</th>
                <th>Predefinita</th>
                <th>assegnata a</th>
                <th><button id="deleteSelected" onClick={this.eliminaSchede}>Elimina le schede selezionate</button></th></tr>
                </thead>
                <tbody>
                {this.state.schede&&this.state.schede.length>0?
                    this.state.schede.map(scheda=>(
                    <RicercaSchedaSARiga scheda={scheda} key={scheda.idscheda} dacancellare={this.state.dacancellare} link={this.props.link} setview={this.props.setview} />
                ))
                :<tr><td colSpan="6">Nessuna scheda trovata</td></tr>
                }
                </tbody>
                </table>
        </div>
                    </article>
                    
                    <hr /> 
        
        <article className="post">
        <div className="entry-content">
        
        </div>
         </article>
        
                </div>
	    );
	  }
	}

const mapStateToProps = state => {
    return {
        palestra: state.gestoreUtente.palestraassociata.palestra,
        email: state.gestoreUtente.emailloggato
    }
}

export default connect(mapStateToProps, null)(RicercaSchedaSA)