import React from "react";
import axios from 'axios';
import '../../util/config'
import SchedaDiarioAlimentare from "./SchedaDiarioAlimentare";
import { connect } from "react-redux";

class ListaSchedeDiarioAlimentare extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            schedeDA:[]
	    }
	  }

	componentWillMount(){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('idanagrafica',this.props.anagselezionata.id);
        axios.post(global.config.server+"/getSchedeDA", formData)
        .then(response=>{
            this.setState({schedeDA:response.data})
        });
    }

	render() {
	    return (
            this.props.anagselezionata&&this.props.anagselezionata.id?
            <div className="container-fluid">
                <SchedaDiarioAlimentare email={this.props.email} />
                <hr />
                <h4>Storico misurazioni </h4>
                <div className="table-responsive">
                <table className="table table-striped" style={{borderWidth:'1px', borderColor:'black', borderStyle:'dashed'}}>
                    <thead className="thead-dark">
                    <tr >
                            <th rowSpan={2} scope="col">
                                Data
                            </th>
                            <th rowSpan={2}scope="col">
                                Peso
                            </th>
                            <th rowSpan={2} scope="col">
                                BMR
                            </th>
                            <th rowSpan={2} scope="col">
                                TDEE
                            </th>
                            <th rowSpan={2} scope="col">
                                Calorie giornaliere
                            </th>
                            <th scope="col" colSpan={4} style={{backgroundColor:"gray"}}>
                                Proteine
                            </th>
                            <th scope="col" colSpan={4} style={{backgroundColor:"green"}}>
                                Carboidrati
                            </th>
                            <th scope="col" colSpan={4} style={{backgroundColor:"yellow", color:"black"}}>
                                Lipidi
                            </th>
                            <th scope="col" rowSpan={2}>
                                Calorie assunte giorno
                            </th>
                        </tr>
                        <tr>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                Gr/Kg
                            </th>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                Gr Tot.
                            </th>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                Kcal
                            </th>
                            <th scope="col" style={{backgroundColor:"gray"}}>
                                %
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                Gr/Kg
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                Gr Tot.
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                Kcal
                            </th>
                            <th scope="col" style={{backgroundColor:"green"}}>
                                %
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                Gr/Kg
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                Gr Tot.
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                Kcal
                            </th>
                            <th scope="col" style={{backgroundColor:"yellow", color:"black"}}>
                                %
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.schedeDA.map(scheda=>(
                        <tr>
                            <td>
                                {scheda.data}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.peso}
                            </td>
                            <td style={{textAlign:'right'}}>
                                {scheda.mediabmr}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.mediatdee}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.caloriedaassumeregiorno}</strong>
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteinegrkg}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteinegrtot}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteinekcal}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.proteineperc}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratigrkg}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratigrtot}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratikcal}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.carboidratiperc}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidigrkg}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidigrtot}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidikcal}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.lipidiperc}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.calorieassuntegiorno}</strong>
                            </td>
                        </tr>
                            ))
                        }
                    
                    </tbody>
                </table>
                </div>
			</div>
            :"E' necessario selezionare un'anagrafica"
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato,
            idanagselezionata: state.gestoreUtente.idanagselezionata,
            anagselezionata : state.gestoreUtente.anagselezionata
        }
    }
    
    export default connect(mapStateToProps, null)(ListaSchedeDiarioAlimentare)