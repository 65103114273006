import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { caricatemplates, eliminatemplate } from "../../DAO/DocumentiDAO";
import {A, navigate} from 'hookrouter'
import { setdocumentoselezionato } from "../../STORE/reducers/gestoreDocumenti";

export default function ListaTemplateDoc(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const dispatch = useDispatch()

    const [templates, settemplates] = useState([])

    function gotodettdoc(template){
        dispatch(setdocumentoselezionato(template))
        navigate('/annotatio-doc-dett/');
    }

    function templatedocc(){
        async function carica(){
            let result = await caricatemplates(palestra.palestra.id);
            //result.map(templ => templ.contenuto(getStringFromBlob(templ.contenuto.wrappedBlob)))
            console.log(result)
            settemplates(result)
        }
        carica();
    }

    function eliminadoc(id){
        async function elimina(){
            const result = await eliminatemplate(palestra.palestra.id, id);
            alert(result)
            templatedocc()
        }
        if (window.confirm('Sei sicuro di voler eliminar il template selezionato?')){
            elimina();
        }
    }


    useEffect(() => {
        templatedocc()
    },[palestra])

    return(
        <div className="container-fluid">
            <h4>Lista Template documenti</h4>
            <button onClick={() => navigate('/annotatio-doc-new/')}>Nuovo template</button>
            <table className="table">
                <thead>
                    <th>Descrizione</th>
                    <th></th>
                </thead>
                <tbody>
            {templates&&Array.isArray(templates)&&templates.map(template => (
                <tr>
                    <td><a onClick={() => gotodettdoc(template)}>{template.descrizione}</a> ({template.html})</td>
                    <td><button className="btn btn-danger" onClick={() => eliminadoc(template.id)}>Elimina</button></td>
                </tr>
            )
            )}
            </tbody>
            </table>
        </div>
    );
}