import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputText from "../../util/UI/InputText";
import MyInput from "../../util/UI/MyInput";
import { savetemplate } from "../../DAO/DocumentiDAO";

export default function DettTemplateDoc(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const template = useSelector(state => state.gestoreDocumenti.docselezionato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);
    const [descr, setdescr] = useState(template.descrizione?template.descrizione:'')
    const [html, sethtml] =useState(template.html?template.html:'')
    const [id, setid] =useState(template.id?template.id:'')
    

    function savetempl(){
        async function save(){
            const result = await savetemplate(palestra.palestra.id, id, descr, html,anagselezionata.id)
        }
        save()
    }

    
    return(
        <div className="container-fluid">
            <style>
                {`@media print {
  body {
    visibility: hidden;
  }
  #section-to-print {
    visibility: visible;
    position: absolute;
    left: 0;
    top: 0;
  }
}`}
            </style>
            <h4>Dettaglio Template documento {template.id}</h4>
            <div className="row">
                <InputText bootstrapsize={"col-md-4"}
                id={template.id}
                label={"descrizione"}
                name={"descr"}
                onChange={(e) => setdescr(e.target.value)}
                placeholder={"Descrizione"}
                type={"text"}
                value={descr}
                 />
            </div>
            <div className="row" >
                <MyInput sethtml={sethtml} defaultcontent={html} />
            </div>
        <div>
            <button className="btn btn-primary" onClick={savetempl}>Salva</button>
        </div>
        </div>
               
    );
}