import React from "react";
import axios from 'axios';
import { Form } from "react-bootstrap";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function ScontiSelect (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [sconti, setsconti] = useState([]);
	const [selezionato, setselezionato] = useState(props.selected);

	 useEffect(() =>{
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.id);
		axios.post(global.config.server+"/listaSconti", formData)
		  .then(response=>{
			  setsconti(response.data);
			  //console.log(response);
		  });
	  }, [palestra]);

	    return (
			<Form.Control as="select" className="form-control" size="sm" 
			onChange={props.onChange} name="sconto" value={props.selected?props.selected:""}>
				<option key={-1} value="">--nessuno sconto--</option>
				{
					sconti.map((item,index) =>(
					<option key={index} value={item.id} >{item.descrizione} - ({item.valore_sconto})</option>
					)
	    			)
	    		}
			</Form.Control>
	    );
	}
