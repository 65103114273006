import React, { useState } from "react";
import '../../util/config'
import { useSelector } from "react-redux";
import { aggiornaTipoProdotto } from "../../DAO/tipiprodottiDAO";
import NomiCalendariSelect from "../corsi/NomiCalendariSelect";
import SelectText from "../../util/UI/SelectText";
import InputText from "../../util/UI/InputText";

export default function NuovaTipologiaInput(props) {
	
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [nome, setnome] = useState('');
    const [descrizione, setdescrizione] = useState('');
    const [nomecalendario, setnomecalendario] = useState('');
    const [visibile, setvisibile] = useState('');
    const [ordine, setordine] = useState('1');
    const [foto, setfoto] = useState('');
    const [fotoTemp, setfotoTemp] = useState(null);
	const [filename, setfilename] = useState(null);
	const [filetype, setfiletype] = useState(null);
	const [messaggio, setmessaggio] = useState('');
	const [visible, setvisible] = useState(false);
	const [visualizzazione, setvisualizzazione]= useState('');
	const listaTipoVisualizzazione=[{name:'calendario',value:'calendario'},{name:'lista',value:'menu'},{name:'menu composto',value:'menucomposto'}]
	const listaVisibileUtenti=[{name:'SI',value:1},{name:'NO',value:0}]

    function salva(){
        async function salvaTipo(){
          
            const result = await aggiornaTipoProdotto(email, palestra.palestra.id,
                0, nome, descrizione, nomecalendario,visibile, ordine, foto, fotoTemp,visualizzazione);
            setmessaggio(result)
        }
		if (!nome){
			alert('Nome obbligatorio')
			return
		}
		if (!descrizione){
			alert('Descrizione obbligatorio')
			return
		}
		if (!nomecalendario){
			alert('Nome calendario obbligatorio')
			return
		}
        salvaTipo();
    }

	function handleInputChange(event) {
        if (event.target.files && event.target.files[0]) {
			console.log(event.target.files[0])
			let img = event.target.files[0];
			let reader = new FileReader();
			reader.readAsDataURL(img);
			reader.onload = (e) => {
				setfotoTemp(e.target.result)
				setfilename(img.name)
				setfiletype(img.type)
			}
		}
    }

		return (
			<form className="form-horizontal" method="post">
				<div className="row">
					<div className="col" style={{backgroundColor: "#D0D0D0"}}>
						<h4 onClick={()=> setvisible(!visible)}>Nuova Categoria</h4>
					</div>
				</div>

				{visible && <div className="row">
					<span>{messaggio}</span>
					<div className="col-md-3">
						<label className="control-label">Nome</label>
						<input className="form-control" type="text" onChange={(e) => setnome(e.target.value)} maxLength="50" name="nome" value={nome} required="required" />
					</div>
					<div className="col-md-5">
						<label className="control-label">Descrizione</label>
						<input className="form-control" type="text" onChange={(e) => setdescrizione(e.target.value)} maxLength="200" name="descrizione" value={descrizione} required="required" />
					</div>
					<div className="col-md-4">
						<label className="control-label">Calendario di appartenenza</label>
						<NomiCalendariSelect onChange={setnomecalendario} selected={nomecalendario} />
					</div>
					<SelectText bootstrapsize={"col-md-3"} 
							label={"Visibile agli utenti"}
							placeholder={"Visibile agli utenti"}
							lista={listaVisibileUtenti}
							value={visibile}
							onChange={(e) => setvisibile(e.target.value)}/>

					<SelectText bootstrapsize={"col-md-6"} 
							label={"Tipo di visualizzazione prodotti"}
							placeholder={"Tipo di visualizzazione prodotti"}
							lista={listaTipoVisualizzazione}
							value={visualizzazione}
							onChange={(e) => setvisualizzazione(e.target.value)}/>
					
					<InputText bootstrapsize={"col-md-3"}
					 label={"Ordine"}
					 onChange={(event) => {setordine(event.target.value)}}
					 placeholder={"Ordine"}
					 type={"number"} value={ordine} />
					<div className="col-12">
						Immagine: <img src={foto} />
						<br />
						<label>Link foto <input type="text" name="foto" onChange={(e) => setfoto(e.target.value)} value={foto} /></label>
						<br />
						<label>Oppure Seleziona immagine</label>
								<input type="file" name="fotoTemp" onChange={handleInputChange} />
							</div>
					<div className="col">
						<a type="button" className="btn btn-primary" onClick={salva} style={{color:'white'}}>Salva modifiche</a>
						{messaggio}
					</div>
				</div>}
			</form>
	);
}