import {COMUNI} from './comuniitaliani'
const MESI = { A: '01', B: '02', C: '03', D: '04', E: '05', H: '06', L: '07', M: '08', P: '09', R: '10', S: '11', T: '12' };

export function comuneCf (codiceFiscale) { return codiceFiscale.charAt(12).toUpperCase() === 'Z' ? "Estero" : COMUNI[codiceFiscale.substring(11,15).toUpperCase()]; }

export function dataCf (codiceFiscale) {
	let [ anno, giorno ] = [ codiceFiscale.substring(6,8), codiceFiscale.substring(9,11) ];
	if (giorno>40) {
		giorno -= 40;
		giorno = ("0" + giorno).slice(-2);
	}
	return (anno < 20 ? "20" : "19" ) + anno + "-" + MESI[codiceFiscale.charAt(8)] + "-" + giorno;
}

export function sessoCf (codiceFiscale) { return codiceFiscale.substring(9,11) > 40 ? "F" : "M"; }