import React, { useEffect, useState } from "react";
import AlimentoSchedadieta from "./AlimentoSchedadieta";
import InputText from "../../util/UI/InputText";
import SelectText from "../../util/UI/SelectText";
import Bottone from "../../util/UI/Bottone";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faTrash} from '@fortawesome/free-solid-svg-icons'

const listapasti=[
    {name:'COLAZIONE', value:'colazione'},
    {name:'SPUNTINO 1', value:'spuntino 1'},
    {name:'SPUNTINO 2', value:'spuntino 2'},
    {name:'PRANZO', value:'pranzo'},
    {name:'CENA', value:'cena'},
]

export default function DettGiornoSchedadieta({giornoscheda, handleChangeGiorni, 
    indexgiorno, handleChangeAlimenti, addriga, listaalimenti, deletegiorno, addpasto}){

    const [alimenti, setalimenti] = useState(giornoscheda.alimenti)

    function addalimento(){
        let alitemp={
            alimento:'',
            alimentobo:null,
            quantita:'',
        };
        setalimenti([...alimenti,alitemp]) 
    }

    const delalimento = (index)=>{
        const rows = [...alimenti];
        rows.splice(index, 1);
        setalimenti(rows);
        handleChangeAlimenti(indexgiorno,rows)
   } 
 
   function handleChangeG (index, evnt){
    const { name, value } = evnt.target;
    const rowsInput = [...alimenti];
   if (name==='alimento'){
    
    rowsInput[index][name] = value.id; //value=ALIMENTOBO
    rowsInput[index]['alimentobo'] = value; //value=ALIMENTOBO
   }
    else{
        const rowsInput = [...alimenti];
        rowsInput[index][name] = value;
    }
    setalimenti(rowsInput);
    handleChangeAlimenti(indexgiorno,rowsInput)
    
}

    return (
        
            <tr >
                <td>
                <InputText 
                id={'giorno'} label={'Giorno'} name={'giorno'} 
                placeholder={'Num. Giorno'} type={'text'}
                value={giornoscheda.giorno} 
                onChange={(evnt)=>handleChangeGiorni(indexgiorno, evnt)}
                 />
                 
                 <Bottone bootstrapstyle={"secondary"} label={<span><FontAwesomeIcon icon={faPlusCircle} /> Pasto</span>} onClick={() => addpasto(giornoscheda.giorno)} />
                 <Bottone bootstrapstyle={"danger"} label={<FontAwesomeIcon icon={faTrash} />} onClick={() => deletegiorno(indexgiorno)} />
                 </td>
                 <td>
                 
                <SelectText
                id={'pasto'} label={'Pasto'} name={'pasto'} 
                placeholder={'Seleziona un pasto'}
                value={giornoscheda.pasto} 
                onChange={(evnt)=>handleChangeGiorni(indexgiorno, evnt)}
                lista={listapasti}
                 />
                <Bottone bootstrapstyle={"success"} label={<span><FontAwesomeIcon icon={faPlusCircle} /> Alimento</span>} onClick={addalimento} />
                </td>
                <td>
                
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Alimento</th>
                                <th>Qta gr</th>
                                <th>kcal</th>
                                <th>prot.</th>
                                <th>carbo</th>
                                <th>zuccheri</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                {
                    alimenti.map((alimento, indexG) => (
                        <AlimentoSchedadieta key={indexG} alimento={alimento}
                        listaalimenti={listaalimenti}
                        delalimento={delalimento}
                         handleChangeG={handleChangeG} index={indexG} />
                    ))
                }
                
                </tbody>
                </table>
                
                </td>
            </tr>

    );
}