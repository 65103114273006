import React from "react";

export default class RiquadroLogo extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
              ultimeIscrizioni:null
      };
    
    }

    componentDidMount(){
          console.log("RiquadroReport")
	  }
  
    componentDidCatch(error, info) {
          // You can also log the error to an error reporting service
          console.log(error, info);
        }
  
    render() {
      return (
        <a href="/">
        <img 
        src={localStorage.getItem("logo")?localStorage.getItem("logo"):"https://annotatio.online/wp-content/uploads/2022/02/logoannotatio.png"} 
        title={localStorage.getItem("palestra")}
        alt={localStorage.getItem("palestra")}
        style={{maxHeight:'300px', float:'left', marginRight:'15px'}}
         />
         </a>
        );
    }
  }