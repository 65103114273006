import React, { useEffect, useState } from "react";
import '../../util/config'
import TipologiaInput from "./TipologiaInput";
import NuovaTipologiaInput from "./NuovaTipologiaInput";
import { useSelector, useDispatch } from "react-redux";
import { getTipi } from "../../DAO/tipiprodottiDAO";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadMainThings from "../../util/LoadMainThings";

export default function TipologieCorso(props)  {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [tipi, settipi] = useState([]);
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())
    const [expanded, setExpanded] = useState(false);

    const dispatch = useDispatch();
	
	const handleChange =
    (idtipo, panel, isExpanded) => {
	  setExpanded(isExpanded ? idtipo : false);
    };

	function gettipi(){
        async function gettipiprod(){
            if (palestra&&palestra.id){
                console.log("palestra NON nulla")
                const result=await getTipi(palestra.id, '', '', '');
                //console.log(result);
                settipi(result);
            }
        }
        gettipiprod();
    }

    useEffect(() => {
        console.log("useEffect")
        gettipi();
    },[palestra, keytorefresh])

    return (
    		<div>
                <NuovaTipologiaInput 
			setkeytorefresh={setkeytorefresh} />
    		{
    			tipi.map(item =>(
                    <Accordion key={item.id} expanded={expanded === item.id} onChange={(event,isExpanded) => handleChange(item.id,event, isExpanded)}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={item.id+"bh-content"}
										id={item.id+"bh-header"}
                                        style={{color:item.visibile?"green":"orange"}}>
											<Typography sx={{ width: '33%', flexShrink: 0 }} style={{fontWeight:"bold"}}>
												{item.nome}
											</Typography>
                                            
									</AccordionSummary>
									<AccordionDetails>
                            <TipologiaInput key={item.id} selected={item} 
                            setkeytorefresh={setkeytorefresh} />
                        </AccordionDetails>
                        </Accordion>
    					)
    			)
    		}
			<NuovaTipologiaInput 
			setkeytorefresh={setkeytorefresh} />
    		</div>
    );
}