import axios from 'axios';
import serverInfo from '../util/globals';

export async function cercaschededieta(idanag, palestra) {
  const formData = new FormData();
  formData.append('palestra', palestra);
  formData.append('idanag', idanag);
  const response = await axios.post(
    serverInfo.namesec+'/ali/getschede',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function getscheda(idscheda) {
    const formData = new FormData();
    formData.append('idscheda', idscheda);
    const response = await axios.post(
      serverInfo.namesec+'/ali/getscheda',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }

  export async function getultimascheda(idanag) {
    const formData = new FormData();
    formData.append('idanag', idanag);
    const response = await axios.post(
      serverInfo.namesec+'/ali/getultimascheda',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }

  export async function salvaschedadieta(giornischeda, idpalestra, id, data, idanag) {
    const formData = new FormData();
    formData.append('palestra', idpalestra);
    formData.append('id', id);
    formData.append('data', data);
    formData.append('idanag', idanag);
    formData.append('giornischeda', giornischeda);
    const response = await axios.post(
      serverInfo.namesec+'/ali/insscheda',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }