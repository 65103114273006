import axios from "axios";
import serverInfo from "../util/globals";
import '../util/config'

export async function cercafatture(nomecognomecodfisc, nome, cognome, codicefiscale, numero, datada, dataa, idpalestra, email) {
    const formData = new FormData();
    formData.append('nomecognomecodfisc', nomecognomecodfisc);
    formData.append('nome', nome);
    formData.append('cognome', cognome);
    formData.append('codicefiscale', codicefiscale);
    formData.append('numero', numero);
    formData.append('datada', datada);
    formData.append('dataa', dataa);
    formData.append('website', global.config.domain);
    formData.append('email', email);
    formData.append('idpalestra', idpalestra);
    
    const response = await axios.post(
      global.config.server+'/cercafatture',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }

  export async function inseriscifattura(nome, cognome, codicefiscale, indirizzo, citta, cap, sesso, datanascita, cittanascita, telefono, emailanag, 
    numero, anno, data,iva,righe, 
    reversecharge, 
    datapag, importopag, tipopag, 
    idpalestra, email) {
		const formData = new FormData();

		formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('anno', anno?anno:new Date().getFullYear());
		formData.append('data', data);
    formData.append('emailanag', emailanag?emailanag:'');
		formData.append('numero', numero);
		formData.append('iva', iva?iva:0);
		formData.append('righe', JSON.stringify(righe));
		formData.append('palestra',idpalestra);//
		formData.append('reversecharge', reversecharge&&reversecharge===1?1:0);
    formData.append('nome', nome?nome:'');//
    formData.append('cognome', cognome?cognome:'');//
    formData.append('codicefiscale', codicefiscale);//
    formData.append('indirizzo', indirizzo?indirizzo:'');//
    formData.append('citta', citta?citta:'');//
    formData.append('cap', cap?cap:'');//
    formData.append('sesso', sesso?sesso:'');//
    formData.append('datanascita', datanascita?datanascita:'');//
    formData.append('luogonascita', cittanascita?cittanascita:'');//
    formData.append('telefono', telefono?telefono:'');//
    formData.append('datapag', datapag?datapag:'');//
    formData.append('importopag', importopag?importopag:'');//
    formData.append('tipopag', tipopag?tipopag:'');//

		const response = await axios.post(global.config.server+'/aggiornaFatturaRest/0',formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function getNumeroFattura(idpalestra, email) {
  const formData = new FormData();
  formData.append('website', global.config.domain);
  formData.append('email', email);
  formData.append('palestra', idpalestra);
  const response = await axios.post(
    global.config.server+"/getNumeroFattura",
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function modificafattura(idfattura, nome, cognome, codicefiscale, indirizzo, citta, cap, sesso, datanascita, luogonascita, telefono, emailanag,
   numero, anno, data,iva,righe, 
   reversecharge,  
   idpagamento, datapag, importopag, tipopag,
   idpalestra, email) {
  const formData = new FormData();

  formData.append('website', global.config.domain);
  formData.append('email',email);
  formData.append('anno', anno?anno:new Date().getFullYear());
  formData.append('data', data);
  formData.append('emailanag', emailanag);
  formData.append('numero', numero);
  formData.append('iva', iva?iva:0);
  formData.append('righe', JSON.stringify(righe));
  formData.append('palestra',idpalestra);//
  formData.append('reversecharge', reversecharge&&reversecharge===1?1:0);
  formData.append('nome', nome);//
  formData.append('cognome', cognome);//
  formData.append('codicefiscale', codicefiscale);//
  formData.append('indirizzo', indirizzo);//
  formData.append('citta', citta);//
  formData.append('cap', cap);//
  formData.append('sesso', sesso);//
  formData.append('datanascita', datanascita);//
  formData.append('luogonascita', luogonascita?luogonascita:'');//
  formData.append('telefono', telefono?telefono:'');//
  formData.append('idpagamento', idpagamento?idpagamento:0);//
  formData.append('datapag', datapag?datapag:'');//
  formData.append('importopag', importopag?importopag:'');//
  formData.append('tipopag', tipopag?tipopag:'');//

  const response = await axios.post(global.config.server+'/aggiornaFatturaRest/'+idfattura,formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
);
return response.data;
}

export async function getFattura(idfattura) {
  const formData = new FormData();
  formData.append('idfattura', idfattura);
  const response = await axios.post(
    global.config.server+"/getfattura",
    formData,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function  sendEmailFattura(idfatt, email, idpalestra) {

    const formData = new FormData();
    formData.append('id', idfatt);
  formData.append('website', global.config.domain);
      formData.append('email',email);
  formData.append('lic','000');
  formData.append('palestra',idpalestra);
  const response = await axios.post(global.config.server+'/inviaEmailPdfFatturaRest',
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
    );
    return response.data;
  }

  export async function  esportafatture(fatture, email, idpalestra, filename, emailexport) {

    const formData = new FormData();
    formData.append('idfattura', fatture);
    formData.append('website', global.config.domain);
    formData.append('email',email);
    formData.append('lic','000');
    formData.append('palestra',idpalestra);
    formData.append('emailexport',emailexport);
    
  const response = await axios.post(global.config.server+'/exportxml',
  formData,
  {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/gzip',
      'Accept': 'application/gzip',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
  
    )
    .then((response) => {
      if (!emailexport||emailexport===''){
        const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();
      }
      else{
      alert('Export inviato correttamente')
      }
    })

  }

  export async function  cancellafattura(idfattura, email, idpalestra) {

    const formData = new FormData();
    formData.append('email', email);
    formData.append('palestra', idpalestra);
    formData.append('website', global.config.domain);
  const response = await axios.post(global.config.server+'/eliminaFatturaRest/'+idfattura,
  formData,
  {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'POST',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      'Access-Control-Allow-Credentials': 'true',
      'Authorization' :'Bearer '+window.$t
    },
  },
    );
    return response.data;
  }