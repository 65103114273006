import React from "react";
import '../util/config'

import { Button } from "react-bootstrap";
import { navigate } from 'hookrouter'

export default class RicercaSchedaSARiga extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
               scheda:null,
	    }
        this.seleziona = this.seleziona.bind(this);
        this.selezionaPerCancellazione=this.selezionaPerCancellazione.bind(this);
	  }

	componentDidMount(){
    }

    seleziona(e) {
        localStorage.setItem('idscheda',this.props.scheda.idscheda)
        if (this.props.link){
            window.location.href = "/"+this.props.link
        }
        else{
            navigate('/anntt-adm-dettsa/',true);
        }
    }


    selezionaPerCancellazione(e) {
        var dacancTemp=this.props.dacancellare;
        if (e.target.checked){
            dacancTemp.push(e.target.value)
        }
        else{
            var carIndex = dacancTemp.indexOf(e.target.value);
            dacancTemp.splice(carIndex, 1);
        }
    }

	render() {
	    return (
                    <tr>
                        <td>
                        <Button onClick={this.seleziona}> {this.props.scheda.idscheda}</Button>
                       </td>
                        <td>{this.props.scheda.descrizione}</td>
                        <td>{this.props.scheda.validoDa}</td>
                        <td>{this.props.scheda.template&&this.props.scheda.template===1?"SI":"NO"}</td>
                    <td>{this.props.scheda.anagrafica?this.props.scheda.anagrafica.nome+" "+this.props.scheda.anagrafica.cognome:null}</td>
                        <td><input type="checkbox" name={"cancella"} value={this.props.scheda.idscheda} onChange={this.selezionaPerCancellazione} /></td>
                        <td></td>
                    </tr>
	    );
	  }
	}