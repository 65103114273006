import React from "react";
import axios from 'axios';
import '../util/config'
import QrReader from 'react-qr-scanner'
import {Helmet} from "react-helmet";
import { connect } from "react-redux";

class ControlloTornello1 extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            risultato:null,
            data:'',
            delay: 100,
            code:'',
            inizioLettura:true,
            tornello:'tornello1'
        };
        this.update=this.update.bind(this)
        this.handleSubmit=this.handleSubmit.bind(this)
        this.handleScan = this.handleScan.bind(this)
    }


    handleScan(data){
        if (data&&this.state.inizioLettura){
            this.setState({inizioLettura:false})
            console.log(data)
            setTimeout(()=>{
                this.setState({code: data.text})
                this.handleSubmit(null)
            },100)
            
        }
    }
    handleError(err){
      console.error(err)
    }

      componentDidMount(){
          if (localStorage.getItem("tornello")){
              this.setState({tornello:localStorage.getItem("tornello")})
          }
      }

    update(e) {
        this.setState({ [e.target.name]: e.target.value, visible: true });
        if (e.target.name==='tornello'){
            localStorage.setItem("tornello",e.target.value)
        }
    }

    handleSubmit(e) {
        if (e)e.preventDefault()
        const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
		formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('code',this.state.code);
        formData.append('tornello',this.state.tornello);
        axios.post(global.config.server+"/checkValiditaCode",formData)
        .then(response=>{
          console.log("Check Tornello1: ")
          console.log(response)
            console.log(response.data)
            this.setState({risultato: response.data});
            this.setState({inizioLettura:true})
            if (response.data.descrValidita==="OK"
			&&response.data.pagamenti.indexOf("Pagamento scaduto")<0){
            this.setState({background:'green'})
            }
            else{
                this.setState({background:'red'})
            }
            this.setState({code:''})
            
        })
        .catch(err => {
            if (err.response){
              alert("accessi 1: "+err.response.data.message);
              this.setState({inizioLettura:true})
            }
            else{
              alert("accessi 2: "+err.response);
              this.setState({inizioLettura:true})
            }
      });
  }

render() {
    
    const previewStyle = {
        height: 240,
        width: 320,
      }
      
    return (
        
        <div style={{backgroundColor:this.state.background}}>
            <div className="container">
                <div className="row text-center">
                    <h1>{this.state.tornello}
                <select name="tornello" onChange={this.update} value={this.state.tornello}>
                  <option value="tornello1">TORNELLO 1</option>
                  <option value="tornello2">TORNELLO 2</option>
                </select></h1>
                    <form method="post" onSubmit={this.handleSubmit}>
                        <input type="text" name="code" value={this.state.code} 
                        onChange={this.update} required={true} 
                        autofocus="autofocus" autoComplete="false"/>
                    </form>
                    <QrReader
                        delay={this.state.delay}
                        style={previewStyle}
                        onError={this.handleError}
                        onScan={this.handleScan}
                 />

                    {!this.state.risultato?
                    <h1>Nessun utente associato
                        {window.ciccio="Nessun utente associato"}
                        <Helmet>
                        <script>responsiveVoice.speak(window.ciccio,"Italian Female");</script></Helmet>
                    </h1>
                    :
                        this.state.risultato.descrValidita!=='OK'?
                        <h2>{this.state.risultato.descrValidita}
                        {window.ciccio=this.state.risultato.descrValidita}
                        <Helmet>
                        <script>responsiveVoice.speak(window.ciccio,"Italian Female");</script></Helmet>
                        </h2>
                        :
                        this.state.risultato&&this.state.risultato.pagamenti.indexOf("Pagamento scaduto")>=0?
                            <h2>{this.state.risultato.pagamenti}
                            {window.ciccio="Codice non valido"}
                            <Helmet>
                            <script>responsiveVoice.speak("Codice non valido","Italian Female");</script></Helmet>
                            </h2>
                                :
                                <span>
                                <h1>{this.state.risultato.nomeCognome}</h1>
                                <h3>{this.state.risultato.validitaAbbonamento}</h3>
                                <h3>{this.state.risultato.tipoAbbonamento}</h3>
                                {window.ciccio=this.state.risultato.nome}
                                <Helmet>
                                <script>responsiveVoice.speak("Ciao "+window.ciccio,"Italian Female");</script>
                                </Helmet>
                                {this.state.risultato.pagamenti!=="Non risultano pagamenti scaduti"?
                                    <h3 style={{backgroundColor: 'red'}}>{this.state.risultato.getPagamenti}</h3>
                                    :null
                                }
                                {this.state.risultato.descrValidita!=="OK"?
                                    <h3 style={{backgroundColor: 'red'}}>{this.state.risultato.descrValidita}</h3>
                                    :null
                                }
                                </span>
                    }
                    </div>
                    </div>
        </div>
);
  }
}

const mapStateToProps = state => {
    return {
      palestra: state.gestoreUtente.palestraassociata.palestra,
      email: state.gestoreUtente.emailloggato,
      idanagselezionata: state.gestoreUtente.idanagselezionata
    }
  }
  
  export default connect(mapStateToProps, null)(ControlloTornello1)