import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RigaSchededieta from "./RigaSchedadieta";
import { cercaschededieta } from "../../DAO/schededietaDAO";
import {navigate} from 'hookrouter'
import Bottone from "../../util/UI/Bottone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

export default function CercaSchededieta(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [alimento, setalimento] = useState('');
    const [schede, setschede] = useState([]);

    function cercaschede(){
        async function cercasch(){
            const result = await cercaschededieta(anagselezionata.id, palestra.id);
            setschede(result);
        }
        cercasch();
    }

    useEffect(() => {
        if (anagselezionata){
            cercaschede()
        }
    },[anagselezionata])

    return (
        <div className="container-fluid">
            
            <div className="row">
            <h4>Piani alimentari</h4>
                <Bottone bootstrapsize={"col-md-3"}
                bootstrapstyle={"success"}
                label={<FontAwesomeIcon icon={faPlusCircle} />}
                onClick={() => navigate('/annotatio-ali-newscheda/')} />
            </div>
            <div className="row">
                <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Anagrafica</th>
                        <th>Data inizio</th>
                    </tr>
                </thead>
                <tbody>
                {schede.map(scheda => (
                    <RigaSchededieta scheda={scheda} />
                ))}
                </tbody>
                </table>
            </div>
        </div>
    );
}