import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import { useSelector } from "react-redux";

export default function NomiCalendariSelect (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [nomi, setnomi] = useState(props.nomi)
	const [calendarioselezionato, setcalendarioselezionato] = useState(props.nomi)
	const [nuovocalendario, setnuovocalendario] = useState('')
	 
	useEffect(() =>{
		if(!nomi){
			const formData = new FormData();
			formData.append('website',palestra.website);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			axios.post(global.config.server+"/getNomiCalendari",formData)
			.then(response=>{
				setnomi(response.data)
			}).catch(err => {
				if (err.response){
				alert(err.response.data.message);
				}
				else{
				alert(err.response);
				}
		});
		}
	  }
	);
	
	    return (
            <span>
			
			<select id="nomecalendario" value={props.selected} onChange={(e) => {setcalendarioselezionato(e.target.value);setnuovocalendario('');props.onChange(e.target.value)}}>
			{nomi&&Array.isArray(nomi)&&nomi.length>0?
					nomi.map(nome =>(
					<option key={nome} value={nome}>{nome}</option>
					)
					)
					:null}
			</select>
			<label>Oppure inseriscine uno nuovo
			<input type="text" list="nomi" name="nomecalendario" value={nuovocalendario} onChange={(e) =>{setnuovocalendario(e.target.value); setcalendarioselezionato(''); props.onChange(e.target.value)}} />
			</label>
				</span>
	    );
	}