import axios from "axios";
import serverInfo from "../util/globals";
import '../util/config'

export async function getpagamentofattura(idfattura, email, idpalestra) {
    const formData = new FormData();
    formData.append('idfattura', idfattura);
    formData.append('website', global.config.domain);
    formData.append('email', email);
    formData.append('palestra', idpalestra);

    const response = await axios.post(
      global.config.server+"/getPagamentiFatt/"+idfattura,
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }