import React from "react";
export default function TipiPagamentoSelect(props) {

	return (
			<select className="form-control" onChange={props.update} name="tipipagamento" value={props.selected}>
				<option value="">-- tutti i tipi di pagamento --</option>
				<option value="contanti">Contanti</option>
				<option value="carta">Bancomat/Carta</option>
				<option value="bonifico">Bonifico</option>
				<option value="assegno">Assegno</option>
				<option value="paypal">Paypal</option>
				<option value="alma">Alma</option>
			</select>
	    );
}