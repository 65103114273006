import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { cercafatture, getNumeroFattura, inseriscifattura } from "../DAO/fattureDAO";
import NuovaRiga from "./NuovaRiga";
import { getcorsi } from "../DAO/corsiDAO";
import { getlistini } from "../DAO/listiniDAO";
import { cercaAnagrafica, cercaAnagrafiche2, getAnagrafica } from "../DAO/anagraficaDAO";
import { comuneCf, dataCf, sessoCf } from "../util/calcolocodfisc";
import { setidfattura } from "../STORE/reducers/gestoreFattura";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faEuroSign, faSave, faPlusSquare, faUserAlt, faAddressCard, faFileInvoice, faSearch} from '@fortawesome/free-solid-svg-icons'
import {navigate, A} from 'hookrouter'
import { calcolocodfisc } from "../util/codfisc";
import ComuniItalianiJson from "../util/ComuniItalianiJson";

var timeout=0
// let righe = [
//     {idrigafattura:'_' + Math.random().toString(36).substr(2, 9),
// quantita:1, 
//     costo:0, 
//     descrizione: "",
//     aliquotaiva:0,
//     fenatura:'',
//     idcorso:0
// }]
export default function InsFattura (props) {
    

	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const dispatch = useDispatch();

    const[nome, setnome] = useState('')
    const[indirizzo, setindirizzo] = useState('')
    const[citta, setcitta] = useState('')
    const[cap, setcap] = useState('')
    const[cognome, setcognome] = useState('')
    const[codicefiscale, setcodicefiscale] = useState('')
    const[numero, setnumero] = useState('')
    const[data, setdata] = useState(new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2))
    const[anno, setanno] = useState(new Date().getFullYear())
    const [iva, setiva] = useState(0);
    const [importoiva, setimportoiva] = useState(0);

    const [totlordo, settotlordo] = useState(0);
    const [prodotti, setprodotti] = useState([])
    const [listini, setlistini] = useState([])
    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());
    const [reversecharge, setreversecharge] =useState(0);
    const [tesserasanitaria, settesserasanitaria] = useState('')
    const [datanascita, setdatanascita] = useState('')
    const [luogonascita, setluogonascita] = useState('')
    const [sesso, setsesso] = useState('')
    const [idanag, setidnang] = useState(0)
    const [messaggio, setmessaggio] = useState('')
    const [telefono, settelefono] = useState('')
    const [emailanag, setemailanag] = useState('')
    
    const[datapag, setdatapag] = useState(new Date().getFullYear()+"-"+(("0" + (new Date().getMonth() + 1)).slice(-2))+"-"+("0" + new Date().getDate()).slice(-2))
    const [importopag, setimportopag] = useState(0);
    const [tipopag, settipopag] = useState('')
    const[nomecognomecodfisc, setnomecognomecodfisc] = useState('')
    const [anagrafiche, setanagrafiche] = useState([])
    const [loading, setloading] = useState(false)
    const [showcomunipernascita, setshowcomunipernascita] = useState(false);
    const [showcomunipercitta, setshowcomunipercitta] = useState(false)
    const [showcomunipercap, setshowcomunipercap] = useState(false)
    const [righe, setrighe] = useState([{idrigafattura:'_' + Math.random().toString(36).substr(2, 9),
    quantita:1, 
    costo:0, 
    descrizione: "",
    aliquotaiva:0,
    fenatura:'',
    idcorso:0
}]);

    function cercaanagbyid(idanag){
        
        console.log('cercaanagbyid '+idanag)
        async function cercanaag(){
            const result=await getAnagrafica(email, '000', palestra.palestra.id,idanag)
            if (result){
                setmessaggio('Anagrafica trovata')
                setidnang(result.id)
                setcognome(result.cognome)
                setnome(result.nome)
                setdatanascita(result.datanascita)
                setsesso(result.sesso)
                setluogonascita(result.luogonascita)
                setindirizzo(result.indirizzo)
                setcap(result.cap)
                setcitta(result.citta)
                settelefono(result.telefono)
                setemailanag(result.email)
                setcodicefiscale(result.codicefiscale)
            }
        }
        cercanaag();
    }

    function ricercaanagrafica(){
        async function cercaanag(){
            try{
            const result = await cercaAnagrafiche2(email,palestra.palestra.id, nomecognomecodfisc?nomecognomecodfisc:'', '', '', '',false,false,'','','','','','',false,0);
            console.log(result)
            setanagrafiche(result)
            }
            catch(error){
                console.log(error)
            }
        }
        if (nomecognomecodfisc){
             cercaanag();
        }
    }


    function testalo(inputcodfisc){
        let codfisc=inputcodfisc;
        if (inputcodfisc.indexOf('%')===0){
            codfisc=inputcodfisc.substring(1,17)
        }

        console.log('codfisc '+codfisc)
        setcodicefiscale(codfisc.toUpperCase())
        async function cercanaag(){
            const result=await cercafatture('', '','',codfisc,'','','',palestra.palestra.id,email);
            console.log(result)
            if (result&&Array.isArray(result)&&result.length>0&&result[0].idanag){
                setmessaggio('testalo Anagrafica trovata')
                setidnang(result[0].id)
                setcognome(result[0].cognome)
                setnome(result[0].nome)
                setdatanascita(result[0].datanascita)
                setsesso(result[0].sesso)
                setluogonascita(result[0].luogonascita)
                setindirizzo(result[0].indirizzo)
                setcap(result[0].cap)
                setcitta(result[0].citta)
                settelefono(result[0].telefono)
                setemailanag(result[0].email)
                setnomecognomecodfisc('')
                setcodicefiscale(result[0].codicefiscale.toUpperCase())
            }
            else{
                setmessaggio('Nuova anagrafica')
                console.log('Nuova anagrafica con cofice fisacle: '+codfisc)

                var sextemp=sessoCf(codfisc)
                setsesso(sextemp?sextemp:'');
                var datanasctemp=dataCf(codfisc)
                setdatanascita(datanasctemp?datanasctemp:'');
                var luogonasc=comuneCf(codfisc)
                setluogonascita(luogonasc?luogonasc:'');
                setnome('')
                setcognome('')
                setindirizzo('')
                setcitta('')
                setcap('')
                settelefono('')
                setemailanag('')
                let valuetemp=[];
                if (inputcodfisc.indexOf('?')>0){
                    valuetemp = inputcodfisc.split('?')
                }
                else if (inputcodfisc.indexOf('_')>0){
                    valuetemp = inputcodfisc.split('_')
                }
                //console.log('prima parte cod fisc '+valuetemp[0].substring(1,17))
                //console.log('seconda parte nome cognome '+valuetemp[0].substring(17))
                if (valuetemp&&Array.isArray(valuetemp)&&valuetemp.length>0){
                let nomecognometemp=valuetemp[0].substring(17).replace('\\n',' ').replace('\\r',' ').split(' ');

                nomecognometemp=nomecognometemp.filter(nc => nc!=='')
                if (nomecognometemp.length===2){
                    console.log('1 ',nomecognometemp[0],' - ',nomecognometemp[1])
                        setcognome(nomecognometemp[0])
                        setnome(nomecognometemp[1])
                    }
                    else if (nomecognometemp.length>2){
                        console.log('2 ',nomecognometemp[0],' - ',nomecognometemp[1])
                        setcognome(nomecognometemp[0]+" "+nomecognometemp[1])
                        let nometemp=''
                        for (let i=2; i<nomecognometemp.length; i++){
                            nometemp+=nomecognometemp[i]
                            if (i<nomecognometemp.length-1){
                                nometemp+=' '
                            }
                        }
                        setnome(nometemp)
                    }
                    else if (nomecognometemp.length===1){
                        setcognome(nomecognometemp[0])
                        setnome('')
                    }
                }
                setnomecognomecodfisc('')
            }
        }
        cercanaag()
        setTimeout(() => {
            settesserasanitaria('')
         }, 1000); 
        
    }

    function splitdati(value){
        if (value.indexOf('%')===0&&value.length>=30){
            clearTimeout(timeout);
            let inputcodfisc=value
            inputcodfisc=inputcodfisc.replace('. ','')
            inputcodfisc=inputcodfisc.replace('\n',' ')
            inputcodfisc=inputcodfisc.replace('\r',' ')
            testalo(inputcodfisc)
            //timeout = setTimeout(testalo, 1000);
        }
      }

    function insfatt(){
        async function insfattura(){
            try{
                if(!nome){
                    alert('Nome obbligatorio')
                    return;
                }
                if(!cognome){
                    alert('Cognome obbligatorio')
                    return;
                }
                if(!codicefiscale){
                    alert('Codice fiscale obbligatorio')
                    return;
                }
                if(!indirizzo){
                    alert('Indirizzo obbligatorio')
                    return;
                }
                if(!citta){
                    alert('Citta obbligatoria')
                    return;
                }
                if(!cap){
                    alert('CAP obbligatorio')
                    return;
                }
                if(!telefono){
                    alert('Telefono obbligatorio')
                    return;
                }
                if(!numero){
                    alert('numero fattura obbligatorio')
                    return;
                }
                if(!anno){
                    alert('Anno fattura obbligatorio')
                    return;
                }
                if(!data){
                    alert('Data fattura obbligatoria')
                    return;
                }
                if (!righe||righe.length===0){
                    alert('Inserire almeno una riga di dettaglio');
                    return;
                }
                for (let i=0; i<righe.length; i++){
                    if (!righe[i].descrizione){
                         alert('Indicare una descrizione alla riga '+(i+1))
                         return;
                    }
                }
            if(!datapag||!importopag||importopag===0||!tipopag){
                if (window.confirm('Dati di pagamento mancanti, Continuare comunque?')){

                }
                else{
                    return;
                }
            }
            let codfisctemp=calcolocodfisc(nome,cognome, luogonascita, datanascita, sesso);
            console.log('codice fiscale calcolato: '+codfisctemp)

            if (codfisctemp!==codicefiscale){
                if (!window.confirm('Codice fiscale calcolato ('+codfisctemp+') differisce dal codice fiscale inserito ('+codicefiscale+')'))
                return
            }
            setloading(true)
            const result = await inseriscifattura(
                nome, cognome, codicefiscale, indirizzo, citta, cap, sesso, datanascita, luogonascita, telefono, emailanag, 
                numero, anno, data,iva,righe, 
                reversecharge,  
                datapag, importopag, tipopag,
                palestra.palestra.id, email);
                if(result&&result.id){
                    dispatch(setidfattura(result.id))
                    alert("Fattura inserita correttamente")
                    navigate('/annotatio-dett-fatt/'+result.id)
                }
                else{
                    alert("Ci sono stati problemi durante l'inserimento della fattura")
                }
                setloading(false)
            }
            catch(error){
                console.log(error)
            }
        }
        insfattura();
    }

    function aggiornarigadaprodotti(idprodotto, riga){
        console.log('aggiornarigadaprodotti ',idprodotto, riga)
        let prodottotemp={prezzo:0, descrizione:''}
        if (idprodotto>0){
            prodottotemp=prodotti.filter(prod=> prod.id===Number(idprodotto))[0]
            console.log(prodottotemp)
        }
        let rigatemp={idrigafattura:riga.idrigafattura, 
            quantita:riga.quantita, 
            costo:prodottotemp.prezzo, 
            descrizione:prodottotemp.descrizione,
            aliquotaiva:prodottotemp.aliquotaiva?prodottotemp.aliquotaiva:iva,
            fenatura:prodottotemp.fenatura?prodottotemp.fenatura:'',
            idcorso:prodottotemp.id
        }
        console.log(rigatemp)
        let listarighetemp= righe.map(rigat =>  rigat.idrigafattura!==riga.idrigafattura?rigat:rigatemp )
        setrighe(listarighetemp)
        calcolaTotaleLordo(listarighetemp, iva);
    }

    function aggiornarigadalistini(idlistino, riga){
        console.log('aggiornarigadalistini ',idlistino, riga)
        let listinotemp={prezzo:0, descrizione:''}
        if (idlistino>0){
            listinotemp=listini.filter(listi=> listi.id===Number(idlistino))[0]
            console.log(listinotemp)
        }
        let rigatemp={idrigafattura:riga.idrigafattura, 
            quantita:riga.quantita, 
            costo:listinotemp.prezzo, 
            descrizione:listinotemp.descrizione,
            aliquotaiva:iva,
            fenatura:'',
            idcorso:listinotemp.id
        }
        console.log(rigatemp)
        let listarighetemp= righe.map(rigat =>  rigat.idrigafattura!==riga.idrigafattura?rigat:rigatemp )
        setrighe(listarighetemp)
        calcolaTotaleLordo(listarighetemp, iva);
    }

    function updateRiga(e,idriga) {
		var righetemp=righe;
		const index=righetemp.findIndex(rigat => rigat.idrigafattura === idriga);
		righetemp[index][e.target.name]=e.target.value
        console.log(e.target.name+' - ' +e.target.value)
		setrighe(righetemp);
		calcolaTotaleLordo(righetemp, iva);
        //setkeytorefresh(new Date().getTime());
	}

    function calcolaTotaleLordo(righe, iva) {
        console.log('calcolaTotaleLordo '+righe.length)
		var totimponibile=0;
        var totfattura=0;
        var totiva=0;
        let marcadabollopresente=false;
		righe.map(riga=>{
            totfattura+=(parseFloat(riga.quantita)*parseFloat(riga.costo))
            if(!(riga.fenatura&&riga.fenatura==='N1')){
                totimponibile+=(parseFloat(riga.quantita)*parseFloat(riga.costo))
                totiva+=parseFloat(riga.quantita)*parseFloat(riga.costo)*riga.aliquotaiva/100
            }
            if(riga.descrizione.toUpperCase()==='MARCA DA BOLLO'){
                marcadabollopresente=true;
            }
        })
        //solo nel caso del dotto pastore aggiungoatomaticamente la marca da bollo
        if (totimponibile>77&&(palestra.palestra.id===112||palestra.palestra.id===113)&&!marcadabollopresente){
                righe.push({idrigafattura:'_' + Math.random().toString(36).substr(2, 9),
                    quantita:1, 
                    costo:2, 
                    descrizione: "Marca da bollo",
                    aliquotaiva:0,
                    fenatura:'N1',
                });
                calcolaTotaleLordo(righe,0);
                return;
        }
        console.log('calcolaTotaleLordo '+totfattura)
        setimportoiva(totiva)
		totfattura=totfattura+totiva;
		settotlordo(totfattura)
        setimportopag(totfattura)
        console.log('tot '+totfattura)
		//setkeytorefresh(new Date().getTime());
	}

    function aggiungiRiga(){
		//var righetemp=righe;
		var nuovariga={idrigafattura:'_' + Math.random().toString(36).substr(2, 9),quantita:1, costo:0, descrizione: ""}
        let righetemp=righe.map(riga => riga.idrigafattura!==nuovariga.idrigafattura?riga:nuovariga)
		righetemp.push(nuovariga);
		setrighe( righetemp );
        //setkeytorefresh(new Date().getTime())
		calcolaTotaleLordo(righetemp, iva);
	}
	 
	function eliminaRiga(event,idriga){
		console.log('elimino riga ',idriga)
		let righetem=righe.filter(rigat => rigat.idrigafattura !== idriga);
		setrighe( righetem );
        //setkeytorefresh(new Date().getTime())
		calcolaTotaleLordo(righe, iva);
	}

    function caricaListini(){
        async function listini(){
            const result=await getlistini(email, palestra.palestra.id);
            console.log(result)
            setlistini(result);
        }
        listini();
    }

    function caricaProdotti(){
        async function prodotti(){
            const result=await getcorsi(email, palestra.palestra.id, '');
            
            let corsitemp=[]
            result.map(tipocorso =>
                tipocorso.corsi.map(corso => {
                    corsitemp.push(corso)
                    }
                    )
                )
            setprodotti(corsitemp);
        }
        prodotti();
    }

    function getnumfattura(){
        async function getnumf(){
            const result = await getNumeroFattura(palestra.palestra.id,email);
            setnumero(result)
        }
        getnumf();
    }

    useEffect(() => {
        caricaListini();
        caricaProdotti();
        getnumfattura();
        if(props.idanag&&props.idanag>0){
            cercaanagbyid(props.idanag);
        }
    },[email,palestra])

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          ricercaanagrafica();
        }
      }

      function svuotacampi(){
       setnome('')
    setindirizzo('')
    setcitta('')
    setcap('')
    setcognome('')
    setcodicefiscale('')
    setdatanascita('')
    setluogonascita('')
    setsesso('')
    settipopag('')
    settelefono('')
    setemailanag('')
    setimportopag(0);
      }

    return (
    		<div key={keytorefresh} className="container-fluid">
                <div className="row">
                    <div className="col-md-3"><h4>Inserimento nuova fattura</h4></div>
                    <div className="col-md-3">
                        <A href="/annotatio-ric-fatt/" className="button" style={{padding:5, margin:5}}>
                            <FontAwesomeIcon icon={faSearch} /> Ricerca fatture</A>
                    </div>
                    <div className="col-md-3">
                       
                        <button onClick={svuotacampi}>Svuota campi fattura</button>
                        </div>

                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                    <label for="inputEmail4">Ricerca anagrafica</label>
                    <div class="input-group mb-3">
                        <input type="text" 
                        autoFocus={true}
                        onKeyDown={handleKeyDown}
                        class="form-control" 
                        placeholder="NOME COGNOME CODICE FISCALE" aria-label="NOME COGNOME CODICE FISCALE" aria-describedby="basic-addon2" 
                        name="nomecognomecodfisc" value={nomecognomecodfisc} onChange={(e) =>{setnomecognomecodfisc(e.target.value); clearTimeout(timeout); timeout = setTimeout(splitdati(e.target.value), 2000);}}
                        />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" onClick={ricercaanagrafica}><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                        </div>
                        {messaggio}
                    </div>
                    {anagrafiche&&anagrafiche.length>0&&
                    <div className="form-group col-md-4">
                    <table className="table">
                            <thead>
                                <tr>
                                    <th>Seleziona</th>
                                    <th>Nome</th>
                                    <th>Cognome</th>
                                    <th>Data nascita</th>
                                    <th>Indirizzo</th>
                                    <th>Citta'</th>
                                </tr>
                            </thead>
                            <tbody>
                    {anagrafiche.map(anag => (
                        <tr>
                        <td><button onClick={() => {cercaanagbyid(anag.id);setanagrafiche([])}}>{anag.id}</button></td>
                        <td>{anag.nome}</td>
                        <td>{anag.cognome}</td>
                        <td>{anag.datanascita}</td>
                        <td>{anag.indirizzo}</td>
                        <td>{anag.citta}</td>
                            </tr>
                    ))}
                    </tbody>
                        </table>
                   </div>}
                </div>
                <div className="row">
                    <div className="col-md-2"><h4> <FontAwesomeIcon icon={faUserAlt} /> Anagrafica</h4></div>
                    <div className="col-md-10">
                   
                    <div className="row">
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Nome</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="NOME" name="nome" value={nome} onChange={(e) =>setnome(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Cognome</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="COGNOME" name="cognome" value={cognome} onChange={(e) =>setcognome(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Cod. Fiscale</label>
                        <div className="input-group">
                        <input type="text" 
                        className="form-control"
                        placeholder="CODICE FISCALE" name="codicefiscale" value={codicefiscale} onChange={(e) =>setcodicefiscale(e.target.value.toUpperCase())} />
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" onClick={() => testalo(codicefiscale)}><FontAwesomeIcon icon={faAddressCard} /></button>
                        </div>
                        </div>
                    </div>

                </div>
                <div className="row">
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Data nascita</label>
                        <input type="date" 
                        className="form-control"
                        placeholder="DATA NASCITA" name="datanascita" value={datanascita} onChange={(e) =>setdatanascita(e.target.value)} />
                    </div>
                    <div className="form-group col-md-4">
                        <label for="inputPassword4">Luogo nascita</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="LUOGO NASCITA" name="luogonascita" value={luogonascita} onChange={(e) =>{setluogonascita(e.target.value); setshowcomunipernascita(true)}} />
                        {luogonascita&&luogonascita.length>=3&&
                        ComuniItalianiJson.map(comune =>
                            showcomunipernascita&&comune.Comune.toUpperCase().indexOf(luogonascita.toUpperCase())===0&&
                            <ul style={{cursor:'pointer', fontSize:'smaller', borderStyle:"dashed", borderWidth:1, borderColor:'black'}}>
                                 <li onClick={() => {setluogonascita(comune.Comune); setshowcomunipernascita(false)}}>{comune.Comune} ({comune.Provincia}) - {comune.Regione}</li>
                             </ul>
                            )}
                    </div>
                    <div className="form-group col-md-4">
                    <label for="inputPassword4">Sesso</label>
                        <select name="sesso" value={sesso} onChange={(e) =>setsesso(e.target.value)} className="form-control form-control-lg">
                            <option value=''>--seleziona sesso--</option>
                            <option value='M'>M</option>
                            <option value='F'>F</option>
                        </select>
                    </div>
                </div>
                </div>
                </div> {/*  FINE ANAGRAFICA */}
<hr />
<div className="row">
                    <div className="col-md-2"><h4><FontAwesomeIcon icon={faAddressCard} /> Indirizzo e recapiti</h4></div>
                    <div className="col-md-10">
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Indirizzo</label>
                            <input type="text" 
                            className="form-control"
                            placeholder="INDIRIZZO" name="indirizzo" value={indirizzo} onChange={(e) =>setindirizzo(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Citta</label>
                            <input type="text" 
                            className="form-control"
                            placeholder="CITTA" name="citta" value={citta} onChange={(e) =>{setcitta(e.target.value); setshowcomunipercitta(true)}} />
                            {citta&&citta.length>=3&&
                        ComuniItalianiJson.map(comune =>
                            showcomunipercitta&&comune.Comune.toUpperCase().indexOf(citta.toUpperCase())===0&&
                            <ul style={{cursor:'pointer', fontSize:'smaller', borderStyle:"dashed", borderWidth:1, borderColor:'black'}}>
                                 <li onClick={() => {setcitta(comune.Comune); setcap(comune.CAP);setshowcomunipercitta(false)}}>{comune.Comune} ({comune.Provincia}) - {comune.Regione}</li>
                             </ul>
                            )}
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Cap</label>
                            <input type="text" 
                            className="form-control"
                            placeholder="CAP" name="cap" value={cap} onChange={(e) =>{setcap(e.target.value); setshowcomunipercap(true)}} />
                            {cap&&cap.length>=3&&
                        ComuniItalianiJson.map(comune =>
                            showcomunipercap&&(""+comune.CAP).indexOf(cap)===0&&
                            <ul style={{cursor:'pointer', fontSize:'smaller', borderStyle:"dashed", borderWidth:1, borderColor:'black'}}>
                                 <li onClick={() => {setcitta(comune.Comune); setcap(comune.CAP);setshowcomunipercap(false)}}>{comune.Comune} ({comune.Provincia}) - {comune.Regione}</li>
                             </ul>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Telefono</label>
                            <input type="phone" 
                            className="form-control"
                            placeholder="TELEFONO" name="telefono" value={telefono} onChange={(e) =>settelefono(e.target.value)} />
                        </div>
                        <div className="form-group col-md-4">
                            <label for="inputPassword4">Email</label>
                            <input type="email" 
                            className="form-control"
                            placeholder="EMAIL" name="email" value={emailanag} onChange={(e) =>setemailanag(e.target.value)} />
                        </div>
                    </div>
                </div>
                </div> {/*  FINE INDIRIZZO */}
                <hr />
                <div className="row">
            <div className="col-md-2"><h4><FontAwesomeIcon icon={faFileInvoice} /> Dati fattura</h4></div>
                <div className="col-md-10">
                    <div className="row">
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Numero</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="NUMERO" name="numero" value={numero} onChange={(e) =>setnumero(e.target.value)} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Anno</label>
                        <input type="text" 
                        className="form-control"
                        placeholder="ANNO" name="anno" value={anno} onChange={(e) =>setanno(e.target.value)} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Data</label>
                        <input type="date" 
                        className="form-control"
                        placeholder="Data" name="data" value={data} onChange={(e) =>setdata(e.target.value)} />
                    </div>
                    
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Reverse Charge</label>
                        <input type="checkbox" placeholder="Reverse charge" name="reversecharge" value={reversecharge} onChange={(e) =>setreversecharge(e.target.value)} />
                    </div>
                </div>
            </div>
        </div>
        <hr />
                
        <h3>Righe fattura</h3>
                <table  className="table table-bordered table-primary">
                    <thead>
                        <tr>
                            <th>Descrizione</th>
                            <th>Quantita</th>
                            <th>Costo</th>
                            <th>Tot. servizio</th>
                            <th>Aliquota Iva</th>
                            <th>Importo IVA</th>
                            <th>Natura (F.E.)</th>
                            <th>Elimina</th>
                        </tr>
                    </thead>
                    <tbody>
                    {righe.map( riga => (
                        <NuovaRiga key={riga.idrigafattura} updateRiga={updateRiga} riga={riga} eliminaRiga={eliminaRiga} 
                        listini={listini} prodotti={prodotti} 
                        aggiornarigadaprodotti={aggiornarigadaprodotti} 
                        aggiornarigadalistini={aggiornarigadalistini} />
                    ))}
                    <tr>
                        <td><button type="button" class="btn btn-secondary" onClick={aggiungiRiga}> 
                <FontAwesomeIcon icon={faPlusSquare} /> Aggiungi riga
                </button></td>
                        <td colspan={3} style={{textAlign:'right'}}>Importo netto totale</td>
                        <td>{totlordo-importoiva}</td>
                        <td style={{textAlign:'right'}}>Importo totale IVA</td>
                        <td>{importoiva}</td>
                    </tr>
                    <tr><td colspan={6} style={{textAlign:'right'}}>Totale Fattura</td>
                    <td>{totlordo}</td>
                    </tr>
                    </tbody>
                </table>                
                
                <hr />

                <div className="row">
            <div className="col-md-2"><h4><FontAwesomeIcon icon={faEuroSign} /> Dati pagamento</h4></div>
                <div className="col-md-10">
                <div className="row">
                <div className="form-group col-md-3">
                        <label for="inputPassword4">Data</label>
                        <input type="date" 
                        className="form-control"
                        placeholder="Data" name="datapag" value={datapag} onChange={(e) =>setdatapag(e.target.value)} />
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Tipo</label>
                        <select className="form-control" name="tipopag" value={tipopag} onChange={(e) =>settipopag(e.target.value)}>
                        <option value="">--Seleziona un metodo di pagamento--</option>
                        <option value="carta">carta/bancomat</option>
                        <option value="contanti">contanti</option>
                        <option value="assegno">assegno</option>
                        <option value="bonifico">bonifico</option>
                        </select>
                    </div>
                    <div className="form-group col-md-3">
                        <label for="inputPassword4">Importo</label>
                        <input type="number" 
                        className="form-control"
                        placeholder="Importo" name="importopag" value={importopag} onChange={(e) =>setimportopag(e.target.value)} />

                </div>
            </div>
            </div>
        </div>

        <div className="row">
            {!loading&&
                    <button type="button" class="btn btn-success" onClick={insfatt}><FontAwesomeIcon icon={faSave} /> SALVA</button>}
            {loading&&<div>Attendere prego...</div>}
            </div>
            </div>
    );
}