import React from "react";
import { useState } from "react";
import { useSelector, } from 'react-redux';
import { eliminatessera, salvatesseraanag } from "../DAO/tessereDAO";

export default function RigaTessereAnagrafica(props){
    const palestraanagrafica = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const idanagselezionata = useSelector(state => state.gestoreUtente.idanagselezionata);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [numero, setnumero] = useState(props.tessera.numero)
    const [validoda, setvalidoda] = useState(props.tessera.validoda)
    const [validoa, setvalidoa] = useState(props.tessera.validoa)
    const [messaggio, setmessaggio] = useState('')

    function salva(){
        async function salvatessereanag(){
            const result = await salvatesseraanag(props.tessera.id,props.tessera.idanagrafica, numero, validoda, validoa)
            if (result&&result.id>0){
                setmessaggio("Tessera modificata correttamente")
            }
            else{
                setmessaggio("Si e' verificato un problema durante la modifica della tessera")
            }
        }
        salvatessereanag();
    }

    function elimina(){
        async function canctessereanag(){
            const result = await eliminatessera(props.tessera.id)
            alert(result);
        }
        if (window.confirm("Sei sicuro di voler eliminare la tessera selezionata?")){
            canctessereanag();
        }
    }

    return (
        <tr>
            <td>{props.tessera.nome}</td>
            <td>{props.tessera.cognome}</td>
            <td>{props.tessera.datanascita}</td>
        <td><input type="text" value={numero} onChange={(e) => setnumero(e.target.value)} /></td>
        <td><input type="date" value={validoda} onChange={(e) => setvalidoda(e.target.value)} /></td>
        <td><input type="date" value={validoa} onChange={(e) => setvalidoa(e.target.value)} /></td>
        <td>{messaggio}<button className="btn btn-primary" onClick={salva}>Salva</button></td>
        <td><button className="btn btn-danger" onClick={elimina}>Elimina</button></td>
    </tr>
    );
}
