import React from "react";
import '../../util/config'

export default class PeriodoSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
    		selezionato:this.props.selected
    };
    this.aggiornaSelezionato = this.aggiornaSelezionato.bind(this);
  }

	componentDidMount(){

	}
  
	aggiornaSelezionato(e){
		 this.props.updatePeriodo(this.props.listaPeriodi[e.target.selectedIndex-1].id);
	 }

  render() {
    return (
    		<span>
				<label className="control-label">Periodo</label>
				<select className="form-control" onChange={this.aggiornaSelezionato} name="periodo" value={this.props.selected?this.props.selected:""}>
					<option key={-1} value="">--nessun periodo--</option>
					{
						this.props.listaPeriodi&&Array.isArray(this.props.listaPeriodi)&&
						this.props.listaPeriodi.length>0&&
						 this.props.listaPeriodi.map((item,index) =>(
						<option key={index} value={item.id} >{item.descrizione}</option>
						)
		    			)
		    		}
				</select>
			</span>
    );
  }
}