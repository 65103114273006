
import Swal from "sweetalert2";
import '../../App.css'
/**
 * 
 * @param {string} position: bottom (-right, -left, -start), center (-end *default*, -right, -left, -start), top (-end, -right, -left, -start)
 * @param {string} title: il titolo grande dell'alert
 * @param {string} text: il testo dell'alert 
 * @param {string} icon: error, info *default*, question, success, warning
 * @param {boolean} backdrop: true disabilita sfondo, false *default* non disabilita sfondo
 * @param {boolean} timer: il tempo in ms per mostrare l'alert, 4000 *default*

 * @returns 
 */
export default function AnnotatioAlert({position, title, text,icon, timer }){
                Swal.fire({
                position:position?position:'bottom-end',
                showConfirmButton:false,
                title:title?title:'',
                text:text?text:'',
                icon:icon?icon:'info',
                timer:timer?timer:4000,
                backdrop:false,
                customClass: {
                    popup: !title&&!text&&icon==='success'&&'my-swal',
                  },
            })

}