import React from "react";
import UltimeIscrizioni from "../riquadri/UltimeIscrizioni";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAddressCard, faUserPlus, faCalendarAlt, faChartLine, faDoorOpen, faDumbbell, faEuroSign, faPlusCircle, faWeight, faArchive, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'hookrouter'
import {useSelector} from 'react-redux'
import { useEffect } from "react";
import { useState } from "react";

export default function AnnotatioModules(props){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());

    useEffect(() => {
setkeytorefresh(new Date().getTime())
    },[palestra, email])

    return(
        <div key={keytorefresh} className="container-fluid">
            {palestra&&
            <div className="row">

                {palestra.moduli&&palestra.moduli.includes("ABBONAMENTI")&&<div className="col-md-4">
                <div className="card" style={{margin:10}}>
                    <div className="card-header text-center">
                        Utlime Iscrizioni
                        </div>
                        <div className="card-body">
                    <UltimeIscrizioni />
                    </div>
                    <div className="card-footer">

                </div>
                 </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('CLIENTI')>=0))&& 
                <div className="col-md-4" >
                    <div className="card border-primary" style={{margin:10}}>
                    <div className="card-header text-center" onClick={()=> navigate('/annotatio-anag/',true)}>
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faAddressCard} />
                    </div>
                    <div className="card-body" onClick={()=> navigate('/annotatio-anag/',true)}>
                        <h5 className="card-title">Gestione Anagrafiche</h5>
                        <p className="card-text">Anagrafiche, Abbonamenti, Pagamenti</p>
                    </div>
                    <div className="card-footer">
                        <button type="button" className="btn btn-link" onClick={()=> navigate('/annotatio-newanag/',true)}>
                        <FontAwesomeIcon icon={faUserPlus} /> Nuova Anagrafica</button>
                    </div>
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('CALENDARIOEPRENOTAZIONI')>=0))&&
                <div className="col-md-4" >
                    <div className="card border-secondary" style={{margin:10}} onClick={()=> navigate('/annotatio-prods/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faCalendarAlt} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Calendari</h5>
                        <p className="card-text">Schedulazioni, Prenotazioni</p>
                    </div>
                    <div className="card-footer">
                </div>
                
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('CASSA')>=0))&&
                <div className="col-md-4" >
                    <div className="card border-success" style={{margin:10}}>
                    <div className="card-header text-center" onClick={()=> navigate('/annotatio-cassa/',true)}>
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faEuroSign} />
                    </div>
                    <div className="card-body" onClick={()=> navigate('/annotatio-cassa/',true)}>
                        <h5 className="card-title">Cassa</h5>
                        <p className="card-text"></p>
                    </div>
                    <div className="card-footer">
                    <button type="button" className="btn btn-link" onClick={()=> navigate('/annotatio-newcassa/',true)}>
                        <FontAwesomeIcon icon={faPlusCircle} /> Nuovo Movimento Cassa</button>
                    </div>
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('REPORT')>=0))&&
                <div className="col-md-4" >
                    <div className="card border-danger" style={{margin:10}} onClick={()=> navigate('/annotatio-dashboard/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faChartLine} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Report e Statistiche</h5>
                        <p className="card-text"></p>
                    </div>
                    <div className="card-footer">

                    </div>
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('SCHEDESALAATTREZZI')>=0))&&
                <div className="col-md-4">
                    <div className="card border-warning" onClick={()=> navigate('/annotatio-sa/',true)} style={{margin:10}}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faDumbbell} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Schede Sala Attrezzi</h5>
                        <p className="card-text">
                        
                        </p>
                    </div>
                    <div className="card-footer">

                    </div>
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('SCHEDESALAATTREZZI')>=0))&&
                <div className="col-md-4">
                    <div className="card border-info" style={{margin:10}} onClick={()=> navigate('/annotatio-cc/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faWeight} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Schede Composizione Corporea e Diario Alimentare</h5>
                        <p className="card-text">
                        &nbsp;
                        </p>
                    </div>
                    <div className="card-footer">

                    </div>
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('CONTROLLOACCESSI')>=0))&&
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-accessi/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}}icon={faDoorOpen} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Controllo Accessi</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
     
                     </div>
                    </div>
                </div>}

                {(palestra.amministratore||(palestra.funzionalita&&palestra.funzionalita.indexOf('ARCHIVI')>=0))&&
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-arch/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faArchive} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Impostazioni</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
     
                     </div>
                    </div>
                </div>}

                {((palestra.funzionalita&&palestra.funzionalita.indexOf('ORDINI')>=0))&&
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-ordini/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faArchive} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Ordini</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
     
                     </div>
                    </div>
                </div>}
                {((palestra.funzionalita&&palestra.funzionalita.indexOf('FATTURE')>=0))&&
                <div className="col-md-4">
                    <div className="card border-dark" style={{margin:10}} onClick={()=> navigate('/annotatio-fatt/',true)}>
                    <div className="card-header text-center">
                    <FontAwesomeIcon style={{width:50, height:50}} icon={faFileInvoice} />
                    </div>
                    <div className="card-body">
                        <h5 className="card-title">Fatture</h5>
                        <p className="card-text">
                        </p>
                    </div>
                    <div className="card-footer">
     
                     </div>
                    </div>
                </div>}
            </div>}
        </div>
    )
}