import axios from 'axios';
import serverInfo from '../util/globals';

export async function cercaalimenti(nome, palestra) {
  const formData = new FormData();
  formData.append('palestra', palestra);
  formData.append('nome', nome);
  const response = await axios.post(
    serverInfo.namesec+'/ali/getalimenti',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function getlistaalimenti(palestra) {
  const formData = new FormData();
  formData.append('palestra', palestra);
  const response = await axios.post(
    serverInfo.namesec+'/ali/getalimenti',
    formData,
    {
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization',
        'Access-Control-Allow-Credentials': 'true',
        'Authorization' :'Bearer '+window.$t
      },
    },
  );
  return response.data;
}

export async function updalimento(nome, 
    acidofolico, amido, caplusplus, carboidrati, colesterolo, feplusplus, fibra,
    qtarif, h2o, id, palestra, indiceglicemico, kcal, kplus, lattosio, lipidi,
    mg, naplus, niacinab3, p, proteine, riboflavinab2, saturi, tiaminab1, vitaminaa, 
    vitaminab12, vitaminac, vitaminad, vitaminae, zn, zuccherisolubili, note
    ) {
    const formData = new FormData();
    formData.append('palestra', palestra);
    formData.append('acidofolico', acidofolico);
    formData.append('amido', amido);
    formData.append('caplusplus', caplusplus);
    formData.append('carboidrati', carboidrati);
    formData.append('colesterolo', colesterolo);
    formData.append('feplusplus', feplusplus);
    formData.append('fibra', fibra);
    formData.append('qtarif', qtarif);
    formData.append('h2o', h2o);
    formData.append('id', id);
    formData.append('indiceglicemico', indiceglicemico);
    formData.append('kcal', kcal);
    formData.append('kplus', kplus);
    formData.append('lattosio', lattosio);
    formData.append('lipidi', lipidi);
    formData.append('mg', mg);
    formData.append('naplus', naplus);
    formData.append('niacinab3', niacinab3);
    formData.append('nome', nome);
    formData.append('p', p);
    formData.append('proteine', proteine);
    formData.append('riboflavinab2', riboflavinab2);
    formData.append('saturi', saturi);
    formData.append('tiaminab1', tiaminab1);
    formData.append('vitaminaa', vitaminaa);
    formData.append('vitaminab12', vitaminab12);
    formData.append('vitaminac', vitaminac);
    formData.append('vitaminad', vitaminad);
    formData.append('vitaminae', vitaminae);
    formData.append('zn', zn);
    formData.append('zuccherisolubili', zuccherisolubili);
    formData.append('note', note?note:'');
    const response = await axios.post(
      serverInfo.namesec+'/ali/updalimento',
      formData,
      {
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'POST',
          'Access-Control-Allow-Headers': 'Content-Type, Authorization',
          'Access-Control-Allow-Credentials': 'true',
          'Authorization' :'Bearer '+window.$t
        },
      },
    );
    return response.data;
  }