import React from "react";
import '../util/config'

import { Button } from "react-bootstrap";
import { navigate } from "hookrouter"
import { useDispatch, } from "react-redux";
import { setanagselezionata } from "../STORE/reducers/gestoreUtente";

export default function RicercaSchedaCCRiga(props) {

    const dispatch = useDispatch();
    
    function seleziona(e) {
        localStorage.setItem('idanagCC',props.anag.id)
        dispatch(setanagselezionata(props.anag))
        if (props.link){
            window.location.href = "/"+props.link
        }
        else{
            navigate('/anntt-adm-dettcc/',true)
        }
    }

	    return (
                    <tr>
                        <td>
                        <Button onClick={seleziona}> {props.anag.nomecognome}</Button>
                       </td>
                        <td>{props.anag.dataCC}</td>
                        <td>{props.anag.dataDA}</td>
                    </tr>
	    );
	}