import React, { useEffect, useState } from "react";
import axios from 'axios';
import '../../util/config'
import SchedaCompCorp from "./SchedaCompCorp";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {connect, useSelector} from 'react-redux'
import ListaAttQuotAnag from "../../nutrizionista/attivitaquotidiane/attquotanag/listaattquotanag";
import Plicometria from "./Plicometria";
import Bottone from "../../util/UI/Bottone";
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import serverInfo from "../../util/globals";
import Swal from "sweetalert2";
import DettaglioSchedadieta from "../../nutrizionista/schededieta/DettaglioSchedadieta";

export default function ListaSchedeCompCorp() {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata)

    const [schedeCC, setschedeCC] = useState([]);

    function eliminaEsercizio(id) {
        if (window.confirm('Sei sicuro di voler cancellare questa scheda?')){
            const formData2 = new FormData();
            formData2.append('website', 'annotatio.online');
            formData2.append('email',email);
            formData2.append('lic','000');
            formData2.append('palestra',palestra.id);
            formData2.append('id',id);
            axios.post(serverInfo.name+'/eliminaSchedaCC', formData2)
            .then((result) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 3000
                        });
                        Toast.fire({
                        type: 'success',
                        title: result.data
                        })
          });
          
        }
    }

	useEffect(() =>{
        const formData = new FormData(); 
        formData.append('website', global.config.domain);
        formData.append('email',email);
        formData.append('lic','00000');
        formData.append('palestra',palestra.id);
        formData.append('idanagrafica',anagselezionata.id);
        axios.post(global.config.server+"/getSchedeCC", formData)
        .then(response=>{
            setschedeCC(response.data)
        });
    },[])

	    return (
            anagselezionata&&anagselezionata.id?
            <div className="container-fluid">
<Tabs>
				<TabList>
                    <Tab>Attivita quotidiane</Tab>
					<Tab>Composizione Corporea</Tab>
                    <Tab>Plicometria</Tab>
					<Tab>Piano Alimentare</Tab>
				</TabList>
                
                <TabPanel>
                    <ListaAttQuotAnag />
                </TabPanel>
				<TabPanel>
                <SchedaCompCorp email={email} />
                <hr />
                <h4>Storico misurazioni</h4>
                <div className="table-responsive">
                <table className="table table-striped" style={{borderWidth:'1px', borderColor:'black', borderStyle:'dashed'}}>
                    <thead className="thead-dark">
                        <tr>
                            <th scope="col">
                                Data
                            </th>
                            <th scope="col">
                                Altezza
                            </th>
                            <th scope="col">
                                Peso
                            </th>
                            <th scope="col">
                                BMI
                            </th>
                            <th scope="col">
                                Elimina
                            </th>
                            {/* <th scope="col">
                                Bicipite
                            </th>
                            <th scope="col">
                                Tricipice
                            </th>
                            <th scope="col">
                                Sottoscapolare
                            </th>
                            <th scope="col">
                                Sovrailiaca
                            </th>
                            <th scope="col">
                                TOT. PLICHE
                            </th>
                            <th scope="col">
                                % massa grassa
                            </th>
                            <th scope="col">
                                kg massa grassa
                            </th>
                            <th scope="col">
                                kg massa magra
                            </th> */}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            schedeCC.map(scheda=>(
                        <tr key={scheda.id}>
                            <td>
                                {scheda.data}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.altezza}
                            </td>
                            <td style={{textAlign:'right'}}>
                                {scheda.peso}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.bmi}</strong>
                            </td>
                            <td>
                            <Bottone 
                            bootstrapstyle={"danger"}
                            icona={faTrash}
                            onClick={() => eliminaEsercizio(scheda.id)}
                            />
                            </td>
                            {/* <td style={{textAlign:'right'}}>
                            {scheda.plbicipite}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.pltricipite}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plsottoscapolare}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plsovrailiaca}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.plbicipite+scheda.pltricipite+scheda.plsottoscapolare+scheda.plsovrailiaca}
                            </td>
                            <td style={{textAlign:'right', border: '1px solid'}}>
                            <strong>{scheda.percmassagrassa}</strong>
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.massagrassa}
                            </td>
                            <td style={{textAlign:'right'}}>
                            {scheda.massamagra}
                            </td> */}
                        </tr>
                            ))
                        }
                    
                    </tbody>
                </table>
                </div>
				</TabPanel>
                <TabPanel>
                    <Plicometria />
                </TabPanel>
				<TabPanel>
                    <DettaglioSchedadieta />
                {/* <CercaSchededieta /> */}
                {/* <ListaSchedeDiarioAlimentare email={this.props.email} /> */}
				</TabPanel>
			</Tabs>
    
<br />
                
			</div>
            :"E' necessario selezionare un'anagrafica"
	    );
	}