import React, { useState } from "react";
import { useSelector } from "react-redux";
import { cercaalimenti } from "../DAO/alimentiDAO";
import EditAlimento from "./EditAlimento";
import NewAlimento from "./NewAlimento";
import Bottone from "../util/UI/Bottone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faSearch } from "@fortawesome/free-solid-svg-icons";
import './table.css'

export default function CercaAlimenti(props) {
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    
    const [nome, setnome] = useState('');
    const [alimenti, setalimenti]= useState([]);
    const [shownew, setshownew] = useState(false);

    function cercaalim(){
        async function cerca(){
            const result = await cercaalimenti(nome, palestra.id);
            setalimenti([]);
            setalimenti(result);
        }
        cerca();
    }

    return (
        <div className="container-fluid">
            <div className="row">
            <div className="form-group col-md-3">
                        <label for="inputPassword4">Cerca alimento</label>
                        <div className="input-group">
                        
                        <input type="text" 
                        className="form-control"
                        placeholder="Nome alimento" name="nome" value={nome}
                        required="required"
                        onChange={(e) => setnome(e.target.value)} />
                        
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" onClick={cercaalim}><FontAwesomeIcon icon={faSearch} /></button>
                        </div>
                        
                        </div>
                    </div>
                    <Bottone bootstrapsize={"col-md-3"}
                     bootstrapstyle={"info"}
                     label={<FontAwesomeIcon icon={faPlusCircle} />}
                     onClick={() => setshownew(!shownew)}
                     /> 
            </div>
            <div className="row">
                <div className="table-responsive">
                {alimenti.length} alimenti trovati
                <table className="table">
                    <thead>
                        <tr>		
                            <th>Alimento</th>
                            <th>Qta Rif.</th>
                            <th>Note</th>
                            <th>Kcal</th>
                            <th>H2O</th>
                            <th>Lipidi</th>
                            <th>Saturi</th>
                            <th>Colesterolo</th>
                            <th>Proteine</th>
                            <th>Carboidrati</th>
                            <th>Amido</th>
                            <th>Zuccheri solubili</th>
                            <th>Fibra</th>
                            <th>Lattosio</th>
                            <th>Indice</th>
                            <th>glicemico</th>
                            <th>Na+</th>
                            <th>K+</th>
                            <th>Fe++</th>
                            <th>Ca++</th>
                            <th>P</th>
                            <th>Mg</th>
                            <th>Zn</th>								
                            <th>Tiamina B1</th>
                            <th>Riboflavina B2</th>
                            <th>Niacina B3</th>
                            <th>Acido Folico</th>
                            <th>Vit B12</th>
                            <th>Vit A</th>
                            <th>Vit C</th>
                            <th>Vit E</th>
                            <th>Vit D</th>
                        </tr>
                    </thead>
                    <tbody>
                    {shownew&&<NewAlimento />}
                        {alimenti.map(alimento => (
                            <EditAlimento alimento={alimento} />
                        )

                        )}
                        
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    );
    
}

