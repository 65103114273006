import React from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { caricatemplates, documentiricevuti, documentisalvati, eliminadoc, handleEliminaFile, handleMostraFile, handleUploadFile, salvadoc } from "../DAO/DocumentiDAO";
import Bottone from "../util/UI/Bottone";
import InputText from "../util/UI/InputText";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPlusCircle, faPrint, faSave} from '@fortawesome/free-solid-svg-icons'
import ListaFilesProto from "./listaFilesProto";
import MyInput from "../util/UI/MyInput";

export default function ProtoUscitaAnag(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);

    const [docsalvati, setdocsalvati] = useState([])
    const [id, setid] = useState(0)
    const [titolo, settitolo] = useState('')
    const [html, sethtml] = useState('')
    const [tipo, settipo] = useState('U')
    const [idtipomovimento, setidtipomovimento] = useState('')
    const [files, setfiles]=useState([])
    const [file, setfile]=useState()
    const [templates, settemplates] = useState([])
    const [defaulthtml, setdefaulthtml] = useState('')

    function templatedocc(){
        async function carica(){
            let result = await caricatemplates(palestra.palestra.id);
            settemplates(result)
        }
        carica();
    }

    function selezionahtml(titolo, htmltemp){
        setid(0)
        htmltemp=htmltemp.replace('[nome]',anagselezionata.nome.toUpperCase())
        htmltemp=htmltemp.replace('[cognome]',anagselezionata.cognome.toUpperCase())
        sethtml(htmltemp)
        setdefaulthtml(htmltemp)
        settitolo(titolo)
        
        //setkeytorefresh(new Date().getTime())
    }

    function selectdocinviato(index){
        setid(docsalvati[index].id)
        sethtml(docsalvati[index].htmlreadable)
        setdefaulthtml(docsalvati[index].htmlreadable)
        settitolo(docsalvati[index].oggetto)
        setidtipomovimento(docsalvati[index].idtipomovimento)
        
        async function caricaFiles(){
            const result = await handleMostraFile(docsalvati[index].id, email, palestra.palestra.id)
            setfiles(result)
        }
        caricaFiles()
        //setkeytorefresh(new Date().getTime())
    }

    function eliminadocinviato(id){
        async function elimina(){
            const result = await eliminadoc(id, palestra.palestra.id, email)
            alert(result)
            docinviati()
        }
        if (window.confirm("Sei sicuro di voler il documento selezionato?")){
            elimina()
        }
    }

    function eliminafile(idfile){
        async function elimina(){
            const result = await handleEliminaFile(idfile, palestra.palestra.id, email)
            alert(result)
            docinviati()
        }
        if (window.confirm("Sei sicuro di voler eliminare il file selezionato?")){
            elimina()
        }
    }

    function salvadocumento(){
        async function salva(){
            const proto = await salvadoc(palestra.palestra.id,email,id, titolo, html, anagselezionata.id, tipo, idtipomovimento)
            if (file){
                const result = await handleUploadFile(proto.id, file, email, palestra.palestra.id)
            }
            console.log(proto)
            if (proto.id&&proto.id>0){
                alert("Documento salvato con successo")
                setid(proto.id)
                docinviati()
            }
        }
        if (!titolo){
            alert('Titolo obbligatorio')
            return
        }
        salva()
    }

    function onChangeFile(e) {
		setfile(e.target.files[0])
	  }

      function stampahtmlpers() {
        var divContents = document.getElementById("printarea").innerHTML;
        var a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body > ');
        a.document.write(divContents);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    }

    function docinviati(){
        async function carica(){
            let result = await documentisalvati(palestra.palestra.id, anagselezionata.id);
            setdocsalvati(result)
        }
        carica();
    }

    useEffect( ()=> {
        templatedocc()
        docinviati()
    },[palestra])

    function nuovodocumento(){
        settitolo('')
        sethtml('<p></p>')
        setdefaulthtml('')
        setid(0)
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-4">
                    Documenti inviati a {anagselezionata.nome} {anagselezionata.cognome}
                </div>
                <Bottone bootstrapsize={"col-md-3"} bootstrapstyle={"warning"}
                label={<FontAwesomeIcon icon={faPlusCircle} />} onClick={nuovodocumento} />
            </div>
            
            <table className="table table-hover">
                <thead>
                    <tr>
                        <th>Data</th>
                        <th>Titolo</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                {docsalvati&&Array.isArray(docsalvati)&&docsalvati.length>0&&
                docsalvati.map((doc,index) =>(
                <tr onClick={() => selectdocinviato(index)}>
                    <td>{doc.numero}/{doc.anno}</td>
                    <td>{doc.dataarrivo}</td>
                    <td>{doc.oggetto}</td>
                    <td><button className="btn btn-danger" onClick={() => eliminadocinviato(doc.id)}>Elimina</button></td>
                </tr>
                ))}
                </tbody>
            </table>
            <div className="row">
            <h5 className="col-md-12">{(id===0)?"Nuovo documento":"Modifica documento "}</h5>
            </div>
            <div className="row" >
            <div className="col-md-9" style={{borderColor:'black', borderStyle:'dashed', borderWidth:1}}>

            <InputText bootstrapsize={"col-md-12"} 
            id={"oggetto"}
            label={"Titolo"}
            name={"titolo"}
            onChange={(e) => settitolo(e.target.value)}
            placeholder={"TITOLO DOCUMENTO"}
            type={"text"} value={titolo} />
            <MyInput  sethtml={sethtml} defaultcontent={defaulthtml} />
            <div id="printarea" style={{display:'none'}}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            </div>
        </div>
       
        
        <div className="col-md-3">
            Seleziona un template:<br />
            {templates&&Array.isArray(templates)&&templates.map(template => (
               <div>
                <button className="btn btn-warning" onClick={() => selezionahtml(template.descrizione,template.html)}>{template.descrizione}</button>
                </div>
            )
            )}
            </div>
        </div>
        <div className="row">
        <button className="btn btn-success" onClick={salvadocumento}><FontAwesomeIcon icon={faSave} /></button>
        <button className="btn btn-info" onClick={stampahtmlpers}><FontAwesomeIcon icon={faPrint} /></button>
        </div>
        <div className="row">
				<ListaFilesProto files={files} handleEliminaFile={eliminafile}
                file={file} onChangeFile={onChangeFile} uploadfile={salvadocumento} allega={true} />
		</div>
        </div>
    )

}