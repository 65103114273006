import React from "react";

export default function InputText({bootstrapsize, label, id, type, placeholder,name, value, onChange, step, align, disabled, width}){
 
    return (
        <div className={"form-group "+bootstrapsize}>
            {label&&<label for={id}>{label}</label>}
            <input 
            type={type}
            style={{textAlign:type==='number'?'right':align?align:'',width:width?width:'100%',margin:0, padding:0, height:'1.5em'}}
            step={step?step:1}
            id={id?id:Math.floor(Math.random() * 100)} 
            className={label?"form-control":''}
            placeholder={placeholder} name={name} value={value} onChange={onChange}
            disabled={disabled?disabled:false} />
        </div>
    );
}