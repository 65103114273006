import React from "react";
import axios from 'axios';
import '../util/config'
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import LoadMainThings from "../util/LoadMainThings";


export default function InsPalestra(props) {

  const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);

  const [palestra, setpalestra] = useState('');
  const [descrizioneattivita, setdescrizioneattivita] = useState('');
  const [logo, setlogo] = useState('');

  const [indirizzo, setindirizzo] = useState('');
  const [citta, setcitta] = useState('');
  const [cap, setcap] = useState('');
  const [piva, setpiva] = useState('');
  const [telefono, settelefono] = useState('');
  const [email, setemail] = useState('');
  const [gpscoords, setgpscoords] = useState('');
  const [website, setwebsite] = useState('');
  const [subdomain, setsubdomain] = useState('');
  const [nazione, setnazione] = useState('');
  const [urlicon, seturlicon] = useState('');
  const [urlbadge, seturlbadge] = useState('');
  const [appPw, setappPw] = useState('');
  const [orarioApertura1, setorarioApertura1] = useState('');
  const [orarioChiusura1, setorarioChiusura1] = useState('');
  const [orarioApertura2, setorarioApertura2] = useState('');
  const [orarioChiusura2, setorarioChiusura2] = useState('');

  function handleSubmit(e){
    e.preventDefault();
		  const formData = new FormData();
		  formData.append('website', global.config.domain);
		  formData.append('email',emailloggato);
		  formData.append('lic','000');
      formData.append('id',  0);
		  formData.append('palestra', palestra);
		  formData.append('descrizione', descrizioneattivita);
      formData.append('logo', logo);
      formData.append('indirizzo', indirizzo);
      formData.append('citta', citta);
      formData.append('cap', cap);
      formData.append('piva', piva);
      formData.append('telefono', telefono);
      formData.append('emailpalestra', email);
      formData.append('gpscoords', gpscoords);
      formData.append('websitepalestra', website);
      formData.append('subdomain', subdomain);
      formData.append('nazione', nazione);
      formData.append('urlIcon', urlicon?urlicon:'');//ok
      formData.append('urlBadge', urlbadge?urlbadge:'');//ok
      formData.append('appPw', appPw?appPw:'');
      formData.append('orarioApertura1', orarioApertura1?orarioApertura1:'');
      formData.append('orarioChiusura1', orarioChiusura1?orarioChiusura1:'');
      formData.append('orarioApertura2', orarioApertura2?orarioApertura2:'');
      formData.append('orarioChiusura2', orarioChiusura2?orarioChiusura2:'');
      axios.post(global.config.server+'/nuovaPalestra', formData)
        .then((result) => {
			console.log(result.data)
      if (result.data.id>0){
        alert('Inserimento effettuato correttamente');
        window.location.href="/"
      }
        else{
          alert('Si è verificato un problema durante l\'inserimento. Riprovare più tardi');
        }
        })
        .catch(err => {
            if (err.response){
              alert(err.response.data.message);
            }
            else{
              alert(err.response);
            }
      });
	  }

    useEffect(()=>{
      


    },[])

    return (
        <form method="POST" onSubmit={handleSubmit} >
          
	        <div className="row">
			
			<div className="col-md">
				<div className="form-group">
            		<label htmlFor="palestra">Nome</label>
              		<input type="text" className="form-control" 
                      placeholder="Nome *" id="palestra" 
                      name="palestra" 
                      value={palestra} 
                      required="true" onChange={(e)=>setpalestra(e.target.value)} />
            	</div>
	        </div>
	        <div className="col-md">
				<div className="form-group">
            		<label htmlFor="palestra">Sottodominio</label>
              		<input type="text" className="form-control" 
                      placeholder="Sottodominio" 
                      id="sottodominio" 
                      name="subdomain" 
                      value={subdomain} onChange={(e)=>setsubdomain(e.target.value)} />
            	</div>
	        </div>
	        <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="piva">Partita Iva</label>
	            		<input type="text" className="form-control" 
                        placeholder="Partita IVA *" 
                        id="piva" 
                        name="piva" 
                        value={piva}
                        required="true" onChange={(e)=>setpiva(e.target.value)} />
	            	</div>
	        </div>
	        <div className="col-md">
	            	<div className="form-group">
	            	<label htmlFor="descr">Descrizione attività</label>
	            		<input type="text" className="form-control" 
                        placeholder="Descrizione attività" 
                        id="descr" 
                        name="descrizioneattivita"  
                        value={descrizioneattivita} required="true" onChange={(e)=>setdescrizioneattivita(e.target.value)} />
	            	</div>
	        </div>
	        <div className="w-100"></div>
	        
	        <div className="w-100"></div>
	        <div className="col-md">
			        <div className="form-group">
		            	<label htmlFor="indirizzo">Indirizzo</label>
		            		<input type="text" className="form-control" 
                            placeholder="Indirizzo *" 
                            id="indirizzo" 
                            name="indirizzo"  
                            value={indirizzo}
                            required="true" onChange={(e)=>setindirizzo(e.target.value)} />
		            	</div>
		   </div>
	        <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="citta">Città</label>
		            		<input type="text" className="form-control" 
                            placeholder="Città *" 
                            name="citta" 
                            id="citta" 
                            value={citta} required="true" onChange={(e)=>setcitta(e.target.value)} />
		            	</div>
	       </div>
	       <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="cap">Cap</label>
		            		<input type="text" className="form-control" 
                            placeholder="CAP *" 
                            name="cap" id="cap" 
                            value={cap} required="true" onChange={(e)=>setcap(e.target.value)} />
		            	</div>
	       </div>
	        <div className="col-md">
		            	<div className="form-group">
		            	<label htmlFor="nazione">Nazione</label>
		            		<input type="text" className="form-control" 
                            placeholder="nazione *" 
                            name="nazione" id="nazione" 
                            value={nazione} required="true" onChange={(e)=>setnazione(e.target.value)} />
		            	</div>
	       </div>
	       <div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="tel">Telefono</label>
			              <input type="text" className="form-control" 
                          placeholder="tel *" 
                          name="telefono" id="telefono" 
                          value={telefono} required="true" onChange={(e)=>settelefono(e.target.value)} />
			            </div>
			</div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Email</label>
			              <input type="text" className="form-control" 
                          placeholder="email *" 
                          name="email" id="email" 
                          value={email} required="true" onChange={(e)=>setemail(e.target.value)} />
			            </div>
			</div>
			<div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Coordinate gps</label>
			              <input type="text" className="form-control" 
                          placeholder="stringa ricerca google maps" 
                          name="gpscoords" id="gpscoords" 
                          value={gpscoords} onChange={(e)=>setgpscoords(e.target.value)} />
			            </div>
			</div>
	    <div className="col-md">
          <div className="form-group">
          <label htmlFor="email">Sito web</label>
            <input type="text" className="form-control" 
                  placeholder="sito web" 
                  name="website" id="website" 
                  value={website} onChange={(e)=>setwebsite(e.target.value)} />
          </div>
			</div>
      <div className="col-md">
          <div className="form-group">
          <label htmlFor="email">WP Application password (Application password plugin)</label>
            <input type="text" className="form-control" 
                  placeholder="sito web" 
                  name="wpApplicationPass" id="wpApplicationPass" 
                  value={appPw} onChange={(e)=>setappPw(e.target.value)} />
          </div>
			</div>

      <div className="w-100"></div>
	        <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario apertura mattutina</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario apertura mattutina" 
                          name="orarioApertura1" id="orarioApertura1" 
                          value={""+orarioApertura1} onChange={(e)=>setorarioApertura1(e.target.value)} />
			            </div>
			</div>

      <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario chiusura mattutina</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario chiusura mattutina" 
                          name="orarioChiusura1" id="orarioChiusura1" 
                          value={orarioChiusura1} onChange={(e)=>setorarioChiusura1(e.target.value)} />
			            </div>
			</div>

      <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario apertura pomeridiana</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario apertura pomeridiana" 
                          name="orarioApertura2" id="orarioApertura2" 
                          value={orarioApertura2} onChange={(e)=>setorarioApertura2(e.target.value)} />
			            </div>
			</div>

      <div className="col-md">
			            <div className="form-group">
			            <label htmlFor="email">Orario chiusura pomeridiana</label>
			              <input type="time" className="form-control" 
                          placeholder="Orario apertura mattutina" 
                          name="orarioChiusura2" id="orarioChiusura2" 
                          value={orarioChiusura2} onChange={(e)=>setorarioChiusura2(e.target.value)} />
			            </div>
			</div>

	    </div>
	    <div className="row">
            <div className="form-group">
	            <img src={logo} alt="logo" width="100" /><br />
	              Link Immagine: <input type="text" name="logo" value={logo} onChange={(e)=>setlogo(e.target.value)} />
            </div>
            <div className="form-group">
	            <img src={urlicon} alt="logo" width="100" /><br />
	              Link icona principale notifiche (32x32): <input type="text" name="urlicon" value={urlicon} onChange={(e)=>seturlicon(e.target.value)} />
            </div>
            <div className="form-group">
	            <img src={urlbadge} alt="logo" width="100" /><br />
	              Link icona secondaria notifiche: <input type="text" name="urlbadge" value={urlbadge} onChange={(e)=>seturlbadge(e.target.value)} />
	              <br /><br />
                </div>
            <div className="form-group text-center">
              <input type="submit" className="btnSubmit" value="Salva" />
            </div>
       </div>
        </form>
        );
}