import React, { useEffect, useState } from "react";
import CorsoInput from "./CorsoInput";
import NuovoCorsoInput from "./NuovoCorsoInput";
import { useDispatch, useSelector } from "react-redux";
import { getcorsi } from "../../DAO/corsiDAO";
import { getTipi } from "../../DAO/tipiprodottiDAO";
import { settipiprodotto } from "../../STORE/reducers/gestoreTipiProdotto";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Input } from "@mui/material";

export default function ListaCorsi(props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
  	const emailloggato = useSelector(state => state.gestoreUtente.emailloggato);
	const tipi = useSelector(state => state.gestoreTipiProdotto.tipi);
	const [expanded, setExpanded] = useState(false);
	
	const handleChange =
    (idcorso, panel, isExpanded) => {
	  setExpanded(isExpanded ? idcorso : false);
    };

	const dispatch=useDispatch();

  	const [tipo, settipo] = useState(null);//route.params.idtipo.id)
  	const [prodotti, setprodotti] = useState([]);

  	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());

	const [filtro, setfiltro] = useState('')

	  function caricaTipiProdotto() {
		
	
		async function caricaTipi() {
			const tipidb = await getTipi(
			  palestra.palestra.id,
			  palestra.idtipicorso&&palestra.idtipicorso!==''?palestra.idtipicorso:'',
			  '',100
			);
			console.log("caricaTipiProdotto ",tipidb)
			if (tipo){
				caricaProdotti(tipo)
			}
			dispatch(settipiprodotto(tipidb));
			
		}

		if (palestra&&palestra.palestra&&palestra.palestra.id){
		  caricaTipi();
		}
	  }

	function caricaProdotti(tipotemp) {
		setfiltro("")
		console.log('caricaProdotti')
		async function caricaProd() {
			const corsi = await getcorsi(
				emailloggato,
			  palestra.palestra.id,
			  tipotemp.id
			);
			console.log("corsi ",corsi)
			settipo(tipotemp)
			setprodotti(corsi)
			//setkeytorefresh(new Date().getTime())
		}
		caricaProd();
	  }
	
	  useEffect(() => {
		console.log('useEffect ListaCorsi')
		caricaTipiProdotto();
	  }, [palestra, keytorefresh]);


	  return (
			  <div className="container" key={keytorefresh}>
				<div>
					{
					tipi.map(tipotemp =>(
						<button key={tipotemp.nome} onClick={() => caricaProdotti(tipotemp)} style={{fontWeight:'bold', color:tipotemp.visibile?"green":"orange"}}>{tipotemp.nome}</button>
					))
					}
				</div>
				  <NuovoCorsoInput setkeytorefresh={setkeytorefresh} tipiCorso={tipi} tipo={tipo} />
			  
					  {tipo && <h3 className="text-center">{tipo.nome}</h3>}
					  <Input aria-label="filtra" value={filtro} placeholder="filtra prodotti" onChange={(e) => setfiltro(e.target.value)} />
						  {
						  prodotti&&prodotti.map(prodottotemp =>(
							prodottotemp.corsi&&prodottotemp.corsi.map(corso =>(
								corso.nome.toLowerCase().indexOf(filtro.toLowerCase())>=0 &&
								<Accordion key={corso.id} expanded={expanded === corso.id} onChange={(event,isExpanded) => handleChange(corso.id,event, isExpanded)}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls={corso.id+"bh-content"}
										id={corso.id+"bh-header"}
										style={{fontWeight:'bold', color:corso.disponibilita==='disponibile'&&corso.disponibileda<=new Date().toISOString().slice(0, 10)&&corso.disponibilea>=new Date().toISOString().slice(0, 10)?"green":"orange"}}>
											<Typography sx={{ width: '33%', flexShrink: 0 }}
											style={{fontWeight:'bold'}}>
												{corso.nome}
											</Typography>
											{corso.disponibilita==='disponibile'&&corso.disponibileda<=new Date().toISOString().slice(0, 10)&&corso.disponibilea>=new Date().toISOString().slice(0, 10)?"DISPONIBILE":""}
									</AccordionSummary>
									<AccordionDetails>
										<CorsoInput corso={corso} 
										setkeytorefresh={setkeytorefresh} 
										tipiCorso={tipi} 
										noncino={props.noncino}
										email={emailloggato}
										/>
									</AccordionDetails>
								 </Accordion>
						  )
							
						  )
						  ))
					  }
				
			  <NuovoCorsoInput setkeytorefresh={setkeytorefresh} tipiCorso={tipi} tipo={tipo} />
			  </div>
	  );
  }