import React from "react";
import serverInfo from "../util/globals";

export default function ListaFilesProto({files, handleEliminaFile, onChangeFile, uploadfile, file, allega}){

    return (
        <>
        {files&&Array.isArray(files)&&files.length>0?
        <div className="col-md-6">
            {files.map(item =>(
                <div key={item.id}>
                    <a href={serverInfo.namesec+"/wp/scaricaFile/"+item.id}>{item.nomeOriginale} ({item.dimensione}K)</a> | 
                    <a type="button" className="btn btn-danger" onClick={() => handleEliminaFile(item.id)} id={item.id}>Elimina</a>
                </div>
            )
            )
            }
            </div>
        :"Nessun allegato"
        }
       {allega&& <div className="col-md-3">
            <h5>Allega un file</h5>
				<input type="file" onChange={onChangeFile} />
				{file?
				<a type="button" className="btn btn-primary" onClick={uploadfile} >Salva FILE</a>		
				:null}
		</div>}
</>
    );
}