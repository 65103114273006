import React from "react";
import axios from 'axios';
import '../../util/config'
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";

class SchedaDiarioAlimentare extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
            id:0,
            idanagrafica:0,
            sesso:'',
            proteinegrkg:0,
            proteinegrtot:0,
            proteinekcal:0,
            proteineperc:0,
            carboidratigrkg:0,
            carboidratigrtot:0,
            carboidratikcal:0,
            carboidratiperc:0,
            lipidigrkg:0,
            lipidigrtot:0,
            lipidikcal:0,
            lipidiperc:0,
            calorieassuntegiorno:0,
            mediabmr:0, 
            mediatdee:0,
            caloriedaassumeregiorno:0,
            palestra:0,
            data:'',
            idutente:0,
	    }
        this.update = this.update.bind(this);
        this.salvaScheda = this.salvaScheda.bind(this);
        this.eliminaRiga = this.eliminaEsercizio.bind(this);
        this.resetValue = this.resetValue.bind(this);
        this.recalculatePerc = this.recalculatePerc.bind(this);
	  }

      resetValue(){
        this.setState({
            id:0,
            proteinegrkg:0,
            proteinegrtot:0,
            proteinekcal:0,
            proteineperc:0,
            carboidratigrkg:0,
            carboidratigrtot:0,
            carboidratikcal:0,
            carboidratiperc:0,
            lipidigrkg:0,
            lipidigrtot:0,
            lipidikcal:0,
            lipidiperc:0,
            calorieassuntegiorno:0,
            mediabmr:0, 
            mediatdee:0,
            caloriedaassumeregiorno:0,
            data:new Date().toISOString().slice(0, 10),
        }) 
      }

	componentDidMount(){
        const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('idanagrafica',this.props.anagselezionata.id);
        axios.post(global.config.server+"/getUltimaSchedaDA", formData)
        .then(response=>{
            this.setState({...response.data, nuovaRigaVisible:true
                //pltotale:(parseInt(response.data.plbicipite)+parseInt(response.data.pltricipite)+parseInt(response.data.plsottoscapolare)+parseInt(response.data.plsovrailiaca))
            })
        });
    }

    salvaScheda(e) {
        e.preventDefault()
        if (this.state.peso===''||this.state.peso==='null'){
            alert('Inserire il peso nella scheda alimentare')
            return
        }
        if (this.state.mediabmr===''||this.state.mediabmr==='null'){
            alert('Inserire la media BMR')
            return
        }
        if (this.state.mediatdee===''||this.state.mediatdee==='null'
        ||parseInt(this.state.mediatdee)<=0){
            alert("Inserire la media TDEE")
            return
        }
        if (this.state.caloriedaassumeregiorno===''||this.state.caloriedaassumeregiorno==='null'
        ||parseInt(this.state.caloriedaassumeregiorno)<=0){
            alert("Inserire le calorie da assumere giornalmente")
            return
        }

	    const formData = new FormData();
        formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
	    formData.append('id', this.state.id);
		formData.append('data', this.state.data);
        formData.append('peso', this.state.peso);
        formData.append('idanagrafica',this.props.anagselezionata.id);
        
        formData.append('proteinegrkg', this.state.proteinegrkg);
        formData.append('proteinegrtot', this.state.proteinegrtot);
        formData.append('proteinekcal', this.state.proteinekcal);
        formData.append('proteineperc', this.state.proteineperc);
        formData.append('carboidratigrkg', this.state.carboidratigrkg);
        formData.append('carboidratigrtot', this.state.carboidratigrtot);
        formData.append('carboidratikcal', this.state.carboidratikcal);
        formData.append('carboidratiperc', this.state.carboidratiperc);
        formData.append('lipidigrkg', this.state.lipidigrkg);
        formData.append('lipidigrtot', this.state.lipidigrtot);
        formData.append('lipidikcal', this.state.lipidikcal);
        formData.append('lipidiperc', this.state.lipidiperc);
        formData.append('calorieassuntegiorno', this.state.calorieassuntegiorno);
        formData.append('mediabmr', this.state.mediabmr);
        formData.append('mediatdee', this.state.mediatdee);
        formData.append('caloriedaassumeregiorno', this.state.caloriedaassumeregiorno);

       formData.append('palestra',this.state.palestra);
       formData.append('idutente',this.state.idutente);

	      axios.post(global.config.server+'/aggiornaSchedaDA',formData)
	      .then((result) => {
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: "Misurazione aggiornata con successo"
					})
                    window.location.reload()
	      })
          .catch(err => {
                alert(err);
            });
    }

    recalculatePerc(){

    }

	update(e) {
        //somma valori medi diario alimentare
        this.setState({ [e.target.name]: e.target.value},()=>{
            if (e.target.name==='mediabmr'
            ||e.target.name==='mediatdee'){
                this.setState({caloriedaassumeregiorno:(parseInt(this.state.mediabmr)+parseInt(this.state.mediatdee))})
            }
            if (e.target.name==='proteinegrkg'){
                let grkg=Math.round(e.target.value*this.state.peso)
                let kcal=Math.round(grkg*4)
                let caloriegiorno=Math.round(parseFloat(this.state.lipidikcal?this.state.lipidikcal:0)+parseFloat(kcal)+parseFloat(this.state.carboidratikcal?this.state.carboidratikcal:0))
                let percgiorno=Math.round(100*kcal/caloriegiorno)
                this.setState({proteinegrtot:grkg, proteinekcal:kcal,
                    calorieassuntegiorno:caloriegiorno,
                    proteineperc:percgiorno,
                    carboidratiperc:Math.round(100*parseFloat(this.state.carboidratikcal?this.state.carboidratikcal:0) /caloriegiorno),
                    lipidiperc:Math.round(100*parseFloat(this.state.lipidikcal?this.state.lipidikcal:0) /caloriegiorno)
                })
            }
            if (e.target.name==='carboidratigrkg'){
                let grkg=Math.round(e.target.value*this.state.peso)
                let kcal=Math.round(grkg*4)
                let caloriegiorno=Math.round(parseFloat(this.state.lipidikcal?this.state.lipidikcal:0)+parseFloat(this.state.proteinekcal?this.state.proteinekcal:0)+parseFloat(kcal))
                let percgiorno=Math.round(100*kcal/caloriegiorno)
                this.setState({carboidratigrtot:grkg, carboidratikcal:kcal,
                    calorieassuntegiorno:caloriegiorno,
                carboidratiperc:percgiorno,
                proteineperc:Math.round(100*parseFloat(this.state.proteinekcal?this.state.proteinekcal:0) /caloriegiorno),
                lipidiperc:Math.round(100*parseFloat(this.state.lipidikcal?this.state.lipidikcal:0) /caloriegiorno)
            })
            }
            if (e.target.name==='lipidigrkg'){
                let grkg=Math.round(e.target.value*this.state.peso)
                let kcal=Math.round(grkg*9)
                let caloriegiorno=Math.round(parseFloat(kcal)+parseFloat(this.state.proteinekcal?this.state.proteinekcal:0)+parseFloat(this.state.carboidratikcal?this.state.carboidratikcal:0))
                let percgiorno=Math.round(100*kcal/caloriegiorno)
                this.setState({lipidigrtot:grkg, lipidikcal:kcal, 
                    calorieassuntegiorno:caloriegiorno,
                    carboidratiperc:Math.round(100*parseFloat(this.state.carboidratikcal?this.state.carboidratikcal:0) /caloriegiorno),
                    proteineperc:Math.round(100*parseFloat(this.state.proteinekcal?this.state.proteinekcal:0) /caloriegiorno),
                    lipidiperc:percgiorno
                })
            }
            
            if (e.target.name==='lipidikcal'){
                this.setState({calorieassuntegiorno:parseInt(e.target.value)+parseInt(this.state.proteinekcal)+parseInt(this.state.carboidratikcal)})
            }
            if (e.target.name==='proteinekcal'){
                this.setState({calorieassuntegiorno:parseInt(this.state.lipidikcal)+parseInt(e.target.value)+parseInt(this.state.carboidratikcal)})
            }
            if (e.target.name==='carboidratikcal'){
                this.setState({calorieassuntegiorno:parseInt(this.state.lipidikcal)+parseInt(this.state.proteinekcal)+parseInt(e.target.value)})
            }
    });
    }

    eliminaEsercizio(et) {
        et.preventDefault()
        if (window.confirm('Sei sicuro di voler cancellare questa scheda?')){
            const formData2 = new FormData();
            formData2.append('website', global.config.domain);
            formData2.append('email',this.props.email);
            formData2.append('lic','000');
            formData2.append('palestra',this.props.palestra.id);
            formData2.append('id',this.state.id);
            axios.post(global.config.server+'/eliminaSchedaDA', formData2)
            .then((result) => {
                    this.setState({messaggio:result.data});
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                        });
                        Toast.fire({
                        type: 'success',
                        title: result.data
                        })
                        this.refresh()
                        
          });
          
        }
    }

	render() {
	    return (
	    		<div className="container-fluid">
                    <h4>
                        Scheda Diario Alimentare {this.state.id===0?'NUOVA':''} di <u>{this.state.nome+' '+this.state.cognome+' ('+ this.state.sesso+')'}</u>
                        <Button onClick={this.resetValue}>Nuova misurazione</Button>
                    </h4>
                    <div className="row">
                    <h5>Data misurazione <input type="date" name="data" onChange={this.update} value={this.state.data} />&nbsp;
                    Peso kg <input type="number" name="peso" onChange={this.update} value={this.state.peso} /></h5>
                    </div>
                    <br/>
                    <div className="row">
                        <h4>Valori medi diario alimentare</h4>
                    </div>
                    <div className="row form-group">
                        <div className="col-4">
                            <label>BMR (metabolismo basale) Kcal
                            <input className="form-control" type="number" min={0} name="mediabmr" value={this.state.mediabmr} onChange={this.update} step={1} />
                            </label>
                        </div>
                        <div className="col-4">
                            <label>TDEE (fabbisogno energetico giornaliero) Kcal
                            <input className="form-control" type="number" min={0} name="mediatdee" value={this.state.mediatdee} onChange={this.update} step={1} />
                            </label>
                        </div>
                        <div className="col-4">
                            <label>Calorie da assumere giornalmente Kcal
                            <input className="form-control" type="number" min={0} name="caloriedaassumeregiorno" value={this.state.caloriedaassumeregiorno} onChange={this.update} step={1} />
                            </label>
                        </div>
                    </div>
                    <hr />

                    <div className="row">
                        <div className="col-md-4">
                    <table className="table table-striped" style={{background:"gray"}}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <h4 style={{textAlign:"center"}}>Proteine</h4>
                                </th>
                                </tr>
                                <tr>
                                    <th>Gr/Kg</th>
                                    <th>Gr Tot.</th>
                                    <th>Kcal</th>
                                    <th>%</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="number" name="proteinegrkg" value={this.state.proteinegrkg} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="proteinegrtot" value={this.state.proteinegrtot} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="proteinekcal" value={this.state.proteinekcal} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="proteineperc" value={this.state.proteineperc} onChange={this.update} step={0.01} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>

                    <table className="table table-striped" style={{background:"green"}}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <h4 style={{textAlign:"center"}}>Carboidrati</h4>
                                </th>
                                </tr>
                                <tr>
                                    <th>Gr/Kg</th>
                                    <th>Gr Tot.</th>
                                    <th>Kcal</th>
                                    <th>%</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="number" name="carboidratigrkg" value={this.state.carboidratigrkg} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="carboidratigrtot" value={this.state.carboidratigrtot} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="carboidratikcal" value={this.state.carboidratikcal} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="carboidratiperc" value={this.state.carboidratiperc} onChange={this.update} step={0.01} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>

                    <table className="table table-striped" style={{background:"yellow", color:"black"}}>
                        <thead>
                            <tr>
                                <th colSpan={4}>
                                    <h4 style={{textAlign:"center"}}>Lipidi</h4>
                                </th>
                                </tr>
                                <tr>
                                    <th>Gr/Kg</th>
                                    <th>Gr Tot.</th>
                                    <th>Kcal</th>
                                    <th>%</th>
                                </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <input type="number" name="lipidigrkg" value={this.state.lipidigrkg} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="lipidigrtot" value={this.state.lipidigrtot} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="lipidikcal" value={this.state.lipidikcal} onChange={this.update} step={0.01} />
                                </td>
                                <td>
                                    <input type="number" name="lipidiperc" value={this.state.lipidiperc} onChange={this.update} step={0.01} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
            </div>
            <br/>
<h4>Calorie assunte giornalmente <input type="number" name="calorieassuntegiorno" value={this.state.calorieassuntegiorno} onChange={this.update} step={1}/> </h4>
                                
                        <div className="col-xs col-sm w-100"></div>
                    <button className="btn btn-info" onClick={this.salvaScheda}>Salva scheda</button>
                    
			</div>
	    );
	  }
	}

    const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato,
            idanagselezionata: state.gestoreUtente.idanagselezionata,
            anagselezionata : state.gestoreUtente.anagselezionata
        }
    }
    
    export default connect(mapStateToProps, null)(SchedaDiarioAlimentare)