import React, { useState } from "react";
import '../../util/config'
import axios from 'axios';
import ScontoInput from "./ScontoInput";
import NuovoScontoInput from "./NuovoScontoInput";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export default function ScontiInput(props){
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata.palestra);
    const email = useSelector(state => state.gestoreUtente.emailloggato);

	const [listaSconti, setlistaSconti] = useState([]);

	const [keytorefresh, setkeytorefresh] = useState(new Date().getTime());
	
	useEffect(() =>{
		if (palestra&&palestra.id){
			const formData = new FormData();
			formData.append('website', palestra.website);
			formData.append('email',email);
			formData.append('lic','000');
			formData.append('palestra',palestra.id);
			axios.post(global.config.server+"/listaSconti", formData)
		.then(response=>{
			setlistaSconti(response.data);
			//console.log(response);
		});
		}
	},[palestra, keytorefresh]
	  )

    return (
    		<div key={keytorefresh}>
    		{
    			listaSconti.map(item =>(
    					<ScontoInput key={item.id} 
						selected={item} 
						email={props.email} 
						refresh={setkeytorefresh}
					 />
						
    					)
    			)
    		}
    		<NuovoScontoInput 
			email={props.email}
			refresh={setkeytorefresh} />
    		</div>
    );
}