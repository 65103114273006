import React from "react";
import axios from 'axios';
import Swal from "sweetalert2";
import {  Button, Form, } from "react-bootstrap";
import CorsiSelect from "../archivi/corsi/CorsiSelect";
import UtentiFilterSelect from "../anagrafica/UtentiFilterSelect";
import {connect} from 'react-redux'
import serverInfo from "../util/globals";

class DettaglioSchedulazione extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
				...this.props.schedulazione,
				duratagiornaliera:0
	    	}

        this.update = this.update.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.aggiungiPrenotazione=this.aggiungiPrenotazione.bind(this)
		this.annullaPrenotazione=this.annullaPrenotazione.bind(this)
		this.refreshPrenotazioni=this.refreshPrenotazioni.bind(this)
		this.eliminaSchedulazione=this.eliminaSchedulazione.bind(this)
		this.inviaMessaggio = this.inviaMessaggio.bind(this);
	  }

	update(e) {
		if (e.target.name==='fisso'){
			this.setState({ [e.target.name]: e.target.checked?1:0});
		}else if(e.target.name==='duratagiornaliera'){
			this.setState({ [e.target.name]: e.target.checked?1:0});
		}
		else{
			this.setState({ [e.target.name]: e.target.value});
		}
	}

	inviaMessaggio(){
        if (this.state.messaggio.trim()===''){
            alert("Messaggio obbligatorio");
            return;
        }
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
        formData.append('msg', this.state.messaggio);
		formData.append('idschedulazione', this.state.id);
        axios.post(global.config.server+'/inviaMessaggioSchedulazione', formData)
        .then((result) => {
            alert(result.data)
        });
	  }

	annullaPrenotazione(e) {
		if (window.confirm("Vuoi annullare la prenotazione selezionata?")){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.palestra.id);
			formData.append('idPrenotazione',e.target.id);
			formData.append('calendario',this.props.calendario);
			axios.post(serverInfo.namesec+'/abb/annullaPrenotazione',formData,
			{
				headers: {
				  Accept: 'application/json',
				  'Content-Type': 'multipart/form-data',
				  'Access-Control-Allow-Origin': '*',
				  'Access-Control-Allow-Methods': 'POST',
				  'Access-Control-Allow-Headers': 'Content-Type, Authorization',
				  'Access-Control-Allow-Credentials': 'true',
				  'Authorization' :'Bearer '+window.$t
				},
			  },
			)
			.then((result)=>{
				alert(result.data)
				this.setState({visible:false})
				this.refreshPrenotazioni()
			})
			.catch(err => {
				if (err.response){
					alert(err.response.data.message);
				}
				else{
					alert(err)
				}
			})
		}
	}

	annullaDefinitivamente(e) {
		if (window.confirm("Vuoi annullare la prenotazione fissa selezionata?")){
			const formData = new FormData();
			formData.append('idPrenotazione',e.target.id);
			axios.post(global.config.server+'/annullaDefinitivamente',formData)
			.then((result)=>{
				alert(result.data)
				this.setState({visible:false})
				this.refreshPrenotazioni()
			})
			.catch(err => {
				if (err.response){
					alert(err.response.data.message);
				}
				else{
					alert(err)
				}
			})
		}
	}

	eliminaSchedulazione(e) {
		if (window.confirm("Vuoi eliminare la schedulazione selezionata?")){
			const formData = new FormData();
			formData.append('website', global.config.domain);
			formData.append('email',this.props.email);
			formData.append('lic','000');
			formData.append('palestra',this.props.palestra.id);
			formData.append('idSchedulazione',this.state.id);
			formData.append('calendario',this.props.calendario);
			axios.post(global.config.server+'/eliminaSchedulazione',formData)
			.then((result)=>{
				alert(result.data)
				this.setState({visible:false})
				this.props.refresh()
			})
			.catch(err => {
				if (err.response){
					alert(err.response.data.message);
				}
				else{
					alert(err)
				}
			})
		}
	}

	  aggiungiPrenotazione(){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
		formData.append('idUtente',this.state.anagrafica?this.state.anagrafica:'');
		formData.append('idAnagrafica',this.state.anagrafica?this.state.anagrafica:'');
		formData.append('nomelibero',this.state.nomeLibero?this.state.nomeLibero:'');
		formData.append('idSchedulazione',this.state.id);
		formData.append('fisso',this.state.fisso);
		formData.append('calendario',this.props.calendario);
		axios.post(global.config.server+'/inserisciPrenotazione',formData)
	      .then((result) => {
				this.setState({messaggio:result.data});
				if (result.data.conferma){
					if (window.confirm('Si è verificato il seguente problema: '+result.data.message+'. Continuare?')){
						axios.post(global.config.server+'/confermaPrenotazione',formData)
						.then((result2) => {
							alert(result2.data.message)
							this.refreshPrenotazioni()
						}).catch(err => {
							if (err.response){
								alert(err.response.data.message);
							}
							else{
								alert(err)
							}
						})
					}
				}
				else{
					alert(result.data.message)
					this.refreshPrenotazioni()
				}
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
	
	  }

	  refreshPrenotazioni(){
		const formData = new FormData();
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
		axios.post(global.config.server+'/getPrenotazioni/'+this.props.schedulazione.id,formData)
		.then((result)=>{
			this.setState({prenotazioni:result.data})
			console.log(result.data)
		})
		.catch(err => {
			if (err.response){
				alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		})
	}

	  componentDidMount(){
		this.setState({
			...this.props.schedulazione
		})
		this.refreshPrenotazioni()
		console.log(this.props)
	  }

      handleSubmit(event) {
		event.preventDefault();
	    const { id,durata,nummaxprenotazioni,corso, ora, data, tipoPrenotazione} = this.state;

		const formData = new FormData();
	    formData.append('duratagiornaliera', this.state.duratagiornaliera);
		formData.append('durata', durata);
	    formData.append('numMaxPrenotazioni', nummaxprenotazioni);
		formData.append('corso', corso);
		formData.append('data', data);
		formData.append('ora', ora);
		formData.append('id', id);
		formData.append('tipoPrenotazione', tipoPrenotazione);
		formData.append('website', global.config.domain);
		formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);
		formData.append('calendario',this.props.calendario);
		axios.post(global.config.server+'/aggiornaSchedulazione',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
					this.props.refresh()
	      }).catch(err => {
			if (err.response){
		  alert(err.response.data.message);
			}
			else{
				alert(err)
			}
		});
      }
      
	render() {
	    return (
			<div>
				<h2>Schedulazione</h2>
				<a name="schedulazione" />
				<form>
					<div className="row">
					<div className="col">
					<Form.Group controlId="datapagamento">
						<Form.Label>Seleziona un Prodotto</Form.Label>
                        <CorsiSelect onChange={this.update} selected={this.state.corso} corsi={this.state.listaCorsi}/>
					</Form.Group>
					
					</div>
					<div className="col">
					<Form.Group controlId="Giorno">
						<Form.Label>Giorno</Form.Label>
						<Form.Control type="date" placeholder="giorno" dateFormat="DD/MM/YYYY"
						name="data" value={this.state.data} required={true} onChange={this.update} />
					</Form.Group>
					</div>
					<div className="col">
                    <Form.Group controlId="importo">
						<Form.Label>Ora</Form.Label>
						<Form.Control type="time" placeholder="ora" 
						name="ora" value={this.state.ora} required={true} onChange={this.update} 
                        min="9" max="22"/>
					</Form.Group>
					</div>
					<div className="col">
                    <Form.Group controlId="importo">
						<Form.Label>Durata</Form.Label>
						<Form.Control type="number" placeholder="Durata" 
						name="durata" value={this.state.durata} required={true} onChange={this.update} 
                        min={5}/>
						<label>Tutto il giorno <input type={"checkbox"} name={"duratagiornaliera"} onChange={this.update}  /></label>
					</Form.Group>
					</div>
					<div className="col">
					<Form.Group>
						<Form.Label>Max prenotazioni</Form.Label>
						<Form.Control type="number" placeholder="Max Prenotazioni" 
						name="nummaxprenotazioni" value={this.state.nummaxprenotazioni} required={true} onChange={this.update} 
                        />
					</Form.Group>
					</div>
					<div className="col">
					<Form.Group>
						<Form.Label>Tipo prenotazione</Form.Label>
						<select defaultValue={this.state.tipoPrenotazione} name="tipoPrenotazione" required={true} onChange={this.update}>
							<option value="">--tipo prenotazione--</option>
							<option value="Abbonamento">Abbonamento</option>
							<option value="Free">Free</option>
							<option value="Pagamento Anticipato">Pagamento Anticipato</option>
							<option value="Pagamento Posticipato o in loco">Pagamento Posticipato</option>
						</select>
					</Form.Group>
					</div>
					<div className="col">
					<Button variant="primary" onClick={this.handleSubmit}>
						Salva Schedulazione
					</Button>
					<Button variant="danger" onClick={this.eliminaSchedulazione}>
						Elimina Schedulazione
					</Button>
					</div>
					</div>
					<div className="row">
						<div className="col">
						<UtentiFilterSelect onChange={this.update}/>
						</div>
						<div className="col">
						<Form.Group controlId="importo">
							<Form.Label>Fisso
							<Form.Control type="checkbox"
							name="fisso" onChange={this.update} 
							/>
							</Form.Label>
						</Form.Group>
						</div>
					</div>
					<hr/>
					<div className="row">
						<div className="col">
							<label>Oppure inserisci un nome liberamente
								<input type="text" name="nomeLibero" value={this.state.nomeLibero} onChange={this.update} />
							</label>
						</div>
					</div>
					<div className="row">
					<div className="col-12">
						<Button variant="info" onClick={this.aggiungiPrenotazione}>
							Aggiungi
						</Button>
						</div>
					</div>
				</form>

<table style={{width:'100%'}}>
	<thead>
		<tr>
			<th>N.</th><th>Nome</th><th>Abb.</th><th>Fisso</th><th>Data prenotazione</th><th>Modalità</th><th>Data annullamento</th>
		</tr>
	</thead>
	<tbody>

	
					{
					this.state.prenotazioni
					&&Array.isArray(this.state.prenotazioni)
					&&this.state.prenotazioni.length>0?
					this.state.prenotazioni.filter(prenotazione=>prenotazione.dataAnnullamento===null||prenotazione.dataAnnullamento==="").map((prenotazione,i)=>(
						<tr>
							<td>{(i+1)>this.state.nummaxprenotazioni? "RISERVA "+(i+1-this.state.nummaxprenotazioni)+" ":(i+1)}){" "}</td>
							<td>{prenotazione.nomecognome}</td>
							<td>{prenotazione.listino}</td>
							<td>{prenotazione.fisso?"(FISSO)":null} </td>
							<td>{prenotazione.dataOraPrenotazione}</td>
							<td>{prenotazione.modalitaPrenotazione}</td>
							<td>
								<Button id={prenotazione.id} onClick={this.annullaPrenotazione} variant="danger" >Annulla prenotazione</Button>
								{prenotazione.fisso&&<Button id={prenotazione.id} onClick={this.annullaDefinitivamente} variant="danger" >Annulla prenotazione FISSA</Button>}
							</td>
						</tr>
))
					
:null}

					{
					this.state.prenotazioni
					&&Array.isArray(this.state.prenotazioni)
					&&this.state.prenotazioni.length>0?
					this.state.prenotazioni.filter(prenotazione=>prenotazione.dataAnnullamento).map(prenotazione=>(
						<tr>
							<td>NC</td>
							<td>{prenotazione.nomecognome}</td>
							<td>{prenotazione.listino}</td>
							<td>{prenotazione.fisso?"(FISSO)":null} </td>
							<td>{prenotazione.dataOraPrenotazione}</td>
							<td>{prenotazione.modalitaPrenotazione}</td>
							<td>{prenotazione.dataAnnullamento}</td>
						</tr>
					))
					:""
					}
					
					</tbody>
</table>

<div class="form-group">
            <label htmlFor="Messaggio">Messaggio</label>
            <textarea class="form-control" 
            name="messaggio" id="Messaggio" 
            value={this.state.messaggio}
            onChange={this.update}></textarea>
             <button class="btn btn-primary" onClick={this.inviaMessaggio}>Invia messaggio</button>
        </div>
				<Button variant="secondary" onClick={this.props.togglePopupDettaglio}>
					Close
				</Button>
			</div>
	    );
	  }
	}

	const mapStateToProps = state => {
		return {
			palestra: state.gestoreUtente.palestraassociata.palestra,
			email: state.gestoreUtente.emailloggato
		}
	}
	
	export default connect(mapStateToProps, null)(DettaglioSchedulazione)