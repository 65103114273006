import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stampaIncarico, stampaInformativa } from "../DAO/DocumentiDAO";
import ProtoUscitaAnag from "./protocolliUscitaAnag";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ProtoEntrataAnag from "./protocolliEntrataAnag";
import Text from "../util/UI/Text";

export default function DocumentiUtente(){
    const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);
    const anagselezionata = useSelector(state => state.gestoreUtente.anagselezionata);


    const [keytorefresh, setkeytorefresh] = useState(new Date().getTime())


    function stampainformativa(){
        async function stampainc(){
            stampaInformativa(palestra.palestra.id, anagselezionata.id)
        }
        if (palestra){
            stampainc()
        }
        else{
            alert('no palestra')
        }
    }

    function stampaincarico(){
        async function stampainc(){
            stampaIncarico(palestra.palestra.id, anagselezionata.id)
        }
        if (palestra){
            stampainc()
        }
        else{
            alert('no palestra')
        }
    }




    useEffect( ()=> {
    },[palestra])

    return(
        <div key={keytorefresh} className="container-fluid">
            <div className="row">
                <h4>Documenti {anagselezionata.nome} {anagselezionata.cognome}</h4>
            </div>
        <div className="row">
            <div className="col-md-3">
            <button onClick={stampaincarico}>
            Stampa lettera incarico 
            </button>
            {anagselezionata.dataincarico?
            <Text bootstrapsize={"col-md-12"}
            label={"Data conferma incarico"}
            value={anagselezionata.dataincarico}
             /> 
             :"Nessuna presa in carico"}
            </div>
            <div className="col-md-3">
            <button onClick={stampainformativa}>
            Stampa informativa privacy 
            </button>
            {anagselezionata.dataconsensoprivacy?
            <Text bootstrapsize={"col-md-12"}
            label={"Data consenso informativa privacy"}
            value={anagselezionata.dataconsensoprivacy}
             /> 
             :"Nessun consenso"}
            </div>
         </div>
        <div className="row">
            <div className="col">
                <Tabs>
                    <TabList>
                        <Tab>Ricevuti</Tab>
                        <Tab>Inviati</Tab>
                    </TabList>
                    <TabPanel>
                    <ProtoEntrataAnag />
				</TabPanel>
				<TabPanel>
                <ProtoUscitaAnag />
				</TabPanel>
			</Tabs>

            </div>
        </div>
       
        </div>
    );
}