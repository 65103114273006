import React, { useEffect, useState } from "react";
import '../../util/config'
import { getAllPlaces } from "../../DAO/tipiprodottiDAO";
import { useSelector } from "react-redux";

export default function LuogoSelect(props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);

	const [places, setplaces] = useState([]);
 
	 const[show, setshow] = useState(false);
	 const [selected, setselected] = useState(props.selected);
 
	 function caricaplaces(){
        async function carica(){
          const result = await getAllPlaces(palestra.palestra.id);
          //console.log("get all,places ",result);
          setplaces(result);
          if (result.length===1){
            setselected(result[0].id);
            props.setselected(result[0].id);
        }
          
        }
        if (!places || places.length===0){
          carica();
        }
        
    }

    useEffect(() => {
      caricaplaces();
        if (places.length===1){

          setselected(places[0].id);
          props.setselected(places[0].id);
      }
    },[]);
	
	    return (
        <span>
				<select name="luogo" selected={selected} onChange={(e)=>{setselected(e.target.value); props.setselected(e.target.value)}} >
					<option value="">--Seleziona un luogo--</option>
					{places&&Array.isArray(places)&&places.length>0?
					places.map(place =>(
					<option key={place.id} value={place.title} selected={place.title===selected}>{place.title}</option>
					)
					)
					:null}
				</select>
        <input type="text" placeholder="Oppure inseriscine uno nuovo" name="luogo" onChange={(e)=>{setselected(e.target.value);props.setselected(e.target.value)}} />
        </span>
	    );
	}