import React from "react";

export default class RiquadroRicAnagVeloce extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
        nomecognome:localStorage.getItem('ricnomecognome')?localStorage.getItem('ricnomecognome'):''
        };
        this.update = this.update.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    
    }

    componentDidMount(){
        this.setState({'nomecognome':localStorage.getItem('ricnomecognome')});
	  }
  
    componentDidCatch(error, info) {
          console.log(error, info);
        }
  
    update(e) {
        this.setState({ [e.target.name]: e.target.value});
    }

    handleSubmit(event){
		event.preventDefault();
        localStorage.setItem('ricnomecognome', this.state.nomecognome?this.state.nomecognome:'');
        window.location.href="/"+this.props.link
	  }

    render() {
      return (
        <span>
        {localStorage.getItem("palselezionata")
        &&localStorage.getItem("palselezionata")!==''
        &&parseInt(localStorage.getItem("palselezionata"))>0?
        <div>
            <form onSubmit={this.handleSubmit}>
            <input type="text" name="nomecognome" onChange={this.update} required={true} value={this.state.nomecognome} placeholder="cerca per cognome" /> 
            </form>
        </div>
        :null}
        </span>
        );
    }
  }