import React from "react";
import axios from 'axios';
import NuovaFattura from './NuovaFattura'
import FatturaEdit from './FatturaEdit'
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function ListaFatture (props) {
	const palestra = useSelector(state => state.gestoreUtente.palestraassociata);
    const email = useSelector(state => state.gestoreUtente.emailloggato);


const [abbonamento, setabbonamento] = useState(props.idabbonamento);
const [idUtente, setidutente] = useState(props.idUtente);
const [fatture, setfatture] = useState([]);
const [possibiliIntestatari, setpossibiliintestatari] = useState([]);
const [totFatture, settotfatture] = useState(0);
const [showNuova, setshownuova] = useState(false);


function showNuovaFattura(){
	setshownuova(!showNuova)
  }

  useEffect(() => {
	if (props.fatture
		&&Array.isArray(props.fatture)
		&&props.fatture.length>0){
			setfatture(props.fatture);
	}
	else if (props.idabbonamento&&palestra&&palestra.palestra&&palestra.palestra.id){
		const formData = new FormData();
		formData.append('website', global.config.domain);
        formData.append('email',email);
		formData.append('lic','000');
		formData.append('palestra',palestra.palestra.id);
		axios.post(global.config.server+"/getFatture/"+props.idabbonamento,formData)
		.then(response=>{
			setfatture(response.data);
			//console.log(response);
		});
	}
	

	axios.post(global.config.server+"/getPossibiliIntestatari/"+props.idUtente)
		.then(response=>{
			setpossibiliintestatari(response.data);
			console.log(response);
		});
},[]);

    return (
    		<span>
				{showNuova?
				<NuovaFattura show={showNuova} 
				showHide={showNuovaFattura} 
				email={email} 
				palestraassociata={palestra}
				idUtente={idUtente} 
				possibiliIntestatari={possibiliIntestatari} 
				idabbonamento={props.idabbonamento} 
				descrizioneAbbonamento={props.descrizioneAbbonamento}
				/>
	    		:null}
				<div className="row">
		    		{
						(fatture&&fatture.length>0?
							fatture.map(item =>(
								<FatturaEdit  selected={item} refresh={props.refresh} email={email} possibiliIntestatari={possibiliIntestatari} idUtente={idUtente} idabbonamento={props.idabbonamento}/>
							))
		    					:
								<div className="col-sm">Nessuna fattura</div>
		    			)
		    		}
	    		</div>
				<button className="btn btn-info btn-lg" onClick={showNuovaFattura}>Nuova Fattura</button>				
    		</span>
    );
}