import React from "react";
import '../../util/config'
import axios from 'axios';
import Swal from "sweetalert2";
import CategorieSelect from "./CategorieSelect";
import MostraImmagineLink from "./MostraImmagineLink";
import { connect } from "react-redux";

class EsercizioInput extends React.Component {
	constructor(props) {
	    super(props);
	    this.state = {
	    		id: this.props.selected.idesercizio,
	    		link: this.props.selected.link,
	    		link2: this.props.selected.link2,
				descrizione:this.props.selected.descrizione,
				categoria:this.props.selected.categoria,
				categorie:this.props.categorie,
	    		visible: true,
	    		messaggio:null
	    	}

	    this.update = this.update.bind(this);
	    this.elimina = this.elimina.bind(this);
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.updateCategoria = this.updateCategoria.bind(this);
	  }

	update(e) {
		  this.setState({ [e.target.name]: e.target.value, visible: true });
	  }

	  handleSubmit(event) {
		event.preventDefault();
	    const { id,link,descrizione,link2,categoria } = this.state;
	    const formData = new FormData();
		  formData.append('website', global.config.domain);
		  formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);        
	    formData.append('id', id);
		formData.append('descrizione', descrizione);
		formData.append('link', link);
		formData.append('link2', link2);
		formData.append('categoria', categoria);
		
	      axios.post(global.config.server+'/aggiornaEsercizioRest',formData)
	      .then((result) => {
		        this.setState({messaggio:result.data});
				const Toast = Swal.mixin({
					  toast: true,
					  position: 'top-end',
					  showConfirmButton: false,
					  timer: 3000
					});
					Toast.fire({
					  type: 'success',
					  title: result.data
					})
		          //this.props.refresh();
		          //this.setState({ visible: false });
	      });
	  }
	  
	  updateCategoria(categSelezionata) {
			var categoria = {...this.state.categoria}
			categoria = categSelezionata;
			this.setState({categoria, buttonVisible: true})
		  }

	  elimina(event) {
		  event.preventDefault();
		  if (window.confirm('Sei sicuro di voler cancellare il corso selezionato?')){
			const formData = new FormData();
		  formData.append('website', global.config.domain);
		  formData.append('email',this.props.email);
        formData.append('lic','000');
        formData.append('palestra',this.props.palestra.id);      
			  axios.post(global.config.server+'/eliminaEsercizioRest/'+this.state.id, formData)
			        .then((result) => {
				         const Toast = Swal.mixin({
						  toast: true,
						  position: 'top-end',
						  showConfirmButton: false,
						  timer: 3000
						});
						Toast.fire({
						  type: 'success',
						  title: result.data
						})
						
						this.props.refresh();
			        });
			}
	  }

	render() {
		var style1={
				color:"white"
		}
		
		var style2={
				width:"80px"
		}

	    return (
			this.state.visible?
			<div class="card">
			<div class="card-header">
				<CategorieSelect selected={this.state.categoria} categorie={this.props.categorie} updateCategoria={this.updateCategoria} />
			<div class="col-xs-3">
				<label htmlFor="ex2">Descrizione</label>
				<input className="form-control" type="text" name="descrizione" onChange={this.update} value={this.state.descrizione} />
			</div>
			</div>
			<div class="card-body">
				{
					this.props.selected.idSchedeAssociate
					&&this.props.selected.idSchedeAssociate.length>0?
						<p>Schede associate:
						{this.props.selected.idSchedeAssociate.map(idScheda=>(
							<a href={"/dettaglioSchedaSalaAttrezzi?idScheda="+idScheda}> - {idScheda}</a> 
						))
						}
						</p>
					:"nessuna scheda associata"
				}
			<div class="row">
				<div class="col-sm">
					<div class="media">
						<MostraImmagineLink link={this.state.link}/>
						<div class="media-body">
							<h5 class="mt-0">Link M</h5>
							<input type="text" name="link" onChange={this.update} value={this.state.link} />
						</div>
					</div>
				</div>
				<div class="col-sm">
					<div class="media">
						<MostraImmagineLink link={this.state.link2}/>
						<div class="media-body">
							<h5 class="mt-0">Link F</h5>
							<input type="text" name="link2" onChange={this.update} value={this.state.link2} />
						</div>
					</div>
				</div>
				</div>
			</div>
			<div class="card-footer text-muted row justify-content-md-center">
				<a href="#" class="btn btn-primary" onClick={this.handleSubmit}>Salva</a>&nbsp;&nbsp;&nbsp;
				<a href="#" class="btn btn-danger" onClick={this.elimina}>Elimina</a>
			</div>
			</div>
			:null
	    );
	  }
	}

	const mapStateToProps = state => {
        return {
            palestra: state.gestoreUtente.palestraassociata.palestra,
            email: state.gestoreUtente.emailloggato,
            idanagselezionata: state.gestoreUtente.idanagselezionata
        }
    }
    
    export default connect(mapStateToProps, null)(EsercizioInput)